<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bank Accounts
          <help-icon docPath="/administration/bankaccounts/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="create"
              class="btn btn-link"
              title="Create Bank Account"
              v-b-popover.hover.top.d500="'Create a new bank account'"
            >
              <i class="fa fa-plus dimmedIcon pointer"></i>
              <span class="sr-only">Create a new bank account</span>
            </button>
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
              <span class="sr-only">Clear filters</span>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="create" type="button">
          <i class="fa fa-plus rpad"></i>Create Bank Account
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Reference',
          field: 'reference',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Bank Name',
          field: 'bankName'
        },
        {
          label: 'Currency',
          field: 'currency'
        }
      ],
      serverParams: {
        sort: [{ field: 'reference', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    onRowClick (params) {
      var bankAccountId = params.row.bankAccountId
      this.$router.push({ name: 'BankAccountEdit', params: { id: bankAccountId } })
    },
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}bankAccounts`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading bank account'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    create () {
      this.$router.push({ name: 'BankAccountCreate' })
    }
  }
}

</script>
