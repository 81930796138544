<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>All Telemetry<span class="pull-right"><a :href="`${docUrl}/automation/logging/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :pagination-options="{
                dropdownAllowAll: false,
                enabled: true,
                perPageDropdown: [10, 20, 100]
              }"
              @on-cell-click="onCellClick">
              styleClass="vgt-table striped bordered table-hover"
            >
            <template slot="table-row" slot-scope="props">
                <span v-if = "props.column.field == 'createdAt'">
                  {{ reformatDate(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'updatedAt'">
                  {{ reformatDate(props.formattedRow[props.column.field])}}
                </span>
                <span v-else-if = "props.column.field == 'workflowId'">
                  <!-- <b-button @click.prevent="viewTimeline(props)" variant="link" class="pointer" title="View Timeline" v-b-popover.hover.top.d500="'View the timeline for this workflow instance.'"><span class="text-secondary"><i class="glyphicon ti-direction-alt"></i></span></b-button> -->
                  <b-button @click.prevent="viewTelemetry(props)" variant="link" class="pointer" title="View Telemetry" v-b-popover.hover.top.d500="'View the telemetry for this workflow instance.'"><span class="text-secondary"><i class="glyphicon ti-view-list"></i></span></b-button>
                </span>
                <span v-else-if = "props.column.field == 'title'">
                  <b-button @click.prevent="viewWorkflow(props)" class="pointer" variant="link" >{{props.formattedRow[props.column.field]}}</b-button>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
            </template>
            <div slot="table-actions">
              <b-button @click.prevent="loadItems" class="" variant="link" title="Refresh Table" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span></b-button>
              <!-- <b-button @click.prevent="printTable" class="" variant="link" title="Print Table" v-b-popover.hover.top.d500="'Print out the contents of the Logs table'" ><i class="fa fa-print pointer dimmedIcon"></i></b-button> -->
              <!-- <b-button @click.prevent="exportTable" class="" variant="link" title="Export Table" v-b-popover.hover.top.d500="'Export the contents of the Logs table'" ><i class="fas fa-file-export pointer dimmedIcon"></i></b-button> -->
            </div>
            </vue-good-table>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row" >
            <div class="col-md-12 col-sm-3">
              <!-- <b-button @click.prevent="viewTelemetry" variant="outline-primary" title="Export Telemetry" v-b-popover.hover.top.d500="'Exports this Trigger\'s telemetry data.'"><i class="fa fa-download mr-2"></i>Export Telemetry</b-button>  -->
              <b-button @click.prevent="deleteTrigger" variant="danger" class="pull-right" title="Delete Telemetry" v-b-popover.hover.top.d500="'Permanently deletes this trigger\'s telemetry data.'"><i class="glyphicon ti-trash mr-2"></i>Delete Telemetry</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'my-component',
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      id: this.$route.params.id,
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'executionId',
          field: 'executionId',
          hidden: true
        },
        {
          label: 'Instance',
          field: 'title',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Current Status',
          field: 'currentStatus',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Created At',
          field: 'createdAt',
          hidden: false,
          inputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
          outputFormat: 'DD-MM-YYYY HH:mm:ss'
        },
        {
          label: 'Last Updated At',
          field: 'updatedAt',
          hidden: false
        },
        {
          label: '',
          field: 'workflowId'
        }
      ],
      row_data: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'createdAt',
          type: 'desc'
        },
        page: 1,
        perPage: 10
      }
    }
  },
  computed: {
    rows: function () {
      return this.row_data
    }
  },
  methods: {
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params.sortType,
          field: this.columns[params.columnIndex].field
        }
      })
      this.loadItems()
    },
    onColumnFilter (params) {
      this.updateParams(params)
      this.loadItems()
    },
    loadItems () {
      const postObj = {
        serverParams: this.serverParams
      }
      axios
        .post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}triggertelemetry`, postObj)
        .then(res => {
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
        })
        .catch(e => {
          this.$snapbar.e(`Error retrieving workflow log data from Paygate. - ${e.message}`)
        })
    },
    viewTimeline (p) {
      this.$router.push(`/automation/workflow/timeline/${p.formattedRow.executionId}`)
    },
    viewTelemetry (p) {
      this.$router.push(`/automation/workflow/workflowtelemetry/${p.formattedRow.executionId}`)
    },
    viewWorkflow (p) {
      if (p.formattedRow.type === 'Workflow') {
        this.$router.push(`/automation/workflow/designer/${p.formattedRow.workflowId}`)
      }
      if (p.formattedRow.type === 'Mapping') {
        this.$router.push(`/automation/mapping/designer/${p.formattedRow.workflowId}`)
      }
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    getData () {
      axios
        .get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}triggertelemetry/${this.id}`)
        .then(res => {
          this.totalRecords = res.data.resObj.count
          this.row_data = res.data.workflowInstance
        })
        .catch(e => {
          this.$snapbar.e(`Error retrieving workflow log data from Paygate. - ${e.message}`)
        })
    },
    onCellClick (params) {
      if (params.column.field !== 'workflowId' && params.column.field !== 'title') {
        this.$router.push(`/automation/workflow/workflowtelemetry/${params.row.executionId}`)
      }
    }
  },
  created: function () {
    this.loadItems()
  }
}
</script>

<style scoped>
  td {
    cursor: pointer;
  }
</style>
