<template>
  <div class="datetimepicker-inline" v-if="config.inline"></div>
  <input type="text" class="form-control" v-else />
</template>

<script>
import jQuery from 'jquery'
import moment from 'moment'

import 'pc-bootstrap4-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
// You have to import css yourself

import events from './events'

export default {
  name: 'date-picker',
  props: {
    value: {
      default: null,
      required: true,
      validator (value) {
        return value === null || value instanceof moment || value instanceof Date
      }
    },
    // http://eonasdan.github.io/bootstrap-datetimepicker/Options/
    config: {
      type: Object,
      default: () => ({})
    },
    /**
       * You can set this to true when component is wrapped in input-group
       * Note: inline and wrap mode wont work together
       */
    wrap: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dp: null,
      // jQuery DOM
      elem: null
    }
  },
  mounted () {
    // Return early if date-picker is already loaded
    /* istanbul ignore if */
    if (this.dp) return
    // Handle wrapped input
    this.elem = jQuery(this.wrap ? this.$el.parentNode : this.$el)
    // Init date-picker
    this.elem.datetimepicker(this.config)
    // Store data control
    this.dp = this.elem.data('DateTimePicker')
    // Set initial value
    this.dp.date(this.value)
    // Watch for changes
    this.elem.on('dp.change', this.onChange)
    // Register remaining events
    this.registerEvents()

    jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
      icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check',
        clear: 'far fa-trash-alt',
        close: 'far fa-times-circle'
      }
    })
  },
  watch: {
    /**
       * Listen to change from outside of component and update DOM
       *
       * @param newValue
       */
    value (newValue) {
      this.dp && this.dp.date(moment(newValue) || null)
    },

    /**
       * Watch for any change in options and set them
       *
       * @param newConfig Object
       */
    config: {
      deep: true,
      handler (newConfig) {
        this.dp && this.dp.options(newConfig)
      }
    }
  },
  methods: {
    /**
       * Update v-model upon change triggered by date-picker itself
       *
       * @param event
       */
    onChange (event) {
      const formattedDate = event.date ? event.date : null
      this.$emit('input', formattedDate)
    },

    /**
       * Emit all available events
       */
    registerEvents () {
      events.forEach((name) => {
        this.elem.on(`dp.${name}`, (...args) => {
          this.$emit(`dp-${name}`, ...args)
        })
      })
    }
  },
  /**
     * Free up memory
     */
  beforeDestroy () {
    /* istanbul ignore else */
    if (this.dp) {
      this.dp.destroy()
      this.dp = null
      this.elem = null
    }
  }
}
</script>
