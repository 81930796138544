var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(0),
      _c("div", { staticClass: "idb-block-content" }, [
        _vm._m(1),
        _c("div", { staticClass: "form-group row" }, [
          _vm._m(2),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "ul",
              _vm._l(_vm.devTeam, function(member) {
                return _c("li", { key: member.index }, [
                  _vm._v(" " + _vm._s(member) + " ")
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _vm._m(3),
        _c("div", { staticClass: "form-group row" }, [
          _vm._m(4),
          _c("div", { staticClass: "col-md-7" }, [
            _c(
              "ul",
              _vm._l(_vm.qaTeam, function(member) {
                return _c("li", { key: member.index }, [
                  _vm._v(" " + _vm._s(member) + " ")
                ])
              }),
              0
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Team")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("label", { attrs: { for: "description" } }, [
        _vm._v("The Paygate Development Team are: ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-5" }, [
      _c("img", {
        attrs: { src: "/img/sharedimages/team/ce9a5355.png", width: "400px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("label", { attrs: { for: "description" } }, [
        _vm._v("The Paygate Test Team are: ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-5" }, [
      _c("img", {
        attrs: { src: "/img/sharedimages/team/qa.png", width: "400px" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }