<template>
    <div id="myId">
        <div class="row mb-4">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="idb-block">
                    <div class="idb-block-title">
                        <h2>Sweet Alert</h2>
                    </div>
                    <div class="idb-block-content">
                      <button class="btn btn-danger" @click="alertDanger">Danger Alert</button>
                      <button class="btn btn-warning" @click="alertWarning">Warning Alert</button>
                      <button class="btn btn-success" @click="alertSuccess">Success Alert</button>
                      <button class="btn btn-primary" @click="alertOther">Other Alert</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    alertDanger () {
      this.$swal('Wow.', 'Something bad happened', 'error')
    },
    alertWarning () {
      this.$swal('Warning.', 'Something sort of not ok happened', 'warning')
    },
    alertSuccess () {
      this.$swal('Good.', 'Something ok happened', 'success')
    },
    alertOther () {
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
    }
  }
}

</script>

<style>

</style>
