var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "BureauUsageReports" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Bureau Usage Report over date range "),
                _vm._m(0),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _vm._v(" From: "),
                  _c("vuejsDatepicker", {
                    ref: "dateFrom",
                    attrs: {
                      name: "dateFrom",
                      id: "dateFrom",
                      format: "dd/MM/yyyy",
                      "input-class": "form-control",
                      "bootstrap-styling": true,
                      "monday-first": true,
                      disabled: _vm.disableDatePicker
                    },
                    on: { selected: _vm.fromDateSet },
                    nativeOn: {
                      focusin: function($event) {
                        return _vm.dateOnfocus($event)
                      }
                    },
                    model: {
                      value: _vm.dateFrom,
                      callback: function($$v) {
                        _vm.dateFrom = $$v
                      },
                      expression: "dateFrom"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _vm._v(" To: "),
                  _c("vuejsDatepicker", {
                    ref: "dateTo",
                    attrs: {
                      name: "dateTo",
                      id: "dateTo",
                      format: "dd/MM/yyyy",
                      "input-class": "form-control",
                      "bootstrap-styling": true,
                      "monday-first": true,
                      disabled: _vm.disableDatePicker
                    },
                    on: { selected: _vm.toDateSet },
                    nativeOn: {
                      focusin: function($event) {
                        return _vm.dateOnfocus($event)
                      }
                    },
                    model: {
                      value: _vm.dateTo,
                      callback: function($$v) {
                        _vm.dateTo = $$v
                      },
                      expression: "dateTo"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c(
                    "p-check",
                    {
                      staticClass: "p-default p-curve",
                      attrs: { id: "allDates" },
                      on: {
                        change: function($event) {
                          return _vm.allDatesSet()
                        }
                      },
                      model: {
                        value: _vm.allDates,
                        callback: function($$v) {
                          _vm.allDates = $$v
                        },
                        expression: "allDates"
                      }
                    },
                    [_vm._v("For all time")]
                  )
                ],
                1
              )
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                on: { click: _vm.loadData }
              },
              [_vm._v("Load")]
            )
          ]),
          _c("hr"),
          _vm.showResults
            ? _c(
                "div",
                { staticClass: "idb-block-content", attrs: { id: "results" } },
                [
                  _c("div", { staticClass: "row form-group col-md-12" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("h3", [
                        _vm._v(
                          "Data for between " +
                            _vm._s(_vm.usage.lowestDateStr) +
                            " - " +
                            _vm._s(_vm.usage.highestDateStr)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h4", [_vm._v("Overall Submission Data")]),
                      _c("span", [
                        _c("b", [_vm._v("Total Submissions:")]),
                        _vm._v(" " + _vm._s(_vm.usage.totalSubmissions) + " ")
                      ]),
                      _c("br"),
                      _c("b", [_vm._v("Total Files Submitted:")]),
                      _vm._v(" " + _vm._s(_vm.usage.totalFilesSubmitted) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Total Number of Payments:")]),
                      _vm._v(" " + _vm._s(_vm.usage.totalPayments) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Total Individual SUNS:")]),
                      _vm._v(" " + _vm._s(_vm.usage.totalSUNS) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Total BACS Submissions:")]),
                      _vm._v(" " + _vm._s(_vm.usage.paymentNetworkBACS) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Total FPS Submissions:")]),
                      _vm._v(" " + _vm._s(_vm.usage.paymentNetworkFPS) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Total Automated Submissions:")]),
                      _vm._v(
                        " " + _vm._s(_vm.usage.automatedSubmissions) + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Total Manual Submissions:")]),
                      _vm._v(" " + _vm._s(_vm.usage.manualSubmissions) + " "),
                      _c("br")
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h4", [_vm._v("Average Monthly Submission Data")]),
                      _c("b", [_vm._v("Average Submissions:")]),
                      _vm._v(
                        " " + _vm._s(_vm.usage.averageMonthlySubmissions) + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Average Number of Files:")]),
                      _vm._v(" " + _vm._s(_vm.usage.averageMonthlyFiles) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Average Number of Payments:")]),
                      _vm._v(
                        " " + _vm._s(_vm.usage.averageMonthlyPayments) + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Average Individual SUNS:")]),
                      _vm._v(" " + _vm._s(_vm.usage.averageMonthlySUNS) + " "),
                      _c("br")
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h4", [_vm._v("Average Weekly Submission Data")]),
                      _c("b", [_vm._v("Average Submissions:")]),
                      _vm._v(
                        " " + _vm._s(_vm.usage.averageWeeklySubmissions) + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Average Number of Files:")]),
                      _vm._v(" " + _vm._s(_vm.usage.averageWeeklyFiles) + " "),
                      _c("br"),
                      _c("b", [_vm._v("Average Number of Payments:")]),
                      _vm._v(
                        " " + _vm._s(_vm.usage.averageWeeklyPayments) + " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Average Individual SUNS:")]),
                      _vm._v(" " + _vm._s(_vm.usage.averageWeeklySUNS) + " ")
                    ])
                  ]),
                  _c("hr"),
                  _c("div", [
                    _c("div", { staticClass: "row form-group col-md-12" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("h3", [_vm._v("SUN Usage")]),
                          _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.chartData,
                              options: _vm.chartOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }