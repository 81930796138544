<template>
  <div>
    <object id="PayGatePlugin" classid="clsid:8C1E006B-FDF1-4AEF-9B8A-83D8004C2E8B" hidden="hidden"></object>

    <b-modal v-model="showModal" @hide="hide" centered size="lg" no-close-on-backdrop static>
      <div slot="modal-header">
        <h3 class="modal-title">{{ title }}</h3>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-warning">
            <strong>{{ text }}</strong>
          </div>
        </div>
      </div>
      <br />
      <div v-show="browser !== 'IE'" class="row form-group">
        <div class="col-md-9">{{ webExtentionText }}</div>
        <div v-if="webExtensionRequired" class="col-md-3">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="webExtensionClicked"
            @click="getWebExtension"
          >Get Extension</button>
        </div>
        <div v-if="!webExtensionRequired && !installCancelled" class="col-md-3">
          <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
        </div>
      </div>
      <br />
      <div class="row form-group">
        <div class="col-md-9" v-html="loaderPluginText"></div>
        <div v-if="pluginRequired || pluginUpdateRequired" class="col-md-3">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="this.disableDownloadMsi"
            @click="downloadPayGatePluginMsi"
          >Download Plugin</button>
        </div>
        <div v-if="!pluginRequired && !pluginUpdateRequired && !installCancelled" class="col-md-3">
          <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
        </div>
      </div>
      <div v-if="getPluginClicked" class="row form-group">
        <div class="col-md-9">
          <div class="alert alert-warning">
            <strong>{{ runInstallerText }}</strong>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="w-100">
        <b-row>
          <b-col cols="2">
            <b-btn
              type="button"
              class="btn btn-success"
              v-if="showContinue"
              @click="continueMethod"
              >Continue
            </b-btn>
          </b-col>
          <b-col cols="10">
            <b-btn
              type="button"
              class="btn btn-danger pull-right"
               @click="cancel"
              >Cancel
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'plugin',
  computed: {
    browser () {
      return this.$store.state.plugin.browser
    },
    deviceType () { return this.$store.state.plugin.deviceType },
    webExtensionRequired () { return this.$store.state.plugin.webExtensionRequired },
    pluginRequired () { return this.$store.state.plugin.pluginRequired },
    pluginUpdateRequired () { return this.$store.state.plugin.pluginUpdateRequired },
    eSignerLimitExceeded () { return this.$store.state.plugin.eSignerLimitExceeded },
    installCancelled () { return this.$store.state.plugin.installCancelled },
    showModal: {
      get () {
        return this.$store.getters.getShowSetupModal
      },
      set (value) {
        if (!value) {
          this.$store.commit('setWebExtensionRequired', false)
          this.$store.commit('setPluginRequired', false)
          this.$store.commit('setPluginUpdateRequired', false)
          setTimeout(() => {
            this.$store.commit('setInstallCancelled', false)
          }, 250)
        }
      }
    },
    title () {
      if (this.browser === 'IE') {
        return 'Download paygate plugin'
      }
      return 'Download Web Extension and paygate plugin'
    },
    text () {
      if (this.browser === 'IE') {
        return `The paygate plugin is required in order to use ${this.devicePronoun} ${this.deviceType}.`
      }
      return `A ${this.browser} web extension and the paygate plugin are required in order to use ${this.devicePronoun} ${this.deviceType}.`
    },
    webExtentionText () {
      if (!this.webExtensionRequired) {
        return `${this.browser} web extension installed`
      } else {
        return `Click "Get Extension" to add the ${this.browser} paygate web extension to your browser`
      }
    },
    webExtentionUrl () {
      switch (this.browser) {
        case 'Chrome':
          return `https://chrome.google.com/webstore/detail/paygate-extension-for-chr/${process.env.VUE_APP_CHROME_EXT_ID}`
        case 'Firefox':
          return 'https://addons.mozilla.org/en-US/firefox/addon/paygateplugin-extension-for-ff/'
        default:
          return ''
      }
    },
    disableDownloadMsi () {
      return this.webExtensionRequired || this.getPluginClicked
    },
    loaderPluginText () {
      if (this.pluginUpdateRequired) {
        return `Click here to download the updated paygate plugin which must be installed to access ${this.devicePronoun} ${this.deviceType}`
      } else if (this.eSignerLimitExceeded) {
        return 'This submission is too big to be signed using eSigner. Click here to download the paygate plugin to sign larger files with your SmartCard'
      }

      return `Click here to download the paygate plugin which must be installed to access ${this.devicePronoun} ${this.deviceType}.<br />`
    },
    runInstallerText () {
      let downloadText = 'Run the plugin installer when you have downloaded it to your PC. '
      if (this.browser === 'IE') {
        // downloadText += 'Please close Internet Explorer before installing the new plugin to avoid needing to reboot your PC.'
        downloadText += `Once the paygate plugin has been installed, click the "Continue" button or refresh the browser to access ${this.devicePronoun} ${this.deviceType}. `
        downloadText += 'If you are upgrading to a newer version of the plugin please close Internet Explorer before installing the new plugin to avoid needing to reboot your PC.'
      } else {
        downloadText += `Once the paygate plugin has been installed, paygate will automatically close this wizard and allow access to ${this.devicePronoun} ${this.deviceType}.`
      }
      return downloadText
    },
    devicePronoun () {
      return this.deviceType === 'Restricted File Browser' ? 'the' : 'your'
    }
  },
  data: () => ({
    modalTitle: '',
    modalText: '',
    webExtensionClicked: false,
    webExtIntervalId: null,
    getPluginClicked: false,
    pluginIntervalId: null,
    showContinue: false
  }),
  methods: {
    hide () {
      if (this.webExtIntervalId !== '') {
        window.clearInterval(this.webExtIntervalId)
      }
      if (this.pluginIntervalId !== '') {
        window.clearInterval(this.pluginIntervalId)
      }
      this.showModal = false
    },

    async getWebExtension () {
      this.webExtensionClicked = true
      window.open(this.webExtentionUrl, '_blank')

      // Poll to check whether the user has installed the web extension while showing this modal.
      this.webExtIntervalId = setInterval(async () => {
        if (this.browser === 'Chrome') {
          await this.$store.dispatch('checkChromeExtensionLoaded')
        } else if (this.browser === 'Firefox') {
          await this.$store.dispatch('checkFirefoxExtensionLoaded')
        }

        if (!this.webExtensionRequired) {
          window.clearInterval(this.webExtIntervalId)
          this.webExtensionClicked = false
          if (this.browser === 'Chrome') {
            await this.$store.dispatch('checkChromePluginInstalled')
          } else if (this.browser === 'Firefox') {
            await this.$store.dispatch('checkFireFoxPluginInstalled')
          }
        }
      }, 5000)
    },

    async downloadPayGatePluginMsi () {
      this.getPluginClicked = true
      var response = await axios.get(
        `${process.env.VUE_APP_BACS_API_URL}bacs/submission/downloadPlugin?browser=${this.browser}`,
        {
          responseType: 'blob'
        })

      const blob = new Blob([response.data])
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = ''
      const disposition = response.headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }

      if (this.browser === 'Chrome') {
        // Poll to see whether user has installed the plugin.
        this.pluginIntervalId = window.setInterval(async () => {
          await this.$store.dispatch('checkChromePluginInstalled')
        }, 5000)
      } else if (this.browser === 'Firefox') {
        // Poll to see whether user has installed the plugin.
        this.pluginIntervalId = window.setInterval(async () => {
          await this.$store.dispatch('checkFireFoxPluginInstalled')
        }, 5000)
      } else if (this.browser === 'IE') {
        this.showContinue = true
      }
    },

    cancel () {
      if (this.webExtensionRequired === true) {
        this.$toastr.w('Web Extension has not been installed so the ' + this.deviceType + ' cannot be used')
        this.$store.commit('setInstallCancelled', true)
        this.$emit('installCancelled')
      } else if (this.pluginRequired === true) {
        this.$toastr.w('paygate plugin has not been installed so the ' + this.deviceType + ' cannot be used')
        this.$store.commit('setInstallCancelled', true)
        this.$emit('installCancelled')
      } else if (this.pluginUpdateRequired === true) {
        this.$toastr.w('Updated paygate plugin has not been installed so the ' + this.deviceType + ' cannot be used')
        this.$store.commit('setInstallCancelled', true)
        this.$emit('installCancelled')
      } else {
        this.$toastr.s(`You can now use ${this.deviceType}`, 'Success')
      }
      this.showModal = false
    },
    continueMethod () {
      location.reload()
    }
  }
}
</script>
