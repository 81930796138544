export function getTheme (selectedTheme) {
  var themeConfig = {}
  if (selectedTheme === 'dark') {
    themeConfig.connectorColour = '#cccccc'
    themeConfig.connectorMouseOverColour = '#444444'
    themeConfig.bodyLabelColour = '#bbbbbb'
    themeConfig.headerLabelColour = '#cccccc'
    themeConfig.connectorPointDefaultColour = '#000000'
    themeConfig.inputConnectorColour = '#33dd33'
    themeConfig.outputConnectorColour = '#0000ff'
    themeConfig.notAllowedConnectorColour = '#BC0016'
    themeConfig.nodeColour = '#555555'
    themeConfig.nodeHoverColour = '#666666'
    themeConfig.selectedNodeColour = '#777777'
    themeConfig.nodeFooterBackColour = '#444444'
    themeConfig.nodeFooterForeColour = '#777777'
    themeConfig.nodeFooterForeHoverColour = '#eeeeee'
    themeConfig.canvasColour = '#2C2C2C'
    themeConfig.minorGridColour = '#353535'
    themeConfig.majorGridColour = '#353535'
    themeConfig.logoColour = '#ffffff'
    themeConfig.gridOpacity = 1
    themeConfig.selectedStroke = '#1a1a1a'
    themeConfig.shadowColour = '#000000'
    themeConfig.connectorLineColour = '#666666'
    themeConfig.selectedConnectorLineColour = '#cccccc'
    themeConfig.popupHeaderBackColour = '#333333'
    themeConfig.popupHeaderForeColour = '#ffffff'
    themeConfig.popupBackColour = '#333333'
    themeConfig.popupForeColour = '#eeeeee'
    themeConfig.popupForeValueColour = '#cccccc'
    themeConfig.popupShadowColour = '#000000'
  }
  if (selectedTheme === 'light') {
    themeConfig.connectorColour = '#ff0000'
    themeConfig.connectorMouseOverColour = '#333333'
    themeConfig.bodyLabelColour = '#333333'
    themeConfig.headerLabelColour = '#ffffff'
    themeConfig.connectorPointDefaultColour = '#777777'
    themeConfig.inputConnectorColour = '#33dd33'
    themeConfig.outputConnectorColour = '#0000ff'
    themeConfig.notAllowedConnectorColour = '#BC0016'
    themeConfig.nodeColour = '#ffffff'
    themeConfig.nodeHoverColour = '#bbbbbb'
    themeConfig.selectedNodeColour = '#777777'
    themeConfig.nodeFooterBackColour = '#888888'
    themeConfig.nodeFooterForeColour = '#cccccc'
    themeConfig.nodeFooterForeHoverColour = '#eeeeee'
    themeConfig.canvasColour = '#F2F0F0'
    themeConfig.minorGridColour = '#E8E6E6'
    themeConfig.majorGridColour = '#dddddd'
    themeConfig.logoColour = '#999999'
    themeConfig.gridOpacity = 1
    themeConfig.selectedStroke = '#999999'
    themeConfig.shadowColour = '#555555'
    themeConfig.connectorLineColour = '#bbbbbb'
    themeConfig.selectedConnectorLineColour = '#666666'
    themeConfig.popupHeaderBackColour = '#333333'
    themeConfig.popupHeaderForeColour = '#ffffff'
    themeConfig.popupBackColour = '#ffffff'
    themeConfig.popupForeColour = '#333333'
    themeConfig.popupForeValueColour = '#666666'
    themeConfig.popupShadowColour = '#777777'
  }
  return themeConfig
}
