var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: {
                docPath: "/paygate-collections/messaging/assigntemplates/"
              }
            }),
            _vm._v("Assign Group Templates"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-2 required" }, [
            _vm._v(" Select a Group ")
          ]),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("group-select", {
                attrs: { groups: _vm.customerGroups, clearable: false },
                on: { input: _vm.groupChange },
                model: {
                  value: _vm.assignTemplatesGroup,
                  callback: function($$v) {
                    _vm.assignTemplatesGroup = $$v
                  },
                  expression: "assignTemplatesGroup"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "idb-block-footer" },
        [
          _c(
            "b-dropdown",
            { attrs: { variant: "primary" } },
            [
              _c(
                "span",
                { attrs: { slot: "button-content" }, slot: "button-content" },
                [
                  _c("i", { staticClass: "fa fa-plus rpad" }),
                  _vm._v("Create Template")
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.goToTemplate("LetterDesigner")
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-envelope rpad",
                    attrs: { title: "Letter" }
                  }),
                  _vm._v("Letter")
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.goToTemplate("EmailDesigner")
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-at rpad",
                    attrs: { title: "Email" }
                  }),
                  _vm._v("Email")
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.goToTemplate("SmsDesigner")
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-mobile rpad",
                    attrs: { title: "SMS" }
                  }),
                  _vm._v("SMS")
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm.loaded && !_vm.disableInteraction
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("assignment", {
                        attrs: {
                          disabled: _vm.disableInteraction,
                          title: "First Payment",
                          subtitle:
                            "Sent to the payer prior to the first payment of a plan.",
                          "template-options": _vm.templates,
                          "message-type": 1
                        },
                        model: {
                          value: _vm.messageTemplates.firstPayment,
                          callback: function($$v) {
                            _vm.$set(_vm.messageTemplates, "firstPayment", $$v)
                          },
                          expression: "messageTemplates.firstPayment"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("assignment", {
                        attrs: {
                          disabled: _vm.disableInteraction,
                          title: "Advance Notice",
                          subtitle:
                            "Sent to notify the payer of a change to a single upcoming payment.",
                          "template-options": _vm.templates,
                          "message-type": 0
                        },
                        model: {
                          value: _vm.messageTemplates.advanceNotice,
                          callback: function($$v) {
                            _vm.$set(_vm.messageTemplates, "advanceNotice", $$v)
                          },
                          expression: "messageTemplates.advanceNotice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("assignment", {
                        attrs: {
                          disabled: _vm.disableInteraction,
                          title: "Multiple Schedule Update",
                          subtitle:
                            "Sent to notify the payer of changes to multiple future payments.",
                          "template-options": _vm.templates,
                          "message-type": 2
                        },
                        model: {
                          value: _vm.messageTemplates.multipleScheduleUpdate,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.messageTemplates,
                              "multipleScheduleUpdate",
                              $$v
                            )
                          },
                          expression: "messageTemplates.multipleScheduleUpdate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("assignment", {
                        attrs: {
                          disabled: _vm.disableInteraction,
                          title: "Cancellation Notice",
                          "template-options": _vm.templates,
                          "message-type": 12
                        },
                        model: {
                          value: _vm.messageTemplates.cancellationNotice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.messageTemplates,
                              "cancellationNotice",
                              $$v
                            )
                          },
                          expression: "messageTemplates.cancellationNotice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "idb-block-footer" },
            [
              _c(
                "b-btn",
                {
                  attrs: { variant: "primary", disabled: _vm.isLoading },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.saveChanges($event)
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("assignment", {
                    attrs: {
                      disabled: _vm.disableInteraction,
                      title: "Email Verification",
                      "template-options": _vm.templates,
                      "message-type": 8
                    },
                    model: {
                      value: _vm.messageTemplates.emailVerification,
                      callback: function($$v) {
                        _vm.$set(_vm.messageTemplates, "emailVerification", $$v)
                      },
                      expression: "messageTemplates.emailVerification"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("assignment", {
                    attrs: {
                      disabled: _vm.disableInteraction,
                      title: "Payer Portal Message Notification",
                      "template-options": _vm.templates,
                      "message-type": 9
                    },
                    model: {
                      value: _vm.messageTemplates.portalMessageSent,
                      callback: function($$v) {
                        _vm.$set(_vm.messageTemplates, "portalMessageSent", $$v)
                      },
                      expression: "messageTemplates.portalMessageSent"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("assignment", {
                    attrs: {
                      disabled: _vm.disableInteraction,
                      title: "Refund Requested",
                      "template-options": _vm.templates,
                      "message-type": 10
                    },
                    model: {
                      value: _vm.messageTemplates.refundRequested,
                      callback: function($$v) {
                        _vm.$set(_vm.messageTemplates, "refundRequested", $$v)
                      },
                      expression: "messageTemplates.refundRequested"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.disableInteraction ? _c("div", [_c("Loading")], 1) : _vm._e(),
      _c(
        "div",
        { staticClass: "idb-block-footer" },
        [
          _c(
            "b-btn",
            {
              attrs: { variant: "primary", disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.saveChanges($event)
                }
              }
            },
            [_vm._v("Save")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(2),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.addCustomType }
                    },
                    [_c("i", { staticClass: "fa fa-plus mr-2" }), _vm._v("Add")]
                  )
                ],
                1
              ),
              _c("b-col", [
                _vm._v(
                  "Adds another message type that can be used for this group."
                )
              ])
            ],
            1
          ),
          _vm._l(_vm.customTemplates, function(temp, i) {
            return _c(
              "b-row",
              { key: i },
              [
                _c(
                  "b-col",
                  [
                    _c("assignment", {
                      attrs: {
                        title: temp.title,
                        "template-options": _vm.templates,
                        custom: true,
                        disabled: _vm.disableInteraction
                      },
                      on: { deleteClicked: _vm.deleteMessageType },
                      model: {
                        value: _vm.customTemplates[i],
                        callback: function($$v) {
                          _vm.$set(_vm.customTemplates, i, $$v)
                        },
                        expression: "customTemplates[i]"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "idb-block-footer" },
        [
          _c(
            "b-btn",
            {
              attrs: { variant: "primary", disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.saveCustomTemplates($event)
                }
              }
            },
            [_vm._v("Save Custom Messaging")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Required Messages")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Advanced")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Custom Messages")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }