var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(
                    " Billing Report For " + _vm._s(_vm.customerName) + " "
                  ),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-md-4" },
                  [
                    _c(
                      "label",
                      { staticClass: "required", attrs: { for: "from" } },
                      [_vm._v("From")]
                    ),
                    _c("vuejsDatepicker", {
                      attrs: {
                        name: "from",
                        id: "from",
                        format: "MMM/yyyy",
                        "input-class": "form-control bg-white",
                        "bootstrap-styling": true,
                        minimumView: "month",
                        maximumView: "year",
                        "initial-view": "month",
                        "use-utc": true,
                        "disabled-dates": {
                          from: new Date()
                        },
                        "calendar-button": true,
                        "calendar-button-icon": "fa fa-calendar"
                      },
                      model: {
                        value: _vm.month,
                        callback: function($$v) {
                          _vm.month = $$v
                        },
                        expression: "month"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: _vm.isLoading },
                  on: {
                    click: function($event) {
                      return _vm.load()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "glyphicon ti-pie-chart rpad" }),
                  _vm._v("Generate Billing Report ")
                ]
              ),
              _c("div", { staticClass: "ml-3 btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success dropdown-toggle",
                    attrs: {
                      type: "button",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                      disabled: _vm.isLoading || !_vm.dataLoaded
                    }
                  },
                  [_vm._v("Export as")]
                ),
                _c("div", { staticClass: "dropdown-menu" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: { click: _vm.saveAsJson }
                    },
                    [_vm._v("JSON")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: { click: _vm.saveAsCsv }
                    },
                    [_vm._v("CSV")]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm.id === null
        ? _c("billing-report-all-customer-stats", {
            attrs: { report: _vm.report, dataLoaded: _vm.dataLoaded }
          })
        : _vm._e(),
      _vm.id !== null
        ? _c("billing-report-single-customer-stats", {
            attrs: {
              report: _vm.report,
              dataLoaded: _vm.dataLoaded,
              month: _vm.month,
              paygateId: _vm.id
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }