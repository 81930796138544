var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "thead",
    [
      _c(
        "tr",
        [
          _vm.lineNumbers
            ? _c(
                "th",
                { staticClass: "line-numbers", attrs: { scope: "col" } },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Line Number")
                  ])
                ]
              )
            : _vm._e(),
          _vm.selectable
            ? _c(
                "th",
                { staticClass: "vgt-checkbox-col", attrs: { scope: "col" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: _vm.id + "_selectall" }
                    },
                    [_vm._v("Select All")]
                  ),
                  _c("input", {
                    attrs: { id: _vm.id + "_selectall", type: "checkbox" },
                    domProps: {
                      checked: _vm.allSelected,
                      indeterminate: _vm.allSelectedIndeterminate
                    },
                    on: { change: _vm.toggleSelectAll }
                  })
                ]
              )
            : _vm._e(),
          _vm._l(_vm.columns, function(column, index) {
            return !column.hidden
              ? _c(
                  "th",
                  {
                    key: index,
                    class: _vm.getHeaderClasses(column, index),
                    style: _vm.columnStyles[index],
                    attrs: {
                      scope: "col",
                      title: column.tooltip,
                      "aria-sort": _vm.getColumnSortLong(column),
                      "aria-controls": "col-" + index
                    }
                  },
                  [
                    _vm._t(
                      "table-column",
                      [_vm._v(" " + _vm._s(column.label) + " ")],
                      { column: column }
                    ),
                    _vm.isSortableColumn(column)
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sort($event, column)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(
                                " Sort table by " +
                                  _vm._s(column.label) +
                                  " in " +
                                  _vm._s(_vm.getColumnSortLong(column)) +
                                  " order "
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          })
        ],
        2
      ),
      _c("vgt-filter-row", {
        ref: "filter-row",
        tag: "tr",
        attrs: {
          "global-search-enabled": _vm.searchEnabled,
          "line-numbers": _vm.lineNumbers,
          selectable: _vm.selectable,
          columns: _vm.columns,
          mode: _vm.mode,
          "typed-columns": _vm.typedColumns
        },
        on: { "filter-changed": _vm.filterRows },
        scopedSlots: _vm._u(
          [
            {
              key: "column-filter",
              fn: function(props) {
                return [
                  _vm._t("column-filter", null, {
                    column: props.column,
                    updateFilters: props.updateFilters
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }