var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [
      _vm._v("Delay"),
      _c("span", { staticClass: "pull-right" }, [
        _c(
          "a",
          {
            attrs: {
              href:
                _vm.docUrl +
                "/automation/paygate-workflow/workflow-nodes/flow/delay/",
              target: "_blank"
            }
          },
          [_c("i", { staticClass: "far fa-question-circle" })]
        )
      ])
    ]),
    _c("hr"),
    _c("br"),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "required" }, [
        _vm._v("Delay Time (seconds)")
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedNode.props.int1.value,
            expression: "selectedNode.props.int1.value"
          }
        ],
        staticClass: "form-control",
        class: { invalid: _vm.$v.selectedNode.props.int1.value.$error },
        attrs: { type: "number" },
        domProps: { value: _vm.selectedNode.props.int1.value },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.selectedNode.props.int1,
                "value",
                $event.target.value
              )
            },
            function($event) {
              return _vm.$v.selectedNode.props.int1.value.$touch()
            }
          ]
        }
      }),
      !_vm.$v.selectedNode.props.int1.value.required &&
      _vm.$v.selectedNode.props.int1.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The delay time field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.int1.value.minValue
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Minimum value is " +
                _vm._s(
                  _vm.$v.selectedNode.props.int1.value.$params.minValue.min
                ) +
                " seconds!"
            )
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.int1.value.maxValue
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum value is " +
                _vm._s(
                  _vm.$v.selectedNode.props.int1.value.$params.maxValue.max
                ) +
                " seconds!"
            )
          ])
        : _vm._e()
    ]),
    _c("br"),
    _c("hr"),
    _c(
      "div",
      { staticClass: "pull-right" },
      [
        _c(
          "b-button",
          {
            staticClass: "btnPad",
            attrs: { variant: "danger" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnCancel($event)
              }
            }
          },
          [_vm._v(" Cancel ")]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "success" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnSave($event)
              }
            }
          },
          [_vm._v(" OK ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }