<template>
  <div>
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Look Ahead Report {{ (this.cumulative ? '(Cumulative)' : '') }}</h4>
    </div>
    <div style="position:relative; height: 350px !important">
      <look-ahead-chart
        :chart-data="chartData"
        :options="chartOptions"
        v-if="loaded"
        :styles="{ height: '100%' }"
      ></look-ahead-chart>
      <div v-if="!loaded">Loading...</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import LookAheadChart from '@/Assets/Components/Charts/CollectionsLookAhead'
import Loading from '@/Assets/Components/Loading/Loading'

export default {
  name: 'LookAheadReportWidget',
  components: {
    LookAheadChart,
    Loading
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      chartData: [],
      chartOptions: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItems, data) {
              return '£' + tooltipItems.yLabel
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 150
      }
    }
  },
  methods: {
    buildData (data, label) {
      var datasets = []
      var labels = []
      for (var i = 0; i < data.length; i++) {
        let current = data[i]
        labels.push(this.formatDatePointString(current.datePointString))
        datasets.push(this.cumulative ? current.cumulativeAmount : current.unitAmount)
      }
      return {
        labels: labels,
        datasets: [
          {
            label: label,
            backgroundColor: '#f87979',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: datasets
          }]
      }
    },
    groupChanged (e) {
      this.populate()
    },
    populate () {
      axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/lookahead/${this.group}/${this.window}`, { params: { paygateid: this.paygateId }})
        .then((response) => {
          this.loaded = true
          var data = response.data.data
          this.chartData = this.buildData(data, response.data.groupName)
        })
    },
    formatDatePointString (input) {
      let dateSplit = input.split('/')
      let year = dateSplit[0]
      let month = dateSplit[1]
      switch (month) {
        case '01':
          month = 'Jan'
          break
        case '02':
          month = 'Feb'
          break
        case '03':
          month = 'Mar'
          break
        case '04':
          month = 'Apr'
          break
        case '05':
          month = 'May'
          break
        case '06':
          month = 'Jun'
          break
        case '07':
          month = 'Jul'
          break
        case '08':
          month = 'Aug'
          break
        case '09':
          month = 'Sep'
          break
        case '10':
          month = 'Oct'
          break
        case '11':
          month = 'Nov'
          break
        case '12':
          month = 'Dec'
          break
      }
      return `${month} ${year}`
    }
  },
  mounted () {
    this.populate()
  },
  computed: {
    window () {
      return this.widget.options.find(o => o.label === 'Window').value
    },
    group () {
      return this.widget.options.find(o => o.label === 'GroupId').value
    },
    cumulative () {
      return this.widget.options.find(o => o.label === 'Cumulative').value
    },
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  watch: {
    group () {
      this.populate()
    },
    cumulative () {
      this.populate()
    }
  }
}
</script>
<style>
</style>
