<template>
  <div>
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Bureau Submission Report
            <help-icon :docPath="'/bacsbureau/submissions/'"></help-icon></h2>
          </div>
          <div class="idb-block-content">
            <div class="mt-3">
              <b-row>
                <b-col>

                  <b-row>
                    <b-col>
                      <strong>Group Name</strong>
                    </b-col>
                    <b-col>{{ this.submission.groupName }}</b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <strong>Payment Date</strong>
                    </b-col>
                    <b-col>{{ formatDate(this.submission.paymentDate) }}</b-col>
                  </b-row>
                  <b-row v-if="false" class="mt-1">
                    <b-col>
                      <strong>Contra Narrative</strong>
                    </b-col>
                    <b-col>{{ this.submission.contraNarrative }}</b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <strong>Bureau SUN</strong>
                    </b-col>
                    <b-col>{{ this.submission.groupServiceUserNumber }}</b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <strong>Number of Contras</strong>
                    </b-col>
                    <b-col>{{ this.contraCount }}</b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <strong>Submission Reference</strong>
                    </b-col>
                    <b-col>{{ this.submission.submissionReference }}</b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <strong>Processing Date</strong>
                    </b-col>
                    <b-col>{{ formatDate(this.submission.processingDate) }}</b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <strong>Bureau Job Name</strong>
                    </b-col>
                    <b-col>{{ this.submission.bureauJobName }}</b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <strong>Service</strong>
                    </b-col>
                    <b-col>{{ this.submission.paymentNetworkType === 0 ? 'BACS'  : 'FPS' }}</b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <strong>Date Generated</strong>
                    </b-col>
                    <b-col>{{ this.dateGeneratedStr}} </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <table class="table table-striped payment-details-summary">
                    <thead>
                    <tr>
                      <th><span class="sr-only">Transaction type</span></th>
                      <th scope="col" class="text-right">
                        <strong>Items</strong>
                      </th>
                      <th scope="col" class="text-right">
                        <strong>Total (£)</strong>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <strong>Credits</strong>
                      </td>
                      <td class="text-right">{{ this.totalCreditCount | number }}</td>
                      <td class="text-right">{{ this.totalCreditValue | currency }}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Debits</strong>
                      </td>
                      <td class="text-right">{{ this.totalDebitCount | number }}</td>
                      <td class="text-right">{{ this.totalDebitValue | currency }}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>DDIs</strong>
                      </td>
                      <td class="text-right">{{ this.totalDDICount | number }}</td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row></b-row>

              <b-row></b-row>
            </div>
            <vue-good-table mode="remote"
                            ref="table"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            @on-search="onSearch"
                            :columns="columns"
                            :rows="submissions"
                            :lineNumbers="true"
                            :totalRows="totalRecords"
                            :search-options="{
              enabled: true
              }"
                            :paginationOptions="paginationOptions"
                            :sort-options="sortOptions"
                            :isLoading.sync="isTableLoading"
                             styleClass="vgt-table striped bordered">
              <template slot="loadingContent">
                <h1>Loading...</h1>
              </template>
              <div slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">
                  No Submissions Available
                </div>
              </div>
              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'"
                >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span><span class="sr-only">Clear filters</span>
                </button>
                <b-button @click.prevent="load" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                  <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                </b-button>
                <b-button @click.prevent="exportTable" class="" variant="link" :disabled="isLoading" v-b-popover.hover.top.d500="'Export the contents of the table'">
                  <i class="fa fa-share-square pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
                </b-button>
              </div>
            </vue-good-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import papa from 'papaparse'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  props: { id: String },
  mixins: [tableFilterMixin, loading],
  data () {
    return {
      totalDebitCount: 0,
      totalDebitValue: 0.00,
      totalCreditCount: 0,
      totalCreditValue: 0.00,
      totalDDICount: 0,
      contraCount: 0,
      dateGeneratedStr: '',
      submissionId: null,
      submissions: [],
      submission: {
        paymentDate: new Date(),
        processingDate: new Date()
      },
      columns: [
        {
          label: 'Block Number',
          field: 'fileNumber'
        },
        {
          label: 'Bureau Customer Name',
          field: 'bureauCustomerName'
        },
        {
          label: 'Job Filename',
          field: 'filename'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Number of Credits',
          field: 'totalNumberOfCredits'
        },
        {
          label: 'Credits Total',
          tdClass: 'text-right',
          type: 'decimal',
          field: 'totalCreditsValue'
        },
        {
          label: 'Number of Debits',
          field: 'totalNumberOfDebits'
        },
        {
          label: 'Debits Total',
          tdClass: 'text-right',
          type: 'decimal',
          field: 'totalDebitsValue'
        },
        {
          label: 'Number of DDIs',
          field: 'totalNumberOfDdis'
        },
        {
          label: 'Number of Contras',
          field: 'numberOfContras'
        },
        {
          label: 'Processing Date',
          field: 'processingDateDisplay'
        },
        {
          label: 'Payment Date',
          field: 'paymentDate'
        }
      ],
      serverParams: {
        sort: [{ field: 'fileNumber', type: 'asc' }]
      },
      dates: { proc: new Date(), coll: new Date() }
    }
  },
  methods: {
    load: async function () {
      try {
        var query = this.buildGoodTableQuery()
        const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissionreport/' + this.$route.params.id, { params: query }, { showload: true })
        this.totalRecords = response.data.count
        this.submissions = response.data.data
      } catch (error) {
        console.error(error)
      }
      await this.loadSubmissionDetails()
      await this.getSummaryInfo()
    },
    loadSubmissionDetails: async function () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getSubmissionData/' + this.$route.params.id, { showload: true })

        this.submission = response.data
      } catch (e) {
      } finally {
        this.isTableLoading = false
      }
    },
    async getSummaryInfo () {
      try {
        const getsubmissioninforesponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissionsummaryinfo/' + this.$route.params.id, { showload: true })
        console.info(getsubmissioninforesponse)
        this.totalDebitCount = getsubmissioninforesponse.data.debitCount
        this.totalDebitValue = getsubmissioninforesponse.data.debitValue
        this.totalCreditCount = getsubmissioninforesponse.data.creditCount
        this.totalCreditValue = getsubmissioninforesponse.data.creditValue
        this.totalDDICount = getsubmissioninforesponse.data.ddiCount
        this.contraCount = getsubmissioninforesponse.data.contraCount
        this.dateGeneratedStr = getsubmissioninforesponse.data.dateGeneratedStr
      } catch (error) {
        console.error('getSummaryInfo')
        console.error(error)
      }
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      query.perPage = this.totalRecords
      query.forExport = true
      const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissionreport/' + this.$route.params.id, { params: query }, { showload: true })
      var rows = response.data
      var csvString = papa.unparse(rows)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'bureau-submission-report.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'bureau-submission-report.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    onRowClick (params) {

    },
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    formatDate (date) {
      if (!date || !date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  },
  async mounted () {
    this.submissionId = this.$route.params.id
  }
}
</script>
