// initial state
const state = {
  paygateId: '',
  name: ''
}

// actions
const actions = {
  setImportedCustomerPaygateId ({ commit }, paygateId) {
    commit('setImportedCustomerPaygateId', paygateId)
  },
  setImportedCustomerName ({ commit }, name) {
    commit('setImportedCustomerName', name)
  }
}

// mutations
const mutations = {
  setImportedCustomerPaygateId (state, paygateId) {
    state.paygateId = paygateId
  },
  setImportedCustomerName (state, name) {
    state.name = name
  }
}

export default {
  state,
  actions,
  mutations
}
