var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showModalFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showModalFileBrowser = false
                _vm.closePopup()
              }
            }
          })
        : _vm._e(),
      _vm.isLicenced
        ? _c("div", { staticClass: "row mb-4" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c("div", { staticClass: "idb-block" }, [
                  _c("div", { staticClass: "idb-block-title" }, [
                    _vm.isNew
                      ? _c("h2", [
                          _c("span", { staticClass: "pull-right" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.docUrl +
                                    "/automation/paygate-mapping/importers/csvimporter/",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-question-circle"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" Create a CSV / delimited importer ")
                        ])
                      : _c("h2", [
                          _c("span", { staticClass: "pull-right" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.docUrl +
                                    "/automation/paygate-mapping/importers/csvimporter/",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-question-circle"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" Edit a CSV / delimited ")
                        ])
                  ]),
                  _c("div", { staticClass: "idb-block-content" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "The title of the item.",
                                expression: "'The title of the item.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "required"
                          },
                          [_vm._v("Title")]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-10 col-sm-12" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.importer.title,
                                expression: "importer.title"
                              }
                            ],
                            staticClass: "form-control",
                            class: { invalid: _vm.$v.importer.title.$error },
                            attrs: { type: "text" },
                            domProps: { value: _vm.importer.title },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.importer,
                                    "title",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  return _vm.$v.importer.title.$touch()
                                }
                              ]
                            }
                          }),
                          !_vm.$v.importer.title.required &&
                          _vm.$v.importer.title.$dirty
                            ? _c("p", { staticClass: "validationText" }, [
                                _vm._v("The title field is required!")
                              ])
                            : _vm._e(),
                          !_vm.$v.importer.title.maxLength
                            ? _c("p", { staticClass: "validationText" }, [
                                _vm._v(
                                  "Maximum length is " +
                                    _vm._s(
                                      _vm.$v.importer.title.$params.maxLength
                                        .max
                                    ) +
                                    " characters!"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm.uiStage === 1
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                            _c(
                              "label",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "An optional text field for you to write a discription of the item.",
                                    expression:
                                      "'An optional text field for you to write a discription of the item.' ",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ]
                              },
                              [_vm._v("Description")]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-10 col-sm-12" },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.importer.description,
                                    expression: "importer.description"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { rows: "4", cols: "60" },
                                domProps: { value: _vm.importer.description },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.importer,
                                        "description",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      return _vm.$emit(
                                        "textarea",
                                        $event.target.value
                                      )
                                    }
                                  ],
                                  blur: function($event) {
                                    return _vm.$v.importer.description.$touch()
                                  }
                                }
                              }),
                              !_vm.$v.importer.description.maxLength
                                ? _c("p", { staticClass: "validationText" }, [
                                    _vm._v(
                                      "Maximum length is " +
                                        _vm._s(
                                          _vm.$v.importer.description.$params
                                            .maxLength.max
                                        ) +
                                        " characters!"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.uiStage === 1
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                            _c(
                              "label",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value:
                                      "The file path that Paygate will use when this mapping is run to locate the file.",
                                    expression:
                                      "'The file path that Paygate will use when this mapping is run to locate the file.'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ]
                              },
                              [_vm._v("File Location")]
                            )
                          ]),
                          _c("div", { staticClass: "col-md-10 mb-3" }, [
                            _c("div", { staticClass: "input-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.importer.filePath,
                                    expression: "importer.filePath"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  invalid: _vm.$v.importer.filePath.$error
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.importer.filePath },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.importer,
                                        "filePath",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.importer.filePath.$touch()
                                    }
                                  ]
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "input-group-append" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "outline-secondary" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.btnFileBrowser($event)
                                        }
                                      }
                                    },
                                    [_vm._v("...")]
                                  )
                                ],
                                1
                              )
                            ]),
                            !_vm.$v.importer.filePath.maxLength
                              ? _c("p", { staticClass: "validationText" }, [
                                  _vm._v(
                                    "Maximum length is " +
                                      _vm._s(
                                        _vm.$v.importer.filePath.$params
                                          .maxLength.max
                                      ) +
                                      " characters!"
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value:
                                  "Sets the data type used for auto-mapping.",
                                expression:
                                  "'Sets the data type used for auto-mapping.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "required"
                          },
                          [_vm._v("Data Type")]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4 col-sm-12" },
                        [
                          _vm.uiStage === 0
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedDataType,
                                      expression: "selectedDataType"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedDataType = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                _vm._l(_vm.dataTypeMode, function(option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.index,
                                      domProps: { value: option.value }
                                    },
                                    [_vm._v(_vm._s(option.text))]
                                  )
                                }),
                                0
                              )
                            : _c("label", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDataTypeText(_vm.selectedDataType)
                                  )
                                )
                              ])
                        ]
                      ),
                      _c("div", {
                        staticClass: "form-group col-md-1 col-sm-12"
                      })
                    ])
                  ])
                ]),
                _vm.uiStage === 0
                  ? _c("div", { staticClass: "idb-block" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12 col-sm-12" },
                            [
                              _c("vue-dropzone", {
                                ref: "myVueDropzone",
                                attrs: {
                                  useCustomDropzoneOptions: true,
                                  id: "dropzone",
                                  options: _vm.dropzoneOptions
                                },
                                on: {
                                  "vdropzone-file-added": _vm.vfileAdded,
                                  "vdropzone-complete": _vm.vcomplete,
                                  "vdropzone-success": _vm.vsuccess,
                                  "vdropzone-error": _vm.verror
                                }
                              }),
                              _c("div", { staticClass: "float-right" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link",
                                    attrs: { type: "button" },
                                    on: { click: _vm.skipDropZoneStep }
                                  },
                                  [_vm._v("Skip")]
                                )
                              ])
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.uiStage === 1
                  ? _c("div", { staticClass: "idb-block" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c(
                          "div",
                          { staticClass: "row mt-3 mb-3" },
                          [
                            _c("div", { staticClass: "col-md-2 col-sm-12" }, [
                              _c(
                                "label",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value:
                                        "Determines whether a file is cleaned when imported.",
                                      expression:
                                        "'Determines whether a file is cleaned when imported.'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: { for: "cleanImportCheck" }
                                },
                                [_vm._v("Clean File on Import")]
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-group col-md-1 col-sm-12" },
                              [
                                _c("p-check", {
                                  staticClass: "p-switch",
                                  attrs: {
                                    name: "check",
                                    labelId: "cleanImportCheck",
                                    color: "primary "
                                  },
                                  model: {
                                    value: _vm.cleanOnImport,
                                    callback: function($$v) {
                                      _vm.cleanOnImport = $$v
                                    },
                                    expression: "cleanOnImport"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("transition", { attrs: { name: "fadein" } }, [
                              _vm.cleanOnImport
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group col-md-2 col-sm-12"
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName:
                                                "v-b-toggle.cleanerRules",
                                              modifiers: { cleanerRules: true }
                                            },
                                            {
                                              name: "b-popover",
                                              rawName:
                                                "v-b-popover.hover.top.d500",
                                              value:
                                                "Set the rules when cleaning an imported file.",
                                              expression:
                                                "'Set the rules when cleaning an imported file.'",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                                d500: true
                                              }
                                            }
                                          ],
                                          attrs: { variant: "outline-primary" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-broom"
                                          }),
                                          _vm._v(" Cleaner Rules "),
                                          _vm.cleanerRules.filter(function(x) {
                                            return x.enabled
                                          }).length
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-pill badge-primary ml-2"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cleanerRules.filter(
                                                        function(x) {
                                                          return x.enabled
                                                        }
                                                      ).length
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("transition", { attrs: { name: "fadein" } }, [
                              _vm.cleanOnImport
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-md-2 col-sm-12 text-right"
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          directives: [
                                            {
                                              name: "b-popover",
                                              rawName:
                                                "v-b-popover.hover.top.d500",
                                              value:
                                                "Will substitute all characters not found in the following character set with a whitespace character.",
                                              expression:
                                                "'Will substitute all characters not found in the following character set with a whitespace character.'",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                                d500: true
                                              }
                                            }
                                          ]
                                        },
                                        [_vm._v("Character Set")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _c("transition", { attrs: { name: "fadein" } }, [
                              _vm.cleanOnImport
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group col-md-2 col-sm-12"
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.selectedCharset,
                                              expression: "selectedCharset"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.selectedCharset = $event
                                                .target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            }
                                          }
                                        },
                                        _vm._l(_vm.charset, function(
                                          option,
                                          index
                                        ) {
                                          return _c(
                                            "option",
                                            {
                                              key: index + 1,
                                              domProps: { value: option.value }
                                            },
                                            [_vm._v(_vm._s(option.text))]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _c("transition", { attrs: { name: "fadein" } }, [
                              _vm.cleanOnImport
                                ? _c("div", {
                                    staticClass: "col-md-1 col-sm-12"
                                  })
                                : _vm._e()
                            ]),
                            _c("transition", { attrs: { name: "fadein" } }, [
                              _vm.cleanOnImport
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-2 col-sm-12" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName:
                                                "v-b-toggle.substitutionRules",
                                              modifiers: {
                                                substitutionRules: true
                                              }
                                            },
                                            {
                                              name: "b-popover",
                                              rawName:
                                                "v-b-popover.hover.top.d500",
                                              value:
                                                "Set the rules Paygate will follow when making substitutions to a imported file.",
                                              expression:
                                                "'Set the rules Paygate will follow when making substitutions to a imported file.'",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                                d500: true
                                              }
                                            }
                                          ],
                                          attrs: { variant: "outline-primary" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon fas fa-exchange-alt"
                                          }),
                                          _vm._v(" Substitution "),
                                          _vm.substitutionRules.length
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-pill badge-primary ml-2"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.substitutionRules
                                                        .length
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _vm.cleanOnImport
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        id: "cleanerRules",
                                        accordion: "accordion"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-card",
                                        {
                                          attrs: { title: "File Cleaner Rules" }
                                        },
                                        [
                                          _c("br"),
                                          _vm._l(_vm.cleanerRules, function(
                                            column,
                                            index
                                          ) {
                                            return _c("div", { key: index }, [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c("div", {
                                                    staticClass: "col-md-1"
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-11"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pretty p-icon p-smooth"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  column.enabled,
                                                                expression:
                                                                  "column.enabled"
                                                              }
                                                            ],
                                                            attrs: {
                                                              type: "checkbox"
                                                            },
                                                            domProps: {
                                                              checked: Array.isArray(
                                                                column.enabled
                                                              )
                                                                ? _vm._i(
                                                                    column.enabled,
                                                                    null
                                                                  ) > -1
                                                                : column.enabled
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    column.enabled,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c = $$el.checked
                                                                    ? true
                                                                    : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v = null,
                                                                    $$i = _vm._i(
                                                                      $$a,
                                                                      $$v
                                                                    )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        column,
                                                                        "enabled",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        column,
                                                                        "enabled",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    column,
                                                                    "enabled",
                                                                    $$c
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "state p-primary"
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "icon fa fa-check"
                                                              }),
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    column.name
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("br")
                                            ])
                                          })
                                        ],
                                        2
                                      ),
                                      _c("br")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm.cleanOnImport
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        id: "substitutionRules",
                                        accordion: "accordion"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-card",
                                        {
                                          attrs: { title: "Substitution Rules" }
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "small" },
                                                  [
                                                    _vm._v(
                                                      "Substitute the 'From' character with the value of the 'To' character."
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("br"),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-12 col-sm-12"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c("div", {
                                                      staticClass: "col-md-1"
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-2"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v("Enabled")
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-2"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            "From Character"
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-2"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v("To Character")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.substitutionRules,
                                                  function(column, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row columnSection"
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "col-md-1"
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-2"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pretty p-icon p-smooth"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              column.enabled,
                                                                            expression:
                                                                              "column.enabled"
                                                                          }
                                                                        ],
                                                                        attrs: {
                                                                          type:
                                                                            "checkbox"
                                                                        },
                                                                        domProps: {
                                                                          checked: Array.isArray(
                                                                            column.enabled
                                                                          )
                                                                            ? _vm._i(
                                                                                column.enabled,
                                                                                null
                                                                              ) >
                                                                              -1
                                                                            : column.enabled
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            var $$a =
                                                                                column.enabled,
                                                                              $$el =
                                                                                $event.target,
                                                                              $$c = $$el.checked
                                                                                ? true
                                                                                : false
                                                                            if (
                                                                              Array.isArray(
                                                                                $$a
                                                                              )
                                                                            ) {
                                                                              var $$v = null,
                                                                                $$i = _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                              if (
                                                                                $$el.checked
                                                                              ) {
                                                                                $$i <
                                                                                  0 &&
                                                                                  _vm.$set(
                                                                                    column,
                                                                                    "enabled",
                                                                                    $$a.concat(
                                                                                      [
                                                                                        $$v
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                              } else {
                                                                                $$i >
                                                                                  -1 &&
                                                                                  _vm.$set(
                                                                                    column,
                                                                                    "enabled",
                                                                                    $$a
                                                                                      .slice(
                                                                                        0,
                                                                                        $$i
                                                                                      )
                                                                                      .concat(
                                                                                        $$a.slice(
                                                                                          $$i +
                                                                                            1
                                                                                        )
                                                                                      )
                                                                                  )
                                                                              }
                                                                            } else {
                                                                              _vm.$set(
                                                                                column,
                                                                                "enabled",
                                                                                $$c
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "state p-primary"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "icon fa fa-check"
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "label"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-1"
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        column.from,
                                                                      expression:
                                                                        "column.from"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type:
                                                                      "text",
                                                                    maxlength:
                                                                      "1"
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      column.from
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        column,
                                                                        "from",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ]
                                                            ),
                                                            _c("div", {
                                                              staticClass:
                                                                "col-md-1"
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-1"
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        column.to,
                                                                      expression:
                                                                        "column.to"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type:
                                                                      "text",
                                                                    maxlength:
                                                                      "1"
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      column.to
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        column,
                                                                        "to",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ]
                                                            ),
                                                            _c("div", {
                                                              staticClass:
                                                                "col-md-1"
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-5 text-right"
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "b-popover",
                                                                        rawName:
                                                                          "v-b-popover.hover.top.d500",
                                                                        value:
                                                                          "Removes the substitute rule.",
                                                                        expression:
                                                                          "'Removes the substitute rule.'",
                                                                        modifiers: {
                                                                          hover: true,
                                                                          top: true,
                                                                          d500: true
                                                                        }
                                                                      }
                                                                    ],
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-danger btn-sm"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        return _vm.btnRemoveSubstitutionRule(
                                                                          index
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "fa fa-times",
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true"
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-12 text-right"
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "b-popover",
                                                                rawName:
                                                                  "v-b-popover.hover.top.d500",
                                                                value:
                                                                  "Add a new substitution rule.",
                                                                expression:
                                                                  "'Add a new substitution rule.'",
                                                                modifiers: {
                                                                  hover: true,
                                                                  top: true,
                                                                  d500: true
                                                                }
                                                              }
                                                            ],
                                                            attrs: {
                                                              variant:
                                                                "outline-primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.btnAddSubstitutionRule(
                                                                  _vm.index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "icon fa fa-plus"
                                                            }),
                                                            _vm._v(" Add Rule ")
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.uiStage === 1
                  ? _c("div", { staticClass: "idb-block" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-2 col-sm-12" }),
                          _vm._m(3),
                          _c("div", { staticClass: "col-md-1" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.importer.parserParams.delimiter,
                                  expression: "importer.parserParams.delimiter"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                invalid:
                                  _vm.$v.importer.parserParams.delimiter.$error
                              },
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.importer.parserParams.delimiter
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.importer.parserParams,
                                      "delimiter",
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.$v.importer.parserParams.delimiter.$touch()
                                  }
                                ]
                              }
                            }),
                            !_vm.$v.importer.parserParams.delimiter.required &&
                            _vm.$v.importer.parserParams.delimiter.$dirty
                              ? _c("p", { staticClass: "validationText" }, [
                                  _vm._v("The delimiter field is required!")
                                ])
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "col-md-1" }),
                          _vm._m(4),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch",
                                attrs: {
                                  labelId: "trimCheck",
                                  name: "check",
                                  color: "primary "
                                },
                                model: {
                                  value: _vm.importer.parserParams.trim,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.importer.parserParams,
                                      "trim",
                                      $$v
                                    )
                                  },
                                  expression: "importer.parserParams.trim"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm.uiStage === 1
                          ? _c("div", { staticClass: "row mt-3" }, [
                              _vm._m(5),
                              _c("div", { staticClass: "col-md-2" }, [
                                _vm._v("Comments Character")
                              ]),
                              _c("div", { staticClass: "col-md-1" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.importer.parserParams.commentChars,
                                      expression:
                                        "importer.parserParams.commentChars"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value:
                                      _vm.importer.parserParams.commentChars
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.importer.parserParams,
                                        "commentChars",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-md-1" }),
                              _c("div", { staticClass: "col-md-2" }, [
                                _vm._v("Quote Character")
                              ]),
                              _c("div", { staticClass: "col-md-1" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.importer.parserParams.quote,
                                      expression: "importer.parserParams.quote"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.importer.parserParams.quote
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.importer.parserParams,
                                        "quote",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          : _vm._e(),
                        _vm.uiStage === 1
                          ? _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-md-2" }),
                              _c("div", { staticClass: "col-md-2" }, [
                                _vm._v("Ignore First Rows")
                              ]),
                              _c("div", { staticClass: "col-md-1" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.importer.parserParams
                                          .ignoreFirstRows,
                                      expression:
                                        "importer.parserParams.ignoreFirstRows"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    oninput:
                                      "this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null",
                                    step: "1"
                                  },
                                  domProps: {
                                    value:
                                      _vm.importer.parserParams.ignoreFirstRows
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.importer.parserParams,
                                        "ignoreFirstRows",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-md-1" }),
                              _vm._m(6),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("p-check", {
                                    staticClass: "p-switch",
                                    attrs: {
                                      name: "check",
                                      labelId: "headerCheck",
                                      color: "primary "
                                    },
                                    model: {
                                      value:
                                        _vm.importer.parserParams.hasHeader,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.importer.parserParams,
                                          "hasHeader",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "importer.parserParams.hasHeader"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm.uiStage === 1
                          ? _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-md-2" }),
                              _c("div", { staticClass: "col-md-2" }, [
                                _vm._v("Ignore Last Rows")
                              ]),
                              _c("div", { staticClass: "col-md-1" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.importer.parserParams
                                          .ignoreLastRows,
                                      expression:
                                        "importer.parserParams.ignoreLastRows"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    oninput:
                                      "this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null",
                                    step: "1"
                                  },
                                  domProps: {
                                    value:
                                      _vm.importer.parserParams.ignoreLastRows
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.importer.parserParams,
                                        "ignoreLastRows",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-md-1" }),
                              _c("div", { staticClass: "col-md-2" }),
                              _c("div", { staticClass: "col-md-1" })
                            ])
                          : _vm._e(),
                        _vm.uiStage === 1 &&
                        _vm.importer.parserParams.hasHeader &&
                        _vm.importer.parserParams.ignoreFirstRows > 0
                          ? _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-md-2" }),
                              _vm._m(7)
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.uiStage === 1
                  ? _c("div", { staticClass: "idb-block" }, [
                      _vm._m(8),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("hr"),
                          _c("br"),
                          _c("br"),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12 col-sm-12" },
                            [
                              _vm._m(9),
                              _c("hr"),
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    options: { group: "columns" },
                                    handle: ".handle"
                                  },
                                  on: {
                                    end: function($event) {
                                      return _vm.endDrag()
                                    }
                                  },
                                  model: {
                                    value: _vm.importer.configuration,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.importer,
                                        "configuration",
                                        $$v
                                      )
                                    },
                                    expression: "importer.configuration"
                                  }
                                },
                                _vm._l(_vm.importer.configuration, function(
                                  column,
                                  index
                                ) {
                                  return _c("div", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "row columnSection" },
                                      [
                                        _c("div", { staticClass: "col-md-1" }, [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-align-justify handle mr-3"
                                          }),
                                          _vm._v(" " + _vm._s(index + 1) + " ")
                                        ]),
                                        _c("div", { staticClass: "col-md-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.truncate(column.value))
                                          )
                                        ]),
                                        _c("div", { staticClass: "col-md-2" }, [
                                          column.weakType
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-pill badge-success"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(column.weakType)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]),
                                        _vm.selectedDataType === "bacsPayments"
                                          ? _c(
                                              "div",
                                              { staticClass: "col-md-5" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    options: _vm.bacsDataTypes
                                                  },
                                                  model: {
                                                    value: column.strongType,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        column,
                                                        "strongType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "column.strongType"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm.selectedDataType ===
                                            "bacsBureauCustomers"
                                          ? _c(
                                              "div",
                                              { staticClass: "col-md-5" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    options:
                                                      _vm.bureauCustomerDataTypes
                                                  },
                                                  model: {
                                                    value: column.strongType,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        column,
                                                        "strongType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "column.strongType"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm.selectedDataType === "ukDdmsVar"
                                          ? _c(
                                              "div",
                                              { staticClass: "col-md-5" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    options:
                                                      _vm.ukDdmsVarDataTypes
                                                  },
                                                  model: {
                                                    value: column.strongType,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        column,
                                                        "strongType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "column.strongType"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "col-1" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      "Removes the column from the Column Parameters.",
                                                    expression:
                                                      "'Removes the column from the Column Parameters.'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                attrs: { variant: "link" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.btnRemoveColumn(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "text-secondary glyphicon ti-trash text-danger",
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "idb-block-footer" }, [
                        _vm.uiStage === 1
                          ? _c("div", { staticClass: "mb-2" }, [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Add a new column.",
                                      expression: "'Add a new column.'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  staticClass:
                                    "btn btn-outline-primary float-right",
                                  attrs: { "aria-hidden": "true" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.btnAddColumn($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-plus mr-2" }),
                                  _vm._v("Add Column ")
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.uiStage === 1
                  ? _c("div", { staticClass: "idb-block" }, [
                      !_vm.isNew
                        ? _c("div", { staticClass: "idb-block-title" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "span",
                                { staticClass: "col-md-12 col-sm-12" },
                                [
                                  _c("strong", [
                                    _vm._v("Mappings using this item")
                                  ]),
                                  _vm.boundMappings.length > 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-pill badge-primary ml-2"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.boundMappings.length)
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "Refresh the list of bound mappings.",
                                          expression:
                                            "'Refresh the list of bound mappings.'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.getBoundMappings($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-sync-alt pointer"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      !_vm.isNew
                        ? _c("div", { staticClass: "idb-block-content" }, [
                            !_vm.isNew && _vm.uiStage === 1
                              ? _c("div", { staticClass: "row" }, [_c("span")])
                              : _vm._e(),
                            _vm.boundMappings.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(_vm.boundMappings, function(m) {
                                    return _c(
                                      "div",
                                      {
                                        key: m.index,
                                        staticClass: "row",
                                        attrs: { value: m.value }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12 ml-5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(m.metaData.title) +
                                                " ( "
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "small",
                                                attrs: {
                                                  tag: "a",
                                                  to:
                                                    "/automation/mapping/mappingdetail/" +
                                                    m._id
                                                }
                                              },
                                              [_vm._v("Edit")]
                                            ),
                                            _vm._v(") ")
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _c("br")
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "idb-block-footer" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-sm-3" },
                            [
                              _vm.isNew && _vm.uiStage === 1
                                ? _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "Creates and saves a new item.",
                                          expression:
                                            "'Creates and saves a new item.'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      staticClass: "mr-3",
                                      attrs: {
                                        disabled: _vm.isLoading,
                                        variant: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.createImporter($event)
                                        }
                                      }
                                    },
                                    [_vm._v("Create")]
                                  )
                                : _vm._e(),
                              !_vm.isNew && _vm.uiStage === 1
                                ? _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "Update the current item saving any changes.",
                                          expression:
                                            "'Update the current item saving any changes.'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      staticClass: "mr-3",
                                      attrs: {
                                        disabled: _vm.isLoading,
                                        variant: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.updateImporter($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-edit mr-2"
                                      }),
                                      _vm._v("Save ")
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "btn-group",
                                  attrs: { role: "group" }
                                },
                                [
                                  _vm._m(10),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: {
                                        "aria-labelledby": "btnGroupDrop1"
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: { click: _vm.cloneImporter }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "glyphicon ti-files mr-2"
                                          }),
                                          _vm._v("Clone Importer ")
                                        ]
                                      ),
                                      _vm.isCorvidAdmin
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: _vm.btnTransferImporter
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "glyphicon ti-new-window mr-2"
                                              }),
                                              _vm._v(
                                                "Copy Importer to Another Customer "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", {
                                        staticClass: "dropdown-divider"
                                      }),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "dropdown-item text-danger",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.deleteImporter($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "glyphicon ti-trash mr-2"
                                          }),
                                          _vm._v("Delete Importer ")
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Sample File")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("File Cleaner")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("File Parameters")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Delimiter")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { for: "trimCheck" } }, [_vm._v("Trim Whitespaces")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { title: "Description" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { for: "headerCheck" } }, [_vm._v("Header")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-8" }, [
      _c("div", { staticClass: "alert alert-warning" }, [
        _c("strong", [_vm._v("Note")]),
        _vm._v(
          " Your current configuration might not work correctly because you have included a header but the header will, most likely, be skipped due to the value of 'Ignore First Rows'. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Columns")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-1" }, [
        _c("strong", [_vm._v("Column")])
      ]),
      _c("div", { staticClass: "col-md-3" }, [
        _c("strong", [_vm._v("Sample")])
      ]),
      _c("div", { staticClass: "col-md-2" }, [
        _c("strong", [_vm._v("Suggestion")])
      ]),
      _c("div", { staticClass: "col-md-5" }, [
        _c("strong", [_vm._v("Data Type")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-outline-primary dropdown-toggle ml-3",
        attrs: {
          id: "btnGroupDrop1",
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "glyphicon ti-more mr-2" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }