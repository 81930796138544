<template>
  <div>
    <form>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Customer Import</h2>
        </div>

        <div class="ml-5 mt-3" hidden>
          <b-row>Filename: {{ filename }}</b-row>
          <b-row>Imported Customer: {{ importedCustomerName }}</b-row>
          <b-row>Imported PaygateId: {{ importedCustomerPaygateId }}</b-row>
          <b-row>User PaygateId: {{ paygateId }}</b-row>
          <b-row>Last Error: {{ lastError }}</b-row>
        </div>

        <div class="idb-block-content">

          <b-row class="mt-1" v-if="canImport">
            <b-col>
              <!-- <button class="btn btn-primary ml-2" type="button" @click="testSignalR">Test SignalR</button> -->
              <!-- <button class="btn btn-primary ml-2" type="button" @click="extractCustomer">Extract</button> -->
              <button v-if="canImport" class="btn btn-primary ml-2" type="button" @click="importCustomer">Import</button>
              <button v-if="canBureau" class="btn btn-primary ml-2" type="button" @click="importBureauCustomer">Migrate Bureau From v2</button>
              <button v-if="importedCustomerName != ''" class="btn btn-primary ml-2" type="button" @click="saveLog">Save Log</button>
              <strong class="ml-2">{{ this.lastError }}</strong>
              <button v-if="canDelete" class="btn btn-danger ml-2 float-right" type="button" @click="deleteCustomer">Delete</button>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-alert v-if="!hubConnected" class="mt-3" variant="danger" show>
                SignalR Hub not connected - Status updates will NOT be shown
              </b-alert>
              <b-alert v-else class="mt-3" variant="success" show>
                SignalR Hub connected
              </b-alert>
            </b-col>
          </b-row>

          <b-row :hidden="this.hideDropzone">
            <b-col sm="12">
              <dropzone id="customer-import-dropzone"
                        ref="fileUploader"
                        :options="dropzoneOptions"
                        @vdropzone-success="afterSuccess"
                        @vdropzone-file-added="fileAdded"
                        @vdropzone-removed-file="fileRemoved"
                        @vdropzone-sending="sendingEvent"
                        @vdropzone-error="uploadError"
                        @vdropzone-upload-progress="uploadProgress"
                        :use-custom-slot="true"
                        :useCustomSlot="true">

                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">Drag and drop to upload your customer import file</h3>
                  <div class="subtitle">...or click to select a file from your computer</div>
                </div>

              </dropzone>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <vue-good-table
                :rows="tasks"
                :columns="columns"
                styleClass="vgt-table condensed bordered"
                :sort-options="{
                      enabled: false,
                    }"
              >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.label === 'Complete'">
                      <span v-if="props.row['complete']">
                        <i class="fa fa-check mr-1"></i>
                      </span>
                      <span v-else>
                        <i class="fa fa-times mr-1"></i>
                      </span>
                    </span>
                </template>
              </vue-good-table>

            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <b-progress :value=percentage :max="100" variant="success"></b-progress>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <ul>
              <li v-for="(item, index) in outstandingItems" :key="index">
                {{ item.category }} - {{ item.message }}
              </li>
            </ul>
          </b-row>

          <b-row>
            <b-col>
              <b-form-textarea
                id="detailLog"
                v-model="log"
                rows="40"
              ></b-form-textarea>
            </b-col>
          </b-row>

        </div>

        <div class="idb-block-footer">
        </div>

      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import Dropzone from 'vue2-dropzone'
import axios from 'axios'
import Websockets from '@/Assets/Websockets/Mixin'
import _ from 'underscore'
import swal from 'sweetalert2'
import { saveAs } from 'file-saver'
import colours from '@/Assets/Constants/colours'

export default {
  data () {
    return {
      hubUrl: '',
      percentage: 0,
      lastUpdate: '',
      lastError: '',
      hideDropzone: false,
      canImport: false,
      canDelete: false,
      canBureau: false,
      hubConnected: false,
      filename: '',
      outstandingItems: {},
      log: '',
      dropzoneOptions: {
        url: process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/upload',
        maxFilesize: 1024 * 1024 * 10, // max individual file size 1024 MB -- 10 GB
        maxFiles: 1,
        chunking: true,
        chunkSize: 1024 * 1024 * 10, // max chunk size in bytes
        forceChunking: true,
        retryChunks: true,
        retryChunksLimit: 3
      },
      tasks: [
        { title: 'Upload File', status: '', complete: false },
        { title: 'Merge', status: '', complete: false },
        { title: 'Extract', status: '', complete: false },
        { title: 'Create Customer', status: '', complete: false },
        { title: 'Update Licence', status: '', complete: false },
        { title: 'Create Importers', status: '', complete: false },
        { title: 'Create Mappings', status: '', complete: false },
        { title: 'Create Users', status: '', complete: false },
        { title: 'Create USB Tokens', status: '', complete: false },
        { title: 'Create Bank Accounts', status: '', complete: false },
        { title: 'Create Customer Certificates', status: '', complete: false },
        { title: 'Create Groups', status: '', complete: false },
        { title: 'Update Security Policy', status: '', complete: false },
        { title: 'Create CRON Triggers', status: '', complete: false },
        { title: 'Create File Watchers', status: '', complete: false },
        { title: 'Importing Payment History', status: '', complete: false },
        //{ title: 'Importing Bureau ', status: '', complete: false },
        // { title: 'Importing Collections', status: '', complete: false },
        { title: 'Create Audit', status: '', complete: false },
         { title: 'Create Bureau Groups', status: '', complete: false },
         { title: 'Create Bureau Customers', status: '', complete: false },
         { title: 'Create Bureau Jobs', status: '', complete: false },
         { title: 'Create Bureau Job Details', status: '', complete: false },
        { title: 'Import Complete', status: '', complete: false },
        { title: 'Delete Customer', status: '', complete: false },
      ],
      columns: [
        { label: 'Task', field: 'title', width: '350px', sortable: false },
        { label: 'Status', field: 'status', width: '100%', sortable: false },
        { label: 'Complete', field: 'complete', width: '100px', sortable: false }
      ]
    }
  },
  computed: {
    paygateId () { return this.$store.state.common.paygateId },
    importedCustomerPaygateId () { return this.$store.state.importedCustomerStore.paygateId },
    importedCustomerName () { return this.$store.state.importedCustomerStore.name }
  },
  mixins: [
    Websockets(`${process.env.VUE_APP_MIGRATION_API_URL}hubs/customermigration`, {
      reconnection: true,
      reconnectionDelay: 10000,
      events: ['Refresh']
    })
  ],
  async mounted () {
    auth.getAccessToken()
      .then(async token => {
        this.bearerToken = 'Bearer ' + token
      })
    this.$store.dispatch('setPaygateId', '9457933c-2e6f-45c9-bdb5-4445dffb98f1')

    var apiVersionResponse = await axios.get(process.env.VUE_APP_MIGRATION_API_URL + 'version')
    this.log = 'Using ' + apiVersionResponse.data
  },
  components: {
    Dropzone
  },
  methods: {
    SOCKET_RECONNECT (data) {
      console.log(this.sendToWebSocket)
      console.log(this.webSocket)
      this.hubConnected = true
      console.info('Re-Connection to status hub succeeded')
    },
    SOCKET_ONOPEN (data) {
      console.log(this.sendToWebSocket)
      console.log(this.webSocket)
      this.hubConnected = true
      console.info('Connection to status hub succeeded')
    },
    SOCKET_ONCLOSE (data) {
      console.log(this.sendToWebSocket)
      console.log(this.webSocket)
      this.hubConnected = false
      console.info('Connection to status hub closed')
    },
    SOCKET_refresh (data) {
      this.SOCKET_REFRESH(data)
    },
    SOCKET_REFRESH (data) {
      console.info('Refresh', data)
      // console.log(this.webSocket)
      this.percentage = data.message.percentComplete
      this.canBureau = data.message.canBureau
      const task = this.tasks[data.message.taskTypeInt]
      this.updateStatus(task.title, data.message.description, data.message.complete)
      if (data.message.taskType === 3) {
        console.log('Setting paygateId ' + data.message.paygateId)
        this.$store.dispatch('setPaygateId', data.message.paygateId)
      }
      if (data.message.details !== '') {
        this.log = this.log.concat('\r\n', data.message.details)
        if (data.message.details.startsWith('Finished importing')) {
          var logData = {
            customerName: this.importedCustomerName,
            log: this.log
          }
          axios.post(process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/saveLog', logData)
        }
      }
    },
    updateStatus (title, status, complete) {
      console.log('Update status: ', title, status, complete)
      var match = _.find(this.tasks, function (item) { return item.title === title })
      if (match) {
        match.status = status
        match.complete = complete
      }
    },
    async afterSuccess (file, response) {
      await this.extractCustomer()
    },
    async fileAdded (file) {
      console.info('fileAdded', file)
      this.filename = file.name
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },
    async uploadProgress (file, progress, bytesSent) {
      this.percentage = bytesSent / file.size * 100
    },
    async uploadError (file, error) {
      console.error('uploadError', file, error)
      this.lastError = error
      this.hideDropzone = false
    },
    fileRemoved (file, error, xhr) {
      console.log('fileRemoved', file, error)
    },
    sendingEvent (file, xhr, formData) {
      console.info('sendingEvent', file, xhr, formData)
      this.updateStatus('Upload File', 'Uploading file ' + file.name, false)
      this.hideDropzone = true
    },
    dropZoneError (file, error, request) {
      console.error('dropZoneError', file, error)
      this.$toastr.e('File upload ' + file.name + ' failed', 'Import Customer')
    },
    async extractCustomer () {
      const data = {
        filename: this.filename
      }
      const response = await axios.post(process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/extract', data)
      this.canImport = true
      console.log(response)
    },
    async getMappings () {
      const response = await axios.get(process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/getmappings/' + this.importedCustomerPaygateId)
      console.log(response)
    },
    async importCustomer () {
      this.lastError = ''
      this.log = ''
      const data = {
        filename: this.filename
      }
      const response = await axios.post(process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/import', data)
      // this.importedCustomerName = response.data.importedCustomerName
      this.$store.dispatch('setImportedCustomerPaygateId', response.data.importedCustomerPaygateId)
      this.$store.dispatch('setImportedCustomerName', response.data.importedCustomerName)
      this.outstandingItems = response.data.outstandingItems
      this.lastError = response.data.error
      this.canDelete = true
      await this.$store.dispatch('loadCustomersList')
    },
    async importBureauCustomer () {
      this.log = ''
      const data = { filename: this.filename }
      for (var i = 0; i < this.tasks.length - 6; i++) {
        this.tasks[i].status = 'Skipping'
        this.tasks[i].complete = true
      }
      const response = await axios.post(process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/importbureau', data)
      this.outstandingItems = response.data.outstandingItems
      this.lastError = response.data.error
      this.canDelete = true
      await this.$store.dispatch('loadCustomersList')
    },
    async deleteCustomer() {
      const swalResult = await swal.fire({
        title: 'Delete Customer',
        text: 'Are you sure you want to delete ' + this.importedCustomerName,
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        this.canImport = false
        this.canDelete = false
        this.log = ''
        const response = await axios.delete(process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/deletecustomer/' + this.importedCustomerPaygateId)
        this.lastError = response.data
        this.canImport = true
        this.canDelete = false
        this.tasks.forEach((t) => {
          if (!_.contains(['Upload File', 'Merge', 'Extract', 'Deleting Customer'], t.title)) {
            t.status = ''
          }
        })
        await this.$store.dispatch('loadCustomersList')
        this.$store.dispatch('setPaygateId', '9457933c-2e6f-45c9-bdb5-4445dffb98f1')
      }
    },
    async saveLog() {
      // const blob = new Blob([this.log], { type: 'application/pdf' });
      // const link = document.createElement('a');
      // link.href = (window.webkitURL || window.URL).createObjectURL(blob);
      // link.download = this.importedCustomerName + '-import-log.txt'
      // link.dataset.downloadurl = ['text/plain', link.download, link.href].join(':');
      // link.click();
      // URL.revokeObjectURL(link.href)
      var file = new Blob([this.log], {type: 'text/plain'})
      saveAs(file, `${this.importedCustomerName} -import-log.txt`)
    },
    async testSignalR() {
      const data = {
        message: 'Hello from SignalR ' + new Date().toTimeString()
      }
      const response = await axios.post(process.env.VUE_APP_MIGRATION_API_URL + 'customerimport/testsignalr', data)
      console.log(response.data)
    }
  },
  validations: {}
}

</script>
