var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasFilterRow
    ? _c(
        "tr",
        [
          _vm.lineNumbers
            ? _c("th", [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v("Line Number cannot be filtered")
                ])
              ])
            : _vm._e(),
          _vm.selectable
            ? _c("th", [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v("Selectable cannot be filtered")
                ])
              ])
            : _vm._e(),
          _vm._l(_vm.columns, function(column, index) {
            return !column.hidden
              ? _c(
                  "th",
                  { key: index, class: _vm.getClasses(column) },
                  [
                    _vm._t(
                      "column-filter",
                      [
                        _vm.isFilterable(column)
                          ? _c("div", [
                              _c(
                                "label",
                                {
                                  staticClass: "sr-only",
                                  attrs: { for: _vm.getName(column) + "_input" }
                                },
                                [_vm._v("Filter by " + _vm._s(column.label))]
                              ),
                              !_vm.isDropdown(column)
                                ? _c("input", {
                                    staticClass: "vgt-input",
                                    attrs: {
                                      id: _vm.getName(column) + "_input",
                                      name: _vm.getName(column),
                                      type: "text",
                                      placeholder: _vm.getPlaceholder(column)
                                    },
                                    domProps: {
                                      value:
                                        _vm.columnFilters[
                                          _vm.fieldKey(column.field)
                                        ]
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.updateFiltersOnEnter(
                                          column,
                                          $event.target.value
                                        )
                                      },
                                      input: function($event) {
                                        return _vm.updateFiltersOnKeyup(
                                          column,
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.isDropdownArray(column)
                                ? _c(
                                    "select",
                                    {
                                      staticClass: "vgt-select",
                                      attrs: {
                                        id: _vm.getName(column) + "_input",
                                        name: _vm.getName(column)
                                      },
                                      domProps: {
                                        value:
                                          _vm.columnFilters[
                                            _vm.fieldKey(column.field)
                                          ]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateFiltersImmediately(
                                            column.field,
                                            $event.target.value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { key: "-1", attrs: { value: "" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getPlaceholder(column))
                                          )
                                        ]
                                      ),
                                      _vm._l(
                                        column.filterOptions
                                          .filterDropdownItems,
                                        function(option, i) {
                                          return _c(
                                            "option",
                                            {
                                              key: i,
                                              domProps: { value: option }
                                            },
                                            [_vm._v(" " + _vm._s(option) + " ")]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.isDropdownObjects(column)
                                ? _c(
                                    "select",
                                    {
                                      staticClass: "vgt-select",
                                      attrs: {
                                        id: _vm.getName(column) + "_input",
                                        name: _vm.getName(column)
                                      },
                                      domProps: {
                                        value:
                                          _vm.columnFilters[
                                            _vm.fieldKey(column.field)
                                          ]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateFiltersImmediately(
                                            column.field,
                                            $event.target.value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { key: "-1", attrs: { value: "" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getPlaceholder(column))
                                          )
                                        ]
                                      ),
                                      _vm._l(
                                        column.filterOptions
                                          .filterDropdownItems,
                                        function(option, i) {
                                          return _c(
                                            "option",
                                            {
                                              key: i,
                                              domProps: { value: option.value }
                                            },
                                            [_vm._v(_vm._s(option.text))]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ])
                          : _c("div", [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v(_vm._s(column.label) + " not filterable")
                              ])
                            ])
                      ],
                      { column: column, updateFilters: _vm.updateSlotFilter }
                    )
                  ],
                  2
                )
              : _vm._e()
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }