<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div>
                <div class="form-group">
              <label class="required">Title</label>
            <input type="text" class="form-control"
                  v-model="workflowMetaData.title"
                  :class="{invalid: $v.workflowMetaData.title.$error}"
                  @input="$v.workflowMetaData.title.$touch()">
                <p class="validationText" v-if="!$v.workflowMetaData.title.maxLength && $v.workflowMetaData.title.$dirty ">The title is too long. The maximum length is 64 characters.</p>
                <p class="validationText" v-if="!$v.workflowMetaData.title.required && $v.workflowMetaData.title.$dirty ">A workflow title is required.</p>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Description</label>
              <textarea class="form-control" rows="4" cols="60"
              v-model="workflowMetaData.description"
              :class="{invalid: $v.workflowMetaData.description.$error}"
              @input="$v.workflowMetaData.description.$touch()"></textarea>
              <p class="validationText" v-if="!$v.workflowMetaData.description.maxLength && $v.workflowMetaData.description.$dirty ">The description is too long. The maximum length is 128 characters.</p>
            </div>
            <br/>
            <div class="form-row">
              <div class="col-md-4">
                <label>Author</label>
                <input type="text" :disabled="true" class="form-control"
                  v-model="workflowMetaData.author"
                  :class="{invalid: $v.workflowMetaData.author.$error}"
                  @input="$v.workflowMetaData.author.$touch()" >
                <p class="validationText" v-if="!$v.workflowMetaData.author.maxLength && $v.workflowMetaData.author.$dirty ">The author field is too long. The maximum length is 128 characters.</p>
              </div>
              <div class="col-md-2">
              </div>
              <div class="col-md-6">
                <label>Type</label><br>
                <select v-model="workflowMetaData.type" class="custom-select custom-select-lg">
                  <option v-for="option in typeOptions" :key="option.index" v-bind:value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <br/>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="danger"
                @click="btnCancel"
                title="Cancel">
                <span class="fa fa-times" aria-hidden="true"></span>
                Cancel
              </b-button>
              <b-button variant="success"
                @click.prevent="btnSave"
                title="Save Metadata">
                <span class="fa fa-save" aria-hidden="true"></span>
                Save
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
export default {
  name: 'popup',
  validations: {
    workflowMetaData: {
      description: {
        maxLength: maxLength(128)
      },
      title: {
        required,
        maxLength: maxLength(128)
      },
      author: {
        maxLength: maxLength(128)
      }
    }
  },
  data () {
    return {
      originalMetaData: {},
      workflowMetaData: {
        title: '',
        description: '',
        author: '',
        dateCreated: '',
        type: 'bacs',
        numExecution: 0,
        lastExecuted: ''
      },
      typeOptions: [
        { text: 'BACS', value: 'bacs' },
        { text: 'Faster Payments', value: 'fps' },
        { text: 'UK BACS Bureau', value: 'ukBureau' },
        { text: 'Bureau Customer Importer', value: 'bureauCustomerImporter' },
        { text: 'UK Collections', value: 'ukddms' },
        { text: 'UK Collections Payers', value: 'ukddmsPayers' },
        { text: 'UK Collections Variable Update', value: 'ukddmsVar' },
        // { text: 'SEPA', value: 'sepa' },
        { text: 'Other', value: 'other' }
      ]
    }
  },
  methods: {
    btnSave () {
      // Add the updated data into the selected node props.
      if (this.$v.$invalid) {
        console.log('invalid')
        this.$v.$touch()
        return
      }
      this.$store.commit('setWorkflowMetaData', this.workflowMetaData)
      this.$emit('close')
    },
    btnCancel () {
      const initialState = JSON.parse(this.originalMetaData)
      // console.log('initialState')
      // console.log(initialState)
      this.$store.commit('setWorkflowMetaData', initialState)
      this.$emit('cancel')
    },
    updateForm (fieldName, value) {
    },
    scrollHandle (evt) {
    }
  },
  beforeUpdate () {
  },
  mounted () {
    this.workflowMetaData = this.$store.state.workflow.workflowMetaData
    this.originalMetaData = JSON.stringify(this.workflowMetaData)
    console.log(this.workflowMetaData)
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 70%;
    margin: 0px auto;
    padding: 20px 30px;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    max-height: 90vh;
    overflow-y: auto;
    background-color: rgba(250, 250, 250)
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .required {
    color: red;
    font-variant-position: super;
    padding-left: 4px;
  }
  .invalid {
    border-color: red;
    border-width: 2px;
  }
  label.required {
    /* $gray-700:  */
    color: #878787;
  }
  label.required::after {
    content: " *";
    color: red;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #dedada;
    border: #dedada;
    color: #585858;
  }
  .vue-input-tag-wrapper .input-tag .remove {
    color: #444444;
  }
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
