export default {
  methods: {
    showSupportLinkSnapbar (msg) {
      msg += '<br />We are sorry you have encountered this error. To help resolve this issue please click on the link below and follow the required instructions.<br />'
      msg += '<a style="color: lightblue;" href="https://www.paygate.uk/common-submission-errors/" target="_blank">https://www.paygate.uk/common-submission-errors/</a>'
      let options = { autoClose: false, timeout: 0, html: true }
      this.$snapbar.e(msg, options)
    }
  }
}
