var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v(
      " " +
        _vm._s(_vm.messageCount) +
        " " +
        _vm._s(_vm.title) +
        " " +
        _vm._s(_vm.linkText) +
        " " +
        _vm._s(_vm.linkUrl) +
        " "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }