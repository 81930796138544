import { render, staticRenderFns } from "./CreateFasterPaymentSubUsingEditor.vue?vue&type=template&id=13eaf66f&"
import script from "./CreateFasterPaymentSubUsingEditor.vue?vue&type=script&lang=js&"
export * from "./CreateFasterPaymentSubUsingEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13eaf66f')) {
      api.createRecord('13eaf66f', component.options)
    } else {
      api.reload('13eaf66f', component.options)
    }
    module.hot.accept("./CreateFasterPaymentSubUsingEditor.vue?vue&type=template&id=13eaf66f&", function () {
      api.rerender('13eaf66f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Views/Bacs/FasterPayments/CreateFasterPaymentSubUsingEditor.vue"
export default component.exports