// Returns a single rgb color interpolation between given rgb color
// based on the factor given; via https://codepen.io/njmcode/pen/axoyD?editors=0010
function interpolateColor (color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5
  }
  var result = color1.slice()
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
  }
  return result
};
// My function to interpolate between two colors completely, returning an array
function interpolateColors (color1, color2, steps) {
  var stepFactor = 1 / (steps - 1)
  var interpolatedColorArray = []

  color1 = color1.match(/\d+/g).map(Number)
  color2 = color2.match(/\d+/g).map(Number)

  for (var i = 0; i < steps; i++) {
    interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i))
  }

  return interpolatedColorArray.map((c) => `rgb(${c.join(',')})`)
}

export default interpolateColors
