var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "viewFlaggedSubmissions" } },
    [
      _c(
        "b-row",
        { attrs: { mb: "4" } },
        [
          _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [
                _c(
                  "h2",
                  [
                    _c("help-icon", {
                      attrs: { docPath: "/paygate-collections/submissions/" }
                    }),
                    _vm._v("Pending overdue submission")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c("p", [
                    _vm._v(
                      'A submission is due to leave the collection window, the processing date can be updated using the "New Submission Date" field below.'
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "Individual collections can have their date modified using the detail tab."
                    )
                  ]),
                  _c(
                    "b-tabs",
                    { attrs: { "nav-wrapper-class": "no-print-tabs" } },
                    [
                      _c("b-tab", { attrs: { title: "Summary" } }, [
                        _c("table", [
                          _c("tr", [
                            _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                              _vm._v("Group")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.submissionResponse
                                    ? _vm.submissionResponse.groupName
                                    : ""
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                              _vm._v("Planned Processing Date")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.submissionResponse
                                    ? _vm.formatDate(
                                        _vm.submissionResponse
                                          .plannedProcessingDate
                                      )
                                    : ""
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                              _vm._v("Count")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.submissionResponse
                                    ? _vm.submissionResponse.totalResults
                                    : "0"
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                              _vm._v("Total")
                            ]),
                            _c("td", [
                              _vm._v(
                                "£" +
                                  _vm._s(
                                    _vm.submissionResponse
                                      ? _vm.numberWithCommas(
                                          _vm.submissionResponse.submissionTotal
                                        )
                                      : "0.00"
                                  )
                              )
                            ])
                          ]),
                          _c("tr", [_c("td")]),
                          _c("tr", [
                            _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                              _vm._v("New Submission Date:")
                            ]),
                            _c(
                              "td",
                              [
                                _c("datepicker", {
                                  attrs: {
                                    disabledDates: _vm.disabledProcDates
                                  },
                                  on: {
                                    input: function($event) {
                                      _vm.dateChanged = true
                                    }
                                  },
                                  model: {
                                    value: _vm.newSubmissionDate,
                                    callback: function($$v) {
                                      _vm.newSubmissionDate = $$v
                                    },
                                    expression: "newSubmissionDate"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "b-tab",
                        { attrs: { title: "Detail" } },
                        [
                          _c(
                            "vue-good-table",
                            {
                              ref: "table",
                              attrs: {
                                columns: _vm.columns,
                                rows: _vm.tableData,
                                lineNumbers: true,
                                totalRows: _vm.totalRecords,
                                "search-options": {
                                  enabled: true
                                },
                                paginationOptions: _vm.paginationOptions,
                                "sort-options": _vm.sortOptions,
                                isLoading: _vm.isTableLoading,
                                styleClass: "vgt-table striped bordered",
                                "row-style-class": _vm.setRowStyle
                              },
                              on: {
                                "on-page-change": _vm.onPageChange,
                                "on-sort-change": _vm.onSortChange,
                                "on-column-filter": _vm.onColumnFilter,
                                "on-per-page-change": _vm.onPerPageChange,
                                "on-search": _vm.onSearch,
                                "on-cell-click": _vm.onCellClick,
                                "on-row-click": _vm.onRowClick,
                                "update:isLoading": function($event) {
                                  _vm.isTableLoading = $event
                                },
                                "update:is-loading": function($event) {
                                  _vm.isTableLoading = $event
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "table-row",
                                  fn: function(props) {
                                    return [
                                      _vm.selectedRow &&
                                      _vm.selectedRow.paymentComposite ===
                                        props.row.paymentComposite
                                        ? _c("span", [
                                            props.column.field === "amount"
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("currency-input", {
                                                      staticStyle: {
                                                        width: "100px"
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        currency: "GBP",
                                                        locale: "en"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.amountChanged(
                                                            props
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: props.row.amount,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.row,
                                                            "amount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.row.amount"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : props.column.field ===
                                                "processingDate"
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("datepicker", {
                                                      attrs: {
                                                        disabledDates:
                                                          _vm.disabledDates
                                                      },
                                                      on: {
                                                        selected:
                                                          _vm.processingDateChanged
                                                      },
                                                      model: {
                                                        value:
                                                          props.row
                                                            .processingDate,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.row,
                                                            "processingDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.row.processingDate"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : props.column.field ===
                                                "viewPayerBtn"
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "editPayer",
                                                            params: {
                                                              ukPayerId:
                                                                props.row
                                                                  .ukPayerId
                                                            }
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("View Payer")
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.formattedRow[
                                                          props.column.field
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                          ])
                                        : _c("span", [
                                            props.column.field == "viewBtn"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        color: "blue"
                                                      }
                                                    },
                                                    [_vm._v("View")]
                                                  )
                                                ])
                                              : props.column.field ===
                                                "viewPayerBtn"
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "editPayer",
                                                            params: {
                                                              ukPayerId:
                                                                props.row
                                                                  .ukPayerId
                                                            }
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("View Payer")
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.formattedRow[
                                                          props.column.field
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                          ])
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "emptystate" },
                                  slot: "emptystate"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vgt-center-align vgt-text-disabled"
                                    },
                                    [_vm._v(" No Flagged Payments Found ")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "table-actions" },
                                  slot: "table-actions"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value: "Clear filters",
                                          expression: "'Clear filters'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      staticClass: "btn btn-link",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.clearTableFilters($event)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "fa-stack",
                                          staticStyle: { "font-size": "10px" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-filter fa-stack-1x dimmedIcon"
                                          }),
                                          _c("i", {
                                            staticClass:
                                              "fa fa-ban fa-stack-2x dimmedIcon"
                                          })
                                        ]
                                      ),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Clear filters")
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "Refresh the data in the table",
                                          expression:
                                            "'Refresh the data in the table'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      attrs: {
                                        variant: "link",
                                        disabled: _vm.isLoading
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.load($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-sync pointer dimmedIcon"
                                      }),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Refresh Table")
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "Export the contents of the table",
                                          expression:
                                            "'Export the contents of the table'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      attrs: {
                                        variant: "link",
                                        disabled: _vm.isLoading
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.exportTable($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-share-square pointer dimmedIcon"
                                      }),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Export Table")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "idb-block-footer mt-3" },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { type: "link", variant: "outline-secondary" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.back($event)
                        }
                      }
                    },
                    [_vm._v(" View Submissions ")]
                  ),
                  _vm.actionId && _vm.actionId !== null
                    ? _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeAction($event)
                            }
                          }
                        },
                        [_vm._v("Clear Action")]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2",
                      attrs: { variant: "info" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.ignoreSubmissionWarning($event)
                        }
                      }
                    },
                    [_vm._v("Ignore")]
                  ),
                  _vm.hasChanges
                    ? _c(
                        "b-btn",
                        {
                          staticClass: "float-right ml-2",
                          attrs: {
                            type: "link",
                            variant: "primary",
                            disabled: _vm.isLoading
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.saveSubmissionChanges($event)
                            }
                          }
                        },
                        [_vm._v(" Save Changes ")]
                      )
                    : _vm._e(),
                  _vm.dateChanged
                    ? _c(
                        "b-btn",
                        {
                          staticClass: "float-right ml-2",
                          attrs: {
                            type: "link",
                            variant: "primary",
                            disabled: _vm.isLoading
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.updateBulkSubmission($event)
                            }
                          }
                        },
                        [_vm._v(" Update Submission Date ")]
                      )
                    : _vm._e(),
                  _vm.hasChanges
                    ? _c(
                        "b-btn",
                        {
                          staticClass: "float-right mr-2",
                          attrs: {
                            type: "link",
                            variant: "outline-warning",
                            disabled: _vm.isLoading
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.cancelSubmissionChanges($event)
                            }
                          }
                        },
                        [_vm._v(" Cancel Changes ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }