<template>
  <div id="BureauUsageReports">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Bureau Usage Report over date range
              <span class="pull-right">
                <a href="#" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group col-md-12">
              <div class="col-md-3">
                From:
                <vuejsDatepicker
                  ref="dateFrom"
                  name="dateFrom"
                  id="dateFrom"
                  v-model="dateFrom"
                  format="dd/MM/yyyy"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  v-on:selected="fromDateSet"
                  @focusin.native="dateOnfocus"
                  :monday-first="true"
                  :disabled="disableDatePicker"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-3">
                To:
                <vuejsDatepicker
                  ref="dateTo"
                  name="dateTo"
                  id="dateTo"
                  v-model="dateTo"
                  format="dd/MM/yyyy"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  v-on:selected="toDateSet"
                  @focusin.native="dateOnfocus"
                  :monday-first="true"
                  :disabled="disableDatePicker"
                ></vuejsDatepicker>
              </div>

              <div class="col-md-3">
                <p-check
                  class="p-default p-curve"
                  id="allDates"
                  @change="allDatesSet()"
                  v-model="allDates"
                >For all time</p-check>
              </div>
            </div>
            <button class="btn btn-primary mr-2" @click="loadData">Load</button>
          </div>
          <hr />
          <!-- Figures -->
          <div class="idb-block-content" v-if="showResults" id="results">
            <div class="row form-group col-md-12">
              <div class="col-md-12">
                <h3>Data for between {{usage.lowestDateStr}} - {{usage.highestDateStr}}</h3>
              </div>
              <div class="col-md-4">
                <h4>Overall Submission Data</h4>
                <span>
                  <b>Total Submissions:</b>
                  {{ usage.totalSubmissions }}
                </span>
                <br />
                <b>Total Files Submitted:</b>
                {{usage.totalFilesSubmitted}}
                <br />

                <b>Total Number of Payments:</b>
                {{usage.totalPayments}}
                <br />

                <b>Total Individual SUNS:</b>
                {{usage.totalSUNS}}
                <br />

                <b>Total BACS Submissions:</b>
                {{usage.paymentNetworkBACS}}
                <br />

                <b>Total FPS Submissions:</b>
                {{usage.paymentNetworkFPS}}
                <br />

                <b>Total Automated Submissions:</b>
                {{usage.automatedSubmissions}}
                <br />

                <b>Total Manual Submissions:</b>
                {{usage.manualSubmissions}}
                <br />
              </div>
              <div class="col-md-4">
                <h4>Average Monthly Submission Data</h4>
                <b>Average Submissions:</b>
                {{usage.averageMonthlySubmissions}}
                <br />

                <b>Average Number of Files:</b>
                {{usage.averageMonthlyFiles}}
                <br />

                <b>Average Number of Payments:</b>
                {{usage.averageMonthlyPayments}}
                <br />

                <b>Average Individual SUNS:</b>
                {{usage.averageMonthlySUNS}}
                <br />
              </div>
              <div class="col-md-4">
                <h4>Average Weekly Submission Data</h4>
                <b>Average Submissions:</b>
                {{usage.averageWeeklySubmissions}}
                <br />

                <b>Average Number of Files:</b>
                {{usage.averageWeeklyFiles}}
                <br />

                <b>Average Number of Payments:</b>
                {{usage.averageWeeklyPayments}}
                <br />

                <b>Average Individual SUNS:</b>
                {{usage.averageWeeklySUNS}}
              </div>
            </div>

            <hr />
            <div>
              <div class="row form-group col-md-12">
                <div class="col-md-6">
                  <h3>SUN Usage</h3>
                  <bar-chart :chart-data="chartData" :options="chartOptions"></bar-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import BarChart from '@/Assets/Components/Charts/BarChart.js'
import bacsMixin from '@/Lib/BacsMixin.js'
export default {
  mixins: [Utility, tableFilterMixin, bacsMixin],
  components: {
    VueSelect, BarChart
  },
  data () {
    return {
      // options
      dateFrom: '',
      dateTo: '',
      allDates: false,
      disableDatePicker: false,
      usage: [],
      showResults: false,

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return value.substring(0, 6)
              }
            },
            gridLines: {
              display: false
            },
            categoryPercentage: 0.5,
            barPercentage: 0.8
          }]
        }
      },
      chartData: {}

    }
  },

  async mounted () {
    var fromDateElement = document.getElementById('dateFrom')
    fromDateElement.style.backgroundColor = 'white'
    var toDateElement = document.getElementById('dateTo')
    toDateElement.style.backgroundColor = 'white'
    this.showResults = false
  },

  methods: {
    async loadData () {
      this.showResults = false
      if (!this.allDates && (this.dateFrom === undefined || this.dateTo === undefined)) { return }
      const data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        allDates: this.allDates
      }
 

      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/GetSubmissionsOverDateRange', data, { showload: true })
      this.showResults = true
      this.usage = response.data
      var labels = []
      var graphdata = []
      for (var i = 0; i < this.usage.bureauCustomerSUNDistribution.length; i++) {
        labels.push(this.usage.bureauCustomerSUNDistribution[i].key)
        graphdata.push(this.usage.bureauCustomerSUNDistribution[i].value)
      }

      this.chartData = {
        labels: labels,
        datasets: [{
          data: graphdata,
          label: 'SUN Usage',
          backgroundColor: '#456aa5'
        }]
      }
      this.showResults = true
      // this.renderChart(this.barChartData, this.barChartOptions)
    },
    allDatesSet () {
      // remove fromdate/todate options
      this.disableDatePicker = this.allDates
      var fromDateElement = document.getElementById('dateFrom')
      var toDateElement = document.getElementById('dateTo')
      if (this.allDates) {
        fromDateElement.style.backgroundColor = 'lightgrey'
        toDateElement.style.backgroundColor = 'lightgrey'
      } else {
        fromDateElement.style.backgroundColor = 'white'
        toDateElement.style.backgroundColor = 'white'
      }
    },
    fromDateSet () {
      this.allDates = false
      this.allDatesSet()
    },
    toDateSet () {
      this.allDates = false
      this.allDatesSet()
    }

  }
}

</script>
