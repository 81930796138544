<template>
  <div>
    <div v-if="!error" class="text-center">
      <div class="d-flex justify-content-between mb-2">
        <h4 class="m-0 w-100">Recently Added Payerss<i @click="populate" role="button" class="fas fa-sync-alt pull-right"></i></h4>
      </div>
      <b-row>
        <b-col>
          <h2 class="bigNumber">
            <router-link
              :to="{ name: 'managePayers', query: { groupid: group, status: 2 } }"
              :class="numberStyle(recentCount)"
            >{{numberFormatter(recentCount)}}</router-link>
          </h2>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>          
            {{recentCount}}
            <router-link
              :to="{ name: 'managePayers', query: { groupid: group, status: 2 } }"
            >({{percentTotal}}% of group)</router-link>          
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          {{groupName}}
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row>
        <b-col cols="8">
          Error getting
          <strong>Added Payers</strong>.
        </b-col>
        <b-col cols="3">
          <b-btn variant="primary" @click.prevent="populate">Refresh</b-btn>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { setTimeout, clearInterval } from 'timers'
export default {
  name: 'PayersAddedWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  computed: {
    windowSizeDays () {
      var option = this.widget.options.find(o => o.label === 'Number of days')
      if (option) {
        return option.value
      }
      return null
    },
    group () {
      var option = this.widget.options.find(o => o.label === 'Group')
      if (option) {
        return option.value
      } else {
        return null
      }
    },
    percentTotal () {
      let percent = this.recentCount / this.totalGroupSize * 100
      percent = +(percent.toFixed(2))
      return percent || 0
    }
  },
  methods: {
    numberStyle (number) {
      if (number < 1) {
        return 'good'
      } else if (number < 5) {
        return 'warning'
      } else {
        return 'flagged'
      }
    },
    numberFormatter (num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
      }
      return num
    },
    async populate () {
      try {
        const url =  this.group !== null ? `${process.env.VUE_APP_DDMS_API_URL}widget/addedpayers/${this.group}` : `${process.env.VUE_APP_DDMS_API_URL}widget/addedpayers/`
        const res = await axios.get(url, { params: { windowSizeDays: this.windowSizeDays, paygateid: this.$store.state.common.paygateId } })
        this.recentCount = res.data.recentCount
        this.allCount = res.data.allCount
        this.totalGroupSize = res.data.totalGroupSize
        this.groupName = this.group !== null ? res.data.groupName : 'All Groups'
        this.error = false
      } catch (e) {
        console.error(e)
        // window.clearInterval(this.intervalId)
        this.error = true
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    // window.clearInterval(this.intervalId)
    next();
  },
  data: function () {
    return {
      loading: true,
      error: false,
      recentCount: 0,
      allCount: 0,
      totalGroupSize: 0,
      customerGroups: [],
      intervalId: '',
      groupName: ''
    }
  },

  async mounted () {
    let ukddmsEnumType = 2
    try {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/dropdown/${this.$store.state.common.paygateId}/${ukddmsEnumType}`, { params: { paygateid: this.$store.state.common.paygateId } })
      this.customerGroups = response.data
      this.populate()
      // this.intervalId = setInterval(this.populate, 30000) // 30s
      // this.$once('hook:beforeDestroy', () => {
      //   window.clearInterval(this.intervalId)
      // })
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
<style scoped>
.bigNumber {
  font-size: 3rem !important;
  min-width: 80px;
  display: inline-block;
}
.warning {
  color: #ffcc00;
}
.good {
  color: #60bd68;
}
.zero {
  color: #000000;
}
.flagged {
  color: #f15854;
}
</style>
