var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [_vm._t("title", [_c("span", [_vm._v("No Title specified.")])])],
        2
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _vm._t("default", [
          _c("p", [_vm._v("Content has not been specified.")])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }