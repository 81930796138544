<template>
  <div id="alertsDiv">
    <vue-good-table
      ref="payerAlerts"
      :columns="columns"
      :rows="rows"
      @on-row-click="viewAlert"
      :pagination-options="{
          enabled: enablePagination,
          perPage: 10,
          dropdownAllowAll: false
        }"
        :search-options="{
          enabled: true
        }"
      styleClass="vgt-table striped bordered"
    >
    <template slot="loadingContent">
      <h1>Loading...</h1>
    </template>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'alertLevel'" class="alertContainer">
        <b-badge pill
          :variant="getSeverityClass(props.row.alertLevel)"
        >{{getMessage(props.row.alertLevel)}}</b-badge>
      </span>
      <span v-else>{{props.formattedRow[props.column.field]}}</span>
    </template>
    <div slot="table-actions">
      <b-button @click.prevent="reloadTable" class="" variant="link" title="Refresh Table" v-b-popover.hover.top.d500="'Refresh the data in the table'">
        <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
      </b-button>
      <b-button @click.prevent="exportTable" class="" variant="link" title="Export Table" v-b-popover.hover.top.d500="'Export the contents of the Logs table'">
        <i class="fa fa-share-square pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
      </b-button>
    </div>
    </vue-good-table>
  </div>
</template>
<script>
import axios from 'axios'
import papa from 'papaparse'
export default {
  props: {
    ukPayerId: String,
    reference: String
  },
  data () {
    return {
      rows: [],
      columns: [
        { label: 'Alert Level', field: 'alertLevel', tdClass: 'text-center', width: '120px' },
        { label: 'Title', field: 'title' },
        { label: 'Message Type', field: 'payerHistoryMessageType' },
        {
          label: 'Date',
          field: 'generationDate',
          formatFn: (value) => {
            let retVal = this.formatDate(new Date(value))
            return retVal
          },
          sortable: true,
          sortFn: (x, y) => {
            x = new Date(x)
            y = new Date(y)
            return x > y ? -1 : x < y ? 1 : 0
          }
        }
      ],
      enablePagination: true
    }
  },
  async mounted () {
    axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/alerts`, { params: { paygateid: this.$store.getters.selectedCustomer } }).then((response) => { this.rows = response.data })
  },
  methods: {
    viewAlert (e) {
      this.$router.push({ path: `/collections/payer/${this.ukPayerId}/history/${e.row.payerHistoryId}` })
    },
    buildGoodTableQuery () {
      const query = {}
      query.sort = this.serverParams.sort.field + ':' + this.serverParams.sort.type
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      return query
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'danger'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatDate (date) {
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${day}/${this.pad(monthIndex, 2)}/${year} ${this.pad(h, 2)}:${this.pad(m, 2)}:${this.pad(s, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    getMessage (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'High'
          break
        case 'Low':
          retVal = 'Low'
          break
        default:
          retVal = 'Info'
          break
      }
      return retVal
    },
    async printTable () {
      this.enablePagination = false
      await this.reloadTable()
      window.print()
      this.enablePagination = true
    },
    exportTable () {
      var csvString = papa.unparse(this.rows)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, this.reference + '-payer-alert.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = this.reference + '-payer-alert.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    async reloadTable () {
      await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/alerts`, { params: { paygateid: this.$store.getters.selectedCustomer } }).then((response) => { this.rows = response.data })
    }
  }
}
</script>
