import axios from 'axios'
import _ from 'lodash'
// default state
const state = {
  submissions: [],
  paymentDetails: {
    groupName: '',
    defaultContraNarrative: '',
    processingDate: new Date(),
    collectionDate: new Date(),
    flaggedPaymentCount: 0,
    detailsLoaded: false
  },
  flaggedPayments: [],
  originalData: [],
  totalPages: 0,
  totalItems: 0,
  submissionStatus: 0
}

// mutators
const mutations = {
  setSubmissions (state, submissions) {
    state.submissions = submissions
  },
  setFlagged (state, flaggedPayments) {
    state.flaggedPayments = flaggedPayments
  },
  setPaymentDetails (state, details) {
    state.paymentDetails = details
  },
  setDetailsLoaded (state, isLoaded) {
    state.paymentDetails.detailsLoaded = isLoaded
  },
  setOriginal (state, originalData) {
    state.originalData = _.cloneDeep(originalData)
  },
  resetSubmission (state) {
    state.flaggedPayments = _.cloneDeep(state.originalData)
  },
  setTotals (state, meta) {
    state.totalItems = meta.totalItems
    state.totalPages = meta.totalPages
  },
  updateSubmissionStatus (state, status) {
    state.submissionStatus = status
  }
}

// getters
const getters = {
  submissions (state) {
    return state.submissions
  },
  paymentDetails (state) {
    return state.paymentDetails
  },
  bacsUrl (state) {
    return process.env.VUE_APP_BACS_API_URL
  },
  submissionMetaData (state) {
    return { totalItems: state.totalItems, totalPages: state.totalPages }
  },
  collectionsSubmissionStatus (state) {
    return state.submissionStatus
  }
}

// actions
const actions = {
  loadFlagged: async ({ commit, rootState }, { serverParams, groupId }) => {
    var params = {
      params: {
        page: serverParams.page,
        perPage: serverParams.perPage,
        columnFilters: serverParams.columnFilters,
        sort: serverParams.sort,
        searchKey: serverParams.searchKey
      },
      showload: true
    }
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/${groupId}/flagged`, params)
    try {
      commit('setFlagged', response.data.data)
      commit('setTotals', response.data.meta)
    } catch (err) {
      console.error('error loading flagged submissions ', err)
    }
  },
  loadSubmission: async ({ commit, rootState }, { serverParams, groupId }) => {
    var params = {
      params: {
        page: serverParams.page,
        perPage: serverParams.perPage,
        columnFilters: serverParams.columnFilters,
        sort: serverParams.sort,
        searchKey: serverParams.searchKey
      },
      showload: true
    }
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/${groupId}/view`, params)
    try {
      commit('setFlagged', response.data.data)
      commit('setOriginal', response.data.data)
      commit('setTotals', response.data.meta)      
    } catch (err) {
      console.error('error loading flagged submissions ', err)
    }
  },
  loadSubmissionInvalidOnly: async ({ commit, rootState }, { serverParams, groupId }) => {    
    var params = {
      params: {
        page: serverParams.page,
        perPage: serverParams.perPage,
        columnFilters: serverParams.columnFilters,
        sort: serverParams.sort,
        searchKey: serverParams.searchKey
      },
      showload: true
    }
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/${groupId}/viewinvalid`, params)
    try {
      commit('setFlagged', response.data.data)
      commit('setOriginal', response.data.data)
      commit('setTotals', response.data.meta)
    } catch (err) {
      console.error('error loading flagged submissions ', err)
    }
  },
  loadAuddisSubmission: async ({ commit, rootState }, { serverParams, groupId }) => {
    var params = {
      params: {
        page: serverParams.page,
        perPage: serverParams.perPage,
        columnFilters: serverParams.columnFilters,
        sort: serverParams.sort,
        searchKey: serverParams.searchKey
      },
      showload: true
    }
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/${groupId}/viewauddis`, params)
      commit('setFlagged', response.data.data)
      commit('setOriginal', response.data.data)
      commit('setTotals', response.data.meta)
      return response
    } catch (err) {
      console.error('error loading flagged submissions ', err)
    }
  },
  loadSubmissions: async ({ commit, rootState }, serverParams) => {
    var params = {
      params: {
        page: serverParams.page,
        perPage: serverParams.perPage,
        columnFilters: serverParams.columnFilters,
        sort: serverParams.sort,
        searchKey: serverParams.searchKey
      },
      showload: true
    }
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/`, params)
    var retVal
    if (response) {
      commit('setSubmissions', response.data.data)
      commit('setTotals', response.data.meta)
      retVal = response.data.data
    }
    return retVal
  },
  processSubmission: async ({ commit, rootState }, sumbissionId) => {
    // let paygateId = rootState.common.paygateId
    axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/${sumbissionId}/process/`, {}, { showload: true })
      .then((response) => { commit('setSubmissions', response.data) })
      .catch((err) => { console.log('error loading submissions', err) })
  },
  validateProcessSubmission: async ({ commit, rootState }, submission) => {
    // let paygateId = rootState.common.paygateId
    axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/${submission.groupId}/validate/`, submission, { showload: true })
      .then((response) => { commit('setSubmissions', response.data) })
      .catch((err) => { console.log('error loading submissions', err) })
  },
  getPaymentDetails: async ({ commit, rootState }, groupId) => {
    let paygateId = rootState.common.paygateId
    commit('setDetailsLoaded', false)
    axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/${groupId}/details/`, { params: { paygateid: paygateId }, showload: true })
      .then((response) => {
        commit('setPaymentDetails', response.data)
        commit('setDetailsLoaded', true)
      })
      .catch((err) => { console.log('error getting payment details', err) })
  },
  postToPresubValidation: async ({ commit }, submissionData) => {
    var response = await axios.post(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/runPresubValidation/`, submissionData, { showload: true })
      .catch((err) => { console.log('error sending to validation', err) })
    return response
  },
  getScheduleProcessingDates: async ({ commit, rootState }) => {
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/getprocessingdates`, { showload: true })
    return response.data
  },
  updateSubmission: async ({ commit, rootState }, collections) => {
    var changedAmounts = []
    for (var i = 0; i < collections.length; i++) {
      var payment = collections[i]
      if (payment.amountChanged || payment.dateChanged) {
        changedAmounts.push(payment)
      }
    }
    console.log(changedAmounts)
    var response = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}submissions`, changedAmounts, { showload: true })
    return response.data
  },
  resetData: async ({ commit, rootState }) => {
    commit('resetSubmission')
  },
  cancelCollectionsSubmission: async ({ commit, dispatch }, submissionId) => {
    try {
      await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/${submissionId}/cancel`, {}, { showload: true })
      await dispatch('updateCurrentCollectionsSubmissionCount')
    } catch (err) {
      console.log('Error restoring ddms submission')
    }
  },
  setCollectionsSubmissionDownloaded: async ({ commit, dispatch }, submissionId) => {
    try {
      await axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/${submissionId}/downloaded`, {}, { showload: true })
      await dispatch('updateCurrentCollectionsSubmissionCount')
    } catch (err) {
      console.log('Error flagging submission as downloaded')
    }
  },
  getSubmissionStatus: async ({ commit, dispatch }, submissionId) => {
    try {
      let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}submissions/${submissionId}/status`, { showload: true })
      if (response) {
        commit('updateSubmissionStatus', response.data)
        return response.data
      }
    } catch (err) {
      console.log('Error getting the submission status')
    }
  }
}

export default { state, getters, actions, mutations }
