var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          !_vm.isLoading && _vm.checkValidation() && _vm.validate()
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                { attrs: { id: "title-of-block-check" } },
                [
                  _vm._v(" UK Bank Account Validation "),
                  _c("help-icon", {
                    attrs: { docPath: "/validate/ukbankaccount/" }
                  }),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group col-md-4 col-sm-12",
                    class: {
                      valid: _vm.sortCodeValid,
                      invalid: _vm.sortCodeInvalid
                    }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "label-control required",
                        attrs: { for: "sortCodeValidateInput" }
                      },
                      [_vm._v("Sort Code")]
                    ),
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("the-mask", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          ref: "sortCode",
                          staticClass: "form-control",
                          attrs: {
                            id: "sortCodeValidateInput",
                            type: "text",
                            mask: ["##-##-##"],
                            guide: true
                          },
                          on: { input: _vm.sortCodeInput },
                          model: {
                            value: _vm.bankAccount.sortCode,
                            callback: function($$v) {
                              _vm.$set(_vm.bankAccount, "sortCode", $$v)
                            },
                            expression: "bankAccount.sortCode"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "38px",
                              "margin-left": "20px",
                              float: "right"
                            }
                          },
                          [
                            _vm.sortCodeValid
                              ? _c("span", {
                                  staticClass: "fa fa-check-circle",
                                  staticStyle: {
                                    "font-size": "200%",
                                    color: "green"
                                  }
                                })
                              : _vm._e(),
                            _vm.sortCodeInvalid
                              ? _c("span", {
                                  staticClass: "fa fa-times-circle",
                                  staticStyle: {
                                    "font-size": "200%",
                                    color: "red"
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.$v.bankAccount.sortCode.$error
                      ? _c("span", [
                          !_vm.$v.bankAccount.sortCode.required
                            ? _c(
                                "small",
                                { staticClass: "form-text text-danger small" },
                                [_vm._v("A Sort Code is required")]
                              )
                            : _vm._e(),
                          !_vm.$v.bankAccount.sortCode.minLength ||
                          !_vm.$v.bankAccount.sortCode.maxLength
                            ? _c(
                                "small",
                                { staticClass: "form-text text-danger small" },
                                [
                                  _vm._v(
                                    "A Sort Code number is required to be 6 numbers long"
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group col-md-7 col-sm-12",
                    class: {
                      valid: _vm.accountNumberValid,
                      invalid: _vm.accountNumberInvalid
                    }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "label-control",
                        attrs: { for: "accountNumberValidationInput" }
                      },
                      [_vm._v("Account Number")]
                    ),
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("the-mask", {
                          ref: "accountNumber",
                          staticClass: "form-control",
                          attrs: {
                            id: "accountNumberValidationInput",
                            type: "text",
                            mask: ["########"],
                            guide: false
                          },
                          on: { input: _vm.accountNumberInput },
                          model: {
                            value: _vm.bankAccount.accountNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.bankAccount, "accountNumber", $$v)
                            },
                            expression: "bankAccount.accountNumber"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "38px",
                              "margin-left": "20px",
                              float: "right"
                            }
                          },
                          [
                            _vm.accountNumberValid
                              ? _c("span", {
                                  staticClass: "fa fa-check-circle",
                                  staticStyle: {
                                    "font-size": "200%",
                                    color: "green"
                                  }
                                })
                              : _vm._e(),
                            _vm.accountNumberInvalid
                              ? _c("span", {
                                  staticClass: "fa fa-times-circle",
                                  staticStyle: {
                                    "font-size": "200%",
                                    color: "red"
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.$v.bankAccount.accountNumber.$error
                      ? _c("span", [
                          !_vm.$v.bankAccount.accountNumber.minLength ||
                          !_vm.$v.bankAccount.accountNumber.maxLength
                            ? _c(
                                "small",
                                { staticClass: "form-text text-danger small" },
                                [
                                  _vm._v(
                                    "An Account number is required to be 8 numbers long"
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col-md-8 col-sm-12" }, [
                  _vm.bankAccount.result != null
                    ? _c("span", [
                        _vm.bankAccount.result.valid
                          ? _c(
                              "small",
                              { staticClass: "form-text text-success small" },
                              [_vm._v("The Bank Account is valid!")]
                            )
                          : _vm._e(),
                        !_vm.bankAccount.result.valid &&
                        (!_vm.bankAccount.result.institutionName ||
                          _vm.bankAccount.accountNumber.length === 8)
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger small" },
                              [_vm._v(_vm._s(_vm.bankAccount.result.errorText))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: _vm.isLoading }
                },
                [_vm._v("Validate")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary ml-3",
                  attrs: { type: "button", disabled: _vm.isLoading },
                  on: { click: _vm.clear }
                },
                [_vm._v("Clear")]
              )
            ])
          ])
        ])
      ]),
      _vm.bankAccount.result != null && _vm.showBankDetails
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "idb-block" }, [
                _vm._m(0),
                _c("div", { staticClass: "idb-block-content" }, [
                  _c(
                    "form",
                    {
                      staticClass: "col-md-12",
                      attrs: { novalidate: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Bank")
                        ]),
                        _c(
                          "span",
                          { staticClass: "form-control-static col-auto" },
                          [
                            _vm._v(
                              _vm._s(_vm.bankAccount.result.institutionName)
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Branch")
                        ]),
                        _c(
                          "span",
                          { staticClass: "form-control-static col-auto" },
                          [_vm._v(_vm._s(_vm.bankAccount.result.branchTitle))]
                        )
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Address")
                        ]),
                        _c("address", {
                          staticClass: "form-control-static col-auto",
                          domProps: { innerHTML: _vm._s(_vm.address) }
                        })
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.bankAccount.result.bic,
                              expression: "bankAccount.result.bic"
                            }
                          ],
                          staticClass: "form-group row"
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "label-control col-md-3" },
                            [_vm._v("BIC")]
                          ),
                          _c(
                            "span",
                            { staticClass: "form-control-static col-auto" },
                            [_vm._v(_vm._s(_vm.bankAccount.result.bic))]
                          )
                        ]
                      ),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Account Code")
                        ]),
                        _c(
                          "span",
                          { staticClass: "form-control-static col-auto" },
                          [_vm._v(_vm._s(_vm.bankAccount.result.accountCode))]
                        )
                      ]),
                      _vm.subBranches.length > 1
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("span", [
                                  _vm._v(
                                    "This sortcode links to multiple sub-branches"
                                  )
                                ]),
                                _c("vue-good-table", {
                                  attrs: {
                                    columns: _vm.subBranchColumns,
                                    rows: this.subBranches,
                                    styleClass: "vgt-table striped condensed"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.bankAccount.result != null && _vm.showBankDetails
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _vm.bacsDetails
                ? _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                    _c("div", { staticClass: "idb-block validate-big-block" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c(
                          "form",
                          {
                            staticClass: "col-md-12",
                            attrs: { novalidate: "" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "form-group row" }, [
                              _vm.bacsDetails.status == "M"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-10" },
                                    [
                                      _vm._v(
                                        "Bank office accepts Bacs payments."
                                      )
                                    ]
                                  )
                                : _vm.bacsDetails.status == "A"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-10" },
                                    [
                                      _vm._v(
                                        "Bank office accepts Bacs payments (sponsored)."
                                      )
                                    ]
                                  )
                                : _vm.bacsDetails.status == "N"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-10" },
                                    [
                                      _vm._v(
                                        "Bank office does not accept Bacs payments."
                                      )
                                    ]
                                  )
                                : _c(
                                    "label",
                                    { staticClass: "label-control col-md-10" },
                                    [_vm._v("No Data Available.")]
                                  )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-2" },
                                [
                                  _c("span", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-check text-success": !_vm.bacsDetails
                                        .transactionsDisallowedAu,
                                      "fa-times text-danger":
                                        _vm.bacsDetails.transactionsDisallowedAu
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "form-control-static col-md-10"
                                },
                                [_vm._v("Direct Debits")]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-2" },
                                [
                                  _c("span", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-check text-success": !_vm.bacsDetails
                                        .transactionsDisallowedBs,
                                      "fa-times text-danger":
                                        _vm.bacsDetails.transactionsDisallowedBs
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "form-control-static col-md-10"
                                },
                                [_vm._v("Credits")]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-2" },
                                [
                                  _c("span", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-check text-success": !_vm.bacsDetails
                                        .transactionsDisallowedCr,
                                      "fa-times text-danger":
                                        _vm.bacsDetails.transactionsDisallowedCr
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "form-control-static col-md-10"
                                },
                                [_vm._v("Claims for Unpaid Cheques")]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-2" },
                                [
                                  _c("span", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-check text-success": !_vm.bacsDetails
                                        .transactionsDisallowedCu,
                                      "fa-times text-danger":
                                        _vm.bacsDetails.transactionsDisallowedCu
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "form-control-static col-md-10"
                                },
                                [_vm._v("Interest Payments")]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-2" },
                                [
                                  _c("span", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-check text-success": !_vm.bacsDetails
                                        .transactionsDisallowedDr,
                                      "fa-times text-danger":
                                        _vm.bacsDetails.transactionsDisallowedDr
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "form-control-static col-md-10"
                                },
                                [_vm._v("Dividend Payments")]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-2" },
                                [
                                  _c("span", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-check text-success": !_vm.bacsDetails
                                        .transactionsDisallowedDV,
                                      "fa-times text-danger":
                                        _vm.bacsDetails.transactionsDisallowedDV
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "form-control-static col-md-10"
                                },
                                [_vm._v("Direct Debit Instructions")]
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.fpsDetails
                ? _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                    _c("div", { staticClass: "idb-block validate-big-block" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c(
                          "form",
                          {
                            staticClass: "col-md-12",
                            attrs: { novalidate: "" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "form-group row" }, [
                              _vm.fpsDetails.status == "M"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office is an FPS Member - Accepts Faster Payments."
                                      )
                                    ]
                                  )
                                : _vm.fpsDetails.status == "A"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office is an Agency Bank - Accepts Faster Payments."
                                      )
                                    ]
                                  )
                                : _vm.fpsDetails.status == "N"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office does not accept Faster Payments."
                                      )
                                    ]
                                  )
                                : _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office does not accept Faster Payments."
                                      )
                                    ]
                                  )
                            ]),
                            _vm.fpsDetails.fpsRedirectionFromFlag == "R"
                              ? _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "The bank office is set as the redirection sorting code for one or more bank offices"
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Handling Bank")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [_vm._v(_vm._s(_vm.handlingBank))]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Settlement Bank")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.fpsDetails.settlementBankName)
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Date Last Changed")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.fpsDetails.effectiveDateOfLastChange
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Date Closed")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.fpsDetails.dateClosedInFps
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Redirection Sortcode")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.fpsDetails.redirectToSortingCode)
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.chapsDetails
                ? _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "idb-block validate-big-block" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c(
                          "form",
                          {
                            staticClass: "col-md-12",
                            attrs: { novalidate: "" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "form-group row" }, [
                              _vm.chapsDetails.status == "D"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office accepts Chaps Sterling payments. (Direct)."
                                      )
                                    ]
                                  )
                                : _vm.chapsDetails.status == "I"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office accepts Chaps Sterling payments. (Indirect)."
                                      )
                                    ]
                                  )
                                : _vm.chapsDetails.status == "N"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office does not accept Chaps Sterling payments."
                                      )
                                    ]
                                  )
                                : _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [_vm._v("No Data Available.")]
                                  )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("CHAPS Sterling ID")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.chapsDetails.settlementMember)
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Routing BIC")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.chapsDetails.routing1) +
                                      " " +
                                      _vm._s(_vm.chapsDetails.routing2)
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Date last changed")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.chapsDetails
                                          .effectiveDateOfLastChange
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Date closed")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.chapsDetails.dateClosedIn
                                      )
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.cccDetails
                ? _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "idb-block validate-big-block" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "idb-block-content" }, [
                        _c(
                          "form",
                          {
                            staticClass: "col-md-12",
                            attrs: { novalidate: "" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "form-group row" }, [
                              _vm.cccDetails.status == "M"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Owning bank of bank office is a C&CC scheme member."
                                      )
                                    ]
                                  )
                                : _vm.cccDetails.status == "F"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [_vm._v("Bank office is a full agency.")]
                                  )
                                : _vm.cccDetails.status == "D"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [_vm._v("Bank office is a debit agency.")]
                                  )
                                : _vm.cccDetails.status == "N"
                                ? _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [
                                      _vm._v(
                                        "Bank office does not accept C&CC payments."
                                      )
                                    ]
                                  )
                                : _c(
                                    "label",
                                    { staticClass: "label-control col-md-12" },
                                    [_vm._v("No Data Available.")]
                                  )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Settlement Bank")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [_vm._v(_vm._s(_vm.cccDetails.settlementBank))]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Debt Agency Sortcode")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.cccDetails.debitAgencySortingCode
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Date last changed")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.cccDetails.effectiveDateOfLastChange
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "label-control col-md-6 col-lg-4 col-xl-3"
                                },
                                [_vm._v("Date closed")]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-control-static col-auto" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.cccDetails.dateClosedInCcc
                                      )
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { attrs: { id: "title-of-block-check" } }, [
        _vm._v("Bank Details")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("img", {
        staticClass: "bank-logo",
        attrs: { src: "/img/bankAccountLogos/bacs.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("img", {
        staticClass: "bank-logo",
        attrs: { src: "/img/bankAccountLogos/fps.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("img", {
        staticClass: "bank-logo",
        attrs: { src: "/img/bankAccountLogos/chaps.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("img", {
        staticClass: "img-responsive bank-logo",
        attrs: { src: "/img/bankAccountLogos/ccc.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }