<template>
  <div id="LicensingTableTests">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2 class="text-danger">Bureau Licensing Table Test</h2>
      </div>
      <div class="idb-block-content">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Bureau License Testing</h2>
          </div>
          <div class="idb-block-content">
            <h2>Hello</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import BarChart from '@/Assets/Components/Charts/BarChart.js'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [Utility, tableFilterMixin, bacsMixin],
  components: {
    VueSelect, BarChart
  },
  data () {
    return {
      groups: [],
      groupid: ''
    }
  },
  methods: {}

}



</script>