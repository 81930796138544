<template>
  <div>
    <div class="idb-block no-print">
      <div class="idb-block-title">
        <h2>
          System Report
          <help-icon docPath="/administration/reports/systemreport/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <div class="form-row">
          <div class="col form-group">
            <label>Users</label>
            <vue-select multiple v-model="selectedUsers" :options="users" :closeOnSelect="false"></vue-select>
          </div>
          <div class="col form-group">
            <label>Groups</label>
            <vue-select multiple v-model="selectedGroups" :options="groups" :closeOnSelect="false"></vue-select>
          </div>
          <div class="col form-group">
            <label>Roles</label>
            <vue-select multiple v-model="selectedRoles" :options="roles" :closeOnSelect="false"></vue-select>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <button class="btn btn-primary" @click="submit" :disabled="isLoading || !anySelected">
          <i class="glyphicon ti-pie-chart rpad"></i>Generate Report
        </button>

        <div class="ml-3 btn-group" v-if="dataLoaded">
          <button
            type="button"
            class="btn btn-success dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="isLoading"
          >Export as</button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#" @click="saveAsJson">JSON</a>
          </div>
        </div>

        <button
          type="button"
          class="btn btn-outline-secondary ml-3 float-right"
          @click="clear"
          :disabled="isLoading"
        >
          <i class="glyphicon ti-na rpad"></i>Clear
        </button>
      </div>
    </div>
    <div ref="report" id="pdf-wrapper" style="visibility: visible;">
      <div v-if="printing">
        <reporting-header :title="'System Report'"></reporting-header>
      </div>
      <group-config-report :groups="reportGroups" :licenceSettings="licenceSettings"></group-config-report>
      <user-config-report :users="reportUsers"></user-config-report>
      <role-config-report :roles="reportRoles"></role-config-report>
      <div v-if="printing">
        <reporting-footer></reporting-footer>
      </div>
    </div>
  </div>
</template>

<script>
// Third party
import axios from 'axios'
import VueSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { saveAs } from 'file-saver'

// Reporting parts
import UserConfigReport from './UserConfigReport'
import GroupConfigReport from './Group/GroupConfigReport'
import RoleConfigReport from './RoleConfigReport'
import ReportingHeader from '@/Assets/Components/Reporting/ReportingHeader'
import ReportingFooter from '@/Assets/Components/Reporting/ReportingFooter'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [loading],
  name: 'FullConfigReport',
  components: {
    VueSelect,
    UserConfigReport,
    GroupConfigReport,
    RoleConfigReport,
    ReportingHeader,
    ReportingFooter
  },
  computed: {
    dataLoaded () {
      return this.reportUsers.some(a => true) || this.reportGroups.some(a => true) || this.reportRoles.some(a => true)
    },
    anySelected () {
      return this.selectedUsers.some(a => true) || this.selectedGroups.some(a => true) || this.selectedRoles.some(a => true)
    },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      printing: false,
      users: [],
      selectedUsers: [],
      reportUsers: [],

      groups: [],
      selectedGroups: [],
      reportGroups: [],

      roles: [],
      selectedRoles: [],
      reportRoles: [],

      licenceSettings: {}

    }
  },
  async created () {
    await this.load()

    window.onbeforeprint = () => {
      this.printing = true
    }

    window.onafterprint = () => {
      this.printing = false
    }
  },
  methods: {
    async load () {
      // Only place I'm not calling the store

      try {
        this.$Progress.start()
        var results = await Promise.all([
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}users/UserSelect`, { showerror: true, errormessage: 'Failed to get users' }),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.$store.state.common.paygateId}`, { showerror: true, errormessage: 'Failed to get groups' }),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}role/Dropdown`, { showerror: true, errormessage: 'Failed to get roles' })
        ])

        this.users = results[0].data
        this.users.splice(0, 0, { label: 'All', value: null })

        this.groups = results[1].data
        this.groups.splice(0, 0, { label: 'All', value: null })

        this.roles = results[2].data
        this.roles.splice(0, 0, { label: 'All', value: null })
      } catch {
        this.$Progress.fail()
      } finally {
        this.$Progress.finish()
      }
    },
    async submit () {
      this.reportUsers = []
      this.reportGroups = []
      this.reportRoles = []
      if (!this.anySelected) {
        this.$toastr.w('You must select at least one user, group or role', 'Nothing Selected')
        return
      }

      try {
        this.$Progress.start()

        var results = await Promise.allSettled([
          this.selectedUsers.some(a => true) ? axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Configuration/User`, this.selectedUsers, { showerror: true, errormessage: 'Failed to get users' }) : null,
          this.selectedGroups.some(a => true) ? axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Configuration/Group`, this.selectedGroups, { showerror: true, errormessage: 'Failed to get groups' }) : null,
          this.selectedRoles.some(a => true) ? axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Configuration/Role`, this.selectedRoles, { showerror: true, errormessage: 'Failed to get roles' }) : null,
          axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showerror: true, errormessage: 'Failed to get licence' })
        ])

        if (results[0].status === 'fulfilled' && results[0].value !== null) {
          this.reportUsers = results[0].value.data
        }

        if (results[1].status === 'fulfilled' && results[1].value !== null) {
          this.reportGroups = results[1].value.data

          this.reportGroups.forEach(rg => {
            if (rg.details.tokenType === 'Hsm') {
              rg.details.tokenType = 'HSM'
            }
          })
        }
        if (results[2].status === 'fulfilled' && results[2].value !== null) {
          this.reportRoles = results[2].value.data
        }

        this.licenceSettings = results[3].value.data
      } catch {
        this.$Progress.fail()
      } finally {
        this.$Progress.finish()
      }
    },
    async saveAsJson () {
      try {
        this.$Progress.start()
        const now = new Date()
        const json = {
          reportDate: now,
          users: this.reportUsers,
          groups: this.reportGroups,
          roles: this.reportRoles
        }
        var file = new Blob([JSON.stringify(json)], { type: 'text/plain' })
        saveAs(file, `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-configuration-report.json`)
      } catch {
        this.$Progress.fail()
      } finally {
        this.$Progress.finish()
      }
    },
    clear () {
      this.selectedUsers = []
      this.selectedGroups = []
      this.selectedRoles = []
    }
  }
}
</script>

<style lang="scss" scoped>
#pdf-wrapper {
  visibility: visible;
}
</style>
