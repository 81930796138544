<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/messaging/messagetemplates/" />Letter Designer</h2>
    </div>
    <div class="idb-block-content">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group>
            <label for="letterTemplateName">
              <strong>Template Name</strong>
            </label>
            <b-form-input
              class
              id="letterTemplateName"
              v-model.trim="templateName"
              type="text"
              :disabled="loading"
              autocomplete="off"
              :state="templateNameStateValid"
              aria-describedby="letterTemplateNameHelp letterTemplateNameFeedback"
              placeholder="Letter Template Name"
              maxlength="50"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!templateNameStateValid" id="letterTemplateNameFeedback">
              The template name must only consist of the following character types:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, underscores "_" or dashes "-"</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-text id="smsTemplateNameHelp">The name of the template in paygate.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <br />
      <div class="container">
        <vue-dropzone
          id="drop1"
          ref="fileUploader"
          :options="dropzoneOptions"
          @vdropzone-success="afterSuccess"
          @vdropzone-file-added="fileAdded"
          @vdropzone-removed-file="fileRemoved"
          @vdropzone-sending="sendingEvent"
          :use-custom-slot="true"
          :accepted-file-types="dropzoneOptions.acceptedFiles"
          :max-file-size-in-m-b="200"
          :useCustomSlot="true">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{dropzoneOptions.title}}</h3>
            <div class="subtitle">{{dropzoneOptions.subtitle}}</div>
          </div>
        </vue-dropzone>
      </div>
      <br />
      <transition name="fade">
        <div class="container" v-if="internalTemplateId">
          <b-row>
            <b-col style="width: 105% !important">
              <div style="padding-top: 110%; position: relative;">
                <iframe
                  id="previewFrame"
                  frameborder="0"
                  ref="previewFrame"
                  height="100%"
                  width="100%"
                  style="position: absolute; top: 0; left: 0;"
                ></iframe>
              </div>
            </b-col>
          </b-row>
        </div>
      </transition>
    </div>
    <div class="idb-block-footer">
      <b-btn variant="primary" @click.prevent="saveClick" :disabled="isLoading || ((!templateid || templateid === null) && uploadCount === 0) || templateName === '' || !templateNameStateValid">Save</b-btn>&nbsp;
      <router-link :to="{ name: 'LetterDesigner' }" class="btn btn-outline-secondary">New Template</router-link>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
const hasNonAlphaNumOrSpaces = (str) => {
  if (!str) {
    return null
  }
  const nonAlphaNumsOrSpace = /[^A-Za-z0-9 -_]/
  return !nonAlphaNumsOrSpace.test(str)
}

export default {
  mixins: [loading],
  props: {
    templateid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      currentFile: null,
      loading: !!this.templateid,
      editMode: !this.templateid,
      internalTemplateId: this.templateid,
      uploaded: false,
      templateName: '',
      previewIsLoaded: false,
      wysiwygHtml: '',
      copyFromGroup: '',
      dropzoneOptions: {        
        url: this.templateid ? `${process.env.VUE_APP_DDMS_API_URL}designer/letter/${this.templateid}` : process.env.VUE_APP_DDMS_API_URL + 'designer/letter',
        withCredentials: true,
        autoProcessQueue: false,
        maxFilesize: 5,
        maxFiles: 1,
        acceptedFiles: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a .docx file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        title: 'Drop a .docx file here.',
        subtitle: 'Or click to select a file to upload. Press "Save" to upload the template.',        
        previewTemplate: this.previewTemplate()
      },
      uploadCount: 0
    }
  },
  methods: {
    fileAdded (file) {
      this.$refs['fileUploader'].setOption('headers', { 'Authorization': 'Bearer ' + this.bearerToken })
      this.uploadCount++
      this.$toastr.s('File ready to upload, press "Save" to upload the template.')
    },
    fileRemoved (file, error, xhr) {
      this.uploadCount--
    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.$store.state.common.paygateId)
      formData.append('groupId', this.groupId)
      formData.append('name', this.templateName)
      if (this.templateid) {
        formData.append('templateId', this.templateid)
      }
    },
    async saveClick () {
      const paygateId = this.$store.state.common.paygateId
      if (this.uploadCount > 0) {
        this.$refs.fileUploader.processQueue()
      } else {
        if (this.templateid) {
          await this.trySaveChanges(this.templateid, paygateId)
        } else {
          await this.tryCreate(paygateId)
        }
      }
    },
    async trySaveChanges (templateId, paygateId) {
      this.loading = true
      const file = this.currentFile
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', this.templateName)
      formData.append('templateId', templateId)
      try {
        const headers = { headers: { 'Content-Type': 'multipart/form-data' }, showload: true }
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/letter/${templateId}`, formData, headers)
        this.internalTemplateId = null
        this.internalTemplateId = response.data.templateId
        document.getElementById('previewFrame').src = this.previewUrl + '&tmstmp='+Date.now()
        // const iframe = this.$refs['previewFrame']
        // iframe.src = this.previewUrl + '&tmstmp='+Date.now()
        this.$toastr.s('Template updated successfully.', 'Template Updated')
        this.clearCurrentFile()
        this.uploaded = true
        // this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Failed to save template.', 'Error Occurred')
      } finally {
        this.loading = false
      }
    },
    async tryCreate (paygateId) {
      this.loading = true
      const file = this.currentFile
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', this.templateName)
      formData.append('templateId', this.templateid)
      this.previewIsLoaded = false
      try {
        const headers = { headers: { 'Content-Type': 'multipart/form-data' }, showload: true }
        const response = await axios.post(this.dropzoneUrl, formData, headers)
        this.$router.push({ name: 'SpecificLetterTemplate', params: { templateid: response.data.templateId } })
        // this.$router.push({DDMS', window.location + '/' + response.data.templateId)
        this.internalTemplateId = response.data.templateId
        this.$toastr.s('Template updated successfully.', 'Template Updated')
        this.clearCurrentFile()
        this.uploaded = true
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Failed to save template.', 'Error Occurred')
      } finally {
        this.loading = false
      }
      this.previewIsLoaded = true
    },
    async afterSuccess (file, response) {
      if (this.templateid == null) {
        this.$router.push({ name: 'SpecificLetterTemplate', params: { templateid: response.templateId } })
        // this.$router.push({DDMS', window.location + '/' + response.data.templateId)      
        this.internalTemplateId = response.templateId
      }
      this.$toastr.s('Template updated successfully.', 'Template Updated')
      this.clearCurrentFile()
      this.uploaded = true
      // const { pdfFile } = response
      const viewer = process.env.VUE_APP_PDFVIEWER_URL
      const api = process.env.VUE_APP_DDMS_URL
      //this.preview.src = `${viewer}?file=${api}/${this.templateId}?token=${this.bearerToken}`
      document.getElementById('previewFrame').src = this.previewUrl + '&tmstmp='+Date.now()
      this.previewIsLoaded = true
    },
    loadGroups (paygateId) {
      const groups = this.$store.getters.customerGroups
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      const groupsNull = (groups === undefined || groups === null)
      if (paygateIdNotDefault && groupsNull) {
        this.$store.dispatch('loadCustomerGroups', paygateId)
      }
    },
    async viewPreview () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/letter/preview/LetterTemplateTest.docx`, { responseType: 'blob', showload: true })
      window.open('~/viewer.html?file=' + response.data)
    },
    async loadTemplateInfo (templateId) {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/letter/${this.templateid}`)
      console.log(response)
      this.templateName = response.data.name
      const iframe = this.$refs['previewFrame']
      iframe.src = this.previewUrl +'&tmstmp='+Date.now()
    },
    clearCurrentFile () {
      this.$refs.fileUploader.removeAllFiles()
      this.uploadCount = 0
    },
    previewTemplate () {
        return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                    <img data-dz-thumbnail="">
                  </div>  
                  <div class="dz-details">
                    <div class="dz-size">
                      <span data-dz-size=""></span>
                    </div>
                    <div class="dz-filename">
                      <span data-dz-name="">
                      </span>
                    </div>  
                  </div>
                  <div class="dz-progress">
                    <span class="dz-upload" data-dz-uploadprogress=""></span>
                  </div> 
                  <div class="dz-error-message">
                    <span data-dz-errormessage=""></span>
                  </div>
                  <div class="dz-success-mark">
                  </div>
                  <div class="dz-error-mark"></div>
                  <a class="dropzone-remove-button-letters" data-dz-remove="">X</a>
                </div>`;
    }
  },
  async mounted () {
    this.bearerToken = await auth.getAccessToken()
    const onPaygateChange = async (paygateId) => {
      if (this.templateid) {
        this.loading = true
        try {
          await this.loadTemplateInfo(this.templateid)
          this.previewIsLoaded = true
          this.loading = false
        } catch (e) {
          this.$toastr.e('An error occurred loading the letter template information.', 'Error')
          // this.$router.push({ name: 'LetterDesigner' })
        }
      }
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    await onPaygateChange()
  },
  computed: {
    truncatedFileName () {
      return this.currentFile.name
      // if (this.currentFile.name.length > 15) {
      //   return this.currentFile.name.substring(0, 15) + '…'
      // } else {
      //   return this.currentFile.name
      // }
    },
    previewUrl () {
      // const paygateId = this.$store.state.common.paygateId
      if (this.internalTemplateId) {
        const pdfUrl = encodeURI(`${process.env.VUE_APP_DDMS_API_URL}designer/letter/preview/${this.internalTemplateId}`)
        var previewUrl = `${process.env.VUE_APP_PDFVIEWER_URL}?file=${pdfUrl}&token=${this.bearerToken}`
        const paygateId = this.$store.state.common.paygateId
        if (paygateId) {
          previewUrl += `&paygateId=${paygateId}`
        }
        return previewUrl+'&tmstmp='+Date.now()
      } else {
        return process.env.VUE_APP_PDFVIEWER_URL
      }
    },
    templateNameStateValid () {
      const str = this.templateName
      if (!str) {
        return null
      }
      const hasNonTemplateChar = /^[\w-]*$/g
      let test = hasNonTemplateChar.test(str)
      return test
    },
    group: {
      get () {
        return this.$store.state.payer.currentPayer.groupId
      },
      set (value) {
        this.$store.commit('updateGroup', value)
      }
    },
    customerGroups () {
      return this.$store.getters.customerGroups || []
    },
    fileReady () {
      return this.currentFile !== undefined && this.currentFile !== null && this.currentFile !== ''
    }
  }
}
</script>

<style>
.dropzone-remove-button-letters {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;  
  color: #ffffff !important;
}
  .dropzone-remove-button-letters:hover {    
    cursor: pointer !important;
  }
</style>
