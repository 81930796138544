var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "createBacsSubFromFile" } },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" Create BACS Submission From File "),
                  _c("help-icon", { attrs: { docPath: _vm.helpUrl } }),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("group-select", {
                      attrs: {
                        id: "groupSelector",
                        groups: _vm.groups,
                        clearable: false
                      },
                      on: {
                        input: function($event) {
                          return _vm.selectGroup()
                        }
                      },
                      model: {
                        value: _vm.selectedGroupId,
                        callback: function($$v) {
                          _vm.selectedGroupId = $$v
                        },
                        expression: "selectedGroupId"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary",
                      attrs: { disabled: this.groupBankLoaded === false },
                      on: { click: _vm.viewGroupBankDetails }
                    },
                    [_vm._v("View")]
                  )
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.submissionData.reference,
                        expression: "submissionData.reference"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "300px" },
                    attrs: {
                      name: "reference",
                      id: "reference",
                      maxlength: "150"
                    },
                    domProps: { value: _vm.submissionData.reference },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.submissionData,
                            "reference",
                            $event.target.value
                          )
                        },
                        function($event) {
                          return _vm.referenceChanged($event.target.value)
                        }
                      ]
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("vuejsDatepicker", {
                      ref: "paymentDate",
                      attrs: {
                        name: "paymentDate",
                        id: "paymentDate",
                        format: "dd/MM/yyyy",
                        "input-class": "form-control",
                        "bootstrap-styling": true,
                        disabledDates: _vm.disabledDates,
                        "monday-first": true,
                        disabled: _vm.disablePicker
                      },
                      on: { selected: _vm.paymentDateChanged },
                      nativeOn: {
                        focusin: function($event) {
                          return _vm.dateOnfocus($event)
                        }
                      },
                      model: {
                        value: _vm.paymentDate,
                        callback: function($$v) {
                          _vm.paymentDate = $$v
                        },
                        expression: "paymentDate"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-1" }),
                _vm._m(3),
                _c("div", { staticClass: "col-md-2" }, [
                  _vm._v(_vm._s(_vm.processingDate))
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(4),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.submissionData.contraNarrative,
                        expression: "submissionData.contraNarrative"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "300px" },
                    attrs: {
                      name: "contraNarrative",
                      id: "contraNarrative",
                      maxlength: "50"
                    },
                    domProps: { value: _vm.submissionData.contraNarrative },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.submissionData,
                          "contraNarrative",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("br"),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDropzone,
                      expression: "showDropzone"
                    }
                  ],
                  staticClass: "row form-group"
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-12", attrs: { id: "dzUpload" } },
                    [
                      _c(
                        "vue-dropzone",
                        {
                          ref: "fileUploader",
                          attrs: {
                            id: "drop1",
                            "use-custom-dropzone-options": true,
                            options: _vm.dropzoneOptions,
                            useCustomSlot: true
                          },
                          on: {
                            "vdropzone-success": _vm.afterSuccess,
                            "vdropzone-file-added": _vm.fileAdded
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dropzone-custom-content" },
                            [
                              _c(
                                "h3",
                                { staticClass: "dropzone-custom-title" },
                                [_vm._v("Drop files here to upload")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-link",
                                  attrs: { type: "button" }
                                },
                                [
                                  _vm._v(
                                    "...or click to select a file from your computer"
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.initRestrictedFileBrowser,
                      expression: "initRestrictedFileBrowser"
                    }
                  ],
                  staticClass: "row form-group"
                },
                [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.showRestrictedFileBrowser }
                      },
                      [_vm._v("Select Submission File")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFtpBrowser,
                      expression: "showFtpBrowser"
                    }
                  ],
                  staticClass: "row form-group"
                },
                [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.btnFtpFileBrowser }
                      },
                      [_vm._v("Select Submission File")]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _c("plugin", { on: { installCancelled: _vm.pluginNotInstalled } }),
      _vm.showFtpFileBrowser
        ? _c(
            "fileBrowser",
            {
              attrs: { groupId: _vm.selectedGroupId },
              on: {
                close: function($event) {
                  return _vm.closeFileBrowserPopup()
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Select Submission File")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 required" }, [
      _c("strong", [
        _c("label", { attrs: { for: "groupSelector" } }, [
          _vm._v("Select a Group")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 required" }, [
      _c("strong", [
        _c("label", { attrs: { for: "reference" } }, [
          _vm._v("Submission Reference")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 required" }, [
      _c("strong", [
        _c("label", { attrs: { for: "paymentDate" } }, [_vm._v("Payment Date")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("BACS Processing Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [
        _c("label", { attrs: { for: "contraNarrative" } }, [
          _vm._v("Contra Narrative")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }