var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row pb-16" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      _vm._l(_vm.labels, function(label, index) {
        return _c(
          "div",
          { key: index, staticClass: "form-check form-check-inline" },
          [
            _c("label", { staticClass: "form-check-label" }, [
              _c("input", {
                staticClass: "form-check-input",
                attrs: { type: "radio", name: _vm.name },
                domProps: { value: label, checked: label == _vm.value },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", $event.target.value)
                  }
                }
              }),
              _vm._v(" " + _vm._s(label) + " ")
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }