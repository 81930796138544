var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.templateView && !_vm.createMode
        ? _c(
            "b-row",
            { staticClass: "border-bottom pb-2 mb-2" },
            [
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "h3",
                  [
                    _vm._v(" " + _vm._s(_vm.plan.planTemplateName) + " "),
                    _vm.plan.paymentPlanId !==
                    "00000000-0000-0000-0000-000000000000"
                      ? _c(
                          "router-link",
                          {
                            staticClass: "float-right",
                            attrs: {
                              to: {
                                name: "PayerSchedule",
                                params: {
                                  id: _vm.ukPayerId,
                                  planId: _vm.plan.paymentPlanId
                                }
                              }
                            }
                          },
                          [
                            _c(
                              "b-button",
                              { attrs: { variant: "outline-secondary" } },
                              [
                                _c("i", { staticClass: "fa fa-calendar mr-2" }),
                                _vm._v("View Schedule ")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.templateView
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    "label-class": "required",
                    horizontal: "",
                    label: "Plan Template Name"
                  }
                },
                [
                  _c("b-form-input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "planTemplateNameInput",
                      disabled: !_vm.editable
                    },
                    on: {
                      input: function($event) {
                        return _vm.dirtyTouch(_vm.$v.plan.planTemplateName)
                      }
                    },
                    model: {
                      value: _vm.plan.planTemplateName,
                      callback: function($$v) {
                        _vm.$set(_vm.plan, "planTemplateName", $$v)
                      },
                      expression: "plan.planTemplateName"
                    }
                  }),
                  _vm.$v.plan.planTemplateName.$dirty
                    ? _c("b-col", [
                        !_vm.$v.plan.planTemplateName.required
                          ? _c(
                              "label",
                              {
                                staticClass: "text-danger small",
                                attrs: { id: "planTemplateNameRequiredLabel" }
                              },
                              [_vm._v("Plan Template Name required")]
                            )
                          : _vm._e(),
                        !_vm.$v.plan.planTemplateName.nameNotInUse
                          ? _c(
                              "label",
                              {
                                staticClass: "text-danger small",
                                attrs: { id: "planTemplateNameExistsLabel" }
                              },
                              [_vm._v("Plan Template Name already exists")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.templateView
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    label: "Plan Reference"
                  }
                },
                [
                  _c("b-form-input", {
                    staticClass: "form-control",
                    attrs: { id: "planReferenceInput", disabled: true },
                    on: {
                      change: function($event) {
                        return _vm.dirtyTouch(_vm.$v.plan.reference)
                      }
                    },
                    model: {
                      value: _vm.plan.reference,
                      callback: function($$v) {
                        _vm.$set(_vm.plan, "reference", $$v)
                      },
                      expression: "plan.reference"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.templateView
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    label: "Plan Status"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "planStatusSelect",
                      options: _vm.statae,
                      "text-field": "name",
                      "value-field": "value",
                      disabled:
                        !_vm.editable || _vm.planClosed || _vm.createMode
                    },
                    on: {
                      change: function($event) {
                        return _vm.dirtyTouch(_vm.$v.plan.planStatus)
                      }
                    },
                    model: {
                      value: _vm.plan.planStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.plan, "planStatus", _vm._n($$v))
                      },
                      expression: "plan.planStatus"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasBureau && !_vm.sharedTemplate && _vm.isBureauOrIndirect
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    label: "Bank Account"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "bankAccountSelect",
                      options: _vm.bankAccounts,
                      "text-field": "text",
                      "value-field": "value",
                      disabled: !_vm.editable || !_vm.isNew
                    },
                    model: {
                      value: _vm.plan.customerBankAccountId,
                      callback: function($$v) {
                        _vm.$set(_vm.plan, "customerBankAccountId", $$v)
                      },
                      expression: "plan.customerBankAccountId"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasBureau && !_vm.sharedTemplate && _vm.isBureauOrIndirect
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    label: "Contra Narrative"
                  }
                },
                [
                  _c("b-form-input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "contraNarrativeInput",
                      disabled: !_vm.editable
                    },
                    model: {
                      value: _vm.plan.contraNarrative,
                      callback: function($$v) {
                        _vm.$set(_vm.plan, "contraNarrative", $$v)
                      },
                      expression: "plan.contraNarrative"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasBureau && !_vm.sharedTemplate && _vm.isBureauOrIndirect
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    "label-class": "required",
                    label: "Indirect Service User Number"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "planStatusSelect",
                      disabled: !_vm.editable || !_vm.isNew
                    },
                    model: {
                      value: _vm.plan.indirectSun,
                      callback: function($$v) {
                        _vm.$set(_vm.plan, "indirectSun", $$v)
                      },
                      expression: "plan.indirectSun"
                    }
                  }),
                  _vm.$v.plan.indirectSun.$dirty
                    ? _c("b-col", [
                        !_vm.$v.plan.indirectSun.required
                          ? _c(
                              "label",
                              {
                                staticClass: "text-danger small",
                                attrs: { id: "indirectSunRequiredLabel" }
                              },
                              [_vm._v("Indirect SUN required.")]
                            )
                          : _vm._e(),
                        !_vm.$v.plan.indirectSun.sunValidator
                          ? _c(
                              "label",
                              {
                                staticClass: "text-danger small",
                                attrs: { id: "indirectSunInvalidLabel" }
                              },
                              [_vm._v("Indirect SUN is in invalid format")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _vm._v(
                      "The format for a Service User Number is 6 numbers or a B followed by 5 numbers."
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              class: _vm.columnClass,
              attrs: {
                "label-cols": _vm.labelCols,
                horizontal: "",
                label: "Direct Debit Type"
              }
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "recurrenceEndTypeSelect",
                  options: _vm.paymentType,
                  "text-field": "name",
                  "value-field": "ordinal",
                  disabled: !_vm.editable
                },
                on: {
                  change: function($event) {
                    return _vm.dirtyTouch(_vm.$v.plan.recurrenceEndType)
                  }
                },
                model: {
                  value: _vm.plan.recurrenceEndType,
                  callback: function($$v) {
                    _vm.$set(_vm.plan, "recurrenceEndType", _vm._n($$v))
                  },
                  expression: "plan.recurrenceEndType"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      !_vm.isAdHoc
        ? _c(
            "section",
            [
              _c(
                "b-form-row",
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.plan.recurrenceEndType === 2
                        ? _c(
                            "b-form-group",
                            {
                              class: _vm.columnClass,
                              attrs: {
                                "label-cols": _vm.labelCols,
                                horizontal: "",
                                label: "Number Of Payments"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "col-sm-4",
                                attrs: {
                                  id: "totalPaymentsInput",
                                  type: "number",
                                  placeholder: "1",
                                  "text-field": "name",
                                  "value-field": "value",
                                  disabled: !_vm.editable,
                                  min: 1
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.dirtyTouch(_vm.$v.plan.ordinal)
                                  }
                                },
                                model: {
                                  value: _vm.plan.totalPayments,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.plan,
                                      "totalPayments",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "plan.totalPayments"
                                }
                              }),
                              _vm.$v.plan.totalPayments.$dirty
                                ? _c("b-col", [
                                    !_vm.$v.plan.totalPayments.required
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "totalPaymentsRequiredLabel"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Regular Collection Amount required"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.$v.plan.totalPayments.positive
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "totalPaymentsPositiveLabel"
                                            }
                                          },
                                          [_vm._v("Value must be positive")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      class: _vm.columnClass,
                      attrs: {
                        "label-cols": _vm.labelCols,
                        horizontal: "",
                        label: "Frequency"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "recurrenceTypeSelect",
                          options: _vm.recurrenceType,
                          "text-field": "name",
                          "value-field": "ordinal",
                          disabled: !_vm.editable
                        },
                        on: { change: _vm.recurrenceTypeChanged },
                        model: {
                          value: _vm.plan.recurrenceType,
                          callback: function($$v) {
                            _vm.$set(_vm.plan, "recurrenceType", _vm._n($$v))
                          },
                          expression: "plan.recurrenceType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.plan.recurrenceType === 1
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          class: _vm.columnClass,
                          attrs: {
                            "label-cols": _vm.labelCols,
                            horizontal: "",
                            label: "Day of the Month"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "ordinalSelect",
                              number: true,
                              type: "number",
                              min: _vm.ordinalOpts[0],
                              max: _vm.ordinalOpts[_vm.ordinalOpts.length - 1],
                              disabled: !_vm.editable
                            },
                            on: { change: _vm.ordinalChanged },
                            model: {
                              value: _vm.plan.ordinal,
                              callback: function($$v) {
                                _vm.$set(_vm.plan, "ordinal", _vm._n($$v))
                              },
                              expression: "plan.ordinal"
                            }
                          }),
                          _vm.showAdvice
                            ? _c("div", { staticClass: "col" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "small",
                                    class: _vm.adivceLabelClass
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.adviceMessage) + " "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.heatmapVisible = !_vm.heatmapVisible
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.heatmapVisible
                                              ? "Show less info."
                                              : "Show more info."
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.plan.recurrenceType === 2
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          class: _vm.columnClass,
                          attrs: {
                            "label-cols": _vm.labelCols,
                            horizontal: "",
                            label: "Day of the Year"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "right-inner-addon" },
                            [
                              _c("datepicker", {
                                attrs: {
                                  id: "dayOfYearDayPicker",
                                  format: _vm.formatDate,
                                  disabled: !_vm.editable,
                                  "input-class": "form-control datepicker"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.dirtyTouchYear()
                                  }
                                },
                                model: {
                                  value: _vm.dayOfYear,
                                  callback: function($$v) {
                                    _vm.dayOfYear = $$v
                                  },
                                  expression: "dayOfYear"
                                }
                              }),
                              _c("i", {
                                staticClass:
                                  "fa fa-calendar form-control-feedback"
                              })
                            ],
                            1
                          ),
                          _vm.showAdvice
                            ? _c("div", { staticClass: "col" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "small",
                                    class: _vm.adivceLabelClass
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.adviceMessage) + " "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.heatmapVisible = !_vm.heatmapVisible
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.heatmapVisible
                                              ? "Show less info."
                                              : "Show more info."
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-row",
                [
                  _vm.plan.recurrenceType === 0
                    ? _c(
                        "b-form-group",
                        {
                          class: _vm.columnClass,
                          attrs: {
                            "label-cols": _vm.labelCols,
                            horizontal: "",
                            label: "Day of the Week"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "weekdaySelect",
                              options: _vm.weekdays,
                              "text-field": "name",
                              "value-field": "value",
                              disabled: !_vm.editable
                            },
                            on: { change: _vm.ordinalChangedWeekday },
                            model: {
                              value: _vm.plan.ordinal,
                              callback: function($$v) {
                                _vm.$set(_vm.plan, "ordinal", _vm._n($$v))
                              },
                              expression: "plan.ordinal"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              !_vm.stepDisabled
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          class: _vm.columnClass,
                          attrs: {
                            "label-cols": _vm.labelCols,
                            horizontal: "",
                            label: "Step"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              number: true,
                              id: "stepSelect",
                              min: 1,
                              max: _vm.steps[_vm.steps.length - 1],
                              disabled: _vm.stepDisabled || !_vm.editable,
                              type: "number"
                            },
                            on: {
                              change: function($event) {
                                return _vm.dirtyTouchStep(_vm.$v.plan.step)
                              }
                            },
                            model: {
                              value: _vm.plan.step,
                              callback: function($$v) {
                                _vm.$set(_vm.plan, "step", _vm._n($$v))
                              },
                              expression: "plan.step"
                            }
                          }),
                          _vm.$v.plan.step.$dirty
                            ? _c("b-col", [
                                !_vm.$v.plan.step.required
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "text-danger small mr-2",
                                        attrs: { id: "stepRequiredLabel" }
                                      },
                                      [_vm._v("Step required")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.plan.step.positive
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "text-danger small mr-2",
                                        attrs: { id: "stepPositiveLabel" }
                                      },
                                      [_vm._v("Value must be positive")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.plan.step.numeric
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "text-danger small mr-2",
                                        attrs: { id: "stepNumericLabel" }
                                      },
                                      [_vm._v("Value must be a number")]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      class: _vm.columnClass,
                      attrs: {
                        "label-cols": _vm.labelCols,
                        horizontal: "",
                        label: "First Collection Date"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "right-inner-addon" },
                        [
                          _c("datepicker", {
                            attrs: {
                              id: "firstPaymentDatePicker",
                              disabled: !_vm.editable || !_vm.isNew,
                              disabledDates: _vm.disabledDates,
                              format: _vm.formatDate,
                              "input-class": "form-control datepicker"
                            },
                            on: {
                              input: function($event) {
                                return _vm.dirtyTouch(
                                  _vm.$v.plan.firstPaymentDate
                                )
                              }
                            },
                            model: {
                              value: _vm.plan.firstPaymentDate,
                              callback: function($$v) {
                                _vm.$set(_vm.plan, "firstPaymentDate", $$v)
                              },
                              expression: "plan.firstPaymentDate"
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-calendar form-control-feedback"
                          })
                        ],
                        1
                      ),
                      _vm.isNew &&
                      _vm.$v.plan.firstPaymentDate.$dirty &&
                      !_vm.templateView
                        ? _c("b-col", [
                            !_vm.$v.plan.firstPaymentDate.newerThanNow
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small",
                                    attrs: { id: "firstPaymentGreaterLabel" }
                                  },
                                  [_vm._v("A date must be greater than today")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.plan.recurrenceEndType === 1
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          class: _vm.columnClass,
                          attrs: {
                            "label-cols": _vm.labelCols,
                            horizontal: "",
                            label: "Final Collection Date"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "right-inner-addon" },
                            [
                              _c("datepicker", {
                                attrs: {
                                  id: "endPaymentDatePicker",
                                  disabledDates: _vm.finalPaymentsDisabledDates,
                                  format: _vm.formatDate,
                                  "input-class": "form-control datepicker",
                                  disabled: !_vm.editable
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.dirtyTouch(_vm.$v.plan.untilDate)
                                  }
                                },
                                model: {
                                  value: _vm.plan.untilDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.plan, "untilDate", $$v)
                                  },
                                  expression: "plan.untilDate"
                                }
                              }),
                              _c("i", {
                                staticClass:
                                  "fa fa-calendar form-control-feedback"
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      class: _vm.columnClass,
                      attrs: {
                        "label-cols": _vm.labelCols,
                        horizontal: "",
                        label: "Variable Amount"
                      }
                    },
                    [
                      _c("p-check", {
                        staticClass: "p-switch p-fill",
                        attrs: {
                          id: "variableCheck",
                          color: "primary",
                          disabled: !_vm.editable
                        },
                        model: {
                          value: _vm.plan.variable,
                          callback: function($$v) {
                            _vm.$set(_vm.plan, "variable", $$v)
                          },
                          expression: "plan.variable"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  !_vm.plan.variable
                    ? _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              class: _vm.columnClass,
                              attrs: {
                                "label-cols": _vm.labelCols,
                                horizontal: "",
                                label: "First Collection Amount"
                              }
                            },
                            [
                              _c("currency-input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "firstAmountInput",
                                  required: "",
                                  disabled: !_vm.editable,
                                  currency: "GBP",
                                  locale: "en"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.dirtyTouchCurrency(
                                      _vm.$v.plan.firstAmount
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.firstAmount,
                                  callback: function($$v) {
                                    _vm.firstAmount = $$v
                                  },
                                  expression: "firstAmount"
                                }
                              }),
                              _vm.$v.plan.firstAmount.$dirty
                                ? _c("b-col", [
                                    !_vm.$v.plan.firstAmount.decimal
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "firstAmountNumberLabel"
                                            }
                                          },
                                          [_vm._v("Value must be a number")]
                                        )
                                      : _vm._e(),
                                    !_vm.$v.plan.firstAmount.zeroOrPositive
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "firstAmountPositiveLabel"
                                            }
                                          },
                                          [_vm._v("Value must be positive")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  !_vm.plan.variable
                    ? _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              class: _vm.columnClass,
                              attrs: {
                                "label-cols": _vm.labelCols,
                                horizontal: "",
                                label: "Regular Collection Amount"
                              }
                            },
                            [
                              _c("currency-input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "regularAmountInput",
                                  "label-class": _vm.plan.variable
                                    ? ""
                                    : "required",
                                  disabled: !_vm.editable,
                                  currency: "GBP",
                                  locale: "en"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.dirtyTouchCurrency(
                                      _vm.$v.plan.regularAmount
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.regularAmount,
                                  callback: function($$v) {
                                    _vm.regularAmount = $$v
                                  },
                                  expression: "regularAmount"
                                }
                              }),
                              _vm.$v.plan.regularAmount.$dirty
                                ? _c("b-col", [
                                    !_vm.$v.plan.regularAmount.required
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "regularAmountRequiredLabel"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Regular Collection Amount required"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.$v.plan.regularAmount.positive
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "regularAmountPositiveLabel"
                                            }
                                          },
                                          [_vm._v("Value must be positive")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              _vm.$v.plan.regularAmount.$dirty
                                ? _c("b-col", [
                                    !_vm.$v.plan.regularAmount.decimal
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "regularAmountNumberLabel"
                                            }
                                          },
                                          [_vm._v("Value must be a number")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  !_vm.plan.variable
                    ? _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              class: _vm.columnClass,
                              attrs: {
                                "label-cols": _vm.labelCols,
                                horizontal: "",
                                label: "Final Collection Amount"
                              }
                            },
                            [
                              _c("currency-input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "lastAmountInput",
                                  disabled: !_vm.editable,
                                  currency: "GBP",
                                  locale: "en"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.dirtyTouchCurrency(
                                      _vm.$v.plan.lastAmount
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.lastAmount,
                                  callback: function($$v) {
                                    _vm.lastAmount = $$v
                                  },
                                  expression: "lastAmount"
                                }
                              }),
                              _vm.$v.plan.lastAmount.$dirty
                                ? _c("b-col", [
                                    !_vm.$v.plan.lastAmount.zeroOrPositive
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "lastAmountPositiveLabel"
                                            }
                                          },
                                          [_vm._v("Value must be a number")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              _vm.$v.plan.lastAmount.$dirty
                                ? _c("b-col", [
                                    !_vm.$v.plan.lastAmount.decimal
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "text-danger small",
                                            attrs: {
                                              id: "lastAmountNumberLabel"
                                            }
                                          },
                                          [_vm._v("Value must be a number")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      class: _vm.columnClass,
                      attrs: {
                        "label-cols": _vm.labelCols,
                        horizontal: "",
                        label: "Gift Aid"
                      }
                    },
                    [
                      _c("p-check", {
                        staticClass: "p-switch p-fill",
                        attrs: {
                          id: "giftAidCheck",
                          color: "primary",
                          disabled: !_vm.editable
                        },
                        model: {
                          value: _vm.plan.giftAid,
                          callback: function($$v) {
                            _vm.$set(_vm.plan, "giftAid", $$v)
                          },
                          expression: "plan.giftAid"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.templateView && !_vm.plan.variable && !_vm.createMode
                ? _c("b-form-row", [
                    _c("strong", [_vm._v("Scheduled Plan Changes")])
                  ])
                : _vm._e(),
              !_vm.templateView && !_vm.plan.variable && !_vm.createMode
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "vue-good-table",
                        {
                          ref: "futurePlanTable",
                          attrs: {
                            columns: _vm.columns,
                            rows: _vm.plan.futurePlanChanges,
                            lineNumbers: true,
                            totalRows: _vm.totalRecords,
                            rowStyleClass: _vm.rowStyler,
                            "search-options": {
                              enabled: false
                            },
                            "pagination-options": {
                              enabled: false
                            },
                            styleClass: "vgt-table striped bordered"
                          },
                          on: {
                            "on-row-click": _vm.onRowClick,
                            "on-cell-click": _vm.onCellClick
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "table-row",
                                fn: function(props) {
                                  return [
                                    _vm.editRowIndex == props.index
                                      ? _c("span", [
                                          props.column.label == "Amount"
                                            ? _c("span", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        props.row.changeAmount,
                                                      expression:
                                                        "props.row.changeAmount"
                                                    }
                                                  ],
                                                  attrs: { type: "number" },
                                                  domProps: {
                                                    value:
                                                      props.row.changeAmount
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        props.row,
                                                        "changeAmount",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ])
                                            : _vm._e(),
                                          props.column.label == "Trigger"
                                            ? _c(
                                                "span",
                                                [
                                                  props.row.triggerType === 0
                                                    ? _c("datepicker", {
                                                        attrs: {
                                                          disabledDates:
                                                            _vm.disabledDates
                                                        },
                                                        model: {
                                                          value:
                                                            props.row.startDate,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              props.row,
                                                              "startDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "props.row.startDate"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  props.row.triggerType === 1
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              props.row
                                                                .paymentsMade,
                                                            expression:
                                                              "props.row.paymentsMade"
                                                          }
                                                        ],
                                                        attrs: {
                                                          type: "number"
                                                        },
                                                        domProps: {
                                                          value:
                                                            props.row
                                                              .paymentsMade
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              props.row,
                                                              "paymentsMade",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          props.column.label == "Applied"
                                            ? _c("span", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: props.row.applied,
                                                      expression:
                                                        "props.row.applied"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    disabled: true
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      props.row.applied
                                                    )
                                                      ? _vm._i(
                                                          props.row.applied,
                                                          null
                                                        ) > -1
                                                      : props.row.applied
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          props.row.applied,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              props.row,
                                                              "applied",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              props.row,
                                                              "applied",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          props.row,
                                                          "applied",
                                                          $$c
                                                        )
                                                      }
                                                    }
                                                  }
                                                })
                                              ])
                                            : _vm._e(),
                                          props.column.label == "Change Type"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options: _vm.changeTypes,
                                                      "text-field": "name",
                                                      "value-field": "value"
                                                    },
                                                    model: {
                                                      value:
                                                        props.row.changeType,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "changeType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.changeType"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          props.column.label === "Trigger Type"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options: _vm.triggerTypes,
                                                      "text-field": "name",
                                                      "value-field": "value"
                                                    },
                                                    model: {
                                                      value:
                                                        props.row.triggerType,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "triggerType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.triggerType"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          props.column.label == ""
                                            ? _c(
                                                "span",
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          props.row.orderIndex,
                                                        expression:
                                                          "props.row.orderIndex"
                                                      }
                                                    ],
                                                    attrs: {
                                                      type: "hidden",
                                                      value: "props.index"
                                                    },
                                                    domProps: {
                                                      value:
                                                        props.row.orderIndex
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          props.row,
                                                          "orderIndex",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.saveFpc(
                                                            props
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-save mr-1"
                                                      }),
                                                      _vm._v("Save ")
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-warning"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.cancelFpc(
                                                            props
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-times mr-1"
                                                      }),
                                                      _vm._v("Cancel ")
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-danger",
                                                        disabled:
                                                          props.row.applied
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteFpc(
                                                            props
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-times mr-1"
                                                      }),
                                                      _vm._v("Delete ")
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                      : _c("span", [
                                          props.column.label == "Applied"
                                            ? _c("span", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: props.row.applied,
                                                      expression:
                                                        "props.row.applied"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    disabled: true
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      props.row.applied
                                                    )
                                                      ? _vm._i(
                                                          props.row.applied,
                                                          null
                                                        ) > -1
                                                      : props.row.applied
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          props.row.applied,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              props.row,
                                                              "applied",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              props.row,
                                                              "applied",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          props.row,
                                                          "applied",
                                                          $$c
                                                        )
                                                      }
                                                    }
                                                  }
                                                })
                                              ])
                                            : props.column.label == ""
                                            ? _c("span", [
                                                _vm.fpcRowLocked(props.row)
                                                  ? _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-lock"
                                                      })
                                                    ])
                                                  : _vm._e(),
                                                !_vm.fpcRowLocked(props.row)
                                                  ? _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-lock-open"
                                                      })
                                                    ])
                                                  : _vm._e()
                                              ])
                                            : props.column.field ==
                                              "changeAmount"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatValue(props.row)
                                                  )
                                                )
                                              ])
                                            : props.column.field == "changeType"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatChangeType(
                                                      props.row.changeType
                                                    )
                                                  )
                                                )
                                              ])
                                            : props.column.label == "Trigger"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.row.triggerType === 0
                                                      ? _vm.formatFpcDate(
                                                          props.row.startDate
                                                        )
                                                      : props.row.paymentsMade
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.formattedRow[
                                                      props.column.field
                                                    ]
                                                  )
                                                )
                                              ])
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2505521404
                          )
                        },
                        [
                          _c("template", { slot: "loadingContent" }, [
                            _c("h1", [_vm._v("Loading...")])
                          ]),
                          _c(
                            "div",
                            {
                              attrs: { slot: "emptystate" },
                              slot: "emptystate"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vgt-center-align vgt-text-disabled"
                                },
                                [_vm._v("No Plan Templates Available")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Add payment",
                                      expression: "'Add payment'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    title: "Add Payment"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.addPayment($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-plus pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Add Payment")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Refresh the data in the table",
                                      expression:
                                        "'Refresh the data in the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    title: "Refresh Table"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.reloadItems($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-sync pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Refresh Table")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value:
                                        "Export the contents of the Logs table",
                                      expression:
                                        "'Export the contents of the Logs table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    title: "Export Table"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.exportTable($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-share-square pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Export Table")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.templateView
        ? _c("payer-ref-control", {
            model: {
              value: _vm.plan.payerReferencePattern,
              callback: function($$v) {
                _vm.$set(_vm.plan, "payerReferencePattern", $$v)
              },
              expression: "plan.payerReferencePattern"
            }
          })
        : _vm._e(),
      _vm.templateView
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    label: "Generate API Key"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "secondary" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.generateApiKey($event)
                                      }
                                    }
                                  },
                                  [_vm._v("Get")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2595112572
                      )
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "apiKeyInput",
                          type: "text",
                          disabled: true
                        },
                        model: {
                          value: _vm.apiKey,
                          callback: function($$v) {
                            _vm.apiKey = _vm._n($$v)
                          },
                          expression: "apiKey"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.templateView
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    label: "Allow Date Choice"
                  }
                },
                [
                  _c("p-check", {
                    staticClass: "p-switch p-fill",
                    attrs: {
                      id: "dateChoiceCheck",
                      color: "primary",
                      disabled: !_vm.editable
                    },
                    model: {
                      value: _vm.plan.allowDateChoice,
                      callback: function($$v) {
                        _vm.$set(_vm.plan, "allowDateChoice", $$v)
                      },
                      expression: "plan.allowDateChoice"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.templateView
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  class: _vm.columnClass,
                  attrs: {
                    "label-cols": _vm.labelCols,
                    horizontal: "",
                    label: "Date Range Allowed"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.dateRangeAllowed,
                        expression: "plan.dateRangeAllowed"
                      }
                    ],
                    attrs: { type: "number" },
                    domProps: { value: _vm.plan.dateRangeAllowed },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.plan,
                          "dateRangeAllowed",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.templateView &&
      !_vm.createMode &&
      _vm.plan.paymentPlanId !== "00000000-0000-0000-0000-000000000000"
        ? _c(
            "b-form-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "PayerSchedule",
                          params: {
                            id: _vm.ukPayerId,
                            planId: _vm.plan.paymentPlanId
                          }
                        }
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "outline-secondary" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-calendar rpad" }),
                          _vm._v("View Schedule ")
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.showDeleteButton
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mt-3 pull-right",
                          attrs: {
                            variant: "danger",
                            disabled: _vm.plan.planStatus !== 0
                          },
                          on: { click: _vm.deletePlan }
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash rpad" }),
                          _vm._v("Delete Plan ")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }