var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Bureau Customer Verification "),
            _c("help-icon"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _vm.TotalRecordsNeedVerification > 0
            ? _c(
                "b-card",
                {
                  staticStyle: { "margin-bottom": "0.8em" },
                  attrs: { "bg-variant": "warning" }
                },
                [
                  _vm._v(
                    "Cannot perform complete import as records require verification"
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-striped payment-details-summary pt-0 mt-0 mb-2"
                },
                [
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(0),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.Filename))
                      ])
                    ]),
                    _c("tr", [
                      _vm._m(1),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.TotalRecordsVerified))
                      ])
                    ]),
                    _c("tr", [
                      _vm._m(2),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.TotalRecordsNeedVerification))
                      ])
                    ]),
                    _c("tr", [
                      _vm._m(3),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.TotalRecordsDeleted))
                      ])
                    ]),
                    _c("tr", [
                      _vm._m(4),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.totalRecordsInImport))
                      ])
                    ])
                  ])
                ]
              )
            ])
          ]),
          _c(
            "vue-good-table",
            {
              ref: "table",
              staticClass: "table-unset",
              attrs: {
                paginationOptions: _vm.paginationOptions,
                "sort-options": _vm.sortOptions,
                rows: _vm.rows,
                columns: _vm.columns,
                searchOptions: { enabled: true },
                mode: "remote",
                totalRows: _vm.totalRecords,
                isLoading: _vm.isTableLoading,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "on-page-change": _vm.onPageChange,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch,
                "on-sort-change": _vm.onSortChange,
                "on-cell-click": _vm.goToEdit,
                "on-row-click": _vm.rowClickEvent,
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.label === "Status"
                        ? _c("span", [
                            props.row.deleted
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value: "Deleted",
                                        expression: "'Deleted'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    staticClass:
                                      "w-100 badge badge-pill badge-warning"
                                  },
                                  [_vm._v("Deleted")]
                                )
                              : props.row.errorlevel > 0
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value:
                                          "This Customer is invalid and cannot be imported",
                                        expression:
                                          "'This Customer is invalid and cannot be imported'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    staticClass:
                                      "w-100 badge badge-pill badge-danger"
                                  },
                                  [_vm._v("Invalid")]
                                )
                              : _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top.d500",
                                        value:
                                          "This Customer is valid and may be imported",
                                        expression:
                                          "'This Customer is valid and may be imported'",
                                        modifiers: {
                                          hover: true,
                                          top: true,
                                          d500: true
                                        }
                                      }
                                    ],
                                    staticClass:
                                      "w-100 badge badge-pill badge-success"
                                  },
                                  [_vm._v("Valid")]
                                )
                          ])
                        : props.column.field === "name"
                        ? _c("span", [
                            props.row.deleted
                              ? _c("span", { staticClass: "pl-2" }, [
                                  _vm._v(" " + _vm._s(props.row.name) + " ")
                                ])
                              : _c("span", [_vm._v(_vm._s(props.row.name))])
                          ])
                        : props.column.field === "serviceUserNumber"
                        ? _c("span", [
                            props.row.deleted
                              ? _c("span", { staticClass: "pl-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.row.serviceUserNumber) +
                                      " "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(props.row.serviceUserNumber))
                                ])
                          ])
                        : props.column.field === "errorMessages"
                        ? _c("span", [
                            props.row.bureauImportStatus == "Editing"
                              ? _c("span", [
                                  props.row.deleted === true
                                    ? _c("span", [
                                        _vm._v(" Removed from import ")
                                      ])
                                    : props.row.errorlevel > 0 &&
                                      props.row.deleted === false
                                    ? _c(
                                        "ul",
                                        { staticClass: "list-unstyled" },
                                        _vm._l(
                                          props.row.errorMessages,
                                          function(error) {
                                            return props.row.errorMessages !==
                                              null &&
                                              props.row.errorMessages.length > 0
                                              ? _c("li", { key: error }, [
                                                  _vm._v(
                                                    " " + _vm._s(error) + " "
                                                  )
                                                ])
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          "This Customer is valid and may be imported"
                                        )
                                      ])
                                ])
                              : _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(props.row.bureauImportStatus))
                                ])
                          ])
                        : props.column.field === "actions"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticStyle: {
                                    "margin-top": "-10px",
                                    "margin-right": "-30px"
                                  },
                                  attrs: {
                                    variant: "link",
                                    size: "lg",
                                    "no-caret": ""
                                  }
                                },
                                [
                                  _c("template", { slot: "button-content" }, [
                                    _c("i", {
                                      staticClass: "fa fa-ellipsis-h",
                                      staticStyle: { color: "#878787" }
                                    }),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Actions")
                                    ])
                                  ]),
                                  !props.row.deleted
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            disabled:
                                              props.row.bureauImportStatus !==
                                                "Editing" &&
                                              props.row.bureauImportStatus !==
                                                "Failed"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onRecordClick(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Edit Customer")]
                                      )
                                    : _vm._e(),
                                  props.row.deleted
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            disabled:
                                              props.row.bureauImportStatus !==
                                              "Editing"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onRecordRestore(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Restore Customer")]
                                      )
                                    : _vm._e(),
                                  !props.row.deleted
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            disabled:
                                              props.row.bureauImportStatus !==
                                              "Editing"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onRecordDelete(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Delete Customer")]
                                      )
                                    : _vm._e(),
                                  !props.row.deleted
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            disabled:
                                              (props.row.bureauImportStatus !==
                                                "Editing" &&
                                                props.row.bureauImportStatus !==
                                                  "Failed") ||
                                              props.row.errorlevel > 0
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onImportSingle(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Import single Customer")]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : props.column.field === "checkbox"
                        ? _c(
                            "span",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "sr-only",
                                  attrs: {
                                    for:
                                      "addToArray_" + props.row.bureauCustomerId
                                  }
                                },
                                [_vm._v("Add To Array")]
                              ),
                              _c("p-check", {
                                staticClass: "p-default p-curve",
                                attrs: {
                                  labelId:
                                    "addToArray_" + props.row.bureauCustomerId,
                                  disabled:
                                    props.row.deleted === true ||
                                    (props.row.bureauImportStatus !==
                                      "Editing" &&
                                      props.row.bureauImportStatus !==
                                        "Failed") ||
                                    props.row.errorlevel > 0,
                                  checked: _vm.BureauCustomerImportIds.some(
                                    function(x) {
                                      return x === props.row.bureauCustomerId
                                    }
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.addToBCArray(props.row)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          })
                        ]
                      ),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Clear filters")
                      ])
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Refresh the data in the table",
                          expression: "'Refresh the data in the table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.load($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-sync pointer dimmedIcon" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Refresh the data in the table")
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        this.checkboxesSelected === true
          ? _c("span", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.onVerifySelectedRecords()
                    }
                  }
                },
                [_vm._v("Verify Selected")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ml-2",
                  on: {
                    click: function($event) {
                      return _vm.onImportSelectedRecords()
                    }
                  }
                },
                [_vm._v("Import Selected")]
              )
            ])
          : _c("span", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.onVerifyRecords()
                    }
                  }
                },
                [_vm._v("Verify Records")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ml-2",
                  attrs: { disabled: _vm.TotalRecordsNeedVerification > 0 },
                  on: {
                    click: function($event) {
                      return _vm.onImportClick()
                    }
                  }
                },
                [_vm._v("Complete Import")]
              )
            ]),
        _c("span", [
          _c(
            "button",
            {
              staticClass: "btn btn-danger float-right",
              on: { click: _vm.onDeleteClick }
            },
            [_vm._v("Delete Import Records")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Filename")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Valid Customers")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Invalid Customers")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Deleted")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Total Customers")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }