<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closePopup()">
      <!-- <h3 slot="header">Report Folder Path</h3> -->
    </folderBrowser>
    <h3>Collections Report Reconciliation<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label class="required" >Report Path</label>
      <div class="input-group mb-3">
        <input type="text"
          class="form-control"
          :class="{invalid: $v.selectedNode.props.s2.value.$error}"
          @input="$v.selectedNode.props.s2.value.$touch()"
          v-model="selectedNode.props.s2.value">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" @click.prevent="btnPathBrowser" type="button">...</button>
        </div>
      </div>
      <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The Report Path field is required!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <br/>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s2: {
          value: {
            required,
            maxLength: maxLength(255)
          }
        }
      }
    }
  },
  data () {
    return {
      options: [],
      selectedNode: {},
      showFolderBrowser: false,
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s2.value = utils.sanitisePath(this.selectedNode.props.s2.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnPathBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showFolderBrowser = true
    },
    closePopup () {
      this.selectedNode.props.s2.value = this.$store.state.mapping.modalPopupString
      this.selectedNode.props.s2.value = utils.sanitisePath(this.$store.state.mapping.modalPopupString)
    }
  },
  created: async function () {
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }

</style>
