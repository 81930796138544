var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c("vue-good-table", {
            attrs: {
              paginationOptions: {
                enabled: true,
                perPage: 10
              },
              rows: _vm.rows,
              columns: _vm.columns,
              searchOptions: {
                enabled: true
              },
              lineNumbers: true,
              styleClass: "vgt-table striped bordered"
            },
            on: { "on-row-click": _vm.onRowClick },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "enabled"
                      ? _c("span", [
                          _c(
                            "span",
                            {
                              class: {
                                "text-success": props.row.enabled,
                                "text-danger": !props.row.enabled
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "glyphicon",
                                class: {
                                  "ti-check": props.row.enabled,
                                  "ti-close": !props.row.enabled
                                }
                              })
                            ]
                          )
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(props.formattedRow[props.column.field]))
                        ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v(" Manage HSM ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }