var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.saveGroups()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _vm.showDropzone
              ? _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12", attrs: { id: "dzUpload" } },
                    [
                      _c("vue-dropzone", {
                        ref: "fileUploader",
                        attrs: {
                          id: "drop1",
                          "use-custom-dropzone-options": true,
                          options: _vm.dropzoneOptions
                        },
                        on: {
                          "vdropzone-success": _vm.afterSuccess,
                          "vdropzone-file-added": _vm.fileAdded,
                          "vdropzone-sending": _vm.sending
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.errors.length > 0 && !_vm.showDropzone
              ? _c(
                  "div",
                  [
                    _c(
                      "b-card",
                      {
                        attrs: {
                          "border-variant": _vm.errorVariant,
                          header: _vm.errorHeader,
                          "header-bg-variant": _vm.errorVariant,
                          "header-text-variant": "white"
                        }
                      },
                      _vm._l(_vm.errors, function(error) {
                        return _c("div", { key: error }, [
                          _c("div", [_vm._v(_vm._s(error))])
                        ])
                      }),
                      0
                    ),
                    _c("br")
                  ],
                  1
                )
              : _vm._e(),
            _vm.groupCount > 0
              ? _c(
                  "div",
                  [
                    _c("bank-account-select", {
                      attrs: { bankAccounts: _vm.bankAccounts },
                      model: {
                        value: _vm.$v.defaultBankAccount,
                        callback: function($$v) {
                          _vm.$set(_vm.$v, "defaultBankAccount", $$v)
                        },
                        expression: "$v.defaultBankAccount"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.defaultGroupAdminUser.$error }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "label-control col-md-3 required" },
                          [_vm._v("Group Admin User")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("b-form-select", {
                              attrs: { options: _vm.users },
                              model: {
                                value: _vm.$v.defaultGroupAdminUser.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.defaultGroupAdminUser,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.defaultGroupAdminUser.$model"
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "group admin user" },
                              model: {
                                value: _vm.$v.defaultGroupAdminUser,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v, "defaultGroupAdminUser", $$v)
                                },
                                expression: "$v.defaultGroupAdminUser"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.groupCount > 0
              ? _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("vue-good-table", {
                        attrs: {
                          columns: _vm.columns,
                          rows: _vm.groups,
                          lineNumbers: true,
                          totalRows: _vm.groupCount,
                          "pagination-options": {
                            enabled: true,
                            perPage: 10,
                            dropdownAllowAll: false
                          },
                          styleClass: "vgt-table striped bordered"
                        },
                        on: {
                          "on-page-change": _vm.onPageChange,
                          "on-sort-change": _vm.onSortChange,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-row-click": _vm.onRowClick
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _vm.canImportGroups
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit", disabled: _vm.preventSaveGroups }
                  },
                  [_vm._v("Save Groups")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger pull-right ml-3",
                attrs: { type: "button" },
                on: { click: _vm.cancel }
              },
              [_vm._v("Cancel")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Import Groups")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }