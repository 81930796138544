var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Watched Folder")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.watchedFolder, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Default Sub Reference")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.defaultSubmissionReference, function(
            diff,
            index
          ) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Default Contra Narrative")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.defaultContraNarrative, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Stop on Duplicate File")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.stopOnDuplicateFile, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Stop on Pre-sub Val Fix")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.stopOnFix, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Stop on Pre-sub Val Warning")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.stopOnWarning, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Remove Fix Payments")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.removeFixPayments, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionAutoFileImport.removeFixPayments
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-3 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Removed Fix Action")]
          ),
          _c("div", { staticClass: "col-sm-9" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.removedFixesAction, function(diff, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Remove Warning Payments")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.removeWarningPayments, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionAutoFileImport.removeWarningPayments
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-3 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Removed Warning Action")]
          ),
          _c("div", { staticClass: "col-sm-9" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.removedWarningsAction, function(
                diff,
                index
              ) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Remove Duplicate Payments")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.removeDuplicatePayments, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionAutoFileImport.removeDuplicatePayments
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-3 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Removed Duplicate Action")]
          ),
          _c("div", { staticClass: "col-sm-9" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.removedDuplicatesAction, function(
                diff,
                index
              ) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-3 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("File Save Folder")]
      ),
      _c("div", { staticClass: "col-sm-9" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.fileSaveFolder, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }