<template>

  <div style="height:100%">

    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">Recently Completed Bureau Jobs</h5>
      <i @click="load" role="button" class="fas fa-sync-alt pull-right"><span class="sr-only">Refresh</span></i>
    </div>
    <VuePerfectScrollbar class="scroll-area" :settings="settings">
      <vue-good-table
        :isLoading.sync="isTableLoading"
        mode="remote"
        ref="table"
        :columns="columns"
        :rows="rows"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        @on-row-click="onRowClick"
        :totalRows="totalRecords"
        :search-options="{
            enabled: false
          }"
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        styleClass="vgt-table striped bordered condensed"
      >
        <template slot="loadingContent">
          <h1>Loading...</h1>
        </template>
        <template slot="emptystate">
          <span>You do not currently have any completed bureau jobs</span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>
    </VuePerfectScrollbar>

  </div>

</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { setInterval } from 'timers'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  name: 'BureauRecentJobsWidget',
  components: {
    VuePerfectScrollbar
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  computed: {
    paygateId () { return this.$store.state.common.paygateId }
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60
      },
      intervalBit: null,
      rows: [],
      columns: [
        { label: 'Reference', field: 'submissionReference' },
        { label: 'Job Name', field: 'bureauJobName' },
        {
          label: 'Completed Date',
          field: 'modifiedDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'modifiedDate', type: 'desc' }]
      },
      sortOptions: { enabled: true, initialSortBy: { field: 'modifiedDate', type: 'desc' } },
    }
  },
  async created () {
    this.load()
    setInterval(this.load, 30000)
  },
  beforeRouteLeave () {
    clearInterval(this.intervalBit)
  },
  mounted () { },
  methods: {
    async load () {
      try {
        if (this.$route.name === 'Dashboard') {
          this.isTableLoading = true
          let response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getRecentSubmissions', { params: this.buildGoodTableQuery(),
            paygateid: this.paygateId })
          this.rows = response.data.data
          this.totalRecords  = response.data.count
          this.isTableLoading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    async onRowClick (e) {
      await this.onViewPaymentsClick(e.row)
    },
    onViewPaymentsClick (submission) {
      console.log(submission)
      this.$store.dispatch('setSubmissionId', submission.bureauSubmissionId)
      this.$store.dispatch('setFileNumber', 1)
      this.$router.push({ path: '/payments/common/viewSubmissionSummary', query: { submissionId: submission.bureauSubmissionId } })
    },
    formatDate (datestr) {
      const date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    truncate (input) {
      var output = input
      if (input.length > 100) {
        output = input.substring(0, 90) + '...'
      }
      return output
    },
    formatStatus (field, row) {
      if (field === 'AwaitingApproval') {
        return row.bacsPaymentStatus === 'None' ? 'Awaiting Approval' : 'Submission (' + row.bacsPaymentStatus + ')'
      } else { return field }
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-area {
  margin-top: 20px;
  height: calc(100% - 25px);
  min-height: unset;
  border-top: solid 1px #eeeeee;
}
.textRow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10px;
}

.mouseHovering {
  color: yellow
}
</style>
