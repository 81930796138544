var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" Bureau Usage Report "),
                  _c("help-icon", {
                    attrs: {
                      docPath: "/administration/reports/licensingusage/"
                    }
                  }),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row form-group" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-md-4" },
                  [
                    _c(
                      "label",
                      { staticClass: "required", attrs: { for: "from" } },
                      [_vm._v("Billing Month")]
                    ),
                    _c("vuejsDatepicker", {
                      attrs: {
                        name: "month",
                        id: "month",
                        format: "MMM/yyyy",
                        "input-class": "form-control bg-white",
                        "bootstrap-styling": true,
                        minimumView: "month",
                        maximumView: "year",
                        "initial-view": "month",
                        "use-utc": true,
                        disabledDates: { from: new Date(Date.now()) }
                      },
                      on: { selected: _vm.dateChanged },
                      model: {
                        value: _vm.monthselection,
                        callback: function($$v) {
                          _vm.monthselection = $$v
                        },
                        expression: "monthselection"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _c(
                  "div",
                  { staticClass: " col-md-12" },
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "table",
                        attrs: {
                          paginationOptions: _vm.paginationOptions,
                          "sort-options": _vm.sortOptions,
                          isLoading: _vm.isTableLoading,
                          rows: _vm.rows,
                          columns: _vm.columns,
                          lineNumbers: true,
                          mode: "remote",
                          totalRows: _vm.totalRecords,
                          styleClass: "vgt-table striped bordered"
                        },
                        on: {
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "on-page-change": _vm.onPageChange,
                          "on-sort-change": _vm.onSortChange,
                          "on-column-filter": _vm.onColumnFilter,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-search": _vm.onSearch
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Clear filters",
                                    expression: "'Clear filters'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.clearTableFilters($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fa-stack",
                                    staticStyle: { "font-size": "10px" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-filter fa-stack-1x dimmedIcon"
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "fa fa-ban fa-stack-2x dimmedIcon"
                                    })
                                  ]
                                ),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Clear filters")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Export the contents of the table",
                                    expression:
                                      "'Export the contents of the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.exportTable($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-file-export pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Export Table")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "idb-block" }, [
            _vm._m(0),
            _c("div", { staticClass: "idb-block-content" }, [
              _vm.EmailRecipients.length > 0
                ? _c("table", { staticClass: "table table-header-rotated" }, [
                    _vm._m(1),
                    _c(
                      "tbody",
                      _vm._l(_vm.EmailRecipients, function(recipient) {
                        return _c(
                          "tr",
                          { key: recipient.id + _vm.componentKey },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "row-header",
                                staticStyle: { width: "150px" }
                              },
                              [
                                _vm._v(_vm._s(recipient.name) + " "),
                                _c("i", [
                                  _vm._v("(" + _vm._s(recipient.email) + ")")
                                ])
                              ]
                            ),
                            _c("td", [
                              _c(
                                "label",
                                {
                                  staticClass: "sr-only",
                                  attrs: { for: recipient.id + "_disabled" }
                                },
                                [_vm._v("Disable Recipient")]
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.ToggleRecipientEnabled(
                                        recipient
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    key:
                                      recipient.id +
                                      "_disabled" +
                                      _vm.componentKey,
                                    staticClass: "fa fa-fw",
                                    class: recipient.disabled
                                      ? "text-danger fa-times"
                                      : "text-success fa-check"
                                  })
                                ]
                              )
                            ]),
                            _c("td", { staticStyle: { width: "20px" } }, [
                              _c("a", {
                                staticClass: "fa fa-cog",
                                attrs: { role: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.EditRecipient(recipient.id)
                                  }
                                }
                              })
                            ]),
                            _c("td", { staticStyle: { width: "20px" } }, [
                              _c("a", {
                                staticClass: "fa fa-trash",
                                attrs: { role: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.DeleteEmailRecipientData(
                                      recipient
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "idb-block-footer" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.AddNewRecipient()
                      }
                    }
                  },
                  [_vm._v("Add New Email Recipient")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "float-right",
                    attrs: { variant: "warning" },
                    on: {
                      click: function($event) {
                        return _vm.ResendEmail()
                      }
                    }
                  },
                  [_vm._v("Resend Email")]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("addRecipientModal", {
        attrs: { RecipientId: _vm.RecipientId, EditMode: _vm.EditMode },
        on: { submit: _vm.addRecipientModalClosed }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Email Control")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "75px" } }),
        _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Enabled")])])
        ]),
        _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Edit")])])
        ]),
        _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Delete")])])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }