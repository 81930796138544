var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showFolderBrowser = false
                _vm.closeFolderPopup()
              }
            }
          })
        : _vm._e(),
      _vm.showModalFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showModalFileBrowser = false
                _vm.closeFilePopup()
              }
            }
          })
        : _vm._e(),
      _c("h3", [
        _vm._v("Mapping"),
        _c("span", { staticClass: "pull-right" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  _vm.docUrl +
                  "/automation/paygate-workflow/workflow-nodes/other/mapping/",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "far fa-question-circle" })]
          )
        ])
      ]),
      _c("hr"),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("Mapping")]),
        _c("br"),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedNode.props.s1.value,
                expression: "selectedNode.props.s1.value"
              }
            ],
            staticClass: "form-control",
            class: { invalid: _vm.$v.selectedNode.props.s1.value.$error },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.selectedNode.props.s1,
                  "value",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          _vm._l(_vm.mappingOptions, function(option) {
            return _c(
              "option",
              { key: option.value, domProps: { value: option.value } },
              [_vm._v(" " + _vm._s(option.text) + " ")]
            )
          }),
          0
        )
      ]),
      _c("br"),
      _c("label", {}, [_vm._v("Override Filename")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s3.value,
              expression: "selectedNode.props.s3.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s3.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s3.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s3,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s3.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnFileBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s3.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s3.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("label", {}, [_vm._v("Override Folder")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s4.value,
              expression: "selectedNode.props.s4.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s4.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s4.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s4,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s4.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnPathBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s4.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s4.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }