import axios from 'axios'

// actions
const actions = {
  checkRouteVisited: async ({ state }, routeName) => {
    try {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}tour`, {
        params: { routeName }
      })

      return response.data
    } catch (error) {
    }
  },
  setRouteVisited: async ({ state }, routeName) => {
    try {
      var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}tour`, { routeName })
      return response.data
    } catch (error) {
    }
  }
}

export default {
  actions
}
