import axios from 'axios'
import { vueInstance } from '@/main'

const state = {
	user: [],
	avatarUrl: '',
	forceLogout: false,
	beatCache: '',
	baseAvatarUrl: `${process.env.VUE_APP_PLATFORM_API_URL}User/GetAvatar/`

}

const mutations = {
	updateUser (state, user) {
		state.user = user
		state.beatCache = `?beatcache=${new Date().toISOString()}`
	},
	updateAvatar (state) {
		state.beatCache = `?beatcache=${new Date().toISOString()}`
	},
	addClaim (state, claim) {
		state.user.push(claim)
	},
	removeClaim (state, claim) {
		state.user.splice(state.user.findIndex(u => u.type === claim.type && u.value === claim.value), 1)
	},
	forceLogout (state) {
		state.forceLogout = true
	}
}

const getters = {
	getClaims: (state) => (claimType) => {
		claimType = claimType.toLowerCase()
		const claims = state.user.filter((u) => u.type.toLowerCase() === claimType)
		return claims
	},
	getClaim: (state) => (claimType, claimValue = null) => {
		claimType = claimType.toLowerCase()
		const claim = state.user.find((u) => u.type.toLowerCase() === claimType && claimValue == null ? true : u.value === claimValue)
		return claim || { type: '', value: '' }
	},
	hasClaim: (state, getters) => (claimType, claimValue = null) => {
		return Boolean(getters.getClaim(claimType, claimValue).value)
	},
	hasLicence: (state) => (licenceToFind) => {
		return state.user.some((u) => u.type === 'licence' && u.value.toLowerCase() === licenceToFind.toLowerCase())
	},
	hasLicences: (state, getters) => (licencesToFind, method = '&') => {
		if (method === '&') {
			// Do and
			return licencesToFind.every(ltf => getters.hasLicence(ltf))
		} else {
			// assume OR
			return licencesToFind.some(ltf => getters.hasLicence(ltf))
		}
	},
	isInRole: (state) => (roleToFind) => {
		return state.user.some((u) => u.type === 'role' && u.value.toLowerCase() === roleToFind.toLowerCase())
	},
	isInRoles: (state, getters) => (rolesTofind, method = '|') => {
		if (method === '&') {
			// Do and
			return rolesTofind.every(rtf => getters.isInRole(rtf))
		} else {
			// assume OR
			return rolesTofind.some(rtf => getters.isInRole(rtf))
		}
	},
	getAccessToken: (state) => {
		const claim = state.user.find((u) => u.type === 'token')
		return (claim || { type: '', value: '' }).value
	},
	getUserId: (state) => {
		const claim = state.user.find((u) => u.type === 'sub')
		return (claim || { type: '', value: '' }).value
	},
	getAvatarUrl: (state) => {
		return state.avatarUrl
	},
	forceLogout: state => state.forceLogout,
	getAvatarCache: (state) => state.beatCache,
	getBaseAvatarUrl: (state) => state.baseAvatarUrl,
	username (state, getters) {
		return getters.getClaim('username').value
	}
}

const actions = {
	async loadUser ({ commit, state, dispatch }, idsCheck) {
		try {
			if (idsCheck) {
				idsCheck = state.user.find((c) => c.type === 'ids_check')
			}
			const response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'User', {
				params: { idsCheck: idsCheck !== undefined ? idsCheck.value : null, beatCaching: new Date() }
			})
			if (response.status === 200) {
				commit('updateUser', response.data)
				if (!idsCheck) {
					dispatch('updateMenu')
				}
			}
		} catch { }
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
