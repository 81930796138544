var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "idb-block" },
    [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(
              " Select Files for Submission: " +
                _vm._s(_vm.submissionReference) +
                " "
            ),
            _c("help-icon", { attrs: { docPath: "/bacsbureau/builder/" } })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.useDropzone,
              expression: "useDropzone"
            }
          ],
          staticClass: "idb-block-content"
        },
        [
          _vm.customerNoBankSelected
            ? _c(
                "b-card",
                {
                  staticStyle: {
                    "margin-bottom": "0.8em",
                    "margin-right": "0.8em"
                  },
                  attrs: {
                    id: "noBankLabel",
                    "bg-variant": "warning",
                    "text-variant": "white"
                  }
                },
                [
                  _vm._v(
                    "This bureau job has associated customers with no bank details set. This bureau job will fail to import with the current configuration."
                  )
                ]
              )
            : _vm._e(),
          _vm.submissionLocked
            ? _c(
                "b-card",
                {
                  staticStyle: { "margin-bottom": "0.8em" },
                  attrs: {
                    id: "lockedSubmissionLabel",
                    "bg-variant": "warning",
                    "text-variant": "white"
                  }
                },
                [
                  _vm._v(
                    "Another user: " +
                      _vm._s(_vm.lockingUsername) +
                      " is progressing this submission, it cannot be edited or progressed."
                  )
                ]
              )
            : _vm._e(),
          !_vm.submissionLocked
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "dropzone",
                        {
                          ref: "fileUploader",
                          attrs: {
                            id: "my-dropzone",
                            options: _vm.dropzoneOptions,
                            "use-custom-slot": true,
                            "max-file-size-in-m-b": 10,
                            useCustomSlot: true
                          },
                          on: {
                            "vdropzone-success": _vm.afterSuccess,
                            "vdropzone-error": _vm.afterError,
                            "vdropzone-file-added": _vm.fileAdded,
                            "vdropzone-removed-file": _vm.fileRemoved,
                            "vdropzone-sending": _vm.sendingEvent
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dropzone-custom-content" },
                            [
                              _c(
                                "h3",
                                { staticClass: "dropzone-custom-title" },
                                [
                                  _vm._v(
                                    "Drag and drop to add files to the Submission"
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-link",
                                  attrs: { type: "button" }
                                },
                                [
                                  _vm._v(
                                    "...or click to select a file from your computer"
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.initRestrictedFileBrowser,
              expression: "initRestrictedFileBrowser"
            }
          ],
          staticClass: "idb-block-content"
        },
        [
          _vm.submissionLocked
            ? _c(
                "b-card",
                {
                  staticStyle: { "margin-bottom": "0.8em" },
                  attrs: { "bg-variant": "warning", "text-variant": "white" }
                },
                [
                  _vm._v(
                    "Another user: " +
                      _vm._s(_vm.lockingUsername) +
                      " is progressing this submission, it cannot be edited or progressed."
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", disabled: _vm.submissionLocked },
                on: { click: _vm.showRestrictedFileBrowser }
              },
              [_vm._v("Select Submission File")]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.useFtpFileBrowser,
              expression: "useFtpFileBrowser"
            }
          ],
          staticClass: "idb-block-content"
        },
        [
          _vm.submissionLocked
            ? _c(
                "b-card",
                {
                  staticStyle: { "margin-bottom": "0.8em" },
                  attrs: { "bg-variant": "warning", "text-variant": "white" }
                },
                [
                  _vm._v(
                    "Another user: " +
                      _vm._s(_vm.lockingUsername) +
                      " is progressing this submission, it cannot be edited or progressed."
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", disabled: _vm.submissionLocked },
                on: { click: _vm.btnFtpFileBrowser }
              },
              [_vm._v("Select Submission File")]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              staticClass: "table-unset",
              attrs: {
                paginationOptions: {
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false
                },
                rows: _vm.submissionDetails,
                columns: _vm.columns,
                lineNumbers: true,
                "sort-options": {
                  enabled: true,
                  initialSortBy: { field: "paymentFilename", type: "asc" }
                },
                styleClass: "vgt-table striped bordered ",
                rowStyleClass: _vm.rowStyler
              },
              on: { "on-row-click": _vm.onRowClick },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "bureauCustomerName"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("model-list-select", {
                                    staticClass: "addFiles-tall-dropdown",
                                    attrs: {
                                      isDisabled:
                                        _vm.submissionLocked ||
                                        _vm.isLoading ||
                                        _vm.loading ||
                                        _vm.selectedRow
                                          .bureauSubmissionDetailId !==
                                          props.row.bureauSubmissionDetailId,
                                      id: props.row.bureauCustomerId,
                                      list: _vm.BureauCustomersDisplay,
                                      label: "name",
                                      "option-value": "bureauCustomerId",
                                      "option-text": "name",
                                      placeholder: "Select Customer"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateBureauCustomer(
                                          props.row
                                        )
                                      }
                                    },
                                    model: {
                                      value: props.row.bureauCustomerId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.row,
                                          "bureauCustomerId",
                                          $$v
                                        )
                                      },
                                      expression: "props.row.bureauCustomerId"
                                    }
                                  }),
                                  _c("b-input-group-append", [
                                    props.row.status == "Uploaded" &&
                                    props.row.schema != "Standard18" &&
                                    !_vm.submissionLocked &&
                                    _vm.selectedRow.bureauSubmissionDetailId ===
                                      props.row.bureauSubmissionDetailId
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-sm primary mt-1 reset-customer",
                                            on: {
                                              click: function($event) {
                                                _vm.submissionLocked
                                                  ? null
                                                  : _vm.onResetSelectedCustomer(
                                                      props.row
                                                    )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times"
                                            }),
                                            _c("span", {
                                              staticClass: "sr-only"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ],
                                1
                              ),
                              (props.row.importSchema === "Mapping" ||
                                props.row.importSchema === "Legacy") &&
                              props.row.bureauCustomerId ===
                                "00000000-0000-0000-0000-000000000000"
                                ? _c("small", { staticClass: "text-danger" }, [
                                    _vm._v("Please select a customer")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : props.column.field == "schema"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "12" } },
                                    [
                                      _c("b-form-select", {
                                        staticClass:
                                          "addFiles-tall-dropdown schemaControl",
                                        attrs: {
                                          id:
                                            "schemaSelector-" +
                                            props.row.bureauSubmissionDetailId,
                                          options: _vm.schemas,
                                          isDisabled:
                                            props.row.status != "Uploaded" ||
                                            _vm.submissionLocked ||
                                            _vm.isLoading ||
                                            _vm.loading ||
                                            _vm.selectedRow
                                              .bureauSubmissionDetailId !==
                                              props.row
                                                .bureauSubmissionDetailId,
                                          disabled:
                                            props.row.status != "Uploaded" ||
                                            _vm.submissionLocked ||
                                            _vm.isLoading ||
                                            _vm.loading ||
                                            _vm.selectedRow
                                              .bureauSubmissionDetailId !==
                                              props.row
                                                .bureauSubmissionDetailId,
                                          placeholder: "Schema"
                                        },
                                        on: { blur: props.row.importSchema },
                                        model: {
                                          value: props.row.importSchema,
                                          callback: function($$v) {
                                            _vm.$set(
                                              props.row,
                                              "importSchema",
                                              $$v
                                            )
                                          },
                                          expression: "props.row.importSchema"
                                        }
                                      }),
                                      props.row.importSchema === "None"
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "Please select an import schema"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : props.column.field == "mapping"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "12" } },
                                    [
                                      _c("model-list-select", {
                                        staticClass: "addFiles-tall-dropdown",
                                        attrs: {
                                          id:
                                            "mappingSelector-" +
                                            props.row.bureauSubmissionDetailId,
                                          list: _vm.mappings,
                                          "option-value": "value",
                                          "option-text": "text",
                                          placeholder: "Mapping",
                                          isDisabled:
                                            props.row.status != "Uploaded" ||
                                            props.row.importSchema !=
                                              "Mapping" ||
                                            _vm.submissionLocked ||
                                            _vm.isFps ||
                                            _vm.selectedRow
                                              .bureauSubmissionDetailId !==
                                              props.row.bureauSubmissionDetailId
                                        },
                                        on: {
                                          input: function($event) {
                                            props.row.edited = true
                                          }
                                        },
                                        model: {
                                          value: props.row.mapping,
                                          callback: function($$v) {
                                            _vm.$set(props.row, "mapping", $$v)
                                          },
                                          expression: "props.row.mapping"
                                        }
                                      }),
                                      props.row.importSchema === "Mapping" &&
                                      (props.row.mapping === null ||
                                        props.row.mapping === "") &&
                                      !_vm.isLoading &&
                                      !_vm.loading
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v("Please select a mapping")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : props.column.field == "paymentDate"
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "12" } },
                                    [
                                      _c("datepicker", {
                                        ref: "props.row.paymentDate",
                                        attrs: {
                                          disabled:
                                            _vm.submissionLocked ||
                                            _vm.isLoading ||
                                            _vm.loading ||
                                            _vm.selectedRow
                                              .bureauSubmissionDetailId !==
                                              props.row
                                                .bureauSubmissionDetailId,
                                          name:
                                            "paymentDate-" +
                                            props.row.bureauSubmissionDetailId,
                                          id:
                                            "paymentDate-" +
                                            props.row.bureauSubmissionDetailId,
                                          format: "dd/MM/yyyy",
                                          readonly: false,
                                          disabledDates: _vm.disabledDates,
                                          "bootstrap-styling": true,
                                          "monday-first": true
                                        },
                                        on: {
                                          selected: function($event) {
                                            return _vm.paymentDateChanged(
                                              props.row
                                            )
                                          }
                                        },
                                        nativeOn: {
                                          focusin: function($event) {
                                            return _vm.dateOnfocusSE(this)
                                          }
                                        },
                                        model: {
                                          value: props.row.paymentDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              props.row,
                                              "paymentDate",
                                              $$v
                                            )
                                          },
                                          expression: "props.row.paymentDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : props.column.field == "delete"
                        ? _c("span", [
                            _vm.selectedRow.bureauSubmissionDetailId !==
                            props.row.bureauSubmissionDetailId
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn w-100 mb-1 btn-primary btn-sm",
                                    attrs: {
                                      disabled:
                                        _vm.isLoading ||
                                        _vm.submissionLocked ||
                                        _vm.isLoading ||
                                        _vm.loading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.onRowClick(props)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-edit mr-2" }),
                                    _vm._v("Edit")
                                  ]
                                )
                              : _vm._e(),
                            _vm.selectedRow.bureauSubmissionDetailId ===
                            props.row.bureauSubmissionDetailId
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn w-100 mb-1 btn-success btn-sm",
                                    attrs: {
                                      disabled:
                                        _vm.isLoading ||
                                        _vm.submissionLocked ||
                                        _vm.isLoading ||
                                        _vm.loading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.updateSubmissionDetail(
                                          props.row
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-save mr-2" }),
                                    _vm._v("Save")
                                  ]
                                )
                              : _vm._e(),
                            _vm.selectedRow.bureauSubmissionDetailId ===
                            props.row.bureauSubmissionDetailId
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn w-100 mb-1 btn-warning btn-sm",
                                    attrs: {
                                      disabled:
                                        _vm.isLoading ||
                                        _vm.submissionLocked ||
                                        _vm.isLoading ||
                                        _vm.loading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.cancelRowUpdate(props.row)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times mr-2"
                                    }),
                                    _vm._v("Cancel")
                                  ]
                                )
                              : _vm._e(),
                            _vm.selectedRow.bureauSubmissionDetailId ===
                            props.row.bureauSubmissionDetailId
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger btn-sm w-100",
                                    attrs: {
                                      disabled:
                                        _vm.isLoading ||
                                        _vm.submissionLocked ||
                                        _vm.isLoading ||
                                        _vm.loading
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.submissionLocked
                                          ? null
                                          : _vm.onDeleteFileClick(props.row)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash mr-2"
                                    }),
                                    _vm._v("Delete ")
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { sm: "12" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "loadingContent" }, [
                _c("h1", [_vm._v("Loading...")])
              ]),
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Clear filters")
                          ])
                        ]
                      ),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Clear filters")
                      ])
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Refresh the data in the table",
                          expression: "'Refresh the data in the table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.load($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-sync pointer dimmedIcon" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Refresh data")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("div", { staticClass: "d-block py-3" })
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            key: _vm.fileCount,
            staticClass: "btn btn-primary mr-2",
            attrs: {
              disabled:
                _vm.submissionDetails.length === 0 ||
                _vm.submissionLocked ||
                _vm.noSchemaSelected ||
                _vm.noMappingSelected ||
                _vm.noCustomerSelected ||
                _vm.customerNoBankSelected
            },
            on: { click: _vm.ImportManualBacsSubmission }
          },
          [_vm._v("Import Submission")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-secondary mr-2",
            attrs: { disabled: _vm.submissionLocked || _vm.noSchemaSelected },
            on: { click: _vm.EditManualBacsSubmission }
          },
          [_vm._v("Change Submission")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-secondary mr-2",
            attrs: { disabled: _vm.submissionLocked || _vm.noSchemaSelected },
            on: { click: _vm.onCreateJobFromManualSubmissionClick }
          },
          [_vm._v("Save as Automated Job")]
        )
      ]),
      _c(
        "div",
        [
          _c("createJobFromManualSubmissionModal", {
            attrs: { manualBureauJobId: _vm.id },
            on: { submit: _vm.createJobFromManualSubmissionClosed }
          })
        ],
        1
      ),
      _c("plugin", { on: { installCancelled: _vm.pluginNotInstalled } }),
      _vm.showFtpFileBrowser
        ? _c(
            "fileBrowser",
            {
              attrs: { groupId: _vm.groupId },
              on: {
                close: function($event) {
                  return _vm.closeFileBrowserPopup()
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Select Submission File")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }