<template>
    <div>
        <content-block>
            <h2 slot="title"><help-icon docPath="/paygate-collections/messaging/messagequeue/" />Message Queue<favourite-icon></favourite-icon></h2>
            <messageQueue :initialFilter="initialFilter" />
        </content-block>
    </div>
</template>
<script>
import ContentBlock from '@/Assets/Components/ContentBlock'
import Components from '@/Components/Collections'
export default {
  data () {
    return {
      initialFilter: ''
    }
  },  
  components: {
    ContentBlock,
    messageQueue: Components.Messaging.MessageQueue
  },
  mounted () {
    this.initialFilter = this.$route.query.searchTerm
  }
}
</script>
