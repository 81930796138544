<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Real-time Telemetry
            <span class="pull-right">
              <a href="#" target="_blank">
                <i class="far fa-question-circle"></i>
              </a>
            </span>
            <span class="pull-right" @click="standardOrder = !standardOrder">
              <i v-if="standardOrder" class="fa fa-arrow-circle-up mr-2"></i>
              <i v-else class="fa fa-arrow-circle-down mr-2"></i>
            </span>
            <span class="pull-right" @click="isGridStyle = !isGridStyle">
              <i v-if="isGridStyle" class="fa fa-table mr-2"></i>
              <i v-else class="fa fa-align-justify mr-2"></i>
            </span>
          </h2>
        </div>
        <div class="idb-block-content">
          <div>
            <modal name="hello-world">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="idb-block">
                    <div class="idb-block-title">
                      <h2>
                        Configure Node
                        <span class="pull-right">
                          <b-button size="sm" variant="primary">
                            <i class="fa fa-save mr-2"></i>Save
                          </b-button>
                        </span>
                      </h2>
                    </div>
                  </div>
                  <div class>
                    <!-- <form-generator :schema="nodeUI"
                        v-model="formData">
                    </form-generator>-->
                  </div>
                </div>
              </div>
            </modal>
            <div v-if="isGridStyle">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-md-2">Data</div>
                    <div class="col-md-1">Node</div>
                    <div class="col-md-3">Message</div>
                    <div class="col-md-2">Trigger</div>
                    <div class="col-md-2">Workflow</div>
                    <div class="col-md-2">Id</div>
                  </div>
                </li>
              </ul>
              <ul class="list-group" v-if="standardOrder">
                <li
                  class="list-group-item"
                  v-for="(message, index) in messages.slice().reverse()"
                  :key="index"
                >
                  <div class="row">
                    <div class="col-md-2">
                      <span>
                        <i class="fa fa-1x" v-bind:class="message.icon"></i>
                        {{ reformatDate(message.datetime) }}
                      </span>
                    </div>
                    <div class="col-md-1">{{ message.title }}</div>
                    <div class="col-md-3">{{ message.message }}</div>
                    <div class="col-md-2">
                      <router-link
                        tag="a"
                        :to="'triggerconfig/scheduleTrigger/' + message.triggerId"
                        class
                      >{{ message.triggerTitle }}</router-link>
                    </div>
                    <div class="col-md-2">
                      <router-link
                        tag="a"
                        :to="'./../workflow/designer/' + message.workflowId"
                        class
                      >{{ message.workflowTitle }}</router-link>
                    </div>
                    <div class="col-md-2">{{ message.executionId }}</div>
                  </div>
                </li>
              </ul>
              <ul class="list-group" v-else>
                <li class="list-group-item" v-for="(message, index) in messages" :key="index">
                  <div class="row">
                    <div class="col-md-2">
                      <span>
                        <i class="fa fa-1x" v-bind:class="message.icon"></i>
                        {{ reformatDate(message.datetime) }}
                      </span>
                    </div>
                    <div class="col-md-1">{{ message.title }}</div>
                    <div class="col-md-3">{{ message.message }}</div>
                    <div class="col-md-2">
                      <router-link
                        tag="a"
                        :to="'triggerconfig/scheduleTrigger/' + message.triggerId"
                        class
                      >{{ message.triggerTitle }}</router-link>
                    </div>
                    <div class="col-md-2">
                      <router-link
                        tag="a"
                        :to="'./../workflow/designer/' + message.workflowId"
                        class
                      >{{ message.workflowTitle }}</router-link>
                    </div>
                    <div class="col-md-2">{{ message.executionId }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul v-if="standardOrder">
                <li
                  class="consoleFont"
                  v-for="(message, index) in messages.slice().reverse()"
                  :key="index"
                >
                  <div v-if="message.message !==''">
                    {{ reformatDate(message.datetime) }}
                    {{ message.message }}
                  </div>
                  <div v-else>
                    {{ reformatDate(message.datetime) }}
                    {{ message.title }}
                  </div>
                </li>
              </ul>
              <ul v-else>
                <li class="consoleFont" v-for="(message, index) in messages" :key="index">
                  <div v-if="message.message !==''">
                    {{ reformatDate(message.datetime) }}
                    {{ message.message }}
                  </div>
                  <div v-else>
                    {{ reformatDate(message.datetime) }}
                    {{ message.title }}
                  </div>
                </li>
              </ul>
            </div>
            <br />
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row">
            <div class="col-md-12 col-sm-3">
              <b-button @click.prevent="btnSocket" variant="primary">
                <i class="fa fa-download mr-2"></i>Button
              </b-button>
              <!-- <b-button @click.prevent="testTrigger" variant="outline-secondary" title="Test trigger" v-b-popover.hover.top="'Test this trigger in sandbox (test) mode'">Test</b-button>  -->
              <b-button
                @click.prevent="deleteTrigger"
                variant="danger"
                class="pull-right"
                title="Delete"
                v-b-popover.hover.top.d500="'Wow'"
              >
                <i class="fa fa-trash mr-2"></i>Delete
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import FormGenerator from '@/Components/Workflow/Workflow/FormGenerator'

export default {
  name: 'my-component',
  // components: {
  //   FormGenerator
  // },
  // sockets: {
  //   connect: function () {
  //     console.log('socket connected')
  //   },
  //   // customEmit: function (val) {
  //   //   console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
  //   // },
  //   messages: function (val) {
  //     console.log(val)
  //     this.messages.push(val)
  //     if (this.messages.length > this.maxItemsToShow) {
  //       this.messages.shift()
  //     }
  //   }
  // },
  data () {
    return {
      messages: [],
      maxItemsToShow: 64,
      standardOrder: true,
      isGridStyle: true
    }
  },
  methods: {
    // changeOrder () {
    //   console.log('change')
    // },
    btnSocket: function (val) {
      // $socket is socket.io-client instance
      // this.$socket.emit('messages', 'Hi!')
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    }
  }
}
</script>

<style scoped>
  .dimmedIcon {
    color: #666666;
  }
  .pointer {
    cursor: pointer;
  }

  ul {
    list-style-type: none;
  }

  .consoleFont {
    font-family: "Courier New", Courier, monospace;
  }
</style>
