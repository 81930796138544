var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [
            _vm._v("All Telemetry"),
            _c("span", { staticClass: "pull-right" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.docUrl + "/automation/logging/",
                    target: "_blank"
                  }
                },
                [_c("i", { staticClass: "far fa-question-circle" })]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "div",
            [
              _c(
                "vue-good-table",
                {
                  attrs: {
                    mode: "remote",
                    totalRows: _vm.totalRecords,
                    columns: _vm.columns,
                    rows: _vm.rows,
                    lineNumbers: true,
                    "pagination-options": {
                      dropdownAllowAll: false,
                      enabled: true,
                      perPageDropdown: [10, 20, 100]
                    }
                  },
                  on: {
                    "on-page-change": _vm.onPageChange,
                    "on-sort-change": _vm.onSortChange,
                    "on-column-filter": _vm.onColumnFilter,
                    "on-per-page-change": _vm.onPerPageChange,
                    "on-cell-click": _vm.onCellClick
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "createdAt"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.reformatDate(
                                        props.formattedRow[props.column.field]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : props.column.field == "updatedAt"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.reformatDate(
                                        props.formattedRow[props.column.field]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : props.column.field == "workflowId"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "View the telemetry for this workflow instance.",
                                          expression:
                                            "'View the telemetry for this workflow instance.'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      staticClass: "pointer",
                                      attrs: {
                                        variant: "link",
                                        title: "View Telemetry"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.viewTelemetry(props)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-secondary" },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "glyphicon ti-view-list"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : props.column.field == "title"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "pointer",
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.viewWorkflow(props)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(
                    ' styleClass="vgt-table striped bordered table-hover" > '
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Refresh the data in the table",
                              expression: "'Refresh the data in the table'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { variant: "link", title: "Refresh Table" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.loadItems($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-sync-alt pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Refresh Table")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "idb-block-footer" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-sm-3" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value:
                          "Permanently deletes this trigger's telemetry data.",
                        expression:
                          "'Permanently deletes this trigger\\'s telemetry data.'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    staticClass: "pull-right",
                    attrs: { variant: "danger", title: "Delete Telemetry" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.deleteTrigger($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "glyphicon ti-trash mr-2" }),
                    _vm._v("Delete Telemetry")
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("br"),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }