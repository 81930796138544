<template>
  <b-modal
    id="manage-block-modal"
    ref="manageBlockModal"
    title="Block Details"
    size="xl"
    hide-header-close
    static
    cancel-variant="danger"
    @ok="SaveBlockDetail"
    @shown="loadBlockDetails"
    @hidden="clearForm"
    :ok-disabled="$v.$invalid"
  >
    <div slot="modal-ok">Save</div>
    <div>
      <!-- Customer -->
      <b-row class="mt-2" v-if="isNew">
        <b-col sm="4" class="mt-2">
          <label for="customerName">Originating Customer</label>
        </b-col>
        <b-col sm="7">
          <model-list-select
            id="customerName"
            :list="bureauCustomers"
            v-model="$v.bureauCustomerId.$model"
            option-value="bureauCustomerId"
            option-text="name"
            placeholder="Select Customer"
            :disabled="readonly"
            @input="selectBureauCustomer()"
          ></model-list-select>
        </b-col>
      </b-row>

      <!-- Customer Name - Existing Block-->
      <b-row class="mt-2" v-if="!isNew">
        <b-col sm="4" class="mt-2">
          <label for="customerName">Originating Customer</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="customerName"
            readonly
            type="text"
            v-model.trim="$v.customerName.$model"
          ></b-form-input>
          <!-- Validation -->
          <span v-if="$v.serviceUserNumber.$dirty">
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.maxLength"
            >Service User Number must be less than {{$v.serviceUserNumber.$params.maxLength.max}} characters</label>
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.required"
            >Service User Number is required</label>
          </span>
        </b-col>
      </b-row>

      <!-- Service User Number -->
      <b-row class="mt-2" v-if="isNew">
        <b-col sm="4" class="mt-2">
          <label class="required" for="serviceUserNumber">Service User Number</label>
        </b-col>
        <b-col sm="3">
          <b-form-input
            id="serviceUserNumber"
            type="text"
            v-model.trim="$v.serviceUserNumber.$model"
            :formatter="upperCase"
            :disabled="true"
          ></b-form-input>
          <!-- Validation -->
          <span v-if="$v.serviceUserNumber.$dirty">
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.maxLength"
            >Service User Number must be less than {{$v.serviceUserNumber.$params.maxLength.max}} characters</label>
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.required"
            >Service User Number is required</label>
          </span>
        </b-col>
      </b-row>

      <!-- Sort Code -->
      <b-row class="mt-2" v-if="isNew">
        <b-col sm="4" class="mt-2">
          <label class="required" for="sortCode">Sort Code</label>
        </b-col>
        <b-col sm="2">
          <the-mask
            id="sortCode"
            type="text"
            class="form-control"
            v-model.trim="$v.sortCode.$model"
            :mask="['##-##-##']"
            :guide="false"
            ref="sortCode"
            :disabled="!noBankDetailsForCustomer"
            :key="updateKey"
          ></the-mask>
          <!-- Validation -->
          <span v-if="$v.sortCode.$dirty">
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.sortCode.required"
            >Sort Code is required</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.sortCode.minLength"
            >Sort Code must be at least {{$v.sortCode.$params.minLength.min}} numbers</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.sortCode.maxLength"
            >Sort Code must be less than {{$v.sortCode.$params.maxLength.max}} numbers</small>
            <small
              class="form-text text-warning small text-nowrap"
              v-if="!sortCodeValid && $v.sortCode.required"
            >{{ sortCodeErrorMessage }}</small>
          </span>
        </b-col>
        <span>
          <b-col v-if="sortCodeValid">
            <i class="fas fa-check mt-2"></i>
          </b-col>
        </span>
      </b-row>

      <!-- Account Number -->
      <b-row class="mt-2" v-if="isNew">
        <b-col sm="4" class="mt-2">
          <label class="required" for="accountNumber">Account Number</label>
        </b-col>
        <b-col sm="2">
          <the-mask
            id="accountNumber"
            type="text"
            class="form-control"
            v-model.trim="$v.accountNumber.$model"
            :mask="['########']"
            ref="originatingAccountNumber"
            :disabled="!noBankDetailsForCustomer"
          ></the-mask>
          <!-- Validation -->
          <span v-if="$v.accountNumber.$dirty">
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.accountNumber.required"
            >Account Number is required</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.accountNumber.minLength"
            >Account Number must be at least {{$v.accountNumber.$params.minLength.min}} numbers</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.accountNumber.maxLength"
            >Account Number must be less than {{$v.accountNumber.$params.maxLength.max}} numbers</small>
            <small
              class="form-text text-warning small text-nowrap"
              v-if="!accountNumberValid && $v.accountNumber.required"
            >{{ accountNumberErrorMessage }}</small>
          </span>
        </b-col>
        <span>
          <b-col v-if="accountNumberValid==true">
            <i class="fas fa-check mt-2"></i>
          </b-col>
        </span>
      </b-row>

      <!-- Contra Narrative -->
      <b-row class="mt-2" v-if="false">
        <b-col sm="4" class="mt-2">
          <label for="contraNarrative">Contra Narrative</label>
        </b-col>
        <b-col sm="4">
          <b-form-input
            id="contraNarrative"
            type="text"
            disabled
            v-model.trim="$v.contraNarrative.$model"
            :formatter="upperCase"
          ></b-form-input>
          <!-- Validation -->
          <span v-if="$v.contraNarrative.$dirty">
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.contraNarrative.maxLength"
            >Contra Narrative must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
          </span>
        </b-col>
      </b-row>

      <!-- Processing Date -->
      <b-row class="mt-2" v-if="!isFps">
        <b-col sm="4" class="mt-2">
          <label for="modalPaymentDate">Block Payment Date</label>
        </b-col>

        <b-col sm="7">
          <vuejsDatepicker
            ref="paymentDate"
            name="paymentDate"
            id="modalPaymentDate"
            v-model="paymentDate"
            format="dd/MM/yyyy"
            input-class="form-control"
            :bootstrap-styling="true"
            :disabledDates="disabledDates"
            v-on:selected="paymentDateChanged"
            @focusin.native="dateOnfocus"
            :monday-first="true"
            :disabled="locked"
          ></vuejsDatepicker>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="!isFps">
        <b-col sm="4" class="mt-2">
          <label for="modalProcessingDate">Block Processing Date</label>
        </b-col>
        <b-col sm="7">
          <input class="form-control" id="modalProcessingDate" v-model="processingDate" disabled />
        </b-col>
      </b-row>

      <hr v-if="validationErrors.length > 0" />

      <!-- Block validation errors -->
      <b-row class="mt-2" v-if="validationErrors.length > 0">
        <b-col class="mt-2">
          <label>Block Validation Warnings and Errors</label>
        </b-col>
      </b-row>
      <b-row v-for="validationError in validationErrors" :key="validationError">
        <b-col sm="12">
          <span class="small">{{ validationError }}</span>
        </b-col>
      </b-row>

      <b-row hidden>
        <b-col>{{ $v.$invalid }}</b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import { ModelListSelect } from 'vue-search-select'
import Utility from '@/Assets/Mixins/Utility'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [Utility, bacsMixin],
  props: {
    itemId: String,
    submissionId: String,
    bureauJobId: String,
    // readonly: Boolean,
    isNew: Boolean,
    locked: Boolean,
    submissionContra: { type: String, default: 'CONTRA' },
    isFps: Boolean
  },
  components: {
    ModelListSelect,
    TheMask
  },

  data () {
    return {
      bureauCustomers: [],
      disabledDates: {},
      sortCodeValid: false,
      accountNumberValid: false,
      validationErrors: [],
      fileNumber: 0,
      bureauCustomerId: '',
      serviceUserNumber: '',
      bankReference: '',
      sortCode: '',
      accountNumber: '',
      contraNarrative: '',
      processingDate: '',
      paymentDate: '',
      sortCodeErrorMessage: '',
      accountNumberErrorMessage: '',
      readonly: false,
      noBankDetailsForCustomer: false,
      updateKey: 0
    }
  },
  watch: {
    sortCode: function () {
      this.bankAccountValidation()
    },
    accountNumber: function () {
      this.bankAccountValidation()
    }
  },
  async created () {
    console.info('created manage block modal')
  },
  async mounted () {
    this.disabledDates = await this.setUpBACSDatePicker()
  },
  methods: {
    async SaveBlockDetail () {
      if (this.isNew) {
        var data = {
          submissionId: this.submissionId,
          bureauCustomerId: this.bureauCustomerId,
          serviceUserNumber: this.serviceUserNumber,
          contraNarrative: this.contraNarrative,
          bankReference: this.bankReference,
          sortCode: this.sortCode,
          accountNumber: this.accountNumber,
          processingDate: this.processingDate
        }
        var responseAdd = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/addmanualblocktosubmission', data, { showload: true })

        if (responseAdd.data.toastType === 2) {
          this.$toastr.s(responseAdd.data.toastMessage)
        } else {
          this.$toastr.e(responseAdd.data.toastMessage)
        }
      } else {
        var params = {
          accountNumber: this.accountNumber,
          sortCode: this.sortCode,
          serviceUserNumber: this.serviceUserNumber,
          contraNarrative: this.contraNarrative,
          processingDate: this.processingDate,
          fileNumber: this.fileNumber,
          bureauCustomerId: this.bureauCustomerId,
          itemId: this.itemId,
          submissionId: this.submissionId
        }
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/saveblockdetails', params, { showload: true })
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      }
      this.$emit('submit')
    },
    async loadBlockDetails () {
      this.disabledDates = await this.setUpBACSDatePicker()
      if (this.isNew) {
        this.title = 'New Block'
        this.customerName = ''
        this.fileNumber = -1
        this.accountNumber = ''
        this.sortCode = ''
        this.serviceUserNumber = ''
        this.contraNarrative = ''
        try {
          console.info('Load customers in job', this.bureauJobId)
          var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getbureaucustomersforsubmission', { showload: true })
          console.info('Load customers in job', response.data)
          this.bureauCustomers = response.data
        } catch (error) {
          console.error('Error in ManageBlockDetails', error)
          console.error(error)
        }
        this.processingDate = ''
        this.paymentDate = ''
        var nextDateResponse = await this.getNextPaymentDate()
        this.bureauCustomerId = ''
        this.paymentDate = nextDateResponse
        if (this.paymentDate !== '') {
          var procDateResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + nextDateResponse.split('+')[0].split(' ')[0])

          this.processingDate = procDateResponse.data
        }
        this.$v.$reset()
      } else {
        var params = {
          itemId: this.itemId,
          submissionId: this.submissionId
        }
        var blockDetailsResponse = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getblockdetailinfo/', params, { showload: true })
        this.title = blockDetailsResponse.data.filename
        this.customerName = blockDetailsResponse.data.name
        this.fileNumber = blockDetailsResponse.data.fileNumber
        this.accountNumber = blockDetailsResponse.data.accountNumber
        this.sortCode = blockDetailsResponse.data.sortCode
        this.serviceUserNumber = blockDetailsResponse.data.serviceUserNumber
        this.contraNarrative = blockDetailsResponse.data.contraNarrative
        this.paymentDate = blockDetailsResponse.data.paymentDate
        this.processingDate = blockDetailsResponse.data.processingDateDisplay
        this.bureauCustomerId = blockDetailsResponse.data.bureauCustomerId
        // this.readonly = this.readonly
        // this.isNew = false
      }
      this.updateKey++
    },
    async paymentDateChanged (value) {
      var valasjson = value.toJSON()
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + value.toJSON())
      this.processingDate = response.data
    },
    async selectBureauCustomer () {
      console.info('SelectBureauCustomer')
      console.info(this.bureauCustomerId)
      const bureauCustomer = this.bureauCustomers.find(o => o.bureauCustomerId === this.bureauCustomerId)
      this.serviceUserNumber = bureauCustomer.serviceUserNumber
      const url = process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/' + bureauCustomer.bureauCustomerId
      const response = await axios.get(url, { showload: true })
      console.info(response)
      this.originatingBankReference = response.data.bankName

      // We call this little line of code a _hack_ 😅
      this.$refs.sortCode.lastValue = null

      this.sortCode = response.data.sortCode
      this.accountNumber = response.data.accountNumber
      this.contraNarrative = response.data.contraNarrative
      if (response.data.contraNarrative === '') {
        this.contraNarrative = this.submissionContra
      }
      this.noBankDetailsForCustomer = response.data.sortCode === '' || response.data.accountNumber === ''
    },
    upperCase (value, event) {
      return value.toUpperCase()
    },
    bankAccountValidation: function () {
      if (!this.$v.sortCode.$invalid && !this.$v.sortCode.$invalid) {
        this.validate()
      } else {
        this.validationResult = null
      }
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.sortCode }

      if (this.accountNumber.length === 8) {
        data.accountNumber = this.accountNumber
      }

      try {
        const validateUrl = process.env.VUE_APP_VALIDATE_API_URL + 'BankAccount'
        const response = await axios.get(validateUrl, { params: data }, { showload: true })

        this.validationResult = response.data
        this.sortCodeErrorMessage = ''
        this.accountNumberErrorMessage = ''
        if (this.validationResult.valid) {
          console.info('Sort code and account number are valid')
          this.sortCodeValid = true
          this.accountNumberValid = true
        } else {
          if (this.validationResult.errorCode === '1502') {
            console.info('Sort code is valid')
            this.sortCodeValid = true
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          } else {
            console.info('Sort code and account number are invalid')
            this.sortCodeValid = false
            this.sortCodeErrorMessage = this.validationResult.errorText
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          }
        }
        if (this.validationResult.valid || this.validationResult.errorCode === '1502') {
        }
      } catch (e) {
      }
    }, 800),
    clearForm () {
      this.bureauCustomers = []
      this.disabledDates = {}
      this.sortCodeValid = false
      this.accountNumberValid = false
      this.validationErrors = []
      this.fileNumber = 0
      this.bureauCustomerId = ''
      this.serviceUserNumber = ''
      this.bankReference = ''
      this.sortCode = ''
      this.accountNumber = ''
      this.contraNarrative = ''
      this.processingDate = ''
      this.paymentDate = ''
      this.sortCodeErrorMessage = ''
      this.accountNumberErrorMessage = ''
      this.readonly = false
      this.$v.$reset()
    }
  },
  validations: {
    bureauCustomerId: { required },
    bankReference: {},
    customerName: {},
    serviceUserNumber: { minLength: minLength(6), maxLength: maxLength(6), required },
    sortCode: { required, minLength: minLength(6), maxLength: maxLength(6) },
    accountNumber: { minLength: minLength(8), maxLength: maxLength(8), required },
    contraNarrative: { maxLength: maxLength(18) },
    processingDate: {}
  }
}
</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}
</style>
