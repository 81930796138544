var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "LicensingTableTests" } }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", { staticClass: "text-danger" }, [
            _vm._v("Bureau Licensing Table Test")
          ])
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [_vm._v("Bureau License Testing")])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("h2", [_vm._v("Hello")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }