<template>
  <div id="downloadSubmission">
    <loading v-bind:loading="loading"></loading>
    <div class="row form-group" v-if="!loading">
      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="downloadSubmission"
          :disabled="downloadButtonDisabled"
        >Download Submission File</button>
      </div>
      <div class="col-md-10">
        <button
          type="button"
          class="btn btn-light pull-right"
          v-on:click="cancelSubmission"
          :disabled="this.canCancel === false && downloadButtonDisabled"
        >Cancel Submission</button>
      </div>
    </div>
  </div>
</template>
<script>
import bacsCommon from '@/Lib/BacsCommon.js'
import axios from 'axios'
const promiseWhile = (data, condition, action) => {
  var whilst = (data) => {
    return condition(data) ? action(data).then(whilst) : Promise.resolve(data)
  }
  return whilst(data)
}
export default {
  data () {
    return {
      loading: false,
      submissionId: null,
      submissionData: {},
      groupId: null,
      canCancel: true,
      actionId: null,
      bacsGroup: null,
      paymentBlockSize: 1000,
      intelligibleSummaries: {},
      fromRecordNumber: 0,
      toRecordNumber: 0,
      standard18: '',
      includeVol1AndXml: true,
      paygateType: '',
      dataReturned: false,
      fileNumber: 1,
      userPin: '',
      submissionStatus: 0
    }
  },
  methods: {
    async downloadSubmission () {
      this.getAllStandard18()
    },
    async cancelSubmission () {
      this.isLoaded = false
      var cancelReponse = await bacsCommon.cancelSubmissionDialog()
      if (cancelReponse.cancel === true) {
        this.bacsGroup = this.$store.getters.bacsGroup

        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId,
          serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
          fileNumber: 0,
          fromRecordNumber: 0,
          toRecordNumber: 0,
          deletePaymentData: this.bacsGroup.groupTypeDetails.deletePaymentData,
          cancellationReason: cancelReponse.cancellationReason
        })

        await bacsCommon.confirmCancellation(json, axios, this.$toastr, this.$router)
        await this.$store.dispatch('setCollectionsSubmissionDownloaded', this.submissionId)
        this.submissionStatus = await this.$store.dispatch('getSubmissionStatus', this.submissionId)
      } else {
        this.isLoaded = true
      }
    },
    async getAllStandard18 () {
      this.showeSigner = false
      this.eSignerHtml = ''
      this.fileNumber = 1
      this.fromRecordNumber = 1
      this.toRecordNumber = this.paymentBlockSize
      var json = this.createJson()
      console.log(json)
      try {
        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getStandard18',
          data: json
        })
        if (response) {
          this.outputFile(response.data)
          await this.$store.dispatch('setCollectionsSubmissionDownloaded', this.submissionId)
        }
      } catch (error) { console.log(error) }
    },

    outputFile (outputString) {
      var blob = new Blob([outputString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `Collections-Submission-${this.generateFileDate()}.txt`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = `Collections-Submission-${this.generateFileDate()}.txt`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },

    async getStandard18Block () {
      return new Promise(
        (resolve, reject) => {
          var json = this.createJson()
          console.log('json: ' + json)
          return axios({
            method: 'POST',
            url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getStandard18Block',
            data: json
          })
            .then(response => {
              this.updateCounters()
              resolve(response.data)
            })
            .catch(error => console.log(error))
        }
      )
    },

    // Used when generating Standard18 data in blocks so API knows the next section of the submission data to use.
    updateCounters () {
      if (this.toRecordNumber > this.intelligibleSummaries[this.fileNumber - 1].totalRecordCount) {
        // Got all payment data for current file block.
        // Now check whether all files have been processed.
        if (this.fileNumber < this.intelligibleSummaries.length) {
          // Multi block file so start getting data for next file.
          this.fileNumber++
          this.fromRecordNumber = 1
          this.toRecordNumber = this.paymentBlockSize
        } else {
          // No more files to process so don't get anymore payment data.
          // this.moreData = false
          this.dataReturned = false
        }
      } else {
        // Haven't got all the payment data yet so update params to get next block of payments.
        this.fromRecordNumber += this.paymentBlockSize
        this.toRecordNumber += this.paymentBlockSize
      }
    },

    // Get the next file block in a multi-file submissions when generating Standard18 data in blocks.
    getNextFile (num) {
      this.dataReturned = true
      console.log('getNextFile file number: ' + this.fileNumber + ', num: ' + num)
      return new Promise((resolve, reject) => {
        // The "true" refers to initial value of this.dataReturned.
        // "i" refers to this.dataReturned from "getPaymentData" i.e. keep going until no more payment data is returned.
        return promiseWhile(true, i => i === true, this.getMorePluginStandard18)
          .then(() => resolve(num + 1))
      })
    },
    async getMorePluginStandard18 () {
      // console.log('1. getMorePluginStandard18')
      let data = await this.getStandard18Block()
      // console.log('2 dataReturned: ' + this.dataReturned)

      if (this.browser === 'IE') {
        // console.log('3 Sending data to ActiveX plugin: ' + data)
        this.plugin.SubmissionData = data
      } else {
        this.standard18 += data
      }

      return new Promise((resolve, reject) => {
        resolve(this.dataReturned)
      })
    },

    createJson () {
      var certThumbprint = ''
      if (this.bacsGroup.groupTypeDetails.certificate !== null && this.bacsGroup.groupTypeDetails.certificate !== undefined) {
        certThumbprint = this.bacsGroup.groupTypeDetails.certificate.thumbprint
      }

      return JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        fileNumber: this.fileNumber,
        fromRecordNumber: this.fromRecordNumber,
        toRecordNumber: this.toRecordNumber,
        certificateThumbprint: certThumbprint,
        paygateType: this.paygateType,
        includeVol1AndXml: this.includeVol1AndXml,
        createAction: true,
        userPin: this.userPin,
        hsmPinRequired: this.bacsGroup.groupTypeDetails.hsmPinRequired
      })
    },
    generateFileDate () {
      var date = new Date()
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${day}/${this.pad(monthIndex, 2)}/${year} ${this.pad(h, 2)}:${this.pad(m, 2)}:${this.pad(s, 2)}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  },
  computed: {
    downloadButtonDisabled () {
      return this.$store.getters.collectionsSubmissionStatus === 12
    }
  },
  async mounted () {
    this.loading = true
    let parentDataLoaded = false
    var id = window.setInterval(async () => {
      parentDataLoaded = this.$store.getters.parentDataLoaded
      if (parentDataLoaded === true) {
        window.clearInterval(id)
        this.submissionId = this.$store.getters.submissionId
        this.actionId = this.$store.getters.actionId
        this.bacsGroup = this.$store.getters.bacsGroup
        this.submissionData = this.$store.getters.submissionData
        console.log(this.$store.getters.submissionData)
        this.intelligibleSummaries = this.$store.getters.intelligibleSummaries
        for (var i = 0; i < this.intelligibleSummaries.length; i++) {
          this.totalNumberOfPayments += this.intelligibleSummaries[i].totalRecordCount
        }
        var bacsEngineConfig = this.$store.getters.bacsEngineConfig
        this.paygateType = bacsCommon.getPaygateType(this.bacsGroup.groupType)
        this.paymentBlockSize = bacsEngineConfig.paymentsPerBlock
        this.submissionStatus = await this.$store.dispatch('getSubmissionStatus', this.submissionId)
        this.loading = false
      }
    }, 500)
  }
}
</script>
