<template>
  <div class="row">
    <!-- BEGIN INBOX CONTENT -->
    <div class="col-md-3">
      <div class="table-responsive inbox">
        <table class="table">
          <tbody>
            <slot name="buttonSlot"></slot>
            <tr v-for="(message, i) in pagedMessage" :key="i" @click.prevent="selectMessage(message)" :class="getClass(message)">
            <td class="name"><p class="avatarFrame">{{getAvatar(message)}}</p></td>
            <td><router-link :to="message.customerMessage ? `/collections/payer/${message.senderId}/edit` : null">{{message.senderName}}</router-link><span v-if="!message.customerMessage">{{message.senderName}}</span><br/><div class="messageSelectorSmall">{{latestTitle(message)}}<br/>
            {{formatDate(message.threadDate)}}<br/>
            <div class="summary" v-html="concatContent(message)"></div></div></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-9">
      <div v-if="selectedMessage === null">
        Select a message to view
      </div>
      <div v-else>
        <div class="row"><div class="col-10"><h4>{{latestTitle(selectedMessage)}}</h4></div><div class="col-2 text-right"><b-button @click.prevent="reply" variant="primary"><i class="fa fa-reply"></i> Reply</b-button></div></div>
        <div v-for="(reply, i) in selectedMessage.replies" :key="i" :style="'padding-left: ' + (i * 20) + 'px'">
          <div class="row mb-2">
            <div class="col-1 text-center"><p class="avatarFrame">{{getAvatar(reply)}}</p></div>
            <div class="col-11">
              <router-link :to="reply.customerMessage ? `/collections/payer/${reply.senderId}/edit` : null">{{reply.senderName}}</router-link><span v-if="!reply.customerMessage">{{reply.senderName}}</span><br/><div class="messageSelectorSmall"><strong>{{latestTitle(reply)}}</strong><br/>
              {{formatDate(reply.displayDate)}}
              </div>
            </div>
          </div>
          <div v-html="reply.messageBody"></div>
          <hr/>
        </div>
        <div class="row mb-2" :style="'padding-left: ' + (selectedMessage.replies.length * 20) + 'px'">
          <div class="col-1 text-center"><p class="avatarFrame">{{getAvatar(selectedMessage)}}</p></div>
          <div class="col-11">
            <router-link :to="selectedMessage.customerMessage ? `/collections/payer/${selectedMessage.senderId}/edit` : null">{{selectedMessage.senderName}}</router-link><span v-if="!selectedMessage.customerMessage">{{selectedMessage.senderName}}</span><br/><div class="messageSelectorSmall"><strong>{{selectedMessage.messageTitle}}</strong><br/>
            {{formatDate(selectedMessage.displayDate)}}
            </div>
          </div>
        </div>
        <div v-html="selectedMessage.messageBody" :style="'padding-left: ' + (selectedMessage.replies.length * 20) + 'px'"></div>
        <p v-if="selectedMessage.paymentComposite && selectedMessage.paymentComposite !== ''"><router-link :to="`/collections/payer/${selectedMessage.senderId}/${selectedMessage.paymentPlanId}/schedule?actioncomposite=${selectedMessage.paymentComposite}`">View Collection</router-link></p>
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="currentMessageView.length"  v-if="currentMessageView.length >= pageCount" :per-page="pageCount" />
    <b-modal id="reply-compose-modal" size="xl" ref="reply-compose-modal">
      <template v-slot:modal-title>
        <h4 class="modal-title">Compose New Message</h4>
      </template>
      <div class="form-group">
        <input name="subject" type="email" v-model="messageTitle" class="form-control" placeholder="Subject" readonly>
      </div>
      <b-form-group>
        <label>Message Body</label>
        <wysiwyg ref="messageBodyControl" :suppressLoad="true" v-model="messageBody"></wysiwyg>
      </b-form-group>
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-default" @click="closeComposeModal"> Discard</button>
        <button type="button" class="btn btn-primary pull-right" @click="send" :disabled="isLoading"><i class="fa fa-envelope"></i> Send Message</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import Wysiwyg from '@/Components/Collections/EmailWysiwyg.vue'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  components: { Wysiwyg },
  mounted () {
    if (this.messages) {
      this.currentMessageView = this.messages
      this.messages.forEach((m) => { m.active = false })
    }
  },
  data () {
    return {
      currentMessageView: [],
      pageCount: 10,
      currentPage: 1,
      selectedMessage: null,
      replyId: '',
      messageTitle: '',
      messageBody: ''
    }
  },
  props: {
    messages: Array,
    unreadOnly: Boolean,
    payerView: Boolean,
    ukPayerId: String
  },
  methods: {
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    async selectMessage (message) {
      message.isRead = true
      for (var i = 0; i < message.replies.length; i++) {
        message.replies[i].isRead = true
      }
      if (this.selectedMessage !== null && this.selectedMessage !== undefined) {
        this.selectedMessage.active = false
      }
      this.selectedMessage = message
      this.selectedMessage.active = true
      this.$bvModal.show('message-modal')
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/${message.payerPortalMessageId}/read`, {}, { showload: true })
      this.$store.dispatch('updatePortalMessagesCount')
      if (!response.data) {
        this.$toastr.e('Could not set message as read.')
      }
    },
    reply () {
      this.replyId = this.selectedMessage.payerPortalMessageId
      this.messageTitle = 'Re: ' + this.selectedMessage.messageTitle
      this.$bvModal.hide('message-modal')
      this.$bvModal.show('reply-compose-modal')
      setTimeout(this.loadQuill, 500)
    },
    loadQuill () {
      this.$refs['messageBodyControl'].loadQuill()
    },
    messageSent () {
      this.replyId = ''
    },
    closeComposeModal () {
      this.messageTitle = ''
      this.messageBody = ''
      this.$bvModal.hide('reply-compose-modal')
    },
    async send () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/${this.selectedMessage.senderId}/send`, {
        planId: this.selectedMessage.paymentPlanId,
        subject: this.messageTitle,
        body: this.messageBody,
        originalMessageId: this.replyId
      }, { showload: true })
      if (response) {
        this.$toastr.s('Message sent successfully')
        this.$bvModal.hide('reply-compose-modal')
        this.$emit('input', {})
      } else {
        this.$toastr.e('Could not send message')
      }
    },
    hasUnread (message) {
      return !message.isRead || message.replies.some(x => !x.isRead)
    },
    getClass (message) {
      let cssClass = 'read'
      if (this.hasUnread(message)) {
        cssClass = 'unread'
      }
      if (message.active) {
        cssClass += ' activeMessage'
      }
      return cssClass
    },
    unreadReplyCount (message) {
      return message.replies.filter(x => !x.isRead && x.customerMessage).length
    },
    latestTitle (message) {
      var title = message.messageTitle
      if (message.replies && message.replies.length > 0) {
        title = message.replies[0].messageTitle
      }
      return title
    },
    getAvatar (message) {
      var avatarText = message.senderName[0]
      if (message.replies.length > 0) {
        avatarText = message.replies[0].senderName[0]
      }
      return avatarText
    },
    concatContent (message) {
      var text = message.messageBody
      if (message.replies && message.replies.length > 0) {
        text = message.replies[0].messageBody
      }
      return this.$sanitize(text)
    }
  },
  computed: {
    pages () {
      return this.currentMessageView ? Math.ceil(this.currentMessageView.length / 10) : 0
    },
    pagedMessage () {
      return this.currentMessageView ? this.currentMessageView.slice((this.currentPage - 1) * this.pageCount, (this.currentPage - 1) * this.pageCount + this.pageCount) : []
    },
    unread () {
      return this.messages.filter((message) => { return !message.isRead || message.replies.some(x => !x.isRead && x.customerMessage) })
    },
    starred () {
      return this.messages.filter((message) => { return message.starred })
    }
  },
  watch: {
    messages (val) {
      this.currentMessageView = val
    },
    unreadOnly (val) {
      this.currentMessageView = val ? this.unread : this.messages
    }
  }
}
</script>
<style scoped>
.inbox  table {
font-weight: 600;
}

.inbox table tr {
  border-right: solid 1px #dee2e6;
}

.inbox  table tr.read > td {
background-color: #f6f6f6;
}

.inbox  table tr.read > td {
font-weight: 400;
}

.inbox  table tr td > i.fa {
font-size: 1.2em;
line-height: 1.5em;
text-align: center;
}
.avatarFrame {
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:#0077b6;
  vertical-align:middle;
  margin-right:1em;
  color:white;
}

.summary {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.messageSelectorSmall {
  font-size: 0.8em;
}
tr.activeMessage {
  background-color: #ffffff;
  border-left: solid 1px #dee2e6;
  border-right: solid 0px transparent !important;
}

  tr.activeMessage > td {
    background-color: #ffffff !important;
    border-top: solid 2px #dee2e6;
    border-bottom: solid 2px #dee2e6;
  }
</style>
