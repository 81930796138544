import axios from 'axios'
import { UserManager, Log, WebStorageStateStore } from 'oidc-client'
import _ from 'underscore'
import moment from 'moment'
import { vue } from '@/main'

Log.logger = console
Log.level = Log.NONE

const Config = {
	authority: process.env.VUE_APP_IDS_AUTHORITY,
	client_id: process.env.VUE_APP_IDS_CLIENT_ID,
	redirect_uri: process.env.VUE_APP_IDS_REDIRECT_URI,
	response_type: process.env.VUE_APP_IDS_RESPONSE_TYPE,
	scope: process.env.VUE_APP_IDS_SCOPE,
	post_logout_redirect_uri: process.env.VUE_APP_IDS_POST_LOGOUT_REDIRECT_URL,
	automaticSilentRenew: process.env.VUE_APP_IDS_AUTOMATIC_SILENT_RENEW === 'true',
	silent_redirect_uri: process.env.VUE_APP_IDS_SILENT_REDIRECT_URI
}
const manager = new UserManager(Object.assign(Config, { userStore: new WebStorageStateStore({ store: window.localStorage }) }))
var vueInstance = vue

manager.clearStaleState()

async function checkRights (user, type, matched) {
	return new Promise((resolve, reject) => {
		var method = '|'
		var rightsToCheck = matched
			.reduce((a, m) => {
				if (m.meta[type] !== undefined) {
					a.push(m.meta[type])
					if (`${m.meta[type]}` !== undefined) {
						method = `${m.meta.type}`
					}
				}
				return a
			}, [])
			.reduceRight((previousValue, currentValue) => previousValue.concat(currentValue), [])
			.filter((v, i, a) => a.indexOf(v) === i)
		var totalRightsToCheck = rightsToCheck.length
		if (totalRightsToCheck === 0) {
			resolve()
			return
		}
		for (let i = 0; i < rightsToCheck.length; i++) {
			const rcc = rightsToCheck[i].toLowerCase()
			let contains = null
			if (type === 'auth') {
				contains = user.some((c) => c.value.toLowerCase() === rcc || c.type.toLowerCase() === rcc)
			} else if (type === 'licence') {
				contains = user.some((c) => c.value.toLowerCase() === rcc && c.type.toLowerCase() === 'licence')
			}

			if (contains) {
				rightsToCheck.splice(i, 1)
				i--
			}
		}

		if (method === '&') {
			if (rightsToCheck.length === 0) {
				resolve()
			} else {
				reject(rightsToCheck)
			}
		} else {
			if (rightsToCheck.length < totalRightsToCheck) {
				resolve()
			} else {
				reject(rightsToCheck)
			}
		}
	})
}

async function isLoggedIn (to, from, next, vue, idsCheck) {
	try {
		if (vue && !vueInstance) {
			vueInstance = vue
		}
		await getUser(idsCheck)
		var store = vueInstance.$store
		var user = vueInstance.$store.state.user.user
		var cookies = new Map(document.cookie.split('; ').map(v => v.split('=').map(decodeURIComponent)))
		if (user && user.some(u => true) && cookies.get('loginstatus')) {
			try {
				await checkRights(user, 'licence', to.matched)

				try {
					await checkRights(user, 'auth', to.matched)
					store.commit('setUnauthorized', false)
					store.commit('setLicence', null)
					next()
					return
				} catch {
					store.commit('setUnauthorized', true)
					next()
					return
				}
			} catch (e) {
				store.commit('setLicence', e)
				next()
				return
			}
		} else {
			login()
			return
		}
	} catch {
		// Assume that we are unable to hit platform
		vueInstance.$snapbar.e('Unable to contact the paygate API, please try refreshing, otherwise contact paygate Support')
	}
}

function login () {
	cleanUp()
	manager.signinRedirect({ state: vueInstance.$router.history.pending.fullPath })
}

function logout () {
	if (vueInstance) {
		vueInstance.$store.commit('forceLogout')
	}
	manager.signoutRedirect().then(() => {
		cleanUp()
	})
}

function cleanUp () {
	manager.removeUser()
	manager.clearStaleState()
	for (var i = 0; i < window.sessionStorage.length; i++) {
		const key = window.sessionStorage.key(i)
		if (key.includes('oidc') || key.includes('store')) {
			window.sessionStorage.removeItem(key)
		}
	}
}

async function getUser (idsCheck) {
	manager.clearStaleState()
	await vueInstance.$store.dispatch('loadUser', !!idsCheck)
}

async function getAccessToken () {
	var user = await manager.getUser()
	if (user) {
		return user.access_token
	} else {
		return undefined
	}
}

function getAuthority () {
	return Config.authority
}

function addGuardsAndInterceptors () {
	axios.interceptors.request.use(
		async (config) => {
			var paygateId = null
			if (vueInstance) {
				paygateId = vueInstance.$store.state.common.paygateId
				if (config.showload) {
					vueInstance.$Progress.start()
				}
			}

			var accessToken = await getAccessToken()
			if (accessToken === undefined) {
				console.error('Why is it undefined? (It should be before you\'ve logged in)')
			}
			config.headers.Authorization = `Bearer ${accessToken}`
			config.params = { paygateid: paygateId, ...config.params }
			return config
		},
		(error) => {
			return Promise.reject(error)
		}
	)

	axios.interceptors.response.use(
		(response) => {
			// Do something with response data
			if (vueInstance && response.config.showload) {
				vueInstance.$Progress.finish()
			}
			if (response.config.action) {
				if (response.data.numberOfApprovers < response.data.numberOfApproversNeeded) {
					vueInstance.$snapbar.w('There are not enough approvers to complete this action')
				}
			}

			return response
		},
		(error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					return Promise.reject(error)
				}

				if (vueInstance && error.response.config.showload) {
					vueInstance.$Progress.fail()
				}

				if (error.response.config.showerror) {
					if (error.response.data) {
						if (typeof (error.response.data) === 'string') {
							vueInstance.$snapbar.e(error.response.data)
						} else if (error.response.data.statusText) {
							vueInstance.$snapbar.e(error.response.data.statusText)
						} else if (error.response.data.errorMessage) {
							vueInstance.$snapbar.e(error.response.data.errorMessage)
						} else if (error.response.config.errormessage) {
							vueInstance.$snapbar.e(error.response.config.errormessage)
						}
					} else {
						vueInstance.$snapbar.e(error.response.config.errormessage)
					}
				}

				if (error.response.config.action) {
					if (error.response.status === 404) {
						vueInstance.$router.replace({ name: 'ActionNotFound' })
					}
				}
			}

			return Promise.reject(error)
		}
	)
}

async function addInactivity () {
	var docTitle = document.title
	var timeoutRequest = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}securitypolicy/timeout`)
	const timeoutSettings = timeoutRequest.data
	let titleIntervalNumber = null
	let flash = false
	setInactivityTimer();
	['click', 'keypress', 'mousemove'].forEach(v => document.addEventListener(v, _.debounce(setInactivityTimer, 200)))
	setInterval(async () => {
		var lastActivity = moment(localStorage.getItem('corvid-timeout'))
		var nowMinusSettings = moment().subtract(timeoutSettings, 'm')
		var duration = moment.duration(lastActivity.diff(nowMinusSettings))

		if (duration.asMinutes() < 5) {
			if (titleIntervalNumber === null) {
				// Create interval here
				titleIntervalNumber = setInterval(() => {
					if (flash) {
						docTitle = document.title
						var lastActivity = moment(localStorage.getItem('corvid-timeout'))
						var nowMinusSettings = moment().subtract(timeoutSettings, 'm')
						var duration = moment.duration(lastActivity.diff(nowMinusSettings))
						document.title = `Logging out in ${duration.minutes()}:${duration.seconds()}`
						flash = !flash
					} else {
						flash = !flash
						document.title = docTitle
					}
				}, 1000)
			}
		} else if (titleIntervalNumber !== null) {
			clearInterval(titleIntervalNumber)
			titleIntervalNumber = null
			document.title = `${process.env.VUE_APP_NAME}`
		}

		if (lastActivity.isBefore(nowMinusSettings)) {
			cleanUp()
			vueInstance.$store.commit('forceLogout')
			manager.signinRedirect({ extraQueryParams: { force_login: 'true', message: 'You\'ve been logged out due to inactivity' } })
		}
	}, 10000)
}

function setInactivityTimer () {
	localStorage.setItem('corvid-timeout', moment().toISOString())
}

export default {
	isLoggedIn,
	login,
	logout,
	getUser,
	getAuthority,
	getAccessToken,
	addGuardsAndInterceptors,
	addInactivity,
	manager
}

// Here we will setup other things to do with auth in Vue
