// dashboard views
import licence from '@/Assets/Constants/licence'
const Dashboard = () => import(/* webpackChunkName: "collections-dashboard" */ '@/Views/Collections/Dashboard')

const Alerts = () => import(/* webpackChunkName: "collections-alerts" */ '@/Views/Collections/Alerts/Alerts')

// pages
const ManagePayers = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/ManagePayers')
const EditPayer = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/EditPayer')
const PayerSchedule = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/PayerSchedule')
const CreatePayer = () => import(/* webpackChunkName: "collections-payer" */ '@/Views/Collections/Payer/PayerWizard')

const ViewSubmissions = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ViewSubmissions')
const ProcessSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ProcessSubmission')
const FlaggedSubmissions = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/FlaggedSubmissions')
const ViewSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ViewSubmission')
const ViewAuddisSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/ViewAuddisSubmission')
const OrphanSubs = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/OrphanSubs')
const PastSubmissions = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/PastSubmissions')
const PastSubmission = () => import(/* webpackChunkName: "collections-submissions" */ '@/Views/Collections/Submissions/PastSubmission')

const ImportData = () => import(/* webpackChunkName: "collections-data" */ '@/Views/Collections/Data/Import')
const ExportData = () => import(/* webpackChunkName: "collections-data" */ '@/Views/Collections/Data/Export')

const VariableAmount = () => import(/* webpackChunkName: "collections-varamount" */ '@/Views/Collections/Data/VariableAmount')
const VariableAmountImportDetails = () => import(/* webpackChunkName: "collections-varamount" */'@/Views/Collections/Data/VariableAmountImportDetails')

const MessageQueue = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/MessageQueue')
const ViewMessage = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/ViewMessage')
const PreviewDefaultMessage = () => import(/* webpackChunkName: "collections-messages" */ '@/Views/Collections/Messaging/PreviewDefaultMessage')

const ImportDetails = () => import(/* webpackChunkName: "collections-importdetails" */ '@/Views/Collections/Data/ImportDetails')

const EmailDesigner = () => import(/* webpackChunkName: "collections-emaildesigner" */ '@/Views/Collections/Messaging/EmailDesigner')

const LetterDesigner = () => import(/* webpackChunkName: "collections-letterdesigner" */ '@/Views/Collections/Messaging/LetterDesigner')

const DownloadReports = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reconciliation/DownloadReports')
const ViewReport = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reconciliation/ViewReport')
const SynchronisationReport = () => import(/* webpackChunkName: "collections-reports" */ '@/Views/Collections/Reconciliation/SynchronisationReport')

const SmsDesigner = () => import(/* webpackChunkName: "collections-smsdesigner" */ '@/Views/Collections/Messaging/SmsDesigner')

const GroupTemplateAssignment = () => import(/* webpackChunkName: "collections-templates" */ '@/Views/Collections/Messaging/GroupTemplateAssignment')
const MessageTemplateManagement = () => import(/* webpackChunkName: "collections-templates" */ '@/Views/Collections/Messaging/MessageTemplateManagement')

const ReconciliationHistory = () => import(/* webpackChunkName: "collections-history" */ '@/Views/Collections/Reconciliation/ReconciliationHistory')
const PayerHistory = () => import(/* webpackChunkName: "collections-history" */ '@/Views/Collections/Payer/ViewHistory')

const PlanDesigner = () => import(/* webpackChunkName: "collections-plandesigner" */ '@/Views/Collections/Plans/PlanDesigner')

const ScheduledPlanChanges = () => import(/* webpackChunkName: "collections-scheduledplanchanges" */ '@/Views/Collections/Plans/ScheduledPlanChanges')

const LookAhead = () => import(/* webpackChunkName: "collections-lookahead" */ '@/Views/Collections/Reporting/LookAhead')
const Orphaned = () => import(/* webpackChunkName: "collections-lookahead" */ '@/Views/Collections/Reporting/OrphanedSubmissions')
const Messaging = () => import(/* webpackChunkName: "collections-lookahead" */ '@/Views/Collections/Reporting/MessagingReports')

const BulkMessageSender = () => import(/* webpackChunkName: "collections-messagesender" */ '@/Views/Collections/Messaging/BulkMessageSender')
const Suppression = () => import(/* webpackChunkName: "collections-suppression" */ '@/Views/Collections/Plans/Suppression')
const OnboardingStyles = () => import(/* webpackChunkName: "collections-onboarding" */ '@/Views/Collections/Onboarding/OnboardingStyles')
const PortalMessages = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/PortalMessages')
const PortalConfiguration = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/PortalConfiguration')
const PortalSendBulk = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/SendBulkMessages')
const PortalMigrate = () => import(/* webpackChunkName: "collections-portal" */ '@/Views/Collections/Portal/BulkMigratePayers')
const OutTray = () => import(/* webpackChunkName: "collections-messaging" */ '@/Views/Collections/Messaging/OutTray')

const FinalWarning = () => import(/* webpackChunkName: "collections-final" */ '@/Views/Collections/Payer/PayerExpiration')
const CancellationPerformed = () => import(/* webpackChunkName: "collections-cancellation" */ '@/Views/Collections/Submissions/CancellationPerformed')
const MessageAction = () => import(/* webpackChunkName: "collections-cancellation" */ '@/Views/Collections/Messaging/MessageAction')
export default [
  {
    path: '/collections',
    component: Dashboard,
    meta: {
      breadcrumb: 'Home'
    }
  },
  {
    path: '/collections/alerts/',
    component: Alerts,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Home',
      title: 'Collections / Alerts'
    }
  },
  {
    path: '/collections/payer/:ukPayerId/edit/',
    name: 'editPayer',
    props: ({ params, query: { openAt, retPath } }) => {
      return {
        ...params,
        retPath,
        openAt,
        payerDetailsInitiallyVisible: openAt === 'payerDetails',
        bankAccountInitiallyVisible: openAt === 'bankAccount',
        paymentPlansInitiallyVisible: openAt === 'paymentPlans',
        messageQueueInitiallyVisible: openAt === 'messagequeue'
      }
    },
    component: EditPayer,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Edit Payer',
      auth: ['ViewPayers', 'ManagePayers'],
      title: 'Collections / Payer / Edit Payer'
    }
  },
  {
    path: '/collections/payer/:groupid/create',
    name: 'createPayer',
    component: CreatePayer,
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Create Payer',
      auth: ['ViewPayers', 'ManagePayers'],
      title: 'Collections / Payer / Create Payer'
    }
  },
  {
    name: 'managePayers',
    path: '/collections/payer/manage',
    component: ManagePayers,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Manage Payers',
      auth: ['ViewPayers'],
      title: 'Collections / Payer / Manage Payers'
    },
    props: ({ params, query: { search, groupid, status } }) => {
      return {
        ...params,
        prepopulatedStatus: status,
        prepopulatedSearchTerm: search,
        prepopulatedGroup: groupid
      }
    }
  },
  {
    path: '/collections/payer/:id/:planId/schedule',
    component: PayerSchedule,
    name: 'PayerSchedule',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Payer',
      auth: ['ViewPayers'],
      title: 'Collections / Payer / Payer Schedule'
    }
  },
  {
    path: '/collections/payer/:id/history/:historyId',
    component: PayerHistory,
    name: 'PayerHistory',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Payer',
      auth: ['ViewPayers'],
      title: 'Collections / Payer / Payer History'
    }
  },
  {
    path: '/collections/submissions/',
    component: ViewSubmissions,
    name: 'ViewSubmissions',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submissions / View Submissions'
    }
  },
  {
    path: '/collections/message/queue',
    component: MessageQueue,
    name: 'MessageQueue',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Message Queue',
      auth: ['ViewMessages'],
      title: 'Collections / Messaging / Message Queue'
    }
  },
  {
    path: '/collections/message/grouptemplates',
    component: GroupTemplateAssignment,
    name: 'GroupTemplateAssignment',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Assign Group Templates',
      auth: ['AssignMessageTemplates'],
      title: 'Collections / Messaging / Assign Group Templates'
    }
  },
  {
    path: '/collections/message/bulkmessaging',
    component: BulkMessageSender,
    name: 'BulkMessageSender',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Bulk Message Sender',
      auth: ['AssignMessageTemplates'],
      title: 'Collections / Messaging / Bulk Message Sender'
    }
  },
  {
    path: '/collections/message/templates',
    component: MessageTemplateManagement,
    name: 'MessageTemplateManagement',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Manage Message Templates',
      auth: ['EditMessageTemplates'],
      title: 'Collections / Messaging / Manage Message Templates'
    }
  },
  {
    path: '/collections/message/designer/email',
    component: EmailDesigner,
    name: 'EmailDesigner',
    props: false,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Email Designer',
      auth: ['EditMessageTemplates'],
      title: 'Collections / Messaging / Email Designer'
    }
  },
  {
    path: '/collections/message/designer/email/:templateid',
    component: EmailDesigner,
    name: 'SpecificEmailTemplate',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Email Designer',
      auth: ['EditMessageTemplates'],
      title: 'Collections / Messaging / Email Designer'
    }
  },
  {
    path: '/collections/message/designer/letter',
    component: LetterDesigner,
    name: 'LetterDesigner',
    props: false,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Letter Designer',
      auth: ['EditMessageTemplates'],
      title: 'Collections / Messaging / Letter Designer'
    }
  },
  {
    path: '/collections/message/designer/letter/:templateid',
    component: LetterDesigner,
    name: 'SpecificLetterTemplate',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Letter Designer',
      auth: ['EditMessageTemplates'],
      title: 'Collections / Messaging / Letter Designer'
    }
  },
  {
    path: '/collections/message/designer/sms/:templateid',
    component: SmsDesigner,
    name: 'SpecificSmsTemplate',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'SMS Designer',
      auth: ['EditMessageTemplates'],
      title: 'Collections / Messaging / SMS Designer'
    }
  },
  {
    path: '/collections/message/designer/sms',
    component: SmsDesigner,
    name: 'SmsDesigner',
    props: false,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'SMS Designer',
      auth: ['EditMessageTemplates'],
      title: 'Collections / Messaging / SMS Designer'
    }
  },
  {
    path: '/collections/message/:messageId',
    component: ViewMessage,
    name: 'viewMessage',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Message',
      auth: ['ViewMessages'],
      title: 'Collections / Messaging / View Message'
    }
  },
  {
    path: '/collections/letters/out',
    component: OutTray,
    name: 'outtray',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Out Tray',
      auth: ['ViewMessages'],
      title: 'Collections / Messaging / Letters Outbox'
    }
  },
  {
    path: '/collections/defaultmessage/:channel/:type',
    component: PreviewDefaultMessage,
    name: 'ViewDefaultMessage',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Preview Default Message',
      auth: ['ViewMessages'],
      title: 'Collections / Messaging / View Default Message'
    }
  },
  {
    path: '/collections/submissions/:groupId/flagged',
    component: FlaggedSubmissions,
    name: 'FlaggedSubmissions',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Flagged Submissions',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submission / Flagged Submissions'
    }
  },
  {
    path: '/collections/submissions/:groupId/view',
    component: ViewSubmission,
    name: 'ViewSubmission',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Submission',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submission / View Submission'
    }
  },
  {
    path: '/collections/submissions/:groupId/viewauddis',
    component: ViewAuddisSubmission,
    name: 'ViewAuddisSubmission',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Auddis Submission',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submission / View Auddis Submission'
    }
  },
  {
    path: '/collections/submissions/uncollected/:orphanWarningId',
    component: OrphanSubs,
    name: 'OrphanSubs',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Submission',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submission / Uncollected Submission'
    }
  },
  {
    path: '/collections/submissions/:groupId/:mode',
    component: ProcessSubmission,
    name: 'ProcessCollection',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submission / Process Submission'
    }
  },
  {
    path: '/collections/cancelsubmissions',
    component: PastSubmissions,
    name: 'CancelSubmissions',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Submissions',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submission /Cancel Submissions'
    }
  },
  {
    path: '/collections/cancelsubmission/:submissionId/:fileNumber',
    component: PastSubmission,
    name: 'CancelSubmission',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Past Submission',
      auth: ['ProcessSubmissions'],
      title: 'Collections / Submission / Cancel Submission'
    }
  },
  {
    path: '/collections/data/import',
    component: ImportData,
    name: 'ImportData',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: ['ImportPayers'],
      title: 'Collections / Import / Import Payers'
    }
  },
  {
    path: '/collections/data/import/:uploadId',
    component: ImportDetails,
    name: 'ImportDetails',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Import',
      auth: ['ImportPayers'],
      title: 'Collections / Import / Import Details'
    }
  },
  {
    path: '/collections/data/export',
    component: ExportData,
    name: 'ExportData',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: ['ExportPayers'],
      title: 'Collections / Export Data'
    }
  },
  {
    path: '/collections/data/variableamount',
    component: VariableAmount,
    name: 'VariableAmount',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: ['ImportVariableAmounts'],
      title: 'Collections / Import / Import Variable Amount'
    }
  },
  {
    path: '/collections/data/variableamount/:uploadId',
    component: VariableAmountImportDetails,
    name: 'VariableAmountImportDetails',
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Data',
      auth: ['ImportVariableAmounts'],
      title: 'Collections / Import / Variable Amount Import Details'
    }
  },
  {
    path: '/collections/reconciliation/downloadReports',
    component: DownloadReports,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reconciliation',
      auth: ['SynchroniseBACSData'],
      title: 'Collections / Reconciliation'
    }
  },
  {
    path: '/collections/reconciliation/viewReport',
    component: ViewReport,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'View Report',
      auth: ['SynchroniseBACSData'],
      title: 'Collections / Reconciliation / View Reconciliation Report'
    },
    props: route => ({ ...route.params, ...route.query })
  },
  {
    path: '/collections/reconciliation/report/:syncId',
    component: SynchronisationReport,
    props: true,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reconciliation',
      auth: ['SynchroniseBACSData'],
      title: 'Collections / Reconciliation / Synchronisation Report'
    }
  },
  {
    path: '/collections/reconciliation/report/',
    component: ReconciliationHistory,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reconciliation',
      auth: ['ViewReconciliationHistory'],
      title: 'Collections / Reconciliation / Reconciliation History'
    }
  },
  {
    path: '/collections/reporting/lookahead/',
    component: LookAhead,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reporting',
      auth: ['ViewReports'],
      title: 'Collections / Reports / Look Ahead Report'
    }
  },
  {
    path: '/collections/reporting/orphaned/',
    component: Orphaned,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reporting',
      auth: ['ViewReports'],
      title: 'Collections / Reports / Orphaned Submissions Report'
    }
  },
  {
    path: '/collections/reporting/messaging/',
    component: Messaging,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Reporting',
      auth: ['ViewReports'],
      title: 'Collections / Reports / Messaging Report'
    }
  },
  {
    path: '/collections/plans/designer/',
    component: PlanDesigner,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Plans',
      auth: ['ViewPlanTemplates'],
      title: 'Collections / Plans / Plan Designer'
    }
  },
  {
    path: '/collections/plans/scheduledchanges/',
    component: ScheduledPlanChanges,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Plans',
      auth: ['ViewPlanTemplates'],
      title: 'Collections / Plans / Scheduled Plan Changes'
    }
  },
  {
    path: '/collections/plans/suppression/',
    component: Suppression,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Plans',
      auth: ['ViewPlanTemplates'],
      title: 'Collections / Plans / Plan Suppression'
    }
  },
  {
    path: '/collections/onboarding/styles/',
    component: OnboardingStyles,
    meta: {
      licence: [licence.collectionsOnBoarding],
      breadcrumb: 'Onboarding',
      auth: [],
      title: 'Collections / Onboarding / Onboarding Styles'
    }
  },
  {
    path: '/collections/portal/messages/',
    component: PortalMessages,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Messages'
    }
  },
  {
    path: '/collections/portal/configuration/',
    component: PortalConfiguration,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Configuration'
    }
  },
  {
    path: '/collections/portal/sendbulk/',
    component: PortalSendBulk,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Send Bulk'
    }
  },
  {
    path: '/collections/portal/migrate/',
    component: PortalMigrate,
    meta: {
      licence: [licence.collectionsPayerPortal],
      breadcrumb: 'Portal',
      auth: [],
      title: 'Collections / Portal / Bulk Account Creation'
    }
  },
  {
    path: '/collections/actions/finalwarning/:ukPayerId',
    name: 'Final Warning',
    props: true,
    component: FinalWarning,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Actions',
      auth: [],
      title: 'Collections / Payers / Final Warning'
    }
  },
  {
    path: '/collections/actions/withdrawalperformed/:submissionId',
    name: 'Withdrawal Performed',
    props: true,
    component: CancellationPerformed,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Actions',
      auth: [],
      title: 'Collections / Submissions / Cancellation Performed'
    }
  },
  {
    path: '/collections/actions/messaging/:messageId/:type',
    name: 'Message Action',
    props: true,
    component: MessageAction,
    meta: {
      licence: [licence.collections],
      breadcrumb: 'Actions',
      auth: [],
      title: 'Collections / Messaging / Failure'
    }
  }
]
