var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasErrors
      ? _c(
          "div",
          { staticClass: "alert alert-danger" },
          _vm._l(_vm.errors, function(error, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Payments Approval "),
                _c("help-icon", {
                  attrs: {
                    docPath: "/administration/securitypolicy/paymentgroups/"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("approval-settings", {
                attrs: { details: _vm.$v.policy, group: _vm.$v.policy }
              })
            ],
            1
          ),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }