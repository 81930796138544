var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "idb-right-panel",
      class: _vm.sidebarOpen ? "collapse-show" : "collapse-hide"
    },
    [
      _c("div", { staticClass: "custom-sidebar-pan" }, [
        _c("div", { staticClass: "system-setting" }, [
          _c("div", { staticClass: "setting-header" }, [
            _vm._v(" Add Widget ")
          ]),
          _c(
            "form",
            {
              staticClass: "pt-10 m-10 widget-search",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.search($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-form-label",
                    attrs: { for: "widgetSearch" }
                  },
                  [_vm._v("Search")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchValue,
                      expression: "searchValue"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "widgetSearch", type: "text" },
                  domProps: { value: _vm.searchValue },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchValue = $event.target.value
                      },
                      function($event) {
                        return _vm.search()
                      }
                    ]
                  }
                })
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "list-group list-group-flush widget-list" },
            _vm._l(_vm.widgets, function(widgetType) {
              return _c(
                "div",
                { key: widgetType.groupType, staticClass: "widget-box" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "list-group-item dropdown-header list-group-item-secondary"
                    },
                    [_vm._v(_vm._s(_vm._f("startCase")(widgetType.groupType)))]
                  ),
                  _vm._l(widgetType.widgets, function(widget) {
                    return _c(
                      "div",
                      {
                        key: widget.name,
                        staticClass: "list-group-item list-group-item-action",
                        class:
                          _vm.selectedWidget !== null &&
                          widget.name == _vm.selectedWidget.name
                            ? "selected"
                            : "",
                        attrs: { draggable: "true" },
                        on: {
                          click: function($event) {
                            return _vm.selectWidget(widget)
                          },
                          dblclick: function($event) {
                            return _vm.addBClick(widget)
                          },
                          dragstart: function($event) {
                            return _vm.dragStart(widget, $event)
                          },
                          dragend: function($event) {
                            return _vm.dragEnd($event)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("img", {
                              staticClass: "img-fluid mt-auto",
                              attrs: {
                                "aria-hidden": "true",
                                src:
                                  "/img/sharedimages/WidgetImages/" +
                                  widget.name.replace(/\s/g, "") +
                                  ".jpg",
                                alt: widget.name + " icon"
                              }
                            })
                          ]),
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("h5", [_vm._v(_vm._s(widget.name))]),
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(_vm._s(widget.description))
                            ])
                          ])
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          ),
          _c("div", { staticClass: "mt-auto buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-10",
                attrs: { disabled: _vm.selectedWidget === null },
                on: { click: _vm.add }
              },
              [_vm._v("Add")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }