<template>
  <div>
    <vue-good-table mode="remote"
      ref="table"
      :columns="columns"
      :rows="rows"
      @on-row-click="viewHistory"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearch"
      :totalRows="totalRecords"
      :isLoading.sync="isTableLoading"
      :search-options="{
          enabled: true
          }"
      :pagination-options="{
          enabled: true,
          perPage: 10,
          dropdownAllowAll: false
        }"
      styleClass="vgt-table striped bordered"
    ></vue-good-table>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [ tableFilterMixin ],
  props: {
    ukPayerId: String
  },
  data () {
    return {
      rows: [],
      columns: [
        { label: 'Message Type', field: 'payerHistoryMessageType' },
        { label: 'Title', field: 'title' },
        { label: 'Body', field: 'body' },
        { label: 'Date', field: 'generationDate' }],
      serverParams: {       
        sort: [{ field: 'generationDate', type: 'asc' }]
      }
    }
  },
  async mounted () {
    await this.load()
    EventBus.$on('refreshHistory', (payload) => { this.load() })
  },
  methods: {
    viewHistory (e) {
      console.log(e)
      this.$router.push({ path: `/collections/payer/${this.ukPayerId}/history/${e.row.payerHistoryId}` })
    },
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/history`, { params: this.buildGoodTableQuery(), paygateid: this.$store.state.common.paygateId, showload: true })
      if (response) {
        this.rows = response.data.item1
        this.totalRecords = response.data.item2
      }
    }
  }
}
</script>
