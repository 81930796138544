var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Actions Report "),
                _c("help-icon", {
                  attrs: { docPath: "/administration/reports/actionsreport/" }
                }),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label col-md-2",
                  attrs: { for: "onlyShowHanging" }
                },
                [_vm._v("Only show actions with no one to action them")]
              ),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("p-check", {
                    staticClass: "p-switch p-fill",
                    attrs: { labelId: "onlyShowHanging", color: "primary" },
                    model: {
                      value: _vm.onlyHanging,
                      callback: function($$v) {
                        _vm.onlyHanging = $$v
                      },
                      expression: "onlyHanging"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.isLoading },
                on: { click: _vm.search }
              },
              [
                _c("i", { staticClass: "glyphicon ti-pie-chart rpad" }),
                _vm._v("Generate Report ")
              ]
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.dataLoaded
          ? _c("div", { staticClass: "idb-block" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c("vue-good-table", {
                    attrs: {
                      paginationOptions: _vm.paginationOptions,
                      rows: _vm.rows,
                      columns: _vm.columns,
                      styleClass: "vgt-table striped bordered cursor-default"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "table-row",
                          fn: function(props) {
                            return [
                              props.column.field == "icon"
                                ? _c("span", [
                                    _c("span", [
                                      _c("i", {
                                        staticClass: "fa",
                                        class: _vm.getIcon(props.row)
                                      })
                                    ])
                                  ])
                                : props.column.field == "createdBy"
                                ? _c(
                                    "span",
                                    [
                                      _c("avatar", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.top.hover",
                                            value: props.row.createdByName,
                                            expression:
                                              "props.row.createdByName",
                                            modifiers: {
                                              top: true,
                                              hover: true
                                            }
                                          }
                                        ],
                                        staticClass: "rounded-circle",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          "user-id": props.row.createdBy
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : props.column.field == "users"
                                ? _c("span", [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.top.hover",
                                            value: props.row.users.reduce(
                                              function(a, u) {
                                                return (
                                                  "" +
                                                  a +
                                                  (a ? ", " : "") +
                                                  u.username
                                                )
                                              },
                                              ""
                                            ),
                                            expression:
                                              "props.row.users.reduce((a, u) => `${a}${a ? ', ': ''}${u.username}`,'')",
                                            modifiers: {
                                              top: true,
                                              hover: true
                                            }
                                          }
                                        ],
                                        staticClass: "badge badge-pill",
                                        class: {
                                          "badge-success":
                                            props.row.users.length >=
                                            props.row.amountOfApproversNeeded,
                                          "badge-danger":
                                            props.row.users.length <
                                            props.row.amountOfApproversNeeded
                                        }
                                      },
                                      [_vm._v(_vm._s(props.row.users.length))]
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        props.formattedRow[props.column.field]
                                      )
                                    )
                                  ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3893710492
                    )
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h4", [_vm._v("Actions")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }