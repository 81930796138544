<template>
  <div v-if="show">
    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Watched Folder</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.watchedFolder}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Default Sub Reference</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.defaultSubmissionReference}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Default Contra Narrative</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.defaultContraNarrative}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Stop on Duplicate File</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.stopOnDuplicateFile" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Stop on Pre-sub Val Fix</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.stopOnFix" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Stop on Pre-sub Val Warning</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.stopOnWarning" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Stop on Pre-sub Val Duplicate</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.stopOnDuplicate" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Remove Fix Payments</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.removeFixPayments" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.removeFixPayments">
      <label class="col-3 offset-1 col-form-label">Removed Fix Action</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.removedFixesAction}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Remove Warning Payments</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.removeWarningPayments" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.removeWarningPayments">
      <label class="col-3 offset-1 col-form-label">Remove Warning Action</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.removedWarningsAction}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Remove Duplicate Payments</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.removeDuplicatePayments" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.removeWarningPayments">
      <label class="col-3 offset-1 col-form-label">Removed Duplicate Action</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.removedDuplicatesAction}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">File Save Folder</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.fileSaveFolder}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    show () {
      return !!this.group.details.watchedFolder && !!this.group.details.fileSaveFolder
    }
  },
  props: {
    group: {
      required: true,
      type: Object
    }
  }
}
</script>

<style>
</style>
