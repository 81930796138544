<template>
  <div>
    <div class="idb-block" v-if="users.length > 0">
      <div class="idb-block-title">
        <h2>Users</h2>
      </div>
      <div class="idb-block-content">
        <div class="container-fluid">
          <div v-for="(user, index) in users" :key="user.email">
            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Email</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.email}}</span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Name</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.name}}</span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Mobile phone</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.mobilePhone}}</span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Email Action</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <tick v-model="user.emailAction" :coloured="false"></tick>
                </div>
              </div>
            </div>

            <div class="form-group row" v-if="user.twoFactorMethod">
              <label class="col-3 offset-1 col-form-label">Two-factor method</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.twoFactorMethod}}</span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Default payment group</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.defaultGroup}}</span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Is locked-out</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <tick v-model="user.lockedOut" :coloured="false"></tick>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Is enabled</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <tick v-model="user.enabled" :coloured="false"></tick>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Payment limit</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.paymentLimit | number}}</span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Submission limit</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.submissionLimit| number}}</span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Approval limit</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{user.approvalLimit| number}}</span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Assigned a USB token</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <tick v-model="user.usbToken" :coloured="false"></tick>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">User's roles</label>
              <div class="col-8">
                <table class="table" v-if="user.roles.length !== 0">
                  <thead>
                    <tr>
                      <th scope="col" width="25.5%">Module</th>
                      <th scope="col">Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="roles in user.roles" :key="roles[0].module">
                      <td>{{roles[0].module}}</td>
                      <td>
                        <div v-for="role in roles" :key="role.name">
                          <div>{{role.name}}&nbsp;</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="form-control-plaintext" v-else>No roles</div>
              </div>
            </div>

            <div class="form-group row" v-if="user.groups.length !== 0">
              <table class="table offset-1">
                <thead>
                  <tr>
                    <th scope="col" width="25.5%">User's Groups</th>
                    <th scope="col">User's Roles within the Group</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="group in user.groups" :key="group.email">
                    <td>{{group.email}}</td>
                    <td>{{group.roles}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-group row" v-else>
              <label class="col-3 offset-1 col-form-label">User's Groups</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>No Groups</span>
                </div>
              </div>
            </div>

            <hr v-if="index !== users.length -1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserConfigReport',
  props: {
    users: {
      required: true,
      type: Array
    }
  }
}
</script>

<style>
</style>
