<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Day/Time Restrictions
            <help-icon docPath="/administration/securitypolicy/timerestrictions/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="form-group row">
            <div class="col-md-10 offset-md-1">
              <p>You can configure paygate to only allow users to login on certain days or between certain times of the day. To disallow the entire day uncheck the Day checkbox.To allow only part of a day check the day checkbox and set a start and end time. e.g. A start time of 0800 and and end time of 1730 will only allow a user to login between 0800 and 1730 for that day</p>
              <hr />
            </div>
          </div>

          <div class="form-group row">
            <label for="enforceDayTime" class="col-form-label col-md-3 offset-md-1">Enforce Day/Time Restrictions</label>
            <div class="col-md-2">
              <p-check :labelId="'enforceDayTime'"
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.enforceRestrictions.$model"
              ></p-check>
            </div>
          </div>

          <transition name="fade">
            <div v-if="policy.enforceRestrictions" class="ml-5">
              <div class="row mb-2">
                <div class="col-md-2 offset-md-1"></div>
                <div class="col-md-2">Start Time</div>
                <div class="col-md-2">End Time</div>
                <div class="col-md-2">Allow Day</div>
              </div>
              <div
                class="row mb-2"
                v-for="daysetting in $v.policy.daySettings.$each.$iter"
                :key="daysetting.day.$model"
              >
                <div class="col-md-2 offset-md-1">{{daysetting.day.$model}}</div>

                <div class="col-md-2" :class="{invalid: daysetting.timeStart.$error}">
                  <input
                    type="time"
                    step="3600"
                    class="form-control"
                    :disabled="!daysetting.allowDay.$model"
                    v-model="daysetting.timeStart.$model"
                  />
                  <validation-messages v-model="daysetting.timeStart">
                    <template slot="override">
                      <small
                        class="form-text small"
                        v-if="!daysetting.timeStart.maxValue"
                      >The start time cannot be later or the same as the end time</small>
                    </template>
                  </validation-messages>
                </div>
                <div class="col-md-2" :class="{invalid: daysetting.timeEnd.$error}">
                  <input
                    type="time"
                    step="3600"
                    class="form-control"
                    :disabled="!daysetting.allowDay.$model"
                    v-model="daysetting.timeEnd.$model"
                  />
                  <validation-messages v-model="daysetting.timeEnd">
                    <template slot="override">
                      <small
                        class="form-text small"
                        v-if="!daysetting.timeEnd.minValue"
                      >The end time cannot be earlier or the same as the start time</small>
                    </template>
                  </validation-messages>
                </div>
                <div class="col-md-2" :class="{invalid: !$v.policy.daySettings.atLeastOne}">
                  <label for="allowDay" class="sr-only">Allow Day</label>
                  <p-check :labelId="'allowDay'" v-model="daysetting.allowDay.$model" @change="daysetting.$touch"></p-check>
                </div>
              </div>
              <div class="row" :class="{invalid: !$v.policy.daySettings.atLeastOne}">
                <div
                  class="offset-md-7 col-md-2 validation-messages"
                  v-if="!$v.policy.daySettings.atLeastOne"
                >
                  <small class="form-text small">You must have at least one day enabled</small>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="idb-block-footer">
          <button class="btn btn-primary" type="submit" :disabled="isLoading">Save</button>
          <button
            @click="reset"
            class="btn btn-outline-warning ml-3"
            type="button"
            :disabled="isLoading"
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { requiredIf, maxValue, minValue } from 'vuelidate/lib/validators'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [DataLeaveMixin, loading],
  computed: {
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      errors: [],
      policy: {
        enforceRestrictions: false,
        daySettings: []
      }
    }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/DayTimeRestrictions`, this.policy,
          { showload: true, showerror: true, errormessage: 'Day/Time restrictions failed to save' })
        this.$toastr.s('Day Time Restrictions policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$reset()
      this.policy = {
        enforceRestrictions: false,
        daySettings: [
          { allowDay: true, day: 'Monday', timeEnd: '18:00:00', timeStart: '08:00:00' },
          { allowDay: true, day: 'Tuesday', timeEnd: '18:00:00', timeStart: '08:00:00' },
          { allowDay: true, day: 'Wednesday', timeEnd: '18:00:00', timeStart: '08:00:00' },
          { allowDay: true, day: 'Thursday', timeEnd: '18:00:00', timeStart: '08:00:00' },
          { allowDay: true, day: 'Friday', timeEnd: '18:00:00', timeStart: '08:00:00' },
          { allowDay: false, day: 'Saturday', timeEnd: '00:00:00', timeStart: '00:00:00' },
          { allowDay: false, day: 'Sunday', timeEnd: '00:00:00', timeStart: '00:00:00' }
        ]
      }
      this.errors = []
      this.$toastr.i('Day Time Restrictions policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/DayTimeRestrictions`,
          { showload: true, showerror: true, errormessage: 'Day/Time restrictions failed to load' })
        this.policy = response.data
      } catch { }
    }
  },
  validations () {
    return {
      policy: {
        enforceRestrictions: {},
        daySettings: {
          atLeastOne: (value) => {
            var result = _.some(value, m => m.allowDay)
            return result
          },
          $each: {
            day: {},
            allowDay: {},
            timeStart: {
              required: requiredIf((value) => value.allowDay),
              maxValue: (value, model) => {
                return model.allowDay ? maxValue(parseFloat(model.timeEnd) - 1)(parseFloat(value)) : true
              }
            },
            timeEnd: {
              required: requiredIf((value) => value.allowDay),
              minValue: (value, model) => {
                return model.allowDay ? minValue(parseFloat(model.timeStart) + 1)(parseFloat(value)) : true
              }
            }
          }
        }
      }
    }
  }
}
</script>
