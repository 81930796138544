var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [
      _vm._v("Sign Submission"),
      _c("span", { staticClass: "pull-right" }, [
        _c(
          "a",
          {
            attrs: {
              href:
                _vm.docUrl +
                "/automation/paygate-workflow/workflow-nodes/bacsautomation/signsubmission/",
              target: "_blank"
            }
          },
          [_c("i", { staticClass: "far fa-question-circle" })]
        )
      ])
    ]),
    _c("hr"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "spacer150" }),
    _c("hr"),
    _c(
      "div",
      { staticClass: "pull-right" },
      [
        _c(
          "b-button",
          {
            staticClass: "btnPad",
            attrs: { variant: "danger" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnCancel($event)
              }
            }
          },
          [_vm._v(" Cancel ")]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "success" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnSave($event)
              }
            }
          },
          [_vm._v(" OK ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }