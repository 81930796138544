<template>
  <div>
    <form novalidate @submit.prevent="checkValidation() && assignToken()">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Assign USB Token
            <help-icon docPath="/administration/usbtokens/assignusbtoken/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <button
            class="btn btn-primary"
            type="button"
            @click="loadDetails"
            v-if="usbTokenDetails==null"
            :disabled="isLoading"
          >Get USB Token Details</button>
          <div v-if="usbTokenDetails != null">
            <!-- Token Serial Number -->
            <div class="form-group row">
              <label class="col-form-label col-md-3">Token Serial Number</label>
              <div class="col-md-6">
                <div class="form-control-plaintext">{{usbTokenDetails.TokenSerialNumber}}</div>
              </div>
            </div>
            <!-- Token Supplier -->
            <div class="form-group row">
              <label class="col-form-label col-md-3">Token Supplier</label>
              <div class="col-md-6">
                <div class="form-control-plaintext">{{usbTokenDetails.TokenSupplier}}</div>
              </div>
            </div>
            <!-- Certificate Serial Number -->
            <div class="form-group row">
              <label class="col-form-label col-md-3">Certificate Serial Number</label>
              <div class="col-md-6">
                <div class="form-control-plaintext">{{usbTokenDetails.CertificateSerialNumber}}</div>
              </div>
            </div>
            <!-- Certificate Valid From -->
            <div class="form-group row">
              <label class="col-form-label col-md-3">Certificate Valid From</label>
              <div class="col-md-6">
                <div
                  class="form-control-plaintext"
                >{{usbTokenDetails.ValidFrom | formatDate('DD/MM/YYYY HH:mm:ss')}}</div>
              </div>
            </div>
            <!-- Certificate Valid To -->
            <div class="form-group row">
              <label class="col-form-label col-md-3">Certificate Valid To</label>
              <div class="col-md-6">
                <div
                  class="form-control-plaintext"
                >{{usbTokenDetails.ValidTo | formatDate('DD/MM/YYYY HH:mm:ss')}}</div>
              </div>
            </div>
            <!-- Selected User -->
            <div class="form-group row" :class="{invalid: $v.userId.$error}">
              <label class="col-form-label col-md-3">Select a User</label>
              <div class="col-md-6">
                <b-form-select
                  v-model="userId"
                  :options="users"
                  value-field="id"
                  text-field="label"
                  class="form-control"
                ></b-form-select>
                <validation-messages v-model="$v.userId" name="user"></validation-messages>
              </div>
            </div>
          </div>
        </div>
        <div class="idb-block-footer" v-if="usbTokenDetails != null">
          <button class="btn btn-primary" type="submit" :disabled="isLoading">Assign Token</button>
        </div>
      </div>
    </form>
    <plugin></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [loading],
  name: 'assignUsbToken',
  components: {
    Plugin
  },
  computed: {

    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () { this.$router.push({ name: 'ListUsbTokens' }) }
  },
  data () {
    return {
      userId: null,
      usbTokenDetails: null,
      users: []
    }
  },
  async mounted () {
    await this.$store.commit('setDeviceType', 'USB Token')
    await this.$store.dispatch('checkPlugin')
    this.users = await this.$store.dispatch('getUsersAsDropDownSource')
  },
  methods: {
    async loadDetails () {
      try {
        this.$Progress.start()
        this.usbTokenDetails = await this.$store.dispatch('getUsbTokenDetails')
      } catch (error) {
        this.$Progress.fail()
        this.$toastr.e(error.message, 'Error')
      } finally {
        this.$Progress.finish()
      }
    },
    async assignToken () {
      var data = {
        certificateSerialNumber: this.usbTokenDetails.CertificateSerialNumber,
        certificateThumbprint: this.usbTokenDetails.CertificateThumbprint,
        certificateValidFrom: new Date(this.usbTokenDetails.ValidFrom).toISOString(),
        certificateValidTo: new Date(this.usbTokenDetails.ValidTo).toISOString(),
        tokenSerialNumber: this.usbTokenDetails.TokenSerialNumber,
        tokenSupplier: this.usbTokenDetails.TokenSupplier,
        userId: this.userId
      }
      try {
        var existingResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}UsbToken/assigned/${data.certificateThumbprint}`,
          { showload: true, showerror: true, errormessage: 'USB token failed to detect already assigned' })

        if (existingResponse.data) {
          try {
            // Get confirmation from the user that they really want to delete the customer
            var swalResult = await swal.fire({
              title: 'Token already assigned',
              text: `This token is already assigned to ${existingResponse.data}, do you wish to reasign it?`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: colours.danger,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No'
            })

            if (!swalResult.isConfirmed) {
              return
            }
          } catch (error) {
            // Do nothing as this will be if they clicked cancel
            return
          }
        }
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}usbtoken`, data,
          { showload: true, showerror: true, errormessage: 'USB token failed to assign' })
        this.$router.push({ name: 'ListUsbTokens' })
        this.$toastr.s('USB Token was assigned successfully', 'Success')
      } catch { }
    }
  },
  validations () {
    return {
      userId: { select: required }
    }
  }

}
</script>

<style>
</style>
