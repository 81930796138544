<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/messaging/assigntemplates/" />Assign Group Templates<favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <div class="row form-group">
          <div class="col-md-2 required">
            Select a Group
          </div>
          <div class="col-md-4">
            <group-select
              v-model="assignTemplatesGroup"
              :groups="customerGroups"
              @input="groupChange"
              :clearable="false"
            ></group-select>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <b-dropdown variant="primary"> <!-- no-caret -->
          <span slot="button-content"><i class="fa fa-plus rpad"></i>Create Template</span>
          <b-dropdown-item @click="goToTemplate('LetterDesigner')"><i class="fa fa-envelope rpad" title="Letter"></i>Letter</b-dropdown-item>
          <b-dropdown-item @click="goToTemplate('EmailDesigner')"><i class="fa fa-at rpad" title="Email"></i>Email</b-dropdown-item>
          <b-dropdown-item @click="goToTemplate('SmsDesigner')"><i class="fa fa-mobile rpad" title="SMS"></i>SMS</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="idb-block" v-if="loaded && !disableInteraction">
      <div class="idb-block-title">
        <h2>Required Messages</h2>
      </div>
      <div class="idb-block-content">
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.firstPayment"
              title="First Payment"
              subtitle="Sent to the payer prior to the first payment of a plan."
              :template-options="templates"
              :message-type="1"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.advanceNotice"
              title="Advance Notice"
              subtitle="Sent to notify the payer of a change to a single upcoming payment."
              :template-options="templates"
              :message-type="0"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.multipleScheduleUpdate"
              title="Multiple Schedule Update"
              subtitle="Sent to notify the payer of changes to multiple future payments."
              :template-options="templates"
              :message-type="2"
            ></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.cancellationNotice"
              title="Cancellation Notice"
              :template-options="templates"
              :message-type="12"></assignment>
          </b-col>
        </b-row>
      </div>
      <div class="idb-block-footer">
        <b-btn variant="primary" :disabled="isLoading" @click.prevent="saveChanges">Save</b-btn>
      </div>
    </div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Advanced</h2>
      </div>
      <div class="idb-block-content">
        <!--b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.customMessage1"
              title="Custom Message 1"
              :template-options="templates"
              :message-type="'3'"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.customMessage2"
              title="Custom Message 2"
              :template-options="templates"
              :message-type="'4'"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.customMessage3"
              title="Custom Message 3"
              :template-options="templates"
              :message-type="'5'"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.customMessage4"
              title="Custom Message 4"
              :template-options="templates"
              :message-type="'6'"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.customMessage5"
              title="Custom Message 5"
              :template-options="templates"
              :message-type="'7'"></assignment>
          </b-col>
        </b-row-->
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.emailVerification"
              title="Email Verification"
              :template-options="templates"
              :message-type="8"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.portalMessageSent"
              title="Payer Portal Message Notification"
              :template-options="templates"
              :message-type="9"></assignment>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <assignment
              :disabled="disableInteraction"
              v-model="messageTemplates.refundRequested"
              title="Refund Requested"
              :template-options="templates"
              :message-type="10"></assignment>
          </b-col>
        </b-row>
      </div>
      <div v-if="disableInteraction">
        <Loading></Loading>
      </div>
      <div class="idb-block-footer">
        <b-btn variant="primary" :disabled="isLoading" @click.prevent="saveChanges">Save</b-btn>
      </div>
    </div>

    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Custom Messages</h2>
      </div>
      <div class="idb-block-content">
        <b-row><b-col cols="1"><b-button @click="addCustomType" variant="primary"><i class="fa fa-plus mr-2"></i>Add</b-button></b-col><b-col>Adds another message type that can be used for this group.</b-col></b-row>
        <b-row v-for="(temp, i) in customTemplates" :key="i">
          <b-col>
            <assignment              
              v-model="customTemplates[i]"
              :title="temp.title"
              :template-options="templates"
              :custom="true" :disabled="disableInteraction"
              @deleteClicked="deleteMessageType"></assignment>
          </b-col>
        </b-row>
      </div>
      <div class="idb-block-footer">
        <b-btn variant="primary" :disabled="isLoading" @click.prevent="saveCustomTemplates">Save Custom Messaging</b-btn>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import TemplateAssignment from '@/Components/Collections/TemplateAssignment.vue'
import axios from 'axios'
import _ from 'lodash'
import Loading from '@/Assets/Components/Loading/Loading'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  data () {
    return {
      disableInteraction: true,
      group: null,
      loaded: false,
      templates: { emails: [], letters: [], sms: [] },
      customTemplates: [],
      messageTemplates: {
        firstPayment: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        advanceNotice: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        multipleScheduleUpdate: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage1: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage2: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage3: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage4: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        customMessage5: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        emailVerification: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        portalMessageSent: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        refundRequested: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        },
        cancellationNotice: {
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: null,
          emailTemplate: null,
          letterTemplate: null
        }
      }
    }
  },
  components: {
    assignment: TemplateAssignment,
    Loading
  },
  async mounted () {
    const onPaygateChange = (paygateId) => {
      this.loadGroups(paygateId)
      this.loadTemplates(paygateId)
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    await Promise.all([
      this.loadGroups(this.$store.state.common.paygateId),
      this.loadTemplates(this.$store.state.common.paygateId)
    ])
    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
      if (this.selectedGroup !== undefined) {
        this.assignTemplatesGroup = defaultGroupId
      }
    }
    this.groupChange(this.assignTemplatesGroup)
  },
  methods: {
    async saveChanges () {
      const paygateId = this.$store.state.common.paygateId
      const groupId = this.assignTemplatesGroup
      const messageTemplates = this.messageTemplates
      let url = `${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates?groupId=${groupId}`
      if (paygateId) {
        url += `&paygateId=${paygateId}`
      }
      try {
        await axios.post(url, messageTemplates, { showload: true })
        this.$toastr.s('Message template changes saved.', 'Success')
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to save changes', 'An error occurred')
      }
    },
    async saveCustomTemplates () {
      const groupId = this.assignTemplatesGroup
      const messageTemplates = this.messageTemplates
      let url = `${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${groupId}`
      try {
        await axios.put(url, this.customTemplates, { showload: true })
        this.$toastr.s('Custom Messages saved.', 'Success')        
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to save custom messages', 'An error occurred')
      }
    },
    async loadGroups (paygateId) {
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      if (paygateIdNotDefault) {
        this.disableInteraction = true
        await this.$store.dispatch('loadCustomerGroups', paygateId)
        this.disableInteraction = false
      }
      this.group = '00000000-0000-0000-0000-000000000000'
    },
    async loadTemplates (paygateId) {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/list`, { showload: true })
      this.templates = response.data
    },
    async loadCustomMessages () {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.assignTemplatesGroup}`, { showload: true })
      this.customTemplates = response.data
    },
    async addCustomType () {
      const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.assignTemplatesGroup}`, {
          title: 'Default',
          smsEnabled: true,
          emailEnabled: true,
          letterEnabled: true,
          smsTemplate: '00000000-0000-0000-0000-000000000000',
          emailTemplate: '00000000-0000-0000-0000-000000000000',
          letterTemplate: '00000000-0000-0000-0000-000000000000'
        })
        this.customTemplates.push(response.data)
    },
    async groupChange (group) {
      if (group !== null) {
        this.disableInteraction = true
        const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates?&groupId=${group}`, { showload: true })
        this.messageTemplates = { ...this.messageTemplates, ...response.data }
        await this.loadCustomMessages()
        this.loaded = true
        this.disableInteraction = false
      }
    },
    goToTemplate (route) {
      this.$router.push({ name: route })
    },
    async deleteMessageType (templateId) {
      const response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.assignTemplatesGroup}/${templateId}`, { showload: true })
      if (response.status === 200) {
        await this.loadCustomMessages()
        this.$toastr.s('Message type deleted.', 'Success')
      } else {
        this.$toastr.e('Message type could not be deleted.', 'Failure')
      }      
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'Please select a group', 'paygateId': this.paygateid, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    assignTemplatesGroup: {
      get () {
        return this.$store.getters.assignTemplatesGroup || null
      },
      set (value) {
        this.$store.commit('setAssignTemplatesGroup', value)
      }
    }
  }
}
</script>
<style scoped>
.newTemplateLinks p {
  float: left;
}

#customMessagesTitle {
  margin-top: 1em;
}

.messageTitle {
  margin-top: 1em;
}
</style>
