import { render, staticRenderFns } from "./CreateBacsSubUsingEditor.vue?vue&type=template&id=bcf0d76e&"
import script from "./CreateBacsSubUsingEditor.vue?vue&type=script&lang=js&"
export * from "./CreateBacsSubUsingEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bcf0d76e')) {
      api.createRecord('bcf0d76e', component.options)
    } else {
      api.reload('bcf0d76e', component.options)
    }
    module.hot.accept("./CreateBacsSubUsingEditor.vue?vue&type=template&id=bcf0d76e&", function () {
      api.rerender('bcf0d76e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Views/Bacs/BacsPayments/CreateBacsSubUsingEditor.vue"
export default component.exports