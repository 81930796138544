var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "label-control col-md-3" }, [
        _vm._v("List Type for Group")
      ]),
      _c(
        "div",
        { staticClass: "col-md-9" },
        [
          _c(
            "p-radio",
            {
              staticClass: "p-default p-round",
              attrs: { value: "Disallowed List" },
              on: { change: _vm.changedListType },
              model: {
                value: _vm.groupListType,
                callback: function($$v) {
                  _vm.groupListType = $$v
                },
                expression: "groupListType"
              }
            },
            [_vm._v("Disallowed List")]
          ),
          _c(
            "p-radio",
            {
              staticClass: "p-default p-round",
              attrs: { value: "Allowed List" },
              on: { change: _vm.changedListType },
              model: {
                value: _vm.groupListType,
                callback: function($$v) {
                  _vm.groupListType = $$v
                },
                expression: "groupListType"
              }
            },
            [_vm._v("Allowed List")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "label-control col-md-3" }, [
        _vm._v("Pre-sub Message Severity")
      ]),
      _c(
        "div",
        { staticClass: "col-md-9" },
        [
          _c(
            "p-radio",
            {
              staticClass: "p-default p-round",
              attrs: { value: "Fix" },
              on: { change: _vm.changedSeverity },
              model: {
                value: _vm.messageSeverity,
                callback: function($$v) {
                  _vm.messageSeverity = $$v
                },
                expression: "messageSeverity"
              }
            },
            [_vm._v("Fix")]
          ),
          _c(
            "p-radio",
            {
              staticClass: "p-default p-round",
              attrs: { value: "Warning" },
              on: { change: _vm.changedSeverity },
              model: {
                value: _vm.messageSeverity,
                callback: function($$v) {
                  _vm.messageSeverity = $$v
                },
                expression: "messageSeverity"
              }
            },
            [_vm._v("Warning")]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.groupListType !== "",
            expression: "groupListType !== ''"
          }
        ],
        staticClass: "form-group row"
      },
      [
        _c(
          "b-list-group",
          { staticClass: "col-5" },
          [
            _c("b-list-group-item", { attrs: { variant: "secondary" } }, [
              _vm._v("Available " + _vm._s(_vm.listTypeDisplayName))
            ]),
            _c(
              "draggable",
              {
                staticClass: "dragArea",
                staticStyle: { "min-height": "50" },
                attrs: {
                  list: _vm.availableLists,
                  group: "lists",
                  disabled: _vm.disabled
                },
                on: { change: _vm.changed }
              },
              _vm._l(_vm.availableLists, function(list, index) {
                return _c(
                  "b-list-group-item",
                  {
                    key: list.id,
                    staticClass: "pointer",
                    on: {
                      dblclick: function($event) {
                        return _vm.toggle(
                          _vm.availableLists,
                          _vm.allocatedLists,
                          index
                        )
                      }
                    }
                  },
                  [_vm._v(_vm._s(list.name))]
                )
              }),
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "col-1 text-center" }),
        _c(
          "b-list-group",
          { staticClass: "col-5" },
          [
            _c("b-list-group-item", { attrs: { variant: "secondary" } }, [
              _vm._v("Allocated " + _vm._s(_vm.listTypeDisplayName))
            ]),
            _c(
              "draggable",
              {
                staticClass: "dragArea",
                staticStyle: { "min-height": "50" },
                attrs: {
                  list: _vm.allocatedLists,
                  group: "lists",
                  disabled: _vm.disabled
                },
                on: { change: _vm.changed }
              },
              _vm._l(_vm.allocatedLists, function(list, index) {
                return _c(
                  "b-list-group-item",
                  {
                    key: list.id,
                    staticClass: "pointer",
                    on: {
                      dblclick: function($event) {
                        return _vm.toggle(
                          _vm.allocatedLists,
                          _vm.availableLists,
                          index
                        )
                      }
                    }
                  },
                  [_vm._v(_vm._s(list.name))]
                )
              }),
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }