var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "fullscreen",
      staticClass: "dashboard-background",
      class: { editable: _vm.editable }
    },
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c("div", {
        staticClass: "backdrop",
        class: { active: _vm.widgetSettings, "full-active": _vm.expanded }
      }),
      _c("widget-adder", {
        attrs: { "sidebar-open": _vm.editable },
        on: { add: _vm.add }
      }),
      _c("widget-settings", {
        attrs: {
          widget: _vm.selectedWidget,
          "sidebar-open": _vm.widgetSettings
        },
        on: { resize: _vm.resize, close: _vm.closeWidgetSettings }
      }),
      _c("div", [
        _c(
          "nav",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isFullscreen,
                expression: "!isFullscreen"
              }
            ],
            staticClass:
              "widget-navbar navbar navbar-expand-lg navbar-light bg-light justify-content-between"
          },
          [
            _c(
              "form",
              { staticClass: "form-inline my-2 my-lg-0 mb-15" },
              [
                _c(
                  "label",
                  {
                    staticClass: "sr-only",
                    attrs: { for: "dashboardSelector" }
                  },
                  [_vm._v("Select Dashboard")]
                ),
                _vm.dashboards.length > 0 && !_vm.customer
                  ? _c("b-form-select", {
                      attrs: {
                        id: "dashboardSelector",
                        options: _vm.dashboards,
                        "value-field": "dashboardId",
                        "text-field": "name",
                        title: "Dashboard Selector"
                      },
                      on: { change: _vm.changeDashboard },
                      model: {
                        value: _vm.selectedDashboardId,
                        callback: function($$v) {
                          _vm.selectedDashboardId = $$v
                        },
                        expression: "selectedDashboardId"
                      }
                    })
                  : _vm._e(),
                _vm.dashboards.length > 0 || _vm.customer
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary btn-sm ml-2",
                        attrs: { type: "button" },
                        on: { click: _vm.editDashboard }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-fw",
                          class: {
                            "fa-save": _vm.editable,
                            "fa-cog": !_vm.editable
                          }
                        }),
                        _vm._v(" " + _vm._s(_vm.editable ? "Save" : "")),
                        _vm.editable
                          ? _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Save")
                            ])
                          : _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Edit")
                            ])
                      ]
                    )
                  : _vm._e(),
                _vm.editable
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary btn-sm ml-2",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelEdit }
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw fa-undo mr-1" }),
                        _vm._v("Undo Changes ")
                      ]
                    )
                  : _vm._e(),
                !_vm.editable && !_vm.customer
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Add new dashboard",
                            expression: "'Add new dashboard'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "btn btn-outline-secondary btn-sm ml-2",
                        attrs: { type: "button" },
                        on: { click: _vm.showNewDashboardModal }
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw fa-plus" }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Add New Dashboard")
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.editable && _vm.dashboards.length > 0 && !_vm.customer
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-sm ml-3",
                        attrs: { type: "button" },
                        on: { click: _vm.deleteDashboard }
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw fa-trash-alt" }),
                        _vm._v(" Delete Dashboard ")
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              [
                _c("help-icon", {
                  staticStyle: { float: "none!important" },
                  attrs: { docPath: _vm.docPath }
                }),
                !_vm.editable
                  ? _c("a", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.bottom.hover",
                          value: "Fullscreen",
                          expression: "'Fullscreen'",
                          modifiers: { bottom: true, hover: true }
                        }
                      ],
                      staticClass: "fa fa-fw fa-expand",
                      on: { click: _vm.fullscreen }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c("maintenance-messages"),
      _c(
        "grid-layout",
        {
          ref: "gridLayout",
          staticStyle: { "min-height": "260px" },
          attrs: {
            layout: _vm.dashboard.widgets,
            "col-num": 4,
            "row-height": 200,
            "is-draggable": _vm.isDraggable,
            "is-resizable": false,
            margin: [30, 30],
            "use-css-transforms": false,
            "vertical-compact": true,
            responsive: false
          },
          on: {
            "update:layout": function($event) {
              return _vm.$set(_vm.dashboard, "widgets", $event)
            }
          }
        },
        [
          _c("div", {
            staticClass: "widget-dropzone",
            on: {
              dragover: function($event) {
                return _vm.dragOver($event)
              },
              drop: function($event) {
                return _vm.addDropped($event)
              },
              dragenter: function($event) {
                return _vm.dragEnter()
              },
              dragleave: function($event) {
                return _vm.dragLeave()
              }
            }
          }),
          _vm._l(_vm.dashboard.widgets, function(widget) {
            return _c(
              "grid-item",
              {
                key: widget.i,
                attrs: {
                  x: widget.x,
                  y: widget.y,
                  w: widget.w,
                  h: widget.h,
                  i: widget.i
                }
              },
              [
                _c(
                  "widget-wrapper",
                  {
                    class: {
                      "selected-widget": widget.selected,
                      "expanded-widget": widget.expanded
                    },
                    attrs: { editable: _vm.editable, widget: widget },
                    on: {
                      remove: _vm.remove,
                      settings: _vm.settings,
                      maximise: _vm.maximise,
                      minimise: _vm.minimise
                    }
                  },
                  [
                    widget.name === "News Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [_c("news-widget", { attrs: { widget: widget } })],
                          1
                        )
                      : widget.name === "Workflow Heatmap Widget"
                      ? _c(
                          "div",
                          [
                            _c("workflow-heatmap-widget", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "Actions Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [_c("actions-widget", { attrs: { widget: widget } })],
                          1
                        )
                      : widget.name === "BACS Submissions Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("bacs-submissions-widget", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "BACS FPS Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("bacs-fps-widget", { attrs: { widget: widget } })
                          ],
                          1
                        )
                      : widget.name === "Bureau Pending Submissions Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("bureau-pending-jobs-widget", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "Bureau Recent Jobs Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("bureau-recent-jobs-widget", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "Bureau Task Progress Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("bureau-task-progress-widget", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "Bureau Submission History Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("bureau-submission-history-widget", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "File Transfers Map"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("agent-flight-map", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "File Transfers Line Graph"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("agent-file-transfer-graph-widget", {
                              attrs: { widget: widget }
                            })
                          ],
                          1
                        )
                      : widget.name === "Licence Widget"
                      ? _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [_c("licence-widget", { attrs: { widget: widget } })],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createNewDashboard($event)
            }
          }
        },
        [
          _c(
            "b-modal",
            {
              ref: "new-dashboard-modal",
              attrs: {
                id: "newDashboardModal",
                title: "New Dashboard",
                "ok-title": "Create New Dashboard",
                "cancel-variant": "secondary-outline",
                centered: true
              },
              on: {
                ok: _vm.createNewDashboard,
                shown: function($event) {
                  return _vm.$refs["new-dash-name"].focus()
                },
                hidden: _vm.resetModal
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "form-group row",
                  class: { invalid: _vm.$v.newDashboardName.$error }
                },
                [
                  _c(
                    "label",
                    { staticClass: "col-form-label col-md-3 required" },
                    [_vm._v("Dashboard Name")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.newDashboardName.$model,
                            expression: "$v.newDashboardName.$model"
                          }
                        ],
                        ref: "new-dash-name",
                        staticClass: "form-control required",
                        attrs: { type: "text" },
                        domProps: { value: _vm.$v.newDashboardName.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.newDashboardName,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("validation-messages", {
                        attrs: { name: "dashboard name" },
                        model: {
                          value: _vm.$v.newDashboardName,
                          callback: function($$v) {
                            _vm.$set(_vm.$v, "newDashboardName", $$v)
                          },
                          expression: "$v.newDashboardName"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }