var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " Submission details for '" +
              _vm._s(_vm.bureauJob.bureauJobName) +
              "' "
          ),
          _c("help-icon", { attrs: { docPath: "/bacsbureau/jobs/" } }),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c(
          "b-row",
          { staticClass: "mt-2" },
          [
            _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
              _c(
                "label",
                {
                  staticClass: "required",
                  attrs: { for: "submissionReference" }
                },
                [_vm._v("Submission Reference")]
              )
            ]),
            _c(
              "b-col",
              { attrs: { sm: "6" } },
              [
                _c("b-form-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.$v.submissionReference.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.submissionReference,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.submissionReference.$model"
                  }
                })
              ],
              1
            ),
            _vm.$v.submissionReference.$dirty
              ? _c(
                  "b-col",
                  { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                  [
                    !_vm.$v.submissionReference.required
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v("A 'Submission Reference' is required")
                        ])
                      : _vm._e(),
                    !_vm.$v.submissionReference.maxLength
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v(
                            "A 'Submission Reference' must be less than " +
                              _vm._s(
                                _vm.$v.submissionReference.$params.maxLength.max
                              ) +
                              " characters"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "mt-2" },
          [
            _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
              _c("label", { attrs: { for: "contraNarrative" } }, [
                _vm._v("Contra Narrative")
              ])
            ]),
            _c(
              "b-col",
              { attrs: { sm: "6" } },
              [
                _c("b-form-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.$v.contraNarrative.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.contraNarrative,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.contraNarrative.$model"
                  }
                })
              ],
              1
            ),
            _vm.$v.contraNarrative.$dirty
              ? _c(
                  "b-col",
                  { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                  [
                    !_vm.$v.contraNarrative.maxLength
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v(
                            "A 'Contra Narrative' must be less than " +
                              _vm._s(
                                _vm.$v.contraNarrative.$params.maxLength.max
                              ) +
                              " characters"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "idb-block-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary mr-2",
          attrs: { disabled: _vm.$v.$invalid || _vm.isTableLoading },
          on: { click: _vm.onNextClick }
        },
        [_vm._v("Next")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }