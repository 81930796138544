<template>
  <b-modal v-model="value" centered size="reportModal" @hide="close" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">Report Details</div>
    <div v-if="reportFormat === 1" class="row form-group">
      <div class="col-md-12">
        <span v-html="reportData"></span>
      </div>
    </div>
    <div v-else class="row form-group">
      <div class="col-md-12">
        <b-form-textarea v-model="reportData" :rows="20"></b-form-textarea>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn size="sm" class="float-left" variant="primary" @click="close">Close</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [
    bacsMixin
  ],
  name: 'viewReportModal',

  props: {
    value: Boolean,
    reportData: String,
    reportFormat: Number
  },

  methods: {
    close () {
      this.$emit('close')
    },

    async downloadReport () {
      // eslint-disable-next-line
      var selectedReports = this.$refs['reportList'].selectedRows
      var selectedReportUrls = []
      for (var i = 0; i < selectedReports.length; i++) {
        var tmpUrl = selectedReports[i].retrievalUrl
        selectedReportUrls.push(tmpUrl)
      }

      var json = JSON.stringify({
        networkType: this.networkType,
        downloadUrls: selectedReportUrls,
        downloadFormat: this.selectedDownloadFormat,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/downloadReports',
        data: json,
        responseType: 'blob'
      })
      this.saveFile(response)
    }
  }
}
</script>
<style lang="scss">
.modal .modal-reportModal {
  max-width: 95%;
}
.primary_line {
  line-height: 1px;
  background-color: #993300;
}
th.voca {
  font-weight: bold;
  font-size: 8pt;
  line-height: 20px;
  background-color: #f5e0d6;
  text-align: left;
}
tr.row1 {
  height: 20px;
  background-color: #ffffff;
}
tr.row111 {
  height: 20px;
  background-color: #ffffff;
}
tr.row2 {
  height: 20px;
  background-color: #faf5f5;
}
tr.row222 {
  height: 20px;
  background-color: #faf5f5;
}
tr.row333 {
  height: 20px;
  background-color: #f5e0d6;
}

.dark-mode {
  .primary_line {
    line-height: 1px;
    background-color: #993300;
  }
  th.voca {
    font-weight: bold;
    font-size: 8pt;
    line-height: 20px;
    background-color: #333333;
    text-align: left;
  }
  tr.row1 {
    height: 20px;
    background-color: #222222;
  }
  tr.row111 {
    height: 20px;
    background-color: #222222;
  }
  tr.row2 {
    height: 20px;
    background-color: #111111;
  }
  tr.row222 {
    height: 20px;
    background-color: #111111;
  }
  tr.row333 {
    height: 20px;
    background-color: #333333;
  }
}
</style>
