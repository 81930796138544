var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "loading-fade", mode: "out-in" } }, [
    _vm.loading
      ? _c("div", [
          _c("div", { staticClass: "loader-overlay" }, [
            _c("div", { staticClass: "loader" }, [_c("Loading")], 1)
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }