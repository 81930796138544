var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "90%" } },
    [
      _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
        _c(
          "h4",
          { staticClass: "m-0 w-100" },
          [
            _vm._v("Collections: "),
            _c("router-link", { attrs: { to: "collections/alerts" } }, [
              _vm._v(_vm._s(_vm.alerts.length) + " alerts require attention")
            ]),
            _vm._v("s"),
            _c("i", {
              staticClass: "fas fa-sync-alt pull-right",
              attrs: { role: "button" },
              on: { click: _vm.populate }
            })
          ],
          1
        )
      ]),
      !_vm.loading
        ? _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scroll-area",
              attrs: { settings: { maxScrollbarLength: 60 } }
            },
            [
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _c(
                        "a",
                        {
                          staticClass: "d-flex",
                          on: {
                            click: function($event) {
                              return _vm.sort("alertLevel", _vm.levelSort)
                            }
                          }
                        },
                        [
                          _vm._v("Level"),
                          _c("i", {
                            staticClass: "ml-10",
                            class:
                              _vm.levelSort === "asc"
                                ? "fa fa-angle-down"
                                : "fa fa-angle-up"
                          })
                        ]
                      )
                    ]),
                    _c("th", [
                      _c(
                        "a",
                        {
                          staticClass: "d-flex",
                          on: {
                            click: function($event) {
                              return _vm.sort("title", _vm.titleSort)
                            }
                          }
                        },
                        [
                          _vm._v("Title"),
                          _c("i", {
                            staticClass: "ml-10",
                            class:
                              _vm.titleSort === "asc"
                                ? "fa fa-angle-down"
                                : "fa fa-angle-up"
                          })
                        ]
                      )
                    ]),
                    _c("th", [
                      _c(
                        "a",
                        {
                          staticClass: "d-flex",
                          on: {
                            click: function($event) {
                              return _vm.sort("generationDate", _vm.dateSort)
                            }
                          }
                        },
                        [
                          _vm._v("Date"),
                          _c("i", {
                            staticClass: "ml-10",
                            class:
                              _vm.dateSort === "asc"
                                ? "fa fa-angle-down"
                                : "fa fa-angle-up"
                          })
                        ]
                      )
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.alerts, function(alert, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        staticClass: "alertsRow",
                        attrs: { valign: "top" },
                        on: {
                          click: function($event) {
                            return _vm.alertClicked(alert)
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  variant: _vm.getSeverityClass(
                                    alert.alertLevel
                                  )
                                }
                              },
                              [_vm._v(_vm._s(alert.alertLevel))]
                            )
                          ],
                          1
                        ),
                        _c("td", [_vm._v(_vm._s(alert.title))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatDate(alert.generationDate)))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.loading ? _c("div", [_vm._v("Loading...")]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }