var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: { docPath: "/paygate-collections/portal/" }
            }),
            _vm._v("Bulk Payer Creation"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", [
                _vm._v(
                  "Select a Group. A Portal Account will be created for each Payer currently without one, where possible."
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", [
                _vm._v(
                  "Valid Payers are classified as those who have no Portal Account already, and a recorded email address."
                )
              ])
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-5",
                  attrs: {
                    "label-cols": 5,
                    horizontal: "",
                    label: "Group",
                    "label-class": "required"
                  }
                },
                [
                  _c("group-select", {
                    attrs: { id: "groupSelect", groups: _vm.customerGroups },
                    model: {
                      value: _vm.groupId,
                      callback: function($$v) {
                        _vm.groupId = $$v
                      },
                      expression: "groupId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.count !== null
            ? _c("b-form-row", [
                _vm._v(
                  "There are " +
                    _vm._s(_vm.count) +
                    " Payers for which a Portal Account will be created."
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "idb-block-footer" },
        [
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                disabled: !_vm.count || _vm.isLoading
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.bulkCreateForGroup(_vm.groupId)
                }
              }
            },
            [_vm._v("Create Accounts")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }