var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.serviceUserNumber.$error }
        },
        [
          _c(
            "label",
            {
              staticClass: "col-form-label col-md-3 required",
              attrs: { for: "sun-input" }
            },
            [_vm._v("Service User Number")]
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.serviceUserNumber.$model,
                    expression: "details.serviceUserNumber.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "sun-input", type: "text" },
                domProps: { value: _vm.details.serviceUserNumber.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.serviceUserNumber,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "validation-messages",
                {
                  attrs: { name: "service user number" },
                  model: {
                    value: _vm.details.serviceUserNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.details, "serviceUserNumber", $$v)
                    },
                    expression: "details.serviceUserNumber"
                  }
                },
                [
                  _vm.details.serviceUserNumber.sunValidator != undefined &&
                  !_vm.details.serviceUserNumber.sunValidator
                    ? _c("small", { staticClass: "form-text small" }, [
                        _vm._v("Incorrect sun format")
                      ])
                    : _vm._e()
                ]
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "The format for a Service User Number is 6 numbers or a B followed by 5 numbers."
                )
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.importSchema.$error }
        },
        [
          _c(
            "label",
            {
              staticClass: "label-control col-md-3 required",
              attrs: { for: "input-schema-input" }
            },
            [_vm._v("Import Schema")]
          ),
          _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("b-form-select", {
                attrs: { id: "input-schema-input", options: _vm.importSchemas },
                model: {
                  value: _vm.details.importSchema.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.importSchema, "$model", $$v)
                  },
                  expression: "details.importSchema.$model"
                }
              }),
              _c("validation-messages", {
                attrs: { name: "import schema" },
                model: {
                  value: _vm.details.importSchema,
                  callback: function($$v) {
                    _vm.$set(_vm.details, "importSchema", $$v)
                  },
                  expression: "details.importSchema"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.details.importSchema.$model === "Mapping"
        ? _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.details.mappingId.$error }
            },
            [
              _c(
                "label",
                {
                  staticClass: "label-control col-md-3 required",
                  attrs: { for: "mapping-input" }
                },
                [_vm._v("Mapping")]
              ),
              _c(
                "div",
                { staticClass: "col-md-5" },
                [
                  _c("b-form-select", {
                    attrs: { id: "mapping-input", options: _vm.mappings },
                    model: {
                      value: _vm.details.mappingId.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.details.mappingId, "$model", $$v)
                      },
                      expression: "details.mappingId.$model"
                    }
                  }),
                  _c("validation-messages", {
                    attrs: { name: "mapping" },
                    model: {
                      value: _vm.details.mappingId,
                      callback: function($$v) {
                        _vm.$set(_vm.details, "mappingId", $$v)
                      },
                      expression: "details.mappingId"
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c("bank-account-select", {
        attrs: { bankAccounts: _vm.bankAccounts },
        model: {
          value: _vm.details.bankAccountId,
          callback: function($$v) {
            _vm.$set(_vm.details, "bankAccountId", $$v)
          },
          expression: "details.bankAccountId"
        }
      }),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.tokenType.$error }
        },
        [
          _c(
            "label",
            {
              staticClass: "label-control col-md-3 required",
              attrs: { for: "signing-method-hsm" }
            },
            [_vm._v("Payment Signing Method")]
          ),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c(
                "p-radio",
                {
                  staticClass: "p-default p-round",
                  attrs: {
                    id: "signing-method-hsm",
                    value: "SmartCard",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.details.tokenType.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.details.tokenType, "$model", $$v)
                    },
                    expression: "details.tokenType.$model"
                  }
                },
                [_vm._v("Smart Card")]
              ),
              _c(
                "p-radio",
                {
                  staticClass: "p-default p-round",
                  attrs: {
                    id: "signing-method-usb",
                    value: "UsbToken",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.details.tokenType.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.details.tokenType, "$model", $$v)
                    },
                    expression: "details.tokenType.$model"
                  }
                },
                [_vm._v("USB Token")]
              ),
              _c(
                "p-radio",
                {
                  staticClass: "p-default p-round",
                  attrs: {
                    id: "signing-method-code",
                    value: "SecurityCode",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.details.tokenType.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.details.tokenType, "$model", $$v)
                    },
                    expression: "details.tokenType.$model"
                  }
                },
                [_vm._v("Security Code")]
              ),
              _c(
                "p-radio",
                {
                  staticClass: "p-default p-round",
                  attrs: {
                    id: "signing-method-none",
                    value: "None",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.details.tokenType.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.details.tokenType, "$model", $$v)
                    },
                    expression: "details.tokenType.$model"
                  }
                },
                [_vm._v("None")]
              ),
              _c("validation-messages", {
                attrs: { name: "payment signing method" },
                model: {
                  value: _vm.details.tokenType,
                  callback: function($$v) {
                    _vm.$set(_vm.details, "tokenType", $$v)
                  },
                  expression: "details.tokenType"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.paymentLimit.$error }
        },
        [
          _c(
            "label",
            {
              staticClass: "col-form-label col-md-3",
              attrs: { for: "individual-payment-limit-input" }
            },
            [_vm._v("Individual Payment Limit (£)")]
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.paymentLimit.$model,
                    expression: "details.paymentLimit.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "individual-payment-limit-input" },
                domProps: { value: _vm.details.paymentLimit.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.paymentLimit,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "validation-messages",
                {
                  attrs: { name: "Individual payment limit" },
                  model: {
                    value: _vm.details.paymentLimit,
                    callback: function($$v) {
                      _vm.$set(_vm.details, "paymentLimit", $$v)
                    },
                    expression: "details.paymentLimit"
                  }
                },
                [
                  _vm.details.paymentLimit.wholepounds != undefined &&
                  !_vm.details.paymentLimit.wholepounds
                    ? _c("small", { staticClass: "form-text small" }, [
                        _vm._v(
                          "individual item limit can only be in whole pounds"
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v("Set to 0 for no limit")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.submissionLimit.$error }
        },
        [
          _c(
            "label",
            {
              staticClass: "col-form-label col-md-3",
              attrs: { for: "submission-limit-input" }
            },
            [_vm._v("Submission Limit (£)")]
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.submissionLimit.$model,
                    expression: "details.submissionLimit.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "submission-limit-input" },
                domProps: { value: _vm.details.submissionLimit.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.submissionLimit,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "validation-messages",
                {
                  attrs: { name: "Submission payment limit" },
                  model: {
                    value: _vm.details.submissionLimit,
                    callback: function($$v) {
                      _vm.$set(_vm.details, "submissionLimit", $$v)
                    },
                    expression: "details.submissionLimit"
                  }
                },
                [
                  _vm.details.paymentLimit.wholepounds != undefined &&
                  !_vm.details.paymentLimit.wholepounds
                    ? _c("small", { staticClass: "form-text small" }, [
                        _vm._v(
                          "submission item limit can only be in whole pounds"
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v("Set to 0 for no limit")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-group row",
          class: { invalid: _vm.details.email.$error }
        },
        [
          _c(
            "label",
            {
              staticClass: "label-control col-md-3",
              attrs: { for: "group-email-input" }
            },
            [_vm._v("Group Email Address")]
          ),
          _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.details.email.$model,
                    expression: "details.email.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "group-email-input", type: "text" },
                domProps: { value: _vm.details.email.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.details.email,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("validation-messages", {
                attrs: { name: "group email address" },
                model: {
                  value: _vm.details.email,
                  callback: function($$v) {
                    _vm.$set(_vm.details, "email", $$v)
                  },
                  expression: "details.email"
                }
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-form-label col-md-3",
            attrs: { for: "segregateGroupRoles" }
          },
          [_vm._v("Segregate Group Roles")]
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: {
                "label-id": "segregateGroupRoles",
                color: "primary",
                disabled: _vm.disabled
              },
              model: {
                value: _vm.details.segregateGroupRoles.$model,
                callback: function($$v) {
                  _vm.$set(_vm.details.segregateGroupRoles, "$model", $$v)
                },
                expression: "details.segregateGroupRoles.$model"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bacsLicenceSettings.proxyGroup === true,
              expression: "bacsLicenceSettings.proxyGroup === true"
            }
          ],
          staticClass: "form-group row"
        },
        [
          _c(
            "label",
            {
              staticClass: "col-form-label col-md-3",
              attrs: { for: "allowMultiBlock" }
            },
            [_vm._v("Allow Multi-Block")]
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("p-check", {
                staticClass: "p-switch p-fill",
                attrs: {
                  "label-id": "allowMultiBlock",
                  color: "primary",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.details.proxyGroup.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.details.proxyGroup, "$model", $$v)
                  },
                  expression: "details.proxyGroup.$model"
                }
              })
            ],
            1
          )
        ]
      ),
      _c("delete-payment-data", {
        attrs: { groupDetails: _vm.details, disabled: _vm.disabled }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }