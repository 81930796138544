var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [_c("b-col", [_c("div", { ref: "editor" })])],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", disabled: _vm.isEmpty },
                  on: { click: _vm.addNote }
                },
                [_vm._v("Add Note")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.rows, function(row, index) {
        return _c("div", { key: index, staticClass: "card mb-4" }, [
          _c("div", { staticClass: "card-header" }, [
            _c(
              "div",
              { staticClass: "media flex-wrap align-items-center" },
              [
                _c("avatar", {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top.d500",
                      value: row.name,
                      expression: "row.name",
                      modifiers: { hover: true, top: true, d500: true }
                    }
                  ],
                  staticClass: "d-block ui-w-40 rounded-circle",
                  attrs: {
                    "user-id": row.userId,
                    width: "30",
                    height: "30",
                    title: row.name
                  }
                }),
                _c("div", { staticClass: "media-body ml-3" }, [
                  _c("a", { attrs: { href: "javascript:void(0)" } }, [
                    _vm._v(_vm._s(row.name))
                  ]),
                  _c("div", { staticClass: "text-muted small" }, [
                    _vm._v(_vm._s(_vm.formatDate(row.creationdate)))
                  ])
                ])
              ],
              1
            ),
            _c("div", {
              staticClass: "card-body",
              domProps: { innerHTML: _vm._s(row.notetext) }
            })
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }