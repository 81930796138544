<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Monitor<favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <div class="progress-bar bg-primary" role="progressbar" :style=getCountdown() aria-valuemax="100"></div>
          <br/>



          <div class="row">
            <div class="col-md-4"><h4>Status</h4></div>
          </div>
          <br/>
          <div class="row">
            <div class="col-md-3">Sensor</div>
            <div class="col-md-2">Last Time / Date</div>
            <div class="col-md-2">State</div>
            <div class="col-md-2">Transition Date</div>
            <div class="col-md-2">Notes</div>
          </div>
          <hr>
          <div class="row" v-for="(sensor, index) in sensorsState" :key="index">
            <div class="col-md-3">{{sensor.component}}</div>
            <div class="col-md-2">{{reformatDate(sensor.lastHeartbeat)}}</div>
            <div class="col-md-2" v-if="sensor.state==='pass'"><span class="badge badge-pill badge-success">Healthy</span></div>
            <div class="col-md-2" v-else-if="sensor.state==='fail'"><span class="badge badge-pill badge-danger">Fail</span></div>
            <div class="col-md-2" v-else><span class="badge badge-pill badge-info">{{sensor.state}}</span></div>
            <div class="col-md-2" v-if="sensor.state==='fail'">{{reformatDate(sensor.initialFailDate)}}</div>
            <br/><br/>
          </div>
        </div>
      </div>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Alerting</h2>
        </div>
        <div class="idb-block-content">
          <div class="row" v-if="isCorvidAdmin">
            <div class="col-md-4">
              <p-check name="check" class="p-switch" color="primary " v-model="emailAlertsEnabled">Email Workflow Alerts</p-check>
            </div>
          </div>
          <br/>
          <transition name="fadein">
            <div class="textarea" v-if="isCorvidAdmin">
              <div class="form-group" v-if="emailAlertsEnabled">
                <label class="">Email Alerts Recipients</label>
                <input-tag
                  :class="{invalid: $v.emailAlertRecipientsArray.$error}"
                  v-model="emailAlertRecipientsArray"
                  :limit="32"
                  validate="email"
                  :allowDuplicates="false"
                ></input-tag>
              </div>
            </div>
          </Transition>
          <!-- <div class="row" v-if="isCorvidAdmin">
            <div class="col-md-4">
              <p-check name="check" class="p-switch" color="primary " v-model="smsAlertsEnabled">SMS Workflow Alerts</p-check>
            </div>
          </div> -->
          <!-- <br/>
          <transition name="fadein">
            <div class="textarea" v-if="isCorvidAdmin">
              <div class="form-group" v-if="smsAlertsEnabled">
                <label class="">SMS Alerts Recipients</label>
                <input-tag
                  :class="{invalid: $v.smsAlertRecipientsArray.$error}"
                  v-model="smsAlertRecipientsArray"
                  :limit="32"
                  validate="/[a-zA-Z]/"
                  :allowDuplicates="false"
                ></input-tag>
              </div>
            </div>
         </transition> -->

        </div>
</div>

<div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Control</h2>
        </div>

        <div class="idb-block-content">
          <!-- <div class="row">
            <div class="col-md-4"><h4>Workflow Control</h4></div>
          </div>
          <br/> -->
          <div class="alert alert-warning" v-if="enabledWorkflowProcessor==false" role="alert" aria-label="Warning:">
            <i class="fas fa-exclamation-triangle mr-2"></i><b>Warning.</b> Workflow execution is currently disabled.
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <label for="enableWorkflowProcessor">Enabled Workflow Processor</label>
            </div>
            <div class="col-md-2">
              <p-check name="check" :labelId="'enableWorkflowProcessor'" class="p-switch" color="primary " @change="saveWorkflowControlConfig" v-model="enabledWorkflowProcessor"></p-check>
            </div>
            <div class="col-md-6">
              When disabled, workflows that are currently running will be allowed to complete but new workflows will <strong>not</strong> start.
            </div>
          </div>
          <br/>
        </div>
        <div class="idb-block-footer">
          <div>
            <button class="btn btn-primary" @click="btnSave"><i class="fas fa-save "></i> Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
import roles from '@/Assets/Constants/roles'
export default {
  name: 'my-component',
  data () {
    return {
      emailAlertRecipientsArray: [],
      emailAlertsEnabled: true,
      smsAlertRecipientsArray: [],
      smsAlertsEnabled: true,
      sensorsState: [],
      progressBarValue: 100,
      timer: undefined,
      timerValue: 0,
      refreshPeriod: 30000,
      timerInterval: 1000,
      enabledWorkflowProcessor: false
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    isCorvidAdmin () {
      return this.$store.getters.isInRole(roles.SystemUser)
    }
  },
  validations: {
    emailAlertRecipientsArray: {
      required,
      maxLength: maxLength(1024)
    },
    smsAlertRecipientsArray: {
      required,
      maxLength: maxLength(1024)
    }
  },
  methods: {
    async saveWorkflowControlConfig () {
      const workflowControl = {
        workflowsEnabled: this.enabledWorkflowProcessor
      }
      let res
      try {
        res = await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowcontrol`, workflowControl)
        if (res) {
          console.log(res.data)
          this.$toastr.s('Workflow control settings have been updated', 'Workflow Control')
        }
      } catch (e) {
        this.$snapbar.e('Could not update workflow subsystem configuration.')
      }
    },
    async getWorkflowControlConfig () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowcontrol`)
        if (res && res.data) {
          this.enabledWorkflowProcessor = res.data.workflowsEnabled
        } else {
          this.$snapbar.w('Warning: Could not get workflow subsystem configuration.')
        }
      } catch (e) {
        this.$snapbar.e('Error: Could not get workflow subsystem configuration.')
      }
    },
    getCountdown () {
      return `width: ${this.progressBarValue}%`
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    async btnSave (trigger) {
      this.$snapbar.hide()
      const config = {
        emailAlertsEnabled: this.emailAlertsEnabled,
        smsAlertsEnabled: this.smsAlertsEnabled,
        emailAlertRecipients: this.emailAlertRecipientsArray.toString(),
        smsAlertRecipients: this.smsAlertRecipientsArray.toString()
      }
      try {
        await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}alertConfig`, config)
        this.$toastr.s('The config was successfully updated.')
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errorMessage) {
          this.$snapbar.e(`Could not update the config. ${e.response.data.errorMessage}`)
        } else {
          this.$snapbar.e(`Error updating config - ${e.message}`)
        }
      }
    },
    async load () {
      await this.getWorkflowControlConfig()
      let res
      let ssres
      try {
        // Heartbeats
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}alertconfig`)
        if (res) {
          if (res.data.alertConfig && res.data.alertConfig.emailAlertRecipients) {
            this.emailAlertRecipientsArray = res.data.alertConfig.emailAlertRecipients.split(',')
            this.smsAlertRecipientsArray = res.data.alertConfig.smsAlertRecipients.split(',')
          }
          this.emailAlertsEnabled = res.data.alertConfig.emailAlertsEnabled
        }
        // Sensors
        ssres = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}sensorstate`)
        if (ssres) {
          this.sensorsState = ssres.data.heartbeats
        }
      } catch (e) {
         if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this data.')
        } else if (e.response && e.response.status === 404) {
          this.$snapbar.e('Could not retrieve the workflow monitor config.')
        } 
        else {
          this.$snapbar.e(`Could not display the Workflow Monitor configuration - ${e.message}`)
          console.log(e)
        }
      }
    }
  },
  async created () {
  },
  async mounted () {
    await this.load()
    this.timerValue = this.refreshPeriod
    this.timer = setInterval(() => {
      this.timerValue -= this.timerInterval
      this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
      if (this.progressBarValue <= 0) {
        this.timerValue = this.refreshPeriod
        this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
        this.load()
      }
    }, this.timerInterval)
    this.progressBarValue = (this.timerValue / this.refreshPeriod) * 100
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      // await this.getTriggerData()
    }
  }
}
</script>
<style scoped>
  .progress {
    height: 4px;
    line-height: 4px;
  }
  .progress-bar {
    height: 4px;
    line-height: 4px;
  }
  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
  /* td {
    cursor: pointer;
  } */
</style>
