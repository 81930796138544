var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "bulkDateEditorModal",
      attrs: {
        id: "bulk-date-editor",
        title: "Processing Date Editor",
        size: "xl",
        "hide-header-close": "",
        static: "",
        "ok-only": true
      },
      on: { shown: _vm.load },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(props) {
            return _c("footer", {}, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary mr-1",
                  on: {
                    click: function($event) {
                      return props.cancel()
                    }
                  }
                },
                [_vm._v("Close")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    disabled:
                      _vm.selectedFilenumbers !== null &&
                      _vm.selectedFilenumbers.length === 0
                  },
                  on: { click: _vm.applyDates }
                },
                [_vm._v("Apply Date to Block/s")]
              )
            ])
          }
        }
      ])
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v("Close")
      ]),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "shadow-sm p-3 mb-5 rounded" }, [
          _c("div", { staticClass: "panel panel-default" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading collapsed",
                attrs: {
                  "data-toggle": "collapse",
                  "data-target": "#collapseOrderItems1"
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("i", { staticClass: "chevron fa fa-fw" }),
                    _vm._v(" Click for filter options ")
                  ]),
                  _c("div", { staticClass: "col-sm-6" }),
                  _vm.filterOptionsActive
                    ? _c(
                        "div",
                        { staticClass: "col-sm-3 pull-right text-warning" },
                        [_vm._v("Filter options active")]
                      )
                    : _vm._e()
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "collapse", attrs: { id: "collapseOrderItems1" } },
              [
                _c(
                  "b-card",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "mt-2", attrs: { sm: "3" } },
                          [
                            _c("label", { attrs: { for: "FilenameFilter" } }, [
                              _vm._v("Filename")
                            ])
                          ]
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("b-form-input", {
                              ref: "BDEfilenameFilter",
                              attrs: { id: "FilenameFilter", type: "text" },
                              on: {
                                input: function($event) {
                                  return _vm.onFilterChanged()
                                }
                              },
                              model: {
                                value: _vm.BDEfilenameFilter,
                                callback: function($$v) {
                                  _vm.BDEfilenameFilter =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "BDEfilenameFilter"
                              }
                            })
                          ],
                          1
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover",
                              value: {
                                content:
                                  "<span>To search filenames you can use wildcards. </br> <ul> <li>You can type a distinct filename to search, <i>for example, MyFile.txt</i></li> <li>You can use a * symbol to denote part of a filename, <i>for example, MyFile*.*, or My*File.txt</i></li> </ul></span>",
                                html: true
                              },
                              expression:
                                " { content: '<span>To search filenames you can use wildcards. </br> <ul> <li>You can type a distinct filename to search, <i>for example, MyFile.txt</i></li> <li>You can use a * symbol to denote part of a filename, <i>for example, MyFile*.*, or My*File.txt</i></li> </ul></span>', html: true }",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fas fa-question-circle text-primary",
                          staticStyle: { "caret-color": "transparent" },
                          attrs: {
                            contenteditable: "",
                            onkeypress: "event.preventDefault()",
                            onpaste: "event.preventDefault()",
                            "data-bs-toggle": "tooltip",
                            "data-bs-html": "true",
                            "data-bs-trigger": "focus"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "mt-2", attrs: { sm: "3" } },
                          [
                            _c("label", { attrs: { for: "BDEFileNumber" } }, [
                              _vm._v("File Number")
                            ])
                          ]
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("b-form-input", {
                              ref: "BDEfilenumberFilter",
                              attrs: { id: "BDEFileNumber", type: "text" },
                              on: {
                                input: function($event) {
                                  return _vm.onFilterChanged()
                                }
                              },
                              model: {
                                value: _vm.BDEfilenumberFilter,
                                callback: function($$v) {
                                  _vm.BDEfilenumberFilter =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "BDEfilenumberFilter"
                              }
                            })
                          ],
                          1
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover",
                              value: {
                                content:
                                  "<span> To search for file numbers, you can use these hints. However, do note that the removal or addition of file blocks will potentially mean files are no longer numbered as you might expect them. </br> <ul> <li>You can type a distinct file number to search</li> <li>You can use a , to enter multiple queries, <i>for example, 1,4,6,8 will return only those file numbers</i></li> <li>You can use a - for a rnage of file numbers, <i>for example, 10-20 will return only file bocks 10 through 20</i></li> <li>You can combine all of these searches, <i>for example, 1,2,5-8 will return only file bocks 1, 2 and 5 through 8</i></li> </ul></span>",
                                html: true
                              },
                              expression:
                                " { content: '<span> To search for file numbers, you can use these hints. However, do note that the removal or addition of file blocks will potentially mean files are no longer numbered as you might expect them. </br> <ul> <li>You can type a distinct file number to search</li> <li>You can use a , to enter multiple queries, <i>for example, 1,4,6,8 will return only those file numbers</i></li> <li>You can use a - for a rnage of file numbers, <i>for example, 10-20 will return only file bocks 10 through 20</i></li> <li>You can combine all of these searches, <i>for example, 1,2,5-8 will return only file bocks 1, 2 and 5 through 8</i></li> </ul></span>', html: true }",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fas fa-question-circle text-primary",
                          staticStyle: { "caret-color": "transparent" },
                          attrs: {
                            contenteditable: "",
                            onkeypress: "event.preventDefault()",
                            onpaste: "event.preventDefault()",
                            "data-bs-toggle": "tooltip",
                            "data-bs-html": "true",
                            "data-bs-trigger": "focus"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "mt-2", attrs: { sm: "3" } },
                          [
                            _c(
                              "label",
                              { attrs: { for: "BDEBureauCustomerSUNFilter" } },
                              [_vm._v("Bureau Customer or SUN")]
                            )
                          ]
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("b-form-input", {
                              ref: "BDEbureauCustomerSUNFilter",
                              attrs: {
                                id: "BDEBureauCustomerSUNFilter",
                                type: "text"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onFilterChanged()
                                }
                              },
                              model: {
                                value: _vm.BDEbureauCustomerSunFilter,
                                callback: function($$v) {
                                  _vm.BDEbureauCustomerSunFilter =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "BDEbureauCustomerSunFilter"
                              }
                            })
                          ],
                          1
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover",
                              value: {
                                content:
                                  "<span> To search bureau customer names and SUNs you can use wildcards. </br> <ul> <li>You can type a distinct bureau customer name or a SUN to search, <i>for example, MyCustomer or 123456</i></li> <li>You can use a , to enter multiple queries, <i>for example, 123456, 234567, MyCustomer are valid inputs</i></li> <li>You can use a * for bureau customer names, <i>for example, MyCust* will search for any bureau customer beginning with MyCust</i></li> <li>You can also combine these options <i>for example, MyCust*, 123456, 234567 will search for any bureau customer beginning with MyCust and any bureau customers with those SUNs</i></li> <li> <i>Note that this search criteria will search both bureau customer name and SUN for any inputs, for example if a bureau customer contains a six digit number that also pertains to a separate SUN, this will also be returned.</i> </li> </ul></span>",
                                html: true
                              },
                              expression:
                                " { content: '<span> To search bureau customer names and SUNs you can use wildcards. </br> <ul> <li>You can type a distinct bureau customer name or a SUN to search, <i>for example, MyCustomer or 123456</i></li> <li>You can use a , to enter multiple queries, <i>for example, 123456, 234567, MyCustomer are valid inputs</i></li> <li>You can use a * for bureau customer names, <i>for example, MyCust* will search for any bureau customer beginning with MyCust</i></li> <li>You can also combine these options <i>for example, MyCust*, 123456, 234567 will search for any bureau customer beginning with MyCust and any bureau customers with those SUNs</i></li> <li> <i>Note that this search criteria will search both bureau customer name and SUN for any inputs, for example if a bureau customer contains a six digit number that also pertains to a separate SUN, this will also be returned.</i> </li> </ul></span>', html: true }",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fas fa-question-circle text-primary",
                          staticStyle: { "caret-color": "transparent" },
                          attrs: {
                            contenteditable: "",
                            onkeypress: "event.preventDefault()",
                            onpaste: "event.preventDefault()",
                            "data-bs-toggle": "tooltip",
                            "data-bs-html": "true",
                            "data-bs-trigger": "focus"
                          }
                        })
                      ],
                      1
                    ),
                    false
                      ? _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "mt-2", attrs: { sm: "3" } },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "BDEDebitsCreditsFilter" } },
                                  [_vm._v("Debits And Credits Value Filter")]
                                )
                              ]
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("b-form-input", {
                                  ref: "BDEdebitsCreditsFilter",
                                  attrs: {
                                    id: "BDEDebitsCreditsFilter",
                                    type: "text"
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.onFilterChanged()
                                    }
                                  },
                                  model: {
                                    value: _vm.BDEdebitsCreditsFilter,
                                    callback: function($$v) {
                                      _vm.BDEdebitsCreditsFilter =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "BDEdebitsCreditsFilter"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover",
                                  value: {
                                    content:
                                      "<span> You can use this to search based on the total value of either debits or credits in a file block. </br> <ul> <li>You can type a distinct value to search, <i>for example, £5000 will return any file block where the total debits or credits sums to exactly £5000. You can also omit the £ if you would like</i></li> <li>You can use a , to enter multiple queries, <i>for example, £5000, £8000 will return any file blocks where the total debits or credits sums to either £5000 or £8000</i></li> <li>You can use a - for a range of values, <i>for example, £5000-£8000 will search for any file blocks where the total credits or debits is between £5000 and £8000</i></li> <li>You can use a ~ symbol to add an implicit 5% variance to the entered amount, <i>for example, ~£5000 will search for any file blocks where the total credits or debits is £5000 with a tolerance of 5%, that is £4750 - £5250. This is useful if you only know the rough value of the file block you are searching for </i></li> <li>You can use combine all of these operations, <i>for example, £1000, £5000-£8000, ~£9000-~£12000 will search for any file blocks where the total credits or debits is £1000, between £5000 and £8000 and between £9000 and £12000 with a 5% tolerance</i></li> </ul></span>",
                                    html: true
                                  },
                                  expression:
                                    " { content: '<span> You can use this to search based on the total value of either debits or credits in a file block. </br> <ul> <li>You can type a distinct value to search, <i>for example, £5000 will return any file block where the total debits or credits sums to exactly £5000. You can also omit the £ if you would like</i></li> <li>You can use a , to enter multiple queries, <i>for example, £5000, £8000 will return any file blocks where the total debits or credits sums to either £5000 or £8000</i></li> <li>You can use a - for a range of values, <i>for example, £5000-£8000 will search for any file blocks where the total credits or debits is between £5000 and £8000</i></li> <li>You can use a ~ symbol to add an implicit 5% variance to the entered amount, <i>for example, ~£5000 will search for any file blocks where the total credits or debits is £5000 with a tolerance of 5%, that is £4750 - £5250. This is useful if you only know the rough value of the file block you are searching for </i></li> <li>You can use combine all of these operations, <i>for example, £1000, £5000-£8000, ~£9000-~£12000 will search for any file blocks where the total credits or debits is £1000, between £5000 and £8000 and between £9000 and £12000 with a 5% tolerance</i></li> </ul></span>', html: true }",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass:
                                "fas fa-question-circle text-primary",
                              staticStyle: { "caret-color": "transparent" },
                              attrs: {
                                contenteditable: "",
                                onkeypress: "event.preventDefault()",
                                onpaste: "event.preventDefault()",
                                "data-bs-toggle": "tooltip",
                                "data-bs-html": "true",
                                "data-bs-trigger": "focus"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("b-row", { staticClass: "mt-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-primary ml-1",
                          attrs: { disabled: _vm.isTableLoading },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onClickApplyFilter()
                            }
                          }
                        },
                        [_vm._v("Apply Filter")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-danger ml-1",
                          attrs: { disabled: _vm.isTableLoading },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onClickClearFilter()
                            }
                          }
                        },
                        [_vm._v("Clear Filter")]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          { staticClass: "row form-group col-md-4" },
          [
            _c("b-col", { staticClass: "mt-2", attrs: { sm: "5" } }, [
              _c("label", { attrs: { for: "paymentDate" } }, [
                _c("strong", [_vm._v("Payment Date:")])
              ])
            ]),
            _c(
              "b-col",
              { attrs: { sm: "7" } },
              [
                _c("vuejsDatepicker", {
                  ref: "paymentDate",
                  attrs: {
                    name: "paymentDate",
                    id: "paymentDate",
                    format: "dd/MM/yyyy",
                    "input-class": "form-control",
                    readonly: false,
                    disabledDates: _vm.disabledDates,
                    "monday-first": true
                  },
                  on: { selected: _vm.paymentDateChanged },
                  nativeOn: {
                    focusin: function($event) {
                      return _vm.dateOnfocus($event)
                    }
                  },
                  model: {
                    value: _vm.paymentDate,
                    callback: function($$v) {
                      _vm.paymentDate = $$v
                    },
                    expression: "paymentDate"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row form-group col-md-4" },
          [
            _c("b-col", { staticClass: "mt-2", attrs: { sm: "5" } }, [
              _c("span", [_c("strong", [_vm._v("Processing Date:")])])
            ]),
            _c("b-col", { staticClass: "mt-2", attrs: { sm: "7" } }, [
              _vm._v(_vm._s(_vm.processingDate))
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "vue-good-table",
              {
                ref: "table",
                attrs: {
                  paginationOptions: _vm.paginationOptions,
                  "sort-options": _vm.sortOptions,
                  isLoading: _vm.isTableLoading,
                  rows: _vm.rows,
                  columns: _vm.columns,
                  totalRows: _vm.totalRecords,
                  mode: "remote",
                  "select-options": { enabled: true }
                },
                on: {
                  "on-page-change": _vm.onPageChange,
                  "on-sort-change": _vm.onSortChange,
                  "on-column-filter": _vm.onColumnFilter,
                  "on-per-page-change": _vm.onPerPageChange,
                  "update:isLoading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "update:is-loading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "on-search": _vm.onSearch,
                  "on-selected-rows-change": _vm.selectionChanged
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field === "checkbox"
                          ? _c(
                              "span",
                              [
                                _c("label", {
                                  attrs: {
                                    for: "checkBox_" + props.row.fileNumber
                                  }
                                }),
                                _c("p-check", {
                                  staticClass: "p-default p-curve",
                                  attrs: {
                                    labelId: "checkBox_" + props.row.fileNumber,
                                    checked: "isChecked(props.row)"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.rowClick(props.row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "table-actions" }, slot: "table-actions" },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Clear filters",
                            expression: "'Clear filters'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "btn btn-link",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.clearTableFilters($event)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fa-stack",
                            staticStyle: { "font-size": "10px" }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                            }),
                            _c("i", {
                              staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                            })
                          ]
                        ),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Clear filters")
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }