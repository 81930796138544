<template>
  <div>
    <h3>Email Message<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/messaging/emailmessage/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>    <hr>
    <br/>
    <div class="form-group">
      <label class="required">Subject</label>
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The Subject field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label>Message</label>
        <textarea class="form-control"
          rows="8"
          cols="40"
          :class="{invalid: $v.selectedNode.props.s2.value.$error}"
          @input="$v.selectedNode.props.s2.value.$touch()"
          v-model="selectedNode.props.s2.value">
        </textarea>
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The message field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
      </div>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label class="required">To</label>
        <input-tag
          :class="{invalid:
          $v.toEmailAddress.$error}"
          v-model="toEmailAddress"
          :limit="32"
          validate="email"
          :allowDuplicates="false">
        </input-tag>
        <p class="validationText" v-if="!$v.toEmailAddress.required && $v.toEmailAddress.$dirty ">At least one 'To' email address is required!</p>
        <p class="validationText" v-if="!$v.toEmailAddress.maxLength">Maximum length is {{ $v.toEmailAddress.$params.maxLength.max }} characters!</p>
      </div>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label>Cc</label>
        <input-tag
          v-model="ccEmailAddress"
          :limit="32"
          validate="email"
          :allowDuplicates="false">
        </input-tag>
      </div>
    </div>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label>Bcc</label>
        <input-tag
          v-model="bccEmailAddress"
          :limit="32"
          validate="email"
          :allowDuplicates="false">
        </input-tag>
      </div>
    </div>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  validations: {
    toEmailAddress: {
      required,
      maxLength: maxLength(1024)
    },
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(4096)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(1024)
          }
        },
        s4: {
          value: {
            maxLength: maxLength(1024)
          }
        },
        s5: {
          value: {
            maxLength: maxLength(1024)
          }
        }
      }
    }
  },
  data () {
    return {
      toEmailAddress: [],
      ccEmailAddress: [],
      bccEmailAddress: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      this.selectedNode.props.s3.value = this.toEmailAddress.toString()
      this.selectedNode.props.s4.value = this.ccEmailAddress.toString()
      this.selectedNode.props.s5.value = this.bccEmailAddress.toString()
      console.log('selectedNode')
      console.log(this.selectedNode)
      if (this.$v.$invalid) {
        console.log('Invalid')
        console.log(this.$v)
        this.$v.$touch()
        return
      }

      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    if (this.selectedNode.props.s3.value) {
      this.toEmailAddress = this.selectedNode.props.s3.value.split(',')
    }
    if (this.selectedNode.props.s4.value) {
      this.ccEmailAddress = this.selectedNode.props.s4.value.split(',')
    }
    if (this.selectedNode.props.s5.value) {
      this.bccEmailAddress = this.selectedNode.props.s5.value.split(',')
    }
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
