<template>
  <div id="createBacsSubUsingEditor">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Create BACS Submission Using Editor
              <help-icon :docPath="helpUrl"></help-icon>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2 required">
                <strong><label for="groupSelector">Select a Group</label></strong>
              </div>
              <div class="col-md-4">
                <group-select
                  id="groupSelector"
                  v-model="selectedGroupId"
                  :groups="groups"
                  @input="selectGroup()"
                  :clearable="false"
                ></group-select>
              </div>
              <div class="col-md-1">
                <button
                  class="btn btn-light"
                  :disabled="this.groupBankLoaded === false"
                  @click="viewGroupBankDetails"
                >View</button>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2 required">
                <strong><label for="reference">Submission Reference</label></strong>
              </div>
              <div class="col-md-6">
                <input
                  name="reference"
                  id="reference"
                  v-model="submissionData.reference"
                  v-on:input="referenceChanged($event.target.value)"
                  class="form-control"
                  style="width: 300px;"
                  maxlength="150"
                />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2 required">
                <strong><label for="paymentDate">Payment Date</label></strong>
              </div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="paymentDate"
                  name="paymentDate"
                  id="paymentDate"
                  v-model="paymentDate"
                  format="dd/MM/yyyy"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  :disabledDates="disabledDates"
                  v-on:selected="paymentDateChanged"
                  @focusin.native="dateOnfocus"
                  :monday-first="true"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>BACS Processing Date</strong>
              </div>
              <div class="col-md-2">{{ processingDate }}</div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <strong><label for="contraNarrative">Contra Narrative</label></strong>
              </div>
              <div class="col-md-6">
                <input
                  name="contraNarrative"
                  id="contraNarrative"
                  v-model="submissionData.contraNarrative"
                  class="form-control"
                  style="width: 300px;"
                  maxlength="50"
                />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="enableEnterPayments === false"
                  v-on:click="enterPayments"
                >Enter BACS Payments</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import bacsCommon from '@/Lib/BacsCommon.js'
import axios from 'axios'
import moment from 'moment'
import bacsMixin from '@/Lib/BacsMixin.js'
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
  name: 'createBacsSubUsingEditor',
  mixins: [
    bacsMixin
  ],
  components: {
  },
  data: () => ({
    groups: [],
    pgeGroups: [],
    pgoGroups: [],
    PGEGROUPTYPE: '0',
    PGOGROUPTYPE: '3',
    paygateId: '',
    selectedGroup: {},
    selectedGroupId: '',
    submissionData: {},
    groupSelected: false,
    subRef: '',
    processingDates: {},
    paymentDate: '',
    processingDate: '',
    disabledDates: {},
    changeSubmission: false,
    bacsGroup: {},
    bearerToken: '',
    signingUser: {},
    npds: [],
    paymentDateDisplay: '',
    bacsLicenceSetting: '',
    groupBankAccount: {},
    groupBankLoaded: false,
    helpUrl: '',
    disableDatePicker: true
  }),
  computed: {
    enableEnterPayments: function () {
      const refNotJustSpaces = this.subRef.replace(/\s/g, '').length > 0
      return this.groupSelected && refNotJustSpaces
    },
    disablePicker: function () {
      return this.disableDatePicker || this.paymentDate.length === 0
    },
    ...mapGetters(['selectedCustomer'])
  },
  methods: {
    async selectGroup () {
      this.selectedGroup = this.groups.find(i => i.groupId === this.selectedGroupId)
      this.$store.commit('setSelectedGroup', this.selectedGroup)
      this.groupSelected = true
      this.submissionData.groupId = this.selectedGroup.groupId
      this.submissionData.groupName = this.selectedGroup.name

      await this.getSelectedGroupDetails()

      this.disableDatePicker = this.bacsGroup.groupTypeDetails.importSchema === 'Standard18'
      var payload = { paygateId: this.paygateId, groupId: this.selectedGroupId, platformUrl: this.$platformApiBaseUrl }
      this.groupBankLoaded = false
      await this.$store.dispatch('getGroupBankAccount', payload)
      this.groupBankAccount = await this.$store.getters.groupBankAccount
      this.groupBankLoaded = true

      this.signingUser = this.bacsGroup.groupUserRights.find(i => i.sign === true)
      if (this.signingUser === undefined) {
        this.$toastr.w('There are no users who can sign a submission created for this group.')
      }
    },

    async getSelectedGroupDetails () {
      await this.$store.dispatch('getSubBacsGroup', this.selectedGroupId)
      this.bacsGroup = this.$store.getters.bacsGroup
    },

    async viewGroupBankDetails () {
      await swal.fire({
        title: '"' + this.submissionData.groupName + '" group bank details',
        html: bacsCommon.viewGroupBankDetails(this.groupBankAccount, this.submissionData.groupName),
        icon: 'info'
      })
    },

    referenceChanged (value) {
      this.subRef = value
    },

    paymentDateChanged (value) {
      this.paymentDateDisplay = moment(value).format('DD/MM/YYYY')

      // Get Processing Date from selected Payment Date.
      this.$store.dispatch('getNextProcessingDate', value.toJSON())
        .then(response => {
          this.processingDate = this.$store.getters.nextProcessingDate
        })
    },

    enterPayments () {
      if (this.submissionData.contraNarrative === undefined) {
        this.submissionData.contraNarrative = ''
      }

      var json = JSON.stringify({
        contraNarrative: this.submissionData.contraNarrative,
        processingDate: this.processingDate,
        serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
        paymentNetworkType: 'BacstelIp'
      })

      return axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/initSubmission/',
        data: json
      })
        .then(response => {
          this.$store.dispatch('setImportFileResponse', response.data)
          this.submissionData.submissionId = response.data.messageId
          this.submissionData.reference = this.subRef
          this.submissionData.processingDate = this.processingDate
          this.submissionData.paymentDate = this.paymentDate
          this.submissionData.paymentNetworkType = 'BacstelIp'
          this.submissionData.submissionType = 'BACS'
          this.submissionData.createPage = 'createBacsSubUsingEditor'
          this.submissionData.filename = 'N/A'
          if (this.submissionData.contraNarrative === undefined) {
            this.submissionData.contraNarrative = ''
          }
          this.$store.dispatch('setSubmissionData', this.submissionData)
          this.$router.push('/payments/bacspayments/viewImportedPayments')
        })
        .catch(error => console.log(error))
    },

    async loadGroups (groupType) {
      var payload = { paygateId: this.paygateId, groupType: groupType, groupRole: ['create', 'add'] }
      await this.$store.dispatch('getGroups', payload)

      if (groupType === this.PGEGROUPTYPE) {
        this.pgeGroups = this.$store.getters.groups
      } else {
        this.pgoGroups = this.$store.getters.groups
      }
    },

    async populateGroupCombo () {
      this.groups = []
      for (var i = 0; i < this.pgeGroups.length; i++) {
        this.groups.push(this.pgeGroups[i])
      }

      for (i = 0; i < this.pgoGroups.length; i++) {
        this.groups.push(this.pgoGroups[i])
      }

      this.groups.sort((a, b) => a.name.localeCompare(b.name))
    },

    async loadLicence () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence')
      this.bacsLicenceSetting = response.data.bacs.value
    },

    async checkLicence () {
      var licenceOk = true
      if (this.bacsLicenceSetting === 'Direct' || this.bacsLicenceSetting === 'Both') {
        await this.loadGroups(this.PGEGROUPTYPE)
      }
      if (this.bacsLicenceSetting === 'Indirect' || this.bacsLicenceSetting === 'Both') {
        await this.loadGroups(this.PGOGROUPTYPE)
      }
      if (this.bacsLicenceSetting === 'Off') {
        this.$snapbar.w('You are not licensed to create BACS submissions.')
        licenceOk = false
      }

      return licenceOk
    },

    async getCustomer () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/allowedlivesubmissions`)
      if (!response.data) {
        this.$snapbar.e('Your customer is set in \'Test Mode\' so is not allowed to send live submissions at this time.')
      }
    }
  },

  async mounted () {
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    this.helpUrl = '/payments/bacs-payments/createbacssubmissionusingeditor/'

    await this.loadLicence()
    if (!await this.checkLicence()) {
      return
    }

    await this.populateGroupCombo()

    if (this.groups.length > 0) {
      await this.$store.dispatch('setGroups', this.groups)
    } else {
      this.$snapbar.w('You don\'t have permission to create a submission for any groups.')
    }

    await this.getCustomer()

    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })

    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.groups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group.
      if (this.selectedGroup !== undefined) {
        this.selectedGroupId = defaultGroupId
        await this.selectGroup()
      }
    }

    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'

    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        this.paymentDate = new Date(this.processingDates.minPaymentDate)
        this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate

        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          this.npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            this.npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = this.npds
        }

        if (this.bacsGroup.groupTypeDetails === undefined || this.bacsGroup.groupTypeDetails === null) {
          this.disableDatePicker = false
        } else {
          this.disableDatePicker = this.bacsGroup.groupTypeDetails.importSchema === 'Standard18'
        }

        this.changeSubmission = this.$route.query.changeSubmission
        if (this.changeSubmission === 'true') {
          this.submissionData = this.$store.getters.submissionData
          this.selectedGroupId = this.submissionData.groupId
          this.groupSelected = true
          this.subRef = this.submissionData.reference
          this.processingDate = this.submissionData.processingDate
          this.paymentDate = this.submissionData.paymentDate
          this.changeSubmission = false

          this.selectedGroup = this.groups.find(i => i.groupId === this.submissionData.groupId)
          this.selectGroup()
        }
      })
  }
}
</script>
