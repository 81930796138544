<template>
  <div class="fillHeightContainer">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Missed Payment Map</h4>
    </div>
    <div class="mapContainer">
      <b-container fluid class="flex-grow" style="min-height:100%; height:100%">
        <b-row class="align-self-stretch d-flex flex-grow" style="min-height:100%; height:100%">
          <b-col class="growBox" v-if="!loading">
            <l-map
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              class="mapBox"
              @update:center="centerUpdate"
              @update:zoom="zoomUpdate"
              ref="postcodeMap"
              @ready="mapReady"
            >
              <l-tile-layer :url="baseLayer" :attribution="attribution" />
            </l-map>
          </b-col>
          <b-col class="growBox" v-if="loading">Loading...</b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import leaflet, { latLng } from 'leaflet'
import axios from 'axios'
import 'leaflet.heat'
import { setTimeout } from 'timers'
import topojson from 'topojson'
import Loading from '@/Assets/Components/Loading/Loading'

leaflet.TopoJSON = leaflet.GeoJSON.extend({
  addData: function (data) {
    var geojson, key
    if (data.type === 'Topology') {
      for (key in data.objects) {
        if (data.objects.hasOwnProperty(key)) {
          geojson = topojson.feature(data, data.objects[key])
          leaflet.GeoJSON.prototype.addData.call(this, geojson)
        }
      }
      return this
    }
    leaflet.GeoJSON.prototype.addData.call(this, data)
    return this
  }
})
leaflet.topoJson = function (data, options) {
  return new leaflet.TopoJSON(data, options)
}
export default {
  name: 'MissedPaymentMapWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      layerId: 'mapbox.streets',
      token: 'pk.eyJ1IjoiZGF2aWRob29kY29ydmlkIiwiYSI6ImNqdnhsMHJjbjA1cjY0M21ydGExNnJjcmgifQ.zpMhPUPQfzkLD9NQnCPHrA',
      zoom: 13,
      center: latLng(51.5074, 0.1278),
      url: `https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={token}`,
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      withPopup: latLng(0, 0),
      withTooltip: latLng(0, 0),
      currentZoom: 5,
      currentCenter: latLng(0, 0),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        preferCanvas: true
      },
      payers: [],
      heatMapArray: [[50.5, 30.5, 0.2]],
      loading: false,
      postCodeLayer: null,
      postCodeTopoLayer: null,
      map: null,
      marker: null,
      popup: null
    }
  },
  async mounted () {
    this.$nextTick(async () => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 250)
    })
  },
  computed: {
    baseLayer () {
      return `https://api.mapbox.com/styles/v1/davidhoodcorvid/cjwg8lne82irk1co87dr5b7j8/tiles/256/{z}/{x}/{y}@2x?access_token=${this.token}`
      // return `https://api.tiles.mapbox.com/v4/${this.layerId}/{z}/{x}/{y}.png?access_token=${this.token}`
    },
    groupId () {
      return this.widget.options.find(o => o.label === 'GroupId').value
    }
  },
  methods: {
    async populate () {
      let postcodeUrl = `https://localhost:5019/postcodes/msoa${this.getParams()}`
      axios.get(postcodeUrl)
        .then((response) => {
          this.postCodeLayer = leaflet.geoJson([], { style: this.styleFeature, onEachFeature: this.onEachFeature }).addTo(this.$refs.postcodeMap.mapObject).bringToFront()
          this.postCodeLayer.addData(response.data)
        })
    },
    styleFeature (feature) {
      let fillColor = '#000000'
      if (feature.properties.afterHousing > 10000) {
        fillColor = '#ff0000'
      }
      if (feature.properties.afterHousing > 15000) {
        fillColor = '#FFFF00'
      }
      if (feature.properties.afterHousing > 20000) {
        fillColor = '#FFFF00'
      }
      if (feature.properties.afterHousing > 40000) {
        fillColor = '#7FFF00'
      }
      if (feature.properties.afterHousing > 80000) {
        fillColor = '#00FF00'
      }
      let strokeColor = '#000000'
      if (feature.properties.payerCount > 0) {
        strokeColor = '#FF0000'
      }
      return {
        fillColor: fillColor,
        color: strokeColor,
        fillOpacity: 0.7,
        stroke: true,
        fill: true
      }
    },
    async refresh () {
      let postcodeUrl = `https://localhost:5019/postcodes/msoa${this.getParams()}`
      axios.get(postcodeUrl)
        .then((response) => {
          this.postCodeLayer.clearLayers()
          this.postCodeLayer.addData(response.data)
        })
    },
    zoomUpdate (zoom) {
      this.refresh()
    },
    getParams () {
      var minX = this.map.getBounds().getWest()
      var minY = this.map.getBounds().getSouth()
      var maxX = this.map.getBounds().getEast()
      var maxY = this.map.getBounds().getNorth()
      var currentZoom = this.map.getZoom()
      var zoom = 0.01
      if (currentZoom > 8) {
        zoom = 0.001
      }
      return `?minx=${minX}&miny=${minY}&maxx=${maxX}&maxy=${maxY}&zoom=${zoom}`
    },
    centerUpdate (center) {
      this.refresh()
      // this.refreshTopo()
    },
    showLongText () {
      this.showParagraph = !this.showParagraph
    },
    innerClick () {
      // click method
    },
    latLong (input) {
      return latLng(input.lat, input.lon)
    },
    getContent (p) {
      return p.ukPayerId
    },
    async mapReady () {
      this.map = this.$refs.postcodeMap.mapObject
      await this.populate()
    },
    async onEachFeature (feature, layer) {
      layer.on('click', this.layerClicked)
    },
    async layerClicked (e) {
      var lat = e.latlng.lat
      var lng = e.latlng.lng
      let postcodeUrl = `https://localhost:5019/postcodes/crime/?lat=${lat}&lng=${lng}`
      var resp = await axios.get(postcodeUrl)
      if (this.marker !== null) {
        this.map.removeLayer(this.marker)
      }
      // eslint-disable-next-line
      this.marker = new leaflet.marker(e.latlng).addTo(this.map)
      this.popup = this.marker.bindPopup(`<b>This location:</b><br />Postal Area: ${e.sourceTarget.feature.properties.postcode}<br/>Failed Collections: ${e.sourceTarget.feature.properties.payerCount}<br/>Crimes within 1 mile (last month): ${resp.data.length}<br/>Average Income After Housing: ${e.sourceTarget.feature.properties.afterHousing}`).openPopup()
    }
  },
  watch: {
    groupId () {
      this.populate()
    }
  }
}
</script>
<style scoped>
.fillHeightContainer {
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
}
.mapContainer {
  display: flex;
  flex-grow: 1;
}
.mapBox,
.growBox {
  display: flex;
}

.dark-mode .leaflet-popup-content-wrapper, .dark-mode .leaflet-popup-tip {
  background-color: #000000
}
</style>
