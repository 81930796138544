<template>
    <div>
        <div class="idb-block">
            <h2 v-if="messageError" class="idb-block-content">Cannot load message.</h2>
            <h2 v-if="!messageError && !message" class="idb-block-content">Loading message.</h2>
            <div v-if="channel === '0'"> <!-- Email -->
              <div v-if="message && messageTitle" class="idb-block-title">
                  <h2 style="margin-bottom: 5px;"><help-icon docPath="/paygate-collections/messaging/messagequeue/" />Message Title</h2>
                  <h4>{{ messageTitle }}</h4>
              </div>
              <div v-html="message && messageBody" class="idb-block-content">
              </div>
            </div>
            <div v-else-if="channel === '1'"> <!-- Letter -->
              <div class="idb-block-title">
                <h2 id="title-of-block-check"><span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span>View Letter Message</h2>
              </div>
              <b-row class="idb-block-content">
                <!-- <b-col offset-lg="2" cols="12" sm="12" offset="0" offset-sm="0" lg="8">
                  <iframe frameBorder="0" v-if="message && message.payerMessageId" ref="previewFrame" :src="previewUrl" width="100%" height="100%"></iframe>
                </b-col> -->

                <b-col style="width: 105% !important">
                  <div style="padding-top: 110%; position: relative;">
                    <iframe
                     :src="previewUrl"
                      v-if="message"
                      frameborder="0"
                      ref="previewFrame"
                      height="100%"
                      width="100%"
                      style="position: absolute; top: 0; left: 0;"
                    ></iframe>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-else-if="channel === '2'"> <!-- SMS -->
              <div v-if="message && senderName" class="idb-block-title">
                  <h2 style="margin-bottom: 5px;"><span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span>Sender Name</h2>
                  <h4>{{ senderName }}</h4>
              </div>
              <div v-html="message && messageBody" class="idb-block-content">
              </div>
            </div>
            <div class="idb-block-footer">
            </div>
            </div>
    </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  data () {
    return {
    }
  },
  computed: {
    messageTitle () {
      return this.$store.state.messages.currentDefaultMessage.messageTitle
    },
    senderName () {
      return this.$store.state.messages.currentDefaultMessage.senderName
    },
    messageBody () {
      return this.$store.state.messages.currentDefaultMessage.messageBody
    },
    message () {
      return this.$store.state.messages.currentDefaultMessage
    },
    messageError () {
      return this.$store.state.messages.messageError
    },
    previewUrl () {
      if (this.message) {
        const pdfUrl = encodeURI(`${process.env.VUE_APP_DDMS_API_URL}messages/defaultletterpreview/${this.type}`)
        var previewUrl = `${process.env.VUE_APP_PDFVIEWER_URL}?file=${pdfUrl}&token=${this.bearerToken}`
        return previewUrl
      } else {
        return process.env.VUE_APP_PDFVIEWER_URL
      }
    }
  },
  props: {
    channel: { type: String, default: "-1" },
    type: { type: String, default: "-1" }
  },
  async mounted () {
    this.bearerToken = await auth.getAccessToken()
  },
  async created () {
    try {
      await this.$store.dispatch('loadDefaultMessageDetails', { channel: this.channel, type: this.type })
    } catch (e) {
      this.$toastr.e('Unable to load message details.', 'An error occurred')
    }
  }
}
</script>
