var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "intelligibleSummary" } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "table",
          {
            staticClass: "table table-striped pt-0 mt-0",
            attrs: { role: "table" }
          },
          [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _vm._m(1),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatCount(_vm.selectedSummary.creditRecordCount)
                    )
                  )
                ]),
                _c("td", [
                  _vm._v(
                    "£" +
                      _vm._s(_vm.formatValue(_vm.selectedSummary.creditValue))
                  )
                ])
              ]),
              _c("tr", [
                _vm._m(2),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatCount(_vm.selectedSummary.debitRecordCount)
                    )
                  )
                ]),
                _c("td", [
                  _vm._v(
                    "£" +
                      _vm._s(_vm.formatValue(_vm.selectedSummary.debitValue))
                  )
                ])
              ]),
              _c("tr", [
                _vm._m(3),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatCount(_vm.selectedSummary.creditContraCount)
                    )
                  )
                ]),
                _c("td", [
                  _vm._v(
                    "£" +
                      _vm._s(
                        _vm.formatValue(_vm.selectedSummary.creditContraValue)
                      )
                  )
                ])
              ]),
              _c("tr", [
                _vm._m(4),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatCount(_vm.selectedSummary.debitContraCount)
                    )
                  )
                ]),
                _c("td", [
                  _vm._v(
                    "£" +
                      _vm._s(
                        _vm.formatValue(_vm.selectedSummary.debitContraValue)
                      )
                  )
                ])
              ]),
              _c("tr", [
                _vm._m(5),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.formatCount(_vm.selectedSummary.ddiRecordCount))
                  )
                ]),
                _vm._m(6)
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "table",
          {
            staticClass: "table table-striped pt-0 mt-0 tableNeedsPadding",
            attrs: { role: "table" }
          },
          [
            _c("tbody", [
              _c("tr", [
                _vm._m(7),
                _c("td", [_vm._v(_vm._s(_vm.submissionType))])
              ]),
              _c("tr", [
                _vm._m(8),
                _c("td", [
                  _vm._v(_vm._s(_vm.selectedSummary.serviceUserNumber))
                ])
              ]),
              _c("tr", [
                _vm._m(9),
                _c("td", [_vm._v(_vm._s(_vm.selectedSummary.workCode))])
              ]),
              _c("tr", [
                _vm._m(10),
                _c("td", [_vm._v(_vm._s(_vm.processingDate))])
              ]),
              false
                ? _c("tr", [
                    _vm._m(11),
                    _c("td", [_vm._v(_vm._s(_vm.selectedContraNarrative))])
                  ])
                : _vm._e()
            ])
          ]
        )
      ])
    ]),
    _vm.actionId !== undefined
      ? _c("div", { staticClass: "row form-group mt-2" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.downloadReport }
              },
              [
                _c("span", {
                  staticClass: "far fa-arrow-alt-circle-down",
                  staticStyle: { "padding-right": "5px" }
                }),
                _vm._v("Download Submission Report ")
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { role: "columnheader" } }, [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Unit")])
        ]),
        _c("th", { attrs: { role: "columnheader" } }, [_vm._v("Items")]),
        _c("th", { attrs: { role: "columnheader" } }, [_vm._v("Total")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Credits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Debits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Credit Contras")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Debit Contras")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("DDIs")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", { staticClass: "sr-only" }, [_vm._v("N/A")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Submission Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Service User Number")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Work Code")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Processing Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Contra Narrative")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }