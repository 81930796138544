const state = {
  paymentNetworkData: {},
  serviceWindowData: {},
  payGateOnlineBureauConfigData: {},
  eiscdData: {},
  performanceTuningData: {}
}

const mutations = {
  setPaymentNetworkData (state, paymentNetworkData) {
    state.paymentNetworkData = paymentNetworkData
  },
  setServiceWindowData (state, serviceWindowData) {
    state.serviceWindowData = serviceWindowData
  },
  setPayGateOnlineBureauConfigData (state, payGateOnlineBureauConfigData) {
    state.payGateOnlineBureauConfigData = payGateOnlineBureauConfigData
  },
  setEiscdData (state, eiscdData) {
    state.eiscdData = eiscdData
  },
  setPerformanceTuningData (state, performanceTuningData) {
    state.performanceTuningData = performanceTuningData
  }
}

const getters = {
  paymentNetworkData: state => {
    return state.paymentNetworkData
  },
  serviceWindowData: state => {
    return state.serviceWindowData
  },
  payGateOnlineBureauConfigData: state => {
    return state.payGateOnlineBureauConfigData
  },
  eiscdData: state => {
    return state.eiscdData
  },
  performanceTuningData: state => {
    return state.performanceTuningData
  }
}

const actions = {
  setPaymentNetworkData ({ commit }, paymentNetworkData) {
    commit('setPaymentNetworkData', paymentNetworkData)
  },
  setServiceWindowData ({ commit }, serviceWindowData) {
    commit('setServiceWindowData', serviceWindowData)
  },
  setPayGateOnlineBureauConfigData ({ commit }, payGateOnlineBureauConfigData) {
    commit('setPayGateOnlineBureauConfigData', payGateOnlineBureauConfigData)
  },
  setEiscdData ({ commit }, eiscdData) {
    commit('setEiscdData', eiscdData)
  },
  setPerformanceTuningData ({ commit }, performanceTuningData) {
    commit('setPerformanceTuningData', performanceTuningData)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
