import axios from 'axios'

const state = {
  submissionData: {},
  processingDates: {},
  nextProcessingDate: '',
  nextPaymentDate: '',
  importFileResponse: {},
  payment: {},
  presubValResponse: {},
  submissionId: '',
  actionId: '',
  fileNumber: 0,
  bacsSubmission: {},
  intelligibleSummaries: {},
  viewSubmissionData: {},
  payments: {},
  serverParams: {},
  standard18: '',
  presubValMessages: {},
  submissionHistory: {},
  reportDownload: {},
  parentDataLoaded: false
}

const mutations = {
  setSubmissionData (state, submissionData) {
    state.submissionData = submissionData
  },

  setProcessingDates (state, processingDates) {
    state.processingDates = processingDates
  },

  setNextProcessingDate (state, nextProcessingDate) {
    state.nextProcessingDate = nextProcessingDate
  },

  setNextPaymentDate (state, nextPaymentDate) {
    state.nextPaymentDate = nextPaymentDate
  },

  setImportFileResponse (state, importFileResponse) {
    state.importFileResponse = importFileResponse
  },

  setPayment (state, payment) {
    state.payment = payment
  },

  setPresubValResponse (state, presubValResponse) {
    state.presubValResponse = presubValResponse
  },

  setSubmissionId (state, submissionId) {
    state.submissionId = submissionId
  },

  setFileNumber (state, fileNumber) {
    state.fileNumber = fileNumber
  },

  setActionId (state, actionId) {
    state.actionId = actionId
  },

  setBacsSubmission (state, bacsSubmission) {
    state.bacsSubmission = bacsSubmission
  },

  setIntelligibleSummaries (state, intelligibleSummaries) {
    state.intelligibleSummaries = intelligibleSummaries
  },

  setViewSubmissionData (state, viewSubmissionData) {
    state.viewSubmissionData = viewSubmissionData
  },

  setPayments (state, payments) {
    state.payments = payments
  },

  setServerParams (state, serverParams) {
    state.serverParams = serverParams
  },

  setStandard18 (state, standard18) {
    state.standard18 = standard18
  },

  setPresubValMessages (state, presubValMessages) {
    state.presubValMessages = presubValMessages
  },

  setSubmissionHistory (state, submissionHistory) {
    state.submissionHistory = submissionHistory
  },

  setReportDownload (state, reportDownload) {
    state.reportDownload = reportDownload
  },

  setParentDataLoaded (state, parentDataLoaded) {
    state.parentDataLoaded = parentDataLoaded
  }
}

const getters = {
  collectionsSubmissionData: state => {
    return state.submissionData
  },

  collectionsProcessingDates: state => {
    return state.processingDates
  },

  collectionsNextProcessingDate: state => {
    return state.nextProcessingDate
  },

  collectionsNextPaymentDate: state => {
    return state.nextPaymentDate
  },

  collectionsImportFileResponse: state => {
    return state.importFileResponse
  },

  collectionsPayment: state => {
    return state.payment
  },

  collectionsPresubValResponse: state => {
    return state.presubValResponse
  },

  collectionsSubmissionId: state => {
    return state.submissionId
  },

  collectionsFileNumber: state => {
    return state.fileNumber
  },

  collectionsActionId: state => {
    return state.actionId
  },

  collectionsBacsSubmission: state => {
    return state.bacsSubmission
  },

  collectionsIntelligibleSummaries: state => {
    return state.intelligibleSummaries
  },

  collectionsViewSubmissionData: state => {
    return state.viewSubmissionData
  },

  collectionsPayments: state => {
    return state.payments
  },

  collectionsServerParams: state => {
    return state.serverParams
  },

  collectionsStandard18: state => {
    return state.standard18
  },

  collectionsPresubValMessages: state => {
    return state.presubValMessages
  },

  collectionsSubmissionHistory: state => {
    return state.submissionHistory
  },

  collectionsReportDownload: state => {
    return state.reportDownload
  },

  collectionsParentDataLoaded: state => {
    return state.parentDataLoaded
  }
}

const actions = {
  setSubmissionData ({ commit }, submissionData) {
    commit('setSubmissionData', submissionData)
  },

  async getProcessingDates ({ commit, dispatch }) {
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}bacs/processingdate`)
    commit('setProcessingDates', response.data)
  },

  async getNextProcessingDate ({ commit, payload }, paymentDate) {
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + paymentDate)
    commit('setNextProcessingDate', response.data)
  },

  async getNextPaymentDate ({ commit, payload }, processingDate) {
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}bacs/processingdate/getPaymentDateFromProcessingDate?processingDate=` + processingDate)
    commit('setNextPaymentDate', response.data)
  },

  setImportFileResponse ({ commit }, importFileResponse) {
    commit('setImportFileResponse', importFileResponse)
  },

  setPayment ({ commit }, payment) {
    commit('setPayment', payment)
  },

  setPresubValResponse ({ commit }, presubValResponse) {
    commit('setPresubValResponse', presubValResponse)
  },

  setSubmissionId ({ commit }, submissionId) {
    commit('setSubmissionId', submissionId)
  },

  setFileNumber ({ commit }, fileNumber) {
    commit('setFileNumber', fileNumber)
  },

  setActionId ({ commit }, actionId) {
    commit('setActionId', actionId)
  },

  setBacsSubmission ({ commit }, bacsSubmission) {
    commit('setBacsSubmission', bacsSubmission)
  },

  setIntelligibleSummaries ({ commit }, intelligibleSummaries) {
    commit('setIntelligibleSummaries', intelligibleSummaries)
  },

  setViewSubmissionData ({ commit }, viewSubmissionData) {
    commit('setViewSubmissionData', viewSubmissionData)
  },

  setPayments ({ commit }, payments) {
    commit('setPayments', payments)
  },

  setServerParams ({ commit }, serverParams) {
    commit('setServerParams', serverParams)
  },

  setStandard18 ({ commit }, standard18) {
    commit('setStandard18', standard18)
  },

  setPresubValMessages ({ commit }, presubValMessages) {
    commit('setPresubValMessages', presubValMessages)
  },

  setSubmissionHistory ({ commit }, submissionHistory) {
    commit('setSubmissionHistory', submissionHistory)
  },

  setReportDownload ({ commit }, reportDownload) {
    commit('setReportDownload', reportDownload)
  },

  setParentDataLoaded ({ commit }, parentDataLoaded) {
    commit('setParentDataLoaded', parentDataLoaded)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
