function getBrowser () {
  var browser = ''
  if (navigator.userAgent.indexOf('Edge') !== -1) {
    browser = 'Edge'
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    browser = 'Chrome'
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    browser = 'Firefox'
  } else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) { // IF IE > 10
    browser = 'IE'
  }

  return browser
}

function browserWarning (obj, device) {
  obj.$toastr.w('Signing with a ' + device + ' only works in Internet Explorer')
}
function smartcardBrowserWarning (obj) {
  obj.$toastr.w('Signing with a smartcard only works in Internet Explorer')
}

function pluginLoaded () {
  var loaded = false
  try {
    var plugin = document.PayGatePlugin
    var pluginVersion = plugin.GetVersion()
    if (pluginVersion !== undefined) {
      loaded = true
    }
  } catch (ex) {
  }

  return loaded
}

// Each value of plugin component version refers to a different part of the plugin.
// From left to right the component is:
// 0 USB submission commit (Paygate Online only)
// 1 USB admin features
// 2 Smartcard signing
// 3 Restricted file browser
function isLatestPlugin (component, expectedVersion) {
  var newPluginRequired = false
  try {
    var plugin = document.PayGatePlugin
    var pluginVersion = plugin.GetVersion()
    if (pluginVersion !== undefined) {
      var componentVersions = pluginVersion.split('.')
      var version = componentVersions[component]
      if (version !== expectedVersion) {
        newPluginRequired = true
      }
    }
  } catch (ex) {
  }
  return newPluginRequired
}

function isLatestNonActiveXPlugin (pluginVersion, component, expectedVersion) {
  var newPluginRequired = false
  try {
    if (pluginVersion !== undefined) {
      var componentVersions = pluginVersion.split('.')
      var version = componentVersions[component]
      if (version !== expectedVersion) {
        newPluginRequired = true
      }
    }
  } catch (ex) {
  }

  return newPluginRequired
}

function parsePluginResponse (responseText) {
  if (responseText !== null && responseText !== undefined) {
    // Some error messages have a CRLF at the end which causes the JSON.parse to fail. So remove the \r\n first which should be OK unless a base64 signature ever contains this!
    var tempPluginResponse = responseText.replace(/\r\n/g, '')
    tempPluginResponse = tempPluginResponse.replace(/~/g, '"')
    // Some error messages have a windows directory file path so need to double up any backslashes otherwise JSON.parse will fail looking for unknown char escape sequences.
    tempPluginResponse = tempPluginResponse.replace(/\\/g, '\\\\')
    var pluginResponse = JSON.parse(tempPluginResponse)
    return pluginResponse
  }
}

export default {
  getBrowser,
  smartcardBrowserWarning,
  browserWarning,
  pluginLoaded,
  isLatestPlugin,
  isLatestNonActiveXPlugin,
  parsePluginResponse
}
