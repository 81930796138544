<template>
    <b-modal
      id="add-email-recipient"
      ref="addEmailRecipient"
      :title="TitleText"
      hide-header-close
      static
      @shown="load"
      @ok="Save"
      @hidden="clear"
      :centered="true"
      footer-class="add-recipient-footer"
    >
      <div slot="modal-ok">{{ButtonText}}</div>
      <div class :class="{invalid: $v.Name.$error}">
        <label class="col-form-label col-md-6 required">Name</label>
        <div class="col-md-12">
          <b-form-input
            class="form-control"
            type="text"
            v-model.trim="$v.Name.$model"
            :formatter="trimInput"
            @input="checkData"
          ></b-form-input>
          <!-- Validation -->
          <validation-messages v-model="$v.Name" name="Name">
          </validation-messages>
        </div>
      </div>

      <div class :class="{invalid: $v.Email.$error}">
        <label class="col-form-label col-md-6 required">Email Address</label>
        <div class="col-md-12">
          <b-form-input
            class="form-control"
            type="text"
            v-model.trim="$v.Email.$model"
            :formatter="trimInput"
            @input="checkEmailAddress"
          ></b-form-input>
          <!-- Validation -->
          <small
            class="form-text small text-danger"
            v-if="EmailAddressErrorMsg.length > 0"
          > {{EmailAddressErrorMsg}} </small>
          <validation-messages  v-model="$v.Email" name="contact email">
          </validation-messages>
        </div>
      </div>
<!--
      <div class>
              <label class="col-form-label col-md-5">Add As CC Address</label>
              <div class="col-md-2">
                <p-check
                  class="p-switch p-fill"
                  color="primary"
                  v-model="CCEmail"
                ></p-check>
              </div>
        </div> -->

      <template  #modal-footer="{ ok, cancel, hide }">
        <b-button :disabled="DisableSave" variant="primary" @click="ok()">{{ButtonText}}</b-button>
        <b-button @click="cancel()">Cancel</b-button>
      </template>
    </b-modal>
  </template>

<script>
import axios from 'axios'
import { maxLength, required } from 'vuelidate/lib/validators'
import _ from 'lodash'
import { email } from '@/Assets/Validators'

const emailValidator = function (value) {
  return email(value)
}
export default {
  props: ['RecipientId', 'EditMode'],
  data () {
    return {
      Name: '',
      Email: '',
      DisableSave: true,
      EmailAddressChecked: false,
      EmailAddressErrorMsg: '',
      CCEmail: false,
      RecipientDisabled: false,
      TitleText: '',
      ButtonText: ''
    }
  },

  async created () {
    this.$v.$reset()
  },

  methods: {
    async load () {
      // Loader
      if (this.EditMode) {
        this.TitleText = 'Edit Email Recipient'
        this.ButtonText = 'Save'
        await this.GetRecipient()
      } else {
        this.TitleText = 'Add New Email Recipient'
        this.ButtonText = 'Add'
      }
      this.$v.$reset()
    },
    async GetRecipient () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/GetEmailRecipient/' + this.RecipientId, { showload: true })
      this.Name = response.data.name
      this.Email = response.data.email
      this.CCEmail = response.data.CcEmail
      this.RecipientDisabled = response.data.disabled
      this.EmailAddressChecked = true
    },

    // trims the input string to a maximum character length
    trimInput (e) {
      if (e.length > 250) {
        return e.substring(0, 250)
      }
      return e
    },

    async Save () {
      if (this.EditMode) {
        await this.UpdateEmailRecipient()
      } else {
        await this.AddEmailRecipient()
      }
      this.$emit('submit')
    },

    async UpdateEmailRecipient () {
      var data = {
        Name: this.Name,
        Email: this.Email,
        CcEmail: this.CCEmail,
        id: this.RecipientId,
        disabled: this.RecipientDisabled
      }
      try {
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/UpdateEmailRecipientData', data, { showload: true })
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$snapbar.e(`Could not update the recipient ${e.message}`)
      }
    },
    async AddEmailRecipient () {
      var data = {
        Name: this.Name,
        Email: this.Email,
        CcEmail: this.CCEmail
      }
      try {
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/AddEmailRecipientData', data, { showload: true })
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$snapbar.e(`Could not add the new recipient ${e.message}`)
      }
    },
    checkEmailAddress: _.debounce(async function () {
      this.EmailAddressChecked = false
      await this.checkData()
    }, 300),

    checkData: _.debounce(async function () {
      if (!this.EmailAddressChecked && this.Email.length > 0 && !this.$v.Email.$invalid) {
        // Run the server check
        const url = `${process.env.VUE_APP_BUREAU_API_URL}bureauusagereport/CheckEmailRecipientDetails`
        const response = await axios.post(url, {
          showload: true,
          Name: this.Name,
          Email: this.Email
        })

        if (response.data.toastType === 2) {
          // Email address doesn't exist, all good to go.
          this.EmailAddressChecked = true
          this.EmailAddressErrorMsg = ''
        } else {
          this.EmailAddressChecked = false
          this.EmailAddressErrorMsg = response.data.toastMessage
        }
      }
      this.$nextTick(() => {
        if (!this.$v.Email.$invalid && !this.$v.Name.$invalid && this.EmailAddressChecked) {
          this.DisableSave = false
        } else {
          this.DisableSave = true
        }
      })
    }, 300),
    clear () {
      this.Name = ''
      this.Email = ''
      this.EmailAddressErrorMsg = ''
    }
  },
  validations: {
    Name: { required, maxLength: maxLength(255) },
    Email: { emailValidator, required, maxLength: maxLength(255) }
  }

}

</script>
  <style>
  .add-recipient-footer {
    justify-content: flex-start !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  </style>
