var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("v-jstree", {
                  attrs: {
                    data: _vm.treeData,
                    multiple: "",
                    "allow-batch": "",
                    "whole-row": ""
                  },
                  on: { "item-click": _vm.itemClick }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "draggable",
                  {
                    attrs: { options: { group: "people" } },
                    on: {
                      start: function($event) {
                        _vm.drag = true
                      },
                      end: function($event) {
                        _vm.drag = false
                      }
                    },
                    model: {
                      value: _vm.myArray,
                      callback: function($$v) {
                        _vm.myArray = $$v
                      },
                      expression: "myArray"
                    }
                  },
                  _vm._l(_vm.myArray, function(element, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "list-complete-item" },
                      [
                        _c("div", { staticClass: "list-group-item" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-start flex-column mr-20"
                                },
                                [
                                  element.isFolder
                                    ? _c("i", {
                                        staticClass: "fa fa-folder fa-4x folder"
                                      })
                                    : _c("i", {
                                        staticClass: "fa fa-file fa-4x file"
                                      })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-start flex-column pt-10"
                                },
                                [
                                  _c("h6", { staticClass: "font-lg" }, [
                                    _vm._v(_vm._s(element.text))
                                  ]),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(_vm._s(element.description))
                                  ]),
                                  element.isFolder
                                    ? _c("div", {
                                        staticClass: "small fw-bold"
                                      })
                                    : _vm._e(),
                                  !element.isFolder
                                    ? _c(
                                        "div",
                                        { staticClass: "small fw-bold" },
                                        [
                                          _c("strong", [_vm._v("Size:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.bytesToSize(
                                                  element.stat.size
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !element.isFolder
                                    ? _c(
                                        "div",
                                        { staticClass: "small fw-bold" },
                                        [
                                          _c("strong", [
                                            _vm._v("Last Modified:")
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reformatDate(
                                                  element.stat.mtime
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-md-3" }, [_vm._v(" Info ")])
          ])
        ]),
        _c("div", { staticClass: "idb-block-footer" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-sm-3" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.btnClick($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-download mr-2" }),
                    _vm._v("Button")
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [
        _vm._v("File Browser "),
        _c("span", { staticClass: "pull-right" }, [
          _c("a", { attrs: { href: "#", target: "_blank" } }, [
            _c("i", { staticClass: "far fa-question-circle" })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }