var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "form-group",
        class: [{ invalid: _vm.$v.value.password.$error }, _vm.formGroupClass]
      },
      [
        _c(
          "label",
          {
            staticClass: "required",
            class: _vm.labelClass,
            attrs: { for: "changePasswordNew" }
          },
          [_vm._v("Password")]
        ),
        _c("div", { class: _vm.inputClass }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.value.password.$model,
                expression: "$v.value.password.$model"
              }
            ],
            staticClass: "form-control",
            attrs: {
              id: "changePasswordNew",
              type: "password",
              autocomplete: "new-password"
            },
            domProps: { value: _vm.$v.value.password.$model },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.$v.value.password, "$model", $event.target.value)
                },
                function($event) {
                  return _vm.updatePasswords()
                }
              ]
            }
          }),
          _vm.$v.value.password.$dirty
            ? _c("span", [
                !_vm.$v.value.password.required
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [_vm._v("A password is required")]
                    )
                  : _vm._e(),
                _vm.$v.value.password.minLength != undefined &&
                !_vm.$v.value.password.minLength
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [
                        _vm._v(
                          "The password has to have a minimum length of " +
                            _vm._s(_vm.passwordComplexity.minLength)
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.value.password.numbers != undefined &&
                !_vm.$v.value.password.numbers
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [
                        _vm._v(
                          "The password has to have at least " +
                            _vm._s(_vm.passwordComplexity.numNumbers) +
                            " numeric characters"
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.value.password.uppercase != undefined &&
                !_vm.$v.value.password.uppercase
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [
                        _vm._v(
                          "The password has to have at least " +
                            _vm._s(_vm.passwordComplexity.numUppercase) +
                            " upper case letters"
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.value.password.nonAlphaNumeric != undefined &&
                !_vm.$v.value.password.nonAlphaNumeric
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [
                        _vm._v(
                          "The password has to have at least " +
                            _vm._s(_vm.passwordComplexity.numNonAlphaNumerics) +
                            " non-alphanumeric characters"
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.$v.value.password.regex != undefined &&
                !_vm.$v.value.password.regex
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [_vm._v(_vm._s(_vm.passwordComplexity.regExFailMessage))]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "form-group",
        class: [
          { invalid: _vm.$v.value.confirmPassword.$error },
          _vm.formGroupClass
        ]
      },
      [
        _c(
          "label",
          {
            staticClass: "required",
            class: _vm.labelClass,
            attrs: { for: "changePasswordConfirm" }
          },
          [_vm._v("Confirm Password")]
        ),
        _c("div", { class: _vm.inputClass }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.value.confirmPassword.$model,
                expression: "$v.value.confirmPassword.$model"
              }
            ],
            staticClass: "form-control",
            attrs: {
              id: "changePasswordConfirm",
              type: "password",
              autocomplete: "new-password"
            },
            domProps: { value: _vm.$v.value.confirmPassword.$model },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.value.confirmPassword,
                    "$model",
                    $event.target.value
                  )
                },
                function($event) {
                  return _vm.updatePasswords()
                }
              ]
            }
          }),
          _vm.$v.value.confirmPassword.$dirty
            ? _c("span", [
                !_vm.$v.value.confirmPassword.required
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [_vm._v("You must confirm your password")]
                    )
                  : _vm._e(),
                !_vm.$v.value.confirmPassword.sameAsPassword
                  ? _c(
                      "small",
                      { staticClass: "form-text text-danger small" },
                      [_vm._v("Your passwords must match")]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }