<template>
  <b-nav-item-dropdown right no-caret v-b-popover.hover.bottom="'Actions'">
    <template slot="button-content">
      <i class="far fa-bell faa-ring animated-hover faa-slow" title="Actions" aria-description="Actions" style="line-height: 30px;"></i><span class="sr-only">Actions</span>
      <b-badge
        v-if="actions.length > 0"
        pill
        :variant="bellColour"
        class="badge-sm badge-top-right fa-notify"
      >{{actionsCount}}<span class="sr-only"> actions available</span></b-badge>
      <b-badge
        v-if="!isConnected"
        pill
        variant="warning"
        class="badge-sm badge-top-right fa-notify"
      >!</b-badge>
    </template>
    <b-dropdown-header
      class="bg-warning text-white"
    >Actions {{actionsCount > 5 ? `(5 of ${actionsCount})`:''}}</b-dropdown-header>
    <b-dropdown-item class="mb-10" v-if="!isConnected" exact>
      <div class="media">
        <div class="media-body pt-10">
          <p
            class="fw-bold text-danger"
            style="max-width:320px; white-space: pre-line;"
          >You are not connected, please click the actions list button below as you may have actions!</p>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-bind:to="goToAction(action)"
      class="pb-10 position-relative"
      v-for="action in actions"
      :key="action.id"
      exact
    >
      <div class="group-colour" :style="{'background-color':action.groupColour}"></div>
      <div class="media">
        <div class="d-flex pos-relative mr-20">
          <span>
            <i class="fa fa-custom" :class="getIcon(action)"></i>
          </span>
          <span
            class="badge badge-label-sm badge-thumb-top-right badge-pill fa-notify"
            :class="dateColour(action)"
          >&nbsp;</span>
        </div>
        <div class="media-body pt-10" v-bind:class="{'text-danger': action.rejected}">
          <h6
            class="fw-bold"
            v-bind:class="{'text-danger': action.rejected}"
            style="max-width:340px;text-overflow: ellipsis;
    overflow: hidden;"
          >{{action.title}} - {{action.groupName? `${action.groupName} - `: ''}}{{action.createdDate | formatDate}}</h6>
          <p
            class="m-0 small"
            style="max-width:320px;text-overflow: ellipsis;
    overflow: hidden;"
          >{{action.message}}</p>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item class="mb-10" v-if="actionsCount === 0 && isConnected" exact>
      <div class="media">
        <div class="media-body pt-10">
          <p class="fw-bold">You have no actions!</p>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item class="mb-10 text-center" :to="{name:'Actions'}">Full Actions List</b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>

import Moment from 'moment'
import { returnRouteObject, getIcon } from '@/Assets/Components/Actions/actionsHelper.js'
import { mapGetters } from 'vuex'

function onlyUnique (value, index, self) {
  return self.indexOf(value) === index
}

export default {
  name: 'actions',
  data: function () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL
    }
  },
  computed: {
    actions () {
      return this.$store.getters.actionsList
    },
    bellColour () {
      var colourArray = this.actions.map(this.dateColour).filter(onlyUnique)

      if (colourArray.includes('badge-danger')) {
        return 'danger'
      } else if (colourArray.includes('badge-warning')) {
        return 'warning'
      }
      return 'success'
    },
    isConnected () {
      return this.$store.state.actionsStore.socket.isConnected
    },
    ...mapGetters(['actionsCount'])

  },
  watch: {
  },
  methods: {
    dateColour ({ actionClass, createdDate }) {
      createdDate = Moment(createdDate)
      const diff = Moment().diff(createdDate, 'days')
      var diffFirst = 1; var diffSecond = 7

      if (actionClass === 'Bacs') {
        diffSecond = 2
      }

      if (diff >= diffSecond) {
        return 'badge-danger'
      } else if (diff >= diffFirst) {
        return 'badge-warning'
      } else {
        return 'badge-success'
      }
    },
    goToAction (action) {
      return returnRouteObject(action)
    },
    getIcon
  }
}
</script>
<style lang="scss" scoped>
.dropdown-item.active,
.dropdown-item:active {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(20, 23, 26);
}

.scroll-area {
  height: calc(100% - 25px);
  min-height: unset;
}

.fa-custom {
  font-size: 35px;
  padding-top: 15px;
}

.fa-info-circle {
  color: rgb(20, 23, 26);
}
</style>
