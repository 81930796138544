<template>
  <div>
    <div class="row mt-2" v-if="dataLoaded">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Billing Report All Customer Stats</h2>
            <span class></span>
          </div>
          <div class="idb-block-content">
            <div class="container-fluid">
              <div class="row mt-2">
                <div class="col text-center">
                  <h5>SMS Sent</h5>
                  <h2>
                    <i-count-up class="ml-2" :delay="1" :endVal="smsSent" :options="counterOptions"></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Emails Sent</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="emailSent"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Address Lookups</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="addressLookupSent"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bacs Submissions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bacsSubs"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bacs Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bacsTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <h5>FPS Submissions</h5>
                  <h2>
                    <i-count-up class="ml-2" :delay="1" :endVal="fpsSubs" :options="counterOptions"></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>FPS Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="fpsTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Collections Submissions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="collectionsSubs"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Collections Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="collectionsTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bureau Unique SUNs</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bureauUniqueSuns"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <h5>Bureau Submissions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bureauSubs"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bureau Transactions</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bureauTrans"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Workflows Executed</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="workflowsExecuted"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Agent Files Downloaded</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="agentFilesDownloaded"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Agent Files Uploaded</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="agentFilesUploaded"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <h5>Credit Card Validations</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="creditCardValidations"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>Bank Account Validations</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="bankAccountValidations"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
                <div class="col text-center">
                  <h5>IBAN Validations</h5>
                  <h2>
                    <i-count-up
                      class="ml-2"
                      :delay="1"
                      :endVal="ibanValidations"
                      :options="counterOptions"
                    ></i-count-up>
                  </h2>
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div
                  class="text-small col-12 text-center"
                >This is a sum of all the customers below, to go into the specific details about a customer and how close they are to certain thresholds in the licence, please click on them in the table below</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2" v-if="dataLoaded">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Billing Report All Customer Table</h2>
          </div>
          <div class="idb-block-content">
            <vue-good-table
              :rows="rows"
              :columns="columns"
              styleClass="vgt-table striped bordered  table-responsive"
              @on-row-click="onRowClick"
            ></vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import ICountUp from 'vue-countup-v2'

// Constants
import UsageTypes from '@/Components/Platform/Reports/Billing/UsageType.js'
import ValidateApiCall from '@/Components/Platform/Reports/Billing/ValidateApiCall.js'

// Functions
function reduceReport (report, type) {
  return report.reduce((a, c) => {
    a += c.data.reduce((aa, cc) => {
      if (cc.type === type) {
        aa += cc.amount
      }
      return aa
    }, 0); return a
  }, 0)
}

function addCommaToNumber (number) {
  return number.toLocaleString()
}

export default {
  name: 'BillingReportAllCustomerStats',
  props: ['report', 'dataLoaded'],
  components: {
    ICountUp
  },
  computed: {
    smsSent () {
      return reduceReport(this.report, UsageTypes.SMSSent)
    },
    emailSent () {
      return reduceReport(this.report, UsageTypes.EmailSent)
    },
    addressLookupSent () {
      return reduceReport(this.report, UsageTypes.AddressLookup)
    },
    bacsSubs () {
      return reduceReport(this.report, UsageTypes.BacsSubmission)
    },
    bacsTrans () {
      return reduceReport(this.report, UsageTypes.BacsTransaction)
    },
    fpsSubs () {
      return reduceReport(this.report, UsageTypes.FPSSubmission)
    },
    fpsTrans () {
      return reduceReport(this.report, UsageTypes.FPSTransaction)
    },
    collectionsSubs () {
      return reduceReport(this.report, UsageTypes.CollectionsSubmission)
    },
    collectionsTrans () {
      return reduceReport(this.report, UsageTypes.CollectionsTransaction)
    },
    bureauUniqueSuns () {
      return reduceReport(this.report, UsageTypes.BureauUniqueSUN)
    },
    bureauSubs () {
      return reduceReport(this.report, UsageTypes.BureauSubmission)
    },
    bureauTrans () {
      return reduceReport(this.report, UsageTypes.BureauTransaction)
    },
    workflowsExecuted () {
      return reduceReport(this.report, UsageTypes.AutomationExecutedWorkflow)
    },
    agentFilesUploaded () {
      return reduceReport(this.report, UsageTypes.AgentFileUploaded)
    },
    agentFilesDownloaded () {
      return reduceReport(this.report, UsageTypes.AgentFileDownloaded)
    },
    creditCardValidations () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && (cc.apiCall === ValidateApiCall.ValidateCreditCard || cc.apiCall === ValidateApiCall.ValidateCreditCardExtended)) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    },
    bankAccountValidations () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && cc.apiCall === ValidateApiCall.ValidateAccount) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    },
    bankDetailCalls () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && (cc.apiCall === ValidateApiCall.GetBACSDetails || cc.apiCall === ValidateApiCall.GetFPSDetails || cc.apiCall === ValidateApiCall.GetCHAPSDetails || cc.apiCall === ValidateApiCall.GetCCCDetails)) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    },
    ibanValidations () {
      return this.report.reduce((a, c) => {
        a += c.data.reduce((aa, cc) => {
          if (cc.type === UsageTypes.Validate && cc.apiCall === ValidateApiCall.ValidateIBAN) {
            aa += cc.amount
          }
          return aa
        }, 0); return a
      }, 0)
    }
  },
  data () {
    return {
      counterOptions: {
        startVal: 0,
        duration: 4,
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'SMS sent',
          field: 'smsSent',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Emails Sent',
          field: 'emailsSent',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Address Lookups',
          field: 'addressLookups',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'BACS Submissions',
          field: 'bacsSubs',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'BACS Transactions',
          field: 'bacsTrans',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'FPS Submissions',
          field: 'fpsSubs',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'FPS Transactions',
          field: 'fpsTrans',
          type: 'number',
          formatFn: addCommaToNumber
        }, {
          label: 'Collections Submissions',
          field: 'collectionsSubs',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Collections Transactions',
          field: 'collectionsTrans',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Bureau Unique SUNs',
          field: 'bureauSun',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Bureau Submissions',
          field: 'bureauSubs',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Bureau Transactions',
          field: 'bureauTrans',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Workflows Executed',
          field: 'workflow',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Agent Files Downloaded',
          field: 'agentDown',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Agent Files Uploaded',
          field: 'agentUp',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Credit Card Validations',
          field: 'creditCardValidations',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'Bank Account Validations',
          field: 'bankAccountValidations',
          type: 'number',
          formatFn: addCommaToNumber
        },
        {
          label: 'IBAN Validations',
          field: 'ibanValidations',
          type: 'number',
          formatFn: addCommaToNumber
        }

      ]
    }
  },
  watch: {
    report () {
      this.populateTable()
    }
  },
  mounted () {
    this.populateTable()
  },
  methods: {
    async populateTable () {
      this.rows = this.report.map(r => {
        return {
          name: r.customerName,
          smsSent: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.SMSSent) {
              a += c.amount
            }
            return a
          }, 0),
          emailsSent: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.EmailSent) {
              a += c.amount
            }
            return a
          }, 0),
          addressLookups: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.AddressLookup) {
              a += c.amount
            }
            return a
          }, 0),
          bacsSubs: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.BacsSubmission) {
              a += c.amount
            }
            return a
          }, 0),
          bacsTrans: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.BacsTransaction) {
              a += c.amount
            }
            return a
          }, 0),
          fpsSubs: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.FPSSubmission) {
              a += c.amount
            }
            return a
          }, 0),
          fpsTrans: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.FPSTransaction) {
              a += c.amount
            }
            return a
          }, 0),
          collectionsSubs: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.CollectionsSubmission) {
              a += c.amount
            }
            return a
          }, 0),
          collectionsTrans: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.CollectionsTransaction) {
              a += c.amount
            }
            return a
          }, 0),
          bureauSun: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.BureauUniqueSUN) {
              a += c.amount
            }
            return a
          }, 0),
          bureauSubs: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.BureauSubmission) {
              a += c.amount
            }
            return a
          }, 0),
          bureauTrans: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.BureauTransaction) {
              a += c.amount
            }
            return a
          }, 0),
          workflow: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.AutomationExecutedWorkflow) {
              a += c.amount
            }
            return a
          }, 0),
          agentDown: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.AgentFileDownloaded) {
              a += c.amount
            }
            return a
          }, 0),
          agentUp: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.AgentFileUploaded) {
              a += c.amount
            }
            return a
          }, 0),
          creditCardValidations: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.Validate && (c.apiCall === ValidateApiCall.ValidateCreditCard || c.apiCall === ValidateApiCall.ValidateCreditCardExtended)) {
              a += c.amount
            }
            return a
          }, 0),
          bankAccountValidations: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.Validate && c.apiCall === ValidateApiCall.ValidateAccount) {
              a += c.amount
            }
            return a
          }, 0),
          ibanValidations: r.data.reduce((a, c) => {
            if (c.type === UsageTypes.Validate && c.apiCall === ValidateApiCall.ValidateIBAN) {
              a += c.amount
            }
            return a
          }, 0),
          paygateId: r.paygateId
        }
      })
    },
    async onRowClick (params) {
      var paygateId = params.row.paygateId
      this.$router.push({ name: 'BillingReport', params: { id: paygateId } })
    }
  }
}
</script>

<style>
</style>
