export default {
  CustomerAdministrator: 'CustomerAdministrator',
  ManageBanks: 'ManageBanks',
  AddGroups: 'AddGroups',
  GroupAdministrator: 'GroupAdministrator',
  ManageUsers: 'ManageUsers',
  ViewAuditLog: 'ViewAuditLog',
  ViewBanks: 'ViewBanks',
  ViewUsers: 'ViewUsers',
  CreateCustomer: 'CreateCustomer',
  DeleteCustomer: 'DeleteCustomer',
  GDPRAdministrator: 'GDPRAdministrator',
  ImportCustomer: 'ImportCustomer',
  AgentConfigure: 'AgentConfigure',
  SystemUser: 'PaygateSystemAdmin',
  ManageAntiFraud: 'ManageAntiFraud',
  CustomerReports: 'CustomerReports',
  ViewWorkflow: 'ViewWorkflow',
  ManageWorkflow: 'ManageWorkflow',
  ProcessSubmissions: 'ProcessSubmissions',
  ActionsReports: 'ActionsReports',
  Finance: 'Finance',
  Marketing: 'Marketing',
  ManageBureauCustomers: 'ManageBureauCustomers'
}
