var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasErrors
      ? _c(
          "div",
          { staticClass: "alert alert-danger" },
          _vm._l(_vm.errors, function(error, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Inactivity Timeout Policy "),
                _c("help-icon", {
                  attrs: {
                    docPath:
                      "/administration/securitypolicy/inactivitytimeoutpolicy/"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "form-group row",
                class: { invalid: _vm.$v.policy.timeoutMinutes.$error }
              },
              [
                _c(
                  "label",
                  { staticClass: "col-form-label col-md-3 offset-md-1" },
                  [_vm._v("Inactivity Timeout (minutes)")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.policy.timeoutMinutes.$model,
                          expression: "$v.policy.timeoutMinutes.$model"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", min: "1" },
                      domProps: { value: _vm.$v.policy.timeoutMinutes.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.policy.timeoutMinutes,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("validation-messages", {
                      attrs: { name: "timeout" },
                      model: {
                        value: _vm.$v.policy.timeoutMinutes,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.policy, "timeoutMinutes", $$v)
                        },
                        expression: "$v.policy.timeoutMinutes"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c("div", { staticClass: "col-md-10 offset-md-1" }, [
        _c("p", [
          _vm._v(
            "If a user's session is inactive for a period of time paygate will automatically log the user out of the system. This is known as the 'Inactivity Timeout' and the value can be set here"
          )
        ]),
        _c("hr")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }