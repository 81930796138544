var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c("h2", [_vm._v(" News Stories "), _c("favourite-icon")], 1)
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _c(
          "div",
          [
            _c(
              "vue-good-table",
              {
                ref: "table",
                attrs: {
                  paginationOptions: _vm.paginationOptions,
                  "sort-options": _vm.sortOptions,
                  isLoading: _vm.isTableLoading,
                  rows: _vm.rows,
                  columns: _vm.columns,
                  lineNumbers: true,
                  mode: "remote",
                  totalRows: _vm.totalRecords,
                  "search-options": { enabled: true },
                  styleClass: "vgt-table striped bordered"
                },
                on: {
                  "update:isLoading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "update:is-loading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "on-row-click": _vm.onRowClick,
                  "on-page-change": _vm.onPageChange,
                  "on-sort-change": _vm.onSortChange,
                  "on-column-filter": _vm.onColumnFilter,
                  "on-per-page-change": _vm.onPerPageChange,
                  "on-search": _vm.onSearch
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field === "enabled" ||
                        props.column.field === "sticky"
                          ? _c("span", [
                              props.row[props.column.field] == true
                                ? _c("span", { staticClass: "text-success" }, [
                                    _c("i", {
                                      staticClass: "glyphicon ti-check"
                                    })
                                  ])
                                : _c("span", { staticClass: "text-danger" }, [
                                    _c("i", {
                                      staticClass: "glyphicon ti-close"
                                    })
                                  ])
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(props.formattedRow[props.column.field])
                              )
                            ])
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "table-actions" }, slot: "table-actions" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Refresh the data in the table",
                            expression: "'Refresh the data in the table'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link", title: "Refresh Table" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.load($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-sync-alt pointer dimmedIcon pointer"
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Refresh Table")
                        ])
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Create a new story",
                            expression: "'Create a new story'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.createNewsStory($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus dimmedIcon pointer"
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Add News Story")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c("br"),
        _c("div", { staticClass: "row col-12" }, [
          _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { id: "btnGroupDrop1", type: "button" },
                on: { click: _vm.createNewsStory }
              },
              [
                _c("i", { staticClass: "fa fa-plus rpad" }),
                _vm._v("New Story ")
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }