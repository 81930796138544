var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "renameBureauJob",
      attrs: {
        id: "rename-bureau-job",
        title: "Rename Bureau Job",
        "hide-header-close": "",
        static: "",
        centered: true,
        "footer-class": "rename-job-footer"
      },
      on: { shown: _vm.load, ok: _vm.RenameBureauJob },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(ref) {
            var ok = ref.ok
            var cancel = ref.cancel
            var hide = ref.hide
            return [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.nameInUse, variant: "primary" },
                  on: {
                    click: function($event) {
                      return ok()
                    }
                  }
                },
                [_vm._v("Rename")]
              ),
              _c(
                "b-button",
                {
                  on: {
                    click: function($event) {
                      return cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v("Rename")
      ]),
      _c("div", {}, [
        _c("label", { staticClass: "col-form-label col-md-6 required" }, [
          _vm._v("Bureau Job Name")
        ]),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { type: "text", formatter: _vm.trimInput },
              on: { input: _vm.checkName },
              model: {
                value: _vm.$v.renameName.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.renameName,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.renameName.$model"
              }
            }),
            _c(
              "validation-messages",
              {
                attrs: { name: "renameName" },
                model: {
                  value: _vm.$v.renameName,
                  callback: function($$v) {
                    _vm.$set(_vm.$v, "renameName", $$v)
                  },
                  expression: "$v.renameName"
                }
              },
              [
                _vm.$v.renameName.notSameAs != undefined &&
                !_vm.$v.renameName.notSameAs
                  ? _c("small", { staticClass: "form-text small" }, [
                      _vm._v(
                        "The new name name cannot be the same as the original name"
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm.nameInUse
              ? _c("small", { staticClass: "form-text small text-danger" }, [
                  _vm._v("Bureau Job Name already exists")
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }