<template>
	<div>
		<header class="app-header" id="app-header">
			<b-navbar toggleable="md" type="light">
				<div class="toolbar">
					<div class="sidebar-logo-wrap">
						<div class="d-flex justify-content-between">
							<div class="align-item-center">
								<router-link to="/">
									<a href="index.html" class="site-logo">
										<inline-svg
											src="/img/sharedimages/CorvidLogo/PAYGATE LOGO_WHITE.svg"
											height="38"
											style="padding-left:3px"
										></inline-svg>
										<h1 class="sr-only">Paygate</h1>
									</a>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<b-navbar-nav>
					<b-nav-item
						@click="emitCollapsedSidebar"
						style="z-index:3;"
						class="nav-link-smaller"
						id="collapsed-sidebar"
						v-b-popover.hover.focus.bottom="menuPopoverOptions"
					>
						<i class="ti ti-angle-left"></i>
						<span class="sr-only" v-if="sidebarCollapsed">Show Menu</span>
						<span class="sr-only" v-else>Hide Menu</span>
					</b-nav-item>
					<b-nav-item
						@click="goHome"
						style="z-index:3;"
						class="nav-link-smaller"
						aria-label="Home"
						v-b-popover.hover.bottom="'Home'"
					>
						<i class="ti ti-home"></i>
						<span class="sr-only">Home</span>
					</b-nav-item>
				</b-navbar-nav>
				<b-collapse is-nav v-if="displayHeader">
					<search-icon></search-icon>
					<b-navbar-nav>
						<b-nav-item v-if="displayCustomerSelector">
							<customer-selector></customer-selector>
						</b-nav-item>
					</b-navbar-nav>

					<!-- Right aligned nav items -->
					<b-navbar-nav is-nav-bar class="ml-auto">
						<transition-group name="fade" tag="ul" class="navbar-nav">
							<b-nav-item
								v-for="favourite in favourites"
								:key="favourite.value"
								right
								:to="favourite.value"
								v-b-popover.hover.focus.bottom="favourite.title"
							>
								<span>
									<i class="ti" :class="favourite.icon" style="line-height:30px"></i>
									<span class="sr-only">{{favourite.title}}</span>
								</span>
							</b-nav-item>
						</transition-group>
					</b-navbar-nav>
				</b-collapse>
				<b-navbar-nav is-nav-bar class="ml-auto">
					<b-nav-item-dropdown
						right
						ref="user-panel"
						id="user-panel"
						v-b-popover.hover.focus.bottom="'User Account'"
					>
						<template slot="button-content">
							<span style="line-height: 30px;">
								<avatar class="rounded-circle" width="32" height="32" alt="user"></avatar>
							</span>
						</template>
						<b-card>
							<p class="card-text"></p>
							<div class="row">
								<div class="col-md-5">
									<avatar width="128" height="128" alt="avatar" class="img-fluid avatar-image" />
								</div>
								<div class="col-md-7">
									<span>{{email || username}}</span>
									<br />
									<p class="text-muted small">Customer Name: {{custname}}</p>
									<a v-if="tel !== ''" v-bind:href="'tel:' + tel" class="text-muted small">{{tel}}</a>
								</div>
							</div>
							<div slot="footer">
								<button class="btn btn-primary" @click="changeSettings">Change Settings</button>
								<button class="btn btn-danger float-right" @click="logout">Sign Out</button>
							</div>
						</b-card>
					</b-nav-item-dropdown>
					<b-nav-item-dropdown
						right
						no-caret
						class="custom-dropdown"
						v-b-popover.focus.hover.bottom="'Quick Links'"
					>
						<template slot="button-content">
							<span>
								<i class="ti-layout-grid3" style="line-height: 30px;"></i>
								<span class="sr-only">Show Quick Links</span>
							</span>
						</template>
						<b-dropdown-header class="bg-success text-white">Quick Links</b-dropdown-header>
						<b-dropdown-item to="/">
							<div class="icon-area">
								<i class="ti-dashboard"></i>
							</div>
							<small>Dashboard</small>
						</b-dropdown-item>
						<b-dropdown-item to="/automation/config/foldermanager">
							<div class="icon-area">
								<i class="ti-folder"></i>
							</div>
							<small>Folder Manager</small>
						</b-dropdown-item>
						<b-dropdown-item to="/actions">
							<div class="icon-area">
								<i class="ti-bell"></i>
							</div>
							<small>Actions</small>
						</b-dropdown-item>
						<b-dropdown-item @click="openHelpInNewTab()">
							<div class="icon-area">
								<i class="ti-book"></i>
							</div>
							<small>Help Portal</small>
						</b-dropdown-item>
						<b-dropdown-item to="/about/contactsupport">
							<div class="icon-area">
								<i class="ti-support"></i>
							</div>
							<small>Contact Support</small>
						</b-dropdown-item>
						<b-dropdown-item @click="openInNewTab('https://status.paygate.cloud/')">
							<div class="icon-area">
								<i class="ti-pulse"></i>
							</div>
							<small>Service Status</small>
						</b-dropdown-item>
						<b-dropdown-item :to="{ name: 'ChangeSettings'}">
							<div class="icon-area">
								<i class="ti-user"></i>
							</div>
							<small>Account</small>
						</b-dropdown-item>
						<!-- <b-dropdown-item @click="openInNewTab('httsp://developers.paygateservice.com')">
              <div class="icon-area">
                <i class="ti-pencil-alt"></i>
              </div>
              <small>Developer Portal</small>
						</b-dropdown-item>-->
						<b-dropdown-item @click="openInNewTab('https://www.paygate.uk/')">
							<div class="icon-area">
								<i class="ti-cloud"></i>
							</div>
							<small>paygate.uk</small>
						</b-dropdown-item>
						<b-dropdown-item @click="openFeedbackModal()">
							<div class="icon-area">
								<i class="ti-comments"></i>
							</div>
							<small>Give Feedback</small>
						</b-dropdown-item>
					</b-nav-item-dropdown>

					<b-nav-item @click="openFeedbackModal()" v-b-popover.focus.hover.bottom="'Give Feedback'">
						<span>
							<i class="ti-comments" style="line-height:30px" tooltip="Give feedback"></i>
							<span class="sr-only">Give Feedback</span>
						</span>
					</b-nav-item>
					<actions></actions>
				</b-navbar-nav>
			</b-navbar>
			<!-- <right-sidebar :sidebarOpen="rightSidebar"></right-sidebar> -->
		</header>
		<feedback></feedback>
	</div>
</template>

<script>
import SearchIcon from '@/Assets/Components/Search/SearchIcon'
import EventBus from '@/Lib/eventBus'
import CustomerSelector from './CustomerSelector.vue'
import Auth from '@/Assets/Components/Authentication/auth.js'
import Actions from '@/Assets/Components/Actions/ActionsIcon'
import Feedback from '@/Assets/Components/Feedback/FeedbackModal'
import { mapGetters } from 'vuex'
import InlineSvg from 'vue-inline-svg'
import Menu from '@/Menu'

function getIconAndTitleForPath (routes, path) {
	let found = false
	let icon = null
	let title = null

	const reccur = (route, path) => {
		// Go to the bottom of the tree
		if (route.routes === undefined && route.child_routes === undefined) {
			if (route.path === path) {
				if (route.menu_icon) {
					icon = route.menu_icon
				}
				if (route.menu_title) {
					title = route.menu_title
					if (route.title) {
						title = route.title
					}
				}

				found = true
			}
		} else {
			var routes = route.child_routes || route.routes
			for (var i = 0; i < routes.length + 1; i++) {
				if (found) {
					if (icon != null) {
						return
					}
					if (routes[i - 1].menu_icon && icon == null) {
						icon = routes[i - 1].menu_icon
						if (title == null) {
							title = routes[i - 1].menu_title
							if (routes[i - 1].title) {
								title = routes[i - 1].title
							}
						}
					}
				} else if (i < routes.length) {
					reccur(routes[i], path)
				}
			}
		}
	}
	reccur(routes, path)
	return { icon, title }
}

export default {
	props: {
		displayCustomerSelector: {
			default: true
		},
		displayHeader: {
			default: true
		},
		sidebarCollapsed: {
			default: false
		}
	},
	data: function () {
		return {
			rightSidebar: false,
			fullScreen: false,
			editAccountUrl: null
		}
	},
	computed: {
		fullScreenView: function () {
			return this.fullScreen
		},
		...mapGetters({
			getClaim: 'getClaim',
			getClaims: 'getClaims'
		}),
		custname () {
			return this.getClaim('customer_name').value
		},
		username () {
			return this.getClaim('username').value
		},
		email () {
			return this.getClaim('email').value
		},
		tel () {
			return this.getClaim('phone').value
		},
		favourites () {
			return this.getClaims('favourite').map(f => {
				return { ...f, ...getIconAndTitleForPath(Menu, f.value) }
			})
		},
		folderManager () {
			return 'https://paygateservicedev.com/automation/config/foldermanager'
		},
		menuPopoverOptions () {
			if (this.sidebarCollapsed) {
				return {
					content: 'Show Menu'
				}
			}

			return {
				content: 'Hide Menu'
			}
		}
	},
	components: {
		SearchIcon,
		CustomerSelector,
		Actions,
		InlineSvg,
		Feedback
	},
	methods: {
		emitCollapsedSidebar () {
			EventBus.$emit('collapsedSidebar')
		},
		logout () {
			Auth.logout()
		},
		changeSettings () {
			this.$refs['user-panel'].hide()
			this.$router.push({ name: 'ChangeSettings' })
		},
		openInNewTab (url) {
			window.open(url, '_blank')
		},
		goHome () {
			this.$router.push({ name: 'Dashboard' })
		},
		openFeedbackModal () {
			this.$bvModal.show('feedback')
		},
		openHelpInNewTab () {
			window.open(process.env.VUE_APP_DOCUMENTATION_ROOT_URL, '_blank')
		}
	}
}
</script>
<style lang="scss">
.dots {
	background: url("/img/sharedimages/dot.png") top center repeat-y;
}

.avatar-image {
	width: 128px;
	border-radius: 4px;
}

.no-btn {
	border-color: transparent;
	background-color: transparent;

	&:focus {
		outline: none;
	}
}

.nav-link-smaller {
	width: 50px;
}

#collapsed-sidebar {
	transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
}

#text {
	transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
}

.collapsed-sidebar {
	#collapsed-sidebar {
		transform: translateY(-5px) rotate(180deg);
	}

	#text {
		opacity: 0;
	}
}
</style>
