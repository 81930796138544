var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "variableAmountUpload" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c("div", { staticClass: "idb-block" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        _vm.checkValidation() && _vm.importData()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "idb-block-title" }, [
                      _c(
                        "h2",
                        [
                          _c("help-icon", {
                            attrs: {
                              docPath:
                                "/paygate-collections/import-export/updatevariableamounts/"
                            }
                          }),
                          _vm._v("Update Variable Amounts "),
                          _c("favourite-icon")
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "idb-block-content" },
                      [
                        _c("div", { staticClass: "row form-group" }, [
                          _c("div", { staticClass: "col-md-2 required" }, [
                            _vm._v("Select a Group")
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-4" },
                            [
                              _c("group-select", {
                                attrs: {
                                  groups: _vm.customerGroups,
                                  clearable: false
                                },
                                on: { input: _vm.customerGroupsChanged },
                                model: {
                                  value: _vm.$v.group.$model,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v.group, "$model", $$v)
                                  },
                                  expression: "$v.group.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "Group" },
                                model: {
                                  value: _vm.$v.group,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v, "group", $$v)
                                  },
                                  expression: "$v.group"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c(
                          "b-container",
                          [
                            _c("dropzoneLike", {
                              ref: "serverFileUploader",
                              attrs: {
                                groupId: _vm.group,
                                dropzoneOptions: _vm.dropzoneOptions,
                                dropzoneUrl: _vm.dropzoneUrl,
                                secureUrl: _vm.secureUrl
                              },
                              on: {
                                fileAdded: _vm.publicFileAdded,
                                fileRemoved: _vm.publicFileRemoved
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "idb-block-footer" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              disabled:
                                !_vm.importEnabled ||
                                _vm.isLoading ||
                                !_vm.canUpload
                            }
                          },
                          [_vm._v("Import")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "idb-block mb-20" }, [
                _c("div", { staticClass: "idb-block-title" }, [
                  _c("h2", [_vm._v("Your Uploads")])
                ]),
                _c(
                  "div",
                  { staticClass: "idb-block-content" },
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "table",
                        attrs: {
                          mode: "remote",
                          columns: _vm.columns,
                          rows: _vm.uploads,
                          lineNumbers: true,
                          totalRows: _vm.totalRecords,
                          "search-options": {
                            enabled: false
                          },
                          isLoading: _vm.isTableLoading,
                          paginationOptions: _vm.paginationOptions,
                          "sort-options": _vm.sortOptions,
                          styleClass: "vgt-table striped bordered"
                        },
                        on: {
                          "on-row-click": _vm.onRowClick,
                          "on-cell-click": _vm.onCellClick,
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field === "status"
                                  ? _c("span", [
                                      _c(
                                        "div",
                                        { staticClass: "fileNameAligner" },
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true }
                                                }
                                              ],
                                              attrs: {
                                                title:
                                                  props.row.status === 5 ||
                                                  props.row.status === 7
                                                    ? props.row.messages
                                                    : _vm.convertStatus(
                                                        props.row.status
                                                      ),
                                                variant: _vm.getSeverityClass(
                                                  props.row.status
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.convertStatus(
                                                    props.row.status
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  : props.column.label === "Staged"
                                  ? _c("span", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "uploadPercentageContainer"
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "uploadPercentage",
                                            class: _vm.buildPercentageBarClass(
                                              props.row.stagingProgress
                                            ),
                                            style: {
                                              width:
                                                props.row.stagingProgress + "%"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "uploadPercentageLabel"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  props.formattedRow[
                                                    props.column.field
                                                  ]
                                                ) + "%"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : props.column.label === "Imported"
                                  ? _c("span", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "uploadPercentageContainer"
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "uploadPercentage",
                                            class: _vm.buildPercentageBarClass(
                                              _vm.processingCompleted(props.row)
                                            ),
                                            style: {
                                              width:
                                                _vm.processingCompleted(
                                                  props.row
                                                ) + "%"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "uploadPercentageLabel"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  props.row.importedRows +
                                                    "/" +
                                                    props.row.totalRows
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : props.column.label === ""
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.clearUpload(
                                                  props.row.uploadedFileId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times",
                                              attrs: { "aria-hidden": "true" }
                                            }),
                                            _vm._v(" Clear ")
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : props.column.field === "uploadDate"
                                  ? _c("span", [
                                      _c(
                                        "div",
                                        { staticClass: "fileNameAligner" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                props.row.uploadDate
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  : _c("span", [
                                      _c(
                                        "div",
                                        { staticClass: "fileNameAligner" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("template", { slot: "loadingContent" }, [
                          _c("h1", [_vm._v("Loading...")])
                        ]),
                        _c(
                          "div",
                          { attrs: { slot: "emptystate" }, slot: "emptystate" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vgt-center-align vgt-text-disabled"
                              },
                              [_vm._v("No Uploads Available")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Clear filters",
                                    expression: "'Clear filters'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.clearTableFilters($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fa-stack",
                                    staticStyle: { "font-size": "10px" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-filter fa-stack-1x dimmedIcon"
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "fa fa-ban fa-stack-2x dimmedIcon"
                                    })
                                  ]
                                ),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Clear filters")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Clear incomplete uploads",
                                    expression: "'Clear incomplete uploads'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRecords($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-times pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Clear incomplete uploads")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }