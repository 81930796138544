var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h4", { staticClass: "m-0" }, [
        _vm._v(
          "Look Ahead Report " + _vm._s(this.cumulative ? "(Cumulative)" : "")
        )
      ])
    ]),
    _c(
      "div",
      { staticStyle: { position: "relative", height: "350px !important" } },
      [
        _vm.loaded
          ? _c("look-ahead-chart", {
              attrs: {
                "chart-data": _vm.chartData,
                options: _vm.chartOptions,
                styles: { height: "100%" }
              }
            })
          : _vm._e(),
        !_vm.loaded ? _c("div", [_vm._v("Loading...")]) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }