<template>
  <div class="idb-right-panel" :class="sidebarOpen ? 'collapse-show' : 'collapse-hide'">
    <div class="custom-sidebar-pan">
      <div class="system-setting">
        <div class="setting-header">
          <!-- <i class="fa fa-plus"></i>  -->
          Add Widget
        </div>
        <form class="pt-10 m-10 widget-search" @submit.prevent="search">
          <div class="form-group">
            <label for="widgetSearch" class="col-form-label">Search</label>
            <input id="widgetSearch" type="text" class="form-control" @input="search()" v-model="searchValue" />
          </div>
        </form>
        <div class="list-group list-group-flush widget-list">
          <div v-for="widgetType in widgets" :key="widgetType.groupType" class="widget-box">
            <span
              class="list-group-item dropdown-header list-group-item-secondary"
            >{{widgetType.groupType | startCase}}</span>
            <div
              class="list-group-item list-group-item-action"
              :class="selectedWidget !== null && widget.name == selectedWidget.name ? 'selected' : ''"
              v-for="widget in widgetType.widgets"
              :key="widget.name"
              draggable="true"
              @click="selectWidget(widget)"
              @dblclick="addBClick(widget)"
              @dragstart="dragStart(widget,$event)"
              @dragend="dragEnd($event)"
            >
              <div class="row">
                <div class="col-md-3">
                  <img aria-hidden="true"
                    :src="`/img/sharedimages/WidgetImages/${widget.name.replace(/\s/g, '')}.jpg`"
                    class="img-fluid mt-auto" :alt="widget.name+' icon'"
                  />
                </div>
                <div class="col-md-9">
                  <h5>{{widget.name}}</h5>
                  <p class="mb-1">{{widget.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-auto buttons">
          <button class="btn btn-primary mr-10" :disabled="selectedWidget === null" @click="add">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import '@/Assets/Filters/startCase.js'

export default {
  name: 'WidgetAdder',
  props: {
    sidebarOpen: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      searchValue: '',
      widgets: [],
      selectedWidget: null
    }
  },
  methods: {
    selectWidget (widget) {
      if (this.selectedWidget !== null && this.selectedWidget.name === widget.name) {
      } else {
        this.selectedWidget = widget
      }
    },
    search: _.debounce(async function (e) {
      var widgetResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Dashboard/Widgets`, { params: { search: this.searchValue } })
      this.widgets = widgetResponse.data
    }, 500),
    add () {
      this.$emit('add', this.selectedWidget)
    },
    addBClick (widget) {
      this.selectWidget(widget)
      this.add()
    },
    dragStart (widget, event) {
      event.dataTransfer.clearData()
      this.selectWidget(widget)
      event.dataTransfer.setData('text', JSON.stringify(widget))
      if (event.dataTransfer.setDragImage) {
        const img = event.currentTarget.firstElementChild.firstElementChild.firstElementChild
        event.dataTransfer.setDragImage(img, img.width / 2, img.height / 2)
      }
      event.effectAllowed = 'copy'
      document.body.classList.add('dragging')
    },
    dragEnd (event) {
      document.body.classList.remove('dragging')
    }
  },
  async mounted () {
    this.search()
  }
}
</script>
<style lang="scss" scoped>
.system-setting {
  flex-direction: column;
  display: flex;
  overflow: hidden;
  min-height: 100%; /* fix IE11 */
  flex-flow: column nowrap;
}
.buttons {
  padding: 1rem;
}

.widget-list {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
  flex-grow: 0; /*  fill horizontal space */
  display: flex;
  flex-direction: column;
}

.widget-box {
  -ms-flex-negative: 0; /*I really really really really dispise IE, Edge is okay, but plain old IE is just a nightmare*/
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

.selected {
  background-color: rgba(80, 140, 252, 0.397);
  border: none !important;
}
</style>
