<template>
  <b-modal
    id="add-bureau-job-detail"
    ref="addBureauJobDetailModal"
    title="Add Bureau Job Detail"
    size="xl"
    hide-header-close
    cancel-variant="danger"
    static
    @shown="load"
    @ok="addJobDetail"
    :ok-disabled="disableSave"
  >
    <div slot="modal-ok">Save</div>
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; onCloseFileBrowser()"></fileBrowser>
    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.bureauCustomerId">Bureau Customer</label>
      </b-col>
      <b-col sm="6">
        <vue-select
          single
          v-model="selectedBureauCustomer"
          :options="bureauCustomers"
          label="name"
          :closeOnSelect="true"
          @deselected="CheckBureauCustomerOnSelect"
          @input="CheckBureauCustomerOnSelect" :clearable="true"
        ></vue-select>
        <span
          v-if="jobDetailRecord.importSchema === 'Standard18'"
          class="text-warning small"
        >You do not have to select a bureau customer for Standard 18 imports</span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.sun">Service User Number</label>
      </b-col>
      <b-col sm="6">
        <b-form-input
          ref="jobDetailRecord.sun"
          disabled
          v-model="selectedBureauCustomer.serviceUserNumber"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.paymentfile">Payment File</label>
      </b-col>
      <b-col sm="6">
        <b-form-input
          ref="filename"
          type="text"
          @input="onFilenameChanged()"
          @keypress="SetFilenameManualInput($event)"
          v-model.trim="$v.jobDetailRecord.filename.$model"
        ></b-form-input>
        <span class="small" v-if="jobDetailRecord.filename.length > 0">Example: {{ parsedFilename }}</span>
        <span v-if="!filenameValid">
          <br />
          <span class="text-danger small">This filename is not valid</span>
        </span>

        <span v-if="ShowFilenames18WildcardWarning">
          <br />
          <span
            class="text-danger small"
          >You cannot use these wildcards with a Standard 18 mode job without choosing a bureau customer.</span>
        </span>
      </b-col>

      <b-col sm="2">
        <button class="btn btn-outline-primary ml-3" type="button" @click="onShowFileBrowser">
          <i class="glyphicon ti-layers rpad"></i>Browse Folder
        </button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="9" offset-sm="3">
        <label>
          <a class="text-primary small" @click="addLookup('[CUSTOMERREF]')">[CUSTOMERREF]</a>
          will be replaced with Customer Reference
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label>
          <a class="text-primary small" @click="addLookup('[SUN]')">[SUN]</a>
          will be replaced with customers Service Reference Number
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label>
          <a
            class="text-primary small"
            @click="addLookup('[DATETIME, DD-MM-YYYY]')"
          >[DATETIME, FORMAT]</a>
          will be replaced with the current formatted date/time
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label class="small">* and ? can be used as wildcards</label>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.importSchema">Import Schema</label>
      </b-col>
      <b-col sm="5">
        <b-form-select v-model="jobDetailRecord.importSchema" :options="this.schemas"></b-form-select>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="jobDetailRecord.importSchema === 'Mapping'">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.Mapping">Mapping</label>
      </b-col>
      <b-col sm="5">
        <b-form-select v-model="jobDetailRecord.mapping" :options="this.mappings"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Utility from '@/Assets/Mixins/Utility'
import { maxLength, required } from 'vuelidate/lib/validators'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import VueSelect from 'vue-select'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
import mapping from '../../../Store/Modules/mapping'
import _ from 'lodash'

export default {
  mixins: [Utility, loading],
  components: {
    VueSelect,
    fileBrowser: fileBrowser
  },
  props: ['bureauJobId', 'bureauCustomers'],
  data () {
    return {
      jobDetailRecord: {
        filename: '',
        mapping: null,
        importSchema: ''
      },
      mappings: [],
      schemas: [],
      parsedFilename: '',
      filenameValid: true,
      disableSave: true,
      selectedBureauCustomer: {
        bureauCustomerId: '',
        serviceUserNumber: ''
      },
      showFileBrowser: false,
      selectedNode: {},
      ShowFilenames18WildcardWarning: false,
      FilenameHasManualInput: false
    }
  },

  async created () {
    this.schemas = this.loadSchemas()
    this.mappings = await this.loadMappings('')
  },

  watch: {
    jobDetailRecord: {
      handler (val) {
        this.runValidation()
      },
      deep: true
    },
    selectedBureauCustomer: function () {
      this.runValidation()
    }

  },

  methods: {
    load () {
      // Loader

      this.jobDetailRecord = {
        filename: ''
      }
      this.parsedFilename = ''
      this.filenameValid = true
      this.selectedBureauCustomer = {}
      this.FilenameHasManualInput = false
    },

    onShowFileBrowser () {
      // this.property = this.selectedNode.props.s2.value
      // this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showFileBrowser = true
    },

    async onShowFolderBrowser () {
      this.$store.commit('setModalPopupString', '')
      this.showFolderBrowser = true
    },

    onCloseFileBrowser () {
      var filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (!this.pathHasFileExtension(filename)) {
        filename += '/*'
      }
      this.jobDetailRecord.filename = filename
      this.FilenameHasManualInput = true
    },

    pathHasFileExtension: function (path) {
      return path.slice((path.lastIndexOf('.') - 1 >>> 0) + 2)
    },

    onCloseFolderBrowser () {
      var filepath = this.$store.state.mapping.modalPopupString
      var filename = this.jobDetailRecord.filename
      if (filepath.length > 0 && filepath.charAt(0) === '/') {
        filepath = filepath.substring(1)
      }
      if (this.jobDetailRecord.filename.length === 0) {
        filename = ''
      } else if (this.jobDetailRecord.filename.includes('/')) {
        if (this.jobDetailRecord.filename.lastIndexOf('/') === this.jobDetailRecord.filename.length) { // it was path only
          filename = ''
        } else {
          filename = this.jobDetailRecord.filename.substring(this.jobDetailRecord.filename.lastIndexOf('/') + 1)
        }
      } else {
        filename = this.jobDetailRecord.filename
      }
      if (filepath.length === 0) {
        this.jobDetailRecord.filename = filename
      } else {
        this.jobDetailRecord.filename = filepath + '/' + filename
      }
      this.FilenameHasManualInput = true
    },

    async addJobDetail () {
      var payload = {
        jobid: this.bureauJobId,
        filename: this.jobDetailRecord.filename,
        importschema: this.jobDetailRecord.importSchema,
        mapping: this.jobDetailRecord.mapping,
        bureaucustomerid: this.selectedBureauCustomer.bureauCustomerId

      }
      var data = JSON.stringify(payload)
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/insertupdatebureaujobdetail', data, { showload: true })

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)

        if (this.cloning) {
          this.$router.push({ name: 'ManageJob', params: { id: this.response.bureauJobId } })
        } else {
          this.$emit('submit')
          this.$v.$reset()
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    },

    CheckBureauCustomerOnSelect (row) {
      if (row !== null) {
        if (!this.FilenameHasManualInput) {
          this.jobDetailRecord.filename = row.defaultImportFilename
        }
        if (row.defaultImportSchema !== 'None' && !(row === undefined || row === null || row === '')) {
          this.jobDetailRecord.importSchema = row.defaultImportSchema
        }
        if (this.jobDetailRecord.importSchema === 'Mapping') {
          var oid = row.defaultImportMapping
          var map = _.find(this.mappings, function (m) {
            if (m.value === oid) {
              return true
            }
          })

          this.jobDetailRecord.mapping = oid
        }
      } else {
        this.selectedBureauCustomer = { bureauCustomerId: 'ffffffff-ffff-ffff-ffff-ffffffffffff' }
      }
    },
    async addLookup (lookup) {
      this.jobDetailRecord.filename += lookup
      await this.onFilenameChanged()
      this.$refs.filename.$el.focus()
    },

    async VerifyDuplicateData () {
      var payload = {
        jobid: this.bureauJobId,
        filename: this.jobDetailRecord.filename,
        importschema: this.jobDetailRecord.importSchema,
        mapping: this.jobDetailRecord.mapping,
        bureaucustomerid: this.selectedBureauCustomer.id,
        bureauJobDetailId: this.bureauJobDetailId
      }
      var data = JSON.stringify(payload)
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/verifyduplicatejobdetail', data, { showload: true })
      if (response.data.toastType === 2 || response.data.toastType === 3) {
        this.showDuplicateWarning = false
      } else {
        this.showDuplicateWarning = true
      }
    },
    SetFilenameManualInput: function ($event) {
      this.FilenameHasManualInput = true
    },
    async onFilenameChanged () {
      let filename = this.jobDetailRecord.filename

      if (filename.length === 0) {
        this.FilenameHasManualInput = false
      }
      // Customer reference
      if (filename.includes('[CUSTOMERREF,')) {
        if (this.jobDetailRecord.customerReference !== 'undefined') {
          filename = filename.replace(/\[CUSTOMERREF\]/g, this.jobDetailRecord.customerReference)
        }
      }

      // Service user number
      if (filename.includes('[SUN,')) {
        if (this.jobDetailRecord.serviceUserNumber !== 'undefined') {
          filename = filename.replace(/\[SUN\]/g, this.selectedBureauCustomer.serviceUserNumber)
        }
      }

      // Date/Time
      if (filename.includes('[DATETIME,')) {
        const start = filename.indexOf('[DATETIME,')
        const end = filename.indexOf(']', start + 10)
        const stringToReplace = filename.substr(start, end - start + 1)
        const format = filename.substr(start + 11, end - start - 11)
        const value = moment().format(format)
        filename = filename.replace(stringToReplace, value)
      }
      this.parsedFilename = filename
      this.filenameValid = this.validateFilename(filename)
      await this.VerifyDuplicateData()
    },
    validateFilename (filename) {
      // Check length
      if (!filename || filename.length > 255) {
        return false
      }

      // Check for ..
      if (filename.includes('..')) {
        return false
      }

      // Check for \\
      if (filename.includes('\\\\')) {
        return false
      }

      // Check for ports
      if (/^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i.test(filename)) {
        return false
      }

      return true
    },
    runValidation () {
      var FilenameValid =
        !this.$v.jobDetailRecord.filename.$invalid &&
        this.validateFilename(this.jobDetailRecord.filename)

      var ImportSchemaValid = (this.jobDetailRecord.importSchema !== 'None' && this.jobDetailRecord.importSchema !== '' && this.jobDetailRecord.importSchema !== undefined)
      var customerId = this.selectedBureauCustomer.bureauCustomerId
      var schema = this.jobDetailRecord.importSchema
      var filename = this.jobDetailRecord.filename
      var standard18Mode = this.jobDetailRecord.importSchema === 'Standard18'
      var customerIdSet = customerId !== undefined && customerId !== '' && customerId !== 'ffffffff-ffff-ffff-ffff-ffffffffffff'
      // if (ImportSchemaValid) {
      //   if ( && customerIdSet) {
      //     standard18Mode = true
      //   } else {
      //     standard18Mode = false
      //   }
      // }

      var BureauCustomerValid = customerIdSet || standard18Mode

      if (ImportSchemaValid && this.jobDetailRecord.importSchema === 'Mapping') {
        ImportSchemaValid = this.jobDetailRecord.mapping !== ''
      }
      var BadWildCardValues = ['[CUSTOMERREF]', '[SUN]']

      var ContainsBadWildcardValues = _.some(BadWildCardValues, (word) => _.includes(this.jobDetailRecord.filename, word))

      if (standard18Mode && ContainsBadWildcardValues && !customerIdSet) {
        this.ShowFilenames18WildcardWarning = true
        this.disableSave = true
      } else {
        this.disableSave = !(FilenameValid && BureauCustomerValid && ImportSchemaValid)
        this.ShowFilenames18WildcardWarning = false
      }

      if (this.$v.jobDetailRecord.filename.$dirty) {
        this.filenameValid = FilenameValid
      }
    }

  },
  validations: {
    jobDetailRecord: {
      filename: { required, maxLength: maxLength(255) }
    }
  }

}

</script>
