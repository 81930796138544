import { render, staticRenderFns } from "./PayerExpiration.vue?vue&type=template&id=63467906&"
import script from "./PayerExpiration.vue?vue&type=script&lang=js&"
export * from "./PayerExpiration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63467906')) {
      api.createRecord('63467906', component.options)
    } else {
      api.reload('63467906', component.options)
    }
    module.hot.accept("./PayerExpiration.vue?vue&type=template&id=63467906&", function () {
      api.rerender('63467906', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Views/Collections/Payer/PayerExpiration.vue"
export default component.exports