<template>
	<form @submit.prevent="checkValidation() && saveMaintenanceWindow()">
		<div class="idb-block">
			<div class="idb-block-title">
				<h2>{{status}} Maintenance Window</h2>
			</div>

			<div class="idb-block-content">
				<!--Type -->
				<div class="form-group row">
					<label class="label-control col-md-2 required">Type</label>
					<div class="col-md-3">
						<b-form-select
							v-model="maintenanceWindow.type"
							:options="types"
							:disabled="status === 'Edit'"
						></b-form-select>
					</div>
				</div>
				<!-- Reason -->
				<div class="form-group row" :class="{ invalid: $v.maintenanceWindow.reason.$error }">
					<label class="col-form-label col-md-2 required">Reason</label>
					<div class="col-md-4">
						<input type="text" class="form-control" v-model.trim="$v.maintenanceWindow.reason.$model" />
						<!-- Validation -->
						<validation-messages v-model="$v.maintenanceWindow.reason" name="reason"></validation-messages>
					</div>
				</div>

				<div class="form-group row">
					<!-- Start DateTime -->
					<label class="col-form-label col-md-2 required">Start</label>
					<div class="col-md-3" :class="{ invalid: $v.maintenanceWindow.start.$error }">
						<div class="input-group date">
							<date-picker v-model="$v.maintenanceWindow.start.$model" :config="dateOptions" :wrap="true"></date-picker>
							<div class="input-group-addon">
								<span class="far fa-calendar"></span>
							</div>
						</div>
						<validation-messages v-model="$v.maintenanceWindow.start" name="start date">
							<small
								v-if="!$v.maintenanceWindow.start.minDate"
								class="form-text small validation-messages"
							>Please enter a start date greater than {{ $v.maintenanceWindow.start.$params.minDate.min }}</small>
							<small
								v-if="!$v.maintenanceWindow.start.maxDate"
								class="form-text small validation-messages"
							>Please enter a start date less than {{ $v.maintenanceWindow.start.$params.maxDate.max }}</small>
						</validation-messages>
					</div>
					<!-- End DateTime -->
					<label class="col-form-label col-md-1 required">End</label>
					<div class="col-md-3" :class="{ invalid: $v.maintenanceWindow.end.$error }">
						<div class="input-group date">
							<date-picker v-model="$v.maintenanceWindow.end.$model" :config="dateOptions" :wrap="true"></date-picker>
							<div class="input-group-addon">
								<span class="far fa-calendar"></span>
							</div>
						</div>
						<validation-messages v-model="$v.maintenanceWindow.end" name="end date">
							<small
								v-if="!$v.maintenanceWindow.end.minDate"
								class="form-text small validation-messages"
							>Please enter an end date greater than {{ $v.maintenanceWindow.end.$params.minDate.min }}</small>
						</validation-messages>
					</div>
				</div>

				<!-- Description -->
				<div class="form-group row" :class="{ invalid: $v.maintenanceWindow.description.$error }">
					<label class="col-form-label col-md-2 required">Description</label>
					<div class="col-md-10">
						<div ref="editor"></div>
						<!-- Validation -->
						<validation-messages v-model="$v.maintenanceWindow.description" name="description"></validation-messages>
					</div>
				</div>

				<!-- Publish Date -->
				<div class="form-group row" :class="{ invalid: $v.maintenanceWindow.publishDate.$error }">
					<label class="col-form-label col-md-2 required">Publish Date</label>
					<div class="col-md-3">
						<div class="input-group date">
							<date-picker
								v-model="$v.maintenanceWindow.publishDate.$model"
								:config="dateOptions"
								:wrap="true"
							></date-picker>
							<div class="input-group-addon">
								<span class="far fa-calendar"></span>
							</div>
						</div>
						<!-- Validation -->
						<validation-messages v-model="$v.maintenanceWindow.publishDate" name="publish date">
							<small
								v-if="!$v.maintenanceWindow.publishDate.maxDate"
								class="form-text small validation-messages"
							>Please enter a publish date less than {{ $v.maintenanceWindow.publishDate.$params.maxDate.max }}</small>
						</validation-messages>
					</div>
				</div>

				<div class="form-group row" :class="{ invalid: $v.maintenanceWindow.selectedCustomers.$error }">
					<label class="col-form-label col-md-2" for="maintWindowCustomers">Limit Access To Customers</label>

					<div class="col-md-5">
						<vue-select
							id="maintWindowCustomers"
							v-model="$v.maintenanceWindow.selectedCustomers.$model"
							:options="customers"
							:closeOnSelect="true"
							label="name"
							:reduce="customer => customer.paygateId"
							:clearable="false"
							:selectable="option => !option.hasOwnProperty('group')"
							multiple
						>
							<template #option="{ group, name }">
								<div v-if="group" class="group">{{ group }}</div>
								{{ name }}
							</template>
						</vue-select>
						<small>
							The Paygate customer will always be included in this, if no customers are selected
							<b>ONLY</b> paygate users will be able to access the system
						</small>
					</div>
				</div>

				<!-- Enabled -->
				<div class="form-group row" :class="{ invalid: $v.maintenanceWindow.enabled.$error }">
					<label class="col-form-label col-md-2" for="maintWindowEnabled">Enabled</label>
					<div class="col-md-5">
						<p-check
							:labelId="'maintWindowEnabled'"
							class="p-switch p-fill"
							color="primary"
							v-model="$v.maintenanceWindow.enabled.$model"
						></p-check>
						<!-- Validation -->
						<validation-messages v-model="$v.maintenanceWindow.enabled" name="enabled"></validation-messages>
					</div>
				</div>
			</div>
			<div class="idb-block-footer">
				<button
					class="btn btn-primary"
					type="submit"
					:disabled="isLoading"
				>{{ status === "Edit" ? "Save" : status }}</button>
				<button
					class="btn btn-danger pull-right ml-3"
					type="button"
					@click="back"
					:disabled="isLoading"
				>Cancel</button>
				<button
					v-if="status === 'Edit'"
					class="btn btn-outline-danger pull-right"
					@click="deletemaintenanceWindow"
					type="button"
					:disabled="isLoading"
				>Delete</button>
			</div>
		</div>
	</form>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VueSelect from 'vue-select'

// Mixins
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import loading from '@/Assets/Mixins/LoadingMixin'

// Components
import datePicker from '@/Assets/Components/DateTimePicker/DatePicker'

// Validators
import {
	required,
	helpers
} from 'vuelidate/lib/validators'


// Functions
function paygateIdToType (paygateId) {
	if (paygateId === process.env.VUE_APP_CORVID_ID) {
		return 3
	}

	return 0
}

var Link = Quill.import('formats/link')
var builtInFunc = Link.sanitize
Link.sanitize = function customSanitizeLinkInput (linkValueInput) {
	var val = linkValueInput

	// do nothing, since this implies user's already using a custom protocol
	if (/^\w+:/.test(val));
	else if (!/^https?:/.test(val)) { val = 'http:' + val }

	return builtInFunc.call(this, val) // retain the built-in logic
}

export default {
	mixins: [DataLeaveMixin, loading],
	props: ['status', 'id'],
	components: {
		datePicker,
		VueSelect
	},
	computed: {
		customers () {
			var customers = JSON.parse(JSON.stringify(this.customersOrdered))
			customers = customers.sort((a, b) => {
				var typeA = paygateIdToType(a.paygateId)
				var typeB = paygateIdToType(b.paygateId)
				return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
			})
			customers.shift()
			return customers
		},
		...mapGetters(['customersOrdered'])
	},
	data () {
		return {
			types: ['Planned Maintenance', 'Info'],
			maintenanceWindow: {
				id: '00000000-0000-0000-0000-000000000000',
				type: 'Planned Maintenance',
				reason: null,
				description: null,
				start: null,
				end: null,
				publishDate: null,
				enabled: true,
				selectedCustomers: []
			},
			dateOptions: {
				format: 'DD/MM/YYYY HH:mm',
				useCurrent: false,
				stepping: 30,
				sideBySide: true
			},
			editor: null
		}
	},
	async mounted () {
		this.editor = new Quill(this.$refs.editor, {
			modules: {
				toolbar: {
					container: [
						[{ header: [1, 2, 3, 4, false] }],
						['bold', 'italic', 'underline'],
						[{ list: 'bullet' }, { list: 'ordered' }],
						['link', 'image']
					]
				},
				history: {
					delay: 2000,
					maxStack: 500,
					userOnly: true
				}
			},
			theme: 'snow'
		})
		var component = this
		this.editor.on('text-change', (delta, oldDelta, source) => {
			let html = this.$refs.editor.children[0].innerHTML

			if (html === '<p><br></p>' || html === '<p><br></p><p><br></p>') {
				html = ''
			}
			component.$v.maintenanceWindow.description.$model = html
		})

		if (this.status === 'Edit') {
			await this.loadMaintenanceWindow()
		} else {
			this.maintenanceWindow.start = moment().set({ minute: 0, second: 0, millisecond: 0 })
			this.maintenanceWindow.end = moment().set({ minute: 0, second: 0, millisecond: 0 }).add(1, 'hours')
			this.maintenanceWindow.publishDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		}

		this.$nextTick(() => {
			this.$v.$reset()
		})
	},
	methods: {
		async loadMaintenanceWindow () {
			try {
				var maintenanceWindowResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}MaintenanceWindow`,
					{
						params: { id: this.id },
						showload: true,
						showerror: true,
						errormessage: 'Maintenance window failed to load'
					})
				this.maintenanceWindow.id = maintenanceWindowResponse.data.id
				this.maintenanceWindow.type = maintenanceWindowResponse.data.type
				this.maintenanceWindow.reason = maintenanceWindowResponse.data.reason
				this.maintenanceWindow.description = maintenanceWindowResponse.data.description
				this.maintenanceWindow.enabled = maintenanceWindowResponse.data.enabled
				this.maintenanceWindow.selectedCustomers = maintenanceWindowResponse.data.selectedCustomers
				this.maintenanceWindow.start = moment(maintenanceWindowResponse.data.start)
				this.maintenanceWindow.end = moment(maintenanceWindowResponse.data.end)
				this.maintenanceWindow.publishDate = moment(maintenanceWindowResponse.data.publishDate)
				this.editor.pasteHTML(this.maintenanceWindow.description)
			} catch { }
		},
		async saveMaintenanceWindow () {
			this.maintenanceWindow.start = moment(this.maintenanceWindow.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss')
			this.maintenanceWindow.end = moment(this.maintenanceWindow.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss')
			this.maintenanceWindow.publishDate = moment(this.maintenanceWindow.publishDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss')
			console.log('Dates?', {
				start: this.maintenanceWindow.start,
				end: this.maintenanceWindow.end,
				publishDate: this.maintenanceWindow.publishDate
			})
			try {
				if (this.status === 'Create') {
					await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}MaintenanceWindow`, this.maintenanceWindow,
						{ showload: true, showerror: true, errormessage: 'Maintenance window failed to create' })
					this.$toastr.s('New maintenance window', 'Created')
				} else {
					await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}MaintenanceWindow`, this.maintenanceWindow,
						{ showload: true, showerror: true, errormessage: 'Maintenance window failed to save' })
					this.$toastr.s('Maintenance window updated', 'Updated')
				}
				this.$v.$reset()
				this.$router.push({ name: 'MaintenanceWindowList' })
			} finally {
				this.maintenanceWindow.start = moment(this.maintenanceWindow.start, 'YYYY-MM-DDTHH:mm:ss')
				this.maintenanceWindow.end = moment(this.maintenanceWindow.end, 'YYYY-MM-DDTHH:mm:ss')
				this.maintenanceWindow.publishDate = moment(this.maintenanceWindow.publishDate, 'YYYY-MM-DDTHH:mm:ss')
			}
		},
		async deletemaintenanceWindow () {
			try {
				await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}MaintenanceWindow`, {
					params: { id: this.id },
					showload: true,
					showerror: true,
					errormessage: 'Maintenance window failed to delete'
				})
				this.$v.$reset()
				this.$toastr.s('Deleted maintenance window', 'Deleted')
				this.$router.push({ name: 'MaintenanceWindowList' })
			} catch { }
		}
	},
	validations () {
		return {
			maintenanceWindow: {
				type: { required },
				reason: { required },
				description: { required },
				start: {
					required,
					maxDate: helpers.withParams({
						max: this.maintenanceWindow.end instanceof moment ? this.maintenanceWindow.end.format('DD/MM/YYYY HH:mm') : null
					}, value => {
						if (!(value instanceof moment)) {
							return true
						}

						return value.isBefore(this.maintenanceWindow.end)
					}),
					minDate: helpers.withParams({
						min: this.maintenanceWindow.publishDate instanceof moment ? this.maintenanceWindow.publishDate.format('DD/MM/YYYY HH:mm') : null
					}, value => {
						if (!(value instanceof moment)) {
							return true
						}

						return value.isAfter(this.maintenanceWindow.publishDate)
					})
				},
				end: {
					required,
					minDate: helpers.withParams({
						min: this.maintenanceWindow.start instanceof moment ? this.maintenanceWindow.start.format('DD/MM/YYYY HH:mm') : null
					}, value => {
						if (!(value instanceof moment)) {
							return true
						}

						return value.isAfter(this.maintenanceWindow.start)
					})
				},
				publishDate: {
					required,
					maxDate: helpers.withParams({
						max: this.maintenanceWindow.start instanceof moment ? this.maintenanceWindow.start.format('DD/MM/YYYY HH:mm') : null
					}, value => {
						if (!(value instanceof moment)) {
							return true
						}

						return value.isBefore(this.maintenanceWindow.start)
					})
				},
				selectedCustomers: {},
				enabled: {}
			}
		}
	}
}
</script>
<style>
.wysiwyg-source-view {
	width: 100%;
	margin: 0px;
	box-sizing: border-box;
	font-size: 15px;
	outline: none;
	padding: 20px;
	line-height: 24px;
	font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
	position: absolute;
	top: 0;
	bottom: 0;
	border: none;
	resize: none;
}

button.ql-editSource {
	width: 100% !important;
}

.ql-container {
	min-height: 15em;
	height: 15em;
}
</style>
