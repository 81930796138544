var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "downloadSubmission" } },
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      !_vm.loading
        ? _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "button",
                    disabled: _vm.downloadButtonDisabled
                  },
                  on: { click: _vm.downloadSubmission }
                },
                [_vm._v("Download Submission File")]
              )
            ]),
            _c("div", { staticClass: "col-md-10" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light pull-right",
                  attrs: {
                    type: "button",
                    disabled:
                      this.canCancel === false && _vm.downloadButtonDisabled
                  },
                  on: { click: _vm.cancelSubmission }
                },
                [_vm._v("Cancel Submission")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }