var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [
            _vm._v(" Mapping Detail "),
            _c("span", { staticClass: "pull-right" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      _vm.docUrl + "/automation/paygate-mapping/mappingdetail/",
                    target: "_blank"
                  }
                },
                [_c("i", { staticClass: "far fa-question-circle" })]
              )
            ]),
            _c(
              "span",
              { staticClass: "pull-right" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-3 mb-1",
                    attrs: { size: "sm", variant: "primary" },
                    on: { click: _vm.btnEditMapping }
                  },
                  [
                    _c("i", { staticClass: "glyphicon ti-pencil mr-2" }),
                    _vm._v("Edit Mapping ")
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-md-3 col-sm-12" }, [
              _vm._v(_vm._s(_vm.mapping.metaData.title))
            ]),
            _vm._m(1),
            _c("div", { staticClass: "col-md-3 col-sm-12" }, [
              _vm._v(_vm._s(_vm.mapping.metaData.description))
            ]),
            _c("div", { staticClass: "col-md-2 col-sm-12" })
          ]),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _c("div", { staticClass: "col-md-3 col-sm-12" }, [
              _vm._v(_vm._s(_vm.reformatType(_vm.mapping.metaData.type)))
            ]),
            _vm._m(3),
            _c("div", { staticClass: "col-md-3 col-sm-12" }, [
              _vm._v(_vm._s(_vm.mapping.metaData.author))
            ])
          ]),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _vm._m(4),
            _c("div", { staticClass: "col-md-3 col-sm-12" }, [
              _vm._v(_vm._s(_vm.reformatDate(_vm.mapping.creationDate)))
            ]),
            _vm._m(5),
            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
              _vm._v(_vm._s(_vm.reformatDate(_vm.mapping.lastExecutionDate)))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [
            _vm._v(" Heatmap "),
            _c(
              "span",
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the data in the heatmap",
                        expression: "'Refresh the data in the heatmap'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.getHeatmapData($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-sync-alt pointer" })]
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c("calendar-heatmap", {
              attrs: {
                "tooltip-unit": "executions",
                endDate: _vm.getHeatmapEnddate(),
                values: _vm.activityData,
                "range-color": _vm.rangeColours
              },
              on: { "day-click": _vm.heatmapClick }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", { staticClass: "col-md-6 col-sm-12" }, [
            _vm._v(" Mapping Log "),
            !_vm.showAll
              ? _c("span", [_vm._v(_vm._s(_vm.justDate(_vm.targetDay)))])
              : _vm._e()
          ])
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "vue-good-table",
              {
                ref: "triggerTelemetry",
                attrs: {
                  paginationOptions: _vm.paginationOptions,
                  "sort-options": _vm.sortOptions,
                  isLoading: _vm.isTableLoading,
                  rows: _vm.rows,
                  columns: _vm.columns,
                  mode: "remote",
                  totalRows: _vm.totalRecords,
                  lineNumbers: true,
                  styleClass: "vgt-table striped bordered table-hover"
                },
                on: {
                  "update:isLoading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "update:is-loading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "on-page-change": _vm.onPageChange,
                  "on-sort-change": _vm.onSortChange,
                  "on-column-filter": _vm.onColumnFilter,
                  "on-per-page-change": _vm.onPerPageChange,
                  "on-search": _vm.onSearch,
                  "on-row-click": _vm.onRowClick,
                  "on-cell-click": _vm.onCellClick
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "currentStatus"
                          ? _c(
                              "span",
                              [
                                props.row.currentStatus == "Failed"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "danger" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus == "Complete"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "success" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus == "Processing"
                                  ? _c(
                                      "b-badge",
                                      { attrs: { pill: "", variant: "info" } },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus ==
                                    "Completed with warnings"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "warning" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : props.row.currentStatus == "Manual Action"
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "primary" }
                                      },
                                      [_vm._v(_vm._s(props.row.currentStatus))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(props.row.currentStatus))
                                    ])
                              ],
                              1
                            )
                          : props.column.field == "createdAt"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.reformatDate(
                                    props.formattedRow[props.column.field]
                                  )
                                )
                              )
                            ])
                          : props.column.field == "updatedAt"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.reformatDate(
                                    props.formattedRow[props.column.field]
                                  )
                                )
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(props.formattedRow[props.column.field])
                              )
                            ])
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(
                  ' styleClass="vgt-table striped bordered table-hover"> '
                ),
                _c(
                  "div",
                  { attrs: { slot: "table-actions" }, slot: "table-actions" },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Clear filters",
                            expression: "'Clear filters'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "btn btn-link",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.clearTableFilters($event)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fa-stack",
                            staticStyle: { "font-size": "10px" }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                            }),
                            _c("i", {
                              staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                            })
                          ]
                        ),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Clear filters")
                        ])
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Show Today",
                            expression: "'Show Today'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.setShowToday($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-calendar pointer dimmedIcon"
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Show Table")
                        ])
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "Refresh the data in the table",
                            expression: "'Refresh the data in the table'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "link" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.load($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-sync-alt pointer dimmedIcon"
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Refresh Table")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block mb-5" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "span",
              { staticClass: "col-md-12 col-sm-12" },
              [
                _c("strong", [_vm._v("Mapping Usage")]),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the list of bound items.",
                        expression: "'Refresh the list of bound items.'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.getBindings($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-sync-alt pointer" })]
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _vm.boundGroups && _vm.boundGroups.length > 0
            ? _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c("span", [
                    _vm.boundGroups
                      ? _c(
                          "span",
                          { staticClass: "col-md-12 col-sm-12 ml-3" },
                          [
                            _c("strong", [_vm._v("Groups using this mapping")]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-primary ml-2"
                              },
                              [_vm._v(_vm._s(_vm.boundGroups.length))]
                            )
                          ]
                        )
                      : _c(
                          "span",
                          { staticClass: "col-md-12 col-sm-12 ml-3" },
                          [
                            _c("strong", [_vm._v("Groups using this mapping")]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-primary ml-2"
                              },
                              [_vm._v("0")]
                            )
                          ]
                        )
                  ])
                ]),
                _vm.boundGroups
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _vm._l(_vm.boundGroups, function(w) {
                          return _c(
                            "div",
                            {
                              key: w.index,
                              staticClass: "row",
                              attrs: { value: w.value }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-12 ml-5" },
                                [
                                  _vm._v(" " + _vm._s(w.name) + " ( "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "small",
                                      attrs: {
                                        tag: "a",
                                        to: "/admin/groups/edit/" + w.groupid
                                      }
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _vm._v(") ")
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _c("br"),
                _c("br")
              ])
            : _vm._e(),
          _vm.boundWorkflows && _vm.boundWorkflows.length > 0
            ? _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c("span", [
                    _vm.boundWorkflows
                      ? _c(
                          "span",
                          { staticClass: "col-md-12 col-sm-12 ml-3" },
                          [
                            _c("strong", [
                              _vm._v("Workflows using this mapping")
                            ]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-primary ml-2"
                              },
                              [_vm._v(_vm._s(_vm.boundWorkflows.length))]
                            )
                          ]
                        )
                      : _c(
                          "span",
                          { staticClass: "col-md-12 col-sm-12 ml-3" },
                          [
                            _c("strong", [
                              _vm._v("Workflows using this mapping")
                            ]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-primary ml-2"
                              },
                              [_vm._v("0")]
                            )
                          ]
                        )
                  ])
                ]),
                _vm.boundWorkflows
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _vm._l(_vm.boundWorkflows, function(w) {
                          return _c(
                            "div",
                            {
                              key: w.index,
                              staticClass: "row",
                              attrs: { value: w.value }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-12 ml-5" },
                                [
                                  _vm._v(
                                    " " + _vm._s(w.metaData.title) + " ( "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "small",
                                      attrs: {
                                        tag: "a",
                                        to:
                                          "/automation/workflow/workflowdetail/" +
                                          w._id
                                      }
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _vm._v(") ")
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _c("br"),
                _c("br")
              ])
            : _vm._e(),
          _vm.boundBureauCustomers && _vm.boundBureauCustomers.length > 0
            ? _c("div", [
                _vm.isBureauLicenced
                  ? _c("div", { staticClass: "row" }, [
                      _c("span", [
                        _vm.boundBureauCustomers && _vm.boundBureauCustomers
                          ? _c(
                              "span",
                              { staticClass: "col-md-12 col-sm-12 ml-3" },
                              [
                                _c("strong", [
                                  _vm._v("Bureau Customers using this mapping")
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-pill badge-primary ml-2"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.boundBureauCustomers.length)
                                    )
                                  ]
                                )
                              ]
                            )
                          : _c(
                              "span",
                              { staticClass: "col-md-12 col-sm-12 ml-3" },
                              [
                                _c("strong", [
                                  _vm._v("Bureau Customers using this mapping")
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-pill badge-primary ml-2"
                                  },
                                  [_vm._v("0")]
                                )
                              ]
                            )
                      ])
                    ])
                  : _vm._e(),
                _vm.isBureauLicenced &&
                _vm.boundBureauCustomers &&
                _vm.boundBureauCustomers
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _vm._l(_vm.boundBureauCustomers, function(w) {
                          return _c(
                            "div",
                            {
                              key: w.index,
                              staticClass: "row",
                              attrs: { value: w.name }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-12 ml-5" },
                                [
                                  _vm._v(" " + _vm._s(w.name) + " ( "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "small",
                                      attrs: {
                                        tag: "a",
                                        to:
                                          "/bureau/bureauCustomers/details/" +
                                          w.bureaucustomerid
                                      }
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _vm._v(") ")
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _c("br"),
                _c("br")
              ])
            : _vm._e(),
          _vm.boundBureauJobs && _vm.boundBureauJobs.length > 0
            ? _c("div", [
                _vm.isBureauLicenced
                  ? _c("div", { staticClass: "row" }, [
                      _c("span", [
                        _vm.boundBureauJobs && _vm.boundBureauJobs
                          ? _c(
                              "span",
                              { staticClass: "col-md-12 col-sm-12 ml-3" },
                              [
                                _c("strong", [
                                  _vm._v("Bureau Jobs using this mapping")
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-pill badge-primary ml-2"
                                  },
                                  [_vm._v(_vm._s(_vm.boundBureauJobs.length))]
                                )
                              ]
                            )
                          : _c(
                              "span",
                              { staticClass: "col-md-12 col-sm-12 ml-3" },
                              [
                                _c("strong", [
                                  _vm._v("Bureau Jobs using this mapping")
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-pill badge-primary ml-2"
                                  },
                                  [_vm._v("0")]
                                )
                              ]
                            )
                      ])
                    ])
                  : _vm._e(),
                _vm.isBureauLicenced && _vm.boundBureauJobs
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _vm._l(_vm.boundBureauJobs, function(w) {
                          return _c(
                            "div",
                            {
                              key: w.index,
                              staticClass: "row",
                              attrs: { value: w.name }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-12 ml-5" },
                                [
                                  _vm._v(" " + _vm._s(w.name) + " ( "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "small",
                                      attrs: {
                                        tag: "a",
                                        to:
                                          "/bureau/bureaujobs/manage/" +
                                          w.bureaujobid
                                      }
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _vm._v(") ")
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _c("br")
        ]),
        _c(
          "div",
          { staticClass: "idb-block-footer" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "primary" },
                on: { click: _vm.btnEditMapping }
              },
              [
                _c("i", { staticClass: "glyphicon ti-pencil mr-2" }),
                _vm._v("Edit Mapping ")
              ]
            ),
            _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
              _vm._m(6),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "btnGroupDrop1" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: { click: _vm.btnCloneMapping }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-files mr-2" }),
                      _vm._v("Clone Mapping ")
                    ]
                  ),
                  _vm.isCorvidAdmin
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: { click: _vm.btnTransferMapping }
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon ti-new-window mr-2"
                          }),
                          _vm._v("Copy Mapping to Another Customer ")
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "dropdown-divider" }),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item text-warning",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.btnDeleteMapping($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-trash mr-2" }),
                      _vm._v("Delete Mapping ")
                    ]
                  )
                ]
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Title")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Description")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Mapping Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Author")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Creation Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Last Executed Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-outline-primary dropdown-toggle ml-3",
        attrs: {
          id: "btnGroupDrop1",
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "glyphicon ti-more mr-2" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }