<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Actions
          <help-icon :docPath="'/common/actions'" />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
              <span class="sr-only">Clear filters</span>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'icon'">
              <span>
                <i class="fa" :class="getIcon(props.row)"></i>
              </span>
            </span>
            <span v-else-if="props.column.field == 'createdBy'">
              <avatar
                :key="props.row.createdBy"
                class="rounded-circle"
                width="30"
                height="30"
                v-b-popover.hover="props.row.createdByName"
                :user-id="props.row.createdBy"
              ></avatar>
            </span>
            <span v-else-if="props.column.field == 'groupName'">
              <div class="group-colour" :style="{'background-color':props.row.groupColour}"></div>
              <span>{{props.formattedRow[props.column.field]}}</span>
            </span>
            <span v-else>{{props.formattedRow[props.column.field]}}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { returnRouteObject, getIcon } from '@/Assets/Components/Actions/actionsHelper'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      platformApi: process.env.VUE_APP_PLATFORM_API_URL,
      rows: [],
      columns: [
        {
          label: '',
          field: 'icon'
        },
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Group Name',
          field: 'groupName',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Message',
          field: 'message',
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Created Date',
          field: 'createdDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: 'Created By',
          field: 'createdBy'
        }]
    }
  },
  created () {
    this.load()
  },
  methods: {
    onRowClick (params) {
      this.$router.push(returnRouteObject(params.row))
    },
    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading actions'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    getIcon
  }
}
</script>

<style scoped>
.fa-info-circle {
  color: rgb(100, 100, 100);
}
</style>
