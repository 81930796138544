<template>
  <div>
    <folderBrowser v-if="showFromFolderBrowser" @close="showFromFolderBrowser = false; closeFromFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showFromFileBrowser" @close="showFromFileBrowser = false; closeFromFilePopup()">
    </fileBrowser>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()">
    </fileBrowser>
    <h3>
      BACS Report Mapping
      <span class="pull-right">
        <a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/bacsautomation/reportmapping/`" target="_blank">
          <i class="far fa-question-circle"></i>
        </a>
      </span>
    </h3>
    <hr />
    <br />
    <h4>Source XML BACS Report</h4>
    <label class="required">Filename</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFromFileBrowser" type="button">...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty "
    >The filename field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <br />
    <label class="required">Folder</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value" />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          @click.prevent="btnFromFolderBrowser"
          type="button">...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty "
    >The Path field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s2.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    <br />
    <h4>Destination Mapped File</h4>
    <!-- <label class="required">Filename</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button">...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty "
    >The filename field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    <br /> -->
    <label class="required">Save Folder</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s4.value.$error}"
        @input="$v.selectedNode.props.s4.value.$touch()"
        v-model="selectedNode.props.s4.value" />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          @click.prevent="btnFolderBrowser"
          type="button">...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s4.value.required && $v.selectedNode.props.s4.value.$dirty "
    >The Path field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s4.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s4.value.$params.maxLength.max }} characters!</p>
    <br />
    <!-- <h4>Mapping Type</h4>
    <div class="form-group">
      <div class="row">
        <div class="col-md-3">
          <p-radio value="Default" color="primary" v-model="selectedNode.props.s5.value">Default</p-radio>
          <p-radio value="Custom" color="primary" disabled="true" v-model="selectedNode.props.s5.value">Custom</p-radio>
        </div>
        <div class="col-md-2" v-if="selectedNode.props.s5.value === 'Custom'">Custom Mapping</div>
        <div class="col-md-3" v-if="selectedNode.props.s5.value === 'Custom'">
          <select class="form-control"
            v-model="selectedNode.props.s6.value">
            <option v-for="o in mappingOptions" :key="o.id" :value="o.id">
              {{o.title}}
            </option>
          </select>
          <p
            class="validationText"
            v-if="!$v.selectedNode.props.s6.value.required "
          >Please select a mapping</p>
        </div>
      </div>
    </div> -->
    <br />
    <hr />
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">Cancel</b-button>
      <b-button class variant="success" @click.prevent="btnSave">OK</b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from '../../Engine/workflowDesigner.js'
import axios from 'axios'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        // s3: {
        //   value: {
        //     required,
        //     maxLength: maxLength(128)
        //   }
        // },
        s4: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s6: {
          value: {
            required: requiredIf(function () {
              return this.selectedNode.props.s5.value === 'Custom'
            }),
            maxLength: maxLength(128)
          }
        }
      }
    }
  },
  data () {
    return {
      showFromFolderBrowser: false,
      showFromFileBrowser: false,
      showFolderBrowser: false,
      showFileBrowser: false,
      mappingOptions: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s3.value = utils.sanitisePath(this.selectedNode.props.s3.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFromFileBrowser () {
      this.property = this.selectedNode.props.s1.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s1.value)
      this.showFromFileBrowser = true
    },
    closeFromFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s1.value = filename
      if (!this.selectedNode.props.s2.value) {
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s2.value = folder
      }
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s3.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showFileBrowser = true
    },
    closeFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s3.value = filename
      if (!this.selectedNode.props.s4.value) {
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s4.value = folder
      }
    },
    btnFromFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showFromFolderBrowser = true
    },
    btnFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s4.value)
      this.showFolderBrowser = true
    },
    closeFromFolderPopup () {
      console.log('closeFromFolderPopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s2.value = this.$store.state.mapping.modalPopupString
    },
    closeFolderPopup () {
      console.log('closeFolderPopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s4.value = this.$store.state.mapping.modalPopupString
    }
  },
  created: async function () {
    // let mappingResponse
    // try {
    //   mappingResponse = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mappingtitles`)
    //   if (mappingResponse) {
    //     for (const mapping of mappingResponse.data.mappings) {
    //       this.mappingOptions.push({
    //         title: mapping.title,
    //         id: mapping._id
    //       })
    //     }
    //     console.log(this.mappingOptions)
    //   }
    // } catch (e) {
    //   this.$snapbar.e('Error getting list of available mappings')
    // }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
