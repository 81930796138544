var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " Submission Details for '" +
              _vm._s(_vm.bureauJob.bureauJobName) +
              "' "
          ),
          _c("help-icon", { attrs: { docPath: "/bacsbureau/jobs/" } }),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _vm.badCustomersAvailable
          ? _c(
              "b-card",
              {
                staticStyle: {
                  "margin-bottom": "0.8em",
                  "margin-right": "0.8em"
                },
                attrs: { "bg-variant": "warning", "text-variant": "white" }
              },
              [
                _vm._v(
                  "This bureau job has associated customers with no bank details set. This bureau job will fail to import with the current configuration."
                )
              ]
            )
          : _vm._e(),
        _c(
          "b-row",
          { staticClass: "mt-2" },
          [
            _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
              _c(
                "label",
                {
                  staticClass: "required",
                  attrs: { for: "submissionReference" }
                },
                [_vm._v("Submission Reference")]
              )
            ]),
            _c(
              "b-col",
              { attrs: { sm: "6" } },
              [
                _c("b-form-input", {
                  attrs: { id: "submissionReference", type: "text" },
                  model: {
                    value: _vm.$v.submissionReference.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.submissionReference,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.submissionReference.$model"
                  }
                })
              ],
              1
            ),
            _vm.$v.submissionReference.$dirty
              ? _c(
                  "b-col",
                  { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                  [
                    !_vm.$v.submissionReference.required
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "submissionReferenceMissing" }
                          },
                          [_vm._v("A 'Submission Reference' is required")]
                        )
                      : _vm._e(),
                    !_vm.$v.submissionReference.maxLength
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "submissionReferenceTooLong" }
                          },
                          [
                            _vm._v(
                              "A 'Submission Reference' must be less than " +
                                _vm._s(
                                  _vm.$v.submissionReference.$params.maxLength
                                    .max
                                ) +
                                " characters"
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "mt-2" },
          [
            _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
              _c("label", { attrs: { for: "contraNarrative" } }, [
                _vm._v("Contra Narrative")
              ])
            ]),
            _c(
              "b-col",
              { attrs: { sm: "6" } },
              [
                _c("b-form-input", {
                  attrs: { id: "contraNarrative", type: "text" },
                  model: {
                    value: _vm.$v.contraNarrative.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.contraNarrative,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.contraNarrative.$model"
                  }
                })
              ],
              1
            ),
            _vm.$v.contraNarrative.$dirty
              ? _c(
                  "b-col",
                  { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                  [
                    !_vm.$v.contraNarrative.maxLength
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "contraNarrativeTooLong" }
                          },
                          [
                            _vm._v(
                              "A 'Contra Narrative' must be less than " +
                                _vm._s(
                                  _vm.$v.contraNarrative.$params.maxLength.max
                                ) +
                                " characters"
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        !_vm.isFps
          ? _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                  _c("label", { attrs: { for: "paymentDate" } }, [
                    _vm._v("Payment Date")
                  ])
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("vuejsDatepicker", {
                      ref: "paymentDate",
                      attrs: {
                        name: "paymentDate",
                        id: "paymentDate",
                        format: "dd/MM/yyyy",
                        "input-class": "form-control",
                        "bootstrap-styling": true,
                        disabledDates: _vm.disabledDates,
                        "monday-first": true,
                        disabled: _vm.disableDatePicker
                      },
                      on: { selected: _vm.paymentDateChanged },
                      nativeOn: {
                        focusin: function($event) {
                          return _vm.dateOnfocus($event)
                        }
                      },
                      model: {
                        value: _vm.paymentDate,
                        callback: function($$v) {
                          _vm.paymentDate = $$v
                        },
                        expression: "paymentDate"
                      }
                    }),
                    _c("small", [
                      _vm._v(
                        "This is a default date for the submission that can be overridden for individual blocks."
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.isFps
          ? _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                  _c("label", { attrs: { for: "processingDate" } }, [
                    _vm._v("Processing Date")
                  ])
                ]),
                _c("b-col", { attrs: { id: "processingDate", sm: "3" } }, [
                  _vm._v(_vm._s(_vm.processingDate))
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.finishedLoading && _vm.bureauJob.bureauJobDetailLines.length === 0
          ? _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                  _c(
                    "small",
                    {
                      staticClass: "form-text text-danger small",
                      attrs: { id: "noDetailLines" }
                    },
                    [_vm._v("This job has no detail lines and will not run")]
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "idb-block-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary mr-2",
          attrs: {
            id: "nextButton",
            disabled:
              _vm.$v.$invalid ||
              _vm.isTableLoading ||
              _vm.bureauJob.bureauJobDetailLines.length === 0 ||
              _vm.subInProcess ||
              _vm.badCustomersAvailable
          },
          on: { click: _vm.onNextClick }
        },
        [_vm._v("Next")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }