var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "editor" }),
    _c("textarea", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.sourceHidden,
          expression: "!sourceHidden"
        },
        {
          name: "model",
          rawName: "v-model",
          value: _vm.content,
          expression: "content"
        }
      ],
      ref: "sourceView",
      staticClass: "wysiwyg-source-view",
      domProps: { value: _vm.content },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.content = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }