var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _vm.messageError
        ? _c("h2", { staticClass: "idb-block-content" }, [
            _vm._v("Cannot load message.")
          ])
        : _vm._e(),
      !_vm.messageError && !_vm.message
        ? _c("h2", { staticClass: "idb-block-content" }, [
            _vm._v("Loading message.")
          ])
        : _vm._e(),
      _vm.channel === "0"
        ? _c("div", [
            _vm.message && _vm.messageTitle
              ? _c("div", { staticClass: "idb-block-title" }, [
                  _c(
                    "h2",
                    { staticStyle: { "margin-bottom": "5px" } },
                    [
                      _c("help-icon", {
                        attrs: {
                          docPath:
                            "/paygate-collections/messaging/messagequeue/"
                        }
                      }),
                      _vm._v("Message Title")
                    ],
                    1
                  ),
                  _c("h4", [_vm._v(_vm._s(_vm.messageTitle))])
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "idb-block-content",
              domProps: { innerHTML: _vm._s(_vm.message && _vm.messageBody) }
            })
          ])
        : _vm.channel === "1"
        ? _c(
            "div",
            [
              _vm._m(0),
              _c(
                "b-row",
                { staticClass: "idb-block-content" },
                [
                  _c("b-col", { staticStyle: { width: "105% !important" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-top": "110%",
                          position: "relative"
                        }
                      },
                      [
                        _vm.message
                          ? _c("iframe", {
                              ref: "previewFrame",
                              staticStyle: {
                                position: "absolute",
                                top: "0",
                                left: "0"
                              },
                              attrs: {
                                src: _vm.previewUrl,
                                frameborder: "0",
                                height: "100%",
                                width: "100%"
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm.channel === "2"
        ? _c("div", [
            _vm.message && _vm.senderName
              ? _c("div", { staticClass: "idb-block-title" }, [
                  _vm._m(1),
                  _c("h4", [_vm._v(_vm._s(_vm.senderName))])
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "idb-block-content",
              domProps: { innerHTML: _vm._s(_vm.message && _vm.messageBody) }
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "idb-block-footer" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { attrs: { id: "title-of-block-check" } }, [
        _c("span", { staticClass: "pull-right" }, [
          _c("a", { attrs: { href: "#", target: "_blank" } }, [
            _c("i", { staticClass: "far fa-question-circle" })
          ])
        ]),
        _vm._v("View Letter Message")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticStyle: { "margin-bottom": "5px" } }, [
      _c("span", { staticClass: "pull-right" }, [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("i", { staticClass: "far fa-question-circle" })
        ])
      ]),
      _vm._v("Sender Name")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }