import axios from 'axios'
// default state
const state = {
  payers: [],
  currentPayer: {
    editable: true,
    status: 4,
    groupId: 0,
    reference: '',
    payerMessageType: 1,
    ukPayerId: null,
    historicPlanCount: 0,
    emailVerified: false,
    payerPortalId: null,
    accessedPortal: false,
    smsVerified: false,
    verifiedMobileNumber: null,
    payerDetails: {
      title: 0,
      firstName: '',
      surname: '',
      tel1: '',
      tel2: '',
      mobile: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      postcode: '',
      town: '',
      county: '',
      lat: 0,
      lon: 0,
      email: ''
    },
    payerStaging: {
      payerDetailsValidated: true,
      paymentPlansValidated: true,
      bankAccountValidated: true
    },
    paymentPlans: [],
    bankAccountDetails: {
      validateApiResult: null,
      accountHoldersName: '',
      sortcode: '',
      accountNumber: '',
      dataModified: false,
      validated: false
    }
  },
  planNames: [],
  inactivePayers: [],
  formValid: false,
  newRefRequired: false,
  useAutoGenerateRef: false,
  createCompleted: {
    addressCompleted: false,
    detailsCompleted: false,
    bankCompleted: false,
    planCompleted: false
  },
  addAnother: false,
  detailsValidationMessage: '',
  bankingValidationMessage: '',
  plansValidationMessage: ''
}

// mutators
const mutations = {
  setCurrentPayerAddress (state, { address1, address2, address3, address4, postcode, town, county }) {
    state.currentPayer.payerDetails.address1 = address1
    state.currentPayer.payerDetails.address2 = address2
    state.currentPayer.payerDetails.address3 = address3
    state.currentPayer.payerDetails.address4 = address4
    state.currentPayer.payerDetails.postcode = postcode
    state.currentPayer.payerDetails.town = town
    state.currentPayer.payerDetails.county = county
  },
  setPayer (state, response) {
    state.currentPayer = response.data
  },
  deletePayerFromActive (state, payer) {
    state.payers = state.payers.filter(p => p.ukPayerId !== payer)
  },
  deletePayer (state, payer) {
    state.payers = state.payers.filter(p => p.ukPayerId !== payer)
    state.inactivePayers = state.inactivePayers.filter(p => p.ukPayerId !== payer)
  },
  setFormValid (state, validity) {
    state.formValid = validity
  },
  setPayerPlan (state, { isValid }) {
    state.currentPayer.planValid = isValid
    state.formValid = state.currentPayer.detailsValid && state.currentPayer.bankingValid && state.currentPayer.planValid
  },
  setPayerBanking (state, { validateApiResult, isValid }) {
    console.log('setting validateApiResult', validateApiResult, 'isValid? ', isValid)
    console.log(state.currentPayer.bankAccountDetails)
    state.currentPayer.bankAccountDetails.validateApiResult = validateApiResult
    state.currentPayer.bankingValid = isValid
    state.formValid = state.currentPayer.detailsValid && state.currentPayer.bankingValid && state.currentPayer.planValid
    state.currentPayer.bankAccountDetails.valid = isValid
  },
  setPayerDetails (state, isValid) {
    state.currentPayer.payerStaging.payerDetailsValidated = isValid
  },
  setPayers (state, payers) {
    state.payers = payers
  },
  setNewPayer (state, payer) {
    state.inactivePayers.push(payer)
  },
  setInactivePayers (state, payers) {
    state.inactivePayers = payers
  },
  setActivationConfirmed (state, ukPayerId) {
    state.inactivePayers = state.inactivePayers.filter(x => x.ukPayerId !== ukPayerId)
  },
  setCurrentPayerStaging (state, stagingDetailsResponse) {
    const { ukPayerId, payer, ...details } = stagingDetailsResponse
    state.currentPayer.payerStaging = details
    state.currentPayer.editable = state.currentPayer.status !== 3
  },
  setPayerFromUkPayer (state, ukPayer) {
    state.currentPayer.editable = ukPayer.status !== 3
    state.currentPayer.groupId = ukPayer.groupId
    state.currentPayer.ukPayerId = ukPayer.ukPayerId
    state.currentPayer.reference = ukPayer.reference
    state.currentPayer.payerMessageType = ukPayer.payerMessageType
    state.currentPayer.status = ukPayer.status
    state.currentPayer.historicPlanCount = ukPayer.historicPlanCount
    state.currentPayer.emailVerified = ukPayer.emailVerified
    state.currentPayer.payerPortalId = ukPayer.payerPortalId
    state.currentPayer.accessedPortal = ukPayer.accessedPortal
    state.currentPayer.smsVerified = ukPayer.smsVerified
    state.currentPayer.verifiedMobileNumber = ukPayer.verifiedMobileNumber
    const plans = ukPayer.plans || []
    state.currentPayer.paymentPlans = plans.map((paymentPlan) => {
      return {
        paymentPlanId: paymentPlan.paymentPlanId,
        reference: paymentPlan.reference,
        planTemplateName: paymentPlan.planTemplateName,
        recurrenceEndType: paymentPlan.recurrenceEndType,
        recurrenceType: paymentPlan.recurrenceType,
        firstPaymentDate: paymentPlan.firstPaymentDate,
        firstAmount: paymentPlan.firstAmount || 0,
        regularAmount: paymentPlan.regularAmount || 0,
        lastAmount: paymentPlan.lastAmount || 0,
        ordinal: paymentPlan.ordinal,
        untilDate: paymentPlan.untilDate,
        step: paymentPlan.step,
        groupId: ukPayer.groupId,
        giftAid: paymentPlan.giftAid,
        totalPayments: paymentPlan.totalPayments || 0,
        variable: !!paymentPlan.variable,
        planStatus: paymentPlan.planStatus,
        planTemplateId: paymentPlan.planTemplateId,
        futurePlanChanges: paymentPlan.futurePlanChanges.map((futureChange) => {
          return {
            ukddmsFuturePlanChangeId: futureChange.ukddmsFuturePlanChangeId,
            planId: futureChange.planId,
            changeAmount: futureChange.changeAmount,
            startDate: futureChange.startDate,
            applied: futureChange.applied,
            appliedDate: futureChange.appliedDate,
            planTemplateId: futureChange.planTemplateId,
            changeType: futureChange.changeType,
            orderIndex: futureChange.orderIndex,
            triggerType: futureChange.triggerType,
            paymentsMade: futureChange.paymentsMade
          }
        }),
        customerBankAccountId: paymentPlan.customerBankAccountId,
        indirectSun: paymentPlan.indirectSun,
        contraNarrative: paymentPlan.contraNarrative
      }
    })
    if (!ukPayer.bankAccount) { ukPayer.bankAccount = ukPayer.bankAccountDetails }
    const bankAccount = ukPayer.bankAccount || {}
    state.currentPayer.bankAccountDetails = {
      accountHoldersName: bankAccount.accountHoldersName === '' || bankAccount.accountHoldersName === null ? ukPayer.payerDetails.firstName + ' ' + ukPayer.payerDetails.surname : bankAccount.accountHoldersName,
      sortcode: bankAccount.sortCode,
      accountNumber: bankAccount.accountNumber,
      validateApiResult: null,
      dataModified: false,
      validated: bankAccount.validated
    }
    const details = ukPayer.payerDetails || {}
    const { ukPayerId, ukPayerDetailsId, ...payerDetails } = details
    state.currentPayer.payerDetails = payerDetails
    // state.currentPayer.payerDetails.firstName = firstName
    state.currentPayer.status = ukPayer.status
    const staging = ukPayer.payerStaging || {}
    state.currentPayer.payerStaging.bankAccountValidated = staging.bankAccountValidated
    state.currentPayer.payerStaging.paymentPlansValidated = staging.paymentPlansValidated
    state.currentPayer.payerStaging.payerDetailsValidated = staging.payerDetailsValidated
  },
  /* Bank Details */
  updateAccountHoldersName (state, value) {
    state.currentPayer.bankAccountDetails.accountHoldersName = value
  },
  updateSortcode (state, value) {
    state.currentPayer.bankAccountDetails.sortcode = value
  },
  updateAccountNumber (state, value) {
    state.currentPayer.bankAccountDetails.accountNumber = value
  },
  updateGroup (state, value) { state.currentPayer.groupId = value },
  /* Payment Plan */
  updaterecurrenceEndType (state, value) { state.currentPayer.paymentPlan.recurrenceEndType = value },
  updaterecurrenceType (state, value) { state.currentPayer.paymentPlan.recurrenceType = value },
  updateFirstPaymentDate (state, value) { state.currentPayer.paymentPlan.firstPaymentDate = value },
  updatefirstAmount (state, value) { state.currentPayer.paymentPlan.firstAmount = value },
  updateregularAmount (state, value) { state.currentPayer.paymentPlan.regularAmount = value },
  updatelastAmount (state, value) { state.currentPayer.paymentPlan.lastmount = value },
  updateOrdinal (state, value) { state.currentPayer.paymentPlan.ordinal = value },
  updateStep (state, value) { state.currentPayer.paymentPlan.step = value },
  updateGiftAid (state, value) { state.currentPayer.paymentPlan.giftAid = value },
  updateVariableAmountEnabled (state, value) { state.currentPayer.paymentPlan.variable = value },
  /* Payer Details */
  updateTitle (state, value) {
    state.currentPayer.payerDetails.title = value
  },
  updateFirstName (state, value) {
    state.currentPayer.payerDetails.firstName = value
  },
  updateSmsVerified (state, value) {
    state.currentPayer.smsVerified = value
  },
  updateVerifiedNumber (state, value) {
    state.currentPayer.verifiedMobileNumber = value
  },
  updateSurname (state, value) { state.currentPayer.payerDetails.surname = value },
  updatePayerReference (state, value) {
    state.reference = value
    state.currentPayer.reference = value
  },
  updateTel1 (state, value) { state.currentPayer.payerDetails.tel1 = value },
  updateTel2 (state, value) { state.currentPayer.payerDetails.tel2 = value },
  updateMobile (state, value) { state.currentPayer.payerDetails.mobile = value },
  updateAddress1 (state, value) { state.currentPayer.payerDetails.address1 = value },
  updateAddress2 (state, value) { state.currentPayer.payerDetails.address2 = value },
  updateAddress3 (state, value) { state.currentPayer.payerDetails.address3 = value },
  updateAddress4 (state, value) { state.currentPayer.payerDetails.address4 = value },
  updatePostcode (state, value) { state.currentPayer.payerDetails.postcode = value },
  updateLat (state, value) { state.currentPayer.payerDetails.lat = value },
  updateLon (state, value) { state.currentPayer.payerDetails.lon = value },
  updateTown (state, value) { state.currentPayer.payerDetails.town = value },
  updateCounty (state, value) { state.currentPayer.payerDetails.county = value },
  updateEmail (state, value) { state.currentPayer.payerDetails.email = value },
  updateStatus (state, value) { state.currentPayer.status = value },
  setPlanNames (state, value) {
    state.planNames = value
  },
  setGroupId (state, value) {
    state.currentPayer.groupId = value
    state.groupId = value
  },
  setNewRefRequired (state, value) {
    state.newRefRequired = value
  },
  setPayerId (state, value) {
    state.currentPayer.ukPayerId = value
    state.currentPayer.payerDetails.ukPayerId = value
    state.currentPayer.bankAccountDetails.ukPayerId = value
    for (var i = 0; i < state.currentPayer.paymentPlans.length; i++) {
      state.currentPayer.paymentPlans[i].ukPayerId = value
    }
  },
  setPayerStatus (state, value) {
    state.currentPayer.status = value
  },
  setUseAutoGenerateRef (state, value) {
    state.useAutoGenerateRef = value
  },
  setDetailsCompleted (state, value) {
    state.createCompleted.detailsCompleted = value
  },
  setAddressCompleted (state, value) {
    state.createCompleted.addressCompleted = value
  },
  setBankCompleted (state, value) {
    state.createCompleted.bankCompleted = value
  },
  setPlanCompleted (state, value) {
    state.createCompleted.planCompleted = value
  },
  updateAddAnother (state, value) {
    state.addAnother = value
  },
  setBankingValidationMessage (state, value) {
    state.bankingValidationMessage = value
  },
  setPlanValidationMessage (state, value) {
    state.plansValidationMessage = value
  },
  setDetailsValidationMessage (state, value) {
    state.detailsValidationMessage = value
  },
  setCurrent (state, value) {
    state.currentPayer = value
  }
}

// getters
const getters = {
  currentPayer: (state) => {
    return state.currentPayer
  },
  payers: (state) => {
    return state.payers
  },
  inactivePayers: (state) => {
    return state.inactivePayers
  },
  planNames: (state) => {
    return state.planNames
  },
  createCompleted: (state) => {
    var c = state.createCompleted
    console.log('create completed object', c)
    return c.detailsCompleted && c.addressCompleted && c.bankCompleted && c.planCompleted
  },
  checkPlanValidity: (state) => {
    var valid = true
    if (state.currentPayer.paymentPlans.length > 0) {
      for (var i = 0; i < state.currentPayer.paymentPlans.length; i++) {
        var plan = state.currentPayer.paymentPlans[i]
        if (!plan.variable && plan.regularAmount === '0' && plan.recurrenceEndType !== 4) {
          valid = false
        }
      }
    }
    return valid
  },
  checkPlanIsAdHoc: (state) => {
    var adHoc = false
    if (state.currentPayer.paymentPlans.length > 0) {
      for (var i = 0; i < state.currentPayer.paymentPlans.length; i++) {
        var plan = state.currentPayer.paymentPlans[i]
        if (plan.recurrenceEndType === 4) {
          adHoc = true
        }
      }
    }
    return adHoc
  },
  getbankingValidationMessage: (state) => {
    return state.bankingValidationMessage
  }
}

// actions
const actions = {
  blankPayer: async ({ commit }) => {
    commit('setCurrent', {
      editable: true,
      status: 4,
      groupId: 0,
      reference: '',
      payerMessageType: 1,
      ukPayerId: null,
      historicPlanCount: 0,
      emailVerified: false,
      payerPortalId: null,
      accessedPortal: false,
      smsVerified: false,
      verifiedMobileNumber: null,
      payerDetails: {
        title: 0,
        firstName: '',
        surname: '',
        tel1: '',
        tel2: '',
        mobile: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        postcode: '',
        town: '',
        county: '',
        lat: 0,
        lon: 0,
        email: ''
      },
      payerStaging: {
        payerDetailsValidated: true,
        paymentPlansValidated: true,
        bankAccountValidated: true
      },
      paymentPlans: [],
      bankAccountDetails: {
        validateApiResult: null,
        accountHoldersName: '',
        sortcode: '',
        accountNumber: '',
        dataModified: false,
        validated: false
      }
    })
  },
  setCurrentDDMSPayer: async ({ commit }, payer) => {
    commit('setPayer', payer)
  },
  setBlankPayer: async ({ commit }, groupId) => {
    commit('setPayerFromUkPayer', {
      editable: true,
      status: 4,
      statusReason: 0,
      groupId: groupId,
      reference: '',
      payerMessageType: 1,
      ukPayerId: null,
      historicPlanCount: 0,
      payerPortalId: null,
      emailVerified: false,
      payerDetails: {
        title: 0,
        firstName: '',
        surname: '',
        tel1: '',
        tel2: '',
        mobile: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        postcode: '',
        town: '',
        county: '',
        lat: 0,
        lon: 0,
        email: ''
      },
      payerStaging: {
        payerDetailsValidated: false,
        paymentPlansValidated: false,
        bankAccountValidated: false
      },
      paymentPlans: [],
      bankAccountDetails: {
        validateApiResult: null,
        accountHoldersName: '',
        sortcode: '',
        accountNumber: '',
        dataModified: false
      }
    })
  },
  setCurrentDDMSPayerWithId: async ({ commit, rootState }, { id }) => {
    const params = {}
    const paygateId = rootState.common.paygateId
    if (paygateId) {
      params.paygateId = paygateId
    }

    const payerRequest = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/` + id, { params, showload: true })
    commit('setPayerFromUkPayer', payerRequest.data)
  },
  setCurrentPayerPlan: async ({ commit }, isValid) => {
    commit('setPayerPlan', isValid)
  },
  setCurrentPayerDetails: async ({ commit }, isValid) => {
    commit('setPayerDetails', isValid)
  },
  setCurrentPayerBankingDetails: async ({ commit, rootState, state }, { accountNumber, sortCode, isValid }) => {
    commit('setPayerBanking', { validateApiResult: null, isValid })
    if (isValid) {
      let url = `${process.env.VUE_APP_DDMS_API_URL}payer/validatebank`
      const validationBody = {
        sortCode,
        accountNumber
      }
      const response = await axios.post(url, validationBody, { showload: true })
      isValid = isValid && response.data.supportsDdi && response.data.valid
      commit('setPayerBanking', { validateApiResult: response.data, isValid })
    }
  },
  savePayer: async ({ rootState, state, commit }) => {
    const currentPayer = state.currentPayer
    const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer`, currentPayer, { params: { paygateid: rootState.common.paygateId }, showload: true })
    commit('setCurrentPayerStaging', response.data)
    return response
  },
  createNewPayer: async ({ state, commit, rootState }, { groupId, reference }) => {
    const result = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}${groupId}/payer`, { paygateId: rootState.common.paygateId, reference }, { params: { paygateid: rootState.common.paygateId }, showload: true })
    return result.data
  },
  createPayer: async ({ state, commit }, payer) => {
    const currentPayer = state.currentPayer
    currentPayer.groupId = payer.groupId
    currentPayer.paygateId = payer.paygateId
    try {
      const updatedPayer = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer`, currentPayer, { showload: true })
      commit('setPayer', updatedPayer)
    } catch (e) {
      alert(e)
    }
  },
  deletePayer: async ({ commit }, payer) => {
    await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}payer/${payer}`, { showload: true })
    commit('deletePayer', payer)
  },
  updatePayer: async ({ rootState, state, commit }, payer) => {
    // var paygateId = rootState.common.paygateId
    console.log(payer)
    console.log(state)
    const currentPayer = state.currentPayer
    currentPayer.groupId = payer.groupid
    currentPayer.paygateId = payer.paygateId
    const updatedPayer = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}payer/` + currentPayer.ukPayerId, currentPayer, { params: { paygateid: rootState.common.paygateId }, showload: true })
    commit('setPayerFromUkPayer', updatedPayer.data)
  },
  getPayerList: async ({ rootState, commit }, { groupId, serverParams }) => {
    serverParams.paygateid = rootState.common.paygateId
    const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}${groupId}/payer`, { params: serverParams, showload: true })
    commit('setPayers', response.data.data)
    return response.data
  },
  getAllPayersPaged: async ({ commit }, { serverParams }) => {
    const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payers`, { params: serverParams, showLoad: true })
    commit('setInactivePayers', response.data.data)
    return response.data
  },
  getInactivePayerList: async ({ rootState, commit }, { groupId, serverParams }) => {
    serverParams.paygateId = rootState.common.paygateId
    const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}${groupId}/payers`, { params: serverParams, showload: true })
    commit('setInactivePayers', response.data.data)
    const incompleteResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/incomplete/${groupId}`, { showload: true })
    response.data.incompleteCount = incompleteResponse.data
    return response.data
  },
  getGroupPlanNames: async ({ state, commit, rootState }) => {
    const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/` + state.currentPayer.groupId, { params: { 'paygateid': rootState.common.paygateId }, showload: true })
    commit('setPlanNames', response.data)
    return response.data
  },
  setGroupId: async ({ commit }, groupId) => {
    commit('setGroupId', groupId)
  },
  generatePayerReference: async ({ commit, state, rootState }) => {
    if (state.newRefRequired) {
      let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payerreference/${state.currentPayer.groupId}`, { FirstName: state.currentPayer.payerDetails.firstName, Surname: state.currentPayer.payerDetails.surname, PlanTemplateId: null }, { params: { paygateid: rootState.common.paygateId }, showload: true })
      commit('updatePayerReference', response.data)
      commit('setNewRefRequired', false)
    }
  },
  generatePayerReferenceWithTemplate: async ({ commit, state, rootState }, { templateId }) => {
    if (state.newRefRequired) {
      let response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payerreference/${state.currentPayer.groupId}`, { FirstName: state.currentPayer.payerDetails.firstName, Surname: state.currentPayer.payerDetails.surname, PlanTemplateId: templateId }, { params: { paygateid: rootState.common.paygateId }, showload: true })
      commit('updatePayerReference', response.data)
      commit('setNewRefRequired', false)
    }
  },
  setNewRefRequired: async ({ commit, state }, value) => {
    commit('setNewRefRequired', value)
  },
  getUseAutoGenerateRef: async ({ commit, state }, groupId) => {
    let response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payerreference/${groupId}/checkautogenerate`, { showload: true })
    commit('setUseAutoGenerateRef', response.data)
  }
}

export default { state, getters, actions, mutations }
