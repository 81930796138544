<template>
  <span class="pull-right mr-2"  :title="'Help page for '+currentRouteName+''" v-if="this.docPath">
    <a href="#" @click="click">
      <i class="text-primary far fa-question-circle question"></i><span class="sr-only">Help page for {{currentRouteName}}</span>
    </a>
  </span>
</template>

<script>

import {cu} from "@fullcalendar/core/internal-common";

export default {
  name: 'HelpIcon',
  props: ['docPath'],
  methods: {
    cu,
    click () {
      window.open(`${process.env.VUE_APP_DOCUMENTATION_ROOT_URL}${this.docPath}`, '_blank')
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name !== undefined ? this.$route.name : this.docPath;
    }
  }
}
</script>
