<template>
  <div>
    {{messageCount}} {{title}} {{linkText}} {{linkUrl}}

  </div>
</template>
<script>
export default {
  props: {
    messageCount: { type: Number, default: 0 },
    title: { type: String, default: '' },
    linkText: { type: String, default: '' },
    linkUrl: { type: String, default: '' }
  },
  data () {
    return {}
  },
  methods: {

  }
}
</script>
