var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: {
              docPath: "/paygate-collections/messaging/assigntemplates/"
            }
          }),
          _vm._v("Manage Message Templates "),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c(
          "b-tabs",
          [
            _c(
              "b-tab",
              { attrs: { title: "Letter", active: "" } },
              [
                _c(
                  "vue-good-table",
                  {
                    attrs: {
                      mode: "remote",
                      columns: _vm.letterColumns,
                      rows: _vm.letterRows,
                      "search-options": {
                        enabled: true
                      },
                      "pagination-options": {
                        enabled: true,
                        dropdownAllowAll: false
                      },
                      totalRows: _vm.totalLetterRecords,
                      lineNumbers: true,
                      styleClass: "vgt-table striped bordered"
                    },
                    on: {
                      "on-row-click": _vm.onLetterRowClick,
                      "on-row-mouseenter": _vm.onLetterRowClick,
                      "on-row-mouseleave": _vm.onLetterRowClick,
                      "on-page-change": _vm.onLetterPageChange,
                      "on-sort-change": _vm.onLetterSortChange,
                      "on-column-filter": _vm.onLetterColumnFilter,
                      "on-per-page-change": _vm.onLetterPerPageChange,
                      "on-search": _vm.onLetterSearch
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-row",
                        fn: function(props) {
                          return [
                            props.column.field === "downloadUrl"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          variant: "outline-primary",
                                          title: "Download Template Document"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.downloadDocx(
                                              props.row.templateId
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-download"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : props.column.field === "deleteUrl"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          variant: "outline-danger",
                                          title: "Delete Template"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.deleteTemplate(props.row)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-trash" })]
                                    )
                                  ],
                                  1
                                )
                              : props.column.field === "preview"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          to: {
                                            name: "SpecificLetterTemplate",
                                            params: {
                                              templateid: props.row.templateId
                                            }
                                          },
                                          title: "View / Edit Template"
                                        }
                                      },
                                      [_vm._v("View / Edit")]
                                    )
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    )
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("template", { slot: "loadingContent" }, [
                      _c("h1", [_vm._v("Loading...")])
                    ]),
                    _c(
                      "div",
                      { attrs: { slot: "emptystate" }, slot: "emptystate" },
                      [
                        _c(
                          "div",
                          { staticClass: "vgt-center-align vgt-text-disabled" },
                          [_vm._v("No Letter Templates Available")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        attrs: { slot: "table-actions" },
                        slot: "table-actions"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Refresh the data in the table",
                                expression: "'Refresh the data in the table'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: { variant: "link", title: "Refresh Table" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.reloadLetterItems($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-sync pointer dimmedIcon"
                            }),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Refresh Table")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "b-tab",
              { attrs: { title: "Email" } },
              [
                _c(
                  "vue-good-table",
                  {
                    attrs: {
                      mode: "remote",
                      columns: _vm.emailColumns,
                      rows: _vm.emailRows,
                      "search-options": {
                        enabled: true
                      },
                      "pagination-options": {
                        enabled: true,
                        dropdownAllowAll: false
                      },
                      totalRows: _vm.totalEmailRecords,
                      lineNumbers: true,
                      styleClass: "vgt-table striped bordered"
                    },
                    on: {
                      "on-page-change": _vm.onEmailPageChange,
                      "on-sort-change": _vm.onEmailSortChange,
                      "on-column-filter": _vm.onEmailColumnFilter,
                      "on-per-page-change": _vm.onEmailPerPageChange,
                      "on-search": _vm.onEmailSearch
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-row",
                        fn: function(props) {
                          return [
                            props.column.field === "deleteUrl"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          variant: "outline-danger",
                                          title: "Delete Template"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.deleteTemplate(props.row)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-trash" })]
                                    )
                                  ],
                                  1
                                )
                              : props.column.field === "preview"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          to: {
                                            name: "SpecificEmailTemplate",
                                            params: {
                                              templateid: props.row.templateId
                                            }
                                          },
                                          title: "View / Edit Template"
                                        }
                                      },
                                      [_vm._v("View / Edit")]
                                    )
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    )
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("template", { slot: "loadingContent" }, [
                      _c("h1", [_vm._v("Loading...")])
                    ]),
                    _c(
                      "div",
                      { attrs: { slot: "emptystate" }, slot: "emptystate" },
                      [
                        _c(
                          "div",
                          { staticClass: "vgt-center-align vgt-text-disabled" },
                          [_vm._v("No Email Templates Available")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        attrs: { slot: "table-actions" },
                        slot: "table-actions"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Refresh the data in the table",
                                expression: "'Refresh the data in the table'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: { variant: "link", title: "Refresh Table" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.reloadEmailItems($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-sync pointer dimmedIcon"
                            }),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Refresh Table")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "b-tab",
              { attrs: { title: "SMS" } },
              [
                _c(
                  "vue-good-table",
                  {
                    attrs: {
                      mode: "remote",
                      columns: _vm.smsColumns,
                      rows: _vm.smsRows,
                      "search-options": {
                        enabled: true
                      },
                      "pagination-options": {
                        enabled: true,
                        dropdownAllowAll: false
                      },
                      totalRows: _vm.totalSmsRecords,
                      lineNumbers: true,
                      styleClass: "vgt-table striped bordered"
                    },
                    on: {
                      "on-page-change": _vm.onSmsPageChange,
                      "on-sort-change": _vm.onSmsSortChange,
                      "on-column-filter": _vm.onSmsColumnFilter,
                      "on-per-page-change": _vm.onSmsPerPageChange,
                      "on-search": _vm.onSmsSearch
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-row",
                        fn: function(props) {
                          return [
                            props.column.field === "deleteUrl"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          disabled: _vm.isLoading,
                                          variant: "outline-danger",
                                          title: "Delete Template"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.deleteTemplate(props.row)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-trash" })]
                                    )
                                  ],
                                  1
                                )
                              : props.column.field === "preview"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          to: {
                                            name: "SpecificSmsTemplate",
                                            params: {
                                              templateid: props.row.templateId
                                            }
                                          },
                                          title: "View / Edit Template"
                                        }
                                      },
                                      [_vm._v("View / Edit")]
                                    )
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    )
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("template", { slot: "loadingContent" }, [
                      _c("h1", [_vm._v("Loading...")])
                    ]),
                    _c(
                      "div",
                      { attrs: { slot: "emptystate" }, slot: "emptystate" },
                      [
                        _c(
                          "div",
                          { staticClass: "vgt-center-align vgt-text-disabled" },
                          [_vm._v("No SMS Templates Available")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        attrs: { slot: "table-actions" },
                        slot: "table-actions"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Refresh the data in the table",
                                expression: "'Refresh the data in the table'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: { disabled: _vm.isLoading, variant: "link" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.reloadSmsItems($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-sync pointer dimmedIcon"
                            }),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Refresh Table")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "idb-block-footer" },
      [
        _c(
          "b-dropdown",
          { attrs: { variant: "primary" } },
          [
            _c(
              "span",
              { attrs: { slot: "button-content" }, slot: "button-content" },
              [
                _c("i", { staticClass: "fa fa-plus rpad" }),
                _vm._v("Create Template ")
              ]
            ),
            _c(
              "b-dropdown-item",
              {
                on: {
                  click: function($event) {
                    return _vm.goToTemplate("LetterDesigner")
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa fa-envelope rpad",
                  attrs: { title: "Letter" }
                }),
                _vm._v("Letter ")
              ]
            ),
            _c(
              "b-dropdown-item",
              {
                on: {
                  click: function($event) {
                    return _vm.goToTemplate("EmailDesigner")
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa fa-at rpad",
                  attrs: { title: "Email" }
                }),
                _vm._v("Email ")
              ]
            ),
            _c(
              "b-dropdown-item",
              {
                on: {
                  click: function($event) {
                    return _vm.goToTemplate("SmsDesigner")
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa fa-mobile rpad",
                  attrs: { title: "SMS" }
                }),
                _vm._v("SMS ")
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }