<template>
  <b-modal
    id="manage-payment-modal"
    ref="managePaymentModal"
    title="Record Details"
    size="xl"
    hide-header-close
    static
    cancel-variant="warning"
    @shown="load"
    :ok-disabled="!canEditSub || $v.$invalid"
  >
    <div>
      <!-- Name -->
      <div class="form-group row" :class="{ invalid: $v.thirdPartyAccountName.$error }">
        <label for="thirdPartyName" class="col-form-label col-md-3 required">Name</label>
        <div class="col-md-6">
          <input
            id="thirdPartyName"
            :readonly="readonly"
            :disabled="disabledFields"
            class="form-control"
            v-model.trim="$v.thirdPartyAccountName.$model"
          />
          <!-- Validation -->
          <validation-messages v-model="$v.thirdPartyAccountName" name="Name"></validation-messages>
        </div>
      </div>

      <!-- Sort Code -->
      <div class="form-group row" :class="{ invalid: $v.thirdPartySortCode.$error }">
        <label for="thirdPartySortCode" class="label-control col-md-3 required">Sort Code</label>
        <div class="col-md-3">
          <the-mask
            id="thirdPartySortCode"
            type="text"
            class="form-control"
            v-model="$v.thirdPartySortCode.$model"
            :mask="['##-##-##']"
            :readonly="readonly"
            :disabled="disabledFields"
            :key="count"
            ref="thirdPartySortCode"
          />
          <!-- Validation -->
        </div>
        <div v-if="bankDetailsValidated && sortCodeValid" class="col-md-1">
          <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
        </div>
        <span v-if="$v.thirdPartySortCode.$dirty">
          <small
            v-if="!$v.thirdPartySortCode.required"
            class="form-text text-danger small text-nowrap"
          >Sort Code is required</small>
          <small
            v-if="!$v.thirdPartySortCode.minLength || !$v.thirdPartySortCode.maxLength"
            class="form-text text-danger small text-nowrap"
          >Sort code must be {{ $v.thirdPartySortCode.$params.minLength.min }} numbers</small>
          <small
            v-if="!sortCodeValid && !redundantSortCodeMessage"
            class="form-text text-warning small text-nowrap"
          >{{ sortCodeErrorMessage }}</small>
        </span>
      </div>

      <div class="form-group row" :class="{ invalid: $v.thirdPartyAccountNumber.$error }">
        <label for="thirdPartyAccountNumber" class="label-control col-md-3 required">Account Number</label>
        <div class="col-md-3" :class="{invalid: $v.thirdPartyAccountNumber.$error}">
          <the-mask
            id="thirdPartyAccountNumber"
            type="text"
            class="form-control"
            :mask="'########'"
            v-model.trim="$v.thirdPartyAccountNumber.$model"
            :readonly="readonly"
            :disabled="disabledFields"
            ref="thirdPartyAccountNumber"
          ></the-mask>
        </div>
        <div v-if="bankDetailsValidated && accountNumberValid" class="col-md-1">
          <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
        </div>
        <span v-if="$v.thirdPartyAccountNumber.$dirty">
          <small
            v-if="!$v.thirdPartyAccountNumber.required"
            class="form-text text-danger small text-nowrap"
          >Account Number is required</small>
          <small
            class="form-text text-danger small text-nowrap"
            v-if="!$v.thirdPartyAccountNumber.minLength"
          >Account Number must be {{$v.thirdPartyAccountNumber.$params.minLength.min}} numbers</small>
          <small
            class="form-text text-danger small text-nowrap"
            v-if="!$v.thirdPartyAccountNumber.maxLength"
          >Account Number must be {{$v.thirdPartyAccountNumber.$params.maxLength.max}} numbers</small>
          <small
            v-if="!accountNumberValid && !redundantSortCodeMessage"
            class="form-text text-warning small text-nowrap"
          >{{ accountNumberErrorMessage }}</small>
        </span>
      </div>

      <!-- User Ref -->
      <div class="form-group row" :class="{ invalid: $v.userReference.$error }">
        <label
          for="userReferenceControl"
          class="col-form-label col-md-3"
          :class="{'required': transactionCode != 99}"
        >User Reference</label>
        <div class="col-md-6">
          <input
            id="userReferenceControl"
            :readonly="readonly"
            :disabled="disabledFields"
            class="form-control"
            v-model.trim="$v.userReference.$model"
          />
          <!-- Validation -->
          <validation-messages v-model="$v.userReference" name="user reference"></validation-messages>
        </div>
      </div>

      <!-- Transaction Code -->
      <div class="form-group row" :class="{ invalid: $v.transactionCode.$error }">
        <label
          for="transactionCodeSelector"
          class="col-form-label col-md-3 required"
        >Transaction Code</label>
        <div class="col-md-2">
          <b-form-select
            :id="'transactionCodeSelector'"
            v-model="$v.transactionCode.$model"
            :options="transactionCodesSorted"
            value-field="code"
            text-field="code"
            :disabled="disabledFields"
            :readonly="readonly"
            @input="transactionCodeChanged()"
          ></b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <div class="offset-3 col-6">
          <span class="form-text small">{{ transactionCodeDescription }}</span>
          <!-- Validation -->
          <validation-messages v-model="$v.transactionCode" name="transaction code"></validation-messages>
        </div>
      </div>

      <!-- Amount -->
      <div class="form-group row" :class="{ invalid: $v.amount.$error }">
        <label for="paymentAmount" class="col-form-label col-md-3 required">Amount</label>
        <div class="col-md-3">
          <currency-input
            id="paymentAmount"
            type="text"
            v-model="$v.amount.$model"
            :readonly="readonly"
            :disabled="disabledFields"
          ></currency-input>
          <validation-messages v-model="$v.amount">
            <small
              class="text-danger small"
              v-if="!$v.amount.amountZeroValidForTransactionCode"
            >Amount must be £0.00 for this transaction code</small>

            <small
              class="text-danger small"
              v-if="!$v.amount.amountNonZeroValidForTransactionCode"
            >Amount must be greater than £0.00 for this transaction code</small>
          </validation-messages>
        </div>
      </div>

      <!-- Processing Date -->
      <div
        class="form-group row"
        :class="{ invalid: $v.processingDate.$error }"
        v-show="isProcessingDateRequired && !isFps"
      >
        <label for="processingDateDetails" class="col-form-label col-md-3 required">Processing Date</label>
        <div class="col-md-3">
          <vuejsDatepicker
            ref="processingDate"
            name="processingDate"
            id="processingDateDetails"
            v-model="$v.processingDate.$model"
            format="dd/MM/yyyy"
            input-class="form-control"
            :bootstrap-styling="true"
            :disabledDates="disabledDates"
            :monday-first="true"
            style="backgroundColor: 'white'"
          ></vuejsDatepicker>
          <validation-messages v-model="$v.processingDate"></validation-messages>
        </div>
      </div>

      <!-- Validation Messages -->
      <span v-if="validationMessages.length > 0">
        <b-table
          class="mt-2"
          borderless
          small
          caption-top
          :striped="true"
          :items="validationMessages"
          :fields="validationFields"
          :per-page="10"
        >
          <template v-slot:cell(messageSeverity)="data">
            <span v-bind:class="data.value" class="badge badge-pill table-pill">{{ data.value }}</span>
          </template>
        </b-table>
      </span>
    </div>

    <footer slot="modal-footer" slot-scope="props">
      <button class="btn btn-danger mr-1" @click="props.cancel()">Cancel</button>
      <button
        class="btn btn-primary"
        :disabled="!canEditSub || $v.$invalid"
        @click="checkValidation() && SaveBlockDetail() && props.ok()"
      >Save</button>
    </footer>
  </b-modal>
</template>

<script>
// Third Party
import _ from 'lodash'
import axios from 'axios'
import { TheMask } from 'vue-the-mask'
import { mapGetters } from 'vuex'

// Mixins
import Utility from '@/Assets/Mixins/Utility'

// Components
import CurrencyInput from '@/Assets/Components/CurrencyInput.vue'

// Validations
import { required, maxLength, minLength, maxValue, requiredIf } from 'vuelidate/lib/validators'
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils'

export default {
  mixins: [Utility],
  components: {
    CurrencyInput,
    TheMask
  },
  props: {
    disabledFields: Boolean,
    itemId: String, // block referrer
    submissionId: String, // submission referrer
    bureauJobId: String, // this bureau job referrer
    pkid: String, // this payment referrer
    groupId: String, // the group referrer
    isNew: Boolean, // New payment or modify existing
    currentBacsSubmissionFileDetail: Object,
    isFps: Boolean
  },

  data () {
    return {
      editRights: [],
      addRights: [],
      count: 0,
      thirdPartyAccountName: '',
      thirdPartySortCode: '',
      thirdPartyAccountNumber: '',
      userReference: '',
      amount: 0.00,
      processingDate: null,
      validationResult: {},
      valid: false,
      sortCodeValid: false,
      accountNumberValid: false,
      sortCodeErrorMessage: '',
      accountNumberErrorMessage: '',
      disabledDates: {},
      readonly: false,
      bankDetailsValidated: false,
      // if (this.submission.paymentNetworkType === 'BacstelIp') {
      // } else {
      //   this.txCodes = ['99', 'Z4', 'Z5']
      // }
      transactionCode: '99',
      transactionCodeDescription: 'standard bank giro (i.e. credit)',
      transactionCodes: [
        { code: '99', description: 'Standard bank giro (i.e. credit)' },
        { code: '17', description: 'Standard bank debit' },
        { code: '01', description: 'First payment. This has to be used the first time a direct debit is collected by the customer' },
        { code: '18', description: 'Re-presentation. If the direct debit failed to go through one time, it can be sent again with this code' },
        { code: '19', description: 'Final payment. The last in a series of direct debit collections. This will also close the mandate' },
        { code: '0N', description: 'Set-up a new instruction with the payer’s Bank/Building Society branch' },
        { code: '0C', description: 'Cancel an existing AUDDIS instruction' },
        { code: '0S', description: 'Convert an existing manually set-up DDI to an AUDDIS direct debit' },
        { code: 'Z4', description: 'Interest payment' },
        { code: 'Z5', description: 'Dividend payment' }
      ],
      transactionCodesFps: [
        { code: '99', description: 'Standard bank giro (i.e. credit)' },
        { code: 'Z4', description: 'Interest payment' },
        { code: 'Z5', description: 'Dividend payment' }
      ],
      validationMessages: [],
      validationFields: [
        // {
        //   key: 'title',
        //   label: 'Title'
        // },
        {
          key: 'message',
          label: 'Message'
        },
        {
          key: 'messageSeverity',
          label: 'Severity'
        }
      ]
    }
  },

  computed: {
    transactionCodesSorted () {
      var key = 'code'
      if (this.isFps) {
        return this.transactionCodesFps.sort((a, b) => {
          var x = a[key]; var y = b[key]
          return ((x < y) ? -1 : ((x > y) ? 1 : 0))
        })
      } else {
        return this.transactionCodes.sort((a, b) => {
          var x = a[key]; var y = b[key]
          return ((x < y) ? -1 : ((x > y) ? 1 : 0))
        })
      }
    },
    isProcessingDateRequired () {
      return this.currentBacsSubmissionFileDetail.executionDayType !== 0
    },
    redundantSortCodeMessage () {
      return this.sortCodeErrorMessage !== null && this.sortCodeErrorMessage.toLowerCase() === 'the sort code is empty' && this.$v.thirdPartySortCode.required
    },
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    canEditSub () {
      return this.editRights.length > 0 && this.editRights.filter(x => x.groupId === this.groupId).length > 0
    },
    canAddToSub () {
      return this.addRights.length > 0 && this.addRights.filter(x => x.groupId === this.groupId).length > 0
    }
  },

  async created () {
    this.disabledDates = await this.setUpBACSDatePicker()
    // var dateElement = document.getElementById('processingDate')
    // dateElement.style.backgroundColor = 'white'
    this.$v.$reset()
  },

  async mounted () {
    this.disabledDates = await this.setUpBACSDatePicker()
    var editRightsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.paygateId}/1/`, { params: { rights: ['Edit'] } })

    console.log('editrights', editRightsResponse)
    this.editRights = editRightsResponse.data

    var addRightsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.paygateId}/1/`, { params: { rights: ['Add'] } })
    this.addRights = addRightsResponse.data

  },

  watch: {
    thirdPartySortCode: function () {
      this.validate()
    },
    thirdPartyAccountNumber: function () {
      this.validate()
    }
  },
  methods: {
    load: async function () {
      this.$v.$reset()

      this.thirdPartyAccountName = ''
      this.thirdPartySortCode = ''
      this.thirdPartyAccountNumber = ''
      this.userReference = ''
      this.amount = 0
      this.processingDate = null
      this.validationMessages = []

      if (!this.isNew) {
        var params = {
          submissionId: this.submissionId,
          itemId: this.itemId,
          bureauJobId: this.bureauJobId,
          pkid: this.pkid
        }
        this.count++
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/GetCurrentSubmissionPayment', params, { showload: true })

        // We call this little line of code a _hack_ 😅
        this.$refs.thirdPartySortCode.lastValue = null
        this.$refs.thirdPartyAccountNumber.lastValue = null

        this.thirdPartyAccountName = response.data.thirdPartyAccountName
        this.thirdPartySortCode = response.data.thirdPartySortCode.trim()
        this.thirdPartyAccountNumber = response.data.thirdPartyAccountNumber.trim()
        this.transactionCode = response.data.transactionCode.trim()
        this.userReference = response.data.userReference
        this.amount = response.data.amount
        var arr = response.data.processingDate.split('/')
        let date = null
        if (arr.length > 1) {
          date = new Date(parseInt(arr[2]), parseInt(arr[1]) - 1, parseInt(arr[0]))
          this.processingDate = date
        }
        this.validationMessages = response.data.preSubValMessages
      }
    },

    SaveBlockDetail: async function () {
      if (this.isNew) {
        var paramsNew = {
          submissionId: this.submissionId,
          itemId: this.itemId,
          bureauJobId: this.bureauJobId,
          thirdPartyAccountName: this.thirdPartyAccountName.trim(),
          thirdPartySortCode: this.thirdPartySortCode.trim(),
          thirdPartyAccountNumber: this.thirdPartyAccountNumber.trim(),
          userReference: this.userReference,
          amount: this.amount,
          processingDate: this.processingDate,
          transactionCode: this.transactionCode
        }
        var responseNew = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/SaveNewSubmissionPayment', paramsNew, { showload: true })
        if (responseNew.data.status !== 'Ok') {
          this.$toastr.e(responseNew.data.toastMessage, responseNew.data.toastTitle)
        }
      } else {
        var ProcessingDateToSave = ''
        if (this.processingDate === null || this.processingDate === undefined) {
          ProcessingDateToSave = ''
        } else {
          ProcessingDateToSave = this.processingDate.toLocaleDateString('en-GB')
        }

        var params = {
          submissionId: this.submissionId,
          itemId: this.itemId,
          bureauJobId: this.bureauJobId,
          pkid: this.pkid,
          thirdPartyAccountName: this.thirdPartyAccountName.trim(),
          thirdPartySortCode: this.thirdPartySortCode.trim(),
          thirdPartyAccountNumber: this.thirdPartyAccountNumber.trim(),
          userReference: this.userReference,
          amount: this.amount,
          processingDate: ProcessingDateToSave,
          transactionCode: this.transactionCode
        }
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/SaveCurrentSubmissionPayment', params, { showload: true })
        if (response.status !== 200) {
          this.$toastr.e('Could not save payment')
        }
      }
      this.$emit('submit')
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.thirdPartySortCode }

      if (this.thirdPartyAccountNumber.length === 8) {
        data.accountNumber = this.thirdPartyAccountNumber
      }

      try {
        const validateUrl = `${process.env.VUE_APP_VALIDATE_API_URL}BankAccount`
        const response = await axios.get(validateUrl, { params: data }, { showload: true })

        this.bankDetailsValidated = true
        this.validationResult = response.data

        this.sortCodeErrorMessage = ''
        this.accountNumberErrorMessage = ''
        if (this.validationResult.valid) {
          this.sortCodeValid = true
          this.accountNumberValid = true
        } else {
          if (this.validationResult.errorCode === '1502') {
            this.sortCodeValid = true
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          } else {
            this.sortCodeValid = false
            this.sortCodeErrorMessage = this.validationResult.errorText
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          }
        }
      } catch (e) {
        console.log(e)
      }
    }, 800),
    transactionCodeChanged () {
      const index = this.transactionCodes.findIndex(x => x.code === this.transactionCode)
      this.transactionCodeDescription = this.transactionCodes[index].description
    }
  },
  validations () {
    return {
      thirdPartyAccountName: { maxLength: maxLength(50), required },
      thirdPartySortCode: { required, minLength: minLength(6), maxLength: maxLength(6) },
      thirdPartyAccountNumber: { minLength: minLength(8), maxLength: maxLength(8), required },
      userReference: {
        required: requiredIf(function () {
          return this.transactionCode !== '99'
        }),
        maxLength: maxLength(18)
      },
      transactionCode: { minLength: minLength(2), maxLength: maxLength(2), required },
      amount: {
        maxValue: maxValue(20000000),
        required,
        amountZeroValidForTransactionCode: (amount) => {
          switch (this.transactionCode) {
            case '0N':
            case '0C':
            case '0S':
              return (amount === 0)
            default:
              return true
          }
        },
        amountNonZeroValidForTransactionCode: (amount) => {
          switch (this.transactionCode) {
            case '99':
            case '17':
            case '01':
            case '18':
            case '19':
            case 'Z4':
            case 'Z5':
              return (amount > 0)
            default:
              return true
          }
        }
      },
      processingDate: {
        required: requiredIf(() => {
          return this.isProcessingDateRequired
        })
      }
    }
  }
}
</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}
</style>
