var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config.inline
    ? _c("div", { staticClass: "datetimepicker-inline" })
    : _c("input", { staticClass: "form-control", attrs: { type: "text" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }