export default {
  BacsSubmission: 'BACS Submission',
  BacsTransaction: 'BACS Transaction',
  FPSSubmission: 'FPS Submission',
  FPSTransaction: 'FPS Transaction',
  CollectionsSubmission: 'Collections Submission',
  CollectionsTransaction: 'Collections Transaction',
  BureauUniqueSUN: 'Bureau Unique SUN',
  BureauSubmission: 'Bureau Submission',
  BureauTransaction: 'Bureau Transaction',
  Validate: 'Validate',
  AutomationExecutedWorkflow: 'Automation Executed Workflow',
  AgentFileUploaded: 'Agent File Uploaded',
  AgentFileDownloaded: 'Agent File Downloaded',
  SMSSent: 'SMS Sent',
  EmailSent: 'Email Sent',
  AddressLookup: 'Address Lookup'
}
