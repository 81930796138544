var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showModalFileBrowser
        ? _c(
            "publicFileBrowser",
            {
              attrs: {
                defaultFolder: _vm.defaultFolder,
                filesOnly: _vm.filesOnly
              },
              on: {
                close: _vm.closeBrowser,
                cancel: function($event) {
                  _vm.showModalFileBrowser = false
                }
              }
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("File Browser")
              ])
            ]
          )
        : _vm._e(),
      _vm.useServerFileBrowser || _vm.useRestrictedFileBrowser
        ? _c(
            "div",
            {
              staticClass: "vue-dropzone dropzone dz-clickable",
              on: { click: _vm.launchBrowser }
            },
            [
              _vm.selectedFiles.length === 0
                ? _c("div", { staticClass: "dz-message" }, [_vm._m(0)])
                : _vm._e(),
              _vm._l(_vm.selectedFiles, function(file, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "dz-preview dz-file-preview dz-processing dz-error dz-complete"
                  },
                  [
                    _vm._m(1, true),
                    _c("div", { staticClass: "dz-details" }, [
                      _c("div", { staticClass: "dz-size" }),
                      _c("div", { staticClass: "dz-filename" }, [
                        _vm._v(_vm._s(file.fileName))
                      ])
                    ]),
                    _vm.showDeleteButtons
                      ? _c(
                          "a",
                          {
                            staticClass: "dz-remove",
                            attrs: { "data-dz-remove": "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.removeFile(index)
                              }
                            }
                          },
                          [_vm._v("Remove file")]
                        )
                      : _vm._e()
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.useDropzone
        ? _c(
            "vue-dropzone",
            {
              ref: "fileUploader",
              attrs: {
                id: "drop1",
                url: _vm.dropzoneUrl,
                options: _vm.dropzoneOptions,
                "use-custom-slot": true,
                "accepted-file-types": _vm.dropzoneOptions.acceptedFiles,
                "max-file-size-in-m-b": 200,
                useCustomDropzoneOptions: true,
                "use-custom-drop-zone-options": true,
                useCustomSlot: true
              },
              on: {
                "vdropzone-success": _vm.afterSuccess,
                "vdropzone-file-added": _vm.fileAdded,
                "vdropzone-removed-file": _vm.fileRemoved,
                "vdropzone-sending": _vm.sendingEvent
              }
            },
            [
              _c("div", { staticClass: "dropzone-custom-content" }, [
                _c("h3", { staticClass: "dropzone-custom-title" }, [
                  _vm._v(_vm._s(_vm.dropzoneOptions.title))
                ]),
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(_vm._s(_vm.dropzoneOptions.subtitle))
                ])
              ])
            ]
          )
        : _vm._e(),
      _c("plugin")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dropzone-custom-content" }, [
      _c("h3", { staticClass: "dropzone-custom-title" }, [
        _vm._v("Click to select file")
      ]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v("Select an uploaded file from the folder")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dz-image" }, [
      _c("img", { attrs: { "data-dz-thumbnail": "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }