<template>
  <div class="loading">
    <folderBrowser
      v-if="showModalFolderBrowser"
      @close="showModalFolderBrowser = false; closePopup()"
    >
    </folderBrowser>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 v-if="isNewTrigger">
              <span class="pull-right">
                <a :href="`${docUrl}/automation/paygate-workflow/triggers/filetrigger/`" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span> Create a file trigger
            </h2>
            <h2 v-else>
              <span class="pull-right">
                <a :href="`${docUrl}/automation/paygate-workflow/triggers/filetrigger/`" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span> Edit a file trigger
            </h2>
          </div>
          <div class="idb-block-content">
            <form>
              <div class="row">
                <div class="col-3">
                  <label
                    class="required"
                    v-b-popover.hover.top.d500="'The title of the trigger.'"
                  >Title</label>
                </div>
                <div class="form-group col-9">
                  <input
                    type="text"
                    class="form-control"
                    :class="{invalid: $v.trigger.title.$error}"
                    @input="$v.trigger.title.$touch()"
                    v-model="trigger.title"
                  />
                  <p
                    class="validationText"
                    v-if="!$v.trigger.title.required && $v.trigger.title.$dirty "
                  >The title field is required!</p>
                  <p
                    class="validationText"
                    v-if="!$v.trigger.title.maxLength"
                  >Maximum length is {{ $v.trigger.title.$params.maxLength.max }} characters!</p>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label
                    v-b-popover.hover.top.d500="'An optional text field for you to write a discription of the trigger.'"
                  >Description</label>
                </div>
                <div class="form-group col-9">
                  <textarea
                    class="form-control"
                    rows="4"
                    cols="60"
                    @input="$emit('textarea',$event.target.value)"
                    @blur="$v.trigger.description.$touch()"
                    v-model="trigger.description"
                  ></textarea>
                  <p
                    class="validationText"
                    v-if="!$v.trigger.description.maxLength"
                  >Maximum length is {{ $v.trigger.description.$params.maxLength.max }} characters!</p>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <label
                    class="required"
                    v-b-popover.hover.top.d500="'The virtual path to the folder that the trigger is watching'"
                  >Folder</label>
                </div>
                <div class="col-9 mb-3">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      :class="{invalid: $v.trigger.configuration.watchFolder.$error}"
                      @input="$v.trigger.configuration.watchFolder.$touch()"
                      v-model="trigger.configuration.watchFolder" />
                    <div class="input-group-append">
                      <b-button @click.prevent="btnFileBrowser" variant="outline-secondary">...</b-button>
                    </div>
                  </div>
                  <p
                    class="validationText"
                    v-if="!$v.trigger.configuration.watchFolder.required && $v.trigger.configuration.watchFolder.$dirty "
                  >The folder field is required!</p>
                  <p
                    class="validationText"
                    v-if="!$v.trigger.configuration.watchFolder.maxLength"
                  >Maximum length is {{ $v.trigger.configuration.watchFolder.$params.maxLength.max }} characters!</p>
                </div>
                <div class="col-1">
                </div>
              </div>
              <div class="row mb-3">
                <div class="form-group col-3">
                  <label
                    class="required"
                    v-b-popover.hover.top.d500="'The filename that the trigger is watching'"
                  >Filename</label>
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    :class="{invalid: $v.trigger.configuration.watchFilename.$error}"
                    @input="$v.trigger.configuration.watchFilename.$touch()"
                    v-model="trigger.configuration.watchFilename"
                  />
                  <p
                    class="validationText"
                    v-if="!$v.trigger.configuration.watchFilename.required && $v.trigger.configuration.watchFilename.$dirty "
                  >The filename field is required!</p>
                  <p
                    class="validationText"
                    v-if="!$v.trigger.configuration.watchFilename.maxLength"
                  >Maximum length is {{ $v.trigger.configuration.watchFilename.$params.maxLength.max }} characters!</p>
                </div>
              </div>
              <div class="row top-buffer">
                <div class="col-md-3 col-sm-12">
                  <span
                    title="Enabled"
                    v-b-popover.hover.top.d500="'Determines if the trigger is enabled or disabled.  Disabled triggers will not execute bound workflows when they fire.'"
                  ><label for="triggerEnabledCheckbox">Enabled</label></span>
                </div>
                <div class="col-md-3 col-sm-12">
                  <p-check name="check" :labelId="'triggerEnabledCheckbox'" class="p-switch" color="primary " v-model="trigger.enabled"></p-check>
                </div>
              </div>
              <br />
              <div class="row top-buffer">
                <div class="col-md-3 col-sm-12">
                  <span v-b-popover.hover.top.d500="'If enabled, the trigger will not run any bound workflows if the current day is a BACS non-working day, i.e. a weekend or public bank holiday.'"
                  ><label for="suppressNonWorkingDaysCheckbox">Suppress on BACS non-working days</label></span>
                </div>
                <div class="col-md-6 col-sm-12">
                  <p-check
                    :labelId="'suppressNonWorkingDaysCheckbox'"
                    name="check"
                    class="p-switch"
                    color="primary "
                    v-model="trigger.configuration.suppressOnNonWorkingDays"
                  ></p-check>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div class="row">
                <div class="col-md-3 col-sm-12">
                  <label
                    v-b-popover.hover.top.d500="'A list of workflows that will execute when the trigger fires.'"
                  >Bound Workflows</label>
                </div>
                <div class="form-group col-md-9 col-sm-12">
                  <ul class="list-group">
                    <li v-for="(workflow,index) in this.trigger.boundWorkflows" :key="index" class="list-group-item align-items-center">
                      <div class="row">
                        <div class="col-1">
                          <div class="pretty p-icon p-smooth">
                            <input type="checkbox" v-model="workflow.enabled" />
                            <div class="state p-primary">
                              <i class="icon fa fa-check"></i>
                              <label></label>
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          {{workflow.Title}} (
                          <router-link
                            tag="a"
                            :to="'/automation/workflow/workflowdetail/' + workflow.id.value"
                            class="small"
                          >Edit</router-link>)
                        </div>
                        <div class="col-2">
                          <b-button variant="link" @click.prevent="btnEditWorkflow(workflow.id)" v-b-popover.hover.top.d500="'Click to edit this workflow using the designer'">
                            <span class="text-secondary glyphicon ti-settings" aria-hidden="true"></span>
                          </b-button>
                          <b-button variant="link" @click.prevent="btnRemoveWorkflow(workflow.id)" v-b-popover.hover.top.d500="'Removes the workflow from the trigger.'">
                            <span class="text-secondary glyphicon ti-trash text-danger" aria-hidden="true"></span>
                          </b-button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <br />
                  <button class="btn btn-outline-primary pull-right" aria-hidden="true" v-b-popover.hover.top.d500="'Add a new workflow to this trigger so that the workflow runs when the trigger fires.'" @click.prevent="btnAddWorkflow">+</button>
                </div>
              </div>
            </form>
          </div>
          <div class="idb-block-footer">
            <div class="row">
              <div class="col-md-12 col-sm-3">
                <b-button :disabled="isLoading" v-if="isNewTrigger" @click.prevent="createTrigger" variant="primary" v-b-popover.hover.top.d500="'Creates a new trigger and saves it in Paygate.  Enabled triggers will become active immediately.'">Create</b-button>
                <b-button :disabled="isLoading" v-else @click.prevent="updateTrigger" variant="primary" v-b-popover.hover.top.d500="'Update the current trigger saving any changes. Enabled triggers will become active immediately.'">Save</b-button>
                <b-button :disabled="isLoading" class="ml-2" v-if="!isNewTrigger" @click.prevent="viewTelemetry" variant="outline-primary" v-b-popover.hover.top.d500="'View this Trigger\'s execution history.'">View Telemetry</b-button>
                <b-button :disabled="isLoading" class="ml-2" v-if="!isNewTrigger" @click.prevent="cloneTrigger" variant="outline-primary" v-b-popover.hover.top.d500="'Create an exact copy of this trigger.'">Clone Trigger</b-button>
                <b-button :disabled="isLoading" v-if="!isNewTrigger" @click.prevent="deleteTrigger" variant="danger" class="pull-right" v-b-popover.hover.top.d500="'Permanently delete this trigger.'" >
                  <i class="glyphicon ti-trash mr-2"></i>Delete Trigger
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'

export default {
  components: {
    folderBrowser: folderBrowser,
    swal
  },
  mixins: [loading],
  validations: {
    trigger: {
      title: {
        required,
        maxLength: maxLength(48)
      },
      description: {
        maxLength: maxLength(1024)
      },
      configuration: {
        watchFolder: {
          required,
          maxLength: maxLength(512)
        },
        watchFilename: {
          required,
          maxLength: maxLength(512)
        }
      }
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      showModalFolderBrowser: false,
      isNewTrigger: true,
      id: this.$route.params.id,
      trigger: {},
      paygateId: '',
      triggerTypes: [
        { text: 'Schedule Trigger', value: 'ScheduleTrigger' },
        { text: 'File Trigger', value: 'FileTrigger' }
      ],
      selectedTriggerType: 'FileTrigger'
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    btnRemoveWorkflow (workflowId) {
      console.log(workflowId)
      this.trigger.boundWorkflows.pop({
        id: workflowId
      })
    },
    async cloneTrigger () {
      const result = await swal.fire({
        title: 'Clone Trigger',
        text: 'Create an exact copy of the current trigger. Cloned trigger will be initially disabled.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        input: 'text',
        inputValue: `${this.trigger.title} (clone)`
      })
      if (result.isConfirmed) {
        if (result.value) {
          const cloneTrigger = {
            title: result.value,
            enableTelemetry: this.trigger.enableTelemetry,
            paygateId: this.trigger.paygateId,
            triggerType: this.trigger.triggerType,
            enabled: false,
            description: this.trigger.description,
            configuration: this.trigger.configuration,
            boundWorkflows: []
          }
          let res
          try {
            res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger`, cloneTrigger, { showload: true })
            if (res) {
              this.$toastr.s(`The trigger '${cloneTrigger.title}' was successfully cloned.`)
              this.$router.push('/automation/workflow/triggers')
            }
          } catch (e) {
            console.log(e)
            if (e.response && e.response.data && e.response.data.errorMessage) {
              this.$snapbar.e(`Error cloning the trigger.  The trigger was not cloned. ${e.response.data.errorMessage}`)
            } else {
              if (e.response && e.response.status === 403) {
                this.$snapbar.e('You are not authorised to clone this trigger.')
              } else {
                this.$snapbar.e(`Error cloning trigger.  The trigger was not cloned. ${e.message}`)
              }
            }
          }
        }
      }
    },
    async btnAddWorkflow () {
      axios
        .get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowtitles`, { showload: true })
        .then(async res => {
          // SWAL needs an object but the API returns an array, so need to transform
          var inputOptions = {}
          res.data.workflows.forEach((element) => {
            inputOptions[element._id] = element.title
          })
          const result = await swal.fire({
            title: 'Select Workflow',
            input: 'select',
            inputOptions: inputOptions,
            showCancelButton: true,
            confirmButtonText: 'Select',
            cancelButtonText: 'Cancel'
          })
          if (result.isConfirmed) {
            if (result.value) {
              this.trigger.boundWorkflows.push({
                Title: inputOptions[result.value],
                enabled: true,
                id: result
              })
            }
          }
        })
        .catch(e => {
          this.$snapbar.e(`Error adding workflow - ${e.message}`)
        })
    },
    createTrigger () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      axios
        .post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger`, this.trigger, { showload: true })
        .then(res => {
          this.$toastr.s(`The trigger '${this.trigger.title}' was successfully created.`)
          this.$router.push('/automation/workflow/triggers')
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.errorMessage) {
            this.$snapbar.e(`Error saving trigger.  The trigger was not saved by Paygate. ${e.response.data.errorMessage}`)
          } else {
            this.$snapbar.e('Error saving trigger.  The trigger was not saved by Paygate.')
          }
        })
    },
    async updateTrigger () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      try {
        await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger/${this.trigger._id}`, this.trigger, { showload: true })
        this.$toastr.s(`The trigger '${this.trigger.title}' was successfully updated.`)
        this.$router.push('/automation/workflow/triggers')
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errorMessage) {
          this.$snapbar.e(`Could not update the trigger. ${e.response.data.errorMessage}`)
        } else {
          this.$snapbar.e(`Error updating trigger - ${e.message}`)
        }
      }
    },
    viewTelemetry () {
      this.$router.push(`/automation/workflow/triggerTelemetry/${this.$route.params.id}`)
    },
    testTrigger () {
      // TODO - Test
      this.$snapbar.w('Not yet implemented.')
    },
    async deleteTrigger () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const result = await swal.fire({
        title: 'Are you sure you want to delete this trigger?',
        text: 'Any workflow currently using this trigger will stop working!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: colours.danger,
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger/${this.id}`, { showload: true })
          .then(res => {
            this.$toastr.s(`The trigger '${this.trigger.title}' was successfully deleted.`)
            this.$router.push('/automation/workflow/triggers')
          })
          .catch(e => {
            if (e.response && e.response.status === 403) {
              this.$snapbar.e('You are not authorised to delete this Trigger.')
            } else {
              this.$snapbar.e(`Could not delete the trigger - ${e.message}`)
            }
          })
      }
    },
    btnFileBrowser () {
      // TODO: File Browser
      // console.log('this.importer.filePath: ' + this.importer.filePath)
      this.$store.commit('setModalPopupString', this.trigger.configuration.watchFolder)

      this.showModalFolderBrowser = true
      // console.log('Click - File Browser')
    },
    closePopup () {
      console.log('Closed popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.trigger.configuration.watchFolder = this.$store.state.mapping.modalPopupString
    },
    async getTrigger () {
      this.$snapbar.hide()
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}trigger/${this.id}`, { showload: true })
        if (res && res.data && res.data.trigger) {
          this.trigger = res.data.trigger
        } else {
          this.$snapbar.w('Unknown or invalid trigger.')
          this.trigger = this.defaultTrigger()
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this Trigger.')
        } else {
          this.$snapbar.e(`Could not display this trigger - ${e.message}`)
        }
        this.trigger = this.defaultTrigger()
      }
    },
    defaultTrigger () {
      return {
        title: '',
        description: '',
        triggerType: 'fileTrigger',
        paygateId: this.paygateId,
        enabled: false,
        configuration: {
          watchFolder: '\\',
          watchFilename: '',
          suppressOnNonWorkingDays: true
        },
        boundWorkflows: []
      }
    }
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      this.trigger = {}
      await this.getTrigger()
    }
  },
  created: async function () {
    // let user = await Auth.getUser()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    this.trigger = this.defaultTrigger()
    if (!this.id) {
      this.isNewTrigger = true
      // change
    } else {
      this.isNewTrigger = false
      await this.getTrigger()
    }
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
  label.required {
    color: #878787;
  }
  label.required::after {
    content: " *";
    color: red;
  }
</style>
