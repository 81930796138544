var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "managePaymentModal",
      attrs: {
        id: "manage-payment-modal",
        title: "Record Details",
        size: "xl",
        "hide-header-close": "",
        static: "",
        "cancel-variant": "warning",
        "ok-disabled": !_vm.canEditSub || _vm.$v.$invalid
      },
      on: { shown: _vm.load },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(props) {
            return _c("footer", {}, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger mr-1",
                  on: {
                    click: function($event) {
                      return props.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { disabled: !_vm.canEditSub || _vm.$v.$invalid },
                  on: {
                    click: function($event) {
                      _vm.checkValidation() &&
                        _vm.SaveBlockDetail() &&
                        props.ok()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ])
          }
        }
      ])
    },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.thirdPartyAccountName.$error }
          },
          [
            _c(
              "label",
              {
                staticClass: "col-form-label col-md-3 required",
                attrs: { for: "thirdPartyName" }
              },
              [_vm._v("Name")]
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.thirdPartyAccountName.$model,
                      expression: "$v.thirdPartyAccountName.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "thirdPartyName",
                    readonly: _vm.readonly,
                    disabled: _vm.disabledFields
                  },
                  domProps: { value: _vm.$v.thirdPartyAccountName.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.thirdPartyAccountName,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "Name" },
                  model: {
                    value: _vm.$v.thirdPartyAccountName,
                    callback: function($$v) {
                      _vm.$set(_vm.$v, "thirdPartyAccountName", $$v)
                    },
                    expression: "$v.thirdPartyAccountName"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.thirdPartySortCode.$error }
          },
          [
            _c(
              "label",
              {
                staticClass: "label-control col-md-3 required",
                attrs: { for: "thirdPartySortCode" }
              },
              [_vm._v("Sort Code")]
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("the-mask", {
                  key: _vm.count,
                  ref: "thirdPartySortCode",
                  staticClass: "form-control",
                  attrs: {
                    id: "thirdPartySortCode",
                    type: "text",
                    mask: ["##-##-##"],
                    readonly: _vm.readonly,
                    disabled: _vm.disabledFields
                  },
                  model: {
                    value: _vm.$v.thirdPartySortCode.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.thirdPartySortCode, "$model", $$v)
                    },
                    expression: "$v.thirdPartySortCode.$model"
                  }
                })
              ],
              1
            ),
            _vm.bankDetailsValidated && _vm.sortCodeValid
              ? _c("div", { staticClass: "col-md-1" }, [
                  _c("span", {
                    staticClass: "fa fa-check-circle",
                    staticStyle: { "font-size": "200%", color: "green" }
                  })
                ])
              : _vm._e(),
            _vm.$v.thirdPartySortCode.$dirty
              ? _c("span", [
                  !_vm.$v.thirdPartySortCode.required
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [_vm._v("Sort Code is required")]
                      )
                    : _vm._e(),
                  !_vm.$v.thirdPartySortCode.minLength ||
                  !_vm.$v.thirdPartySortCode.maxLength
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [
                          _vm._v(
                            "Sort code must be " +
                              _vm._s(
                                _vm.$v.thirdPartySortCode.$params.minLength.min
                              ) +
                              " numbers"
                          )
                        ]
                      )
                    : _vm._e(),
                  !_vm.sortCodeValid && !_vm.redundantSortCodeMessage
                    ? _c(
                        "small",
                        {
                          staticClass:
                            "form-text text-warning small text-nowrap"
                        },
                        [_vm._v(_vm._s(_vm.sortCodeErrorMessage))]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.thirdPartyAccountNumber.$error }
          },
          [
            _c(
              "label",
              {
                staticClass: "label-control col-md-3 required",
                attrs: { for: "thirdPartyAccountNumber" }
              },
              [_vm._v("Account Number")]
            ),
            _c(
              "div",
              {
                staticClass: "col-md-3",
                class: { invalid: _vm.$v.thirdPartyAccountNumber.$error }
              },
              [
                _c("the-mask", {
                  ref: "thirdPartyAccountNumber",
                  staticClass: "form-control",
                  attrs: {
                    id: "thirdPartyAccountNumber",
                    type: "text",
                    mask: "########",
                    readonly: _vm.readonly,
                    disabled: _vm.disabledFields
                  },
                  model: {
                    value: _vm.$v.thirdPartyAccountNumber.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.thirdPartyAccountNumber,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.thirdPartyAccountNumber.$model"
                  }
                })
              ],
              1
            ),
            _vm.bankDetailsValidated && _vm.accountNumberValid
              ? _c("div", { staticClass: "col-md-1" }, [
                  _c("span", {
                    staticClass: "fa fa-check-circle",
                    staticStyle: { "font-size": "200%", color: "green" }
                  })
                ])
              : _vm._e(),
            _vm.$v.thirdPartyAccountNumber.$dirty
              ? _c("span", [
                  !_vm.$v.thirdPartyAccountNumber.required
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [_vm._v("Account Number is required")]
                      )
                    : _vm._e(),
                  !_vm.$v.thirdPartyAccountNumber.minLength
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [
                          _vm._v(
                            "Account Number must be " +
                              _vm._s(
                                _vm.$v.thirdPartyAccountNumber.$params.minLength
                                  .min
                              ) +
                              " numbers"
                          )
                        ]
                      )
                    : _vm._e(),
                  !_vm.$v.thirdPartyAccountNumber.maxLength
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-danger small text-nowrap"
                        },
                        [
                          _vm._v(
                            "Account Number must be " +
                              _vm._s(
                                _vm.$v.thirdPartyAccountNumber.$params.maxLength
                                  .max
                              ) +
                              " numbers"
                          )
                        ]
                      )
                    : _vm._e(),
                  !_vm.accountNumberValid && !_vm.redundantSortCodeMessage
                    ? _c(
                        "small",
                        {
                          staticClass:
                            "form-text text-warning small text-nowrap"
                        },
                        [_vm._v(_vm._s(_vm.accountNumberErrorMessage))]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.userReference.$error }
          },
          [
            _c(
              "label",
              {
                staticClass: "col-form-label col-md-3",
                class: { required: _vm.transactionCode != 99 },
                attrs: { for: "userReferenceControl" }
              },
              [_vm._v("User Reference")]
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.userReference.$model,
                      expression: "$v.userReference.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "userReferenceControl",
                    readonly: _vm.readonly,
                    disabled: _vm.disabledFields
                  },
                  domProps: { value: _vm.$v.userReference.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.userReference,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("validation-messages", {
                  attrs: { name: "user reference" },
                  model: {
                    value: _vm.$v.userReference,
                    callback: function($$v) {
                      _vm.$set(_vm.$v, "userReference", $$v)
                    },
                    expression: "$v.userReference"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.transactionCode.$error }
          },
          [
            _c(
              "label",
              {
                staticClass: "col-form-label col-md-3 required",
                attrs: { for: "transactionCodeSelector" }
              },
              [_vm._v("Transaction Code")]
            ),
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("b-form-select", {
                  attrs: {
                    id: "transactionCodeSelector",
                    options: _vm.transactionCodesSorted,
                    "value-field": "code",
                    "text-field": "code",
                    disabled: _vm.disabledFields,
                    readonly: _vm.readonly
                  },
                  on: {
                    input: function($event) {
                      return _vm.transactionCodeChanged()
                    }
                  },
                  model: {
                    value: _vm.$v.transactionCode.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.transactionCode, "$model", $$v)
                    },
                    expression: "$v.transactionCode.$model"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "div",
            { staticClass: "offset-3 col-6" },
            [
              _c("span", { staticClass: "form-text small" }, [
                _vm._v(_vm._s(_vm.transactionCodeDescription))
              ]),
              _c("validation-messages", {
                attrs: { name: "transaction code" },
                model: {
                  value: _vm.$v.transactionCode,
                  callback: function($$v) {
                    _vm.$set(_vm.$v, "transactionCode", $$v)
                  },
                  expression: "$v.transactionCode"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "form-group row",
            class: { invalid: _vm.$v.amount.$error }
          },
          [
            _c(
              "label",
              {
                staticClass: "col-form-label col-md-3 required",
                attrs: { for: "paymentAmount" }
              },
              [_vm._v("Amount")]
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("currency-input", {
                  attrs: {
                    id: "paymentAmount",
                    type: "text",
                    readonly: _vm.readonly,
                    disabled: _vm.disabledFields
                  },
                  model: {
                    value: _vm.$v.amount.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.amount, "$model", $$v)
                    },
                    expression: "$v.amount.$model"
                  }
                }),
                _c(
                  "validation-messages",
                  {
                    model: {
                      value: _vm.$v.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.$v, "amount", $$v)
                      },
                      expression: "$v.amount"
                    }
                  },
                  [
                    !_vm.$v.amount.amountZeroValidForTransactionCode
                      ? _c("small", { staticClass: "text-danger small" }, [
                          _vm._v(
                            "Amount must be £0.00 for this transaction code"
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.amount.amountNonZeroValidForTransactionCode
                      ? _c("small", { staticClass: "text-danger small" }, [
                          _vm._v(
                            "Amount must be greater than £0.00 for this transaction code"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isProcessingDateRequired && !_vm.isFps,
                expression: "isProcessingDateRequired && !isFps"
              }
            ],
            staticClass: "form-group row",
            class: { invalid: _vm.$v.processingDate.$error }
          },
          [
            _c(
              "label",
              {
                staticClass: "col-form-label col-md-3 required",
                attrs: { for: "processingDateDetails" }
              },
              [_vm._v("Processing Date")]
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("vuejsDatepicker", {
                  ref: "processingDate",
                  staticStyle: { backgroundColor: "'white'" },
                  attrs: {
                    name: "processingDate",
                    id: "processingDateDetails",
                    format: "dd/MM/yyyy",
                    "input-class": "form-control",
                    "bootstrap-styling": true,
                    disabledDates: _vm.disabledDates,
                    "monday-first": true
                  },
                  model: {
                    value: _vm.$v.processingDate.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.processingDate, "$model", $$v)
                    },
                    expression: "$v.processingDate.$model"
                  }
                }),
                _c("validation-messages", {
                  model: {
                    value: _vm.$v.processingDate,
                    callback: function($$v) {
                      _vm.$set(_vm.$v, "processingDate", $$v)
                    },
                    expression: "$v.processingDate"
                  }
                })
              ],
              1
            )
          ]
        ),
        _vm.validationMessages.length > 0
          ? _c(
              "span",
              [
                _c("b-table", {
                  staticClass: "mt-2",
                  attrs: {
                    borderless: "",
                    small: "",
                    "caption-top": "",
                    striped: true,
                    items: _vm.validationMessages,
                    fields: _vm.validationFields,
                    "per-page": 10
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(messageSeverity)",
                        fn: function(data) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "badge badge-pill table-pill",
                                class: data.value
                              },
                              [_vm._v(_vm._s(data.value))]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3527705608
                  )
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }