var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "viewSubmission" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.action.id !== undefined,
            expression: "action.id !== undefined"
          }
        ]
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.action.id !== "00000000-0000-0000-0000-000000000000",
                expression:
                  "action.id !== '00000000-0000-0000-0000-000000000000'"
              }
            ],
            attrs: { id: "actionDetails" }
          },
          [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" " + _vm._s(this.action.title) + " "),
                  _c("help-icon", { attrs: { docPath: _vm.helpUrl } })
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c("b-col", { attrs: { sm: "8" } }, [
              _vm.action.id !== "00000000-0000-0000-0000-000000000000"
                ? _c("div", [
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("strong", [_vm._v("Message")])
                      ]),
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._v(_vm._s(this.action.message))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.validationWarnings
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("strong", [_vm._v("Validation Messages")])
                    ]),
                    _c("div", { staticClass: "col-md-9" }, [
                      _c(
                        "span",
                        { staticClass: "badge badge-warning badge-pill" },
                        [
                          _vm._v(
                            "Submission contains " +
                              _vm._s(this.totalPresubValWarnings) +
                              " " +
                              _vm._s(this.validationTotalText)
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("strong", [_vm._v("Submission Reference")])
                ]),
                _c("div", { staticClass: "col-md-9" }, [
                  _vm._v(_vm._s(this.bacsSubmission.reference))
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("strong", [_vm._v("Group Name")])
                ]),
                _c("div", { staticClass: "col-md-9" }, [
                  _vm._v(_vm._s(this.groupName))
                ])
              ]),
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("strong", [_vm._v("Submission File")])
                ]),
                _c("div", { staticClass: "col-md-9" }, [
                  _vm._v(_vm._s(this.importedFilename))
                ])
              ]),
              _vm.BureauSummaryData.bureauSun !== ""
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("strong", [_vm._v("Bureau Sun")])
                    ]),
                    _c("div", { staticClass: "col-md-9" }, [
                      _vm._v(_vm._s(_vm.BureauSummaryData.bureauSun))
                    ])
                  ])
                : _vm._e(),
              _vm.isMultiFile
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("strong", [_vm._v("File SUN")])
                    ]),
                    _c("div", { staticClass: "col-md-9" }, [
                      _vm._v(_vm._s(_vm.selectedFileSun))
                    ])
                  ])
                : _vm._e(),
              _vm.bacsSubmission.paygateType === "Bureau"
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          attrs: { type: "button" },
                          on: { click: _vm.goToBureauReport }
                        },
                        [_vm._v("View Bureau Submission Report ")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.bacsSubmission.paygateType === "Bureau"
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("strong", [_vm._v("Job Name")])
                    ]),
                    _c("div", { staticClass: "col-md-9" }, [
                      _vm._v(
                        _vm._s(
                          _vm.BureauSummaryData.jobName !== null
                            ? _vm.BureauSummaryData.jobName
                            : "Bureau Builder"
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              this.isMultiFile === true
                ? _c("div", { staticClass: "row form-group" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("b", [_vm._v("Select Block")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            staticClass: "tall-dropdown",
                            attrs: {
                              text: _vm.selectedFile,
                              split: "",
                              variant: "outline-secondary"
                            }
                          },
                          _vm._l(_vm.intelligibleSummaries, function(summary) {
                            return _c(
                              "b-dropdown-item",
                              {
                                key: summary.fileNumber,
                                attrs: { value: summary.fileNumber },
                                on: {
                                  click: function($event) {
                                    return _vm.selectSummary(summary)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(summary.vocalinkSubId))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c("b", [_vm._v("Processing Date")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("vuejsDatepicker", {
                          ref: "processingDateDP",
                          style: { backgroundColor: "white" },
                          attrs: {
                            name: "processingDateDP",
                            id: "processingDateDP",
                            format: "dd/MM/yyyy",
                            "input-class": "form-control",
                            "bootstrap-styling": true,
                            disabledDates: _vm.disabledDates,
                            "monday-first": true
                          },
                          on: { selected: _vm.processingDateChanged },
                          nativeOn: {
                            focusin: function($event) {
                              return _vm.dateOnfocus($event)
                            }
                          },
                          model: {
                            value: _vm.processingDate,
                            callback: function($$v) {
                              _vm.processingDate = $$v
                            },
                            expression: "processingDate"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]),
            _vm.isLoaded
              ? _c(
                  "b-tabs",
                  {
                    attrs: { lazy: "" },
                    on: { input: _vm.tabSelected },
                    model: {
                      value: _vm.tabIndex,
                      callback: function($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex"
                    }
                  },
                  [
                    _vm.bacsSubmission.paygateType === "Bureau"
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Bureau Summary" } },
                          [_c("bureauSummary")],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-tab",
                      { attrs: { title: "Summary", active: "" } },
                      [_c("intelligibleSummary")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Payments" } },
                      [_c("payments")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Standard18" } },
                      [_c("standard18")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Submission History" } },
                      [_c("submissionHistory")],
                      1
                    ),
                    _vm.numberOfWarnings > 0
                      ? _c(
                          "b-tab",
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v(" Validation Messages "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge badge-warning badge-pill"
                                },
                                [_vm._v(_vm._s(this.numberOfWarnings))]
                              )
                            ]),
                            _c("validationWarnings")
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }