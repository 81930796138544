var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showModalFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showModalFileBrowser = false
                _vm.closePopup()
              }
            }
          })
        : _vm._e(),
      _vm._m(0),
      _c("hr"),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Service")]),
          _c("br"),
          _c(
            "p-radio",
            {
              attrs: { value: "BACS", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s4.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s4, "value", $$v)
                },
                expression: "selectedNode.props.s4.value"
              }
            },
            [_vm._v("BACS")]
          ),
          _c(
            "p-radio",
            {
              attrs: { value: "Faster Payments", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s4.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s4, "value", $$v)
                },
                expression: "selectedNode.props.s4.value"
              }
            },
            [_vm._v("Faster Payments")]
          )
        ],
        1
      ),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Group:")]),
        _c("span", { staticClass: "ml-3" }, [_vm._v(_vm._s(_vm.groupName))])
      ]),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Jobs")]),
        _c("br"),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedNode.props.s9.value,
                expression: "selectedNode.props.s9.value"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.selectedNode.props.s9,
                  "value",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          _vm._l(_vm.jobs, function(o) {
            return _c(
              "option",
              { key: o.bureauJobId, domProps: { value: o.bureauJobId } },
              [_vm._v(" " + _vm._s(o.name) + " ")]
            )
          }),
          0
        )
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("div", { staticClass: "form-group collapse" }, [
        _c("label", [_vm._v("Exception Handling Behaviour")]),
        _c("br"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "p-radio",
                  {
                    attrs: {
                      value:
                        "All payment instructions must validate before the entire submission can continue",
                      color: "primary"
                    },
                    model: {
                      value: _vm.selectedNode.props.s5.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s5, "value", $$v)
                      },
                      expression: "selectedNode.props.s5.value"
                    }
                  },
                  [
                    _vm._v(
                      "All payment instructions must validate before the entire submission can continue"
                    )
                  ]
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-md-12" }),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "p-radio",
                  {
                    attrs: {
                      value:
                        "Remove from the submision, those payment instructions that do not pass validation",
                      color: "primary"
                    },
                    model: {
                      value: _vm.selectedNode.props.s5.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s5, "value", $$v)
                      },
                      expression: "selectedNode.props.s5.value"
                    }
                  },
                  [
                    _vm._v(
                      "Remove from the submision, those payment instructions that do not pass validation"
                    )
                  ]
                )
              ],
              1
            ),
            _c("transition", { attrs: { name: "fadein" } }, [
              _vm.selectedNode.props.s5.value ===
              "Remove from the submision, those payment instructions that do not pass validation"
                ? _c(
                    "div",
                    { staticClass: "col-md-11 offset-md-1" },
                    [
                      _c("br"),
                      _c(
                        "p-check",
                        {
                          staticClass: "p-switch",
                          attrs: { name: "check", color: "primary" },
                          model: {
                            value: _vm.selectedNode.props.b4.value,
                            callback: function($$v) {
                              _vm.$set(_vm.selectedNode.props.b4, "value", $$v)
                            },
                            expression: "selectedNode.props.b4.value"
                          }
                        },
                        [
                          _vm._v(
                            "Duplicates - Payment instructions with a validation result of Duplicate will be removed from the submission"
                          )
                        ]
                      ),
                      _c("br"),
                      _c("br"),
                      _c(
                        "p-check",
                        {
                          staticClass: "p-switch",
                          attrs: { name: "check", color: "primary" },
                          model: {
                            value: _vm.selectedNode.props.b1.value,
                            callback: function($$v) {
                              _vm.$set(_vm.selectedNode.props.b1, "value", $$v)
                            },
                            expression: "selectedNode.props.b1.value"
                          }
                        },
                        [
                          _vm._v(
                            "Warnings - Payment instructions with a validation result of Warning will be removed from the submission"
                          )
                        ]
                      ),
                      _c("br"),
                      _c("br"),
                      _c(
                        "p-check",
                        {
                          staticClass: "p-switch",
                          attrs: {
                            disabled: "true",
                            name: "check",
                            color: "primary"
                          },
                          model: {
                            value: _vm.selectedNode.props.b2.value,
                            callback: function($$v) {
                              _vm.$set(_vm.selectedNode.props.b2, "value", $$v)
                            },
                            expression: "selectedNode.props.b2.value"
                          }
                        },
                        [
                          _vm._v(
                            "Errors - Payment instructions with a validation result of Fix will be removed from the submission"
                          )
                        ]
                      ),
                      _c("br"),
                      _c("br"),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c(
                              "p-check",
                              {
                                staticClass: "p-switch",
                                attrs: { name: "check", color: "primary" },
                                model: {
                                  value: _vm.selectedNode.props.b3.value,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedNode.props.b3,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "selectedNode.props.b3.value"
                                }
                              },
                              [_vm._v("Save removed transactions to a file")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("transition", { attrs: { name: "fadein" } }, [
                        _vm.selectedNode.props.b3.value
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-10" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2 offset-md-1" },
                                    [_c("label", [_vm._v("Save Path")])]
                                  ),
                                  _c("div", { staticClass: "col-md-8" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group mb-3" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.selectedNode.props.s11
                                                    .value,
                                                expression:
                                                  "selectedNode.props.s11.value"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              invalid:
                                                _vm.$v.selectedNode.props.s11
                                                  .value.$error
                                            },
                                            attrs: { type: "text" },
                                            domProps: {
                                              value:
                                                _vm.selectedNode.props.s11.value
                                            },
                                            on: {
                                              input: [
                                                function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.selectedNode.props.s11,
                                                    "value",
                                                    $event.target.value
                                                  )
                                                },
                                                function($event) {
                                                  return _vm.$v.selectedNode.props.s11.value.$touch()
                                                }
                                              ]
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input-group-append"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-secondary",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.btnFileBrowser(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("...")]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      !_vm.$v.selectedNode.props.s11.value
                                        .maxLength
                                        ? _c(
                                            "p",
                                            { staticClass: "validationText" },
                                            [
                                              _vm._v(
                                                "Maximum length is " +
                                                  _vm._s(
                                                    _vm.$v.selectedNode.props
                                                      .s11.value.$params
                                                      .maxLength.max
                                                  ) +
                                                  " characters!"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-4 offset-md-1" },
                                    [
                                      _c(
                                        "p-radio",
                                        {
                                          attrs: {
                                            value: "Simple JSON",
                                            color: "primary"
                                          },
                                          model: {
                                            value:
                                              _vm.selectedNode.props.s8.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.selectedNode.props.s8,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedNode.props.s8.value"
                                          }
                                        },
                                        [_vm._v("Simple JSON")]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c("br"),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-4 offset-md-1" },
                                    [
                                      _c(
                                        "p-radio",
                                        {
                                          attrs: {
                                            value: "Simple CSV",
                                            color: "primary"
                                          },
                                          model: {
                                            value:
                                              _vm.selectedNode.props.s8.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.selectedNode.props.s8,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedNode.props.s8.value"
                                          }
                                        },
                                        [_vm._v("Simple CSV")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _c("br"),
      _c("div", { staticClass: "form-group" }),
      _c("br"),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("BACS Bureau Job Processor"),
      _c("span", { staticClass: "pull-right" }, [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("i", { staticClass: "far fa-question-circle" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }