<template>
  <div>
    <!-- Service User Number -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Service User Number</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserNumber"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Import Schema -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Import Schema</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.importSchema"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Mapping -->
    <div class="form-group row" v-if="actionTypeDetails.importSchema === 'Mapping'">
      <label for="description" class="col-sm-2 text-right col-form-label">Mapping</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.mappingId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Bank Account -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Bank Account</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.bankAccountId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Payment Signing Method -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Payment Signing Method</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.tokenType"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- HSM Certificate -->
    <div class="form-group row" v-if="actionTypeDetails.tokenType === 'Hsm'">
      <label for="description" class="col-sm-2 text-right col-form-label">HSM Certificate</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.certificateId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Individual Payment Limit -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Individual Payment Limit</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.paymentLimit"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Submission  Limit -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Submission Payment Limit</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.submissionLimit"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Is indirect submitter group -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Is Indirect Submitter Group</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.isIndirectSubmitterGroup"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Bank grade group -->
    <div class="form-group row" v-show="bacsLicenceSettings.bankGrade === true">
      <label for="description" class="col-sm-2 text-right col-form-label">Bank Grade Group</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.bankGradeGroup"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Allow File Level Signing -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Allow File Level Signing</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.allowFileLevelSigning"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Segregate Group Roles -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Segregate Group Roles</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.segregateGroupRoles"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Delete Payment Data -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Delete Payment Data</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.deletePaymentData"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- HSM PIN required -->
    <div class="form-group row" v-if="actionTypeDetails.tokenType === 'Hsm'">
      <label for="description" class="col-sm-2 text-right col-form-label">HSM PIN Required</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="diff in difference.hsmPinRequired"
            :key="diff.count"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'

const guidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
const mongoIdRegex = /^[a-f\d]{24}$/

export default {
  props: ['groupTypeDetails', 'actionTypeDetails'],
  computed: {
    difference () {
      const diff = Object.keys(this.groupTypeDetails).reduce((a, v) => {
        if (this.groupTypeDetails[v] === null || this.groupTypeDetails[v] === undefined) {
          this.groupTypeDetails[v] = ''
        }

        if (!this.actionTypeDetails[v] === null || this.actionTypeDetails[v] === undefined) {
          this.actionTypeDetails[v] = ''
        }

        switch (v) {
          case 'bankAccountId':
            this.getBankAccounts()
            break
          case 'certificateId':
            this.getCertificate()
            break
          case 'mappingId':
            this.getMappings()
            break
        }

        var tempObj = {}

        tempObj[v] = diffSentences(this.groupTypeDetails[v].toString(), this.actionTypeDetails[v].toString())
        return Object.assign(a, tempObj)
      }, {})
      return diff
    }
  },
  data () {
    return {
      bacsLicenceSettings: {}
    }
  },
  async created () {
    // Get BACS licence
    try {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}licence`, { showerror: true, errormessage: 'Failed to get licence' })
      this.bacsLicenceSettings = response.data.bacs
    } catch (e) {
      this.$toastr.e(e.response.data, e.response.statusText)
    }
  },
  methods: {
    async getBankAccounts () {
      var promises = []

      if (guidRegex.test(this.groupTypeDetails.bankAccountId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.groupTypeDetails.bankAccountId}`, { showerror: true, errormessage: 'Failed to get group bank account', name: 'group' }))
      }
      if (guidRegex.test(this.actionTypeDetails.bankAccountId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.actionTypeDetails.bankAccountId}`, { showerror: true, errormessage: 'Failed to get action bank account', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.bankAccountId = value.data.reference
                break
              case 'action':
                this.actionTypeDetails.bankAccountId = value.data.reference
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    async getCertificate () {
      var promises = []

      if (guidRegex.test(this.groupTypeDetails.certificateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/${this.groupTypeDetails.certificateId}`, { showerror: true, errormessage: 'Failed to get group certificate', name: 'group' }))
      }
      if (guidRegex.test(this.actionTypeDetails.certificateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/${this.actionTypeDetails.certificateId}`, { showerror: true, errormessage: 'Failed to get action certificate', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.certificateId = value.data.friendlyName
                break
              case 'action':
                this.actionTypeDetails.certificateId = value.data.friendlyName
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    async getMappings () {
      var promises = []

      if (mongoIdRegex.test(this.groupTypeDetails.mappingId)) {
        promises.push(axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.groupTypeDetails.mappingId}`, { showerror: true, errormessage: 'Failed to get group mapping', name: 'group' }))
      }
      if (mongoIdRegex.test(this.actionTypeDetails.mappingId)) {
        promises.push(axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.actionTypeDetails.mappingId}`, { showerror: true, errormessage: 'Failed to get action mapping', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.mappingId = value.data.mapping.title
                break
              case 'action':
                this.actionTypeDetails.mappingId = value.data.mapping.title
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    }
  }
}
</script>
