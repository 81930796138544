<template>
    <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
                <h2><help-icon docPath="/paygate-collections/import-export/importpayer/" />Message failed to deliver</h2>
              </div>
              <div class="idb-block-content">
                <p>Message failed to send due to : {{ type=== 'bounced' ? 'Bounce' : 'Error' }}</p>    
                <p><strong>Title:</strong><br/>{{messageTitle}}</p>            
                <p><strong>Body:</strong><br/>{{messageBody}}</p>            
                <p><strong>Sent Date:</strong><br/>{{sentDate}}</p>            
                <p><strong>Error:</strong><br/>{{messageError}}</p> 
              </div>
              <div class="idb-block-footer">
                <b-button type="submit" :disabled="sent" variant="primary" @click.prevent="resendMessage" >Resend Message</b-button>
                <b-button class="ml-2" variant="primary" @click.prevent="removeAction" v-if="actionId && actionId !== null">Clear Action</b-button>
              </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
export default {
  props: {
    messageId: String,
    type: String
  },
  data () {
    return {
      actionId: null,
      sent: false
    }
  },
  methods: {
    removeAction () {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/clearaction/${this.actionId}`).then(
      (response) => {
        this.$toastr.s('Action cleared')
        this.actionId = null
        this.$router.push('/actions')
      }
      )
    },
    resendMessage () {
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}messages/resend/${this.messageId}`).then(
      (response) => {
        this.$toastr.s('Message Sent')
        this.sent = true
      }
      )
    },
  },
  computed: {
    channelType () {
      if (this.$store.state.messages.currentMessage) {
        return this.$store.state.messages.currentMessage.channelType
      } else {
        return null
      }
    },
    messageTitle () {
      return this.$store.state.messages.currentMessage.messageTitle
    },
    senderName () {
      return this.$store.state.messages.currentMessage.senderName
    },
    messageBody () {
      return this.$store.state.messages.currentMessage.messageBody
    },
    message () {
      return this.$store.state.messages.currentMessage
    },
    messageError () {
      return this.$store.state.messages.messageError
    },
    sentDate () {
      return this.$store.state.messages.sentDate
    },
    previewUrl () {
      if (this.message.payerMessageId) {
        const pdfUrl = encodeURI(`${process.env.VUE_APP_DDMS_API_URL}messages/letterpreview/${this.message.payerMessageId}`)
        var previewUrl = `${process.env.VUE_APP_PDFVIEWER_URL}?file=${pdfUrl}&token=${this.bearerToken}`
        return previewUrl
      } else {
        return process.env.VUE_APP_PDFVIEWER_URL
      }
    }
  },
  async mounted () {
    this.actionId = this.$route.query.actionId
    this.bearerToken = await auth.getAccessToken()
    try {
      await this.$store.dispatch('loadMessageDetails', this.messageId)
    } catch (e) {
      this.$toastr.e('Unable to load message details.', 'An error occurred')
    }
  },
  async created () {
    try {
      await this.$store.dispatch('loadMessageDetails', this.messageId)
    } catch (e) {
      this.$toastr.e('Unable to load message details.', 'An error occurred')
    }
  }
}
</script>