var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveHsm($event)
        }
      }
    },
    [
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.hsm.cryptoLibrary.$error }
            },
            [
              _c("label", { staticClass: "col-form-label col-md-3 required" }, [
                _vm._v("Library Path")
              ]),
              _c("div", { staticClass: "col-md-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.hsm.cryptoLibrary.$model,
                      expression: "$v.hsm.cryptoLibrary.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.$v.hsm.cryptoLibrary.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.hsm.cryptoLibrary,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm.$v.hsm.cryptoLibrary.$error
                  ? _c("span", { staticClass: "validation-messages" }, [
                      !_vm.$v.hsm.cryptoLibrary.required
                        ? _c("small", { staticClass: "form-text small" }, [
                            _vm._v(
                              "Please enter a path to the HSM crytographic library"
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-form-label col-md-3",
                attrs: { for: "hsmEnabled" }
              },
              [_vm._v("Enabled")]
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("p-check", {
                  staticClass: "p-switch p-fill",
                  attrs: { labelId: "hsmEnabled", color: "primary" },
                  model: {
                    value: _vm.$v.hsm.enabled.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.hsm.enabled, "$model", $$v)
                    },
                    expression: "$v.hsm.enabled.$model"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-form-label col-md-3" }, [
              _vm._v("PIN")
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.$v.hsm.pin.$model,
                    expression: "$v.hsm.pin.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: _vm.hsmPinToggled ? "text" : "password" },
                domProps: { value: _vm.$v.hsm.pin.$model },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.hsm.pin,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("span", {
                staticClass: "fa fa-fw field-icon",
                class: {
                  "fa-eye-slash": _vm.hsmPinToggled,
                  "fa-eye": !_vm.hsmPinToggled
                },
                on: {
                  mousedown: function($event) {
                    return _vm.toggleHSMPin(true)
                  },
                  mouseup: function($event) {
                    return _vm.toggleHSMPin(false)
                  }
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "idb-block-footer" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", attrs: { type: "submit" } },
            [_vm._v("Save")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-danger pull-right",
              attrs: { type: "button" },
              on: { click: _vm.cancel }
            },
            [_vm._v("Cancel")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Manage HSM")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }