const state = {
  preference: false,
  tracking: false
}

const getters = {
  doCookiesExist: () => {
    return Boolean(localStorage.getItem('cookiePolicy'))
  }
}

const mutations = {
  updateCookiePolicy (state, cookiePolicy) {
    state.preference = cookiePolicy.cookies.preference
    state.tracking = cookiePolicy.cookies.tracking
    localStorage.setItem('cookiePolicy', JSON.stringify(state))
    var vm = cookiePolicy.vm
    if (state.tracking) {
      vm.$gtag.optIn()
    } else {
      vm.$gtag.optOut()
      // We must kill the cookies!
      deleteCookie('_ga')
      deleteCookie('_gid')
    }
  }
}

const actions = {
  loadCookies ({ commit, getters }, vm) {
    if (getters.doCookiesExist) {
      var cookies = localStorage.getItem('cookiePolicy')
      commit('updateCookiePolicy', { cookies: JSON.parse(cookies), vm })
    }
  }
}

function deleteCookie (name, path, domain) {
  if (getCookie(name)) {
    document.cookie = name + '=' +
      ((path) ? ';path=' + path : '') +
      ((domain) ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}

function getCookie (name) {
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=')
  })
}

export default {
  state,
  getters,
  mutations,
  actions
}
