<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/plans/scheduledchanges/" />Scheduled Plan Changes<favourite-icon></favourite-icon></h2>
    </div>
    <div>
      <div class="m-3">
        <div class="row form-group">
          <div class="col-md-2 required">
            Select a Group
          </div>
          <div class="col-md-4">
            <group-select
              v-model="group"
              :groups="customerGroups"
              @input="customerGroupsChanged"
              :clearable="false"
            ></group-select>
            </div>
        </div>
        <b-row  class="planRow">
          <b-tabs vertical nav-wrapper-class="col-3 vertical-tab-container" nav-class="w-90 ml-2 vertical-tab-list" v-model="activeTabIndex">
            <b-tab v-for="(paymentPlan, index) in paymentPlans" :key="index" @click="planSelected(paymentPlan)" :title="paymentPlan.name">
              <b-form-row>
                <b-form-group :label-cols="7" horizontal label="Current Regular Amount">
                  <b-form-input
                    type="text"
                    class="form-control w-100"
                    v-model="paymentPlan.plan.regularAmount"
                    :disabled="true"
                  />
                </b-form-group>
              </b-form-row>
              <vue-good-table
                :columns="columns"
                :rows="currentFpc"
                :lineNumbers="true"
                :totalRows="totalRecords"
                :rowStyleClass="rowStyler"
                :search-options="{
                    enabled: false
                    }"
                :pagination-options="{
                    enabled: false
                  }"
                @on-row-click="onRowClick"
                @on-cell-click="onCellClick"
                styleClass="vgt-table striped bordered"
                ref="futurePlanTable"
              >
                <template slot="loadingContent">
                  <h1>Loading...</h1>
                </template>
                <div slot="emptystate">
                  <div class="vgt-center-align vgt-text-disabled">No Plan Templates Available</div>
                </div>
                <div slot="table-actions">
                  <b-button
                    @click.prevent="addPayment"
                    class
                    variant="link"
                    title="Add Payment"
                    v-b-popover.hover.top.d500="'Add payment'" :disabled="isLoading"
                  >
                    <i class="fa fa-plus pointer dimmedIcon"></i><span class="sr-only">Refresh Scheduled Change</span>
                  </b-button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.row.subs && props.row.subs.length > 0">
                    <div
                      v-for="(sub,index) in props.row.subs"
                      :key="index"
                      @click="switchToSubEdit(props.index, index, props.column.label)"
                      :class="index > 0 ? 'subRow' : ''"
                    >
                      <span v-if="props.column.label=='Trigger'">
                        <span
                          v-if="!isEditState(props.index, index) && index === 0"
                        >{{ sub.triggerType === 0 ? formatFpcDate(sub.startDate) : sub.paymentsMade }}</span>
                        <span v-if="!isEditState(props.index, index) && index !== 0">&nbsp;</span>
                        <datepicker v-if="sub.triggerType === 0 && isEditState(props.index, index)" v-model="sub.startDate" :disabledDates="disabledDates" />
                        <input type="number" v-if="sub.triggerType === 1 && isEditState(props.index, index)" v-model="sub.paymentsMade" />
                      </span>
                      <span v-if="props.column.field == 'changeAmount'">
                        <span
                          v-if="!isEditState(props.index, index)"
                        >{{props.row.subs.length > 1 ? (index + 1) + ': ' : ''}}{{formatValue(sub)}}</span>
                        <input
                          type="number"
                          v-if="isEditState(props.index, index)"
                          v-model="sub.changeAmount" style="width: 60px"
                        />
                      </span>
                      <span v-else-if="props.column.field == 'changeType'">
                        <span v-if="!isEditState(props.index, index)">{{ formatChangeType(sub)}}</span>
                        <b-form-select
                          v-if="isEditState(props.index, index)"
                          :options="changeTypes"
                          text-field="name"
                          value-field="value"
                          v-model="sub.changeType"
                        ></b-form-select>
                      </span>
                      <span v-else-if="props.column.field == 'triggerType'">
                        <span v-if="!isEditState(props.index, index)">{{ formatTriggerType(sub.triggerType)}}</span>
                        <b-form-select
                          v-if="isEditState(props.index, index)"
                          :options="triggerTypes"
                          text-field="name"
                          value-field="value"
                          v-model="sub.triggerType"
                        ></b-form-select>
                      </span>
                      <span v-else-if="props.column.label == ''">
                        <input type="hidden" v-model="sub.orderIndex" />
                        <span
                          v-if="props.row.subs.length > 1 && !isEditState(props.index, index) && !sub.applied"
                          @click="moveUp(props.row.subs, index, props.index)"
                          :class="!canMove(props.row.subs, index, -1) ? 'disabled-order' : null"
                        >
                          <i class="fa fa-arrow-up mr-2" title="Move Up"></i>
                        </span>
                        <span
                          v-if="props.row.subs.length > 1 && !isEditState(props.index, index) && !sub.applied"
                          @click="moveDown(props.row.subs, index, props.index)"
                          :class="!canMove(props.row.subs, index, 1) ? 'disabled-order' : null"
                        >
                          <i class="fa fa-arrow-down mr-2" title="Move Down"></i>
                        </span>
                        <i class="fa fa-lock ml-2" v-if="sub.applied && index === 0" title="Locked"></i>
                        <span v-if="isEditState(props.index, index)">
                          <b-button variant="primary" @click="saveFpc(sub)" title="Confirm Changes">
                            <i class="fa fa-check mr-1"></i> Confirm
                          </b-button>
                          <b-button :disabled="isLoading"
                            variant="outline-warning"
                            @click="cancelFpc(sub)"
                            title="Cancel Changes"
                          >
                            <i class="fa fa-times mr-1"></i> Cancel
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            @click="deleteFpc(sub)"
                            title="Delete"
                            :disabled="props.row.applied || isLoading"
                          >
                            <i class="fa fa-trash mr-1"></i> Delete
                          </b-button>
                        </span>
                      </span>
                      <span v-else></span>
                    </div>
                  </span>
                </template>
              </vue-good-table>
              <b-button
                v-if="selectedPlan != null"
                @click="saveFutureChanges"
                class="mt-2"
                variant="primary" :disabled="isLoading"
              >
                <i class="fa fa-save mr-2" aria-hidden="true"></i>Save
              </b-button>
            </b-tab>
            <template v-slot:empty>
              There are no plan templates associated with this group.
            </template>
          </b-tabs>
        </b-row>
      </div>
    </div>
    <div class="idb-block-footer"></div>
  </div>
</template>
<script>
import axios from 'axios'
import EventBus from '@/Lib/eventBus'
import Datepicker from 'vuejs-datepicker'
import Moment from 'moment'
import _ from 'lodash'
import { firstBy } from 'thenby'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  components: {
    Datepicker
  },
  data () {
    return {
      group: '00000000-0000-0000-0000-000000000000',
      activeTabIndex: 0,
      selectedPaymentPlan: {
        reference: '',
        recurrenceEndType: 0,
        recurrenceType: 0,
        ordinal: 1,
        firstAmount: 0,
        regularAmount: 0,
        totalPayments: 0,
        firstPaymentDate: new Date(),
        giftAid: false,
        variable: false,
        dayOfYear: 1 },
      selectedPlan: null,
      selectedPlanTemplateId: null,
      editRowIndex: -1,
      editSubIndex: -1,
      newRowIndex: -1,
      paymentPlans: [],
      changeTypes: [{ name: 'Replace old amount', value: 'NewValue' }, { name: 'Numeric Change', value: 'NumericModifier' }, { name: 'Percentage Change', value: 'PercentageModifier' }],
      triggerTypes: [{ name: 'After Date', value: 0 }, { name: 'After X Payments', value: 1 }],
      currentFpc: [],
      planName: null,
      oldVersion: null,
      columns: [
        {
          label: 'Amount',
          field: 'changeAmount'
        },
        {
          label: 'Trigger',
          field: 'planTemplateId'
        },
        {
          label: 'Applied',
          field: 'applied'
        },
        {
          label: 'Change Type',
          field: 'changeType'
        },
        {
          label: 'Trigger Type',
          field: 'triggerType'
        },
        {
          label: '',
          field: 'control'
        }
      ],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: 'surname', type: 'asc' }],
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      selectedRow: null
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'Shared Templates', 'paygateId': this.paygateid, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    },
    disabledDates: function () {
      let maxDate = new Date(8640000000000000)
      let minDate = new Date()
      return {
        days: [0, 6],
        // daysOfMonth: [29, 30, 31],
        dates: this.$store.getters.nonProcessingDates,
        to: minDate,
        from: maxDate
      }
    }
  },
  formatDate (date) {
    return Moment(date).format('DD/MM/YYYY')
  },
  async created (e) {
    this.$store.dispatch('getNonProcessingDates')
    this.$store.dispatch('populateAllCollectionsGroups')
  },
  async mounted (e) {
    this.loadGroups(this.paygateId)
    const onPaygateChange = (paygateId) => {
      this.loadGroups(paygateId)
    }
    EventBus.$on('paygateIdChange', onPaygateChange)
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })
    this.group = '00000000-0000-0000-0000-000000000000'
    await this.getPlans()
  },
  methods: {
    planSelected (plan) {
      this.selectedPaymentPlan = plan
      this.selectedPlanTemplateId = plan.paymentPlanTemplateId
      this.selectedPlan = this.toCamel(JSON.parse(plan.templateJson))
      if (!this.selectedPaymentPlan.futurePlanChanges) {
        this.selectedPaymentPlan.futurePlanChanges = []
      }
      for (var i = 0; i < this.selectedPaymentPlan.futurePlanChanges.length; i++) {
        if (!isNaN(this.selectedPaymentPlan.futurePlanChanges[i].changeType)) {
          this.selectedPaymentPlan.futurePlanChanges[i].changeType = this.changeTypes[this.selectedPaymentPlan.futurePlanChanges[i].changeType].value
        }
      }

      this.currentFpc = _.clone(this.selectedPaymentPlan.futurePlanChanges)
      this.sortData()
      this.editRowIndex = -1
      this.editSubIndex = -1
    },
    sortData () {
      this.currentFpc.sort(firstBy((a, b) => { return new Moment(a.startDate).format('YYYYMMDD') - new Moment(b.startDate).format('YYYYMMDD') }).thenBy('orderIndex'))
      var grouped = _.groupBy(this.currentFpc, (x) => { return new Moment(x.startDate).format('YYYYMMDD') })
      var groupedFpc = []
      for (var prop in grouped) {
        var item = grouped[prop]
        groupedFpc.push({ startDate: item[0].startDate, applied: item[0].applied, appliedDate: item[0].appliedDate, subs: item })
      }
      this.currentFpc = groupedFpc
    },
    loadGroups (paygateId) {
      this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    async customerGroupsChanged (e) {
      this.selectedPaymentPlan = null
      this.selectedPlan = null
      this.activeTabIndex = 0
      await this.getPlans()
    },
    async getPlans () {
      var groupId = this.group === null ? '00000000-0000-0000-0000-000000000000' : this.group
      var resp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/group/${groupId}`, { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true })
      this.paymentPlans = resp.data
      if (this.paymentPlans.length > 0) {
        this.selectedPlan = this.paymentPlans[0].plan
        this.selectedPaymentPlan = this.paymentPlans[0].plan
        this.selectedPlanClosed = this.paymentPlans[0].plan.planStatus === 0
        this.planSelected(this.selectedPlan)
      }
    },
    reloadItems: async function () {
      // not implemented
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {
      // not implemented
    },
    onSortChange (params) {
      // not implemented
    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
    },
    onPerPageChange (params) {
      // not implemented
    },
    onRowClick (params) {
      // not implemented
    },
    onCellClick (params) {
      // if (params.column.field !== 'control') {
      //   this.oldVersion = _.clone(params.row)
      //   this.editRowIndex = params.rowIndex
      // }
    },
    onSearch (params) {
      // not implemented
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    addPayment () {
      var now = new Date()
      now.setHours(24, 0, 0, 0)
      var item = {
        ukddmsFuturePlanChangeId: '00000000-0000-0000-0000-000000000000',
        planTemplateId: this.selectedPlanTemplateId,
        changeAmount: 0,
        startDate: now.toISOString(),
        applied: false,
        appliedDate: '',
        planId: '00000000-0000-0000-0000-000000000000',
        changeType: 'NewValue',
        orderIndex: 0,
        triggerType: 0,
        paymentsMade: -1
      }
      var newPlannedChange = { startDate: item.startDate, applied: item.applied, appliedDate: item.appliedDate, subs: [item] }
      this.currentFpc.push(newPlannedChange)
      this.editRowIndex = this.currentFpc.length - 1
      this.editSubIndex = 0
      this.newRowIndex = this.editRowIndex
    },
    rowStyler (row) {
      return this.currentFpc.filter(x => new Moment(x.startDate).format('YYYYMMDD') === new Moment(row.startDate).format('YYYYMMDD')).length > 1 ? 'grouped ' : ''
    },
    saveFpc (e) {
      console.log(e)
      e.startDate = Moment(e.startDate).toDate().setHours(0, 0, 0, 0)
      e.startDate = Moment(e.startDate).toDate().toISOString()
      this.currentFpc[this.editRowIndex].subs[this.editSubIndex] = e

      this.currentFpc = this.currentFpc.sort(firstBy((a, b) => { return new Moment(a.startDate).format('YYYYMMDD') - new Moment(b.startDate).format('YYYYMMDD') }).thenBy('orderIndex'))
      var flatFpc = this.flattenFpc()
      this.selectedPlan.futurePlanChanges = flatFpc
      this.currentFpc = flatFpc
      this.sortData()
      this.editRowIndex = -1
      this.editSubIndex = -1
      this.getPlans()
    },
    flattenFpc () {
      var flatFpc = []
      for (var i = 0; i < this.currentFpc.length; i++) {
        var fpc = this.currentFpc[i]
        for (var s = 0; s < fpc.subs.length; s++) {
          var sub = fpc.subs[s]
          flatFpc.push(sub)
        }
      }
      return flatFpc
    },
    cancelFpc (e) {
      if (this.oldVersion) {
        e = this.oldVersion
        this.currentFpc[this.editRowIndex].subs[this.editSubIndex] = _.clone(this.oldVersion)
      } else {
        this.currentFpc[this.editRowIndex].subs.splice(this.editSubIndex, 1)
      }
      var flatFpc = this.flattenFpc()
      this.selectedPlan.futurePlanChanges = flatFpc
      this.currentFpc = flatFpc
      this.sortData()
      this.editRowIndex = -1
      this.editSubIndex = -1
    },
    deleteFpc (e) {
      this.currentFpc[this.editRowIndex].splice(this.editSubIndex, 1)
      if (this.currentFpc[this.editRowIndex].subs.length === 0) {
        this.currentFpc.splice(this.editRowIndex, 1)
      }
      this.editRowIndex = -1
      this.editSubIndex = -1
    },
    async saveFutureChanges () {
      var flatFpc = this.flattenFpc()
      this.selectedPlan.futurePlanChanges = flatFpc
      try {
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}futureplanchange/${this.selectedPlan.planTemplateId}`, flatFpc, { showload: true })
        if (response) {
          console.log(response)
          this.$toastr.s('Saved')
        }
      } catch (ex) {
        console.log(ex)
        this.$toastr.e('An exception occurred saving the future changes.')
      }
    },
    formatCurrency (value) {
      var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      return formatter.format(value)
    },
    formatChangeType (value) {
      if (value.changeType != null) {
        return this.changeTypes.find(f => f.value === value.changeType).name
      } else {
        return ''
      }
    },
    formatValue (row) {
      var val = ''
      switch (row.changeType) {
        case 0:
        case 'NewValue':
          val = this.formatCurrency(row.changeAmount)
          break
        case 1:
        case 'NumericModifier':
          val = row.changeAmount > 0 ? '+' + this.formatCurrency(row.changeAmount) : '-' + this.formatCurrency(row.changeAmount * -1)
          break
        case 2:
        case 'PercentageModifier':
          val = (row.changeAmount > 0 ? '+' : '-') + row.changeAmount + '%'
          break
        default:
          break
      }
      return val
    },
    moveUp (itemArray, itemIndex, rowIndex) {
      var toIndex = itemIndex - 1 >= 0 ? itemIndex - 1 : 0
      var element = itemArray[itemIndex]
      if (this.canMove(itemArray, itemIndex, -1)) {
        itemArray.splice(itemIndex, 1)
        itemArray.splice(toIndex, 0, element)
      }
      this.currentFpc[rowIndex].subs = itemArray
    },
    moveDown (itemArray, itemIndex, rowIndex) {
      var toIndex = itemIndex + 1 < itemArray.length - 1 ? itemIndex + 1 : itemArray.length
      var element = itemArray[itemIndex]
      if (this.canMove(itemArray, itemIndex, 1)) {
        itemArray.splice(itemIndex, 1)
        itemArray.splice(toIndex, 0, element)
      }
      this.currentFpc[rowIndex].subs = itemArray
    },
    canMove (itemArray, index, delta) {
      var canGo = false
      var checkItem = itemArray[index + delta]
      if (checkItem && !itemArray[index].applied) {
        canGo = true
      }
      return canGo
    },
    switchToSubEdit (rowIndex, subIndex, label) {
      if (this.editSubIndex === -1 && this.editRowIndex === -1 && label !== '' && !this.currentFpc[rowIndex].subs[subIndex].applied) {
        this.editRowIndex = rowIndex
        this.editSubIndex = subIndex
        this.oldVersion = _.clone(this.currentFpc[rowIndex].subs[subIndex])
      }
    },
    isEditState (rowIndex, subIndex) {
      return this.editRowIndex === rowIndex && this.editSubIndex === subIndex
    },
    toCamel (o) {
      var newO, origKey, newKey, value
      if (o instanceof Array) {
        return o.map(function (value) {
          if (typeof value === 'object') {
            value = this.toCamel(value)
          }
          return value
        })
      } else {
        newO = {}
        for (origKey in o) {
          if (o.hasOwnProperty(origKey)) {
            newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
            value = o[origKey]
            if (value instanceof Array || (value !== null && value.constructor === Object)) {
              value = this.toCamel(value)
            }
            newO[newKey] = value
          }
        }
      }
      return newO
    },
    formatFpcDate (value) {
      return Moment(value).format('DD/MM/YYYY')
    },
    formatTriggerType (value) {
      return value === 0 ? 'After Date' : 'After X Payments Made'
    }
  }
}

</script>
<style>
.disabled-order,
.unlocked {
  color: #aaaaaa;
}
.grouped td {
  background-color: #eeeeee !important;
}
.grouped .startDateClass {
  color: red;
}
.subRow {
  margin-top: 10px;
  padding-top: 10px;
}

.vertical-tab-list {
  border-bottom: solid 0px black !important;
  border-right: 0px solid #ddd;
}

.vertical-tab-container {
  border-bottom: solid 0px black !important;
  border-right: 1px solid #ddd;
  padding-right: 0px;
}

.vertical-tab-list .nav-item .active, .vertical-tab-list .nav-item .nav-link:hover {
  border: 1px solid #ddd;
  border-right: solid 1px transparent;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  margin: 0
}
.planRow .tabs {
  width: 100%
}
</style>
