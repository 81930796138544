<template>
<div class="row">
  <div class="col-md-12">
  <div class="form-group">
    <label>{{label}}</label>
    <select class="form-control" :multiple="multi"
      v-model=value
      @input="$emit('input', $event.target.value)">
      <option v-for="option in options" :key="option">
        {{option}}
      </option>
    </select>
  </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'SelectList',
  props: [
    'multi',
    'options',
    'name',
    'label',
    'value'
  ],
  data () {
    return {
    }
  }
}
</script>
