var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          _vm.checkValidation() && _vm.saveServiceAccount()
        }
      }
    },
    [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v(" " + _vm._s(_vm.status) + " Service Account "),
              _c("help-icon", { attrs: { docPath: _vm.docPath } })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.serviceAccount.type.$error }
            },
            [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 text-right col-form-label required",
                  attrs: { for: "description" }
                },
                [_vm._v("Type")]
              ),
              _c("div", { staticClass: "col-sm-3" }, [
                _vm.status == "Edit"
                  ? _c("div", { staticClass: "form-control-plaintext" }, [
                      _c("span", { staticStyle: { "margin-right": "5px" } }, [
                        _vm._v(_vm._s(_vm.serviceAccount.type))
                      ])
                    ])
                  : _c(
                      "div",
                      [
                        _c("b-form-select", {
                          staticClass: "form-input",
                          attrs: { options: _vm.serviceAccountTypes },
                          model: {
                            value: _vm.$v.serviceAccount.type.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.serviceAccount.type,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.serviceAccount.type.$model"
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.$v.serviceAccount.type,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.serviceAccount, "type", $$v)
                            },
                            expression: "$v.serviceAccount.type"
                          }
                        })
                      ],
                      1
                    )
              ])
            ]
          ),
          _vm.serviceAccount.type === "API"
            ? _c(
                "div",
                {
                  staticClass: "form-group row",
                  class: { invalid: _vm.$v.serviceAccount.username.$error }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      class: { required: _vm.status == "Create" },
                      attrs: { for: "description" }
                    },
                    [_vm._v("Username")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _vm.status == "Create"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.serviceAccount.username.$model,
                                expression: "$v.serviceAccount.username.$model"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.$v.serviceAccount.username.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.serviceAccount.username,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        : _c(
                            "span",
                            { staticStyle: { "margin-right": "5px" } },
                            [_vm._v(_vm._s(_vm.serviceAccount.username))]
                          ),
                      _c(
                        "validation-messages",
                        {
                          attrs: { name: "username" },
                          model: {
                            value: _vm.$v.serviceAccount.username,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.serviceAccount, "username", $$v)
                            },
                            expression: "$v.serviceAccount.username"
                          }
                        },
                        [
                          _vm.$v.serviceAccount.username.whitespace !=
                            undefined &&
                          !_vm.$v.serviceAccount.username.whitespace
                            ? _c("small", { staticClass: "form-text small" }, [
                                _vm._v(
                                  "The username cannot contain whitespaces, only alphanumeric characters"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "description" }
              },
              [_vm._v("Description")]
            ),
            _c("div", { staticClass: "col-sm-5" }, [
              _vm.serviceAccount.type !== "Internal"
                ? _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.serviceAccount.description.$model,
                        expression: "$v.serviceAccount.description.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { rows: "3" },
                    domProps: {
                      value: _vm.$v.serviceAccount.description.$model
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.serviceAccount.description,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                : _c("div", { staticClass: "form-control-plaintext" }, [
                    _c("span", { staticStyle: { "margin-right": "5px" } }, [
                      _vm._v(_vm._s(_vm.serviceAccount.description))
                    ])
                  ])
            ])
          ]),
          _vm.status == "Edit"
            ? _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("API Key")]
                ),
                _c("div", { staticClass: "col-sm-7" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.serviceAccount.passwordHidden,
                          expression: "serviceAccount.passwordHidden"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: _vm.serviceAccount.passwordHidden },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.serviceAccount,
                            "passwordHidden",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-info ml-3",
                          attrs: { type: "button" },
                          on: { click: _vm.revealPassword }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.passwordRevealed ? "Hide" : "Reveal")
                          )
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary ml-3",
                          attrs: { type: "button" },
                          on: { click: _vm.copyPassword }
                        },
                        [_vm._v("Copy Key")]
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c("br"),
          _c("br")
        ]),
        _c("div", { staticClass: "idb-block-footer button-block" }, [
          _vm.status == "Create" || _vm.serviceAccount.type !== "Internal"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-3",
                  attrs: { disabled: _vm.isLoading, type: "submit" }
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _vm.serviceAccount.type === "API" && _vm.status == "Edit"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary mr-3",
                  attrs: { disabled: _vm.isLoading, type: "button" },
                  on: { click: _vm.gotoApiEdit }
                },
                [_vm._v("Edit Roles")]
              )
            : _vm._e(),
          _vm.status == "Edit"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-danger pull-right",
                  attrs: { disabled: _vm.isLoading, type: "button" },
                  on: { click: _vm.deleteApiKey }
                },
                [
                  _c("i", { staticClass: "glyphicon ti-trash rpad" }),
                  _vm._v("Delete Service Account ")
                ]
              )
            : _vm._e(),
          _vm.status == "Edit"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger pull-right mr-3",
                  attrs: { disabled: _vm.isLoading, type: "button" },
                  on: { click: _vm.resetApiKey }
                },
                [_vm._v("Reset API Key")]
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }