<template>
	<div>
		<transition name="fade-long">
			<div
				v-if="!loading"
				class="app-container"
				:class="{ 'collapsed-sidebar': sidebarCollapse, 'dark-mode': darkModeEvent }"
			>
				<app-header
					:displayCustomerSelector="displayCustomerSelector"
					:displayHeader="displayHeader"
					:sidebarCollapsed="sidebarCollapse"
				></app-header>
				<div class="sidebar-wrapper">
					<app-sidebar-nav></app-sidebar-nav>
				</div>
				<!-- Sidebar panel closed -->
				<div class="main-content">
					<!-- header closed -->
					<div class="base-container" id="base-container">
						<div class="inner-container" id="inner-container">
							<transition name="fade" mode="out-in">
								<div v-if="licences">
									<not-licenced></not-licenced>
								</div>
								<div v-if="unauthorized">
									<unauthorized></unauthorized>
								</div>
								<router-view v-if="!unauthorized && !licences" :key="$route.fullPath"></router-view>
							</transition>
						</div>
					</div>
					<!-- base container closed -->
					<cookie-message></cookie-message>
				</div>
				<!-- Main Content closed -->
			</div>
		</transition>
	</div>
	<!-- app container closed -->
</template>

<script>
import Header from '@/Assets/Components/Header'
import SideNav from '@/Assets/Components/SideNav'
import EventBus from '@/Lib/eventBus'
import Auth from '@/Assets/Components/Authentication/auth'
import CookieMessage from '@/Assets/Components/Cookies/CookieMessage'
import axios from 'axios'
import $ from 'jquery'

import Vue from 'vue'
import Websockets from '@/Assets/Websockets/Main'
import store from '@/Store/index'
import { mapState, mapGetters } from 'vuex'
import Unauthorized from '@/Assets/Components/Authorization/Unauthorized'
import NotLicenced from '@/Assets/Components/Authorization/NotLicenced'
import roles from '@/Assets/Constants/roles'

export default {
	name: 'Full',
	props: {
		displayHeader: {
			default: true
		}
	},
	data: function () {
		return {
			collapsed: false,
			boxLayout: false,
			darkMode: false,
			themeColor: '',
			displayCustomerSelector: false
		}
	},
	components: {
		'app-header': Header,
		'app-sidebar-nav': SideNav,
		CookieMessage,
		Unauthorized,
		NotLicenced
	},
	async created () {
		// Add auth to app
		try {
			await this.$store.dispatch('loadCustomersList')
			await this.$store.dispatch('loadUser')

			Vue.use(Websockets, process.env.VUE_APP_PLATFORM_API_URL + 'hubs/actions', {
				store,
				reconnection: true, // (Boolean) whether to reconnect automatically (false)
				reconnectionAttempts: Infinity,
				reconnectionDelay: 10000,
				events: ['InitialActions', 'ActionAdded', 'ActionRemoved', 'RefreshActions'],
				pre: 'actions'
			})

			if (this.$store.getters.getClaim('theme').value === 'dark') {
				this.darkMode = true
			} else {
				this.darkMode = false
			}

			if (this.$store.getters.getClaim('sidemenu').value === 'true') {
				this.collapsed = true
			} else {
				this.collapsed = false
			}

			this.displayCustomerSelector = this.$store.getters.isInRole(roles.SystemUser)
			Auth.addInactivity.call(this)
		} catch {
			// Nothing?
		} finally {
			setTimeout(() => { this.$store.commit('appFinishedLoading') }, 1000)
		}
	},
	async mounted () {
		window.addEventListener('resize', (e) => {
			if (window.innerWidth <= 768) {
				EventBus.$emit('collapsedSidebar', true)
			}
		}, { passive: true })
		EventBus.$on('collapsedSidebar', async (payload) => {
			if (payload == null) {
				this.collapsed = !this.collapsed
			} else {
				this.collapsed = payload
			}

			await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}User/UpdateSideMenu`, null, {
				params: {
					value: this.collapsed
				}
			})
		})

		EventBus.$on('darkMode', async (payload) => {
			if (payload == null) {
				await this.$store.dispatch('loadUser')
				if (this.$store.getters.getClaim('theme').value === 'dark') {
					this.darkMode = true
				} else {
					this.darkMode = false
				}
			} else {
				this.darkMode = payload
			}
		})
	},
	computed: {
		// computed property to get the state of collapsed sidebar
		sidebarCollapse () {
			return this.collapsed
		},
		// computed property to get the state of dark mode
		darkModeEvent () {
			var body = $('body')
			if (this.darkMode) {
				body.addClass('dark-mode')
			} else {
				body.removeClass('dark-mode')
			}
			return this.darkMode
		},
		// computed property to get theme color
		getThemeColor () {
			return this.themeColor
		},
		...mapGetters([
			'licences', 'unauthorized'
		]),
		...mapState({
			loading: 'appLoading'
		})
	},
	beforeRouteEnter (to, from, next) {
		next()
	}

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.fade-long-enter-active {
	transition: opacity 0.8s;
}

.fade-long-enter, .fade-long-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
