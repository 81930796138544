import { render, staticRenderFns } from "./EditCustomerLicence.vue?vue&type=template&id=73007ed6&scoped=true&"
import script from "./EditCustomerLicence.vue?vue&type=script&lang=js&"
export * from "./EditCustomerLicence.vue?vue&type=script&lang=js&"
import style0 from "./EditCustomerLicence.vue?vue&type=style&index=0&id=73007ed6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73007ed6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73007ed6')) {
      api.createRecord('73007ed6', component.options)
    } else {
      api.reload('73007ed6', component.options)
    }
    module.hot.accept("./EditCustomerLicence.vue?vue&type=template&id=73007ed6&scoped=true&", function () {
      api.rerender('73007ed6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Components/Platform/Customer/Licence/EditCustomerLicence.vue"
export default component.exports