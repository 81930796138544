<template>
  <div>
    <h3>Exporter<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label>Group</label>
      <br>
      <group-select v-model="selectedNode.props.s1.value" :groups="groupOptions" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>
    </div>
    <br/>
    <div class="form-group">
      <label>Exporter</label>
      <br>
      <select class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        v-model="selectedNode.props.s1.value">
        <option v-for="option in exporterOptions" :key="option.value" :value="option.value">
        {{option.text}}
      </option>
    </select>
    </div>
    <br/>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            maxLength: maxLength(128)
          }
        }
      }
    }
  },
  data () {
    return {
      groupOptions: [],
      exporterOptions: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        console.log('invalid')
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  created: async function () {
    this.$snapbar.hide()
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    if (!this.paygateId) {
      this.$toastr.e('Error getting list of available groups. (001)', 'Error', 'error')
      return
    }

    let groupsResponse
    try {
      groupsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups`)
      this.groupOptions = groupsResponse.data.data
    } catch (e) {
      this.$snapbar.e('Error getting list of available groups. (002)')
    }

    let exporterResponse
    try {
      exporterResponse = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}exportertitles`)
      if (exporterResponse) {
        console.log('exporterResponse.data')
        console.log(exporterResponse.data)
        for (const exporter of exporterResponse.data.exporters) {
          this.exporterOptions.push({
            text: exporter.title,
            value: exporter._id
          })
        }
      }
    } catch (e) {
      this.$snapbar.e('Error getting list of available exporters')
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    console.log('selectedNode')
    console.log(this.selectedNode)
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }

  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
