<template>
  <div>
  <b-form-row v-if="!useAutoGeneratedRef">
    <b-form-group
        :label-cols="5"
        class="col-sm-5"
        label-class="required"
        horizontal
        label="Payer Reference"
      >
        <b-form-input
          id="referenceInput"
          type="text"
          class="form-control"
          required          
          v-model="reference"
          @input="checkReference($v.reference)"
          :formatter="formatReference"
        />
        <b-col v-if="!this.useAutoGeneratedRef && $v.reference.$dirty">
          <label id="payerReferenceRequiredLabel" class="text-danger small" v-if="$v.reference.$invalid">Payer Reference required</label>
        </b-col>
        <b-col v-if="!this.useAutoGeneratedRef && existingReference">
          <label id="payerReferenceExistsLabel" class="text-warning small">
            A user already exists with this reference.
          </label>
        </b-col>
      </b-form-group>
  </b-form-row>
    <b-form-row>
      <b-form-group :label-cols="5" class="col-sm-5" horizontal label="Title">
        <b-form-select
          id="titleSelect"
          class="form-control"
          :options="titles"
          value-field="ordinal"
          text-field="name"
          v-model="$v.title.$model"
        />
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-5"
        label-class="required"
        horizontal
        label="First Name"
      >
        <b-form-input
          type="text"
          class="form-control"
          required
          v-model="$v.firstName.$model"
          id="firstNameInput"
        />
        <b-col v-if="$v.firstName.$dirty">
          <label id="firstNameRequiredLabel" class="text-danger small" v-if="$v.firstName.$invalid">First Name required</label>
        </b-col>
      </b-form-group>
      </b-form-row>

    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-5"
        label-class="required"
        horizontal
        label="Surname"
      >
        <b-form-input type="text" class="form-control" v-model="$v.surname.$model" id="surnameInput" />
        <b-col v-if="$v.surname.$dirty">
          <label id="surnameRequiredLabel" class="text-danger small" v-if="$v.surname.$invalid">Surname required</label>
        </b-col>
      </b-form-group>
    </b-form-row>

    <b-form-row>
      <b-form-group :label-cols="5" class="col-sm-5" horizontal label="Telephone Number 1">
        <b-form-input type="text" id="tel1Input" class="form-control" v-model="$v.tel1.$model" :disabled="!editable" />
        <b-col v-if="$v.tel1.$invalid">
          <label id="tel1InvalidLabel" class="text-danger small">Valid telephone number required</label>
        </b-col>
      </b-form-group>
      </b-form-row>

    <b-form-row>
      <b-form-group :label-cols="5" class="col-sm-5" horizontal label="Telephone Number 2">
        <b-form-input type="text" id="tel2Input" class="form-control" v-model="$v.tel2.$model" />
        <b-col v-if="$v.tel2.$invalid">
          <label id="tel2InvalidLabel" class="text-danger small">Valid telephone number required</label>
        </b-col>
      </b-form-group>
      </b-form-row>

    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-5"
        :label-class="smsRequired ? 'required' : ''"
        horizontal
        label="Mobile Number"
      >
        <b-form-input type="text" id="mobileInput" class="form-control" v-model="$v.mobile.$model" />
        <b-col v-if="$v.mobile.$invalid">
          <label id="smsInvalidLabel" class="text-danger small">Mobile number required</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-5"
        :label-class="emailRequired ? 'required' : ''"
        horizontal
        label="Email Address"
      >
        <b-form-input
          type="text"
          class="form-control"
          @input="checkEmail($v.email)"
          v-model="$v.email.$model"
          id="emailInput"
          @keydown="existingEmailUsers = false"
        />
        <b-col v-if="$v.email.$dirty">
          <label id="emailRequiredLabel"
            class="text-danger small"
            v-if="emailRequired && !$v.email.required"
          >Provide a valid Email Address</label>
          <label id="emailInvalidLabel" class="text-danger small" v-if="!$v.email.email">Valid Email Address required</label>
        </b-col>
        <b-col v-if="existingEmailUsers">
          <label  id="emailDuplicateLabel" class="text-warning small">
            Users with this email are already in this group. Is this correct?
            <a class="dupeLink" v-b-modal="pageInstanceId">View duplicates.</a>
          </label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-modal :id="pageInstanceId" :title="'Duplicate Emails for '+$v.email.$model" :ok-only="true">
      <p>Below is a list of Payers which share the address: {{ $v.email.$model }}, clicking on a row will navigate to the selected payer.</p>
      <table style="width:100%">
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Reference</th>
        </tr>
        <tr style="cursor:pointer"  v-for="(payer, index) in duplicateEmails" :key="index">
          <td>{{payer.name}}</td>
          <td>{{statusToText(payer.status)}}</td>
          <td>{{payer.reference}}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>
<script>
import { numeric, required, helpers, sameAs } from 'vuelidate/lib/validators'
import { email } from '@/Assets/Validators'

import Enum from '@/Enums/Collections'
import _ from 'lodash'
import axios from 'axios'
import uuidv4 from 'uuid/v4'
const referenceRequred = function (value) {
  if (!this.useAutoGeneratedRef) {
    return helpers.req(value)
  }
  return true
}

const phoneRegex = /^[\d +()]*$/
const phoneValidator = (value) => value ? phoneRegex.test(value) : true
export default {
  props: {
    open: Boolean,
    clickedNext: Boolean,
    currentStep: Object
  },
  data () {
    return {
      titles: Enum.Payer.Titles.enumValues,
      existingEmailUsers: false,
      addressLookupInProgress: false,
      addressOptions: [],
      addressesSet: false,
      existingReference: false,
      smsRequired: false,
      duplicateEmails: [],
      pageInstanceId: null
    }
  },
  methods: {
    dirtyTouch (callingModel) {
      callingModel.$touch()
    },
    checkEmail: _.debounce(async function (emailValidator) {
      if (!emailValidator.$invalid) {
        const email = emailValidator.$model
        let url = `${process.env.VUE_APP_DDMS_API_URL}payer/checkexistingemail`
        if (email !== null && email !== undefined && email !== '') {
          const groupId = this.$store.state.payer.currentPayer.groupId
          const response = await axios.post(url, { email, groupId }, { showload: true })
          if (response.data.totalCount > 0) {
            this.existingEmailUsers = true
            this.showEmailDuplicates()
          }
        }
      }
    }, 300),
    validate () {
      this.$emit('can-continue', { value: true })
    },
    async showEmailDuplicates () {
      var getDupes = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}${this.groupId}/payers/?searchFilter=email:${this.$v.email.$model}&perPage=100&page=1`, { showload: true })
      this.duplicateEmails = getDupes.data.data
    },
    checkReference: _.debounce(async function (referenceValidator) {
      if (!referenceValidator.$invalid) {
        const reference = referenceValidator.$model
        let url = `${process.env.VUE_APP_DDMS_API_URL}payerreference/${this.groupId}/dupecheck?reference=${reference}`
        const response = await axios.get(url, { showload: true })
        this.existingReference = response.data
      }
    }, 300),
    statusToText (x) {
      switch (x) {
        case -1:
          return 'Any'
        case 0:
          return 'Closed'
        case 1:
          return 'Alert'
        case 2:
          return 'Suspended'
        case 3:
          return 'Operational'
        case 4:
          return 'Incomplete'
        default:
          return 'Unknown'
      }
    },
    goToPayer (id) {
      this.$router.push(`/collections/payer/${id}/edit`)
    },
    formatReference (e) {
      return String(e).substring(0,10);
    }
  },
  components: {},
  validations () {
    if (this.messageChannel === 0) {
      return {
        title: { numeric },
        reference: this.useAutoGeneratedRef ? {} : { required },
        firstName: { required },
        surname: { required },
        tel1: { phoneValidator },
        tel2: { phoneValidator },
        mobile: { phoneValidator },
        email: { required, email },
        existingReference: this.useAutoGeneratedRef ? {} : { sameAs: sameAs(() => false) }
      }
    } else if (this.messageChannel === 2) {
      return {
        title: { numeric },
        reference: this.useAutoGeneratedRef ? {} : { referenceRequred },
        firstName: { required },
        surname: { required },
        tel1: { phoneValidator },
        tel2: { phoneValidator },
        mobile: { required, phoneValidator },
        email: { email },
        existingReference: this.useAutoGeneratedRef ? {} : { sameAs: sameAs(() => false) }
      }
    } else {
      return {
        title: { numeric },
        reference: this.useAutoGeneratedRef ? {} : { referenceRequred },
        firstName: { required },
        surname: { required },
        tel1: { phoneValidator },
        tel2: { phoneValidator },
        mobile: { phoneValidator },
        email: { email },
        existingReference: this.useAutoGeneratedRef ? {} : { sameAs: sameAs(() => false) }
      }
    }
  },
  computed: {
    editable () {
      return this.$store.state.payer.currentPayer.editable
    },
    groupId () {
      return this.$store.state.payer.currentPayer.groupId
    },
    reference: {
      get () { return this.$store.state.payer.currentPayer.reference },
      set (value) { this.$store.commit('updatePayerReference', value) }
    },
    title: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.title },
      set (value) { this.$store.commit('updateTitle', value) }
    },
    firstName: {
      get () {
        return this.$store.state.payer.currentPayer.payerDetails.firstName
      },
      set (value) {
        this.$store.commit('updateAccountHoldersName', value + ' ' + this.$store.state.payer.currentPayer.payerDetails.surname)
        this.$store.commit('updateFirstName', value)
        this.$store.dispatch('setNewRefRequired', true)
      }
    },
    surname: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.surname },
      set (value) {
        this.$store.commit('updateAccountHoldersName', this.$store.state.payer.currentPayer.payerDetails.firstName + ' ' + value)
        this.$store.commit('updateSurname', value)
        this.$store.dispatch('setNewRefRequired', true)
      }
    },
    tel1: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.tel1 },
      set (value) { this.$store.commit('updateTel1', value) }
    },
    tel2: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.tel2 },
      set (value) { this.$store.commit('updateTel2', value) }
    },
    mobile: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.mobile },
      set (value) { this.$store.commit('updateMobile', value) }
    },
    lat: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.lat },
      set (value) { this.$store.commit('updateLat', value) }
    },
    lon: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.lon },
      set (value) { this.$store.commit('updateLon', value) }
    },
    email: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.email },
      set (value) { this.$store.commit('updateEmail', value) }
    },
    status: {
      get () { return this.$store.state.payer.currentPayer.status },
      set (value) { this.$store.commit('updateStatus', value) }
    },
    messageChannel: {
      get () {
        var cfg = this.$store.getters.collectionsGroupConfig
        return cfg.defaultPayerMessageType
        // Get will be called when this changes.
        // If it changes (based on the action of an external component, re-check the form validation for SMS and email address)
        // this.dirtyTouch(this.$v.mobile)
        // this.dirtyTouch(this.$v.email)
        // return this.$store.state.payer.currentPayer.payerMessageType
      }
    },
    useAutoGeneratedRef: {
      get () {
        return this.$store.state.payer.useAutoGenerateRef
      }
    },
    emailRequired () {
      var cfg = this.$store.getters.collectionsGroupConfig
      return cfg.defaultPayerMessageType === 0
    },
    mobileRequired () {
      var cfg = this.$store.getters.collectionsGroupConfig
      return cfg.defaultPayerMessageType === 2
    }
  },
  watch: {
    $v: {
      handler (val) {
        this._self.$emit('can-continue', { value: !val.$invalid })
        this.$store.commit('setDetailsCompleted', !this.$v.$invalid)
      },
      deep: true
    },
    clickedNext (val) {
      if (val === true) {
        this.$emit('can-continue', { value: !this.$v.$invalid })
      }
    },
    currentStep (val) {
      console.log(val)
      if (val.name === 'personalDetails') {
        this.$emit('can-continue', { value: !this.$v.$invalid })
      }
    }
  },
  async mounted () {
    this.$emit('can-continue', { value: !this.$v.$invalid })
    await this.$store.dispatch('getUseAutoGenerateRef', this.groupId)
    this.pageInstanceId = uuidv4()
  }
}
</script>
<style>
  .dupeLink {
    text-decoration: underline !important;
    color: blue !important;
    cursor: pointer !important;
  }
</style>
