import { Enum } from 'enumify'

class Titles extends Enum {}
Titles.initEnum([ 'Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Other' ])

class RecurrenceType extends Enum {}
RecurrenceType.initEnum([ 'Weekly', 'Monthly', 'Yearly' ])

class RecurrenceEndType extends Enum {}
RecurrenceEndType.initEnum([ 'OneOff', 'UntilDate', 'UntilNumberOfPayments', 'Ongoing', 'AdHoc' ])

class PayerStatus extends Enum {}
PayerStatus.initEnum(['Closed', 'Alert', 'Suspended', 'Operational', 'AwaitingActivation', 'Incomplete'])

export default { Titles, RecurrenceType, RecurrenceEndType, PayerStatus }
