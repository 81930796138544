var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasErrors
      ? _c(
          "div",
          { staticClass: "alert alert-danger" },
          _vm._l(_vm.errors, function(error, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Password Expiration Policy "),
                _c("help-icon", {
                  attrs: {
                    docPath:
                      "/administration/securitypolicy/passwordexpirationpolicy/"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-7 offset-md-1" },
                  [
                    _c(
                      "p-radio",
                      {
                        staticClass: "p-default p-round",
                        attrs: {
                          name: "passwordExpirationBehaviour",
                          value: "Default"
                        },
                        model: {
                          value:
                            _vm.$v.policy.passwordExpirationBehaviour.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.policy.passwordExpirationBehaviour,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.policy.passwordExpirationBehaviour.$model"
                        }
                      },
                      [
                        _vm._v(
                          "Use Standard Behaviour (Passwords never expire)"
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-7 offset-md-1" },
                  [
                    _c(
                      "p-radio",
                      {
                        staticClass: "p-default p-round",
                        attrs: {
                          name: "passwordExpirationBehaviour",
                          value: "Custom"
                        },
                        model: {
                          value:
                            _vm.$v.policy.passwordExpirationBehaviour.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.policy.passwordExpirationBehaviour,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.policy.passwordExpirationBehaviour.$model"
                        }
                      },
                      [_vm._v("Passwords expire after n days")]
                    )
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.$v.policy.passwordExpirationBehaviour.$model == "Custom"
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row pl-5",
                        class: { invalid: _vm.$v.policy.expirationDays.$error }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-2 offset-md-1"
                          },
                          [_vm._v("Amount of Days")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.policy.expirationDays.$model,
                                  expression: "$v.policy.expirationDays.$model"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "number" },
                              domProps: {
                                value: _vm.$v.policy.expirationDays.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.policy.expirationDays,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c("validation-messages", {
                              model: {
                                value: _vm.$v.policy.expirationDays,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.policy, "expirationDays", $$v)
                                },
                                expression: "$v.policy.expirationDays"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-form-label col-md-4 offset-md-1",
                    attrs: { for: "forceChangePostReset" }
                  },
                  [
                    _vm._v(
                      "Force user to change password after administrator reset?"
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: {
                        labelId: "forceChangePostReset",
                        color: "primary"
                      },
                      model: {
                        value: _vm.$v.policy.forceChangeAfterReset.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.forceChangeAfterReset,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.forceChangeAfterReset.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-form-label col-md-4 offset-md-1",
                    attrs: { for: "excludeSpecificUsers" }
                  },
                  [_vm._v("Never expire passwords for specific users?")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: {
                        labelId: "excludeSpecificUsers",
                        color: "primary"
                      },
                      model: {
                        value: _vm.$v.policy.excludeSpecificUsers.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.excludeSpecificUsers,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.excludeSpecificUsers.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.$v.policy.excludeSpecificUsers.$model
                  ? _c("div", { staticClass: "form-group row pl-5" }, [
                      _c(
                        "label",
                        { staticClass: "col-form-label col-md-2 offset-md-1" },
                        [_vm._v("Excluded users")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("vue-select", {
                            attrs: {
                              multiple: "",
                              options: _vm.users,
                              closeOnSelect: false
                            },
                            model: {
                              value: _vm.policy.excludedUsers,
                              callback: function($$v) {
                                _vm.$set(_vm.policy, "excludedUsers", $$v)
                              },
                              expression: "policy.excludedUsers"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }