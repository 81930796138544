var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loading" },
    [
      _vm.showModalFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showModalFolderBrowser = false
                _vm.closePopup()
              }
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _vm.isNewTrigger
                ? _c("h2", [
                    _c("span", { staticClass: "pull-right" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.docUrl +
                              "/automation/paygate-workflow/triggers/filetrigger/",
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "far fa-question-circle" })]
                      )
                    ]),
                    _vm._v(" Create a file trigger ")
                  ])
                : _c("h2", [
                    _c("span", { staticClass: "pull-right" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.docUrl +
                              "/automation/paygate-workflow/triggers/filetrigger/",
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "far fa-question-circle" })]
                      )
                    ]),
                    _vm._v(" Edit a file trigger ")
                  ])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("form", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "The title of the trigger.",
                            expression: "'The title of the trigger.'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "required"
                      },
                      [_vm._v("Title")]
                    )
                  ]),
                  _c("div", { staticClass: "form-group col-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.trigger.title,
                          expression: "trigger.title"
                        }
                      ],
                      staticClass: "form-control",
                      class: { invalid: _vm.$v.trigger.title.$error },
                      attrs: { type: "text" },
                      domProps: { value: _vm.trigger.title },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.trigger, "title", $event.target.value)
                          },
                          function($event) {
                            return _vm.$v.trigger.title.$touch()
                          }
                        ]
                      }
                    }),
                    !_vm.$v.trigger.title.required &&
                    _vm.$v.trigger.title.$dirty
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v("The title field is required!")
                        ])
                      : _vm._e(),
                    !_vm.$v.trigger.title.maxLength
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v(
                            "Maximum length is " +
                              _vm._s(
                                _vm.$v.trigger.title.$params.maxLength.max
                              ) +
                              " characters!"
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "An optional text field for you to write a discription of the trigger.",
                            expression:
                              "'An optional text field for you to write a discription of the trigger.'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ]
                      },
                      [_vm._v("Description")]
                    )
                  ]),
                  _c("div", { staticClass: "form-group col-9" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.trigger.description,
                          expression: "trigger.description"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { rows: "4", cols: "60" },
                      domProps: { value: _vm.trigger.description },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.trigger,
                              "description",
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.$emit("textarea", $event.target.value)
                          }
                        ],
                        blur: function($event) {
                          return _vm.$v.trigger.description.$touch()
                        }
                      }
                    }),
                    !_vm.$v.trigger.description.maxLength
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v(
                            "Maximum length is " +
                              _vm._s(
                                _vm.$v.trigger.description.$params.maxLength.max
                              ) +
                              " characters!"
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "The virtual path to the folder that the trigger is watching",
                            expression:
                              "'The virtual path to the folder that the trigger is watching'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "required"
                      },
                      [_vm._v("Folder")]
                    )
                  ]),
                  _c("div", { staticClass: "col-9 mb-3" }, [
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.trigger.configuration.watchFolder,
                            expression: "trigger.configuration.watchFolder"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          invalid:
                            _vm.$v.trigger.configuration.watchFolder.$error
                        },
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.trigger.configuration.watchFolder
                        },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.trigger.configuration,
                                "watchFolder",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.trigger.configuration.watchFolder.$touch()
                            }
                          ]
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "input-group-append" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-secondary" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.btnFileBrowser($event)
                                }
                              }
                            },
                            [_vm._v("...")]
                          )
                        ],
                        1
                      )
                    ]),
                    !_vm.$v.trigger.configuration.watchFolder.required &&
                    _vm.$v.trigger.configuration.watchFolder.$dirty
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v("The folder field is required!")
                        ])
                      : _vm._e(),
                    !_vm.$v.trigger.configuration.watchFolder.maxLength
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v(
                            "Maximum length is " +
                              _vm._s(
                                _vm.$v.trigger.configuration.watchFolder.$params
                                  .maxLength.max
                              ) +
                              " characters!"
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "col-1" })
                ]),
                _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "form-group col-3" }, [
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: "The filename that the trigger is watching",
                            expression:
                              "'The filename that the trigger is watching'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "required"
                      },
                      [_vm._v("Filename")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.trigger.configuration.watchFilename,
                          expression: "trigger.configuration.watchFilename"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        invalid:
                          _vm.$v.trigger.configuration.watchFilename.$error
                      },
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.trigger.configuration.watchFilename
                      },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.trigger.configuration,
                              "watchFilename",
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.$v.trigger.configuration.watchFilename.$touch()
                          }
                        ]
                      }
                    }),
                    !_vm.$v.trigger.configuration.watchFilename.required &&
                    _vm.$v.trigger.configuration.watchFilename.$dirty
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v("The filename field is required!")
                        ])
                      : _vm._e(),
                    !_vm.$v.trigger.configuration.watchFilename.maxLength
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v(
                            "Maximum length is " +
                              _vm._s(
                                _vm.$v.trigger.configuration.watchFilename
                                  .$params.maxLength.max
                              ) +
                              " characters!"
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "row top-buffer" }, [
                  _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "Determines if the trigger is enabled or disabled.  Disabled triggers will not execute bound workflows when they fire.",
                            expression:
                              "'Determines if the trigger is enabled or disabled.  Disabled triggers will not execute bound workflows when they fire.'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { title: "Enabled" }
                      },
                      [
                        _c(
                          "label",
                          { attrs: { for: "triggerEnabledCheckbox" } },
                          [_vm._v("Enabled")]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-3 col-sm-12" },
                    [
                      _c("p-check", {
                        staticClass: "p-switch",
                        attrs: {
                          name: "check",
                          labelId: "triggerEnabledCheckbox",
                          color: "primary "
                        },
                        model: {
                          value: _vm.trigger.enabled,
                          callback: function($$v) {
                            _vm.$set(_vm.trigger, "enabled", $$v)
                          },
                          expression: "trigger.enabled"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("br"),
                _c("div", { staticClass: "row top-buffer" }, [
                  _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "If enabled, the trigger will not run any bound workflows if the current day is a BACS non-working day, i.e. a weekend or public bank holiday.",
                            expression:
                              "'If enabled, the trigger will not run any bound workflows if the current day is a BACS non-working day, i.e. a weekend or public bank holiday.'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ]
                      },
                      [
                        _c(
                          "label",
                          { attrs: { for: "suppressNonWorkingDaysCheckbox" } },
                          [_vm._v("Suppress on BACS non-working days")]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-sm-12" },
                    [
                      _c("p-check", {
                        staticClass: "p-switch",
                        attrs: {
                          labelId: "suppressNonWorkingDaysCheckbox",
                          name: "check",
                          color: "primary "
                        },
                        model: {
                          value:
                            _vm.trigger.configuration.suppressOnNonWorkingDays,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trigger.configuration,
                              "suppressOnNonWorkingDays",
                              $$v
                            )
                          },
                          expression:
                            "trigger.configuration.suppressOnNonWorkingDays"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("br"),
                _c("hr"),
                _c("br"),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "A list of workflows that will execute when the trigger fires.",
                            expression:
                              "'A list of workflows that will execute when the trigger fires.'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ]
                      },
                      [_vm._v("Bound Workflows")]
                    )
                  ]),
                  _c("div", { staticClass: "form-group col-md-9 col-sm-12" }, [
                    _c(
                      "ul",
                      { staticClass: "list-group" },
                      _vm._l(this.trigger.boundWorkflows, function(
                        workflow,
                        index
                      ) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "list-group-item align-items-center"
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-1" }, [
                                _c(
                                  "div",
                                  { staticClass: "pretty p-icon p-smooth" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: workflow.enabled,
                                          expression: "workflow.enabled"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(workflow.enabled)
                                          ? _vm._i(workflow.enabled, null) > -1
                                          : workflow.enabled
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = workflow.enabled,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  workflow,
                                                  "enabled",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  workflow,
                                                  "enabled",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(workflow, "enabled", $$c)
                                          }
                                        }
                                      }
                                    }),
                                    _vm._m(0, true)
                                  ]
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-9" },
                                [
                                  _vm._v(" " + _vm._s(workflow.Title) + " ( "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "small",
                                      attrs: {
                                        tag: "a",
                                        to:
                                          "/automation/workflow/workflowdetail/" +
                                          workflow.id.value
                                      }
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _vm._v(") ")
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "Click to edit this workflow using the designer",
                                          expression:
                                            "'Click to edit this workflow using the designer'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.btnEditWorkflow(
                                            workflow.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "text-secondary glyphicon ti-settings",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top.d500",
                                          value:
                                            "Removes the workflow from the trigger.",
                                          expression:
                                            "'Removes the workflow from the trigger.'",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d500: true
                                          }
                                        }
                                      ],
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.btnRemoveWorkflow(
                                            workflow.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "text-secondary glyphicon ti-trash text-danger",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    ),
                    _c("br"),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "Add a new workflow to this trigger so that the workflow runs when the trigger fires.",
                            expression:
                              "'Add a new workflow to this trigger so that the workflow runs when the trigger fires.'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        staticClass: "btn btn-outline-primary pull-right",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.btnAddWorkflow($event)
                          }
                        }
                      },
                      [_vm._v("+")]
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-sm-3" },
                  [
                    _vm.isNewTrigger
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value:
                                  "Creates a new trigger and saves it in Paygate.  Enabled triggers will become active immediately.",
                                expression:
                                  "'Creates a new trigger and saves it in Paygate.  Enabled triggers will become active immediately.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.createTrigger($event)
                              }
                            }
                          },
                          [_vm._v("Create")]
                        )
                      : _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value:
                                  "Update the current trigger saving any changes. Enabled triggers will become active immediately.",
                                expression:
                                  "'Update the current trigger saving any changes. Enabled triggers will become active immediately.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateTrigger($event)
                              }
                            }
                          },
                          [_vm._v("Save")]
                        ),
                    !_vm.isNewTrigger
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "View this Trigger's execution history.",
                                expression:
                                  "'View this Trigger\\'s execution history.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "ml-2",
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "outline-primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.viewTelemetry($event)
                              }
                            }
                          },
                          [_vm._v("View Telemetry")]
                        )
                      : _vm._e(),
                    !_vm.isNewTrigger
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Create an exact copy of this trigger.",
                                expression:
                                  "'Create an exact copy of this trigger.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "ml-2",
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "outline-primary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.cloneTrigger($event)
                              }
                            }
                          },
                          [_vm._v("Clone Trigger")]
                        )
                      : _vm._e(),
                    !_vm.isNewTrigger
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Permanently delete this trigger.",
                                expression:
                                  "'Permanently delete this trigger.'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "pull-right",
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "danger"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteTrigger($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "glyphicon ti-trash mr-2" }),
                            _vm._v("Delete Trigger ")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }