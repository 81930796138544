<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Timeline<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <light-timeline :items='items'></light-timeline>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row" >
            <div class="col-md-12 col-sm-3">
              <b-button @click.prevent="exportTelemetry" variant="outline-primary" title="Export Telemetry" v-b-popover.hover.top.d500="'Exports this Trigger\'s telemetry data.'"><i class="fa fa-download mr-2"></i>Export Telemetry</b-button>
              <b-button @click.prevent="viewTelemetry" variant="outline-primary" title="View Telemetry" v-b-popover.hover.top.d500="'View the telemetry data as a table.'"><i class="fa fa-table mr-2"></i>View  Telemetry Table</b-button>
              <b-button @click.prevent="deleteTrigger" variant="danger" class="pull-right" title="Delete Telemetry" v-b-popover.hover.top.d500="'Permanently deletes this trigger\'s telemetry data.'"><i class="fa fa-trash mr-2"></i>Delete Telemetry</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      id: this.$route.params.id,
      items: [
        {
          tag: '2018-01-12',
          content: 'hallo'
        },
        {
          tag: '2018-01-13',
          color: '#00ff00',
          type: 'circle',
          content: '{{<div v-html="legacySystemHTML">}}'
        },
        {
          type: 'star',
          tag: '2018-01-14',
          content: '=v ='
        }
      ]
    }
  },
  methods: {
    viewTelemetry () {
      this.$router.push(`/automation/workflow/workflowtelemetry/${this.id}`)
    }
  }
}
</script>
<style>
.item-content {
  border-style: solid;
  border-width: 1px;
  border-color: #dddddd;
  padding: 6px;
}
</style>
