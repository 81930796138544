var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bacs-group-config-report", {
        attrs: {
          group: _vm.group,
          includeUsers: false,
          bacsLicenceSettings: _vm.bacsLicenceSettings
        }
      }),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Submission Window Type")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.details.submissionWindowType))])
          ])
        ])
      ]),
      _vm.group.details.submissionWindowType == "MultiDay"
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Window Size")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.multidayWindowSize))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Advance Notice Period (In Days)")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.details.advanceNoticeInDays))])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Default Contra Narrative")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.group.details.defaultContraNarrative))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("AUDDIS Group")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c(
            "div",
            { staticClass: "form-control-plaintext" },
            [
              _c("tick", {
                attrs: { coloured: false },
                model: {
                  value: _vm.group.details.isAuddis,
                  callback: function($$v) {
                    _vm.$set(_vm.group.details, "isAuddis", $$v)
                  },
                  expression: "group.details.isAuddis"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Service User Name")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.details.serviceUserName))])
          ])
        ])
      ]),
      _vm.group.details.serviceUserAddress1
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Address 1")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserAddress1))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserAddress2
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Address 2")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserAddress2))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserAddress3
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Address 3")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserAddress3))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserAddress4
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Address 4")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserAddress4))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserPostcode
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Postcode")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserPostcode))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserTelephone
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Service User Telephone")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserTelephone))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserFax
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Service User Fax")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [_vm._v(_vm._s(_vm.group.details.serviceUserFax))])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserEmail
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Email Address")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [_vm._v(_vm._s(_vm.group.details.serviceUserEmail))])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserContactName1
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Service User Contact Name 1")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserContactName1))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserContactName2
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Service User Contact Name 2")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserContactName2))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserContactName3
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Service User Contact Name 3")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserContactName3))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField1
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 1")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField1))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField2
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 2")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField2))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField3
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 3")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField3))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField4
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 4")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField4))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField5
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 5")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField5))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField6
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 6")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField6))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField7
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 7")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField7))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.serviceUserCustomField8
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Custom Field 8")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.serviceUserCustomField8))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Default Payer Message Type")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.group.details.defaultPayerMessageType))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Letter File Export Type")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.details.docxExportType))])
          ])
        ])
      ]),
      _vm.group.details.emailBccList
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Email BCC Address List")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [_vm._v(_vm._s(_vm.group.details.emailBccList))])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.letterOutputFolder
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Letter File Export Folder")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.letterOutputFolder))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.domainEmailId
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Email Address")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [_vm._v(_vm._s(_vm.group.details.domainEmailId))])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Update Scheduled Payments On Error")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c(
            "div",
            { staticClass: "form-control-plaintext" },
            [
              _c("tick", {
                attrs: { coloured: false },
                model: {
                  value: _vm.group.details.updateSchedPaymentOnError,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.group.details,
                      "updateSchedPaymentOnError",
                      $$v
                    )
                  },
                  expression: "group.details.updateSchedPaymentOnError"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Enable Schedule Suppression")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c(
            "div",
            { staticClass: "form-control-plaintext" },
            [
              _c("tick", {
                attrs: { coloured: false },
                model: {
                  value: _vm.group.details.suppressionEnabled,
                  callback: function($$v) {
                    _vm.$set(_vm.group.details, "suppressionEnabled", $$v)
                  },
                  expression: "group.details.suppressionEnabled"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm.group.details.suppressionEnabled
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Suppression Start Day")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.suppressionDayStart))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.suppressionEnabled
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Suppression Start Month")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.suppressionMonthStart))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.suppressionEnabled
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Suppression End Day")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.suppressionDayEnd))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.group.details.suppressionEnabled
        ? _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Suppression End Month")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.suppressionMonthEnd))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Group Workflow Type")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [_vm._v(_vm._s(_vm.group.details.groupWorkflowType))])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("API Active")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c(
            "div",
            { staticClass: "form-control-plaintext" },
            [
              _c("tick", {
                attrs: { coloured: false },
                model: {
                  value: _vm.group.details.apiActive,
                  callback: function($$v) {
                    _vm.$set(_vm.group.details, "apiActive", $$v)
                  },
                  expression: "group.details.apiActive"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Use Default Variable Amount File Mapping")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c(
            "div",
            { staticClass: "form-control-plaintext" },
            [
              _c("tick", {
                attrs: { coloured: false },
                model: {
                  value: _vm.group.details.defaultVariableAmountMapping,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.group.details,
                      "defaultVariableAmountMapping",
                      $$v
                    )
                  },
                  expression: "group.details.defaultVariableAmountMapping"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Variable Amount File Mapping")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.group.details.variableAmountMappingId))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Default Plan Template")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.group.details.defaultPlanTemplateId))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
          _vm._v("Payer Reference Generation Pattern")
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "form-control-plaintext" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.group.details.payerReferencePattern))
            ])
          ])
        ])
      ]),
      _c("group-config-users", { attrs: { users: _vm.group.users } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }