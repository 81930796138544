var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "90%" } },
    [
      _vm._m(0),
      _vm.group !== null && _vm.oneSquare
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [_vm._v("Group")]),
              _c("b-col", [_vm._v(_vm._s(_vm.groupName))])
            ],
            1
          )
        : _vm._e(),
      _vm.oneSquare
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [_vm._v("Count")]),
              _c("b-col", [
                _vm._v(
                  _vm._s(
                    new Intl.NumberFormat("en-GB", {}).format(
                      _vm.submissionCount
                    )
                  )
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.oneSquare
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "6" } }, [_vm._v("Value")]),
              _c("b-col", [
                _vm._v(
                  _vm._s(
                    new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP"
                    }).format(_vm.submissionValue)
                  )
                )
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.oneSquare
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _vm.group !== null && !_vm.oneSquare
                ? _c("b-col", [
                    _c("h2", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.goToSub(true, false)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.groupName))]
                      )
                    ])
                  ])
                : _vm._e(),
              _c("b-col", [
                _c("div", { staticClass: "pos-relative" }, [
                  _c("h2", { class: _vm.ddiStyle }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToSub(false, false)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.numberFormatter(_vm.submissionCount)))]
                    )
                  ])
                ])
              ]),
              _c("b-col", [
                _c("div", { staticClass: "pos-relative" }, [
                  _c("h2", { class: _vm.ddiStyle }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToSub(false, false)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "£" + _vm._s(_vm.numberFormatter(_vm.submissionValue))
                        )
                      ]
                    )
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.oneSquare
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _vm.group !== null && !_vm.oneSquare
                ? _c("b-col", [_c("span", { staticClass: "d-block" })])
                : _vm._e(),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(
                    _vm._s(
                      new Intl.NumberFormat("en-GB", {}).format(
                        _vm.submissionCount
                      )
                    )
                  )
                ])
              ]),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(
                    _vm._s(
                      new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP"
                      }).format(_vm.submissionValue)
                    )
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.oneSquare
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _vm.group !== null && !_vm.oneSquare
                ? _c("b-col", [
                    _c("span", { staticClass: "pb-10 d-block" }, [
                      _vm._v("Group")
                    ])
                  ])
                : _vm._e(),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v("Submission Count")
                ])
              ]),
              _c("b-col", [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v("Submission Value")
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h4", { staticClass: "m-0" }, [_vm._v("Pending Overdue Submissons")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }