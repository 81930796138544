var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: "This is the Account Holder Name",
              expression: "'This is the Account Holder Name'",
              modifiers: { hover: true, top: true }
            }
          ],
          staticClass: "col-md-3 required"
        },
        [_vm._v("Account Holder Name")]
      ),
      _c(
        "div",
        {
          staticClass: "col-md-4",
          class: { invalid: _vm.$v.accountHoldersName.$error }
        },
        [
          _c("b-form-input", {
            staticClass: "form-control",
            attrs: {
              id: "accountHolderNameInput",
              type: "text",
              required: "",
              disabled: !_vm.editable
            },
            on: {
              input: function($event) {
                return _vm.$v.accountHoldersName.$reset()
              }
            },
            nativeOn: {
              blur: function($event) {
                return _vm.dirtyTouch(_vm.$v.accountHoldersName)
              }
            },
            model: {
              value: _vm.accountHoldersName,
              callback: function($$v) {
                _vm.accountHoldersName = $$v
              },
              expression: "accountHoldersName"
            }
          })
        ],
        1
      ),
      _vm.$v.accountHoldersName.$error
        ? _c("span", [
            !_vm.$v.accountHoldersName.required
              ? _c(
                  "label",
                  {
                    staticClass: "text-danger small",
                    attrs: { id: "accountHolderRequiredLabel" }
                  },
                  [_vm._v(" Account Holder Name required! ")]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: "This is the third party's sort code.",
              expression: "'This is the third party\\'s sort code.'",
              modifiers: { hover: true, top: true }
            }
          ],
          staticClass: "col-md-3 required"
        },
        [_vm._v("Sort Code")]
      ),
      _c(
        "div",
        { staticClass: "col-md-3", class: { invalid: _vm.$v.sortcode.$error } },
        [
          _c("the-mask", {
            ref: "sortcode",
            staticClass: "form-control",
            attrs: {
              id: "sortcodeInput",
              mask: ["##-##-##"],
              required: "",
              guide: true,
              type: "text",
              tabindex: 27,
              state: _vm.validateApiResult && _vm.validateApiResult.valid,
              disabled: !_vm.editable
            },
            on: {
              input: function($event) {
                return _vm.$v.sortcode.$reset()
              }
            },
            nativeOn: {
              blur: function($event) {
                return _vm.dirtyTouch(_vm.$v.sortcode)
              }
            },
            model: {
              value: _vm.sortcode,
              callback: function($$v) {
                _vm.sortcode = $$v
              },
              expression: "sortcode"
            }
          })
        ],
        1
      ),
      _vm.validateApiResult && !_vm.invalidSortCode
        ? _c("div", { staticClass: "col-md-1" }, [
            _c("span", {
              staticClass: "fa fa-check-circle",
              staticStyle: { "font-size": "200%", color: "green" }
            })
          ])
        : _vm._e(),
      _vm.$v.sortcode.$error
        ? _c("span", [
            !_vm.$v.sortcode.minLength || !_vm.$v.sortcode.maxLength
              ? _c(
                  "label",
                  {
                    staticClass: "text-danger small",
                    attrs: { id: "sortCodeLengthLabel" }
                  },
                  [_vm._v(" The Sort Code needs to be 6 numbers long ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.$v.sortcode.$error && _vm.$v.sortcode.$model !== null
        ? _c("span", [
            !_vm.$v.sortcode.required
              ? _c(
                  "label",
                  {
                    staticClass: "text-danger small",
                    attrs: { id: "sortCodeRequiredLabel" }
                  },
                  [_vm._v(" Sort Code required! ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.validateApiResult &&
      _vm.validateApiResult.supportsDdi !== null &&
      !_vm.validateApiResult.supportsDdi
        ? _c("span", [
            _c("label", { staticClass: "text-danger small" }, [
              _vm._v(" Sort code does not support DDI. It cannot be used ")
            ])
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: "This is the third party's account number.",
              expression: "'This is the third party\\'s account number.'",
              modifiers: { hover: true, top: true }
            }
          ],
          staticClass: "col-md-3 required"
        },
        [_vm._v("Account Number")]
      ),
      _c(
        "div",
        {
          staticClass: "col-md-3",
          class: { invalid: _vm.$v.accountNumber.$error }
        },
        [
          _c("the-mask", {
            ref: "accountNumber",
            staticClass: "form-control",
            attrs: {
              id: "accountHolderInput",
              state: _vm.validateApiResult && _vm.validateApiResult.valid,
              mask: ["########"],
              required: "",
              guide: true,
              type: "text",
              tabindex: 28,
              disabled: !_vm.editable
            },
            on: {
              input: function($event) {
                return _vm.$v.accountNumber.$reset()
              }
            },
            nativeOn: {
              blur: function($event) {
                return _vm.dirtyTouch(_vm.$v.accountNumber)
              }
            },
            model: {
              value: _vm.accountNumber,
              callback: function($$v) {
                _vm.accountNumber = $$v
              },
              expression: "accountNumber"
            }
          })
        ],
        1
      ),
      _vm.validateApiResult && !_vm.invalidAccountNumber
        ? _c("div", { staticClass: "col-md-1" }, [
            _c("span", {
              staticClass: "fa fa-check-circle",
              staticStyle: { "font-size": "200%", color: "green" }
            })
          ])
        : _vm._e(),
      _vm.$v.accountNumber.$error
        ? _c("span", [
            !_vm.$v.accountNumber.minLength || !_vm.$v.accountNumber.maxLength
              ? _c(
                  "label",
                  {
                    staticClass: "text-danger small",
                    attrs: { id: "accNumberLengthLabel" }
                  },
                  [_vm._v(" The account number needs to be 8 numbers long ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.$v.accountNumber.$error && _vm.$v.accountNumber.$model !== null
        ? _c("span", [
            !_vm.$v.accountNumber.required
              ? _c(
                  "label",
                  {
                    staticClass: "text-danger small",
                    attrs: { id: "accNumberRequiredLabel" }
                  },
                  [_vm._v(" Account Number required! ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.validateApiResult && !_vm.validateApiResult.valid
        ? _c("span", [
            _c("label", { staticClass: "text-danger small" }, [
              _vm._v(" " + _vm._s(_vm.validateApiResult.errorText) + " ")
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }