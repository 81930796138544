var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { attrs: { mb: "4" } },
        [
          _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [
                _c("h2", [
                  _c("span", { staticClass: "pull-right" }, [
                    _c("a", { attrs: { href: "#", target: "_blank" } }, [
                      _c("i", { staticClass: "far fa-question-circle" })
                    ])
                  ]),
                  _vm._v("Email Bounce")
                ])
              ]),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c("table", { staticClass: "mb-3" }, [
                    _c("tr", [
                      _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                        _vm._v("Customer Name")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.bounceReport
                              ? _vm.bounceReport.customerName
                              : "Loading"
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                        _vm._v("Group Name")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.bounceReport
                              ? _vm.bounceReport.groupName
                              : "Loading"
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                        _vm._v("Sent Date")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.bounceReport
                              ? _vm.formatDate(_vm.bounceReport.sentDate)
                              : "Loading"
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                        _vm._v("Total")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.bounceReport
                              ? _vm.bounceReport.total
                              : "Loading"
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                        _vm._v("Sent")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.bounceReport ? _vm.bounceReport.sent : "Loading"
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                        _vm._v("Bounced")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.bounceReport
                              ? _vm.bounceReport.bounced
                              : "Loading"
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                        _vm._v("Halted")
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.bounceReport
                              ? _vm.bounceReport.halted
                              : "Loading"
                          )
                        )
                      ])
                    ])
                  ]),
                  _c(
                    "vue-good-table",
                    {
                      attrs: {
                        mode: "remote",
                        columns: _vm.columns,
                        rows: _vm.bounceReports,
                        lineNumbers: true,
                        totalRows: _vm.totalRecords,
                        "search-options": {
                          enabled: true
                        },
                        "pagination-options": {
                          enabled: true,
                          dropdownAllowAll: false
                        },
                        styleClass: "vgt-table striped bordered"
                      },
                      on: {
                        "on-page-change": _vm.onPageChange,
                        "on-sort-change": _vm.onSortChange,
                        "on-column-filter": _vm.onColumnFilter,
                        "on-per-page-change": _vm.onPerPageChange,
                        "on-search": _vm.onSearch,
                        "on-row-click": _vm.onRowClick
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "table-row",
                          fn: function(props) {
                            return [
                              props.column.field === "status"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.displayStatus(props.row.status)
                                      )
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(props.row[props.column.field])
                                    )
                                  ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("template", { slot: "loadingContent" }, [
                        _c("h1", [_vm._v("Loading...")])
                      ]),
                      _c(
                        "div",
                        { attrs: { slot: "emptystate" }, slot: "emptystate" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vgt-center-align vgt-text-disabled"
                            },
                            [_vm._v("No bounced messages available to send")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          attrs: { slot: "table-actions" },
                          slot: "table-actions"
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top.d500",
                                  value: "Refresh the data in the table",
                                  expression: "'Refresh the data in the table'",
                                  modifiers: {
                                    hover: true,
                                    top: true,
                                    d500: true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "link",
                                title: "Refresh Table"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.reloadItems($event)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-sync pointer dimmedIcon"
                              }),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Refresh Table")
                              ])
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top.d500",
                                  value:
                                    "Export the contents of the Logs table",
                                  expression:
                                    "'Export the contents of the Logs table'",
                                  modifiers: {
                                    hover: true,
                                    top: true,
                                    d500: true
                                  }
                                }
                              ],
                              attrs: { variant: "link", title: "Export Table" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.exportTable($event)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-share-square pointer dimmedIcon"
                              }),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Export Table")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "idb-block-footer noprint-footer" },
                [
                  _vm.bounceReports.length > 0
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.getBouncedData }
                        },
                        [_vm._v("Export Bounced Emails")]
                      )
                    : _vm._e(),
                  _vm.bounceReport && _vm.bounceReports.length > 0
                    ? _c(
                        "b-button",
                        {
                          staticClass: "pull-right",
                          attrs: {
                            variant: "success",
                            disabled: _vm.hasInvalid
                          },
                          on: { click: _vm.resend }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bounceReport.isHalted
                                ? "Resume and resend failed messages"
                                : "Resend failed messages"
                            )
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }