<template>
  <div class="idb-block">
    <div class="idb-block-title">
            <h2>
              <span class="pull-right">
                <a :href="`${docUrl}/gettingstarted/`" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span> Contact Support
              <favourite-icon></favourite-icon>
            </h2>
    </div>
    <div class="idb-block-content">
      <div class="pl-3"><strong>Customer Support</strong></div>
      <div class="pl-3">Gladstone House</div>
      <div class="pl-3">Hithercroft Road</div>
      <div class="pl-3">Wallingford</div>
      <div class="pl-3">Oxfordshire</div>
      <div class="pl-3">OX10 9BT</div>
      <div class="pl-3 pt-3">Tel:
        <a
          href="tel:+441462708444"
        >+44 (0) 1462 708 444</a></div>

      <div class="pl-3 pt-1">Email:
        <a href="mailto:support@paygate.uk">support@paygate.uk</a></div>
      <div class="pl-3 pt-5"><strong>Other useful links</strong></div>
      <div class="pl-3 pt-3">Service Status:
        <a href="https://status.paygate.cloud/" target="_blank">status.paygateservice.com</a></div>
      <!-- TODO: Check Production URL -->
      <div class="pl-3 pt-2">Online help guide:
        <a :href="`${docUrl}/gettingstarted/`" target="_blank">help.paygateservice.com</a></div>
        <div class="pl-3 pt-3">Developer Portal (API Swagger and Tutorials)
        <a href="https://developer.paygateservice.com/" target="_blank">developer.paygateservice.com</a></div>
      <!-- <div class="pl-3 pt-2">Developer Portal:
        <a href="https://developer.paygateservice.com" target="_blank">https://developer.paygateservice.com</a></div> -->
      <div class="pl-3 pt-2">paygate.uk:
        <a href="https://www.paygate.uk/" target="_blank">https://www.paygate.uk/</a></div>
        <div class="pl-3 pt-2" v-if="hasAgent">Agent Installer:
        <a :href="`${cdnUrl}/downloads/Paygate.Agent.Installer.msi`" target="_blank">Paygate.Agent.Installer.msi (v.3.1.20.0)</a></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Licence from '@/Assets/Constants/licence'
export default {
  computed: {
    ...mapGetters(['selectedCustomer']),
    hasAgent () {
      return this.$store.getters.hasLicence(Licence.agent)
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      cdnUrl: process.env.VUE_APP_CDN_LOCATION_ROOT
    }
  }
}
</script>

<style scoped>
address {
  white-space: pre-line;
}
</style>
