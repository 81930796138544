<template>
  <mappingDesigner></mappingDesigner>
</template>

<script>
import swal from 'sweetalert2'
export default {
  components: {
    swal
  },
  async beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave')
    console.log('Dirty: ' + this.$store.state.mapping.isMappingDirty)
    if (this.$store.state.mapping.isMappingDirty === true) {
      const result = await swal.fire({
        title: 'You have unsaved changes!',
        text: 'You will lose any changes to the current mapping.  Are you sure you want to navigate away?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        confirmButtonClass: 'btn btn-danger'
      })
      if (result.isConfirmed) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>
