var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "bureauSummary" } }, [
    _c(
      "table",
      {
        staticClass: "table table-striped pt-0 mt-0",
        attrs: { role: "table" }
      },
      [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _vm._m(1),
            _c("td", [
              _vm._v(
                _vm._s(_vm.formatCount(_vm.BureauSummaryData.totalCreditCount))
              )
            ]),
            _c("td", [
              _vm._v(
                "£" +
                  _vm._s(
                    _vm.formatValue(_vm.BureauSummaryData.totalCreditValue)
                  )
              )
            ])
          ]),
          _c("tr", [
            _vm._m(2),
            _c("td", [
              _vm._v(
                _vm._s(_vm.formatCount(_vm.BureauSummaryData.totalDebitCount))
              )
            ]),
            _c("td", [
              _vm._v(
                "£" +
                  _vm._s(_vm.formatValue(_vm.BureauSummaryData.totalDebitValue))
              )
            ])
          ]),
          _c("tr", [
            _vm._m(3),
            _c("td", [
              _vm._v(
                _vm._s(_vm.formatCount(_vm.BureauSummaryData.totalDDICount))
              )
            ]),
            _vm._m(4)
          ]),
          _c("tr", [
            _vm._m(5),
            _c("td", [
              _vm._v(_vm._s(_vm.formatCount(_vm.BureauSummaryData.contraCount)))
            ]),
            _vm._m(6)
          ]),
          _c("tr", [
            _vm._m(7),
            _c("td", [
              _vm._v(_vm._s(_vm.formatCount(_vm.BureauSummaryData.totalFiles)))
            ]),
            _vm._m(8)
          ])
        ])
      ]
    ),
    _vm.actionId !== undefined && false
      ? _c("div", { staticClass: "row form-group mt-2" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.downloadReport }
              },
              [
                _c("span", {
                  staticClass: "far fa-arrow-alt-circle-down",
                  staticStyle: { "padding-right": "5px" }
                }),
                _vm._v("Download Merged Submission Report ")
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { role: "columnheader" } }, [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Unit")])
        ]),
        _c("th", { attrs: { role: "columnheader" } }, [_vm._v("Items")]),
        _c("th", { attrs: { role: "columnheader" } }, [_vm._v("Total")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Credits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Debits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("DDIs")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", { staticClass: "sr-only" }, [_vm._v("N/A")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Contras")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", { staticClass: "sr-only" }, [_vm._v("N/A")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { role: "rowheader" } }, [
      _c("strong", [_vm._v("Total Blocks")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", { staticClass: "sr-only" }, [_vm._v("N/A")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }