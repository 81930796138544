<template>
  <div>
    <div v-if="addedLists && addedLists.length > 0">
      <div v-for="(antiFraudList) in addedLists" :key="antiFraudList.id">
        <div class="row">
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                class="ins"
              >{{ antiFraudList.name }} ({{ antiFraudList.listType }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="removedLists && removedLists.length > 0">
      <div v-for="(antiFraudList) in removedLists" :key="antiFraudList.id">
        <div class="row">
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span
                style="margin-right:5px"
                class="del"
              >{{ antiFraudList.name }} ({{ antiFraudList.listType }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="sameLists && sameLists.length > 0">
      <div v-for="(antiFraudList) in sameLists" :key="antiFraudList.id">
        <div class="row">
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{ antiFraudList.name }} ({{ antiFraudList.listType }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label for="description" class="col-sm-4 col-form-label">Pre-submission message severity</label>
      <div class="col-md-8">
        <div
          v-if="groupPresubMessageSeverity !== actionPresubMessageSeverity"
          class="form-control-plaintext"
        >
          <span style="margin-right:5px" class="del">{{ groupPresubMessageSeverity }}</span>
          <span style="margin-right:5px" class="ins">{{ actionPresubMessageSeverity }}</span>
        </div>
        <div v-else class="form-control-plaintext">
          <span style="margin-right:5px">{{ groupPresubMessageSeverity }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['status', 'groupAntiFraudLists', 'actionAntiFraudLists'],
  data: () => ({
    addedLists: [],
    removedLists: [],
    sameLists: [],
    groupPresubMessageSeverity: '',
    actionPresubMessageSeverity: ''
  }),
  async mounted () {
    try {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList`, { params: { paygateid: this.$store.state.common.paygateId }, showerror: true, errormessage: 'Failed to get anti-fraud lists' })
      const allLists = response.data.data
      if (this.groupAntiFraudLists.length > 0) {
        for (let j = 0; j < this.groupAntiFraudLists.length; j++) {
          const firstList = allLists.find(i => i.id === this.groupAntiFraudLists[j].antiFraudListId)
          const isActionList = this.actionAntiFraudLists.find(i => i.antiFraudListId === this.groupAntiFraudLists[j].antiFraudListId)
          if (isActionList === undefined) {
            this.removedLists.push(firstList)
          } else {
            this.sameLists.push(firstList)
          }
        }

        this.groupPresubMessageSeverity = this.groupAntiFraudLists[0].presubValMessageSeverity
      }
      if (this.actionAntiFraudLists.length > 0) {
        for (let j = 0; j < this.actionAntiFraudLists.length; j++) {
          const firstList = allLists.find(i => i.id === this.actionAntiFraudLists[j].antiFraudListId)
          const isGroupList = this.groupAntiFraudLists.find(i => i.antiFraudListId === this.actionAntiFraudLists[j].antiFraudListId)
          if (isGroupList === undefined) {
            this.addedLists.push(firstList)
          }
        }
        this.actionPresubMessageSeverity = this.actionAntiFraudLists[0].presubValMessageSeverity
      }
    } catch (e) {
      console.log(e)
    }
  }
}
</script>
