import common from '@/Assets/Stores/Modules/common'
import actionsStore from '@/Assets/Stores/Modules/actions'
import user from '@/Assets/Stores/Modules/user'
import search from '@/Assets/Stores/Modules/search'
import plugin from '@/Assets/Stores/Modules/plugin'
import twoFactor from '@/Assets/Stores/Modules/twoFactor'
import group from '@/Assets/Stores/Modules/group'
import cookies from '@/Assets/Stores/Modules/cookies'
import menu from '@/Assets/Stores/Modules/menu'
import tableFilters from '@/Assets/Stores/Modules/tableFilters'

export default {
  common,
  actionsStore,
  user,
  search,
  plugin,
  twoFactor,
  group,
  cookies,
  menu,
  tableFilters
}
