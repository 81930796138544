import Vue from 'vue'
import Accounting from 'accounting-js'

Vue.filter('number', function (amt) {
  var options = {
    symbol: '',
    decimal: '.',
    thousand: ',',
    precision: 0,
    format: '%s%v'
  }
  return Accounting.formatMoney(amt, options)
})
