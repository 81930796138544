import Vue from 'vue'

import { LMap, LTileLayer, LMarker, LLayerGroup, LCircle, LPopup } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import './corvid.css'

// Leaflet (map) specific components
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-layer-group', LLayerGroup)
Vue.component('l-circle', LCircle)
Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)
Vue.component('l-popup', LPopup)
// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
// end of Leaflet
