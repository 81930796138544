import Vue from 'vue'

const state = {
  tables: {}
}

const mutations = {
  updateTable (state, table) {
    var tableName = table.name
    var tableFilters = JSON.parse(JSON.stringify(table.filters))
    Vue.set(state.tables, tableName, tableFilters)
  },
  clearTableFilters (state) {
    state.tables = {}
  }
}

const getters = {
  tableFilters: (state) => (tableName) => {
    return state.tables[tableName]
  }
}

export default {
  state,
  mutations,
  getters
}
