var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Usage Report "),
                _c("help-icon", {
                  attrs: { docPath: "/administration/reports/usagereport/" }
                }),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-group col-md-4" },
                [
                  _c(
                    "label",
                    { staticClass: "required", attrs: { for: "from" } },
                    [_vm._v("From")]
                  ),
                  _c("vuejsDatepicker", {
                    attrs: {
                      name: "from",
                      id: "from",
                      format: "MMM/yyyy",
                      "input-class": "form-control bg-white",
                      "bootstrap-styling": true,
                      minimumView: "month",
                      maximumView: "year",
                      "initial-view": "month",
                      "use-utc": true,
                      disabledDates: _vm.fromDisabledDates
                    },
                    model: {
                      value: _vm.dates.from,
                      callback: function($$v) {
                        _vm.$set(_vm.dates, "from", $$v)
                      },
                      expression: "dates.from"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group col-md-4" },
                [
                  _c(
                    "label",
                    { staticClass: "required", attrs: { for: "to" } },
                    [_vm._v("To")]
                  ),
                  _c("vuejsDatepicker", {
                    attrs: {
                      name: "to",
                      id: "to",
                      format: "MMM/yyyy",
                      "input-class": "form-control bg-white",
                      "bootstrap-styling": true,
                      minimumView: "month",
                      maximumView: "year",
                      "initial-view": "month",
                      "use-utc": true,
                      disabledDates: _vm.dates.disabledDates
                    },
                    model: {
                      value: _vm.dates.to,
                      callback: function($$v) {
                        _vm.$set(_vm.dates, "to", $$v)
                      },
                      expression: "dates.to"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-group col-md-4" },
                [
                  _c(
                    "label",
                    { staticClass: "required", attrs: { for: "group" } },
                    [_vm._v("Select Group")]
                  ),
                  _c("group-select", {
                    attrs: { id: "group", groups: _vm.groups, clearable: true },
                    on: {
                      input: function($event) {
                        return _vm.selectGroup()
                      }
                    },
                    model: {
                      value: _vm.selectedGroupId,
                      callback: function($$v) {
                        _vm.selectedGroupId = $$v
                      },
                      expression: "selectedGroupId"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    return _vm.load()
                  }
                }
              },
              [
                _c("i", { staticClass: "glyphicon ti-pie-chart rpad" }),
                _vm._v("Generate Usage Report ")
              ]
            ),
            _c("div", { staticClass: "ml-3 btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success dropdown-toggle",
                  attrs: {
                    type: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                    disabled: _vm.isLoading || !_vm.dataLoaded
                  }
                },
                [_vm._v("Export as")]
              ),
              _c("div", { staticClass: "dropdown-menu" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: { click: _vm.saveAsJson }
                  },
                  [_vm._v("JSON")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: { click: _vm.saveAsCsv }
                  },
                  [_vm._v("CSV")]
                )
              ])
            ]),
            _c("div", { staticClass: "ml-3 btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success dropdown-toggle",
                  attrs: {
                    type: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                    disabled: _vm.isLoading
                  }
                },
                [_vm._v("Export BACS CSV")]
              ),
              _c("div", { staticClass: "dropdown-menu" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: { click: _vm.downloadBacsStats }
                  },
                  [_vm._v(_vm._s(_vm.downloadStatsText))]
                ),
                _vm.isSystemUser
                  ? _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: { click: _vm.downloadAllCustomerBacsStats }
                      },
                      [
                        _vm._v(
                          "Create BACS and FPS Stats CSV File For All Customers"
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("SMS Sent")]),
            _c(
              "div",
              [
                _c(
                  "h2",
                  { staticClass: "card-count" },
                  [
                    _c("i-count-up", {
                      attrs: {
                        delay: 1,
                        endVal: _vm.smsSent,
                        options: _vm.counterOptions
                      }
                    })
                  ],
                  1
                ),
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.sms.data,
                    options: _vm.charts.sms.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-4" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("Emails Sent")]),
            _c(
              "div",
              [
                _c(
                  "h2",
                  { staticClass: "card-count" },
                  [
                    _c("i-count-up", {
                      attrs: {
                        delay: 1,
                        endVal: _vm.emailSent,
                        options: _vm.counterOptions
                      }
                    })
                  ],
                  1
                ),
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.email.data,
                    options: _vm.charts.email.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-4" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("Address Lookups")
            ]),
            _c(
              "div",
              [
                _c(
                  "h2",
                  { staticClass: "card-count" },
                  [
                    _c("i-count-up", {
                      attrs: {
                        delay: 1,
                        endVal: _vm.addressLookupSent,
                        options: _vm.counterOptions
                      }
                    })
                  ],
                  1
                ),
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.addressLookup.data,
                    options: _vm.charts.addressLookup.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mt-4 mb-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("Storage")]),
            _vm.charts.space.any
              ? _c("div", { staticClass: "progress" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.top.hover",
                          value:
                            "Privata Data - " +
                            _vm.charts.space.private.value +
                            " - " +
                            _vm.roundToTwo(_vm.charts.space.private.percent) +
                            "%",
                          expression:
                            "`Privata Data - ${charts.space.private.value} - ${roundToTwo(charts.space.private.percent)}%`",
                          modifiers: { top: true, hover: true }
                        }
                      ],
                      staticClass: "progress-bar bg-success",
                      style: { width: _vm.charts.space.private.percent + "%" },
                      attrs: { role: "progressbar" }
                    },
                    [
                      _vm._v(
                        "Private Data " + _vm._s(_vm.charts.space.private.value)
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.top.hover",
                          value:
                            "Public Data - " +
                            _vm.charts.space.public.value +
                            " - " +
                            _vm.roundToTwo(_vm.charts.space.public.percent) +
                            "%",
                          expression:
                            "`Public Data - ${charts.space.public.value} - ${roundToTwo(charts.space.public.percent)}%`",
                          modifiers: { top: true, hover: true }
                        }
                      ],
                      staticClass: "progress-bar",
                      style: { width: _vm.charts.space.public.percent + "%" },
                      attrs: { role: "progressbar" }
                    },
                    [
                      _vm._v(
                        "Public Data " + _vm._s(_vm.charts.space.public.value)
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.top.hover",
                          value:
                            "Free Space - " +
                            _vm.charts.space.free.value +
                            " - " +
                            _vm.roundToTwo(_vm.charts.space.free.percent) +
                            "%",
                          expression:
                            "`Free Space - ${charts.space.free.value} - ${roundToTwo(charts.space.free.percent)}%`",
                          modifiers: { top: true, hover: true }
                        }
                      ],
                      staticClass: "progress-bar bg-info",
                      style: { width: _vm.charts.space.free.percent + "%" },
                      attrs: { role: "progressbar" }
                    },
                    [
                      _vm._v(
                        "Free Space " + _vm._s(_vm.charts.space.free.value)
                      )
                    ]
                  )
                ])
              : _c("div", [
                  _vm._v("You do not have any storage space allocated")
                ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("Validations")]),
            _c(
              "div",
              [
                _vm._v(" Total - "),
                _c("i-count-up", {
                  attrs: {
                    delay: 1,
                    endVal: _vm.report.validate.total,
                    options: _vm.counterOptions
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.validate.data,
                    options: _vm.charts.validate.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("BACS Submission Count " + _vm._s(_vm.selectedGroupName))
            ]),
            _c(
              "div",
              [
                _c(
                  "h2",
                  { staticClass: "card-count" },
                  [
                    _c("i-count-up", {
                      attrs: {
                        delay: 1,
                        endVal: _vm.bacsSubmissionsSent,
                        options: _vm.counterOptions
                      }
                    })
                  ],
                  1
                ),
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.bacsSubmissionCount.data,
                    options: _vm.charts.bacsSubmissionCount.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("BACS Transaction Count " + _vm._s(_vm.selectedGroupName))
            ]),
            _c(
              "div",
              [
                _c(
                  "h2",
                  { staticClass: "card-count" },
                  [
                    _c("i-count-up", {
                      attrs: {
                        delay: 1,
                        endVal: _vm.bacsTransactionsSent,
                        options: _vm.counterOptions
                      }
                    })
                  ],
                  1
                ),
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.bacsTransactionCount.data,
                    options: _vm.charts.bacsTransactionCount.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v(
                "Faster Payment Submission Count " +
                  _vm._s(_vm.selectedGroupName)
              )
            ]),
            _c(
              "div",
              [
                _c(
                  "h2",
                  { staticClass: "card-count" },
                  [
                    _c("i-count-up", {
                      attrs: {
                        delay: 1,
                        endVal: _vm.fpsSubmissionsSent,
                        options: _vm.counterOptions
                      }
                    })
                  ],
                  1
                ),
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.fpsSubmissionCount.data,
                    options: _vm.charts.fpsSubmissionCount.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "idb-block text-center" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v(
                "Faster Payment Transaction Count " +
                  _vm._s(_vm.selectedGroupName)
              )
            ]),
            _c(
              "div",
              [
                _c(
                  "h2",
                  { staticClass: "card-count" },
                  [
                    _c("i-count-up", {
                      attrs: {
                        delay: 1,
                        endVal: _vm.fpsTransactionsSent,
                        options: _vm.counterOptions
                      }
                    })
                  ],
                  1
                ),
                _c("bar-chart", {
                  attrs: {
                    "chart-data": _vm.charts.fpsTransactionCount.data,
                    options: _vm.charts.fpsTransactionCount.options
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("vue-good-table", {
                attrs: {
                  paginationOptions: _vm.paginationOptions,
                  isLoading: _vm.isTableLoading,
                  rows: _vm.rows,
                  columns: _vm.columns,
                  mode: "remote",
                  totalRows: _vm.totalRecords,
                  styleClass: "vgt-table striped bordered"
                },
                on: {
                  "update:isLoading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "update:is-loading": function($event) {
                    _vm.isTableLoading = $event
                  },
                  "on-page-change": _vm.onPageChange,
                  "on-column-filter": _vm.onColumnFilter,
                  "on-per-page-change": _vm.onPerPageChange
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Usage Report Table")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }