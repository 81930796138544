<template>
  <div>
    <h1 class="text-center">DDMS Collections Dashboard</h1>
    <information-control
      title="Pending Submissions"
      :messageCount="1"
      linkText="Pending Submissions"
      linkUrl="http://www.google.com"
    ></information-control>
    <information-control
      title="New Actions!"
      :messageCount="1"
      linkText="View Actions List"
      linkUrl="http://www.google.com"
    ></information-control>
    <information-control
      title="New Alerts!"
      :messageCount="1"
      linkText="View Alerts List"
      linkUrl="http://www.google.com"
    ></information-control>
    <button @click="pingServer()">Test</button>
  </div>
</template>
<script>
import InformationControl from '@/Components/Collections/Dashboard/InformationControl'
export default {
  data () {
    return {
      isConnected: false,
      socketMessage: ''
    }
  },
  components: {
    InformationControl: InformationControl
  },
  sockets: {
    connect () {
      this.isConnected = true
    },
    messageChannel (data) {
      this.socketMessage = data
    },
    disconnect () {
      this.isConnected = false
    }
  },
  methods: {
    pingServer () {
      this.$socket.emit('pingServer', 'Ping')
    }
  }
}
</script>
