import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
var messagingConnection = null

function establishMessagingHub (paygateId, commit) {
  var hubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/messaging?paygateid=' + paygateId
  messagingConnection = new HubConnectionBuilder().withUrl(hubUrl).configureLogging(LogLevel.Error).build()
  messagingConnection.start()
  messagingConnection.on('messagesupdated', async data => {
    const url = `${process.env.VUE_APP_DDMS_API_URL}portalmessaging/unread/count`
    var response = await axios.get(url)
    const count = response.data
    console.log('new portal message check')
    commit('updatePortalMessagesCount', count)
  })
}

const state = {
  collectionsAlertCount: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  collectionsSubmissionCount: { count: 0, includeInTopLevel: true, treatAsBoolean: true },
  submissionsInProgressCount: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  bouncedEmailsCount: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  submissionSeverityLevel: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  collectionsPortalMessages: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  triggerCount: { count: 0, includeInTopLevel: false, treatAsBoolean: false },

  // Bureau
  bureauTopSeverityLevel: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  bureauSubmissionPill: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  bureauCustomerImportPill: { count: 0, includeInTopLevel: true, treatAsBoolean: false },
  bureauCustomerImportSeverity: { count: 0, includeInTopLevel: true, treatAsBoolean: false }
}

const getters = {
  collectionsAlertCount (state) {
    return state.collectionsAlertCount
  },
  collectionsSubmissionCount (state) {
    return state.collectionsSubmissionCount
  },
  submissionsInProgressCount (state) {
    return state.submissionsInProgressCount
  },
  bouncedEmailsCount (state) {
    return state.bouncedEmailsCount
  },
  submissionSeverityLevel (state) {
    return state.submissionSeverityLevel
  },
  collectionsPortalMessages (state) {
    return state.collectionsPortalMessages
  },
  triggerCount (state) {
    return state.triggerCount
  },

  bureauTopSeverityLevel (state) {
    return state.bureauTopSeverityLevel
  },

  bureauSubmissionPill (state) {
    return state.bureauSubmissionPill
  },

  bureauCustomerImportPill (state) {
    return state.bureauCustomerImportPill
  },

  bureauCustomerImportSeverity (state) {
    return state.bureauCustomerImportSeverity
  }

}

const actions = {
  updateCurrentCollectionsAlertCount: async ({ commit, rootState }) => {
    try {
      // Hi, I (Gary) commented the below out on 1/8/23 because it was smamming the console with cors errors. :-)
      // var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}widget/alerts/all/count`, { params: { paygateid: rootState.common.paygateId } })
      // commit('updateCollectionsAlerts', response.data)
    } catch (e) {
      console.log('Alert update Failed', e)
    }
  },
  updateCurrentCollectionsSubmissionCount: async ({ commit, rootState }) => {
    try {
      const url = `${process.env.VUE_APP_DDMS_API_URL}widget/submissions/any`
      var response = await axios.get(url, { params: { paygateid: rootState.common.paygateId } })
      const count = response.data.submissionCount
      commit('updateCollectionsSubmissions', count)
    } catch (e) {
      console.log('Submission update failed', e)
    }
  },
  updateSubmissionsInProgressCount: async ({ commit, rootState }) => {
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionsInProgress`)
      commit('updateSubmissionsInProgress', response.data.length)
    } catch (e) {
      console.log('Submissions in progress update Failed.', e)
    }
  },
  updateBouncedEmailsCount: async ({ commit, rootState }) => {
    try {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}emailreporting/getbounces`)
      commit('updateBouncedEmailsCount', response.data)
    } catch (e) {
      console.log('Bounced emails update failed.')
    }
  },
  updateSubmissionSeverityLevel: async ({ commit, rootState }) => {
    try {
      const url = `${process.env.VUE_APP_DDMS_API_URL}submissions/maxescalationlevel`
      var response = await axios.get(url)
      const count = response.data
      commit('updateSubmissionSeverityLevel', count)
    } catch (e) {
      console.log('Submission severity failed', e)
    }
  },
  updatePortalMessagesCount: async ({ commit, rootState }) => {
    try {
      if (messagingConnection === null) {
        establishMessagingHub(rootState.common.paygateId, commit)
      }
      const url = `${process.env.VUE_APP_DDMS_API_URL}portalmessaging/unread/count`
      var response = await axios.get(url)
      const count = response.data
      commit('updatePortalMessagesCount', count)
    } catch (e) {
      console.log('Portal Messages failed', e)
    }
  },
  updateCurrentTriggerCount: async ({ commit, rootState }) => {
    if (process.env.VUE_APP_VERSION < 335) return // 335 // 340
    try {
      const response = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}triggers`)
      if (response && response.data && response.data.triggers) {
        const numEnabledTriggers = response.data.triggers.filter((o) => o.enabled === true).length
        commit('updateTriggers', numEnabledTriggers)
      }
    } catch (e) {
      console.log('Trigger update Failed', e)
    }
  },
  // Bureau
  updateBureauTopSeverityLevel: async ({ commit, rootState }) => {
    try {
      const url = `${process.env.VUE_APP_BUREAU_API_URL}BureauGeneral/GetBureauTopSeverityLevel`
      var response = await axios.get(url)
      const count = response.data
      commit('updateBureauTopSeverityLevel', count)
    } catch (e) {
      console.log('Update Bureau Top Severity failed', e)
    }
  },

  updateBureauSubmissionPill: async ({ commit, rootState }) => {
    try {
      const url = `${process.env.VUE_APP_BUREAU_API_URL}BureauGeneral/GetBureauBureauSubmissionPill`
      var response = await axios.get(url)
      const count = response.data
      commit('updateBureauSubmissionPill', count)
    } catch (e) {
      console.log('Update Bureau Submission Pill failed', e)
    }
  },

  updateBureauCustomerImportPill: async ({ commit, rootState }) => {
    try {
      const url = `${process.env.VUE_APP_BUREAU_API_URL}BureauGeneral/GetBureauCustomerImportPill`
      var response = await axios.get(url)
      const count = response.data
      commit('updateBureauCustomerImportPill', count)
      commit('updateBureauCustomerImportSeverity', 0)
    } catch (e) {
      console.log('Update Bureau Customer Import Pill failed', e)
    }
  }
}

const mutations = {
  updateCollectionsAlerts (state, value) {
    state.collectionsAlertCount.count = value
  },
  updateCollectionsSubmissions (state, value) {
    state.collectionsSubmissionCount.count = value
  },
  updateSubmissionsInProgress (state, value) {
    state.submissionsInProgressCount.count = value
  },
  updateBouncedEmailsCount (state, value) {
    state.bouncedEmailsCount.count = value
  },
  updateSubmissionSeverityLevel (state, value) {
    state.submissionSeverityLevel.count = value
  },
  updatePortalMessagesCount (state, value) {
    state.collectionsPortalMessages.count = value
  },
  updateTriggers (state, value) {
    state.triggerCount.count = value
  },
  updateBureauTopSeverityLevel (state, value) {
    state.bureauTopSeverityLevel.count = value
  },
  updateBureauSubmissionPill (state, value) {
    state.bureauSubmissionPill.count = value
  },
  updateBureauCustomerImportPill (state, value) {
    state.bureauCustomerImportPill.count = value
  },
  updateBureauCustomerImportSeverity (state, value) {
    state.bureauCustomerImportSeverity.count = value
  }
}

export default { state, getters, actions, mutations }
