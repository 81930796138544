<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Incorrect Password Policy
            <help-icon docPath="/administration/securitypolicy/incorrectpasswordpolicy/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="form-group row" :class="{invalid: $v.policy.numInvalidAttempts.$error}">
            <label class="col-form-label col-md-3 offset-md-1">Number of invalid attempts</label>
            <div class="col-md-2">
              <input
                type="number"
                min="1"
                max="9"
                class="form-control"
                v-model="$v.policy.numInvalidAttempts.$model"
              />
              <validation-messages v-model="$v.policy.numInvalidAttempts"></validation-messages>
            </div>
          </div>

          <div class="form-group row">
            <label for="lockAccount" class="col-form-label col-md-3 offset-md-1">Lock the account</label>
            <div class="col-md-3">
              <p-check :labelId="'lockAccount'"
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.lockAccount.$model"
              ></p-check>
              <br />
              <small>This will lock the account for 5 minutes after {{$v.policy.numInvalidAttempts.$model}} failed attempts</small>
            </div>
          </div>
          <transition name="fade">
            <div v-if="$v.policy.lockAccount.$model">
              <div class="form-group row">
                <label for="permanentLockout" class="col-form-label col-md-3 offset-md-1">Permanently Lock Account</label>
                <div class="col-md-4">
                  <p-check :labelId="'permanentLockout'"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.policy.permanentlyLockAccount.$model"
                  ></p-check>
                  <br />
                  <small>This will lock the account after {{$v.policy.numInvalidAttempts.$model}} failed attempts and will only unlock after an admin unlocks it</small>
                </div>
              </div>
              <div class="form-group row">
                <label for="informAdmins"
                  class="col-form-label col-md-3 offset-md-1"
                >Send an email to specific administrators</label>
                <div class="col-md-2">
                  <p-check :labelId="'informAdmins'"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.policy.sendAdminEmail.$model"
                  ></p-check>
                </div>
              </div>
              <transition name="fade">
                <div
                  class="form-group row pl-5"
                  v-if="$v.policy.sendAdminEmail.$model"
                  :class="{invalid: $v.policy.adminEmailAddress.$error}"
                >
                  <label
                    class="col-form-label col-md-3 offset-md-1 required"
                  >Specific administrators</label>
                  <div class="col-md-5">
                    <vue-select
                      multiple
                      v-model="$v.policy.adminEmailAddress.$model"
                      :options="adminUsers"
                      :closeOnSelect="false"
                    ></vue-select>
                    <validation-messages v-model="$v.policy.adminEmailAddress"></validation-messages>
                  </div>
                </div>
              </transition>

              <div class="form-group row">
                <label for="excludeSpecific" class="col-form-label col-md-3 offset-md-1">Exclude specific users</label>
                <div class="col-md-2">
                  <p-check :labelId="'excludeSpecific'"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="$v.policy.excludeSpecificUsers.$model"
                  ></p-check>
                </div>
              </div>
              <transition name="fade">
                <div
                  class="form-group row pl-5"
                  v-if="$v.policy.excludeSpecificUsers.$model"
                  :class="{invalid: $v.policy.excludedUsers.$error}"
                >
                  <label class="col-form-label col-md-3 offset-md-1">Excluded users</label>
                  <div class="col-md-5">
                    <vue-select
                      multiple
                      v-model="$v.policy.excludedUsers.$model"
                      :options="users"
                      :closeOnSelect="false"
                    ></vue-select>
                    <validation-messages v-model="$v.policy.excludedUsers"></validation-messages>
                  </div>
                </div>
              </transition>
            </div>
          </transition>

          <div class="form-group row">
            <label for="createAuditMessage" class="col-form-label col-md-3 offset-md-1">Create an audit message</label>
            <div class="col-md-2">
              <p-check :labelId="'createAuditMessage'"
                class="p-switch p-fill"
                color="primary"
                v-model="$v.policy.createAuditMessage.$model"
              ></p-check>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <button type="submit" class="btn btn-primary" :disabled="isLoading">Save</button>
          <button
            type="button"
            reset
            class="btn btn-outline-warning ml-3"
            @click="reset"
            :disabled="isLoading"
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import { required, minValue, maxValue, numeric, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [DataLeaveMixin, loading],
  components: {
    VueSelect
  },
  computed: {
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      policy: {
        numInvalidAttempts: 3,
        lockAccount: false,
        permanentlyLockAccount: false,
        createAuditMessage: false,
        sendAdminEmail: false,
        adminEmailAddress: [],
        excludeSpecificUsers: false,
        excludedUsers: []
      },
      users: [],
      adminUsers: [],
      errors: []
    }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/IncorrectPassword`, this.policy,
          { showload: true, showerror: true, errormessage: 'Incorrect Password failed to save' })
        this.$toastr.s('Incorrect Password policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$reset()
      this.policy = {
        numInvalidAttempts: 3,
        lockAccount: false,
        permanentlyLockAccount: false,
        createAuditMessage: false,
        sendAdminEmail: false,
        adminEmailAddress: [],
        excludeSpecificUsers: false,
        excludedUsers: []
      }
      this.errors = []
      this.$toastr.i('Incorrect Password policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        this.users = await this.$store.dispatch('getUsersAsDropDownSource')
        this.adminUsers = await this.$store.dispatch('getUsersAsDropDownSource', { role: roles.CustomerAdministrator })
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/IncorrectPassword`,
          { showload: true, showerror: true, errormessage: 'Incorrect Password failed to load' })
        this.policy = response.data
      } catch { }
    }
  },
  validations: {
    policy: {
      numInvalidAttempts: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(9)
      },
      lockAccount: {},
      permanentlyLockAccount: {},
      createAuditMessage: {},
      sendAdminEmail: {},
      adminEmailAddress: {
        required: requiredIf((value) => {
          return value.sendAdminEmail && value.lockAccount
        })
      },
      excludeSpecificUsers: {},
      excludedUsers: {
        required: requiredIf((value) => {
          return value.excludeSpecificUsers && value.lockAccount
        })
      }
    }
  }
}
</script>
