<template>
  <div id="serviceWindow">
    <div class="row form-group">
      <div class="col-md-1">Network</div>
      <div class="col-md-2">
        <b-select
          v-model.trim="selectedNetworkType"
          :options="networkTypes"
          @change="getServiceWindows"
        />
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-1">Window</div>
      <div class="col-md-3">
        <b-select
          v-model.trim="selectedWindowType"
          :options="serviceWindowTypes"
          @change="getServiceWindows"
        />
      </div>
    </div>
    <div v-if="serviceWindowLoaded">
      <div class="row form-group">
        <div class="col-md-12">
          <vue-good-table
            mode="remote"
            @on-row-click="onRowClick"
            :columns="columns"
            :rows="serviceWindow"
            styleClass="vgt-table striped bordered"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'excludePublicHolidays'">
                <span v-if="props.row.excludePublicHolidays === true">
                  <label class="sr-only" for="excludePublicHolidays">Exclude Public Holidays?</label>
                  <p-check :labelId="'excludePublicHolidays'" class="p-switch p-fill" color="primary" checked :disabled="true"></p-check>
                </span>
                <span v-else>
                  <label class="sr-only" for="excludePublicHolidays">Exclude Public Holidays?</label>
                  <p-check :labelId="'excludePublicHolidays'" class="p-switch p-fill" color="primary" :disabled="true"></p-check>
                </span>
              </span>
              <span v-else-if="props.column.field === 'isTwentyFourSeven'">
                <span v-if="props.row.isTwentyFourSeven === true">
                  <label class="sr-only" for="isTwentyFourSeven">Twenty Four Seven?</label>
                  <p-check :labelId="'isTwentyFourSeven'" class="p-switch p-fill" color="primary" checked :disabled="true"></p-check>
                </span>
                <span v-else>
                  <label class="sr-only" for="isTwentyFourSeven">Twenty Four Seven?</label>
                  <p-check  :labelId="'isTwentyFourSeven'" class="p-switch p-fill" color="primary" :disabled="true"></p-check>
                </span>
              </span>
              <span v-else-if="props.column.field === 'isProcessingDay'">
                <span v-if="props.row.isProcessingDay === true">
                  <label class="sr-only" for="isProcessingDay">Twenty Four Seven?</label>
                  <p-check :labelId="'isProcessingDay'" class="p-switch p-fill" color="primary" checked :disabled="true"></p-check>
                </span>
                <span v-else>
                  <label class="sr-only" for="isProcessingDay">Twenty Four Seven?</label>
                  <p-check :labelId="'isProcessingDay'" class="p-switch p-fill" color="primary" :disabled="true"></p-check>
                </span>
              </span>
              <span v-else-if="props.column.type === 'boolean'">
                <label class="sr-only" for="selectCheck">Twenty Four Seven?</label>
                <input type="checkbox" id="selectCheck" />
              </span>
              <span v-else-if="props.column.field === 'day'">{{getDay(props.row.day)}}</span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
          </vue-good-table>
        </div>
      </div>
      <editServiceWindow
        v-model="isModalVisible"
        :serviceWindowDay="currentServiceWindowDay"
        :networkTypes="networkTypes"
        :serviceWindowTypes="serviceWindowTypes"
        :daysOfWeek="daysOfWeek"
        @close="editCancelled"
        @savedServiceWindow="savedServiceWindow"
      ></editServiceWindow>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EditServiceWindow from '@/Views/Bacs/BacsAdmin/EditServiceWindow.vue'

export default {
  components: {
    editServiceWindow: EditServiceWindow
  },

  data () {
    return {
      networkTypes: ['BacstelIp', 'SecureIp'],
      serviceWindowTypes: ['Live Submission Window', 'Test Submission Window'],
      selectedNetworkType: '',
      selectedWindowType: '',
      serviceWindow: [],
      serviceWindowLoaded: false,
      columns: [
        {
          label: 'Day',
          field: 'day'
        },
        {
          label: 'Start Time',
          field: 'startTime'
        },
        {
          label: 'End Time',
          field: 'endTime'
        },
        {
          label: 'Exclude Public Holidays',
          field: 'excludePublicHolidays',
          type: 'boolean',
          html: true,
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          label: 'Is 24/7',
          field: 'isTwentyFourSeven',
          type: 'boolean',
          html: true,
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          label: 'Is Processing Day',
          field: 'isProcessingDay',
          type: 'boolean',
          html: true,
          thClass: 'text-left',
          tdClass: 'text-left'
        }
      ],
      daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      currentServiceWindowDay: {},
      isModalVisible: false
    }
  },

  methods: {
    async getServiceWindows () {
      // console.log('getEngineConfig: ' + this.selectedNetworkType + ', ' + this.selectedWindowType)
      if (this.selectedNetworkType !== '' && this.selectedWindowType !== '') {
        this.serviceWindowLoaded = false
        this.serviceWindow = []
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getServiceWindows?networkType=` + this.selectedNetworkType + '&windowType=' + this.selectedWindowType)
        this.serviceWindow = response.data
        this.$store.dispatch('setServiceWindowData', this.serviceWindow)
        this.serviceWindowLoaded = true
      }
    },

    getDay (day) {
      return this.daysOfWeek[day]
    },

    onRowClick (params) {
      // console.log('Row details: ' + JSON.stringify(params))
      this.currentServiceWindowDay = params.row
      this.isModalVisible = true
    },

    editCancelled () {
      this.isModalVisible = false
    },

    savedServiceWindow () {
      this.isModalVisible = false
      this.getServiceWindows()
    }
  },

  async mounted () {
    this.serviceWindowLoaded = false
    this.serviceWindow = this.$store.getters.serviceWindowData
    if (this.serviceWindow !== null && Object.keys(this.serviceWindow).length > 0) {
      this.selectedNetworkType = this.networkTypes[this.serviceWindow[0].paymentNetworkType]
      this.selectedWindowType = this.serviceWindowTypes[this.serviceWindow[0].serviceWindowType]
      this.serviceWindowLoaded = true
    }
  }
}
</script>
