<template>
  <div>
    <bacs-group-config-report
      :group="group"
      :includeUsers="false"
      :bacsLicenceSettings="bacsLicenceSettings"
    ></bacs-group-config-report>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Submission Window Type</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.submissionWindowType}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.submissionWindowType == 'MultiDay'">
      <label class="col-3 offset-1 col-form-label">Window Size</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.multidayWindowSize}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Advance Notice Period (In Days)</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.advanceNoticeInDays}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Default Contra Narrative</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.defaultContraNarrative}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">AUDDIS Group</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.isAuddis" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Service User Name</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserName}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserAddress1">
      <label class="col-3 offset-1 col-form-label">Address 1</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserAddress1}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserAddress2">
      <label class="col-3 offset-1 col-form-label">Address 2</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserAddress2}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserAddress3">
      <label class="col-3 offset-1 col-form-label">Address 3</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserAddress3}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserAddress4">
      <label class="col-3 offset-1 col-form-label">Address 4</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserAddress4}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserPostcode">
      <label class="col-3 offset-1 col-form-label">Postcode</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserPostcode}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserTelephone">
      <label class="col-3 offset-1 col-form-label">Service User Telephone</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserTelephone}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserFax">
      <label class="col-3 offset-1 col-form-label">Service User Fax</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserFax}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserEmail">
      <label class="col-3 offset-1 col-form-label">Email Address</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserEmail}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserContactName1">
      <label class="col-3 offset-1 col-form-label">Service User Contact Name 1</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserContactName1}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserContactName2">
      <label class="col-3 offset-1 col-form-label">Service User Contact Name 2</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserContactName2}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserContactName3">
      <label class="col-3 offset-1 col-form-label">Service User Contact Name 3</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserContactName3}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField1">
      <label class="col-3 offset-1 col-form-label">Custom Field 1</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField1}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField2">
      <label class="col-3 offset-1 col-form-label">Custom Field 2</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField2}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField3">
      <label class="col-3 offset-1 col-form-label">Custom Field 3</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField3}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField4">
      <label class="col-3 offset-1 col-form-label">Custom Field 4</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField4}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField5">
      <label class="col-3 offset-1 col-form-label">Custom Field 5</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField5}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField6">
      <label class="col-3 offset-1 col-form-label">Custom Field 6</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField6}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField7">
      <label class="col-3 offset-1 col-form-label">Custom Field 7</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField7}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.serviceUserCustomField8">
      <label class="col-3 offset-1 col-form-label">Custom Field 8</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserCustomField8}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Default Payer Message Type</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.defaultPayerMessageType}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Letter File Export Type</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.docxExportType}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.emailBccList">
      <label class="col-3 offset-1 col-form-label">Email BCC Address List</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.emailBccList}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.letterOutputFolder">
      <label class="col-3 offset-1 col-form-label">Letter File Export Folder</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.letterOutputFolder}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.domainEmailId">
      <label class="col-3 offset-1 col-form-label">Email Address</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.domainEmailId}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Update Scheduled Payments On Error</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.updateSchedPaymentOnError" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Enable Schedule Suppression</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.suppressionEnabled" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.suppressionEnabled">
      <label class="col-3 offset-1 col-form-label">Suppression Start Day</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.suppressionDayStart}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.suppressionEnabled">
      <label class="col-3 offset-1 col-form-label">Suppression Start Month</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.suppressionMonthStart}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.suppressionEnabled">
      <label class="col-3 offset-1 col-form-label">Suppression End Day</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.suppressionDayEnd}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.suppressionEnabled">
      <label class="col-3 offset-1 col-form-label">Suppression End Month</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.suppressionMonthEnd}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Group Workflow Type</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.groupWorkflowType}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">API Active</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.apiActive" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Use Default Variable Amount File Mapping</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.defaultVariableAmountMapping" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Variable Amount File Mapping</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.variableAmountMappingId}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Default Plan Template</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.defaultPlanTemplateId}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Payer Reference Generation Pattern</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.payerReferencePattern}}</span>
        </div>
      </div>
    </div>

    <group-config-users :users="group.users"></group-config-users>
  </div>
</template>

<script>
import GroupConfigUsers from './GroupConfigUsers'
import BacsGroupConfigReport from './BacsGroupConfigReport'

export default {
  props: {
    group: {
      required: true,
      type: Object
    },
    bacsLicenceSettings: {
      type: Object
    }
  },
  components: {
    GroupConfigUsers,
    BacsGroupConfigReport
  }
}
</script>

<style>
</style>
