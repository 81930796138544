<template>
  <div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Watched Folder</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.watchedFolder"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Default Sub Reference</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.defaultSubmissionReference"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Default Contra Narrative</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.defaultContraNarrative"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Stop on Duplicate File</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.stopOnDuplicateFile"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Stop on Pre-sub Val Fix</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.stopOnFix"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-3 text-right col-form-label"
      >Stop on Pre-sub Val Warning</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.stopOnWarning"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Remove Fix Payments</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.removeFixPayments"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row" v-if="actionAutoFileImport.removeFixPayments">
      <label for="description" class="col-sm-3 text-right col-form-label">Removed Fix Action</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.removedFixesAction"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Remove Warning Payments</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.removeWarningPayments"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row" v-if="actionAutoFileImport.removeWarningPayments">
      <label for="description" class="col-sm-3 text-right col-form-label">Removed Warning Action</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.removedWarningsAction"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">Remove Duplicate Payments</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.removeDuplicatePayments"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row" v-if="actionAutoFileImport.removeDuplicatePayments">
      <label for="description" class="col-sm-3 text-right col-form-label">Removed Duplicate Action</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.removedDuplicatesAction"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-3 text-right col-form-label">File Save Folder</label>
      <div class="col-sm-9">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.fileSaveFolder"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { diffSentences } from 'diff'

export default {
  props: ['status', 'groupAutoFileImport', 'actionAutoFileImport'],
  computed: {
    difference () {
      const diff = Object.keys(this.groupAutoFileImport).reduce((a, v) => {
        if (this.groupAutoFileImport[v] === null) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.groupAutoFileImport[v] = ''
        }

        if (this.actionAutoFileImport[v] === null || this.actionAutoFileImport[v] === undefined) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.actionAutoFileImport[v] = ''
        }

        var tempObj = {}

        tempObj[v] = diffSentences(this.groupAutoFileImport[v].toString(), this.actionAutoFileImport[v].toString())
        return Object.assign(a, tempObj)
      }, {})
      return diff
    }
  }
}
</script>
