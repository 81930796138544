var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", "min-width": "25vw" } },
    [
      _c("label", { staticClass: "sr-only" }, [_vm._v("Select Customer")]),
      _c("vue-select", {
        attrs: {
          id: "select",
          options: _vm.customers,
          closeOnSelect: true,
          label: "name",
          reduce: function(customer) {
            return customer.paygateId
          },
          clearable: false,
          selectable: function(option) {
            return !option.hasOwnProperty("group")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function(ref) {
              var group = ref.group
              var name = ref.name
              return [
                group
                  ? _c("div", { staticClass: "group" }, [_vm._v(_vm._s(group))])
                  : _vm._e(),
                _vm._v(" " + _vm._s(name) + " ")
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedPaygateId,
          callback: function($$v) {
            _vm.selectedPaygateId = $$v
          },
          expression: "selectedPaygateId"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }