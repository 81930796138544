var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "content-block",
        [
          _c(
            "h2",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("help-icon", {
                attrs: {
                  docPath: "/paygate-collections/messaging/messagequeue/"
                }
              }),
              _vm._v("Message Queue"),
              _c("favourite-icon")
            ],
            1
          ),
          _c("messageQueue", { attrs: { initialFilter: _vm.initialFilter } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }