<template>
  <div style="height:100%">
    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">Pending Bureau Submissions</h5>
      <i @click="load" role="button" class="fas fa-sync-alt pull-right"><span class="sr-only">Refresh</span></i>
    </div>
    <VuePerfectScrollbar class="scroll-area" :settings="settings">
      <vue-good-table
        :isLoading.sync="isTableLoading"
        mode="remote"
        ref="table"
        :columns="columns"
        :rows="rows"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        @on-row-click="onRowClick"
        :totalRows="totalRecords"
        :search-options="{
            enabled: false
          }"
        :paginationOptions="paginationOptions"
        :sort-options="sortOptions"
        styleClass="vgt-table striped bordered condensed"
      >
        <template slot="loadingContent">
          <h1>Loading...</h1>
        </template>
        <template slot="emptystate">
          <span>You do not currently have any pending bureau jobs</span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { setInterval } from 'timers'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [tableFilterMixin, loading],
  name: 'BureauPendingJobsWidget',
  components: {
    VuePerfectScrollbar
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  computed: {
    paygateId () { return this.$store.state.common.paygateId }
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60
      },
      intervalBit: null,
      rows: [],
      columns: [
        { label: 'Reference', field: 'submissionReference' },
        { label: 'Job Name', field: 'bureauJobName' },
        { label: 'Status', field: 'status', formatFn: this.formatStatus, tdClass: 'text-center', width: '130px' },
        {
          label: 'Created Date',
          field: 'createdDate',
          formatFn: (value) => {
            const retVal = this.formatDate(new Date(value))
            return retVal
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'createdDate', type: 'desc' }]
      },
      sortOptions: { enabled: true, initialSortBy: { field: 'createdDate', type: 'desc' } },
    }
  },
  async created () {
    this.load()
    this.intervalBit = setInterval(this.load, 30000)
  },
  beforeRouteLeave () {
    clearInterval(this.intervalBit)
  },
  methods: {
    async load () {
      try {
        if (this.$route.name === 'Dashboard') {
          this.isTableLoading = true
          const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getPendingSubmissionsquery', {
            params: this.buildGoodTableQuery(),
            paygateid: this.paygateId
          })
          this.rows = response.data.data
          this.totalRecords = response.data.meta.totalItems
          this.isTableLoading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    async onRowClick (e) {
      await this.onViewPaymentsClick(e.row)
    },
    async onViewPaymentsClick (data) {
      switch (data.lastScreenState) {
        case 'BureauManualBacsAddInitialFiles':
          this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: data.bureauSubmissionId } })
          break
        case 'BureauManualFPSAddInitialFiles':
          this.$router.push({ name: 'ManualFPSSubmissionAddFiles', params: { id: data.bureauSubmissionId } })
          break
        case 'BureauManualBacsImporting':
          this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: data.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauManualFPSImporting':
          this.$router.push({ name: 'ManualFPSImportPaymentFiles', params: { id: data.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoBacsImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: data.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoFPSImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: data.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauBacsViewPayments':
          this.$router.push({ name: 'PaymentDetails', params: { id: data.bureauSubmissionId } })
          break
        case 'BureauFPSViewPayments':
          this.$router.push({ name: 'PaymentDetailsFPS', params: { id: data.bureauSubmissionId } })
          break
        case 'BureauActionsPage':
          var actionUrl = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${data.bureauSubmissionId}`, { showload: true })

          if (actionUrl.data) {
            this.$router.replace(returnRouteObject(actionUrl.data))
          } else {
            this.autoReturnToHomePage = true
            this.$router.push('/')
          }
          break
      }
    },
    onViewPaymentsClickOLD (submission) {
      this.$store.dispatch('setSubmission', submission)
      this.$router.push({ name: 'PaymentDetails' })
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value) {
        case 'AwaitingApproval':
          retVal = 'warning'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatDate (datestr) {
      const date = new Date(datestr)
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var h = date.getHours()
      var m = date.getMinutes()
      var s = date.getSeconds()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    truncate (input) {
      var output = input
      if (input.length > 100) {
        output = input.substring(0, 90) + '...'
      }
      return output
    },
    formatStatus (field, row) {
      if (field === 'AwaitingApproval') {
        return row.bacsPaymentStatus === 'None' ? 'Awaiting Approval' : 'Submission (' + row.bacsPaymentStatus + ')'
      } else { return field }
    }
  }
}
</script>

<style lang="scss" scoped>
.textRow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10px;
}

.scroll-area {
  margin-top: 20px;
  height: calc(100% - 25px);
  min-height: unset;
  border-top: solid 1px #eeeeee;
}
</style>
