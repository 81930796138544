<template>
  <div>
    <div class="form-group row" v-if="users.length !== 0">
      <table class="table offset-1">
        <thead>
          <tr>
            <th scope="col" width="25.5%">Users Assigned to Group</th>
            <th scope="col" width="35%">User's Roles within the Group</th>
            <th scope="col">Upload Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.name">
            <td>{{user.email}}</td>
            <td>{{user.roles == '0'? 'None': user.roles}}</td>
            <td>
              {{user.uploadType}}
              <span
                v-if="user.uploadType == 'Secure Cloud Folder' || user.uploadType == 'Restricted File Browser'"
              >
                <br />
                File Upload Directory: {{user.uploadPath}}
              </span>
              <span v-if="user.uploadType == 'Restricted File Browser'">
                <br />User Cannot Browse Sub-folders:
                <tick v-model="user.directoryLocked" :coloured="false"></tick>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-group row" v-else>
      <label class="col-3 offset-1 col-form-label">Users Assigned to Group</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>No Users</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupConfigUsers',
  props: {
    users: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
