<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        <help-icon docPath="/paygate-collections/messaging/assigntemplates/" />Manage Message Templates
        <favourite-icon></favourite-icon>
      </h2>
    </div>
    <div class="idb-block-content">
      <b-tabs>
        <b-tab title="Letter" active>
          <vue-good-table
            mode="remote"
            :columns="letterColumns"
            :rows="letterRows"
            @on-row-click="onLetterRowClick"
            @on-row-mouseenter="onLetterRowClick"
            @on-row-mouseleave="onLetterRowClick"
            :search-options="{
                    enabled: true
                    }"
            :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                  }"
            :totalRows="totalLetterRecords"
            @on-page-change="onLetterPageChange"
            @on-sort-change="onLetterSortChange"
            @on-column-filter="onLetterColumnFilter"
            @on-per-page-change="onLetterPerPageChange"
            @on-search="onLetterSearch"
            :lineNumbers="true"
            styleClass="vgt-table striped bordered"
          >
            <template slot="loadingContent">
              <h1>Loading...</h1>
            </template>
            <div slot="emptystate">
              <div class="vgt-center-align vgt-text-disabled">No Letter Templates Available</div>
            </div>
            <div slot="table-actions">
              <b-button
                @click.prevent="reloadLetterItems"
                class
                variant="link"
                title="Refresh Table"
                v-b-popover.hover.top.d500="'Refresh the data in the table'"
              >
                <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
              </b-button>
              <!-- <b-button
                @click.prevent="printLetterTable"
                class
                variant="link"
                title="Print Table"
                v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
              >
                <i class="fa fa-print pointer dimmedIcon"></i>
              </b-button>
              <b-button
                @click.prevent="exportLetterTable"
                class
                variant="link"
                title="Export Table"
                v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
              >
                <i class="fa fa-share-square pointer dimmedIcon"></i>
              </b-button>-->
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'downloadUrl'">
                <b-btn
                  variant="outline-primary"
                  @click.prevent="downloadDocx(props.row.templateId)"
                  title="Download Template Document"
                >
                  <i class="fa fa-download"></i>
                </b-btn>
              </span>
              <span v-else-if="props.column.field === 'deleteUrl'">
                <b-btn
                  variant="outline-danger"
                  @click.prevent="deleteTemplate(props.row)"
                  title="Delete Template"
                >
                  <i class="fa fa-trash"></i>
                </b-btn>
              </span>
              <span v-else-if="props.column.field === 'preview'">
                <router-link
                  :to="{ name: 'SpecificLetterTemplate', params: { templateid: props.row.templateId }  }"
                  class="btn btn-primary"
                  title="View / Edit Template"
                >View / Edit</router-link>
              </span>
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>
          </vue-good-table>
        </b-tab>
        <b-tab title="Email">
          <vue-good-table
            mode="remote"
            :columns="emailColumns"
            :rows="emailRows"
            :search-options="{
                    enabled: true
                    }"
            :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                  }"
            :totalRows="totalEmailRecords"
            @on-page-change="onEmailPageChange"
            @on-sort-change="onEmailSortChange"
            @on-column-filter="onEmailColumnFilter"
            @on-per-page-change="onEmailPerPageChange"
            @on-search="onEmailSearch"
            :lineNumbers="true"
            styleClass="vgt-table striped bordered"
          >
            <template slot="loadingContent">
              <h1>Loading...</h1>
            </template>
            <div slot="emptystate">
              <div class="vgt-center-align vgt-text-disabled">No Email Templates Available</div>
            </div>
            <div slot="table-actions">
              <b-button
                @click.prevent="reloadEmailItems"
                class
                variant="link"
                title="Refresh Table"
                v-b-popover.hover.top.d500="'Refresh the data in the table'"
              >
                <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
              </b-button>
              <!-- <b-button
                @click.prevent="printEmailTable"
                class
                variant="link"
                title="Print Table"
                v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
              >
                <i class="fa fa-print pointer dimmedIcon"></i>
              </b-button>
              <b-button
                @click.prevent="exportEmailTable"
                class
                variant="link"
                title="Export Table"
                v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
              >
                <i class="fa fa-share-square pointer dimmedIcon"></i>
              </b-button>-->
            </div>
            <template v-b-tooltip="'ToolTip!'" slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'deleteUrl'">
                <b-btn
                  variant="outline-danger"
                  @click.prevent="deleteTemplate(props.row)"
                  title="Delete Template"
                >
                  <i class="fa fa-trash"></i>
                </b-btn>
              </span>
              <span v-else-if="props.column.field === 'preview'">
                <router-link
                  :to="{ name: 'SpecificEmailTemplate', params: { templateid: props.row.templateId }  }"
                  class="btn btn-primary"
                  title="View / Edit Template"
                >View / Edit</router-link>
              </span>
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>
          </vue-good-table>
        </b-tab>
        <b-tab title="SMS">
          <vue-good-table
            mode="remote"
            :columns="smsColumns"
            :rows="smsRows"
            :search-options="{
                    enabled: true
                    }"
            :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                  }"
            :totalRows="totalSmsRecords"
            @on-page-change="onSmsPageChange"
            @on-sort-change="onSmsSortChange"
            @on-column-filter="onSmsColumnFilter"
            @on-per-page-change="onSmsPerPageChange"
            @on-search="onSmsSearch"
            :lineNumbers="true"
            styleClass="vgt-table striped bordered"
          >
            <template slot="loadingContent">
              <h1>Loading...</h1>
            </template>
            <div slot="emptystate">
              <div class="vgt-center-align vgt-text-disabled">No SMS Templates Available</div>
            </div>
            <div slot="table-actions">
              <b-button
                :disabled="isLoading"
                @click.prevent="reloadSmsItems"
                class
                variant="link"
                v-b-popover.hover.top.d500="'Refresh the data in the table'"
              >
                <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
              </b-button>
              <!-- <b-button
                @click.prevent="printSmsTable"
                class
                variant="link"
                title="Print Table"
                v-b-popover.hover.top.d500="'Print out the contents of the Logs table'"
              >
                <i class="fa fa-print pointer dimmedIcon"></i>
              </b-button>
              <b-button
                @click.prevent="exportSmsTable"
                class
                variant="link"
                title="Export Table"
                v-b-popover.hover.top.d500="'Export the contents of the Logs table'"
              >
                <i class="fa fa-share-square pointer dimmedIcon"></i>
              </b-button>-->
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'deleteUrl'">
                <b-btn
                  :disabled="isLoading"
                  variant="outline-danger"
                  @click.prevent="deleteTemplate(props.row)"
                  title="Delete Template"
                >
                  <i class="fa fa-trash"></i>
                </b-btn>
              </span>
              <span v-else-if="props.column.field === 'preview'">
                <router-link
                  :to="{ name: 'SpecificSmsTemplate', params: { templateid: props.row.templateId }  }"
                  class="btn btn-primary"
                  title="View / Edit Template"
                >View / Edit</router-link>
              </span>
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>
          </vue-good-table>
        </b-tab>
      </b-tabs>
    </div>
    <div class="idb-block-footer">
      <b-dropdown variant="primary">
        <!-- no-caret -->
        <span slot="button-content">
          <i class="fa fa-plus rpad"></i>Create Template
        </span>
        <b-dropdown-item @click="goToTemplate('LetterDesigner')">
          <i class="fa fa-envelope rpad" title="Letter"></i>Letter
        </b-dropdown-item>
        <b-dropdown-item @click="goToTemplate('EmailDesigner')">
          <i class="fa fa-at rpad" title="Email"></i>Email
        </b-dropdown-item>
        <b-dropdown-item @click="goToTemplate('SmsDesigner')">
          <i class="fa fa-mobile rpad" title="SMS"></i>SMS
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import _ from 'lodash'
import FileSaver from 'file-saver'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  methods: {
    async downloadDocx (templateId) {
      try {
        let url = `${this.baseDownloadUri}/${templateId}/docx`
        const response = await axios.get(url, {
          responseType: 'blob',
          headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          }
        })
        FileSaver.saveAs(response.data, `${templateId}-${new Date().toISOString()}.docx`)
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to download template.', 'An error occurred.')
      }
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onLetterPageChange (params) {
      this.updateLetterParams({ page: 1, page: params.currentPage })
      this.reloadLetterItems()
    },
    onLetterSortChange (params) {
      this.updateLetterParams({
        sort: params[0]
      })
      this.reloadLetterItems()
    },
    onLetterColumnFilter (params) {
      this.updateLetterParams(params)
      this.reloadLetterItems()
    },
    onLetterPerPageChange ({ currentPerPage }) {
      this.updateLetterParams({ perPage: currentPerPage })
      this.reloadLetterItems()
    },
    onLetterSearch ({ searchTerm }) {
      this.letterServerParams.searchKey = searchTerm
      this.reloadLetterItems()
    },
    updateLetterParams (newProps) {
      this.letterServerParams = Object.assign({}, this.letterServerParams, newProps)
    },
    reloadLetterItems: _.debounce(async function () {
      try {
        const query = this.buildGoodTableQuery(this.letterServerParams, this.$store.state.common.paygateId)

        const { data } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/letter`, { params: query })
        this.totalLetterRecords = data.totalItems
        this.letterRows = data.data
      } catch (e) {
        this.$toastr.e('Unable to get Letter Templates', 'Error')
      }
    }, 300),
    onLetterRowClick (arg) {
      this.letterRowHover = arg.row
    },
    async deleteTemplate (row) {
      const { templateId, name } = row
      const willDelete = await this.$swal({
        title: `Delete '${name}'?`,
        text: 'You will not be able to recover the template.',
        type: 'warning',
        animation: true,
        showCancelButton: true
      })
      if (willDelete) {
        try {
          await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/${templateId}`)
          const refreshedLetters = this.letterRows.filter((val, index) => val.templateId !== templateId)
          this.totalLetterRecords = this.totalLetterRecords - (this.letterRows - refreshedLetters)
          this.letterRows = refreshedLetters

          const refreshedEmails = this.emailRows.filter((v, i) => v.templateId !== templateId)
          this.totalEmailRecords = this.totalEmailRecords - (this.emailRows - refreshedEmails)
          this.emailRows = refreshedEmails

          const refreshedSms = this.smsRows.filter((v, i) => v.templateId !== templateId)
          this.totalSmsRecords = this.totalSmsRecords - (this.smsRows - refreshedSms)
          this.smsRows = refreshedSms
          this.$toastr.s(`Template '${name}' has been deleted.`, 'Template Deleted')
        } catch (e) {
          this.$toastr.e(`Unable to delete the template.`, 'Error Occurred')
        }
      }
    },
    onEmailPageChange (params) {
      this.updateEmailParams({ page: params.currentPage })
      this.reloadEmailItems()
    },
    onEmailSortChange (params) {
      this.updateEmailParams({
        sort: params[0]
      })
      this.reloadEmailItems()
    },
    onEmailColumnFilter (params) {
      this.updateEmailParams(params)
      this.reloadEmailItems()
    },
    onEmailPerPageChange ({ currentPerPage }) {
      this.updateEmailParams({ page: 1, perPage: currentPerPage })
      this.reloadEmailItems()
    },
    onEmailSearch ({ searchTerm }) {
      this.emailServerParams.searchKey = searchTerm
      this.reloadEmailItems()
    },
    updateEmailParams (newProps) {
      this.emailServerParams = Object.assign({}, this.emailServerParams, newProps)
    },
    reloadEmailItems: _.debounce(async function () {
      try {
        const query = this.buildGoodTableQuery(this.emailServerParams, this.$store.state.common.paygateId)
        const { data } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/email`, { params: query })
        this.totalEmailRecords = data.totalItems
        this.emailRows = data.data
      } catch (e) {
        this.$toastr.e('Unable to get Email Templates', 'Error')
      }
    }, 300),
    onSmsPageChange (params) {
      this.updateSmsParams({ page: params.currentPage })
      this.reloadSmsItems()
    },
    onSmsSortChange (params) {
      this.updateSmsParams({
        sort: params[0]
      })
      this.reloadSmsItems()
    },
    onSmsColumnFilter (params) {
      this.updateSmsParams(params)
      this.reloadSmsItems()
    },
    onSmsPerPageChange ({ currentPerPage }) {
      this.updateSmsParams({ page: 1, perPage: currentPerPage })
      this.reloadSmsItems()
    },
    onSmsSearch ({ searchTerm }) {
      this.smsServerParams.searchKey = searchTerm
      this.reloadSmsItems()
    },
    updateSmsParams (newProps) {
      this.smsServerParams = Object.assign({}, this.smsServerParams, newProps)
    },
    buildGoodTableQuery (serverParams, paygateId) {
      const query = {}
      query.sort = serverParams.sort.field + ':' + serverParams.sort.type
      query.perPage = serverParams.perPage
      query.page = serverParams.page
      if (serverParams.searchKey) {
        query.searchFilter = serverParams.searchKey
      }
      query.paygateId = paygateId
      return query
    },
    reloadSmsItems: _.debounce(async function () {
      try {
        const query = this.buildGoodTableQuery(this.smsServerParams, this.$store.state.common.paygateId)
        const { data } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/sms`, { params: query })
        this.totalSmsRecords = data.totalItems
        this.smsRows = data.data
      } catch (e) {
        this.$toastr.e('Unable to get SMS Templates', 'Error')
      }
    }, 300),
    async reloadAllItems (paygateId) {
      try {
        const sms = async () => {
          const searchParams = {
            params: this.buildGoodTableQuery(this.smsServerParams, this.$store.state.common.paygateId)
          }
          const { data: { totalItems, data: rows } } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/sms`, searchParams)
          this.totalSmsRecords = totalItems
          this.smsRows = rows
        }

        const letter = async () => {
          const searchParams = {
            params: this.buildGoodTableQuery(this.letterServerParams, this.$store.state.common.paygateId)
          }
          const { data: { totalItems, data: rows } } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/letter`, searchParams)
          this.totalLetterRecords = totalItems
          this.letterRows = rows
        }

        const email = async () => {
          const searchParams = {
            params: this.buildGoodTableQuery(this.emailServerParams, this.$store.state.common.paygateId)
          }
          const { data: { totalItems, data: rows } } = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/email`, searchParams)
          this.totalEmailRecords = totalItems
          this.emailRows = rows
        }

        await Promise.all([
          sms(),
          letter(),
          email()
        ])
      } catch (e) {
        this.$toastr.e('Loading Templates Failed', 'Error Occurred')
      }
    },
    goToTemplate (route) {
      this.$router.push({ name: route })
    }
  },
  data () {
    return {
      letterRowHover: { originalIndex: null },
      totalLetterRecords: 0,
      letterServerParams: {
        columnFilters: {},
        sort: {
          field: 'name',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      letterColumns: [
        {
          hidden: true,
          field: 'templateId'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Last Modified',
          field: 'lastModified',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: '',
          field: 'preview',
          width: '5rem',
          sortable: false
        },
        {
          field: 'downloadUrl',
          sortable: false,
          width: '2rem'
        },
        {
          field: 'deleteUrl',
          sortable: false,
          width: '2rem'
        }
      ],
      letterRows: [],
      totalEmailRecords: 0,
      emailServerParams: {
        columnFilters: {},
        sort: {
          field: 'name',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      emailColumns: [
        {
          hidden: true,
          field: 'templateId'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Last Modified',
          field: 'lastModified',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: '',
          field: 'preview',
          width: '5rem',
          sortable: false
        },
        {
          label: '',
          field: 'deleteUrl',
          sortable: false,
          width: '2rem'
        }
      ],
      emailRows: [],
      totalSmsRecords: 0,
      smsServerParams: {
        columnFilters: {},
        sort: {
          field: 'name',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      smsColumns: [
        {
          hidden: true,
          field: 'templateId'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Last Modified',
          field: 'lastModified',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy HH:mm:ss'
        },
        {
          label: '',
          field: 'preview',
          width: '5rem',
          sortable: false
        },
        {
          label: '',
          field: 'deleteUrl',
          sortable: false,
          width: '2rem'
        }
      ],
      smsRows: []
    }
  },
  mounted () {
    const onPaygateChange = (paygateId) => {
      this.reloadAllItems(paygateId)
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    this.reloadAllItems(this.$store.state.common.paygateId)
  },
  computed: {
    baseDownloadUri () {
      return process.env.VUE_APP_DDMS_API_URL + 'designer/letter'
    }
  }
}
</script>
<style scoped>
.table-create-new {
  margin-bottom: 1.4rem;
}

.newTemplateLinks > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.newTemplateLinks li {
  display: inline;
  margin: 0.5rem;
}

.newTemplateLinks i {
  margin-right: 1%;
}

.newTemplateLinks p {
  float: left;
}
</style>
