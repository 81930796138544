import axios from 'axios'

// initial state
const state = {
  behaviourOptions: [
    { text: 'Automatic approval', value: 'Default' },
    { text: 'Manual approval', value: 'Custom' }
  ]
}

export default {
  state
}
