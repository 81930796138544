var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "manageSchedule" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c("div", { staticClass: "idb-block" }, [
                _c("div", { staticClass: "idb-block-title" }, [
                  _c(
                    "h2",
                    [
                      _c("help-icon", {
                        attrs: {
                          docPath:
                            "/paygate-collections/import-export/importpayer/"
                        }
                      }),
                      _vm._v("Message failed to deliver")
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "idb-block-content" }, [
                  _c("p", [
                    _vm._v(
                      "Message failed to send due to : " +
                        _vm._s(_vm.type === "bounced" ? "Bounce" : "Error")
                    )
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Title:")]),
                    _c("br"),
                    _vm._v(_vm._s(_vm.messageTitle))
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Body:")]),
                    _c("br"),
                    _vm._v(_vm._s(_vm.messageBody))
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Sent Date:")]),
                    _c("br"),
                    _vm._v(_vm._s(_vm.sentDate))
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Error:")]),
                    _c("br"),
                    _vm._v(_vm._s(_vm.messageError))
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "idb-block-footer" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "submit",
                          disabled: _vm.sent,
                          variant: "primary"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.resendMessage($event)
                          }
                        }
                      },
                      [_vm._v("Resend Message")]
                    ),
                    _vm.actionId && _vm.actionId !== null
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removeAction($event)
                              }
                            }
                          },
                          [_vm._v("Clear Action")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }