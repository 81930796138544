<template>
	<b-navbar-nav>
		<li class="search" id="search" v-if="isActive" :class="{expanded: isActive}">
			<a class="nav-link" style="padding-top: 17px!important;">
				<input
					aria-label="Search"
					type="search"
					ref="search"
					class="form-control"
					v-model="searchTerm"
					v-on:input="search()"
					v-on:keypress="escape"
				/>
				<div class="dropdown-menu" id="results" :class="{'not-expanded':!isActive}">
					<transition name="fade-fast">
						<div v-if="loading" class="text-center">
							<i class="fas fa-spinner fa-pulse"></i>
						</div>
					</transition>
					<div v-for="result in results" :key="result.typeName">
						<h4 class="dropdown-header bg-secondary text-white">{{result.typeName}}</h4>
						<div
							class="col-sm-12 dropdown-item"
							v-for="(hit, index) in result.hits"
							:key="index"
							@click="goToSearchResult(hit)"
						>
							<search-types v-bind:hit="hit" :type="result.typeName"></search-types>
						</div>
					</div>
					<div v-if="results.length === 0">
						<h4 class="dropdown-header" v-if="hasSearched">No Results found</h4>
					</div>
					<a
						class="dropdown-item text-center bg-dark text-white text-small"
						@click="advancedSearch"
					>Show More Results/Advanced Search</a>
				</div>
			</a>
		</li>

		<b-nav-item href="#" @click="show" style="z-index:999" v-b-popover.focus.hover.bottom="'Search'">
			<i
				class="ti"
				:class="{'ti-search':!isActive,'ti-close':isActive}"
				style="line-height:30px; color:black;"
			></i>
			<span v-if="!isActive" class="sr-only">Search</span>
			<span v-else class="sr-only">Cancel Search</span>
		</b-nav-item>
	</b-navbar-nav>
</template>
<script>

import SearchTypes from '@/Assets/Components/Search/SearchTypes'
import _ from 'lodash'
import axios from 'axios'
import { returnRouteObject } from '@/Assets/Components/Search/searchRouter'

export default {
	computed: {
		searchTerm: {
			get () {
				return this.$store.state.search.searchTerm
			},
			set (value) {
				this.$store.commit('setSearchTerm', value)
			}
		}
	},
	components: {
		SearchTypes
	},
	data () {
		return {
			results: [],
			isActive: false,
			hasSearched: false,
			loading: false
		}
	},
	methods: {
		search: _.debounce(async function (e) {
			try {

				this.loading = true
				if (this.searchTerm === '') {
					this.results = []
					this.hasSearched = false
					return
				}

				this.$gtag.event('search')

				var apiresult = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}search`, {
					params: {
						searchTerm: this.searchTerm
					}
				})
				let data = apiresult.data

				if (data.length > 0) {
					data = _.each(data, function (value) {
						value.hits = _.sortBy(value.hits, function (hit) {
							return -hit.score
						})
					})
					this.results = data
				} else {
					this.results = []
				}
				this.hasSearched = true
			} catch (e) {

			} finally {
				this.loading = false
			}
		}, 500),
		show () {
			this.isActive = !this.isActive
			if (this.isActive) {
				this.$nextTick(() => { this.focusSearch() })
				this.search()
			} else {
				this.results = []
				this.hasSearched = false
			}
		},
		focusSearch () {
			this.$refs.search.focus()
		},
		goToSearchResult (hit) {
			this.$router.push(returnRouteObject(hit.typeName, hit.data))
			this.show()
		},
		escape (e) {
			if (e.key === 'Escape' || e.key === 27) {
				this.show()
			}
		},
		advancedSearch () {
			this.$router.push({ name: 'SearchForm' }).catch((e) =>
				console.error(e))
			this.show()
		}
	},
	mounted () {
		document.onkeydown = (e) => {
			if ((e.metaKey || e.ctrlKey) && (String.fromCharCode(e.which).toLowerCase() === 'e')) {
				e.preventDefault()
				this.show()
			}

			if (this.isActive) {
				if (e.key === 27 || e.key === 'Escape') {
					this.$refs.search.blur()
					this.show()
					e.preventDefault()
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.search {
	opacity: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: 0px;
	&.expanded {
		opacity: 1;
		width: 300px;
	}
}

#results {
	width: 90%;
	position: absolute;
	top: auto !important;
	left: auto !important;
	display: block;
	min-width: auto;
	margin-top: 0;
}

.not-expanded {
	display: none !important;
}
#search {
	position: relative;
}

.text-small {
	font-size: 12px;
}

.sml-image {
	height: 2em;
	display: block;
	margin: 0 auto;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
	transition: opacity 0.2s;
}
.fade-fast-enter, .fade-fast-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
