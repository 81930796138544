var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", size: "logModal", "no-close-on-backdrop": "" },
      on: { hide: _vm.close },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-header" },
          slot: "modal-header"
        },
        [_vm._v("Log Details")]
      ),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("b-form-textarea", {
              attrs: { rows: 20 },
              model: {
                value: _vm.logContents,
                callback: function($$v) {
                  _vm.logContents = $$v
                },
                expression: "logContents"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "float-left",
                      attrs: { size: "sm", variant: "primary" },
                      on: { click: _vm.close }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }