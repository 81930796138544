<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; closeFilePopup()">
    </fileBrowser>
    <fileBrowser v-if="showPvtKeyFileBrowser" @close="showPvtKeyFileBrowser = false; closePvtKeyFilePopup()">
    </fileBrowser>
    <h3>SFTP (SSH) File Transfer<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/filetransfer/sftpfiletransfer/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label>Transfer Type</label>
      <br>
      <p-radio value="Upload" color="primary" v-model="selectedNode.props.s7.value">Upload</p-radio>
      <p-radio value="Download" color="primary" v-model="selectedNode.props.s7.value">Download</p-radio>
    </div>
    <br/>
    <div class="form-group">
      <label class="required" >Host SFTP Server Address</label>
      <input type="url"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The Server Address field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="required">Port</label>
      <input
        type="number"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.int1.value.$error}"
        @input="$v.selectedNode.props.int1.value.$touch()"
        v-model="selectedNode.props.int1.value">
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.required && $v.selectedNode.props.int1.value.$dirty ">The Port field is required!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.minValue">Minimum Port value is {{ $v.selectedNode.props.int1.value.$params.minValue.min }}!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.maxValue">Maximum Port value is {{ $v.selectedNode.props.int1.value.$params.maxValue.max }}!</p>
    </div>
    <br/>
    <label class="required" >Local Folder</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFolderBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty ">The local folder field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="required" >Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s4.value.$error}"
        @input="$v.selectedNode.props.s4.value.$touch()"
        v-model="selectedNode.props.s4.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s4.value.required && $v.selectedNode.props.s4.value.$dirty ">The File field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s4.value.maxLength">Maximum length is {{ $v.selectedNode.props.s4.value.$params.maxLength.max }} characters!</p>
    <br/>
    <div class="form-group">
      <label class="">Remote folder</label>
      <input type="text" class="form-control" v-model="selectedNode.props.s2.value">
    </div>
    <br/>
    <div class="font-weight-bold">Authentication</div>
    <br/>
    <transition name="fadein">
      <div>
        <div class="form-group">
          <p-radio value="Password" color="primary" v-model="selectedNode.props.s8.value">Password</p-radio>
          <p-radio value="Pvt Key" color="primary" v-model="selectedNode.props.s8.value">Pvt Key</p-radio>
          <p-radio value="Pvt Key &amp; Password" color="primary" v-model="selectedNode.props.s8.value">Pvt Key &amp; Password</p-radio>
        </div>
        <br/>
        <div class="form-group">
          <label class="required">Username</label>
          <input
            type="text"
            class="form-control"
            :class="{invalid: $v.selectedNode.props.s5.value.$error}"
            @input="$v.selectedNode.props.s5.value.$touch()"
            v-model="selectedNode.props.s5.value">
          <p class="validationText" v-if="!$v.selectedNode.props.s5.value.required && $v.selectedNode.props.s5.value.$dirty ">The username field is required!</p>
          <p class="validationText" v-if="!$v.selectedNode.props.s5.value.maxLength">Maximum length is {{ $v.selectedNode.props.s5.value.$params.maxLength.max }} characters!</p>
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Password' || selectedNode.props.s8.value === 'Pvt Key & Password'">
          <label class="required">Password</label>
          <input type="password"
              class="form-control"
              :class="{invalid: $v.selectedNode.props.pw1.value.$error}"
              @input="$v.selectedNode.props.pw1.value.$touch()"
              v-model="selectedNode.props.pw1.value">
          <p class="validationText" v-if="!$v.selectedNode.props.pw1.value.required && $v.selectedNode.props.pw1.value.$dirty ">The password is required!</p>
          <p class="validationText" v-if="!$v.selectedNode.props.pw1.value.maxLength">Maximum length is {{ $v.selectedNode.props.pw1.value.$params.maxLength.max }} characters!</p>
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Pvt Key' || selectedNode.props.s8.value === 'Pvt Key & Password'">
          <label class="required">Pvt Key Path</label>
          <div class="input-group mb-3">
            <input type="text"
              class="form-control"
              :class="{invalid: $v.selectedNode.props.s6.value.$error}"
              @input="$v.selectedNode.props.s6.value.$touch()"
              v-model="selectedNode.props.s6.value">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" @click.prevent="btnPvtKeyFileBrowser" type="button">...</button>
            </div>
          </div>
          <p class="validationText" v-if="!$v.selectedNode.props.s6.value.required && $v.selectedNode.props.s6.value.$dirty ">The Pvt Key Path field is required!</p>
          <p class="validationText" v-if="!$v.selectedNode.props.s6.value.maxLength">Maximum length is {{ $v.selectedNode.props.s6.value.$params.maxLength.max }} characters!</p>
          <br/>
        </div>
        <div class="form-group" v-if="selectedNode.props.s8.value === 'Pvt Key' || selectedNode.props.s8.value === 'Pvt Key & Password'">
          <label class="required">Pvt Key PIN</label>
          <input type="password"
              class="form-control"
              :class="{invalid: $v.selectedNode.props.pw2.value.$error}"
              @input="$v.selectedNode.props.pw2.value.$touch()"
              v-model="selectedNode.props.pw2.value">
          <p class="validationText" v-if="!$v.selectedNode.props.pw2.value.required && $v.selectedNode.props.pw2.value.$dirty ">The Pvt Key PIN field is required because only encrypted Pvt keys are allowed!</p>
          <p class="validationText" v-if="!$v.selectedNode.props.pw2.value.maxLength">Maximum length is {{ $v.selectedNode.props.pw2.value.$params.maxLength.max }} characters!</p>
        </div>
      </div>
    </transition>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button variant="success" @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength, integer, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        s4: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        s5: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        s6: {
          value: {
            required: requiredIf(function () {
              let c1 = false
              if (this.selectedNode.props.s8.value === 'Pvt Key' || this.selectedNode.props.s8.value === 'Pvt Key & Password'){
                return true
              } 
              return false
            }),
            maxLength: maxLength(128)
          }
        },
        pw1: {
          value: {
            required: requiredIf(function () {
              let c1 = false
              if (this.selectedNode.props.s8.value === 'Password' || this.selectedNode.props.s8.value === 'Pvt Key & Password'){
                return true
              } 
              return false
            }),
            maxLength: maxLength(32)
          }
        },
        pw2: {
          value: {
            required: requiredIf(function () {
              let c1 = false
              if (this.selectedNode.props.s8.value === 'Pvt Key' || this.selectedNode.props.s8.value === 'Pvt Key & Password'){
                return true
              } 
              return false
            }),
            maxLength: maxLength(32)
          }
        },
        int1: {
          value: {
            required,
            integer,
            maxValue: maxValue(65535),
            minValue: minValue(1)
          }
        }
      }
    }
  },
  data () {
    return {
      showFolderBrowser: false,
      showFileBrowser: false,
      showPvtKeyFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s2.value = utils.sanitisePath(this.selectedNode.props.s2.value)
      this.selectedNode.props.s3.value = utils.sanitisePath(this.selectedNode.props.s3.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s4.value)
      this.showFileBrowser = true
    },
    btnPvtKeyFileBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s6.value)
      this.showPvtKeyFileBrowser = true
    },  
    btnFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showFolderBrowser = true
    },
    closeFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s4.value = filename
    },
    closePvtKeyFilePopup () {
      console.log('Closed closePvtKeyFilePopup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s6.value = this.$store.state.mapping.modalPopupString
    },
    closeFolderPopup () {
      console.log('closeFolderPopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s3.value = this.$store.state.mapping.modalPopupString
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity .6s;
  }
  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
</style>
