var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.reportDownload.downloadFormat === 1
      ? _c("div", { staticStyle: { width: "98%", margin: "0 auto" } }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.reportData) } })
        ])
      : _c(
          "div",
          { staticStyle: { width: "98%", margin: "0 auto" } },
          [
            _c("b-form-textarea", {
              attrs: { rows: 20 },
              model: {
                value: _vm.reportData,
                callback: function($$v) {
                  _vm.reportData = $$v
                },
                expression: "reportData"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }