var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "idb-block" },
    [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: {
                docPath: "/paygate-collections/messaging/messagetemplates/"
              }
            }),
            _vm._v("Email Designer")
          ],
          1
        )
      ]),
      !_vm.previewOpen
        ? _c(
            "div",
            { staticClass: "idb-block-content", attrs: { id: "emailEditor" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", { attrs: { for: "emailTemplateName" } }, [
                            _c("strong", [_vm._v("Template Name")])
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              id: "emailTemplateName",
                              disabled: _vm.loading,
                              type: "text",
                              autocomplete: "off",
                              state: _vm.templateNameStateValid,
                              "aria-describedby":
                                "emailTemplateName emailTemplateNameFeedback",
                              placeholder: "Email Template Name",
                              maxlength: "50"
                            },
                            model: {
                              value: _vm.templateName,
                              callback: function($$v) {
                                _vm.templateName = $$v
                              },
                              expression: "templateName"
                            }
                          }),
                          !_vm.templateNameStateValid
                            ? _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "emailTemplateNameFeedback" } },
                                [
                                  _vm._v(
                                    " The template name may only be up to 11 characters of the following types: "
                                  ),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v("Upper-case letters A-Z")
                                    ]),
                                    _c("li", [
                                      _vm._v("Lower-case letters a-z")
                                    ]),
                                    _c("li", [_vm._v("Numbers 0-9")]),
                                    _c("li", [
                                      _vm._v(
                                        'Spaces, underscores "_", or dashes "-"'
                                      )
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-form-text",
                            { attrs: { id: "smsTemplateNameHelp" } },
                            [_vm._v("The name of the template in paygate.")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("br"),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", { attrs: { for: "smsSenderName" } }, [
                            _vm._v("Email Title")
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              id: "smsSenderName",
                              type: "text",
                              autocomplete: "off",
                              state: _vm.emailTitleStateValid,
                              "aria-describedby":
                                "emailTitleHelp emailTitleFeedback",
                              placeholder: "Enter the email title",
                              maxlength: "80"
                            },
                            model: {
                              value: _vm.messageTitle,
                              callback: function($$v) {
                                _vm.messageTitle =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "messageTitle"
                            }
                          }),
                          !_vm.emailTitleStateValid
                            ? _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "emailTitleFeedback" } },
                                [
                                  _vm._v(" The email title must only have: "),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v("Upper-case letters A-Z")
                                    ]),
                                    _c("li", [
                                      _vm._v("Lower-case letters a-z")
                                    ]),
                                    _c("li", [_vm._v("Numbers 0-9")]),
                                    _c("li", [
                                      _vm._v(
                                        'Spaces, underscores "_" or dashes "-"'
                                      )
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-form-text",
                            { attrs: { id: "emailTitleHelp" } },
                            [_vm._v("The title seen by the email recipient.")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                [
                  _c("label", [_vm._v("Message Body")]),
                  _c("wysiwyg", {
                    attrs: { "upload-url": _vm.uploadUrl },
                    model: {
                      value: _vm.messageBody,
                      callback: function($$v) {
                        _vm.messageBody = $$v
                      },
                      expression: "messageBody"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "preview-modal",
            size: "lg",
            title: "Subject: " + _vm.preview.title
          }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.preview.body) } })]
      ),
      _c(
        "div",
        { staticClass: "idb-block-footer" },
        [
          _c(
            "b-btn",
            {
              attrs: { disabled: _vm.isLoading, variant: "primary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.saveContent($event)
                }
              }
            },
            [_vm._v("Save")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { to: { name: "EmailDesigner" } }
            },
            [_vm._v("New Template")]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              attrs: { variant: "info", disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.togglePreview($event)
                }
              }
            },
            [_vm._v("Preview")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }