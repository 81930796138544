var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c("h2", [
          _vm._v("About "),
          _c("span", { staticClass: "pull-right" }, [
            _c("a", { attrs: { href: _vm.docUrl + "/", target: "_blank" } }, [
              _c("i", { staticClass: "far fa-question-circle" })
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _vm._m(0),
        _vm.isSystemUser
          ? _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 text-right col-form-label",
                  attrs: { for: "description" }
                },
                [_vm._v("Commit Hash")]
              ),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("div", { staticClass: "form-control-plaintext" }, [
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v(_vm._s(_vm.about.commit))
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm.isSystemUser
          ? _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 text-right col-form-label",
                  attrs: { for: "description" }
                },
                [_vm._v("Build Date")]
              ),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("div", { staticClass: "form-control-plaintext" }, [
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDate")(
                          _vm.about.buildDate,
                          "DD/MM/YYYY HH:mm"
                        )
                      )
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Customer Code")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v(_vm._s(_vm.about.customerCode))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Customer Name")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v(_vm._s(_vm.about.customerName))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Paygate Id")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v(_vm._s(_vm.about.customerId))
              ])
            ])
          ])
        ]),
        _vm.isSystemUser
          ? _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 text-right col-form-label",
                  attrs: { for: "description" }
                },
                [_vm._v("Instance")]
              ),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("div", { staticClass: "form-control-plaintext" }, [
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v(_vm._s(_vm.about.instance))
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm.isSystemUser
          ? _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 text-right col-form-label",
                  attrs: { for: "description" }
                },
                [_vm._v("Server Time")]
              ),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("div", { staticClass: "form-control-plaintext" }, [
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDate")(_vm.about.time, "DD/MM/YYYY HH:mm")
                      )
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "label",
          {
            staticClass: "form-control-plaintext",
            attrs: { for: "description" }
          },
          [
            _vm._v(
              "©Copyright " +
                _vm._s(new Date().getFullYear()) +
                " Paygate Solutions Limited."
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Version")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("div", { staticClass: "form-control-plaintext" }, [
          _c("span", { staticStyle: { "margin-right": "5px" } }, [
            _vm._v("3.3.5")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }