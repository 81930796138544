<template>
  <div>
    <form novalidate v-on:submit.prevent="verifyCode">
      <b-modal
        @shown="focusMyElement"
        id="verifyModal"
        ref="verifyModal"
        variant="primary"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        centered
        static
        :title="title"
      >
        <div v-if="type == 'Email' || type == 'Phone'">
          <div class="row">
            <div class="col-md-12">
              <p v-html="body"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="help-block">Once you've received the code, please enter it here.</p>
              <div class="form-group" :class="{'invalid':incorrectCode}">
                <input
                  id="code"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  v-model="code"
                  ref="code"
                />
                <span v-if="incorrectCode">
                  <span class="form-text text-danger small">The Code is incorrect</span>
                </span>
                <small v-if="type =='Email'" class="form-text text-muted">
                  Or if you want a link to be sent to your email to confirm later, please
                  <a
                    @click="sendEmail"
                    class="alert-link"
                    href="#"
                  >Click Here</a>.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="type == 'Authenticator'">
          <div class="row">
            <div class="col-md-12">
              <p class="text-center">Please scan this QR code with any authentication app</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mx-auto">
              <img
                v-if="qrCode"
                v-bind:src="qrCode"
                style="min-width:150px;min-height:150px;max-height:250px"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="row text-center">
            <div class="col-md-12">
              <div class="form-group" :class="{'invalid':incorrectCode}">
                <p class="help-block">After scanning the QR code, enter the verification code below:</p>
                <input
                  id="code"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  v-model="code"
                  ref="code"
                />
                <span class="text-danger field-validation-error" v-if="incorrectCode">
                  <span class>The Code is incorrect</span>
                </span>
                <small class="form-text text-muted">
                  Your Two-Factor backup code is
                  <b>{{authenticatorCode}}</b>. This can be used for manual set up.
                </small>
              </div>
            </div>
          </div>
        </div>
        <template slot="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="hideModal"
            :disabled="isLoading"
          >Close</button>
          <button type="submit" class="btn btn-primary" :disabled="isLoading">Verify Code</button>
        </template>
      </b-modal>
    </form>
  </div>
</template>
<script>

import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  name: 'confimModal',
  props: {
    type: {
      type: String
    }
  },
  mixins: [loading],
  data () {
    return {
      code: null,
      incorrectCode: false,
      qrCode: null,
      emailAddress: '',
      phoneNumber: '',
      authenticatorCode: ''
    }
  },
  computed: {
    title () {
      switch (this.type) {
        case 'Email':
          return 'Confirm Email'
        case 'Phone':
          return 'Confirm Phone'
        case 'Authenticator':
          return 'Confirm Authenticator'
        default:
          return null
      }
    },
    body () {
      switch (this.type) {
        case 'Email':
          return `You have been sent a code via email to the address <b>${this.emailAddress}</b>`
        case 'Phone':
          return `You have been sent a code via SMS to the phone number <b>${this.phoneNumber}</b>`
        case 'Authenticator':
          return 'Confirm Authenticator'
        default:
          return null
      }
    }
  },
  methods: {
    async verifyCode (evt) {
      evt.preventDefault()
      var requestData = { Code: this.code, Provider: this.type }
      var response = await axios.post(process.env.VUE_APP_PLATFORM_API_URL + 'User/ConfirmConfirmCode', requestData, { showload: true, showerror: true, errormessage: 'Failed to verify 2FA code' })

      if (response.data) {
        this.$toastr.s(`${this.type} has been confirmed!`, 'Success')
        this.$emit('submit')
        this.hideModal()
      } else {
        this.incorrectCode = true
      }
    },
    hideModal () {
      this.$refs.verifyModal.hide()
    },
    async focusMyElement () {
      this.code = ''
      this.incorrectCode = false
      // This will fire everytime it's opened so use this to call the backend to send a verify token
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'User/GenerateConfirmCode', { showload: true, showerror: true, errormessage: 'Failed to send 2FA code', params: { provider: this.type } })

      switch (this.type) {
        case 'Email':
          this.emailAddress = response.data
          break
        case 'Phone':
          this.phoneNumber = response.data
          break
        case 'Authenticator':
          var codeResponse = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'User/GetQrCode', { showload: true })
          this.qrCode = `data:image/bmp;base64,${codeResponse.data.image}`
          this.authenticatorCode = response.data
          break
      }

      this.$refs.code.focus()
    },
    async sendEmail () {
      await axios.post(process.env.VUE_APP_PLATFORM_API_URL + 'User/SendConfirmEmail')
      this.hideModal()
    }
  },
  validations () {
    return {
      code: { required }
    }
  }
}
</script>
