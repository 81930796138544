<template>
  <b-modal
    id="rename-bureau-job"
    ref="renameBureauJob"
    title="Rename Bureau Job"
    hide-header-close
    static
    @shown="load"
    @ok="RenameBureauJob"
    :centered="true"
    footer-class="rename-job-footer"
  >
    <div slot="modal-ok">Rename</div>
    <div class>
      <label class="col-form-label col-md-6 required">Bureau Job Name</label>
      <div class="col-md-12">
        <b-form-input
          class="form-control"
          type="text"
          v-model.trim="$v.renameName.$model"
          :formatter="trimInput"
          @input="checkName"

        ></b-form-input>
        <!-- Validation -->
        <validation-messages v-model="$v.renameName" name="renameName">
          <small
            class="form-text small"
            v-if="
                $v.renameName.notSameAs != undefined &&
                !$v.renameName.notSameAs
              "
          >The new name name cannot be the same as the original name</small>
        </validation-messages>
        <small
          class="form-text small text-danger"
          v-if="nameInUse"
        >Bureau Job Name already exists</small>
      </div>
    </div>
    <template  #modal-footer="{ ok, cancel, hide }">
      <b-button :disabled="nameInUse" variant="primary" @click="ok()">Rename</b-button>
      <b-button @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Utility from '@/Assets/Mixins/Utility'
import { maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import VueSelect from 'vue-select'
import _ from 'lodash'

export default {
  props: ['bureauJobId','currentName'],
  data () {
    return {
      renameName: '',
      nameInUse: false
    }
  },

  async created () {
  },

  methods: {
    load () {
      // Loader

    },
    // trims the input string to a maximum character length
    trimInput (e) {
      if (e.length > 50) {
        return e.substring(0, 50)
      }
      return e
    },
    async RenameBureauJob () {
      var data = {
        bureauJobId: this.bureauJobId,
        cloning: false,
        name: this.renameName
      }
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/SaveBureauJob', data, { showload: true })

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)
        if (!data.cloning) {
          this.$refs.renameBureauJob.hide()
          this.$emit('jobRenamed', this.renameName)
          //this.$router.push({ name: 'ManageJob', params: { id: response.data.id } })
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    },
    checkName: _.debounce(async function () {
      if (this.renameName !== this.currentName) {
        this.nameInUse = false
        let url = `${process.env.VUE_APP_BUREAU_API_URL}bureauJob/jobnameexists`
        const response = await axios.get(url, {
          showload: true,
          params: {paygateId: this.paygateId, jobName: this.renameName}
        })
        this.nameInUse = response.data
      }
    }, 300),

  },
  validations: {
    renameName: { maxLength: maxLength(255) }
  }

}

</script>
<style>
.rename-job-footer {
  justify-content: flex-start !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
