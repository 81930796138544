<template>
<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <label>{{label}}</label>
      <select class="form-control"
              v-model=value
              @input="$emit('input', $event.target.value)">
        <option v-for="option in options" :key="option.value" v-bind:value="option.value">
          {{option.text}}
        </option>
      </select>
    </div>
  </div>
  <div class="spacer150"></div>
</div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'GroupDetails',
  props: [
    'multi',
    'name',
    'label',
    'value'
  ],
  data () {
    return {
      options: [],
      groupOptions: []
    }
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  created: async function () {
    const res = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups?paygateid=${this.corvidSelectedPaygateId}&sort=name:asc,&perPage=999&page=1`)
    try {
      if (res && res.data && res.data.data) {
        for (const group of res.data.data) {
          this.options.push({
            text: group.name,
            value: group.groupId
          })
        }
        this.options.sort(this.sortGroups)
      } else {
        this.$snapbar.e('Error getting list of available groups. (002)')
      }
    } catch {
      this.$snapbar.e('Error getting list of available groups. (001)')
    }
  },
  methods: {
    sortGroups (a, b) {
      const nameA = a.text.toUpperCase()
      const nameB = b.text.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    }
  }
}
</script>
