var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "submissionsInProgress" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v("Submissions In Progress "),
                _c("span", { staticClass: "pull-right" }, [
                  _c("a", { attrs: { href: _vm.helpUrl, target: "_blank" } }, [
                    _c("i", { staticClass: "far fa-question-circle" })
                  ])
                ]),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: (_vm.submissionsInProgressLoaded = true),
                    expression: "submissionsInProgressLoaded=true"
                  }
                ],
                staticClass: "col-md-12"
              },
              [
                _c(
                  "vue-good-table",
                  {
                    ref: "submissionsInProgress",
                    attrs: {
                      columns: _vm.columns,
                      rows: _vm.submissionsInProgress,
                      "pagination-options": {
                        enabled: true,
                        perPage: 10,
                        dropdownAllowAll: false
                      },
                      styleClass: "vgt-table striped bordered"
                    },
                    on: { "on-cell-click": _vm.onCellClick },
                    scopedSlots: _vm._u([
                      {
                        key: "table-row",
                        fn: function(props) {
                          return [
                            props.column.field === "deleteButton"
                              ? _c("span", [
                                  _c(
                                    "button",
                                    { staticClass: "btn btn-danger" },
                                    [_vm._v("Delete")]
                                  )
                                ])
                              : _c("span", [
                                  _c(
                                    "span",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ]
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "emptystate" }, slot: "emptystate" },
                      [_vm._v("There are no submissions in progress.")]
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-footer" })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }