<template>
  <div id="viewSubmission">
    <div v-show="action.id !== undefined">
      <div v-show="action.id !== '00000000-0000-0000-0000-000000000000'" id="actionDetails">
        <div class="idb-block-title">
          <h2>
            {{ this.action.title }}
            <help-icon :docPath="helpUrl"></help-icon>
          </h2>
        </div>
      </div>
      <div class="idb-block-content">
        <b-col sm="8">
          <div v-if="action.id !== '00000000-0000-0000-0000-000000000000'">
            <div class="row form-group">
              <div class="col-md-3">
                <strong>Message</strong>
              </div>
              <div class="col-md-9">{{ this.action.message }}</div>
            </div>
          </div>
          <div v-if="validationWarnings" class="row form-group">
            <div class="col-md-3">
              <strong>Validation Messages</strong>
            </div>
            <div class="col-md-9">
              <span
                class="badge badge-warning badge-pill"
              >Submission contains {{ this.totalPresubValWarnings }} {{ this.validationTotalText }}</span>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-3">
              <strong>Submission Reference</strong>
            </div>
            <div class="col-md-9">{{ this.bacsSubmission.reference }}</div>
          </div>
          <div class="row form-group">
            <div class="col-md-3">
              <strong>Group Name</strong>
            </div>
            <div class="col-md-9">{{ this.groupName }}</div>
          </div>
          <div class="row form-group">
            <div class="col-md-3">
              <strong>Submission File</strong>
            </div>
            <div class="col-md-9">{{ this.importedFilename }}</div>
          </div>
          <div class="row form-group" v-if="BureauSummaryData.bureauSun !== ''">
            <div class="col-md-3">
              <strong>Bureau Sun</strong>
            </div>
            <div class="col-md-9">{{ BureauSummaryData.bureauSun }}</div>
          </div>
          <div class="row form-group" v-if="isMultiFile">
            <div class="col-md-3">
              <strong>File SUN</strong>
            </div>
            <div class="col-md-9">{{ selectedFileSun }}</div>
          </div>
          <div class="row form-group" v-if="bacsSubmission.paygateType === 'Bureau'">
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-outline-secondary"
                v-on:click="goToBureauReport"
              >View Bureau Submission Report
              </button>
            </div>
          </div>
          <div class="row form-group" v-if="bacsSubmission.paygateType === 'Bureau'">
            <div class="col-md-3">
              <strong>Job Name</strong>
            </div>
            <div class="col-md-9">{{ BureauSummaryData.jobName !== null ? BureauSummaryData.jobName : 'Bureau Builder' }}</div>
          </div>
          <div v-if="this.isMultiFile === true" class="row form-group">
            <div class="col-md-3">
              <b>Select Block</b>
            </div>
            <div class="col-md-3">
              <b-dropdown
                :text="selectedFile"
                class="tall-dropdown"
                split
                variant="outline-secondary"
              >
                <b-dropdown-item
                  v-for="summary in intelligibleSummaries"
                  :key="summary.fileNumber"
                  :value="summary.fileNumber"
                  @click="selectSummary(summary)"
                >{{ summary.vocalinkSubId }}</b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="col-md-2">
              <b>Processing Date</b>
            </div>
            <div class="col-md-3">
              <vuejsDatepicker
                ref="processingDateDP"
                name="processingDateDP"
                id="processingDateDP"
                v-model="processingDate"
                format="dd/MM/yyyy"
                input-class="form-control"
                :bootstrap-styling="true"
                :disabledDates="disabledDates"
                v-on:selected="processingDateChanged"
                @focusin.native="dateOnfocus"
                :monday-first="true"
                :style="{backgroundColor: 'white'}"
              ></vuejsDatepicker>
            </div>
          </div>
        </b-col>

        <b-tabs v-if="isLoaded" v-on:input="tabSelected" lazy v-model="tabIndex">
          <b-tab v-if="bacsSubmission.paygateType === 'Bureau'" title="Bureau Summary">
            <bureauSummary></bureauSummary>
          </b-tab>

          <b-tab title="Summary" active>
            <intelligibleSummary></intelligibleSummary>
          </b-tab>
          <b-tab title="Payments">
            <payments></payments>
          </b-tab>
          <b-tab title="Standard18">
            <standard18></standard18>
          </b-tab>
          <b-tab title="Submission History">
            <submissionHistory></submissionHistory>
          </b-tab>
          <b-tab v-if="numberOfWarnings > 0">
            <template slot="title">
              Validation Messages
              <span
                class="badge badge-warning badge-pill"
              >{{ this.numberOfWarnings }}</span>
            </template>
            <validationWarnings></validationWarnings>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import IntelligibleSummary from '@/Views/Bacs/Common/IntelligibleSummary.vue'
import Payments from '@/Views/Bacs/Common/Payments.vue'
import Standard18 from '@/Views/Bacs/Common/Standard18.vue'
import SubmissionHistory from '@/Views/Bacs/Common/SubmissionHistory.vue'
import ValidationWarnings from '@/Views/Bacs/Common/ValidationWarnings.vue'
import BureauSummary from '@/Views/Bacs/Common/BureauSummary.vue'
import bacsMixin from '@/Lib/BacsMixin.js'
import Utility from '@/Assets/Mixins/Utility'
import roles from '@/Assets/Constants/roles'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
    intelligibleSummary: IntelligibleSummary,
    payments: Payments,
    standard18: Standard18,
    submissionHistory: SubmissionHistory,
    validationWarnings: ValidationWarnings,
    bureauSummary: BureauSummary
  },
  mixins: [
    bacsMixin, Utility
  ],
  computed: {
    validationTotalText: function () {
      if (this.totalPresubValWarnings === 1) {
        return 'validation message'
      } else {
        return 'validation messages'
      }
    },
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      processingDate: '',
      processingDates: {},
      paymentDate: '',
      disabledDates: {},
      submissionData: {},
      submissionId: '',
      selectedFileNumber: 1,
      serviceUserNumber: '',
      isMultiFile: false,
      intelligibleSummaries: {},
      contraNarratives: {},
      tabIndex: 0,
      validationWarnings: false,
      numberOfWarnings: 0,
      totalPresubValWarnings: 0,
      bacsSubmission: {},
      isLoaded: false,
      viewSubmissionData: {},
      action: {},
      groupName: '',
      tokenType: '',
      messageCountPerFile: {},
      valMessageClasses: 'badge badge-warning badge-pill',
      helpUrl: '',
      importedFilename: '',
      selectedFileSun: '',
      currentFiles: [],
      selectedFile: '', // Only relevant for multi-block PGO Standard18 files.
      BureauSummaryData: { // data required for the Bureau Summaries tab
        totalDebitCount: 0,
        totalDebitValue: 0,
        totalCreditCount: 0,
        totalCreditValue: 0,
        totalDDICount: 0,
        jobName: '',
        bureauSun: ''
      }
    }
  },

  async mounted () {
    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        // this.paymentDate = new Date(this.processingDates.minPaymentDate)
        // this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate
        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          var npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = npds
        }
      }
      )
  },

  methods: {
    async onDownloadFullPaymentReportClick () {
      try {
        var url = process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/getfullpaymentreport?id=' + this.submissionId
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob',
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log('Error from get presub report', JSON.stringify(ex))
      }
    },

    async onPreSubmissionReportClick () {
      try {
        var url = process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/getpresubmissionreport?id=' + this.submissionId
        var response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob',
          showload: true
        })
        this.saveFile(response)
      } catch (ex) {
        console.log('Error from get presub report', JSON.stringify(ex))
      }
    },

    tabSelected (tabIndex) {
    },

    selectSummary (summary) {
      this.serviceUserNumber = summary.serviceUserNumber
      this.selectedFile = summary.vocalinkSubId
      this.selectedFileNumber = summary.fileNumber
      this.$store.dispatch('setFileNumber', summary.fileNumber)
      this.viewSubmissionData = this.$store.getters.viewSubmissionData
      this.viewSubmissionData.paymentsLoaded = false
      this.viewSubmissionData.presubValMessagesLoaded = false
      this.numberOfWarnings = this.messageCountPerFile[summary.fileNumber - 1]
      this.processingDate = new Date(summary.processingDate)

      for (var i = 0; i < this.currentFiles.length; i++) {
        if (summary.fileNumber === this.currentFiles[i].fileNumber) {
          // tweak the file name to just show the file name, preventing any path stuff from being included
          let fileName = this.currentFiles[i].filename
          let pathArr = fileName.split('\\')
          fileName = pathArr[pathArr.length-1]
          pathArr = fileName.split('/')
          fileName = pathArr[pathArr.length-1]
          this.importedFilename = fileName

          this.selectedFileSun = this.currentFiles[i].serviceUserNumber
        }
      }
      this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
      if (this.tabIndex > 0) {
        this.tabIndex = 0
      } else {
        this.tabIndex = 1
        setTimeout(() => {
          this.changeTab()
        }, 100)
      }
    },

    changeTab () {
      this.tabIndex = 0
    },

    async getContraNarratives () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getContraNarratives?submissionId=` + this.submissionId, { showload: true })
      this.contraNarratives = response.data
      this.$store.dispatch('setContraNarratives', this.contraNarratives)
    },

    async getIntelligibleSummaries () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getIntelligibleSummaries?submissionId=` + this.submissionId, { showload: true })
      this.intelligibleSummaries = response.data
      if (this.intelligibleSummaries.length > 0) {
        this.$store.dispatch('setIntelligibleSummaries', this.intelligibleSummaries)
        this.isMultiFile = this.intelligibleSummaries.length > 1
        this.serviceUserNumber = this.intelligibleSummaries[0].serviceUserNumber
        this.selectedFile = this.intelligibleSummaries[0].vocalinkSubId
        this.processingDate = new Date(this.intelligibleSummaries[0].processingDate)
        this.viewSubmissionData.summariesLoaded = true
        this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
      }
    },

    async getBacsSubmission () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getBacsSubmission?submissionId=` + this.submissionId)
      this.bacsSubmission = response.data
      this.$store.dispatch('setBacsSubmission', this.bacsSubmission)
      this.$store.dispatch('getSubBacsGroup', this.bacsSubmission.groupId)
    },

    async getPresubValidationResult () {
      var presubValResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getPresubValidationResult?submissionId=` + this.submissionId)
      this.validationWarnings = presubValResponse.data.validationResult === 'Warnings' || presubValResponse.data.validationResult === 'Info'
      if (presubValResponse.data.validationResult === 'Info') {
        this.valMessageClasses = 'badge badge-info badge-pill'
      }
      this.messageCountPerFile = presubValResponse.data.presubValMessagesForFile
      this.numberOfWarnings = this.messageCountPerFile[0]
      for (var i = 0; i < this.messageCountPerFile.length; i++) {
        this.totalPresubValWarnings += this.messageCountPerFile[i]
      }
    },

    async getAction () {
      var actionId = this.$store.getters.actionId
      if (actionId !== undefined) {
        if (actionId !== '00000000-0000-0000-0000-000000000000') {
          var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + actionId
          var response = await axios.get(url, { action: true })
          this.action = response.data
        } else {
          this.action.id = '00000000-0000-0000-0000-000000000000'
        }
      }
    },

    async getBacsEngineConfig () {
      await this.$store.dispatch('getBacsEngineConfig', this.bacsSubmission.paymentNetworkType)
    },

    async getGroupDetails () {
      try {
        var url = process.env.VUE_APP_PLATFORM_API_URL + 'groups/' + this.bacsSubmission.groupId
        var response = await axios.get(url)
        const groupDetails = response.data
        this.groupName = groupDetails.name
        this.tokenType = groupDetails.groupTypeDetails.tokenType
      } catch (err) {
        console.log(err)
        if (this.groupName === '' || this.groupName === undefined) {
          this.groupName = this.$store.getters.selectedRowGroupName
        }
      }
    },

    async processingDateChanged (date) {
      var newProcessingDate = moment(date).format('DD/MM/YYYY')
      var swalhtml = `Are you sure you want to change the processing date to <strong> ${newProcessingDate} </strong> for this file? <br/> <br/> This will update the dates of any payments which are before this selected processing date. Please note that <strong>this is not reversible.</strong>.`
      const swalResult = await swal.fire({
        title: 'Change Processing Date',
        html: swalhtml,
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: this.submissionId,
            fileNumber: this.selectedFileNumber,
            newProcessingDate: moment(date).format('YYYY-MM-DD')
          }
          // const response = await axios.post(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/updateProcessingDate', data, { showload: true })
          const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/ChangeSubmissionDateAfterPresubval', data, { showload: true })

          if (!response.Success === false) {
            this.$toastr.s('The processing date has been updated', 'Bureau Submission')
          } else {
            this.$toastr.e('Could not update the processing date', 'Bureau Submission')
          }
          await this.getIntelligibleSummaries()
        } catch (error) {
          console.error(error)
        }
      }
    },

    async getBureauSummaries () {
      try {
        var BureauSummaryResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissionsummaryinfoafterpresubval/' + this.submissionId, { showload: true })
        this.BureauSummaryData.totalDebitCount = BureauSummaryResponse.data.debitCount
        this.BureauSummaryData.totalDebitValue = BureauSummaryResponse.data.debitValue
        this.BureauSummaryData.totalCreditCount = BureauSummaryResponse.data.creditCount
        this.BureauSummaryData.totalCreditValue = BureauSummaryResponse.data.creditValue
        this.BureauSummaryData.totalDDICount = BureauSummaryResponse.data.ddiCount
        this.BureauSummaryData.totalFiles = BureauSummaryResponse.data.totalFiles
        this.BureauSummaryData.contraCount = BureauSummaryResponse.data.contraCount
        this.BureauSummaryData.jobName = BureauSummaryResponse.data.jobName
        this.BureauSummaryData.bureauSun = BureauSummaryResponse.data.bureauSun
        this.$store.dispatch('setBureauSummaryData', this.BureauSummaryData)
        // this.processingDate = BureauSummaryResponse.data.processingDate
      } catch (err) {
        console.log(err)
      }
    },

    async getCustomer () {
      if (!this.$store.getters.isInRole(roles.SystemUser)) {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/allowedlivesubmissions`)
        if (!response.data) {
          this.$snapbar.e('Your customer is set in \'Test Mode\' so is not allowed to send live submissions at this time.')
        }
      }
    },

    async getFileDetails () {
      if (this.submissionId !== undefined) {
        var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/GetFileDetails?submissionId=${this.submissionId}&showImportedFilename=true`)
        var fileDetails = response.data
        this.currentFiles = fileDetails
        if (fileDetails !== null && fileDetails.length > 0) {
          this.importedFilename = fileDetails[0].filename
          this.selectedFileSun = fileDetails[0].serviceUserNumber
        }
      }
    },
    goToBureauReport () {
      const routeData = this.$router.resolve({ name: 'BureauSubmissionReport', params: { id: this.submissionId } })
      window.open(routeData.href, '_blank')
    }
  },

  async created () {
    this.disabledDates = await this.setUpBACSDatePicker()
    this.$store.dispatch('setParentDataLoaded', false)
    this.submissionId = this.$store.getters.submissionId
    this.$store.dispatch('setSubmissionId', this.submissionId)
    this.$store.dispatch('setFileNumber', this.selectedFileNumber)

    this.viewSubmissionData = {
      summariesLoaded: false,
      paymentsLoaded: false,
      standard18Loaded: false,
      moreStnd18: false,
      fileNumber: 0,
      fromRecordNumber: 0,
      toRecordNumber: 0,
      presubValMessagesLoaded: false,
      historyLoaded: false
    }

    this.$store.dispatch('setViewSubmissionData', this.viewSubmissionData)
    this.$store.dispatch('setStandard18', '')

    await this.getCustomer()
    await this.getContraNarratives()
    await this.getIntelligibleSummaries()
    await this.getBacsSubmission()
    await this.getFileDetails()
    await this.getPresubValidationResult()
    await this.getBacsEngineConfig()
    await this.getAction()
    await this.getGroupDetails()
    if (this.bacsSubmission.paygateType === 'Bureau') {
      await this.getBureauSummaries()
    }
    this.isLoaded = true
    this.$store.dispatch('setParentDataLoaded', true)

    if (this.action.actionSubClass === 'Sign' || this.action.actionSubClass === 'Commit') {
      if (this.tokenType === 'Hsm') {
        this.helpUrl = '/payments/actions/signsubmissionwithhsm/'
      } else if (this.tokenType === 'SmartCard') {
        this.helpUrl = '/payments/actions/signsubmissionwithsmartcard/'
      } else if (this.tokenType === 'SecurityCode') {
        this.helpUrl = '/payments/actions/commitsms/'
      } else if (this.tokenType === 'UsbToken') {
        this.helpUrl = '/payments/actions/commitusbtoken/'
      } else if (this.tokenType === 'None') {
        this.helpUrl = '/payments/actions/commitwithoutauthentication/'
      }
    } else if (this.action.actionSubClass === 'Approve') {
      this.helpUrl = '/payments/actions/approvesubmission/'
    } else if (this.action.actionSubClass === 'Send') {
      this.helpUrl = '/payments/actions/sendsubmission/'
    }
  }
}
</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}

.tall-dropdown .dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}
</style>
