<template>
  <b-modal v-model="value" @hide="hide" @shown="setFocus" centered size="lg" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">
      Service Window Details for the
      <strong>{{ networkType }} {{ windowType }}</strong> on a
      <strong>{{ dayOfWeek }}</strong>
    </div>

    <div class="row form-group">
      <div class="col-md-4">Start Time</div>
      <div class="col-md-8" :class="{invalid: $v.startTime.$error}">
        <input v-model.trim="$v.startTime.$model" class="form-control" style="width: 100px;" />
        <validation-messages v-model="$v.startTime" name="Start Time">
          <small
            class="form-text small"
            v-if="$v.startTime.timeValidator != undefined && !$v.startTime.timeValidator"
          >Incorrect time format. The format for the time must be hh:mm.</small>
        </validation-messages>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4">End Time</div>
      <div class="col-md-8" :class="{invalid: $v.endTime.$error}">
        <input v-model.trim="$v.endTime.$model" class="form-control" style="width: 100px;" />
        <validation-messages v-model="$v.endTime" name="End Time" :an="true">
          <small
            class="form-text small"
            v-if="$v.endTime.timeValidator != undefined && !$v.endTime.timeValidator"
          >Incorrect time format. The format for the time must be hh:mm</small>
        </validation-messages>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><label for="">Exclude Public Holidays</label></div>
      <div class="col-md-2">
        <p-check :labelId="'excludePublicHolidays'" class="p-switch p-fill" color="primary" v-model="excludePublicHolidays"></p-check>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><label for="is247">Is 24/7</label></div>
      <div class="col-md-2">
        <p-check :labelId="'is247'" class="p-switch p-fill" color="primary" v-model="isTwentyFourSeven"></p-check>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4"><label for="isProcesingDay">Is Processing Day</label></div>
      <div class="col-md-2">
        <p-check :labelId="'isProcesingDay'" class="p-switch p-fill" color="primary" v-model="isProcessingDay"></p-check>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="3">
          <b-btn
            size="sm"
            class="float-left"
            variant="primary"
            @click="saveServiceWindow"
            :disabled="disableButtons"
          >Save Service Window</b-btn>
        </b-col>
        <b-col cols="3">
          <b-btn
            size="sm"
            class="float-left"
            variant="light"
            @click="cancel"
            :disabled="disableButtons"
          >Cancel</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { requiredIf, helpers } from 'vuelidate/lib/validators'
const timeValidator = helpers.regex('alpha', /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/)

export default {
  name: 'editServiceWindow',
  props: {
    value: Boolean,
    serviceWindowDay: {},
    daysOfWeek: {},
    networkTypes: {},
    serviceWindowTypes: {}
  },

  data () {
    return {
      disableButtons: false,
      networkType: '',
      windowType: '',
      dayOfWeek: '',
      startTime: '',
      endTime: '',
      excludePublicHolidays: false,
      isTwentyFourSeven: false,
      isProcessingDay: false
    }
  },

  methods: {
    hide () {
      this.$emit('input', false)
    },

    cancel () {
      this.$emit('close')
    },

    setFocus () {
      this.networkType = this.networkTypes[this.serviceWindowDay.paymentNetworkType]
      this.windowType = this.serviceWindowTypes[this.serviceWindowDay.serviceWindowType]
      this.dayOfWeek = this.daysOfWeek[this.serviceWindowDay.day]
      this.startTime = this.serviceWindowDay.startTime
      this.endTime = this.serviceWindowDay.endTime
      this.excludePublicHolidays = this.serviceWindowDay.excludePublicHolidays
      this.isTwentyFourSeven = this.serviceWindowDay.isTwentyFourSeven
      this.isProcessingDay = this.serviceWindowDay.isProcessingDay
      this.disableButtons = false
    },

    async saveServiceWindow () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }

      this.disableButtons = true

      var json = JSON.stringify({
        id: this.serviceWindowDay.id,
        paymentNetworkType: this.serviceWindowDay.paymentNetworkType,
        serviceWindowType: this.serviceWindowDay.serviceWindowType,
        day: this.serviceWindowDay.day,
        startTime: this.startTime,
        endTime: this.endTime,
        excludePublicHolidays: this.excludePublicHolidays,
        isTwentyFourSeven: this.isTwentyFourSeven,
        isProcessingDay: this.isProcessingDay
      })

      var response = await axios({
        method: 'PUT',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/admin/updateBacsServiceWindow',
        data: json
      })

      if (response.data.status === 'Updated') {
        this.$toastr.s(response.data.toastMessage)
        this.$emit('savedServiceWindow')
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    }
  },

  validations () {
    return {
      startTime: {
        required: requiredIf(function () {
          return this.isProcessingDay === true && this.isTwentyFourSeven === false
        }),
        timeValidator
      },
      endTime: {
        required: requiredIf(function () {
          return this.isProcessingDay === true && this.isTwentyFourSeven === false
        }),
        timeValidator
      }
    }
  }
}
</script>
