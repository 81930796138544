<template>
    <div style="height:90%">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0 w-100">Collections: <router-link to="collections/alerts">{{alerts.length}} alerts require attention</router-link>s<i @click="populate" role="button" class="fas fa-sync-alt pull-right"></i></h4>
    </div>    
    <vue-perfect-scrollbar class="scroll-area" v-if="!loading" :settings="{maxScrollbarLength: 60}">
      <table  class="table table-striped">
        <thead>
          <tr>
            <th><a class="d-flex" @click="sort('alertLevel', levelSort)">Level<i class="ml-10" :class="levelSort === 'asc' ? 'fa fa-angle-down' : 'fa fa-angle-up'"></i></a></th>
            <th><a class="d-flex" @click="sort('title', titleSort)">Title<i class="ml-10" :class="titleSort === 'asc' ? 'fa fa-angle-down' : 'fa fa-angle-up'"></i></a></th>
            <th><a class="d-flex" @click="sort('generationDate', dateSort)">Date<i class="ml-10" :class="dateSort === 'asc' ? 'fa fa-angle-down' : 'fa fa-angle-up'"></i></a></th>
          </tr>
        </thead>
        <tbody>
          <tr valign="top" class="alertsRow" v-for="(alert, index) in alerts" :key="index" @click="alertClicked(alert)">
            <td><b-badge :variant="getSeverityClass(alert.alertLevel)">{{alert.alertLevel}}</b-badge></td>
            <td>{{alert.title}}</td>
            <td>{{formatDate(alert.generationDate)}}</td>
          </tr>
        </tbody>
      </table>
    </vue-perfect-scrollbar>
    <div v-if="loading">Loading...</div>
  </div>
</template>
<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { setTimeout, clearInterval } from 'timers'
import Loading from '@/Assets/Components/Loading/Loading'

export default {
  name: 'CurrentAlertsWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Loading
  },
  data: function () {
    return {
      originalOrder: [],
      alerts: [],
      sortField: 'generationDate',
      sortDirection: 'desc',
      loading: true,
      intervalId: null
    }
  },
  mounted () {
    this.populate()
    this.sortField = 'generationDate'
    this.sortDirection = 'asc'
    // this.intervalId = setInterval(this.populate, 30000) // 30s
    // this.$once('hook:beforeDestroy', () => {
    //   window.clearInterval(this.intervalId)
    // })
  },
  beforeRouteLeave(to, from, next) {
    // window.clearInterval(this.intervalId)
    next();
  },
  methods: {
    alertClicked (alert) {
      let historyUrl = `/collections/payer/${alert.ukPayerId}/history/${alert.payerHistoryId}`
      this.$router.push(historyUrl)
    },
    getSeverityClass (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'danger'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatDate (date) {
      var newDate = new Date(Date.parse(date))
      return newDate.toLocaleDateString()
    },
    truncate (input) {
      var output = input
      if (input.length > 100) {
        output = input.substring(0, 90) + '...'
      }
      return output
    },
    populate () {
      this.loading = true
      axios.get(`${process.env.VUE_APP_DDMS_API_URL}widget/alerts/${this.highLevelOnly ? 'high' : 'all'}`, { params: { paygateid: this.$store.state.common.paygateId } })
        .then((response) => {
          this.alerts = response.data
          this.loading = false
          this.sort(this.sortField, this.sortDirection)
        })
        .catch((response) => {
          console.log('error' + response)
          // window.clearInterval(this.intervalId)
        })
    },
    sort (field, order) {
      this.sortField = field
      this.sortDirection = order
      this.alerts = this.alerts.sort((a, b) => a[field].localeCompare(b[field]))
      if (order === 'desc') {
        this.alerts.reverse()
      }
    }
  },
  computed: {
    highLevelOnly () {
      return this.widget.options.find(o => o.label === 'HighLevelOnly').value
    },
    levelSort () {
      return this.sortField === 'alertLevel' && this.sortDirection === 'desc' ? 'asc' : 'desc'
    },
    titleSort () {
      return this.sortField === 'title' && this.sortDirection === 'desc' ? 'asc' : 'desc'
    },
    dateSort () {
      return this.sortField === 'generationDate' && this.sortDirection === 'desc' ? 'asc' : 'desc'
    }
  },
  watch: {
    highLevelOnly () {
      this.populate()
    }
  }
}
</script>

<style lang="scss" scoped>
  .textRow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10px;
  }

  .scroll-area {
    margin-top: 20px;
    height: calc(100% - 25px);
    min-height: unset;
    border-top: solid 1px #eeeeee;
  }

  .alertsRow {
    border-bottom: solid 1px #dddddd;
  }

  .alertsRow:hover  {
    cursor: pointer
  }

  .alertsRow:hover td {
    background-color: #f1f5fd;
  }
</style>
