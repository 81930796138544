<template>
  <div id="manageSchedule">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <form @submit.prevent="checkValidation() && exportData()">
            <div class="idb-block-title">
              <h2><help-icon docPath="/paygate-collections/import-export/exportdata/" />Export Data<favourite-icon></favourite-icon></h2>
            </div>
            <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2 required">
                Select a Group
              </div>
              <div class="col-md-4">
                <group-select
                  v-model="$v.group.$model"
                  :groups="customerGroups"
                  :clearable="false"
                ></group-select>
                <validation-messages name="Group" v-model="$v.group" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2 required">
                Exported file format
              </div>
              <div class="col-md-4">
              <b-form-select :options="fileFormats" v-model="$v.fileFormat.$model" />
              <validation-messages name="File Format" v-model="$v.fileFormat" />
              </div>
            </div>            
              <!-- <b-form-group :label-cols="3" horizontal label="Type of data to be exported" :class="{invalid: $v.dataType.$error}">
                <b-form-select  class="w-25" :options="dataTypes" v-model="$v.dataType.$model" />
                <validation-messages name="Data Type" v-model="$v.dataType" />
              </b-form-group>-->
            </div>
            <div class="idb-block-footer">
              <b-button type="submit" variant="primary" :disabled="group === null || fileFormat === null">Export</b-button>
            </div>
          </form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import ValidationMessages from '@/Assets/Components/Validation/ValidationMessages.vue'
import FileSaver from 'file-saver'
import EventBus from '@/Lib/eventBus'
export default {
  data () {
    return {
      group: null,
      fileFormat: null,
      dataType: 'Payers',
      fileFormats: ['csv', 'xml', 'json'],
      dataTypes: ['Payers']
    }
  },
  components: { ValidationMessages },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = this.$store.getters.customerGroups
      }
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  mounted () {
    this.loadGroups(this.paygateId)

    const onPaygateChange = (paygateId) => {
      this.loadGroups(paygateId)
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
      if (this.selectedGroup !== undefined) {
        this.group = defaultGroupId
      }
    }
  },
  methods: {
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      // const groups = this.$store.getters.customerGroups
      // const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      // const groupsNull = (groups === undefined || groups === null)
      // if (paygateIdNotDefault && groupsNull) {
      //   this.$store.dispatch('loadCustomerGroups', paygateId)
      // }
    },
    exportData () {
      axios.get(`${process.env.VUE_APP_DDMS_API_URL}export/${this.paygateId}/${this.group}/${this.dataType}.${this.fileFormat}`).then((response) => {
        let blob = new Blob([response.data.$values ? JSON.stringify(response.data.$values) : response.data], { type: response.headers.contentType })
        let url = window.URL.createObjectURL(blob)
        FileSaver.saveAs(blob, `${this.dataType}-${new Date().toISOString()}.${this.fileFormat}`)
        window.URL.revokeObjectURL(url)
      })
    }
  },
  validations: {
    group: { required },
    fileFormat: { required }
    // dataType: { required }
  }
}
</script>
