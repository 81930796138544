var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.difference.addedUsers, function(user) {
        return _c("div", { key: user.userName }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label ins",
                staticStyle: { "word-break": "break-all" },
                attrs: { for: "description" }
              },
              [_vm._v(_vm._s(user.userName))]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c(
                  "span",
                  {
                    staticClass: "ins",
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(user.keys))]
                )
              ])
            ])
          ])
        ])
      }),
      _vm._l(_vm.difference.sameUsers, function(user) {
        return _c("div", { key: user.userName }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                staticStyle: { "word-break": "break-all" },
                attrs: { for: "description" }
              },
              [_vm._v(_vm._s(user.userName))]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c(
                "div",
                { staticClass: "form-control-plaintext" },
                _vm._l(user.diffObject, function(diff, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { ins: diff.added, del: diff.removed },
                      staticStyle: { "margin-right": "5px" }
                    },
                    [_vm._v(_vm._s(diff.value))]
                  )
                }),
                0
              )
            ])
          ])
        ])
      }),
      _vm._l(_vm.difference.removedUsers, function(user) {
        return _c("div", { key: user.userName }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label del",
                staticStyle: { "word-break": "break-all" },
                attrs: { for: "description" }
              },
              [_vm._v(_vm._s(user.userName))]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c(
                  "span",
                  {
                    staticClass: "del",
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(user.keys))]
                )
              ])
            ])
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }