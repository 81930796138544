<template>
  <div>
    <!-- Service User Number -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Service User Number</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserNumber"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Payment Signing Method -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Payment Signing Method</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.tokenType"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- HSM Certificate -->
    <div class="form-group row" v-if="actionTypeDetails.tokenType === 'Hsm'">
      <label for="description" class="col-sm-2 text-right col-form-label">HSM Certificate</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.certificateId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Individual Payment Limit -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Individual Payment Limit</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.paymentLimit"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Submission  Limit -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Submission Payment Limit</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.submissionLimit"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Delete Payment Data -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Delete Payment Data</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.deletePaymentData"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- HSM PIN required -->
    <div class="form-group row" v-if="actionTypeDetails.tokenType === 'Hsm'">
      <label for="description" class="col-sm-2 text-right col-form-label">HSM PIN Required</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="diff in difference.hsmPinRequired"
            :key="diff.count"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'

const guidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/

export default {
  props: ['groupTypeDetails', 'actionTypeDetails'],
  computed: {
    difference () {
      const diff = Object.keys(this.groupTypeDetails).reduce((a, v) => {
        if (this.groupTypeDetails[v] === null || this.groupTypeDetails[v] === undefined) {
          this.groupTypeDetails[v] = ''
        }

        if (!this.actionTypeDetails[v] === null || this.actionTypeDetails[v] === undefined) {
          this.actionTypeDetails[v] = ''
        }

        switch (v) {
          case 'certificateId':
            this.getCertificate()
            break
        }

        var tempObj = {}

        tempObj[v] = diffSentences(this.groupTypeDetails[v].toString(), this.actionTypeDetails[v].toString())
        return Object.assign(a, tempObj)
      }, {})
      return diff
    }
  },
  methods: {
    async getCertificate () {
      var promises = []

      if (guidRegex.test(this.groupTypeDetails.certificateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/${this.groupTypeDetails.certificateId}`, { showerror: true, errormessage: 'Failed to get group certificate', name: 'group' }))
      }
      if (guidRegex.test(this.actionTypeDetails.certificateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/${this.actionTypeDetails.certificateId}`, { showerror: true, errormessage: 'Failed to get action certificate', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.certificateId = value.data.friendlyName
                break
              case 'action':
                this.actionTypeDetails.certificateId = value.data.friendlyName
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    }
  }
}
</script>
