import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import axios from 'axios'

export default {
  methods: {
    dateOnfocus () {
      setTimeout((ev) => {
        this.$refs.paymentDate.isOpen || this.$refs.paymentDate.showCalendar(ev)
      }, 250)
    },

    fromDateOnfocus () {
      setTimeout((ev) => {
        this.$refs.createdFrom.isOpen || this.$refs.createdFrom.showCalendar(ev)
      }, 250)
    },

    toDateOnfocus () {
      setTimeout((ev) => {
        this.$refs.createdTo.isOpen || this.$refs.createdTo.showCalendar(ev)
      }, 250)
    },

    saveFile (response) {
      const blob = new Blob([response.data], { type: 'who/cares' })
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = ''
      const disposition = response.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    },

    formatValue (value) {
      try {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    formatCount (count) {
      try {
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '$&,')
      } catch (e) {
        return 0
      }
    },

    async doOneClickSubmission () {
      if (this.paygateType !== 'Bureau') { return }

      var result = await swal.fire({
        title: 'One-Click Submissiion',
        text: 'THIS IS CURRENTLY STILL BEING DEV TESTED. This process will approve, sign and send this submission consecutively. Would you like to continue?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        var json = JSON.stringify({
          submissionId: this.submissionId,
          selectedSubType: this.selectedSubType
        })

        axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/oneClickSubmission/',
          data: json
        })
      }
    },
    async returnToViewImportedPayments () {
      var result = await swal.fire({
        title: 'Return to Submission Details?',
        text: 'Are you sure you want to return to the Submission Details page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        // Navigate to a bureau page.
        if (this.paymentNetworkType === 0) {
          // Return to view imported payments BACS Bureau
          this.$router.push({ name: 'PaymentDetails', params: { id: this.submissionId } })
        } else {
          // Return to view imported payments FPS Bureau
          this.$router.push({ name: 'PaymentDetailsFPS', params: { id: this.submissionId } })
        }
      }
    },

    async returnToViewImportedPayments_OLD () {
      // commented this out as I reopen the submission on a different page (paymentdetails in bureau)
      // I have left it here in case you ever need this for BACS.
      if (this.paygateType !== 'Bureau') { return }

      var result = await swal.fire({
        title: 'Return to Submission Details?',
        text: 'Are you sure you want to return to the Submission Details page?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        // Change any required data in order to reset the submision.
        var json = JSON.stringify({
          submissionId: this.submissionId,
          actionId: this.actionId
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/resetSubmission',
          data: json,
          showload: true
        })

        // Navigate to a bureau page.
        if (response.data.success) {
          if (this.paymentNetworkType === 0) {
            // Return to view imported payments BACS Bureau
            this.$router.push({ name: 'PaymentDetails', params: { id: this.submissionId } })
          } else {
            // Return to view imported payments FPS Bureau
            this.$router.push({ name: 'PaymentDetailsFPS', params: { id: this.submissionId } })
          }
        } else {
          this.$snapbar.w(response.data.errorMessage)
        }
      }
    }
  }
}
