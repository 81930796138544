<template>
  <div>
    <b-form-row :class="rowClass">
      <b-form-group :label-cols="4" class="col-sm-6" horizontal label="Payer Reference Generation Pattern">
        <input-tag v-model="tags" :allow-duplicates="true" :add-tag-on-blur="true" placeholder="add pattern" :validate="isValid" @input="cleanUp" />
        <b-col v-if="errorTriggered">
          <label
            class="text-danger small"
          >Tag removed as it would generate a reference that violates scheme rules</label>
        </b-col>
        <b-col>
          <label class="text-info small">i.e. {{generatedPayer}}</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-form-group :label-cols="4" class="col-sm-6" horizontal label="Add pattern (Type, Length)">
        <b-input-group>
          <b-form-select v-model="selectedPattern" :options="types">
            <!-- <template v-slot:first><option :value="null" disabled>Pattern</option></template> -->
          </b-form-select>
          <template v-slot:append>
            <b-form-select v-model="selectedCount" variant="outline-secondary">
              <!-- <template v-slot:first><option :value="null" disabled>x</option></template> -->
              <option v-for="n in 6" :key="n">{{n}}</option>
            </b-form-select>
            <b-button @click="addPattern" :disabled="disableAddition">+</b-button>
          </template>
        </b-input-group>
        <b-col v-if="disableAddition">
          <label
            class="text-danger small"
            v-if="disableAddition"
          >Additional entries will create invalid references that violates scheme rules</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row></b-form-row>
  </div>
</template>
<script>
import InputTag from 'vue-input-tag'
export default {
  props: {
    value: {
      default: '[Firstname]{3}|[Surname]{3}|[0-9A-Z]{2}|[Sequence]{2}',
      type: String
    },
    rowClass: String
  },
  data () {
    return {
      tagPattern: /^\[[0-9a-zA-Z-]+\]\{[0-9]*\}$/,
      countPattern: /\{[0-9]*\}/,
      typePattern: /\[[0-9a-zA-Z-]+\]/,
      maxLength: 12,
      selectedPattern: '[Sequence]',
      selectedCount: 3,
      patterns: [],
      tags: [],
      tag: '',
      currentLength: 0,
      errorTriggered: false,
      types: [
        { text: 'First Name', value: '[Firstname]' },
        { text: 'Surname', value: '[Surname]' },
        { text: 'Next in sequence', value: '[Sequence]' },
        { text: 'Random Letter', value: '[A-Z]' },
        { text: 'Random Number', value: '[0-9]' },
        { text: 'Random Number or Letter', value: '[0-9A-Z]' }
      ]
    }
  },
  computed: {
    payerReference: {
      get () {
        return this.value || ''
      },
      set (payerReference) {
        this.$emit('input', payerReference)
      }
    },
    disableAddition () {
      return (this.currentLength + parseInt(this.selectedCount)) >= this.maxLength
    },
    generatedPayer () {
      var testData = [
        { value: '[Firstname]', data: 'JOSEPH' },
        { value: '[Surname]', data: 'JOHNSON' },
        { value: '[Sequence]', data: '000000000000000000001' },
        { value: '[A-Z]', data: 'ASADFGRTRTFPOJHUHM<YYGMIHLIH' },
        { value: '[0-9]', data: '316813818833813814138138' },
        { value: '[A-Z0-9]', data: 'A3E12A3ASD5HS7XG12SSD78K12381' },
        { value: '[0-9A-Z]', data: 'A3E12A3ASD5HS7XG12SSD78K12381' }
      ]
      var output = ''
      this.tags.forEach((element) => {
        var match = element.match(this.tagPattern)
        if (match !== null) {
          var countStr = element.match(this.countPattern)[0]
          var tagStr = element.match(this.typePattern)[0]
          var data = testData.find(x => x.value === tagStr)
          if (data !== null) {
            var count = parseInt(countStr.replace('{', '').replace('}', ''))
            output += data.value === '[Sequence]' ? data.data.slice(count * -1) : data.data.substring(0, count)
          } else {
            output += element
          }
        } else {
          output += element
        }
      })
      return output
    }
  },
  methods: {
    addPattern (e) {
      this.tags.push(`${this.selectedPattern}{${this.selectedCount}}`)
      this.currentLength += this.selectedCount
    },
    updateCount () {
      var count = 0
      this.tags.forEach((element) => {
        var match = element.match(this.tagPattern)
        if (match !== null) {
          var countStr = element.match(this.countPattern)[0]
          count += parseInt(countStr.replace('{', '').replace('}', ''))
        } else {
          count += element.length
        }
      })
      this.currentLength = count
    },
    isValid () {
      return !this.disableAddition
    },
    cleanUp (e) {
      console.log(e)
    }
  },
  watch: {
    tags () {
      this.$emit('input', this.tags.join('|'))
      this.updateCount()
    },
    payerReference (value, oldValue) {
      if (value !== oldValue) {
        let splitVal = value.split('|')
        if (splitVal.length === 1 && splitVal[0] === '') {
          splitVal = []
        }
        this.tags = splitVal
        this.updateCount()
      }
    },
    currentLength (value) {
      if (value > this.maxLength) {
        this.tags.pop()
        this.errorTriggered = true
        setTimeout(() => { this.errorTriggered = false }, 5000)
      }
    }
  },
  components: {
    InputTag
  },
  mounted () {
    this.tags = this.payerReference.split('|')
    this.updateCount()
  }
}
</script>
<style>
.dark-mode .vue-input-tag-wrapper .input-tag {
  background-color: #508cfc !important;
  border: 1px solid #508cfc !important;
}
</style>
