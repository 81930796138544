<template>
  <div>
    <div class="form-group scrollable">
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="group.groupUserRights.$error">
          <ul>
            <li
              v-if="!group.groupUserRights.groupAdmin"
            >You require at least one group administrator</li>
            <div v-for="user in group.groupUserRights.$each.$iter" :key="user.userId.$model">
              <li
                v-if="user.uploadPath.$error"
              >{{user.userName.$model}}'s File Upload Options are incorrect</li>
              <li v-if="user.required.$error">{{user.userName.$model}} needs at least one group role</li>
            </div>
          </ul>
        </div>
        <!-- BACS User Group Rights -->
        <table
          class="table table-header-rotated"
          v-if="group.$model.groupType == groupTypes.bacs && group.groupUserRights.$model.length > 0"
        >
          <thead>
            <tr>
              <!-- Users Name -->
              <th></th>

              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'If a user has this role for a group then the group will be available on the create submission page.'"
                >
                  <span>Create</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to add payments before running pre-sub validation.'"
                >
                  <span>Add</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'"
                >
                  <span>Edit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to delete imported payments before running pre-sub validation.'"
                >
                  <span>Delete</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\'s a BACS submission.'"
                >
                  <span>Sign</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can approve a submission.'">
                  <span>Approve</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'"
                >
                  <span>Approve Own</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can send a submission to BACS.'">
                  <span>Submit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'"
                >
                  <span>Collect Reports</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'The group administrator can make any changes to a group including user\'s roles in the group. At least one user must be a group administrator.'"
                >
                  <span>Group Administrator</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div>
                  <span>Upload Type</span>
                </div>
              </th>
              <th style="width:50px;"></th>
              <!-- Delete link -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in group.groupUserRights.$each.$iter" :key="user.userId.$model">
              <td class="row-header" style="width:150px;">{{ user.userName.$model }}</td>
              <td>
                <label :for="user.userId.$model+'_create'" class="sr-only">Create</label>
                <p-check
                  :labelId="user.userId.$model+'_create'"
                  class="p-default p-curve"
                  v-model="user.create.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_add'" class="sr-only">Add</label>
                <p-check
                  :labelId="user.userId.$model+'_add'"
                  class="p-default p-curve"
                  v-model="user.add.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_edit'" class="sr-only">Edit</label>
                <p-check
                  :labelId="user.userId.$model+'_edit'"
                  class="p-default p-curve"
                  v-model="user.edit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_delete'" class="sr-only">Delete</label>
                <p-check
                  :labelId="user.userId.$model+'_delete'"
                  class="p-default p-curve"
                  v-model="user.delete.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_sign'" class="sr-only">Sign</label>
                <p-check
                  :labelId="user.userId.$model+'_sign'"
                  class="p-default p-curve"
                  v-model="user.sign.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_approval'" class="sr-only">Approval</label>
                <p-check
                  :labelId="user.userId.$model+'_approval'"
                  class="p-default p-curve"
                  v-model="user.approval.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_approveOwn'"
                  class="sr-only"
                >Can Approve Own Submission</label>
                <p-check
                  :labelId="user.userId.$model+'_approveOwn'"
                  class="p-default p-curve"
                  v-model="user.approveOwnSubmission.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_submit'" class="sr-only">Submit</label>
                <p-check
                  :labelId="user.userId.$model+'_submit'"
                  class="p-default p-curve"
                  v-model="user.submit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_collectreports'" class="sr-only">Collect Reports</label>
                <p-check
                  :labelId="user.userId.$model+'_collectreports'"
                  class="p-default p-curve"
                  v-model="user.collectReports.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_groupAdministrator'"
                  class="sr-only"
                >Group Administrator</label>
                <p-check
                  :labelId="user.userId.$model+'_groupAdministrator'"
                  class="p-default p-curve"
                  v-model="user.groupAdministrator.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>{{user.uploadType.$model}}</td>
              <td>
                <button
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  :disabled="group.$model.isItemActioned"
                  @click="openFileModal(user)"
                >Edit File Upload Options</button>
              </td>
              <td style="width:20px;">
                <a
                  class="fa fa-trash"
                  role="button"
                  @click="removeUser(user.userId.$model)"
                  :disabled="group.$model.isItemActioned"
                ></a>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- BACS Bureau User Group Rights -->
        <table
          class="table table-header-rotated"
          v-if="(group.$model.groupType === groupTypes.bureau || group.$model.groupType === groupTypes.fpsBureau) && group.groupUserRights.$model.length > 0"
        >
          <thead>
            <tr style="height: 159px;">
              <!-- Users Name -->
              <th></th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'If a user has this role for a group then the group will be available on the create submission page.'"
                >
                  <span>Create</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to add payments before running pre-sub validation.'"
                >
                  <span>Add</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'"
                >
                  <span>Edit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to delete imported payments before running pre-sub validation.'"
                >
                  <span>Delete</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\'s a BACS submission.'"
                >
                  <span>Sign</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can approve a submission.'">
                  <span>Approval</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'"
                >
                  <span>Approve Own Submission</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can send a submission to BACS.'">
                  <span>Submit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'"
                >
                  <span>Collect Reports</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'Users with this role can collect reports from the payment processing centre.'"
                >
                  <span>Collect Certificate Reports</span>
                </div>
              </th>

              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'The group administrator can make any changes to a group including user\'s roles in the group. At least one user must be a group administrator.'"
                >
                  <span>Group Administrator</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div>
                  <span>Upload Type</span>
                </div>
              </th>
              <th style="width:50px;"></th>
              <!-- Delete link -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in group.groupUserRights.$each.$iter" :key="user.userId.$model">
              <td class="row-header" style="width:150px;">{{ user.userName.$model }}</td>
              <td>
                <label :for="user.userId.$model+'_create'" class="sr-only">Create</label>
                <p-check
                  :label-id="user.userId.$model+'_create'"
                  class="p-default p-curve"
                  v-model="user.create.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_add'" class="sr-only">Add</label>
                <p-check
                  :label-id="user.userId.$model+'_add'"
                  class="p-default p-curve"
                  v-model="user.add.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_edit'" class="sr-only">Edit</label>
                <p-check
                  :label-id="user.userId.$model+'_edit'"
                  class="p-default p-curve"
                  v-model="user.edit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_delete'" class="sr-only">Delete</label>
                <p-check
                  :label-id="user.userId.$model+'_delete'"
                  class="p-default p-curve"
                  v-model="user.delete.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_sign'" class="sr-only">Sign</label>
                <p-check
                  :label-id="user.userId.$model+'_sign'"
                  class="p-default p-curve"
                  v-model="user.sign.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_approval'" class="sr-only">Approval</label>
                <p-check
                  :label-id="user.userId.$model+'_approval'"
                  class="p-default p-curve"
                  v-model="user.approval.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_approveOwn'"
                  class="sr-only"
                >Can Approve Own Submission</label>
                <p-check
                  :label-id="user.userId.$model+'_approveOwn'"
                  class="p-default p-curve"
                  v-model="user.approveOwnSubmission.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_submit'" class="sr-only">Submit</label>
                <p-check
                  :label-id="user.userId.$model+'_submit'"
                  class="p-default p-curve"
                  v-model="user.submit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_collectreports'" class="sr-only">Collect Reports</label>
                <p-check
                  :label-id="user.userId.$model+'_collectreports'"
                  class="p-default p-curve"
                  v-model="user.collectReports.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_collectCertificateLevelReports'"
                  class="sr-only"
                >Collect Certificate Level Reports</label>
                <p-check
                  :label-id="user.userId.$model+'_collectCertificateLevelReports'"
                  class="p-default p-curve"
                  v-model="user.collectCertificateLevelReports.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_groupAdministrator'"
                  class="sr-only"
                >Group Administrator</label>
                <p-check
                  :label-id="user.userId.$model+'_groupAdministrator'"
                  class="p-default p-curve"
                  v-model="user.groupAdministrator.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>{{user.uploadType.$model}}</td>
              <td>
                <button
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  :disabled="group.$model.isItemActioned"
                  @click="openFileModal(user)"
                >Edit File Upload Options</button>
              </td>
              <td style="width:20px;">
                <a
                  class="fa fa-trash"
                  role="button"
                  @click="removeUser(user.userId.$model)"
                  :disabled="group.$model.isItemActioned"
                ></a>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- BACS Online User Group Rights -->
        <table
          class="table table-header-rotated"
          v-if="group.$model.groupType == groupTypes.bacsOnline && group.groupUserRights.$model.length > 0"
        >
          <thead>
            <tr>
              <!-- Users Name -->
              <th></th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'If a user has this role for a group then the group will be available on the create submission page.'"
                >
                  <span>Create</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to add payments before running pre-sub validation.'"
                >
                  <span>Add</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'"
                >
                  <span>Edit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to delete imported payments before running pre-sub validation.'"
                >
                  <span>Delete</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\'s a BACS submission.'"
                >
                  <span>Sign</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can approve a submission.'">
                  <span>Approve</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'"
                >
                  <span>Approve Own</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'"
                >
                  <span>Collect Reports</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'The group administrator can make any changes to a group including user\'s roles in the group. At least one user must be a group administrator.'"
                >
                  <span>Group Administrator</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div>
                  <span>Upload Type</span>
                </div>
              </th>
              <th style="width:50px;"></th>
              <!-- Delete link -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in group.groupUserRights.$each.$iter" :key="user.userId.$model">
              <td class="row-header" style="width:150px;">{{ user.userName.$model }}</td>
              <td>
                <label :for="user.userId.$model+'_create'" class="sr-only">Create</label>
                <p-check
                  :label-id="user.userId.$model+'_create'"
                  class="p-default p-curve"
                  v-model="user.create.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_add'" class="sr-only">Add</label>
                <p-check
                  :label-id="user.userId.$model+'_add'"
                  class="p-default p-curve"
                  v-model="user.add.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_edit'" class="sr-only">Edit</label>
                <p-check
                  :label-id="user.userId.$model+'_edit'"
                  class="p-default p-curve"
                  v-model="user.edit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_delete'" class="sr-only">Delete</label>
                <p-check
                  :label-id="user.userId.$model+'_delete'"
                  class="p-default p-curve"
                  v-model="user.delete.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_sign'" class="sr-only">Sign</label>
                <p-check
                  :label-id="user.userId.$model+'_sign'"
                  class="p-default p-curve"
                  v-model="user.sign.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_approval'" class="sr-only">Approval</label>
                <p-check
                  :label-id="user.userId.$model+'_approval'"
                  class="p-default p-curve"
                  v-model="user.approval.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_approveOwn'"
                  class="sr-only"
                >Can Approve Own Submission</label>
                <p-check
                  :label-id="user.userId.$model+'_approveOwn'"
                  class="p-default p-curve"
                  v-model="user.approveOwnSubmission.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_collectreports'" class="sr-only">Collect Reports</label>
                <p-check
                  :label-id="user.userId.$model+'_collectreports'"
                  class="p-default p-curve"
                  v-model="user.collectReports.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_groupAdministrator'"
                  class="sr-only"
                >Group Administrator</label>
                <p-check
                  :label-id="user.userId.$model+'_groupAdministrator'"
                  class="p-default p-curve"
                  v-model="user.groupAdministrator.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>{{user.uploadType.$model}}</td>
              <td>
                <button
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  :disabled="group.$model.isItemActioned"
                  @click="openFileModal(user)"
                >Edit File Upload Options</button>
              </td>
              <td style="width:20px;">
                <a
                  class="fa fa-trash"
                  role="button"
                  @click="removeUser(user.userId.$model)"
                  :disabled="group.$model.isItemActioned"
                ></a>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- FPS User Group Rights -->
        <table
          class="table table-header-rotated"
          v-if="group.$model.groupType == groupTypes.fps && group.groupUserRights.$model.length > 0"
        >
          <thead>
            <tr>
              <!-- Users Name -->
              <th></th>

              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'If a user has this role for a group then the group will be available on the create submission page.'"
                >
                  <span>Create</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to add payments before running pre-sub validation.'"
                >
                  <span>Add</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'"
                >
                  <span>Edit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to delete imported payments before running pre-sub validation.'"
                >
                  <span>Delete</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\'s a BACS submission.'"
                >
                  <span>Sign</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can approve a submission.'">
                  <span>Approve</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'"
                >
                  <span>Approve Own</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can send a submission to BACS.'">
                  <span>Submit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'"
                >
                  <span>Collect Reports</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'The group administrator can make any changes to a group including user\'s roles in the group. At least one user must be a group administrator.'"
                >
                  <span>Group Administrator</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div>
                  <span>Upload Type</span>
                </div>
              </th>
              <th style="width:50px;"></th>

              <!-- Delete link -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in group.groupUserRights.$each.$iter" :key="user.userId.$model">
              <td class="row-header" style="width:150px;">{{ user.userName.$model }}</td>
              <td>
                <label :for="user.userId.$model+'_create'" class="sr-only">Create</label>
                <p-check
                  :label-id="user.userId.$model+'_create'"
                  class="p-default p-curve"
                  v-model="user.create.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_add'" class="sr-only">Add</label>
                <p-check
                  :label-id="user.userId.$model+'_add'"
                  class="p-default p-curve"
                  v-model="user.add.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_edit'" class="sr-only">Edit</label>
                <p-check
                  :label-id="user.userId.$model+'_edit'"
                  class="p-default p-curve"
                  v-model="user.edit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_delete'" class="sr-only">Delete</label>
                <p-check
                  :label-id="user.userId.$model+'_delete'"
                  class="p-default p-curve"
                  v-model="user.delete.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_sign'" class="sr-only">Sign</label>
                <p-check
                  :label-id="user.userId.$model+'_sign'"
                  class="p-default p-curve"
                  v-model="user.sign.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_approval'" class="sr-only">Approval</label>
                <p-check
                  :label-id="user.userId.$model+'_approval'"
                  class="p-default p-curve"
                  v-model="user.approval.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_approveOwn'"
                  class="sr-only"
                >Can Approve Own Submission</label>
                <p-check
                  :label-id="user.userId.$model+'_approveOwn'"
                  class="p-default p-curve"
                  v-model="user.approveOwnSubmission.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_submit'" class="sr-only">Submit</label>
                <p-check
                  :label-id="user.userId.$model+'_submit'"
                  class="p-default p-curve"
                  v-model="user.submit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_collectreports'" class="sr-only">Collect Reports</label>
                <p-check
                  :label-id="user.userId.$model+'_collectreports'"
                  class="p-default p-curve"
                  v-model="user.collectReports.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_groupAdministrator'"
                  class="sr-only"
                >Group Administrator</label>
                <p-check
                  :label-id="user.userId.$model+'_groupAdministrator'"
                  class="p-default p-curve"
                  v-model="user.groupAdministrator.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>{{user.uploadType.$model}}</td>
              <td>
                <button
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  :disabled="group.$model.isItemActioned"
                  @click="openFileModal(user)"
                >Edit File Upload Options</button>
              </td>
              <td style="width:20px;">
                <a
                  class="fa fa-trash"
                  role="button"
                  @click="removeUser(user.userId.$model)"
                  :disabled="group.$model.isItemActioned"
                ></a>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- FPS Online User Group Rights -->
        <table
          class="table table-header-rotated"
          v-if="group.$model.groupType == groupTypes.fpsOnline && group.groupUserRights.$model.length > 0"
        >
          <thead>
            <tr>
              <!-- Users Name -->
              <th></th>

              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'If a user has this role for a group then the group will be available on the create submission page.'"
                >
                  <span>Create</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to add payments before running pre-sub validation.'"
                >
                  <span>Add</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'"
                >
                  <span>Edit</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User has permission to delete imported payments before running pre-sub validation.'"
                >
                  <span>Delete</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\'s a BACS submission.'"
                >
                  <span>Sign</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div v-b-popover.hover.focus.top.viewport="'User can approve a submission.'">
                  <span>Approve</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'"
                >
                  <span>Approve Own</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'"
                >
                  <span>Collect Reports</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div
                  v-b-popover.hover.focus.top.viewport="'The group administrator can make any changes to a group including user\'s roles in the group. At least one user must be a group administrator.'"
                >
                  <span>Group Administrator</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div>
                  <span>Upload Type</span>
                </div>
              </th>
              <th style="width:50px;"></th>
              <!-- Delete link -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in group.groupUserRights.$each.$iter" :key="user.userId.$model">
              <td class="row-header" style="width:150px;">{{ user.userName.$model }}</td>
              <td>
                <label :for="user.userId.$model+'_create'" class="sr-only">Create</label>
                <p-check
                  :label-id="user.userId.$model+'_create'"
                  class="p-default p-curve"
                  v-model="user.create.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_add'" class="sr-only">Add</label>
                <p-check
                  :label-id="user.userId.$model+'_add'"
                  class="p-default p-curve"
                  v-model="user.add.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_edit'" class="sr-only">Edit</label>
                <p-check
                  :label-id="user.userId.$model+'_edit'"
                  class="p-default p-curve"
                  v-model="user.edit.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_delete'" class="sr-only">Delete</label>
                <p-check
                  :label-id="user.userId.$model+'_delete'"
                  class="p-default p-curve"
                  v-model="user.delete.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_sign'" class="sr-only">Sign</label>
                <p-check
                  :label-id="user.userId.$model+'_sign'"
                  class="p-default p-curve"
                  v-model="user.sign.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_approval'" class="sr-only">Approval</label>
                <p-check
                  :label-id="user.userId.$model+'_approval'"
                  class="p-default p-curve"
                  v-model="user.approval.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_approveOwn'"
                  class="sr-only"
                >Can Approve Own Submission</label>
                <p-check
                  :label-id="user.userId.$model+'_approveOwn'"
                  class="p-default p-curve"
                  v-model="user.approveOwnSubmission.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId.$model+'_collectreports'" class="sr-only">Collect Reports</label>
                <p-check
                  :label-id="user.userId.$model+'_collectreports'"
                  class="p-default p-curve"
                  v-model="user.collectReports.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>
                <label
                  :for="user.userId.$model+'_groupAdministrator'"
                  class="sr-only"
                >Group Administrator</label>
                <p-check
                  :label-id="user.userId.$model+'_groupAdministrator'"
                  class="p-default p-curve"
                  v-model="user.groupAdministrator.$model"
                  :disabled="group.$model.isItemActioned"
                ></p-check>
              </td>
              <td>{{user.uploadType.$model}}</td>
              <td>
                <button
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  :disabled="group.$model.isItemActioned"
                  @click="openFileModal(user)"
                >Edit File Upload Options</button>
              </td>
              <td style="width:20px;">
                <a
                  class="fa fa-trash"
                  role="button"
                  @click="removeUser(user.userId.$model)"
                  :disabled="group.$model.isItemActioned"
                ></a>
              </td>
            </tr>
          </tbody>
        </table>

        <div style="padding-bottom:10px;">
          <b-form inline>
            <b-form-select
              class="col-md-3"
              v-model="selectedUnallocatedUser"
              :options="unallocatedUsers"
              :disabled="group.$model.isItemActioned"
            ></b-form-select>
            <b-button
              variant="primary"
              style="margin-left: 10px;"
              :disabled="group.$model.isItemActioned"
              @click="addUser"
            >Add</b-button>
          </b-form>
        </div>
      </div>
    </div>
    <file-upload-modal :ok="hideFileOptions" :user="user"></file-upload-modal>
  </div>
</template>

<script>
import fileUploadModal from './GroupFileUploadOptionsModal'
import groupTypes from '@/Assets/Constants/groupTypes'

export default {
  props: ['group'],
  components: { 'file-upload-modal': fileUploadModal },
  data () {
    return {
      user: null,
      unallocatedUsers: [],
      selectedUnallocatedUser: '',
      groupTypes
    }
  },
  methods: {
    addUser: function () {
      // Add user to group users
      var userToAdd = {
        userId: this.selectedUnallocatedUser.id,
        userName: this.selectedUnallocatedUser.label,
        create: false,
        add: false,
        edit: false,
        delete: false,
        sign: false,
        approval: false,
        approveOwnSubmission: false,
        submit: false,
        collectReports: false,
        collectCertificateLevelReports: false,
        groupAdministrator: false,
        required: null,
        uploadType: null,
        uploadPath: null
      }
      if (this.group.$model.groupType === groupTypes.bacs || this.group.$model.groupType === groupTypes.fps || this.group.$model.groupType === groupTypes.bacsOnline || this.group.$model.groupType === groupTypes.fpsOnline || this.group.$model.groupType === groupTypes.collections) {
        userToAdd.uploadType = 'Browser Dialog'
      }
      this.group.groupUserRights.$model.push(userToAdd)
      this.group.groupUserRights.$touch()
      // Remove user from unallocated dropdown
      var index = -1
      this.unallocatedUsers.forEach(user => {
        index++
        if (user.value.id === this.selectedUnallocatedUser.id) {
          this.unallocatedUsers.splice(index, 1)
          if (this.unallocatedUsers.length > 0) {
            this.selectedUnallocatedUser = this.unallocatedUsers[0].value
          } else {
            this.selectedUnallocatedUser = null
          }
        }
      })
    },
    removeUser: function (userId) {
      var index = -1
      this.group.groupUserRights.$model.forEach(user => {
        index++
        if (user.userId === userId) {
          // Add user to unallocated dropdown array and sort
          this.unallocatedUsers.push({ value: { id: user.userId, label: user.userName }, text: user.userName })
          this.unallocatedUsers.sort(function (a, b) { return (a.value.name > b.value.name) ? 1 : ((b.value.name > a.value.name) ? -1 : 0) })
          if (this.unallocatedUsers.length === 1) {
            this.selectedUnallocatedUser = this.unallocatedUsers[0].value
          }
          // Remove user from group users
          this.group.groupUserRights.$model.splice(index, 1)
          this.group.groupUserRights.$touch()
        }
      })
    },
    openFileModal (user) {
      this.user = user
    },
    hideFileOptions () {
      this.user = null
    }
  },
  async created () {
    // Load users
    var customerUsers = await this.$store.dispatch('getUsersAsDropDownSource', { includeServiceAccounts: true })
    customerUsers.forEach(user => {
      var found = false
      for (let index = 0; index < this.group.groupUserRights.$model.length; index++) {
        const groupUser = this.group.groupUserRights.$model[index]
        if (groupUser.userId === user.id) {
          found = true
          break
        }
      }
      if (!found) {
        this.unallocatedUsers.push({ value: user, text: user.label })
      }
    })

    if (this.unallocatedUsers.length) {
      this.selectedUnallocatedUser = this.unallocatedUsers[0].value
    }
  }
}

</script>

<style scoped>
th {
  white-space: nowrap;
  border: none !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

th.rotate {
  /* Something you can count on */
  height: 130px;
}

th.rotate > div {
  transform:
    /* Magic Numbers */ translate(0px, 0px)
    /* 45 is really 360 - 45 */ rotate(315deg);
  width: 30px;
}

.small-text {
  height: 24px;
}

.scrollable {
  overflow: auto;
}
</style>
