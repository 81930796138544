<template>
  <div>
    <div v-if="reportDownload.downloadFormat === 1" style="width: 98%; margin:0 auto;">
      <span v-html="reportData"></span>
    </div>
    <div v-else style="width: 98%; margin:0 auto;">
      <b-form-textarea v-model="reportData" :rows="20"></b-form-textarea>
    </div>
    <b-button
      v-if="showDownloadButton"
      @click.prevent="downloadReport"
      class
      variant="link"
      title="Download Report"
      v-b-popover.hover.top.d500="'Download this report'"
    ></b-button>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      reportDownload: {},
      reportData: '',
      showDownloadButton: true
    }
  },

  methods: {
    async downloadReport () {
      return true
    }
  },

  async mounted () {
    this.loading = true
    this.reportDownload = this.$store.getters.reportDownload
    this.reportDownload.downloadFormat = 1

    var json = JSON.stringify({
      networkType: this.reportDownload.networkType,
      downloadUrl: this.reportDownload.downloadUrl,
      downloadFormat: this.reportDownload.downloadFormat,
      authResponse: this.reportDownload.authResponse
    })

    var response = await axios({
      method: 'POST',
      url: 'bacs/report/viewReport',
      data: json
    })

    this.loading = false
    this.reportData = response.data
  }
}
</script>
