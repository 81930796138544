import Store from '@/Store'
import roles from '@/Assets/Constants/roles'

export default [
  {
    menu_title: 'Contact Support',
    menu_icon: 'ti-headphone-alt',
    path: '/about/contactsupport'
  },
  {
    menu_title: 'Privacy Policy',
    menu_icon: 'ti-shield',
    path: '/about/privacypolicy'
  },
  {
    menu_title: 'Accessibility Statement',
    menu_icon: 'ti-headphone',
    path: '/about/islington',
    external: true,
    showMethod: async () => {
      return Store.getters.selectedCustomer === process.env.VUE_APP_ISLINGTON_ID
    }
  },
  {
    menu_title: 'Help',
    menu_icon: 'ti-help-alt',
    path: '/about/help',
    external: true
  },
  {
    menu_title: 'About',
    menu_icon: 'ti-info',
    path: '/about/about'
  }

]
