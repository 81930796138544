var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" " + _vm._s(_vm.currentPage) + "Bureau Customer "),
            _c("help-icon", {
              attrs: { docPath: "/bacsbureau/bureaucustomers/" }
            }),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _vm.BankDetailWarning && _vm.hasCustomerId
            ? _c(
                "b-card",
                {
                  staticStyle: {
                    "margin-bottom": "0.8em",
                    "margin-right": "0.8em"
                  },
                  attrs: {
                    id: "noBankLabel",
                    "bg-variant": "warning",
                    "text-variant": "white"
                  }
                },
                [
                  _vm._v(
                    "No bank details have been provided for this bureau customer. This bureau customer can still be used for processing but bank details will need to be provided at submission stage."
                  )
                ]
              )
            : _vm._e(),
          _vm.currentSubmissionCount > 0
            ? _c(
                "b-card",
                {
                  staticStyle: {
                    "margin-bottom": "0.8em",
                    "margin-right": "0.8em"
                  },
                  attrs: {
                    id: "inUseLabel",
                    "bg-variant": "warning",
                    "text-variant": "white"
                  }
                },
                [
                  _vm._v(
                    "This bureau customer is currently being used as part of a submission. No changes can be made until the submission is completed or cancelled."
                  )
                ]
              )
            : _vm._e(),
          _vm.bureauCustomer.isItemActioned
            ? _c(
                "div",
                {
                  staticClass: "alert alert-warning",
                  attrs: { id: "pendingActionLabel" }
                },
                [
                  _vm._v(
                    "This bureau customer has a pending action against it and cannot be edited"
                  )
                ]
              )
            : _vm._e(),
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    id: "detailsTab",
                    title: "Bureau Customer Details",
                    active: ""
                  }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bureauCustomerReference" } },
                          [_vm._v("Unique Customer Reference")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bureauCustomerReference",
                              type: "text",
                              readonly: "",
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.customerReference,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "customerReference",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.customerReference"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: { for: "bureauCustomerName" }
                          },
                          [_vm._v("Name")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bureauCustomerName",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            on: { input: _vm.checkName },
                            model: {
                              value: _vm.$v.bureauCustomer.name.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.bureauCustomer.name.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.bureauCustomer.name.$dirty
                    ? _c(
                        "b-col",
                        { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                        [
                          !_vm.$v.bureauCustomer.name.required
                            ? _c(
                                "label",
                                {
                                  staticClass: "text-danger small",
                                  attrs: { id: "bureauCustomerNameRequired" }
                                },
                                [_vm._v("A Bureau Customer Name is required")]
                              )
                            : _vm._e(),
                          _vm.nameInUse
                            ? _c(
                                "label",
                                {
                                  staticClass: "text-danger small",
                                  attrs: { id: "bureauCustomerNameInUse" }
                                },
                                [
                                  _vm._v(
                                    "This Bureau Customer Name is already in use, provide a new name."
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: { for: "serviceUserNumberInput" }
                          },
                          [_vm._v("Service User Number")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "serviceUserNumberInput",
                              type: "text",
                              maxlength: 6,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value:
                                _vm.$v.bureauCustomer.serviceUserNumber.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.serviceUserNumber,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.serviceUserNumber.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.bureauCustomer.serviceUserNumber.$dirty
                    ? _c(
                        "b-col",
                        { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                        [
                          !_vm.$v.bureauCustomer.serviceUserNumber.required
                            ? _c(
                                "label",
                                {
                                  staticClass: "text-danger small",
                                  attrs: {
                                    id: "serviceUserNumberInputRequired"
                                  }
                                },
                                [_vm._v("A Service User Number is required")]
                              )
                            : _vm._e(),
                          !_vm.$v.bureauCustomer.serviceUserNumber.sunValidator
                            ? _c(
                                "label",
                                {
                                  staticClass: "text-danger small",
                                  attrs: { id: "serviceUserNumberInvalid" }
                                },
                                [_vm._v("The Service User Number is not valid")]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "defaultImportFilenameInput" } },
                          [_vm._v("Default Import Filename")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "defaultImportFilenameInput",
                              type: "text",
                              maxlength: 250,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.defaultImportFilename,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "defaultImportFilename",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.defaultImportFilename"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
                        _c("label", [
                          _c(
                            "a",
                            {
                              staticClass: "text-primary small",
                              on: {
                                click: function($event) {
                                  return _vm.addLookup("[CUSTOMERREF]")
                                }
                              }
                            },
                            [_vm._v("[CUSTOMERREF]")]
                          ),
                          _vm._v(" will be replaced with Customer Reference ")
                        ])
                      ]),
                      _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
                        _c("label", [
                          _c(
                            "a",
                            {
                              staticClass: "text-primary small",
                              on: {
                                click: function($event) {
                                  return _vm.addLookup("[SUN]")
                                }
                              }
                            },
                            [_vm._v("[SUN]")]
                          ),
                          _vm._v(
                            " will be replaced with customers Service Reference Number "
                          )
                        ])
                      ]),
                      _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
                        _c("label", [
                          _c(
                            "a",
                            {
                              staticClass: "text-primary small",
                              on: {
                                click: function($event) {
                                  return _vm.addLookup("[DATETIME, DD-MM-YYYY]")
                                }
                              }
                            },
                            [_vm._v("[DATETIME, FORMAT]")]
                          ),
                          _vm._v(
                            " will be replaced with the current formatted date/time "
                          )
                        ])
                      ]),
                      _c("b-col", { attrs: { sm: "9", "offset-sm": "3" } }, [
                        _c("label", { staticClass: "small" }, [
                          _vm._v("* and ? can be used as wildcards")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "defaultImportSchemaInput" } },
                          [_vm._v("Default Import Schema")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "defaultImportSchemaInput",
                              disabled: _vm.bureauCustomer.isItemActioned,
                              options: _vm.schemas
                            },
                            model: {
                              value: _vm.bureauCustomer.defaultImportSchema,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "defaultImportSchema",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.defaultImportSchema"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.bureauCustomer.defaultImportSchema.$model == "Mapping"
                    ? _c(
                        "b-row",
                        {
                          staticClass: "mt-2",
                          class: {
                            invalid:
                              _vm.$v.bureauCustomer.defaultImportMapping.$error
                          }
                        },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "required",
                                  attrs: { for: "defaultImportMappingInput" }
                                },
                                [_vm._v("Default Import Mapping")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "defaultImportMappingInput",
                                  options: _vm.mappings,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value:
                                    _vm.$v.bureauCustomer.defaultImportMapping
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.bureauCustomer
                                        .defaultImportMapping,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.bureauCustomer.defaultImportMapping.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "Default Import Mapping" },
                                model: {
                                  value:
                                    _vm.$v.bureauCustomer.defaultImportMapping,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.bureauCustomer,
                                      "defaultImportMapping",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.bureauCustomer.defaultImportMapping"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "contraNarrativeInput" } },
                          [_vm._v("Contra Narrative")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contraNarrativeInput",
                              type: "text",
                              maxlength: 255,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.contraNarrative,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "contraNarrative",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.contraNarrative"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { id: "bankTab" } },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(" Bank Details "),
                    !_vm.$v.bureauCustomer.bankReference.required ||
                    !_vm.$v.bureauCustomer.sortCode.required ||
                    !_vm.$v.bureauCustomer.accountNumber.required
                      ? _c("i", {
                          staticClass:
                            "missing-fields text-danger fa fa-exclamation-triangle",
                          attrs: {
                            id: "bankDetailsMissing",
                            "aria-hidden": "true",
                            title: "Bank details missing"
                          }
                        })
                      : _vm._e()
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            class:
                              _vm.bureauCustomer.bankReference === "" &&
                              _vm.bureauCustomer.sortCode === "" &&
                              _vm.bureauCustomer.accountNumber === ""
                                ? ""
                                : "required",
                            attrs: { for: "bankReferenceInput" }
                          },
                          [_vm._v("Reference")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankReferenceInput",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.$v.bureauCustomer.bankReference.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.bankReference,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.bankReference.$model"
                            }
                          }),
                          !_vm.$v.bureauCustomer.bankReference.required
                            ? _c("span", [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-danger small text-nowrap",
                                    attrs: { id: "bankReferenceInputMissing" }
                                  },
                                  [_vm._v("Reference is required")]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            class:
                              _vm.bureauCustomer.bankReference === "" &&
                              _vm.bureauCustomer.sortCode === "" &&
                              _vm.bureauCustomer.accountNumber === ""
                                ? ""
                                : "required",
                            attrs: { for: "sortCodeInput" }
                          },
                          [_vm._v("Sort Code")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("the-mask", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            ref: "softCode",
                            staticClass: "form-control",
                            attrs: {
                              id: "sortCodeInput",
                              type: "text",
                              disabled: _vm.bureauCustomer.isItemActioned,
                              mask: ["##-##-##"],
                              guide: true
                            },
                            model: {
                              value: _vm.$v.bureauCustomer.sortCode.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.sortCode,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.bureauCustomer.sortCode.$model"
                            }
                          }),
                          _vm.$v.bureauCustomer.sortCode.$dirty
                            ? _c("span", [
                                !_vm.$v.bureauCustomer.sortCode.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap",
                                        attrs: { id: "sortCodeInputRequired" }
                                      },
                                      [_vm._v("Sort Code is required")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.sortCode.minLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap",
                                        attrs: { id: "sortCodeInputShort" }
                                      },
                                      [
                                        _vm._v(
                                          " Sort Code must be at least " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer.sortCode
                                                .$params.minLength.min
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.sortCode.maxLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap",
                                        attrs: { id: "sortCodeInputTooLong" }
                                      },
                                      [
                                        _vm._v(
                                          " Sort Code must be less than " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer.sortCode
                                                .$params.maxLength.max
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      !(
                        _vm.bureauCustomer.bankReference === "" &&
                        _vm.bureauCustomer.sortCode === "" &&
                        _vm.bureauCustomer.accountNumber === ""
                      )
                        ? _c(
                            "span",
                            [
                              _vm.sortCodeValid === true
                                ? _c("b-col", [
                                    _c("span", {
                                      staticClass: "fa fa-check-circle",
                                      staticStyle: {
                                        "font-size": "200%",
                                        color: "green"
                                      },
                                      attrs: { id: "sortCodeInputValid" }
                                    })
                                  ])
                                : _c("b-col", [
                                    !_vm.sortCodeValid &&
                                    _vm.$v.bureauCustomer.sortCode.required
                                      ? _c(
                                          "small",
                                          {
                                            staticClass:
                                              "form-text text-warning small text-nowrap",
                                            attrs: {
                                              id: "sortCodeInputInvalid"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.sortCodeErrorMessage)
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            class:
                              _vm.bureauCustomer.bankReference === "" &&
                              _vm.bureauCustomer.sortCode === "" &&
                              _vm.bureauCustomer.accountNumber === ""
                                ? ""
                                : "required",
                            attrs: { for: "accountNumberInput" }
                          },
                          [_vm._v("Account Number")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("the-mask", {
                            ref: "originatingAccountNumber",
                            staticClass: "form-control",
                            attrs: {
                              id: "accountNumberInput",
                              type: "text",
                              disabled: _vm.bureauCustomer.isItemActioned,
                              mask: ["########"],
                              guide: false
                            },
                            model: {
                              value: _vm.$v.bureauCustomer.accountNumber.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.accountNumber,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.bureauCustomer.accountNumber.$model"
                            }
                          }),
                          _vm.$v.bureauCustomer.accountNumber.$dirty
                            ? _c("span", [
                                !_vm.$v.bureauCustomer.accountNumber.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap",
                                        attrs: {
                                          id: "accountNumberInputRequired"
                                        }
                                      },
                                      [_vm._v("Account Number is required")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.accountNumber.minLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap",
                                        attrs: { id: "accountNumberInputShort" }
                                      },
                                      [
                                        _vm._v(
                                          " Account Number must be at least " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer
                                                .accountNumber.$params.minLength
                                                .min
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.$v.bureauCustomer.accountNumber.maxLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap",
                                        attrs: { id: "accountNumberInputLong" }
                                      },
                                      [
                                        _vm._v(
                                          " Account Number must be less than " +
                                            _vm._s(
                                              _vm.$v.bureauCustomer
                                                .accountNumber.$params.maxLength
                                                .max
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      !(
                        _vm.bureauCustomer.bankReference === "" &&
                        _vm.bureauCustomer.sortCode === "" &&
                        _vm.bureauCustomer.accountNumber === ""
                      )
                        ? _c(
                            "span",
                            [
                              _vm.accountNumberValid === true
                                ? _c("b-col", [
                                    _c("span", {
                                      staticClass: "fa fa-check-circle",
                                      staticStyle: {
                                        "font-size": "200%",
                                        color: "green"
                                      },
                                      attrs: { id: "accountNumberInputValid" }
                                    })
                                  ])
                                : _c("b-col", [
                                    !_vm.accountNumberValid &&
                                    _vm.$v.bureauCustomer.accountNumber.required
                                      ? _c(
                                          "small",
                                          {
                                            staticClass:
                                              "form-text text-warning small text-nowrap",
                                            attrs: {
                                              id:
                                                "accountNumberInputLabelInvalid"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.accountNumberErrorMessage
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "bankNameInput" } }, [
                          _vm._v("Bank Name")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankNameInput",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.$v.bureauCustomer.bankName.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.bureauCustomer.bankName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.bureauCustomer.bankName.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "bankAddress1Input" } }, [
                          _vm._v("Address")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddress1Input",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine1,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddress2Input",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine2,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine2",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddress3Input",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine3,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine3",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddress4Input",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLine4,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLine4",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLine4"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", {
                          attrs: { for: "bankAddressLocalityInput" }
                        })
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddressLocalityInput",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressLocality,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressLocality",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressLocality"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bankAddressTownInput" } },
                          [_vm._v("Town")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "5" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddressTownInput",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressTown,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressTown",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressTown"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bankAddressCountyInput" } },
                          [_vm._v("County")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "5" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddressCountyInput",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressCounty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressCounty",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressCounty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "bankAddressPostcodeInput" } },
                          [_vm._v("Post Code")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "bankAddressPostcodeInput",
                              type: "text",
                              maxlength: 10,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.bankAddressPostcode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "bankAddressPostcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.bankAddressPostcode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "b-tab",
                { attrs: { id: "limitsTab", title: "Payment Limits" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "paymentLimitInput" } }, [
                          _vm._v("Payment Limit")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("currency-input", {
                            attrs: {
                              id: "paymentLimitInput",
                              type: "text",
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.paymentLimit,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "paymentLimit",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.paymentLimit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "submissionLimitInput" } },
                          [_vm._v("Submission Limit")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("currency-input", {
                            attrs: {
                              id: "submissionLimitInput",
                              type: "text",
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.submissionLimit,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "submissionLimit",
                                  $$v
                                )
                              },
                              expression: "bureauCustomer.submissionLimit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { id: "contactTab", title: "Contact Info" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "contactFirstNameInput" } },
                          [_vm._v("Forename")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contactFirstNameInput",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.contactFirstName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "contactFirstName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.contactFirstName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "contactSurnameInput" } }, [
                          _vm._v("Surname")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contactSurnameInput",
                              type: "text",
                              maxlength: 50,
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.contactSurname,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "contactSurname",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.contactSurname"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "contactAddress1Input" } },
                                [_vm._v("Address")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contactAddress1Input",
                                  type: "text",
                                  maxlength: 50,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value: _vm.bureauCustomer.addressLine1,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine1",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contactAddress2Input",
                                  type: "text",
                                  maxlength: 50,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value: _vm.bureauCustomer.addressLine2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine2",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contactAddress3Input",
                                  type: "text",
                                  maxlength: 50,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value: _vm.bureauCustomer.addressLine3,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine3",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine3"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contactAddress4Input",
                                  type: "text",
                                  maxlength: 50,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value: _vm.bureauCustomer.addressLine4,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLine4",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLine4"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c("label", {
                                attrs: { for: "contactAddressLocality" }
                              })
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contactAddressLocality",
                                  type: "text",
                                  maxlength: 50,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value: _vm.bureauCustomer.addressLocality,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressLocality",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressLocality"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "concatAddressTown" } },
                                [_vm._v("Town")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "5" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "concatAddressTown",
                                  type: "text",
                                  maxlength: 50,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value: _vm.bureauCustomer.addressTown,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressTown",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressTown"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "contactAddressCounty" } },
                                [_vm._v("County")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "5" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contactAddressCounty",
                                  type: "text",
                                  maxlength: 50,
                                  disabled: _vm.bureauCustomer.isItemActioned
                                },
                                model: {
                                  value: _vm.bureauCustomer.addressCounty,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bureauCustomer,
                                      "addressCounty",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "bureauCustomer.addressCounty"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "concactAddressPostcode" } },
                          [_vm._v("Postcode")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "concactAddressPostcode",
                              type: "text",
                              maxlength: "10",
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.addressPostcode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "addressPostcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.addressPostcode"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { sm: "2" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info",
                            attrs: {
                              id: "concactAddressPostcodeLookupButton",
                              disabled:
                                this.bureauCustomer.addressPostcode === "" ||
                                this.bureauCustomer.addressPostcode === null ||
                                _vm.bureauCustomer.isItemActioned
                            },
                            on: {
                              click: function($event) {
                                return _vm.getAddress()
                              }
                            }
                          },
                          [_vm._v("Lookup Address")]
                        )
                      ])
                    ],
                    1
                  ),
                  this.addressOptions.length > 0 && !this.addressFound
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "addressOptions" } },
                                [_vm._v("Addresses")]
                              )
                            ]
                          ),
                          _c("b-col", { attrs: { sm: "8" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedAddress,
                                    expression: "selectedAddress"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "addressOptions",
                                  "aria-label": "Please select an address"
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedAddress = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.setAddress
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { selected: "" } }, [
                                  _vm._v("Please select an address")
                                ]),
                                _vm._l(_vm.addressOptions, function(
                                  foundAddress
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: foundAddress.addressLine1,
                                      domProps: { value: foundAddress }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(foundAddress.addressLine1) +
                                          ", " +
                                          _vm._s(foundAddress.addressTown) +
                                          ", " +
                                          _vm._s(foundAddress.addressCounty)
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "contactTelephone" } }, [
                          _vm._v("Telephone")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contactTelephone",
                              type: "text",
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.telephone,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "telephone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.telephone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "contactEmailAddress" } }, [
                          _vm._v("Email")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contactEmailAddress",
                              type: "text",
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.emailAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "emailAddress",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.emailAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { attrs: { for: "contactWebsiteUrl" } }, [
                          _vm._v("Website")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contactWebsiteUrl",
                              type: "text",
                              disabled: _vm.bureauCustomer.isItemActioned
                            },
                            model: {
                              value: _vm.bureauCustomer.websiteUrl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bureauCustomer,
                                  "websiteUrl",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "bureauCustomer.websiteUrl"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.customerId !== undefined && _vm.customerId !== null
                ? _c(
                    "b-tab",
                    {
                      attrs: { id: "notesTab", title: "Notes" },
                      on: { click: _vm.reloadNotes }
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("bureau-customer-notes-control", {
                                ref: "customerNotes",
                                attrs: {
                                  "can-load": true,
                                  "customer-id": _vm.customerId
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.ManageBureauCustomer
        ? _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  id: "customerSaveButton",
                  disabled:
                    _vm.saveDisabled ||
                    _vm.bureauCustomer.isItemActioned ||
                    _vm.currentSubmissionCount > 0 ||
                    _vm.nameInUse
                },
                on: {
                  click: function($event) {
                    _vm.checkValidation() && _vm.onSaveClick()
                  }
                }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-danger float-right",
                attrs: {
                  id: "customerDeleteButton",
                  disabled:
                    _vm.bureauCustomer.isItemActioned ||
                    _vm.currentSubmissionCount > 0 ||
                    _vm.inMapping
                },
                on: {
                  click: function($event) {
                    return _vm.onDeleteClick()
                  }
                }
              },
              [_vm._v("Delete")]
            ),
            _vm.inMapping
              ? _c(
                  "span",
                  {
                    staticClass: "text-warning float-right p-2",
                    attrs: { id: "inMappingLabel" }
                  },
                  [
                    _vm._v(
                      "This customer cannot be deleted as it is used in a mapping"
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }