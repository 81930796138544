var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              _vm.checkValidation() && _vm.assignToken()
            }
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" Assign USB Token "),
                  _c("help-icon", {
                    attrs: {
                      docPath: "/administration/usbtokens/assignusbtoken/"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _vm.usbTokenDetails == null
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.loadDetails }
                    },
                    [_vm._v("Get USB Token Details")]
                  )
                : _vm._e(),
              _vm.usbTokenDetails != null
                ? _c("div", [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Token Serial Number")
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-control-plaintext" }, [
                          _vm._v(_vm._s(_vm.usbTokenDetails.TokenSerialNumber))
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Token Supplier")
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-control-plaintext" }, [
                          _vm._v(_vm._s(_vm.usbTokenDetails.TokenSupplier))
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Certificate Serial Number")
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-control-plaintext" }, [
                          _vm._v(
                            _vm._s(_vm.usbTokenDetails.CertificateSerialNumber)
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Certificate Valid From")
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-control-plaintext" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.usbTokenDetails.ValidFrom,
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-form-label col-md-3" }, [
                        _vm._v("Certificate Valid To")
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-control-plaintext" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.usbTokenDetails.ValidTo,
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.userId.$error }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Select a User")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("b-form-select", {
                              staticClass: "form-control",
                              attrs: {
                                options: _vm.users,
                                "value-field": "id",
                                "text-field": "label"
                              },
                              model: {
                                value: _vm.userId,
                                callback: function($$v) {
                                  _vm.userId = $$v
                                },
                                expression: "userId"
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "user" },
                              model: {
                                value: _vm.$v.userId,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v, "userId", $$v)
                                },
                                expression: "$v.userId"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ]),
            _vm.usbTokenDetails != null
              ? _c("div", { staticClass: "idb-block-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit", disabled: _vm.isLoading }
                    },
                    [_vm._v("Assign Token")]
                  )
                ])
              : _vm._e()
          ])
        ]
      ),
      _c("plugin")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }