var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.users.length > 0
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              _vm._l(_vm.users, function(user, index) {
                return _c("div", { key: user.email }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Email")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [_vm._v(_vm._s(user.email))])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Name")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [_vm._v(_vm._s(user.name))])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Mobile phone")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [_vm._v(_vm._s(user.mobilePhone))])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Email Action")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        { staticClass: "form-control-plaintext" },
                        [
                          _c("tick", {
                            attrs: { coloured: false },
                            model: {
                              value: user.emailAction,
                              callback: function($$v) {
                                _vm.$set(user, "emailAction", $$v)
                              },
                              expression: "user.emailAction"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  user.twoFactorMethod
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-3 offset-1 col-form-label" },
                          [_vm._v("Two-factor method")]
                        ),
                        _c("div", { staticClass: "col-8" }, [
                          _c("div", { staticClass: "form-control-plaintext" }, [
                            _c("span", [_vm._v(_vm._s(user.twoFactorMethod))])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Default payment group")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [_vm._v(_vm._s(user.defaultGroup))])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Is locked-out")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        { staticClass: "form-control-plaintext" },
                        [
                          _c("tick", {
                            attrs: { coloured: false },
                            model: {
                              value: user.lockedOut,
                              callback: function($$v) {
                                _vm.$set(user, "lockedOut", $$v)
                              },
                              expression: "user.lockedOut"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Is enabled")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        { staticClass: "form-control-plaintext" },
                        [
                          _c("tick", {
                            attrs: { coloured: false },
                            model: {
                              value: user.enabled,
                              callback: function($$v) {
                                _vm.$set(user, "enabled", $$v)
                              },
                              expression: "user.enabled"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Payment limit")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("number")(user.paymentLimit)))
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Submission limit")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("number")(user.submissionLimit)))
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Approval limit")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("number")(user.approvalLimit)))
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Assigned a USB token")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        { staticClass: "form-control-plaintext" },
                        [
                          _c("tick", {
                            attrs: { coloured: false },
                            model: {
                              value: user.usbToken,
                              callback: function($$v) {
                                _vm.$set(user, "usbToken", $$v)
                              },
                              expression: "user.usbToken"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("User's roles")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      user.roles.length !== 0
                        ? _c("table", { staticClass: "table" }, [
                            _vm._m(1, true),
                            _c(
                              "tbody",
                              _vm._l(user.roles, function(roles) {
                                return _c("tr", { key: roles[0].module }, [
                                  _c("td", [_vm._v(_vm._s(roles[0].module))]),
                                  _c(
                                    "td",
                                    _vm._l(roles, function(role) {
                                      return _c("div", { key: role.name }, [
                                        _c("div", [
                                          _vm._v(_vm._s(role.name) + " ")
                                        ])
                                      ])
                                    }),
                                    0
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _c("div", { staticClass: "form-control-plaintext" }, [
                            _vm._v("No roles")
                          ])
                    ])
                  ]),
                  user.groups.length !== 0
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("table", { staticClass: "table offset-1" }, [
                          _vm._m(2, true),
                          _c(
                            "tbody",
                            _vm._l(user.groups, function(group) {
                              return _c("tr", { key: group.email }, [
                                _c("td", [_vm._v(_vm._s(group.email))]),
                                _c("td", [_vm._v(_vm._s(group.roles))])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-3 offset-1 col-form-label" },
                          [_vm._v("User's Groups")]
                        ),
                        _vm._m(3, true)
                      ]),
                  index !== _vm.users.length - 1 ? _c("hr") : _vm._e()
                ])
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Users")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", width: "25.5%" } }, [
          _vm._v("Module")
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", width: "25.5%" } }, [
          _vm._v("User's Groups")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("User's Roles within the Group")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "form-control-plaintext" }, [
        _c("span", [_vm._v("No Groups")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }