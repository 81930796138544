var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "managePaymentModal",
      attrs: {
        id: "manage-payment-modal",
        title: "Payment Details for FPS Submission",
        size: "xl",
        "hide-header-close": "",
        static: ""
      },
      on: { ok: _vm.SaveBlockDetail, shown: _vm.load }
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v("Save")
      ]),
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "thirdPartyAccountName" }
                  },
                  [_vm._v("Name")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      readonly: _vm.readonly,
                      disabled: _vm.disabledFields
                    },
                    model: {
                      value: _vm.$v.thirdPartyAccountName.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.thirdPartyAccountName,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.thirdPartyAccountName.$model"
                    }
                  })
                ],
                1
              ),
              _vm.$v.thirdPartyAccountName.$dirty
                ? _c(
                    "b-col",
                    { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                    [
                      !_vm.$v.thirdPartyAccountName.required
                        ? _c(
                            "label",
                            {
                              staticClass:
                                "form-text text-danger small text-nowrap"
                            },
                            [_vm._v("Name is required")]
                          )
                        : _vm._e(),
                      !_vm.$v.thirdPartyAccountName.maxLength
                        ? _c(
                            "label",
                            {
                              staticClass:
                                "form-text text-danger small text-nowrap"
                            },
                            [
                              _vm._v(
                                "Name must be less than " +
                                  _vm._s(
                                    _vm.$v.thirdPartyAccountName.$params
                                      .maxLength.max
                                  ) +
                                  " characters"
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "thirdPartySortCode" }
                  },
                  [_vm._v("Sort Code")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "2" } },
                [
                  _c("the-mask", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    ref: "thirdPartySortCode",
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      mask: ["##-##-##"],
                      guide: true,
                      readonly: _vm.readonly,
                      disabled: _vm.disabledFields
                    },
                    model: {
                      value: _vm.$v.thirdPartySortCode.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.thirdPartySortCode,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.thirdPartySortCode.$model"
                    }
                  }),
                  _vm.$v.thirdPartySortCode.$dirty
                    ? _c("span", [
                        !_vm.$v.thirdPartySortCode.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [_vm._v("Sort Code is required")]
                            )
                          : _vm._e(),
                        !_vm.$v.thirdPartySortCode.minLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Sort Code must be at least " +
                                    _vm._s(
                                      _vm.$v.thirdPartySortCode.$params
                                        .minLength.min
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.$v.thirdPartySortCode.maxLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Sort Code must be less than " +
                                    _vm._s(
                                      _vm.$v.thirdPartySortCode.$params
                                        .maxLength.max
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.sortCodeValid && _vm.$v.thirdPartySortCode.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-warning small text-nowrap"
                              },
                              [_vm._v(_vm._s(_vm.sortCodeErrorMessage))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm.sortCodeValid
                    ? _c("b-col", [
                        _c("i", { staticClass: "fas fa-check mt-2" })
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "thirdPartyAccountNumber" }
                  },
                  [_vm._v("Account Number")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "2" } },
                [
                  _c("the-mask", {
                    ref: "thirdPartyAccountNumber",
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      mask: ["########"],
                      guide: false,
                      readonly: _vm.readonly,
                      disabled: _vm.disabledFields
                    },
                    model: {
                      value: _vm.$v.thirdPartyAccountNumber.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.thirdPartyAccountNumber,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.thirdPartyAccountNumber.$model"
                    }
                  }),
                  _vm.$v.thirdPartyAccountNumber.$dirty
                    ? _c("span", [
                        !_vm.$v.thirdPartyAccountNumber.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [_vm._v("Account Number is required")]
                            )
                          : _vm._e(),
                        !_vm.$v.thirdPartyAccountNumber.minLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Account Number must be at least " +
                                    _vm._s(
                                      _vm.$v.thirdPartyAccountNumber.$params
                                        .minLength.min
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.$v.thirdPartyAccountNumber.maxLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "Account Number must be less than " +
                                    _vm._s(
                                      _vm.$v.thirdPartyAccountNumber.$params
                                        .maxLength.max
                                    ) +
                                    " numbers"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.accountNumberValid &&
                        _vm.$v.thirdPartyAccountNumber.required
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-warning small text-nowrap"
                              },
                              [_vm._v(_vm._s(_vm.accountNumberErrorMessage))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm.accountNumberValid == true
                    ? _c("b-col", [
                        _c("i", { staticClass: "fas fa-check mt-2" })
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                _c("label", { attrs: { for: "userReference" } }, [
                  _vm._v("User Reference")
                ])
              ]),
              _c(
                "b-col",
                { attrs: { sm: "7" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      readonly: _vm.readonly,
                      disabled: _vm.disabledFields
                    },
                    model: {
                      value: _vm.$v.userReference.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.userReference,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.userReference.$model"
                    }
                  }),
                  _vm.$v.userReference.$dirty
                    ? _c("span", [
                        !_vm.$v.userReference.maxLength
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "form-text text-danger small text-nowrap"
                              },
                              [
                                _vm._v(
                                  "User reference must be less than " +
                                    _vm._s(
                                      _vm.$v.userReference.$params.maxLength.max
                                    ) +
                                    " characters"
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "transactionCode" }
                  },
                  [_vm._v("Transaction Code")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "2" } },
                [
                  !_vm.readonly
                    ? _c("b-form-select", {
                        attrs: {
                          id: "transactionCodeSelector",
                          options: _vm.transactionCodes,
                          "value-field": "code",
                          "text-field": "code",
                          disabled: _vm.disabledFields
                        },
                        on: {
                          input: function($event) {
                            return _vm.transactionCodeChanged()
                          }
                        },
                        model: {
                          value: _vm.transactionCode,
                          callback: function($$v) {
                            _vm.transactionCode = $$v
                          },
                          expression: "transactionCode"
                        }
                      })
                    : _vm._e(),
                  _vm.readonly
                    ? _c("b-form-input", {
                        attrs: { type: "text", readonly: _vm.readonly },
                        model: {
                          value: _vm.$v.transactionCode.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.transactionCode,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.transactionCode.$model"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { "offset-sm": "3" } }, [
                _c("span", { staticClass: "form-text small" }, [
                  _vm._v(_vm._s(_vm.transactionCodeDescription))
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                _c(
                  "label",
                  { staticClass: "required", attrs: { for: "amount" } },
                  [_vm._v("Amount")]
                )
              ]),
              _c(
                "b-col",
                { attrs: { sm: "3" } },
                [
                  _c("currency-input", {
                    attrs: {
                      type: "text",
                      readonly: _vm.readonly,
                      disabled: _vm.disabledFields
                    },
                    model: {
                      value: _vm.$v.amount.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.amount, "$model", $$v)
                      },
                      expression: "$v.amount.$model"
                    }
                  })
                ],
                1
              ),
              _vm.$v.amount.$dirty
                ? _c(
                    "b-col",
                    { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                    [
                      !_vm.$v.amount.required
                        ? _c("label", { staticClass: "text-danger small" }, [
                            _vm._v("Amount is required")
                          ])
                        : _vm._e(),
                      !_vm.$v.amount.maxValue
                        ? _c("label", { staticClass: "text-danger small" }, [
                            _vm._v("Maximum payment is £20,000,000")
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              !_vm.$v.amount.amountZeroValidForTransactionCode
                ? _c(
                    "b-col",
                    { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                    [
                      _c("label", { staticClass: "text-danger small" }, [
                        _vm._v("Amount must be £0.00 for this transaction code")
                      ])
                    ]
                  )
                : _vm._e(),
              !_vm.$v.amount.amountNonZeroValidForTransactionCode
                ? _c(
                    "b-col",
                    { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                    [
                      _c("label", { staticClass: "text-danger small" }, [
                        _vm._v(
                          "Amount must be greater than £0.00 for this transaction code"
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.validationMessages.length > 0
            ? _c(
                "span",
                [
                  _c("b-table", {
                    staticClass: "mt-2",
                    attrs: {
                      borderless: "",
                      small: "",
                      "caption-top": "",
                      striped: true,
                      items: _vm.validationMessages,
                      fields: _vm.validationFields,
                      "per-page": 10
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(messageSeverity)",
                          fn: function(data) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "badge badge-pill table-pill",
                                  class: data.value
                                },
                                [_vm._v(_vm._s(data.value))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3527705608
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }