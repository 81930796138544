var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.action.id
    ? _c(
        "actions-block",
        {
          attrs: {
            action: _vm.action,
            status: _vm.status,
            from: _vm.from,
            name: "Bureau Customer",
            shouldShowFooter: _vm.shouldShowFooter,
            userRoleError: _vm.userRoleError
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [_vm._v("Bureau Customer Details")])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _vm.status === "Edit"
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-secondary",
                      attrs: { role: "alert" }
                    },
                    [
                      _vm._v(" The previous details are shown in "),
                      _c("span", { staticClass: "del" }, [_vm._v("red")]),
                      _vm._v(", the new details in "),
                      _c("span", { staticClass: "ins" }, [_vm._v("Green")]),
                      _vm._v(". ")
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Name")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.name, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Service User Number")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.serviceUserNumber, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Contra Narrative")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.contraNarrative, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Default Import Filename")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.defaultImportFilename, function(
                      diff
                    ) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Default Import Mapping")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.defaultImportMapping, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Default Import Schema")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.defaultImportSchema, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Payment Limit")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.paymentLimit, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Submission Limit")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.submissionLimit, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Tags")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.tags, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Address")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.addressLine1, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("-")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.addressLine2, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("-")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.addressLine3, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("-")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.addressLine4, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Postcode")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.addressPostcode, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Town")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.addressTown, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Telephone")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.telephone, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Telephone 2")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.telephone2, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Website")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.websiteUrl, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Contact Forename")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.contactFirstName, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Contact Surname")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.contactSurname, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("E-Mail")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.emailAddress, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [_vm._v("Bank Name and Address")])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Bank Reference")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankReference, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Sort Code")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.sortCode, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Account Number")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.accountNumber, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Bank Name")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankName, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Address")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankAddressLine1, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankAddressLine2, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankAddressLine3, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankAddressLine4, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankTown, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "description" }
                  },
                  [_vm._v("Post Code")]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "form-control-plaintext" },
                    _vm._l(_vm.difference.bankAddressPostcode, function(diff) {
                      return _c(
                        "span",
                        {
                          key: diff.value,
                          class: { ins: diff.added, del: diff.removed },
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(diff.value))]
                      )
                    }),
                    0
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }