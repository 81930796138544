<template>
  <div>
    <folderBrowser v-if="showInputFolderBrowser" @close="showInputFolderBrowser = false; closeInputFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showInputFileBrowser" @close="showInputFileBrowser = false; closeInputFilePopup()">
    </fileBrowser>
    <folderBrowser v-if="showOutputFolderBrowser" @close="showOutputFolderBrowser = false; closeOutputFolderPopup()">
    </folderBrowser>
    <fileBrowser v-if="showOutputFileBrowser" @close="showOutputFileBrowser = false; closeOutputFilePopup()">
    </fileBrowser>
    <h3>File Joiner<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/fileoperations/filejoiner/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <label class="required" >Input Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnInputFileBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The input filename field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="required" >Input Folder</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnInputFolderBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The input folder field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="required" >Output Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s4.value.$error}"
        @input="$v.selectedNode.props.s4.value.$touch()"
        v-model="selectedNode.props.s4.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnOutputFileBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s4.value.required && $v.selectedNode.props.s4.value.$dirty ">The output filename field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s4.value.maxLength">Maximum length is {{ $v.selectedNode.props.s4.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="required" >Output Folder</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnOutputFolderBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty ">The output folder field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label>Delimiter</label>
        <textarea class="form-control"
          rows="8"
          cols="40"
          :class="{invalid: $v.selectedNode.props.s5.value.$error}"
          @input="$v.selectedNode.props.s5.value.$touch()"
          v-model="selectedNode.props.s5.value">
        </textarea>
      </div>
    </div>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s4: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s5: {
          value: {
            maxLength: maxLength(2048)
          }
        }
      }
    }
  },
  data () {
    return {
      showInputFolderBrowser: false,
      showInputFileBrowser: false,
      showOutputFolderBrowser: false,
      showOutputFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s1.value = utils.sanitisePath(this.selectedNode.props.s1.value)
      this.selectedNode.props.s3.value = utils.sanitisePath(this.selectedNode.props.s3.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnInputFileBrowser () {
      this.property = this.selectedNode.props.s2.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showInputFileBrowser = true
    },
    btnInputFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s1.value)
      this.showInputFolderBrowser = true
    },
    btnOutputFileBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s4.value)
      this.showOutputFileBrowser = true
    },
    btnOutputFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showOutputFolderBrowser = true
    },
    closeInputFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s2.value = filename
      if (!this.selectedNode.props.s1.value) {
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s1.value = folder
      }
    },
    closeInputFolderPopup () {
      this.selectedNode.props.s1.value = this.$store.state.mapping.modalPopupString
    },
    closeOutputFilePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s4.value = filename
      if (!this.selectedNode.props.s3.value) {
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s3.value = folder
      }
    },
    closeOutputFolderPopup () {
      this.selectedNode.props.s3.value = this.$store.state.mapping.modalPopupString
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
