var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _vm.headerRow.mode === "span"
        ? _c(
            "th",
            {
              staticClass: "vgt-left-align vgt-row-header",
              attrs: { colspan: _vm.fullColspan }
            },
            [
              _vm.selectAllByGroup
                ? [
                    _vm._t(
                      "table-header-group-select",
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: _vm.id + "_selectall" }
                          },
                          [_vm._v("Select All")]
                        ),
                        _c("input", {
                          attrs: {
                            id: _vm.id + "_selectall",
                            type: "checkbox"
                          },
                          domProps: { checked: _vm.allSelected },
                          on: {
                            change: function($event) {
                              return _vm.toggleSelectGroup($event)
                            }
                          }
                        })
                      ],
                      { columns: _vm.columns, row: _vm.headerRow }
                    )
                  ]
                : _vm._e(),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      _vm.collapsable
                        ? _vm.$emit("vgtExpand", !_vm.headerRow.vgtIsExpanded)
                        : function() {}
                    }
                  }
                },
                [
                  _vm.collapsable
                    ? _c("span", {
                        staticClass: "triangle",
                        class: { expand: _vm.headerRow.vgtIsExpanded }
                      })
                    : _vm._e(),
                  _vm._t(
                    "table-header-row",
                    [
                      _vm.headerRow.html
                        ? _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.headerRow.label) }
                          })
                        : _c("span", [
                            _vm._v(" " + _vm._s(_vm.headerRow.label) + " ")
                          ])
                    ],
                    { row: _vm.headerRow }
                  )
                ],
                2
              )
            ],
            2
          )
        : _vm._e(),
      _vm.headerRow.mode !== "span" && _vm.lineNumbers
        ? _c("th", { staticClass: "vgt-row-header" }, [
            _c("span", { staticClass: "sr-only" }, [_vm._v("Line Number")])
          ])
        : _vm._e(),
      _vm.headerRow.mode !== "span" && _vm.selectable
        ? _c(
            "th",
            { staticClass: "vgt-row-header" },
            [
              _vm.selectAllByGroup
                ? [
                    _vm._t(
                      "table-header-group-select",
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: _vm.id + "_selectall" }
                          },
                          [_vm._v("Select All")]
                        ),
                        _c("input", {
                          attrs: {
                            id: _vm.id + "_selectall",
                            type: "checkbox"
                          },
                          domProps: { checked: _vm.allSelected },
                          on: {
                            change: function($event) {
                              return _vm.toggleSelectGroup($event)
                            }
                          }
                        })
                      ],
                      { columns: _vm.columns, row: _vm.headerRow }
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._l(_vm.columns, function(column, i) {
        return _vm.headerRow.mode !== "span" && !column.hidden
          ? _c(
              "th",
              {
                key: i,
                staticClass: "vgt-row-header",
                class: _vm.getClasses(i, "td"),
                on: {
                  click: function($event) {
                    _vm.columnCollapsable(i)
                      ? _vm.$emit("vgtExpand", !_vm.headerRow.vgtIsExpanded)
                      : function() {}
                  }
                }
              },
              [
                _vm.columnCollapsable(i)
                  ? _c("span", {
                      staticClass: "triangle",
                      class: { expand: _vm.headerRow.vgtIsExpanded }
                    })
                  : _vm._e(),
                _vm._t(
                  "table-header-row",
                  [
                    !column.html
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.collectFormatted(
                                  _vm.headerRow,
                                  column,
                                  true
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    column.html
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.collectFormatted(_vm.headerRow, column, true)
                            )
                          }
                        })
                      : _vm._e()
                  ],
                  {
                    row: _vm.headerRow,
                    column: column,
                    formattedRow: _vm.formattedRow(_vm.headerRow, true)
                  }
                )
              ],
              2
            )
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }