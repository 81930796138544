import axios from 'axios'

// actions
const actions = {
  async getGroupsAsDropDownSource ({ state }, type = '') {
    try {
      if (type !== undefined || type !== null) {
        type = `/${type}`
      }

      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/Dropdown/${this.state.common.paygateId}${type}`)

      return response.data
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  actions
}
