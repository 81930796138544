var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "payerSetupForm" } }, [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _c("help-icon", {
                    attrs: {
                      docPath: "/paygate-collections/payers/managepayers/"
                    }
                  }),
                  _vm._v("Manage Payers"),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _vm.incompleteCount > 0 && !_vm.isLoading
                  ? _c(
                      "b-card",
                      {
                        staticStyle: {
                          "margin-bottom": "0.8em",
                          "margin-right": "0.8em"
                        },
                        attrs: {
                          "bg-variant": "warning",
                          "text-variant": "white"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-exclamation-triangle rpad"
                        }),
                        _vm._v(
                          " You have " +
                            _vm._s(_vm.incompleteCount) +
                            " incomplete Payers. "
                        ),
                        _c(
                          "b-btn",
                          {
                            attrs: { variant: "warning ml-3" },
                            on: {
                              click: function($event) {
                                _vm.statusFilter = 4
                                _vm.load()
                              }
                            }
                          },
                          [_vm._v("View")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "groupSelector" }, [
                  _c(
                    "div",
                    { staticClass: "row form-group" },
                    [
                      _c("div", { staticClass: "col-md-2 required" }, [
                        _vm._v(" Select a Group ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("group-select", {
                            attrs: {
                              groups: _vm.customerGroups,
                              clearable: false
                            },
                            on: { input: _vm.groupChanged },
                            model: {
                              value: _vm.group,
                              callback: function($$v) {
                                _vm.group = $$v
                              },
                              expression: "group"
                            }
                          }),
                          _vm.allGroups
                            ? _c("p", [
                                _c("small", [
                                  _vm._v(
                                    "Payers can only be created once a group is selected."
                                  )
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-sm-4",
                          attrs: {
                            "label-cols": 2,
                            horizontal: "",
                            label: "Status"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              options: _vm.statuses,
                              required: "",
                              "value-field": "value",
                              "text-field": "name"
                            },
                            on: { change: _vm.load },
                            model: {
                              value: _vm.statusFilter,
                              callback: function($$v) {
                                _vm.statusFilter = $$v
                              },
                              expression: "statusFilter"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "vue-good-table",
                        {
                          ref: "table",
                          attrs: {
                            mode: "remote",
                            columns: _vm.columns,
                            rows: _vm.inactivePayers,
                            lineNumbers: true,
                            totalRows: _vm.totalRecords,
                            "search-options": {
                              enabled: true
                            },
                            paginationOptions: _vm.paginationOptions,
                            "sort-options": _vm.sortOptions,
                            rowStyleClass: _vm.rowToCss,
                            isLoading: _vm.isTableLoading,
                            styleClass: "vgt-table striped bordered"
                          },
                          on: {
                            "on-page-change": _vm.onPageChange,
                            "on-sort-change": _vm.onSortChange,
                            "on-column-filter": _vm.onColumnFilter,
                            "on-per-page-change": _vm.onPerPageChange,
                            "on-search": _vm.onSearch,
                            "on-row-click": _vm.onRowClick,
                            "on-cell-click": _vm.onCellClick,
                            "update:isLoading": function($event) {
                              _vm.isTableLoading = $event
                            },
                            "update:is-loading": function($event) {
                              _vm.isTableLoading = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field === "status"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: _vm.getBadgeClass(
                                                  props.row.status
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  props.formattedRow[
                                                    props.column.field
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : props.column.field === "buttons"
                                    ? _c(
                                        "span",
                                        [
                                          props.row.plans.length === 1
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "outline-secondary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.goToSchedule(
                                                        props.row.ukPayerId,
                                                        props.row.plans[0]
                                                          .planId
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-calendar mr-2"
                                                  }),
                                                  _vm._v("View Schedule")
                                                ]
                                              )
                                            : _vm._e(),
                                          props.row.plans.length > 1
                                            ? _c(
                                                "b-dropdown",
                                                {
                                                  attrs: {
                                                    variant: "outline-secondary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        slot: "button-content"
                                                      },
                                                      slot: "button-content"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-calendar mr-2"
                                                      }),
                                                      _vm._v("View Schedule")
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    props.row.plans,
                                                    function(plan, index) {
                                                      return _c(
                                                        "b-dropdown-item",
                                                        {
                                                          key: index,
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.goToSchedule(
                                                                props.row
                                                                  .ukPayerId,
                                                                plan.planId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              plan.planName
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("template", { slot: "loadingContent" }, [
                            _c("h1", [_vm._v("Loading...")])
                          ]),
                          _c(
                            "div",
                            {
                              attrs: { slot: "emptystate" },
                              slot: "emptystate"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vgt-center-align vgt-text-disabled"
                                },
                                [_vm._v(" No Payers Available ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Clear filters",
                                      expression: "'Clear filters'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  staticClass: "btn btn-link",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.clearTableFilters($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fa-stack",
                                      staticStyle: { "font-size": "10px" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-filter fa-stack-1x dimmedIcon"
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "fa fa-ban fa-stack-2x dimmedIcon"
                                      })
                                    ]
                                  ),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Clear filters")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: _vm.allGroups
                                        ? "A group must be selected to add a payer"
                                        : "Create a new payer in the current group.",
                                      expression:
                                        "allGroups ? 'A group must be selected to add a payer' : 'Create a new payer in the current group.'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    disabled: _vm.allGroups || _vm.isLoading,
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.addNewPayer($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-plus pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Add Payer")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Refresh the data in the table",
                                      expression:
                                        "'Refresh the data in the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    disabled: _vm.isLoading,
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.load($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-sync pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Refresh Table")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "idb-block-footer" },
                  [
                    _c(
                      "b-btn",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value: _vm.allGroups
                              ? "A group must be selected to add a payer"
                              : "Create a new payer in the current group.",
                            expression:
                              "allGroups ? 'A group must be selected to add a payer' : 'Create a new payer in the current group.'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: {
                          id: "addPayerButton",
                          disabled: _vm.allGroups || _vm.isLoading,
                          type: "link",
                          variant: "primary"
                        },
                        on: { click: _vm.addNewPayer }
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus mr-2" }),
                        _vm._v("Create New Payer ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }