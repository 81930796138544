<template>
  <div>
    <folderBrowser v-if="showFromFolderBrowser" @close="showFromFolderBrowser = false; closeFromPopup()">
    </folderBrowser>
    <folderBrowser v-if="showToFolderBrowser" @close="showToFolderBrowser = false; closeToPopup()">
    </folderBrowser>
    <fileBrowser v-if="showModalFileBrowser" @close="showModalFileBrowser = false; closePopup()">
    </fileBrowser>
    <h3>Move Some Files<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/fileoperations/movesomefiles/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>    <hr>
    <br/>
    <label class="required" >Filename</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty ">The filename field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>

    <br/>
    <label class="required">From Path</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s1.value.$error}"
        @input="$v.selectedNode.props.s1.value.$touch()"
        v-model="selectedNode.props.s1.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnFromPathBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">The From Path field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <br/>
    <label class="required">To Path</label>
    <div class="input-group mb-3">
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" @click.prevent="btnToPathBrowser" type="button">...</button>
      </div>
    </div>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The To Path field is required!</p>
    <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
    <br/>
    <div class="form-group">
      <label class="required">Number of Files</label>
      <input
        type="number"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.int1.value.$error}"
        @input="$v.selectedNode.props.int1.value.$touch()"
        v-model="selectedNode.props.int1.value">
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.required && $v.selectedNode.props.int1.value.$dirty ">The field is required!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.minValue">Minimum value is {{ $v.selectedNode.props.int1.value.$params.minValue.min }}!</p>
      <p class="validationText" v-if="!$v.selectedNode.props.int1.value.maxValue">Maximum value is {{ $v.selectedNode.props.int1.value.$params.maxValue.max }}!</p>
    </div>
    <br/>
    <div class="form-group">
      <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b1.value">Overwrite Existing File</p-check>
    </div>
    <br/>
    <div class="form-group">
      <p-check name="check" class="p-switch" color="primary" v-model="selectedNode.props.b2.value">Follow error path if no matching files found</p-check>
    </div>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength, maxValue, minValue, integer } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser: folderBrowser,
    fileBrowser: fileBrowser
  },
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(48)
          }
        },
        int1: {
          value: {
            required,
            integer,
            maxValue: maxValue(65535),
            minValue: minValue(1)
          }
        }
      }
    }
  },
  data () {
    return {
      showToFolderBrowser: false,
      showFromFolderBrowser: false,
      showModalFileBrowser: false,
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s1.value = utils.sanitisePath(this.selectedNode.props.s1.value)
      this.selectedNode.props.s2.value = utils.sanitisePath(this.selectedNode.props.s2.value)
      // this.selectedNode.props.s3.value = utils.sanitiseFilename(this.selectedNode.props.s3.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    btnFileBrowser () {
      this.property = this.selectedNode.props.s3.value
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showModalFileBrowser = true
    },
    btnFromPathBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s1.value)
      this.showFromFolderBrowser = true
    },
    btnToPathBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s2.value)
      this.showToFolderBrowser = true
    },
    closePopup () {
      let filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (filename.includes('/')) {
        filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('/') + 1)
      }
      this.selectedNode.props.s3.value = filename
      if (!this.selectedNode.props.s1.value) {
        let folder = this.$store.state.mapping.modalPopupString.substring(0, this.$store.state.mapping.modalPopupString.length - filename.length)
        if (folder.endsWith('/') || folder.endsWith('\\')) {
          folder = folder.slice(0, -1)
        }
        this.selectedNode.props.s1.value = folder
      }
    },
    closeFromPopup () {
      this.selectedNode.props.s1.value = this.$store.state.mapping.modalPopupString
    },
    closeToPopup () {
      this.selectedNode.props.s2.value = this.$store.state.mapping.modalPopupString
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
