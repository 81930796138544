var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Workflow/Mapping Designer Settings "),
                _c("help-icon", {
                  attrs: {
                    docPath:
                      "/automation/paygate-mapping/mapping-designer/designersettings/"
                  }
                }),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("form", [
            _c("div", { staticClass: "idb-block-content" }, [
              _c("br"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-1" }),
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "p-check",
                      {
                        staticClass: "p-switch",
                        attrs: {
                          labelId: "autoSaveCheck",
                          name: "check",
                          color: "primary "
                        },
                        model: {
                          value: _vm.config.autosave,
                          callback: function($$v) {
                            _vm.$set(_vm.config, "autosave", $$v)
                          },
                          expression: "config.autosave"
                        }
                      },
                      [_c("span", { staticClass: "ml-2" })]
                    )
                  ],
                  1
                )
              ]),
              _c("br"),
              _c("br"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-1" }),
                _c("div", { staticClass: "col-md-2" }, [
                  _vm._v("Default Group")
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("group-select", {
                      attrs: { groups: _vm.options, clearable: false },
                      model: {
                        value: _vm.config.selectedGroupId,
                        callback: function($$v) {
                          _vm.$set(_vm.config, "selectedGroupId", $$v)
                        },
                        expression: "config.selectedGroupId"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("br"),
              _c("br"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-1" }),
                _c("div", { staticClass: "col-md-2" }, [
                  _vm._v("Default User")
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.config.selectedUserId,
                          expression: "config.selectedUserId"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.config,
                            "selectedUserId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.userOptions, function(option) {
                      return _c(
                        "option",
                        { key: option.id, domProps: { value: option.value } },
                        [_vm._v(_vm._s(option.label))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._m(1)
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-sm-3" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { disabled: _vm.isLoading, variant: "primary" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.btnSave($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-save mr-2" }),
                        _vm._v("Save Settings ")
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { for: "autoSaveCheck" } }, [
        _vm._v("Enable Auto Save")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "line-height": "600%" } }, [_c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }