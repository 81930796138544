<template>
  <div class="idb-block">
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block-title">
      <h2>
        Submission details for '{{ bureauJob.bureauJobName }}'
        <help-icon :docPath="'/bacsbureau/jobs/'"></help-icon>
        <favourite-icon></favourite-icon>
      </h2>
    </div>

    <div class="idb-block-content">
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label class="required" for="submissionReference">Submission Reference</label>
        </b-col>
        <b-col sm="6">
          <b-form-input type="text" v-model.trim="$v.submissionReference.$model"></b-form-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.submissionReference.$dirty">
          <label
            class="text-danger small"
            v-if="!$v.submissionReference.required"
          >A 'Submission Reference' is required</label>
          <label
            class="text-danger small"
            v-if="!$v.submissionReference.maxLength"
          >A 'Submission Reference' must be less than {{$v.submissionReference.$params.maxLength.max}} characters</label>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="contraNarrative">Contra Narrative</label>
        </b-col>
        <b-col sm="6">
          <b-form-input type="text" v-model.trim="$v.contraNarrative.$model"></b-form-input>
        </b-col>
        <b-col offset-sm="3" class="pl-3" v-if="$v.contraNarrative.$dirty">
          <label
            class="text-danger small"
            v-if="!$v.contraNarrative.maxLength"
          >A 'Contra Narrative' must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
        </b-col>
      </b-row>
      <!--
      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="paymentDate">Payment Date</label>
        </b-col>
        <b-col sm="3">
          <vuejsDatepicker
            ref="paymentDate"
            name="paymentDate"
            id="paymentDate"
            v-model="paymentDate"
            format="dd/MM/yyyy"
            input-class="form-control"
            :bootstrap-styling="true"
            :disabledDates="disabledDates"
            v-on:selected="paymentDateChanged"
            @focusin.native="dateOnfocus"
            :monday-first="true"
            :disabled="disableDatePicker"
          ></vuejsDatepicker>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="3" class="mt-2">
          <label for="processingDate">Processing Date</label>
        </b-col>
        <b-col sm="3">{{ processingDate }}</b-col>
      </b-row>-->
    </div>

    <div class="idb-block-footer">
      <button
        class="btn btn-primary mr-2"
        :disabled="$v.$invalid || isTableLoading"
        @click="onNextClick"
      >Next</button>
    </div>
  </div>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
import Utility from '@/Assets/Mixins/Utility'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import moment from "moment";

export default {
  mixins: [tableFilterMixin,
    bacsMixin,
    Utility,
    loading
  ],
  props: {
    id: String
  },
  data () {
    return {
      submissionReference: '',
      contraNarrative: '',
      // processingDate: '',
      // paymentDate: '',
      disabledDates: {},
      // processingDates: {},
      // disableDatePicker: false,
      readonly: false, // get rid of this
      bureauJob: {},
      // bureauJobName: '',
      // bureauServiceUserNumber: '',
      // groupId: '',
      rows: [],
      columns: [
        {
          label: 'Bureau Customer',
          field: 'bureauCustomerName'
        },
        {
          label: 'Filename',
          field: 'filename'
        }
      ]
    }
  },

  async created () {
    await this.load()
  },

  async mounted () {

  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/GetBureauJobDetails/' + this.id, {})
        this.bureauJob = response.data
        this.rows = response.data.bureauJobDetailLines
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    async onNextClick () {
      try {
        var data = {
          submissionReference: this.submissionReference,
          bureauJobId: this.bureauJob.bureauJobId,
          contraNarrative: this.contraNarrative,
          // processingDate: this.processingDate,
          groupId: this.bureauJob.groupId
        }

        var sendupdata = JSON.stringify(data)
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/startfpsbureausubmission', sendupdata, { showload: true })

        // temp status ok
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          var bureauSubmissionId = response.data.id
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: bureauSubmissionId, startProcess: true } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (error) {
        this.$toastr.e(`Bureau Submission ${data.submissionReference} failed ${error}`, 'Bureau Submission')
      }
    },
    paymentDateChanged (value) {
      value = moment(value).format('DD/MM/YYYY')
      this.$store.dispatch('getNextProcessingDate', value)
        .then(response => {
          this.processingDate = this.$store.getters.nextProcessingDate
        })
    }
  },
  validations: {
    submissionReference: {
      required,
      maxLength: maxLength(150)
    },
    contraNarrative: {
      maxLength: maxLength(50)
    }
  }

}

</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}
</style>
