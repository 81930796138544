<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Detail<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h2>
        </div>
        <div class="idb-block-content">
          <div>
            <div class="row">
              <div class="col-lg-2 col-sm-12"><strong>Title</strong></div>
              <div class="col-lg-3 col-sm-12">{{truncate(workflow.metaData.title)}}</div>
              <div class="col-lg-2 col-sm-12"><strong>Description</strong></div>
              <div class="col-lg-3 col-sm-12">{{workflow.metaData.description}}</div>
              <div class="col-lg-2 col-sm-12">
                <b-button v-if="workflowIsLoaded" :disabled="isLoading" variant="primary" @click="btnEditWorkflow" v-b-popover.hover.top.d500="'Edit this workflow in the Workflow Designer.'"><i class="glyphicon ti-pencil mr-2"></i>Edit Workflow</b-button>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-2 col-sm-12"><strong>Workflow Type</strong></div>
              <div class="col-md-3 col-sm-12">{{reformatType(workflow.metaData.type)}}</div>
              <div class="col-md-2 col-sm-12"><strong>Author</strong></div>
              <div class="col-md-3 col-sm-12">{{workflow.metaData.author}}</div>
            </div>
            <br/>
          </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            <span>
              Workflow Runs
              <b-spinner v-if="heatmapLoading" small variant="secondary" label="Spinning"></b-spinner>
              <b-button @click.prevent="getHeatmapData" :disabled="isLoading" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the heatmap'" ><i class="fas fa-sync-alt pointer"></i></b-button>
            </span>
          </h2>
        </div>
        <div class="idb-block-content">
          <div>
            <calendar-heatmap
              tooltip-unit="executions"
              @day-click="heatmapClick"
              :endDate=getHeatmapEnddate()
              :values=activityData
              :range-color=rangeColours />
          </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Workflow Log <span v-if="!showAll"> {{justDate(targetDay)}} </span><b-spinner v-if="vgtLoading" small variant="secondary" label="Spinning"></b-spinner>
          </h2>
        </div>
        <div class="idb-block-content">
          <div>
            <vue-good-table
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              :rows="rows"
              :columns="columns"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              ref="triggerTelemetry"
              :lineNumbers="true"
              styleClass="vgt-table striped bordered table-hover"
              @on-row-click="onRowClick"
              @on-cell-click="onCellClick">
              styleClass="vgt-table striped bordered table-hover">
                  <template slot="table-row" slot-scope="props">
                    <span v-if = "props.column.field == 'currentStatus'">
                      <b-badge pill v-if = "props.row.currentStatus == 'Failed'" variant="danger">{{props.row.currentStatus}}</b-badge>
                      <b-badge pill v-else-if = "props.row.currentStatus == 'Complete'" variant="success">{{props.row.currentStatus}}</b-badge>
                      <b-badge pill v-else-if = "props.row.currentStatus == 'Completed with warnings'" variant="warning">{{props.row.currentStatus}}</b-badge>
                      <b-badge pill v-else-if = "props.row.currentStatus == 'Manual Action'" variant="warning">{{props.row.currentStatus}}</b-badge>
                      <b-badge pill v-else-if = "props.row.currentStatus == 'Processing'" variant="primary">{{props.row.currentStatus}}</b-badge>
                      <span v-else>{{props.row.currentStatus}}</span>
                    </span>
                    <span v-else-if = "props.column.field == 'createdAt'">
                      {{ reformatDate(props.formattedRow[props.column.field])}}
                    </span>
                    <span v-else-if = "props.column.field == 'updatedAt'">
                      {{ reformatDate(props.formattedRow[props.column.field])}}
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                  <div slot="table-actions">
                    <button
                      @click.prevent="clearTableFilters"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Clear filters'">
                      <span class="fa-stack" style="font-size: 10px;">
                        <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                        <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                      </span><span class="sr-only">Clear filters</span>
                    </button>
                    <b-button @click.prevent="setShowToday" class="" :disabled="isLoading" variant="link"  v-b-popover.hover.top.d500="'Show Today'" ><i class="far fa-calendar pointer dimmedIcon"></i><span class="sr-only">Show Today</span></b-button>
                    <!-- <b-button @click.prevent="setShowAll" class="" :disabled="isLoading" variant="link"  v-b-popover.hover.top.d500="'Show all days'" ><i class="far fa-calendar-alt pointer dimmedIcon"></i></b-button> -->
                    <b-button @click.prevent="load" class="" :disabled="isLoading" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'" ><i class="fas fa-sync-alt pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span></b-button>
                  </div>
                </vue-good-table>
              </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Report
            <!-- <span class="pull-right">
              <b-button class="mr-3" variant="outline-primary" size="sm" @click="btnSaveWorkflow"><i class="glyphicon ti-save mr-2"></i>Save</b-button>
            </span> -->
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-md-2 col-sm-12">
              <p-check name="check" class="p-switch" color="primary " v-model="workflow.createReport">Create a Workflow Report</p-check>
            </div>
            <transition name="fadein">
            <div class="col-md-9 col-sm-12" v-if="workflow.createReport">
              <textarea
                class="form-control"
                placeholder="Email recipients (Comma Separated)"
                rows="4"
                cols="60"
                @input="$emit('textarea',$event.target.value)"
                @blur="$v.workflow.reportEmailAddresses.$touch()"
                v-model="workflow.reportEmailAddresses">
              </textarea>
              <p class="validationText" v-if="!$v.workflow.reportEmailAddresses.required && $v.workflow.reportEmailAddresses.$dirty ">This field is required because you have requested that a workflow report is sent via email!</p>
              <p class="validationText" v-if="!$v.workflow.reportEmailAddresses.maxLength">Maximum length is {{ $v.workflow.reportEmailAddresses.$params.maxLength.max }} characters!</p>
            </div>
            </transition>
          </div>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Alerts
            <!-- <span class="pull-right">
              <b-button class="mr-3" variant="outline-primary" size="sm" @click="btnSaveWorkflow"><i class="glyphicon ti-save mr-2"></i>Save</b-button>
            </span> -->
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-md-2 col-sm-12">
              <p-check name="check" class="p-switch" color="primary " v-model="workflow.monitorAlerts">Enable Alert Monitoring</p-check>
            </div>
          </div>
          <br/>
          <transition name="fadein">
          <div v-if="workflow.monitorAlerts">
          <div class="row">
            <div class="col-md-12 col-sm-12 offset-md-2">
              <p-check name="check" class="p-switch" color="primary " v-model="workflow.monitorDuration">Monitor Duration - Raises an alert if the workflow takes longer to complete than normal.</p-check>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-md-12 col-sm-12 offset-md-2">
              <p-check name="check" class="p-switch" color="primary " v-model="workflow.monitorErrors">Monitor errors - Raises an alert if an error is detected whilst it is running.</p-check>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-md-12 col-sm-12 offset-md-2">
              <p-check name="check" class="p-switch" color="primary " v-model="workflow.monitorStart">Monitor Start - Raises an alert if a scheduled workflow does not start when it is scheduled to.</p-check>
            </div>
          </div>
          <br><br>
          <div class="row">
            <div class="col-md-10 col-sm-12 offset-md-2">
              If an alert is raised, send en email to the following recipients:
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-md-9 col-sm-12 offset-md-2">
              <textarea
                class="form-control"
                placeholder="Email recipients (Comma Separated)"
                rows="4"
                cols="60"
                @input="$emit('textarea',$event.target.value)"
                @blur="$v.workflow.alertEmailAddresses.$touch()"
                v-model="workflow.alertEmailAddresses">
              </textarea>
              <p class="validationText" v-if="!$v.workflow.alertEmailAddresses.maxLength">Maximum length is {{ $v.workflow.alertEmailAddresses.$params.maxLength.max }} characters!</p>
            </div>
          </div>

        </div>
        </transition>

        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Triggers using this Workflow
            <span class="badge badge-pill badge-primary">{{boundTriggers.length}}</span>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row" v-for="(trigger, index) in boundTriggers" :key="index">
            <div class="col-md-12">
              <i v-if="trigger.triggerType=='cronTrigger'" class="far fa-clock"></i>
              <i v-else-if="trigger.triggerType=='fileTrigger'" class="far fa-file"></i>
              <i v-else-if="trigger.triggerType=='scheduleTrigger'" class="far fa-calendar-alt"></i>
              <router-link v-if="trigger.triggerType='cronTrigger'" tag="a" :to="'/automation/workflow/triggerconfig/cronTrigger/' + trigger.id" class=""> {{trigger.title}} </router-link>
              <span v-if="trigger.enabled == true" class="muted small"> Enabled</span>
              <span v-else class="muted small"> Disabled</span>
            </div>
          </div>
        </div>
      </div>
       <div class="idb-block">
        <div class="idb-block-title">
          <h2>Workflow Metrics <b-spinner v-if="workflowMetricsLoading" small variant="secondary" label="Spinning"></b-spinner></h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-md-3">
              <p class="font-weight-bold">Number of Executions</p>
            </div>
            <div class="col-md-3">
              <span v-if="!workflowMetrics.numExecuted">None</span>
              <span v-else>{{workflowMetrics.numExecuted}}</span>
            </div>
            <!-- <div class="col-md-3">
              <p class="font-weight-bold">Last Execution</p>
            </div>
            <div class="col-md-3">
              <span v-if="!workflow.lastExecutionDate">-</span>
              <span v-else>{{reformatDate(workflow.lastExecutionDate)}}</span>
            </div> -->
          </div>

          <div class="row">
            <div class="col-md-3">
              <p class="font-weight-bold">Average Queue Time</p>
            </div>
            <div class="col-md-3">
              <span v-if="workflowMetrics.avgQueueTime">{{millisecToSec(workflowMetrics.avgQueueTime)}} Seconds</span>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold">Average Execution Time</p>
            </div>
            <div class="col-md-3">
              <span v-if="workflowMetrics.avgExecutionTime">{{millisecToSec(workflowMetrics.avgExecutionTime)}} Seconds</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <p class="font-weight-bold">Workflow Created</p>
            </div>
            <div class="col-md-3">
              <span v-if="!workflow.creationDate">-</span>
              <span v-else>{{reformatDate(workflow.creationDate)}}</span>
            </div>
            <div class="col-md-3">
              <p class="font-weight-bold">Last Execution</p>
            </div>
            <div class="col-md-3">
              <span v-if="!workflow.lastExecutionDate">-</span>
              <span v-else>{{reformatDate(workflow.lastExecutionDate)}}</span>
            </div>
           </div>

          <br>
        </div>

        <div class="idb-block-footer">

          <b-button v-if="workflowIsLoaded" :disabled="isLoading" class="mr-3" variant="primary" @click="btnEditWorkflow"><i class="glyphicon ti-pencil mr-2" v-b-popover.hover.top.d500="'Edit this workflow in the Workflow Designer.'"></i>Edit Workflow</b-button>
          <b-button v-if="workflowIsLoaded" :disabled="isLoading" class="mr-3" variant="outline-primary" @click="btnSaveWorkflow"><i class="glyphicon ti-save mr-2" v-b-popover.hover.top.d500="'Save this workflow.'"></i>Save Details</b-button>

          <!-- <b-button v-if="workflowIsLoaded" :disabled="isLoading" variant="outline-primary" v-b-popover.hover.top.d500="'Create a new workflow with the same configuration as this current workflow.'" @click="btnCloneWorkflow">Clone Workflow</b-button>
          <b-button v-if="isCorvidAdmin" class="ml-3" variant="outline-primary" @click="btnTransferWorkflow"><i class="glyphicon ti-new-window mr-2"></i>Copy Workflow to Another Customer</b-button>
           -->
          <div class="btn-group " role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="glyphicon ti-more mr-2"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <a v-if="workflowIsLoaded" @click="btnCloneWorkflow" class="dropdown-item" href="#"><i class="glyphicon ti-files mr-2"></i>Clone Workflow</a>
              <a v-if="isCorvidAdmin"  @click="btnTransferWorkflow" class="dropdown-item" href="#"><i class="glyphicon ti-new-window mr-2"></i>Copy Workflow to Another Customer</a>
              <div class="dropdown-divider"></div>
              <a v-if="workflowIsLoaded"  @click.prevent="btnDeleteWorkflow"  class="dropdown-item text-danger" href="#"><i class="glyphicon ti-trash mr-2"></i>Delete Workflow</a>
            </div>
          </div>
          <!-- <b-button v-if="workflowIsLoaded" :disabled="isLoading" @click.prevent="btnDeleteWorkflow" variant="danger" class="pull-right" v-b-popover.hover.top.d500="'Permanently delete this workflow.'"><i class="glyphicon ti-trash mr-2"></i>Delete Workflow</b-button> -->
        </div>
      </div>
      <br/>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { requiredIf, maxLength } from 'vuelidate/lib/validators'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import { CalendarHeatmap } from 'vue-calendar-heatmap'
import roles from '@/Assets/Constants/roles'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'

export default {
  name: 'Workflow',
  mixins: [tableFilterMixin, loading],
  components: {
    CalendarHeatmap,
    swal
  },
  validations: {
    workflow: {
      reportEmailAddresses: {
        required: true,
        required: requiredIf(function () {
          return this.workflow.createReport
        }),
        maxLength: maxLength(2048)
      },
      alertEmailAddresses: {
        maxLength: maxLength(2048)
      }
    }
  },
  data () {
    return {
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
      heatmapLoading: true,
      vgtLoading: true,
      workflowIsLoaded: false,
      workflowMetricsLoading: false,
      canViewTelemetry: true,
      workflowTitle: 'My Workflow',
      id: this.$route.params.id,
      workflow: {
        metaData: {}
      },
      workflowMetrics: {},
      activityData: [],
      columns: [
        {
          label: 'Id',
          field: '_id',
          hidden: true
        },
        {
          label: 'executionId',
          field: 'executionId',
          hidden: true
        },
        {
          label: 'Instance',
          field: 'title',
          filterOptions: { enabled: false },
          sortable: false
        },
        {
          label: 'Status',
          field: 'currentStatus',
          filterOptions: { enabled: false },
          sortable: true
        },
        {
          label: 'Created At',
          field: 'createdAt',
          hidden: false,
          inputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
          outputFormat: 'DD-MM-YYYY HH:mm:ss',
          filterOptions: { enabled: false },
          sortable: true
        },
        {
          label: 'Last Updated At',
          field: 'updatedAt',
          hidden: false,
          inputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
          outputFormat: 'DD-MM-YYYY HH:mm:ss',
          filterOptions: { enabled: false },
          sortable: true
        }
      ],
      boundTriggers: [],
      createReport: true,
      targetDay: new Date(),
      showAll: false,
      row_data: [],
      totalRecords: 0,
      defaultParams: {},
      sortOptions: { enabled: true },
      paginationOptions: { enabled: true, perPage: 10 },
      doneParams: false,
      serverParams: {
        columnFilters: {},
        sort: [{
          field: 'createdAt',
          type: 'desc'
        }],
        page: 1,
        perPage: 10
      }
    }
  },
  computed: {
    isSystemAdmin () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    isCorvidAdmin () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    rows: function () {
      return this.row_data
    },
    rangeColours () {
      if (this.$store.getters.getClaim('theme').value === 'dark') {
        return ['#777777', '#136fc9', '#0e59a4', '#12427a', '#0b224f']
      }
      return ['#ebedf0', '#c0ddf9', '#73b3f3', '#3886e1', '#17459e']
    },
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  methods: {
    truncate (txt) {
      if (txt) {
        if (txt.length > 24) {
          txt = txt.substring(0, 24) + '...'
        }
      }
      return txt
    },
    async setShowAll () {
      this.showAll = true
      await this.load()
    },
    async setShowToday () {
      this.showAll = false
      this.targetDay = new Date()
      await this.load()
    },
    async heatmapClick (evt) {
      this.targetDay = evt.date
      this.showAll = false
      await this.load()
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
    },
    onSortChange (params) {
      this.updateParams({
        sort: params
      })
    },
    onColumnFilter (params) {
      this.updateParams(params)
    },
    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSearch (params) {
      this.updateParams({ searchKey: params.searchTerm })
    },
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      this.load()
    },
    async btnSaveWorkflow () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      try {
        await axios.patch(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/${this.id}`, this.workflow)
        this.$toastr.s(`The workflow '${this.workflow.title}' was successfully updated.`)
      } catch (e) {
        this.$snapbar.e(`Could not save workflow data. ${e.message}`)
      }
    },
    savedParamsToObjects (savedParams) {
      // Update the sort options, might not need to do this as the changes _should_ call the event handlers
      this.sortOptions = { ...this.sortOptions, initialSortBy: savedParams.sort }
      // Get the field names from the column filters
      const columnnFilterKeys = Object.keys(savedParams.columnFilters)
      for (var i = 0; i < columnnFilterKeys.length; i++) {
        const filterKey = columnnFilterKeys[i]
        // Find the column in the list
        const column = this.columns.find(c => c.field === filterKey)
        // Apply the filter
        column.filterOptions.filterValue = savedParams.columnFilters[filterKey]
      }
      // Set per page
      this.paginationOptions.perPage = savedParams.perPage
      if (this.$refs.table) {
        this.$refs.triggerTelemetry.$refs.paginationBottom.perPage = savedParams.perPage
        this.$refs.triggerTelemetry.$refs.paginationBottom.handlePerPage()
      }
      // Set page
      this.paginationOptions.setCurrentPage = savedParams.page
      // Has to be done in next tick
      this.$nextTick(() => {
        // They have no initial global search so this be the only way!
        this.$refs.triggerTelemetry.globalSearchTerm = savedParams.searchKey
      })
    },
    clearTableFilters () {
      // Clear column filters, doubt there will be any initial ones but just in case apply them after
      this.$refs.triggerTelemetry.resetTable()
      // Update params on good table
      this.savedParamsToObjects(this.defaultParams)
      // Update params that are actually sent to the server
      this.updateParams(this.defaultParams)
      // Reset show all days
      this.setShowAll()
    },
    // load items is what brings back the rows from server
    async load () {
      this.isTableLoading = true
      const postObj = {
        serverParams: this.serverParams,
        id: this.id,
        showAll: this.showAll,
        targetDay: this.targetDay
      }

      let res
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowinstances`, postObj)
        if (res) {
          this.row_data = res.data.resObj.data
          this.totalRecords = res.data.resObj.count
          this.vgtLoading = false
        }
      } catch (e) {
        // this.$snapbar.e(`Could not return workflow telemetry data. ${e.message}`)
        console.log(e)
        this.canViewTelemetry = false
      }
      this.isTableLoading = false
    },
    async getBoundTriggers () {
      let res
      const body = {
        workflowId: this.id
      }
      try {
        res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}getboundTriggers`, body)
        if (res) {
          console.log(res.data)
          this.boundTriggers = res.data
          if (!this.workflow) {
            this.$snapbar.e('Could not return workflow bound triggers data.')
            this.workflowIsLoaded = false
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this data.')
        } else {
          this.$snapbar.e(`Could not display the bound triggers - ${e.message}`)
        }
        this.workflowIsLoaded = false
      }
    },
    async getWorkflow () {
      this.$snapbar.hide()
      this.workflowIsLoaded = true
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/${this.id}`)
        if (res) {
          this.workflow = res.data.workflow
          if (!this.workflow) {
            this.$snapbar.e('Could not return workflow data.  The workflow does not exist or the data could not be retrieved.')
            this.workflowIsLoaded = false
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view this Workflow.')
        } else {
          this.$snapbar.e(`Could not display the Workflow - ${e.message}`)
        }
        this.workflowIsLoaded = false
      }
    },
    getHeatmapEnddate () {
      return new Date().toISOString().split('T')[0]
    },
    async getHeatmapData () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowheatmapdata/${this.id}`)
        if (res) {
          this.activityData = res.data
          this.heatmapLoading = false
        }
      } catch (e) {
        this.canViewTelemetry = false
      }
    },
    async getWorkflowMetrics () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflowMetrics/${this.id}`)
        if (res) {
          console.log(res)
          this.workflowMetrics = res.data
          this.workflowMetricsLoading = false
        }
      } catch (e) {
        this.canViewTelemetry = false
      }
    },
    btnNewWorkflow () {
      this.$router.push('/automation/workflow/designer')
    },
    btnCancel () {
      this.$router.go(-1)
    },
    async btnCloneWorkflow () {
      const result = await swal.fire({
        title: 'Clone Workflow',
        text: 'Create an exact copy of the current workflow.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        input: 'text',
        inputValue: `${this.workflow.metaData.title} (clone)`
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        if (result.value) {
          if (result.value === this.workflow.metaData.title) {
            result.value = `${this.workflow.metaData.title} (clone)`
          }
          this.workflow.metaData.title = result.value
          const cloneWorkflow = {
            title: result.value,
            paygateId: this.workflow.paygateId,
            data: this.workflow.data,
            metaData: this.workflow.metaData
          }
          let res
          try {
            res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow`, cloneWorkflow, { showload: true })
            if (res) {
              this.$toastr.s(`The workflow '${cloneWorkflow.title}' was successfully created.`)
              this.$router.push('/automation/workflow/workflows')
            }
          } catch (e) {
            if (e.response && e.response.data && e.response.data.errorMessage) {
              this.$snapbar.e(`Error cloning workflow.  The workflow was not cloned. ${e.response.data.errorMessage}`)
            } else {
              this.$snapbar.e('Error cloning workflow.  The workflow was not cloned.')
            }
          }
        }
      }
    },
    async btnTransferWorkflow () {
      // Get list of customers
      const customers = this.$store.getters.customersOrdered
      let inputOptions = {}
      // Munge the customer data into something that the SweetAlert will use.
      // Probably a nicer way to do this but it works for now
      let str = '{ '
      customers.forEach(el => {
        const name = el.name.replace(/[^0-9a-z-A-Z ]/g, '').replace(/ +/, ' ')
        const token = `"${el.paygateId}": "${name}", `
        str = str + token
      })
      str = str.substring(0, str.length - 2)
      str = str + '}'
      inputOptions = JSON.parse(str)

      const result = await swal.fire({
        title: 'Copy Workflow To Another Customer',
        text: 'Copy the current workflow to another customer.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        input: 'select',
        inputOptions: inputOptions
      })
      if (result.isConfirmed) {
        // paygateId of the recipient customer is in result.value
        console.log('selected: ' + result.name)
        const transferWorkflow = {
          title: this.workflow.metaData.title,
          paygateId: result.value,
          data: this.workflow.data,
          metaData: this.workflow.metaData,
          transfer: true
        }
        // cloneMapping.metaData.title = cloneMapping.title
        console.log(transferWorkflow)
        let res
        try {
          res = await axios.post(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow`, transferWorkflow, { showload: true })
          if (res) {
            this.$toastr.s(`The workflow '${transferWorkflow.title}' was successfully transferred.`)
          }
        } catch (e) {
          if (e.response && e.response.data && e.response.data.errorMessage) {
            this.$snapbar.e(`Error transferring workflow.  The workflow was not transferred. ${e.response.data.errorMessage}`)
          } else {
            this.$snapbar.e('Error transferring workflow.  The workflow was not transferred.')
          }
        }
      }
    },
    async btnDeleteWorkflow () {
      const result = await swal.fire({
        title: 'Are you sure you want to permanently delete this workflow?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes - Delete the workflow',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        this.$snapbar.hide()
        let res
        try {
          res = await axios.delete(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/${this.id}`, { showload: true })
          if (res) {
            this.$toastr.s(`The workflow '${this.workflow.title}' was successfully deleted.`)
            this.$router.push('/automation/workflow/workflows')
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete this Workflow.')
          } else {
            if (e.response && e.response.data && e.response.data.errorMessage) {
              this.$snapbar.e(`Could not delete this Workflow - ${e.response.data.errorMessage}`)
            } else {
              this.$snapbar.e(`Could not delete this Workflow - ${e.message}`)
            }
          }
        }
      }
    },
    btnEditWorkflow () {
      this.$router.push(`/automation/workflow/designer/${this.id}`)
    },
    reformatType (type) {
      if (type === 'bureauCustomerImporter') return 'Bureau Customer Importer'
      if (type === 'bacs') return 'BACS'
      if (type === 'fps') return 'Faster Payments'
      if (type === 'ukBureau') return 'UK BACS Bureau'
      return 'Other'
    },
    reformatTags (t) {
      // Simple visual reformat from ["one","two"] to one, two
      let niceTags = ''
      if (t) {
        for (const tag of t) {
          niceTags += `<b-badge pill variant="info">${tag}</b-badge>`
        }
      }
      return niceTags
      // const nt = JSON.stringify(t)
      // if (nt) {
      //   console.log(nt)
      //   let ntr = nt.replace(/"/g, '')
      //   // eslint-disable-next-line
      //   ntr = ntr.replace(/\,/g, ', ')
      //   ntr = ntr.replace(/\[/g, '')
      //   ntr = ntr.replace(/\]/g, '')
      //   console.log(ntr)
      //   return ntr
      // } else {
      //   return ''
      // }
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!

      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    justDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      return dd + '/' + mm + '/' + yyyy
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    millisecToSec (ms) {
      const s = (ms / 1000).toFixed(2)
      return s
    },
    async getData () {
      let res
      try {
        if (res) {
          res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}triggertelemetry/${this.id}`)
          this.totalRecords = res.data.resObj.count
          this.row_data = res.data.workflowInstance
          this.vgtLoading = false
        }
      } catch (e) {
        this.canViewTelemetry = false
      }
    },
    onRowClick (params) {
    },
    onCellClick (params) {
      if (params.column.field !== 'workflowId') {
        this.$router.push(`/automation/workflow/workflowtelemetry/${params.row.executionId}`)
      }
    }
  },
  created: async function () {
    this.vgtLoading = true
    this.heatmapLoading = true
    this.workflowIsLoaded = true

    await this.getWorkflow()
    await this.load()
    await this.getHeatmapData()
    await this.getWorkflowMetrics()
    await this.getBoundTriggers()

    const qs = this.$route.query.d
    if (qs) {
      const qsDate = new Date(parseInt(qs))
      this.targetDay = qsDate
      this.showAll = false
    }
    this.defaultParams = JSON.parse(JSON.stringify(this.serverParams))

    // Check if there are any in there?
    const savedParams = this.$store.getters.tableFilters('triggerTelemetry')
    if (savedParams) {
      // If there are, apply them!
      this.savedParamsToObjects(savedParams)
      this.updateParams(savedParams)
    }
    this.doneParams = true
  },
  watch: {
    corvidSelectedPaygateId: async function (val) {
      this.$router.push('/automation/workflow/workflows')
    }
  }
}
</script>

<style scoped>
  .fadein-enter
  {
    opacity: 0;
  }
  .fadein-enter-active {
    transition: opacity .6s;
  }
  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }
  td {
    cursor: pointer;
  }

.vch__day__square {
  fill: rgb(255,0,255)
}

.vch__day__square .has-tooltip {
  fill: rgb(255,255,0)
}
.vue-tooltip-theme.tooltip .tooltip-inner {
  background: rgba(255, 0, 0, .7);
  color: #333333;
}

  /* .vue-tooltip-theme.tooltip .tooltip-inner {
    background: rgba(255, 0, 0, .7);
    border-radius: 3px;
    color: #FF0000;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    line-height: 16px;
    padding: 14px 10px;
  } */

/* svg.vch__wrapper .vch__months__labels__wrapper text.vch__month__label,
  svg.vch__wrapper .vch__days__labels__wrapper text.vch__day__label,
  svg.vch__wrapper .vch__legend__wrapper text {
    fill: #0000ff;
  } */

  /* svg.vch__wrapper rect.vch__day__square:hover {
    stroke: #ff0000;
    stroke-width: 1px;
  } */

  /* .vch__day__square {
    fill: #ff0000;
  } */
  textarea {
    resize: none;
  }
</style>
