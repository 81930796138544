var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("input", {
      ref: "input",
      attrs: {
        type: _vm._type,
        name: _vm.name,
        id: _vm.labelId,
        disabled: _vm._disabled,
        required: _vm._required
      },
      domProps: { checked: _vm.shouldBeChecked, value: _vm.value },
      on: { change: _vm.updateInput }
    }),
    _c(
      "div",
      { class: _vm.onClasses },
      [
        _vm._t("extra"),
        _vm.labelId === undefined || _vm.labelId === null || _vm.labelId === ""
          ? _c(
              "label",
              { attrs: { for: _vm.hiddenInputId } },
              [_vm._t("default")],
              2
            )
          : _c("label", { attrs: { for: _vm.hiddenInputId } }, [
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.checked ? "On" : "Off"))
              ])
            ])
      ],
      2
    ),
    _vm._toggle
      ? _c(
          "div",
          { class: _vm.offClasses },
          [_vm._t("off-extra"), _vm._t("off-label")],
          2
        )
      : _vm._e(),
    _vm._hover
      ? _c(
          "div",
          { class: _vm.hoverClasses },
          [_vm._t("hover-extra"), _vm._t("hover-label")],
          2
        )
      : _vm._e(),
    _vm._indeterminate
      ? _c(
          "div",
          { class: _vm.indeterminateClasses },
          [_vm._t("indeterminate-extra"), _vm._t("indeterminate-label")],
          2
        )
      : _vm._e(),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.checked,
          expression: "checked"
        }
      ],
      staticClass: "sr-only",
      attrs: { "aria-hidden": "true", type: "hidden", id: _vm.hiddenInputId },
      domProps: { value: _vm.checked },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.checked = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }