<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>
                <help-icon docPath="/paygate-collections/submissions/" />
                Process Submissions: {{ title }}
              </h2>
            </div>
            <div class="idb-block-content">
              <b-container>
                <div v-if="!detailsLoaded">Loading Submission Details</div>
                <transition name="fade">
                  <div
                    v-if="mode === 'collection' && showFlaggedCard && flaggedPaymentCount > 0 && detailsLoaded"
                  >
                    <b-card
                      bg-variant="info"
                      text-variant="white"
                      title="Payments Flagged For Review"
                      style="margin-bottom: 0.8em"
                    >
                      <p class="card-text" style="margin-bottom: 1.5em">
                        {{ flaggedPaymentCount }} payment(s) have been flagged for review in this submission.
                        <br />Invalid payments will not be sent with the current submission.
                        <br />
                      </p>
                      <router-link
                        :to="{ name: 'FlaggedSubmissions', params: { groupId: groupId } }"
                      >
                        <b-button variant="primary">View Flagged Payments</b-button>
                      </router-link>&nbsp;&nbsp;
                      <b-button variant="warning" @click="showFlaggedCard = false">Dismiss</b-button>
                    </b-card>
                  </div>
                </transition>
                <b-row>Process a Direct Debit Submission</b-row>
                <b-row class="mt-20">
                  <b-col sm="4">
                    <strong>Group:</strong>
                    {{group}}
                  </b-col>
                  <b-col sm="4">
                    <strong>Current Processing Date:</strong>
                    {{formatDate(new Date(processingDate))}}
                  </b-col>
                  <b-col sm="4">
                    <strong>Current Collection Date:</strong>
                    {{formatDate(new Date(collectionDate))}}
                  </b-col>
                </b-row>
                <b-row class="mt-20">
                  <b-form-group
                    :label-cols="5"
                    class="col-sm-5"
                    label-class="required"
                    label="Submission Title"
                  >
                    <b-form-input
                      type="text"
                      class="form-control"
                      id="submissionTitleInput"
                      v-model="submissionTitle"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="mode ==='collection'"
                    :label-cols="5"
                    class="col-sm-5"
                    label="Contra Narrative"
                  >
                    <b-form-input
                      type="text"
                      class="form-control"
                      id="contraNarrativeInput"
                      v-model="contraNarrative"
                    />
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col md="2">
                    <b-button
                      variant="primary"
                      :disabled="!canValidate || isLoading"
                      @click="processSubmission"
                    >Validate Submission</b-button>
                  </b-col>
                  <b-col md="2">
                    <b-button
                      variant="primary"
                      :disabled="this.presubValResult === 'Fixes' || this.presubValResult === '' || isLoading"
                      @click="confirmProcessSubmission"
                    >Process Submission</b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-20" v-show="saveStarted && progress < 100">
                  <b-col md="12">
                    {{stage}}
                    <b-progress :value="progress" :max="max" show-progress class="mb-3"></b-progress>
                  </b-col>
                </b-row>

                <b-row v-if="totalRecords > 0" class="mt-20">
                  <b-col md="12">
                    <vue-good-table
                      ref="table"
                      mode="remote"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-per-page-change="onPerPageChange"
                      @on-column-filter="onColumnFilter"
                      :columns="columns"
                      :rows="rows"
                      :lineNumbers="true"
                      :totalRows="totalRecords"
                      :paginationOptions="paginationOptions"
                      :sort-options="sortOptions"
                      styleClass="vgt-table striped bordered"
                      :isLoading.sync="isTableLoading"
                    >
                      <template slot="loadingContent">
                        <h1>Loading...</h1>
                      </template>
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'messageSeverity'">
                          <b-badge
                            :variant="getSeverityClass(props.row.messageSeverity)"
                            pill
                          >{{props.row.messageSeverity}}</b-badge>
                        </span>
                        <span v-else-if="props.column.field === 'itemId'">
                          <span>
                            <a :href="'/payer/' + props.row.itemId + '/edit'">View Payer</a>
                          </span>
                        </span>
                        <span v-else>{{props.formattedRow[props.column.field]}}</span>
                      </template>
                      <div slot="table-actions">
                        <button
                          @click.prevent="clearTableFilters"
                          class="btn btn-link"
                          v-b-popover.hover.top.d500="'Clear filters'"
                          :disabled="isLoading"
                        >
                          <span class="fa-stack" style="font-size: 10px;">
                            <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                            <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                          </span><span class="sr-only">Clear filters</span>
                        </button>
                        <b-button
                          @click.prevent="load"
                          class
                          variant="link"
                          v-b-popover.hover.top.d500="'Refresh the data in the table'"
                          :disabled="isLoading"
                        >
                          <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                        </b-button>
                        <b-button
                          @click.prevent="exportTable"
                          class
                          variant="link"
                          v-b-popover.hover.top.d500="'Export the contents of the table'"
                          :disabled="isLoading"
                        >
                          <i class="fa fa-share-square pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
                        </b-button>
                      </div>
                    </vue-good-table>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import moment from 'moment'
import axios from 'axios'
import papa from 'papaparse'
import swal from 'sweetalert2'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
export default {
  mixins: [tableFilterMixin, loading],
  async created () {
    await this.$store.dispatch('getPaymentDetails', this.groupId)
  },
  data () {
    return {
      showFlaggedCard: true,
      submissionTitle: '',
      validationMessages: [],
      contraNarrative: '',
      submissionId: '',
      progress: 0,
      validationStarted: false,
      saveStarted: false,
      showPresubValMessages: false,
      max: 100,
      columns: [
        {
          label: 'Record No.',
          field: 'recordNumber',
          type: 'number'
        },
        {
          label: 'Severity',
          field: 'messageSeverity',
          tdClass: 'text-center'
        },
        {
          label: 'Title',
          field: 'title'
        },
        {
          label: 'Description',
          field: 'description'
        },
        {
          label: 'Area',
          field: 'presubValArea',
          type: 'number'
        },
        {
          field: 'itemId',
          type: 'string'
        }
      ],
      presubValResult: '',
      showPresubValResult: true,
      rows: [],
      serverParams: {
        submissionId: '',
        showPresubValMessages: this.showPresubValMessages,
        sort: [{ field: 'messageseverity', type: 'asc' }]
      },
      submissionData: {},
      checkInterval: null,
      checkInterval2: null,
      subCompleted: false,
      hubUrl: null,
      subStatusConnection: null,
      stage: ''
    }
  },
  props: {
    flagged: Array,
    groupId: String,
    mode: String
  },
  computed: {
    flaggedPaymentCount () {
      return this.$store.getters.paymentDetails.flaggedPaymentCount
    },
    detailsLoaded () {
      return this.$store.getters.paymentDetails.detailsLoaded
    },
    canValidate () {
      return this.submissionTitle.length > 0 && (this.saveStarted || this.detailsLoaded)
    },
    title () {
      return this.mode === 'collection' ? 'Collections' : 'AUDDIS'
    },
    group: {
      get () {
        return this.$store.getters.paymentDetails.groupName
      }
    },
    collectionDate: {
      get () {
        return this.$store.getters.paymentDetails.collectionDate
      }
    },
    processingDate: {
      get () {
        return this.$store.getters.paymentDetails.processingDate
      }
    },
    defaultContraNarrative: {
      get () {
        return this.$store.getters.paymentDetails.defaultContraNarrative
      },
      set (val) {
        this.contraNarrative = val
      }
    },
    progressUrl () {
      return this.$store.getters.bacsUrl + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionId
    },
    endTaskUrl () {
      return this.$store.getters.bacsUrl + 'bacs/submission/EndSaveTask?submissionId=' + this.submissionId
    },
    downloadUrl () {
      return this.$store.getters.bacsUrl + 'bacs/submission/getPresubValidationResultAndMessages/'
    },
    saveUrl () {
      return this.$store.getters.bacsUrl + 'bacs/submission/runSaveSubmission/'
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.checkInterval != null) {
      clearInterval(this.checkInterval)
    }
    if (this.checkInterval2 != null) {
      clearInterval(this.checkInterval2)
    }
    next()
  },
  mounted () {
    this.contraNarrative = this.defaultContraNarrative
    this.hubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/buildsubmission'
    if (this.groupId) {
      this.hubUrl += '?groupId=' + this.groupId
    }
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.subStatusConnection = new HubConnectionBuilder().withUrl(this.hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.subStatusConnection.start()
        this.subStatusConnection.on('UpdateSubmissionBuildProgress', data => {
          this.stage = 'Building Submission'
          this.progress = data
        })
      })
  },
  methods: {
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async processSubmission () {
      this.stage = ''
      this.saveStarted = true
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/${this.groupId}/validate/`, { groupId: this.groupId, title: this.submissionTitle, contraNarrative: this.contraNarrative, mode: this.mode, paygateId: this.$store.state.common.paygateId })
        .then(async (response) => {
          this.submissionData = response.data
          this.submissionId = this.submissionData.submissionId
          this.stage = 'Validating Submission'
          axios({
            method: 'POST',
            url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/runPresubValidation/',
            data: this.submissionData
          })

          var id = setInterval(() => {
            return axios.get(this.progressUrl, {
            })
              .then(response => {
                this.progress = response.data
                if (this.progress >= 100) {
                  clearInterval(id)
                  this.validationStarted = false
                  this.stage = ''
                  return axios.get(this.endTaskUrl, {
                  })
                    .then(() => {
                      setTimeout(() => {
                        this.load()
                      }, 1000)
                    })
                } else if (this.progress === -1) {
                  clearInterval(id)
                  this.validationStarted = false
                }
              })
              .catch(error => console.log(error))
          }, 1000)
        }).catch((err) => { console.log(err) })
    },
    confirmProcessSubmission () {
      axios({
        method: 'POST',
        url: this.saveUrl,
        data: { ...this.submissionData, createAction: true }
      })

      this.checkInterval2 = setInterval(() => {
        this.stage = 'Submitting'
        return axios.get(this.progressUrl, {
        })
          .then(response => {
            this.progress = response.data
            if (response.data >= 100) { // temp at 75 as EF issue is happening. 🔥🔥🔥
              clearInterval(this.checkInterval2)
              return axios.get(this.endTaskUrl, {
              })
                .then(response => {
                  this.stage = ''
                  if (response.data === '') {
                    this.$toastr.s('BACS submission created')
                    setTimeout(() => {
                      axios.post(`${process.env.VUE_APP_DDMS_API_URL}submissions/archivepayments/` + this.submissionId, {}, { showload: true }).then(() => {
                        this.subCompleted = true
                        this.$store.dispatch('updateCurrentCollectionsSubmissionCount')
                        this.$router.push({ path: '/collections/submissions/' })
                        // this.$toastr.s('BACS submission archived!!!!!')
                      })
                    }, 1000)
                  } else {
                    this.saveStarted = false
                    this.progress = 0
                    this.$swal('Save Failed.', response.data, 'error')
                  }
                })
            }
          })
          .catch(error => console.log(error))
      }, 1000)
    },
    load () {
      this.serverParams.submissionId = this.submissionId
      var json = JSON.stringify({
        submissionId: this.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: false,
        fileNumber: 1,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: false
      })

      return axios({
        method: 'POST',
        url: this.downloadUrl,
        data: json,
        showload: true
      }).then(response => {
        this.presubValResult = response.data.presubValResult
        this.rows = response.data.pagedDataResponse.data
        this.totalRecords = response.data.pagedDataResponse.count

        if (this.showPresubValResult === true) {
          if (this.presubValResult === 'Valid') {
            this.$toastr.s('All payments are valid.')
          } else if (this.presubValResult === 'Warnings') {
            this.$snapbar.w('Validation has identified some payments which may have an issue. Resolving these issues is optional')
          } else if (this.presubValResult === 'Fixes') {
            this.$swal('Payment Validation.', 'Some of the payments require fixing. You must edit these payments before continuing.', 'error')
          }
          this.showPresubValResult = false
        }
      })
    },
    getSeverityClass (severity) {
      severity = severity.toLowerCase()
      switch (severity) {
        case 'fix':
        case 'fixes':
          severity = 'danger'
          break
        case 'warning':
        case 'warnings':
        case 'duplicate':
          severity = 'warning'
          break
        default:
          break
      }
      return severity
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      this.serverParams.submissionId = this.submissionData.submissionId
      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        page: 1,
        perPage: this.totalRecords,
        showPresubValMessages: false,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: false
      })
      axios({
        method: 'POST',
        url: this.downloadUrl,
        data: json,
        showload: true
      }).then(response => {
        var rows = response.data.pagedDataResponse.data
        var csvString = papa.unparse(rows)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, this.submissionData.submissionId + '-validation-messages.csv')
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = this.submissionData.submissionId + '-validation-messages.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    sload () {
      this.serverParams.submissionId = this.submissionData.submissionId

      var json = JSON.stringify({
        submissionId: this.submissionData.submissionId,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        showPresubValMessages: false,
        fileNumber: this.fileNumber,
        sortColumn: this.serverParams.sort.field,
        sortDirection: this.serverParams.sort.type,
        resetStart: false
      })

      return axios({
        method: 'POST',
        url: this.downloadUrl,
        data: json,
        showload: true
      })
        .then(response => {
          this.presubValResult = response.data.presubValResult
          this.rows = response.data.pagedDataResponse.data
          this.totalRecords = response.data.pagedDataResponse.count

          if (this.showPresubValResult === true) {
            this.showPresubValResult = false
          }
        })
    },
    async leaving () {
      var result = null
      try {
        result = await swal.fire({
          title: 'Navigation',
          text: 'Your submission details will be lost. Are you sure want to navigate away from this page?',
          type: 'warning',
          animation: false,
          showCancelButton: true,
          cancelButtonClass: 'btn btn-light',
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          confirmButtonClass: 'btn btn-danger'
        })
      } catch (err) {
        console.log(err)
        result = false
      }
      return result
    }
  },
  async beforeRouteLeave (to, from, next) {
    try {
      if (!this.subCompleted) {
        const result = await this.leaving()
        if (result) {
          if (result.isConfirmed) {
            next()
          }
        } else {
          next(false)
        }
      } else {
        next()
      }
    } catch (e) {
    }
  }
}
</script>
<style>
.mandatory {
  color: red;
  padding-left: 10px;
}
.alertColumnHeader {
  text-align: right;
  font-weight: bold;
}
.alertKey {
  text-align: left;
  font-weight: bold;
}
.alertValueLeft {
  text-align: left;
}
.alertValueRight {
  text-align: right;
}
.Fixes,
.Fix {
  background-color: rgb(243, 102, 102);
  padding: 10px 39px 10px 38px;
}
.Warnings,
.Warning {
  background-color: rgb(241, 196, 112);
  padding: 10px 19px 10px 18px;
}
.Duplicate {
  background-color: rgb(241, 196, 112);
  padding: 10px 15px 10px 14px;
}
.Info {
  background-color: lightskyblue;
  padding: 10px 36px 10px 35px;
}
</style>
