var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "submissionHistory" } }, [
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("vue-good-table", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.rows,
              lineNumbers: true,
              "pagination-options": {
                enabled: true
              },
              "sort-options": {
                enabled: false
              },
              styleClass: "vgt-table striped bordered"
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "userId"
                      ? _c(
                          "span",
                          [
                            _c("avatar", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  value: props.row.Username,
                                  expression: "props.row.Username",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "rounded-circle",
                              attrs: {
                                width: "30",
                                height: "30",
                                title: props.row.Username,
                                "user-id": props.row.userId
                              }
                            })
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(_vm._s(props.formattedRow[props.column.field]))
                        ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }