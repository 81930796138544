var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c(
            "div",
            { staticClass: "idb-block-content" },
            _vm._l(_vm.licences, function(licence) {
              return _c("div", { key: licence }, [
                licence == _vm.licenceConstanst.validate_iban
                  ? _c("div", [
                      _c("div", { staticClass: "alert alert-warning" }, [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v(
                            _vm._s(_vm.subjectText) +
                              " not licensed for IBAN validation"
                          )
                        ]),
                        _c("hr"),
                        _c("p", [
                          _vm._v(
                            "Please contact your paygate account manager if you would like to purchase this feature."
                          )
                        ])
                      ])
                    ])
                  : licence == _vm.licenceConstanst.validate_modcheck
                  ? _c("div", [
                      _c("div", { staticClass: "alert alert-warning" }, [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v(
                            _vm._s(_vm.subjectText) +
                              " not licensed for Bank Account validation"
                          )
                        ]),
                        _c("hr"),
                        _c("p", [
                          _vm._v(
                            "Please contact your paygate account manager if you would like to purchase this feature."
                          )
                        ])
                      ])
                    ])
                  : licence == _vm.licenceConstanst.validate_cc
                  ? _c("div", [
                      _c("div", { staticClass: "alert alert-warning" }, [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v(
                            _vm._s(_vm.subjectText) +
                              " not licensed for Credit Card validation"
                          )
                        ]),
                        _c("hr"),
                        _c("p", [
                          _vm._v(
                            "Please contact your paygate account manager if you would like to purchase this feature."
                          )
                        ])
                      ])
                    ])
                  : licence === _vm.licenceConstanst.mfa_usb_tokens
                  ? _c("div", [
                      _c("div", { staticClass: "alert alert-warning" }, [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v(
                            _vm._s(_vm.subjectText) +
                              " not licensed to use USB Tokens"
                          )
                        ]),
                        _c("hr"),
                        _c("p", [
                          _vm._v(
                            "USB Tokens allow you to progress actions and submissions via a secure, USB-based, token."
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            "Please contact your paygate account manager if you would like to purchase this feature."
                          )
                        ])
                      ])
                    ])
                  : licence === _vm.licenceConstanst.hsm
                  ? _c("div", [
                      _c("div", { staticClass: "alert alert-warning" }, [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v(
                            _vm._s(_vm.subjectText) +
                              " not licensed to use HSM certificates"
                          )
                        ]),
                        _c("hr"),
                        _c("p", [
                          _vm._v(
                            "HSM certificates allow you to securely sign payments."
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            "Please contact your paygate account manager if you would like to purchase this feature."
                          )
                        ])
                      ])
                    ])
                  : licence == _vm.licenceConstanst.bureau
                  ? _c("div", [
                      _vm._v(
                        _vm._s(_vm.subjectText) +
                          " not licenced for paygate bureau"
                      )
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }