var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6",
              attrs: { "label-cols": 5, horizontal: "", label: "Title" }
            },
            [
              _c("b-form-select", {
                staticClass: "form-control",
                attrs: {
                  options: _vm.titles,
                  "value-field": "ordinal",
                  "text-field": "name",
                  disabled: !_vm.editable
                },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6 mr-5",
              attrs: {
                "label-cols": 5,
                "label-class": "required",
                horizontal: "",
                label: "First Name"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", required: "", disabled: !_vm.editable },
                model: {
                  value: _vm.firstName,
                  callback: function($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName"
                }
              }),
              _vm.$v.firstName.$dirty
                ? _c("b-col", [
                    _vm.$v.firstName.$invalid
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v("First Name required")
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6",
              attrs: {
                "label-cols": 5,
                "label-class": "required",
                horizontal: "",
                label: "Surname"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", disabled: !_vm.editable },
                model: {
                  value: _vm.surname,
                  callback: function($$v) {
                    _vm.surname = $$v
                  },
                  expression: "surname"
                }
              }),
              _vm.$v.surname.$dirty
                ? _c("b-col", [
                    _vm.$v.surname.$invalid
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v("Surname required")
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6 mr-5",
              attrs: {
                "label-cols": 5,
                horizontal: "",
                label: "Telephone Number 1"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", disabled: !_vm.editable },
                model: {
                  value: _vm.tel1,
                  callback: function($$v) {
                    _vm.tel1 = $$v
                  },
                  expression: "tel1"
                }
              }),
              _vm.$v.tel1.$invalid
                ? _c("b-col", [
                    _c("label", { staticClass: "text-danger small" }, [
                      _vm._v("Valid telephone number required")
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6",
              attrs: {
                "label-cols": 5,
                horizontal: "",
                label: "Telephone Number 2"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", disabled: !_vm.editable },
                model: {
                  value: _vm.tel2,
                  callback: function($$v) {
                    _vm.tel2 = $$v
                  },
                  expression: "tel2"
                }
              }),
              _vm.$v.tel2.$invalid
                ? _c("b-col", [
                    _c("label", { staticClass: "text-danger small" }, [
                      _vm._v("Valid telephone number required")
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6 mr-5",
              attrs: {
                "label-cols": 5,
                "label-class": _vm.messageChannel === 0 ? "required" : "",
                horizontal: "",
                label: "Email Address"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", disabled: !_vm.editable },
                on: {
                  input: function($event) {
                    return _vm.checkEmail(_vm.$v.email)
                  },
                  keydown: function($event) {
                    _vm.existingEmailUsers = false
                  }
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _vm.$v.email.$dirty
                ? _c("b-col", [
                    !_vm.$v.email.emailValidator && _vm.messageChannel === 0
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v("Provide a valid Email Address")
                        ])
                      : _vm._e(),
                    !_vm.$v.email.emailRequired && _vm.messageChannel === 0
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v("Email Address required")
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.existingEmailUsers
                ? _c("b-col", [
                    _c("label", { staticClass: "text-warning small" }, [
                      _vm._v(
                        " Users with this email are already in this group. Is this correct? "
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: _vm.pageInstanceId,
                              expression: "pageInstanceId"
                            }
                          ],
                          staticClass: "dupeLink"
                        },
                        [_vm._v("View duplicates.")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.shareCount > 0 &&
              !_vm.existingEmailUsers &&
              _vm.hasPortalAccount
                ? _c("b-col", [
                    _c("label", { staticClass: "text-warning small" }, [
                      _vm._v(
                        " This email address is shared with " +
                          _vm._s(_vm.shareCount) +
                          " payers. The updated email address will be reflected across all the duplicates. "
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: _vm.pageInstanceId,
                              expression: "pageInstanceId"
                            }
                          ],
                          staticClass: "dupeLink"
                        },
                        [_vm._v("View duplicates.")]
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6",
              attrs: {
                "label-cols": 5,
                "label-class": _vm.messageChannel === 2 ? "required" : "",
                horizontal: "",
                label: "Mobile Number"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", disabled: !_vm.editable },
                model: {
                  value: _vm.mobile,
                  callback: function($$v) {
                    _vm.mobile = $$v
                  },
                  expression: "mobile"
                }
              }),
              false
                ? _c("b-col", [
                    _vm.smsVerifiedAndUnchanged
                      ? _c("label", { staticClass: "small" }, [
                          _c("i", {
                            staticClass: "fa fa-lock",
                            staticStyle: { color: "green" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Mobile verified.")])
                        ])
                      : _c("label", { staticClass: "small" }, [
                          _c("i", { staticClass: "fa fa-unlock" }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Mobile unverified.")])
                        ])
                  ])
                : _vm._e(),
              _vm.$v.mobile.$invalid
                ? _c("b-col", [
                    _c("label", { staticClass: "text-danger small" }, [
                      _vm._v("Mobile number required")
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "form-control",
                      attrs: {
                        variant: "info",
                        disabled:
                          _vm.$v.mobile.$model === "" ||
                          _vm.$v.mobile.$model === null ||
                          _vm.$v.mobile.$model === undefined ||
                          _vm.$v.mobile.$invalid ||
                          !_vm.editable ||
                          _vm.smsVerifiedAndUnchanged ||
                          _vm.isLoading
                      },
                      on: { click: _vm.sendMobileVerification }
                    },
                    [
                      _vm.verificationSmsSent
                        ? _c("span", [_vm._v("Re-send SMS Verification")])
                        : _c("span", [_vm._v("Send SMS Verification")])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.verificationSmsSent
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-sm-6",
                      attrs: {
                        "label-cols": 5,
                        horizontal: "",
                        label: "Verification Token"
                      }
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          disabled: !_vm.verificationSmsSent
                        },
                        model: {
                          value: _vm.verifyToken,
                          callback: function($$v) {
                            _vm.verifyToken = $$v
                          },
                          expression: "verifyToken"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "form-control",
                          attrs: {
                            variant: "primary",
                            disabled: !_vm.verificationSmsSent || _vm.isLoading
                          },
                          on: { click: _vm.checkMobileVerification }
                        },
                        [_vm._v("Verify")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.addressesSet || !!this.address1
          ? _c(
              "div",
              [
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-sm-6",
                        attrs: {
                          "label-cols": 5,
                          "label-class":
                            _vm.messageChannel === 1 ? "required" : "",
                          horizontal: "",
                          label: "Address"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            required: "",
                            disabled: !_vm.editable
                          },
                          model: {
                            value: _vm.address1,
                            callback: function($$v) {
                              _vm.address1 = $$v
                            },
                            expression: "address1"
                          }
                        }),
                        _vm.$v.address1.$dirty && _vm.$v.address1.letterRequired
                          ? _c("b-col", [
                              _vm.$v.address1.$invalid
                                ? _c(
                                    "label",
                                    { staticClass: "text-danger small" },
                                    [_vm._v("Address required")]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-sm-6",
                        attrs: {
                          "label-cols": 5,
                          horizontal: "",
                          label: "Address line 2"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: !_vm.editable },
                          model: {
                            value: _vm.address2,
                            callback: function($$v) {
                              _vm.address2 = $$v
                            },
                            expression: "address2"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-sm-6",
                        attrs: {
                          "label-cols": 5,
                          horizontal: "",
                          label: "Address line 3"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: !_vm.editable },
                          model: {
                            value: _vm.address3,
                            callback: function($$v) {
                              _vm.address3 = $$v
                            },
                            expression: "address3"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-sm-6",
                        attrs: {
                          "label-cols": 5,
                          horizontal: "",
                          label: "Address line 4"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: !_vm.editable },
                          model: {
                            value: _vm.address4,
                            callback: function($$v) {
                              _vm.address4 = $$v
                            },
                            expression: "address4"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-sm-6",
                        attrs: {
                          "label-cols": 5,
                          horizontal: "",
                          label: "Town"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: !_vm.editable },
                          model: {
                            value: _vm.town,
                            callback: function($$v) {
                              _vm.town = $$v
                            },
                            expression: "town"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-sm-6",
                        attrs: {
                          "label-cols": 5,
                          horizontal: "",
                          label: "County"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: !_vm.editable },
                          model: {
                            value: _vm.county,
                            callback: function($$v) {
                              _vm.county = $$v
                            },
                            expression: "county"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6",
              attrs: {
                "label-cols": 5,
                "label-class": _vm.messageChannel === 1 ? "required" : "",
                horizontal: "",
                label: "Postcode"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", required: "", disabled: !_vm.editable },
                on: {
                  input: function($event) {
                    _vm.addressOptions = []
                  }
                },
                model: {
                  value: _vm.postcode,
                  callback: function($$v) {
                    _vm.postcode = $$v
                  },
                  expression: "postcode"
                }
              }),
              !_vm.$v.postcode.letterRequired
                ? _c("b-col", [
                    _c("label", { staticClass: "text-danger small" }, [
                      _vm._v("Postcode required")
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "form-control",
                  attrs: {
                    variant: "info",
                    disabled:
                      _vm.$v.postcode.$model === "" ||
                      _vm.$v.postcode.$model === null ||
                      _vm.$v.postcode.$model === undefined ||
                      !_vm.editable ||
                      _vm.isLoading
                  },
                  on: { click: _vm.getAddress }
                },
                [_vm._v("Lookup Address")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.addressOptionsSelect.length > 0 && !this.addressLookupInProgress
            ? _c(
                "b-form-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-sm-6",
                      attrs: {
                        "label-cols": "5",
                        horizontal: "",
                        label: "Addresses"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.addressOptionsSelect,
                          disabled: !_vm.editable
                        },
                        on: {
                          change: function($event) {
                            _vm.addressesSet = true
                          }
                        },
                        model: {
                          value: _vm.selectedAddress,
                          callback: function($$v) {
                            _vm.selectedAddress = $$v
                          },
                          expression: "selectedAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.pageInstanceId,
            title: "Duplicate Emails for " + _vm.$v.email.$model,
            "ok-only": true
          }
        },
        [
          _c("p", [
            _vm._v(
              "Below is a list of Payers which share the address: " +
                _vm._s(_vm.$v.email.$model) +
                ", clicking on a row will navigate to the selected payer."
            )
          ]),
          _c(
            "table",
            { staticStyle: { width: "100%" } },
            [
              _c("tr", [
                _c("th", [_vm._v("Name")]),
                _c("th", [_vm._v("Status")]),
                _c("th", [_vm._v("Reference")])
              ]),
              _vm._l(_vm.duplicateEmails, function(payer, index) {
                return _c(
                  "tr",
                  { key: index, staticStyle: { cursor: "pointer" } },
                  [
                    _c("td", [_vm._v(_vm._s(payer.name))]),
                    _c("td", [_vm._v(_vm._s(_vm.statusToText(payer.status)))]),
                    _c("td", [_vm._v(_vm._s(payer.reference))])
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }