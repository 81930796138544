const state = {
  bureauJobDetailsReturnType: ''
}

// actions
const actions = {
  setSubmission ({ commit }, submission) {
    commit('setSubmission', submission)
  },
  setBureauJobId ({ commit }, bureauJobId) {
    commit('setBureauJobId', bureauJobId)
  },
  setBureauJob ({ commit }, bureauJob) {
    commit('setBureauJob', bureauJob)
  }
}

const mutations = {
  setBureauJobDetailsReturnType (state, returnType) {
    state.bureauJobDetailsReturnType = returnType
  },
  setBureauJobId (state, bureauJobId) {
    state.bureauJobId = bureauJobId
  },
  setBureauJob (state, bureauJob) {
    state.bureauJob = bureauJob
  }
}

const getters = {
  bureauJobDetailsReturnType: (state) => {
    return state.bureauJobDetailsReturnType
  },
  importSchemas: (state) => {
    return state.importSchemas
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
