<template>
  <div>
    <h3>Send Tweet<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/messaging/sendtweet/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>    <hr>
    <hr>
    <br/>
    <div class="textarea">
      <div class="form-group">
        <label class="">Message</label>
        <textarea class="form-control"
          rows="3"
          cols="40"
          :class="{invalid: $v.selectedNode.props.s1.value.$error}"
          @input="$v.selectedNode.props.s1.value.$touch()"
          v-model="selectedNode.props.s1.value">
        </textarea>
        <p class="validationText" v-if="!$v.selectedNode.props.s1.value.maxLength">Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p>
      </div>
    </div>
    <div class="form-group">
      <label class="required">Cunsumer Key</label>
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">Consumer Key field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="required">Consumer Secret</label>
      <input type="password"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.pw1.value.$error}"
        @input="$v.selectedNode.props.pw1.value.$touch()"
        v-model="selectedNode.props.pw1.value">
        <p class="validationText" v-if="!$v.selectedNode.props.pw1.value.required && $v.selectedNode.props.pw1.value.$dirty ">Field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.pw1.value.maxLength">Maximum length is {{ $v.selectedNode.props.pw1.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="required">Access Token Key</label>
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty ">Access Token Key field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="required">Access Token Secret</label>
      <input type="password"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.pw2.value.$error}"
        @input="$v.selectedNode.props.pw2.value.$touch()"
        v-model="selectedNode.props.pw2.value">
        <p class="validationText" v-if="!$v.selectedNode.props.pw2.value.required && $v.selectedNode.props.pw2.value.$dirty ">Field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.pw2.value.maxLength">Maximum length is {{ $v.selectedNode.props.pw2.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">
        Cancel
      </b-button>
      <b-button  class="" variant="success" @click.prevent="btnSave">
        OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  validations: {
    selectedNode: {
      props: {
        s1: {
          value: {
            required,
            maxLength: maxLength(256)
          }
        },
        s2: {
          value: {
            required,
            maxLength: maxLength(1024)
          }
        },
        s3: {
          value: {
            required,
            maxLength: maxLength(2048)
          }
        },
        pw1: {
          value: {
            required,
            maxLength: maxLength(2048)
          }
        },
        pw2: {
          value: {
            required,
            maxLength: maxLength(2048)
          }
        }
      }
    }
  },
  data () {
    return {
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
