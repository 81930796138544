var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.internalGroups
        ? _c("v-select", {
            attrs: {
              "aria-hidden": "true",
              id: _vm.id,
              label: "name",
              value: _vm.value,
              reduce: function(group) {
                return group.groupId
              },
              options: _vm.internalGroups,
              clearable: _vm.clearable,
              disabled: _vm.disabled
            },
            on: { input: _vm.emitInput },
            scopedSlots: _vm._u(
              [
                {
                  key: "selected-option",
                  fn: function(option) {
                    return [
                      _c("div", {
                        staticClass: "group-colour",
                        style: {
                          "background-color": _vm.getOptionColour(option)
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.getGroupName(option)))])
                    ]
                  }
                },
                {
                  key: "option",
                  fn: function(option) {
                    return [
                      _c("div", {
                        staticClass: "group-colour",
                        style: { "background-color": option.colour }
                      }),
                      _vm._v(" " + _vm._s(option.name) + " ")
                    ]
                  }
                },
                {
                  key: "no-options",
                  fn: function(ref) {
                    var search = ref.search
                    var searching = ref.searching
                    return [
                      _vm.groups == null
                        ? [
                            _c("i", { staticClass: "fas fa-spinner fa-pulse" }),
                            _vm._v(" Groups are loading ")
                          ]
                        : searching
                        ? [
                            _vm._v(" No results found for "),
                            _c("em", [_vm._v(_vm._s(search))]),
                            _vm._v(". ")
                          ]
                        : _c("em", { staticStyle: { opacity: "0.5" } }, [
                            _vm._v("Sorry, no groups available")
                          ])
                    ]
                  }
                }
              ],
              null,
              false,
              4017392030
            )
          })
        : _vm._e(),
      _vm.groups != null
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              staticClass: "sr-only",
              attrs: { id: _vm.id },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.value = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _vm.groups.length === 0
                ? _c("option", [_vm._v("Sorry, no groups are available")])
                : _vm._e(),
              _vm._l(_vm.groups, function(group) {
                return _c(
                  "option",
                  { key: group.groupId, domProps: { value: group.groupId } },
                  [_vm._v(_vm._s(_vm.getGroupName(group)))]
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }