import '@/Assets/themify-icons/themify-icons.css'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import '@fortawesome/fontawesome-free/css/all.css'
// import 'weather-icons/css/weather-icons.min.css'

// Animation css
import '@/Assets/Animate/font-awesome-animation.css'

/* bootstrap */
import '@/Assets/scss/style.scss'

/* CORVID css */
import '@/Assets/corvid.css'
