var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.displayValue,
          expression: "displayValue"
        }
      ],
      staticClass: "form-control",
      attrs: { id: _vm.id, type: "text", disabled: _vm.disabled },
      domProps: { value: _vm.displayValue },
      on: {
        blur: function($event) {
          _vm.isInputActive = false
        },
        focus: function($event) {
          _vm.isInputActive = true
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.displayValue = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }