export default {
  state: {
    socket: {
      isConnected: false,
      actions: [],
      reconnectError: false
    }
  },
  mutations: {
    ACTIONSSOCKET_ONOPEN (state, connection) {
      this.$socket = connection
      state.socket.isConnected = true
    },
    ACTIONSSOCKET_ONCLOSE (state, event) {
      state.socket.isConnected = false
    },
    ACTIONSSOCKET_ONERROR (state, event) {
      console.error(state, event)
    },
    ACTIONSSOCKET_ACTIONADDED (state, action) {
      state.socket.actions.push(action)
    },
    ACTIONSSOCKET_ACTIONREMOVED (state, actionId) {
      state.socket.actions = state.socket.actions.filter(a => a.id !== actionId)
    },
    ACTIONSSOCKET_INITIALACTIONS (state, actions) {
      state.socket.actions = actions.data
    },
    ACTIONSSOCKET_REFRESHACTIONS (state, actions) {
      state.socket.actions = actions.data
    },
    // mutations for reconnect methods
    ACTIONSSOCKET_RECONNECT (state, count) {
    },
    ACTIONSSOCKET_RECONNECT_ERROR (state) {
      state.socket.reconnectError = true
    }
  },
  getters: {
    actionsList: state => {
      return state.socket.actions.slice(0, 5)
    },
    actionListVariable: (state) => (amount) => {
      return state.socket.actions.slice(0, amount)
    },
    actionsCount: state => {
      return state.socket.actions.length
    }
  }
}
