<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 id="title-of-block-check">Weight Table Import</h2>
          </div>
          <div class="idb-block-content">
            <form novalidate v-on:submit.prevent class="col-md-12">
              <div class="form-group row">
                <label class="label-control col-md-3">Last Import Date</label>
                <span
                  class="form-control-static"
                >{{lastImportDate | formatDate('DD/MM/YYYY HH:mm')}}</span>
              </div>
            </form>

            <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
              <div class="dropbox">
                <input
                  type="file"
                  multiple
                  :name="uploadFieldName"
                  :disabled="isSaving"
                  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                  accept=".txt"
                  class="input-file"
                />
                <p v-if="isInitial">
                  Drag your file here to begin
                  <br />or click to browse
                </p>
                <p v-if="isSaving">Uploading {{ fileCount }} file...</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  name: 'WeightTableImport',
  data: function () {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'WeightTable',
      lastImportDate: null
    }
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    reset () {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    async save (formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING

      try {
        await axios.post(`${process.env.VUE_APP_VALIDATE_API_URL}Import/WeightTableImport`, formData, {
          showload: true,
          showerror: true,
          errormessage: 'Failed to import'
        })
        this.currentStatus = STATUS_SUCCESS
        this.$toastr.s('The Weight Table has been imported', 'Weight Table Import')
        await this.getWeightTableImportDate()
      } catch (err) {
        this.uploadError = err.response
        this.currentStatus = STATUS_FAILED
        this.$toastr.e('The Weight Table has failed importing', 'Weight Table Import')
      }
      this.reset()
    },
    filesChange (fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name)
        })

      // save it
      this.save(formData)
    },
    async getWeightTableImportDate () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}Import/WeightTableLastImportDate`, {
          showload: true,
          showerror: true,
          errormessage: 'Failed to get last import date'
        })
        this.lastImportDate = response.data
      } catch (err) {

      }
    }
  },
  mounted () {
    this.reset()
    this.getWeightTableImportDate()
  }
}
</script>
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
  &:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dark-mode {
  .dropbox {
    background: darkcyan !important;
    &:hover {
      background: rgb(86, 194, 230) !important;
    }
  }
}
</style>
