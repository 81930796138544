var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fillHeightContainer" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "mapContainer" },
      [
        _c(
          "b-container",
          {
            staticClass: "flex-grow",
            staticStyle: { "min-height": "100%", height: "100%" },
            attrs: { fluid: "" }
          },
          [
            _c(
              "b-row",
              {
                staticClass: "align-self-stretch d-flex flex-grow",
                staticStyle: { "min-height": "100%", height: "100%" }
              },
              [
                !_vm.loading
                  ? _c(
                      "b-col",
                      { staticClass: "growBox" },
                      [
                        _c(
                          "l-map",
                          {
                            ref: "postcodeMap",
                            staticClass: "mapBox",
                            attrs: {
                              zoom: _vm.zoom,
                              center: _vm.center,
                              options: _vm.mapOptions
                            },
                            on: {
                              "update:center": _vm.centerUpdate,
                              "update:zoom": _vm.zoomUpdate,
                              ready: _vm.mapReady
                            }
                          },
                          [
                            _c("l-tile-layer", {
                              attrs: {
                                url: _vm.baseLayer,
                                attribution: _vm.attribution
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.loading
                  ? _c("b-col", { staticClass: "growBox" }, [
                      _vm._v("Loading...")
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h4", { staticClass: "m-0" }, [_vm._v("Missed Payment Map")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }