var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Audit Details "),
            _c("help-icon", {
              attrs: { docPath: "/administration/customer/auditlogs#details" }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _c("form", [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "auditId" }
              },
              [_vm._v("Audit Tamper Detection")]
            ),
            !_vm.loading
              ? _c("div", { staticClass: "col-sm-10 form-control-plaintext" }, [
                  _vm.audit.imported
                    ? _c("div", [
                        _vm._v(
                          "The audit has been imported and is exempt from tamper protection"
                        )
                      ])
                    : _vm.audit.checksumValid
                    ? _c(
                        "div",
                        [
                          _c("tick", { attrs: { value: true } }),
                          _vm._v(" The audit has "),
                          _c("b", [_vm._v("not")]),
                          _vm._v(" been tampered with ")
                        ],
                        1
                      )
                    : !_vm.audit.checksumValid
                    ? _c(
                        "div",
                        [
                          _c("tick", { attrs: { value: false } }),
                          _vm._v(" The audit "),
                          _c("b", [_vm._v("has")]),
                          _vm._v(" been tampered with ")
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "auditId" }
              },
              [_vm._v("Audit ID")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.audit.auditId,
                    expression: "audit.auditId"
                  }
                ],
                staticClass: "form-control-plaintext",
                attrs: { type: "text", readonly: "", id: "auditId" },
                domProps: { value: _vm.audit.auditId },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.audit, "auditId", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "auditCode" }
              },
              [_vm._v("Audit Code")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.audit.auditCode,
                    expression: "audit.auditCode"
                  }
                ],
                staticClass: "form-control-plaintext",
                attrs: { type: "text", readonly: "", id: "auditCode" },
                domProps: { value: _vm.audit.auditCode },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.audit, "auditCode", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "auditSeverity" }
              },
              [_vm._v("Audit Severity")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.audit.severity,
                    expression: "audit.severity"
                  }
                ],
                staticClass: "form-control-plaintext",
                attrs: { type: "text", readonly: "", id: "auditSeverity" },
                domProps: { value: _vm.audit.severity },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.audit, "severity", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "auditType" }
              },
              [_vm._v("Audit Type")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.audit.auditType,
                    expression: "audit.auditType"
                  }
                ],
                staticClass: "form-control-plaintext",
                attrs: { type: "text", readonly: "", id: "auditType" },
                domProps: { value: _vm.audit.auditType },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.audit, "auditType", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "auditTitle" }
              },
              [_vm._v("Audit Title")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.audit.auditTitle,
                    expression: "audit.auditTitle"
                  }
                ],
                staticClass: "form-control-plaintext",
                attrs: { type: "text", readonly: "", id: "auditTitle" },
                domProps: { value: _vm.audit.auditTitle },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.audit, "auditTitle", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "auditBody" }
              },
              [_vm._v("Audit Body")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c(
                "p",
                {
                  staticClass: "form-control-plaintext",
                  attrs: { readonly: "", id: "extraInfo" }
                },
                [_vm._v(_vm._s(_vm.audit.auditBody))]
              )
            ])
          ]),
          _vm.extraInfo.some(function(s) {
            return true
          })
            ? _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 text-right col-form-label",
                    attrs: { for: "extraInfo" }
                  },
                  [_vm._v("Extra Info")]
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  _vm._l(_vm.extraInfo, function(info) {
                    return _c(
                      "p",
                      {
                        key: info,
                        staticClass: "form-control-plaintext",
                        attrs: { readonly: "", id: "extraInfo" }
                      },
                      [_vm._v(_vm._s(info))]
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "createdDate" }
              },
              [_vm._v("Created Date")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c(
                "span",
                {
                  staticClass: "form-control-plaintext",
                  attrs: { type: "text", readonly: "", id: "createdDate" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatDate")(
                        _vm.audit.createdDate,
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    )
                  )
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 text-right col-form-label",
                attrs: { for: "username" }
              },
              [_vm._v("Username")]
            ),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.audit.username,
                    expression: "audit.username"
                  }
                ],
                staticClass: "form-control-plaintext",
                attrs: { type: "text", readonly: "", id: "username" },
                domProps: { value: _vm.audit.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.audit, "username", $event.target.value)
                  }
                }
              })
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            }
          },
          [_vm._v("Back")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }