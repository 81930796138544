var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: {
              docPath: "/paygate-collections/messaging/messagetemplates/"
            }
          }),
          _vm._v("Letter Designer")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c(
                  "b-form-group",
                  [
                    _c("label", { attrs: { for: "letterTemplateName" } }, [
                      _c("strong", [_vm._v("Template Name")])
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        id: "letterTemplateName",
                        type: "text",
                        disabled: _vm.loading,
                        autocomplete: "off",
                        state: _vm.templateNameStateValid,
                        "aria-describedby":
                          "letterTemplateNameHelp letterTemplateNameFeedback",
                        placeholder: "Letter Template Name",
                        maxlength: "50"
                      },
                      model: {
                        value: _vm.templateName,
                        callback: function($$v) {
                          _vm.templateName =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "templateName"
                      }
                    }),
                    !_vm.templateNameStateValid
                      ? _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "letterTemplateNameFeedback" } },
                          [
                            _vm._v(
                              " The template name must only consist of the following character types: "
                            ),
                            _c("ul", [
                              _c("li", [_vm._v("Upper-case letters A-Z")]),
                              _c("li", [_vm._v("Lower-case letters a-z")]),
                              _c("li", [_vm._v("Numbers 0-9")]),
                              _c("li", [
                                _vm._v('Spaces, underscores "_" or dashes "-"')
                              ])
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-form-text",
                      { attrs: { id: "smsTemplateNameHelp" } },
                      [_vm._v("The name of the template in paygate.")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("br"),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "vue-dropzone",
              {
                ref: "fileUploader",
                attrs: {
                  id: "drop1",
                  options: _vm.dropzoneOptions,
                  "use-custom-slot": true,
                  "accepted-file-types": _vm.dropzoneOptions.acceptedFiles,
                  "max-file-size-in-m-b": 200,
                  useCustomSlot: true
                },
                on: {
                  "vdropzone-success": _vm.afterSuccess,
                  "vdropzone-file-added": _vm.fileAdded,
                  "vdropzone-removed-file": _vm.fileRemoved,
                  "vdropzone-sending": _vm.sendingEvent
                }
              },
              [
                _c("div", { staticClass: "dropzone-custom-content" }, [
                  _c("h3", { staticClass: "dropzone-custom-title" }, [
                    _vm._v(_vm._s(_vm.dropzoneOptions.title))
                  ]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(_vm._s(_vm.dropzoneOptions.subtitle))
                  ])
                ])
              ]
            )
          ],
          1
        ),
        _c("br"),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.internalTemplateId
            ? _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticStyle: { width: "105% !important" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-top": "110%",
                                position: "relative"
                              }
                            },
                            [
                              _c("iframe", {
                                ref: "previewFrame",
                                staticStyle: {
                                  position: "absolute",
                                  top: "0",
                                  left: "0"
                                },
                                attrs: {
                                  id: "previewFrame",
                                  frameborder: "0",
                                  height: "100%",
                                  width: "100%"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "idb-block-footer" },
      [
        _c(
          "b-btn",
          {
            attrs: {
              variant: "primary",
              disabled:
                _vm.isLoading ||
                ((!_vm.templateid || _vm.templateid === null) &&
                  _vm.uploadCount === 0) ||
                _vm.templateName === "" ||
                !_vm.templateNameStateValid
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.saveClick($event)
              }
            }
          },
          [_vm._v("Save")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "btn btn-outline-secondary",
            attrs: { to: { name: "LetterDesigner" } }
          },
          [_vm._v("New Template")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }