const state = {
  // holds the configuration of the selected node in the diagram canvas
  selectedNode: {},
  workflowMetaData: {},
  // Holds all selected objects (nodes, links, etc) for clipboard use.
  selectedObjects: [],
  saveWorkflow: false,
  isWorkflowDirty: false,
  // Used to display the node configuration popup when opened from the Designer (cog icon)
  showNodeCfgModelPopup: false,
  selectedWorkflowId: ''
}

const getters = {
  // selectedTrigger (state) {
  //   return state.selectedTrigger
  // },
  // test (state) {
  //   return state.test
  // }
  // showPopup: state => state.showNodeCfgModelPopup
}

const mutations = {
  setSelectedWorkflowId (state, payload) {
    state.selectedWorkflowId = payload
  },
  setWorkflowMetaData (state, payload) {
    state.workflowMetaData = payload
  },
  setSelectedNode (state, payload) {
    // console.log('Vuex mutation - setSelectedNode')
    // console.log(payload)
    state.selectedNode = payload
  },
  setSaveWorkflow (state, payload) {
    state.saveWorkflow = payload
  },
  setSelectedObjects (state, payload) {
    // console.log('Vuex mutation - setSelectedObjects')
    // console.log(payload)
    state.selectedObjects = payload
  },
  setIsWorkflowDirty (state, payload) {
    // console.log('Vuex mutation - isWorkflowDirty')
    state.isWorkflowDirty = payload
  },
  setSelectedNodeProps (state, payload) {
    // console.log('Vuex mutation - setSelectedNodeProps')
    // console.log(payload)
    // console.log(state.selectedNode.props)
    if (payload) {
      // if (payload.s1) state.selectedNode.props.s1.value = payload.s1
      // if (payload.s2) state.selectedNode.props.s2.value = payload.s2
      // if (payload.s3) state.selectedNode.props.s3.value = payload.s3
      // if (payload.s4) state.selectedNode.props.s4.value = payload.s4
      // if (payload.s5) state.selectedNode.props.s5.value = payload.s5
      // if (payload.s6) state.selectedNode.props.s6.value = payload.s6
      // if (payload.s7) state.selectedNode.props.s7.value = payload.s7
      // if (payload.s8) state.selectedNode.props.s8.value = payload.s8

      // if (payload.b1 === undefined) {
      //   state.selectedNode.props.b1.value = false
      // } else {
      //   state.selectedNode.props.b1.value = payload.b1
      // }

      if (payload.s1 !== undefined) {
        state.selectedNode.props.s1.value = payload.s1
      }
      if (payload.s2 !== undefined) {
        state.selectedNode.props.s2.value = payload.s2
      }
      if (payload.s3 !== undefined) {
        state.selectedNode.props.s3.value = payload.s3
      }
      if (payload.s4 !== undefined) {
        state.selectedNode.props.s4.value = payload.s4
      }
      if (payload.s5 !== undefined) {
        state.selectedNode.props.s5.value = payload.s5
      }
      if (payload.s6 !== undefined) {
        state.selectedNode.props.s6.value = payload.s6
      }
      if (payload.s7 !== undefined) {
        state.selectedNode.props.s7.value = payload.s7
      }
      if (payload.s8 !== undefined) {
        state.selectedNode.props.s8.value = payload.s8
      }
      if (payload.s9 !== undefined) {
        state.selectedNode.props.s9.value = payload.s9
      }

      // console.log('payload s9: ' + payload.s9)
      // console.log('state.selectedNode.props: ')
      // console.dir(state.selectedNode.props.s9)

      if (payload.b1 !== undefined) {
        state.selectedNode.props.b1.value = payload.b1
      }
      if (payload.b2 !== undefined) {
        state.selectedNode.props.b2.value = payload.b2
      }
      if (payload.b3 !== undefined) {
        state.selectedNode.props.b3.value = payload.b3
      }
      if (payload.b4 !== undefined) {
        state.selectedNode.props.b4.value = payload.b4
      }
      if (payload.b5 !== undefined) {
        state.selectedNode.props.b5.value = payload.b5
      }
      if (payload.b6 !== undefined) {
        state.selectedNode.props.b6.value = payload.b6
      }
      if (payload.b7 !== undefined) {
        state.selectedNode.props.b7.value = payload.b7
      }
      if (payload.b8 !== undefined) {
        state.selectedNode.props.b8.value = payload.b8
      }
      if (payload.b9 !== undefined) {
        state.selectedNode.props.b9.value = payload.b9
      }
      if (payload.b10 !== undefined) {
        state.selectedNode.props.b10.value = payload.b10
      }
      if (payload.b11 !== undefined) {
        state.selectedNode.props.b11.value = payload.b11
      }
      if (payload.b12 !== undefined) {
        state.selectedNode.props.b12.value = payload.b12
      }
      if (payload.pw1 !== undefined) {
        state.selectedNode.props.pw1.value = payload.pw1
      }
      if (payload.pw2 !== undefined) {
        state.selectedNode.props.pw2.value = payload.pw2
      }

      if (payload.int1 !== undefined) {
        state.selectedNode.props.int1.value = payload.int1
      }
      if (payload.int2 !== undefined) {
        state.selectedNode.props.int2.value = payload.int2
      }
      if (payload.int3 !== undefined) {
        state.selectedNode.props.int3.value = payload.int3
      }
      if (payload.int4 !== undefined) {
        state.selectedNode.props.int4.value = payload.int4
      }

      // if (payload.guid1) state.selectedNode.props.guid1.value = payload.guid1
      // if (payload.guid2) state.selectedNode.props.guid2.value = payload.guid2
      // if (payload.guid3) state.selectedNode.props.guid3.value = payload.guid3
      // if (payload.guid4) state.selectedNode.props.guid4.value = payload.guid4
    }
  },
  setPopupState (state, payload) {
    // Used to display the node configuration popup when opened from the Designer (cog icon)
    state.showNodeCfgModelPopup = payload
  }
  // setSelectedTrigger (state, payload) {
  //   state.selectedTrigger = payload
  // },
  // TEST_COMMIT (state, payload) {
  //   state.test = payload
  // }
}

export default {
  state,
  getters,
  mutations
}
