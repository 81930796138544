<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          SSO Report
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          :group-options="{
            enabled: true,
            collapsable: true // or column index
          }"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
              <span class="sr-only">Clear filters</span>
            </button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import { mapGetters } from 'vuex'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  name: 'SSOReport',
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name'

        },
        {
          label: 'Email',
          field: 'email'

        }
      ],
      serverParams: {
        sort: [{ field: 'reference', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Report/sso`,
          {
            params: { ...this.buildGoodTableQuery() },
            showerror: true,
            errormessage: 'Failed loading sso accounts'
          })
        this.rows = response.data
      } catch { } finally {
        this.isTableLoading = false
      }
    }
  }
}
</script>

<style>
</style>
