import axios from 'axios'
// default state
const state = {
  schedule: [],
  originalSchedule: [],
  metaData: {
    tableLinks: {},
    totalPages: 0,
    totalItems: 0,
    firstPaymentTxTotal: 0,
    lastPaymentTxTotal: 0,
    lastPossiblePaymentDate: new Date(8640000000000000)
  },
  dataChanged: false,
  nonProcessingDates: [],
  currentPayerGroupId: null,
  badDate: false,
  noViableAmendments: false,
  tooManyFirsts: false,
  tooManyFinals: false
}

// mutators
const mutations = {
  setSchedule (state, schedule) {
    state.schedule = schedule.data
    state.originalSchedule = schedule.data
    state.dataChanged = false
  },
  setScheduleMetaData (state, meta) {
    state.metaData.tableLinks = meta.data.links
    state.metaData.totalPages = meta.data.meta.totalPages
    state.metaData.totalItems = meta.data.meta.totalItems
    state.metaData.firstPaymentTxTotal = meta.data.scheduleMeta.firstPaymentTxTotal
    state.metaData.lastPaymentTxTotal = meta.data.scheduleMeta.lastPaymentTxTotal
    state.metaData.lastPossiblePaymentDate = meta.data.scheduleMeta.lastPossiblePaymentDate
  },
  setDataChanged (state, latest) {
    state.dataChanged = latest
  },
  setNonProcessingDates (state, nprResponse) {
    var dateArr = []
    for (var i = 0; i < nprResponse.length; i++) {
      dateArr.push(new Date(nprResponse[i]))
    }
    state.nonProcessingDates = dateArr
  },
  setCurrentPayerGroupId (state, id) {
    state.currentPayerGroupId = id
  },
  setBadDate (state, value) {
    state.badDate = value
  },
  setNoneViable (state, value) {
    state.noViableAmendments = value
  },
  setTooManyFirsts (state, value) {
    state.tooManyFirsts = value
  },
  setTooManyFinals (state, value) {
    state.tooManyFinals = value
  }
}

// getters
const getters = {
  schedule: (state) => {
    return state.schedule
  },
  scheduleMetaData: (state) => {
    return state.metaData
  },
  dataChanged: (state) => {
    return state.dataChanged
  },
  nonProcessingDates: (state) => {
    return state.nonProcessingDates
  },
  currentPayerGroupId: (state) => {
    return state.currentPayerGroupId
  },
  noViableAmendments: (state) => {
    return state.noViableAmendments
  },
  tooManyFirsts: (state) => {
    return state.tooManyFirsts
  },
  tooManyFinals: (state) => {
    return state.tooManyFinals
  }
}

// actions
const actions = {
  getCurrentPayerScheduleFromId: async ({ commit, rootState }, request) => {
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}schedule/${request.id}/${request.planId}/`, {
        params: {
          page: request.serverParams.page,
          perPage: request.serverParams.perPage,
          columnFilters: request.serverParams.columnFilters,
          sort: request.serverParams.sort,
          searchKey: request.serverParams.searchFilter,
          paygateid: rootState.common.paygateId,
          includeHistoric: request.includeHistoric
        },
        showload: true
      })
      if (response) {
        commit('setSchedule', response.data)
        commit('setScheduleMetaData', response)
      }
    } catch (error) {
      console.log('axios error', error)
      commit('setSchedule', [])
      commit('setScheduleMetaData', {
        tableLinks: {},
        totalPages: 0,
        totalItems: 0,
        firstPaymentTxTotal: 0,
        lastPaymentTxTotal: 0,
        lastPossiblePaymentDate: new Date(8640000000000000)
      })
    }
    return true
  },
  getCurrentPayerGroupIdFromId: async ({ commit }, id) => {
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${id}`)
    commit('setCurrentPayerGroupId', response.data.groupId)
  },
  updateSchedule: async ({ commit, rootState }, request) => {
    var success = false
    var amendmentRequest = {
      ...request,
      paymentAmendment: {
        paymentAmendmentId: request.paymentAmendmentId,
        amendedDate: request.collectionDate,
        amendedAmount: request.amount,
        amendedTransactionCode: request.transactionCode,
        paymentPlanId: request.paymentPlanId,
        originalDate: request.originalDate,
        originalAmount: request.originalAmount,
        originalTransactionCode: request.originalTransactionCode,
        originalStatus: request.originalStatus ? request.originalStatus : request.status,
        ukPayerId: request.ukPayerId
      }
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}amendment/`, amendmentRequest, { params: { paygateid: rootState.common.paygateId }, showload: true })
      if (response.data.badDate) {
        success = false
        commit('setBadDate', true)
      } else {
        commit('setDataChanged', true)
        commit('setBadDate', false)
        success = true
      }
    } catch (err) {
      console.log('axios error', err)
      commit('setBadDate', false)
    }
    return success
  },
  getNonProcessingDates: async ({ commit, rootState }) => {
    axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/processingDate', { params: { paygateid: rootState.common.paygateId } })
      .then((response) => {
        console.log('non processing days')
        if (response.data.nonProcessingDates) {
          console.log(response.data.nonProcessingDates)
          commit('setNonProcessingDates', response.data.nonProcessingDates)
        }
      }).catch((error) => { console.log('Could not get non processing dates', error) })
  },
  addSchedule: async ({ commit, rootState }, request) => {
    var success = false
    var amendmentRequest = {
      ...request,
      paymentAmendment: {
        amendedDate: request.collectionDate,
        amendedAmount: request.amount,
        amendedTransactionCode: request.transactionCode,
        paymentPlanId: request.paymentPlanId,
        ukPayerId: request.ukPayerId,
        originalStatus: request.status
      }
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}amendment/new`, amendmentRequest, { params: { paygateid: rootState.common.paygateId }, showload: true })
      if (response.data.badDate) {
        success = false
        commit('setBadDate', true)
      } else {
        commit('setDataChanged', true)
        commit('setBadDate', false)
        success = true
      }
    } catch (err) {
      console.log('Error adding scheduled payment', err)
      commit('setBadDate', false)
    }
    return success
  },
  deleteSchedule: async ({ commit, rootState }, request) => {
    var amendmentRequest = {
      ...request,
      paymentAmendment: {
        amendedDate: request.collectionDate,
        amendedAmount: request.amount,
        amendedTransactionCode: request.transactionCode,
        paymentPlanId: request.paymentPlanId,
        ukPayerId: request.ukPayerId,
        originalStatus: request.status
      }
    }
    await axios.put(`${process.env.VUE_APP_DDMS_API_URL}amendment/delete`, amendmentRequest, { params: { paygateid: rootState.common.paygateId }, showload: true })
  },
  undeleteSchedule: async ({ commit, rootState }, request) => {
    var amendmentRequest = {
      ...request,
      paymentAmendment: {
        amendedDate: request.collectionDate,
        amendedAmount: request.amount,
        amendedTransactionCode: request.transactionCode,
        paymentPlanId: request.paymentPlanId,
        ukPayerId: request.ukPayerId
      }
    }
    await axios.put(`${process.env.VUE_APP_DDMS_API_URL}amendment/undelete`, amendmentRequest, { params: { paygateid: rootState.common.paygateId }, showload: true })
  },
  updateEntireSchedule: async ({ commit, rootState, dispatch }, request) => {
    commit('setDataChanged', false)
    commit('setBadDate', false)
    commit('setNoneViable', false)
    commit('setTooManyFirsts', false)
    commit('setTooManyFinals', false)
    var amendments = []
    for (var i = 0; i < request.schedule.length; i++) {
      if (request.schedule[i].isNew) {
        await dispatch('addSchedule', request.schedule[i])
      } else {
        if (new Date(request.schedule[i].processingDate) > new Date() && request.schedule[i].changed) {
          amendments.push({ ...request.schedule[i],
            paymentAmendment: {
              paymentAmendmentId: request.schedule[i].paymentAmendmentId,
              amendedDate: request.schedule[i].collectionDate,
              amendedAmount: request.schedule[i].amount,
              amendedTransactionCode: request.schedule[i].transactionCode,
              paymentPlanId: request.schedule[i].paymentPlanId,
              originalDate: request.schedule[i].originalDate,
              originalAmount: request.schedule[i].originalAmount,
              originalTransactionCode: request.schedule[i].originalTransactionCode,
              originalStatus: request.schedule[i].originalStatus ? request.schedule[i].originalStatus : request.schedule[i].status,
              ukPayerId: request.schedule[i].ukPayerId
            }
          })
        }
      }
    }
    var success = true
    if (amendments.length > 0) {
      try {
        var amendmentsRequest = { amendments, createPayerMessages: request.createPayerMessages, removeQueued: request.removeQueued }
        var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}amendment/all`, amendmentsRequest, { params: { paygateid: rootState.common.paygateId }, showload: true })
        console.log(response)
        if (response.data.badDate) {
          success = false
          commit('setBadDate', true)
        } else if (response.data.tooManyFirstPayments) {
          success = false
          commit('setTooManyFirsts', true)
        } else if (response.data.tooManyFinalPayments) {
          success = false
          commit('setTooManyFinals', true)
        } else {
          commit('setDataChanged', true)
          success = true
        }
      } catch (err) {
        success = false
        console.log('axios error', err)
      }
    } else {
      success = false
      commit('setNoneViable', true)
    }
    return success
  }
}

export default { state, getters, actions, mutations }
