var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [_vm._v("Workflow Monitor"), _c("favourite-icon")], 1)
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c("div", {
              staticClass: "progress-bar bg-primary",
              style: _vm.getCountdown(),
              attrs: { role: "progressbar", "aria-valuemax": "100" }
            }),
            _c("br"),
            _vm._m(0),
            _c("br"),
            _vm._m(1),
            _c("hr"),
            _vm._l(_vm.sensorsState, function(sensor, index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(_vm._s(sensor.component))
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _vm._v(_vm._s(_vm.reformatDate(sensor.lastHeartbeat)))
                ]),
                sensor.state === "pass"
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "span",
                        { staticClass: "badge badge-pill badge-success" },
                        [_vm._v("Healthy")]
                      )
                    ])
                  : sensor.state === "fail"
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "span",
                        { staticClass: "badge badge-pill badge-danger" },
                        [_vm._v("Fail")]
                      )
                    ])
                  : _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "span",
                        { staticClass: "badge badge-pill badge-info" },
                        [_vm._v(_vm._s(sensor.state))]
                      )
                    ]),
                sensor.state === "fail"
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _vm._v(_vm._s(_vm.reformatDate(sensor.initialFailDate)))
                    ])
                  : _vm._e(),
                _c("br"),
                _c("br")
              ])
            })
          ],
          2
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _vm.isCorvidAdmin
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c(
                        "p-check",
                        {
                          staticClass: "p-switch",
                          attrs: { name: "check", color: "primary " },
                          model: {
                            value: _vm.emailAlertsEnabled,
                            callback: function($$v) {
                              _vm.emailAlertsEnabled = $$v
                            },
                            expression: "emailAlertsEnabled"
                          }
                        },
                        [_vm._v("Email Workflow Alerts")]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("br"),
            _c("transition", { attrs: { name: "fadein" } }, [
              _vm.isCorvidAdmin
                ? _c("div", { staticClass: "textarea" }, [
                    _vm.emailAlertsEnabled
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", {}, [
                              _vm._v("Email Alerts Recipients")
                            ]),
                            _c("input-tag", {
                              class: {
                                invalid: _vm.$v.emailAlertRecipientsArray.$error
                              },
                              attrs: {
                                limit: 32,
                                validate: "email",
                                allowDuplicates: false
                              },
                              model: {
                                value: _vm.emailAlertRecipientsArray,
                                callback: function($$v) {
                                  _vm.emailAlertRecipientsArray = $$v
                                },
                                expression: "emailAlertRecipientsArray"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(3),
        _c("div", { staticClass: "idb-block-content" }, [
          _vm.enabledWorkflowProcessor == false
            ? _c(
                "div",
                {
                  staticClass: "alert alert-warning",
                  attrs: { role: "alert", "aria-label": "Warning:" }
                },
                [
                  _c("i", { staticClass: "fas fa-exclamation-triangle mr-2" }),
                  _c("b", [_vm._v("Warning.")]),
                  _vm._v(" Workflow execution is currently disabled. ")
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row mb-5" }, [
            _vm._m(4),
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("p-check", {
                  staticClass: "p-switch",
                  attrs: {
                    name: "check",
                    labelId: "enableWorkflowProcessor",
                    color: "primary "
                  },
                  on: { change: _vm.saveWorkflowControlConfig },
                  model: {
                    value: _vm.enabledWorkflowProcessor,
                    callback: function($$v) {
                      _vm.enabledWorkflowProcessor = $$v
                    },
                    expression: "enabledWorkflowProcessor"
                  }
                })
              ],
              1
            ),
            _vm._m(5)
          ]),
          _c("br")
        ]),
        _c("div", { staticClass: "idb-block-footer" }, [
          _c("div", [
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.btnSave } },
              [_c("i", { staticClass: "fas fa-save " }), _vm._v(" Save")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4" }, [_c("h4", [_vm._v("Status")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-3" }, [_vm._v("Sensor")]),
      _c("div", { staticClass: "col-md-2" }, [_vm._v("Last Time / Date")]),
      _c("div", { staticClass: "col-md-2" }, [_vm._v("State")]),
      _c("div", { staticClass: "col-md-2" }, [_vm._v("Transition Date")]),
      _c("div", { staticClass: "col-md-2" }, [_vm._v("Notes")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Alerting")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Workflow Control")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("label", { attrs: { for: "enableWorkflowProcessor" } }, [
        _vm._v("Enabled Workflow Processor")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _vm._v(
        " When disabled, workflows that are currently running will be allowed to complete but new workflows will "
      ),
      _c("strong", [_vm._v("not")]),
      _vm._v(" start. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }