var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-md-3" }, [
        _c("div", { staticClass: "table-responsive inbox" }, [
          _c("table", { staticClass: "table" }, [
            _c(
              "tbody",
              [
                _vm._t("buttonSlot"),
                _vm._l(_vm.pagedMessage, function(message, i) {
                  return _c(
                    "tr",
                    {
                      key: i,
                      class: _vm.getClass(message),
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.selectMessage(message)
                        }
                      }
                    },
                    [
                      _c("td", { staticClass: "name" }, [
                        _c("p", { staticClass: "avatarFrame" }, [
                          _vm._v(_vm._s(_vm.getAvatar(message)))
                        ])
                      ]),
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: message.customerMessage
                                  ? "/collections/payer/" +
                                    message.senderId +
                                    "/edit"
                                  : null
                              }
                            },
                            [_vm._v(_vm._s(message.senderName))]
                          ),
                          !message.customerMessage
                            ? _c("span", [_vm._v(_vm._s(message.senderName))])
                            : _vm._e(),
                          _c("br"),
                          _c("div", { staticClass: "messageSelectorSmall" }, [
                            _vm._v(_vm._s(_vm.latestTitle(message))),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.formatDate(message.threadDate))
                            ),
                            _c("br"),
                            _c("div", {
                              staticClass: "summary",
                              domProps: {
                                innerHTML: _vm._s(_vm.concatContent(message))
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-9" }, [
        _vm.selectedMessage === null
          ? _c("div", [_vm._v(" Select a message to view ")])
          : _c(
              "div",
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-10" }, [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.latestTitle(_vm.selectedMessage)))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-2 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.reply($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-reply" }),
                          _vm._v(" Reply")
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._l(_vm.selectedMessage.replies, function(reply, i) {
                  return _c(
                    "div",
                    { key: i, style: "padding-left: " + i * 20 + "px" },
                    [
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-1 text-center" }, [
                          _c("p", { staticClass: "avatarFrame" }, [
                            _vm._v(_vm._s(_vm.getAvatar(reply)))
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-11" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: reply.customerMessage
                                    ? "/collections/payer/" +
                                      reply.senderId +
                                      "/edit"
                                    : null
                                }
                              },
                              [_vm._v(_vm._s(reply.senderName))]
                            ),
                            !reply.customerMessage
                              ? _c("span", [_vm._v(_vm._s(reply.senderName))])
                              : _vm._e(),
                            _c("br"),
                            _c("div", { staticClass: "messageSelectorSmall" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.latestTitle(reply)))
                              ]),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDate(reply.displayDate)) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      ]),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(reply.messageBody) }
                      }),
                      _c("hr")
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "row mb-2",
                    style:
                      "padding-left: " +
                      _vm.selectedMessage.replies.length * 20 +
                      "px"
                  },
                  [
                    _c("div", { staticClass: "col-1 text-center" }, [
                      _c("p", { staticClass: "avatarFrame" }, [
                        _vm._v(_vm._s(_vm.getAvatar(_vm.selectedMessage)))
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-11" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.selectedMessage.customerMessage
                                ? "/collections/payer/" +
                                  _vm.selectedMessage.senderId +
                                  "/edit"
                                : null
                            }
                          },
                          [_vm._v(_vm._s(_vm.selectedMessage.senderName))]
                        ),
                        !_vm.selectedMessage.customerMessage
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.selectedMessage.senderName))
                            ])
                          : _vm._e(),
                        _c("br"),
                        _c("div", { staticClass: "messageSelectorSmall" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.selectedMessage.messageTitle))
                          ]),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDate(_vm.selectedMessage.displayDate)
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _c("div", {
                  style:
                    "padding-left: " +
                    _vm.selectedMessage.replies.length * 20 +
                    "px",
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedMessage.messageBody)
                  }
                }),
                _vm.selectedMessage.paymentComposite &&
                _vm.selectedMessage.paymentComposite !== ""
                  ? _c(
                      "p",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/collections/payer/" +
                                _vm.selectedMessage.senderId +
                                "/" +
                                _vm.selectedMessage.paymentPlanId +
                                "/schedule?actioncomposite=" +
                                _vm.selectedMessage.paymentComposite
                            }
                          },
                          [_vm._v("View Collection")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
      ]),
      _vm.currentMessageView.length >= _vm.pageCount
        ? _c("b-pagination", {
            attrs: {
              "total-rows": _vm.currentMessageView.length,
              "per-page": _vm.pageCount
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "reply-compose-modal",
          attrs: { id: "reply-compose-modal", size: "xl" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v("Compose New Message")
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.closeComposeModal }
                    },
                    [_vm._v(" Discard")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary pull-right",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.send }
                    },
                    [
                      _c("i", { staticClass: "fa fa-envelope" }),
                      _vm._v(" Send Message")
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.messageTitle,
                  expression: "messageTitle"
                }
              ],
              staticClass: "form-control",
              attrs: {
                name: "subject",
                type: "email",
                placeholder: "Subject",
                readonly: ""
              },
              domProps: { value: _vm.messageTitle },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.messageTitle = $event.target.value
                }
              }
            })
          ]),
          _c(
            "b-form-group",
            [
              _c("label", [_vm._v("Message Body")]),
              _c("wysiwyg", {
                ref: "messageBodyControl",
                attrs: { suppressLoad: true },
                model: {
                  value: _vm.messageBody,
                  callback: function($$v) {
                    _vm.messageBody = $$v
                  },
                  expression: "messageBody"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }