<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Email Monitoring</h2>
      </div>
      <div class="idb-block-content">
        <table style="margin-bottom:10px;">
          <thead>
            <tr>
              <th></th>
              <!-- Delete link -->
              <th class="w-75"></th>
              <!-- Users Name -->
              <th class="rotate" style="width:50px;">
                <div>
                  <span>Email</span>
                </div>
              </th>
              <th class="rotate" style="width:50px;">
                <div>
                  <span>SMS</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in contactUsers" :key="user.userId">
              <td style="width:20px;">
                <span
                  class="fa fa-trash"
                  role="button"
                  @click="removeUser(user.userId)"
                ></span>
              </td>
              <td class="row-header" style="width:150px;">{{ user.email }}</td>
              <td>
                <label :for="user.userId+'_contactViaEmail'" class='sr-only'>Contact Via Email?</label>
                <p-check :labelId="user.userId+'_contactViaEmail'"
                  class="p-default p-curve"
                  v-model="user.contactViaEmail"
                ></p-check>
              </td>
              <td>
                <label :for="user.userId+'_contactViaSms'" class='sr-only'>Contact Via SMS?</label>
                <p-check :labelId="user.userId+'_contactViaSms'"
                  class="p-default p-curve"
                  v-model="user.contactViaSms"
                ></p-check>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="padding-bottom:10px;, margin-top:10px">
          <b-form inline>
            <b-form-select
              class="col-md-3"
              v-model="selectedUnallocatedUser"
              :options="userList"
            >
              <template v-slot:first>
                <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
              </template>
            </b-form-select>
            <b-button
              variant="primary"
              style="margin-left: 10px;"
              @click="addUser"
            >Add</b-button>
          </b-form>
        </div>
      </div>
      <div class="idb-block-footer">
        <b-button
            variant="primary"
            @click.prevent="updateLoggingUsers"
          >
            <i class="fa fa-save mr-2"></i>Save
          </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
export default {
  data () {
    return {
      contactUsers: [],
      unallocatedUsers: [],
      selectedUnallocatedUser: null
    }
  },
  methods: {
    removeUser (id) {
      this.contactUsers = _.remove(this.contactUsers, function (x) { return x.userId !== id })
    },
    async addUser () {
      var selected = this.unallocatedUsers.filter(x => x.id === this.selectedUnallocatedUser)[0]
      this.contactUsers.push({ userId: selected.id, name: selected.label, email: selected.label, phoneNumber: '', contactViaEmail: false, contactViaSms: false })
    },
    async getLoggingUsers () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}emailreporting/loggingusers`)
        if (response) {
          this.contactUsers = response.data.loggingUsers
          this.unallocatedUsers = response.data.allUsers
        }
      } catch (err) {
        console.log(err)
      }
    },
    async updateLoggingUsers () {
      try {
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}emailreporting/loggingusers`, this.contactUsers)
      } catch (err) {
        console.log(err)
      }
    }
  },
  async mounted () {
    await this.getLoggingUsers()
  },
  computed: {
    userList () {
      var output = []
      for (var a = 0; a < this.unallocatedUsers.length; a++) {
        if (this.contactUsers && this.contactUsers.filter(x => x.userId === this.unallocatedUsers[a].id).length === 0) {
          output.push({ value: this.unallocatedUsers[a].id, text: this.unallocatedUsers[a].label })
        }
      }
      return output
    }
  }
}
</script>
