var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vgt-wrap__footer vgt-clearfix" }, [
    _vm.perPageDropdownEnabled
      ? _c("div", { staticClass: "footer__row-count vgt-pull-left" }, [
          _c("form", [
            _c(
              "label",
              {
                staticClass: "footer__row-count__label",
                attrs: { for: _vm.id }
              },
              [_vm._v(_vm._s(_vm.rowsPerPageText) + ":")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentPerPage,
                    expression: "currentPerPage"
                  }
                ],
                staticClass: "footer__row-count__select",
                attrs: {
                  id: _vm.id,
                  autocomplete: "off",
                  name: "perPageSelect",
                  "aria-controls": "vgt-table"
                },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.currentPerPage = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.perPageChanged
                  ]
                }
              },
              [
                _vm._l(_vm.rowsPerPageOptions, function(option, idx) {
                  return _c(
                    "option",
                    { key: idx, domProps: { value: option } },
                    [_vm._v(" " + _vm._s(option) + " ")]
                  )
                }),
                _vm.paginateDropdownAllowAll
                  ? _c("option", { domProps: { value: -1 } }, [
                      _vm._v(_vm._s(_vm.allText))
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "footer__navigation vgt-pull-right" },
      [
        _c("pagination-page-info", {
          attrs: {
            "total-records": _vm.total,
            "last-page": _vm.pagesCount,
            "current-page": _vm.currentPage,
            "current-per-page": _vm.currentPerPage,
            "of-text": _vm.ofText,
            "page-text": _vm.pageText,
            "info-fn": _vm.infoFn,
            mode: _vm.mode
          },
          on: { "page-changed": _vm.changePage }
        }),
        _vm.jumpFirstOrLast
          ? _c(
              "button",
              {
                staticClass: "footer__navigation__page-btn",
                class: { disabled: !_vm.firstIsPossible },
                attrs: { type: "button", "aria-controls": "vgt-table" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.firstPage($event)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "chevron",
                  class: { left: !_vm.rtl, right: _vm.rtl },
                  attrs: { "aria-hidden": "true" }
                }),
                _c("span", [_vm._v(_vm._s(_vm.firstText))])
              ]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "footer__navigation__page-btn",
            class: { disabled: !_vm.prevIsPossible },
            attrs: { type: "button", "aria-controls": "vgt-table" },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.previousPage($event)
              }
            }
          },
          [
            _c("span", {
              staticClass: "chevron",
              class: { left: !_vm.rtl, right: _vm.rtl },
              attrs: { "aria-hidden": "true" }
            }),
            _c("span", [_vm._v(_vm._s(_vm.prevText))])
          ]
        ),
        _c(
          "button",
          {
            staticClass: "footer__navigation__page-btn",
            class: { disabled: !_vm.nextIsPossible },
            attrs: { type: "button", "aria-controls": "vgt-table" },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.nextPage($event)
              }
            }
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.nextText))]),
            _c("span", {
              staticClass: "chevron",
              class: { right: !_vm.rtl, left: _vm.rtl },
              attrs: { "aria-hidden": "true" }
            })
          ]
        ),
        _vm.jumpFirstOrLast
          ? _c(
              "button",
              {
                staticClass: "footer__navigation__page-btn",
                class: { disabled: !_vm.lastIsPossible },
                attrs: { type: "button", "aria-controls": "vgt-table" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.lastPage($event)
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.lastText))]),
                _c("span", {
                  staticClass: "chevron",
                  class: { right: !_vm.rtl, left: _vm.rtl },
                  attrs: { "aria-hidden": "true" }
                })
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }