var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm.actionSubClass === "Info" || _vm.actionSubClass === "GroupAdmin"
            ? _c("div", [_c("infoAction")], 1)
            : _vm.actionSubClass !== "Edit"
            ? _c(
                "div",
                [
                  _c("viewSubmission"),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.actionLoaded,
                          expression: "actionLoaded"
                        }
                      ],
                      staticClass: "idb-block-footer"
                    },
                    [
                      (_vm.actionSubClass === "Sign" ||
                        _vm.actionSubClass === "Commit") &&
                      !_vm.unintegratedSubmission
                        ? _c("signSubmission")
                        : _vm.actionSubClass === "Approve" &&
                          !_vm.unintegratedSubmission
                        ? _c("approveSubmission")
                        : _vm._e(),
                      _vm.actionSubClass === "Send" &&
                      !_vm.unintegratedSubmission
                        ? _c("sendSubmission")
                        : _vm._e(),
                      _vm.unintegratedSubmission
                        ? _c("unintegratedSubmssion")
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }