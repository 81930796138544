<template>
  <div>
    <h3>Delay<span class="pull-right"><a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/flow/delay/`" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <div class="form-group">
      <label class="required" >Delay Time (seconds)</label>
      <input type="number"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.int1.value.$error}"
        @input="$v.selectedNode.props.int1.value.$touch()"
        v-model="selectedNode.props.int1.value">
        <p class="validationText" v-if="!$v.selectedNode.props.int1.value.required && $v.selectedNode.props.int1.value.$dirty ">The delay time field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.int1.value.minValue">Minimum value is {{ $v.selectedNode.props.int1.value.$params.minValue.min }} seconds!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.int1.value.maxValue">Maximum value is {{ $v.selectedNode.props.int1.value.$params.maxValue.max }} seconds!</p>
    </div>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, integer, maxValue, minValue } from 'vuelidate/lib/validators'
export default {
  validations: {
    selectedNode: {
      props: {
        int1: {
          value: {
            required,
            integer,
            maxValue: maxValue(300),
            minValue: minValue(1)
          }
        }
      }
    }
  },
  data () {
    return {
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
