<template>
	<div>
		<fileBrowser v-if="showModalFileBrowser" @close="showModalFileBrowser = false; closePopup()"></fileBrowser>
		<!-- uiStage: {{uiStage}} -->
		<div class="row mb-4" v-if="isLicenced">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2 v-if="isNew">
							<span class="pull-right">
								<a :href="`${docUrl}/automation/paygate-mapping/importers/csvimporter/`" target="_blank">
									<i class="far fa-question-circle"></i>
								</a>
							</span> Create a CSV / delimited importer
						</h2>
						<h2 v-else>
							<span class="pull-right">
								<a :href="`${docUrl}/automation/paygate-mapping/importers/csvimporter/`" target="_blank">
									<i class="far fa-question-circle"></i>
								</a>
							</span> Edit a CSV / delimited
						</h2>
					</div>
					<div class="idb-block-content">
						<div class="row">
							<div class="col-md-2 col-sm-12">
								<label class="required" v-b-popover.hover.top.d500="'The title of the item.'">Title</label>
							</div>
							<div class="form-group col-md-10 col-sm-12">
								<input
									type="text"
									class="form-control"
									:class="{invalid: $v.importer.title.$error}"
									@input="$v.importer.title.$touch()"
									v-model="importer.title"
								/>
								<p
									class="validationText"
									v-if="!$v.importer.title.required && $v.importer.title.$dirty "
								>The title field is required!</p>
								<p
									class="validationText"
									v-if="!$v.importer.title.maxLength"
								>Maximum length is {{ $v.importer.title.$params.maxLength.max }} characters!</p>
							</div>
						</div>
						<div class="row" v-if="uiStage === 1">
							<div class="col-md-2 col-sm-12">
								<label
									v-b-popover.hover.top.d500="'An optional text field for you to write a discription of the item.' "
								>Description</label>
							</div>
							<div class="form-group col-md-10 col-sm-12">
								<textarea
									class="form-control"
									rows="4"
									cols="60"
									@input="$emit('textarea',$event.target.value)"
									@blur="$v.importer.description.$touch()"
									v-model="importer.description"
								></textarea>
								<p
									class="validationText"
									v-if="!$v.importer.description.maxLength"
								>Maximum length is {{ $v.importer.description.$params.maxLength.max }} characters!</p>
							</div>
						</div>
						<div class="row" v-if="uiStage === 1">
							<div class="col-md-2 col-sm-12">
								<label
									class
									v-b-popover.hover.top.d500="'The file path that Paygate will use when this mapping is run to locate the file.'"
								>File Location</label>
							</div>
							<div class="col-md-10 mb-3">
								<div class="input-group">
									<input
										type="text"
										class="form-control"
										:class="{invalid: $v.importer.filePath.$error}"
										@input="$v.importer.filePath.$touch()"
										v-model="importer.filePath"
									/>
									<div class="input-group-append">
										<b-button @click.prevent="btnFileBrowser" variant="outline-secondary">...</b-button>
									</div>
								</div>
								<!-- <p class="validationText" v-if="!$v.importer.filePath.required && $v.importer.filePath.$dirty ">The folder path is required!</p> -->
								<p
									class="validationText"
									v-if="!$v.importer.filePath.maxLength"
								>Maximum length is {{ $v.importer.filePath.$params.maxLength.max }} characters!</p>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-md-2 col-sm-12">
								<label
									class="required"
									v-b-popover.hover.top.d500="'Sets the data type used for auto-mapping.'"
								>Data Type</label>
							</div>
							<div class="form-group col-md-4 col-sm-12">
								<select v-if="uiStage === 0" v-model="selectedDataType" class="form-control">
									<option
										v-for="option in dataTypeMode"
										:key="option.index"
										:value="option.value"
									>{{ option.text }}</option>
								</select>
								<label v-else class>{{getDataTypeText(selectedDataType)}}</label>
							</div>
							<div class="form-group col-md-1 col-sm-12"></div>
						</div>
						<!-- <div class="row mt-2">
                <div class="col-md-2 col-sm-12">
                  <label class="" v-b-popover.hover.top.d500="'Determines whether an existing file is to be imported into the system or a file will be created and exported from the system.'" >Type</label>
                </div>
                <div class="form-group col-md-1 col-sm-12">
                  <select v-if="uiStage === 0" v-model="selectedType" class="form-control">
                    <option v-for="option in typeMode" :key="option.index" :value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                  <label v-else class="">{{getTypeText(selectedType)}}</label>
                </div>
						</div>-->
					</div>
				</div>
				<div class="idb-block" v-if="uiStage === 0">
					<div class="idb-block-title">
						<h2>Sample File</h2>
					</div>
					<div class="idb-block-content">
						<div class="row">
							<div class="form-group col-md-12 col-sm-12">
								<vue-dropzone
									:useCustomDropzoneOptions="true"
									ref="myVueDropzone"
									id="dropzone"
									@vdropzone-file-added="vfileAdded"
									@vdropzone-complete="vcomplete"
									@vdropzone-success="vsuccess"
									@vdropzone-error="verror"
									:options="dropzoneOptions"
								></vue-dropzone>
								<div class="float-right">
									<button type="button" class="btn btn-link" @click="skipDropZoneStep">Skip</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="idb-block" v-if="uiStage === 1">
					<div class="idb-block-title">
						<h2>File Cleaner</h2>
					</div>
					<div class="idb-block-content">
						<div class="row mt-3 mb-3">
							<div class="col-md-2 col-sm-12">
								<label
									class
									for="cleanImportCheck"
									v-b-popover.hover.top.d500="'Determines whether a file is cleaned when imported.'"
								>Clean File on Import</label>
							</div>
							<div class="form-group col-md-1 col-sm-12">
								<p-check
									name="check"
									class="p-switch"
									:labelId="'cleanImportCheck'"
									color="primary "
									v-model="cleanOnImport"
								></p-check>
							</div>
							<transition name="fadein">
								<div class="form-group col-md-2 col-sm-12" v-if="cleanOnImport">
									<b-button
										v-b-toggle.cleanerRules
										variant="outline-primary"
										v-b-popover.hover.top.d500="'Set the rules when cleaning an imported file.'"
									>
										<i class="fas fa-broom"></i> Cleaner Rules
										<span
											v-if="cleanerRules.filter(x => x.enabled).length"
											class="badge badge-pill badge-primary ml-2"
										>{{ cleanerRules.filter(x => x.enabled).length }}</span>
									</b-button>
								</div>
							</transition>
							<transition name="fadein">
								<div class="col-md-2 col-sm-12 text-right" v-if="cleanOnImport">
									<label
										class
										v-b-popover.hover.top.d500="'Will substitute all characters not found in the following character set with a whitespace character.'"
									>Character Set</label>
								</div>
							</transition>
							<transition name="fadein">
								<div class="form-group col-md-2 col-sm-12" v-if="cleanOnImport">
									<select v-model="selectedCharset" class="form-control">
										<option
											v-for="(option, index) in charset"
											:key="index + 1"
											:value="option.value"
										>{{ option.text }}</option>
									</select>
								</div>
							</transition>
							<transition name="fadein">
								<div class="col-md-1 col-sm-12" v-if="cleanOnImport"></div>
							</transition>
							<transition name="fadein">
								<div class="col-md-2 col-sm-12" v-if="cleanOnImport">
									<b-button
										v-b-toggle.substitutionRules
										variant="outline-primary"
										v-b-popover.hover.top.d500="'Set the rules Paygate will follow when making substitutions to a imported file.'"
									>
										<i class="icon fas fa-exchange-alt"></i> Substitution
										<span
											v-if="substitutionRules.length"
											class="badge badge-pill badge-primary ml-2"
										>{{ substitutionRules.length }}</span>
									</b-button>
								</div>
							</transition>
						</div>
						<!-- Cleaner Rules -->
						<div class="row" v-if="cleanOnImport">
							<div class="col-md-12">
								<b-collapse id="cleanerRules" accordion="accordion">
									<b-card title="File Cleaner Rules">
										<br />
										<div v-for="(column,index) in cleanerRules" :key="index">
											<div class="row">
												<div class="col-md-1"></div>
												<div class="col-md-11">
													<div class="pretty p-icon p-smooth">
														<input type="checkbox" v-model="column.enabled" />
														<div class="state p-primary">
															<i class="icon fa fa-check"></i>
															<label>{{column.name}}</label>
														</div>
													</div>
												</div>
											</div>
											<br />
										</div>
									</b-card>
									<br />
								</b-collapse>
							</div>
						</div>
						<!-- Substitution Rules -->
						<div class="row" v-if="cleanOnImport">
							<div class="col-md-12">
								<b-collapse id="substitutionRules" accordion="accordion">
									<b-card title="Substitution Rules">
										<div class="row">
											<div class="col-12">
												<span
													class="small"
												>Substitute the 'From' character with the value of the 'To' character.</span>
											</div>
										</div>
										<br />
										<div class="row">
											<div class="form-group col-md-12 col-sm-12">
												<div class="row">
													<div class="col-md-1"></div>
													<div class="col-md-2">
														<strong>Enabled</strong>
													</div>
													<div class="col-md-2">
														<strong>From Character</strong>
													</div>
													<div class="col-md-2">
														<strong>To Character</strong>
													</div>
												</div>
												<div v-for="(column,index) in substitutionRules" :key="index">
													<div class="row columnSection">
														<div class="col-md-1"></div>
														<div class="col-md-2">
															<div class="pretty p-icon p-smooth">
																<input type="checkbox" v-model="column.enabled" />
																<div class="state p-primary">
																	<i class="icon fa fa-check"></i>
																	<label></label>
																</div>
															</div>
														</div>
														<div class="col-md-1">
															<input type="text" class="form-control" maxlength="1" v-model="column.from" />
														</div>
														<div class="col-md-1"></div>
														<div class="col-md-1">
															<input type="text" class="form-control" maxlength="1" v-model="column.to" />
														</div>
														<div class="col-md-1"></div>
														<div class="col-md-5 text-right">
															<b-button
																variant="outline-danger btn-sm"
																@click.prevent="btnRemoveSubstitutionRule(index)"
																v-b-popover.hover.top.d500="'Removes the substitute rule.'"
															>
																<span class="fa fa-times" aria-hidden="true"></span>
															</b-button>
														</div>
													</div>
												</div>
												<br />
												<div class="row">
													<div class="col-md-12 text-right">
														<b-button
															variant="outline-primary"
															@click.prevent="btnAddSubstitutionRule(index)"
															v-b-popover.hover.top.d500="'Add a new substitution rule.'"
														>
															<i class="icon fa fa-plus"></i> Add Rule
														</b-button>
													</div>
												</div>
											</div>
										</div>
									</b-card>
								</b-collapse>
							</div>
						</div>
					</div>
				</div>
				<div class="idb-block" v-if="uiStage === 1">
					<div class="idb-block-title">
						<h2>File Parameters</h2>
					</div>
					<div class="idb-block-content">
						<div class="row">
							<div class="col-md-2 col-sm-12"></div>
							<div class="col-md-2">
								<label class="required">Delimiter</label>
							</div>
							<div class="col-md-1">
								<input
									type="text"
									class="form-control"
									:class="{invalid: $v.importer.parserParams.delimiter.$error}"
									@input="$v.importer.parserParams.delimiter.$touch()"
									v-model="importer.parserParams.delimiter"
								/>
								<p
									class="validationText"
									v-if="!$v.importer.parserParams.delimiter.required && $v.importer.parserParams.delimiter.$dirty "
								>The delimiter field is required!</p>
							</div>
							<div class="col-md-1"></div>
							<div class="col-md-2">
								<label for="trimCheck">Trim Whitespaces</label>
							</div>
							<div class="col-md-3">
								<p-check
									:labelId="'trimCheck'"
									name="check"
									class="p-switch"
									color="primary "
									v-model="importer.parserParams.trim"
								></p-check>
							</div>
						</div>
						<div class="row mt-3" v-if="uiStage === 1">
							<div class="col-md-2">
								<label title="Description"></label>
							</div>
							<div class="col-md-2">Comments Character</div>
							<div class="col-md-1">
								<input type="text" class="form-control" v-model="importer.parserParams.commentChars" />
							</div>
							<div class="col-md-1"></div>

							<div class="col-md-2">Quote Character</div>
							<div class="col-md-1">
								<input type="text" class="form-control" v-model="importer.parserParams.quote" />
							</div>
						</div>
						<div class="row mt-3" v-if="uiStage === 1">
							<div class="col-md-2"></div>
							<div class="col-md-2">Ignore First Rows</div>
							<div class="col-md-1">
								<input
									class="form-control"
									type="number"
									min="0"
									oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
									step="1"
									v-model="importer.parserParams.ignoreFirstRows"
								/>
							</div>
							<div class="col-md-1"></div>
							<div class="col-md-2">
								<label for="headerCheck">Header</label>
							</div>
							<div class="col-md-3">
								<p-check
									name="check"
									:labelId="'headerCheck'"
									class="p-switch"
									color="primary "
									v-model="importer.parserParams.hasHeader"
								></p-check>
							</div>
						</div>
						<div class="row mt-3" v-if="uiStage === 1">
							<div class="col-md-2"></div>
							<div class="col-md-2">Ignore Last Rows</div>
							<div class="col-md-1">
								<input
									class="form-control"
									type="number"
									min="0"
									oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
									step="1"
									v-model="importer.parserParams.ignoreLastRows"
								/>
							</div>
							<div class="col-md-1"></div>
							<div class="col-md-2">
								<!-- Ignore Empty Rows -->
							</div>
							<div class="col-md-1">
								<!-- <p-check name="check" class="p-switch" color="primary " v-model="importer.parserParams.ignoreEmpty"></p-check> -->
							</div>
						</div>
						<div
							class="row mt-3"
							v-if="uiStage === 1 && importer.parserParams.hasHeader && importer.parserParams.ignoreFirstRows > 0"
						>
							<div class="col-md-2"></div>
							<div class="col-md-8">
								<div class="alert alert-warning">
									<strong>Note</strong> Your current configuration might not work correctly because you have included a header but the header will, most likely, be skipped due to the value of 'Ignore First Rows'.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="idb-block" v-if="uiStage === 1">
					<div class="idb-block-title">
						<h2>Columns</h2>
					</div>
					<div class="idb-block-content">
						<div class="row">
							<hr />
							<br />
							<br />
							<div class="form-group col-md-12 col-sm-12">
								<div class="row">
									<div class="col-md-1">
										<strong>Column</strong>
									</div>
									<div class="col-md-3">
										<strong>Sample</strong>
									</div>
									<div class="col-md-2">
										<strong>Suggestion</strong>
									</div>
									<div class="col-md-5">
										<strong>Data Type</strong>
									</div>
								</div>
								<hr />
								<draggable
									v-model="importer.configuration"
									:options="{group:'columns'}"
									@end="endDrag()"
									handle=".handle"
								>
									<div v-for="(column, index) in importer.configuration" :key="index">
										<div class="row columnSection">
											<div class="col-md-1">
												<i class="fa fa-align-justify handle mr-3"></i>
												{{index + 1}}
											</div>
											<div class="col-md-3">{{truncate(column.value)}}</div>
											<div class="col-md-2">
												<span v-if="column.weakType" class="badge badge-pill badge-success">{{column.weakType}}</span>
											</div>
											<div v-if="selectedDataType === 'bacsPayments'" class="col-md-5">
												<v-select v-model="column.strongType" :options="bacsDataTypes"></v-select>
											</div>
											<div v-else-if="selectedDataType === 'bacsBureauCustomers'" class="col-md-5">
												<v-select v-model="column.strongType" :options="bureauCustomerDataTypes"></v-select>
											</div>
											<div v-else-if="selectedDataType === 'ukDdmsVar'" class="col-md-5">
												<v-select v-model="column.strongType" :options="ukDdmsVarDataTypes"></v-select>
											</div>
											<div class="col-1">
												<b-button
													variant="link"
													@click.prevent="btnRemoveColumn(index)"
													v-b-popover.hover.top.d500="'Removes the column from the Column Parameters.'"
												>
													<span class="text-secondary glyphicon ti-trash text-danger" aria-hidden="true"></span>
												</b-button>
											</div>
										</div>
									</div>
								</draggable>
							</div>
						</div>
					</div>
					<div class="idb-block-footer">
						<div class="mb-2" v-if="uiStage === 1">
							<button
								class="btn btn-outline-primary float-right"
								aria-hidden="true"
								v-b-popover.hover.top.d500="'Add a new column.'"
								@click.prevent="btnAddColumn"
							>
								<i class="fa fa-plus mr-2"></i>Add Column
							</button>
						</div>
					</div>
				</div>
				<div class="idb-block" v-if="uiStage === 1">
					<div class="idb-block-title" v-if="!isNew">
						<div class="row">
							<span class="col-md-12 col-sm-12">
								<strong>Mappings using this item</strong>
								<span
									v-if="boundMappings.length > 0"
									class="badge badge-pill badge-primary ml-2"
								>{{ boundMappings.length }}</span>
								<b-button
									@click.prevent="getBoundMappings"
									class
									variant="link"
									v-b-popover.hover.top.d500="'Refresh the list of bound mappings.'"
								>
									<i class="fas fa-sync-alt pointer"></i>
								</b-button>
							</span>
						</div>
					</div>
					<div class="idb-block-content" v-if="!isNew">
						<div class="row" v-if="!isNew && uiStage === 1">
							<span></span>
						</div>
						<div v-if="boundMappings.length > 0">
							<div class="row" v-for="m in boundMappings" :key="m.index" :value="m.value">
								<div class="col-md-12 ml-5">
									{{m.metaData.title}} (
									<router-link tag="a" :to="'/automation/mapping/mappingdetail/' + m._id" class="small">Edit</router-link>)
								</div>
							</div>
						</div>
						<br />
					</div>
					<div class="idb-block-footer">
						<div class="row">
							<div class="col-md-12 col-sm-3">
								<b-button
									class="mr-3"
									v-if="isNew && (uiStage === 1)"
									@click.prevent="createImporter"
									:disabled="isLoading"
									variant="primary"
									v-b-popover.hover.top.d500="'Creates and saves a new item.'"
								>Create</b-button>
								<b-button
									class="mr-3"
									v-if="!isNew && (uiStage === 1)"
									@click.prevent="updateImporter"
									:disabled="isLoading"
									variant="primary"
									v-b-popover.hover.top.d500="'Update the current item saving any changes.'"
								>
									<i class="fa fa-edit mr-2"></i>Save
								</b-button>
								<!-- <b-button class="mr-3"  v-if="!isNew" @click.prevent="cloneImporter" variant="outline-primary" :disabled="isLoading" v-b-popover.hover.top.d500="'Create a clone of this item'">Clone</b-button> -->
								<!-- <b-button v-if="!isNew" @click.prevent="deleteImporter" :disabled="!isNew && boundMappings.length > 0" variant="danger" class="pull-right" v-b-popover.hover.top.d500="'Permanently delete this item.'"><i class="fa fa-trash mr-2"></i>Delete</b-button> -->

								<div class="btn-group" role="group">
									<button
										id="btnGroupDrop1"
										type="button"
										class="btn btn-outline-primary dropdown-toggle ml-3"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i class="glyphicon ti-more mr-2"></i>
									</button>
									<div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
										<a @click="cloneImporter" class="dropdown-item" href="#">
											<i class="glyphicon ti-files mr-2"></i>Clone Importer
										</a>
										<a v-if="isCorvidAdmin" @click="btnTransferImporter" class="dropdown-item" href="#">
											<i class="glyphicon ti-new-window mr-2"></i>Copy Importer to Another Customer
										</a>
										<div class="dropdown-divider"></div>
										<a @click.prevent="deleteImporter" class="dropdown-item text-danger" href="#">
											<i class="glyphicon ti-trash mr-2"></i>Delete Importer
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import { required, maxLength } from 'vuelidate/lib/validators'
import vue2Dropzone from 'vue2-dropzone'
import vSelect from 'vue-select'
import Auth from '@/Assets/Components/Authentication/auth'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser'
import draggable from 'vuedraggable'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'

export default {
	components: {
		vueDropzone: vue2Dropzone,
		'v-select': vSelect,
		fileBrowser,
		draggable,
		swal
	},
	mixins: [loading],
	validations: {
		importer: {
			title: {
				required,
				maxLength: maxLength(64)
			},
			description: {
				maxLength: maxLength(1024)
			},
			filePath: {
				maxLength: maxLength(512)
			},
			// filename: {
			//   required,
			//   maxLength: maxLength(128)
			// },
			configuration: {
				required
			},
			parserParams: {
				delimiter: {
					required
				}
			}
			// importer: {
			//   parserParams: {
			//     ignoreFirstRows: {
			//       required,
			//       integer,
			//       minValue: minValue(0),
			//       maxValue: maxValue(9999)
			//     },
			//     ignoreLastRows: {
			//       required,
			//       integer,
			//       minValue: minValue(0),
			//       maxValue: maxValue(9999)
			//     },
			//   }
			// }
		}
	},
	computed: {
		corvidSelectedPaygateId () {
			return this.$store.state.common.paygateId
		},
		isCorvidAdmin () {
			return this.$store.getters.isInRole(roles.SystemUser)
		}
	},
	data () {
		return {
			isLicenced: true,
			docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
			boundMappings: [],
			showModalFileBrowser: false,
			isNew: true,
			id: this.$route.params.id,
			importer: {},
			authToken: '',
			importerTypes: [
				{ text: 'Cron Trigger', value: 'CronTrigger' },
				{ text: 'Schedule Trigger', value: 'ScheduleTrigger' },
				{ text: 'File Trigger', value: 'FileTrigger' }
			],
			dropzoneOptions: {
				url: `${process.env.VUE_APP_WORKFLOW_API_URL}fileupload`,
				maxFilesize: 2, // MB
				maxFiles: 1,
				addRemoveLinks: true,
				thumbnailWidth: 64, // px
				thumbnailHeight: 64,
				acceptedFiles: '.dat,.txt,.csv',
				dictDefaultMessage: '<i class="fas fa-cloud-upload-alt fa-4x"></i><br/><br/><h3 class="text-primary">Drop a delimited file, such as a CSV file, here.</h3><div class="subtitle">Or click to select a file to upload.</div>'
			},
			selectedImporterType: 'Csv',
			// 0 start, 1 imported and received configuration from the server
			uiStage: 0,
			selectedDataType: 'bacsPayments',
			dataTypeMode: [
				{ text: 'BACS/FPS Payments', value: 'bacsPayments' },
				{ text: 'Bureau Customers', value: 'bacsBureauCustomers' },
				{ text: 'ADDACS Report', value: 'addacsReport' }
				// { text: 'UK BACS ADDACS Report', value: 'ukBacsAddacsReport' },
				// { text: 'UK BACS AWACS Report', value: 'ukBacsAwacsReport' },
				// { text: 'UK BACS AUDDIS Report', value: 'ukBacsAuddisReport' },
				// { text: 'UK BACS ARUCS Report', value: 'ukBacsArucsReport' },
				// { text: 'UK BACS ARUDDS Report', value: 'ukBacsAruddsReport' }
			],
			typeMode: [
				{ text: 'Importer', value: 'importer' },
				{ text: 'Exporter', value: 'exporter' }
			],
			substitutionRules: [],
			cleanerRules: [
				{
					rule: 'convertToUpperCase',
					name: 'Convert to Upper Case',
					enabled: false
				},
				{
					rule: 'removeAsciiNulls',
					name: 'Remove ASCII Nulls',
					enabled: false
				},
				{
					rule: 'ConvertLfToCrLf',
					name: 'Convert LF to CRLF',
					enabled: false
				},
				{
					rule: 'ConvertCrToCrLf',
					name: 'Convert CR to CRLF',
					enabled: false
				},
				{
					rule: 'ConvertCrLfToCr',
					name: 'Convert CRLF to CR',
					enabled: false
				},
				{
					rule: 'ConvertCrLfToLf',
					name: 'Convert CRLF to LF',
					enabled: false
				},
				{
					rule: 'ConverCrToLf',
					name: 'Convert CR to LF',
					enabled: false
				},
				{
					rule: 'ConverLfToCr',
					name: 'Convert LF to CR',
					enabled: false
				},
				{
					rule: 'ConvertToUTF8',
					name: 'Convert To UTF-8',
					enabled: false
				}
			],
			cleanOnImport: false,
			isImporter: true,
			selectedCharset: 'bacs',
			charset: [
				{ text: 'BACS', value: 'bacs' },
				{ text: 'Other', value: 'other' }
				// { text: 'SEPA', value: 'sepa' },
				// { text: 'SWIFT X', value: 'swiftx' },
				// { text: 'SWIFT Y', value: 'swifty' },
				// { text: 'SWIFT Z', value: 'swiftz' },
				// { text: 'ASCII', value: 'ascii' },
				// { text: 'UTF-8', value: 'utf8' },
				// { text: 'UTF-16', value: 'utf16' }
			],
			// TODO: Centralise
			bacsReportAddacsDataTypes: [

			],
			bacsDataTypes: [
				'Third-party Sortcode',
				'Third-party Account Number',
				'Third-party Account Type',
				'Third-party Account Name',
				'Originating Sortcode',
				'Originating Account Number',
				'Originating Account Name',
				'Service User Reference',
				'Transaction Code',
				'Amount',
				'Date',
				// 'RTI Reference',
				'Service User Number',
				'Numeric Reference / RTI',
				'Message',
				'File Number',
				'Custom Field 01',
				'Custom Field 02',
				'Custom Field 03',
				'Custom Field 04',
				'Custom Field 05',
				'Custom Field 06',
				'Custom Field 07',
				'Custom Field 08',
				'Custom Field 09',
				'Custom Field 10',
				'Custom Field 11',
				'Custom Field 12',
				'Custom Field 13',
				'Custom Field 14',
				'Custom Field 15',
				'Custom Field 16'
			],
			bureauCustomerDataTypes: [
				'Company Name',
				'Contact First Name',
				'Contact Surname',
				'Address 1',
				'Address 2',
				'Address 3',
				'Address 4',
				'Address 5',
				'Postcode',
				'Telephone 1',
				'Telephone 2',
				'Website URL',
				'Email',
				'SUN',
				'Payment Limit',
				'Submission Limit',
				'Contra Narrative',
				'Bank Name',
				'Bank Reference',
				'Bank Address 1',
				'Bank Address 2',
				'Bank Address 3',
				'Bank Address 4',
				'Bank Address 5',
				'Bank Postcode',
				'Sortcode',
				'Account Number',
				'Default Import Schema',
				'Default Import Mapping',
				'Default Filename',
				'Notes',
				'Custom Field 01',
				'Custom Field 02',
				'Custom Field 03',
				'Custom Field 04',
				'Custom Field 05',
				'Custom Field 06',
				'Custom Field 07',
				'Custom Field 08',
				'Custom Field 09',
				'Custom Field 10',
				'Custom Field 11',
				'Custom Field 12',
				'Custom Field 13',
				'Custom Field 14',
				'Custom Field 15',
				'Custom Field 16'
			],
			ukDdmsVarDataTypes: [
				'Payer Reference',
				'Amount',
				'Plan Name',
				'Group ID'
			]
		}
	},
	methods: {
		async loadLicence () {
			let response
			try {
				response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })
				if (response && response.data && response.data.automation && response.data.automation.mappingDesigner === true) {
					console.log('response.data.automation.mappingDesigner: ', response.data.automation.mappingDesigner)
					this.isLicenced = response.data.automation.mappingDesigner
				} else {
					this.isLicenced = false
				}
			} catch (e) {
				console.log(e.message)
				this.isLicenced = false
			}

			// The exception is if the current user is a system admin then we allow it
			if (this.isCorvidAdmin) {
				this.isLicenced = true
			}



			if (this.isLicenced === false) {
				this.$snapbar.e('Sorry, you are not licensed to use the Mapping Designer and Importers.')
			}
		},
		skipDropZoneStep () {
			this.uiStage = 1
			this.importer.configuration = []
			this.importer.dataType = this.selectedDataType
			this.importer.cleanerParams.cleanOnImport = this.cleanOnImport
			this.importer.cleanerParams.charset = this.selectedCharset
			this.importer.cleanerParams.cleanerRules = this.cleanerRules
			this.importer.cleanerParams.substitutionRules = this.substitutionRules
			this.importer.parserParams = {
				commentChars: '#',
				delimiter: ',',
				hadHeader: false,
				ignoreEmpty: true,
				ignoreFirstRows: 0,
				ignoreLastRows: 0,
				quote: '"',
				trim: true
			}
		},
		endDrag () {
			for (let i = 0; i < this.importer.configuration.length; i++) {
				this.importer.configuration[i].column = i
			}
		},
		async getBoundMappings () {
			this.boundMappings = []
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}boundMappings/${this.id}`)
				if (res) {
					this.boundMappings = res.data.mappings
				}
			} catch (e) {
				this.$snapbar.e(`Error retrieving bound mappings - ${e.message}`)
			}
		},
		truncate (text) {
			if (text.length < 20) {
				return text
			} else {
				return `${text.substring(0, 19)}...`
			}
		},
		vcomplete (file) {
		},
		vfileAdded (file) {
		},
		vsuccess (file, response) {
			// Fires with the response from /fileupload route, i.e. after the dropzone upload
			// We need the server-side filename
			axios
				.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importerparser?type=csv&filename=${response.ssFilename}`)
				.then(res => {
					// this.$toastr.s(`The importer '${this.importer.title}' was successfully parsed.`)
					// this.$router.push('/mapping/import/importers')
					if (res) {
						if (!res.data.isError) {
							this.uiStage = 1
							this.importer.configuration = res.data.columnData
							this.importer.parserParams = res.data.parserParams
						} else {
							this.$snapbar.e(`Parser error (MP0018) - ${res.data.error}`)
						}
					} else {

					}
				})
				.catch(e => {
					this.$snapbar.e(`Parser error (MP0012) - ${e.message}`)
				})
		},
		verror (file, response) {
			console.error('Dropzone:  verror')
			this.$snapbar.e('File read error (MP0013-DZ)')
		},
		createImporter () {
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}
			this.importer.dataType = this.selectedDataType
			this.importer.cleanerParams.cleanOnImport = this.cleanOnImport
			this.importer.cleanerParams.charset = this.selectedCharset
			this.importer.cleanerParams.cleanerRules = this.cleanerRules
			this.importer.cleanerParams.substitutionRules = this.substitutionRules
			axios
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}importer`, this.importer, { showload: true })
				.then(res => {
					this.$toastr.s(`The importer '${this.importer.title}' was successfully created.`)
					this.$router.push('/automation/mapping/import/importers')
				})
				.catch(e => {
					if (e.response && e.response.status === 403) {
						this.$snapbar.e('You are not authorised to create.')
					} else {
						if (e.response && e.response.data && e.response.data.errorMessage) {
							this.$snapbar.e(`Error creating. ${e.response.data.errorMessage}`)
						} else {
							this.$snapbar.e('Error creating.')
						}
					}
				})
		},
		getDataTypeText (selected) {
			const item = this.dataTypeMode.find(x => x.value === selected)
			return item.text
		},
		getTypeText (selected) {
			const item = this.typeMode.find(x => x.value === selected)
			return item.text
		},
		async btnTransferImporter () {
			// Get list of customers
			const customers = this.$store.getters.customersOrdered
			let inputOptions = {}
			// Munge the customer data into something that the SweetAlert will use.
			// Probably a nicer way to do this but it works for now
			let str = '{ '
			customers.forEach(el => {
				const name = el.name.replace(/[^0-9a-z-A-Z ]/g, '').replace(/ +/, ' ')
				const token = `"${el.paygateId}": "${name}", `
				str = str + token
			})
			str = str.substring(0, str.length - 2)
			str = str + '}'
			inputOptions = JSON.parse(str)

			const result = await swal.fire({
				title: 'Copy Importer To Another Customer',
				text: 'Copy the current importer to another customer.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes!',
				cancelButtonText: 'No',
				input: 'select',
				inputOptions: inputOptions
			})
			if (result.isConfirmed) {
				// paygateId of the recipient customer is in result.value
				let transferImporter = this.importer
				transferImporter.paygateId = result.value
				transferImporter.filePath = '\\'

				let res
				try {
					res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}transferimporter`, transferImporter)
					if (res) {
						this.$toastr.s(`The importer '${transferImporter.title}' was successfully transferred.`)
					}
				} catch (e) {
					if (e.response && e.response.data && e.response.data.errorMessage) {
						this.$snapbar.e(`Error transferring importer.  The importer was not transferred. ${e.response.data.errorMessage}`)
					} else {
						this.$snapbar.e('Error transferring importer.  The importer was not transferred.')
					}
				}
			}
		},
		updateImporter () {
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}
			this.importer.dataType = this.selectedDataType

			this.importer.cleanerParams = {
				charset: this.selectedCharset,
				cleanOnImport: this.cleanOnImport,
				substitutionRules: this.substitutionRules,
				cleanerRules: this.cleanerRules
			}
			axios
				.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.importer._id}`, this.importer, { showload: true })
				.then(res => {
					this.$toastr.s(`The importer '${this.importer.title}' was successfully updated.`)
					this.$router.push('/automation/mapping/import/importers')
				})
				.catch(e => {
					if (e.response && e.response.status === 400) {
						this.$snapbar.e(e.response.data.errorMessage)
					} else if (e.response && e.response.status === 403) {
						this.$snapbar.e('You are not authorised to update this item.')
					} else {
						this.$snapbar.e(`Update error (MP0017) - ${e.message}`)
					}
				})
		},
		restartImporter () {
			this.uiStage = 0
			this.isNew = true // ?
			this.getAuthToken()
			this.importer.configuration = null
			this.importer.parserParams = {
				delimiter: ',',
				hasHeader: false,
				ignoreEmpty: true,
				quote: '"',
				trim: true,
				commentChars: '#',
				ignoreFirstRows: 0,
				ignoreLastRows: 0
			}
		},
		async cloneImporter () {
			const result = await swal.fire({
				title: 'Clone Importer',
				text: 'Create an exact copy of the current item.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes!',
				cancelButtonText: 'No',
				input: 'text',
				inputValue: `${this.importer.title} (clone)`
			})
			if (result.isConfirmed) {
				if (result.value) {
					if (!this.importer.configuration) {
						this.importer.configuration = {}
					}
					const cloneImporter = {
						title: result.value,
						description: this.importer.description,
						filePath: this.importer.filePath,
						importerType: this.importer.importerType,
						paygateId: this.importer.paygateId,
						parserParams: this.importer.parserParams,
						configuration: this.importer.configuration,
						dataType: this.selectedDataType,
						type: this.type,
						cleanerParams: {
							cleanOnImport: this.cleanOnImport,
							charset: this.selectedCharset,
							substitutionRules: this.substitutionRules,
							cleanerRules: this.cleanerRules
						}
					}

					try {
						const response = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}importer`, cloneImporter, { showload: true })
						if (response) {
							this.$toastr.s(`The item '${cloneImporter}' was successfully cloned.`)
							this.$router.push('/automation/mapping/import/importers')
						}
					} catch (e) {
						if (e.response && e.response.status === 403) {
							this.$snapbar.e('You are not authorised to clone this item.')
						} else {
							if (e.response && e.response.data && e.response.data.errorMessage) {
								this.$snapbar.e(`Error cloning. ${e.response.data.errorMessage}`)
							} else {
								this.$snapbar.e('Error cloning.')
							}
						}
					}
				}
			}
		},
		async deleteImporter () {
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}
			const result = await swal.fire({
				title: 'Are you sure you want to delete this item?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: colours.danger,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			})
			if (result.isConfirmed) {
				try {
					const response = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.id}`, { showload: true })
					this.$toastr.s(`The importer '${this.importer.title}' was successfully deleted.`)
					this.$router.push('/automation/mapping/import/importers')
				} catch (e) {
					if (e.response && e.response.status === 403) {
						this.$snapbar.e('You are not authorised to delete this item.')
					} else if (e.response && e.response.status === 409) {
						this.$snapbar.e(e.response.data.errorMessage)
					} else {
						this.$snapbar.e(`Could not delete the item - ${e.message}`)
					}
				}
			}
		},
		btnFileBrowser () {
			this.$store.commit('setModalPopupString', this.importer.filePath)
			this.showModalFileBrowser = true
		},
		closePopup () {
			this.importer.filePath = this.$store.state.mapping.modalPopupString
		},
		// getAuthToken () {
		//   Auth.getAccessToken()
		//     .then(token => this.$refs.myVueDropzone.setOption('headers', { Authorization: 'Bearer ' + token }))
		// },
		async getAuthToken () {
			const token = await Auth.getAccessToken()
			if (token) {
				if (this.$refs.myVueDropzone) {
					this.$refs.myVueDropzone.setOption('headers', { Authorization: 'Bearer ' + token })
				}
			}
		},
		btnRemoveSubstitutionRule (index) {
			this.substitutionRules.splice(index, 1)
		},
		btnAddSubstitutionRule () {
			const newRule = {
				enabled: true,
				from: '',
				to: ''
			}
			this.substitutionRules.push(newRule)
		},
		btnRemoveColumn (index) {
			this.importer.configuration.splice(index, 1)
		},
		btnAddColumn () {
			let colNum = 0
			for (const col of this.importer.configuration) {
				if (col.column >= colNum) colNum = col.column + 1
			}

			const newCol = {
				column: colNum,
				enabled: true,
				title: '',
				value: '',
				stringType: ''
			}
			this.importer.configuration.push(newCol)
		},
		async loadImporter () {
			if (this.isLicenced === false) {
				return
			}
			this.$snapbar.hide()
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.id}`, { showload: true })

				if (res && res.data && res.data.importer) {
					this.importer = res.data.importer
					// Default to avoid breaking changes
					// console.log('this.importer.cleanerParams')
					// console.log(this.importer.cleanerParams)
					if (!this.importer.cleanerParams) {
						// console.log('this.importer.cleanerParams is falsey')
						this.importer.cleanerParams = {
							cleanOnImport: true,
							charset: 'bacs',
							cleanerRules: [
								{
									rule: 'convertToUpperCase',
									name: 'Convert to Upper Case',
									enabled: false
								},
								{
									rule: 'removeAsciiNulls',
									name: 'Remove ASCII Nulls',
									enabled: false
								},
								{
									rule: 'ConvertLfToCrLf',
									name: 'Convert LF to CRLF',
									enabled: false
								},
								{
									rule: 'ConvertCrToCrLf',
									name: 'Convert CR to CRLF',
									enabled: false
								},
								{
									rule: 'ConvertCrLfToCr',
									name: 'Convert CRLF to CR',
									enabled: false
								},
								{
									rule: 'ConvertCrLfToLf',
									name: 'Convert CRLF to LF',
									enabled: false
								},
								{
									rule: 'ConverCrToLf',
									name: 'Convert CR to LF',
									enabled: false
								},
								{
									rule: 'ConverLfToCr',
									name: 'Convert LF to CR',
									enabled: false
								},
								{
									rule: 'ConvertToUTF8',
									name: 'Convert To UTF-8',
									enabled: false
								}
							]
						}
						this.importer.cleanerParams.substitutionRules = []
					}
					if (!this.importer.cleanerParams.substitutionRules) {
						this.importer.cleanerParams.substitutionRules = []
						this.substitutionRules = []
					}

					this.selectedDataType = this.importer.dataType
					this.cleanOnImport = this.importer.cleanerParams.cleanOnImport
					this.selectedCharset = this.importer.cleanerParams.charset
					this.cleanerRules = this.importer.cleanerParams.cleanerRules
					this.substitutionRules = this.importer.cleanerParams.substitutionRules

					if (!this.importer.parserParams.ignoreFirstRows) {
						this.importer.parserParams.ignoreFirstRows = 0
					}

					if (!this.importer.parserParams.ignoreLastRows) {
						this.importer.parserParams.ignoreLastRows = 0
					}

					this.selectedDataType = this.importer.dataType
					this.getBoundMappings()
				} else {
					this.importer = this.defaultImporter()
					this.$snapbar.w('Unknown or Invalid item')
				}
			} catch (e) {
				this.importer = this.defaultImporter()
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view this item.')
				} else {
					this.$snapbar.e(`Could not display items - ${e.message}`)
				}
			}
		},
		defaultImporter () {
			return {
				title: '',
				description: '',
				filePath: '\\',
				dataType: this.selectedDataType,
				importerType: 'csv',
				paygateId: this.paygateId,
				configuration: {},
				parserParams: {
					delimiter: ',',
					hasHeader: false,
					ignoreEmpty: true,
					quote: '"',
					trim: true,
					commentChars: '#',
					ignoreFirstRows: 0,
					ignoreLastRows: 0
				},
				cleanerParams: {
					cleanOnImport: true,
					charset: 'bacs',
					cleanerRules: [],
					substitutionRules: []
				}
			}
		},
		capitalise (word) {
			return word.replace(/\b\w/g, l => l.toUpperCase())
		}
	},
	watch: {
		corvidSelectedPaygateId: async function (val) {
			this.loadImporter()
		}
	},
	created: async function () {
		await this.loadLicence()
		this.paygateId = this.$store.getters.getClaim('paygate_id').value
		this.selectedDataType = 'bacsPayments'
		this.importer = this.defaultImporter()
		this.getAuthToken()
		if (!this.id) {
			this.isNew = true
		} else {
			this.isNew = false
			this.uiStage = 1
			this.loadImporter()
		}
	}
}
</script>

<style scoped>
.invalid {
	border-color: red;
	border-width: 2px;
}
label.required {
	/* $gray-700:  */
	color: #878787;
}
label.required::after {
	content: " *";
	color: red;
}
.top-buffer {
	margin-top: 20px;
}

.columnSection {
	padding-bottom: 14px;
	padding-top: 8px;
	border-bottom: #dddddd;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.dropzone-custom-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.fadein-enter {
	opacity: 0;
}

.fadein-enter-active {
	transition: opacity 0.6s;
}

.fadein-leave-active {
	transition: opacity 0.6s;
	opacity: 0;
}
.subtitle {
	color: #314b5f;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited {
	background-color: #c3164f !important;
}

.handle {
	cursor: ns-resize;
}
</style>
