<template>
<div class = "row pb-16">
  <div class="col-md-12">
    <div class = "form-check form-check-inline">
      <label class = "form-check-label">
        <input class = "form-check-input" type = "checkbox"
          :name = "name"
          @input = "$emit('input',$event.target.checked)"
          :checked="value"
          :placeholder = "placeholder" >
        {{label}}
      </label>
    </div>
  </div>
</div>

</template>
<script>
export default {
  name: 'Checkbox',
  props: [
    'placeholder',
    'label',
    'name',
    'value'
  ]
}
</script>
