<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <h5 class="unpad">Workflow Heatmap <span><b-button @click.prevent="getHeatmapData" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the heatmap'" ><i class="fas fa-sync-alt pointer"></i></b-button></span></h5>
        <span class="small"><strong>{{workflow.title}} (</strong></span>
        <router-link tag="a" :to="'/automation/workflow/workflowdetail/' + workflowId" class="small">View</router-link>
        <span>)</span>
        <br>
        <span class="small">{{niceDateTime()}}</span>
      </div>
      <div class="col-md-9">
        <div class="heatmap-widget">
          <calendar-heatmap
            tooltip-unit="executions"
            @day-click="heatmapClick"
            :endDate=getHeatmapEnddate()
            :values=activityData
            :range-color=rangeColours
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { CalendarHeatmap } from 'vue-calendar-heatmap'
export default {
  components: {
    CalendarHeatmap
  },
  name: 'WorkflowHeatmapWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        this.getHeatmapData()
        this.getWorkflow()
      },
      deep: true
    }
  },
  data () {
    return {
      workflow: {},
      activityData: [],
      intervalId: null
    }
  },
  mounted () {
    this.getHeatmapData()
    this.getWorkflow()
    this.intervalId = setInterval(this.getHeatmapData, 30000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.intervalId)
    })
  },
  computed: {
    workflowId () {
      return this.widget.options.find(o => o.label === 'Workflow').value
    },
    rangeColours () {
      if (this.$store.getters.getClaim('theme').value === 'dark') {
        return ['#777777', '#136fc9', '#0e59a4', '#12427a', '#0b224f']
      }
      return ['#ebedf0', '#c0ddf9', '#73b3f3', '#3886e1', '#17459e']
    }
  },
  methods: {
    getHeatmapEnddate () {
      return new Date().toISOString().split('T')[0]
    },
    getHeatmapData () {
      axios
        .get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowheatmapdata/${this.workflowId}`)
        .then(res => {
          this.activityData = res.data
        })
        .catch(e => {
          console.log('error: ' + e.message)
        })
    },
    getWorkflow () {
      axios
        .get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflow/${this.workflowId}`)
        .then(res => {
          this.workflow = res.data.workflow
        })
        .catch(e => {
          console.log('error: ' + e.message)
        })
    },
    heatmapClick (evt) {
      const d = new Date(evt.date)
      const ts = d.getTime()
      this.$router.push(`workflow/workflowdetail/${this.workflowId}?d=${ts}`)
    },
    niceDateTime () {
      const myDate = new Date()
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      const reformatted = dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
      return reformatted
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    }
  }
}
</script>
