var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "manageBlockModal",
      attrs: {
        id: "manage-block-modal",
        title: "Block Details",
        size: "xl",
        "hide-header-close": "",
        static: "",
        "cancel-variant": "danger",
        "ok-disabled": _vm.$v.$invalid
      },
      on: {
        ok: _vm.SaveBlockDetail,
        shown: _vm.loadBlockDetails,
        hidden: _vm.clearForm
      }
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v("Save")
      ]),
      _c(
        "div",
        [
          _vm.isNew
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c("label", { attrs: { for: "customerName" } }, [
                      _vm._v("Originating Customer")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "7" } },
                    [
                      _c("model-list-select", {
                        attrs: {
                          id: "customerName",
                          list: _vm.bureauCustomers,
                          "option-value": "bureauCustomerId",
                          "option-text": "name",
                          placeholder: "Select Customer",
                          disabled: _vm.readonly
                        },
                        on: {
                          input: function($event) {
                            return _vm.selectBureauCustomer()
                          }
                        },
                        model: {
                          value: _vm.$v.bureauCustomerId.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.bureauCustomerId, "$model", $$v)
                          },
                          expression: "$v.bureauCustomerId.$model"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isNew
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c("label", { attrs: { for: "customerName" } }, [
                      _vm._v("Originating Customer")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "7" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "customerName",
                          readonly: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.$v.customerName.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.customerName,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.customerName.$model"
                        }
                      }),
                      _vm.$v.serviceUserNumber.$dirty
                        ? _c("span", [
                            !_vm.$v.serviceUserNumber.maxLength
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Service User Number must be less than " +
                                        _vm._s(
                                          _vm.$v.serviceUserNumber.$params
                                            .maxLength.max
                                        ) +
                                        " characters"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.serviceUserNumber.required
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small text-nowrap"
                                  },
                                  [_vm._v("Service User Number is required")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isNew
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "serviceUserNumber" }
                      },
                      [_vm._v("Service User Number")]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "3" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "serviceUserNumber",
                          type: "text",
                          formatter: _vm.upperCase,
                          disabled: true
                        },
                        model: {
                          value: _vm.$v.serviceUserNumber.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.serviceUserNumber,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.serviceUserNumber.$model"
                        }
                      }),
                      _vm.$v.serviceUserNumber.$dirty
                        ? _c("span", [
                            !_vm.$v.serviceUserNumber.maxLength
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Service User Number must be less than " +
                                        _vm._s(
                                          _vm.$v.serviceUserNumber.$params
                                            .maxLength.max
                                        ) +
                                        " characters"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.serviceUserNumber.required
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small text-nowrap"
                                  },
                                  [_vm._v("Service User Number is required")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isNew
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c(
                      "label",
                      { staticClass: "required", attrs: { for: "sortCode" } },
                      [_vm._v("Sort Code")]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "2" } },
                    [
                      _c("the-mask", {
                        key: _vm.updateKey,
                        ref: "sortCode",
                        staticClass: "form-control",
                        attrs: {
                          id: "sortCode",
                          type: "text",
                          mask: ["##-##-##"],
                          guide: false,
                          disabled: !_vm.noBankDetailsForCustomer
                        },
                        model: {
                          value: _vm.$v.sortCode.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.sortCode,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.sortCode.$model"
                        }
                      }),
                      _vm.$v.sortCode.$dirty
                        ? _c("span", [
                            !_vm.$v.sortCode.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-danger small text-nowrap"
                                  },
                                  [_vm._v("Sort Code is required")]
                                )
                              : _vm._e(),
                            !_vm.$v.sortCode.minLength
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Sort Code must be at least " +
                                        _vm._s(
                                          _vm.$v.sortCode.$params.minLength.min
                                        ) +
                                        " numbers"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.sortCode.maxLength
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Sort Code must be less than " +
                                        _vm._s(
                                          _vm.$v.sortCode.$params.maxLength.max
                                        ) +
                                        " numbers"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.sortCodeValid && _vm.$v.sortCode.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-warning small text-nowrap"
                                  },
                                  [_vm._v(_vm._s(_vm.sortCodeErrorMessage))]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "span",
                    [
                      _vm.sortCodeValid
                        ? _c("b-col", [
                            _c("i", { staticClass: "fas fa-check mt-2" })
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isNew
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "accountNumber" }
                      },
                      [_vm._v("Account Number")]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "2" } },
                    [
                      _c("the-mask", {
                        ref: "originatingAccountNumber",
                        staticClass: "form-control",
                        attrs: {
                          id: "accountNumber",
                          type: "text",
                          mask: ["########"],
                          disabled: !_vm.noBankDetailsForCustomer
                        },
                        model: {
                          value: _vm.$v.accountNumber.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.accountNumber,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.accountNumber.$model"
                        }
                      }),
                      _vm.$v.accountNumber.$dirty
                        ? _c("span", [
                            !_vm.$v.accountNumber.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-danger small text-nowrap"
                                  },
                                  [_vm._v("Account Number is required")]
                                )
                              : _vm._e(),
                            !_vm.$v.accountNumber.minLength
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Account Number must be at least " +
                                        _vm._s(
                                          _vm.$v.accountNumber.$params.minLength
                                            .min
                                        ) +
                                        " numbers"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.accountNumber.maxLength
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Account Number must be less than " +
                                        _vm._s(
                                          _vm.$v.accountNumber.$params.maxLength
                                            .max
                                        ) +
                                        " numbers"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.accountNumberValid &&
                            _vm.$v.accountNumber.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "form-text text-warning small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.accountNumberErrorMessage)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "span",
                    [
                      _vm.accountNumberValid == true
                        ? _c("b-col", [
                            _c("i", { staticClass: "fas fa-check mt-2" })
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c("label", { attrs: { for: "contraNarrative" } }, [
                      _vm._v("Contra Narrative")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "4" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "contraNarrative",
                          type: "text",
                          disabled: "",
                          formatter: _vm.upperCase
                        },
                        model: {
                          value: _vm.$v.contraNarrative.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.contraNarrative,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.contraNarrative.$model"
                        }
                      }),
                      _vm.$v.contraNarrative.$dirty
                        ? _c("span", [
                            !_vm.$v.contraNarrative.maxLength
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "text-danger small text-nowrap"
                                  },
                                  [
                                    _vm._v(
                                      "Contra Narrative must be less than " +
                                        _vm._s(
                                          _vm.$v.contraNarrative.$params
                                            .maxLength.max
                                        ) +
                                        " characters"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isFps
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c("label", { attrs: { for: "modalPaymentDate" } }, [
                      _vm._v("Block Payment Date")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "7" } },
                    [
                      _c("vuejsDatepicker", {
                        ref: "paymentDate",
                        attrs: {
                          name: "paymentDate",
                          id: "modalPaymentDate",
                          format: "dd/MM/yyyy",
                          "input-class": "form-control",
                          "bootstrap-styling": true,
                          disabledDates: _vm.disabledDates,
                          "monday-first": true,
                          disabled: _vm.locked
                        },
                        on: { selected: _vm.paymentDateChanged },
                        nativeOn: {
                          focusin: function($event) {
                            return _vm.dateOnfocus($event)
                          }
                        },
                        model: {
                          value: _vm.paymentDate,
                          callback: function($$v) {
                            _vm.paymentDate = $$v
                          },
                          expression: "paymentDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isFps
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { sm: "4" } }, [
                    _c("label", { attrs: { for: "modalProcessingDate" } }, [
                      _vm._v("Block Processing Date")
                    ])
                  ]),
                  _c("b-col", { attrs: { sm: "7" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.processingDate,
                          expression: "processingDate"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { id: "modalProcessingDate", disabled: "" },
                      domProps: { value: _vm.processingDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.processingDate = $event.target.value
                        }
                      }
                    })
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.validationErrors.length > 0 ? _c("hr") : _vm._e(),
          _vm.validationErrors.length > 0
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "mt-2" }, [
                    _c("label", [
                      _vm._v("Block Validation Warnings and Errors")
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.validationErrors, function(validationError) {
            return _c(
              "b-row",
              { key: validationError },
              [
                _c("b-col", { attrs: { sm: "12" } }, [
                  _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(validationError))
                  ])
                ])
              ],
              1
            )
          }),
          _c(
            "b-row",
            { attrs: { hidden: "" } },
            [_c("b-col", [_vm._v(_vm._s(_vm.$v.$invalid))])],
            1
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }