<template>
  <div>
    <vue-good-table
      ref="bureauCustomerTable"
      mode="remote"
      :paginationOptions="{
        enabled: true,
        perPage: 10,
      }"
      :rows="rows"
      :columns="columns"
      :totalRows="totalRecords"
      :searchOptions="{
        enabled: true,
      }"
      :selectOptions="{
        enabled: true,
        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      }"
      :row-style-class="rowStyleClassFn"
      @on-search="onSearch"
      @on-row-click="onRowClick"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    ></vue-good-table>

    <b-row class="mt-3">
      <b-col>
        <b-btn class="mr-2" variant="primary" @click="onAddSelected">Add Selected</b-btn>
        <b-btn class="mr-2" variant="primary" @click="onAddUnallocated">
          {{
          addUnallocatedLabel
          }}
        </b-btn>
        <b-btn class="float-right" variant="primary" @click="onCancel">Cancel</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Already In Job',
          field: 'injob',
          sortable: false
        },
        {
          label: 'Schema',
          field: 'defaultImportSchema',
          hidden: true
        },
        {
          label: 'Mapping',
          field: 'defaultImportMapping',
          hidden: true
        },
        {
          label: 'Filename',
          field: 'defaultImportFilename',
          hidden: true
        }
      ],
      sort: {
        field: '',
        type: ''
      },
      page: 1,
      perPage: 10,
      totalRecords: 0,
      searchTerm: '',
      addUnallocatedLabel: 'Add Not In Job'
    }
  },
  created () {
    this.$store.commit('setBureauCustomerSelectorSelection', [])
    this.sort = 'name'
    this.load()
  },
  props: ['customerIdsInJob'],
  methods: {
    onCancel () {
      this.$store.commit('setBureauCustomerSelectorReturnType', 'Cancel')
      this.$store.commit('setBureauCustomerSelectorFilter', this.searchTerm)
      this.$emit('hideCustomerSelector')
    },
    onAddSelected () {
      this.$store.commit('setBureauCustomerSelectorReturnType', 'Selection')
      this.$store.commit('setBureauCustomerSelectorSelection', this.$refs.bureauCustomerTable.selectedRows)
      this.$emit('hideCustomerSelector')
    },
    onAddUnallocated () {
      this.$store.commit('setBureauCustomerSelectorReturnType', 'AddUnallocated')
      this.$store.commit('setBureauCustomerSelectorFilter', this.searchTerm)
      this.$emit('hideCustomerSelector')
    },
    onSearch (params) {
      this.searchTerm = params.searchTerm
      this.addUnallocatedLabel = 'Add Not In Job'
      if (this.searchTerm !== '') {
        this.addUnallocatedLabel += ' (filtered)'
      }
      this.load()
    },
    onRowClick (params) {
    },
    onPageChange (params) {
      this.page = params.currentPage
      this.load()
    },
    onPerPageChange (params) {
      this.perPage = params.currentPerPage
      this.load()
    },
    onSortChange (params) {
      this.sort = `${[this.columns[params.columnIndex].field]} ${params.sortType}`
      this.load()
    },
    onColumnFilter (params) {
      this.updateParams(params)
      this.load()
    },
    rowStyleClassFn (row) {
      return row.vgtSelected ? 'isselected' : ''
    },
    load: _.debounce(async function () {
      var query = {
        params: {
          fields: 'name,serviceusernumber,bureaucustomerid'
        }
      }
      query.params.paygateId = this.$store.state.common.paygateId
      query.params.sort = this.sort
      query.params.perPage = this.perPage
      query.params.page = this.page
      //      query.params.bureauCustomerList = 'bureauCustomerList'
      if (this.searchTerm !== '') {
        query.params.searchFilter = this.searchTerm
      }


      await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer', query)
        .then(response => {
          this.rows = response.data.data
          this.rows.forEach(row => {
            // Check if customer already in job
            var inJobIndex = this.customerIdsInJob.findIndex(function (id) {
              return id === row.bureauCustomerId
            })
            if (inJobIndex >= 0) {
              row.injob = 'Yes'
            }
          })
          this.totalRecords = response.data.meta.totalItems
          this.page = 1
        })
        .catch(error => console.error(error))
    }, 200)
  }
}

</script>

<style>
.isselected {
  background-color: lightgray !important;
}
</style>
