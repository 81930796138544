var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "synchronisationReportDetails" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c("div", { staticClass: "idb-block" }, [
                _c("div", { staticClass: "idb-block-title" }, [
                  _c(
                    "h2",
                    [
                      _c("help-icon", {
                        attrs: {
                          docPath: "/paygate-collections/reconciliation/"
                        }
                      }),
                      _vm._v("Reconciliation History"),
                      _c("favourite-icon")
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "idb-block-content" },
                  [
                    _c("p", [
                      _vm._v(
                        "Click on a row to see the results of a specific processing batch."
                      )
                    ]),
                    _c("vue-good-table", {
                      ref: "reportList",
                      attrs: {
                        columns: _vm.columns,
                        rows: _vm.reports,
                        "pagination-options": {
                          enabled: true,
                          perPage: 10,
                          dropdownAllowAll: false
                        },
                        styleClass: "vgt-table striped bordered"
                      },
                      on: { "on-row-click": _vm.viewReport },
                      scopedSlots: _vm._u([
                        {
                          key: "table-row",
                          fn: function(props) {
                            return [
                              props.column.field == "groupname"
                                ? _c("span", [
                                    _c("div", {
                                      staticClass: "group-colour",
                                      style: {
                                        "background-color":
                                          props.row.groupcolour
                                      }
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }