<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2>
        Pre-Submission Validation for '{{ this.submissionDetails.submissionReference }}'
        <help-icon :docPath="'/bacsbureau/submissions/'"></help-icon>
      </h2>
    </div>

    <!-- Summary Information -->
    <div class="idb-block-content">
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <strong>Submission Reference</strong>
            </b-col>
            <b-col>{{ this.submissionDetails.submissionReference }}</b-col>
          </b-row>
          <b-row v-if="this.submissionDetails.contraNarrative !== 'CONTRA' && this.submissionDetails.contraNarrative.length > 0">
            <b-col>
              <strong>Contra Narrative</strong>
            </b-col>
            <b-col>{{ this.submissionDetails.contraNarrative }}</b-col>
          </b-row>
          <b-row  v-if="!isFps">
            <b-col>
              <strong>Default Payment Date</strong>
            </b-col>
            <b-col>{{ this.submissionDetails.paymentDate | formatDate('DD/MM/YYYY') }}</b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Default Processing Date</strong>
            </b-col>
            <b-col>{{ this.submissionDetails.processingDate | formatDate('DD/MM/YYYY')}}</b-col>
          </b-row>
          <b-row v-if="false">
            <b-col>
              <strong>Submission Status</strong>
            </b-col>
            <b-col>{{ this.bureauSubmissionStatus }}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <strong>Must Fix Errors</strong>
            </b-col>
            <b-col>{{ this.numberOfMustFix | number }}</b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Warnings</strong>
            </b-col>
            <b-col>{{ this.numberOfWarnings | number }}</b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Duplicates</strong>
            </b-col>
            <b-col>{{ this.numberOfDuplicates | number }}</b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Information Messages</strong>
            </b-col>
            <b-col>{{ this.numberOfMessages | number }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <table class="table table-striped payment-details-summary pt-0 mt-0">
            <thead class="pt-0 mt-0">
              <tr class="pt-0 mt-0">
                <th class="pt-0 mt-0">
                  <span class="sr-only">Transaction</span>
                </th>
                <th scope="col" class="text-right pt-0 mt-0">
                  <strong>Items</strong>
                </th>
                <th scope="col" class="text-right pt-0 mt-0">
                  <strong>Total (£)</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Credits</strong>
                </td>
                <td class="text-right">{{ this.totalCreditCount | number }}</td>
                <td class="text-right">{{ this.totalCreditValue | currency }}</td>
              </tr>
              <tr  v-if="!isFps">
                <td>
                  <strong>Debits</strong>
                </td>
                <td class="text-right">{{ this.totalDebitCount | number }}</td>
                <td class="text-right">{{ this.totalDebitValue | currency }}</td>
              </tr>
              <tr  v-if="!isFps">
                <td>
                  <strong>DDIs</strong>
                </td>
                <td class="text-right">{{ this.totalDDICount | number }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <b-row>
            <b-col>
              <br />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Bureau Service User Number</strong>
            </b-col>
            <b-col>{{ this.submissionDetails.groupServiceUserNumber }}</b-col>
          </b-row>

          <b-row>
            <b-col>
              <strong>Submission Type</strong>
            </b-col>
            <b-col>{{ this.PaymentNetworkTypeName }}</b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Work Code</strong>
            </b-col>
            <b-col>{{ this.submissionDetails.workCode }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <hr class="mt-2" v-if="validationMessages.length>0" />

    <!-- Validation Messages -->
    <div class="idb-block-content" v-if="validationMessages.length > 0">
      <div class="row">
        <div class="col-md-12">
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            ref="table"
            :totalRows="totalRecords"
            :isLoading.sync="isTableLoading"
            :paginationOptions="paginationOptions"
            :rows="validationMessages"
            :columns="validationFields"
            :sort-options="{
                        enabled: true,
                        initialSortBy: {field: 'messageSeverity', type: 'desc'}
                      }"
            styleClass="vgt-table striped bordered"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'messageSeverity'">
                <span
                  v-bind:class="getSeverityClass(props.row.messageSeverity)"
                  class="badge badge-pill table-pill"
                >{{ props.row.messageSeverity }}</span>
              </span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>

    <div class="idb-block-footer mt-4">
      <button
        class="btn btn-success"
        :disabled="!(numberOfMustFix==0) || isLoading || submitClicked"
        @click.stop="onSubmitClick()"
      >Process Submission</button>
      <button
        class="btn btn-primary ml-2"
        @click="onViewPaymentsClick()"
        :disabled="isLoading"
      >View Submission Details</button>
      <button
        class="btn btn-danger pull-right"
        @click.stop="onCancelSubmission()"
        :disabled="isLoading"
      >Cancel Submission</button>
    </div>
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import swal from "sweetalert2";
import colours from "@/Assets/Constants/colours";
import bacsCommon from "@/Lib/BacsCommon";

export default {
  mixins: [Utility, loading, tableFilterMixin],
  data () {
    return {
      totalDebitCount: 0,
      totalDebitValue: 0.00,
      totalCreditCount: 0,
      totalCreditValue: 0.00,
      totalDDICount: 0,
      numberOfMustFix: 0,
      numberOfWarnings: 0,
      numberOfMessages: 0,
      numberOfDuplicates: 0,
      submissionDetails: [],
      bureauSubmissionStatus: '',
      percentComplete: 0,
      submissionId: '',
      hubUrl: '',
      validationMessages: [],
      validationFields: [
        {
          field: 'recordNumber',
          label: 'Record Number'
        },
        {
          field: 'fileNumber',
          label: 'File'
        },
        {
          field: 'title',
          label: 'Title'
        },
        {
          field: 'description',
          label: 'Description'
        },
        {
          field: 'presubValArea',
          label: 'Area'
        },
        {
          field: 'messageSeverity',
          label: 'Severity'
        }
      ],
      currentPage: 1,
      submitClicked: false,
      isFps: false
    }
  },
  props: {

  },
  computed: {
    PaymentNetworkTypeName: function () {
      if (this.submissionDetails.paymentNetworkType === 0) { return 'BACS' } else { return 'FPS' }
    }

  },
  beforeDestroy () {
    if (this.statusConnection !== null) {
      this.statusConnection.stop()
    }
  },
  async created () {
    this.submissionId = this.$route.params.id
    await this.GetBureauLicenseCheck()
  },
  async mounted () {
    this.hubUrl = process.env.VUE_APP_BUREAU_API_URL + 'hubs/bureaustatushub?paygateId=' + this.paygateId
    var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getSubmissionData/' + this.submissionId, { showload: true })
    console.log('Got bureau sub data', response.data)
    this.submissionDetails = response.data
    this.isFps = response.data.paymentNetworkType === 1

    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        this.statusConnection = new HubConnectionBuilder().withUrl(this.hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.statusConnection.start()

        this.statusConnection.on('Refresh', data => {
          console.info('Refresh')
          console.info(data)
          if (data.hasOwnProperty('submissionValidationSummary')) {
            this.numberOfMustFix = data.submissionValidationSummary.numberOfMustFix
            this.numberOfWarnings = data.submissionValidationSummary.numberOfWarnings
            this.numberOfMessages = data.submissionValidationSummary.numberOfMessages
            this.numberOfDuplicates = data.submissionValidationSummary.numberOfDuplicates
            this.percentComplete = data.submissionValidationSummary.bureauSubmissionStatus
            this.percentComplete = data.submissionValidationSummary.percentComplete
            if (!data.submissionValidationSummary) {
              this.validationMessages = data.submissionValidationSummary.validationMessages
            }
          }
        })
      })

    window.addEventListener('beforeunload', this.stopSocketListener)

    await this.getSummaryInfo()
    await this.getValidationResults()
    this.submitClicked = false
  },
  methods: {
    async getSummaryInfo () {
      try {
        console.info('getSummaryInfo')
        const getsubmissioninforesponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissionsummaryinfo/' + this.submissionId, { showload: true })
        console.info(getsubmissioninforesponse)
        this.totalDebitCount = getsubmissioninforesponse.data.debitCount
        this.totalDebitValue = getsubmissioninforesponse.data.debitValue
        this.totalCreditCount = getsubmissioninforesponse.data.creditCount
        this.totalCreditValue = getsubmissioninforesponse.data.creditValue
        this.totalDDICount = getsubmissioninforesponse.data.ddiCount
      } catch (error) {
        console.error('getSummaryInfo')
        console.error(error)
      }
    },
    async load () {
      console.log('table')
      if (this.submissionId && this.submissionId !== '' && this.submissionId !== null) {
        await this.getValidationResults()
      }
    },
    async getValidationResults () {
      this.isTableLoading = true
      console.info('getValidationResults')
      const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getvalidationinfo/' + this.submissionId, { showload: true, params: this.buildGoodTableQuery() })
      console.info(response)
      this.numberOfMustFix = response.data.numberOfMustFix
      this.numberOfWarnings = response.data.numberOfWarnings
      this.numberOfMessages = response.data.numberOfMessages
      this.numberOfDuplicates = response.data.numberOfDuplicates
      this.validationMessages = response.data.validationMessages
      this.bureauSubmissionStatus = response.data.bureauSubmissionStatus
      this.totalRecords = response.data.totalRecords
      this.isTableLoading = false
    },
    async onValidateClick () {
      try {
        const data = {
          bureauSubmissionId: this.submissionId
        }
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/validatesubmission', data, { showload: true })
        await this.getValidationResults()
        await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/endTask?submissionId=' + this.submissionId + '&fileNumber=0', { showload: true })
        this.$toastr.s(`Bureau Submission ${this.submissionDetails.submissionReference} validated`, 'Bureau Submission')
      } catch (error) {

      }
    },
    onViewPaymentsClick () {
      // Show validation results?
      this.$router.push({ name: 'PaymentDetails' })
    },
    getSeverityClass (severity) {
      if (severity === 'Duplicate') {
        return 'badge-warning'
      } else if (severity.toLowerCase() === 'fix') {
        return 'badge-danger'
      } else {
        return 'badge-' + severity.toLowerCase()
      }
    },
    async onSubmitClick () {
      if (!this.submitClicked) {
        this.submitClicked = true
        console.info('Save the submission')
        try {
          await axios.put(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/zeroimportfilestaskprogress/${this.submissionId}`, {}, { showload: true })
          var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/startsubmission', { submissionId: this.submissionId }, { showload: true })
          console.info('save sub response', response)
          if (response.data.toastType === 2) {
            try {
              await axios.post(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/updatelastmodified/${this.submissionId}`)
            } catch (e) {
              console.log('Could not update last modified date')
            }
            this.$toastr.s(`Bureau Submission ${this.submissionDetails.submissionReference} created`, 'Bureau Submission')
            // Go off to the Bacs Actions here
            console.log('getting actionurl with submissionid: ', this.submissionId)
            var actionUrl = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${this.submissionId}`, { showload: true })

            if (actionUrl.data) {
              // Check if they can approve the action
              try {
                var actualActionCheck = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}actions/${actionUrl.data.id}`)
                console.log(actualActionCheck)
                this.$router.replace(returnRouteObject(actionUrl.data))
              } catch {
                this.autoReturnToHomePage = true
                this.$router.push('/')
              }

            } else {
              this.autoReturnToHomePage = true
              this.$router.push('/')
            }
          } else {
            this.$toastr.e('Could not submit Bureau Submission')
          }
        } catch (e) {
          this.$toastr.e('Could not submit Bureau Submission')
          this.submitClicked = false
        }
      }
    },
    async onCancelSubmission () {
      this.canDeleteAction = false
      const submissionId = this.submissionId
      const submissionReference = this.submissionDetails.submissionReference

      event.stopPropagation()

      const swalResult = await swal.fire({
        title: 'Cancel Submission',
        text: `Are you sure you want to cancel the submission: ${submissionReference}`,
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          this.loading = true
          try {
            await axios.post(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/updatelastmodified/${this.submissionId}`)
          } catch (e) {
            console.log('Could not update last modified date')
          }
          if (this.submissionDetails.status === 1) {
            var nextStatus = 'Created'
            switch (this.submissionDetails.bacsPaymentStatus) {
              case 'Created':
                nextStatus = 'Signed'
                break
              case 'Signed':
                nextStatus = 'Approved'
                break
              case 'Approved':
                nextStatus = 'Submitted'
                break
            }
            var action = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${submissionId}`, { showload: true })
            var json = JSON.stringify({
              submissionId: submissionId,
              actionId: action.data.id,
              nextStatus: nextStatus
            })

            var CanProceeedResponse = await axios({
              method: 'POST',
              url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
              data: json
            })

            if (CanProceeedResponse.data.success) {
              var cancelReponse = await bacsCommon.cancelSubmissionDialog()
              if (cancelReponse.cancel === true) {
                var group = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getbureaugroup/${this.submissionDetails.groupId}`, { showload: true })

                json = JSON.stringify({
                  submissionId: submissionId,
                  actionId: action.data.id,
                  serviceUserNumber: group.data.serviceUserNumber,
                  fileNumber: 0,
                  fromRecordNumber: 0,
                  toRecordNumber: 0,
                  deletePaymentData: group.data.deletePaymentData,
                  cancellationReason: cancelReponse.cancellationReason
                })

                await bacsCommon.confirmCancellation(json, axios, this.$toastr, this.$router)
                await this.$store.dispatch('cancelCollectionsSubmission', submissionId)
              } else {
                var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + submissionId
                await axios.get(endTaskUrl)
                this.isLoaded = true
              }
            } else {
              var endTaskUrlCancel = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + submissionId
              await axios.get(endTaskUrlCancel)
              //                this.isLoaded = true
            }
          } else {
            const response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteSubmission/' + submissionId, {})

            if (response.data.status === 'Deleted') {
              this.$toastr.s(`Bureau Submission ${submissionReference} deleted`, 'Bureau Submission')
              this.$store.dispatch('updateBureauTopSeverityLevel')
              this.$store.dispatch('updateBureauSubmissionPill')
              this.$router.push({ name: 'SubmissionsInProgress' })
            } else {
            }
          }
        } catch (error) {
          console.error('Failed to call API')
          console.error(error)
        }

        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
.btn-secondary,
.btn-secondary:active,
.btn-secondary:hover,
.btn-secondary:focus {
  background: linear-gradient(#f4f5f8, #f1f3f6);
  border: 0px solid #dcdfe6;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-radius: 0;
}

.progress,
.progress-bar {
  height: 2rem;
}

.payment-details-summary * {
  padding: 0;
  color: #212224;
}
</style>
