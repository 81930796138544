<template>
  <div id="importingPayments">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h3>Importing submission files, please wait...</h3>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-12">
                <b-progress striped :animated="true" :max="max" class="mb-2">
                  <b-progress-bar :value="importProgress" :label="`Importing ${importProgress}%`"></b-progress-bar>
                  <b-progress-bar
                    :value="processingProgress"
                    variant="info"
                    :label="`Processing ${processingProgress}%`"
                  ></b-progress-bar>
                  <b-progress-bar
                    :value="validationProgress"
                    variant="success"
                    :label="`Validating ${validationProgress}%`"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <div v-if="this.monitorStatus == 'Compiling'">Gathering data to import</div>
                <div v-else-if="this.monitorStatus == 'Importing'">
                  Importing file {{fileImportedCount + fileErrorCount + ( fileImportedCount < totalFiles ? 1 : 0)}} of {{totalFiles}}: {{currentFilename}}
                  <br />
                  {{fileErrorCount}} files cannot be imported
                </div>
                <div v-else-if="this.monitorStatus == 'Imported'">
                  Processing file {{fileCompletedCount + fileErrorCount}} of {{totalFiles}}: {{currentFilename}}
                  <br />
                  {{totalFiles - (fileCompletedCount + fileErrorCount)}} files remaining
                  <br />
                  {{fileErrorCount}} files cannot be imported
                </div>
                <div v-else-if="this.monitorStatus == 'Validating'">
                  Validating submission {{ fileValidationPercent }}%
                  <br />
                  {{fileErrorCount}} files cannot be imported
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Utility from '@/Assets/Mixins/Utility'

export default {
  props: {
    id: String,
    startProcess: Boolean
  },
  mixins: [Utility],
  data () {
    return {
      max: 300,
      importProgress: 0,
      processingProgress: 0,
      validationProgress: 0,
      counter: 0,
      getTaskProgressUrl: '',
      endTaskUrl: '',
      // counters
      fileImportedCount: 0,
      fileCompletedCount: 0,
      totalFiles: 0,
      filePendingCount: 0,
      fileErrorCount: 0,
      fileValidationPercent: 0,
      currentFilename: '',
      monitorStatus: 'Compiling',
      totalProgress: 0,
      readyToStart: true,
      intervalId: null
    }
  },

  mounted () {
    this.getTaskProgressUrl = `${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/GetImportFilesTaskProgress/${this.id}`
    this.endTaskUrl = `${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/EndImportFilesTask/${this.id}`

    this.intervalId = setInterval(async () => {
      var response = await axios.get(this.getTaskProgressUrl, { showload: false })
      this.importProgress = response.data.importProgress
      this.processingProgress = response.data.processingProgress
      this.validationProgress = response.data.validationProgress
      this.fileImportedCount = response.data.fileImportedCount
      this.fileCompletedCount = response.data.fileCompletedCount
      this.totalFiles = response.data.totalFiles
      this.filePendingCount = response.data.filePendingCount
      this.fileErrorCount = response.data.fileErrorCount
      this.currentFilename = response.data.currentFilename
      this.monitorStatus = response.data.monitorStatus
      this.fileValidationPercent = response.data.fileValidationPercent
      if (this.monitorStatus === 'Completed' || this.monitorStatus === 'Error') {
        if (this.intervalId !== null) {
          clearInterval(this.intervalId)
        } else {
        }
        if (this.$route.name === 'ManualBacsImportPaymentFiles') {
          this.$router.push({name: 'ImportedPaymentFilesSummary', params: {id: this.id}})
        } else {
          this.$toastr.s('Your Bureau Builder Import has finished.')
        }
      }
    }, 5000)
  },
  beforeRouteLeave (to, from, next) {
    if (this.intervalId !== undefined && this.intervalId !== null) {
      clearInterval(this.intervalId)
    }
    next()
  },
  async created () {
    await this.GetBureauLicenseCheck()
    this.readyToStart = this.startProcess
    if (this.readyToStart) {
      this.readyToStart = false
      await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/manualbacsbureaustartimportfiles/${this.id}`, { showload: false })
    }
  }
}
</script>
