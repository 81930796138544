
// Actions List
const ActionsList = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Actions/ActionsList')
const ActionNotFound = () => import(/* webpackChunkName: "action-not-found" */ '@/Assets/Components/Actions/ActionNotFound')

// Change Settings
const ChangeSettings = () => import(/* webpackChunkName: "usersettings" */ '@/Assets/Components/UserSettings/ChangeSettings/ChangeSettings')
const ChangePassword = () => import(/* webpackChunkName: "usersettings" */ '@/Assets/Components/UserSettings/ChangePassword/ChangePassword')
const ViewUsageSettings = () => import(/* webpackChunkName: "viewUsagesettings" */ '@/Assets/Components/UserSettings/ChangeSettings/ViewUsageSettings')
// dashboard views
const Dashboard = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Dashboard/Dashboard')
// Search
const SearchForm = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Search/SearchForm')

const props = (route) => {
  return { ...route.query, ...route.params }
}

export default [
  {
    name: 'Dashboard',
    path: '/',
    component: Dashboard,
    meta: {
      title: 'Dashboard'
    },
    props: { docPath: '/gettingstarted/dashboard/' }
  },
  {
    name: 'Actions',
    path: '/actions',
    component: ActionsList,
    meta: {
      title: 'Actions'
    }
  },
  {
    name: 'ChangeSettings',
    path: '/ChangeSettings',
    component: ChangeSettings,
    props,
    meta: {
      title: 'Change Settings'
    }
  },
  {
    name: 'ChangePassword',
    path: '/ChangePassword',
    component: ChangePassword,
    meta: {
      title: 'Change Password'
    }
  },
  {
    name: 'SearchForm',
    path: '/Search',
    component: SearchForm,
    props,
    meta: {
      title: 'Search'
    }
  },
  {
    name: 'ViewUsageSettings',
    path: '/ViewUsageSettings',
    component: ViewUsageSettings,
    meta: {
      title: 'View Usage Settings'
    }
  },
  {
    name: 'ActionNotFound',
    path: '/Action/NotFound',
    component: ActionNotFound,
    meta: {
      title: 'Action Not Found'
    }
  }
]
