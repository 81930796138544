var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "downloadBureauReports" } },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [
                _c(
                  "h2",
                  [
                    _vm._v(
                      " Download " + _vm._s(this.networkName) + " Reports "
                    ),
                    _c("help-icon", {
                      attrs: { docPath: "/bacsbureau/reports/" }
                    }),
                    _c("favourite-icon")
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row form-group" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "col-sm-3" },
                  [
                    _c("group-select", {
                      attrs: {
                        groups: _vm.bureaugroups,
                        clearable: false,
                        disabled: this.isLoggedIn
                      },
                      on: {
                        input: function($event) {
                          return _vm.changeGroup()
                        }
                      },
                      model: {
                        value: _vm.groupId,
                        callback: function($$v) {
                          _vm.groupId = $$v
                        },
                        expression: "groupId"
                      }
                    })
                  ],
                  1
                ),
                _vm.isLoggedIn === false
                  ? _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              disabled: this.enableLogin === false
                            },
                            on: { click: _vm.loginStart }
                          },
                          [_vm._v("Login")]
                        )
                      ]),
                      _vm._m(1),
                      _c("div", { staticClass: "col-md-1" }),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light",
                            attrs: {
                              type: "button",
                              disabled: this.enableLogin === false
                            },
                            on: { click: _vm.viewVocalinkCertificate }
                          },
                          [_vm._v("Vocalink Certificate")]
                        )
                      ])
                    ])
                  : _c("div", [
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col-md-3" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                disabled: this.loggingIn === true
                              },
                              on: { click: _vm.logoff }
                            },
                            [_vm._v("Log Off")]
                          )
                        ]),
                        _vm._m(2),
                        _c("div", { staticClass: "col-md-1" }),
                        _c("div", { staticClass: "col-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: {
                                type: "button",
                                disabled: this.loggingIn === true
                              },
                              on: { click: _vm.viewVocalinkCertificate }
                            },
                            [_vm._v("Vocalink Certificate")]
                          )
                        ])
                      ])
                    ])
              ]),
              _vm.BureauCustomersLoaded === true && _vm.isLoggedIn === true
                ? _c("div", [
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Bureau Customers")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("vue-select", {
                            attrs: {
                              multiple: "",
                              label: "name",
                              options: _vm.BureauCustomersDisplay,
                              closeOnSelect: false
                            },
                            on: {
                              input: _vm.CheckBureauCustomerDropdownEntries
                            },
                            model: {
                              value: _vm.SelectedBureauCustomers,
                              callback: function($$v) {
                                _vm.SelectedBureauCustomers = $$v
                              },
                              expression: "SelectedBureauCustomers"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _c("br"),
              _vm.isLoggedIn === true
                ? _c("div", [
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Report Types")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("b-select", {
                            attrs: { options: _vm.reportTypes },
                            model: {
                              value: _vm.selectedReportType,
                              callback: function($$v) {
                                _vm.selectedReportType =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "selectedReportType"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Report Period")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("b-select", {
                            attrs: { options: _vm.reportPeriods },
                            on: { change: _vm.reportPeriodChanged },
                            model: {
                              value: _vm.selectedReportPeriodValue,
                              callback: function($$v) {
                                _vm.selectedReportPeriodValue =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "selectedReportPeriodValue"
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.disableDatePicker
                        ? _c("div", { staticClass: "col-md-1" }, [
                            _vm._v("Select Date")
                          ])
                        : _vm._e(),
                      !_vm.disableDatePicker
                        ? _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("vuejsDatepicker", {
                                ref: "calendarDate",
                                attrs: {
                                  name: "calendarDate",
                                  id: "calendarDate",
                                  format: "dd/MM/yyyy",
                                  "input-class": "form-control",
                                  "bootstrap-styling": true,
                                  disabledDates: _vm.disabledDates,
                                  "monday-first": true,
                                  disabled: _vm.disableDatePicker
                                },
                                on: { selected: _vm.calendarDateChanged },
                                model: {
                                  value: _vm.calendarDate,
                                  callback: function($$v) {
                                    _vm.calendarDate = $$v
                                  },
                                  expression: "calendarDate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v("Previously Accessed")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("b-select", {
                            attrs: { options: _vm.reportAccessed },
                            model: {
                              value: _vm.selectedReportAccessed,
                              callback: function($$v) {
                                _vm.selectedReportAccessed =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "selectedReportAccessed"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("br"),
                    _c("div", { staticClass: "row form-group" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              disabled: this.enableGetReportList === false
                            },
                            on: { click: _vm.getReportList }
                          },
                          [_vm._v("Get Report List")]
                        )
                      ])
                    ]),
                    _vm.haveReportList === true
                      ? _c("div", { staticClass: "row form-group" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "vue-good-table",
                                {
                                  ref: "reportList",
                                  attrs: {
                                    columns: _vm.columns,
                                    rows: _vm.rows,
                                    "select-options": {
                                      enabled: true,
                                      selectOnCheckboxOnly: true,
                                      selectionInfoClass: "report-row-selected",
                                      clearSelectionText: ""
                                    },
                                    paginationOptions: _vm.paginationOptions,
                                    styleClass: "vgt-table striped bordered"
                                  },
                                  on: {
                                    "on-per-page-change": _vm.onPerPageChange
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "table-row",
                                        fn: function(props) {
                                          return [
                                            props.column.field == "buttons"
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "b-dropdown",
                                                      {
                                                        staticClass: "m-2",
                                                        attrs: {
                                                          right: "",
                                                          variant:
                                                            "outline-primary",
                                                          split: "",
                                                          text: "Options"
                                                        }
                                                      },
                                                      [
                                                        _vm.showCsvOption(props)
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.viewReportModal(
                                                                      props,
                                                                      "CSV"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("CSV")]
                                                            )
                                                          : _vm._e(),
                                                        _vm.showHtmlOption(
                                                          props
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.viewReportModal(
                                                                      props,
                                                                      "HTML"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("HTML")]
                                                            )
                                                          : _vm._e(),
                                                        _vm.showPdfOption(props)
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.viewReportModal(
                                                                      props,
                                                                      "PDF"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("PDF")]
                                                            )
                                                          : _vm._e(),
                                                        _vm.showXmlOption(props)
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.viewReportModal(
                                                                      props,
                                                                      "XML"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("XML")]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            props.column.field ===
                                            "viewReportXml"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "grid-link"
                                                    },
                                                    [_vm._v("XML")]
                                                  )
                                                ])
                                              : props.column.field ===
                                                "viewReportHtml"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "grid-link"
                                                    },
                                                    [_vm._v("HTML")]
                                                  )
                                                ])
                                              : props.column.field ===
                                                "viewReportCsv"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "grid-link"
                                                    },
                                                    [_vm._v("CSV")]
                                                  )
                                                ])
                                              : props.column.field ===
                                                "viewReportPdf"
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "grid-link"
                                                    },
                                                    [_vm._v("PDF")]
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.formattedRow[
                                                        props.column.field
                                                      ]
                                                    )
                                                  )
                                                ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3963763545
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { width: "1200px" },
                                      attrs: { slot: "selected-row-actions" },
                                      slot: "selected-row-actions"
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        !_vm.showDownloadWarning
                                          ? _c("div", {
                                              staticClass: "col-md-6"
                                            })
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "col-md-6",
                                                staticStyle: {
                                                  color: "#DD6B55"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.downloadWarningText
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                        _c("div", { staticClass: "col-md-2" }, [
                                          _vm._v("Download Format")
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-2" },
                                          [
                                            _c("b-select", {
                                              attrs: {
                                                options: _vm.downloadFormats
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedDownloadFormat,
                                                callback: function($$v) {
                                                  _vm.selectedDownloadFormat =
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                },
                                                expression:
                                                  "selectedDownloadFormat"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "col-md-2" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary pull-right",
                                              attrs: {
                                                type: "button",
                                                disabled:
                                                  this
                                                    .selectedDownloadFormat ===
                                                  ""
                                              },
                                              on: { click: _vm.downloadReports }
                                            },
                                            [_vm._v("Download")]
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ]),
            _vm.haveReportList === true
              ? _c("div", { staticClass: "idb-block-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mr-2",
                      attrs: { type: "button", disabled: _vm.downloadClicked },
                      on: {
                        click: function($event) {
                          return _vm.downloadAllReports("XML")
                        }
                      }
                    },
                    [_vm._v("Download All XML")]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.anyNonDdicReports,
                          expression: "anyNonDdicReports"
                        }
                      ],
                      staticClass: "btn btn-primary mr-2",
                      attrs: { type: "button", disabled: _vm.downloadClicked },
                      on: {
                        click: function($event) {
                          return _vm.downloadAllReports("HTML")
                        }
                      }
                    },
                    [_vm._v("Download All HTML")]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.anyDdicReports,
                          expression: "anyDdicReports"
                        }
                      ],
                      staticClass: "btn btn-primary mr-2",
                      attrs: { type: "button", disabled: _vm.downloadClicked },
                      on: {
                        click: function($event) {
                          return _vm.downloadAllReports("PDF")
                        }
                      }
                    },
                    [_vm._v("Download All PDF")]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.anyDdicReports,
                          expression: "anyDdicReports"
                        }
                      ],
                      staticClass: "btn btn-primary mr-2",
                      attrs: { type: "button", disabled: _vm.downloadClicked },
                      on: {
                        click: function($event) {
                          return _vm.downloadAllReports("CSV")
                        }
                      }
                    },
                    [_vm._v("Download All CSV")]
                  ),
                  _vm.downloadClicked
                    ? _c("span", { staticClass: "ml-2" }, [
                        _vm._v(
                          "Your file is being prepared, and will automatically download when ready."
                        )
                      ])
                    : _vm._e(),
                  _vm.downloadClicked && _vm.showDownloadWarning
                    ? _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.downloadWarningText))
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showeSigner,
              expression: "showeSigner"
            }
          ],
          attrs: { id: "eSigner" }
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.eSignerHtml) } })]
      ),
      _c(
        "div",
        [
          _c("viewReportModal", {
            attrs: {
              reportData: _vm.reportContent,
              reportFormat: _vm.selectedReportFormat
            },
            on: { close: _vm.closeViewReport },
            model: {
              value: _vm.isViewReportModalVisible,
              callback: function($$v) {
                _vm.isViewReportModalVisible = $$v
              },
              expression: "isViewReportModalVisible"
            }
          })
        ],
        1
      ),
      _c("plugin", { on: { installCancelled: _vm.pluginNotInstalled } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Select a Group")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("span", {
        staticClass: "fa fa-unlock",
        staticStyle: { "font-size": "200%" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("span", {
        staticClass: "fa fa-lock",
        staticStyle: { "font-size": "200%" }
      }),
      _vm._v(" Securely logged into Vocalink ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }