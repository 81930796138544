var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          attrs: { novalidate: "", autocomplete: "user-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              _vm.checkValidation("There are validation errors on the form", [
                _vm.passwords.$v
              ]) && _vm.saveUser()
            }
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [
                !_vm.canManageUsers
                  ? _c(
                      "div",
                      [
                        _vm._v(" View User "),
                        _c("help-icon", { attrs: { docPath: _vm.docPath } })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.status) +
                            " " +
                            _vm._s(_vm.type) +
                            " "
                        ),
                        _c("help-icon", { attrs: { docPath: _vm.docPath } })
                      ],
                      1
                    )
              ])
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _vm.user.isItemActioned
                  ? _c("div", { staticClass: "alert alert-warning" }, [
                      _vm._v(
                        "This user has a pending action against it and cannot be edited"
                      )
                    ])
                  : _vm._e(),
                !_vm.user.apiServiceAccount
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.user.name.$error }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-3 required",
                            attrs: { for: "username" }
                          },
                          [_vm._v("Name")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.user.name.$model,
                                  expression: "$v.user.name.$model",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "username",
                                type: "text",
                                disabled: _vm.user.isItemActioned,
                                readonly: !_vm.canManageUsers
                              },
                              domProps: { value: _vm.$v.user.name.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.user.name,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "name" },
                              model: {
                                value: _vm.$v.user.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.user, "name", $$v)
                                },
                                expression: "$v.user.name"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.user.apiServiceAccount
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: {
                          invalid:
                            _vm.$v.user.email.$error ||
                            _vm.$v.emailBounced.$invalid
                        }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-3 required",
                            attrs: { for: "useremail" }
                          },
                          [_vm._v("Email")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.user.email.$model,
                                  expression: "$v.user.email.$model",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "useremail",
                                type: "email",
                                disabled: _vm.user.isItemActioned,
                                readonly: !_vm.canManageUsers,
                                autocomplete: "off"
                              },
                              domProps: { value: _vm.$v.user.email.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.user.email,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "email" },
                              model: {
                                value: _vm.$v.user.email,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.user, "email", $$v)
                                },
                                expression: "$v.user.email"
                              }
                            }),
                            _c(
                              "validation-messages",
                              {
                                attrs: { name: "Email Bounced" },
                                model: {
                                  value: _vm.$v.emailBounced,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v, "emailBounced", $$v)
                                  },
                                  expression: "$v.emailBounced"
                                }
                              },
                              [
                                _c(
                                  "small",
                                  { staticClass: "form-text small" },
                                  [
                                    _vm._v(
                                      "This email address has bounced in the past, and may be invalid."
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "This will be used to login to the application"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.status === "Edit"
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.user.enabled.$error }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-3",
                            attrs: { for: "enabledUser" }
                          },
                          [_vm._v("Enabled")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: {
                                labelId: "enabledUser",
                                color: "primary",
                                disabled: !_vm.canManageUsers
                              },
                              model: {
                                value: _vm.$v.user.enabled.$model,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.user.enabled, "$model", $$v)
                                },
                                expression: "$v.user.enabled.$model"
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "enabled" },
                              model: {
                                value: _vm.$v.user.enabled,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.user, "enabled", $$v)
                                },
                                expression: "$v.user.enabled"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.status === "Create"
                  ? _c(
                      "div",
                      [
                        _c("change-password-inputs", {
                          attrs: {
                            formGroupClass: "row",
                            inputClass: "col-md-5",
                            labelClass: "col-form-label col-md-3"
                          },
                          model: {
                            value: _vm.passwords,
                            callback: function($$v) {
                              _vm.passwords = $$v
                            },
                            expression: "passwords"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.user.apiServiceAccount
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.user.phoneNumber.$error }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-3",
                            attrs: { for: "usermobile" }
                          },
                          [_vm._v("Mobile Phone")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.user.phoneNumber.$model,
                                  expression: "$v.user.phoneNumber.$model",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "usermobile",
                                type: "tel",
                                disabled: _vm.user.isItemActioned,
                                readonly: !_vm.canManageUsers
                              },
                              domProps: {
                                value: _vm.$v.user.phoneNumber.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.user.phoneNumber,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _c(
                              "validation-messages",
                              {
                                attrs: { name: "phone number" },
                                model: {
                                  value: _vm.$v.user.phoneNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v.user, "phoneNumber", $$v)
                                  },
                                  expression: "$v.user.phoneNumber"
                                }
                              },
                              [
                                !_vm.$v.user.phoneNumber.phoneNumber
                                  ? _c(
                                      "small",
                                      { staticClass: "form-text small" },
                                      [
                                        _vm._v(
                                          "This is not a valid phone number"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.user.apiServiceAccount
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.user.emailAction.$error }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-3",
                            attrs: { for: "emailAction" }
                          },
                          [_vm._v("Email Action")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: {
                                labelId: "emailAction",
                                color: "primary",
                                disabled: !_vm.canManageUsers
                              },
                              model: {
                                value: _vm.$v.user.emailAction.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.user.emailAction,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.user.emailAction.$model"
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "email action" },
                              model: {
                                value: _vm.$v.user.emailAction,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.user, "emailAction", $$v)
                                },
                                expression: "$v.user.emailAction"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.user.apiServiceAccount &&
                _vm.mfaEnabled &&
                _vm.checkTokenAtLogin &&
                _vm.twoFactorMethods.length > 2
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-3",
                          attrs: { for: "default2Fa" }
                        },
                        [_vm._v("Default Login Two Factor Method")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "default2Fa",
                              options: _vm.twoFactorMethods,
                              disabled: !_vm.canManageUsers
                            },
                            model: {
                              value: _vm.$v.user.defaultTwoFactor.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.user.defaultTwoFactor,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.user.defaultTwoFactor.$model"
                            }
                          }),
                          _c("small", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "This will restrict this user to this choice of 2FA at login"
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.user.paymentLimit.$error }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label col-md-3",
                        attrs: { for: "individualItemLimit" }
                      },
                      [_vm._v("Individual Item Limit (£)")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.user.paymentLimit.$model,
                              expression: "$v.user.paymentLimit.$model"
                            },
                            { name: "integer", rawName: "v-integer" }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "individualItemLimit",
                            type: "text",
                            disabled: _vm.user.isItemActioned,
                            readonly: !_vm.canManageUsers,
                            autocomplete: "user.paymentLimit"
                          },
                          domProps: { value: _vm.$v.user.paymentLimit.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.user.paymentLimit,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c(
                          "validation-messages",
                          {
                            attrs: { name: "individual item limit" },
                            model: {
                              value: _vm.$v.user.paymentLimit,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.user, "paymentLimit", $$v)
                              },
                              expression: "$v.user.paymentLimit"
                            }
                          },
                          [
                            _vm.$v.user.paymentLimit.wholepounds != undefined &&
                            !_vm.$v.user.paymentLimit.wholepounds
                              ? _c(
                                  "small",
                                  { staticClass: "form-text small" },
                                  [
                                    _vm._v(
                                      "individual item limit can only be in whole pounds"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v("Set to 0 for no limit")
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.user.submissionLimit.$error }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label col-md-3",
                        attrs: { for: "submissionLimit" }
                      },
                      [_vm._v("Submission Limit (£)")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.user.submissionLimit.$model,
                              expression: "$v.user.submissionLimit.$model",
                              modifiers: { trim: true }
                            },
                            { name: "integer", rawName: "v-integer" }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "submissionLimit",
                            type: "text",
                            step: "1",
                            disabled: _vm.user.isItemActioned,
                            readonly: !_vm.canManageUsers,
                            autocomplete: "user.submissionLimit"
                          },
                          domProps: {
                            value: _vm.$v.user.submissionLimit.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.user.submissionLimit,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c(
                          "validation-messages",
                          {
                            attrs: { name: "submission limit" },
                            model: {
                              value: _vm.$v.user.submissionLimit,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.user, "submissionLimit", $$v)
                              },
                              expression: "$v.user.submissionLimit"
                            }
                          },
                          [
                            _vm.$v.user.submissionLimit.wholepounds !=
                              undefined &&
                            !_vm.$v.user.submissionLimit.wholepounds
                              ? _c(
                                  "small",
                                  { staticClass: "form-text small" },
                                  [
                                    _vm._v(
                                      "submission limit can only be in whole pounds"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v("Set to 0 for no limit")
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.$v.user.approvalLimit.$error }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label col-md-3",
                        attrs: { for: "approvalLimit" }
                      },
                      [_vm._v("Approval Limit (£)")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.user.approvalLimit.$model,
                              expression: "$v.user.approvalLimit.$model",
                              modifiers: { trim: true }
                            },
                            { name: "integer", rawName: "v-integer" }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "approvalLimit",
                            type: "text",
                            disabled: _vm.user.isItemActioned,
                            readonly: !_vm.canManageUsers,
                            autocomplete: "user.approvalLimit"
                          },
                          domProps: { value: _vm.$v.user.approvalLimit.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.user.approvalLimit,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c(
                          "validation-messages",
                          {
                            attrs: { name: "approval limit" },
                            model: {
                              value: _vm.$v.user.approvalLimit,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.user, "approvalLimit", $$v)
                              },
                              expression: "$v.user.approvalLimit"
                            }
                          },
                          [
                            _vm.$v.user.approvalLimit.wholepounds !=
                              undefined &&
                            !_vm.$v.user.approvalLimit.wholepounds
                              ? _c(
                                  "small",
                                  { staticClass: "form-text small" },
                                  [
                                    _vm._v(
                                      "approval limit can only be in whole pounds"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v("Set to 0 for no limit")
                        ])
                      ],
                      1
                    )
                  ]
                ),
                !_vm.user.apiServiceAccount
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        class: { invalid: _vm.$v.user.hsmPin.$error }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label col-md-3",
                            attrs: { for: "hsmApprovalPassword" }
                          },
                          [_vm._v("HSM and Approval Password")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.user.hsmPin.$model,
                                  expression: "$v.user.hsmPin.$model",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "hsmApprovalPassword",
                                type: _vm.hsmPinToggled ? "text" : "password",
                                disabled: _vm.user.isItemActioned,
                                readonly: !_vm.canManageUsers,
                                autocomplete: "user.hsmPin"
                              },
                              domProps: { value: _vm.$v.user.hsmPin.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.user.hsmPin,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _c("span", {
                              staticClass: "fa fa-fw field-icon",
                              class: {
                                "fa-eye-slash": _vm.hsmPinToggled,
                                "fa-eye": !_vm.hsmPinToggled
                              },
                              on: {
                                mousedown: function($event) {
                                  return _vm.toggleHSMPin(true)
                                },
                                mouseup: function($event) {
                                  return _vm.toggleHSMPin(false)
                                }
                              }
                            }),
                            _c("validation-messages", {
                              attrs: { name: "HSM and approval password" },
                              model: {
                                value: _vm.$v.user.hsmPin,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.user, "hsmPin", $$v)
                                },
                                expression: "$v.user.hsmPin"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c("br"),
                _c(
                  "b-tabs",
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: "Common" } },
                      [
                        _c("roles", {
                          attrs: {
                            roleType: "Common",
                            userRoles: _vm.user.rolesToAdd,
                            disabled:
                              _vm.user.isItemActioned || !_vm.canManageUsers,
                            roles: _vm.databaseRoles.Common
                          },
                          on: { rolesChanged: _vm.rolesChanged }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Bureau" } },
                      [
                        _c("roles", {
                          attrs: {
                            roleType: "Bureau",
                            userRoles: _vm.user.rolesToAdd,
                            disabled:
                              _vm.user.isItemActioned || !_vm.canManageUsers,
                            roles: _vm.databaseRoles.Bureau
                          },
                          on: { rolesChanged: _vm.rolesChanged }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Agent" } },
                      [
                        _c("roles", {
                          attrs: {
                            roleType: "Agent",
                            userRoles: _vm.user.rolesToAdd,
                            disabled:
                              _vm.user.isItemActioned || !_vm.canManageUsers,
                            roles: _vm.databaseRoles.Agent
                          },
                          on: { rolesChanged: _vm.rolesChanged }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Automation" } },
                      [
                        _c("roles", {
                          attrs: {
                            roleType: "Automation",
                            userRoles: _vm.user.rolesToAdd,
                            disabled:
                              _vm.user.isItemActioned || !_vm.canManageUsers,
                            roles: _vm.databaseRoles.Automation
                          },
                          on: { rolesChanged: _vm.rolesChanged }
                        })
                      ],
                      1
                    ),
                    false
                      ? _c(
                          "b-tab",
                          { attrs: { title: "Collections" } },
                          [
                            _c("roles", {
                              attrs: {
                                roleType: "Collections",
                                userRoles: _vm.user.rolesToAdd,
                                disabled:
                                  _vm.user.isItemActioned ||
                                  !_vm.canManageUsers,
                                roles: _vm.databaseRoles.Collections
                              },
                              on: { rolesChanged: _vm.rolesChanged }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.corvidId === _vm.user.paygateId
                      ? _c(
                          "b-tab",
                          { attrs: { title: "System" } },
                          [
                            _c("roles", {
                              attrs: {
                                roleType: "System",
                                userRoles: _vm.user.rolesToAdd,
                                disabled:
                                  _vm.user.isItemActioned ||
                                  !_vm.canManageUsers,
                                roles: _vm.databaseRoles.System
                              },
                              on: { rolesChanged: _vm.rolesChanged }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "idb-block-footer" }, [
              _vm.canManageUsers
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.user.isItemActioned || _vm.isLoading,
                        type: "submit"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.status === "Edit" ? "Save" : _vm.status)
                      )
                    ]
                  )
                : _vm._e(),
              _vm.status === "Edit" &&
              _vm.canManageUsers &&
              !_vm.user.apiServiceAccount
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary ml-3",
                      attrs: {
                        disabled: _vm.user.isItemActioned || _vm.isLoading,
                        type: "button"
                      },
                      on: { click: _vm.showCloneModel }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-layers rpad" }),
                      _vm._v("Clone ")
                    ]
                  )
                : _vm._e(),
              _vm.status !== "Create" &&
              _vm.canManageUsers &&
              !_vm.user.apiServiceAccount
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary ml-3",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.openChangePasswordModal }
                    },
                    [_vm._v("Reset Password")]
                  )
                : _vm._e(),
              _vm.status !== "Create" &&
              _vm.canManageUsers &&
              _vm.user.isLockedOut &&
              !_vm.user.apiServiceAccount
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary ml-3",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.unlockUser }
                    },
                    [_vm._v("Unlock User")]
                  )
                : _vm._e(),
              _vm.status !== "Create" &&
              _vm.canManageUsers &&
              _vm.user.hasMicrosoft
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-warning ml-3",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.removeMicrosoftSignin }
                    },
                    [_vm._v("Unlink Microsoft Account")]
                  )
                : _vm._e(),
              _vm.status !== "Create" && _vm.canManageUsers
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary ml-3",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.openViewLoginHistory }
                    },
                    [_vm._v("View Login History")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger pull-right ml-3",
                  attrs: { type: "button", disabled: _vm.isLoading },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancel")]
              ),
              _vm.status === "Edit" &&
              _vm.canManageUsers &&
              !_vm.user.apiServiceAccount
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger pull-right",
                      attrs: {
                        disabled: _vm.user.isItemActioned || _vm.isLoading,
                        type: "button"
                      },
                      on: { click: _vm.deleteUser }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-trash rpad" }),
                      _vm._v("Delete User ")
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "change-password",
          attrs: {
            id: "change-password",
            title: "Reset User Password",
            "no-close-on-backdrop": "",
            centered: true
          }
        },
        [
          !_vm.user.apiServiceAccount
            ? _c(
                "form",
                {
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.changePassword()
                    }
                  }
                },
                [
                  _vm.anyErrors
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger",
                          attrs: { role: "alert" }
                        },
                        _vm._l(_vm.passwordErrors, function(error) {
                          return _c("span", { key: error }, [
                            _vm._v(_vm._s(error))
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("change-password-inputs", {
                    ref: "change-password-inputs",
                    attrs: {
                      "user-id": _vm.userId,
                      formGroupClass: "col-md-10"
                    },
                    model: {
                      value: _vm.passwords,
                      callback: function($$v) {
                        _vm.passwords = $$v
                      },
                      expression: "passwords"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("template", { slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary-outline",
                attrs: { type: "button" },
                on: { click: _vm.closeChangePasswordModal }
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disable: _vm.passwords.loading },
                on: {
                  click: function($event) {
                    return _vm.changePassword()
                  }
                }
              },
              [_vm._v("Reset Password")]
            )
          ])
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "clone-modal",
          attrs: {
            id: "cloneModal",
            title: "Clone User",
            "ok-title": "Clone",
            "cancel-variant": "secondary-outline",
            "no-close-on-backdrop": "",
            centered: true
          },
          on: {
            ok: _vm.cloneUser,
            hidden: _vm.resetModal,
            shown: _vm.setCloneModalFocus
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.user.clonedName.$error }
            },
            [
              _c(
                "label",
                {
                  staticClass: "col-form-label col-md-3 required",
                  attrs: { for: "newName" }
                },
                [_vm._v("New Name")]
              ),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.user.clonedName.$model,
                        expression: "$v.user.clonedName.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    ref: "defaultElement",
                    staticClass: "form-control",
                    attrs: { id: "newName", type: "text" },
                    domProps: { value: _vm.$v.user.clonedName.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.user.clonedName,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _c(
                    "validation-messages",
                    {
                      attrs: { name: "new name" },
                      model: {
                        value: _vm.$v.user.clonedName,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.user, "clonedName", $$v)
                        },
                        expression: "$v.user.clonedName"
                      }
                    },
                    [
                      _vm.$v.user.clonedName.notSameAs !== undefined &&
                      !_vm.$v.user.clonedName.notSameAs
                        ? _c("small", { staticClass: "form-text small" }, [
                            _vm._v(
                              "Cloned name cannot be the same as the original name"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.user.clonedEmail.$error }
            },
            [
              _c(
                "label",
                {
                  staticClass: "col-form-label col-md-3 required",
                  attrs: { for: "newEmail" }
                },
                [_vm._v("New Email")]
              ),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.user.clonedEmail.$model,
                        expression: "$v.user.clonedEmail.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "newEmail", type: "text" },
                    domProps: { value: _vm.$v.user.clonedEmail.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.user.clonedEmail,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _c(
                    "validation-messages",
                    {
                      attrs: { name: "new email" },
                      model: {
                        value: _vm.$v.user.clonedEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.user, "clonedEmail", $$v)
                        },
                        expression: "$v.user.clonedEmail"
                      }
                    },
                    [
                      _vm.$v.user.clonedEmail.notSameAs !== undefined &&
                      !_vm.$v.user.clonedEmail.notSameAs
                        ? _c("small", { staticClass: "form-text small" }, [
                            _vm._v(
                              "Cloned email cannot be the same as the original email"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.user.clonedPassword.$error }
            },
            [
              _c(
                "label",
                {
                  staticClass: "col-form-label col-md-3 required",
                  attrs: { for: "modalPassword" }
                },
                [_vm._v("Password")]
              ),
              _c(
                "div",
                { staticClass: "col-md-5" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.user.clonedPassword.$model,
                        expression: "$v.user.clonedPassword.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "password", id: "modalPassword" },
                    domProps: { value: _vm.$v.user.clonedPassword.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.user.clonedPassword,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _c("validation-messages", {
                    attrs: { name: "password" },
                    model: {
                      value: _vm.$v.user.clonedPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.user, "clonedPassword", $$v)
                      },
                      expression: "$v.user.clonedPassword"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.user.clonedConfirmPassword.$error }
            },
            [
              _c(
                "label",
                {
                  staticClass: "col-form-label col-md-3 required",
                  attrs: { for: "modalConfirmPassword" }
                },
                [_vm._v("Confirm Password")]
              ),
              _c(
                "div",
                { staticClass: "col-md-5" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.user.clonedConfirmPassword.$model,
                        expression: "$v.user.clonedConfirmPassword.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "modalConfirmPassword", type: "password" },
                    domProps: {
                      value: _vm.$v.user.clonedConfirmPassword.$model
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.user.clonedConfirmPassword,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _c(
                    "validation-messages",
                    {
                      attrs: { name: "confirm password" },
                      model: {
                        value: _vm.$v.user.clonedConfirmPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.user, "clonedConfirmPassword", $$v)
                        },
                        expression: "$v.user.clonedConfirmPassword"
                      }
                    },
                    [
                      !_vm.$v.user.clonedConfirmPassword.sameAs
                        ? _c("small", { staticClass: "form-text small" }, [
                            _vm._v("Passwords do not match")
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "login-history",
          attrs: {
            id: "viewLoginHistory",
            title: "Login History For The Past Month",
            "ok-title": "Close",
            "ok-only": true,
            "no-close-on-backdrop": "",
            centered: true,
            size: "lg"
          },
          on: {
            ok: function($event) {
              return _vm.$refs["login-history"].hide()
            }
          }
        },
        [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Title")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Time")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Application")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("IP Address")])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.loginHistory, function(login) {
                return _c("tr", { key: login.createdDate }, [
                  _c("th", [_vm._v(_vm._s(login.auditTitle))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDate")(
                          login.createdDate,
                          "DD/MM/YYYY HH:mm"
                        )
                      )
                    )
                  ]),
                  _c("td", [_vm._v(_vm._s(login.application))]),
                  _c("td", [_vm._v(_vm._s(login.ipAddress))])
                ])
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }