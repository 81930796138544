<template>
  <div id="findSubmissions">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Search Sent Submissions
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div class="row">
              <div class="col-md-2">
                <strong>Paygate Type</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>From Date</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>To Date</strong>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <strong>Customers</strong>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2">
                <b-select v-model.trim="selectedPaygateType" :options="paygateTypes" />
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdFrom"
                  name="createdFrom"
                  id="createdFrom"
                  v-model="createdFrom"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="fromDateOnfocus"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <vuejsDatepicker
                  ref="createdTo"
                  name="createdTo"
                  id="createdTo"
                  v-model="createdTo"
                  format="dd/MM/yyyy"
                  :bootstrap-styling="true"
                  v-on:input="checkDates"
                  @focusin.native="toDateOnfocus"
                  :monday-first="true"
                  :disabledDates="disabledDates"
                ></vuejsDatepicker>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <b-select v-model.trim="selectedCustomerType" :options="customerTypes" />
              </div>
            </div>
            <div v-if="invalidDateRange === true" class="row form-group">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <span class="mandatory">The "From Date" cannot be after the "To Date"</span>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="idb-block-footer">
            <div class="row form-group">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="this.canSearch === false"
                  v-on:click="searchSubmissions"
                >Search</button>
              </div>
              <div v-bind:class="getBtnColClass">
                <button
                  type="button"
                  class="btn btn-outline-primary pull-right"
                  v-on:click="clearSearchParams"
                >Clear Search Criteria</button>
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12" style="overflow-x: auto; white-space: nowrap;">
                <vue-good-table
                  ref="table"
                  @on-row-click="onRowClick"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  @on-cell-click="onCellClick"
                  @on-sort-change="onSortChange"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: rowsPerPage,
                    dropdownAllowAll: false,
                    jumpFirstOrLast: true,
                    setCurrentPage: currentPage
                  }"
                  :sort-options="sortOptions"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'submissionStatus'">
                      <span
                        v-b-popover.hover.top="statusToolTip(props.row.submissionStatus)"
                        v-bind:class="getStatusClass(props.row.submissionStatus)"
                        v-bind:style="getStatusStyle(props.row.submissionStatus)"
                      ></span>
                    </span>
                    <span v-else-if="props.column.field === 'logs'">
                      <span v-bind:class="viewLog(props.row.logs)"></span>
                    </span>
                    <span v-else-if="props.column.field === 'submissionSummaryReport'">
                      <span v-bind:class="getReportClass(props.row.submissionStatus)"></span>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <viewLogModal
        v-model="isViewLogModalVisible"
        :logContents="logDetails"
        @close="closeViewLog"
      ></viewLogModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { numeric } from 'vuelidate/lib/validators'
import bacsMixin from '@/Lib/BacsMixin.js'
import roles from '@/Assets/Constants/roles'
import { mapGetters } from 'vuex'
import ViewLogModal from '@/Views/Bacs/Search/ViewLogModal.vue'

export default {
  name: 'searchSentSubmissions',
  mixins: [
    bacsMixin
  ],
  components: {
    viewLogModal: ViewLogModal
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    isSystemUser () { return this.$store.getters.isInRole(roles.SystemUser) },
    getBtnColClass () { return this.antiFraudListId !== undefined && this.antiFraudListId ? 'col-md-8' : 'col-md-10' },
    showMaxRowsWarning () { return this.totalRecords === this.maxRows }
  },

  data () {
    return {
      canSearch: false,
      maxRows: 1000,
      paygateId: '',
      createdFrom: '',
      createdTo: '',
      reference: '',
      columns: [
        {
          label: 'PaygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'SubmissionId',
          field: 'submissionId',
          hidden: true
        },
        {
          label: 'Customer',
          field: 'name'
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Type',
          field: 'paymentEngineType',
          formatFn: this.setUpperCase
        },
        {
          label: 'Run Start',
          field: 'runStart',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Run End',
          field: 'runEnd',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
        },
        {
          label: 'Type',
          field: 'paygateType'
        },
        {
          label: 'Status',
          field: 'submissionStatus'
        },
        {
          label: 'View Log',
          field: 'logs',
          sortable: false
        },
        {
          label: 'Report',
          field: 'submissionSummaryReport',
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      searchComplete: false,
      disabledDates: {},
      invalidDateRange: false,
      selectedSubmissionId: '',
      subReference: '',
      currentPage: 1,
      rowsPerPage: 10,
      customerTypes: {},
      paygateTypes: {},
      selectedCustomerType: 1,
      selectedPaygateType: -1,
      antiFraudListId: false,
      btnColClass: 'col-md-10',
      helpUrl: '',
      submissionStatus: '',
      defaultFromDate: '',
      defaultToDate: '',
      logDetails: '',
      isViewLogModalVisible: false,
      sortOptions: {
        enabled: true,
        initialSortBy: { field: '', type: '' }
      },
      resetTable: true
    }
  },

  methods: {
    async searchSubmissions () {
      if (this.checkDates() === false || this.$v.$invalid === true) {
        return
      }

      this.sortOptions.initialSortBy = { field: 'runStart', type: 'desc' }
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
      // This calls the onSortChange event.
      this.$refs.table.reset()

      this.searchComplete = false
      this.rows = []

      var fromDate = this.createdFrom
      var toDate = this.createdTo

      if (fromDate === '') {
        fromDate = '01/01/0001'
      }

      if (toDate === '') {
        toDate = '01/01/0001'
      }

      var paygateIdSearch = this.selectedCustomerType === 0 ? '00000000-0000-0000-0000-000000000000' : this.paygateId
      var submissionSearchParams = JSON.stringify({
        createdFrom: fromDate,
        createdTo: toDate,
        customerType: this.selectedCustomerType,
        paygateType: this.selectedPaygateType,
        paygateId: paygateIdSearch
      })

      this.$store.dispatch('setSearchParams', submissionSearchParams)

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/search/searchSentSubmissions/',
        data: submissionSearchParams,
        showload: true
      })

      if (response.data !== null) {
        this.totalRecords = response.data.length
        this.rows = response.data
        this.currentPage = 1
      }

      this.searchComplete = true
    },

    clearSearchParams () {
      this.invalidDateRange = false
      this.createdFrom = ''
      this.createdTo = ''
      this.reference = ''
      this.maxRows = 1000
      this.rows = []
      this.searchComplete = false
      this.selectedCustomerType = 1
      this.selectedPaygateType = -1
      this.totalRecords = 0
      this.submissionStatus = ''
      if (this.resetTable) {
        // Don't do this after returning to page after viewing submission details.
        this.sortOptions.initialSortBy = { field: 'runStart', type: 'desc' }
        this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
        this.$refs.table.reset()
      }
      this.initSearchDates()
      this.resetTable = true
    },

    async initComboData () {
      this.clearSearchParams()
      // Only a system user search page.
      this.paygateId = this.selectedCustomer
      await this.getEnums()
    },

    initSearchDates () {
      // Dates in Javascript and/or combined with the date picker are nuts.
      // Dateformat dd/MM/yyyy returned by server has the day and month swapped over by JS/datepicker.
      // Hence the fudge of using the MM returned as the day and the dd returned as the month!
      var mm = this.defaultFromDate.substring(0, 2)
      var dd = this.defaultFromDate.substring(3, 5)
      var yyyy = this.defaultFromDate.substring(6, 10)
      this.createdFrom = dd + '/' + mm + '/' + yyyy

      mm = this.defaultToDate.substring(0, 2)
      dd = this.defaultToDate.substring(3, 5)
      yyyy = this.defaultToDate.substring(6, 10)
      this.createdTo = dd + '/' + mm + '/' + yyyy
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/search/getDropdownValues`)
      this.customerTypes = response.data.customerTypes
      this.paygateTypes = response.data.paygateTypes
      this.defaultFromDate = response.data.defaultFromDate
      this.defaultToDate = response.data.defaultToDate
    },

    onRowClick (params) {
      var subId = params.row.submissionId
      this.$store.dispatch('setActionId', undefined)
      this.$store.dispatch('setSubmissionId', subId)
      this.$store.dispatch('setFileNumber', 1)
      this.$store.dispatch('setSearchResults', this.rows)
      this.$store.dispatch('setSelectedRowGroupName', params.row.groupName)
    },

    async onCellClick (params) {
      this.$store.dispatch('setSubmissionId', params.row.submissionId)
      this.$store.dispatch('setFileNumber', 1)
      this.$store.dispatch('setSearchResults', this.rows)

      if (params.column.field === 'logs') {
        if (params.row.logs !== '' && params.row.logs !== undefined) {
          this.logDetails = params.row.logs
          this.isViewLogModalVisible = true
        }
      } else if (params.column.field === 'submissionSummaryReport') {
        if (params.row.submissionStatus === 'complete' || params.row.submissionStatus === 'rejected' || params.row.submissionStatus === 'warning') {
          const routeData = this.$router.resolve({ path: '/payments/common/viewSubmissionSummary', query: { submissionId: params.row.submissionId } })
          window.open(routeData.href, '_blank')
        }
      } else {
        this.$store.dispatch('setPayments', [])
        const subUrl = '/payments/search/submissionWrapper?returnPage=searchSentSubmissions&submissionStatus=' + params.row.submissionStatus
        this.$router.push(subUrl)
      }
    },

    closeViewLog () {
      this.isViewLogModalVisible = false
      this.logDetails = ''
    },

    checkDates () {
      var datesOk = true
      if (this.createdFrom !== '' && this.createdTo !== '') {
        var fromDate = new Date(this.createdFrom)
        var toDate = new Date(this.createdTo)

        if (fromDate > toDate) {
          this.invalidDateRange = true
          datesOk = false
        } else {
          this.invalidDateRange = false
        }
      }

      return datesOk
    },

    getStatusClass (submissionStatus) {
      var iconClass = ''
      switch (submissionStatus) {
        case 'Created':
          iconClass = 'fa fa-plus'
          break
        case 'Signed':
          iconClass = 'fa fa-file-signature'
          break
        case 'Committed':
          iconClass = 'fa fa-handshake'
          break
        case 'Approved':
          iconClass = 'fa fa-thumbs-up'
          break
        case 'complete':
          iconClass = 'fa fa-check-circle'
          break
        case 'rejected':
        case 'NoSubmissionSummary':
          iconClass = 'fa fa-times-circle'
          break
        case 'Cancelled':
          iconClass = 'fa fa-trash-alt'
          break
        case 'warning':
          iconClass = 'fa fa-exclamation-circle'
          break
      }
      return iconClass
    },

    viewLog (log) {
      var iconClass = ''
      if (log !== '' && log !== undefined) {
        iconClass = 'fa fa-list-alt'
      }
      return iconClass
    },

    getStatusStyle (submissionStatus) {
      var colour = ''
      switch (submissionStatus) {
        case 'complete':
          colour = 'color: green;'
          break
        case 'rejected':
          colour = 'color: red;'
          break
        case 'warning':
          colour = 'color: #DD6B55;'
          break
      }
      return colour
    },

    getReportClass (submissionStatus) {
      var iconClass = ''
      if (submissionStatus === 'complete' || submissionStatus === 'rejected' || submissionStatus === 'warning') {
        iconClass = 'fa fa-file-alt'
      }
      return iconClass
    },

    statusToolTip (submissionStatus) {
      var toolTip = ''
      switch (submissionStatus) {
        case 'complete':
          toolTip = 'Complete'
          break
        case 'rejected':
          toolTip = 'Rejected'
          break
        case 'NoSubmissionSummary':
          toolTip = 'Rejected'
          break
        case 'warning':
          toolTip = 'Warning'
          break
        default:
          toolTip = submissionStatus
          break
      }
      return toolTip
    },

    onPageChange (params) {
      this.currentPage = params.currentPage
      this.$store.dispatch('setCurrentSearchResultsPage', this.currentPage)
    },

    onPerPageChange (params) {
      this.rowsPerPage = params.currentPerPage
      this.$store.dispatch('setSearchRowsPerPage', this.rowsPerPage)
    },

    onSortChange (params) {
      this.sortOptions.initialSortBy = params
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
    },

    setUpperCase (value) {
      return value.toUpperCase()
    }
  },

  watch: {
    selectedCustomer () {
      this.initComboData()
      this.initSearchDates()
    }
  },

  created () {
    this.disabledDates.from = new Date()
    var useSearchParams = this.$route.query.useSearchParams
    if (useSearchParams !== undefined) {
      this.sortOptions.initialSortBy = this.$store.getters.initialSortBy
      this.resetTable = false
    } else {
      this.sortOptions.initialSortBy = { field: 'createdOn', type: 'desc' }
    }
  },

  async mounted () {
    document.getElementById('createdFrom').style.backgroundColor = 'white'
    document.getElementById('createdTo').style.backgroundColor = 'white'

    this.helpUrl = process.env.VUE_APP_DOCUMENTATION_ROOT_URL + '/payments/search/searchbackgroundjobs/'
    await this.initComboData()

    var useSearchParams = this.$route.query.useSearchParams
    if (useSearchParams === undefined) {
      this.$store.dispatch('setSearchParams', null)
      this.$store.dispatch('setCurrentSearchResultsPage', 1)
      this.$store.dispatch('setSelectedRowGroupName', null)
      this.sortOptions.initialSortBy = { field: 'runStart', type: 'desc' }
      this.$store.dispatch('setInitialSortBy', this.sortOptions.initialSortBy)
      this.initSearchDates()
    } else {
      // Returning from submission details...
      var searchParams = JSON.parse(this.$store.getters.searchParams)
      console.log('searchParams: ' + JSON.stringify(searchParams))
      this.createdFrom = searchParams.createdFrom !== '01/01/0001' ? searchParams.createdFrom : ''
      this.createdTo = searchParams.createdTo !== '01/01/0001' ? searchParams.createdTo : ''
      this.reference = searchParams.reference
      this.maxRows = searchParams.maxRows
      this.selectedCustomerType = searchParams.customerType
      this.selectedPaygateType = searchParams.paygateType

      if (useSearchParams === 'true') {
        this.rows = this.$store.getters.searchResults
        this.totalRecords = this.rows.length
        this.searchComplete = true
        this.currentPage = this.$store.getters.currentSearchResultsPage
        this.rowsPerPage = this.$store.getters.searchRowsPerPage
      } else if (useSearchParams === 'auto') {
        this.submissionStatus = 'complete'
        this.searchSubmissions()
      }
    }
    this.canSearch = true
  },

  destroyed () {
  },

  validations: {
    maxRows: { numeric }
  }
}
</script>
