var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "app-header", attrs: { id: "app-header" } },
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "md", type: "light" } },
            [
              _c("div", { staticClass: "toolbar" }, [
                _c("div", { staticClass: "sidebar-logo-wrap" }, [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c(
                      "div",
                      { staticClass: "align-item-center" },
                      [
                        _c("router-link", { attrs: { to: "/" } }, [
                          _c(
                            "a",
                            {
                              staticClass: "site-logo",
                              attrs: { href: "index.html" }
                            },
                            [
                              _c("inline-svg", {
                                staticStyle: { "padding-left": "3px" },
                                attrs: {
                                  src:
                                    "/img/sharedimages/CorvidLogo/PAYGATE LOGO_WHITE.svg",
                                  height: "38"
                                }
                              }),
                              _c("h1", { staticClass: "sr-only" }, [
                                _vm._v("Paygate")
                              ])
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.focus.bottom",
                          value: _vm.menuPopoverOptions,
                          expression: "menuPopoverOptions",
                          modifiers: { hover: true, focus: true, bottom: true }
                        }
                      ],
                      staticClass: "nav-link-smaller",
                      staticStyle: { "z-index": "3" },
                      attrs: { id: "collapsed-sidebar" },
                      on: { click: _vm.emitCollapsedSidebar }
                    },
                    [
                      _c("i", { staticClass: "ti ti-angle-left" }),
                      _vm.sidebarCollapsed
                        ? _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Show Menu")
                          ])
                        : _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Hide Menu")
                          ])
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.bottom",
                          value: "Home",
                          expression: "'Home'",
                          modifiers: { hover: true, bottom: true }
                        }
                      ],
                      staticClass: "nav-link-smaller",
                      staticStyle: { "z-index": "3" },
                      attrs: { "aria-label": "Home" },
                      on: { click: _vm.goHome }
                    },
                    [
                      _c("i", { staticClass: "ti ti-home" }),
                      _c("span", { staticClass: "sr-only" }, [_vm._v("Home")])
                    ]
                  )
                ],
                1
              ),
              _vm.displayHeader
                ? _c(
                    "b-collapse",
                    { attrs: { "is-nav": "" } },
                    [
                      _c("search-icon"),
                      _c(
                        "b-navbar-nav",
                        [
                          _vm.displayCustomerSelector
                            ? _c("b-nav-item", [_c("customer-selector")], 1)
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-navbar-nav",
                        { staticClass: "ml-auto", attrs: { "is-nav-bar": "" } },
                        [
                          _c(
                            "transition-group",
                            {
                              staticClass: "navbar-nav",
                              attrs: { name: "fade", tag: "ul" }
                            },
                            _vm._l(_vm.favourites, function(favourite) {
                              return _c(
                                "b-nav-item",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.focus.bottom",
                                      value: favourite.title,
                                      expression: "favourite.title",
                                      modifiers: {
                                        hover: true,
                                        focus: true,
                                        bottom: true
                                      }
                                    }
                                  ],
                                  key: favourite.value,
                                  attrs: { right: "", to: favourite.value }
                                },
                                [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "ti",
                                      class: favourite.icon,
                                      staticStyle: { "line-height": "30px" }
                                    }),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(_vm._s(favourite.title))
                                    ])
                                  ])
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto", attrs: { "is-nav-bar": "" } },
                [
                  _c(
                    "b-nav-item-dropdown",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.focus.bottom",
                          value: "User Account",
                          expression: "'User Account'",
                          modifiers: { hover: true, focus: true, bottom: true }
                        }
                      ],
                      ref: "user-panel",
                      attrs: { right: "", id: "user-panel" }
                    },
                    [
                      _c("template", { slot: "button-content" }, [
                        _c(
                          "span",
                          { staticStyle: { "line-height": "30px" } },
                          [
                            _c("avatar", {
                              staticClass: "rounded-circle",
                              attrs: { width: "32", height: "32", alt: "user" }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("b-card", [
                        _c("p", { staticClass: "card-text" }),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-5" },
                            [
                              _c("avatar", {
                                staticClass: "img-fluid avatar-image",
                                attrs: {
                                  width: "128",
                                  height: "128",
                                  alt: "avatar"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-7" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.email || _vm.username))
                            ]),
                            _c("br"),
                            _c("p", { staticClass: "text-muted small" }, [
                              _vm._v("Customer Name: " + _vm._s(_vm.custname))
                            ]),
                            _vm.tel !== ""
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "text-muted small",
                                    attrs: { href: "tel:" + _vm.tel }
                                  },
                                  [_vm._v(_vm._s(_vm.tel))]
                                )
                              : _vm._e()
                          ])
                        ]),
                        _c(
                          "div",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: { click: _vm.changeSettings }
                              },
                              [_vm._v("Change Settings")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger float-right",
                                on: { click: _vm.logout }
                              },
                              [_vm._v("Sign Out")]
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.focus.hover.bottom",
                          value: "Quick Links",
                          expression: "'Quick Links'",
                          modifiers: { focus: true, hover: true, bottom: true }
                        }
                      ],
                      staticClass: "custom-dropdown",
                      attrs: { right: "", "no-caret": "" }
                    },
                    [
                      _c("template", { slot: "button-content" }, [
                        _c("span", [
                          _c("i", {
                            staticClass: "ti-layout-grid3",
                            staticStyle: { "line-height": "30px" }
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Show Quick Links")
                          ])
                        ])
                      ]),
                      _c(
                        "b-dropdown-header",
                        { staticClass: "bg-success text-white" },
                        [_vm._v("Quick Links")]
                      ),
                      _c("b-dropdown-item", { attrs: { to: "/" } }, [
                        _c("div", { staticClass: "icon-area" }, [
                          _c("i", { staticClass: "ti-dashboard" })
                        ]),
                        _c("small", [_vm._v("Dashboard")])
                      ]),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/automation/config/foldermanager" } },
                        [
                          _c("div", { staticClass: "icon-area" }, [
                            _c("i", { staticClass: "ti-folder" })
                          ]),
                          _c("small", [_vm._v("Folder Manager")])
                        ]
                      ),
                      _c("b-dropdown-item", { attrs: { to: "/actions" } }, [
                        _c("div", { staticClass: "icon-area" }, [
                          _c("i", { staticClass: "ti-bell" })
                        ]),
                        _c("small", [_vm._v("Actions")])
                      ]),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openHelpInNewTab()
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "icon-area" }, [
                            _c("i", { staticClass: "ti-book" })
                          ]),
                          _c("small", [_vm._v("Help Portal")])
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/about/contactsupport" } },
                        [
                          _c("div", { staticClass: "icon-area" }, [
                            _c("i", { staticClass: "ti-support" })
                          ]),
                          _c("small", [_vm._v("Contact Support")])
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openInNewTab(
                                "https://status.paygate.cloud/"
                              )
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "icon-area" }, [
                            _c("i", { staticClass: "ti-pulse" })
                          ]),
                          _c("small", [_vm._v("Service Status")])
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: { name: "ChangeSettings" } } },
                        [
                          _c("div", { staticClass: "icon-area" }, [
                            _c("i", { staticClass: "ti-user" })
                          ]),
                          _c("small", [_vm._v("Account")])
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openInNewTab("https://www.paygate.uk/")
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "icon-area" }, [
                            _c("i", { staticClass: "ti-cloud" })
                          ]),
                          _c("small", [_vm._v("paygate.uk")])
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openFeedbackModal()
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "icon-area" }, [
                            _c("i", { staticClass: "ti-comments" })
                          ]),
                          _c("small", [_vm._v("Give Feedback")])
                        ]
                      )
                    ],
                    2
                  ),
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.focus.hover.bottom",
                          value: "Give Feedback",
                          expression: "'Give Feedback'",
                          modifiers: { focus: true, hover: true, bottom: true }
                        }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.openFeedbackModal()
                        }
                      }
                    },
                    [
                      _c("span", [
                        _c("i", {
                          staticClass: "ti-comments",
                          staticStyle: { "line-height": "30px" },
                          attrs: { tooltip: "Give feedback" }
                        }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Give Feedback")
                        ])
                      ])
                    ]
                  ),
                  _c("actions")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("feedback")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }