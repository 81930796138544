<template>
	<div>
		<div class="row mb-4">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>
							Workflow/Mapping Designer Settings
							<help-icon docPath="/automation/paygate-mapping/mapping-designer/designersettings/" />
							<favourite-icon></favourite-icon>
						</h2>
					</div>
					<form>
						<div class="idb-block-content">
							<!-- <div class="row">
                <div class="col-md-1">
                </div>
                <div class="col-md-4">
                  <p-check name="check" class="p-switch" color="primary " v-model="config.magneticLinks"><span class=" ml-2">Enable Magnetic Links</span></p-check>
                </div>
                <div class="col-md-7">
                  Links snap to the closest available connector.
                </div>
							</div>-->
							<br />

							<div class="row">
								<div class="col-md-1"></div>
								<div class="col-md-2">
									<label for="autoSaveCheck">Enable Auto Save</label>
								</div>
								<div class="col-md-3">
									<p-check
										:labelId="'autoSaveCheck'"
										name="check"
										class="p-switch"
										color="primary "
										v-model="config.autosave"
									>
										<span class="ml-2"></span>
									</p-check>
								</div>
								<!-- <div class="col-md-5">
                  The Mapping or Workflow is automatically saved in the background
								</div>-->
							</div>
							<br />
							<br />
							<div class="row">
								<div class="col-md-1"></div>
								<div class="col-md-2">Default Group</div>
								<div class="col-md-3">
									<group-select v-model="config.selectedGroupId" :groups="options" :clearable="false"></group-select>
								</div>
								<!-- <div class="col-md-5">
                  New workflows will automatically be set to use this group.
								</div>-->
							</div>
							<br />
							<br />
							<div class="row">
								<div class="col-md-1"></div>
								<div class="col-md-2">Default User</div>
								<div class="col-md-3">
									<select class="form-control" v-model="config.selectedUserId">
										<option
											v-for="option in userOptions"
											:key="option.id"
											v-bind:value="option.value"
										>{{option.label}}</option>
									</select>
								</div>
								<!-- <div class="col-md-5">
                  New workflows will automatically be set to run under this user.
								</div>-->
							</div>
							<!-- <br/><br/><br/><br/> -->
							<div style="line-height:600%;">
								<br />
							</div>
						</div>
						<div class="idb-block-footer">
							<div class="row">
								<div class="col-md-12 col-sm-3">
									<b-button @click.prevent="btnSave" :disabled="isLoading" variant="primary">
										<i class="fa fa-save mr-2"></i>Save Settings
									</b-button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
	mixins: [loading],
	data: () => ({
		options: [],
		userOptions: [],
		paygateId: '',
		config: {},
		docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
	}),
	methods: {
		async btnSave () {
			try {
				await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}designersettings`, this.config, { showload: true })
				this.$toastr.s('The settings were successfully updated.')
			} catch (e) {
				this.$snapbar.e(`Error saving duplication configuration - ${e.message}`)
			}
		}
	},
	created: async function () {
		this.$snapbar.hide()
		let res
		try {
			res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}designersettings`, { showload: true })
			console.log(res)
			if (res && res.data) {
				this.config = res.data
			} else {
				this.$snapbar.e('Error retrieving Designer Settings')
			}
		} catch (e) {
			this.$snapbar.e(`Error retrieving Designer Settings - ${e.message}`)
		}

		// Fetch Groups
		this.paygateId = this.$store.getters.getClaim('paygate_id').value
		if (!this.paygateId) {
			this.$snapbar.e('Error retrieving list of available groups. (002)')
			return
		}
		let groupRes
		try {
			groupRes = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups?sort=name:asc`)
			this.options = groupRes.data.data
		} catch (e) {
			this.$snapbar.e('Error getting list of available groups. (002)')
		}

		// Fetch users
		try {
			const userRes = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}users`)
			if (userRes && userRes.data && userRes.data.data) {
				for (const user of userRes.data.data) {
					if (!user.isServiceAccount && user.enabled) {
						this.userOptions.push({
							label: `${user.email} (${user.name})`,
							value: user.id
						})
					}
				}
			} else {
				this.$snapbar.e('Error getting list of available users.')
			}
		} catch (e) {
			this.$snapbar.e('Error retrieving list of available users.')
		}



	}
}
</script>

<style scoped>
.fadein-enter {
	opacity: 0;
}

.fadein-enter-active {
	transition: opacity 0.6s;
}

.fadein-leave-active {
	transition: opacity 0.6s;
	opacity: 0;
}
</style>
