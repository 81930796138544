<template>
  <div>
    <h3>Collections Processor<span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span></h3>
    <hr>
    <br/>
    <!-- <div class="form-group">
      <label class="required">Group</label>
      <br>
      <group-select v-model="selectedNode.props.s1.value" :groups="options" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>
    <p class="validationText" v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty ">A valid PayGate group must be selected!</p>
    </div>
    <br/> -->
    <div class="form-group">
      <label class="required" >Submission Reference</label>
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s2.value.$error}"
        @input="$v.selectedNode.props.s2.value.$touch()"
        v-model="selectedNode.props.s2.value">
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.required && $v.selectedNode.props.s2.value.$dirty ">The Submission Reference field is required!</p>
        <p class="validationText" v-if="!$v.selectedNode.props.s2.value.maxLength">Maximum length is {{ $v.selectedNode.props.s2.value.$params.maxLength.max }} characters!</p>
    </div>
    <br/>
    <div class="form-group">
      <label class="" >Contra Narrative</label>
      <input type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value">
      <p class="validationText" v-if="!$v.selectedNode.props.s3.value.maxLength">Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>

    </div>
    <br/>
    <div class="form-group">
      <label>Collection Type</label>
      <br>
        <p-radio value="Standard" color="primary" v-model="selectedNode.props.s4.value">Standard</p-radio>
        <p-radio value="AUDDIS" color="primary" v-model="selectedNode.props.s4.value">AUDDIS</p-radio>
    </div>
    <br/>
    <br/>
    <hr>
    <div class="pull-right">
      <b-button variant="danger"
        class="btnPad"
        @click.prevent="btnCancel">
          Cancel
      </b-button>
      <b-button  class="" variant="success"
        @click.prevent="btnSave">
          OK
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as diagram from './../../Engine/workflowDesigner.js'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  validations: {
    selectedNode: {
      props: {
        // s1: {
        //   value: {
        //     required,
        //     maxLength: maxLength(128)
        //   }
        // },
        s2: {
          value: {
            required,
            maxLength: maxLength(18)
          }
        },
        s3: {
          value: {
            maxLength: maxLength(18)
          }
        }
      }
    }
  },
  data () {
    return {
      options: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    }
  },
  created: async function () {
    // this.paygateId = this.$store.getters.getClaim('paygate_id').value
    // if (!this.paygateId) {
    //   this.$toastr.e('Error getting list of available groups. (001)', 'Error', 'error')
    //   return
    // }
    // let res
    // try {
    //   res = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups`)
    //   this.options = res.data.data
    // } catch (e) {
    //   this.$snapbar.e('Error getting list of available groups. (002)')
    // }
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    this.rollbackState = JSON.stringify(this.selectedNode.props)
  },
  watch: {
    selectedNode: {
      handler: function (val, oldVal) {
        // Used a deep watch because p-radio does not support an @click event.
        if (val.props.s2.value === 'Faster Payments') {
          // Prevent selecting an unsupported report format in Faster Payments.
          if (this.selectedNode.props.s3.value === 'Full Test') {
            this.selectedNode.props.s3.value = ''
          }
        }
      },
      deep: true
    }
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }

  .fadein-enter
  {
    opacity: 0;
  }

  .fadein-enter-active {
    transition: opacity .6s;
  }

  .fadein-leave-active {
    transition: opacity .6s;
    opacity: 0;
  }

</style>
