var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "addEmailRecipient",
      attrs: {
        id: "add-email-recipient",
        title: _vm.TitleText,
        "hide-header-close": "",
        static: "",
        centered: true,
        "footer-class": "add-recipient-footer"
      },
      on: { shown: _vm.load, ok: _vm.Save, hidden: _vm.clear },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(ref) {
            var ok = ref.ok
            var cancel = ref.cancel
            var hide = ref.hide
            return [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.DisableSave, variant: "primary" },
                  on: {
                    click: function($event) {
                      return ok()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.ButtonText))]
              ),
              _c(
                "b-button",
                {
                  on: {
                    click: function($event) {
                      return cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("div", { attrs: { slot: "modal-ok" }, slot: "modal-ok" }, [
        _vm._v(_vm._s(_vm.ButtonText))
      ]),
      _c("div", { class: { invalid: _vm.$v.Name.$error } }, [
        _c("label", { staticClass: "col-form-label col-md-6 required" }, [
          _vm._v("Name")
        ]),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { type: "text", formatter: _vm.trimInput },
              on: { input: _vm.checkData },
              model: {
                value: _vm.$v.Name.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.Name,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.Name.$model"
              }
            }),
            _c("validation-messages", {
              attrs: { name: "Name" },
              model: {
                value: _vm.$v.Name,
                callback: function($$v) {
                  _vm.$set(_vm.$v, "Name", $$v)
                },
                expression: "$v.Name"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { class: { invalid: _vm.$v.Email.$error } }, [
        _c("label", { staticClass: "col-form-label col-md-6 required" }, [
          _vm._v("Email Address")
        ]),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { type: "text", formatter: _vm.trimInput },
              on: { input: _vm.checkEmailAddress },
              model: {
                value: _vm.$v.Email.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.Email,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.Email.$model"
              }
            }),
            _vm.EmailAddressErrorMsg.length > 0
              ? _c("small", { staticClass: "form-text small text-danger" }, [
                  _vm._v(" " + _vm._s(_vm.EmailAddressErrorMsg) + " ")
                ])
              : _vm._e(),
            _c("validation-messages", {
              attrs: { name: "contact email" },
              model: {
                value: _vm.$v.Email,
                callback: function($$v) {
                  _vm.$set(_vm.$v, "Email", $$v)
                },
                expression: "$v.Email"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }