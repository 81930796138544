<template>
  <div>
    <!-- Service User Number -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Service User Number</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserNumber"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Bank Account -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Bank Account</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.bankAccountId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Payment Signing Method -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Payment Signing Method</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.tokenType"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- HSM Certificate -->
    <div class="form-group row" v-if="actionTypeDetails.tokenType === 'Hsm'">
      <label for="description" class="col-sm-2 text-right col-form-label">HSM Certificate</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.certificateId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Individual Payment Limit -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Individual Payment Limit</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.paymentLimit"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Submission  Limit -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Submission Payment Limit</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.submissionLimit"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Submission Window Type -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Submission Window Type</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.submissionWindowType"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Window Size -->
    <div class="form-group row" v-if="this.actionTypeDetails.submissionWindowType === 'Multi Day'">
      <label for="description" class="col-sm-2 text-right col-form-label">Window Size</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.multidayWindowSize"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Advance Notice Period (In Days) -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Advance Notice Period (In Days)</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.advanceNoticeInDays"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Default Contra Narrative -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Default Contra Narrative</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.defaultContraNarrative"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- AUDDIS Group -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">AUDDIS Group</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.isAuddis"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Service User Name -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Service User Name</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserName"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Address 1 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Address 1</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserAddress1"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Address 2 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Address 2</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserAddress2"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Address 3 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Address 3</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserAddress3"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Address 4 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Address 4</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserAddress4"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Postcode -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Postcode</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserPostcode"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Service User Telephone -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Service User Telephone</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserTelephone"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Service User Fax -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Service User Fax</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserFax"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Email Address -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Email Address</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserEmail"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Service User Contact Name 1 -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Service User Contact Name 1</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserContactName1"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Service User Contact Name 2 -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Service User Contact Name 2</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserContactName2"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Service User Contact Name 3 -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Service User Contact Name 3</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserContactName3"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 1 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 1</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField1"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 2 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 2</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField2"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 3 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 3</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField3"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 4 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 4</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField4"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 5 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 5</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField5"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 6 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 6</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField6"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 7 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 7</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField7"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Custom Field 8 -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Custom Field 8</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.serviceUserCustomField8"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Default Payer Message Type -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Default Payer Message Type</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.defaultPayerMessageType"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Letter File Export Type -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Letter File Export Type</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.docxExportType"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Email BCC Address List -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Email BCC Address List</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.emailBccList"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Letter File Export Folder -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Letter File Export Folder</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.letterOutputFolder"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Email Address -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Email Address</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.domainEmailId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Allow warnings on missed submissions -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Allow warnings on missed submissions</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.allowOrphanWarnings"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Late submission notification recipients -->
    <div class="form-group row" v-if="actionTypeDetails.allowOrphanWarnings">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Late submission notification recipients</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.orphanReportingEmails"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Default Plan Template -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Default Plan Template</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.defaultPlanTemplateId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Plan Purpose -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Plan Purpose</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.planPurpose"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Auto Generate References -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Auto Generate References</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.autoGenerateReferences"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Payer Reference Generation Pattern -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Payer Reference Generation Pattern</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.payerReferencePattern"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Allow Multiple Plans -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Allow Multiple Plans</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.allowMultiplePlans"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Enable Schedule Suppression -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Enable Schedule Suppression</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.suppressionEnabled"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Suppression Start Day -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Suppression Start Day</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.suppressionDayStart"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Suppression Start Month -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Suppression Start Month</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.suppressionMonthStart"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Suppression End Day -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Suppression End Day</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.suppressionDayEnd"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Suppression End Month -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Suppression End Month</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.suppressionMonthEnd"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Update Scheduled Payments On Error -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Update Scheduled Payments On Error</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.updateSchedPaymentOnError"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Group Workflow Type -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Group Workflow Type</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.groupWorkflowType"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Api Active -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Api Active</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.apiActive"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Use Default Variable Amount File Mapping -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Use Default Variable Amount File Mapping</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.defaultVariableAmountMapping"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Variable Amount File Mapping  -->
    <div class="form-group row">
      <label
        for="description"
        class="col-sm-2 text-right col-form-label"
      >Variable Amount File Mapping</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="(diff,index) in difference.variableAmountMappingId"
            :key="index"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'
const enumRegex = /[0-9]/
const guidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
const mongoIdRegex = /^[a-f\d]{24}$/

export default {
  props: ['groupTypeDetails', 'actionTypeDetails'],
  computed: {
    difference () {
      const diff = Object.keys(this.groupTypeDetails).reduce((a, v) => {
        if (this.groupTypeDetails[v] === null || this.groupTypeDetails[v] === undefined) {
          this.groupTypeDetails[v] = ''
        }

        if (!this.actionTypeDetails[v] === null || this.actionTypeDetails[v] === undefined) {
          this.actionTypeDetails[v] = ''
        }

        switch (v) {
          case 'submissionWindowType':
            this.enumToText('submissionWindowType', this.submissionWindowTypes)

            break
          case 'defaultPayerMessageType':
            this.enumToText('defaultPayerMessageType', this.defaultPayerMessageTypes)

            break
          case 'docxExportType':
            this.enumToText('docxExportType', this.letterFileExportTypes)

            break
          case 'domainEmailId':
            this.getEmails()
            break
          case 'orphanReportingEmails':
            if (Array.isArray(this.groupTypeDetails[v])) {
              this.groupTypeDetails[v] = this.groupTypeDetails[v].map((v) => v.emailAddress).join(',')
            }

            if (Array.isArray(this.actionTypeDetails[v])) {
              this.actionTypeDetails[v] = this.actionTypeDetails[v].map((v) => v.emailAddress).join(',')
            }
            break
          case 'groupWorkflowType':
            this.enumToText('groupWorkflowType', this.groupWorkflowTypes)
            break
          case 'variableAmountMappingId':
            this.getvariableAmountMappings()
            break
          case 'bankAccountId':
            this.getBankAccounts()
            break
          case 'certificateId':
            this.getCertificate()
            break
          case 'mappingId':
            this.getMappings()
            break
        }

        var tempObj = {}

        tempObj[v] = diffSentences(this.groupTypeDetails[v].toString(), this.actionTypeDetails[v].toString())
        return Object.assign(a, tempObj)
      }, {})
      return diff
    }
  },
  data () {
    return {
      submissionWindowTypes: [{ value: 1, text: 'Single Day' }, { value: 2, text: 'Multi Day' }],
      defaultPayerMessageTypes: [{ value: 0, text: 'Email' }, { value: 1, text: 'Letter' }],
      letterFileExportTypes: [{ value: 0, text: 'Single' }, { value: 1, text: 'Merged' }],
      groupWorkflowTypes: [{ value: 0, text: 'Fully Integrated' }, { value: 1, text: 'Not Integrated' }]

    }
  },
  methods: {
    async getEmails () {
      var promises = []

      if (guidRegex.test(this.groupTypeDetails.domainEmailId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}emails/${this.groupTypeDetails.bankAccountId}`, { showerror: true, errormessage: 'Failed to get group emails', name: 'group' }))
      }
      if (guidRegex.test(this.actionTypeDetails.domainEmailId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}emails/${this.actionTypeDetails.bankAccountId}`, { showerror: true, errormessage: 'Failed to get action emails', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.domainEmailId = value.data.emailAddress
                break
              case 'action':
                this.actionTypeDetails.domainEmailId = value.data.emailAddress
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    async getPlanTemplates () {
      var promises = []

      if (guidRegex.test(this.groupTypeDetails.defaultPlanTemplateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/plandetails/${this.groupTypeDetails.defaultPlanTemplateId}`, { showerror: true, errormessage: 'Failed to get group plan template', name: 'group' }))
      }
      if (guidRegex.test(this.actionTypeDetails.defaultPlanTemplateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/plandetails/${this.actionTypeDetails.defaultPlanTemplateId}`, { showerror: true, errormessage: 'Failed to get action plan template', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.defaultPlanTemplateId = value.data.name
                break
              case 'action':
                this.actionTypeDetails.defaultPlanTemplateId = value.data.name
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    async getvariableAmountMappings () {
      var promises = []

      if (mongoIdRegex.test(this.groupTypeDetails.variableAmountMappingId)) {
        promises.push(axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.groupTypeDetails.variableAmountMappingId}`, { showerror: true, errormessage: 'Failed to get group variable amount mapping', name: 'group' }))
      }
      if (mongoIdRegex.test(this.actionTypeDetails.variableAmountMappingId)) {
        promises.push(axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.actionTypeDetails.variableAmountMappingId}`, { showerror: true, errormessage: 'Failed to get action variable amount mapping', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.variableAmountMappingId = value.data.mapping.title
                break
              case 'action':
                this.actionTypeDetails.variableAmountMappingId = value.data.mapping.title
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    async getBankAccounts () {
      var promises = []

      if (guidRegex.test(this.groupTypeDetails.bankAccountId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.groupTypeDetails.bankAccountId}`, { showerror: true, errormessage: 'Failed to get group bank account', name: 'group' }))
      }
      if (guidRegex.test(this.actionTypeDetails.bankAccountId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.actionTypeDetails.bankAccountId}`, { showerror: true, errormessage: 'Failed to get action bank account', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.bankAccountId = value.data.reference
                break
              case 'action':
                this.actionTypeDetails.bankAccountId = value.data.reference
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    async getCertificate () {
      var promises = []

      if (guidRegex.test(this.groupTypeDetails.certificateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/${this.groupTypeDetails.certificateId}`, { showerror: true, errormessage: 'Failed to get group certificate', name: 'group' }))
      }
      if (guidRegex.test(this.actionTypeDetails.certificateId)) {
        promises.push(axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate/${this.actionTypeDetails.certificateId}`, { showerror: true, errormessage: 'Failed to get action certificate', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.certificateId = value.data.friendlyName
                break
              case 'action':
                this.actionTypeDetails.certificateId = value.data.friendlyName
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    async getMappings () {
      var promises = []

      if (mongoIdRegex.test(this.groupTypeDetails.mappingId)) {
        promises.push(axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.groupTypeDetails.mappingId}`, { showerror: true, errormessage: 'Failed to get group mapping', name: 'group' }))
      }
      if (mongoIdRegex.test(this.actionTypeDetails.mappingId)) {
        promises.push(axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/${this.actionTypeDetails.mappingId}`, { showerror: true, errormessage: 'Failed to get action mapping', name: 'action' }))
      }

      try {
        if (promises.some(v => true)) {
          this.$Progress.start()
          var results = await Promise.all(promises)
          this.$Progress.finish()
          results.forEach(value => {
            switch (value.config.name) {
              case 'group':
                this.groupTypeDetails.mappingId = value.data.mapping.title
                break
              case 'action':
                this.actionTypeDetails.mappingId = value.data.mapping.title
                break
            }
          })
        }
      } catch {
        this.$Progress.fail()
      }
    },
    // Change the enum numbers to text
    enumToText (field, enumFields) {
      if (enumRegex.test(this.groupTypeDetails[field])) {
        this.groupTypeDetails[field] = enumFields.filter(swt => swt.value === this.groupTypeDetails[field])
        if (this.groupTypeDetails[field].some(x => x)) {
          this.groupTypeDetails[field] = this.groupTypeDetails[field][0].text
        } else {
          this.groupTypeDetails[field] = ''
        }
      }

      if (enumRegex.test(this.actionTypeDetails[field])) {
        this.actionTypeDetails[field] = enumFields.filter(swt => swt.value === this.actionTypeDetails[field])
        if (this.actionTypeDetails[field].some(x => x)) {
          this.actionTypeDetails[field] = this.actionTypeDetails[field][0].text
        } else {
          this.actionTypeDetails[field] = ''
        }
      }
    }
  }
}
</script>

<style>
</style>
