import _ from 'lodash'
export default {
  data () {
    return {
      isTableLoading: true,
      serverParams: {
        columnFilters: {},
        sort: [],
        page: 1,
        perPage: 10,
        searchKey: ''
      },
      defaultParams: {},
      totalRecords: 0,
      sortOptions: { enabled: true, initialSortBy: {} },
      paginationOptions: {
        enabled: true,
        perPage: 10,
        setCurrentPage: 1,
        dropdownAllowAll: false,
        jumpFirstOrLast: true
      },
      doneParams: false,
      hideTotal: false,
      updateTotal: false,
      shouldLoad: true,
      shouldSaveTableFilters: true
    }
  },
  async created () {
    // Safer to copy
    this.defaultParams = JSON.parse(JSON.stringify(this.serverParams))

    // Check if there are any in there?
    var savedParams = this.$store.getters.tableFilters(this.$route.path)
    if (savedParams && this.shouldSaveTableFilters) {
      // If there are, apply them!
      this.savedParamsToObjects(savedParams)
      this.serverParams = Object.assign({}, this.serverParams, savedParams)
    }

    if (this.shouldLoad) {
      await this.load()
    }
    this.doneParams = true
  },
  methods: {
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
    },
    onSortChange (params) {
      // Stop error appearing in console when page first loaded.
      if (params[0] !== undefined) {
        if (params[0].type === 'none') {
          params[0].type = 'asc'
        }
        this.updateParams({
          sort: params,
          page: 1
        })
      }
    },
    onColumnFilter (params) {
      this.updateTotal = true
      this.updateParams({ ...params, page: 1 })
    },
    onPerPageChange (params) {
      this.$refs.table.currentPage = 1
      this.updateParams({ perPage: params.currentPerPage, page: 1 })
    },
    onSearch: _.throttle(function (params) {
      if (this.serverParams.searchKey !== params.searchTerm) {
        this.$refs.table.currentPage = 1
        this.updateTotal = true
        this.updateParams({ searchKey: params.searchTerm, page: 1 })
      }
    }, 200, { leading: false }),
    updateParams (newProps) {
      if (this.doneParams) {
        this.serverParams = Object.assign({}, this.serverParams, newProps)
        this.load()
      }
    },
    buildGoodTableQuery (otherData) {
      const query = { ...this.serverParams.columnFilters }
      Object.keys(query).forEach((key) => { query[key] = `*${query[key]}*` })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }

      this.serverParams.otherData = otherData
      if (this.doneParams && this.shouldSaveTableFilters) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },
    savedParamsToObjects (savedParams) {
      // Update the sort options, might not need to do this as the changes _should_ call the event handlers
      this.sortOptions = { ...this.sortOptions, initialSortBy: savedParams.sort }

      // Get the field names from the column filters
      const columnnFilterKeys = Object.keys(savedParams.columnFilters)
      for (var i = 0; i < columnnFilterKeys.length; i++) {
        const filterKey = columnnFilterKeys[i]
        // Find the column in the list
        const column = this.columns.find(c => c.field === filterKey)
        // Apply the filter
        column.filterOptions.filterValue = savedParams.columnFilters[filterKey]
      }
      // Set per page
      this.paginationOptions.perPage = savedParams.perPage
      if (this.$refs.table) {
        this.$refs.table.$refs.paginationBottom.perPage = savedParams.perPage
        this.$refs.table.$refs.paginationBottom.handlePerPage()
        this.$refs.table.globalSearchTerm = savedParams.searchKey
      }
      // Set page
      this.paginationOptions.setCurrentPage = savedParams.page

      if (savedParams.searchKey !== '') {
        this.$nextTick(() => {
          this.$refs.table.globalSearchTerm = savedParams.searchKey
        })
      }
    },
    clearTableFilters () {
      this.doneParams = false
      // Clear column filters, doubt there will be any initial ones but just in case apply them after
      this.$refs.table.currentPerPage = this.defaultParams.perPage
      this.$refs.table.reset()
      // Update params on good table
      this.savedParamsToObjects(this.defaultParams)

      this.doneParams = true
      // Update params that are actually sent to the server
      this.$nextTick(() => {
        this.updateParams(this.defaultParams)
      })
    }
  }
}
