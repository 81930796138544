<template>
  <div>
    <form @submit.prevent="checkValidation() && saveAntiFraudList()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            {{status}} Anti-Fraud List
            <help-icon :docPath="docPath" />
          </h2>
        </div>
        <div class="idb-block-content">
          <div
            class="alert alert-warning"
            v-if="antiFraudList.isItemActioned"
          >This anti-fraud list has a pending action against it and cannot be edited</div>
          <b-tabs v-model="tabIndex">
            <b-tab
              title="Anti-Fraud List Details"
              :title-link-class="{invalid: $v.antiFraudList.mainAntiFraudListValidation.$error}"
              active
            >
              <!-- Name -->
              <div class="form-group row" :class="{invalid: $v.antiFraudList.name.$error}">
                <label class="col-form-label col-md-3 required">Name</label>
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    ref="defaultElement"
                    v-model.trim="$v.antiFraudList.name.$model"
                    :disabled="antiFraudList.isItemActioned"
                  />
                  <!-- Validation -->
                  <validation-messages v-model="$v.antiFraudList.name" name="name"></validation-messages>
                </div>
              </div>

              <!-- Description -->
              <div class="form-group row" :class="{invalid: $v.antiFraudList.description.$error}">
                <label class="col-form-label col-md-3">Description</label>
                <div class="col-md-6">
                  <textarea
                    rows="3"
                    class="form-control"
                    v-model.trim="$v.antiFraudList.description.$model"
                    :disabled="antiFraudList.isItemActioned"
                  ></textarea>
                  <!-- Validation -->
                  <validation-messages v-model="$v.antiFraudList.description" name="description"></validation-messages>
                </div>
              </div>

              <!-- List Type -->
              <div class="form-group row" :class="{invalid: $v.antiFraudList.listType.$error}">
                <label class="label-control col-md-3 required">List Type</label>
                <div class="col-md-3" @mouseover="mouseoverListType" @mouseout="mouseoutListType">
                  <p-radio
                    class="p-default p-round"
                    value="Disallowed List"
                    v-model="antiFraudList.listType"
                    :disabled="disableListType"
                    @change="onListTypeChange($v.antiFraudList.listType)"
                  >Disallowed List</p-radio>
                  <p-radio
                    class="p-default p-round"
                    value="Allowed List"
                    v-model="antiFraudList.listType"
                    :disabled="disableListType"
                    @change="onListTypeChange($v.antiFraudList.listType)"
                  >Allowed List</p-radio>
                  <validation-messages
                    v-model="$v.antiFraudList.listType"
                    name="anti-fraud list type"
                  ></validation-messages>
                </div>
              </div>
              <div v-show="this.showAntiFraudGroupWarning" class="form-group row">
                <span class="alert alert-warning">{{ antiFraudGroupText }}</span>
              </div>
            </b-tab>
            <b-tab title="Anti-Fraud List Items">
              <antiFraudListItems
                :status="status"
                :selectedAntiFraudListId="antiFraudList.id"
                :isItemActioned="antiFraudList.isItemActioned"
                :antiFraudList="antiFraudList"
                @itemsChanged="updateItemsChanged"
              ></antiFraudListItems>
            </b-tab>
          </b-tabs>
        </div>
        <div class="idb-block-footer">
          <button
            class="btn btn-primary"
            :disabled="disableSave"
            type="submit"
            v-if="canManageAntiFraud"
          >{{ status === "Edit" ? "Save" : status }}</button>
          <button
            v-if="status === 'Edit'"
            class="btn btn-outline-primary ml-3"
            :disabled="antiFraudList.isItemActioned || callingApi"
            type="button"
            @click="showCloneModel"
          >
            <i class="glyphicon ti-layers rpad"></i>Clone
          </button>
          <button
            class="btn btn-danger pull-right ml-3"
            :disabled="callingApi"
            type="button"
            @click="cancelChanges"
          >Cancel</button>
          <button
            v-if="canManageAntiFraud && status === 'Edit'"
            class="btn btn-outline-danger pull-right"
            @click="deleteAntiFraudList"
            :disabled="antiFraudList.isItemActioned || callingApi"
            type="button"
          >
            <i class="glyphicon ti-trash rpad"></i>Delete Anti-Fraud List
          </button>
        </div>
      </div>
    </form>
    <b-modal
      id="cloneModal"
      ref="clone-modal"
      title="Clone Anti-Fraud List"
      ok-title="Clone"
      cancel-variant="secondary-outline"
      @ok="cloneAntiFraudList"
      @hidden="resetModal"
      no-close-on-backdrop
      :centered="true"
      @shown="setCloneModalFocus"
    >
      <div class="form-group row" :class="{invalid: $v.antiFraudList.clonedName.$error}">
        <label class="col-form-label col-md-3 required">New Name</label>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.antiFraudList.clonedName.$model"
            ref="defaultElement"
          />
          <!-- Validation -->
          <validation-messages v-model="$v.antiFraudList.clonedName" name="new name">
            <small
              class="form-text small"
              v-if="$v.antiFraudList.clonedName.notSameAs !== undefined && !$v.antiFraudList.clonedName.notSameAs"
            >Cloned name cannot be the same as the original name</small>
          </validation-messages>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import { required, maxLength, requiredIf, not, sameAs } from 'vuelidate/lib/validators'
import roles from '@/Assets/Constants/roles'
import AntiFraudListItems from './AntiFraudListItems.vue'
import Utility from '@/Assets/Mixins/Utility'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [Utility],
  props: ['status', 'id'],
  computed: {
    canManageAntiFraud () {
      return this.$store.getters.isInRole(roles.ManageAntiFraud)
    },
    disableListType () {
      return this.antiFraudList.isItemActioned || this.antiFraudGroupText.length > 0
    },
    docPath () {
      switch (this.status) {
        case 'Create':
          return '/administration/antifraudlists/createantifraudlist/'
        case 'Edit':
          return '/administration/antifraudlists/editantifraudlist/'
        default:
          return null
      }
    },
    disableSave: function () {
      return this.antiFraudList.isItemActioned || this.callingApi || this.licenceError
    }
  },
  components: {
    antiFraudListItems: AntiFraudListItems
  },
  data: () => ({
    cloning: false,
    antiFraudList: {
      id: '',
      paygateId: '',
      name: '',
      clonedName: '',
      description: '',
      listType: {}
    },
    antiFraudListItems: [],
    antiFraudMessages: [],
    tabIndex: 0,
    showAntiFraudGroupWarning: false,
    antiFraudGroupText: '',
    linkedToGroups: false,
    callingApi: false,
    antiFraudLicenceSettings: {},
    blacklistCount: 0,
    whitelistCount: 0,
    licenceError: false
  }),
  created () {
    this.$store.dispatch('setParentDataLoaded', false)
  },

  async mounted () {
    var parentDataLoaded = this.$store.getters.parentDataLoaded
    console.log('START parentDataLoaded: ' + parentDataLoaded)

    console.log('Status2: ' + this.status)
    const paygateidtmp = this.$store.state.common.paygateId
    console.log('*** paygateid: ' + paygateidtmp)

    const storedAntiFraudList = this.$store.getters.antiFraudList
    console.log('storedAntiFraudList: ' + JSON.stringify(storedAntiFraudList))
    if (storedAntiFraudList === undefined) {
      if (this.status === 'Edit') {
        await this.loadAntiFraudList()
        await this.loadAntiFraudGroups()
      } else {
        this.antiFraudList.id = this.id
        this.antiFraudList.paygateId = this.$store.state.common.paygateId
      }
      await this.loadLicence()
      await this.loadAntiFraudLists()
    } else {
      this.antiFraudList.id = storedAntiFraudList.id
      this.antiFraudList.paygateId = storedAntiFraudList.paygateId
      // this.id = this.antiFraudList.id
      this.antiFraudList.name = storedAntiFraudList.name
      this.antiFraudList.description = storedAntiFraudList.description
      this.antiFraudList.listType = storedAntiFraudList.listType
      this.$store.dispatch('setAntiFraudList', undefined)
    }

    console.log('AF ID: ' + this.antiFraudList.id)

    const submissionId = this.$route.query.submissionId
    if (submissionId !== undefined && submissionId !== 'cancel') {
      this.tabIndex = 1
    } else {
      this.$refs.defaultElement.focus()
    }
    this.$store.dispatch('setParentDataLoaded', true)
    var parentDataLoaded2 = this.$store.getters.parentDataLoaded
    console.log('END parentDataLoaded: ' + parentDataLoaded2)
  },

  async beforeRouteLeave (to, from, next) {
    try {
      if (to.path.includes('/payments/search/searchSubmissions')) {
        next()
      }
      else if (this.$v.$anyDirty && to.name !== 'Help') {
        var result = await swal.fire({
          title: 'Navigation',
          text: 'Data has not been saved. Continue without saving?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
        if (result.isConfirmed) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    } catch (e) {
    }
  },

  methods: {
    async saveAntiFraudList () {
      console.log('AF list: ' + JSON.stringify(this.antiFraudList))
      try {
        this.callingApi = true
        if (this.status === 'Create') {
          // this.antiFraudList.id = this.id
          // this.antiFraudList.paygateId = this.$store.state.common.paygateId
          const response = await axios.post(
            `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList`,
            this.antiFraudList, { showload: true }
          )
          this.$v.$reset()
          if (response.data.status === 'Action') {
            this.$toastr.s(
              'New anti-fraud list needs to be approved',
              'Needs Approval'
            )
            this.$router.push({ name: 'AntiFraudLists' })
          } else {
            if (response.data.status !== 'Failed') {
              this.$toastr.s('New anti-fraud list created', 'Created')
              this.$router.push({ name: 'AntiFraudLists' })
            } else {
              this.$toastr.e(response.data.toastMessage, 'Update Error')
            }
          }
        } else {
          const response = await axios.put(
            `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList`,
            this.antiFraudList, { showload: true }
          )
          if (response.data.status === 'Action') {
            this.$toastr.s(
              'Anti-fraud list update needs to be approved',
              'Needs Approval'
            )
            this.antiFraudList.isItemActioned = true
          } else {
            if (response.data.status !== 'Failed') {
              this.$toastr.s('Anti-fraud list updated', 'Updated')
            } else {
              this.$toastr.e(response.data.toastMessage, 'Update Error')
            }
          }
        }
        this.$v.$reset()
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.callingApi = false
      }
    },

    async deleteAntiFraudList () {
      try {
        if (this.linkedToGroups) {
          this.$toastr.w('Cannot delete this anti-fraud list because it is linked to some groups.')
          return
        }

        // Get confirmation from the user that they really want to delete the anti-fraud list.
        var swalResult = await swal.fire({
          title: 'Delete Anti-Fraud List?',
          text: 'Are you sure you want to delete this anti-fraud list?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch (error) {
        // Do nothing as this will be if they clicked cancel
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        this.$v.$reset()
        this.callingApi = true
        const response = await axios.delete(
          `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/${this.antiFraudList.id}`, { showload: true }
        )
        if (response.data.status === 'Action') {
          this.$toastr.s(
            'Anti-fraud list deletion needs to be approved',
            'Needs Approval'
          )
          await this.loadAntiFraudList()
        } else {
          this.$toastr.s('Anti-fraud list deleted', 'Deleted')
          this.$router.push({ name: 'AntiFraudLists' })
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.callingApi = false
      }
    },

    async loadAntiFraudList () {
      try {
        this.callingApi = true
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/${this.id}`,
          { params: { paygateId: this.$store.state.common.paygateId } }
        )
        this.antiFraudList = response.data
        console.log('Loaded AF list: ' + JSON.stringify(this.antiFraudList))
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.callingApi = false
          this.$v.$reset()
        })
      }
    },

    async loadAntiFraudLists () {
      var paygateId = this.selectedCustomer

      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList`, { params: { paygateid: paygateId } })
        response.data.data.forEach(aflist => {
          if (aflist.listType === 'Black List') {
            this.blacklistCount++
          } else {
            this.whitelistCount++
          }
        })
      } catch (e) {
        console.log(e)
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async loadAntiFraudGroups () {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/getAntiFraudGroupNames/${this.id}`
        )
        // console.log('antiFraudGroups: ' + JSON.stringify(response.data))
        const antiFraudGroupNames = response.data.groupNames
        if ((antiFraudGroupNames !== undefined && antiFraudGroupNames.length > 0)) {
          this.linkedToGroups = true
          const grammer = antiFraudGroupNames.length === 1 ? 'group' : 'groups'
          this.antiFraudGroupText = `Cannot change the List Type because this anti-fraud list is linked to the following ${grammer} : ${response.data.formattedGroupNames}`
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      } finally {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },

    updateItemsChanged () {
      this.$v.$touch()
    },

    showCloneModel () {
      this.antiFraudList.clonedName = `${this.antiFraudList.name} Copy`
      this.$refs['clone-modal'].show()
      this.cloning = true
    },

    setCloneModalFocus () {
      this.$refs.defaultElement.focus()
    },

    async cloneAntiFraudList (e) {
      this.$v.antiFraudList.clonedName.$touch()
      e.preventDefault()
      if (this.$v.antiFraudList.clonedName.$invalid) {
        this.$toastr.e('There are validation errors on the form', 'Validation')
      } else {
        try {
          this.antiFraudList.antiFraudListItems = this.antiFraudListItems
          const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}antiFraudList/Clone`, this.antiFraudList)
          this.$v.$reset()
          if (response.data.status === 'Action') {
            this.$toastr.s('New anti-fraud list needs to be approved', 'Needs Approval')
            this.$router.push({ name: 'AntiFraudLists' })
          } else {
            this.$toastr.s('Anti-fraud list cloned', 'Cloned')
            this.$router.push({ name: 'AntiFraudListEdit', params: { id: response.data.id } })
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        }
      }
    },

    resetModal () {
      this.cloning = false
      this.antiFraudList.clonedName = ''
    },

    mouseoverListType () {
      if (this.antiFraudGroupText.length > 0) {
        this.showAntiFraudGroupWarning = true
      }
    },

    mouseoutListType () {
      this.showAntiFraudGroupWarning = false
    },

    onListTypeChange (element) {
      element.$touch()
      this.licenceError = false
      if (this.antiFraudList.listType === 'Black List') {
        if (this.blacklistCount + 1 > this.antiFraudLicenceSettings.blacklistThreshold) {
          this.$snapbar.e(`You are not licensed to have more than ${this.antiFraudLicenceSettings.blacklistThreshold} Disallowed List${this.antiFraudLicenceSettings.blacklistThreshold === 1 ? '' : 's'}.`)
          this.licenceError = true
        }
      } else {
        if (this.whitelistCount + 1 > this.antiFraudLicenceSettings.whitelistThreshold) {
          this.$snapbar.e(`You are not licensed to have more than ${this.antiFraudLicenceSettings.whitelistThreshold} Allowed List${this.antiFraudLicenceSettings.whitelistThreshold === 1 ? '' : 's'}.`)
          this.licenceError = true
        }
      }
    },

    async loadLicence () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence')
      this.antiFraudLicenceSettings = response.data.antiFraud
    },

    cancelChanges () {
      this.$router.push('/admin/antifraud/list')
    }
  },
  validations: {
    antiFraudList: {
      name: { required, maxLength: maxLength(50) },
      description: { maxLength: maxLength(200) },
      listType: { required },
      clonedName: {
        required: requiredIf(function () {
          return this.cloning
        }),
        maxLength: maxLength(50),
        notSameAs: not(sameAs('name'))
      },
      mainAntiFraudListValidation: ['antiFraudList.name', 'antiFraudList.description', 'antiFraudList.listType']
    }
  }
}
</script>
