<template>
  <actions-block
    v-bind:action="action"
    :status="status"
    :from="from"
    name="User"
    v-if="action.id"
    :shouldShowFooter="shouldShowFooter"
    :userRoleError="userRoleError"
  >
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>User Information</h2>
      </div>
      <div class="idb-block-content">
        <form>
          <div class="alert alert-secondary" role="alert" v-if="status === 'Edit'">
            The previous details are shown in
            <span class="del">red</span>, the new details in
            <span class="ins">green</span>.
          </div>
          <!-- Name -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Name</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.name"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Email -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Email</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.email"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Enabled -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Enabled</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.enabled"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Mobile Phone -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Mobile Phone</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.phoneNumber"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Email Action -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Email Action</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.emailAction"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Individual Item Limit -->
          <div class="form-group row">
            <label
              for="description"
              class="col-sm-2 text-right col-form-label"
            >Individual Item Limit</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.paymentLimit"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Submission Limit -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Submission Limit</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.submissionLimit"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Approval Limit -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Approval Limit</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.approvalLimit"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>

          <!-- Roles -->
          <div class="form-group row">
            <label for="description" class="col-sm-2 text-right col-form-label">Roles</label>
            <div class="col-sm-10">
              <div class="form-control-plaintext">
                <span
                  style="margin-right:5px"
                  v-for="diff in difference.roles"
                  :class="{ins:diff.added,del:diff.removed}"
                  :key="diff.value"
                >{{diff.value}}</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </actions-block>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'
import ActionsBlock from '@/Components/Platform/Actions/ActionsBlock'

export default {
  props: ['actionId', 'userId'],
  components: { 'actions-block': ActionsBlock },
  computed: {
    difference () {
      if (!this.user || !this.action.data) {
        if (this.status === 'Create') {
          this.user = this.action.data
        } else if (this.status === 'Delete' && this.user) {
          this.action.data = this.user
        } else {
          return {}
        }
      }
      return Object.keys(this.user).map((v) => {
        if (v === 'isItemActioned' || v === 'roles' || v === 'claims' || v === 'logins' || v === 'historicPasswords' || v === 'customer' || v === 'hsmPin') {
          return
        }
        var tempObj = {}
        if (v === 'rolesToAdd') {
          const removed = this.user.rolesToAdd.filter((v) => !this.action.data.rolesToAdd.some((t) => { return t.id === v.id }))
          const added = this.action.data.rolesToAdd.filter((v) => !this.user.rolesToAdd.some((t) => t.id === v.id))
          const same = this.user.rolesToAdd.filter((v) => this.action.data.rolesToAdd.some((t) => t.id === v.id))
          const diffObject = []

          if (removed.length > 0) {
            diffObject.push({ removed: true, value: removed.map(v => v.name).join(', ') })
          }
          if (added.length > 0) {
            diffObject.push({ added: true, value: added.map(v => v.name).join(', ') })
          }
          if (same.length > 0) {
            diffObject.push({ value: same.map(v => v.name).join(', ') })
          }
          tempObj.roles = diffObject
          return tempObj
        } else {
          if (this.user[v] === null || this.user[v] === undefined) {
            this.user[v] = ''
          }
          if (this.action.data[v] === null || this.action.data[v] === undefined) {
            this.action.data[v] = ''
          }
        }

        tempObj[v] = diffSentences(this.user[v].toString(), this.action.data[v].toString())
        return tempObj
      }).reduce((a, v) => {
        return Object.assign(a, v)
      })
    }
  },
  data () {
    return {
      user: null,
      action: { data: null },
      status: '',
      from: {},
      shouldShowFooter: false,
      userRoleError: false
    }
  },
  async mounted () {
    try {
      this.$Progress.start()
      const actionResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })

      this.action = actionResponse.data
      this.status = this.action.actionSubClass

      var roleResult = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/CheckRole/${this.actionId}`, { action: true })

      if (!roleResult.data) {
        this.userRoleError = true
        if (this.action.rejected) {
          this.shouldShowFooter = false
        }

        this.$Progress.finish()
        return
      }

      if (this.status !== 'Create') {
        const userResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Users/${this.userId}`, { showerror: true, errormessage: 'Failed to get user' })
        if (this.status === 'Delete') {
          this.action.data = null
        }
        this.user = userResponse.data
      }

      this.$Progress.finish()
      this.shouldShowFooter = true
    } catch {
      this.$Progress.fail()
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  }
}
</script>
