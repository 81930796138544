var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "manageSchedule" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c(
                "div",
                { staticClass: "idb-block" },
                [
                  _c("div", { staticClass: "idb-block-title" }, [
                    _c(
                      "h2",
                      [
                        false
                          ? _c(
                              "router-link",
                              {
                                staticClass: "pull-right ml-2 text-danger",
                                attrs: { to: "/collections/payer/manage" }
                              },
                              [_c("i", { staticClass: "fas fa-times mr-2" })]
                            )
                          : _vm._e(),
                        _c("help-icon", {
                          attrs: {
                            docPath:
                              "/paygate-collections/payers/payercreation/"
                          }
                        }),
                        _vm._v("Create Payer Wizard"),
                        _vm.stepsTaken
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                ": " +
                                  _vm._s(_vm.accountHolderName) +
                                  " (" +
                                  _vm._s(_vm.reference) +
                                  ")"
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("horizontal-stepper", {
                    ref: "stepper",
                    attrs: { steps: _vm.payerSteps },
                    on: {
                      "completed-step": _vm.stepCompleted,
                      "stepper-finished": _vm.checkCompleted,
                      cancel: _vm.cancelProcess
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }