<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              View Usage Settings
              <span class="pull-right">
                <a :href="helpUrl" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <label for="optinReport" class="col-form-label col-md-3">Opt in to Receive Report</label>
              <div class="col-md-1">
                <p-check :labelId="'optinReport'"
                  class="p-switch p-fill"
                  color="primary"
                  v-model="viewUsageSettings.optedIn"
                ></p-check>
              </div>
            </div>
            <div v-show="viewUsageSettings.optedIn">
              <div class="row form-group">
                <label class="col-form-label col-md-3">Report Frequency</label>
                <div class="col-md-3">
                  <b-form-select v-model="viewUsageSettings.reportFrequency" :options="reportFrequencies"></b-form-select>
                </div>
              </div>
              <div class="row form-group">
                <label class="col-form-label col-md-3">Included Groups</label>
                <div class="col-md-4">
                  <vue-select
                    multiple
                    v-model="selectedGroups"
                    :options="usersGroups"
                    :closeOnSelect="false"
                    append-to-body
                  ></vue-select>
                </div>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="disableSave"
              v-on:click="saveSettings"
            >Save Settings</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    VueSelect
  },
  data: () => ({
    reportFrequencies: [
      { value: '0', text: 'Daily' },
      { value: '1', text: 'Weekly' },
      { value: '2', text: 'Monthly' },
      { value: '3', text: 'Annual' }
    ],
    viewUsageSettings: {},
    usersGroups: [],
    selectedGroups: [],
    disableSave: true,
    userId: '',
    paygateId: '',
    newSettings: false,
    bacsPgeGroups: null,
    bacsPgoGroups: null,
    fpsPgeGroups: null,
    fpsPgoGroups: null,
    ddmsGroups: null,
    BACSPGEGROUPTYPE: '0',
    BACSPGOGROUPTYPE: '3',
    FPSPGEGROUPTYPE: '4',
    FPSPGOGROUPTYPE: '5',
    DDMSGROUPTYPE: '2',
    helpUrl: ''
  }),
  methods: {
    // async loadGroups () {
    //   var paygateId = this.$store.getters.getClaim('paygate_id').value
    //   var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/dropdown/${paygateId}`, { showload: true })
    //   response.data.map(group => {
    //     var grp = { value: group.id, label: group.label }
    //     this.usersGroups.push(grp)
    //   })
    //   this.usersGroups.splice(0, 0, { value: '00000000-0000-0000-0000-000000000000', label: 'All' })
    //   this.usersGroups.sort((a, b) => a.label.localeCompare(b.label))
    // },
    async loadGroups (groupTypes) {
      await Promise.all(groupTypes.map(async groupType => {
        // eslint-disable-next-line
        var payload = { 'paygateId': this.paygateId, 'groupType': groupType, 'or': true, 'groupRole': ['Create', 'Sign', 'Approval', 'Submit', 'GroupAdministrator'] }
        await this.$store.dispatch('getGroups', payload)

        switch (groupType) {
          case this.BACSPGEGROUPTYPE:
            this.bacsPgeGroups = this.$store.getters.groups
            await this.pushGroups(this.bacsPgeGroups)
            break
          case this.BACSPGOGROUPTYPE:
            this.bacsPgoGroups = this.$store.getters.groups
            await this.pushGroups(this.bacsPgoGroups)
            break
          case this.FPSPGEGROUPTYPE:
            this.fpsPgeGroups = this.$store.getters.groups
            await this.pushGroups(this.fpsPgeGroups)
            break
          case this.FPSPGOGROUPTYPE:
            this.fpsPgoGroups = this.$store.getters.groups
            await this.pushGroups(this.fpsPgoGroups)
            break
          case this.DDMSGROUPTYPE:
            this.ddmsGroups = this.$store.getters.groups
            await this.pushGroups(this.ddmsGroups)
            break
        }
      }))

      this.usersGroups.splice(0, 0, { value: '00000000-0000-0000-0000-000000000000', label: 'All' })
      this.usersGroups.sort((a, b) => a.label.localeCompare(b.label))
    },

    async pushGroups (groups) {
      groups.map(group => {
        var grp = { value: group.groupId, label: group.name }
        this.usersGroups.push(grp)
      })
    },

    async loadViewUsageSettings () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}usageReportRecipient`, { showload: true })
        this.viewUsageSettings = response.data
        if (this.viewUsageSettings.userId === '00000000-0000-0000-0000-000000000000') {
          this.newSettings = true
          this.viewUsageSettings.userId = this.userId
          this.viewUsageSettings.paygateId = this.paygateId
        } else {
          if (this.viewUsageSettings.usageReportRecipientGroups !== undefined && this.viewUsageSettings.usageReportRecipientGroups.length > 0) {
            for (var i = 0; i < this.viewUsageSettings.usageReportRecipientGroups.length; i++) {
              var group = this.usersGroups.find(m => m.value === this.viewUsageSettings.usageReportRecipientGroups[i].groupId)
              console.log('group' + i + ':' + JSON.stringify(group))
              if (group !== null && group !== undefined) {
                this.selectedGroups.push(group)
              }
            }
            // console.log('loaded selected groups: ' + JSON.stringify(this.selectedGroups))
          }
        }
        // console.log('user settings: ' + JSON.stringify(this.viewUsageSettings))
        // console.log('new settings: ' + this.newSettings)
      } catch (e) {
        console.log(e)
      }
    },

    async saveSettings () {
      if (this.viewUsageSettings.optedIn && this.selectedGroups.length === 0) {
        this.$toastr.w('You must select at least one group')
        return
      }
      this.disableSave = true
      try {
        console.log('saveSettings selected groups: ' + JSON.stringify(this.selectedGroups))
        this.viewUsageSettings.usageReportRecipientGroups = []
        if (this.selectedGroups !== undefined && this.selectedGroups.length > 0) {
          for (var i = 0; i < this.selectedGroups.length; i++) {
            this.viewUsageSettings.usageReportRecipientGroups.push({ userId: this.viewUsageSettings.userId, paygateId: this.viewUsageSettings.paygateId, groupId: this.selectedGroups[i].value })
          }
        }
        // console.log('saveSettings usage settings: ' + JSON.stringify(this.viewUsageSettings))
        var response = {}
        if (this.viewUsageSettings.optedIn) {
          if (this.newSettings) {
            response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}usageReportRecipient`, this.viewUsageSettings, { showload: true })
            this.newSettings = false
          } else {
            response = await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}usageReportRecipient`, this.viewUsageSettings, { showload: true })
          }
        } else {
          response = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}usageReportRecipient`, { showload: true })
          this.newSettings = true
        }
        // console.log('response: ' + JSON.stringify(response.data))
        if (response.data.status !== 'Failed') {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
        this.disableSave = false
      } catch (e) {
        this.disableSave = false
        console.log(e)
        this.$toastr.e('Failed to save settings')
      }
    }
  },

  async mounted () {
    this.userId = this.$store.getters.getClaim('sub').value
    this.paygateId = this.$store.getters.getClaim('paygate_id').value
    await this.loadGroups([this.BACSPGEGROUPTYPE, this.BACSPGOGROUPTYPE, this.FPSPGEGROUPTYPE, this.FPSPGOGROUPTYPE, this.DDMSGROUPTYPE])
    await this.loadViewUsageSettings()
    this.disableSave = false
  }
}
</script>
