<template>
  <div>
    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Name</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.name}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Description</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.description}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Group Type</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.groupType}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Service User Number</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.serviceUserNumber}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Payment Signing Method</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.tokenType}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.tokenType == 'Hsm'">
      <label class="col-3 offset-1 col-form-label">HSM Certificate</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.friendlyName}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Individual Payment Limit</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.paymentLimit | number}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Submission Payment Limit</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.submissionLimit| number}}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Delete Payment Data</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.deletePaymentData" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.tokenType == 'Hsm'">
      <label class="col-3 offset-1 col-form-label">HSM PIN Required</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <tick v-model="group.details.hsmPinRequired" :coloured="false"></tick>
        </div>
      </div>
    </div>

    <group-config-users :users="group.users"></group-config-users>
  </div>
</template>

<script>
import GroupConfigUsers from './GroupConfigUsers'

export default {
  props: {
    group: {
      required: true,
      type: Object
    }
  },
  components: {
    GroupConfigUsers
  }
}
</script>

<style>
</style>
