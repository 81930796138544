var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "idb-block" },
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Select bureau customer import file "),
            _vm._m(0),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "ml-5 mt-3", attrs: { hidden: "" } },
        [_c("b-row", [_vm._v("Paygate Id = " + _vm._s(this.paygateId))])],
        1
      ),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "dropzone",
                    {
                      ref: "fileUploader",
                      attrs: {
                        id: "my-dropzone",
                        options: _vm.dropzoneOptions,
                        "use-custom-slot": true,
                        useCustomSlot: true,
                        "max-file-size-in-m-b": 120,
                        "max-number-of-files": 10
                      },
                      on: {
                        "vdropzone-success": _vm.afterSuccess,
                        "vdropzone-file-added": _vm.fileAdded,
                        "vdropzone-removed-file": _vm.fileRemoved,
                        "vdropzone-sending": _vm.sendingEvent,
                        "vdropzone-error": _vm.dropZoneError
                      }
                    },
                    [
                      _c("div", { staticClass: "dropzone-custom-content" }, [
                        _c("h3", { staticClass: "dropzone-custom-title" }, [
                          _vm._v(
                            "Drag and drop to upload your customer files for importing"
                          )
                        ]),
                        _c("div", { staticClass: "subtitle" }, [
                          _vm._v(
                            "...or click to select a file from your computer"
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c("vue-good-table", {
            ref: "customerImportFiles",
            staticClass: "table-unset",
            attrs: {
              paginationOptions: {
                enabled: true,
                perPage: 10
              },
              rows: _vm.importFiles,
              columns: _vm.columns,
              lineNumbers: true,
              "sort-options": {
                enabled: true
              },
              styleClass: "vgt-table striped bordered"
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "mapping"
                      ? _c(
                          "span",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { sm: "12" } },
                                  [
                                    _c("model-list-select", {
                                      attrs: {
                                        id: "mappingSelector-" + props.row.id,
                                        list: _vm.mappings,
                                        "option-value": "value",
                                        "option-text": "text",
                                        placeholder: "Mapping",
                                        isDisabled:
                                          props.row.status !== "Uploaded"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateFileMapping(
                                            props.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: props.row.mapping,
                                        callback: function($$v) {
                                          _vm.$set(props.row, "mapping", $$v)
                                        },
                                        expression: "props.row.mapping"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : props.column.field === "bureauFileStatus"
                      ? _c("span", [
                          _c(
                            "span",
                            {
                              staticClass: "w-100 badge badge-pill",
                              class: _vm.getVariant(props.row.bureauFileStatus)
                            },
                            [
                              _vm._v(
                                _vm._s(props.formattedRow[props.column.field])
                              )
                            ]
                          )
                        ])
                      : props.column.field == "actions"
                      ? _c("span", [
                          props.row.status == "Uploaded"
                            ? _c(
                                "span",
                                [
                                  props.row.mapping === ""
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary mt-1",
                                          attrs: {
                                            id: "Disabled",
                                            title: "Please select a mapping",
                                            disabled: true
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-file-import mr-2"
                                          }),
                                          _vm._v("Import ")
                                        ]
                                      )
                                    : _c(
                                        "b-button",
                                        {
                                          staticClass: "btn btn-primary mt-1",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onImportClick(
                                                props.row
                                                  .bureauCustomerImportFileId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-file-import mr-2"
                                          }),
                                          _vm._v("Import")
                                        ]
                                      ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn mt-1 btn-danger ml-1",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onDeleteFileClick(
                                            props.row
                                              .bureauCustomerImportFileId,
                                            props.row.filename
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-trash mr-2"
                                      }),
                                      _vm._v("Delete ")
                                    ]
                                  )
                                ],
                                1
                              )
                            : props.row.status == "Editing"
                            ? _c("span", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary mt-1",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.onVerifyFileClick(
                                          props.row.bureauCustomerImportFileId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-file-import mr-2"
                                    }),
                                    _vm._v("Verify ")
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn mt-1 btn-danger ml-1",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.onDeleteFileClick(
                                          props.row.bureauCustomerImportFileId,
                                          props.row.filename
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash mr-2"
                                    }),
                                    _vm._v("Delete ")
                                  ]
                                )
                              ])
                            : _c("span", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary mt-1",
                                    attrs: { disabled: true }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-spinner fa-pulse mr-2"
                                    }),
                                    _vm._v("Please wait")
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn mt-1 btn-danger ml-1",
                                    attrs: { disabled: true },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.onDeleteFileClick(
                                          props.row.bureauCustomerImportFileId,
                                          props.row.filename
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash mr-2"
                                    }),
                                    _vm._v("Delete ")
                                  ]
                                )
                              ])
                        ])
                      : _c(
                          "span",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "mt-2", attrs: { sm: "12" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          props.formattedRow[props.column.field]
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pull-right" }, [
      _c("a", { attrs: { href: "#", target: "_blank" } }, [
        _c("i", { staticClass: "far fa-question-circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }