<template>
  <div>Nope</div>
</template>

<script>

export default {
  data: () => ({
  })
  // watch: {
  //   query: {
  //     handler (query) {
  //       mockData(query).then(({ rows, total }) => {
  //         this.data = rows
  //         this.total = total
  //       })
  //     },
  //     deep: true
  //   }
  // }
}
</script>

<style>

</style>
