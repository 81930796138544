var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fillHeightContainer" }, [
    _vm._m(0),
    _vm.chartData && _vm.options
      ? _c(
          "span",
          [
            _c("agent-file-transfer-line-graph", {
              attrs: {
                height: _vm.height,
                "chart-data": _vm.chartData,
                options: _vm.options
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h4", { staticClass: "m-0" }, [_vm._v("Agent File Transfers")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }