<template>
  <loading class="main" v-bind:loading="true"></loading>
</template>
<script>
import Auth from '@/Assets/Components/Authentication/auth'

export default {
  name: 'authCallbackSilent',
  data: function () {
    return {
      loading: true
    }
  },
  created () {
    Auth.manager.signinSilentCallback()
  }
}
</script>
