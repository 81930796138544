<template>
  <div>
    <!-- Watched folder -->
    <div class="form-group row" :class="{invalid: details.watchedFolder.$error}">
      <label class="col-form-label col-md-3">Watched Folder</label>
      <div class="col-md-6">
        <input type="text" class="form-control" v-model.trim="details.watchedFolder.$model" :disabled="true" />
        <!-- Validation -->
        <validation-messages v-model="details.watchedFolder" name="watched folder if any other settings have been set">
          <small
            class="form-text small"
            v-if="details.watchedFolder.uniqueWatchedFolderValidator !== null && details.watchedFolder.uniqueWatchedFolderValidator !== undefined && !details.watchedFolder.uniqueWatchedFolderValidator">
              This watched folder is already being used by another group
            </small>
        </validation-messages>
      </div>
      <div class="col-md-1">
        <button class="btn btn-outline-secondary" @click.prevent="btnWatchedFolderBrowser" type="button">...</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-outline-danger pull-right" @click.prevent="btnClearSettings" type="button">Clear Settings</button>
      </div>
    </div>
    <!-- Default submission reference -->
    <div class="form-group row" :class="{invalid: details.defaultSubmissionReference.$error}">
      <label class="col-form-label col-md-3">Default Sub Reference</label>
      <div class="col-md-6">
        <input type="text" class="form-control" v-model.trim="details.defaultSubmissionReference.$model" maxlength="100" />
      </div>
    </div>
    <!-- Default contra narrative -->
    <div class="form-group row" :class="{invalid: details.defaultContraNarrative.$error}">
      <label class="col-form-label col-md-3">Default Contra Narrative</label>
      <div class="col-md-6">
        <input type="text" class="form-control" v-model.trim="details.defaultContraNarrative.$model" />
      </div>
    </div>
    <!-- Stop on duplicate file -->
    <div class="form-group row">
      <label for="stoponduplicate" class="col-form-label col-md-3">Stop on Duplicate File</label>
      <div class="col-md-1">
        <p-check :labelId="'stoponduplicate'"
          class="p-switch p-fill"
          color="primary"
          v-model="details.stopOnDuplicateFile.$model"
        ></p-check>
      </div>
    </div>
    <!-- Stop on presub val fix -->
    <div class="form-group row">
      <label for="stoponpresubVal" class="col-form-label col-md-3">Stop on Pre-sub Val Fix</label>
      <div class="col-md-1">
        <p-check :labelId="'stoponpresubVal'"
          class="p-switch p-fill"
          color="primary"
          v-model="details.stopOnFix.$model"
        ></p-check>
      </div>
      <label for="removeFixes" v-show="!details.stopOnFix.$model" class="col-form-label col-md-3 required">Remove Fix Payments</label>
      <div v-show="!details.stopOnFix.$model" class="col-md-1">
        <p-check :labelId="'removeFixes'"
          class="p-switch p-fill"
          color="primary"
          v-model="details.removeFixPayments.$model"
          @change="removeFixPaymentsChange()"
        ></p-check>
      </div>
    </div>
    <!-- Remove fix payments -->
    <div v-show="!details.stopOnFix.$model && details.removeFixPayments.$model" class="form-group row">
      <div class="col-md-4"></div>
      <label v-show="details.removeFixPayments.$model === true" class="col-form-label col-md-3 required">Removed Fix Action</label>
      <div v-show="details.removeFixPayments.$model === true" class="col-md-2">
        <b-form-select v-model="details.removedFixesAction.$model" :options="removedPaymentActions"></b-form-select>
      </div>
    </div>

    <!-- Stop on presub val warning -->
    <div class="form-group row">
      <label for="stopOnWarning" class="col-form-label col-md-3">Stop on Pre-sub Val Warning</label>
      <div class="col-md-1">
        <p-check :labelId="'stopOnWarning'"
          class="p-switch p-fill"
          color="primary"
          v-model="details.stopOnWarning.$model"
        ></p-check>
      </div>
      <label for="removeWarnings" v-show="!details.stopOnWarning.$model" class="col-form-label col-md-3 required">Remove Warning Payments</label>
      <div v-show="!details.stopOnWarning.$model" class="col-md-1">
        <p-check :labelId="'removeWarnings'"
          class="p-switch p-fill"
          color="primary"
          v-model="details.removeWarningPayments.$model"
          @change="removeWarningPaymentsChange()"
        ></p-check>
      </div>
    </div>
    <!-- Remove warning payments -->
    <div v-show="!details.stopOnWarning.$model && details.removeWarningPayments.$model" class="form-group row">
      <div class="col-md-4"></div>
      <label v-show="details.removeWarningPayments.$model === true" class="col-form-label col-md-3 required">Removed Warning Action</label>
      <div v-show="details.removeWarningPayments.$model === true" class="col-md-2">
        <b-form-select v-model="details.removedWarningsAction.$model" :options="removedPaymentActions"></b-form-select>
      </div>
    </div>

    <!-- Stop on presub val duplicate -->
    <div class="form-group row">
      <label for="stopOnDuplicatePreSub" class="col-form-label col-md-3">Stop on Pre-sub Val Duplicate</label>
      <div class="col-md-1">
        <p-check :labelId="'stopOnDuplicatePreSub'"
          class="p-switch p-fill"
          color="primary"
          v-model="details.stopOnDuplicate.$model"
        ></p-check>
      </div>
      <label for="'removeDupe'" v-show="!details.stopOnDuplicate.$model" class="col-form-label col-md-3 required">Remove Duplicate Payments</label>
      <div v-show="!details.stopOnDuplicate.$model" class="col-md-1">
        <p-check :labelId="'removeDupe'"
          class="p-switch p-fill"
          color="primary"
          v-model="details.removeDuplicatePayments.$model"
          @change="removeDuplicatePaymentsChange()"
        ></p-check>
      </div>
    </div>
    <!-- Remove duplicate payments -->
    <div v-show="!details.stopOnDuplicate.$model && details.removeDuplicatePayments.$model" class="form-group row">
      <div class="col-md-4"></div>
      <label v-show="details.removeDuplicatePayments.$model === true" class="col-form-label col-md-3 required">Removed Duplicate Action</label>
      <div v-show="details.removeDuplicatePayments.$model === true" class="col-md-2">
        <b-form-select v-model="details.removedDuplicatesAction.$model" :options="removedPaymentActions"></b-form-select>
      </div>
    </div>
    <!-- File save folder -->
    <div class="form-group row" :class="{invalid: details.fileSaveFolder.$error}">
      <label class="col-form-label col-md-3">File Save Folder</label>
      <div class="col-md-6">
        <input type="text" class="form-control" v-model.trim="details.fileSaveFolder.$model" :disabled="true" />
        <!-- Validation -->
        <validation-messages v-model="details.fileSaveFolder" name="file save folder if any removed payments are saved to a new file">
        </validation-messages>
      </div>
      <div class="col-md-1">
        <button class="btn btn-outline-secondary" @click.prevent="btnFileSaveFolderBrowser" type="button">...</button>
      </div>
    </div>
    <folderBrowser v-if="showWatchedFolderBrowser" @close="closeWatchedFolderPopup()">
      <h3 slot="header">Watched Folder Path</h3>
    </folderBrowser>
    <folderBrowser v-if="showFileSaveFolderBrowser" @close="closeFileSaveFolderPopup()">
      <h3 slot="header">File Save Path</h3>
    </folderBrowser>
  </div>
</template>

<script>
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'

export default {
  components: {
    folderBrowser: folderBrowser
  },
  props: ['details'],
  data () {
    return {
      removedPaymentActions: [
        { value: 'CreateNewSubmission', text: 'Create new submission' },
        { value: 'CreateNewFile', text: 'Create new file' },
        { value: 'Ignore', text: 'Ignore' }
      ],
      showWatchedFolderBrowser: false,
      showFileSaveFolderBrowser: false
    }
  },

  methods: {
    removeFixPaymentsChange () {
      this.details.removedFixesAction.$model = 'Ignore'
    },

    removeWarningPaymentsChange () {
      this.details.removedWarningsAction.$model = 'Ignore'
    },

    removeDuplicatePaymentsChange () {
      this.details.removedDuplicatesAction.$model = 'Ignore'
    },

    btnWatchedFolderBrowser () {
      this.showWatchedFolderBrowser = true
    },

    closeWatchedFolderPopup () {
      this.showWatchedFolderBrowser = false
      this.details.watchedFolder.$model = this.$store.state.mapping.modalPopupString
    },

    btnFileSaveFolderBrowser () {
      this.showFileSaveFolderBrowser = true
    },

    closeFileSaveFolderPopup () {
      this.showFileSaveFolderBrowser = false
      this.details.fileSaveFolder.$model = this.$store.state.mapping.modalPopupString
    },

    btnClearSettings () {
      const vm = this
      this.$swal({
        title: 'Are you sure?',
        text: 'Do you want to clear the auto-file import settings?',
        type: 'warning',
        animation: false,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-light',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonClass: 'btn btn-danger'
      }).then(function () {
        vm.details.watchedFolder.$model = ''
        vm.details.fileSaveFolder.$model = ''
        vm.details.defaultSubmissionReference.$model = ''
        vm.details.defaultContraNarrative.$model = ''
        vm.details.stopOnDuplicateFile.$model = false
        vm.details.stopOnFix.$model = false
        vm.details.stopOnWarning.$model = false
        vm.details.stopOnDuplicate.$model = false
        vm.details.removeFixPayments.$model = false
        vm.details.removeWarningPayments.$model = false
        vm.details.removeDuplicatePayments.$model = false
        vm.details.removedFixesAction.$model = 0
        vm.details.removedWarningsAction.$model = 0
        vm.details.removedDuplicatesAction.$model = 0
      }, function (dismiss) {
        if (dismiss === 'cancel') {
          // do nothing.
        }
      })
    }
  }
}
</script>
