<template>
  <div id="intelligibleSummary">
    <div class="row">
      <div class="col-6">
        <table role="table" class="table table-striped pt-0 mt-0">
          <thead>
          <tr><th role="columnheader"><span class="sr-only">Unit</span></th><th role="columnheader">Items</th><th role="columnheader">Total</th></tr>
          </thead>
          <tbody>
          <tr><td role="rowheader"><strong>Credits</strong></td><td>{{ formatCount(selectedSummary.creditRecordCount) }}</td><td>£{{ formatValue(selectedSummary.creditValue) }}</td></tr>
          <tr><td role="rowheader"><strong>Debits</strong></td><td>{{ formatCount(selectedSummary.debitRecordCount) }}</td><td>£{{ formatValue(selectedSummary.debitValue) }}</td></tr>
          <tr><td role="rowheader"><strong>Credit Contras</strong></td><td>{{ formatCount(selectedSummary.creditContraCount) }}</td><td>£{{ formatValue(selectedSummary.creditContraValue) }}</td></tr>
          <tr><td role="rowheader"><strong>Debit Contras</strong></td><td>{{ formatCount(selectedSummary.debitContraCount) }}</td><td>£{{ formatValue(selectedSummary.debitContraValue) }}</td></tr>
          <tr><td role="rowheader"><strong>DDIs</strong></td><td>{{ formatCount(selectedSummary.ddiRecordCount) }}</td><td><span class="sr-only">N/A</span></td></tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <table role="table" class="table table-striped pt-0 mt-0 tableNeedsPadding">
          <tbody>
            <tr><td role="rowheader"><strong>Submission Type</strong></td><td>{{submissionType}}</td></tr>
            <tr><td role="rowheader"><strong>Service User Number</strong></td><td>{{selectedSummary.serviceUserNumber}}</td></tr>
            <tr><td role="rowheader"><strong>Work Code</strong></td><td>{{selectedSummary.workCode}}</td></tr>
            <tr><td role="rowheader"><strong>Processing Date</strong></td><td>{{processingDate}}</td></tr>
            <tr  v-if="false"><td role="rowheader"><strong>Contra Narrative</strong></td><td>{{selectedContraNarrative}}</td></tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="actionId !== undefined" class="row form-group mt-2">
      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-outline-secondary"
          v-on:click="downloadReport"
          :disabled="isLoading"
        >
          <span class="far fa-arrow-alt-circle-down" style="padding-right: 5px;"></span>Download Submission Report
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [
    bacsMixin,
    loading
  ],
  data () {
    return {
      submissionId: '',
      actionId: '',
      fileNumber: 1,
      intelligibleSummaries: {},
      contraNarratives: {},
      selectedSummary: {},
      selectedContraNarrative: ''
    }
  },

  computed: {
    submissionType: function () {
      var subType = ''
      if (this.selectedSummary.workCode !== undefined && this.selectedSummary.workCode.indexOf('FPS') > 0) {
        subType = 'Faster Payment'
      } else {
        subType = 'BACS'
      }
      return subType
    },

    processingDate: function () {
      var procDate = ''
      if (this.selectedSummary.processingDate !== undefined) {
        var date = this.selectedSummary.processingDate
        procDate = date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4)
      }
      return procDate
    }
  },

  methods: {
    async downloadReport () {
      try {
        var response = await axios({
          url: `${process.env.VUE_APP_BACS_API_URL}bacs/report/createBacsSummaryReport`,
          method: 'GET',
          responseType: 'blob', // important
          showload: true,
          params: {
            submissionId: this.submissionId,
            actionId: this.actionId,
            fileNumber: this.fileNumber
          }
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  },

  mounted () {
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    this.fileNumber = this.$store.getters.fileNumber
    this.intelligibleSummaries = this.$store.getters.intelligibleSummaries
    this.selectedSummary = this.intelligibleSummaries[this.fileNumber - 1]
    this.contraNarratives = this.$store.getters.contraNarratives
    // DDI only submissions won't have any contra records.
    if (this.contraNarratives !== undefined && this.contraNarratives.length > 0) {
      const idx = this.fileNumber > 0 ? this.fileNumber - 1 : 0
      this.selectedContraNarrative = this.contraNarratives[idx]
    }
  }
}
</script>
<style scoped>
  .tableNeedsPadding {
    position: relative;
    top: 3em;
  }
</style>
