<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Pending Bureau Submissions
          <help-icon :docPath="'/bacsbureau/submissions/'"></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          @on-row-click="onRowClick"
          @on-cell-click="onCellClick"
          styleClass="vgt-table striped bordered"
          :searchOptions="{
                          enabled: true
                        }"
          :sort-options="sortOptions"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-sort-change="onSortChange"
          ref="table"
          :isLoading.sync="isTableLoading"
        >
          <!-- <template slot="loadingContent">
            <h1>Loading...</h1>
          </template>-->

          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'delete' && !props.row.preventDelete">
              <a class="btn btn-sm primary" @on-cell-click="onCellClick" :disabled="isLoading">
                <i class="fa fa-trash"></i>
              </a>
            </span>
            <span v-else>
              <b-row>
                <b-col sm="12" class="mt-1">{{ props.formattedRow[props.column.field] }}</b-col>
              </b-row>
            </span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span><span class="sr-only">Clear filters</span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export the contents of the table'"
            >
              <i class="fa fa-file-export pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import Utility from '@/Assets/Mixins/Utility'
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [Utility, tableFilterMixin, loading],
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Bureau Submission Id',
          field: 'bureauSubmissionId',
          hidden: true
        },
        {
          label: 'Prevent Delete',
          field: 'preventDelete',
          hidden: true
        },
        {
          label: 'Submission Reference',
          field: 'submissionReference'
        },
        {
          label: 'Job',
          field: 'bureauJobName'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Files',
          field: 'numberOfFiles'
        },
        {
          label: 'Processing Date',
          field: 'processingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Payment Date',
          field: 'paymentDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Workflow',
          field: 'isWorkflow',
          hidden: false
        },
        {
          field: 'delete'
        }
      ],
      serverParams: {
        sort: [{ field: 'status', type: 'desc' }]
      }
      // loading: false
    }
  },

  components: {
  },
  async created () {
    await this.GetBureauLicenseCheck()
    this.load()
  },
  methods: {
    onRowClick (data) {
      var readonly = data.row.preventDelete
      if (readonly) {
        this.$router.push({ name: 'PaymentDetails', params: { id: data.row.bureauSubmissionId } })
      } else {
        this.$router.push({ name: 'UploadPaymentFiles', params: { id: data.row.bureauSubmissionId } })
      }
    },
    onRowClick_OLD (data) {
      const submission = {
        bureauSubmissionId: data.row.bureauSubmissionId,
        submissionReference: data.row.submissionReference,
        bureauJobId: data.row.bureauJobId,
        bureauJobName: data.row.bureauJobName,
        contraNarrative: data.row.contraNarrative,
        paymentDate: data.row.paymentDate,
        processingDate: data.row.processingDate,
        status: data.row.status,
        groupId: data.row.groupId,
        paygateId: this.$store.state.common.paygateId
      }
      this.$store.dispatch('setSubmission', submission)

      const readonly = data.row.preventDelete
      if (readonly) {
        this.$router.push({ name: 'PaymentDetails' })
      } else {
        this.$router.push({ name: 'UploadPaymentFiles' })
      }

      event.preventDefault()
    },
    async onCellClick (params) {
      if (params.column.field === 'delete') {
        const submissionId = params.row.bureauSubmissionId
        const submissionReference = params.row.submissionReference

        event.stopPropagation()

        const swalResult = await swal.fire({
          title: 'Cancel Submission',
          text: `Are you sure you want to cancel the submission: ${submissionReference}`,
          icon: 'warning',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })

        if (swalResult.isConfirmed) {
          try {
            this.loading = true
            const response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteSubmission/' + submissionId, {})
            console.info('Delete submission response:')
            console.info(response)
            if (response.data.status === 'Deleted') {
              this.$toastr.s(`Bureau Submission ${submissionReference} deleted`, 'Bureau Submission')
              this.load()
            } else {
              console.error('Failed to delete bureau job')
              console.error(response)
            }
          } catch (error) {
            console.error('Failed to call API')
            console.error(error)
          }
          this.loading = false
        }
      }
    },

    load: _.debounce(async function () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/getPendingSubmissionsQuery`, { params: { ...this.buildGoodTableQuery() }, showerror: true, errormessage: 'Failed loading pending submissions' })

        this.rows = response.data.data
      } catch { } finally {
        this.isTableLoading = false
      }
    }, 500
    ),

    async loadOLD () {
      try {
        this.loading = true
        const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getPendingSubmissions', { params: { ...this.buildGoodTableQuery() } })
        const data = response.data
        _.forEach(data, function (row) {
          row.preventDelete = _.includes(row.status, 'Signing') ||
            _.includes(row.status, 'AwaitingApproval') ||
            _.includes(row.status, 'Approved') ||
            _.includes(row.status, 'Submitting') ||
            _.includes(row.status, 'Submitted')
        })
        this.rows = response.data
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  }
}

</script>
