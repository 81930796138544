var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v(" Manage Job - " + _vm._s(_vm.bureauJob.name) + " "),
              _c("help-icon", { attrs: { docPath: "/bacsbureau/jobs/" } })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _vm.currentSubmissionCount > 0
              ? _c(
                  "b-card",
                  {
                    staticStyle: {
                      "margin-bottom": "0.8em",
                      "margin-right": "0.8em"
                    },
                    attrs: { "bg-variant": "warning", "text-variant": "white" }
                  },
                  [
                    _vm._v(
                      "This bureau job is currently being used in a submission in progress. No modifications can be made while it is in the processing stages."
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { sm: "2" } }, [
                  _c(
                    "label",
                    { staticClass: "mt-2", attrs: { for: "filter" } },
                    [_vm._v("Group")]
                  )
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("group-select", {
                      attrs: {
                        id: "filter",
                        groups: _vm.groups,
                        clearable: false,
                        disabled:
                          _vm.isLoading ||
                          _vm.currentSubmissionCount > 0 ||
                          !_vm.canManage
                      },
                      on: {
                        input: function($event) {
                          return _vm.onSave()
                        }
                      },
                      model: {
                        value: _vm.bureauJob.groupId,
                        callback: function($$v) {
                          _vm.$set(_vm.bureauJob, "groupId", $$v)
                        },
                        expression: "bureauJob.groupId"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c("b-col", { attrs: { sm: "2" } }, [
                  _c("label", { attrs: { for: "DeleteSourceFiles" } }, [
                    _vm._v("Delete Source Files")
                  ])
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: {
                        name: "DeleteSourceFiles",
                        labelId: "DeleteSourceFiles",
                        color: "primary",
                        disabled:
                          _vm.isLoading ||
                          _vm.currentSubmissionCount > 0 ||
                          !_vm.canManage,
                        "aria-describedby": "deleteSourceFilesDescription"
                      },
                      on: {
                        change: function($event) {
                          return _vm.onSave()
                        }
                      },
                      model: {
                        value: _vm.bureauJob.deleteSourceFiles,
                        callback: function($$v) {
                          _vm.$set(_vm.bureauJob, "deleteSourceFiles", $$v)
                        },
                        expression: "bureauJob.deleteSourceFiles"
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "form-text small",
                        attrs: { id: "deleteSourceFilesDescription" }
                      },
                      [
                        _c("label", [
                          _vm._v(
                            "Specifies whether the source files will be deleted upon successfully being submitted to BACS."
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12" } },
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "table",
                        attrs: {
                          paginationOptions: _vm.paginationOptions,
                          rows: _vm.rows,
                          columns: _vm.columns,
                          lineNumbers: true,
                          styleClass: "vgt-table striped bordered",
                          searchOptions: {
                            enabled: true
                          },
                          "sort-options": _vm.sortOptions,
                          mode: "remote",
                          totalRows: _vm.totalRecords,
                          isLoading: _vm.isTableLoading
                        },
                        on: {
                          "on-row-click": function() {},
                          "on-cell-click": _vm.onCellClick,
                          "on-page-change": _vm.onPageChange,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-search": _vm.onSearch,
                          "on-sort-change": _vm.onSortChange,
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "actions"
                                  ? _c("span", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-warning",
                                          attrs: {
                                            id: "onDuplicateJobDetailClick",
                                            title: "Duplicate Job Detail",
                                            disabled:
                                              _vm.isLoading ||
                                              _vm.currentSubmissionCount > 0 ||
                                              !_vm.canManage
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onDuplicateJobDetailClick(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "fa fa-copy"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-danger ml-1",
                                          attrs: {
                                            id: "onDeleteJobDetailClick",
                                            title: "Delete Job Detail",
                                            disabled:
                                              _vm.isLoading ||
                                              _vm.currentSubmissionCount > 0 ||
                                              !_vm.canManage
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onDeleteJobDetailClick(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "fa fa-trash-alt"
                                          })
                                        ]
                                      )
                                    ])
                                  : props.column.field === "customerName"
                                  ? _c("span", [
                                      props.row.customerName === " ()"
                                        ? _c("span", [
                                            _c("i", [_vm._v("Not Set")])
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row.customerName) +
                                                " "
                                            )
                                          ])
                                    ])
                                  : props.column.field === "hasWildcard"
                                  ? _c("span", [
                                      props.row.hasWildcard
                                        ? _c("i", {
                                            staticClass: "fa fa-check"
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                    ])
                                  : props.column.field === "importSchema"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row.mapping === null ||
                                              props.row.mapping === ""
                                              ? props.formattedRow[
                                                  props.column.field
                                                ]
                                              : _vm.getMappingName(
                                                  props.row.mapping
                                                )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onCellClick(props.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Clear filters",
                                    expression: "'Clear filters'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                attrs: { "aria-label": "Clear Filters" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.clearTableFilters($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fa-stack",
                                    staticStyle: { "font-size": "10px" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-filter fa-stack-1x dimmedIcon"
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "fa fa-ban fa-stack-2x dimmedIcon"
                                    })
                                  ]
                                ),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Clear filters")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: {
                                  variant: "link",
                                  "aria-label": "Refresh the data in the table"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Export the contents of the table",
                                    expression:
                                      "'Export the contents of the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: {
                                  variant: "link",
                                  "aria-label":
                                    "Export the contents of the table"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.exportTable($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-file-export pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Export Table")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Add Job Detail",
                                    expression: "'Add Job Detail'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: {
                                  variant: "link",
                                  "aria-label": "Add Job Detail",
                                  disabled:
                                    _vm.isLoading ||
                                    _vm.currentSubmissionCount > 0 ||
                                    !_vm.canManage
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onAddNewJobDetailClick($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-plus pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Add New Job Detail")
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions-bottom" },
                            slot: "table-actions-bottom"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { padding: "8px 0 8px 8px" }
                              },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-outline-primary",
                                      attrs: {
                                        disabled:
                                          _vm.currentSubmissionCount > 0 ||
                                          !_vm.canManage
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.onAddNewJobDetailClick(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Add Job Detail")]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "idb-block-footer" },
          [
            _vm.canRunSub && _vm.canSubmit && _vm.currentSubmissionCount == 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success mr-2",
                    attrs: {
                      disabled: _vm.isLoading || _vm.currentSubmissionCount > 0
                    },
                    on: { click: _vm.runJob }
                  },
                  [
                    _c("i", { staticClass: "fa fa-play mr-2" }),
                    _vm._v("Create Submission ")
                  ]
                )
              : _vm._e(),
            _vm.canRunSub && _vm.canSubmit && _vm.currentSubmissionCount > 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success mr-2",
                    attrs: {
                      disabled: _vm.isLoading || _vm.currentSubmissionCount == 0
                    },
                    on: { click: _vm.GoToJob }
                  },
                  [
                    _c("i", { staticClass: "fa fa-play mr-2" }),
                    _vm._v("Go To Submission ")
                  ]
                )
              : _vm._e(),
            _c(
              "b-button",
              {
                staticClass: "float-right",
                attrs: {
                  variant: "danger",
                  disabled:
                    _vm.isLoading ||
                    _vm.currentSubmissionCount > 0 ||
                    !_vm.canManage
                },
                on: {
                  click: function($event) {
                    return _vm.onDeleteClick()
                  }
                }
              },
              [_vm._v("Delete")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: {
                  type: "button",
                  disabled:
                    _vm.isLoading ||
                    _vm.currentSubmissionCount > 0 ||
                    !_vm.canManage
                },
                on: { click: _vm.onRenameBureauJob }
              },
              [
                _c("i", { staticClass: "fas fa-edit rpad" }),
                _vm._v("Rename Job ")
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ml-3",
                attrs: {
                  type: "button",
                  disabled: _vm.isLoading || !_vm.canManage
                },
                on: { click: _vm.onShowCloneModal }
              },
              [
                _c("i", { staticClass: "glyphicon ti-layers rpad" }),
                _vm._v("Clone Bureau Job ")
              ]
            ),
            _vm.enableUpdateButton
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-danger ml-1",
                    attrs: {
                      disabled: _vm.isLoading || _vm.currentSubmissionCount > 0
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.onSave()
                      }
                    }
                  },
                  [_vm._v("Update Bureau Group")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            lazy: "",
            size: "lg",
            id: "customerselector",
            title: "Select Bureau Customer(s)",
            "hide-footer": ""
          }
        },
        [
          _c("bureau-customer-selector", {
            attrs: { customerIdsInJob: _vm.getCustomerIdsFromRows() },
            on: { hideCustomerSelector: _vm.hideCustomerSelector }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("addBureauJobDetailModal", {
            attrs: {
              bureauJobId: _vm.bureauJob.bureauJobId,
              bureauCustomers: _vm.bureauCustomers
            },
            on: { submit: _vm.addBureauJobDetailClosed }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("editBureauJobDetailModal", {
            attrs: {
              bureauJobId: _vm.bureauJob.bureauJobId,
              bureauJobDetailId: _vm.bureauJobDetailId,
              bureauCustomers: _vm.bureauCustomers,
              duplicateMode: _vm.duplicateMode
            },
            on: { submit: _vm.editBureauJobDetailClosed }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("cloneModal", {
            attrs: { bureauJobId: _vm.bureauJob.bureauJobId },
            on: { submit: _vm.cloneModalClosed }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("renameModal", {
            attrs: {
              "current-name": _vm.bureauJob.name,
              bureauJobId: _vm.bureauJob.bureauJobId
            },
            on: { jobRenamed: _vm.jobRenamed, submit: _vm.renameModalClosed }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }