<template>
  <div>
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the Account Holder Name'">Account Holder Name</div>
      <div class="col-md-4" :class="{invalid: $v.accountHoldersName.$error}">
        <b-form-input id="accountHolderNameInput" type="text" class="form-control" required @input="$v.accountHoldersName.$reset()" @blur.native="dirtyTouch($v.accountHoldersName)" v-model="accountHoldersName" :disabled="!editable"/>
      </div>
      <span  v-if="$v.accountHoldersName.$error">
        <label id="accountHolderRequiredLabel" class="text-danger small" v-if="!$v.accountHoldersName.required">
          Account Holder Name required!
        </label>
      </span>
    </div>
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party\'s sort code.'">Sort Code</div>
      <div class="col-md-3" :class="{invalid: $v.sortcode.$error}">
        <the-mask id="sortcodeInput" :mask="['##-##-##']" required :guide="true" ref="sortcode" type="text" class="form-control" @input="$v.sortcode.$reset()" @blur.native="dirtyTouch($v.sortcode)" :tabindex="27" v-model="sortcode" :state="validateApiResult && validateApiResult.valid" :disabled="!editable">
        </the-mask>
      </div>
      <div class="col-md-1" v-if="validateApiResult && !invalidSortCode">
        <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
      </div>
      <span v-if="$v.sortcode.$error">
        <label id="sortCodeLengthLabel" class="text-danger small" v-if="!$v.sortcode.minLength || !$v.sortcode.maxLength">
          The Sort Code needs to be 6 numbers long
        </label>
      </span>
      <span v-if="$v.sortcode.$error && $v.sortcode.$model !== null">
        <label id="sortCodeRequiredLabel" class="text-danger small" v-if="!$v.sortcode.required">
          Sort Code required!
        </label>
      </span>
      <span v-if="validateApiResult && validateApiResult.supportsDdi !== null && !validateApiResult.supportsDdi">
        <label class="text-danger small">
          Sort code does not support DDI. It cannot be used
        </label>
      </span>
    </div>
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party\'s account number.'">Account Number</div>
      <div class="col-md-3" :class="{invalid: $v.accountNumber.$error}">
        <the-mask id="accountHolderInput" :state="validateApiResult && validateApiResult.valid" :mask="['########']" @input="$v.accountNumber.$reset()" required :guide="true" ref="accountNumber" type="text" class="form-control" @blur.native="dirtyTouch($v.accountNumber)" :tabindex="28" v-model="accountNumber" :disabled="!editable">
      </the-mask>
      </div>
      <div class="col-md-1" v-if="validateApiResult && !invalidAccountNumber">
        <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
      </div>
      <span v-if="$v.accountNumber.$error">
        <label id="accNumberLengthLabel" class="text-danger small" v-if="!$v.accountNumber.minLength || !$v.accountNumber.maxLength">
          The account number needs to be 8 numbers long
        </label>
      </span>
      <span v-if="$v.accountNumber.$error && $v.accountNumber.$model !== null">
        <label id="accNumberRequiredLabel" class="text-danger small" v-if="!$v.accountNumber.required">
          Account Number required!
        </label>
      </span>
      <span v-if="validateApiResult && !validateApiResult.valid">
        <label class="text-danger small">
          {{validateApiResult.errorText}}
        </label>
      </span>
    </div>
  </div>
</template>
<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
export default {
  data () {
    return {
      dataModified: false
    }
  },
  props: {
    open: Boolean,
    clickedNext: Boolean,
    currentStep: Object,
    showIntro: { type: Boolean, default: true }
    // accountHolderName: String,
    // sortCode: String,
    // accountNumber: String,
    // isValid: Boolean
  },
  components: {
    TheMask
  },
  validations: {
    accountHoldersName: { required },
    sortcode: { required, minLength: minLength(6), maxLength: maxLength(6) },
    accountNumber: { required, minLength: minLength(8), maxLength: maxLength(8) }
  },
  computed: {
    editable () {
      return this.$store.state.payer.currentPayer.editable
    },
    validateApiResult () {
      return this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult
    },
    accountHoldersName: {
      get () {
        return this.$store.state.payer.currentPayer.bankAccountDetails.accountHoldersName
      },
      set (value) {
        this.$store.commit('updateAccountHoldersName', value)
      }
    },
    sortcode: {
      get () {
        return this.$store.state.payer.currentPayer.bankAccountDetails.sortcode
      },
      set (value) {
        this.$store.commit('updateSortcode', value)
      }
    },
    accountNumber: {
      get () {
        return this.$store.state.payer.currentPayer.bankAccountDetails.accountNumber
      },
      set (value) {
        this.$store.commit('updateAccountNumber', value)
      }
    },
    validationMessage () {
      return this.$store.getters.getbankingValidationMessage
    },
    invalidAccountNumber () {
      return this.validateApiResult && !this.validateApiResult.valid && this.validateApiResult.errorCode === '1502'      
    },
    invalidSortCode () {
      return this.validateApiResult && !this.validateApiResult.valid && this.validateApiResult.errorCode !== '1502'
    }
  },
  methods: {
    formDirty: function () {
      return this.dataModified
    },
    dirtyTouch (callingModel) {
      callingModel.$touch()
      let isValid = !this.$v.$invalid
      this.$store.dispatch('setCurrentPayerBankingDetails', { isValid, accountNumber: this.accountNumber, sortCode: this.sortcode })
    },
    validate () {
      this.$emit('can-continue', { value: true })
    },
    buildValidationMessage () {
      var fields = []
      if (this.$v.accountHoldersName.$invalid) {
        fields.push('Account Holders Name')
      }
      if (this.$v.sortcode.$invalid) {
        fields.push('Sort Code')
      }
      if (this.$v.accountNumber.$invalid) {
        fields.push('Account Number')
      }
      var message = fields.length > 0 ? `Invalid Banking Details: ${fields.join(', ')}` : ''
      this.$store.commit('setBankingValidationMessage', message)
    }
  },
  watch: {
    $v: {
      handler (val) {
        this.$emit('can-continue', { value: true })
        this.$store.commit('setBankCompleted', !this.$v.$invalid)
        this.buildValidationMessage()
      },
      deep: true
    },
    clickedNext (val) {
      if (val === true) {
        this.$emit('can-continue', { value: true })
      }
    },
    currentStep (val) {
      this.$emit('can-continue', { value: true })
    }
  },
  async mounted () {
    this.$emit('can-continue', { value: true })
    console.log('building validation message')
    this.buildValidationMessage()
  }
}
</script>
