<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div v-if="actionSubClass === 'Info' || actionSubClass === 'GroupAdmin'">
            <infoAction></infoAction>
          </div>
          <div v-else-if="actionSubClass !== 'Edit'">
            <viewSubmission></viewSubmission>
            <div v-show="actionLoaded" class="idb-block-footer">
              <signSubmission
                v-if="(actionSubClass === 'Sign' || actionSubClass === 'Commit') && !unintegratedSubmission"
              ></signSubmission>
              <approveSubmission
                v-else-if="actionSubClass === 'Approve' && !unintegratedSubmission"
              ></approveSubmission>
              <sendSubmission v-if="actionSubClass === 'Send' && !unintegratedSubmission"></sendSubmission>
              <unintegratedSubmssion v-if="unintegratedSubmission"></unintegratedSubmssion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ViewSubmission from '@/Views/Bacs/Common/ViewSubmission.vue'
import SignSubmission from '@/Views/Bacs/BacsActions/SignSubmission.vue'
import ApproveSubmission from '@/Views/Bacs/BacsActions/ApproveSubmission.vue'
import SendSubmission from '@/Views/Bacs/BacsActions/SendSubmission.vue'
import InfoAction from '@/Views/Bacs/BacsActions/InfoAction.vue'
import UnintegratedSubmission from '@/Components/Collections/Actions/UnintegratedSubmission.vue'
export default {
  components: {
    viewSubmission: ViewSubmission,
    signSubmission: SignSubmission,
    approveSubmission: ApproveSubmission,
    sendSubmission: SendSubmission,
    infoAction: InfoAction,
    unintegratedSubmssion: UnintegratedSubmission
  },

  props: {
    actionId: {},
    actionedObjectId: {}
  },

  data () {
    return {
      submissionInProgress: {},
      canContinue: false,
      userId: '',
      action: {},
      unlockSubmissionOnLeave: true
    }
  },

  methods: {
    async getSubmissionInProgress () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSelectedSubmissionInProgress?submissionId=` + this.actionedObjectId, { showload: true })
      if (response.data !== null && (!response.data.selected || response.data.userId === this.userId)) {
        this.submissionInProgress = response.data
        this.canContinue = true
      }
    },

    async getAction () {
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/' + this.actionId
      var response = await axios.get(url, { action: true })
      this.action = response.data
    },

    async setupSubmissonData () {
      var submissionData = {}
      submissionData.submissionId = this.submissionInProgress.submissionId
      submissionData.reference = this.submissionInProgress.reference
      submissionData.processingDate = this.submissionInProgress.processingDate
      submissionData.paymentDate = this.submissionInProgress.paymentDate
      submissionData.paymentNetworkType = this.submissionInProgress.paymentNetworkType
      submissionData.contraNarrative = this.submissionInProgress.contraNarrative
      submissionData.paygateType = this.submissionInProgress.paygateType
      submissionData.groupId = this.submissionInProgress.groupId
      submissionData.serviceUserNumber = this.submissionInProgress.serviceUserNumber
      submissionData.filename = this.submissionInProgress.filename
      submissionData.createPage = 'infoAction'
      submissionData.submissionType = submissionData.paymentNetworkType === 0 ? 'BACS' : 'FPS'

      var importFileResponse = {}
      importFileResponse.totalNumberOfCredits = this.submissionInProgress.numberOfCredits
      importFileResponse.totalNumberOfDebits = this.submissionInProgress.numberOfDebits
      importFileResponse.totalNumberOfDdis = this.submissionInProgress.numberOfDdis
      importFileResponse.totalCreditsValue = this.submissionInProgress.creditsValue
      importFileResponse.totalDebitsValue = this.submissionInProgress.debitsValue

      if (this.action.data.details !== undefined) {
        importFileResponse.responseMessages = this.action.data.details
      }

      await this.$store.dispatch('getSubBacsGroup', submissionData.groupId)
      var bacsGroup = this.$store.getters.bacsGroup
      submissionData.groupName = bacsGroup.name

      var payload = { paygateId: '', groupId: submissionData.groupId, platformUrl: '' }
      await this.$store.dispatch('getGroupBankAccount', payload)

      this.$store.dispatch('setSubmissionData', submissionData)
      this.$store.dispatch('setImportFileResponse', importFileResponse)
    },

    async unlockSubmission () {
      var unlockResponse = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/RemoveUserSubmissionLock?submissionId=' + this.actionedObjectId)
      if (unlockResponse.data.status === 'Failed') {
        this.$snapbar.e(unlockResponse.data.errorText)
      }
    }
  },

  async created () {
    this.userId = this.$store.getters.getClaim('sub').value
    this.$store.dispatch('setParentDataLoaded', false)
    this.$store.dispatch('setActionId', this.actionId)
    this.$store.dispatch('setSubmissionId', this.actionedObjectId)
    await this.getAction()

    if (this.actionSubClass === 'Edit') {
      await this.getSubmissionInProgress()
      await this.setupSubmissonData()

      var json = JSON.stringify({
        submissionId: this.actionedObjectId,
        actionId: this.actionId,
        nextStatus: 'None'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      console.log('canProceed: ' + JSON.stringify(response.data))
      if (response.data.success) {
        this.unlockSubmissionOnLeave = false
        this.$router.push('/payments/bacspayments/viewImportedPayments?showPresubValMessages=' + this.submissionInProgress.showPresubValMessages + '&editAction=true')
      } else {
        this.$snapbar.w(response.data.errorMessage)
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('setActionLoadedFromPath', from.path)
    })
    next()
  },

  async beforeRouteLeave (to, from, next) {
    // var bacsGroup = this.$store.getters.bacsGroup
    // if (this.actionSubClass === 'Approve' && bacsGroup.groupTypeDetails.paymentApprovalMethod !== 'Default' && bacsGroup.groupTypeDetails.approvalMethod !== 'Via Action Item') {
    //   await this.unlockSubmission()
    // }
    if (this.unlockSubmissionOnLeave) {
      await this.unlockSubmission()
    }

    if (this.actionSubClass === 'Edit' || (!to.path.includes('viewImportedPayments') && !to.path.includes('preSubValidationResult'))) {
      next()
    } else {
      this.$router.push('/')
    }
  },

  computed: {
    unintegratedSubmission () {
      if (this.submissionInProgress.groupId !== undefined) {
        this.$store.dispatch('getSubBacsGroup', this.submissionInProgress.groupId)
        if (this.$store.getters.bacsGroup && this.$store.getters.bacsGroup.groupTypeDetails) {
          return this.$store.getters.bacsGroup.groupTypeDetails.groupWorkflowType === 1
        }
      }
      return false
    },
    actionLoaded () { return this.$store.getters.parentDataLoaded },
    actionSubClass () {
      return this.action.actionSubClass
    }
  }
}
</script>
