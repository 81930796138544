<template>
  <form>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/plans/plandesigner/" />Plan Designer<favourite-icon></favourite-icon></h2>
      </div>
      <div>
        <div class="m-3">
          <div class="row form-group">
            <div class="col-md-2 required">
              Select a Group
            </div>
            <div class="col-md-4">
              <group-select
                v-model="$v.group.$model"
                :groups="customerGroups"
                @input="customerGroupsChanged"
                :clearable="false"
              ></group-select>
              <validation-messages name="Group" v-model="$v.group" />
              </div>
          </div>
          <b-row  class="planRow">
            <div class="tabs row">
              <div class="col-auto col-3 vertical-tab-container">
                <ul role="tablist" class="nav nav-tabs flex-column w-90 ml-2 vertical-tab-list">
                  <li role="presentation" class="nav-item" v-if="groupPlans === undefined || groupPlans === null || groupPlans.length === 0">
                    There are no plan templates associated with this group.
                  </li>
                  <li role="presentation" class="nav-item" v-for="(paymentPlan, index) in groupPlans" :key="index">
                    <a role="tab" class="nav-link" :class="activeTabIndex === index ? 'active' : null" @click="updatePlanInput(index)">{{paymentPlan.name}}</a>
                  </li>
                  <li>
                    <button type="button" class="btn mt-4 btn-primary" @click="addNewPaymentPlan" :disabled="isLoading"><i class="fa fa-plus rpad"></i>Add new plan template</button>
                  </li>
                </ul>
              </div>
              <div class="tab-content col">
                <payment-plan-input v-if="activePlanValid"
                  :plan="activePlan.plan"
                  :templateView="true"
                  ref="planInput" :insightFeedback="true"
                  @isinvalid="setValidity"
                  :sharedTemplate="group === '00000000-0000-0000-0000-000000000000'"
                ></payment-plan-input>
                <b-btn v-if="activePlanValid" class="float-right ml-1" variant="danger" :disabled="isLoading" @click.stop="deletePlan">
                  <i class="fa fa-trash" aria-hidden="true"></i> Delete
                </b-btn>
                <b-btn v-if="activePlanValid" variant="primary" @click.stop="savePlan" :disabled="activePlanValid && !activePlan.plan.valid || isLoading">
                  <i class="fa fa-save" aria-hidden="true"></i> Save
                </b-btn>
              </div>
            </div>
          </b-row>
        </div>
      </div>
      <div class="idb-block-footer">
        <!-- <b-button type="submit" variant="primary"><i class="fa fa-save mr-2" aria-hidden="true"></i>Save</b-button> -->
      </div>
    </div>
  </form>
</template>
<script>
import axios from 'axios'
import Components from '@/Components/Collections'
import { required, String } from 'vuelidate/lib/validators'
import EventBus from '@/Lib/eventBus'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
export default {
  mixins: [loading],
  components: {
    paymentPlanInput: Components.Payer.PaymentPlan
  },
  data () {
    return {
      newPlanReference: null,
      group: null,
      selectedPaymentPlan: {
        reference: '',
        recurrenceEndType: 0,
        recurrenceType: 0,
        ordinal: 1,
        firstAmount: 0,
        regularAmount: 0,
        totalPayments: 0,
        firstPaymentDate: new Date(),
        giftAid: false,
        variable: false,
        dayOfYear: 1,
        allowDateChoice: false,
        dateRangeAllowed: 2,
        step: 1,
        valid: false,
        contraNarrative: '',
        indirectSun: '',
        customerBankAccountId: '00000000-0000-0000-0000-000000000000'
      },
      paymentPlans: [],
      planName: null,
      activeTabIndex: 0
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'Shared Templates', 'paygateId': this.paygateid, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    },
    defaultBankAccount () {
      var account = '00000000-0000-0000-0000-000000000000'
      if (this.$store.getters.groupBankAccounts.length > 0) {
        if (this.$store.getters.groupBankAccounts.filter(x => x.item2).length > 0) {
          account = this.$store.getters.groupBankAccounts.filter(x => x.item2)[0].item1
        } else {
          account = this.$store.getters.groupBankAccounts[0].item1
        }
      }
      return account
    },
    planTemplateTree () {
      return this.$store.getters.planTemplateTree
    },
    groupPlans () {
      var retVal = []
      if (this.$store.getters.planTemplateTree[this.group] !== null || this.$store.getters.planTemplateTree[this.group] !== undefined) {
        retVal = this.$store.getters.planTemplateTree[this.group]
        if (retVal !== null && retVal !== undefined && retVal.length > 0) {
          for (var i = 0; i < retVal.length; i++) {
            retVal[i].plan.payerReferencePattern = retVal[i].payerReferencePattern
            retVal[i].plan.planTemplateName = retVal[i].name
            retVal[i].plan.allowDateChoice = retVal[i].allowDateChoice
            retVal[i].plan.dateRangeAllowed = retVal[i].dateRangeAllowed
            retVal[i].plan.groupId = this.group
            retVal[i].plan.valid = false
            if (retVal[i].plan.customerBankAccountId === undefined || retVal[i].plan.customerBankAccountId === null || retVal[i].plan.customerBankAccountId === '00000000-0000-0000-0000-000000000000') {
              retVal[i].plan.customerBankAccountId = this.defaultBankAccount
            }
          }
        }
      }
      return retVal
    },
    activePlan () {
      var retVal = null
      if (this.groupPlans !== undefined && this.groupPlans !== null && this.groupPlans.length > 0) {
        retVal = this.groupPlans[this.activeTabIndex]
      }
      return retVal
    },
    activePlanValid () {
      return this.activePlan !== null && this.activePlan !== undefined && this.activePlan.plan !== null && this.activePlan.plan !== undefined
    }
  },
  validations: {
    planName: { required },
    newPlanReference: { required, String },
    group: { required }
  },
  async created (e) {
    this.$store.dispatch('getNonProcessingDates')
    this.$store.dispatch('populateAllCollectionsGroups')
  },
  async mounted (e) {
    this.loadGroups(this.paygateId)
    const onPaygateChange = async (paygateId) => {
      await this.$store.dispatch('buildPlanTemplateTree')
      await this.$store.dispatch('getAvailableBankAccounts')
      this.loadGroups(paygateId)
    }
    EventBus.$on('paygateIdChange', onPaygateChange)
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })
    this.group = '00000000-0000-0000-0000-000000000000'
    await this.$store.dispatch('getAvailableBankAccounts')
    await this.$store.dispatch('buildPlanTemplateTree')
  },
  methods: {
    async getPlans () {
      var groupId = this.group === null ? '00000000-0000-0000-0000-000000000000' : this.group
      await this.$store.dispatch('getGroupConfigurationFromGroupId', this.group)
      await this.$store.dispatch('updateAvailablePlanTemplates', this.group)
      await this.$store.dispatch('getGroupBankAccounts', this.group)
      var resp = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/group/${groupId}`, { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true })
      for (var i = 0; i < resp.data.length; i++) {
        resp.data[i].plan.payerReferencePattern = resp.data[i].payerReferencePattern
        resp.data[i].plan.planTemplateName = resp.data[i].name
        resp.data[i].plan.allowDateChoice = resp.data[i].allowDateChoice
        resp.data[i].plan.dateRangeAllowed = resp.data[i].dateRangeAllowed
        resp.data[i].plan.valid = false
        if (resp.data[i].plan.customerBankAccountId === undefined || resp.data[i].plan.customerBankAccountId === null || resp.data[i].plan.customerBankAccountId === '00000000-0000-0000-0000-000000000000') {
          resp.data[i].plan.customerBankAccountId = this.defaultBankAccount
        }
      }
      this.paymentPlans = resp.data
    },
    loadGroups (paygateId) {
      this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    async addNewPaymentPlan (e) {
      let newPlan = {
        reference: 'New Plan',
        recurrenceEndType: 3,
        recurrenceType: 1,
        ordinal: 1,
        firstAmount: 0,
        regularAmount: 0,
        totalPayments: 0,
        firstPaymentDate: new Date(),
        giftAid: false,
        variable: false,
        planTemplateId: '00000000-0000-0000-0000-000000000000',
        payerReferencePattern: null,
        dateRangeAllowed: 2,
        allowDateChoice: false,
        step: 1,
        valid: false,
        contraNarrative: '',
        indirectSun: '',
        bankAccountId: '00000000-0000-0000-0000-000000000000'
      }
      var newPlanTemplate = { name: 'New Plan', templateJson: JSON.stringify(newPlan), plan: newPlan, groupId: this.group }
      this.selectedPaymentPlan = newPlanTemplate
      var resp = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/`, newPlanTemplate, { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true })
      await this.$store.dispatch('buildPlanTemplateTree')
      this.selectedPaymentPlan = resp.data
      for (var i = 0; i < this.groupPlans.length; i++) {
        if (this.groupPlans[i].paymentPlanTemplateId === resp.data.plan.planTemplateId) {
          this.activeTabIndex = i
        }
      }
    },
    setPlan () {
      for (var p = 0; p < this.$refs.planInput.length; p++) {
        this.$refs.planInput[p].reset()
      }
    },
    async deletePlan () {
      var e = this.activeTabIndex
      var result = await swal.fire({
        title: 'Are you sure?',
        text: 'Deleting a payment plan name will prevent the addition of plans with that name, but existing plans will remain in the system.',
        icon: 'warning',
        showCancelButton: true,
        dangerMode: true
      })
      if (result.isConfirmed) {
        var resp = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/` + this.groupPlans[e].paymentPlanTemplateId, { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true })
        if (resp.data) {
          this.groupPlans.splice(e, 1)
          this.activeTabIndex = -1
          this.$toastr.s('Plan template deleted')
        } else {
          this.$toastr.e('Plan template deletion failed')
        }
      }
      await this.$store.dispatch('buildPlanTemplateTree')
    },
    async savePlan () {
      var e = this.activeTabIndex
      // this.paymentPlans[e] = this.selectedPaymentPlan
      this.groupPlans[e].templateJson = JSON.stringify(this.groupPlans[e].plan)
      this.groupPlans[e].name = this.groupPlans[e].plan.planTemplateName
      this.groupPlans[e].payerReferencePattern = this.groupPlans[e].plan.payerReferencePattern
      this.groupPlans[e].allowDateChoice = this.groupPlans[e].plan.allowDateChoice
      this.groupPlans[e].dateRangeAllowed = this.groupPlans[e].plan.dateRangeAllowed
      try {
        var resp = await axios.put(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/`, this.groupPlans[e], { params: { 'paygateid': this.$store.state.common.paygateId }, showload: true })
        this.groupPlans[e] = resp.data
        this.groupPlans[e].plan.payerReferencePattern = resp.data.payerReferencePattern
        this.$toastr.s('Plan template updated')
        await this.$store.dispatch('getGroupBankAccounts', this.group)
        await this.$store.dispatch('buildPlanTemplateTree')
        for (var i = 0; i < this.groupPlans.length; i++) {
          if (this.groupPlans[i].paymentPlanTemplateId === resp.data.plan.planTemplateId) {
            this.activeTabIndex = i
          }
        }
      } catch (e) {
        console.log(e)
        this.$toastr.e('Plan template update failed')
      }
    },
    async customerGroupsChanged (e) {
      this.selectedPaymentPlan = null
      this.activeTabIndex = 0
      // await this.getPlans()
    },
    async generateApiKey () {
      let url = process.env.VUE_APP_DDMS_API_URL + 'plantemplate/apikey/' + this.planTemplateId
      var result = await axios.post(url, {}, { showload: true })
      this.apiKey = result.data
    },
    setValidity (e) {
      for (var i = 0; i < this.groupPlans.length; i++) {
        if (this.groupPlans[i].paymentPlanTemplateId === e.plan.planTemplateId) {
          this.groupPlans[i].plan.valid = e.valid
          this.$forceUpdate()
        }
      }
    },
    updatePlanInput (index) {
      this.activeTabIndex = index
      this.$nextTick(() => {
        this.$refs.planInput.initialize()
      })
    }
  },
  watch: {
    activeTabIndex () {
      if (this.$refs.planInput !== undefined && this.$refs.planInput !== null && this.$refs.planInput.length > 0) {
        this.$refs.planInput.reset()
      }
    }
  }
}
</script>
<style scoped>
.vertical-tab-list {
  border-bottom: solid 0px black !important;
  border-right: 0px solid #ddd;
}

.vertical-tab-container {
  border-bottom: solid 0px black !important;
  border-right: 1px solid #ddd;
  padding-right: 0px;
}

.vertical-tab-list .nav-item .active, .vertical-tab-list .nav-item .nav-link:hover {
  border: 1px solid #ddd;
  border-right: solid 1px transparent;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  margin: 0
}

.planRow .tabs {
  width: 100%
}
</style>
