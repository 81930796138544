var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-form", [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _c("help-icon", {
                  attrs: { docPath: "/paygate-collections/payers/editpayer/" }
                }),
                _vm._v("Edit Payer ")
              ],
              1
            )
          ]),
          _c(
            "div",
            [
              _vm.status === 5 && _vm.emailVerified
                ? _c(
                    "b-card",
                    {
                      staticStyle: {
                        "margin-bottom": "0.8em",
                        "margin-right": "0.8em"
                      },
                      attrs: {
                        "bg-variant": "warning",
                        "text-variant": "white"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-triangle rpad"
                      }),
                      _vm._v(" Activation Required "),
                      _c(
                        "b-btn",
                        {
                          attrs: { variant: "outline-light" },
                          on: { click: _vm.activateOnboarded }
                        },
                        [_vm._v("Activate")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.status === 5 && !_vm.emailVerified
                ? _c(
                    "b-card",
                    {
                      staticStyle: {
                        "margin-bottom": "0.8em",
                        "margin-right": "0.8em"
                      },
                      attrs: {
                        "bg-variant": "warning",
                        "text-variant": "white"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-exclamation-triangle rpad"
                      }),
                      _vm._v(
                        " Email Verification Required - Cannot activate payer without Email Verification. "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "b-tabs",
                { attrs: { "nav-wrapper-class": "no-print-tabs" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { active: !_vm.openAt, title: "Main" } },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-sm-6",
                              attrs: {
                                "label-cols": 5,
                                "label-class": "required",
                                horizontal: "",
                                label: "Group"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  value: _vm.groupName,
                                  type: "text",
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-sm-6",
                              attrs: {
                                "label-cols": 5,
                                "label-class": "required",
                                horizontal: "",
                                label: "Payer Reference"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  required: "",
                                  disabled: true
                                },
                                model: {
                                  value: _vm.$v.payerReference.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.payerReference,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.payerReference.$model"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-sm-6",
                              attrs: {
                                "label-cols": 5,
                                "label-class": "required",
                                horizontal: "",
                                label: "Payer Status"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options:
                                    _vm.status === 5
                                      ? _vm.statae
                                      : _vm.statae.slice(0, 5),
                                  "text-field": "name",
                                  "value-field": "value",
                                  disabled:
                                    !_vm.editable ||
                                    (_vm.status == 5 && !_vm.emailVerified)
                                },
                                model: {
                                  value: _vm.status,
                                  callback: function($$v) {
                                    _vm.status = _vm._n($$v)
                                  },
                                  expression: "status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-sm-6",
                              attrs: {
                                "label-cols": 5,
                                "label-class": "required",
                                horizontal: "",
                                label: "Message Channel"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.messageChannels,
                                  "text-field": "name",
                                  "value-field": "value",
                                  disabled: !_vm.editable
                                },
                                on: {
                                  change: function($event) {
                                    _vm.showPreview ? _vm.getPreview() : null
                                  }
                                },
                                model: {
                                  value: _vm.messageChannel,
                                  callback: function($$v) {
                                    _vm.messageChannel = $$v
                                  },
                                  expression: "messageChannel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.hasPayerPortal
                        ? _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-sm-6",
                                  attrs: {
                                    "label-cols": 5,
                                    "label-class": "required",
                                    horizontal: "",
                                    label:
                                      _vm.payerPortalId === undefined ||
                                      _vm.payerPortalId === null
                                        ? "Create Portal Account"
                                        : "Resend Password"
                                  }
                                },
                                [
                                  _vm.payerPortalId === undefined &&
                                  _vm.payerPortalId === null
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isLoading,
                                            variant: "secondary"
                                          },
                                          on: { click: _vm.createPortalAccount }
                                        },
                                        [_vm._v("Create")]
                                      )
                                    : _vm._e(),
                                  _vm.payerPortalId !== undefined &&
                                  _vm.payerPortalId !== null
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isLoading,
                                            variant: "secondary"
                                          },
                                          on: { click: _vm.resendPortalCode }
                                        },
                                        [_vm._v("Resend Code")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { active: _vm.openAt === "payerDetails" } },
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" Payer Details "),
                        _vm.payerStagingDetails !== undefined &&
                        !_vm.payerStagingDetails.payerDetailsValidated
                          ? _c("i", {
                              staticClass:
                                "missing-fields fa fa-exclamation-triangle",
                              attrs: {
                                "aria-hidden": "true",
                                title: _vm.detailsValidationMessage
                              }
                            })
                          : _vm._e()
                      ]),
                      _c("payer-details-input", {
                        attrs: { ukPayerId: _vm.ukPayerId }
                      })
                    ],
                    2
                  ),
                  _c(
                    "b-tab",
                    { attrs: { active: _vm.openAt === "paymentPlans" } },
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" Payment Plans "),
                        _vm.payerStagingDetails !== undefined &&
                        !_vm.payerStagingDetails.paymentPlansValidated
                          ? _c("i", {
                              staticClass:
                                "missing-fields fa fa-exclamation-triangle",
                              attrs: {
                                "aria-hidden": "true",
                                title: _vm.plansValidationMessage
                              }
                            })
                          : _vm._e()
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "planRow" },
                        [
                          _c(
                            "b-tabs",
                            {
                              attrs: {
                                vertical: "",
                                "nav-wrapper-class":
                                  "col-3 vertical-tab-container",
                                "nav-class": "w-100 vertical-tab-list"
                              },
                              scopedSlots: _vm._u(
                                [
                                  !_vm.planLimitHit
                                    ? {
                                        key: "tabs-end",
                                        fn: function() {
                                          return [
                                            _c(
                                              "b-nav-item",
                                              { attrs: { href: "#" } },
                                              [
                                                _c(
                                                  "b-form-select",
                                                  {
                                                    staticClass:
                                                      "form-control w-100",
                                                    attrs: {
                                                      type: "text",
                                                      placeholder:
                                                        "New plan name...",
                                                      options: _vm.planNames,
                                                      "text-field": "name",
                                                      "value-field": "name"
                                                    },
                                                    on: {
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.addNewPaymentPlan(
                                                          $event
                                                        )
                                                      },
                                                      change:
                                                        _vm.addNewPaymentPlan
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.newPlanReference
                                                          .$model,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.$v
                                                            .newPlanReference,
                                                          "$model",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.newPlanReference.$model"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "first" },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              disabled: ""
                                                            },
                                                            domProps: {
                                                              value: null
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Please select a plan"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    : null
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._l(_vm.paymentPlans, function(
                                paymentPlan,
                                index
                              ) {
                                return _c(
                                  "b-tab",
                                  {
                                    key: index,
                                    attrs: {
                                      title: paymentPlan.planTemplateName
                                    }
                                  },
                                  [
                                    paymentPlan !== null &&
                                    paymentPlan !== undefined
                                      ? _c("payment-plan-input", {
                                          attrs: {
                                            plan: paymentPlan,
                                            ukPayerId: _vm.ukPayerId,
                                            planClosed:
                                              _vm.isClosed ||
                                              _vm.selectedPlanClosed,
                                            showDeleteButton: true
                                          },
                                          on: {
                                            planDeleted: function($event) {
                                              return _vm.deletePlan(index)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              _c("template", { slot: "empty" }, [
                                _vm._v(
                                  "There are no plans associated with this payer."
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c(
                    "b-tab",
                    { attrs: { active: _vm.openAt === "bankDetails" } },
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" Bank Details "),
                        _vm.payerStagingDetails !== undefined &&
                        !_vm.payerStagingDetails.bankAccountValidated
                          ? _c("i", {
                              staticClass:
                                "missing-fields fa fa-exclamation-triangle",
                              attrs: {
                                "aria-hidden": "true",
                                title: _vm.bankingValidationMessage
                              }
                            })
                          : _vm._e(),
                        !_vm.isLoading && !_vm.validModulus
                          ? _c("i", {
                              staticClass:
                                "warning-fields fa fa-exclamation-triangle",
                              attrs: {
                                "aria-hidden": "true",
                                title: "Bank details failed modulus check"
                              }
                            })
                          : _vm._e()
                      ]),
                      _c("bank-account-details-input", {
                        attrs: { showIntro: false }
                      })
                    ],
                    2
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Alerts",
                        active: _vm.openAt === "alerts",
                        lazy: ""
                      }
                    },
                    [
                      _c("payer-alerts", {
                        attrs: {
                          reference: _vm.$v.payerReference.$model,
                          ukPayerId: _vm.ukPayerId
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Payer History",
                        active: _vm.openAt === "payerHistory",
                        lazy: ""
                      }
                    },
                    [
                      _c("payer-history", {
                        attrs: { ukPayerId: _vm.ukPayerId }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Notes",
                        active: _vm.openAt === "notes",
                        lazy: ""
                      }
                    },
                    [
                      _c("payer-notes", { attrs: { ukPayerId: _vm.ukPayerId } })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Scheme Messages",
                        active: _vm.openAt === "messages"
                      }
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-2" },
                        [
                          _c("b-col", { attrs: { md: "3" } }, [
                            _c("h3", [_vm._v("Send Message")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.paymentPlanSelectOptions
                                },
                                model: {
                                  value: _vm.sendMessagePlan,
                                  callback: function($$v) {
                                    _vm.sendMessagePlan = $$v
                                  },
                                  expression: "sendMessagePlan"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.messageTypeSelect,
                                  "value-field": "value",
                                  "text-field": "name"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getPreview()
                                  }
                                },
                                model: {
                                  value: _vm.sendMessageType,
                                  callback: function($$v) {
                                    _vm.sendMessageType = $$v
                                  },
                                  expression: "sendMessageType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant: "primary",
                                    disabled:
                                      _vm.sendMessageLoading ||
                                      _vm.sendMessageType === null ||
                                        _vm.sendMessagePlan === null ||
                                        _vm.isLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.sendMessage(
                                        _vm.sendMessageType
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-envelope rpad"
                                  }),
                                  _vm._v("Send ")
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "p-check",
                                {
                                  staticClass: "p-switch p-fill",
                                  attrs: {
                                    id: "previewCheck",
                                    color: "primary"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.getPreview()
                                    }
                                  },
                                  model: {
                                    value: _vm.showPreview,
                                    callback: function($$v) {
                                      _vm.showPreview = $$v
                                    },
                                    expression: "showPreview"
                                  }
                                },
                                [_vm._v("Show Preview")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.sendMessagePlan !== null && _vm.showPreview
                        ? _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [_vm._v("Message Preview")])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.sendMessagePlan !== null && _vm.showPreview
                        ? _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm.messageChannel === 1
                                  ? _c(
                                      "div",
                                      { staticClass: "container" },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticStyle: {
                                                  width: "105% !important"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "padding-top": "110%",
                                                      position: "relative"
                                                    }
                                                  },
                                                  [
                                                    _c("iframe", {
                                                      ref: "previewFrame",
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "0",
                                                        left: "0"
                                                      },
                                                      attrs: {
                                                        frameborder: "0",
                                                        height: "100%",
                                                        width: "100%"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.messageChannel === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "idb-block-content",
                                        attrs: { id: "emailPreview" }
                                      },
                                      [
                                        _c("h4", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              "Subject: " + _vm.previewTitle
                                            )
                                          }
                                        }),
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(_vm.previewBody)
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.messageChannel === 2
                                  ? _c("div", { staticClass: "inline-flex" }, [
                                      _c(
                                        "label",
                                        { staticClass: "speech-bubble-label" },
                                        [
                                          _c("strong", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.previewTitle
                                              )
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "speech-bubble" },
                                        [
                                          !_vm.previewLoading
                                            ? _c("div", {
                                                staticClass: "smsPreviewText",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.previewBody
                                                  )
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.previewLoading
                                            ? _c("div", {
                                                staticClass:
                                                  "smsPreviewTextLoading",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.previewTextPreview
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Message Queue",
                        active: _vm.openAt === "messagequeue"
                      },
                      on: { click: _vm.getPayerMessages }
                    },
                    [
                      _vm.paymentPlans.length > 1
                        ? _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-sm-6",
                                  attrs: {
                                    "label-cols": 5,
                                    "label-class": "required",
                                    horizontal: "",
                                    label: "Plan Messages"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.paymentPlanSelectOptions
                                    },
                                    on: { input: _vm.changeMessageQueuePlan },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "first",
                                          fn: function() {
                                            return [
                                              _c(
                                                "b-form-select-option",
                                                { attrs: { value: null } },
                                                [_vm._v("-- All Plans --")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      678185151
                                    ),
                                    model: {
                                      value: _vm.queuePlan,
                                      callback: function($$v) {
                                        _vm.queuePlan = $$v
                                      },
                                      expression: "queuePlan"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "messageQueue",
                        _vm._b(
                          {},
                          "messageQueue",
                          {
                            payerId: _vm.ukPayerId,
                            planId:
                              _vm.paymentPlans.length > 1
                                ? _vm.paymentPlans[0].paymentPlanId
                                : _vm.queuePlan
                          },
                          false
                        )
                      )
                    ],
                    1
                  ),
                  _vm.hasPayerPortal
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            title: "Direct Messages",
                            active: _vm.openAt === ""
                          }
                        },
                        [
                          _c("compose", {
                            attrs: {
                              plans: _vm.paymentPlans,
                              ukPayerId: _vm.ukPayerId
                            },
                            on: { input: _vm.getPayerMessages }
                          }),
                          _c("inbox", {
                            attrs: {
                              messages: _vm.portalMessages,
                              ukPayerId: _vm.ukPayerId,
                              payerView: true
                            },
                            on: { input: _vm.getPayerMessages }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "idb-block-footer noprint-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    disabled:
                      this.$v.$invalid || !this.editable || _vm.isLoading
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.savePayer($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-save mr-2" }),
                  _vm._v("Save Payer ")
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "pull-right",
                  attrs: {
                    variant: "danger",
                    disabled: _vm.isLoading && !_vm.canDelete
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.requestPayerDeletion($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-trash mr-2" }),
                  _vm._v("Delete Payer ")
                ]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }