<template>
  <div id="importingPayments">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h3>Importing submission files, please wait...</h3>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-12">
                <b-progress striped :animated="true" :max="max" show-progress class="mb-3">
                  <b-progress-bar :value="progress" :label="`${progress}%`"></b-progress-bar>
                </b-progress>
              </div>
            </div>
            <div class="row form-group">
              Importing file {{fileImportedCount + fileErrorCount}} of {{totalFiles}}: {{currentFilename}}
              <br />
              {{filePendingCount}} files remaining
              <br />
              {{fileErrorCount}} files cannot be imported
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    id: String,
    startProcess: Boolean
  },
  data () {
    return {
      max: 100,
      progress: 0,
      counter: 0,
      getTaskProgressUrl: '',
      endTaskUrl: '',
      // counters
      fileImportedCount: 0,
      totalFiles: 0,
      filePendingCount: 0,
      fileErrorCount: 0,
      currentFilename: '',
      monitorStatus: 0

    }
  },

  mounted () {
    this.getTaskProgressUrl = process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/GetImportFilesTaskProgress/' + this.id
    this.endTaskUrl = process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/EndImportFilesTask/' + this.id

    var id = setInterval(() => {
      return axios.get(this.getTaskProgressUrl, {
      })
        .then(response => {
          this.progress = response.data.percentComplete
          this.fileImportedCount = response.data.fileImportedCount
          this.totalFiles = response.data.totalFiles
          this.filePendingCount = response.data.filePendingCount
          this.fileErrorCount = response.data.fileErrorCount
          this.currentFilename = response.data.currentFilename
          this.monitorStatus = response.data.monitorStatus
          if (this.monitorStatus === 'Completed' || this.monitorStatus === 'Error') {
            clearInterval(id)
            this.$router.push({ name: 'ImportedPaymentFilesSummary', params: { id: this.id } })
          }
        })
        .catch(error => console.log(error))
    }, 1000)
  },

  async created () {
    if (this.startProcess) {
       var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/manualbacsbureaustartimportfiles/' + this.id)
    }
  }
}
</script>
