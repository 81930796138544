// General purpose, WF wide file utils
const sanitize = require('sanitize-filename')

export const sanitisePath = path => {
  // takes a path and sanitises it:
  if (!path) return ''
  //  - Remove directory traversal   (..\..\..\mySecretFiles\passwords.txt)
  path = path.replace(/\.\./g, '')

  return path
}

export const sanitiseFilename = filename => {
  // NOTE!!  Will strip off wildcard characters - best to do this node side tbh.
  // See: https://www.npmjs.com/package/sanitize-filename
  if (!filename) return ''
  return sanitize(filename)
}
