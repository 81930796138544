var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "viewFlaggedSubmissions" } },
    [
      _c(
        "b-row",
        { attrs: { mb: "4" } },
        [
          _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [
                _c(
                  "h2",
                  [
                    _c("help-icon", {
                      attrs: { docPath: "/paygate-collections/submissions/" }
                    }),
                    _vm._v("Flagged Payments")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { staticStyle: { "margin-bottom": "0.8em" } },
                        [
                          _c("b-col", { attrs: { md: "2" } }, [
                            _c("strong", [_vm._v("Group:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.flaggedPayments.length > 0
                                    ? _vm.flaggedPayments[0].groupName
                                    : ""
                                ) +
                                " "
                            )
                          ]),
                          _c("b-col", { attrs: { md: "2" } })
                        ],
                        1
                      ),
                      _c(
                        "vue-good-table",
                        {
                          ref: "table",
                          attrs: {
                            columns: _vm.columns,
                            rows: _vm.flaggedPayments,
                            lineNumbers: true,
                            totalRows: _vm.totalRecords,
                            "search-options": {
                              enabled: true
                            },
                            paginationOptions: _vm.paginationOptions,
                            "sort-options": _vm.sortOptions,
                            isLoading: _vm.isTableLoading,
                            styleClass: "vgt-table striped bordered"
                          },
                          on: {
                            "on-page-change": _vm.onPageChange,
                            "on-sort-change": _vm.onSortChange,
                            "on-column-filter": _vm.onColumnFilter,
                            "on-per-page-change": _vm.onPerPageChange,
                            "on-search": _vm.onSearch,
                            "on-row-click": _vm.onRowClick,
                            "update:isLoading": function($event) {
                              _vm.isTableLoading = $event
                            },
                            "update:is-loading": function($event) {
                              _vm.isTableLoading = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field == "viewBtn"
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              color: "blue"
                                            }
                                          },
                                          [_vm._v("View")]
                                        )
                                      ])
                                    : props.column.field == "viewPayerBtn"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to:
                                                  "/collections/payer/" +
                                                  props.row.ukPayerId +
                                                  "/" +
                                                  props.row.paymentPlanId +
                                                  "/schedule"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("View Schedule")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "emptystate" },
                              slot: "emptystate"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vgt-center-align vgt-text-disabled"
                                },
                                [_vm._v(" No Flagged Payments Found ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Clear filters",
                                      expression: "'Clear filters'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  staticClass: "btn btn-link",
                                  attrs: { disabled: _vm.isLoading },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.clearTableFilters($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fa-stack",
                                      staticStyle: { "font-size": "10px" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-filter fa-stack-1x dimmedIcon"
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "fa fa-ban fa-stack-2x dimmedIcon"
                                      })
                                    ]
                                  ),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Clear filters")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Refresh the data in the table",
                                      expression:
                                        "'Refresh the data in the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    disabled: _vm.isLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.load($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-sync pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Refresh Table")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Export the contents of the table",
                                      expression:
                                        "'Export the contents of the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    disabled: _vm.isLoading,
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.exportTable($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-share-square pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Export Table")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }