const Workflows = () => import(/* webpackChunkName: "workflow-workflows" */ '@/Views/Workflow/Workflow/Workflows.vue')
const WorkflowDetail = () => import(/* webpackChunkName: "workflow-workflows" */ '@/Views/Workflow/Workflow/WorkflowDetail.vue')
const WorkflowEvent = () => import(/* webpackChunkName: "workflow-workflows" */ '@/Views/Workflow/Workflow/telemetry/WorkflowEvent.vue')
const RealtimeTelemetry = () => import(/* webpackChunkName: "workflow-telemetry" */ '@/Views/Workflow/Workflow/RealtimeTelemetry.vue')
const TriggerTelemetry = () => import(/* webpackChunkName: "workflow-telemetry" */ '@/Views/Workflow/Workflow/telemetry/TriggerTelemetry.vue')
const AllTriggerTelemetry = () => import(/* webpackChunkName: "workflow-alltelemetry" */ '@/Views/Workflow/Workflow/telemetry/AllTriggerTelemetry.vue')
const WorkflowTelemetry = () => import(/* webpackChunkName: "workflow-telemetry" */ '@/Views/Workflow/Workflow/telemetry/WorkflowTelemetry.vue')
const FileBrowser = () => import(/* webpackChunkName: "workflow-browser" */ '@/Views/Workflow/Workflow/FileBrowser.vue')
const Timeline = () => import(/* webpackChunkName: "workflow-browser" */ '@/Views/Workflow/Workflow/telemetry/Timeline.vue')
const Triggers = () => import(/* webpackChunkName: "workflow-triggers" */ '@/Views/Workflow/Workflow/Triggers.vue')
const ScheduleTrigger = () => import(/* webpackChunkName: "workflow-triggers" */ '@/Views/Workflow/Workflow/triggerConfig/ScheduleTrigger.vue')
const CronTrigger = () => import(/* webpackChunkName: "workflow-triggers" */ '@/Views/Workflow/Workflow/triggerConfig/CronTrigger.vue')
const FileTrigger = () => import(/* webpackChunkName: "workflow-triggers" */ '@/Views/Workflow/Workflow/triggerConfig/FileTrigger.vue')
const WebhookTrigger = () => import(/* webpackChunkName: "workflow-triggers-wh" */ '@/Views/Workflow/Workflow/triggerConfig/WebhookTrigger.vue')
// const TriggerExplorer = () => import(/* webpackChunkName: "workflow-triggers" */ '@/Views/Workflow/System/TriggerExplorer.vue')
const WorkflowMonitor = () => import(/* webpackChunkName: "workflow-monitor" */ '@/Views/Workflow/System/WorkflowMonitor.vue')
const MappingDetail = () => import(/* webpackChunkName: "workflow-mapping" */ '@/Views/Workflow/Mapping/MappingDetail.vue')
const Mappings = () => import(/* webpackChunkName: "workflow-mapping" */ '@/Views/Workflow/Mapping/Mappings.vue')
const Importers = () => import(/* webpackChunkName: "workflow-importer" */ '@/Views/Workflow/Mapping/import/Importers.vue')
const CsvImporter = () => import(/* webpackChunkName: "workflow-importer" */ '@/Views/Workflow/Mapping/import/CsvImporter.vue')
const FixedLengthImporter = () => import(/* webpackChunkName: "workflow-importer" */ '@/Views/Workflow/Mapping/import/FixedLengthImporter.vue')
const WorkflowDesigner = () => import(/* webpackChunkName: "workflow-designer" */ '@/Components/Workflow/Workflow/Designer.vue')
const WorkflowLoad = () => import(/* webpackChunkName: "workflow-load" */ '@/Views/Workflow/Workflow/Load.vue')
const MappingDesigner = () => import(/* webpackChunkName: "workflow-designer" */ '@/Views/Workflow/Mapping/MappingDesignerContainer.vue')
const Tabs = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Forms/Tabs.vue')
const Alerts = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Ui/SweetAlert.vue')
const Toast = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Ui/Toast.vue')
const Chartjs = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Ui/Chartjs.vue')
const DatatablesDotnet = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Tables/DatatablesDotnet.vue')
const Other = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Tables/Other.vue')
const Sample = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Ui/Sample.vue')
const Duplication = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Config/Duplication.vue')
const DesignerSettings = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Config/DesignerSettings.vue')
const WorkflowQueue = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Config/WorkflowQueue.vue')
const FolderManager = () => import(/* webpackChunkName: "workflow-other" */ '@/Views/Workflow/Config/FolderManager.vue')

export default [
	{
		path: '/automation/mapping/mappings',
		component: Mappings,
		meta: {
			title: 'Mappings',
			breadcrumb: 'home > mapping > mappings'
		}
	},
	{
		path: '/automation/mapping/mappingdetail/:id',
		component: MappingDetail,
		meta: {
			title: 'Mapping Detail',
			breadcrumb: 'home > mapping > mapping detail'
		}
	},
	{
		path: '/automation/mapping/import/importers',
		component: Importers,
		meta: {
			title: 'Importers',
			breadcrumb: 'home > mapping > import > importers'
		}
	},
	{
		path: '/automation/mapping/import/csvImporter',
		component: CsvImporter,
		meta: {
			title: 'CSV Importer',
			breadcrumb: 'home > mapping > import > importers > csv'
		}
	},
	{
		path: '/automation/mapping/import/csvImporter/:id',
		component: CsvImporter,
		meta: {
			title: 'CSV Importer',
			breadcrumb: 'home > mapping > import > importers > csv'
		}
	},
	{
		path: '/automation/mapping/import/fixedLengthImporter',
		component: FixedLengthImporter,
		meta: {
			title: 'Fixed Length Importer',
			breadcrumb: 'home > mapping > import > importers > fixed length'
		}
	},
	{
		path: '/automation/mapping/import/fixedLengthImporter/:id',
		component: FixedLengthImporter,
		meta: {
			title: 'Fixed Length Importer',
			breadcrumb: 'home > mapping > import > importers > fixed length'
		}
	},
	{
		path: '/automation/mapping/designer',
		component: MappingDesigner,
		meta: {
			breadcrumb: 'home > mapping > designer',
			title: 'Mapping Designer'
		}
	},
	{
		path: '/automation/mapping/designer/:id',
		component: MappingDesigner,
		meta: {
			breadcrumb: 'home > mapping > designer',
			title: 'Mapping Designer'
		}
	},
	{
		path: '/automation/workflow/workflows',
		component: Workflows,
		meta: {
			breadcrumb: 'home > workflows',
			title: 'Workflows'
		}
	},
	{
		path: '/automation/workflow/workflowdetail/:id',
		component: WorkflowDetail,
		meta: {
			breadcrumb: 'home > workflows > Detail',
			title: 'Workflow Detail'
		}
	},
	{
		path: '/automation/workflow/triggers',
		component: Triggers,
		meta: {
			title: 'Triggers',
			breadcrumb: 'home > workflow > triggers'
		}
	},
	{
		path: '/automation/workflow/fileBrowser',
		component: FileBrowser,
		meta: {
			breadcrumb: 'home > workflow > file browser',
			title: 'File Browser'
		}
	},
	{
		path: '/automation/workflow/RealtimeTelemetry',
		component: RealtimeTelemetry,
		meta: {
			breadcrumb: 'home > workflow > Real-time telemetry',
			title: 'Real-time telemetry'
		}
	},
	{
		path: '/automation/workflow/triggerTelemetry',
		component: TriggerTelemetry,
		meta: {
			breadcrumb: 'home > workflow > Log',
			title: 'Workflow Log'
		}
	},
  {
		path: '/automation/workflow/triggerTelemetry/:id',
		component: TriggerTelemetry,
		meta: {
			breadcrumb: 'home > workflow > Log',
			title: 'Workflow Log'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/scheduletrigger/:id',
		component: ScheduleTrigger,
		meta: {
			breadcrumb: 'home > triggers > schedule trigger',
			title: 'Schedule Trigger'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/crontrigger/:id',
		component: CronTrigger,
		meta: {
			breadcrumb: 'home > triggers > cron trigger',
			title: 'Cron Trigger'
		}
	},
	{
		path: '/automation/logging/triggertelemetry/:id',
		component: TriggerTelemetry,
		meta: {
			breadcrumb: 'home > telemetry > trigger telemetry',
			title: 'Workflow Log'
		}
	},
	{
		path: '/automation/logging/triggertelemetry',
		component: AllTriggerTelemetry,
		meta: {
			breadcrumb: 'home > telemetry > all trigger telemetry',
			title: 'Workflow Logs'
		}
	},
	{
		path: '/automation/workflow/workflowtelemetry/:id',
		component: WorkflowTelemetry,
		meta: {
			breadcrumb: 'home > telemetry > trigger telemetry > workflow telemetry',
			title: 'Workflow Log'
		}
	},
	{
		path: '/automation/workflow/workflowevent/:id/:eid',
		component: WorkflowEvent,
		meta: {
			breadcrumb: 'home > telemetry > trigger telemetry > workflow telemetry > event',
			title: 'Workflow Event'
		}
	},
	{
		path: '/automation/workflow/timeline/:id',
		component: Timeline,
		meta: {
			breadcrumb: 'home > telemetry > trigger telemetry > timeline',
			title: 'Workflow Timeline'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/scheduletrigger/',
		component: ScheduleTrigger,
		meta: {
			breadcrumb: 'home > triggers > new schedule trigger',
			title: 'Schedule Trigger'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/crontrigger/',
		component: CronTrigger,
		meta: {
			breadcrumb: 'home > triggers > new cron trigger',
			title: 'Cron Trigger'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/filetrigger/:id',
		component: FileTrigger,
		meta: {
			breadcrumb: 'home > triggers > file trigger',
			title: 'File Trigger'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/filetrigger/',
		component: FileTrigger,
		meta: {
			breadcrumb: 'home > triggers > new file trigger',
			title: 'File Trigger'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/webhooktrigger/:id',
		component: WebhookTrigger,
		meta: {
			breadcrumb: 'home > triggers > file trigger',
			title: 'File Trigger'
		}
	},
	{
		path: '/automation/workflow/triggerConfig/webhooktrigger/',
		component: WebhookTrigger,
		meta: {
			breadcrumb: 'home > triggers > new webhook trigger',
			title: 'Webhook Trigger'
		}
	},
	{
		path: '/automation/config/Duplication',
		component: Duplication,
		meta: {
			breadcrumb: 'home > config > duplication',
			title: 'Duplication Configuration'
		}
	},
	{
		path: '/automation/config/DesignerSettings',
		component: DesignerSettings,
		meta: {
			breadcrumb: 'home > config > designer settings',
			title: 'Designer Settings'
		}
	},
	// {
	//   path: '/automation/config/WorkflowQueue',
	//   component: WorkflowQueue,
	//   meta: {
	//     breadcrumb: 'home > config > workflow queue',
	//     title: 'Workflow Queue'
	//   }
	// },
	{
		path: '/automation/config/FolderManager',
		component: FolderManager,
		meta: {
			breadcrumb: 'home > config > foldermanager',
			title: 'Folder Manager'
		}
	},
	// {
	//   path: '/automation/system/TriggerExplorer',
	//   component: TriggerExplorer,
	//   meta: {
	//     breadcrumb: 'home > config > duplication',
	//     title: 'Trigger Explorer'
	//   }
	// },
	{
		path: '/automation/system/WorkflowMonitor',
		component: WorkflowMonitor,
		meta: {
			breadcrumb: 'home > config > monitor',
			title: 'Workflow Monitor'
		}
	},
	{
		path: '/automation/workflow/forms/Tabs',
		component: Tabs,
		meta: {
			breadcrumb: 'Tabs'
		}
	},
	{
		path: '/automation/workflow/tables/datatablesdotnet',
		component: DatatablesDotnet,
		meta: {
			breadcrumb: 'Datatables.net'
		}
	},
	{
		path: '/automation/workflow/tables/other',
		component: Other,
		meta: {
			breadcrumb: 'home > Other'
		}
	},
	{
		path: '/automation/workflow/ui/alerts',
		component: Alerts,
		meta: {
			breadcrumb: 'Alerts'
		}
	},
	{
		path: '/automation/ui/toasts',
		component: Toast,
		meta: {
			breadcrumb: 'Toast'
		}
	},
	{
		path: '/automation/workflow/designer',
		component: WorkflowDesigner,
		meta: {
			breadcrumb: '/automation/workflow/designer',
			title: 'Workflow Designer'
		}
	},
	{
		path: '/automation/workflow/designer/:id',
		component: WorkflowDesigner,
		meta: {
			breadcrumb: '/automation/workflow/designer',
			title: 'Workflow Designer'
		}
	},
	{
		path: '/automation/system/workflowload',
		component: WorkflowLoad,
		meta: {
			breadcrumb: 'home > workflow > load',
			title: 'Workflow Load'
		}
	},
	{
		path: '/automation/ui/chartjs',
		component: Chartjs,
		meta: {
			breadcrumb: 'ChartJs'
		}
	},
	{
		path: '/automation/sample',
		component: Sample,
		meta: {
			breadcrumb: 'home > area > sample'
		}
	}
]
