var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "synchronisationReportDetails" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c("div", { staticClass: "idb-block" }, [
                _c("div", { staticClass: "idb-block-title" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.title))])
                ]),
                _c("div", { staticClass: "idb-block-content" }, [
                  _vm._v(_vm._s(_vm.body))
                ]),
                _c(
                  "div",
                  { staticClass: "idb-block-footer" },
                  [
                    _vm.actionId && _vm.actionId !== null
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "info" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removeAction($event)
                              }
                            }
                          },
                          [_vm._v("Clear Action")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }