var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
        _vm._v("Payment Approval Method")
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c("div", { staticClass: "form-control-plaintext" }, [
          _c("span", [_vm._v(_vm._s(_vm.group.details.paymentApprovalMethod))])
        ])
      ])
    ]),
    _vm.group.details.paymentApprovalMethod === "Number of Approvers"
      ? _c("div", [
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Number of Approvers")
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.group.details.numberOfApprovers))
                ])
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm.group.details.paymentApprovalMethod === "Approval Range"
      ? _c("div", [
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
              _vm._v("Approval Range")
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              _vm._l(_vm.approvalRanges, function(approvalRange) {
                return _c(
                  "div",
                  { key: approvalRange, staticClass: "form-control-plaintext" },
                  [_c("span", [_vm._v(_vm._s(approvalRange))])]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _vm.group.details.paymentApprovalMethod !== "Default"
      ? _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Approval Method")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", [_vm._v(_vm._s(_vm.group.details.approvalMethod))])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }