<template>
  <div id="importedPayments">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Imported Files Summary
          <help-icon :docPath="'/bacsbureau/submissions/'"></help-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <div class="container-fluid">
          <div class="form-row">
            <div class="col-md-12">
              <span>
                <b>Submission Reference:</b>
                {{submissionReference}}
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <span>
                <b>Bureau Job Template:</b>
                {{bureauJobName}}
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <vue-good-table
                :paginationOptions="paginationOptions"
                :sort-options="sortOptions"
                :rows="rows"
                :columns="columns"
                :searchOptions="{ enabled: true }"
                mode="remote"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                @on-sort-change="onSortChange"
                ref="table"
                :isLoading.sync="isTableLoading"
                styleClass="vgt-table striped bordered"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'filename'">
                    <div
                      v-if="props.row.bureauFileImportStatus === 'Error'"
                      class="group-colour"
                      :style="{ 'background-color': 'red' }"
                    ></div>
                    <div v-else class="group-colour" :style="{ 'background-color': 'green' }"></div>
                    {{props.row.filename}}
                  </span>
                </template>
                <div slot="table-actions">
                  <button
                    @click.prevent="clearTableFilters"
                    class="btn btn-link"
                    v-b-popover.hover.top.d500="'Clear filters'"
                  >
                    <span class="fa-stack" style="font-size: 10px;">
                      <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                      <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                    </span>
                    <span class="sr-only">Clear filters</span>
                  </button>
                </div>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>

      <div class="idb-block-footer">
        <div v-if="canProceed">
          <button
            class="btn btn-primary ml-2"
            @click="onViewPaymentsClick()"
            :disabled="isTableLoading"
          >View Submission Details</button>
        </div>
        <div v-else-if="!canProceed && showTotalFailure">
          <span
            class="fw-bold text-danger"
          >There are no viable payment files in this submission. The submission cannot proceed.</span>
          <span class="col-sm-3 pull-right">
            <button
              type="button"
              class="btn btn-danger pull-right"
              v-on:click="onCancelSubmission"
            >Cancel Submission</button>
          </span>
        </div>
        <div v-else class="fw-bold text-warning">
          <span
            v-if="finishedLoading"
          >The submission still appears to be processing. Please wait and refresh.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import Utility from '@/Assets/Mixins/Utility'
import colours from '@/Assets/Constants/colours'
import bacsCommon from '@/Lib/BacsCommon.js'
import swal from 'sweetalert2'

export default {
  props: {
    id: String,
    startProcess: Boolean
  },
  mixins: [
    Utility,
    tableFilterMixin
  ],
  data () {
    return {
      submissionReference: '',
      bureauJobName: '',
      paymentNetworkType: 0,
      submission: {},
      canProceed: false,
      showTotalFailure: false,
      columns: [
        {
          label: 'Filename',
          field: 'filename'
        },
        {
          label: 'Status',
          field: 'bureauFileImportStatus'
        },
        {
          label: 'Messages',
          field: 'errorText'
        }
      ],
      rows: [],
      serverParams: {
        sort: [{ field: 'bureauFileImportStatus', type: 'desc' }]
      },
      finishedLoading: false
    }
  },

  async created () {
    await this.getBureauImportSummary()
    await this.load()
    await this.loadSubmissionDetails()
    this.finishedLoading = true
  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getimportedpaymentfilessummary', { params: { ...this.buildGoodTableQuery(), id: this.id }, showload: true })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    async getBureauImportSummary () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureauimportsummary/' + this.id)
        this.bureauJobName = response.data.bureauJobName
        this.submissionReference = response.data.submissionReference
        this.paymentNetworkType = response.data.paymentNetworkType
        if (response.data.bureauSubmissionMonitorStatus === 'Completed') {
          this.canProceed = true
        }
        else if (response.data.bureauSubmissionMonitorStatus === 'Error') {
          this.canProceed = false
          this.showTotalFailure = true
        } else {
          this.canProceed = false
          this.showTotalFailure = false
        }
      } catch { } finally { }
    },
    onViewPaymentsClick () {
      // The normal payment detail view can handle the FPS stuff, so this has been adjusted.
      this.$router.push({ name: 'PaymentDetails', params: { id: this.id } })
    },

    loadSubmissionDetails: async function () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getSubmissionData/' + this.id, { showload: true })

        this.submission = response.data
      } catch (e) {
      } finally {
        this.isTableLoading = false
      }
    },

    async onCancelSubmission () {
      this.canDeleteAction = false
      const submissionId = this.id
      const submissionReference = this.submissionReference

      event.stopPropagation()

      const swalResult = await swal.fire({
        title: 'Cancel Submission',
        text: `Are you sure you want to cancel the submission: ${submissionReference}`,
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          this.loading = true

          if (this.submission.status === 1) {
            var nextStatus = 'Created'
            switch (this.submission.bacsPaymentStatus) {
              case 'Created':
                nextStatus = 'Signed'
                break
              case 'Signed':
                nextStatus = 'Approved'
                break
              case 'Approved':
                nextStatus = 'Submitted'
                break
            }
            var action = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${submissionId}`, { showload: true })
            var json = JSON.stringify({
              submissionId: submissionId,
              actionId: action.data.id,
              nextStatus: nextStatus
            })

            var CanProceeedResponse = await axios({
              method: 'POST',
              url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
              data: json
            })

            if (CanProceeedResponse.data.success) {
              var cancelReponse = await bacsCommon.cancelSubmissionDialog()
              if (cancelReponse.cancel === true) {
                var group = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getbureaugroup/${this.submission.groupId}`, { showload: true })

                json = JSON.stringify({
                  submissionId: submissionId,
                  actionId: action.data.id,
                  serviceUserNumber: group.data.serviceUserNumber,
                  fileNumber: 0,
                  fromRecordNumber: 0,
                  toRecordNumber: 0,
                  deletePaymentData: group.data.deletePaymentData,
                  cancellationReason: cancelReponse.cancellationReason
                })

                await bacsCommon.confirmCancellation(json, axios, this.$toastr, this.$router)
                await this.$store.dispatch('cancelCollectionsSubmission', submissionId)
              } else {
                var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + submissionId
                await axios.get(endTaskUrl)
                this.isLoaded = true
              }
            } else {
              var endTaskUrlCancel = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + submissionId
              await axios.get(endTaskUrlCancel)
              //                this.isLoaded = true
            }
          } else {
            const response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteSubmission/' + submissionId, {})

            if (response.data.status === 'Deleted') {
              this.$toastr.s(`Bureau Submission ${submissionReference} deleted`, 'Bureau Submission')
              this.$store.dispatch('updateBureauTopSeverityLevel')
              this.$store.dispatch('updateBureauSubmissionPill')
              this.$router.push({ name: 'SubmissionsInProgress' })
            } else {
            }
          }
        } catch (error) {
          console.error('Failed to call API')
          console.error(error)
        }
        this.loading = false
      }
    }

  }

}

</script>
