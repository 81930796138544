var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          !_vm.isLoading && _vm.checkValidation() && _vm.validate()
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                { attrs: { id: "title-of-block-check" } },
                [
                  _vm._v(" IBAN Validation "),
                  _c("help-icon", { attrs: { docPath: "/validate/iban/" } }),
                  _c("favourite-icon")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c(
                "div",
                {
                  staticClass: "form-group col-md-6",
                  class: {
                    warn: _vm.$v.iban.number.$error,
                    valid:
                      _vm.iban.result != null &&
                      _vm.iban.result.valid &&
                      _vm.iban.result.errorCode !== "1405",
                    invalid:
                      _vm.iban.result != null &&
                      (!_vm.iban.result.valid ||
                        _vm.iban.result.errorCode === "1405")
                  }
                },
                [
                  _c("label", { staticClass: "label-control required" }, [
                    _vm._v("IBAN")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.iban.number.$model,
                        expression: "$v.iban.number.$model",
                        modifiers: { trim: true }
                      },
                      { name: "focus", rawName: "v-focus" }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.$v.iban.number.$model },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.iban.number,
                            "$model",
                            $event.target.value.trim()
                          )
                        },
                        _vm.adjustInput
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm.$v.iban.number.$dirty
                    ? _c("span", [
                        !_vm.$v.iban.number.required
                          ? _c(
                              "small",
                              { staticClass: "form-text text-warning small" },
                              [_vm._v("An IBAN is required")]
                            )
                          : _vm._e(),
                        !_vm.$v.iban.number.minLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-warning small" },
                              [
                                _vm._v(
                                  "IBAN's must be at least " +
                                    _vm._s(_vm.validation.minLength) +
                                    " characters long for this country"
                                )
                              ]
                            )
                          : _vm._e(),
                        !_vm.$v.iban.number.maxLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-warning small" },
                              [
                                _vm._v(
                                  "IBAN's must be less than " +
                                    _vm._s(_vm.validation.minLength) +
                                    " characters long for this country"
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.iban.result != null
                    ? _c("span", [
                        _vm.iban.result.valid &&
                        _vm.iban.result.errorCode !== "1405"
                          ? _c(
                              "small",
                              { staticClass: "form-text text-success small" },
                              [_vm._v("The IBAN is valid")]
                            )
                          : _vm._e(),
                        _vm.iban.result.valid &&
                        _vm.iban.result.errorCode === "1405"
                          ? _c(
                              "small",
                              { staticClass: "form-text text-warning small" },
                              [_vm._v(_vm._s(_vm.iban.result.errorText))]
                            )
                          : _vm._e(),
                        !_vm.iban.result.valid
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger small" },
                              [_vm._v("The IBAN is invalid")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.iban.code,
                      expression: "this.iban.code"
                    }
                  ],
                  staticClass: "form-group col-md-6"
                },
                [
                  _c("span", {
                    class:
                      "flag-icon flag-icon-" +
                      this.iban.code.toLowerCase().trim(),
                    staticStyle: { border: "1px solid black" }
                  }),
                  _c("label", { staticClass: "label-control pl-2" }, [
                    _vm._v(_vm._s(_vm.iban.countryName))
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: _vm.isLoading }
                },
                [_vm._v("Validate")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary ml-3",
                  attrs: { type: "button" },
                  on: { click: _vm.clear }
                },
                [_vm._v("Clear")]
              )
            ])
          ])
        ])
      ]),
      _vm.iban.result != null &&
      _vm.iban.result.valid &&
        _vm.iban.result.errorCode !== "1405" &&
        _vm.iban.code === "GB"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "idb-block" }, [
                _vm._m(0),
                _c("div", { staticClass: "idb-block-content" }, [
                  _c(
                    "form",
                    {
                      staticClass: "col-md-12",
                      attrs: { novalidate: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Bank")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(_vm._s(_vm.iban.result.bankName))
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Branch")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(_vm._s(_vm.iban.result.branchName))
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Address")
                        ]),
                        _c("address", {
                          staticClass: "form-control-static",
                          domProps: { innerHTML: _vm._s(_vm.Address) }
                        })
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("BIC")
                        ]),
                        _c("span", { staticClass: "form-control-static" }, [
                          _vm._v(_vm._s(_vm.iban.result.bic))
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { attrs: { id: "title-of-block-check" } }, [
        _vm._v("Bank Details")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }