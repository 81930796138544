<script>
import SnapBar from './SnapBar'

SnapBar.install = (Vue, options = {}) => {
  const SnapBarComponent = Vue.extend({
    render: h => {
      return h(SnapBar, {
        props: {
          options: options
        },
        ref: 'snapBar'
      })
    }
  })

  var component = new SnapBarComponent().$mount()
  document.body.appendChild(component.$el)
  Vue.prototype.$snapbar = component.$refs.snapBar

  if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(SnapBar)
  }
}

export default SnapBar
</script>
