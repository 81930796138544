var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.useAutoGeneratedRef
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-5",
                  attrs: {
                    "label-cols": 5,
                    "label-class": "required",
                    horizontal: "",
                    label: "Payer Reference"
                  }
                },
                [
                  _c("b-form-input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "referenceInput",
                      type: "text",
                      required: "",
                      formatter: _vm.formatReference
                    },
                    on: {
                      input: function($event) {
                        return _vm.checkReference(_vm.$v.reference)
                      }
                    },
                    model: {
                      value: _vm.reference,
                      callback: function($$v) {
                        _vm.reference = $$v
                      },
                      expression: "reference"
                    }
                  }),
                  !this.useAutoGeneratedRef && _vm.$v.reference.$dirty
                    ? _c("b-col", [
                        _vm.$v.reference.$invalid
                          ? _c(
                              "label",
                              {
                                staticClass: "text-danger small",
                                attrs: { id: "payerReferenceRequiredLabel" }
                              },
                              [_vm._v("Payer Reference required")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  !this.useAutoGeneratedRef && _vm.existingReference
                    ? _c("b-col", [
                        _c(
                          "label",
                          {
                            staticClass: "text-warning small",
                            attrs: { id: "payerReferenceExistsLabel" }
                          },
                          [
                            _vm._v(
                              " A user already exists with this reference. "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: { "label-cols": 5, horizontal: "", label: "Title" }
            },
            [
              _c("b-form-select", {
                staticClass: "form-control",
                attrs: {
                  id: "titleSelect",
                  options: _vm.titles,
                  "value-field": "ordinal",
                  "text-field": "name"
                },
                model: {
                  value: _vm.$v.title.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.title, "$model", $$v)
                  },
                  expression: "$v.title.$model"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: {
                "label-cols": 5,
                "label-class": "required",
                horizontal: "",
                label: "First Name"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", required: "", id: "firstNameInput" },
                model: {
                  value: _vm.$v.firstName.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.firstName, "$model", $$v)
                  },
                  expression: "$v.firstName.$model"
                }
              }),
              _vm.$v.firstName.$dirty
                ? _c("b-col", [
                    _vm.$v.firstName.$invalid
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "firstNameRequiredLabel" }
                          },
                          [_vm._v("First Name required")]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: {
                "label-cols": 5,
                "label-class": "required",
                horizontal: "",
                label: "Surname"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", id: "surnameInput" },
                model: {
                  value: _vm.$v.surname.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.surname, "$model", $$v)
                  },
                  expression: "$v.surname.$model"
                }
              }),
              _vm.$v.surname.$dirty
                ? _c("b-col", [
                    _vm.$v.surname.$invalid
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "surnameRequiredLabel" }
                          },
                          [_vm._v("Surname required")]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: {
                "label-cols": 5,
                horizontal: "",
                label: "Telephone Number 1"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "tel1Input",
                  disabled: !_vm.editable
                },
                model: {
                  value: _vm.$v.tel1.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.tel1, "$model", $$v)
                  },
                  expression: "$v.tel1.$model"
                }
              }),
              _vm.$v.tel1.$invalid
                ? _c("b-col", [
                    _c(
                      "label",
                      {
                        staticClass: "text-danger small",
                        attrs: { id: "tel1InvalidLabel" }
                      },
                      [_vm._v("Valid telephone number required")]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: {
                "label-cols": 5,
                horizontal: "",
                label: "Telephone Number 2"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", id: "tel2Input" },
                model: {
                  value: _vm.$v.tel2.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.tel2, "$model", $$v)
                  },
                  expression: "$v.tel2.$model"
                }
              }),
              _vm.$v.tel2.$invalid
                ? _c("b-col", [
                    _c(
                      "label",
                      {
                        staticClass: "text-danger small",
                        attrs: { id: "tel2InvalidLabel" }
                      },
                      [_vm._v("Valid telephone number required")]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: {
                "label-cols": 5,
                "label-class": _vm.smsRequired ? "required" : "",
                horizontal: "",
                label: "Mobile Number"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", id: "mobileInput" },
                model: {
                  value: _vm.$v.mobile.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.mobile, "$model", $$v)
                  },
                  expression: "$v.mobile.$model"
                }
              }),
              _vm.$v.mobile.$invalid
                ? _c("b-col", [
                    _c(
                      "label",
                      {
                        staticClass: "text-danger small",
                        attrs: { id: "smsInvalidLabel" }
                      },
                      [_vm._v("Mobile number required")]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: {
                "label-cols": 5,
                "label-class": _vm.emailRequired ? "required" : "",
                horizontal: "",
                label: "Email Address"
              }
            },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", id: "emailInput" },
                on: {
                  input: function($event) {
                    return _vm.checkEmail(_vm.$v.email)
                  },
                  keydown: function($event) {
                    _vm.existingEmailUsers = false
                  }
                },
                model: {
                  value: _vm.$v.email.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.email, "$model", $$v)
                  },
                  expression: "$v.email.$model"
                }
              }),
              _vm.$v.email.$dirty
                ? _c("b-col", [
                    _vm.emailRequired && !_vm.$v.email.required
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "emailRequiredLabel" }
                          },
                          [_vm._v("Provide a valid Email Address")]
                        )
                      : _vm._e(),
                    !_vm.$v.email.email
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "emailInvalidLabel" }
                          },
                          [_vm._v("Valid Email Address required")]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.existingEmailUsers
                ? _c("b-col", [
                    _c(
                      "label",
                      {
                        staticClass: "text-warning small",
                        attrs: { id: "emailDuplicateLabel" }
                      },
                      [
                        _vm._v(
                          " Users with this email are already in this group. Is this correct? "
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal",
                                value: _vm.pageInstanceId,
                                expression: "pageInstanceId"
                              }
                            ],
                            staticClass: "dupeLink"
                          },
                          [_vm._v("View duplicates.")]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.pageInstanceId,
            title: "Duplicate Emails for " + _vm.$v.email.$model,
            "ok-only": true
          }
        },
        [
          _c("p", [
            _vm._v(
              "Below is a list of Payers which share the address: " +
                _vm._s(_vm.$v.email.$model) +
                ", clicking on a row will navigate to the selected payer."
            )
          ]),
          _c(
            "table",
            { staticStyle: { width: "100%" } },
            [
              _c("tr", [
                _c("th", [_vm._v("Name")]),
                _c("th", [_vm._v("Status")]),
                _c("th", [_vm._v("Reference")])
              ]),
              _vm._l(_vm.duplicateEmails, function(payer, index) {
                return _c(
                  "tr",
                  { key: index, staticStyle: { cursor: "pointer" } },
                  [
                    _c("td", [_vm._v(_vm._s(payer.name))]),
                    _c("td", [_vm._v(_vm._s(_vm.statusToText(payer.status)))]),
                    _c("td", [_vm._v(_vm._s(payer.reference))])
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }