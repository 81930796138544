<template>
  <div id="TheBureauSceneryChannel">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2 class="text-danger">Bureau Scenery Channel</h2>
      </div>
      <div class="idb-block-content">
        <b-col sm="3">
          <group-select
            v-model="groupId"
            :groups="groups"
            :clearable="false"
            @input="onGroupSelect"
          ></group-select>
        </b-col>
        <button class="btn btn-primary mr-2" @click="onTestWorkflowGetBureauJobs">Get Bureau Jobs</button>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2 class="text-danger">Get Processing or Payment Date Tests</h2>
        </div>
        <div class="idb-block-content">
          <button class="btn btn-primary mr-2" @click="onTestGetPaymentDate">GetPaymentDate</button>
          <button class="btn btn-primary mr-2" @click="onTestGetProcessingDate">GetProcessingDate</button>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2 class="text-danger">File Deletion Testing</h2>
        </div>
        <div class="idb-block-content">
          <button class="btn btn-primary mr-2" @click="onTestDeleteFiles">Test Delete</button>
        </div>
      </div>

      <div class="idb-block">
        <div class="idb-block-title">
          <h2 class="text-danger">Test Bureau Vocalink Summary Getter</h2>
        </div>
        <div class="idb-block-content">
          <button
            class="btn btn-primary mr-2"
            @click="onTestBureauSummaryGetter"
          >Test Bureau Vocalink Summary Getter</button>

          <button
            class="btn btn-primary mr-2"
            @click="TestPillUPdate"
          >Test Pill Update</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import BarChart from '@/Assets/Components/Charts/BarChart.js'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [Utility, tableFilterMixin, bacsMixin],
  components: {
    VueSelect, BarChart
  },
  data () {
    return {
      groups: [],
      groupid: ''
    }
  },

  computed: {
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  created () {
    this.getBureauGroupData()
    this.getBureauLicenseCheck()
  },
  methods: {
    async getBureauLicenseCheck () {
      var LicenceCheckResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/GetBureauLicensedSunsCheck', { showload: true })
      console.log('LicenceCheckResponse', LicenceCheckResponse)
      if (LicenceCheckResponse.overLimit) {
        this.$.w(LicenceCheckResponse.errorMessage)
      }
    },

    async onGetBureauLicenseCheck () {
      console.log('License Check Test')
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/GetBureauLicensedSunsCheck', { showload: true })
      console.log('LicenceCheckTest', response)
    },
    async onLicenseWriteTest () {
      console.log('License Write Test')
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/TestBureauSubmissionPostProcess', { showload: true })
      console.log('License Write Test', response)
    },
    async getBureauGroupData () {
      const getGroupsResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaugroups/getbureaugroups', { showload: true })
      this.groups = getGroupsResponse.data
      console.log('bureauGroups Getter', getGroupsResponse)
    },

    async onTestWorkflowGetBureauJobs () {
      var data = {
        GroupId: this.groupId
      }
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauworkflow/GetBureauJobs/' + this.paygateId + '/' + this.groupId, data, { showload: true })
      console.log(response)
    },
    onGroupSelect () {
      console.log('selected group id: ', this.groupId)
    },

    async onTestDeleteFiles () {
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/TestFileDeletion', { showload: true })
      console.log(response)
    },

    async onTestGetProcessingDate () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getprocessingdatefrompaymentdate', { showload: true })
      console.log(response)
    },
    async onTestGetPaymentDate () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getpaymentdatefromprocessingdate', { showload: true })
      console.log(response)
    },

    async onTestBureauSummaryGetter () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/TestSummaryGetter', { showload: true })
      console.log(response)
    },
    TestPillUPdate () {
      this.$store.dispatch('updateBureauSubmissionPill')
    }

  }
}

</script>
