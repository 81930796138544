<template>
  <loading class="main" v-bind:loading="true"></loading>
</template>
<script>

import Auth from '@/Assets/Components/Authentication/auth'

export default {
  name: 'authCallback',
  data: function () {
    return {
    }
  },
  async created () {
    await this.handleLogin()
  },
  methods: {
    async handleLogin () {
      try {
        var response = await Auth.manager.signinRedirectCallback()
        document.cookie = 'loginstatus=loggedin'
        await this.$store.dispatch('loadCustomersList')
        if (response.state) {
          this.$router.replace(response.state)
        } else {
          throw new Error('State not defined')
        }
      } catch {
        this.$router.push({ name: 'Dashboard' })
      }
    }
  }
}
</script>
