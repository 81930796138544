<template>
  <b-modal
    id="manage-block-modal"
    ref="manageBlockModal"
    title="Block Details"
    size="xl"
    hide-header-close
    static
    @ok="SaveBlockDetail"
    @shown="loadBlockDetails"
  >
    <div v-if="isNew" slot="modal-ok">Save</div>
    <div>
      <!-- Customer -->
      <b-row class="mt-2" v-if="isNew">
        <b-col sm="4" class="mt-2">
          <label for="customerName">Originating Customer</label>
        </b-col>
        <b-col sm="7">
          <model-list-select
            :list="bureauCustomers"
            v-model="$v.bureauCustomerId.$model"
            option-value="bureauCustomerId"
            option-text="customerName"
            placeholder="Select Customer or enter Service User Number"
            :disabled="readonly"
            @input="selectBureauCustomer()"
          ></model-list-select>
        </b-col>
      </b-row>

      <!-- Customer Name - Existing Block-->
      <b-row class="mt-2" v-if="isNew===false">
        <b-col sm="4" class="mt-2">
          <label for="customerName">Originating Customer</label>
        </b-col>
        <b-col sm="7">
          <b-form-input :disabled="true" type="text" v-model.trim="$v.customerName.$model"></b-form-input>
          <!-- Validation -->
          <span v-if="$v.serviceUserNumber.$dirty">
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.maxLength"
            >Service User Number must be less than {{$v.serviceUserNumber.$params.maxLength.max}} characters</label>
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.required"
            >Service User Number is required</label>
          </span>
        </b-col>
      </b-row>

      <!-- Service User Number -->
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label class="required" for="serviceUserNumber">Service User Number</label>
        </b-col>
        <b-col sm="3">
          <b-form-input
            type="text"
            v-model.trim="$v.serviceUserNumber.$model"
            :formatter="upperCase"
            :disabled="true"
          ></b-form-input>
          <!-- Validation -->
          <span v-if="$v.serviceUserNumber.$dirty">
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.maxLength"
            >Service User Number must be less than {{$v.serviceUserNumber.$params.maxLength.max}} characters</label>
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.serviceUserNumber.required"
            >Service User Number is required</label>
          </span>
        </b-col>
      </b-row>

      <!-- Sort Code -->
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label class="required" for="sortCode">Sort Code</label>
        </b-col>
        <b-col sm="2">
          <the-mask
            type="text"
            class="form-control"
            v-model.trim="$v.sortCode.$model"
            v-focus
            :mask="['##-##-##']"
            :guide="true"
            ref="sortCode"
            :disabled="true"
          ></the-mask>
          <!-- Validation -->
          <span v-if="$v.sortCode.$dirty">
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.sortCode.required"
            >Sort Code is required</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.sortCode.minLength"
            >Sort Code must be at least {{$v.sortCode.$params.minLength.min}} numbers</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.sortCode.maxLength"
            >Sort Code must be less than {{$v.sortCode.$params.maxLength.max}} numbers</small>
            <small
              class="form-text text-warning small text-nowrap"
              v-if="!sortCodeValid && $v.sortCode.required"
            >{{ sortCodeErrorMessage }}</small>
          </span>
        </b-col>
        <span>
          <b-col v-if="sortCodeValid">
            <i class="fas fa-check mt-2"></i>
          </b-col>
        </span>
      </b-row>

      <!-- Account Number -->
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label class="required" for="accountNumber">Account Number</label>
        </b-col>
        <b-col sm="2">
          <the-mask
            type="text"
            class="form-control"
            v-model.trim="$v.accountNumber.$model"
            :mask="['########']"
            :guide="false"
            ref="originatingAccountNumber"
            :disabled="true"
          ></the-mask>
          <!-- Validation -->
          <span v-if="$v.accountNumber.$dirty">
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.accountNumber.required"
            >Account Number is required</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.accountNumber.minLength"
            >Account Number must be at least {{$v.accountNumber.$params.minLength.min}} numbers</small>
            <small
              class="form-text text-danger small text-nowrap"
              v-if="!$v.accountNumber.maxLength"
            >Account Number must be less than {{$v.accountNumber.$params.maxLength.max}} numbers</small>
            <small
              class="form-text text-warning small text-nowrap"
              v-if="!accountNumberValid && $v.accountNumber.required"
            >{{ accountNumberErrorMessage }}</small>
          </span>
        </b-col>
        <span>
          <b-col v-if="accountNumberValid==true">
            <i class="fas fa-check mt-2"></i>
          </b-col>
        </span>
      </b-row>

      <!-- Contra Narrative -->
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label for="contraNarrative">Contra Narrative</label>
        </b-col>
        <b-col sm="4">
          <b-form-input
            type="text"
            v-model.trim="$v.contraNarrative.$model"
            :formatter="upperCase"
            :disabled="true"
          ></b-form-input>
          <!-- Validation -->
          <span v-if="$v.contraNarrative.$dirty">
            <label
              class="text-danger small text-nowrap"
              v-if="!$v.contraNarrative.maxLength"
            >Contra Narrative must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
          </span>
        </b-col>
      </b-row>

      <hr v-if="validationErrors.length > 0" />

      <!-- Block validation errors -->
      <b-row class="mt-2" v-if="validationErrors.length > 0">
        <b-col class="mt-2">
          <label>Block Validation Warnings and Errors</label>
        </b-col>
      </b-row>
      <b-row v-for="validationError in validationErrors" :key="validationError">
        <b-col sm="12">
          <span class="small">{{ validationError }}</span>
        </b-col>
      </b-row>

      <b-row hidden>
        <b-col>{{ $v.$invalid }}</b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import { ModelListSelect } from 'vue-search-select'
import Utility from '@/Assets/Mixins/Utility'
import bacsMixin from '@/Lib/BacsMixin.js'

export default {
  mixins: [Utility, bacsMixin],
  props: {
    itemId: String,
    submissionId: String,
    bureauJobId: String,
    // readonly: Boolean,
    isNew: Boolean

  },
  components: {
    ModelListSelect,
    TheMask
  },

  data () {
    return {
      bureauCustomers: [],
      disabledDates: {},
      sortCodeValid: false,
      accountNumberValid: false,
      validationErrors: [],
      fileNumber: 0,
      bureauCustomerId: '',
      serviceUserNumber: '',
      bankReference: '',
      sortCode: '',
      accountNumber: '',
      contraNarrative: '',
      sortCodeErrorMessage: '',
      accountNumberErrorMessage: '',
      readonly: true
    }
  },
  watch: {
    sortCode: function () {
      this.validate()
    },
    accountNumber: function () {
      this.validate()
    }
  },
  async created () {
    console.info('created manage block modal isnew', this.isNew)
  },
  methods: {
    async SaveBlockDetail () {
      if (this.isNew) {
        var data = {
          submissionId: this.submissionId,
          bureauCustomerId: this.bureauCustomerId,
          serviceUserNumber: this.serviceUserNumber,
          contraNarrative: this.contraNarrative,
          bankReference: this.bankReference,
          sortCode: this.sortCode,
          accountNumber: this.accountNumber,
          processingDate: ''
        }
        var responseAdd = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/addmanualblocktosubmission', data, { showload: true })

        if (responseAdd.data.toastType === 2) {
          this.$toastr.s(responseAdd.data.toastMessage)
        } else {
          this.$toastr.e(responseAdd.data.toastMessage)
        }
      } else {

      }
      this.$emit('submit')
    },
    async loadBlockDetails () {
      this.disabledDates = await this.setUpBACSDatePicker()
      if (this.isNew) {
        this.title = 'New Block'
        this.customerName = ''
        this.fileNumber = -1
        this.accountNumber = ''
        this.sortCode = ''
        this.serviceUserNumber = ''
        this.contraNarrative = ''
        try {
          console.info('Load customers in job', this.bureauJobId)
          var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/getbureaucustomerlistforjob/' + this.bureauJobId, { showload: true })
          console.info('Load customers in job', response.data)
          this.bureauCustomers = response.data
        } catch (error) {
          console.error('Error in ManageBlockDetails', error)
          console.error(error)
        }
      } else {
        var params = {
          itemId: this.itemId,
          submissionId: this.submissionId
        }
        var blockDetailsResponse = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getblockdetailinfo/', params, { showload: true })
        this.title = blockDetailsResponse.data.filename
        this.customerName = blockDetailsResponse.data.name
        this.fileNumber = blockDetailsResponse.data.fileNumber
        this.accountNumber = blockDetailsResponse.data.accountNumber
        this.sortCode = blockDetailsResponse.data.sortCode
        this.serviceUserNumber = blockDetailsResponse.data.serviceUserNumber
        this.contraNarrative = blockDetailsResponse.data.contraNarrative
        this.paymentDate = blockDetailsResponse.data.paymentDate
        this.processingDate = blockDetailsResponse.data.processingDateDisplay
        this.bureauCustomerId = blockDetailsResponse.data.bureauCustomerId
        // this.readonly = this.readonly
        // this.isNew = false
      }
    },

    async selectBureauCustomer () {
      console.info('SelectBureauCustomer')
      console.info(this.bureauCustomerId)
      const bureauCustomer = this.bureauCustomers.find(o => o.bureauCustomerId === this.bureauCustomerId)
      this.serviceUserNumber = bureauCustomer.serviceUserNumber
      const url = process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/' + bureauCustomer.bureauCustomerId
      const response = await axios.get(url, { showload: true })
      console.info(response)
      this.originatingBankReference = response.data.bankName
      this.sortCode = response.data.sortCode
      this.accountNumber = response.data.accountNumber
    },
    upperCase (value, event) {
      return value.toUpperCase()
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.sortCode }

      if (this.accountNumber.length === 8) {
        data.accountNumber = this.accountNumber
      }

      try {
        const validateUrl = process.env.VUE_APP_VALIDATE_API_URL + 'BankAccount'
        const response = await axios.get(validateUrl, { params: data }, { showload: true })

        this.validationResult = response.data

        this.sortCodeErrorMessage = ''
        this.accountNumberErrorMessage = ''
        if (this.validationResult.valid) {
          console.info('Sort code and account number are valid')
          this.sortCodeValid = true
          this.accountNumberValid = true
        } else {
          if (this.validationResult.errorCode === '1502') {
            console.info('Sort code is valid')
            this.sortCodeValid = true
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          } else {
            console.info('Sort code and account number are invalid')
            this.sortCodeValid = false
            this.sortCodeErrorMessage = this.validationResult.errorText
            this.accountNumberValid = false
            this.accountNumberErrorMessage = this.validationResult.errorText
          }
        }
        if (this.validationResult.valid || this.validationResult.errorCode === '1502') {
        }
      } catch (e) {
      }
    }, 800)
  },
  validations: {
    bureauCustomerId: '',
    bankReference: '',
    customerName: '',
    serviceUserNumber: { minLength: minLength(6), maxLength: maxLength(6), required },
    sortCode: { required, minLength: minLength(6), maxLength: maxLength(6) },
    accountNumber: { minLength: minLength(8), maxLength: maxLength(8), required },
    contraNarrative: { maxLength: maxLength(18) }

  }
}
</script>
