<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Team</h2>
      </div>
      <div class="idb-block-content">
        <div class="">
          <label for="description" class="">The Paygate Development Team are: </label>

        </div>
        <div class="form-group row">
          <div class="col-md-5">
            <img src="/img/sharedimages/team/ce9a5355.png" width="400px" class="" />
          </div>
          <div class="col-md-6">
            <ul>
            <li v-for="member in devTeam" :key="member.index">
              {{member}}
            </li>
            </ul>
          </div>
        </div>
      </div>
            <div class="idb-block-content">
        <div class="">
          <label for="description" class="">The Paygate Test Team are: </label>

        </div>
        <div class="form-group row">
          <div class="col-md-5">
            <img src="/img/sharedimages/team/qa.png" width="400px" class="" />
          </div>
          <div class="col-md-7">
            <ul>
            <li v-for="member in qaTeam" :key="member.index">
              {{member}}
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'about',
  data () {
    return {
      devTeam: [
        'Gary Vry',
        'Mark Newman',
        'Simon Thomas',
        'Jon-Paul McCarthy',
        'George Lee',
        'Dave Hood',
        'Michael Loughlin'
      ],
      qaTeam: [
        'Andrew Hyde',
        'Vikki Gillett'
      ]
    }
  },
  async created () {
    this.devTeam.sort(() => Math.random() - 0.5)
    this.qaTeam.sort(() => Math.random() - 0.5)
  }
}
</script>

<style scoped>
  ul {
    list-style-type: none;
  }
</style>
