<template>
  <div style="height:100%">
    <div class="d-flex justify-content-between mb-20">
      <h5 class="m-0">Bureau Submission History</h5>
    </div>

    <hr />

    <div style="height: 100%">
      <bar-chart :chart-data="chartData" :options="chartOptions"></bar-chart>
    </div>
  </div>
</template>

<script>
import BarChart from '@/Assets/Components/Charts/BarChart'

export default {
  name: 'BureauSubmissionHistoryWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        await this.load()
      },
      deep: true
    }
  },
  components: {
    BarChart
  },
  data () {
    return {
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            barPercentage: 0.5,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            gridLines: {
              offsetGridLines: true
            }
          }]
        }
      }
    }
  },
  methods: {
    async load () {
      this.chartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            label: '2018',
            backgroundColor: '#f87979',
            data: [40, 20, 10, 20, 30, 40, 50, 40, 60, 10, 40, 20]
          },
          {
            label: '2019',
            backgroundColor: 'blue',
            data: [20, 10, 20, 30, 40, 50, 40, 60, 10, 40, 20, 40]
          }
        ]
      }
    }
  },
  async mounted () {
    await this.load()
  }
}
</script>
