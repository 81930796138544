<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Unlock Submissions
              <span class="pull-right">
              </span>
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong>Customers</strong>
              </div>
              <div class="col-md-2">
                <b-select v-model.trim="selectedCustomerType" :options="customerTypes" @change="getLockedSubmissions()" />
              </div>
            </div>
            <div v-show="searchComplete === true" class="row form-group">
              <div class="col-md-12" style="overflow-x: auto; white-space: nowrap;">
                <vue-good-table
                  ref="table"
                  :columns="columns"
                  :rows="rows"
                  :lineNumbers="false"
                  :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: rowsPerPage,
                    dropdownAllowAll: false,
                    setCurrentPage: currentPage
                  }"
                  styleClass="vgt-table striped bordered"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'unlockButton'">
                      <button class="btn btn-primary" @click="onRowClick(props.row.id)">Unlock</button>
                    </span>
                    <span style="vertical-align: middle; line-height: 40px;" v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                  <div slot="table-actions">
                    <b-button
                      @click.prevent="refreshGrid"
                      class
                      variant="link"
                      v-b-popover.hover.top.d500="'Refresh unlocked submissions'"
                    >
                      <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                    </b-button>
                  </div>
                </vue-good-table>
              </div>
            </div>
          </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['selectedCustomer'])
  },

  data () {
    return {
      customerTypes: {},
      selectedCustomerType: 0,
      searchComplete: false,
      totalRecords: 0,
      currentPage: 1,
      rowsPerPage: 10,
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true
        },
        {
          label: 'PaygateId',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'Customer',
          field: 'customerName'
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Type',
          field: 'networkName'
        },
        {
          label: 'Time Locked',
          field: 'taskCreatedAt'
        },
        {
          label: '',
          field: 'unlockButton'
        }
      ],
      rows: []
    }
  },

  methods: {
    async getLockedSubmissions () {
      this.searchComplete = false
      var selectedCustomerOnly = this.selectedCustomerType === 1
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getLockedSubmissions?selectedCustomerOnly=${selectedCustomerOnly}`, { showload: true })
      this.searchComplete = true
      this.rows = response.data
      this.totalRecords = response.data.length
    },

    async onRowClick (id) {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/unlockSubmission?submissionid=${id}`, { showload: true })
      if (response.data.status === 'Deleted') {
        this.$toastr.s('Submission has been unlocked')
      } else {
        this.snapbar.e(response.data.errorText)
      }
      await this.getLockedSubmissions()
    },

    async refreshGrid () {
      await this.getLockedSubmissions()
    }
  },

  watch: {
    selectedCustomer () {
      this.getLockedSubmissions()
    }
  },

  async mounted () {
    var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/search/getDropdownValues`)
    this.customerTypes = response.data.customerTypes

    await this.getLockedSubmissions()
  }
}
</script>
