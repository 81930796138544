<template>
  <div class="col-md-8">
    <div
      class="alert alert-info"
    >If a "Greater than" 0 range is not set, this will automatically be applied and will require 1 approver</div>
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th>Greater than (or equal to)</th>
          <th>Number of Approvers</th>
          <th>
            <button id="add-range-button" type="button" class="btn btn-success" @click="addRange">Add New Range</button>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(range,index) in ranges.$each.$iter" :key="index">
          <td :class="{invalid: range.greaterThan.$error}">
            <input :id="'greater-than-'+ index"
              type="number"
              step="0.01"
              class="form-control"
              v-model.number="range.greaterThan.$model"
            />
            <validation-messages v-model="range.greaterThan" name="number">
              <small
                v-if="!range.greaterThan.valid"
                class="form-text small validation-messages"
              >You cannot have duplicate greater than values</small>
            </validation-messages>
          </td>
          <td :class="{invalid: range.numberOfApprovers.$error}">
            <input :id="'number-of-approvers-'+ index"
              type="number"
              min="1"
              class="form-control"
              v-model.number="range.numberOfApprovers.$model"
            />
            <validation-messages v-model="range.numberOfApprovers" name="number"></validation-messages>
          </td>
          <td>
            <button :id="'remove-range-button-'+index" type="button" class="btn btn-danger" @click="removeRange(index)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  props: ['ranges'],
  data () {
    return {
    }
  },
  methods: {
    addRange () {
      this.ranges.$model.push({ greaterThan: 0, numberOfApprovers: 1, groupId: '00000000-0000-0000-0000-000000000000' })
      this.$nextTick(() => {
        this.ranges.$each[this.ranges.$model.length - 1].$reset()
      })
    },
    removeRange (index) {
      this.ranges.$model.splice(index, 1)
    }
  }
}

</script>

<style>
</style>
