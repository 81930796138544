var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.wrapStyleClasses }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "vgt-loading vgt-center-align" },
          [
            _vm._t("loadingContent", [
              _c("span", { staticClass: "vgt-loading__content" }, [
                _vm._v("Loading...")
              ])
            ])
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "vgt-inner-wrap", class: { "is-loading": _vm.isLoading } },
      [
        _vm.paginate && _vm.paginateOnTop
          ? _vm._t(
              "pagination-top",
              [
                _c("vgt-pagination", {
                  ref: "paginationTop",
                  attrs: {
                    perPage: _vm.perPage,
                    rtl: _vm.rtl,
                    total: _vm.totalRows || _vm.totalRowCount,
                    mode: _vm.paginationMode,
                    jumpFirstOrLast: _vm.paginationOptions.jumpFirstOrLast,
                    firstText: _vm.firstText,
                    lastText: _vm.lastText,
                    nextText: _vm.nextText,
                    prevText: _vm.prevText,
                    rowsPerPageText: _vm.rowsPerPageText,
                    perPageDropdownEnabled:
                      _vm.paginationOptions.perPageDropdownEnabled,
                    customRowsPerPageDropdown: _vm.customRowsPerPageDropdown,
                    paginateDropdownAllowAll: _vm.paginateDropdownAllowAll,
                    ofText: _vm.ofText,
                    pageText: _vm.pageText,
                    allText: _vm.allText,
                    "info-fn": _vm.paginationInfoFn
                  },
                  on: {
                    "page-changed": _vm.pageChanged,
                    "per-page-changed": _vm.perPageChanged
                  }
                })
              ],
              {
                pageChanged: _vm.pageChanged,
                perPageChanged: _vm.perPageChanged,
                total: _vm.totalRows || _vm.totalRowCount
              }
            )
          : _vm._e(),
        _c(
          "vgt-global-search",
          {
            attrs: {
              "search-enabled":
                _vm.searchEnabled && _vm.externalSearchQuery == null,
              "global-search-placeholder": _vm.searchPlaceholder
            },
            on: {
              "on-keyup": _vm.searchTableOnKeyUp,
              "on-enter": _vm.searchTableOnEnter
            },
            model: {
              value: _vm.globalSearchTerm,
              callback: function($$v) {
                _vm.globalSearchTerm = $$v
              },
              expression: "globalSearchTerm"
            }
          },
          [
            _c(
              "template",
              { slot: "internal-table-actions" },
              [_vm._t("table-actions")],
              2
            )
          ],
          2
        ),
        _vm.selectedRowCount && !_vm.disableSelectInfo
          ? _c(
              "div",
              {
                staticClass: "vgt-selection-info-row clearfix",
                class: _vm.selectionInfoClass
              },
              [
                _vm._v(" " + _vm._s(_vm.selectionInfo) + " "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.unselectAllInternal(true)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.clearSelectionText))]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vgt-selection-info-row__actions vgt-pull-right"
                  },
                  [_vm._t("selected-row-actions")],
                  2
                )
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "vgt-fixed-header" }, [
          _vm.fixedHeader
            ? _c(
                "table",
                { class: _vm.tableStyleClasses, attrs: { id: "vgt-table" } },
                [
                  _c(
                    "colgroup",
                    _vm._l(_vm.columns, function(column, index) {
                      return _c("col", {
                        key: index,
                        attrs: { id: "col-" + index }
                      })
                    }),
                    0
                  ),
                  _c("vgt-table-header", {
                    ref: "table-header-secondary",
                    tag: "thead",
                    attrs: {
                      columns: _vm.columns,
                      "line-numbers": _vm.lineNumbers,
                      selectable: _vm.selectable,
                      "all-selected": _vm.allSelected,
                      "all-selected-indeterminate":
                        _vm.allSelectedIndeterminate,
                      mode: _vm.mode,
                      sortable: _vm.sortable,
                      "multiple-column-sort": _vm.multipleColumnSort,
                      "typed-columns": _vm.typedColumns,
                      getClasses: _vm.getClasses,
                      searchEnabled: _vm.searchEnabled,
                      paginated: _vm.paginated,
                      "table-ref": _vm.$refs.table
                    },
                    on: {
                      "on-toggle-select-all": _vm.toggleSelectAll,
                      "on-sort-change": _vm.changeSort,
                      "filter-changed": _vm.filterRows
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "table-column",
                          fn: function(props) {
                            return [
                              _vm._t(
                                "table-column",
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(props.column.label))
                                  ])
                                ],
                                { column: props.column }
                              )
                            ]
                          }
                        },
                        {
                          key: "column-filter",
                          fn: function(props) {
                            return [
                              _vm._t("column-filter", null, {
                                column: props.column,
                                updateFilters: props.updateFilters
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _c(
          "div",
          {
            class: { "vgt-responsive": _vm.responsive },
            style: _vm.wrapperStyles
          },
          [
            _c(
              "table",
              {
                ref: "table",
                class: _vm.tableStyles,
                attrs: { id: "vgt-table" }
              },
              [
                _c(
                  "colgroup",
                  _vm._l(_vm.columns, function(column, index) {
                    return _c("col", {
                      key: index,
                      attrs: { id: "col-" + index }
                    })
                  }),
                  0
                ),
                _c("vgt-table-header", {
                  ref: "table-header-primary",
                  tag: "thead",
                  attrs: {
                    columns: _vm.columns,
                    "line-numbers": _vm.lineNumbers,
                    selectable: _vm.selectable,
                    "all-selected": _vm.allSelected,
                    "all-selected-indeterminate": _vm.allSelectedIndeterminate,
                    mode: _vm.mode,
                    sortable: _vm.sortable,
                    "multiple-column-sort": _vm.multipleColumnSort,
                    "typed-columns": _vm.typedColumns,
                    getClasses: _vm.getClasses,
                    searchEnabled: _vm.searchEnabled
                  },
                  on: {
                    "on-toggle-select-all": _vm.toggleSelectAll,
                    "on-sort-change": _vm.changeSort,
                    "filter-changed": _vm.filterRows
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "table-column",
                        fn: function(props) {
                          return [
                            _vm._t(
                              "table-column",
                              [
                                _c("span", [_vm._v(_vm._s(props.column.label))])
                              ],
                              { column: props.column }
                            )
                          ]
                        }
                      },
                      {
                        key: "column-filter",
                        fn: function(props) {
                          return [
                            _vm._t("column-filter", null, {
                              column: props.column,
                              updateFilters: props.updateFilters
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._l(_vm.paginated, function(headerRow, hIndex) {
                  return _c(
                    "tbody",
                    { key: hIndex },
                    [
                      _vm.groupHeaderOnTop
                        ? _c("vgt-header-row", {
                            class: _vm.getRowStyleClass(headerRow),
                            attrs: {
                              "header-row": headerRow,
                              columns: _vm.columns,
                              "line-numbers": _vm.lineNumbers,
                              selectable: _vm.selectable,
                              "select-all-by-group": _vm.selectAllByGroup,
                              collapsable: _vm.groupOptions.collapsable,
                              "collect-formatted": _vm.collectFormatted,
                              "formatted-row": _vm.formattedRow,
                              "get-classes": _vm.getClasses,
                              "full-colspan": _vm.fullColspan,
                              groupIndex: hIndex
                            },
                            on: {
                              vgtExpand: function($event) {
                                return _vm.toggleExpand(
                                  headerRow[_vm.rowKeyField]
                                )
                              },
                              "on-select-group-change": function($event) {
                                return _vm.toggleSelectGroup($event, headerRow)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "table-header-row",
                                  fn: function(props) {
                                    return _vm.hasHeaderRowTemplate
                                      ? [
                                          _vm._t("table-header-row", null, {
                                            column: props.column,
                                            formattedRow: props.formattedRow,
                                            row: props.row
                                          })
                                        ]
                                      : undefined
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e(),
                      _vm._l(headerRow.children, function(row, index) {
                        return (_vm.groupOptions.collapsable
                        ? headerRow.vgtIsExpanded
                        : true)
                          ? _c(
                              "tr",
                              {
                                key: row.originalIndex,
                                class: _vm.getRowStyleClass(row),
                                on: {
                                  mouseenter: function($event) {
                                    return _vm.onMouseenter(row, index)
                                  },
                                  mouseleave: function($event) {
                                    return _vm.onMouseleave(row, index)
                                  },
                                  dblclick: function($event) {
                                    return _vm.onRowDoubleClicked(
                                      row,
                                      index,
                                      $event
                                    )
                                  },
                                  click: function($event) {
                                    return _vm.onRowClicked(row, index, $event)
                                  },
                                  auxclick: function($event) {
                                    return _vm.onRowAuxClicked(
                                      row,
                                      index,
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _vm.lineNumbers
                                  ? _c("th", { staticClass: "line-numbers" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getCurrentIndex(row.originalIndex)
                                        )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.selectable
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "vgt-checkbox-col",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.onCheckboxClicked(
                                              row,
                                              index,
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "sr-only",
                                            attrs: {
                                              for:
                                                _vm.id +
                                                "_" +
                                                index +
                                                "_selectRow"
                                            }
                                          },
                                          [_vm._v("Select")]
                                        ),
                                        _c("input", {
                                          attrs: {
                                            id:
                                              _vm.id +
                                              "_" +
                                              index +
                                              "_selectRow",
                                            type: "checkbox",
                                            disabled: row.vgtDisabled
                                          },
                                          domProps: { checked: row.vgtSelected }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.columns, function(column, i) {
                                  return !column.hidden && column.field
                                    ? _c(
                                        "td",
                                        {
                                          key: i,
                                          class: _vm.getClasses(i, "td", row),
                                          attrs: {
                                            "data-label": _vm.compactMode
                                              ? column.label
                                              : undefined
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onCellClicked(
                                                row,
                                                column,
                                                index,
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._t(
                                            "table-row",
                                            [
                                              !column.html
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.collectFormatted(
                                                          row,
                                                          column
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.collect(
                                                          row,
                                                          column.field
                                                        )
                                                      )
                                                    }
                                                  })
                                            ],
                                            {
                                              row: row,
                                              column: column,
                                              formattedRow: _vm.formattedRow(
                                                row
                                              ),
                                              index: index
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      }),
                      _vm.groupHeaderOnBottom
                        ? _c("vgt-header-row", {
                            attrs: {
                              "header-row": headerRow,
                              columns: _vm.columns,
                              "line-numbers": _vm.lineNumbers,
                              selectable: _vm.selectable,
                              "select-all-by-group": _vm.selectAllByGroup,
                              "collect-formatted": _vm.collectFormatted,
                              "formatted-row": _vm.formattedRow,
                              "get-classes": _vm.getClasses,
                              "full-colspan": _vm.fullColspan,
                              groupIndex: _vm.index
                            },
                            on: {
                              "on-select-group-change": function($event) {
                                return _vm.toggleSelectGroup($event, headerRow)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "table-header-row",
                                  fn: function(props) {
                                    return _vm.hasHeaderRowTemplate
                                      ? [
                                          _vm._t("table-header-row", null, {
                                            column: props.column,
                                            formattedRow: props.formattedRow,
                                            row: props.row
                                          })
                                        ]
                                      : undefined
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  )
                }),
                _vm.showEmptySlot
                  ? _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: _vm.fullColspan } },
                          [
                            _vm._t("emptystate", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vgt-center-align vgt-text-disabled"
                                },
                                [_vm._v("No data for table")]
                              )
                            ])
                          ],
                          2
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ]
        ),
        _vm.hasFooterSlot
          ? _c(
              "div",
              { staticClass: "vgt-wrap__actions-footer" },
              [_vm._t("table-actions-bottom")],
              2
            )
          : _vm._e(),
        _vm.paginate && _vm.paginateOnBottom
          ? _vm._t(
              "pagination-bottom",
              [
                _c("vgt-pagination", {
                  ref: "paginationBottom",
                  attrs: {
                    perPage: _vm.perPage,
                    rtl: _vm.rtl,
                    total: _vm.totalRows || _vm.totalRowCount,
                    mode: _vm.paginationMode,
                    jumpFirstOrLast: _vm.paginationOptions.jumpFirstOrLast,
                    firstText: _vm.firstText,
                    lastText: _vm.lastText,
                    nextText: _vm.nextText,
                    prevText: _vm.prevText,
                    rowsPerPageText: _vm.rowsPerPageText,
                    perPageDropdownEnabled:
                      _vm.paginationOptions.perPageDropdownEnabled,
                    customRowsPerPageDropdown: _vm.customRowsPerPageDropdown,
                    paginateDropdownAllowAll: _vm.paginateDropdownAllowAll,
                    ofText: _vm.ofText,
                    pageText: _vm.pageText,
                    allText: _vm.allText,
                    "info-fn": _vm.paginationInfoFn
                  },
                  on: {
                    "page-changed": _vm.pageChanged,
                    "per-page-changed": _vm.perPageChanged
                  }
                })
              ],
              {
                pageChanged: _vm.pageChanged,
                perPageChanged: _vm.perPageChanged,
                total: _vm.totalRows || _vm.totalRowCount
              }
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }