<template>
  <div>
    <b-form-group row description="Double click or drag to allocate">
      <b-row :class="{disabled:disabled}">
        <b-list-group class="col-5">
          <b-list-group-item variant="secondary">Available Roles</b-list-group-item>
          <draggable
            :list="availableRoles"
            class="dragArea"
            style="min-height=50"
            @change="changed"
            group="roles"
            :disabled="disabled"
          >
            <b-list-group-item
              v-for="(role, index) in availableRoles"
              class="pointer"
              :key="role.id"
              @dblclick="toggle(availableRoles, allocatedRoles, index)"
            >{{ role.description }}</b-list-group-item>
          </draggable>
        </b-list-group>
        <div class="col-1 text-center"></div>
        <b-list-group class="col-5">
          <b-list-group-item variant="secondary">Allocated Roles</b-list-group-item>
          <draggable
            :list="allocatedRoles"
            class="dragArea"
            style="min-height=50"
            @change="changed"
            group="roles"
            :disabled="disabled"
          >
            <b-list-group-item
              v-for="(role, index) in allocatedRoles"
              class="pointer"
              :key="role.id"
              @dblclick="toggle(allocatedRoles, availableRoles,index)"
            >{{ role.description }}</b-list-group-item>
          </draggable>
        </b-list-group>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  computed: {
    allocatedRoles: {
      get () {
        return this.userRoles.filter(r => r.module === this.roleType)
      }
    },
    availableRoles: {
      get () {
        this.$emit('rolesChanged', this.roleType, this.allocatedRoles)
        return this.roles.filter(r => !this.allocatedRoles.some(ar => ar.name === r.name))
      }
    }
  },
  props: {
    roleType: { type: String },
    userRoles: { type: Array },
    disabled: { type: Boolean, default: false },
    roles: { type: Array }
  },
  components: {
    draggable
  },
  methods: {
    changed () {
      this.$forceUpdate()
      this.$emit('rolesChanged', this.roleType, this.allocatedRoles)
    },
    // User double clicked item to move
    toggle (source, target, index) {
      if (this.disabled) {
        return
      }
      target.push(source[index])
      source.splice(index, 1)
      this.changed()
    }
  }
}
</script>

<style lang="scss" scoped>
.dragArea {
  min-height: 200px;
}

.pointer:hover {
  cursor: pointer;
}

.disabled {
  .pointer:hover {
    cursor: not-allowed;
  }
}
</style>
