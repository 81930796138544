var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { row: "", description: "Double click or drag to allocate" } },
        [
          _c(
            "b-row",
            { class: { disabled: _vm.disabled } },
            [
              _c(
                "b-list-group",
                { staticClass: "col-5" },
                [
                  _c("b-list-group-item", { attrs: { variant: "secondary" } }, [
                    _vm._v("Available Roles")
                  ]),
                  _c(
                    "draggable",
                    {
                      staticClass: "dragArea",
                      staticStyle: {},
                      attrs: {
                        list: _vm.availableRoles,
                        group: "roles",
                        disabled: _vm.disabled
                      },
                      on: { change: _vm.changed }
                    },
                    _vm._l(_vm.availableRoles, function(role, index) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: role.id,
                          staticClass: "pointer",
                          on: {
                            dblclick: function($event) {
                              return _vm.toggle(
                                _vm.availableRoles,
                                _vm.allocatedRoles,
                                index
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(role.description))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "col-1 text-center" }),
              _c(
                "b-list-group",
                { staticClass: "col-5" },
                [
                  _c("b-list-group-item", { attrs: { variant: "secondary" } }, [
                    _vm._v("Allocated Roles")
                  ]),
                  _c(
                    "draggable",
                    {
                      staticClass: "dragArea",
                      staticStyle: {},
                      attrs: {
                        list: _vm.allocatedRoles,
                        group: "roles",
                        disabled: _vm.disabled
                      },
                      on: { change: _vm.changed }
                    },
                    _vm._l(_vm.allocatedRoles, function(role, index) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: role.id,
                          staticClass: "pointer",
                          on: {
                            dblclick: function($event) {
                              return _vm.toggle(
                                _vm.allocatedRoles,
                                _vm.availableRoles,
                                index
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(role.description))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }