<template>
  <div>
    <b-form-row>
      <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Title">
        <b-form-select
          class="form-control"
          :options="titles"
          value-field="ordinal"
          text-field="name"
          v-model="title"
          :disabled="!editable"
        />
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-6 mr-5"
        label-class="required"
        horizontal
        label="First Name"
      >
        <b-form-input
          type="text"
          class="form-control"
          required
          v-model="firstName"
          :disabled="!editable"
        />
        <b-col v-if="$v.firstName.$dirty">
          <label class="text-danger small" v-if="$v.firstName.$invalid">First Name required</label>
        </b-col>
      </b-form-group>
      <b-form-group
        :label-cols="5"
        class="col-sm-6"
        label-class="required"
        horizontal
        label="Surname"
      >
        <b-form-input type="text" class="form-control" v-model="surname" :disabled="!editable" />
        <b-col v-if="$v.surname.$dirty">
          <label class="text-danger small" v-if="$v.surname.$invalid">Surname required</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group :label-cols="5" class="col-sm-6 mr-5" horizontal label="Telephone Number 1">
        <b-form-input type="text" class="form-control" v-model="tel1" :disabled="!editable" />
        <b-col v-if="$v.tel1.$invalid">
          <label class="text-danger small">Valid telephone number required</label>
        </b-col>
      </b-form-group>
      <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Telephone Number 2">
        <b-form-input type="text" class="form-control" v-model="tel2" :disabled="!editable" />
        <b-col v-if="$v.tel2.$invalid">
          <label class="text-danger small">Valid telephone number required</label>
        </b-col>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-6 mr-5"
        :label-class="messageChannel === 0 ? 'required' : ''"
        horizontal
        label="Email Address"
      >
        <b-form-input
          type="text"
          class="form-control"
          @input="checkEmail($v.email)"
          v-model="email"
          :disabled="!editable"
          @keydown="existingEmailUsers = false"
        />
        <b-col v-if="$v.email.$dirty">
          <label
            class="text-danger small"
            v-if="!$v.email.emailValidator && messageChannel === 0"
          >Provide a valid Email Address</label>
          <label
            class="text-danger small"
            v-if="!$v.email.emailRequired && messageChannel === 0"
          >Email Address required</label>
        </b-col>
        <b-col v-if="existingEmailUsers">
          <label class="text-warning small">
            Users with this email are already in this group. Is this correct?
            <a
              class="dupeLink"
              v-b-modal="pageInstanceId"
            >View duplicates.</a>
          </label>
        </b-col>
        <b-col v-if="shareCount > 0 && !existingEmailUsers && hasPortalAccount">
          <label class="text-warning small">
            This email address is shared with {{shareCount}} payers. The updated email address will be reflected across all the duplicates.
            <a
              class="dupeLink"
              v-b-modal="pageInstanceId"
            >View duplicates.</a>
          </label>
        </b-col>
      </b-form-group>
      <b-form-group
        :label-cols="5"
        class="col-sm-6"
        :label-class="messageChannel === 2 ? 'required' : ''"
        horizontal
        label="Mobile Number"
      >
        <b-form-input type="text" class="form-control" v-model="mobile" :disabled="!editable" />
        <b-col v-if="false">
          <label class="small" v-if="smsVerifiedAndUnchanged">
            <i class="fa fa-lock" style="color: green;"></i>
            &nbsp;
            <span>Mobile verified.</span>
          </label>
          <label class="small" v-else>
            <i class="fa fa-unlock"></i>
            &nbsp;
            <span>Mobile unverified.</span>
          </label>
        </b-col>
        <b-col v-if="$v.mobile.$invalid">
          <label class="text-danger small">Mobile number required</label>
        </b-col>
      </b-form-group>&nbsp; &nbsp;
      <div class="form-row" v-if="false">
        <b-btn
          class="form-control"
          variant="info"
          :disabled="$v.mobile.$model === '' || $v.mobile.$model === null || $v.mobile.$model === undefined || $v.mobile.$invalid || !editable || smsVerifiedAndUnchanged || isLoading"
          @click="sendMobileVerification"
        >
          <span v-if="verificationSmsSent">Re-send SMS Verification</span>
          <span v-else>Send SMS Verification</span>
        </b-btn>
      </div>
    </b-form-row>
    <transition name="fade">
      <b-form-row v-if="verificationSmsSent">
        <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Verification Token">
          <b-form-input
            type="text"
            class="form-control"
            v-model="verifyToken"
            :disabled="!verificationSmsSent"
          />
        </b-form-group>&nbsp; &nbsp;
        <div class="form-row">
          <b-btn
            class="form-control"
            variant="primary"
            :disabled="!verificationSmsSent || isLoading"
            @click="checkMobileVerification"
          >Verify</b-btn>
        </div>
      </b-form-row>
    </transition>
    <transition name="fade">
      <div v-if="addressesSet || !!this.address1">
        <b-form-row>
          <b-form-group
            :label-cols="5"
            class="col-sm-6"
            :label-class="messageChannel === 1 ? 'required' : ''"
            horizontal
            label="Address"
          >
            <b-form-input
              type="text"
              class="form-control"
              required
              v-model="address1"
              :disabled="!editable"
            />
            <b-col v-if="$v.address1.$dirty && $v.address1.letterRequired">
              <label class="text-danger small" v-if="$v.address1.$invalid">Address required</label>
            </b-col>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Address line 2">
            <b-form-input
              type="text"
              class="form-control"
              v-model="address2"
              :disabled="!editable"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Address line 3">
            <b-form-input
              type="text"
              class="form-control"
              v-model="address3"
              :disabled="!editable"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Address line 4">
            <b-form-input
              type="text"
              class="form-control"
              v-model="address4"
              :disabled="!editable"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-6" horizontal label="Town">
            <b-form-input type="text" class="form-control" v-model="town" :disabled="!editable" />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :label-cols="5" class="col-sm-6" horizontal label="County">
            <b-form-input type="text" class="form-control" v-model="county" :disabled="!editable" />
          </b-form-group>
        </b-form-row>
      </div>
    </transition>
    <b-form-row>
      <b-form-group
        :label-cols="5"
        class="col-sm-6"
        :label-class="messageChannel === 1 ? 'required' : ''"
        horizontal
        label="Postcode"
      >
        <b-form-input
          type="text"
          required
          class="form-control"
          @input="addressOptions = []"
          v-model="postcode"
          :disabled="!editable"
        />
        <b-col v-if="!$v.postcode.letterRequired">
          <label class="text-danger small">Postcode required</label>
        </b-col>
      </b-form-group>&nbsp; &nbsp;
      <div class="form-row">
        <b-btn
          class="form-control"
          variant="info"
          :disabled="$v.postcode.$model === '' || $v.postcode.$model === null || $v.postcode.$model === undefined || !editable || isLoading"
          @click="getAddress"
        >Lookup Address</b-btn>
      </div>
    </b-form-row>
    <transition name="fade">
      <b-form-row
        class="mt-2"
        v-if="addressOptionsSelect.length > 0 && !this.addressLookupInProgress"
      >
        <b-form-group label-cols="5" class="col-sm-6" horizontal label="Addresses">
          <b-form-select
            @change="addressesSet = true"
            v-model="selectedAddress"
            :options="addressOptionsSelect"
            :disabled="!editable"
          ></b-form-select>
        </b-form-group>
      </b-form-row>
    </transition>
    <b-modal :id="pageInstanceId" :title="'Duplicate Emails for '+$v.email.$model" :ok-only="true">
      <p>Below is a list of Payers which share the address: {{ $v.email.$model }}, clicking on a row will navigate to the selected payer.</p>
      <table style="width:100%">
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Reference</th>
        </tr>
        <tr style="cursor:pointer" v-for="(payer, index) in duplicateEmails" :key="index">
          <td>{{payer.name}}</td>
          <td>{{statusToText(payer.status)}}</td>
          <td>{{payer.reference}}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>
<script>
import { numeric, required, helpers } from 'vuelidate/lib/validators'
import Enum from '@/Enums/Collections'
import _ from 'lodash'
import axios from 'axios'
import uuidv4 from 'uuid/v4'
import loading from '@/Assets/Mixins/LoadingMixin'
import { email } from '@/Assets/Validators'

const emailRequired = function (value) {
  if (this.messageChannel === 0) {
    return helpers.req(value)
  }
  return true
}

const letterRequired = function (value) {
  if (this.messageChannel === 1) {
    return helpers.req(value)
  }
  return true
}

const emailValidator = function (value) {
  if (this.messageChannel === 1) {
    return email(value)
  } else {
    // value is optional, but if it's there it must be email
    if (value) {
      return email(value)
    } else {
      return true
    }
  }
}
const phoneRegex = /^[\d +()]*$/
const phoneValidator = (value) => value ? phoneRegex.test(value) : true
const smsValidator = function (value) {
  if (this.messageChannel === 2) {
    return helpers.req(value) && phoneValidator(value)
  } else {
    return phoneValidator(value)
  }
}

export default {
  mixins: [loading],
  props: {
    open: Boolean
  },
  data () {
    return {
      titles: Enum.Payer.Titles.enumValues,
      existingEmailUsers: false,
      addressLookupInProgress: false,
      addressOptions: [],
      addressesSet: false,
      duplicateEmails: [],
      pageInstanceId: null,
      canSendSmsVerify: true,
      verificationSmsSent: false,
      verifyToken: null,
      shareCount: 0
    }
  },
  methods: {
    dirtyTouch (callingModel) {
      callingModel.$touch()
    },
    sendMobileVerification: _.debounce(async function () {
      try {
        const url = `${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/sendverifysms?mobile=${this.$v.mobile.$model}`
        await axios.post(url, {}, { showload: true })
        this.verificationSmsSent = true
        this.$toastr.s('Payer will receive a 9 digit code to read back to you. It is valid for 10 minutes.', 'SMS Verification Sent')
      } catch (e) {
        this.$toastr.e('Failed to send Mobile Verification')
      }
    }, 1000),
    checkMobileVerification: _.debounce(async function () {
      try {
        if (this.verifyToken) {
          const url = `${process.env.VUE_APP_DDMS_API_URL}payer/${this.ukPayerId}/verifysms?token=${this.verifyToken}`
          const { data: { verified, mobileNumber } } = await axios.post(url, {}, { showload: true })
          this.smsVerified = verified
          this.verifiedMobileNumber = mobileNumber
          if (verified) {
            this.mobile = mobileNumber
          }
          if (verified) {
            this.$toastr.s('Verification successful.', 'Payer Verified Successfully')
            this.verificationSmsSent = false
          } else {
            this.$toastr.w('Verification token not valid. Please retry.', 'Payer Unverified')
          }
        }
      } catch (e) {
        console.error(e)
        this.$toastr.e('Failed to contact server.', 'Mobile Verification Failed')
      }
    }, 1000),
    checkEmail: _.debounce(async function (emailValidator) {
      if (!emailValidator.$invalid) {
        const email = emailValidator.$model
        var totalCount = await this.getDupeEmailCount(email)
        if (totalCount > 0) {
          this.existingEmailUsers = true
          this.showEmailDuplicates()
        }
      }
    }, 300),
    async getDupeEmailCount (email) {
      const url = `${process.env.VUE_APP_DDMS_API_URL}payer/checkexistingemail`
      const groupId = this.$store.state.payer.currentPayer.groupId
      if (groupId !== undefined && groupId !== null && groupId !== 0) {
        const response = await axios.post(url, { email, groupId }, { showload: true })
        return response.data.totalCount
      } else {
        return 0
      }
    },
    async getAddress () {
      if (this.$v.postcode.$invalid) {
        console.log('Postcode Invalid, aborting address lookup.')
        return
      }
      this.addressLookupInProgress = true
      var data = { postcode: this.postcode }
      try {
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}addresslookup`, data, { showload: true })
        if (response.data.addresses.length === 1) {
          this.selectedAddress = response.data.addresses[0]
          this.lat = response.data.latitude
          this.lon = response.data.longitude
          this.addressesSet = true
        } else {
          this.addressOptions = response.data.addresses
        }
      } catch (e) {
        console.error(e)
        try {
          await this.$swal({
            title: 'Postcode not found',
            text: 'Sorry we could not locate an address using the information you provided, do you want to enter the address manually?',
            type: 'question',
            showCancelButton: true,
            cancelButtonText: 'No',
            showConfirmButton: true,
            confirmButtonText: 'Yes'
          })
          this.postcode = ''
        } catch (e) {
          console.error(e)
        }
      } finally {
        this.addressLookupInProgress = false
      }
    },
    async showEmailDuplicates () {
      var getDupes = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}${this.groupId}/payers/?searchFilter=email:${this.$v.email.$model}&perPage=100&page=1`, { showload: true })
      this.duplicateEmails = getDupes.data.data
    },
    statusToText (x) {
      switch (x) {
        case -1:
          return 'Any'
        case 0:
          return 'Closed'
        case 1:
          return 'Alert'
        case 2:
          return 'Suspended'
        case 3:
          return 'Operational'
        case 4:
          return 'Incomplete'
        default:
          return 'Unknown'
      }
    },
    goToPayer (id) {
      this.$router.push(`/collections/payer/${id}/edit`)
    },
    buildValidationMessage () {
      var fields = []
      if (this.$v.title.$invalid) {
        fields.push('Title')
      }
      if (this.$v.firstName.$invalid) {
        fields.push('First Name')
      }
      if (this.$v.surname.$invalid) {
        fields.push('Surname')
      }
      if (this.$v.tel1.$invalid) {
        fields.push('Telephone 1')
      }
      if (this.$v.tel2.$invalid) {
        fields.push('Telephone 2')
      }
      if (this.$v.mobile.$invalid) {
        fields.push('Mobile Number')
      }
      if (this.$v.address1.$invalid) {
        fields.push('Address Line 1')
      }
      if (this.$v.postcode.$invalid) {
        fields.push('Postcode')
      }
      if (this.$v.email.$invalid) {
        fields.push('Email Address')
      }
      var message = fields.length > 0 ? `Invalid Personal Details: ${fields.join(', ')}` : ''
      this.$store.commit('setDetailsValidationMessage', message)
    }
  },
  components: {},
  validations: {
    title: { numeric },
    firstName: { required },
    surname: { required },
    tel1: { phoneValidator },
    tel2: { phoneValidator },
    mobile: { smsValidator },
    address1: { letterRequired },
    address2: {},
    address3: {},
    address4: {},
    town: {},
    county: {},
    postcode: { letterRequired },
    email: { emailRequired, emailValidator }
  },
  computed: {
    editable () {
      return this.$store.state.payer.currentPayer.editable
    },
    addressOptionsSelect () {
      if (this.addressOptions.length > 0) {
        const addresses = this.addressOptions.map(x => {
          const addressFields = [
            x.addressLine1,
            x.addressLine2,
            x.addressLine3,
            x.addressLine4,
            x.addressLocality,
            x.addressTown,
            x.addressCounty,
            x.addressPostCode
          ].filter(x => x)
          return { text: addressFields.join(', '), value: x }
        })
        return addresses
      }
      return []
    },
    selectedAddress: {
      get () {
        return this.$store.state.payer.currentPayer.payerDetails.address
      },
      set (value) {
        if (value) {
          const { addressLine1, addressLine2, addressLine3, addressLine4, addressPostCode, addressTown, addressCounty } = value
          this.$store.commit('setCurrentPayerAddress', { address1: addressLine1, address2: addressLine2, address3: addressLine3, address4: addressLine4, postcode: addressPostCode, town: addressTown, county: addressCounty })
        }
      }
    },
    ukPayerId () {
      return this.$store.state.payer.currentPayer.ukPayerId
    },
    smsVerifiedAndUnchanged () {
      return this.smsVerified && this.verifiedMobile === this.$v.mobile.$model
    },
    smsVerified: {
      get () { return this.$store.state.payer.currentPayer.smsVerified },
      set (value) { this.$store.commit('updateSmsVerified', value) }
    },
    verifiedMobile: {
      get () { return this.$store.state.payer.currentPayer.verifiedMobileNumber },
      set (value) { this.$store.commit('updateVerifiedNumber', value) }
    },
    groupId () {
      return this.$store.state.payer.currentPayer.groupId
    },
    title: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.title },
      set (value) { this.$store.commit('updateTitle', value) }
    },
    firstName: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.firstName },
      set (value) {
        this.$store.commit('updateFirstName', value)
        this.$store.commit('updateAccountHoldersName', value + ' ' + this.$store.state.payer.currentPayer.payerDetails.surname)
      }
    },
    surname: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.surname },
      set (value) {
        this.$store.commit('updateSurname', value)
        this.$store.commit('updateAccountHoldersName', this.$store.state.payer.currentPayer.payerDetails.firstName + ' ' + value)
      }
    },
    tel1: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.tel1 },
      set (value) { this.$store.commit('updateTel1', value) }
    },
    tel2: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.tel2 },
      set (value) { this.$store.commit('updateTel2', value) }
    },
    mobile: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.mobile },
      set (value) { this.$store.commit('updateMobile', value) }
    },
    address1: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address1 },
      set (value) { this.$store.commit('updateAddress1', value) }
    },
    address2: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address2 },
      set (value) { this.$store.commit('updateAddress2', value) }
    },
    address3: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address3 },
      set (value) { this.$store.commit('updateAddress3', value) }
    },
    address4: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.address4 },
      set (value) { this.$store.commit('updateAddress4', value) }
    },
    town: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.town },
      set (value) { this.$store.commit('updatetown', value) }
    },
    county: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.county },
      set (value) { this.$store.commit('updateCounty', value) }
    },
    postcode: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.postcode },
      set (value) { this.$store.commit('updatePostcode', value) }
    },
    lat: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.lat },
      set (value) { this.$store.commit('updateLat', value) }
    },
    lon: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.lon },
      set (value) { this.$store.commit('updateLon', value) }
    },
    email: {
      get () { return this.$store.state.payer.currentPayer.payerDetails.email },
      set (value) { this.$store.commit('updateEmail', value) }
    },
    status: {
      get () { return this.$store.state.payer.currentPayer.status },
      set (value) { this.$store.commit('updateStatus', value) }
    },
    messageChannel: {
      get () {
        // Get will be called when this changes.
        // If it changes (based on the action of an external component, re-check the form validation for SMS and email address)
        this.dirtyTouch(this.$v.mobile)
        this.dirtyTouch(this.$v.email)
        return this.$store.state.payer.currentPayer.payerMessageType
      }
    },
    payerPortalId: {
      get () {
        return this.$store.state.payer.currentPayer.payerPortalId
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerPortalId = value
      }
    },
    hasPortalAccount: {
      get () {
        return this.payerPortalId !== undefined && this.payerPortalId !== null
      }
    }
  },
  async mounted () {
    this.pageInstanceId = uuidv4()
    console.log('building validation message')
    this.buildValidationMessage()
    this.shareCount = await this.getDupeEmailCount(this.email)
  },
  watch: {
    $v: {
      handler (val) {
        this._self.$emit('can-continue', { value: !val.$invalid })
        this.buildValidationMessage()
      },
      deep: true
    },
    async email (val) {
      this.shareCount = await this.getDupeEmailCount(val)
    }
  }
}
</script>
<style>
.dupeLink {
  text-decoration: underline !important;
  color: blue !important;
  cursor: pointer !important;
}
</style>
