var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: { docPath: "/paygate-collections/alerts/" }
            }),
            _vm._v("Alerts "),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                isLoading: _vm.isTableLoading,
                mode: "remote",
                columns: _vm.columns,
                rows: _vm.rows,
                totalRows: _vm.totalRecords,
                "search-options": {
                  enabled: true
                },
                paginationOptions: _vm.paginationOptions,
                "sort-options": _vm.sortOptions,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                },
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch,
                "on-row-click": _vm.onRowChange
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "alertLevel"
                        ? _c(
                            "span",
                            { staticClass: "alertContainer" },
                            [
                              _c(
                                "b-badge",
                                {
                                  attrs: {
                                    pill: "",
                                    variant: _vm.getSeverityClass(
                                      props.row.alertLevel
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "loadingContent" }, [
                _c("h1", [_vm._v("Loading...")])
              ]),
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          })
                        ]
                      ),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Clear filters")
                      ])
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Refresh the data in the table",
                          expression: "'Refresh the data in the table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.load($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-sync pointer dimmedIcon" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Refresh Table")
                      ])
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Export the Alert Table",
                          expression: "'Export the Alert Table'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      attrs: { variant: "link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.exportTable($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-share-square pointer dimmedIcon"
                      }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Export Table")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }