<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Billing Report For {{customerName}}
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="from" class="required">From</label>
                <vuejsDatepicker
                  name="from"
                  id="from"
                  format="MMM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="month"
                  maximumView="year"
                  initial-view="month"
                  :use-utc="true"
                  :disabled-dates="{
                    from: new Date()
                    }"
                  :calendar-button="true"
                  calendar-button-icon="fa fa-calendar"
                  v-model="month"
                ></vuejsDatepicker>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button type="submit" class="btn btn-primary" @click="load()" :disabled="isLoading">
              <i class="glyphicon ti-pie-chart rpad"></i>Generate Billing Report
            </button>
            <div class="ml-3 btn-group">
              <button
                type="button"
                class="btn btn-success dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="isLoading || !dataLoaded"
              >Export as</button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" @click="saveAsJson">JSON</a>
                <a class="dropdown-item" href="#" @click="saveAsCsv">CSV</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <billing-report-all-customer-stats v-if="id === null" :report="report" :dataLoaded="dataLoaded"></billing-report-all-customer-stats>
    <billing-report-single-customer-stats
      v-if="id !== null"
      :report="report"
      :dataLoaded="dataLoaded"
      :month="month"
      :paygateId="id"
    ></billing-report-single-customer-stats>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import moment from 'moment'

import { saveAs } from 'file-saver'

// Mixins and Helpers
import loading from '@/Assets/Mixins/LoadingMixin'

// Components
import BillingReportAllCustomerStats from '@/Components/Platform/Reports/Billing/BillingReportAllCustomerStats'
import BillingReportSingleCustomerStats from '@/Components/Platform/Reports/Billing/BillingReportSingleCustomerStats'

export default {
  mixins: [loading],
  name: 'BillingReport',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  components: {
    BillingReportAllCustomerStats,
    BillingReportSingleCustomerStats
  },
  computed: {
    customerName () {
      if (this.id === null) {
        return 'All Customers'
      }
      if (this.dataLoaded) {
        return this.report[0].customerName
      }
      return ''
    }
  },
  data () {
    return {
      month: null,
      report: [],
      counterOptions: {
        startVal: 0,
        duration: 4,
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      dataLoaded: false
    }
  },
  methods: {
    async load () {
      try {
        this.dataLoaded = false
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Billing`, { month: this.month, paygateId: this.id }, { showload: true, showerror: true, errormessage: 'Failed to load billing report' })
        this.report = response.data.map(d => {
          return {
            customerName: d.customerName,
            paygateId: d.paygateId,
            licenceLimits: d.licenceLimits,
            accountId: d.accountId,
            data: d.data.map(dd => {
              return {
                amount: dd.amount,
                apiCall: dd.apiCall,
                name: dd.customerName,
                paygateId: dd.paygateId,
                type: dd.type
              }
            })
          }
        })
        this.dataLoaded = true
      } catch (e) {
        console.error(e)
      }
    },

    async saveAsJson () {
      try {
        this.$Progress.start()
        const now = new Date()
        const json = {
          reportDate: now,
          customers: this.report
        }
        var file = new Blob([JSON.stringify(json)], { type: 'text/plain' })
        saveAs(file, `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-billing-report.json`)
      } catch {
        this.$Progress.fail()
      } finally {
        this.$Progress.finish()
      }
    },
    async saveAsCsv () {
      try {
        const now = new Date()

        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Report/Billing/CSV`, { month: this.month, paygateId: this.id }, { showload: true, showerror: true, errormessage: 'Failed to download billing report CSV' })
        var file = new Blob([response.data], { type: 'text/csv' })
        saveAs(file, `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-billing-report.csv`)
      } catch { }
    }
  },
  async created () {
    var thisMonth = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 })
    this.month = moment(thisMonth).toDate()
    await this.load()
  }

}
</script>

<style lang="scss" scoped>
</style>
