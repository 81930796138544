var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block no-print" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" System Report "),
            _c("help-icon", {
              attrs: { docPath: "/administration/reports/systemreport/" }
            }),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "col form-group" },
            [
              _c("label", [_vm._v("Users")]),
              _c("vue-select", {
                attrs: {
                  multiple: "",
                  options: _vm.users,
                  closeOnSelect: false
                },
                model: {
                  value: _vm.selectedUsers,
                  callback: function($$v) {
                    _vm.selectedUsers = $$v
                  },
                  expression: "selectedUsers"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col form-group" },
            [
              _c("label", [_vm._v("Groups")]),
              _c("vue-select", {
                attrs: {
                  multiple: "",
                  options: _vm.groups,
                  closeOnSelect: false
                },
                model: {
                  value: _vm.selectedGroups,
                  callback: function($$v) {
                    _vm.selectedGroups = $$v
                  },
                  expression: "selectedGroups"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col form-group" },
            [
              _c("label", [_vm._v("Roles")]),
              _c("vue-select", {
                attrs: {
                  multiple: "",
                  options: _vm.roles,
                  closeOnSelect: false
                },
                model: {
                  value: _vm.selectedRoles,
                  callback: function($$v) {
                    _vm.selectedRoles = $$v
                  },
                  expression: "selectedRoles"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { disabled: _vm.isLoading || !_vm.anySelected },
            on: { click: _vm.submit }
          },
          [
            _c("i", { staticClass: "glyphicon ti-pie-chart rpad" }),
            _vm._v("Generate Report ")
          ]
        ),
        _vm.dataLoaded
          ? _c("div", { staticClass: "ml-3 btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success dropdown-toggle",
                  attrs: {
                    type: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                    disabled: _vm.isLoading
                  }
                },
                [_vm._v("Export as")]
              ),
              _c("div", { staticClass: "dropdown-menu" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: { click: _vm.saveAsJson }
                  },
                  [_vm._v("JSON")]
                )
              ])
            ])
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-secondary ml-3 float-right",
            attrs: { type: "button", disabled: _vm.isLoading },
            on: { click: _vm.clear }
          },
          [_c("i", { staticClass: "glyphicon ti-na rpad" }), _vm._v("Clear ")]
        )
      ])
    ]),
    _c(
      "div",
      {
        ref: "report",
        staticStyle: { visibility: "visible" },
        attrs: { id: "pdf-wrapper" }
      },
      [
        _vm.printing
          ? _c(
              "div",
              [_c("reporting-header", { attrs: { title: "System Report" } })],
              1
            )
          : _vm._e(),
        _c("group-config-report", {
          attrs: {
            groups: _vm.reportGroups,
            licenceSettings: _vm.licenceSettings
          }
        }),
        _c("user-config-report", { attrs: { users: _vm.reportUsers } }),
        _c("role-config-report", { attrs: { roles: _vm.reportRoles } }),
        _vm.printing ? _c("div", [_c("reporting-footer")], 1) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }