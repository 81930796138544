var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", [
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Watched Folder")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", [_vm._v(_vm._s(_vm.group.details.watchedFolder))])
            ])
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Default Sub Reference")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.group.details.defaultSubmissionReference))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Default Contra Narrative")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.group.details.defaultContraNarrative))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Stop on Duplicate File")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              [
                _c("tick", {
                  attrs: { coloured: false },
                  model: {
                    value: _vm.group.details.stopOnDuplicateFile,
                    callback: function($$v) {
                      _vm.$set(_vm.group.details, "stopOnDuplicateFile", $$v)
                    },
                    expression: "group.details.stopOnDuplicateFile"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Stop on Pre-sub Val Fix")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              [
                _c("tick", {
                  attrs: { coloured: false },
                  model: {
                    value: _vm.group.details.stopOnFix,
                    callback: function($$v) {
                      _vm.$set(_vm.group.details, "stopOnFix", $$v)
                    },
                    expression: "group.details.stopOnFix"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Stop on Pre-sub Val Warning")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              [
                _c("tick", {
                  attrs: { coloured: false },
                  model: {
                    value: _vm.group.details.stopOnWarning,
                    callback: function($$v) {
                      _vm.$set(_vm.group.details, "stopOnWarning", $$v)
                    },
                    expression: "group.details.stopOnWarning"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Stop on Pre-sub Val Duplicate")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              [
                _c("tick", {
                  attrs: { coloured: false },
                  model: {
                    value: _vm.group.details.stopOnDuplicate,
                    callback: function($$v) {
                      _vm.$set(_vm.group.details, "stopOnDuplicate", $$v)
                    },
                    expression: "group.details.stopOnDuplicate"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Remove Fix Payments")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              [
                _c("tick", {
                  attrs: { coloured: false },
                  model: {
                    value: _vm.group.details.removeFixPayments,
                    callback: function($$v) {
                      _vm.$set(_vm.group.details, "removeFixPayments", $$v)
                    },
                    expression: "group.details.removeFixPayments"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm.group.details.removeFixPayments
          ? _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
                _vm._v("Removed Fix Action")
              ]),
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "form-control-plaintext" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.group.details.removedFixesAction))
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Remove Warning Payments")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              [
                _c("tick", {
                  attrs: { coloured: false },
                  model: {
                    value: _vm.group.details.removeWarningPayments,
                    callback: function($$v) {
                      _vm.$set(_vm.group.details, "removeWarningPayments", $$v)
                    },
                    expression: "group.details.removeWarningPayments"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm.group.details.removeWarningPayments
          ? _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
                _vm._v("Remove Warning Action")
              ]),
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "form-control-plaintext" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.group.details.removedWarningsAction))
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Remove Duplicate Payments")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              [
                _c("tick", {
                  attrs: { coloured: false },
                  model: {
                    value: _vm.group.details.removeDuplicatePayments,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.group.details,
                        "removeDuplicatePayments",
                        $$v
                      )
                    },
                    expression: "group.details.removeDuplicatePayments"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm.group.details.removeWarningPayments
          ? _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
                _vm._v("Removed Duplicate Action")
              ]),
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "form-control-plaintext" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.group.details.removedDuplicatesAction))
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("File Save Folder")
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", [_vm._v(_vm._s(_vm.group.details.fileSaveFolder))])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }