<template>
  <div>
    <div id="synchronisationReportDetails">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2><help-icon docPath="/paygate-collections/reconciliation/" />Reconciliation History<favourite-icon></favourite-icon></h2>
            </div>
            <div class="idb-block-content">
              <p>Click on a row to see the results of a specific processing batch.</p>
              <vue-good-table
                ref="reportList"
                :columns="columns"
                :rows="reports"
                @on-row-click="viewReport"
                :pagination-options="{
                      enabled: true,
                      perPage: 10,
                      dropdownAllowAll: false
                    }"
                styleClass="vgt-table striped bordered"
              >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'groupname'">
                  <div class="group-colour" :style="{'background-color':props.row.groupcolour}"></div>
                  <span>{{props.formattedRow[props.column.field]}}</span>
                </span>
              </template>
              </vue-good-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data () {
    return {
      reports: [],
      columns: [
        {
          label: 'Group',
          field: 'groupname'
        },
        {
          label: 'Files processed',
          field: 'filenames'
        },
        {
          label: 'Report Types',
          field: 'reporttypes'
        },
        {
          label: 'Processing Date',
          field: 'processingdate',
          formatFn: (value) => {
            let retVal = this.formatDate(new Date(value))
            return retVal
          }
        },
        {
          label: 'No. Reports Processed',
          field: 'filecount'
        }
      ]
    }
  },
  async mounted () {
    axios.get(`${process.env.VUE_APP_DDMS_API_URL}reconciliation/`, { showload: true }).then((response) => {
      this.reports = response.data
    }).catch(() => { this.$toastr.e('Unable to retrieve reconciliation history.') })
  },
  methods: {
    viewReport (e) {
      this.$router.push({ path: '/collections/reconciliation/report/' + e.row.synchronisationbatchid })
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  }
}
</script>
