var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-button", { on: { click: _vm.showComposeModal } }, [
        _vm._v("Compose")
      ]),
      _c(
        "b-modal",
        {
          ref: "compose-modal",
          attrs: { id: "compose-modal", size: "xl" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v("Compose New Message")
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.closeComposeModal }
                    },
                    [_vm._v(" Discard")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary pull-right",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: { click: _vm.send }
                    },
                    [
                      _c("i", { staticClass: "fa fa-envelope" }),
                      _vm._v(" Send Message")
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.plans.length > 1
            ? _c("div", { staticClass: "form-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.planId,
                        expression: "planId"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "plan", id: "selectSchedule" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.planId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "-1", disabled: "" } }, [
                      _vm._v("Plan this message is regarding")
                    ]),
                    _vm._l(_vm.plans, function(plan, i) {
                      return _c(
                        "option",
                        { key: i, domProps: { value: plan.planId } },
                        [_vm._v(_vm._s(plan.planReference))]
                      )
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.messageTitle,
                  expression: "messageTitle"
                }
              ],
              staticClass: "form-control",
              attrs: { name: "subject", type: "email", placeholder: "Subject" },
              domProps: { value: _vm.messageTitle },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.messageTitle = $event.target.value
                }
              }
            })
          ]),
          _c(
            "b-form-group",
            [
              _c("label", [_vm._v("Message Body")]),
              _c("wysiwyg", {
                ref: "messageBodyControl",
                attrs: { suppressLoad: true },
                model: {
                  value: _vm.messageBody,
                  callback: function($$v) {
                    _vm.messageBody = $$v
                  },
                  expression: "messageBody"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }