var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.maintenanceMessages.length > 0
    ? _c(
        "div",
        {
          staticClass: "maintenance-messages",
          staticStyle: { margin: "0 30px" }
        },
        [
          _c(
            "div",
            {
              staticClass: "bd-callout bg-white",
              class: _vm.maintenanceCurrentClass
            },
            [
              _c(
                "b-carousel",
                {
                  attrs: {
                    id: "maintenance-messages",
                    interval: 10000,
                    indicators: ""
                  },
                  on: { "sliding-start": _vm.pageChangeEvent }
                },
                _vm._l(_vm.maintenanceMessages, function(maintenanceMessage) {
                  return _c(
                    "b-carousel-slide",
                    { key: maintenanceMessage.id },
                    [
                      _c("h4", [
                        _vm._v(" " + _vm._s(maintenanceMessage.reason) + " "),
                        maintenanceMessage.type === "Planned Maintenance"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(
                                    maintenanceMessage.start,
                                    "DD/MM/YYYY HH:mm"
                                  )
                                ) +
                                  " until " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      maintenanceMessage.end,
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  )
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("p", {
                        staticStyle: {
                          "overflow-y": "auto",
                          "min-height": "170px",
                          "max-height": "170px"
                        },
                        domProps: {
                          innerHTML: _vm._s(maintenanceMessage.description)
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }