var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Service Accounts "),
            _c("help-icon", {
              attrs: { docPath: "/administration/serviceaccounts/" }
            }),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "vue-good-table",
            {
              attrs: {
                isLoading: _vm.isTableLoading,
                paginationOptions: {
                  enabled: true,
                  perPage: 10
                },
                rows: _vm.rows,
                columns: _vm.columns,
                lineNumbers: true,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                },
                "on-row-click": _vm.onRowClick
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Create a new service account",
                          expression: "'Create a new service account'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.create($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus dimmedIcon pointer" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Create Service Account")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.create }
          },
          [
            _c("i", { staticClass: "fa fa-plus rpad" }),
            _vm._v("Create Service Account ")
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }