var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.groups.length > 0
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              _vm._l(_vm.groups, function(group, index) {
                return _c("div", { key: group.name }, [
                  group.groupType === _vm.groupTypes.bacs
                    ? _c(
                        "div",
                        [
                          _c("bacs-group-config-report", {
                            attrs: {
                              group: group,
                              bacsLicenceSettings: _vm.licenceSettings.bacs
                            }
                          })
                        ],
                        1
                      )
                    : group.groupType === _vm.groupTypes.bacsOnline
                    ? _c(
                        "div",
                        [
                          _c("bacs-online-group-config-report", {
                            attrs: {
                              group: group,
                              bacsLicenceSettings: _vm.licenceSettings.bacs
                            }
                          })
                        ],
                        1
                      )
                    : group.groupType === _vm.groupTypes.fps
                    ? _c(
                        "div",
                        [
                          _c("fps-group-config-report", {
                            attrs: { group: group }
                          })
                        ],
                        1
                      )
                    : group.groupType === _vm.groupTypes.fpsOnline
                    ? _c(
                        "div",
                        [
                          _c("fps-online-group-config-report", {
                            attrs: {
                              group: group,
                              fpsLicenceSettings: _vm.licenceSettings.fps
                            }
                          })
                        ],
                        1
                      )
                    : group.groupType === _vm.groupTypes.bureau
                    ? _c(
                        "div",
                        [
                          _c("bureau-group-config-report", {
                            attrs: { group: group }
                          })
                        ],
                        1
                      )
                    : group.groupType === _vm.groupTypes.collections
                    ? _c(
                        "div",
                        [
                          _c("collections-group-config-report", {
                            attrs: {
                              group: group,
                              bacsLicenceSettings: _vm.licenceSettings.bacs
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  index !== _vm.groups.length - 1 ? _c("hr") : _vm._e()
                ])
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Groups")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }