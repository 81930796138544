<template>
  <form @submit.prevent="!isLoading && checkValidation() && validate()">
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 id="title-of-block-check">
              IBAN Validation
              <help-icon docPath="/validate/iban/" />
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div
              class="form-group col-md-6"
              :class="{'warn': $v.iban.number.$error, 'valid':iban.result != null && iban.result.valid && iban.result.errorCode !== '1405','invalid': iban.result != null && (!iban.result.valid || iban.result.errorCode === '1405')}"
            >
              <!-- Label -->
              <label class="label-control required">IBAN</label>
              <!-- Input -->
              <input
                type="text"
                class="form-control"
                v-model.trim="$v.iban.number.$model"
                v-focus
                @input="adjustInput"
              />
              <!-- Validation Messages -->
              <span v-if="$v.iban.number.$dirty">
                <small
                  class="form-text text-warning small"
                  v-if="!$v.iban.number.required"
                >An IBAN is required</small>
                <small
                  class="form-text text-warning small"
                  v-if="!$v.iban.number.minLength"
                >IBAN's must be at least {{validation.minLength}} characters long for this country</small>
                <small
                  class="form-text text-warning small"
                  v-if="!$v.iban.number.maxLength"
                >IBAN's must be less than {{validation.minLength}} characters long for this country</small>
              </span>
              <span v-if="iban.result != null">
                <small
                  v-if="iban.result.valid && iban.result.errorCode !== '1405'"
                  class="form-text text-success small"
                >The IBAN is valid</small>
                <small
                  v-if="iban.result.valid && iban.result.errorCode === '1405'"
                  class="form-text text-warning small"
                >{{iban.result.errorText}}</small>
                <small
                  v-if="!iban.result.valid"
                  class="form-text text-danger small"
                >The IBAN is invalid</small>
              </span>
            </div>
            <div class="form-group col-md-6" v-show="this.iban.code">
              <span
                :class="'flag-icon flag-icon-' + this.iban.code.toLowerCase().trim()"
                style="border: 1px solid black;"
              ></span>
              <label class="label-control pl-2">{{iban.countryName}}</label>
            </div>
          </div>
          <div class="idb-block-footer">
            <button type="submit" class="btn btn-primary" :disabled="isLoading">Validate</button>

            <button type="button" class="btn btn-outline-primary ml-3" @click="clear">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-if="iban.result != null && (iban.result.valid && iban.result.errorCode !== '1405' && iban.code === 'GB')"
    >
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2 id="title-of-block-check">Bank Details</h2>
          </div>
          <div class="idb-block-content">
            <form novalidate v-on:submit.prevent class="col-md-12">
              <div class="form-group row">
                <label class="label-control col-md-3">Bank</label>
                <span class="form-control-static">{{iban.result.bankName}}</span>
              </div>
              <div class="form-group row">
                <label class="label-control col-md-3">Branch</label>
                <span class="form-control-static">{{iban.result.branchName}}</span>
              </div>
              <div class="form-group row">
                <label class="label-control col-md-3">Address</label>
                <address class="form-control-static" v-html="Address"></address>
              </div>
              <div class="form-group row">
                <label class="label-control col-md-3">BIC</label>
                <span class="form-control-static">{{iban.result.bic}}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import axios from 'axios'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'

function addressFormatter (addressObject, joinString) {
  var addressArr = []

  if (addressObject.address1) {
    addressArr.push(addressObject.address1)
  }
  if (addressObject.address2) {
    addressArr.push(addressObject.address2)
  }
  if (addressObject.address3) {
    addressArr.push(addressObject.address3)
  }
  if (addressObject.address4) {
    addressArr.push(addressObject.address4)
  }
  if (addressObject.county) {
    addressArr.push(addressObject.county)
  }
  if (addressObject.town) {
    addressArr.push(addressObject.town)
  }
  if (addressObject.postcode) {
    addressArr.push(addressObject.postcode)
  }

  return addressArr.join(joinString)
}

export default {
  name: 'ibanValidation',
  mixins: [loading],
  data () {
    return {
      loading: false,
      iban: {
        number: '',
        result: null,
        code: '',
        countryName: null
      },
      validation: {
        minLength: 0,
        maxLength: 12
      }
    }
  },
  computed: {
    Address () {
      return addressFormatter(this.iban.result, '<br/>')
    }
  },
  methods: {
    async validate () {
      if (this.iban.number.length === this.validation.minLength) {
        var response = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}Iban`, {
          params: { iban: this.iban.number }
        })

        this.iban.result = response.data
      }
    },
    adjustInput: async function () {
      this.iban.result = null
      this.iban.number = this.iban.number.replace(/[^0-9a-zA-Z]+/g, '')
      this.iban.number = this.iban.number.toUpperCase()

      if (this.iban.number.length < 2) {
        this.validation.minLength = 0
        this.validation.maxLength = 12
        this.iban.code = ''
        return
      }

      var cc = this.iban.number.substr(0, 2)

      if (cc !== this.iban.code) {
        this.iban.code = cc

        var result = await axios.get(`${process.env.VUE_APP_VALIDATE_API_URL}Iban/Length`, {
          params: { countryCode: this.iban.code }
        })

        if (result.status === 204) {
          this.validation.minLength = 2
          this.validation.maxLength = 2
          this.iban.countryName = null
        } else {
          this.validation.minLength = result.data.length
          this.validation.maxLength = result.data.length
          this.iban.countryName = result.data.name
        }
      }
    },
    clear: function () {
      this.iban.number = ''
      this.iban.result = null
      this.iban.code = ''
      this.iban.countryName = null
      this.$v.iban.$reset()
    }
  },
  validations () {
    return {
      iban: {
        number: { required, minLength: minLength(this.validation.minLength), maxLength: maxLength(this.validation.maxLength) }
      }
    }
  }
}
</script>
<style>
</style>
