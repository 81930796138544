<template>
    <div class="idb-block">
        <div class="idb-block-title">
          <h2><help-icon docPath="/paygate-collections/payers/editpayer/" />Payer "{{reference}}" Due To Expire Due to Inactivity</h2>
        </div>
        <div class="idb-block-content">
            <p>The payer below is due to be closed due to inactivity.</p>
            <p>Reference: {{reference}}</p>
            <p>Status: {{status}}</p>
            <p>Most Recent Payment: {{mostRecentPayment}}</p>        
            <p v-if="ukPayerId !== null"><router-link :to="{ name: 'editPayer', params: { ukPayerId: ukPayerId } }"> <!-- eslint-disable-line -->
              <span>View Payer</span>
            </router-link></p>
        </div>
        <div class="idb-block-footer noprint-footer">
            <b-button variant="primary" @click.prevent="removeAction" :disabled="isLoading" v-if="actionId && actionId !== null">Clear Action</b-button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  props: { ukPayerId: String },
  data () {
    return {
      actionId: null,
      reference: '',
      planId: '',
      status: '',
      mostRecentPayment: null,
      ukPayerId: null
    }
  },
  async mounted () {
    this.actionId = this.$route.query.actionId
    var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payeractions/finalwarning/${this.ukPayerId}`)
    this.reference = response.data.reference
    this.status = response.data.status
    this.mostRecentPayment = response.data.mostRecentPayment
    this.ukPayerId = response.data.ukPayerId
  },
  methods: {
    async removeAction () {
      var response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}payeractions/finalwarning/${this.actionId}`)
      if (response) {
        this.$toastr.s('Action Cleared')
      }
    }
  }
}
</script>