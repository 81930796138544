<template>
  <div>
    <div id="manageSchedule">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>
              <router-link v-if="false" class="pull-right ml-2 text-danger" to="/collections/payer/manage">
                <i class="fas fa-times mr-2"></i>
              </router-link>
              <help-icon docPath="/paygate-collections/payers/payercreation/" />Create Payer Wizard<span class="text-muted" v-if="stepsTaken">: {{accountHolderName}} ({{reference}})</span></h2>
            </div>
            <horizontal-stepper ref="stepper" :steps="payerSteps"
            @completed-step="stepCompleted"
            @stepper-finished="checkCompleted"
            @cancel="cancelProcess"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
// import Bank from './Wizard/Bank'
// import Details from './Wizard/Details'
import Bank from '@/Components/Collections/Payer/BankAccountDetails'
import Details from './Wizard/Details'
import Address from './Wizard/Address'
import Plan from './Wizard/Plan'
import swal from 'sweetalert2'
import HorizontalStepper from '@/Components/Collections/Payer/Wizard'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
export default {
  mixins: [loading],
  components: {
    HorizontalStepper
  },
  props: {
    groupid: String
  },
  data () {
    return {
      complete: false,
      payerSteps: [
        {
          icon: 'fas fa-user',
          name: 'personalDetails',
          title: 'Personal Details',
          component: Details,
          completed: false
        },
        {
          icon: 'fas fa-home',
          name: 'address',
          title: 'Address',
          component: Address,
          completed: false
        },
        {
          icon: 'fas fa-university',
          name: 'bankDetails',
          title: 'Bank Details',
          component: Bank,
          completed: false
        },
        {
          icon: 'fas fa-calendar',
          name: 'planDetails',
          title: 'Payment Plan',
          component: Plan,
          completed: false
        }
      ],
      stepsTaken: false,
      canNavigate: false,
      highestStepDone: 0
    }
  },
  methods: {
    async stepCompleted (payload) {
      if (this.highestStepDone <= payload.index) {
        this.highestStepDone = payload.index + 1
      }
      console.log('payload!!', payload)
      this.payerSteps.forEach(async (step) => {
        if (step.name === payload.name) {
          step.completed = true
        }
      })
      if (payload.hasOwnProperty('index')) {
        if (payload.index === 0) {
          await this.generateReferenceAndPayer()
        }
        await this.$store.dispatch('savePayer')
      }
    },
    async generateReferenceAndPayer () {
      if (this.$store.state.payer.useAutoGenerateRef) {
        await this.$store.dispatch('generatePayerReference')
      }
      this.stepsTaken = true
      if (this.$store.state.payer.currentPayer.ukPayerId === null) {
        console.log('YaRyAR creating new payer', { groupId: this.groupid, reference: this.$store.state.payer.reference })
        var response = await this.$store.dispatch('createNewPayer', { groupId: this.groupid, reference: this.$store.state.payer.reference })
        this.$store.commit('setPayerId', response.ukPayerId)
      }
    },
    async createPayer () {
      this.$store.commit('setGroupId', this.groupid)
      this.$store.commit('setPayerStatus', 0)
      await this.$store.dispatch('updatePayer', { groupid: this.groupid, paygateId: this.$store.state.common.paygateId })
      this.$toastr.s(`Payer: ${this.$store.state.payer.reference} created`)
      this.canNavigate = true
      if (!this.$store.state.payer.addAnother) {
        if (this.$store.state.payer.currentPayer.paymentPlans.length > 0 && this.$store.state.payer.currentPayer.paymentPlans[0].recurrenceEndType === 4) {
          this.$router.push({ path: `/collections/payer/${this.$store.state.payer.currentPayer.ukPayerId}/${this.$store.state.payer.currentPayer.paymentPlans[0].paymentPlanId}/schedule` })
        } else {
          this.$router.push({ path: '/collections/payer/manage' })
        }
      } else {
        this.$refs.stepper.reset = true
      }
      await this.$store.dispatch('setBlankPayer', this.groupid)
    },
    async leaving () {
      var result = null
      try {
        result = await swal.fire({
          title: 'Navigation',
          text: `Leaving this page will leave the user in an incomplete state. This payer ('${this.$store.state.payer.currentPayer.reference}') can be accessed from the manage payers page. This payer may also be abandoned, in which case it may not be completed later.`,
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonColor: colours.success,
          confirmButtonText: 'Leave Wizard',
          denyButtonText: 'Abandon and Leave Wizard',
          denyButtonColor: colours.warning,
          cancelButtonText: 'Stay',
          width: '40%'
        })
      } catch (err) {
        console.log(err)
        result = false
      }
      return result
    },
    async incomplete () {
      var result = null
      try {
        result = await swal.fire({
          icon: 'warning',
          title: 'Navigation',
          text: `This payer ('${this.$store.state.payer.currentPayer.reference}') is in an incomplete state, you may complete this wizard, or it can be completed from within the manage payers page.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Leave Wizard',
          cancelButtonText: 'Stay'
        })
      } catch (err) {
        result = false
      }
      return result
    },
    async checkCompleted (e) {
      console.log(e)
      console.log(this.$store.getters.createCompleted)
      console.log(this.$store.state)
      this.canNavigate = this.$store.getters.createCompleted
      if (this.canNavigate) {
        await this.createPayer()
      } else {
        const result = await this.incomplete()
        if (result.isConfirmed) {
          this.canNavigate = true
          this.$router.push({ path: '/collections/payer/manage' })
        } else {
          this.$refs.stepper.canContinue = true
        }
      }
    },
    navigateToStep (e) {
      var selectedStep = 0
      var selectedParent = null
      for (let i = 0; i < e.path.length; i++) {
        if (e.path[i].getAttribute('step-index') !== null) {
          selectedStep = parseInt(e.path[i].getAttribute('step-index'))
          selectedParent = e.path[i]
          break
        }
      }
      console.log(selectedStep)
      var wizardNodes = this.$refs.stepper.$el.querySelectorAll('.step')
      for (let i = 0; i < wizardNodes.length; i++) {
        wizardNodes[i].setAttribute('class', 'step deactivated')
      }
      if (this.$store.state.payer.currentPayer.ukPayerId !== null) {
        this.$refs.stepper.activateStep(selectedStep, selectedStep < this.$refs.stepper.currentStep)
        if (selectedParent !== null) {
          selectedParent.setAttribute('class', 'step active')
        }
      }
    },
    async abandonPayer () {
      await this.$store.dispatch('deletePayer', this.$store.state.payer.currentPayer.ukPayerId)
    },
    async cancelProcess () {
      this.$router.push('/collections/payer/manage')
    }
  },
  async created () {
    await this.$store.dispatch('populateAllCollectionsGroups')
  },
  async mounted () {
    await this.$store.dispatch('setBlankPayer', this.groupid)
    await this.$store.dispatch('getGroupPlanNames')
    await this.$store.dispatch('getUseAutoGenerateRef', this.groupid)
    await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.groupid })
    await this.$store.dispatch('getGroupBankAccounts', this.groupId)
    this.$store.state.payer.currentPayer.payerMessageType = this.$store.getters.collectionsGroupConfig.defaultPayerMessageType
    var wizardNodes = this.$refs.stepper.$el.querySelectorAll('.step')
    // var step = 0
    for (var i = 0; i < wizardNodes.length; i++) {
      wizardNodes[i].setAttribute('step-index', i)
      wizardNodes[i].addEventListener('click', this.navigateToStep)
    }
  },
  async beforeRouteLeave (to, from, next) {
    try {
      if ((this.stepsTaken || this.detailsValid) && !this.canNavigate) {
        if (!this.stepsTaken && this.detailsValid) {
          await this.generateReferenceAndPayer()
        }
        const result = await this.leaving()
        if (result) {
          if (result.isConfirmed) {
            next()
          } else if (result.isDenied) {
            await this.abandonPayer()
            next()
          }
        } else {
          next(false)
        }
      } else {
        next()
      }
    } catch (e) {
    }
  },
  computed: {
    reference () {
      return this.$store.state.payer.reference
    },
    accountHolderName () {
      return this.$store.state.payer.currentPayer.bankAccountDetails.accountHoldersName
    },
    detailsValid () {
      return this.$store.state.payer.createCompleted.detailsCompleted
    }
  }
}
</script>
