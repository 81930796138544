var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFromFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showFromFolderBrowser = false
                _vm.closeFromPopup()
              }
            }
          })
        : _vm._e(),
      _vm.showToFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showToFolderBrowser = false
                _vm.closeToPopup()
              }
            }
          })
        : _vm._e(),
      _vm.showModalFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showModalFileBrowser = false
                _vm.closePopup()
              }
            }
          })
        : _vm._e(),
      _c("h3", [
        _vm._v("Move Some Files"),
        _c("span", { staticClass: "pull-right" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  _vm.docUrl +
                  "/automation/paygate-workflow/workflow-nodes/fileoperations/movesomefiles/",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "far fa-question-circle" })]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Filename")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s3.value,
              expression: "selectedNode.props.s3.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s3.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s3.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s3,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s3.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnFileBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s3.value.required &&
      _vm.$v.selectedNode.props.s3.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The filename field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s3.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s3.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("From Path")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s1.value,
              expression: "selectedNode.props.s1.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s1.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s1.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s1,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s1.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnFromPathBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s1.value.required &&
      _vm.$v.selectedNode.props.s1.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The From Path field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s1.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s1.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("To Path")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s2.value,
              expression: "selectedNode.props.s2.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s2.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s2.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s2,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s2.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnToPathBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s2.value.required &&
      _vm.$v.selectedNode.props.s2.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The To Path field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s2.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s1.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("Number of Files")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.int1.value,
              expression: "selectedNode.props.int1.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.int1.value.$error },
          attrs: { type: "number" },
          domProps: { value: _vm.selectedNode.props.int1.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.int1,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.int1.value.$touch()
              }
            ]
          }
        }),
        !_vm.$v.selectedNode.props.int1.value.required &&
        _vm.$v.selectedNode.props.int1.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("The field is required!")
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.int1.value.minValue
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Minimum value is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.int1.value.$params.minValue.min
                  ) +
                  "!"
              )
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.int1.value.maxValue
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Maximum value is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.int1.value.$params.maxValue.max
                  ) +
                  "!"
              )
            ])
          : _vm._e()
      ]),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "p-check",
            {
              staticClass: "p-switch",
              attrs: { name: "check", color: "primary" },
              model: {
                value: _vm.selectedNode.props.b1.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.b1, "value", $$v)
                },
                expression: "selectedNode.props.b1.value"
              }
            },
            [_vm._v("Overwrite Existing File")]
          )
        ],
        1
      ),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "p-check",
            {
              staticClass: "p-switch",
              attrs: { name: "check", color: "primary" },
              model: {
                value: _vm.selectedNode.props.b2.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.b2, "value", $$v)
                },
                expression: "selectedNode.props.b2.value"
              }
            },
            [_vm._v("Follow error path if no matching files found")]
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }