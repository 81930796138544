var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("form", [
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "vue-good-table",
                      {
                        attrs: {
                          rows: _vm.passwordRequestItems,
                          columns: _vm.passwordRequestColumns,
                          "sort-options": { enabled: false },
                          styleClass: "vgt-table condensed bordered"
                        },
                        on: { "on-row-click": _vm.onPasswordRequestRowClick }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.loadPasswordRequests($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.passwordRequestItem.customerName != ""
              ? _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c("b-col", { attrs: { sm: "2" } }, [
                      _c(
                        "label",
                        { attrs: { for: "passwordRequestItem.ftpFolder" } },
                        [_vm._v("FTP Folder")]
                      )
                    ]),
                    _c(
                      "b-col",
                      { attrs: { sm: "3" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text", readonly: "" },
                          model: {
                            value: _vm.passwordRequestItem.ftpFolder,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.passwordRequestItem,
                                "ftpFolder",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "passwordRequestItem.ftpFolder"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.passwordRequestItem.customerName != ""
              ? _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c("b-col", { attrs: { sm: "2" } }, [
                      _c(
                        "label",
                        { attrs: { for: "passwordRequestItem.customerName" } },
                        [_vm._v("Customer")]
                      )
                    ]),
                    _c(
                      "b-col",
                      { attrs: { sm: "3" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text", readonly: "" },
                          model: {
                            value: _vm.passwordRequestItem.customerName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.passwordRequestItem,
                                "customerName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "passwordRequestItem.customerName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.passwordRequestItem.customerName != ""
              ? _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c("b-col", { attrs: { sm: "2" } }, [
                      _c(
                        "label",
                        { attrs: { for: "passwordRequestItem.userName" } },
                        [_vm._v("Requested by")]
                      )
                    ]),
                    _c(
                      "b-col",
                      { attrs: { sm: "3" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text", readonly: "" },
                          model: {
                            value: _vm.passwordRequestItem.userName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.passwordRequestItem,
                                "userName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "passwordRequestItem.userName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.passwordRequestItem.customerName != ""
              ? _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c("b-col", { attrs: { sm: "2" } }, [
                      _c(
                        "label",
                        { attrs: { for: "passwordRequestItem.sftpUser" } },
                        [_vm._v("SFTP User Name")]
                      )
                    ]),
                    _c(
                      "b-col",
                      { attrs: { sm: "3" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text", readonly: "" },
                          model: {
                            value: _vm.passwordRequestItem.sftpUser,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.passwordRequestItem,
                                "sftpUser",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "passwordRequestItem.sftpUser"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "2" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "info",
                              disabled: _vm.isCopyUsernameDisabled
                            },
                            on: { click: _vm.copySftpUsername }
                          },
                          [_vm._v("Copy to clipboard")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.passwordRequestItem.customerName != ""
              ? _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c("b-col", { attrs: { sm: "2" } }, [
                      _c(
                        "label",
                        { attrs: { for: "passwordRequestItem.password" } },
                        [_vm._v("Password")]
                      )
                    ]),
                    _c(
                      "b-col",
                      { attrs: { sm: "3" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.passwordRequestItem.password,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.passwordRequestItem,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "passwordRequestItem.password"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "4" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "info",
                              disabled: _vm.isCopyPasswordDisabled
                            },
                            on: { click: _vm.copyPassword }
                          },
                          [_vm._v("Copy to clipboard")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: { variant: "success" },
                            on: { click: _vm.generatePassword }
                          },
                          [_vm._v("Generate Password")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.passwordRequestItem.customerName != ""
              ? _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c("b-col", { attrs: { sm: "2" } }, [
                      _c(
                        "label",
                        { attrs: { for: "passwordRequestItem.pubkey" } },
                        [_vm._v("Public Key")]
                      )
                    ]),
                    _c(
                      "b-col",
                      { attrs: { sm: "6" } },
                      [
                        _c("b-form-textarea", {
                          attrs: { rows: "9", "max-rows": "9" },
                          model: {
                            value: _vm.passwordRequestItem.pubkey,
                            callback: function($$v) {
                              _vm.$set(_vm.passwordRequestItem, "pubkey", $$v)
                            },
                            expression: "passwordRequestItem.pubkey"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm.passwordRequestItem.customerName != ""
          ? _c(
              "div",
              { staticClass: "idb-block-footer " },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "info" },
                            on: { click: _vm.savePublicKey }
                          },
                          [
                            _c("i", { staticClass: "fas fa-save mr-2" }),
                            _vm._v("Save Public Key")
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-3",
                            attrs: { variant: "primary" },
                            on: { click: _vm.sendPasswordEmail }
                          },
                          [_vm._v("Send email and mark complete")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "pull-right",
                            attrs: { variant: "danger" },
                            on: { click: _vm.removeSFTPAccountRequest }
                          },
                          [_vm._v("Remove")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "vue-good-table",
                      {
                        attrs: {
                          rows: _vm.whiteListItems,
                          columns: _vm.whiteListColumns,
                          "sort-options": { enabled: false },
                          "search-options": { enabled: true },
                          styleClass: "vgt-table condensed bordered"
                        },
                        on: { "on-row-click": _vm.onWhiteListRowClick }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.loadWhiteList($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.whiteListItem.customerName != ""
          ? _c(
              "div",
              { staticClass: "idb-block-footer " },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.whiteListItem.customerName) +
                              " - " +
                              _vm._s(_vm.whiteListItem.ipText) +
                              " "
                          )
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-3",
                            attrs: {
                              variant: "warning ",
                              hidden: _vm.whiteListItem.active === false
                            },
                            on: {
                              click: function($event) {
                                return _vm.setWhiteListItemState(false)
                              }
                            }
                          },
                          [_vm._v("Disable")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-3",
                            attrs: {
                              variant: "primary",
                              hidden: _vm.whiteListItem.active === true
                            }
                          },
                          [_vm._v("Enable")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-3",
                            attrs: {
                              variant: "warning ",
                              hidden: _vm.whiteListItem.active === true
                            }
                          },
                          [_vm._v("Reject")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "pull-right",
                            attrs: {
                              variant: "danger",
                              hidden: _vm.whiteListItem.active === false
                            }
                          },
                          [_vm._v("Remove")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "3" } },
                  [
                    _c("b-form-select", {
                      attrs: { options: _vm.paygateStaff },
                      on: { change: _vm.onPaygateStaffChange },
                      model: {
                        value: _vm.staffName,
                        callback: function($$v) {
                          _vm.staffName = $$v
                        },
                        expression: "staffName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "3" } },
                  [
                    _c("b-form-input", {
                      attrs: { placeholder: "Enter new IP address" },
                      model: {
                        value: _vm.newIpAddress,
                        callback: function($$v) {
                          _vm.newIpAddress = $$v
                        },
                        expression: "newIpAddress"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-3",
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.onSetSecurityGroupIPAddress()
                      }
                    }
                  },
                  [_vm._v("Set IP Address")]
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "vue-good-table",
                      {
                        attrs: {
                          rows: _vm.securityGroups,
                          columns: _vm.securityGroupColumns,
                          styleClass: "vgt-table condensed bordered"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onPaygateStaffChange($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "ContainerInstanceStateTable",
                        attrs: {
                          rows: _vm.containerInstanceStateItems,
                          columns: _vm.containerInstanceStateColumns,
                          styleClass: "vgt-table condensed bordered",
                          selectOptions: {
                            enabled: true,
                            selectOnCheckboxOnly: false
                          },
                          "row-style-class": _vm.rowStyleClassFn
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.loadContainerInstanceState(
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            attrs: { slot: "selected-row-actions" },
                            slot: "selected-row-actions"
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "ml-3",
                                attrs: { variant: "primary", size: "sm" },
                                on: { click: _vm.onToggleIsSingleInstanceClick }
                              },
                              [_vm._v("Toggle Single Instance")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "ml-3",
                                attrs: { variant: "primary", size: "sm" },
                                on: { click: _vm.onToggleInstanceActiveClick }
                              },
                              [_vm._v("Toggle State")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Agent SFTP Password Requests")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("SFTP IP Address Approvals")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("AWS Security Groups")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [
        _vm._v(
          "AWS Single Instance Containers (ONLY CHANGE IF YOU KNOW IT'S CORRECT)"
        )
      ]),
      _c("small", [
        _c("i", [
          _vm._v(
            "This controls whether containers will automatically restart, e.g. BACS"
          )
        ]),
        _c("br"),
        _c("i", [
          _vm._v(
            "Single instance containers will need their Active status resetting before they will restart"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }