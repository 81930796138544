var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    [
      _vm.isActive
        ? _c(
            "li",
            {
              staticClass: "search",
              class: { expanded: _vm.isActive },
              attrs: { id: "search" }
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  staticStyle: { "padding-top": "17px!important" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchTerm,
                        expression: "searchTerm"
                      }
                    ],
                    ref: "search",
                    staticClass: "form-control",
                    attrs: { "aria-label": "Search", type: "search" },
                    domProps: { value: _vm.searchTerm },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchTerm = $event.target.value
                        },
                        function($event) {
                          return _vm.search()
                        }
                      ],
                      keypress: _vm.escape
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      class: { "not-expanded": !_vm.isActive },
                      attrs: { id: "results" }
                    },
                    [
                      _c("transition", { attrs: { name: "fade-fast" } }, [
                        _vm.loading
                          ? _c("div", { staticClass: "text-center" }, [
                              _c("i", {
                                staticClass: "fas fa-spinner fa-pulse"
                              })
                            ])
                          : _vm._e()
                      ]),
                      _vm._l(_vm.results, function(result) {
                        return _c(
                          "div",
                          { key: result.typeName },
                          [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "dropdown-header bg-secondary text-white"
                              },
                              [_vm._v(_vm._s(result.typeName))]
                            ),
                            _vm._l(result.hits, function(hit, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "col-sm-12 dropdown-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.goToSearchResult(hit)
                                    }
                                  }
                                },
                                [
                                  _c("search-types", {
                                    attrs: { hit: hit, type: result.typeName }
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      }),
                      _vm.results.length === 0
                        ? _c("div", [
                            _vm.hasSearched
                              ? _c("h4", { staticClass: "dropdown-header" }, [
                                  _vm._v("No Results found")
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass:
                            "dropdown-item text-center bg-dark text-white text-small",
                          on: { click: _vm.advancedSearch }
                        },
                        [_vm._v("Show More Results/Advanced Search")]
                      )
                    ],
                    2
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "b-nav-item",
        {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.focus.hover.bottom",
              value: "Search",
              expression: "'Search'",
              modifiers: { focus: true, hover: true, bottom: true }
            }
          ],
          staticStyle: { "z-index": "999" },
          attrs: { href: "#" },
          on: { click: _vm.show }
        },
        [
          _c("i", {
            staticClass: "ti",
            class: { "ti-search": !_vm.isActive, "ti-close": _vm.isActive },
            staticStyle: { "line-height": "30px", color: "black" }
          }),
          !_vm.isActive
            ? _c("span", { staticClass: "sr-only" }, [_vm._v("Search")])
            : _c("span", { staticClass: "sr-only" }, [_vm._v("Cancel Search")])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }