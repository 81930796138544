var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { attrs: { mb: "4" } },
        [
          _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [
                _c("h2", [
                  _c("span", { staticClass: "pull-right" }, [
                    _c("a", { attrs: { href: "#", target: "_blank" } }, [
                      _c("i", { staticClass: "far fa-question-circle" })
                    ])
                  ]),
                  _vm._v("View SFTP Request")
                ])
              ]),
              _c("div", { staticClass: "idb-block-content" }, [
                _c("table", { staticClass: "mb-3" }, [
                  _c("tr", [
                    _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                      _vm._v("User Name")
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.sftpRequest ? _vm.sftpRequest.userName : "Loading"
                        )
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                      _vm._v("Customer Name")
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.sftpRequest
                            ? _vm.sftpRequest.customerName
                            : "Loading"
                        )
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                      _vm._v("Customer code")
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.sftpRequest
                            ? _vm.sftpRequest.customerCode
                            : "Loading"
                        )
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                      _vm._v("Sent Date")
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.sftpRequest
                            ? _vm.formatDate(_vm.sftpRequest.timeRequested)
                            : "Loading"
                        )
                      )
                    ])
                  ]),
                  false
                    ? _c("tr", [
                        _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                          _vm._v("Email Address")
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.sftpRequest
                                ? _vm.sftpRequest.emailAddress
                                : "Loading"
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                      _vm._v("Pem Key (to send to client)")
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "input-group" }, [
                        (_vm.pinRevealed ? "text" : "password") === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sftpRequest.pemPass,
                                  expression: "sftpRequest.pemPass"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { readonly: "", type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.sftpRequest.pemPass)
                                  ? _vm._i(_vm.sftpRequest.pemPass, null) > -1
                                  : _vm.sftpRequest.pemPass
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.sftpRequest.pemPass,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sftpRequest,
                                          "pemPass",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sftpRequest,
                                          "pemPass",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.sftpRequest, "pemPass", $$c)
                                  }
                                }
                              }
                            })
                          : (_vm.pinRevealed ? "text" : "password") === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sftpRequest.pemPass,
                                  expression: "sftpRequest.pemPass"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { readonly: "", type: "radio" },
                              domProps: {
                                checked: _vm._q(_vm.sftpRequest.pemPass, null)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.sftpRequest,
                                    "pemPass",
                                    null
                                  )
                                }
                              }
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sftpRequest.pemPass,
                                  expression: "sftpRequest.pemPass"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                readonly: "",
                                type: _vm.pinRevealed ? "text" : "password"
                              },
                              domProps: { value: _vm.sftpRequest.pemPass },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.sftpRequest,
                                    "pemPass",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showKey($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.pinRevealed ? "Hide" : "Reveal")
                              )
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              class: _vm.pinRevealed
                                ? "btn-outline-primary"
                                : " btn-outline-secondary",
                              attrs: {
                                type: "button",
                                disabled: !_vm.pinRevealed
                              },
                              on: { click: _vm.copyPin }
                            },
                            [_vm._v("Copy Key")]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                      _vm._v("Actioned")
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.sftpRequest ? _vm.sftpRequest.actioned : "Loading"
                        )
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "pr-5 font-weight-bold" }, [
                      _vm._v("Public Key")
                    ]),
                    _c(
                      "td",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "success", size: "sm" },
                            on: { click: _vm.savePublicKey }
                          },
                          [
                            _c("i", { staticClass: "fas fa-save mr-2" }),
                            _vm._v("Save")
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "idb-block-footer noprint-footer" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.sftpRequest.actioned
                      },
                      on: { click: _vm.setToActioned }
                    },
                    [_vm._v("Mark as actioned")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }