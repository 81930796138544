<template>
  <div class="idb-block dash-card widget">
    <div class="idb-block-content widget">
      <div v-if="editable && !widget.selected" class="button-block">
        <button @click="remove" class="btn btn-sm btn-danger float-right">
          <span class="fa fa-fw fa-times"></span>
        </button>
        <button @click="settings" class="btn btn-sm btn-info pull-right mr-10">
          <span class="fa fa-fw fa-cog"></span>
        </button>
      </div>
      <div ref="fullscreen" style="height:100%">
        <slot style="height:100%"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WidgetWrapper',
  props: {
    widget: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    settings () {
      this.$emit('settings', this.widget)
    },
    remove () {
      this.$emit('remove', this.widget)
    },
    maximise () {
      this.$emit('maximise', this.widget)
    },
    minimise () {
      this.$emit('minimise', this.widget)
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  height: 100%;
  overflow: hidden;
}
.button-block {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
