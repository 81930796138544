<template>
  <div class="idb-block">
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block-title">
      <h2>
        Faster Payment details for '{{ this.submission.submissionReference }}'
        <help-icon :docPath="'/bacsbureau/submissions/'"></help-icon>
      </h2>
    </div>

    <!-- Summary Information -->
    <div class="ml-5 mt-3">
      <b-row>
        <b-col sm="2">Submission Reference</b-col>
        <b-col sm="2">{{ this.submission.submissionReference }}</b-col>
        <b-col sm="2">Total number of debits</b-col>
        <b-col sm="2">{{ this.totalDebitCount | number }}</b-col>
        <b-col sm="2">Total number of credits</b-col>
        <b-col sm="2">{{ this.totalCreditCount | number }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="2">Total number of DDI</b-col>
        <b-col sm="2">{{ this.totalDDICount | number }}</b-col>
        <b-col sm="2">Total value of debits</b-col>
        <b-col sm="2">{{ this.totalDebitValue | currency }}</b-col>
        <b-col sm="2">Total value of credits</b-col>
        <b-col sm="2">{{ this.totalCreditValue | currency }}</b-col>
      </b-row>
      <b-row></b-row>
      <b-row></b-row>
    </div>

    <!-- Summary Files -->
    <div class="idb-block-content">
      <div role="tablist">
        <b-card no-body v-for="item in submissionSummaryItems" v-bind:key="item.fileNumber">
          <b-card-header header-tag="header" class="p-2" role="tab">
            <b-row
              v-b-toggle="'item-' + item.bureauSubmissionId + item.fileNumber"
              class="payment-header pt-0"
            >
              <b-col cols="1">{{ item.description }}</b-col>

              <b-col cols="2">
                <b-col cols="12">{{item.bureauCustomerName}}</b-col>
                <b-col cols="12">SUN: {{item.serviceUserNumber}}</b-col>
              </b-col>

              <b-col cols="3"></b-col>
              <b-col cols="2">Contra: {{item.contraNarrative}}</b-col>
              <b-col cols="3">
                <b-col cols="12">
                  Debits:
                  {{ item.totalNumberOfDebits | number }}
                  (
                  <strong>{{ item.totalDebitsValue | currency }}</strong>)
                </b-col>
                <b-col cols="12">
                  Credits:
                  {{ item.totalNumberOfCredits | number }}
                  (
                  <strong>{{ item.totalCreditsValue | currency }}</strong>
                  )
                </b-col>
              </b-col>
              <b-col cols="1">
                <span v-if="item.hasMustFix">
                  <i class="fa fa-times-circle mr-1"></i>
                </span>
                <span v-else-if="item.hasWarnings">
                  <i class="fa fa-exclamation-triangle mr-1"></i>
                </span>
                <span v-else-if="item.hasDuplicates">
                  <i class="fa fa-clone mr-1"></i>
                </span>
                <span v-else-if="item.hasMessages">
                  <i class="fa fa-info-circle"></i>
                </span>
                <span class="float-right">
                  <b-dropdown
                    variant="link"
                    size="lg"
                    no-caret
                    style="margin-top:-10px;margin-right:-30px;"
                  >
                    <template slot="button-content">
                      <i class="fa fa-ellipsis-h" style="color:#878787"></i>
                      <span class="sr-only">Search</span>
                    </template>
                    <b-dropdown-item
                      v-if="!readonly"
                      :disabled="groupUserRights.edit === false"
                      @click.stop="onInsertPaymentClick(item)"
                    >Insert Payment</b-dropdown-item>
                    <b-dropdown-item
                      v-if="!readonly"
                      @click.stop="onValidateBlockClick(item)"
                    >Validate Payments</b-dropdown-item>
                    <b-dropdown-divider v-if="!readonly"></b-dropdown-divider>
                    <b-dropdown-item @click.stop="onShowBlockDetailsClick(item)">Block Details</b-dropdown-item>
                    <b-dropdown-divider v-if="!readonly"></b-dropdown-divider>
                    <b-dropdown-item
                      v-if="!readonly"
                      :disabled="groupUserRights.edit === false"
                      @click.stop="onZeroPaymentsForBlockClick(item.fileNumber)"
                    >Zero Payments</b-dropdown-item>
                    <b-dropdown-item v-if="!readonly" @click.stop="onDeleteBlock(item)">Remove Block</b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown-item
                    v-if="!readonly"
                    :disabled="groupUserRights.edit === false"
                    @click.stop="onRemoveZeroPaymentsForBlock(item.fileNumber)"
                  >Remove All Zero Payments</b-dropdown-item>
                </span>
              </b-col>
            </b-row>
          </b-card-header>

          <b-collapse
            :id="'item-' + item.bureauSubmissionId + item.fileNumber"
            accordion="my-accordion"
            @show="onShowPayments(item)"
            @hide="onHidePayments()"
            role="tabpanel"
          >
            <b-table
              id="blockValidationMessages"
              borderless
              small
              thead-class="hidden_header"
              class="mt-0 mb-0"
              :items="blockValidationMessages"
              :fields="blockValidationMessagesColumns"
            >
              <template v-slot:cell(type)="data">
                <span v-bind:class="data.value" class="badge badge-pill table-pill">{{ data.value }}</span>
              </template>
            </b-table>

            <vue-good-table
              ref="paymentsTable"
              mode="remote"
              styleClass="vgt-table striped bordered"
              :rows="payments"
              :columns="columns"
              :lineNumbers="true"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :paginationOptions="{
                              enabled: true,
                              perPage: serverParams.perPage
                            }"
              @on-row-click="onManagePayment"
            >
              <template slot="loadingContent">
                <h1>Loading...</h1>
              </template>

              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.label === 'Amount'">
                  <div>{{ props.formattedRow[props.column.field] | currency }}</div>
                </span>
                <span v-else-if="props.column.label === 'Issues'">
                  <span v-if="props.row['fix'] > 0">
                    <i class="fa fa-times-circle mr-1"></i>
                  </span>
                  <span v-else-if="props.row['warnings'] > 0">
                    <i class="fa fa-exclamation-triangle mr-1"></i>
                  </span>
                  <span v-else-if="props.row['duplicates'] > 0">
                    <i class="fa fa-clone mr-1"></i>
                  </span>
                  <span v-else-if="props.row['information'] > 0">
                    <i class="fa fa-info-circle"></i>
                  </span>
                </span>
                <span v-else-if="props.column.field == 'delete'">
                  <span>
                    <i
                      class="fa fa-trash"
                      v-if="!readonly"
                      :disabled="groupUserRights.delete === false"
                      @click.stop="onDeletePaymentClick(props)"
                    ></i>
                  </span>
                </span>
                <span v-else>
                  <div>{{ props.formattedRow[props.column.field] }}</div>
                </span>
              </template>
            </vue-good-table>
          </b-collapse>
        </b-card>

        <b-row>
          <b-col class="mt-2">There are {{ this.submissionDetailCount }} block(s) in the submission</b-col>
          <b-col v-if="this.submissionDetailCount > 10">
            <b-pagination
              class="mt-2 float-right"
              v-model="submissionDetailsPage"
              :total-rows="submissionDetailCount"
              :per-page="10"
              size="sm"
              @input="loadSubmissionSummary()"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="idb-block-footer">
      <button
        class="btn btn-outline-primary ml-1"
        v-if="!readonly"
        @click.stop="onImportedFilesViewClick()"
        :disabled="isLoading || groupUserRights.add === false"
      >View Imported Files</button>
      <button
        class="btn btn-outline-primary ml-1"
        v-if="!readonly"
        @click.stop="onAddFilesClick()"
        :disabled="isLoading || groupUserRights.add === false"
      >Add Files</button>
      <button
        class="btn btn-outline-primary ml-2"
        v-if="!readonly"
        @click.stop="onAddBlockClick()"
        :disabled="isLoading || groupUserRights.add === false"
      >Add Manual Block</button>

      <button
        class="btn btn-success ml-2"
        v-if="!readonly"
        @click.stop="onStartSubmissionClick()"
        :disabled="isLoading || groupUserRights.submit === false"
      >Start Submission Process</button>
      <b-button
        class="float-right"
        variant="danger"
        v-if="!readonly"
        @click.stop="onZeroPaymentsForSubmissionClick()"
        :disabled="isLoading || groupUserRights.edit === false"
      >Zero All Payments</b-button>
    </div>

    <!-- Submission Details Modal -->
    <!-- <b-modal
      id="submissionDetailsModal"
      ref="submissionDetailsModal"
      title="Submission Details"
      size="lg"
      hide-header-close
      lazy
    >
      <submissionDetails
        ref="submissionDetailsComponent"
        id="submissionDetailsComponent"
        :submissionReferenceProperty="submission.submissionReference"
        :contraNarrativeProperty="submission.contraNarrative"
        :processingDateProperty="submission.processingDate"
        :paymentDateProperty="submission.paymentDate"
        :readonly="readonly"
      ></submissionDetails>
    </b-modal>-->

    <!-- Manage Payment. Modal -->
    <!-- <b-modal
      id="managePaymentModal"
      ref="managePaymentModal"
      title="Manage Payment"
      size="xl"
      hide-header-close
      static
      :disabledFields="readOnlyModal"
      @ok="validatePayment"
    >
      <managePayment id="managePaymentComponent" ref="managePaymentComponent"></managePayment>
    </b-modal>-->

    <div>
      <managePaymentFPSModal
        :itemId="currentItemId"
        :submissionId="submissionId"
        :bureauJobId="submission.bureauJobId"
        :isNew="currentIsNew"
        :pkid="currentpkid"
        v-on:submit="manageBlockModalClosed"
      ></managePaymentFPSModal>
    </div>

    <div>
      <manageBlockFPSModal
        :itemId="currentItemId"
        :submissionId="submissionId"
        :bureauJobId="submission.bureauJobId"
        :isNew="currentIsNew"
        v-on:submit="manageBlockModalClosed"
      ></manageBlockFPSModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import ManagePaymentFPS from '@/Components/Bureau/BureauJob/ManagePaymentFPS.vue'
import ManageBlockFPS from '@/Components/Bureau/BureauJob/ManageBlockFPS.vue'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

import _ from 'lodash'

export default {
  mixins: [Utility, loading, tableFilterMixin],
  data () {
    return {
      emptyGuid: '00000000-0000-0000-0000-000000000000',
      submissionId: '',
      submission: [],
      currentItemId: '',
      currentpkid: '',
      currentIsNew: false,
      readOnlyModal: false,
      groupUserRights: {},
      currentBacsSubmissionFileDetail: {},
      currentPaymentRow: {},
      submissionSummaryItems: [],
      submissionDetailCount: 0,
      blockValidationMessages: [],
      blockValidationMessagesColumns: [
        {
          label: 'Message',
          field: 'message'
        },
        {
          label: 'Type',
          field: 'type'
        }
      ],
      payments: [],
      columns: [
        {
          label: 'Record Number',
          field: 'recordNumber',
          hidden: true
        },
        {
          label: 'Name',
          field: 'thirdPartyAccountName',
          filterOptions: { enabled: true },
          sortable: true
        },
        {
          label: 'Sort Code',
          field: 'thirdPartySortCode',
          filterOptions: { enabled: true },
          sortable: true
        },
        {
          label: 'Account Number',
          field: 'thirdPartyAccountNumber',
          filterOptions: { enabled: true },
          sortable: true
        },
        {
          label: 'User Ref',
          field: 'userReference',
          filterOptions: { enabled: true },
          sortable: true
        },
        {
          label: 'TX Code',
          field: 'transactionCode',
          filterOptions: { enabled: true },
          sortable: true
        },
        {
          label: 'Amount',
          field: 'amount',
          filterOptions: { enabled: true },
          sortable: true
        },
        {
          label: 'Issues',
          field: 'issues',
          sortable: true
        },
        {
          field: 'delete',
          sortable: false
        }
      ],
      submissionDetailsPage: 1,
      totalDebitCount: 0,
      totalDebitValue: 0.00,
      totalCreditCount: 0,
      totalCreditValue: 0.00,
      totalDDICount: 0,
      // serverParams: {
      //   columnFilters: {
      //   },
      //   sort: {
      //     field: '',
      //     type: ''
      //   },
      //   page: 1,
      //   perPage: 10
      // },
      serverParams: {
        sort: [{ field: '', type: '' }]
      },
      totalRecords: 0,
      paymentsDisplayed: false
    }
  },
  components: {
    managePaymentFPSModal: ManagePaymentFPS,
    manageBlockFPSModal: ManageBlockFPS
  },
  computed: {
    // paygateId () { return this.$store.state.common.paygateId },
    // submission () { return this.$store.state.bureau.submission },
    numberOfFiles () {
      return this.submissionDetailCount
    },
    readonly () {
      const readonlyStatusValues = [
        'Signing',
        'AwaitingApproval',
        'Approved',
        'Submitting',
        'Submitted',
        'Complete'
        // 'Validated' // only for debugging
      ]
      return _.includes(readonlyStatusValues, this.submission.status)
    }
  },
  async created () {
    this.submissionId = this.$route.params.id
    await this.loadSubmissionDetails()
    await this.getSelectedGroupDetails()
    await this.loadSubmissionSummary()
  },
  async mounted () {

  },
  beforeDestroy () {
    this.$root.$off('bv::modal::hide')
  },
  methods: {
    onImportedFilesViewClick () {
      this.$router.push({ name: 'ImportedPaymentFilesSummary', params: { id: this.submissionId } })
    },
    loadSubmissionDetails: async function () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getSubmissionData/' + this.submissionId, { showload: true })
      this.submission = response.data
    },
    async getSelectedGroupDetails () {
      var userId = this.$store.getters.getClaim('sub').value
      var groupResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/permissions/${this.submission.groupId}/${userId}`)
      this.groupUserRights = groupResponse.data
      this.readOnlyModal = this.groupUserRights.edit === false
    },
    async load () {
      console.info('Load payments')
      this.payments = []
      this.blockValidationMessages = []
      const submissionId = this.submission.bureauSubmissionId

      this.loading = true

      if (Object.entries(this.currentBacsSubmissionFileDetail).length === 0 && this.currentBacsSubmissionFileDetail.constructor === Object) {
        console.info('Nothing current')
        return
      }

      var fileNumber = this.currentBacsSubmissionFileDetail.fileNumber
      // try {
      //   const json = {
      //     submissionId: submissionId,
      //     fileNumber: fileNumber,
      //     page: this.serverParams.page,
      //     perPage: this.serverParams.perPage,
      //     sortColumn: this.serverParams.sort.field,
      //     sortDirection: this.serverParams.sort.type
      //   }

      try {
        this.isTableLoading = true
        var params = { ...this.buildGoodTableQuery(), itemid: this.currentBacsSubmissionFileDetail.itemId }

        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getpaymentswithvalidationstats`,
          {
            params,
            showerror: true,
            errormessage: 'Failed to load payments'
          })

        const paymentsUnsorted = response.data.data
        const paymentsSorted = _.orderBy(paymentsUnsorted, ['fix', 'warnings', 'duplicates', 'information'], ['desc', 'desc', 'desc', 'desc'])
        this.payments = paymentsSorted
        this.blockValidationMessages = response.data.blockValidationMessages
      } catch (e) {

      } finally {
        this.isTableLoading = false
      }

      try {
        const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getnumberoftransactionsinfile/' + submissionId + '/' + fileNumber, { showload: true })
        this.totalRecords = response.data
      } catch (errorTransactionCount) {
        console.error(errorTransactionCount)
      }
      this.loading = false
    },
    async loadSubmissionSummary () {
      try {
        const data = {
          sort: 'description',
          perPage: 10,
          page: this.submissionDetailsPage
        }
        const id = this.submission.bureauSubmissionId
        const response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getbacssubmissionfiledetails/' + id, { params: data }, { showload: true })

        this.submissionDetailCount = response.data.meta.totalItems
        this.submissionSummaryItems = response.data.data
      } catch (error) {
        console.error(error)
      }
      await this.getSummaryInfo()
    },
    async onShowPayments (bacsSubmissionFileDetail) {
      this.currentBacsSubmissionFileDetail = bacsSubmissionFileDetail
      this.serverParams.page = 1
      this.load()
      this.paymentsDisplayed = true
    },
    onHidePayments () {
      this.paymentsDisplayed = false
    },
    onManagePayment (item) {
      this.currentIsNew = false
      this.currentItemId = item.row.itemId
      this.currentpkid = item.row.pkid

      this.$bvModal.show('manage-payment-modal')
    },

    async onInsertPaymentClick (item) {
      this.currentIsNew = true
      this.currentpkid = this.emptyGuid
      this.currentItemId = item.itemId

      this.$bvModal.show('manage-payment-modal')
    },

    onAddFilesClick () {
      this.$router.push({ name: 'UploadAdditionalFilesFPS', params: { id: this.submissionId } })
    },
    onAddBlockClick () {
      this.currentItemId = ''
      this.currentIsNew = true
      this.$bvModal.show('manage-block-modal')
    },
    async onShowBlockDetailsClick (item) {
      this.currentItemId = item.itemId
      this.currentIsNew = false

      this.$bvModal.show('manage-block-modal')
    },

    async manageBlockModalClosed () {
      await this.loadSubmissionDetails()
      await this.getSelectedGroupDetails()
      await this.loadSubmissionSummary()
    },

    async onValidateBlockClick (item) {
      console.info('onValidateBlockClick')
      console.info(item)
      const data = {
        submissionId: this.submission.bureauSubmissionId,
        fileNumber: item.fileNumber
      }
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/validateBlock', data, { showload: true })
      this.$toastr.s('Block validated', 'Bureau Submission')
      // await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/endTask?submissionId=' + this.submission.bureauSubmissionId + '&fileNumber=' + item.fileNumber, { showload: true })
      this.loadSubmissionSummary()
      this.load()
    },
    async onDeletePaymentClick (props) {
      try {
        const data = {
          submissionId: this.submissionId,
          fileNumber: props.row.fileNumber,
          recordNumber: props.row.recordNumber
          // paygateId: props.row.paygateId
        }
        console.info('Deleting payment with following data:')
        console.info(data)
        const response = await axios.delete(process.env.VUE_APP_BACS_API_URL + 'bacs/payment', { data: data }, { showload: true })

        if (response.data.success) {
          // await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionStatus/' + this.submission.bureauSubmissionId, { showload: true })
          // this.getSummaryInfo()
          this.loadSubmissionSummary()
          this.load()
          this.$toastr.s('Payment deleted', 'Bureau Submission')
        } else {
          this.$toastr.e(response.data.errorMessage, 'Bureau Submission')
        }
      } catch (error) {
        console.error('onDeletePaymentClick')
        console.error(error)
      }
    },
    async onDeleteBlock (item) {
      console.info('OnDeleteBlock')
      console.info(item)

      const swalResult = await swal.fire({
        title: 'Delete Payment Block',
        text: `Are you sure you want to remove the block : ${item.filename} (${item.fileNumber})`,
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: '#f74a55',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      let failed = false
      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: item.submissionId,
            fileNumber: item.fileNumber,
            itemId: item.itemId
          }
          const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteBlock', data, { showload: true })
        } catch (error) {
          failed = true
          console.error('Error in UploadFiles.OnDeleteBlock.DeleteSubmissionDetail')
          console.error(error)
        }
      }

      this.loadSubmissionSummary()

      if (failed) {
        this.$toastr.e('Failed to remove file ' + item.filename, 'Bureau Submission')
      } else {
        this.$toastr.s('Removed file ' + item.filename, 'Bureau Submission')
      }
    },
    onShowInformationMessages () {
      event.stopPropagation()
      swal.fire('Show Information Messages')
    },
    onShowDuplicateMessages () {
      event.stopPropagation()
      swal.fire('Show Duplicate Messages')
    },
    onShowFixMessages () {
      event.stopPropagation()
      swal.fire('Show Fix Messages')
    },
    onShowWarningMessages () {
      event.stopPropagation()
      swal.fire('Show Warning Messages')
    },
    onShowWarnings () {
      event.stopPropagation()
      swal.fire('Show Warnings')
    },
    onShowErrors () {
      event.stopPropagation()
      swal.fire('Show Errors')
    },
    onDisplayImportWarnings (importMessages) {
      event.stopPropagation()
      let swalMessage = ''
      importMessages.forEach(message => {
        swalMessage += message + '<br>'
      })

      const options = {
        title: 'Import Messages',
        icon: 'warning',
        width: '50rem',
        html: swalMessage
      }
      swal.fire(options)
    },
    async onZeroPaymentsForSubmissionClick () {
      const swalResult = await swal.fire({
        title: 'Zero Payments for the submission',
        text: 'Are you sure you want to zero the payments for this SUBMISSION',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: '#f74a55',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: this.submissionDetails.bureauSubmissionId,
            reference: this.submission.submissionReference,
            fileNumber: 0
          }
          await axios.post(process.env.VUE_APP_BACS_API_URL + 'bacs/payment/resetAmountsToZero', data, { showload: true })
          await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionStatus/' + this.submission.bureauSubmissionId, { showload: true })
          await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionDetailStatus/' + this.currentBacsSubmissionFileDetail.itemId, { showload: true })
          this.$toastr.s('Payments zeroed for submission', 'Bureau Submission')
          // this.getSummaryInfo()
          this.loadSubmissionSummary()
          this.load()
        } catch (error) {

        }
      }
    },
    async onRemoveZeroPaymentsForBlock (fileNumber) {
      const swalResult = await swal.fire({
        title: 'Zero Payments for Block',
        text: 'Are you sure you want to remove all zero payments for this block',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: this.submission.bureauSubmissionId,
            fileNumber: fileNumber,
            itemid: this.currentBacsSubmissionFileDetail.itemId
          }
          console.info(data)
          const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/removeAllZeroPaymentsFromBlock/', data, { showload: true })
          if (response.data.success) {
            await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionStatus/' + this.submission.bureauSubmissionId, { showload: true })
            await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionDetailStatus/' + this.currentBacsSubmissionFileDetail.itemId, { showload: true })
            this.$toastr.s('Payments zeroed for block', 'Bureau Submission')
            // this.getSummaryInfo()
            this.loadSubmissionSummary()
            this.load()
          } else {
            this.$toastr.e(response.data.errorMessage, 'Bureau Submission')
          }
        } catch (error) {

        }
      }
    },

    async onZeroPaymentsForBlockClick (fileNumber) {
      const swalResult = await swal.fire({
        title: 'Zero Payments for Block',
        text: 'Are you sure you want to zero the payments for this block',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: '#f74a55',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          const data = {
            submissionId: this.submission.bureauSubmissionId,
            reference: this.submission.submissionReference,
            fileNumber: fileNumber
          }
          console.info(data)
          const response = await axios.post(process.env.VUE_APP_BACS_API_URL + 'bacs/payment/resetAmountsToZero', data, { showload: true })
          if (response.data.success) {
            await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionStatus/' + this.submission.bureauSubmissionId, { showload: true })
            await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionDetailStatus/' + this.currentBacsSubmissionFileDetail.itemId, { showload: true })
            this.$toastr.s('Payments zeroed for block', 'Bureau Submission')
            // this.getSummaryInfo()
            this.loadSubmissionSummary()
            this.load()
          } else {
            this.$toastr.e(response.data.errorMessage, 'Bureau Submission')
          }
        } catch (error) {

        }
      }
    },
    async getSummaryInfo () {
      try {
        console.info('getSummaryInfo')
        const getsubmissioninforesponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getsubmissioninfo/' + this.submission.bureauSubmissionId, { showload: true })
        console.info(getsubmissioninforesponse)
        this.totalDebitCount = getsubmissioninforesponse.data.debitCount
        this.totalDebitValue = getsubmissioninforesponse.data.debitValue
        this.totalCreditCount = getsubmissioninforesponse.data.creditCount
        this.totalCreditValue = getsubmissioninforesponse.data.creditValue
        this.totalDDICount = getsubmissioninforesponse.data.ddiCount
      } catch (error) {
        console.error('getSummaryInfo')
        console.error(error)
      }
    },
    async insertPayment () {
      console.info('insertPayment')
      this.populateCurrentPaymentFromModal()

      try {
        console.info('Insert via BACS API')
        this.currentPaymentRow.thirdPartySortCode = this.currentPaymentRow.thirdPartySortCode.replace(/-/g, '')
        console.info(this.currentPaymentRow)
        const response = await axios.post(process.env.VUE_APP_BACS_API_URL + 'bacs/payment', this.currentPaymentRow, { showload: true })
        if (response.data.success) {
          // await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionStatus/' + this.submission.bureauSubmissionId, { showload: true })
          this.$toastr.s('Payment added', 'Bureau Payment')
          this.serverParams.page = 1
          // this.getSummaryInfo()
          this.loadSubmissionSummary()
          this.load()
        } else {
          this.$toastr.e(response.data.errorMessage, 'Bureau Payment')
        }
      } catch (error) {
        this.$toastr.e('Failed to add payment', 'Bureau Payment')
        console.error(error)
      }
    },
    async updatePayment () {
      console.info('updatePayment')
      this.populateCurrentPaymentFromModal()

      try {
        console.info('Update via BACS API')
        this.currentPaymentRow.thirdPartySortCode = this.currentPaymentRow.thirdPartySortCode.replace(/-/g, '')
        const submissionProcessingDate = new Date(this.submission.processingDate)
        const paymentProcessingDate = new Date(this.currentPaymentRow.processingDate)
        // console.info(`Submission Processing Date: ${submissionProcessingDate}`)
        // console.info(`Payment Processing Date: ${paymentProcessingDate}`)
        // console.info(paymentProcessingDate.toLocaleDateString('en-GB') == submissionProcessingDate.toLocaleDateString('en-GB'))
        // Update the payment processing date if different from the submission processing date
        // The processing date on the bacspayment record is a string in the format DD/MM/YYYY
        // Should this be updated to a datetime field in the database?
        if (paymentProcessingDate.toLocaleDateString('en-GB') === submissionProcessingDate.toLocaleDateString('en-GB')) {
          this.currentPaymentRow.processingDate = ''
        } else {
          this.currentPaymentRow.processingDate = paymentProcessingDate.toLocaleDateString('en-GB')
        }
        const response = await axios.put(process.env.VUE_APP_BACS_API_URL + 'bacs/payment', this.currentPaymentRow, { showload: true })
        console.info(response)
        if (response.data.success) {
          // await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/resetSubmissionStatus/' + this.submission.bureauSubmissionId, { showload: true })
          this.$toastr.s('Payment updated', 'Bureau Payment')
          // this.getSummaryInfo()
          this.loadSubmissionSummary()
          this.load()
        } else {
          this.$toastr.e(response.data.errorMessage, 'Bureau Payment')
          console.error('Error')
          console.error(response.data)
        }
      } catch (error) {
        this.$toastr.e('Failed to update payment', 'Bureau Payment')
        console.error(error)
      }
    },
    populateCurrentPaymentFromModal () {
      this.currentPaymentRow.processingDate = this.$refs.managePaymentComponent.processingDate
      this.currentPaymentRow.thirdPartyAccountName = this.$refs.managePaymentComponent.thirdPartyAccountName
      this.currentPaymentRow.thirdPartySortCode = this.$refs.managePaymentComponent.thirdPartySortCode
      this.currentPaymentRow.thirdPartyAccountNumber = this.$refs.managePaymentComponent.thirdPartyAccountNumber
      this.currentPaymentRow.userReference = this.$refs.managePaymentComponent.userReference
      this.currentPaymentRow.transactionCode = this.$refs.managePaymentComponent.transactionCode
      this.currentPaymentRow.amount = this.$refs.managePaymentComponent.amount
      this.currentPaymentRow.amountText = this.$refs.managePaymentComponent.amount + ''
    },
    async populateModalFromCurrentPayment (isNew) {
      console.info(`populateModalFromCurrentPayment - new: ${isNew}`)
      console.info(this.currentPaymentRow)
      this.$refs.managePaymentComponent.processingDate = this.currentPaymentRow.processingDate
      this.$refs.managePaymentComponent.thirdPartyAccountName = this.currentPaymentRow.thirdPartyAccountName
      this.$refs.managePaymentComponent.thirdPartySortCode = this.currentPaymentRow.thirdPartySortCode
      this.$refs.managePaymentComponent.thirdPartyAccountNumber = this.currentPaymentRow.thirdPartyAccountNumber
      this.$refs.managePaymentComponent.userReference = this.currentPaymentRow.userReference
      this.$refs.managePaymentComponent.transactionCode = this.currentPaymentRow.transactionCode
      this.$refs.managePaymentComponent.amount = this.currentPaymentRow.amount
      this.$refs.managePaymentComponent.amountText = this.currentPaymentRow.amountText + ''
      this.$refs.managePaymentComponent.isNew = isNew
      this.$refs.managePaymentComponent.readonly = this.readonly

      // Get validation errors
      const hasValidationErrors = (this.currentPaymentRow.information + this.currentPaymentRow.duplicates + this.currentPaymentRow.warnings + this.currentPaymentRow.fix) > 0
      if (hasValidationErrors) {
        try {
          const submissionId = this.currentPaymentRow.submissionId
          const fileNumber = this.currentPaymentRow.fileNumber
          const recordNumber = this.currentPaymentRow.recordNumber
          const url = `${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getpresubvalidationmessages/${submissionId}/${fileNumber}/${recordNumber}`
          console.info(`Getting validation errors from ${url}`)
          const response = await axios.get(url, { showload: true })
          this.$refs.managePaymentComponent.validationMessages = []
          response.data.forEach(message => {
            const descriptionLowerCase = message.description.toLowerCase()
            const isBlockValidation = _.includes(descriptionLowerCase, ['originating', 'submission contains'])
            if (!isBlockValidation) {
              const row = {
                title: message.title,
                description: message.description,
                messageSeverity: message.messageSeverity
              }
              this.$refs.managePaymentComponent.validationMessages.push(row)
            }
          })
        } catch (errorTransactionCount) {

        }
      } else {
        this.$refs.managePaymentComponent.validationMessages = []
      }
    },
    validatePayment (bvModalEvent) {
      if (this.$refs.managePaymentComponent.$v.$invalid) {
        bvModalEvent.preventDefault()
      }
    },
    validateBlock (bvModalEvent) {
      if (this.$refs.manageBlockComponent.$v.$invalid) {
        bvModalEvent.preventDefault()
      }
    },
    onStartSubmissionClick () {
      this.$router.push({ name: 'PreSubmissionValidation', params: { id: this.submissionId } })
    }
  }
}
</script>

<style scoped>
.btn-secondary,
.btn-secondary:active,
.btn-secondary:hover,
.btn-secondary:focus {
  background: linear-gradient(#f4f5f8, #f1f3f6);
  border: 0px solid #dcdfe6;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-radius: 0;
}

.payment-header {
  height: 40px;
  padding: 0.75em 1.5em 0.75em 0.75em;
}

.popover {
  max-width: 800px;
}

#blockValidationMessages thead {
  display: none;
}

.hidden_header {
  display: none;
}

.fa-exclamation-circle {
  color: red;
}

.fa-check {
  color: green;
}

.fa-exclamation-triangle {
  color: yellow;
  text-shadow: 1px 1px 2px #000;
}

.fa-exclamation-circle {
  color: blue;
}

.fa-info-circle {
  color: blue;
}

.fa-clone {
  color: grey;
}

.fa-times-circle {
  color: red;
}
</style>
