var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-client" } },
    [
      [
        _c("loading", { staticClass: "main", attrs: { loading: _vm.loading } }),
        _c("router-view"),
        _c("vue-progress-bar", { attrs: { id: "loading-bar" } })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }