var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", [
                _c(
                  "div",
                  _vm._l(_vm.schema, function(field, index) {
                    return _c(
                      field.fieldType,
                      _vm._b(
                        {
                          key: index,
                          tag: "component",
                          attrs: { value: _vm.formData[field.name] },
                          on: {
                            input: function($event) {
                              return _vm.updateForm(field.name, $event)
                            }
                          }
                        },
                        "component",
                        field,
                        false
                      )
                    )
                  }),
                  1
                )
              ])
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm._t("footer", [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "danger", title: "Edit Mapping" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel")
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "fa fa-times",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" Cancel ")
                  ]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success", title: "Edit Mapping" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.btnSave($event)
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "fa fa-save",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" Save ")
                  ]
                )
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }