var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "synchronisationReportDetails" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c("div", { staticClass: "idb-block" }, [
                _c("div", { staticClass: "idb-block-title" }, [
                  _c(
                    "h2",
                    [
                      _c("help-icon", {
                        attrs: {
                          docPath: "/paygate-collections/reconciliation/"
                        }
                      }),
                      _vm._v("Synchronisation Report Details")
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "idb-block-content" },
                  [
                    _vm.reports.length === 0
                      ? _c("span", [
                          _vm._v(
                            "Synchronisation was successful, but no reports were available to synchronise."
                          )
                        ])
                      : _vm._e(),
                    _vm.reports.length > 0
                      ? _c(
                          "b-tabs",
                          _vm._l(_vm.reports, function(report) {
                            return _c(
                              "b-tab",
                              {
                                key: _vm.removeDupeString(report.filename),
                                attrs: {
                                  title:
                                    _vm.removeDupeString(report.filename) +
                                    " (" +
                                    _vm.getReportType(report.reporttype) +
                                    ")"
                                }
                              },
                              [
                                _c("vue-good-table", {
                                  ref: "reportList",
                                  refInFor: true,
                                  attrs: {
                                    columns: _vm.columns,
                                    rows: report.messages,
                                    "pagination-options": {
                                      enabled: true,
                                      perPage: 10,
                                      dropdownAllowAll: false
                                    },
                                    styleClass: "vgt-table striped bordered"
                                  },
                                  on: { "on-row-click": _vm.viewReport }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "idb-block-footer mt-2" },
                  [
                    _vm.actionId && _vm.actionId !== null
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              disabled: _vm.isLoading
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removeAction($event)
                              }
                            }
                          },
                          [_vm._v("Clear Action")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }