var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group scrollable" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm.group.groupUserRights.$error
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _c(
                  "ul",
                  [
                    !_vm.group.groupUserRights.groupAdmin
                      ? _c("li", [
                          _vm._v("You require at least one group administrator")
                        ])
                      : _vm._e(),
                    _vm._l(_vm.group.groupUserRights.$each.$iter, function(
                      user
                    ) {
                      return _c("div", { key: user.userId.$model }, [
                        user.uploadPath.$error
                          ? _c("li", [
                              _vm._v(
                                _vm._s(user.userName.$model) +
                                  "'s File Upload Options are incorrect"
                              )
                            ])
                          : _vm._e(),
                        user.required.$error
                          ? _c("li", [
                              _vm._v(
                                _vm._s(user.userName.$model) +
                                  " needs at least one group role"
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.group.$model.groupType == _vm.groupTypes.bacs &&
          _vm.group.groupUserRights.$model.length > 0
            ? _c("table", { staticClass: "table table-header-rotated" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "If a user has this role for a group then the group will be available on the create submission page.",
                                expression:
                                  "'If a user has this role for a group then the group will be available on the create submission page.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Create")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to add payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to add payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Add")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.",
                                expression:
                                  "'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Edit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to delete imported payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to delete imported payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Delete")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User will be able to sign a submission with a BACS smartcard or via the HSM assuming it's a BACS submission.",
                                expression:
                                  "'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\\'s a BACS submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Sign")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can approve a submission.",
                                expression: "'User can approve a submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.",
                                expression:
                                  "'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve Own")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can send a submission to BACS.",
                                expression:
                                  "'User can send a submission to BACS.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Submit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.",
                                expression:
                                  "'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Collect Reports")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "The group administrator can make any changes to a group including user's roles in the group. At least one user must be a group administrator.",
                                expression:
                                  "'The group administrator can make any changes to a group including user\\'s roles in the group. At least one user must be a group administrator.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Group Administrator")])]
                        )
                      ]
                    ),
                    _vm._m(0),
                    _c("th", { staticStyle: { width: "50px" } }),
                    _c("th")
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.group.groupUserRights.$each.$iter, function(user) {
                    return _c("tr", { key: user.userId.$model }, [
                      _c(
                        "td",
                        {
                          staticClass: "row-header",
                          staticStyle: { width: "150px" }
                        },
                        [_vm._v(_vm._s(user.userName.$model))]
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_create" }
                            },
                            [_vm._v("Create")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_create",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.create.$model,
                              callback: function($$v) {
                                _vm.$set(user.create, "$model", $$v)
                              },
                              expression: "user.create.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_add" }
                            },
                            [_vm._v("Add")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_add",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.add.$model,
                              callback: function($$v) {
                                _vm.$set(user.add, "$model", $$v)
                              },
                              expression: "user.add.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_edit" }
                            },
                            [_vm._v("Edit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_edit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.edit.$model,
                              callback: function($$v) {
                                _vm.$set(user.edit, "$model", $$v)
                              },
                              expression: "user.edit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_delete" }
                            },
                            [_vm._v("Delete")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_delete",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.delete.$model,
                              callback: function($$v) {
                                _vm.$set(user.delete, "$model", $$v)
                              },
                              expression: "user.delete.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_sign" }
                            },
                            [_vm._v("Sign")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_sign",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.sign.$model,
                              callback: function($$v) {
                                _vm.$set(user.sign, "$model", $$v)
                              },
                              expression: "user.sign.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approval" }
                            },
                            [_vm._v("Approval")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_approval",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approval.$model,
                              callback: function($$v) {
                                _vm.$set(user.approval, "$model", $$v)
                              },
                              expression: "user.approval.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approveOwn" }
                            },
                            [_vm._v("Can Approve Own Submission")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_approveOwn",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approveOwnSubmission.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  user.approveOwnSubmission,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "user.approveOwnSubmission.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_submit" }
                            },
                            [_vm._v("Submit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_submit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.submit.$model,
                              callback: function($$v) {
                                _vm.$set(user.submit, "$model", $$v)
                              },
                              expression: "user.submit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_collectreports"
                              }
                            },
                            [_vm._v("Collect Reports")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId: user.userId.$model + "_collectreports",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.collectReports.$model,
                              callback: function($$v) {
                                _vm.$set(user.collectReports, "$model", $$v)
                              },
                              expression: "user.collectReports.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_groupAdministrator"
                              }
                            },
                            [_vm._v("Group Administrator")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              labelId:
                                user.userId.$model + "_groupAdministrator",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.groupAdministrator.$model,
                              callback: function($$v) {
                                _vm.$set(user.groupAdministrator, "$model", $$v)
                              },
                              expression: "user.groupAdministrator.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", [_vm._v(_vm._s(user.uploadType.$model))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-primary",
                            attrs: {
                              type: "button",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFileModal(user)
                              }
                            }
                          },
                          [_vm._v("Edit File Upload Options")]
                        )
                      ]),
                      _c("td", { staticStyle: { width: "20px" } }, [
                        _c("a", {
                          staticClass: "fa fa-trash",
                          attrs: {
                            role: "button",
                            disabled: _vm.group.$model.isItemActioned
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeUser(user.userId.$model)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          (_vm.group.$model.groupType === _vm.groupTypes.bureau ||
            _vm.group.$model.groupType === _vm.groupTypes.fpsBureau) &&
          _vm.group.groupUserRights.$model.length > 0
            ? _c("table", { staticClass: "table table-header-rotated" }, [
                _c("thead", [
                  _c("tr", { staticStyle: { height: "159px" } }, [
                    _c("th"),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "If a user has this role for a group then the group will be available on the create submission page.",
                                expression:
                                  "'If a user has this role for a group then the group will be available on the create submission page.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Create")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to add payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to add payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Add")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.",
                                expression:
                                  "'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Edit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to delete imported payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to delete imported payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Delete")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User will be able to sign a submission with a BACS smartcard or via the HSM assuming it's a BACS submission.",
                                expression:
                                  "'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\\'s a BACS submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Sign")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can approve a submission.",
                                expression: "'User can approve a submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approval")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.",
                                expression:
                                  "'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve Own Submission")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can send a submission to BACS.",
                                expression:
                                  "'User can send a submission to BACS.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Submit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.",
                                expression:
                                  "'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Collect Reports")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "Users with this role can collect reports from the payment processing centre.",
                                expression:
                                  "'Users with this role can collect reports from the payment processing centre.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Collect Certificate Reports")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "The group administrator can make any changes to a group including user's roles in the group. At least one user must be a group administrator.",
                                expression:
                                  "'The group administrator can make any changes to a group including user\\'s roles in the group. At least one user must be a group administrator.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Group Administrator")])]
                        )
                      ]
                    ),
                    _vm._m(1),
                    _c("th", { staticStyle: { width: "50px" } }),
                    _c("th")
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.group.groupUserRights.$each.$iter, function(user) {
                    return _c("tr", { key: user.userId.$model }, [
                      _c(
                        "td",
                        {
                          staticClass: "row-header",
                          staticStyle: { width: "150px" }
                        },
                        [_vm._v(_vm._s(user.userName.$model))]
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_create" }
                            },
                            [_vm._v("Create")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_create",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.create.$model,
                              callback: function($$v) {
                                _vm.$set(user.create, "$model", $$v)
                              },
                              expression: "user.create.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_add" }
                            },
                            [_vm._v("Add")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_add",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.add.$model,
                              callback: function($$v) {
                                _vm.$set(user.add, "$model", $$v)
                              },
                              expression: "user.add.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_edit" }
                            },
                            [_vm._v("Edit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_edit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.edit.$model,
                              callback: function($$v) {
                                _vm.$set(user.edit, "$model", $$v)
                              },
                              expression: "user.edit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_delete" }
                            },
                            [_vm._v("Delete")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_delete",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.delete.$model,
                              callback: function($$v) {
                                _vm.$set(user.delete, "$model", $$v)
                              },
                              expression: "user.delete.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_sign" }
                            },
                            [_vm._v("Sign")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_sign",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.sign.$model,
                              callback: function($$v) {
                                _vm.$set(user.sign, "$model", $$v)
                              },
                              expression: "user.sign.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approval" }
                            },
                            [_vm._v("Approval")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approval",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approval.$model,
                              callback: function($$v) {
                                _vm.$set(user.approval, "$model", $$v)
                              },
                              expression: "user.approval.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approveOwn" }
                            },
                            [_vm._v("Can Approve Own Submission")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approveOwn",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approveOwnSubmission.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  user.approveOwnSubmission,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "user.approveOwnSubmission.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_submit" }
                            },
                            [_vm._v("Submit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_submit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.submit.$model,
                              callback: function($$v) {
                                _vm.$set(user.submit, "$model", $$v)
                              },
                              expression: "user.submit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_collectreports"
                              }
                            },
                            [_vm._v("Collect Reports")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_collectreports",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.collectReports.$model,
                              callback: function($$v) {
                                _vm.$set(user.collectReports, "$model", $$v)
                              },
                              expression: "user.collectReports.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for:
                                  user.userId.$model +
                                  "_collectCertificateLevelReports"
                              }
                            },
                            [_vm._v("Collect Certificate Level Reports")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model +
                                "_collectCertificateLevelReports",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.collectCertificateLevelReports.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  user.collectCertificateLevelReports,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "user.collectCertificateLevelReports.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_groupAdministrator"
                              }
                            },
                            [_vm._v("Group Administrator")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_groupAdministrator",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.groupAdministrator.$model,
                              callback: function($$v) {
                                _vm.$set(user.groupAdministrator, "$model", $$v)
                              },
                              expression: "user.groupAdministrator.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", [_vm._v(_vm._s(user.uploadType.$model))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-primary",
                            attrs: {
                              type: "button",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFileModal(user)
                              }
                            }
                          },
                          [_vm._v("Edit File Upload Options")]
                        )
                      ]),
                      _c("td", { staticStyle: { width: "20px" } }, [
                        _c("a", {
                          staticClass: "fa fa-trash",
                          attrs: {
                            role: "button",
                            disabled: _vm.group.$model.isItemActioned
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeUser(user.userId.$model)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.group.$model.groupType == _vm.groupTypes.bacsOnline &&
          _vm.group.groupUserRights.$model.length > 0
            ? _c("table", { staticClass: "table table-header-rotated" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "If a user has this role for a group then the group will be available on the create submission page.",
                                expression:
                                  "'If a user has this role for a group then the group will be available on the create submission page.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Create")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to add payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to add payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Add")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.",
                                expression:
                                  "'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Edit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to delete imported payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to delete imported payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Delete")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User will be able to sign a submission with a BACS smartcard or via the HSM assuming it's a BACS submission.",
                                expression:
                                  "'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\\'s a BACS submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Sign")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can approve a submission.",
                                expression: "'User can approve a submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.",
                                expression:
                                  "'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve Own")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.",
                                expression:
                                  "'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Collect Reports")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "The group administrator can make any changes to a group including user's roles in the group. At least one user must be a group administrator.",
                                expression:
                                  "'The group administrator can make any changes to a group including user\\'s roles in the group. At least one user must be a group administrator.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Group Administrator")])]
                        )
                      ]
                    ),
                    _vm._m(2),
                    _c("th", { staticStyle: { width: "50px" } }),
                    _c("th")
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.group.groupUserRights.$each.$iter, function(user) {
                    return _c("tr", { key: user.userId.$model }, [
                      _c(
                        "td",
                        {
                          staticClass: "row-header",
                          staticStyle: { width: "150px" }
                        },
                        [_vm._v(_vm._s(user.userName.$model))]
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_create" }
                            },
                            [_vm._v("Create")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_create",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.create.$model,
                              callback: function($$v) {
                                _vm.$set(user.create, "$model", $$v)
                              },
                              expression: "user.create.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_add" }
                            },
                            [_vm._v("Add")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_add",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.add.$model,
                              callback: function($$v) {
                                _vm.$set(user.add, "$model", $$v)
                              },
                              expression: "user.add.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_edit" }
                            },
                            [_vm._v("Edit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_edit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.edit.$model,
                              callback: function($$v) {
                                _vm.$set(user.edit, "$model", $$v)
                              },
                              expression: "user.edit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_delete" }
                            },
                            [_vm._v("Delete")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_delete",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.delete.$model,
                              callback: function($$v) {
                                _vm.$set(user.delete, "$model", $$v)
                              },
                              expression: "user.delete.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_sign" }
                            },
                            [_vm._v("Sign")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_sign",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.sign.$model,
                              callback: function($$v) {
                                _vm.$set(user.sign, "$model", $$v)
                              },
                              expression: "user.sign.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approval" }
                            },
                            [_vm._v("Approval")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approval",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approval.$model,
                              callback: function($$v) {
                                _vm.$set(user.approval, "$model", $$v)
                              },
                              expression: "user.approval.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approveOwn" }
                            },
                            [_vm._v("Can Approve Own Submission")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approveOwn",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approveOwnSubmission.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  user.approveOwnSubmission,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "user.approveOwnSubmission.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_collectreports"
                              }
                            },
                            [_vm._v("Collect Reports")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_collectreports",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.collectReports.$model,
                              callback: function($$v) {
                                _vm.$set(user.collectReports, "$model", $$v)
                              },
                              expression: "user.collectReports.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_groupAdministrator"
                              }
                            },
                            [_vm._v("Group Administrator")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_groupAdministrator",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.groupAdministrator.$model,
                              callback: function($$v) {
                                _vm.$set(user.groupAdministrator, "$model", $$v)
                              },
                              expression: "user.groupAdministrator.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", [_vm._v(_vm._s(user.uploadType.$model))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-primary",
                            attrs: {
                              type: "button",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFileModal(user)
                              }
                            }
                          },
                          [_vm._v("Edit File Upload Options")]
                        )
                      ]),
                      _c("td", { staticStyle: { width: "20px" } }, [
                        _c("a", {
                          staticClass: "fa fa-trash",
                          attrs: {
                            role: "button",
                            disabled: _vm.group.$model.isItemActioned
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeUser(user.userId.$model)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.group.$model.groupType == _vm.groupTypes.fps &&
          _vm.group.groupUserRights.$model.length > 0
            ? _c("table", { staticClass: "table table-header-rotated" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "If a user has this role for a group then the group will be available on the create submission page.",
                                expression:
                                  "'If a user has this role for a group then the group will be available on the create submission page.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Create")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to add payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to add payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Add")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.",
                                expression:
                                  "'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Edit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to delete imported payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to delete imported payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Delete")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User will be able to sign a submission with a BACS smartcard or via the HSM assuming it's a BACS submission.",
                                expression:
                                  "'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\\'s a BACS submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Sign")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can approve a submission.",
                                expression: "'User can approve a submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.",
                                expression:
                                  "'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve Own")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can send a submission to BACS.",
                                expression:
                                  "'User can send a submission to BACS.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Submit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.",
                                expression:
                                  "'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Collect Reports")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "The group administrator can make any changes to a group including user's roles in the group. At least one user must be a group administrator.",
                                expression:
                                  "'The group administrator can make any changes to a group including user\\'s roles in the group. At least one user must be a group administrator.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Group Administrator")])]
                        )
                      ]
                    ),
                    _vm._m(3),
                    _c("th", { staticStyle: { width: "50px" } }),
                    _c("th")
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.group.groupUserRights.$each.$iter, function(user) {
                    return _c("tr", { key: user.userId.$model }, [
                      _c(
                        "td",
                        {
                          staticClass: "row-header",
                          staticStyle: { width: "150px" }
                        },
                        [_vm._v(_vm._s(user.userName.$model))]
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_create" }
                            },
                            [_vm._v("Create")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_create",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.create.$model,
                              callback: function($$v) {
                                _vm.$set(user.create, "$model", $$v)
                              },
                              expression: "user.create.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_add" }
                            },
                            [_vm._v("Add")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_add",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.add.$model,
                              callback: function($$v) {
                                _vm.$set(user.add, "$model", $$v)
                              },
                              expression: "user.add.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_edit" }
                            },
                            [_vm._v("Edit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_edit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.edit.$model,
                              callback: function($$v) {
                                _vm.$set(user.edit, "$model", $$v)
                              },
                              expression: "user.edit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_delete" }
                            },
                            [_vm._v("Delete")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_delete",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.delete.$model,
                              callback: function($$v) {
                                _vm.$set(user.delete, "$model", $$v)
                              },
                              expression: "user.delete.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_sign" }
                            },
                            [_vm._v("Sign")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_sign",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.sign.$model,
                              callback: function($$v) {
                                _vm.$set(user.sign, "$model", $$v)
                              },
                              expression: "user.sign.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approval" }
                            },
                            [_vm._v("Approval")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approval",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approval.$model,
                              callback: function($$v) {
                                _vm.$set(user.approval, "$model", $$v)
                              },
                              expression: "user.approval.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approveOwn" }
                            },
                            [_vm._v("Can Approve Own Submission")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approveOwn",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approveOwnSubmission.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  user.approveOwnSubmission,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "user.approveOwnSubmission.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_submit" }
                            },
                            [_vm._v("Submit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_submit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.submit.$model,
                              callback: function($$v) {
                                _vm.$set(user.submit, "$model", $$v)
                              },
                              expression: "user.submit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_collectreports"
                              }
                            },
                            [_vm._v("Collect Reports")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_collectreports",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.collectReports.$model,
                              callback: function($$v) {
                                _vm.$set(user.collectReports, "$model", $$v)
                              },
                              expression: "user.collectReports.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_groupAdministrator"
                              }
                            },
                            [_vm._v("Group Administrator")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_groupAdministrator",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.groupAdministrator.$model,
                              callback: function($$v) {
                                _vm.$set(user.groupAdministrator, "$model", $$v)
                              },
                              expression: "user.groupAdministrator.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", [_vm._v(_vm._s(user.uploadType.$model))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-primary",
                            attrs: {
                              type: "button",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFileModal(user)
                              }
                            }
                          },
                          [_vm._v("Edit File Upload Options")]
                        )
                      ]),
                      _c("td", { staticStyle: { width: "20px" } }, [
                        _c("a", {
                          staticClass: "fa fa-trash",
                          attrs: {
                            role: "button",
                            disabled: _vm.group.$model.isItemActioned
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeUser(user.userId.$model)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.group.$model.groupType == _vm.groupTypes.fpsOnline &&
          _vm.group.groupUserRights.$model.length > 0
            ? _c("table", { staticClass: "table table-header-rotated" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "If a user has this role for a group then the group will be available on the create submission page.",
                                expression:
                                  "'If a user has this role for a group then the group will be available on the create submission page.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Create")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to add payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to add payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Add")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.",
                                expression:
                                  "'User has permission to edit imported payments before running pre-sub validation. User can also set all payment amounts to zero.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Edit")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User has permission to delete imported payments before running pre-sub validation.",
                                expression:
                                  "'User has permission to delete imported payments before running pre-sub validation.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Delete")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "User will be able to sign a submission with a BACS smartcard or via the HSM assuming it's a BACS submission.",
                                expression:
                                  "'User will be able to sign a submission with a BACS smartcard or via the HSM assuming it\\'s a BACS submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Sign")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value: "User can approve a submission.",
                                expression: "'User can approve a submission.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.",
                                expression:
                                  "'When a user has this role they can approve a submission they have created. For security reasons it may be preferable that a user can create and sign a submission but not approve it so at least 2 people must be involved in the process. In this case a user should only be given the “Approve” role.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Approve Own")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.",
                                expression:
                                  "'Users with this role can collect reports from the payment processing centre e.g. collect BACS reports.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Collect Reports")])]
                        )
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "rotate", staticStyle: { width: "50px" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.focus.top.viewport",
                                value:
                                  "The group administrator can make any changes to a group including user's roles in the group. At least one user must be a group administrator.",
                                expression:
                                  "'The group administrator can make any changes to a group including user\\'s roles in the group. At least one user must be a group administrator.'",
                                modifiers: {
                                  hover: true,
                                  focus: true,
                                  top: true,
                                  viewport: true
                                }
                              }
                            ]
                          },
                          [_c("span", [_vm._v("Group Administrator")])]
                        )
                      ]
                    ),
                    _vm._m(4),
                    _c("th", { staticStyle: { width: "50px" } }),
                    _c("th")
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.group.groupUserRights.$each.$iter, function(user) {
                    return _c("tr", { key: user.userId.$model }, [
                      _c(
                        "td",
                        {
                          staticClass: "row-header",
                          staticStyle: { width: "150px" }
                        },
                        [_vm._v(_vm._s(user.userName.$model))]
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_create" }
                            },
                            [_vm._v("Create")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_create",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.create.$model,
                              callback: function($$v) {
                                _vm.$set(user.create, "$model", $$v)
                              },
                              expression: "user.create.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_add" }
                            },
                            [_vm._v("Add")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_add",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.add.$model,
                              callback: function($$v) {
                                _vm.$set(user.add, "$model", $$v)
                              },
                              expression: "user.add.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_edit" }
                            },
                            [_vm._v("Edit")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_edit",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.edit.$model,
                              callback: function($$v) {
                                _vm.$set(user.edit, "$model", $$v)
                              },
                              expression: "user.edit.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_delete" }
                            },
                            [_vm._v("Delete")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_delete",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.delete.$model,
                              callback: function($$v) {
                                _vm.$set(user.delete, "$model", $$v)
                              },
                              expression: "user.delete.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_sign" }
                            },
                            [_vm._v("Sign")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_sign",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.sign.$model,
                              callback: function($$v) {
                                _vm.$set(user.sign, "$model", $$v)
                              },
                              expression: "user.sign.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approval" }
                            },
                            [_vm._v("Approval")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approval",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approval.$model,
                              callback: function($$v) {
                                _vm.$set(user.approval, "$model", $$v)
                              },
                              expression: "user.approval.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: user.userId.$model + "_approveOwn" }
                            },
                            [_vm._v("Can Approve Own Submission")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id": user.userId.$model + "_approveOwn",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.approveOwnSubmission.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  user.approveOwnSubmission,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "user.approveOwnSubmission.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_collectreports"
                              }
                            },
                            [_vm._v("Collect Reports")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_collectreports",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.collectReports.$model,
                              callback: function($$v) {
                                _vm.$set(user.collectReports, "$model", $$v)
                              },
                              expression: "user.collectReports.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: {
                                for: user.userId.$model + "_groupAdministrator"
                              }
                            },
                            [_vm._v("Group Administrator")]
                          ),
                          _c("p-check", {
                            staticClass: "p-default p-curve",
                            attrs: {
                              "label-id":
                                user.userId.$model + "_groupAdministrator",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            model: {
                              value: user.groupAdministrator.$model,
                              callback: function($$v) {
                                _vm.$set(user.groupAdministrator, "$model", $$v)
                              },
                              expression: "user.groupAdministrator.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", [_vm._v(_vm._s(user.uploadType.$model))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-primary",
                            attrs: {
                              type: "button",
                              disabled: _vm.group.$model.isItemActioned
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFileModal(user)
                              }
                            }
                          },
                          [_vm._v("Edit File Upload Options")]
                        )
                      ]),
                      _c("td", { staticStyle: { width: "20px" } }, [
                        _c("a", {
                          staticClass: "fa fa-trash",
                          attrs: {
                            role: "button",
                            disabled: _vm.group.$model.isItemActioned
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeUser(user.userId.$model)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "10px" } },
            [
              _c(
                "b-form",
                { attrs: { inline: "" } },
                [
                  _c("b-form-select", {
                    staticClass: "col-md-3",
                    attrs: {
                      options: _vm.unallocatedUsers,
                      disabled: _vm.group.$model.isItemActioned
                    },
                    model: {
                      value: _vm.selectedUnallocatedUser,
                      callback: function($$v) {
                        _vm.selectedUnallocatedUser = $$v
                      },
                      expression: "selectedUnallocatedUser"
                    }
                  }),
                  _c(
                    "b-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        variant: "primary",
                        disabled: _vm.group.$model.isItemActioned
                      },
                      on: { click: _vm.addUser }
                    },
                    [_vm._v("Add")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("file-upload-modal", {
        attrs: { ok: _vm.hideFileOptions, user: _vm.user }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
      _c("div", [_c("span", [_vm._v("Upload Type")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
      _c("div", [_c("span", [_vm._v("Upload Type")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
      _c("div", [_c("span", [_vm._v("Upload Type")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
      _c("div", [_c("span", [_vm._v("Upload Type")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
      _c("div", [_c("span", [_vm._v("Upload Type")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }