var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "idb-block" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.paymentDetailsCollapse",
                  modifiers: { paymentDetailsCollapse: true }
                }
              ],
              staticClass: "idb-block-title",
              class: { invalid: _vm.details.paymentDetails.$error }
            },
            [
              _vm._v(" Payment Details "),
              _c("i", { staticClass: "fa fa-chevron-down" }),
              _c("i", { staticClass: "fa fa-chevron-up" })
            ]
          ),
          _c(
            "b-collapse",
            { attrs: { id: "paymentDetailsCollapse", visible: "" } },
            [
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group row",
                      class: { invalid: _vm.details.serviceUserNumber.$error }
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "col-form-label col-md-3 required" },
                        [_vm._v("Service User Number")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserNumber.$model,
                                expression: "details.serviceUserNumber.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserNumber.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserNumber,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _c(
                            "validation-messages",
                            {
                              attrs: { name: "service user number" },
                              model: {
                                value: _vm.details.serviceUserNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.details,
                                    "serviceUserNumber",
                                    $$v
                                  )
                                },
                                expression: "details.serviceUserNumber"
                              }
                            },
                            [
                              _vm.details.serviceUserNumber
                                .collectionsSunValidator != undefined &&
                              !_vm.details.serviceUserNumber
                                .collectionsSunValidator
                                ? _c(
                                    "small",
                                    { staticClass: "form-text small" },
                                    [_vm._v("Incorrect sun format")]
                                  )
                                : _vm._e()
                            ]
                          ),
                          !_vm.needsBureauSun
                            ? _c(
                                "small",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    "The format for a Service User Number is 6 numbers or a B followed by 5 numbers."
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.needsBureauSun
                            ? _c(
                                "small",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    "The format for a Service User Number is a B followed by 5 numbers."
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "label-control col-md-3 required" },
                      [_vm._v("BACS Submission Type:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.bacsSubmissionTypes },
                          model: {
                            value: _vm.details.submissionType.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.submissionType,
                                "$model",
                                $$v
                              )
                            },
                            expression: "details.submissionType.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  false
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Import Schema")
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("b-form-select", {
                              attrs: { options: _vm.importSchemas },
                              model: {
                                value: _vm.details.importSchema.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.details.importSchema,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "details.importSchema.$model"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.details.importSchema.$model === "Mapping"
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Mapping")
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("b-form-select", {
                              attrs: { options: _vm.mappings },
                              model: {
                                value: _vm.details.mappingId.$model,
                                callback: function($$v) {
                                  _vm.$set(_vm.details.mappingId, "$model", $$v)
                                },
                                expression: "details.mappingId.$model"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("bank-account-select", {
                    attrs: { bankAccounts: _vm.bankAccounts },
                    model: {
                      value: _vm.details.bankAccountId,
                      callback: function($$v) {
                        _vm.$set(_vm.details, "bankAccountId", $$v)
                      },
                      expression: "details.bankAccountId"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "form-group row",
                      class: { invalid: _vm.details.tokenType.$error }
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "label-control col-md-3 required" },
                        [_vm._v("Payment Signing Method")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-5" },
                        [
                          !_vm.isIndirect && _vm.hasHsm
                            ? _c(
                                "p-radio",
                                {
                                  staticClass: "p-default p-round",
                                  attrs: {
                                    value: "Hsm",
                                    disabled: _vm.disabled
                                  },
                                  model: {
                                    value: _vm.details.tokenType.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.details.tokenType,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "details.tokenType.$model"
                                  }
                                },
                                [_vm._v("HSM")]
                              )
                            : _vm._e(),
                          _c(
                            "p-radio",
                            {
                              staticClass: "p-default p-round",
                              attrs: {
                                value: "SmartCard",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.details.tokenType.$model,
                                callback: function($$v) {
                                  _vm.$set(_vm.details.tokenType, "$model", $$v)
                                },
                                expression: "details.tokenType.$model"
                              }
                            },
                            [_vm._v("Smart Card")]
                          ),
                          _vm.isIndirect
                            ? _c(
                                "p-radio",
                                {
                                  staticClass: "p-default p-round",
                                  attrs: {
                                    value: "UsbToken",
                                    disabled: _vm.disabled
                                  },
                                  model: {
                                    value: _vm.details.tokenType.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.details.tokenType,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "details.tokenType.$model"
                                  }
                                },
                                [_vm._v("USB Token")]
                              )
                            : _vm._e(),
                          _vm.isIndirect
                            ? _c(
                                "p-radio",
                                {
                                  staticClass: "p-default p-round",
                                  attrs: {
                                    value: "SecurityCode",
                                    disabled: _vm.disabled
                                  },
                                  model: {
                                    value: _vm.details.tokenType.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.details.tokenType,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "details.tokenType.$model"
                                  }
                                },
                                [_vm._v("Security Code")]
                              )
                            : _vm._e(),
                          _vm.isIndirect
                            ? _c(
                                "p-radio",
                                {
                                  staticClass: "p-default p-round",
                                  attrs: {
                                    value: "None",
                                    disabled: _vm.disabled
                                  },
                                  model: {
                                    value: _vm.details.tokenType.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.details.tokenType,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "details.tokenType.$model"
                                  }
                                },
                                [_vm._v("None")]
                              )
                            : _vm._e(),
                          _c("validation-messages", {
                            attrs: { name: "payment signing method" },
                            model: {
                              value: _vm.details.tokenType,
                              callback: function($$v) {
                                _vm.$set(_vm.details, "tokenType", $$v)
                              },
                              expression: "details.tokenType"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm.isIndirect
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Proxy Group")
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: { color: "primary" },
                              model: {
                                value: _vm.details.proxyGroup.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.details.proxyGroup,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "details.proxyGroup.$model"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.isIndirect
                    ? _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: { invalid: _vm.details.groupEmail.$error }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "label-control col-md-3 required" },
                            [_vm._v("Group Email Address")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-5" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.details.groupEmail.$model,
                                    expression: "details.groupEmail.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "email" },
                                domProps: {
                                  value: _vm.details.groupEmail.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.details.groupEmail,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "email" },
                                model: {
                                  value: _vm.details.groupEmail,
                                  callback: function($$v) {
                                    _vm.$set(_vm.details, "groupEmail", $$v)
                                  },
                                  expression: "details.groupEmail"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.details.tokenType.$model == "Hsm" && !_vm.isIndirect
                    ? _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.details.certificateId.$error,
                            warn: !_vm.selectedHSMCert.valid
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "label-control col-md-3 required" },
                            [_vm._v("HSM Certificate")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-5" },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.hsmCertificates },
                                model: {
                                  value: _vm.details.certificateId.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.details.certificateId,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "details.certificateId.$model"
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "HSM certificates" },
                                model: {
                                  value: _vm.details.certificateId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.details, "certificateId", $$v)
                                  },
                                  expression: "details.certificateId"
                                }
                              }),
                              !_vm.selectedHSMCert.valid
                                ? _c(
                                    "span",
                                    { staticClass: "validation-messages" },
                                    [
                                      _c(
                                        "small",
                                        { staticClass: "form-text small" },
                                        [_vm._v("Certificate is expired")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.details.tokenType.$model == "Hsm"
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("HSM PIN Required")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("p-check", {
                              staticClass: "p-switch p-fill",
                              attrs: {
                                color: "primary",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.details.hsmPinRequired.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.details.hsmPinRequired,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "details.hsmPinRequired.$model"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.showAdvanced
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: { invalid: _vm.details.paymentLimit.$error }
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Individual Payment Limit")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.details.paymentLimit.$model,
                                      expression: "details.paymentLimit.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "number" },
                                  domProps: {
                                    value: _vm.details.paymentLimit.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.details.paymentLimit,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _c(
                                  "validation-messages",
                                  {
                                    attrs: { name: "Individual payment limit" },
                                    model: {
                                      value: _vm.details.paymentLimit,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.details,
                                          "paymentLimit",
                                          $$v
                                        )
                                      },
                                      expression: "details.paymentLimit"
                                    }
                                  },
                                  [
                                    _vm.details.paymentLimit.wholepounds !=
                                      undefined &&
                                    !_vm.details.paymentLimit.wholepounds
                                      ? _c(
                                          "small",
                                          { staticClass: "form-text small" },
                                          [
                                            _vm._v(
                                              "individual item limit can only be in whole pounds"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "small",
                                  { staticClass: "form-text text-muted" },
                                  [_vm._v("Set to 0 for no limit")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: {
                              invalid: _vm.details.submissionLimit.$error
                            }
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Submission Payment Limit")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.details.submissionLimit.$model,
                                      expression:
                                        "details.submissionLimit.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "number" },
                                  domProps: {
                                    value: _vm.details.submissionLimit.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.details.submissionLimit,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _c(
                                  "validation-messages",
                                  {
                                    attrs: { name: "Submission payment limit" },
                                    model: {
                                      value: _vm.details.submissionLimit,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.details,
                                          "submissionLimit",
                                          $$v
                                        )
                                      },
                                      expression: "details.submissionLimit"
                                    }
                                  },
                                  [
                                    _vm.details.paymentLimit.wholepounds !=
                                      undefined &&
                                    !_vm.details.paymentLimit.wholepounds
                                      ? _c(
                                          "small",
                                          { staticClass: "form-text small" },
                                          [
                                            _vm._v(
                                              "submission item limit can only be in whole pounds"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "small",
                                  { staticClass: "form-text text-muted" },
                                  [_vm._v("Set to 0 for no limit")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Is Indirect Submitter Group")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.disabled
                                },
                                model: {
                                  value:
                                    _vm.details.isIndirectSubmitterGroup.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.details.isIndirectSubmitterGroup,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "details.isIndirectSubmitterGroup.$model"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Allow File Level Signing")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.disabled
                                },
                                model: {
                                  value:
                                    _vm.details.allowFileLevelSigning.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.details.allowFileLevelSigning,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "details.allowFileLevelSigning.$model"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Segregate Group Roles")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.disabled
                                },
                                model: {
                                  value: _vm.details.segregateGroupRoles.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.details.segregateGroupRoles,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "details.segregateGroupRoles.$model"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-form-label col-md-3" },
                            [_vm._v("Delete Payment Data")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("p-check", {
                                staticClass: "p-switch p-fill",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.disabled
                                },
                                model: {
                                  value: _vm.details.deletePaymentData.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.details.deletePaymentData,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "details.deletePaymentData.$model"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "idb-block" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.submissionDetailsCollapse",
                  modifiers: { submissionDetailsCollapse: true }
                }
              ],
              staticClass: "idb-block-title",
              class: { invalid: _vm.details.submissionDetails.$error }
            },
            [
              _vm._v(" Submission Details "),
              _c("i", { staticClass: "fa fa-chevron-down" }),
              _c("i", { staticClass: "fa fa-chevron-up" })
            ]
          ),
          _c("b-collapse", { attrs: { id: "submissionDetailsCollapse" } }, [
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-form-label col-md-3" }, [
                  _vm._v("Submission Window Type")
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("b-form-select", {
                      attrs: { options: _vm.submissionWindowTypes },
                      model: {
                        value: _vm.details.submissionWindowType.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.details.submissionWindowType,
                            "$model",
                            $$v
                          )
                        },
                        expression: "details.submissionWindowType.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.details.submissionWindowType.$model == 2
                ? _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Window Size")
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.multidayWindowSize.$model,
                            expression: "details.multidayWindowSize.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number" },
                        domProps: {
                          value: _vm.details.multidayWindowSize.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.multidayWindowSize,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-form-label col-md-3" }, [
                  _vm._v("Advance Notice Period (In Days)")
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.details.advanceNoticeInDays.$model,
                        expression: "details.advanceNoticeInDays.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number" },
                    domProps: { value: _vm.details.advanceNoticeInDays.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.details.advanceNoticeInDays,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-form-label col-md-3" }, [
                  _vm._v("Default Contra Narrative")
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.details.defaultContraNarrative.$model,
                        expression: "details.defaultContraNarrative.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: {
                      value: _vm.details.defaultContraNarrative.$model
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.details.defaultContraNarrative,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-form-label col-md-3" }, [
                  _vm._v("AUDDIS Group")
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.details.isAuddis.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.details.isAuddis, "$model", $$v)
                        },
                        expression: "details.isAuddis.$model"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "idb-block" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.serviceUserDetailsCollapse",
                  modifiers: { serviceUserDetailsCollapse: true }
                }
              ],
              staticClass: "idb-block-title",
              class: { invalid: _vm.details.serviceUserDetails.$error }
            },
            [
              _vm._v(" Service User Details "),
              _c("i", { staticClass: "fa fa-chevron-down" }),
              _c("i", { staticClass: "fa fa-chevron-up" })
            ]
          ),
          _c("b-collapse", { attrs: { id: "serviceUserDetailsCollapse" } }, [
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    class: { invalid: _vm.details.serviceUserName.$error }
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "col-form-label col-md-3 required" },
                      [_vm._v("Service User Name")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.details.serviceUserName.$model,
                              expression: "details.serviceUserName.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.details.serviceUserName.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.details.serviceUserName,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("validation-messages", {
                          attrs: { name: "service user name" },
                          model: {
                            value: _vm.details.serviceUserName,
                            callback: function($$v) {
                              _vm.$set(_vm.details, "serviceUserName", $$v)
                            },
                            expression: "details.serviceUserName"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm.showAdvanced
                  ? [
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Address 1")
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserAddress1.$model,
                                expression:
                                  "details.serviceUserAddress1.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserAddress1.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserAddress1,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Address 2")
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserAddress2.$model,
                                expression:
                                  "details.serviceUserAddress2.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserAddress2.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserAddress2,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Address 3")
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserAddress3.$model,
                                expression:
                                  "details.serviceUserAddress3.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserAddress3.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserAddress3,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Address 4")
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserAddress4.$model,
                                expression:
                                  "details.serviceUserAddress4.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserAddress4.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserAddress4,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Postcode")
                        ]),
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserPostcode.$model,
                                expression:
                                  "details.serviceUserPostcode.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserPostcode.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserPostcode,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Service User Telephone")
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserTelephone.$model,
                                expression:
                                  "details.serviceUserTelephone.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "tel" },
                            domProps: {
                              value: _vm.details.serviceUserTelephone.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserTelephone,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Service User Fax")
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserFax.$model,
                                expression: "details.serviceUserFax.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserFax.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserFax,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Service User Website")
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.details.serviceUserWebsite.$model,
                                expression: "details.serviceUserWebsite.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.serviceUserWebsite.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.serviceUserWebsite,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "form-group row",
                          class: {
                            invalid: _vm.details.serviceUserEmail.$error
                          }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "label-control col-md-3" },
                            [_vm._v("Email Address")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-5" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.details.serviceUserEmail.$model,
                                    expression:
                                      "details.serviceUserEmail.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "email" },
                                domProps: {
                                  value: _vm.details.serviceUserEmail.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.details.serviceUserEmail,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _c("validation-messages", {
                                attrs: { name: "email" },
                                model: {
                                  value: _vm.details.serviceUserEmail,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.details,
                                      "serviceUserEmail",
                                      $$v
                                    )
                                  },
                                  expression: "details.serviceUserEmail"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      ),
      _vm.showAdvanced
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.serviceUserContactNamesCollapse",
                      modifiers: { serviceUserContactNamesCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title",
                  class: { invalid: _vm.details.serviceUserContactNames.$error }
                },
                [
                  _vm._v(" Service User Contact Names "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c(
                "b-collapse",
                { attrs: { id: "serviceUserContactNamesCollapse" } },
                [
                  _c("div", { staticClass: "idb-block-content" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Service User Contact Name 1")
                      ]),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.details.serviceUserContactName1.$model,
                              expression:
                                "details.serviceUserContactName1.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.details.serviceUserContactName1.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.details.serviceUserContactName1,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Service User Contact Name 2")
                      ]),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.details.serviceUserContactName2.$model,
                              expression:
                                "details.serviceUserContactName2.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.details.serviceUserContactName2.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.details.serviceUserContactName2,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Service User Contact Name 3")
                      ]),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.details.serviceUserContactName3.$model,
                              expression:
                                "details.serviceUserContactName3.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.details.serviceUserContactName3.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.details.serviceUserContactName3,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showAdvanced
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.customFieldsCollapse",
                      modifiers: { customFieldsCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title",
                  class: { invalid: _vm.details.customFields.$error }
                },
                [
                  _vm._v(" Custom Fields "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c("b-collapse", { attrs: { id: "customFieldsCollapse" } }, [
                _c("div", { staticClass: "idb-block-content" }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 1")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField1.$model,
                            expression:
                              "details.serviceUserCustomField1.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField1.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField1,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 2")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField2.$model,
                            expression:
                              "details.serviceUserCustomField2.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField2.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField2,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 3")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField3.$model,
                            expression:
                              "details.serviceUserCustomField3.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField3.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField3,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 4")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField4.$model,
                            expression:
                              "details.serviceUserCustomField4.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField4.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField4,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 5")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField5.$model,
                            expression:
                              "details.serviceUserCustomField5.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField5.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField5,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 6")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField6.$model,
                            expression:
                              "details.serviceUserCustomField6.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField6.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField6,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 7")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField7.$model,
                            expression:
                              "details.serviceUserCustomField7.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField7.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField7,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Custom Field 8")
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.details.serviceUserCustomField8.$model,
                            expression:
                              "details.serviceUserCustomField8.$model",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.details.serviceUserCustomField8.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.serviceUserCustomField8,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.showAdvanced
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.payerMessagingCollapse",
                      modifiers: { payerMessagingCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title",
                  class: { invalid: _vm.details.payerMessaging.$error }
                },
                [
                  _vm._v(" Payer Messaging "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c("b-collapse", { attrs: { id: "payerMessagingCollapse" } }, [
                _c("div", { staticClass: "idb-block-content" }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Default Payer Message Type")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.defaultPayerMessageTypes },
                          model: {
                            value: _vm.details.defaultPayerMessageType.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.defaultPayerMessageType,
                                "$model",
                                $$v
                              )
                            },
                            expression: "details.defaultPayerMessageType.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Letter File Export Type")
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "side-icon" },
                        [
                          _c("b-form-select", {
                            attrs: {
                              options: _vm.letterFileExportTypes,
                              plain: true
                            },
                            model: {
                              value: _vm.details.docxExportType.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.details.docxExportType,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "details.docxExportType.$model"
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-info-circle",
                            attrs: { id: "docExportIcon" }
                          }),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "docExportIcon",
                                placement: "right"
                              }
                            },
                            [
                              _vm._v(
                                " Single - Letters for individual payers are created as separate Word (.docx) files. Merged - Letters for individual payers are created in a single Word (.docx) file separated by a page break. "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "form-group row",
                      class: { invalid: _vm.details.emailBccList.$error }
                    },
                    [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Email BCC Address List")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "div",
                            { staticClass: "side-icon" },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.details.emailBccList.$model,
                                    expression: "details.emailBccList.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { rows: "3" },
                                domProps: {
                                  value: _vm.details.emailBccList.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.details.emailBccList,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _c("i", {
                                staticClass: "fa fa-info-circle",
                                attrs: { id: "emailBccIcon" }
                              }),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: "emailBccIcon",
                                    placement: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    " All payer email messages will be 'bcc' sent to the recipients listed below. Semicolon (;) separated list of email addresses "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "validation-messages",
                            {
                              attrs: { name: "BCC List" },
                              model: {
                                value: _vm.details.emailBccList,
                                callback: function($$v) {
                                  _vm.$set(_vm.details, "emailBccList", $$v)
                                },
                                expression: "details.emailBccList"
                              }
                            },
                            [
                              _vm.details.emailBccList.bccValidator !=
                                undefined &&
                              !_vm.details.emailBccList.bccValidator
                                ? _c(
                                    "small",
                                    { staticClass: "form-text small" },
                                    [
                                      _vm._v(
                                        "Invalid email addresses found, please use semicolon (;) to separate values."
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Letter File Export Folder")
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "side-icon" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.details.letterOutputFolder.$model,
                                expression: "details.letterOutputFolder.$model"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.details.letterOutputFolder.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.letterOutputFolder,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-info-circle",
                            staticStyle: { float: "left" },
                            attrs: { id: "letterOutputFolderIcon" }
                          }),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "letterOutputFolderIcon",
                                placement: "right"
                              }
                            },
                            [
                              _vm._v(
                                "The folder that PayGate will use to create generated payer letters."
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Email Address")
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "side-icon" },
                        [
                          _c("b-form-select", {
                            attrs: {
                              options: _vm.emailAddresses,
                              "value-field": "id",
                              "text-field": "emailAddress"
                            },
                            model: {
                              value: _vm.details.domainEmailId.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.details.domainEmailId,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "details.domainEmailId.$model"
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-info-circle",
                            attrs: { id: "domainEmailIcon" }
                          }),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "domainEmailIcon",
                                placement: "right"
                              }
                            },
                            [
                              _vm._v(
                                "Please note: emails that cannot be successfully delivered will cause changes to associated Payer statuses – please refer to the DDMS help here for more information"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.showAdvanced
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.orphanSubmissionCollapse",
                      modifiers: { orphanSubmissionCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title"
                },
                [
                  _vm._v(" Unprocessed Submission Settings "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c("b-collapse", { attrs: { id: "orphanSubmissionCollapse" } }, [
                _c("div", { staticClass: "idb-block-content" }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Allow warnings on missed submissions")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.details.allowOrphanWarnings.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.allowOrphanWarnings,
                                "$model",
                                $$v
                              )
                            },
                            expression: "details.allowOrphanWarnings.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.details.allowOrphanWarnings.$model
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Late submission notification recipients")
                        ]),
                        _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "ul",
                            {
                              directives: [
                                {
                                  name: "draggable",
                                  rawName: "v-draggable",
                                  value: {
                                    value:
                                      _vm.details.orphanReportingEmails.$model
                                  },
                                  expression:
                                    "{value: details.orphanReportingEmails.$model }"
                                }
                              ],
                              staticClass: "list-group",
                              on: { end: _vm.onDragEnd }
                            },
                            _vm._l(
                              _vm.details.orphanReportingEmails.$model,
                              function(email, index) {
                                return _c(
                                  "li",
                                  {
                                    key: email.orphanReportingEmailId,
                                    staticClass: "list-group-item"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(index + 1) +
                                        ": " +
                                        _vm._s(email.emailAddress) +
                                        " "
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "pull-right" },
                                      [
                                        _c(
                                          "b-btn",
                                          {
                                            attrs: { variant: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delItem(email)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.details.allowOrphanWarnings.$model
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "label-control col-md-3" }, [
                          _vm._v("Add Email")
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "side-icon" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newEmail,
                                  expression: "newEmail"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.newEmail },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.newEmail = $event.target.value
                                }
                              }
                            })
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: { click: _vm.addEmail }
                              },
                              [
                                _c("i", { staticClass: "fa fa-plus rpad" }),
                                _vm._v("Add ")
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.showAdvanced
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.planSettingsCollapse",
                      modifiers: { planSettingsCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title"
                },
                [
                  _vm._v(" Plan Settings "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c("b-collapse", { attrs: { id: "planSettingsCollapse" } }, [
                _c(
                  "div",
                  { staticClass: "idb-block-content" },
                  [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Default Plan Template")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c(
                            "b-form-select",
                            {
                              attrs: {
                                options: _vm.defaultPlanTemplates,
                                "value-field": "paymentPlanTemplateId",
                                "text-field": "name"
                              },
                              model: {
                                value: _vm.details.defaultPlanTemplateId.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.details.defaultPlanTemplateId,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "details.defaultPlanTemplateId.$model"
                              }
                            },
                            [
                              _c("template", { slot: "first" }, [
                                _c(
                                  "option",
                                  {
                                    attrs: { disabled: "" },
                                    domProps: { value: null }
                                  },
                                  [_vm._v("-- Please select an option --")]
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Plan Purpose")
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.details.planPurpose.$model,
                              expression: "details.planPurpose.$model"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.details.planPurpose.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.details.planPurpose,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("small", [
                          _vm._v(
                            'A label for the plan payments, e.g. "monthly utility".'
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Auto Generate References")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: { color: "primary" },
                            model: {
                              value: _vm.details.autoGenerateReferences.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.details.autoGenerateReferences,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "details.autoGenerateReferences.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.showRefBox
                      ? _c("payer-ref-control", {
                          attrs: { rowClass: "form-group row" },
                          model: {
                            value: _vm.details.payerReferencePattern.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.payerReferencePattern,
                                "$model",
                                $$v
                              )
                            },
                            expression: "details.payerReferencePattern.$model"
                          }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "label-control col-md-3" }, [
                        _vm._v("Allow Multiple Plans")
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("p-check", {
                            staticClass: "p-switch p-fill",
                            attrs: { color: "primary" },
                            model: {
                              value: _vm.details.allowMultiplePlans.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.details.allowMultiplePlans,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "details.allowMultiplePlans.$model"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.showAdvanced
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.suppressionCollapse",
                      modifiers: { suppressionCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title"
                },
                [
                  _vm._v(" Suppression "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c("b-collapse", { attrs: { id: "suppressionCollapse" } }, [
                _c("div", { staticClass: "idb-block-content" }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Enable Schedule Suppression")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.details.suppressionEnabled.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.suppressionEnabled,
                                "$model",
                                $$v
                              )
                            },
                            expression: "details.suppressionEnabled.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.details.suppressionEnabled.$model
                    ? _c(
                        "div",
                        _vm._l(_vm.suppressionWindows, function(
                          suppressionWindow,
                          index
                        ) {
                          return _c("div", { key: index }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-3" },
                                [_vm._v("Suppression Start Day")]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.details.suppressionDayStart
                                            .$model,
                                        expression:
                                          "details.suppressionDayStart.$model",
                                        modifiers: { trim: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "number" },
                                    domProps: {
                                      value:
                                        _vm.details.suppressionDayStart.$model
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.details.suppressionDayStart,
                                          "$model",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _c("validation-messages", {
                                    attrs: { name: "suppression start day" },
                                    model: {
                                      value: _vm.details.suppressionDayStart,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.details,
                                          "suppressionDayStart",
                                          $$v
                                        )
                                      },
                                      expression: "details.suppressionDayStart"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-3" },
                                [_vm._v("Suppression Start Month")]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.details.suppressionMonthStart
                                            .$model,
                                        expression:
                                          "details.suppressionMonthStart.$model",
                                        modifiers: { trim: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "number" },
                                    domProps: {
                                      value:
                                        _vm.details.suppressionMonthStart.$model
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.details.suppressionMonthStart,
                                          "$model",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _c("validation-messages", {
                                    attrs: { name: "suppression start month" },
                                    model: {
                                      value: _vm.details.suppressionMonthStart,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.details,
                                          "suppressionMonthStart",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "details.suppressionMonthStart"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-3" },
                                [_vm._v("Suppression End Day")]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.details.suppressionDayEnd.$model,
                                        expression:
                                          "details.suppressionDayEnd.$model",
                                        modifiers: { trim: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "number" },
                                    domProps: {
                                      value:
                                        _vm.details.suppressionDayEnd.$model
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.details.suppressionDayEnd,
                                          "$model",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _c("validation-messages", {
                                    attrs: { name: "suppression end day" },
                                    model: {
                                      value: _vm.details.suppressionDayEnd,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.details,
                                          "suppressionDayEnd",
                                          $$v
                                        )
                                      },
                                      expression: "details.suppressionDayEnd"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "label-control col-md-3" },
                                [_vm._v("Suppression End Month")]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.details.suppressionMonthEnd
                                            .$model,
                                        expression:
                                          "details.suppressionMonthEnd.$model",
                                        modifiers: { trim: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "number" },
                                    domProps: {
                                      value:
                                        _vm.details.suppressionMonthEnd.$model
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.details.suppressionMonthEnd,
                                          "$model",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _c("validation-messages", {
                                    attrs: { name: "suppression end month" },
                                    model: {
                                      value: _vm.details.suppressionMonthEnd,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.details,
                                          "suppressionMonthEnd",
                                          $$v
                                        )
                                      },
                                      expression: "details.suppressionMonthEnd"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.showAdvanced && _vm.hasRefunds
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.refundCollapse",
                      modifiers: { refundCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title"
                },
                [
                  _vm._v(" Refunds "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c("b-collapse", { attrs: { id: "refundCollapse" } }, [
                _c("div", { staticClass: "idb-block-content" }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Refund Window Start")
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.details.refundWindowStart.$model,
                            expression: "details.refundWindowStart.$model",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", min: 0, max: 12 },
                        domProps: {
                          value: _vm.details.refundWindowStart.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.refundWindowStart,
                              "$model",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c("small", [_vm._v("Number of months in the past.")])
                    ])
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "col-form-label col-md-3" }, [
                      _vm._v("Refund Window End")
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.details.refundWindowEnd.$model,
                            expression: "details.refundWindowEnd.$model",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", min: 0, max: 12 },
                        domProps: { value: _vm.details.refundWindowEnd.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.details.refundWindowEnd,
                              "$model",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c("small", [_vm._v("Number of months in the past.")])
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.showAdvanced
        ? _c(
            "div",
            { staticClass: "idb-block" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.advancedCollapse",
                      modifiers: { advancedCollapse: true }
                    }
                  ],
                  staticClass: "idb-block-title",
                  class: { invalid: _vm.details.advanced.$error }
                },
                [
                  _vm._v(" Advanced "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                  _c("i", { staticClass: "fa fa-chevron-up" })
                ]
              ),
              _c("b-collapse", { attrs: { id: "advancedCollapse" } }, [
                _c("div", { staticClass: "idb-block-content" }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Update Scheduled Payments On Error")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.details.updateSchedPaymentOnError.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.updateSchedPaymentOnError,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "details.updateSchedPaymentOnError.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Group Workflow Type")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.groupWorkflowTypes },
                          model: {
                            value: _vm.details.groupWorkflowType.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.groupWorkflowType,
                                "$model",
                                $$v
                              )
                            },
                            expression: "details.groupWorkflowType.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Api Active")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.details.apiActive.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.details.apiActive, "$model", $$v)
                            },
                            expression: "details.apiActive.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.details.apiActive.$model
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label-control col-md-3",
                            attrs: { for: "description" }
                          },
                          [_vm._v("Group API Key")]
                        ),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.apiKey,
                                  expression: "apiKey"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", readonly: "" },
                              domProps: { value: _vm.apiKey },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.apiKey = $event.target.value
                                }
                              }
                            }),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-secondary ml-3",
                                  attrs: { type: "button" },
                                  on: { click: _vm.copyKey }
                                },
                                [_vm._v("Copy Key")]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Use Default Variable Amount File Mapping")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value:
                              _vm.details.defaultVariableAmountMapping.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.defaultVariableAmountMapping,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "details.defaultVariableAmountMapping.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Variable Amount File Mapping")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-select",
                          {
                            attrs: {
                              options: _vm.variableAmountMappings,
                              "value-field": "_id",
                              "text-field": "title",
                              disabled:
                                _vm.details.defaultVariableAmountMapping.$model
                            },
                            model: {
                              value: _vm.details.variableAmountMappingId.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.details.variableAmountMappingId,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "details.variableAmountMappingId.$model"
                            }
                          },
                          [
                            _c("template", { slot: "first" }, [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null }
                                },
                                [_vm._v("-- Please select an option --")]
                              )
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Suppress 0C Messages")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.details.suppress0c.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.details.suppress0c, "$model", $$v)
                            },
                            expression: "details.suppress0c.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.corvidUser
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-form-label col-md-3" },
                          [_vm._v("Payer Threshold")]
                        ),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.details.payerThreshold.$model,
                                expression: "details.payerThreshold.$model"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number", disabled: _vm.corvidUser },
                            domProps: {
                              value: _vm.details.payerThreshold.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.details.payerThreshold,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "label-control col-md-3" }, [
                      _vm._v("Auto Cancel Submission on Withdrawal Report")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.details.autoCancelFromWithdrawal.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.details.autoCancelFromWithdrawal,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "details.autoCancelFromWithdrawal.$model"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-button",
        {
          attrs: { variant: "outline-secondary" },
          on: {
            click: function($event) {
              _vm.showAdvanced = !_vm.showAdvanced
            }
          }
        },
        [
          _vm._v(
            _vm._s(_vm.showAdvanced ? "Hide" : "Show") + " Advanced Options"
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }