<template>
  <span class="pull-right mr-2">
    <a href="#" @click="toggle" :title="'Add '+currentRouteName+' to favourites'">
      <i class="fa-star star" :class="active?'fas':'far'"></i><span class="d-none">Add {{currentRouteName}} to favourites</span>
    </a>
  </span>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'FavouriteIcon',
  computed: {
    ...mapGetters({
      hasClaim: 'hasClaim'
    }),
    active () {
      return this.hasClaim('Favourite', this.$router.currentRoute.fullPath)
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  data () {
    return {
    }
  },
  methods: {
    async toggle () {
      try {
        if (!this.active) {
          if (this.$store.getters.getClaims('favourite').length < 6) {
            this.$store.commit('addClaim', { type: 'favourite', value: this.$router.currentRoute.fullPath })
            await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}User/AddFavourite`, null, {
              params: {
                path: this.$router.currentRoute.fullPath
              }
            })
          } else {
            this.$toastr.w('You can only have 6 favourites', 'Cannot Add Favourite')
          }
        } else {
          this.$store.commit('removeClaim', { type: 'favourite', value: this.$router.currentRoute.fullPath })
          await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}User/RemoveFavourite`, {
            params: {
              path: this.$router.currentRoute.fullPath
            }
          })
        }
      } catch (e) {

      } finally {
        await this.loadUser()
      }
    },
    loadUser: _.debounce(async function () {
      await this.$store.dispatch('loadUser')
    }, 3000)
  }
}
</script>

<style lang="scss" scoped>
.star {
  color: #fde396;
}
</style>
