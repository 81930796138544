import { render, staticRenderFns } from "./PaymentApproval.vue?vue&type=template&id=343bc1fd&"
import script from "./PaymentApproval.vue?vue&type=script&lang=js&"
export * from "./PaymentApproval.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('343bc1fd')) {
      api.createRecord('343bc1fd', component.options)
    } else {
      api.reload('343bc1fd', component.options)
    }
    module.hot.accept("./PaymentApproval.vue?vue&type=template&id=343bc1fd&", function () {
      api.rerender('343bc1fd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Components/Platform/SecurityPolicy/Pages/PaymentApproval.vue"
export default component.exports