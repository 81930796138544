var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "downloadBureauCustomerReports" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Bureau Customer Configuration Report "),
                _c("help-icon", { attrs: { docPath: "/bacsbureau/reports/" } }),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c("div", { staticClass: "row form-group col-md-12" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("vue-select", {
                      attrs: {
                        label: "name",
                        options: _vm.searchTypes,
                        closeOnSelect: false,
                        clearable: false
                      },
                      on: { input: _vm.searchTypeChanged },
                      model: {
                        value: _vm.searchType,
                        callback: function($$v) {
                          _vm.searchType = $$v
                        },
                        expression: "searchType"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.checkedAdvanced
                ? [
                    _c("div", { staticClass: "row form-group col-md-12" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("vue-select", {
                            attrs: {
                              multiple: "",
                              label: "name",
                              options: _vm.BureauJobDisplay,
                              closeOnSelect: false
                            },
                            on: { input: _vm.CheckBureauJobsDropdownEntries },
                            model: {
                              value: _vm.SelectedBureauJobs,
                              callback: function($$v) {
                                _vm.SelectedBureauJobs = $$v
                              },
                              expression: "SelectedBureauJobs"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-group col-md-12" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("vue-select", {
                            attrs: {
                              multiple: "",
                              label: "name",
                              options: _vm.BureauCustomersDisplay,
                              closeOnSelect: false
                            },
                            on: {
                              input: _vm.CheckBureauCustomerDropdownEntries
                            },
                            model: {
                              value: _vm.SelectedBureauCustomers,
                              callback: function($$v) {
                                _vm.SelectedBureauCustomers = $$v
                              },
                              expression: "SelectedBureauCustomers"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-group col-md-12" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("group-select", {
                            attrs: { groups: _vm.groups, clearable: false },
                            on: { input: _vm.load },
                            model: {
                              value: _vm.groupId,
                              callback: function($$v) {
                                _vm.groupId = $$v
                              },
                              expression: "groupId"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                : _vm._e(),
              _c(
                "vue-good-table",
                {
                  ref: "table",
                  attrs: {
                    mode: "remote",
                    paginationOptions: _vm.paginationOptions,
                    rows: _vm.rows,
                    columns: _vm.columns,
                    totalRows: _vm.totalRecords,
                    lineNumbers: true,
                    searchOptions: {
                      enabled: true
                    },
                    sortOptions: {
                      enabled: true
                    },
                    styleClass: "vgt-table striped bordered"
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "on-page-change": _vm.onPageChange,
                    "on-sort-change": _vm.onSortChange,
                    "on-column-filter": _vm.onColumnFilter,
                    "on-per-page-change": _vm.onPerPageChange
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Clear filters",
                              expression: "'Clear filters'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          staticClass: "btn btn-link",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.clearTableFilters($event)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fa-stack",
                              staticStyle: { "font-size": "10px" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-filter fa-stack-1x dimmedIcon"
                              }),
                              _c("i", {
                                staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                              })
                            ]
                          ),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Clear filters")
                          ])
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Refresh the data in the table",
                              expression: "'Refresh the data in the table'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.load($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-sync pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Refresh Table")
                          ])
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Export the contents of the table",
                              expression: "'Export the contents of the table'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.exportTable($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-share-square pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Export Table")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", [_vm._v("Search Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", [_vm._v("Bureau Jobs")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", [_vm._v("Bureau Customers")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { staticClass: "mt-2", attrs: { for: "filter" } }, [
        _vm._v("Group")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }