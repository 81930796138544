var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "TheBureauSceneryChannel" } }, [
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-col",
            { attrs: { sm: "3" } },
            [
              _c("group-select", {
                attrs: { groups: _vm.groups, clearable: false },
                on: { input: _vm.onGroupSelect },
                model: {
                  value: _vm.groupId,
                  callback: function($$v) {
                    _vm.groupId = $$v
                  },
                  expression: "groupId"
                }
              })
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              on: { click: _vm.onTestWorkflowGetBureauJobs }
            },
            [_vm._v("Get Bureau Jobs")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(1),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              on: { click: _vm.onTestGetPaymentDate }
            },
            [_vm._v("GetPaymentDate")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              on: { click: _vm.onTestGetProcessingDate }
            },
            [_vm._v("GetProcessingDate")]
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(2),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              on: { click: _vm.onTestDeleteFiles }
            },
            [_vm._v("Test Delete")]
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(3),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              on: { click: _vm.onTestBureauSummaryGetter }
            },
            [_vm._v("Test Bureau Vocalink Summary Getter")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              on: { click: _vm.TestPillUPdate }
            },
            [_vm._v("Test Pill Update")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { staticClass: "text-danger" }, [
        _vm._v("Bureau Scenery Channel")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { staticClass: "text-danger" }, [
        _vm._v("Get Processing or Payment Date Tests")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { staticClass: "text-danger" }, [
        _vm._v("File Deletion Testing")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { staticClass: "text-danger" }, [
        _vm._v("Test Bureau Vocalink Summary Getter")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }