<template>
  <div>
    <b-form>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            <help-icon docPath="/paygate-collections/payers/editpayer/" />Edit Payer
          </h2>
        </div>
        <div>
          <b-card
            v-if="status === 5 && emailVerified"
            bg-variant="warning"
            text-variant="white"
            style="margin-bottom: 0.8em; margin-right: 0.8em"
          >
            <i class="fa fa-exclamation-triangle rpad"></i> Activation Required
            <b-btn variant="outline-light" @click="activateOnboarded">Activate</b-btn>
          </b-card>
          <b-card
            v-if="status === 5 && !emailVerified"
            bg-variant="warning"
            text-variant="white"
            style="margin-bottom: 0.8em; margin-right: 0.8em"
          >
            <i class="fa fa-exclamation-triangle rpad"></i> Email Verification Required - Cannot activate payer without Email Verification.
          </b-card>
          <b-tabs nav-wrapper-class="no-print-tabs">
            <b-tab :active="!openAt" title="Main">
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Group"
                >
                  <b-form-input :value="groupName" type="text" disabled />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Payer Reference"
                >
                  <b-form-input
                    type="text"
                    class="form-control"
                    required
                    v-model.trim="$v.payerReference.$model"
                    :disabled="true"
                  />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Payer Status"
                >
                  <b-form-select
                    v-model.number="status"
                    :options="status === 5 ? statae : statae.slice(0,5)"
                    text-field="name"
                    value-field="value"
                    :disabled="!editable || (status == 5 && !emailVerified)"
                  />
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Message Channel"
                >
                  <b-form-select
                    v-model="messageChannel"
                    :options="messageChannels"
                    text-field="name"
                    value-field="value"
                    :disabled="!editable"
                    @change="showPreview ? getPreview() : null"
                  />
                </b-form-group>
              </b-form-row>
              <b-form-row v-if="hasPayerPortal">
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  :label="(payerPortalId === undefined || payerPortalId === null) ? 'Create Portal Account' : 'Resend Password'"
                >
                  <b-button
                    v-if="(payerPortalId === undefined && payerPortalId === null)"
                    :disabled="isLoading"
                    variant="secondary"
                    @click="createPortalAccount"
                  >Create</b-button>
                  <b-button
                    v-if="(payerPortalId !== undefined && payerPortalId !== null)"
                    :disabled="isLoading"
                    variant="secondary"
                    @click="resendPortalCode"
                  >Resend Code</b-button>
                </b-form-group>
              </b-form-row>
            </b-tab>
            <b-tab :active="openAt === 'payerDetails'">
              <template slot="title">
                Payer Details
                <i
                  v-if="payerStagingDetails !== undefined && !payerStagingDetails.payerDetailsValidated"
                  class="missing-fields fa fa-exclamation-triangle"
                  aria-hidden="true"
                  :title="detailsValidationMessage"
                ></i>
              </template>
              <payer-details-input :ukPayerId="ukPayerId"></payer-details-input>
            </b-tab>
            <b-tab :active="openAt === 'paymentPlans'">
              <template slot="title">
                Payment Plans
                <i
                  v-if="payerStagingDetails !== undefined && !payerStagingDetails.paymentPlansValidated"
                  class="missing-fields fa fa-exclamation-triangle"
                  aria-hidden="true"
                  :title="plansValidationMessage"
                ></i>
              </template>
              <b-row class="planRow">
                <b-tabs
                  vertical
                  nav-wrapper-class="col-3 vertical-tab-container"
                  nav-class="w-100 vertical-tab-list"
                >
                  <template v-slot:tabs-end v-if="!planLimitHit">
                    <b-nav-item href="#">
                      <b-form-select
                        class="form-control w-100"
                        @keyup.enter="addNewPaymentPlan"
                        @change="addNewPaymentPlan"
                        v-model.trim="$v.newPlanReference.$model"
                        type="text"
                        placeholder="New plan name..."
                        :options="planNames"
                        text-field="name"
                        value-field="name"
                      >
                        <!-- This slot appears above the options from 'options' prop -->
                        <template slot="first">
                          <option :value="null" disabled>Please select a plan</option>
                        </template>
                      </b-form-select>
                    </b-nav-item>
                  </template>
                  <b-tab
                    v-for="(paymentPlan, index) in paymentPlans"
                    :key="index"
                    :title="paymentPlan.planTemplateName"
                  >
                    <payment-plan-input
                      :plan="paymentPlan"
                      v-if="paymentPlan !== null && paymentPlan !== undefined"
                      :ukPayerId="ukPayerId"
                      :planClosed="isClosed || selectedPlanClosed"
                      @planDeleted="deletePlan(index)"
                      :showDeleteButton="true"
                    ></payment-plan-input>
                  </b-tab>
                  <template slot="empty">There are no plans associated with this payer.</template>
                </b-tabs>
              </b-row>
            </b-tab>
            <b-tab :active="openAt === 'bankDetails'">
              <template slot="title">
                Bank Details
                <i
                  v-if="payerStagingDetails !== undefined && !payerStagingDetails.bankAccountValidated"
                  class="missing-fields fa fa-exclamation-triangle"
                  aria-hidden="true"
                  :title="bankingValidationMessage"
                ></i>
                <i
                  v-if="!isLoading && !validModulus"
                  class="warning-fields fa fa-exclamation-triangle"
                  aria-hidden="true"
                  title="Bank details failed modulus check"
                ></i>
              </template>
              <bank-account-details-input :showIntro="false"></bank-account-details-input>
            </b-tab>
            <b-tab title="Alerts" :active="openAt === 'alerts'" lazy>
              <payer-alerts :reference="$v.payerReference.$model" :ukPayerId="ukPayerId"></payer-alerts>
            </b-tab>
            <b-tab title="Payer History" :active="openAt === 'payerHistory'" lazy>
              <payer-history :ukPayerId="ukPayerId"></payer-history>
            </b-tab>
            <b-tab title="Notes" :active="openAt === 'notes'" lazy>
              <payer-notes :ukPayerId="ukPayerId"></payer-notes>
            </b-tab>
            <b-tab title="Scheme Messages" :active="openAt === 'messages'">
              <b-row class="mb-2">
                <b-col md="3">
                  <h3>Send Message</h3>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col md="3">
                  <b-form-select v-model="sendMessagePlan" :options="paymentPlanSelectOptions"></b-form-select>
                </b-col>
                <b-col>
                  <b-form-select
                    v-model="sendMessageType"
                    :options="messageTypeSelect"
                    value-field="value"
                    text-field="name"
                    @change="getPreview()"
                  >
                    <!--option :value="null" disabled>Select a message type</option>
                    <option value="FirstPayment">First Payment</option>
                    <option value="AdvanceNotice">Advance Notice</option>
                    <option value="MultipleScheduleUpdate">Multiple Schedule Update</option>
                    <option value="CancellationNotice">Cancellation Notice</option>
                    <option value="EmailVerification">Email Verification</option>
                    <option value="PortalMessageSent">Portal Message Sent</option>
                    <option value="PortalEmailVerification">Portal Email Verification</option>
                    <option value="RefundRequested">Refund Requested</option-->
                  </b-form-select>
                </b-col>
                <b-col>
                  <b-btn
                    variant="primary"
                    :disabled="sendMessageLoading || (sendMessageType === null || sendMessagePlan === null || isLoading)"
                    @click.prevent="sendMessage(sendMessageType)"
                  >
                    <i class="fa fa-envelope rpad"></i>Send
                  </b-btn>
                </b-col>
                <b-col>
                  <p-check
                    id="previewCheck"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="showPreview"
                    @change="getPreview()"
                  >Show Preview</p-check>
                </b-col>
              </b-row>
              <b-row v-if="sendMessagePlan !== null && showPreview">
                <b-col>
                  <h3>Message Preview</h3>
                </b-col>
              </b-row>
              <b-row v-if="sendMessagePlan !== null && showPreview">
                <b-col>
                  <div class="container" v-if="messageChannel === 1">
                    <b-row>
                      <b-col style="width: 105% !important">
                        <div style="padding-top: 110%; position: relative;">
                          <iframe
                            frameborder="0"
                            ref="previewFrame"
                            height="100%"
                            width="100%"
                            style="position: absolute; top: 0; left: 0;"
                          ></iframe>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div id="emailPreview" v-if="messageChannel === 0" class="idb-block-content">
                    <h4 v-html="'Subject: ' + previewTitle"></h4>
                    <div v-html="previewBody"></div>
                  </div>
                  <div class="inline-flex" v-if="messageChannel === 2">
                    <label class="speech-bubble-label">
                      <strong v-html="previewTitle"></strong>
                    </label>
                    <div class="speech-bubble">
                      <div class="smsPreviewText" v-if="!previewLoading" v-html="previewBody"></div>
                      <div
                        class="smsPreviewTextLoading"
                        v-if="previewLoading"
                        v-html="previewTextPreview"
                      ></div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              title="Message Queue"
              :active="openAt === 'messagequeue'"
              @click="getPayerMessages"
            >
              <b-form-row v-if="paymentPlans.length > 1">
                <b-form-group
                  :label-cols="5"
                  class="col-sm-6"
                  label-class="required"
                  horizontal
                  label="Plan Messages"
                >
                  <b-form-select
                    v-model="queuePlan"
                    :options="paymentPlanSelectOptions"
                    @input="changeMessageQueuePlan"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null">-- All Plans --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form-row>
              <messageQueue
                v-bind="{ payerId: ukPayerId, planId: paymentPlans.length > 1 ? paymentPlans[0].paymentPlanId : queuePlan}"
              />
            </b-tab>
            <b-tab title="Direct Messages" :active="openAt === ''" v-if="hasPayerPortal">
              <compose :plans="paymentPlans" :ukPayerId="ukPayerId" @input="getPayerMessages" />
              <inbox
                :messages="portalMessages"
                :ukPayerId="ukPayerId"
                :payerView="true"
                @input="getPayerMessages"
              />
            </b-tab>
          </b-tabs>
        </div>
        <div class="idb-block-footer noprint-footer">
          <b-button
            variant="primary"
            @click.prevent="savePayer"
            :disabled="this.$v.$invalid || !this.editable || isLoading"
          >
            <i class="fa fa-save mr-2"></i>Save Payer
          </b-button>&nbsp;
          <b-button
            class="pull-right"
            variant="danger"
            :disabled="isLoading && !canDelete"
            @click.prevent="requestPayerDeletion"
          >
            <i class="fa fa-trash mr-2"></i>Delete Payer
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import _ from 'lodash'
import axios from 'axios'
import Components from '@/Components/Collections'
import { required, String } from 'vuelidate/lib/validators'
import EventBus from '@/Lib/eventBus'

import auth from '@/Assets/Components/Authentication/auth.js'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import swal from 'sweetalert2'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
export default {
  mixins: [loading],
  props: {
    retPath: String,
    ukPayerId: String,
    openAt: String,
    payerDetailsInitiallyVisible: Boolean,
    bankAccountInitiallyVisible: Boolean,
    paymentPlansInitiallyVisible: Boolean
  },
  data () {
    return {
      queuePlan: null,
      sendMessagePlan: null,
      sendMessageType: null,
      sendMessageLoading: false,
      messageTypeSelect: [
        { name: 'First Message', value: 'FirstMessage' },
        { name: 'Advance Notice', value: 'AdvanceNotice' },
        { name: 'Multiple Schedule Update', value: 'MultipleScheduleUpdate' },
        { name: 'Custom Message 1', value: 'CustomMessage1' },
        { name: 'Custom Message 2', value: 'CustomMessage2' },
        { name: 'Custom Message 3', value: 'CustomMessage3' },
        { name: 'Custom Message 4', value: 'CustomMessage4' },
        { name: 'Custom Message 5', value: 'CustomMessage5' },
        { name: 'Cancellation Notice', value: 'CancellationNotice' },
        { name: 'Email Verification', value: 'EmailVerification' },
        { name: 'Portal Message Sent', value: 'PortalMessageSent' },
        { name: 'Portal Email Verification', value: 'PortalEmailVerification' },
        { name: 'Refund Requested', value: 'RefundRequested' }
      ],
      statae: [
        { name: 'Operational', value: 0 },
        { name: 'Suspended', value: 1 },
        { name: 'Alert', value: 2 },
        { name: 'Closed', value: 3 },
        { name: 'Incomplete', value: 4 },
        { name: 'Awaiting Activation', value: 5 }
      ],
      messageChannels: [
        { name: 'Letter', value: 1 },
        { name: 'Email', value: 0 },
        { name: 'SMS', value: 2 }
      ],
      // moreInfoData: null,
      payerDetailsVisible: true,
      paymentPlansVisible: true,
      bankAccountVisible: true,
      payerDetails: {},
      newPlanReference: null,
      selectedPaymentPlan: null,
      bankAccountDetails: {},
      groupValid: false,
      loadingPayer: false,
      showFade: true,
      planNames: [],
      outOfPlanNames: false,
      selectedPlanClosed: false,
      groupConfig: null,
      showPreview: false,
      messageResponse: null,
      previewBody: '',
      previewTitle: '',
      bearerToken: null,
      portalMessages: [],
      replyId: '',
      portalMessagingConnection: null
    }
  },
  components: {
    payerDetailsInput: Components.Payer.PayerDetails,
    paymentPlanInput: Components.Payer.PaymentPlan,
    bankAccountDetailsInput: Components.Payer.BankAccountDetails,
    payerNotes: Components.Payer.PayerNotes,
    payerAlerts: Components.Payer.PayerAlerts,
    payerHistory: Components.Payer.PayerHistory,
    inbox: Components.Messaging.Inbox,
    compose: Components.Messaging.Compose,
    messageQueue: Components.Messaging.MessageQueue
  },
  methods: {
    validatedTabTitle (title, stagingSection) {
      if (stagingSection) {
        return title
      } else {
        return `${title} `
      }
    },
    sendMessage: _.throttle(async function (messageType) {
      var result = await swal.fire({
        title: 'Send Message to Payer?',
        text: 'A message will be added to the message queue to be sent to the payer.',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'No',
        showConfirmButton: true,
        confirmButtonText: 'Yes'
      })
      if (result.isConfirmed) {
        const url = `${process.env.VUE_APP_DDMS_API_URL}payer/message`
        const body = {
          paymentPlanId: this.sendMessagePlan,
          payerId: this.$store.state.payer.currentPayer.ukPayerId,
          messageType: messageType
        }
        try {
          this.sendMessageLoading = true
          await axios.post(url, body, { showload: true })
          EventBus.$emit('refreshHistory')
          this.$toastr.s('Message added to queue for selected plan.', 'Success')
        } catch (e) {
          console.error(e)
          this.$toastr.e('Failed to add message to queue.', 'An error occurred.')
        } finally {
          this.sendMessageLoading = false
        }
      }
    }, 1500),
    async requestPayerDeletion () {
      var response = await swal.fire({
        title: 'Delete Payer?',
        text: 'The payer will be deleted and unable to be recovered.',
        type: 'warning',
        icon: 'warning',
        animation: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (response.isConfirmed) {
        try {
          await this.$store.dispatch('deletePayer', this.ukPayerId)
          this.$toastr.s('Payer Deleted')
          this.$router.push('/collections/payer/manage')
        } catch {
          this.$toastr.e('Could not delete the payer.')
        }
      }
    },
    deletePlan (index) {
      this.$swal({
        title: 'Delete Plan',
        text: 'The plan will be deleted and unable to be recovered.',
        type: 'warning',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
        .then((confirmDelete) => {
          if (confirmDelete) {
            var ref = this.paymentPlans[this.paymentPlans.indexOf(this.selectedPaymentPlan)].reference
            this.planNames.push(ref)
            if (this.paymentPlans.indexOf(this.selectedPaymentPlan) === index) {
              this.$delete(this.paymentPlans, index)
              this.setSelectedPlan(0)
            } else {
              this.$delete(this.paymentPlans, index)
            }
            this.planNames = this.checkPlanNames(this.planNames)
          }
        })
    },
    setSelectedPlan (index) {
      if (this.paymentPlans.length > 0) {
        this.selectedPaymentPlan = this.paymentPlans[index]
        this.selectedPlanClosed = this.paymentPlans[index].planStatus === 0
      } else {
        this.selectedPaymentPlan = null
        this.selectedPlanClosed = false
        this.selectedPlanClosed = this.paymentPlans[index].planStatus === 0
      }
    },
    selectPlan (paymentPlan) {
      this.selectedPaymentPlan = paymentPlan
      this.selectedPlanClosed = paymentPlan.planStatus === 0
    },
    addNewPaymentPlan () {
      if (!this.$v.newPlanReference.$invalid) {
        var selectedPlan = null
        let plan
        for (var i = 0; i < this.planNames.length; i++) {
          plan = this.planNames[i]
          if (plan && plan.name === this.newPlanReference) {
            selectedPlan = this.planNames[i].plan
            selectedPlan.step = selectedPlan.step === null ? 0 : selectedPlan.step
            selectedPlan.reference = this.allowMultiplePlans ? `${this.payerReference} - ${(this.historicPlanCount + 1)}` : this.payerReference
            selectedPlan.firstPaymentDate = new Date()
            this.historicPlanCount = this.historicPlanCount + 1
            selectedPlan.futurePlanChanges = []
            selectedPlan.planTemplateName = this.newPlanReference
            selectedPlan.planStatus = 3
          }
        }
        this.paymentPlans.unshift(selectedPlan)
        this.setSelectedPlan(0)
        this.planNames = this.checkPlanNames(this.planNames)
        this.newPlanReference = null
      }
    },
    async savePayer () {
      this.$v.$touch()
      try {
        if (this.$v.$invalid) {

        } else {
          if (this.isClosed) {
            const willClose = await this.$swal({
              title: 'Close Payer?',
              text: 'This is irreversible. The payer will no longer be editable.',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: colours.danger,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No'
            })
            if (!willClose) {
              return
            }
          }
          await this.$store.dispatch('savePayer', { group: this.group })
          this.$toastr.s('Payer saved successfully.', 'Success')
        }
      } catch (e) {
        if (e.response.status === 409) {
          this.$toastr.e(e.response.data, 'Payer not saved')
        } else if (e.response.status === 400) {
          const { bankAccountValidated, payerDetailsValidated, paymentPlansValidated } = e.response.data
          let message = 'Validation failed. Check '
          if (!payerDetailsValidated) {
            this.payerStagingDetails.payerDetailsValidated = false
            message += "'Payer Details', "
          }
          if (!paymentPlansValidated) {
            this.payerStagingDetails.paymentPlansValidated = false
            message += "'Payment Plans', "
          }
          if (!bankAccountValidated) {
            this.payerStagingDetails.bankAccountValidated = false
            message += "'Bank Details', "
          }
          message = message.slice(0, message.length - 2)
          message += '.'
          this.$toastr.e(message, 'Payer not saved')
        } else {
          console.error(e)
          this.$toastr.e('Unable to save payer.', 'Error')
        }
      }
      this.$v.$reset()
      this.groupValid = false
      this.$store.dispatch('updateCurrentCollectionsAlertCount')
    },
    dirtyGroup () {
      this.$v.group.$touch()
      this.groupValid = !this.$v.group.$invalid
    },
    async fetchPayerDetails () {
      this.loadingPayer = true
      await this.$store.dispatch('setCurrentDDMSPayerWithId', { id: this.ukPayerId })
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.groupId}`, { showload: true })
      for (var i = 0; i < response.data.length; i++) {
        this.messageTypeSelect.push({ name: response.data[i].title, value: response.data[i].customGroupTemplateId })
      }
      this.$nextTick(function () {
        this.loadingPayer = false
        setTimeout(function () {
          this.showFade = false
        }.bind(this), 1600)
      })
    },
    checkPlanNames (input) {
      var plans = this.$store.state.payer.currentPayer.paymentPlans
      var usableNames = input
      for (var i = 0; i < plans.length; plans++) {
        if (usableNames.find(val => val.name === plans[i].planTemplateName)) {
          usableNames = usableNames.filter(item => item.name !== plans[i].planTemplateName)
        }
      }
      this.outOfPlanNames = usableNames.length === 0
      return usableNames
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      const groups = this.$store.getters.customerGroups
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      const groupsNull = (groups === undefined || groups === null)
      if (paygateIdNotDefault && groupsNull) {
        var payload = { paygateId: paygateId, groupRole: ['groupAdministrator', 'create', 'edit', 'add'] }
        this.$store.dispatch('getGroupsWithRights', payload).then(response => {
          this.groups = this.$store.getters.rightsGroups
          if (this.groups.length === 0) {
            this.$toastr.w('You don\'t have permission to view any payer groups.')
          }
        })
      }
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupid
      }
    },
    async getPreview () {
      setTimeout(async () => {
        if (this.messageChannel === 1) {
          const iframe = this.$refs.previewFrame
          if (iframe) {
            console.log(this.previewUrl)
            iframe.src = this.previewUrl
          }
        } else {
          var previewResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}messages/payerpreview/${this.sendMessagePlan}/${this.messageChannel}/${this.sendMessageType}`, { showload: true })
          this.previewBody = previewResponse.data[0]
          this.previewTitle = previewResponse.data.length > 1 ? previewResponse.data[1] : 'Sender'
        }
      }, 1000)
    },
    async activateOnboarded () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/activate/${this.ukPayerId}`, {}, { showload: true })
      if (response) {
        await this.fetchPayerDetails()
      }
    },
    async createPortalAccount () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/portal/${this.ukPayerId}`, {}, { showload: true })
      console.log('portal account creation res:', response)
      if (response) {
        await this.fetchPayerDetails()
      }
    },
    async resendPortalCode () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/portal/${this.ukPayerId}/resend`, {}, { showload: true })
      if (response.data) {
        this.$toastr.s('Code sent.')
      }
    },
    async getPayerMessages () {
      if (this.hasPayerPortal) {
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}portalmessaging/payer/${this.ukPayerId}`, { showload: true })
        if (response.data) {
          this.portalMessages = response.data
        }
      }
    },
    stopSocketListener () {
      this.portalMessagingConnection.stop()
    },
    async loadLicence () {
      try {
        const licenceRequest = axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`, { showload: true })
        var response = await licenceRequest
        this.license = response.data
      } catch (err) {
        alert(err)
      }
    },
    changeMessageQueuePlan () {
      EventBus.$emit('changeMessageQueuePlan', this.queuePlan)
    }
  },
  computed: {
    isClosed () {
      return this.status === 3
    },
    paymentPlanSelectOptions () {
      const selectList = this.paymentPlans.map(plan => ({
        value: plan.paymentPlanId,
        text: plan.planTemplateName
      }))
      if (selectList.length === 0) {
        selectList.push({ value: null, text: 'No plans found' })
      }
      return selectList
    },
    canActivate () {
      const stagingDetails = this.$store.state.payer.currentPayer.payerStaging
      return (stagingDetails.bankAccountValidated &&
        stagingDetails.paymentPlansValidated &&
        stagingDetails.payerDetailsValidated &&
        !stagingDetails.awaitingActivation)
    },
    returnPath () {
      return `/payer/${this.retPath}`
    },
    selectedPaygateId () {
      return this.$store.state.common.paygateId
    },
    customerGroups () {
      return this.$store.getters.customerGroups
    },
    valid () {
      return this.$store.state.payer.formValid
    },
    groupId: {
      get () {
        return this.$store.state.payer.currentPayer.groupId
      },
      set (value) {
        this.$store.commit('updateGroup', value)
      }
    },
    messageChannel: {
      get () {
        return this.$store.state.payer.currentPayer.payerMessageType
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerMessageType = value
      }
    },
    payerReference: {
      get () {
        return this.$store.state.payer.currentPayer.reference
      },
      set (value) {
        this.$store.commit('updatePayerReference', value)
      }
    },
    paymentPlans: {
      get () {
        return this.$store.state.payer.currentPayer.paymentPlans
      },
      set (value) {
        this.$store.state.payer.currentPayer.paymentPlans = value
      }
    },
    payerStagingDetails: {
      get () {
        return this.$store.state.payer.currentPayer.payerStaging
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerStaging = value
      }
    },
    status: {
      get () {
        return this.$store.state.payer.currentPayer.status
      },
      set (value) {
        this.$store.state.payer.currentPayer.status = value
      }
    },
    payerPortalId: {
      get () {
        return this.$store.state.payer.currentPayer.payerPortalId
      },
      set (value) {
        this.$store.state.payer.currentPayer.payerPortalId = value
      }
    },
    accessedPortal: {
      get () {
        return this.$store.state.payer.currentPayer.accessedPortal
      },
      set (value) {
        this.$store.state.payer.currentPayer.accessedPortal = value
      }
    },
    editable: {
      get () {
        return this.$store.state.payer.currentPayer.editable
      }
    },
    emailVerified: {
      get () {
        return this.$store.state.payer.currentPayer.emailVerified
      }
    },
    historicPlanCount: {
      get () {
        return this.$store.state.payer.currentPayer.historicPlanCount
      },
      set (value) {
        this.$store.state.payer.currentPayer.historicPlanCount = value
      }
    },
    detailsValidationMessage: {
      get () {
        return this.$store.state.payer.detailsValidationMessage
      }
    },
    bankingValidationMessage: {
      get () {
        return this.$store.state.payer.bankingValidationMessage
      }
    },
    plansValidationMessage: {
      get () {
        return this.$store.state.payer.plansValidationMessage
      }
    },
    bankingValidated: {
      get () {
        return this.$store.state.payer.currentPayer.bankAccountDetails.validated
      }
    },
    paygateId () {
      return this.$store.state.common.paygateId
    },
    allowMultiplePlans () {
      if (!this.groupConfig) {
        return false
      }
      return this.groupConfig.allowMultiplePlans
    },
    planLimitHit () {
      return (!this.allowMultiplePlans && this.historicPlanCount === 1) || (this.allowMultiplePlans && this.outOfPlanNames)
    },
    validModulus () {
      if (!this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult) {
        return true
      } else if (!this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult) {
        return true
      }
      return this.$store.state.payer.currentPayer.bankAccountDetails.validateApiResult.valid
    },
    groupName () {
      let retVal = ''
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        for (var i = 0; i < this.customerGroups.length; i++) {
          if (this.customerGroups[i].groupId === this.groupId) {
            retVal = this.customerGroups[i].name
            break
          }
        }
      }
      return retVal
    },
    previewUrl () {
      // const paygateId = this.$store.state.common.paygateId
      if (this.sendMessagePlan && this.sendMessageType) {
        const pdfUrl = encodeURI(`${process.env.VUE_APP_DDMS_API_URL}messages/payerpreview/letter/${this.sendMessagePlan}/${this.sendMessageType}`)
        console.log(pdfUrl)
        var previewUrl = `${process.env.VUE_APP_PDFVIEWER_URL}?file=${pdfUrl}&token=${this.bearerToken}`
        const paygateId = this.$store.state.common.paygateId
        if (paygateId) {
          previewUrl += `&paygateId=${paygateId}`
        }
        return previewUrl
      } else {
        return process.env.VUE_APP_PDFVIEWER_URL
      }
    },
    hasPayerPortal () {
      return this.$store.getters.hasLicence('LicenceCollectionsPayerPortal')
    },
    canDelete () {
      return this.status >= 3
    }
  },
  async mounted () {
    await this.$store.dispatch('populateAllCollectionsGroups')
    this.bearerToken = await auth.getAccessToken()
    this.groupConfig = await this.$store.dispatch('getGroupConfigurationFromPayerId', { id: this.ukPayerId })
    await this.loadGroups(this.paygateId)
    this.sendMessagePlan = this.paymentPlanSelectOptions[0].value
    if (this.paymentPlans.length > 0) {
      this.setSelectedPlan(0)
    }
    if (this.sendMessageType && this.sendMessageType !== null) {
      await this.getPreview()
    }
    if (this.paymentPlans.length === 0) {
      this.$store.commit('setPlanValidationMessage', 'No Plans Specified')
    }
    // await this.getPayerMessages()
    // await this.$store.dispatch('getGroupBankAccounts', this.groupId)
    await this.$store.dispatch('getAvailableBankAccounts')
    var hubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/messaging?paygateid=' + this.selectedPaygateId
    window.addEventListener('beforeunload', this.stopSocketListener)
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        this.portalMessagingConnection = new HubConnectionBuilder().withUrl(hubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.portalMessagingConnection.start()
        this.portalMessagingConnection.on('messagesupdated', async data => {
          await this.getPayerMessages()
        })
      })
  },
  async created () {
    // If there are no initially visible sections, keep the defaults (all visible). Else set them here:
    if (this.payerDetailsInitiallyVisible || this.bankAccountInitiallyVisible || this.paymentPlansInitiallyVisible) {
      this.payerDetailsVisible = this.payerDetailsInitiallyVisible
      this.bankAccountVisible = this.bankAccountInitiallyVisible
      this.paymentPlansVisible = this.paymentPlansInitiallyVisible
    }
    await this.fetchPayerDetails()
    const data = await this.$store.dispatch('getGroupPlanNames')
    await this.$store.dispatch('populateAllCollectionsGroups')
    this.planNames = this.checkPlanNames(data)
    await this.loadLicence()
  },
  validations: {
    groupId: { required },
    payerReference: {
      required
    },
    newPlanReference: {}
  }
}
</script>
<style>
.focused-item {
  animation-name: yellowfade;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

.missing-fields {
  color: red;
}
.warning-fields {
  color: orange;
}
.planRow .tabs {
  width: 100%;
}

.vertical-tab-list {
  border-bottom: solid 0px black !important;
  border-right: 0px solid #ddd;
}

.vertical-tab-container {
  border-bottom: solid 0px black !important;
  border-right: 1px solid #ddd;
  padding-right: 0px;
}

.vertical-tab-list .nav-item .active,
.vertical-tab-list .nav-item .nav-link:hover {
  border: 1px solid #ddd;
  border-right: solid 1px transparent;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
  margin: 0;
}

@media print {
  .no-print-tabs,
  .noprint-footer,
  .vgt-global-search__input,
  .vgt-global-search__actions,
  .idb-block-title {
    display: none;
  }
  th {
    border: solid 1px black;
  }
  .vgt-inner-wrap,
  .vgt-global-search {
    border: solid 1px transparent;
  }
}

#smsMessageBody {
  min-height: 7rem;
}

.inline-flex {
  display: flex;
}

.smsPreviewText {
  margin-left: 2rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  margin-right: 2rem;
}

.smsPreviewTextLoading {
  margin-left: 2rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  color: transparent;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.speech-bubble-label {
  margin-top: 0.5rem;
  margin-right: 2rem;
  min-width: 5rem;
}

.speech-bubble {
  position: relative;
  background: #eeee;
  border-radius: 0.4em;
  color: black;
  width: 20rem;
  min-height: 7rem;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 1.7rem;
  width: 0;
  height: 0;
  border: 1.25em solid transparent;
  border-right-color: #eee;
  border-left: 0;
  border-top: 0;
  margin-top: -0.625em;
  margin-left: -1em;
}
</style>
