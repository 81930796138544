<template>
	<div style="width:100%; min-width:25vw">
		<label class="sr-only">Select Customer</label>
		<vue-select
			id="select"
			v-model="selectedPaygateId"
			:options="customers"
			:closeOnSelect="true"
			label="name"
			:reduce="customer => customer.paygateId"
			:clearable="false"
			:selectable="option => !option.hasOwnProperty('group')"
		>
			<template #option="{ group, name }">
				<div v-if="group" class="group">{{ group }}</div>
				{{ name }}
			</template>
		</vue-select>
	</div>
</template>

<script>
import VueSelect from 'vue-select'

import { mapGetters } from 'vuex'

function paygateIdToType (paygateId) {
	if (paygateId === process.env.VUE_APP_CORVID_ID) {
		return 3
	}

	return 0
}

export default {
	components: {
		VueSelect
	},
	computed: {
		selectedPaygateId: {
			get: function () {
				return this.$store.state.common.paygateId
			},
			set: function (paygateId) {
				try {
					this.$store.dispatch('setPaygateId', paygateId)
					this.$store.commit('clearTableFilters')
				} catch (error) {
					console.error(error)
				}
			}
		},
		customers () {
			var customers = JSON.parse(JSON.stringify(this.customersOrdered))

			customers = customers.sort((a, b) => {
				var typeA = paygateIdToType(a.paygateId)
				var typeB = paygateIdToType(b.paygateId)
				return (typeA > typeB) ? -1 : (typeA < typeB) ? 1 : 0
			})
			var result = []
			customers.forEach((c, i) => {
				if (i === 0) {
					if (c.paygateId === process.env.VUE_APP_CORVID_ID) {
						result.push({ group: 'System Customer', name: '' })
					} else {
						result.push({ group: 'Customers', name: '' })
					}
				}
				if (i > 0) {
					var previousC = customers[i - 1]
					if (c.type !== previousC.type || previousC.paygateId === process.env.VUE_APP_CORVID_ID) {
						result.push({ group: 'Customers', name: '' })
					}
				}
				result.push(c)
			})

			return result
		},
		...mapGetters(['customersOrdered'])
	}
}
</script>
<style lang="scss">
.vs__dropdown-option--disabled {
	background: #ededed;
	color: #3b3b3b;
}

.v-select {
	font-size: 16px;
}
</style>
