export default {
  state: {
    searchTerm: ''
  },
  mutations: {
    setSearchTerm (state, searchTerm) {
      state.searchTerm = searchTerm
    }
  },
  getters: {
    searchTerm: state => state.searchTerm
  }
}
