var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("h5", { staticClass: "unpad" }, [
            _vm._v("Workflow Heatmap "),
            _c(
              "span",
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the data in the heatmap",
                        expression: "'Refresh the data in the heatmap'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.getHeatmapData($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-sync-alt pointer" })]
                )
              ],
              1
            )
          ]),
          _c("span", { staticClass: "small" }, [
            _c("strong", [_vm._v(_vm._s(_vm.workflow.title) + " (")])
          ]),
          _c(
            "router-link",
            {
              staticClass: "small",
              attrs: {
                tag: "a",
                to: "/automation/workflow/workflowdetail/" + _vm.workflowId
              }
            },
            [_vm._v("View")]
          ),
          _c("span", [_vm._v(")")]),
          _c("br"),
          _c("span", { staticClass: "small" }, [
            _vm._v(_vm._s(_vm.niceDateTime()))
          ])
        ],
        1
      ),
      _c("div", { staticClass: "col-md-9" }, [
        _c(
          "div",
          { staticClass: "heatmap-widget" },
          [
            _c("calendar-heatmap", {
              attrs: {
                "tooltip-unit": "executions",
                endDate: _vm.getHeatmapEnddate(),
                values: _vm.activityData,
                "range-color": _vm.rangeColours
              },
              on: { "day-click": _vm.heatmapClick }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }