var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "idb-full-block" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [_c("FullCalendar", { attrs: { options: _vm.calendarOptions } })],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h4", { staticClass: "m-0" }, [_vm._v("BACS Calendar")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }