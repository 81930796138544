<template>
  <div>
    <dashboard docPath="/administration/customer/dashboard/" :customer="true"></dashboard>
  </div>
</template>

<script>
import dashboard from '@/Assets/Components/Dashboard/Dashboard'

export default {
  name: 'EditCustomerDashboard',
  components: {
    dashboard: dashboard
  }

}
</script>

<style>
</style>
