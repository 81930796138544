import axios from 'axios'

// actions
const actions = {
  async getRolesAsDropDownSource ({ state }) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}role/Dropdown`)

      return response.data
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  actions
}
