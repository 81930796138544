var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Cancel Submissions"), _c("favourite-icon")], 1)
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-2 required" }, [
            _vm._v(" Select a Group ")
          ]),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("group-select", {
                attrs: { groups: _vm.customerGroups, clearable: false },
                on: { input: _vm.groupChanged },
                model: {
                  value: _vm.group,
                  callback: function($$v) {
                    _vm.group = $$v
                  },
                  expression: "group"
                }
              }),
              _vm.allGroups
                ? _c("p", [
                    _c("small", [
                      _vm._v(
                        "Payers can only be created once a group is selected."
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _c(
          "vue-good-table",
          {
            ref: "table",
            attrs: {
              columns: _vm.columns,
              rows: _vm.records,
              mode: "remote",
              styleClass: "vgt-table striped bordered",
              lineNumbers: true,
              totalRows: _vm.totalRecords,
              "search-options": {
                enabled: true
              },
              paginationOptions: _vm.paginationOptions,
              "sort-options": _vm.sortOptions,
              isLoading: _vm.isTableLoading
            },
            on: {
              "on-page-change": _vm.onPageChange,
              "on-sort-change": _vm.onSortChange,
              "on-column-filter": _vm.onColumnFilter,
              "on-per-page-change": _vm.onPerPageChange,
              "on-search": _vm.onSearch,
              "update:isLoading": function($event) {
                _vm.isTableLoading = $event
              },
              "update:is-loading": function($event) {
                _vm.isTableLoading = $event
              },
              "on-row-click": _vm.onRowClick,
              "on-cell-click": _vm.handleClick
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field === "cancelSub"
                      ? _c(
                          "span",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.cancelSubmission(
                                      props.row.submissionId,
                                      props.row.fileNumber
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "fas fa-times mr-2" }),
                                  _vm._v("Cancel")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(props.formattedRow[props.column.field]) +
                              " "
                          )
                        ])
                  ]
                }
              }
            ])
          },
          [
            _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
              _c("span", { staticStyle: { "text-align": "center" } }, [
                _vm._v("No entries have been found matching the criteria.")
              ])
            ]),
            _c(
              "div",
              { attrs: { slot: "table-actions" }, slot: "table-actions" },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Clear filters",
                        expression: "'Clear filters'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    staticClass: "btn btn-link",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.clearTableFilters($event)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "fa-stack",
                        staticStyle: { "font-size": "10px" }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                        }),
                        _c("i", {
                          staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                        })
                      ]
                    ),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Clear filters")
                    ])
                  ]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the data in the table",
                        expression: "'Refresh the data in the table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { disabled: _vm.isLoading, variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.load($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-sync pointer dimmedIcon" }),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Refresh Table")
                    ])
                  ]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Export the contents of the table",
                        expression: "'Export the contents of the table'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.exportTable($event)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-file-export pointer dimmedIcon"
                    }),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Export Table")
                    ])
                  ]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "idb-block-footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }