var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v("Workflow and Mapping Logs"),
              _c("span", { staticClass: "pull-right" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.docUrl + "/automation/logging/",
                      target: "_blank"
                    }
                  },
                  [_c("i", { staticClass: "far fa-question-circle" })]
                )
              ]),
              _c("favourite-icon")
            ],
            1
          ),
          _vm.trigger
            ? _c("h6", { staticClass: "pt-1" }, [
                _vm._v(_vm._s(_vm.trigger.title) + " "),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v(
                    " - " +
                      _vm._s(_vm.trigger.triggerType) +
                      " " +
                      _vm._s(_vm.trigger.enabled) +
                      " "
                  )
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "div",
            [
              _c(
                "vue-good-table",
                {
                  ref: "telemtable",
                  attrs: {
                    paginationOptions: _vm.paginationOptions,
                    "sort-options": _vm.sortOptions,
                    isLoading: _vm.isTableLoading,
                    rows: _vm.rows,
                    columns: _vm.columns,
                    mode: "remote",
                    totalRows: _vm.totalRecords,
                    lineNumbers: true,
                    styleClass: "vgt-table striped bordered table-hover"
                  },
                  on: {
                    "update:isLoading": function($event) {
                      _vm.isTableLoading = $event
                    },
                    "update:is-loading": function($event) {
                      _vm.isTableLoading = $event
                    },
                    "on-page-change": _vm.onPageChange,
                    "on-sort-change": _vm.onSortChange,
                    "on-column-filter": _vm.onColumnFilter,
                    "on-per-page-change": _vm.onPerPageChange,
                    "on-search": _vm.onSearch,
                    "on-row-click": _vm.onRowClick,
                    "on-cell-click": _vm.onCellClick
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "currentStatus"
                            ? _c(
                                "span",
                                [
                                  props.row.currentStatus == "Failed"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: { pill: "", variant: "danger" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus == "Execution"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "success"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus == "Complete"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "success"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus == "Processing"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: { pill: "", variant: "info" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus ==
                                      "Completed with warnings"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "warning"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus == "Manual Action"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "primary"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(props.row.currentStatus))
                                      ])
                                ],
                                1
                              )
                            : props.column.field == "createdAt"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.reformatDate(
                                        props.formattedRow[props.column.field]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : props.column.field == "updatedAt"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.reformatDate(
                                        props.formattedRow[props.column.field]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Clear filters",
                              expression: "'Clear filters'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          staticClass: "btn btn-link",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.clearTableFilters($event)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fa-stack",
                              staticStyle: { "font-size": "10px" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-filter fa-stack-1x dimmedIcon"
                              }),
                              _c("i", {
                                staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                              })
                            ]
                          ),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Clear filters")
                          ])
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Refresh the Workflow and Mapping Logs",
                              expression:
                                "'Refresh the Workflow and Mapping Logs'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.load($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-sync-alt pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Refresh Table")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._m(0)
      ])
    ]),
    _c("br"),
    _c("br")
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 col-sm-3" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }