var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.approveWithCode($event)
          }
        }
      },
      [
        _c(
          "b-modal",
          {
            ref: "approveAction",
            attrs: {
              id: "approveAction",
              variant: "primary",
              "no-close-on-backdrop": "",
              "no-close-on-esc": "",
              "hide-header-close": "",
              centered: "",
              title: "Approve Action",
              "ok-variant": "success",
              "cancel-variant": "danger",
              "cancel-title": "Cancel",
              "ok-title": "Approve Action",
              static: ""
            },
            on: { shown: _vm.focusInput }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("p", { domProps: { innerHTML: _vm._s(_vm.body) } })
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _vm.type !== "Authenticator" && _vm.type !== "Password"
                  ? _c("p", { staticClass: "help-block" }, [
                      _vm._v(
                        "Once you've received the code, please enter it here."
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  (_vm.type === "Password" ? "password" : "text") === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.code,
                            expression: "code"
                          }
                        ],
                        ref: "code",
                        staticClass: "form-control",
                        attrs: {
                          id: "code",
                          autocomplete: "off",
                          autofocus: "",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.code)
                            ? _vm._i(_vm.code, null) > -1
                            : _vm.code
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.code,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.code = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.code = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.code = $$c
                            }
                          }
                        }
                      })
                    : (_vm.type === "Password" ? "password" : "text") ===
                      "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.code,
                            expression: "code"
                          }
                        ],
                        ref: "code",
                        staticClass: "form-control",
                        attrs: {
                          id: "code",
                          autocomplete: "off",
                          autofocus: "",
                          type: "radio"
                        },
                        domProps: { checked: _vm._q(_vm.code, null) },
                        on: {
                          change: function($event) {
                            _vm.code = null
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.code,
                            expression: "code"
                          }
                        ],
                        ref: "code",
                        staticClass: "form-control",
                        attrs: {
                          id: "code",
                          autocomplete: "off",
                          autofocus: "",
                          type: _vm.type === "Password" ? "password" : "text"
                        },
                        domProps: { value: _vm.code },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.code = $event.target.value
                          }
                        }
                      }),
                  _vm.incorrectCode
                    ? _c("span", [
                        _c(
                          "span",
                          { staticClass: "form-text text-danger small" },
                          [_vm._v(_vm._s(_vm.incorrectCodeMsg))]
                        )
                      ])
                    : _vm._e(),
                  _vm.type !== "Authenticator" && _vm.type !== "Password"
                    ? _c("small", { staticClass: "form-text text-muted" }, [
                        _vm._v(" If you haven't received anything, please "),
                        _c(
                          "a",
                          {
                            staticClass: "alert-link",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.resendCode($event)
                              }
                            }
                          },
                          [_vm._v("Click Here")]
                        ),
                        _vm._v(" to resend the code. ")
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c("template", { slot: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", disabled: _vm.isLoading },
                  on: { click: _vm.hideModal }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "submit", disabled: _vm.isLoading }
                },
                [_vm._v("Approve Action")]
              )
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }