<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>About
          <span class="pull-right">
            <a :href="`${docUrl}/`" target="_blank">
              <i class="far fa-question-circle"></i>
            </a>
          </span>
        </h2>
      </div>
      <div class="idb-block-content">
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Version</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">3.3.5</span>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Commit Hash</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.commit}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Build Date</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.buildDate | formatDate('DD/MM/YYYY HH:mm')}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Customer Code</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.customerCode}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Customer Name</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.customerName}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-2 text-right col-form-label">Paygate Id</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.customerId}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Instance</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.instance}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="isSystemUser">
          <label for="description" class="col-sm-2 text-right col-form-label">Server Time</label>
          <div class="col-sm-10">
            <div class="form-control-plaintext">
              <span style="margin-right:5px">{{about.time | formatDate('DD/MM/YYYY HH:mm')}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="idb-block-footer">
        <label for="description" class="form-control-plaintext">&copy;Copyright {{new Date().getFullYear()}} Paygate Solutions Limited.</label>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AboutInfo from '@/Components/About/AboutInfo'
import roles from '@/Assets/Constants/roles'

export default {
  name: 'about',
  computed: {
    isSystemUser () { return this.$store.getters.isInRole(roles.SystemUser) }
  },
  data () {
    return {
      about: {
        customerId: null,
        instance: null,
        time: null
      },
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  async created () {
    var aboutResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}about`)
    this.about = aboutResponse.data
    this.about = { ...AboutInfo, ...this.about }
  }
}
</script>

<style>
</style>
