var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: { docPath: "/paygate-collections/portal/" }
            }),
            _vm._v("Portal Settings"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-5",
                  attrs: {
                    "label-cols": 5,
                    horizontal: "",
                    label: "Group",
                    "label-class": "required"
                  }
                },
                [
                  _c("group-select", {
                    attrs: { id: "groupSelect", groups: _vm.customerGroups },
                    model: {
                      value: _vm.groupId,
                      callback: function($$v) {
                        _vm.groupId = $$v
                      },
                      expression: "groupId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-5",
                  attrs: {
                    "label-cols": 5,
                    "label-class": "required",
                    horizontal: "",
                    label: "Email Subject"
                  }
                },
                [
                  _c("b-form-input", {
                    staticClass: "form-control",
                    attrs: { type: "text", id: "subjectInput" },
                    on: {
                      input: function($event) {
                        return _vm.delayTouch(_vm.$v.emailSubject)
                      }
                    },
                    model: {
                      value: _vm.emailSubject,
                      callback: function($$v) {
                        _vm.emailSubject = $$v
                      },
                      expression: "emailSubject"
                    }
                  }),
                  _vm.$v.emailSubject.$dirty
                    ? _c("b-col", [
                        _vm.$v.emailSubject.$invalid
                          ? _c(
                              "label",
                              {
                                staticClass: "text-danger small",
                                attrs: { id: "subjectRequiredLabel" }
                              },
                              [_vm._v("Please ensure a subject is provided")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              _c("label", [_vm._v("Message Body")]),
              _c("wysiwyg", {
                on: {
                  input: function($event) {
                    return _vm.delayTouch(_vm.$v.emailBody)
                  }
                },
                model: {
                  value: _vm.emailBody,
                  callback: function($$v) {
                    _vm.emailBody = $$v
                  },
                  expression: "emailBody"
                }
              }),
              _vm.$v.emailBody.$dirty
                ? _c("b-col", [
                    _vm.$v.emailBody.$invalid
                      ? _c(
                          "label",
                          {
                            staticClass: "text-danger small",
                            attrs: { id: "emailBodyRequiredLabel" }
                          },
                          [_vm._v("Ensure the Email body includes {####}.")]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "idb-block-footer" },
        [
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                disabled: _vm.$v.$invalid || _vm.isLoading
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.sendBulk($event)
                }
              }
            },
            [_vm._v("Send")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }