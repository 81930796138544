var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "text-center text-warning" }, [
      _c("i", { staticClass: "fa fa-lock" }),
      _vm._v(" Warning: You are not authorised to view that page. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }