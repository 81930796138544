<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Groups
          <help-icon docPath="/administration/groups/" />
          <favourite-icon />
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <div class="group-colour" :style="{'background-color':props.row.colour}"></div>
              <span>{{props.formattedRow[props.column.field]}}</span>
            </span>
          </template>
          <div slot="table-actions">
            <button
              v-if="canAddGroups"
              @click.prevent="create"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Create a new group'"
            >
              <i class="fa fa-plus dimmedIcon pointer"></i><span class="sr-only">Create a new group</span>
            </button>
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span><span class="sr-only">Clear filters</span>
            </button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer" v-if="canAddGroups">
        <button class="btn btn-primary" @click="create" type="button">
          <i class="fa fa-plus rpad"></i>Create Group
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import groupTypes from '@/Assets/Constants/groupTypes'
import roles from '@/Assets/Constants/roles'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  computed: {
    canAddGroups () {
      return this.$store.getters.isInRole(roles.AddGroups)
    },
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          tdClass: this.groupColourTd,
          filterOptions: {
            enabled: true
          }
        },
        {
          label: 'Type',
          field: 'groupType',
          filterOptions: {
            enabled: true,
            placeholder: 'All Types',
            filterDropdownItems: Object.values(groupTypes)
          }
        },
        {
          label: 'Created',
          field: 'createdDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'do MMMM yyyy'
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    onRowClick (params) {
      var groupId = params.row.groupId
      this.$router.push({ name: 'GroupEdit', params: { groupId } })
    },
    async load () {
      try {
        this.isTableLoading = true
        const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups`,
          {
            params: { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer },
            showerror: true,
            errormessage: 'Failed loading groups'
          })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    create () {
      this.$router.push({ name: 'GroupCreate' })
    }
  }
}

</script>
