<template>
  <div>
    <!-- Payment Approval Method -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Payment Approval Method</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="diff in difference.paymentApprovalMethod"
            :key="diff.count"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Number Of Approvers -->
    <div
      class="form-group row"
      v-if="actionGroup.groupTypeDetails.paymentApprovalMethod === 'Number of Approvers'"
    >
      <label for="description" class="col-sm-2 text-right col-form-label">Number Of Approvers</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="diff in difference.numberOfApprovers"
            :key="diff.count"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>

    <!-- Approval Range -->
    <div
      class="form-group row"
      v-if="actionGroup.groupTypeDetails.paymentApprovalMethod === 'Approval Range'"
    >
      <label for="description" class="col-sm-2 text-right col-form-label">Approval Range</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <div
            style="margin-right:5px; white-space: pre-wrap;display: inline-block;"
            v-for="diff in difference.approvalRanges"
            :key="diff.count"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</div>
        </div>
      </div>
    </div>

    <!-- Approval Method -->
    <div class="form-group row">
      <label for="description" class="col-sm-2 text-right col-form-label">Approval Method</label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          <span
            style="margin-right:5px"
            v-for="diff in difference.approvalMethod"
            :key="diff.count"
            :class="{ins:diff.added,del:diff.removed}"
          >{{diff.value}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences, diffLines } from 'diff'

export default {
  props: ['group', 'actionGroup'],
  computed: {
    difference () {
      const diff = Object.keys(this.group.groupTypeDetails).reduce((a, v) => {
        if (v !== 'paymentApprovalMethod' && v !== 'numberOfApprovers' && v !== 'approvalMethod') {
          return a
        }
        if (this.group.groupTypeDetails[v] === null || this.group.groupTypeDetails[v] === undefined) {
          this.group.groupTypeDetails[v] = '0'
        }
        if (this.actionGroup.groupTypeDetails[v] === null || this.actionGroup.groupTypeDetails[v] === undefined) {
          this.actionGroup.groupTypeDetails[v] = '0'
        }

        var tempObj = {}

        tempObj[v] = diffSentences(this.group.groupTypeDetails[v].toString(), this.actionGroup.groupTypeDetails[v].toString())
        return Object.assign(a, tempObj)
      }, {})

      if (this.actionGroup.groupTypeDetails.paymentApprovalMethod === 'Approval Range') {
        const group = this.group.approverAmountRanges.reduce((a, v) => {
          a.push(`greater than £${v.greaterThan} requires ${v.numberOfApprovers} approvers`)
          return a
        }, [])
        const action = this.actionGroup.approverAmountRanges.reduce((a, v) => {
          a.push(`greater than £${v.greaterThan} requires ${v.numberOfApprovers} approvers`)
          return a
        }, [])
        diff.approvalRanges = diffLines(group.join('\n'), action.join('\n'))
      }
      return diff
    }
  }
}
</script>

<style>
</style>
