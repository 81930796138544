import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Auth from '@/Assets/Components/Authentication/auth.js'

class WebSocketManager {
  constructor (connectionUrl, opts = {}, component) {
    this.format = opts.format && opts.format.toLowerCase()

    this.connectionUrl = connectionUrl
    this.opts = opts
    this.component = component

    this.reconnection = this.opts.reconnection || false
    this.reconnectionAttempts = this.opts.reconnectionAttempts || 50
    this.reconnectionDelay = this.opts.reconnectionDelay || 1000
    this.reconnectTimeoutId = 0
    this.reconnectionCount = 0
    this.events = this.opts.events || []
    this.connect(connectionUrl, opts)
  }

  connect (connectionUrl, opts = {}) {
    this.WebSocket = new HubConnectionBuilder()
      .withUrl(connectionUrl, {
        accessTokenFactory: async () => Auth.getAccessToken()
      })
      .configureLogging(LogLevel.Debug)
      .build()
    this.WebSocket.serverTimeoutInMilliseconds = 1000 * 1000
    this.WebSocket.send = this.send.bind(this)
    this.WebSocket
      .start()
      .then(() => {
        this.passToComponent('SOCKET_' + 'onopen', this.WebSocket)

        this.reconnectionCount = 0
        this.addEvents()
      })
      .catch(() => this.reconnect())
    return this.WebSocket
  }

  send (action, args) {
    this.WebSocket.invoke(action, args)
  }

  reconnect () {
    if (this.reconnectionCount < this.reconnectionAttempts) {
      this.reconnectionCount++
      clearTimeout(this.reconnectTimeoutId)

      this.reconnectTimeoutId = setTimeout(() => {
        this.passToComponent('SOCKET_RECONNECT', this.reconnectionCount)

        this.connect(this.connectionUrl, this.opts)
      }, this.reconnectionDelay)
    } else {
      this.passToComponent('SOCKET_RECONNECT_ERROR', true)
    }
  }

  addEvents () {
    this.events.forEach((eventType) => {
      this.WebSocket.on(eventType, (event) => {
        this.passToComponent('SOCKET_' + eventType, event)
      })
    })

    this.WebSocket.connection.transport._webSocket.onopen = (event) => {
      this.reconnectionCount = 0
    }
    // Add Close and Error Events
    this.WebSocket.connection.transport._webSocket.onerror = (event) => {
      this.passToComponent('SOCKET_' + 'onerror', event)
    }

    this.WebSocket.connection.transport._webSocket.onclose = (event) => {
      this.passToComponent('SOCKET_' + 'onclose', event)
      this.reconnect()
    }
  }

  passToComponent (eventName, event) {
    if (!eventName.startsWith('SOCKET_')) {
      return
    }
    try {
      // assume they're using the component
      let msg = event
      if (this.format === 'json' && event.data) {
        msg = JSON.parse(event.data)
      }
      const target = eventName.toUpperCase()
      this.component[target](msg)
    } catch (e) {
      console.error('You don\'t have this method defined on your component!', eventName)
    }
  }

  stop () {
    this.WebSocket.stop()
  }
}

export default (connectionUrl, opts = {}) => {
  return {
    created () {
      this.webSocket = new WebSocketManager(connectionUrl, opts, this)
    },
    destroyed () {
      this.webSocket.stop()
    },
    methods: {
      sendToWebSocket (action, args) {
        this.webSocket.send(action, args)
      }
    }
  }
}
