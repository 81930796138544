var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "bacsConfig" } }, [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _vm.isLoaded
              ? _c(
                  "b-tabs",
                  {
                    attrs: { lazy: "" },
                    on: { input: _vm.tabSelected },
                    model: {
                      value: _vm.tabIndex,
                      callback: function($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: "Payment Network", active: "" } },
                      [_c("paymentNetwork")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Service Window" } },
                      [_c("serviceWindow")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Non-Processing Days" } },
                      [_c("nonProcessingDays")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Paygate Online Bureau Config" } },
                      [_c("payGateOnlineBureauConfig")],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Performance Tuning" } },
                      [_c("performanceTuning")],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("BACS Config")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }