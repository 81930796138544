var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " " +
              _vm._s(_vm.details.areaName) +
              " " +
              _vm._s(_vm.formType) +
              " "
          ),
          _c("help-icon", { attrs: { docPath: _vm.docPath } })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            { staticClass: "form-check" },
            [
              _c(
                "p-radio",
                {
                  staticClass: "p-default p-round",
                  attrs: { value: "Default" },
                  model: {
                    value: _vm.policy.behaviour.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.policy.behaviour, "$model", $$v)
                    },
                    expression: "policy.behaviour.$model"
                  }
                },
                [_vm._v(_vm._s(_vm.behaviourOptions[0].text))]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-check" },
            [
              _c(
                "p-radio",
                {
                  staticClass: "p-default p-round",
                  attrs: { value: "Custom" },
                  model: {
                    value: _vm.policy.behaviour.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.policy.behaviour, "$model", $$v)
                    },
                    expression: "policy.behaviour.$model"
                  }
                },
                [_vm._v(_vm._s(_vm.behaviourOptions[1].text))]
              )
            ],
            1
          )
        ]),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.policy.behaviour.$model === "Custom"
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group row",
                      class: {
                        invalid: _vm.policy.numberOfApprovalUsers.$error
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "col-form-label col-md-3 offset-md-1 required"
                        },
                        [_vm._v("Number of approval users")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-2" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.policy.numberOfApprovalUsers.$model,
                                expression:
                                  "policy.numberOfApprovalUsers.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number", min: "0", step: "1" },
                            domProps: {
                              value: _vm.policy.numberOfApprovalUsers.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.policy.numberOfApprovalUsers,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _c("validation-messages", {
                            model: {
                              value: _vm.policy.numberOfApprovalUsers,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.policy,
                                  "numberOfApprovalUsers",
                                  $$v
                                )
                              },
                              expression: "policy.numberOfApprovalUsers"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "form-check offset-md-1" },
                      [
                        _c(
                          "p-radio",
                          {
                            staticClass: "p-default p-round",
                            attrs: {
                              id: "customBehaviourAdministrators",
                              value: "Administrators"
                            },
                            model: {
                              value: _vm.policy.customBehaviour.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.policy.customBehaviour,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "policy.customBehaviour.$model"
                            }
                          },
                          [_vm._v(_vm._s(_vm.customBehaviourOptions[0].text))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-check offset-md-1" },
                      [
                        _c(
                          "p-radio",
                          {
                            staticClass: "p-default p-round",
                            attrs: {
                              id: "customBehaviourUserInRole",
                              value: "UsersInRole"
                            },
                            model: {
                              value: _vm.policy.customBehaviour.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.policy.customBehaviour,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "policy.customBehaviour.$model"
                            }
                          },
                          [_vm._v(_vm._s(_vm.customBehaviourOptions[1].text))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-check offset-md-1" },
                      [
                        _c(
                          "p-radio",
                          {
                            staticClass: "p-default p-round",
                            attrs: {
                              id: "customBehaviourSpecificUsers",
                              value: "SpecificUsers"
                            },
                            model: {
                              value: _vm.policy.customBehaviour.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.policy.customBehaviour,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "policy.customBehaviour.$model"
                            }
                          },
                          [_vm._v(_vm._s(_vm.customBehaviourOptions[2].text))]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.policy.customBehaviour.$model === "SpecificUsers"
                      ? _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: { invalid: _vm.policy.approvalUsers.$error }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-form-label col-md-3 offset-md-1 required"
                              },
                              [_vm._v("Specific Users")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("vue-select", {
                                  attrs: {
                                    multiple: "",
                                    options: _vm.details.users,
                                    closeOnSelect: false,
                                    "append-to-body": "",
                                    "calculate-position": _vm.withPopper
                                  },
                                  model: {
                                    value: _vm.policy.approvalUsers.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.policy.approvalUsers,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "policy.approvalUsers.$model"
                                  }
                                }),
                                _c(
                                  "validation-messages",
                                  {
                                    model: {
                                      value: _vm.policy.approvalUsers,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.policy,
                                          "approvalUsers",
                                          $$v
                                        )
                                      },
                                      expression: "policy.approvalUsers"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "override" }, [
                                      !_vm.policy.approvalUsers.required
                                        ? _c(
                                            "small",
                                            { staticClass: "form-text small" },
                                            [
                                              _vm._v(
                                                "At least one user is required"
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.policy.approvalUsers.min
                                        ? _c(
                                            "small",
                                            { staticClass: "form-text small" },
                                            [
                                              _vm._v(
                                                "At least " +
                                                  _vm._s(
                                                    _vm.policy
                                                      .numberOfApprovalUsers
                                                      .$model
                                                  ) +
                                                  " users are required"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label col-md-3 offset-md-1",
                        attrs: { for: "canApproveOwnAction" }
                      },
                      [_vm._v("Allow Named Users to Approve Their Own Actions")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("p-check", {
                          staticClass: "p-switch p-fill",
                          attrs: {
                            labelId: "canApproveOwnAction",
                            color: "primary"
                          },
                          model: {
                            value: _vm.policy.canApproveOwnCreation.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.policy.canApproveOwnCreation,
                                "$model",
                                $$v
                              )
                            },
                            expression: "policy.canApproveOwnCreation.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.policy.canApproveOwnCreation.$model
                      ? _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: {
                              invalid: _vm.policy.selfApprovalUsers.$error
                            }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "col-form-label col-md-3 offset-md-1 required"
                              },
                              [_vm._v("Users That Can Self Approve")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("vue-select", {
                                  attrs: {
                                    multiple: "",
                                    options: _vm.details.users,
                                    closeOnSelect: false,
                                    "append-to-body": "",
                                    "calculate-position": _vm.withPopper
                                  },
                                  model: {
                                    value: _vm.policy.selfApprovalUsers.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.policy.selfApprovalUsers,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "policy.selfApprovalUsers.$model"
                                  }
                                }),
                                _c(
                                  "validation-messages",
                                  {
                                    model: {
                                      value: _vm.policy.selfApprovalUsers,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.policy,
                                          "selfApprovalUsers",
                                          $$v
                                        )
                                      },
                                      expression: "policy.selfApprovalUsers"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "override" }, [
                                      !_vm.policy.selfApprovalUsers.required
                                        ? _c(
                                            "small",
                                            { staticClass: "form-text small" },
                                            [
                                              _vm._v(
                                                "At least one user is required"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-form-label col-md-3 offset-md-1" },
                      [_vm._v("Approval Method")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.approvalMethods },
                          model: {
                            value: _vm.policy.approvalMethod.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.policy.approvalMethod, "$model", $$v)
                            },
                            expression: "policy.approvalMethod.$model"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }