var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "alert alert-danger" }, [
                _c("h4", { staticClass: "alert-heading" }, [
                  _c("i", { staticClass: "fa fa-exclamation-triangle mr-2" }),
                  _vm._v(" Action Not Found ")
                ]),
                _c("hr"),
                _c("p", [
                  _vm._v(
                    " An action could not be found with that ID and may have already been actioned. "
                  ),
                  _c("br"),
                  _vm._v(
                    "Please check the action list to ensure the action still exists. "
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }