var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showFolderBrowser = false
                _vm.closePopup()
              }
            }
          })
        : _vm._e(),
      _vm._m(0),
      _c("hr"),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("Report Path")]),
        _c("div", { staticClass: "input-group mb-3" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedNode.props.s2.value,
                expression: "selectedNode.props.s2.value"
              }
            ],
            staticClass: "form-control",
            class: { invalid: _vm.$v.selectedNode.props.s2.value.$error },
            attrs: { type: "text" },
            domProps: { value: _vm.selectedNode.props.s2.value },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.selectedNode.props.s2,
                    "value",
                    $event.target.value
                  )
                },
                function($event) {
                  return _vm.$v.selectedNode.props.s2.value.$touch()
                }
              ]
            }
          }),
          _c("div", { staticClass: "input-group-append" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.btnPathBrowser($event)
                  }
                }
              },
              [_vm._v("...")]
            )
          ])
        ]),
        !_vm.$v.selectedNode.props.s2.value.required &&
        _vm.$v.selectedNode.props.s2.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("The Report Path field is required!")
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.s2.value.maxLength
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Maximum length is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.s2.value.$params.maxLength.max
                  ) +
                  " characters!"
              )
            ])
          : _vm._e()
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("Collections Report Reconciliation"),
      _c("span", { staticClass: "pull-right" }, [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("i", { staticClass: "far fa-question-circle" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }