import roles from '@/Assets/Constants/roles'
import Store from '@/Store/index'
import licence from '@/Assets/Constants/licence'

import Bacs from '@/Menu/Modules/bacs'
import Bureau from '@/Menu/Modules/bureau'
import Workflow from '@/Menu/Modules/workflow'
import Validate from '@/Menu/Modules/validate'
import Platform from '@/Menu/Modules/platform'
import About from '@/Menu/Modules/about'

export default {
	routes: [
		{
			menu_title: 'Payments',
			menu_icon: 'ti-cloud-up',
			routes: Bacs,
			path: '/payments/'
		},
		{
			menu_title: 'Bureau',
			menu_icon: 'ti-archive',
			routes: Bureau,
			path: '/bureau/',
			showMethod: async () => {
				return Store.getters.hasLicence(licence.bureau)
			}
		},
		{
			menu_title: 'Automation',
			menu_icon: 'ti-loop',
			routes: Workflow,
			path: '/automation/'
		},
		{
			menu_title: 'Validate',
			menu_icon: 'ti-check-box',
			routes: Validate,
			path: '/validate/',
			showMethod: async () => {
				return Store.getters.hasLicence(licence.validate) && (Store.getters.hasLicence(licence.validate_modcheck) || Store.getters.hasLicence(licence.validate_iban) || Store.getters.hasLicence(licence.validate_cc))
			}
		},
		{
			menu_title: 'Admin',
			menu_icon: 'ti-settings',
			routes: Platform,
			showMethod: async () => {
				return Store.getters.isInRoles([
					roles.CustomerAdministrator,
					roles.ManageBanks,
					roles.AddGroups,
					roles.ManageUsers,
					roles.ViewAuditLog,
					roles.ViewBanks,
					roles.ViewUsers,
					roles.CreateCustomer,
					roles.DeleteCustomer,
					roles.GDPRAdministrator,
					roles.ManageAntiFraud,
					roles.ImportCustomer,
					roles.Finance,
					roles.Marketing,
					roles.GroupAdministrator,
					roles.AgentConfigure])
			},
			path: '/admin',
			name: 'Admin'
		},
		{
			menu_title: 'About',
			menu_icon: 'ti-info-alt',
			routes: About,
			path: '/about'
		}
	]
}
