var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", size: "lg", "no-close-on-backdrop": "" },
      on: { hide: _vm.hide, shown: _vm.setFocus },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-header" },
          slot: "modal-header"
        },
        [
          _vm._v(" Service Window Details for the "),
          _c("strong", [
            _vm._v(_vm._s(_vm.networkType) + " " + _vm._s(_vm.windowType))
          ]),
          _vm._v(" on a "),
          _c("strong", [_vm._v(_vm._s(_vm.dayOfWeek))])
        ]
      ),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4" }, [_vm._v("Start Time")]),
        _c(
          "div",
          {
            staticClass: "col-md-8",
            class: { invalid: _vm.$v.startTime.$error }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.$v.startTime.$model,
                  expression: "$v.startTime.$model",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              staticStyle: { width: "100px" },
              domProps: { value: _vm.$v.startTime.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.startTime,
                    "$model",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _c(
              "validation-messages",
              {
                attrs: { name: "Start Time" },
                model: {
                  value: _vm.$v.startTime,
                  callback: function($$v) {
                    _vm.$set(_vm.$v, "startTime", $$v)
                  },
                  expression: "$v.startTime"
                }
              },
              [
                _vm.$v.startTime.timeValidator != undefined &&
                !_vm.$v.startTime.timeValidator
                  ? _c("small", { staticClass: "form-text small" }, [
                      _vm._v(
                        "Incorrect time format. The format for the time must be hh:mm."
                      )
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4" }, [_vm._v("End Time")]),
        _c(
          "div",
          {
            staticClass: "col-md-8",
            class: { invalid: _vm.$v.endTime.$error }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.$v.endTime.$model,
                  expression: "$v.endTime.$model",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              staticStyle: { width: "100px" },
              domProps: { value: _vm.$v.endTime.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.$v.endTime, "$model", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _c(
              "validation-messages",
              {
                attrs: { name: "End Time", an: true },
                model: {
                  value: _vm.$v.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.$v, "endTime", $$v)
                  },
                  expression: "$v.endTime"
                }
              },
              [
                _vm.$v.endTime.timeValidator != undefined &&
                !_vm.$v.endTime.timeValidator
                  ? _c("small", { staticClass: "form-text small" }, [
                      _vm._v(
                        "Incorrect time format. The format for the time must be hh:mm"
                      )
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Exclude Public Holidays")
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { labelId: "excludePublicHolidays", color: "primary" },
              model: {
                value: _vm.excludePublicHolidays,
                callback: function($$v) {
                  _vm.excludePublicHolidays = $$v
                },
                expression: "excludePublicHolidays"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", { attrs: { for: "is247" } }, [_vm._v("Is 24/7")])
        ]),
        _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { labelId: "is247", color: "primary" },
              model: {
                value: _vm.isTwentyFourSeven,
                callback: function($$v) {
                  _vm.isTwentyFourSeven = $$v
                },
                expression: "isTwentyFourSeven"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", { attrs: { for: "isProcesingDay" } }, [
            _vm._v("Is Processing Day")
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { labelId: "isProcesingDay", color: "primary" },
              model: {
                value: _vm.isProcessingDay,
                callback: function($$v) {
                  _vm.isProcessingDay = $$v
                },
                expression: "isProcessingDay"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "float-left",
                      attrs: {
                        size: "sm",
                        variant: "primary",
                        disabled: _vm.disableButtons
                      },
                      on: { click: _vm.saveServiceWindow }
                    },
                    [_vm._v("Save Service Window")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "float-left",
                      attrs: {
                        size: "sm",
                        variant: "light",
                        disabled: _vm.disableButtons
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }