var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type == "Bank Account"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [
                _vm._v(
                  _vm._s(_vm._f("substring")(_vm.hit.data.reference, 0, 2))
                )
              ]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.reference))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(_vm._s(_vm.hit.data.description))
            ])
          ])
        ])
      : _vm.type == "User"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [_vm._v(_vm._s(_vm._f("substring")(_vm.hit.data.name, 0, 2)))]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.name))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(_vm._s(_vm.hit.data.email))
            ])
          ])
        ])
      : _vm.type == "Mappings"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [_vm._v(_vm._s(_vm._f("substring")(_vm.hit.data.title, 0, 2)))]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.title))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(_vm._s(_vm.hit.data.email))
            ])
          ])
        ])
      : _vm.type == "Workflows"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [_vm._v(_vm._s(_vm._f("substring")(_vm.hit.data.title, 0, 2)))]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.title))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(_vm._s(_vm.hit.data.email))
            ])
          ])
        ])
      : _vm.type == "Triggers"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [_vm._v(_vm._s(_vm._f("substring")(_vm.hit.data.title, 0, 2)))]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.title))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(_vm._s(_vm.hit.data.triggerType))
            ])
          ])
        ])
      : _vm.type == "Importers"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [_vm._v(_vm._s(_vm._f("substring")(_vm.hit.data.title, 0, 2)))]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.title))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(_vm._s(_vm.hit.data.importerType))
            ])
          ])
        ])
      : _vm.type == "Payments"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [
                _vm._v(
                  _vm._s(_vm._f("substring")(_vm.hit.data.userReference, 0, 2))
                )
              ]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.userReference))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(
                _vm._s(_vm.hit.data.paymentEngineType) +
                  " - " +
                  _vm._s(_vm.hit.data.thirdPartyAccountName) +
                  " - " +
                  _vm._s(_vm.hit.data.thirdPartySortCode) +
                  " - " +
                  _vm._s(_vm.hit.data.thirdPartyAccountNumber)
              )
            ])
          ])
        ])
      : _vm.type == "Collections Payer"
      ? _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [_vm._v(_vm._s(_vm._f("substring")(_vm.hit.data.referene, 0, 2)))]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.reference))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(
                _vm._s(_vm.hit.data.firstName) +
                  " - " +
                  _vm._s(_vm.hit.data.surname)
              )
            ])
          ])
        ])
      : _c("div", { staticClass: "row py-10" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "span",
              {
                staticClass:
                  "square-40 rounded-circle bg-warning text-white text-center font-lg mr-30"
              },
              [_vm._v(_vm._s(_vm._f("substring")(_vm.hit.data.name, 0, 2)))]
            )
          ]),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("h6", [
              _c("a", { staticClass: "search-title" }, [
                _vm._v(_vm._s(_vm.hit.data.name))
              ])
            ]),
            _c("p", { staticClass: "mb-0 font-sm search-description" }, [
              _vm._v(_vm._s(_vm.hit.data.description))
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }