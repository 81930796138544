<template>
	<div>
		<fileBrowser v-if="showModalFileBrowser" @close="showModalFileBrowser = false; closePopup()"></fileBrowser>
		<div class="row mb-4" v-if="isLicenced">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2 v-if="isNew">
							<span class="pull-right">
								<a
									:href="`${docUrl}/automation/paygate-mapping/importers/fixedlengthimporter/`"
									target="_blank"
								>
									<i class="far fa-question-circle"></i>
								</a>
							</span> Create a Fixed Length Importer
						</h2>
						<h2 v-else>
							<span class="pull-right">
								<a
									:href="`${docUrl}/automation/paygate-mapping/importers/fixedlengthimporter/`"
									target="_blank"
								>
									<i class="far fa-question-circle"></i>
								</a>
							</span> Edit a Fixed Length Importer
						</h2>
					</div>
					<div class="idb-block-content">
						<div class="row">
							<div class="col-md-2 col-sm-12">
								<label class="required" v-b-popover.hover.top.d500="'The title of the item.'">Title</label>
							</div>
							<div class="form-group col-md-10 col-sm-12">
								<input
									type="text"
									class="form-control"
									:class="{invalid: $v.importer.title.$error}"
									@input="$v.importer.title.$touch()"
									v-model="importer.title"
								/>
								<p
									class="validationText"
									v-if="!$v.importer.title.required && $v.importer.title.$dirty "
								>The title field is required!</p>
								<p
									class="validationText"
									v-if="!$v.importer.title.maxLength"
								>Maximum length is {{ $v.importer.title.$params.maxLength.max }} characters!</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-12">
								<label
									v-b-popover.hover.top.d500="'An optional text field for you to write a discription of the item.' "
								>Description</label>
							</div>
							<div class="form-group col-md-10 col-sm-12">
								<textarea
									class="form-control"
									rows="4"
									cols="60"
									@input="$emit('textarea',$event.target.value)"
									@blur="$v.importer.description.$touch()"
									v-model="importer.description"
								></textarea>
								<p
									class="validationText"
									v-if="!$v.importer.description.maxLength"
								>Maximum length is {{ $v.importer.description.$params.maxLength.max }} characters!</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-12">
								<label
									class
									v-b-popover.hover.top.d500="'The file path that Paygate will use when this mapping is run to locate the file.'"
								>File Location</label>
							</div>
							<div class="col-md-10 col-sm-12 mb-3">
								<div class="input-group">
									<input
										type="text"
										class="form-control"
										:class="{invalid: $v.importer.filePath.$error}"
										@input="$v.importer.filePath.$touch()"
										v-model="importer.filePath"
									/>
									<div class="input-group-append">
										<b-button @click.prevent="btnFileBrowser" variant="outline-secondary">...</b-button>
									</div>
								</div>
								<p
									class="validationText"
									v-if="!$v.importer.filePath.maxLength"
								>Maximum length is {{ $v.importer.filePath.$params.maxLength.max }} characters!</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-12">
								<label
									class="required"
									v-b-popover.hover.top.d500="'Sets the data type used for auto-mapping.'"
								>Data Type</label>
							</div>
							<div class="form-group col-md-4 col-sm-12">
								<select v-model="selectedDataType" class="form-control">
									<option
										v-for="option in dataTypeMode"
										:key="option.id"
										:value="option.value"
									>{{ option.text }}</option>
								</select>
							</div>
							<div class="form-group col-md-1 col-sm-12"></div>
						</div>
						<!-- <div class="row mt-2">
              <div class="col-md-2 col-sm-12">
                <label class="" v-b-popover.hover.top.d500="'Determines whether an existing file is to be imported into the system or a file will be created and exported from the system.'" >Type</label>
              </div>
              <div class="form-group col-md-2 col-sm-12">
                <select v-model="selectedType" class="form-control">
                  <option v-for="option in typeMode" :key="option.index" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
						</div>-->
					</div>
				</div>
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>
							File Cleaner
							<span class="pull-right">
								<a :href="`${docUrl}/automation/paygate-mapping/importers/filecleaning/`" target="_blank">
									<i class="far fa-question-circle"></i>
								</a>
							</span>
						</h2>
					</div>
					<div class="idb-block-content">
						<div class="row">
							<div class="col-md-2 col-sm-12">
								<label for="cleanFileCheckbox">Clean File on Import</label>
							</div>
							<div class="form-group col-md-1 col-sm-12">
								<p-check
									name="check"
									:labelId="'cleanFileCheckbox'"
									class="p-switch"
									color="primary "
									v-model="cleanOnImport"
								></p-check>
							</div>
							<transition name="fadein">
								<div class="form-group col-md-2 col-sm-12" v-if="cleanOnImport">
									<b-button
										v-b-toggle.cleanerRules
										variant="outline-primary"
										v-b-popover.hover.top.d500="'Set the rules Paygate will follow when cleaning an imported file.'"
									>
										<i class="fas fa-broom"></i> Cleaner Rules
										<span
											v-if="cleanerRules.filter(x => x.enabled).length"
											class="badge badge-pill badge-primary ml-2"
										>{{ cleanerRules.filter(x => x.enabled).length }}</span>
									</b-button>
								</div>
							</transition>
							<transition name="fadein">
								<div class="col-md-2 col-sm-12 text-right" v-if="cleanOnImport">
									<label
										class
										v-b-popover.hover.top.d500="'Will substitute all characters not found in the following character set with a whitespace character.'"
									>Character Set</label>
								</div>
							</transition>
							<transition name="fadein">
								<div class="form-group col-md-2 col-sm-12" v-if="cleanOnImport">
									<select v-model="selectedCharset" class="form-control">
										<option
											v-for="(option, index) in charset"
											:key="index + 1"
											:value="option.value"
										>{{ option.text }}</option>
									</select>
								</div>
							</transition>
							<transition name="fadein">
								<div class="col-md-1 col-sm-12" v-if="cleanOnImport"></div>
							</transition>
							<transition name="fadein">
								<div class="col-md-2 col-sm-12" v-if="cleanOnImport">
									<b-button
										v-b-toggle.substitutionRules
										variant="outline-primary"
										v-b-popover.hover.top.d500="'Set the rules Paygate will follow when making substitutions to a imported file.'"
									>
										<i class="icon fas fa-exchange-alt"></i> Substitution
										<span
											v-if="substitutionRules.length"
											class="badge badge-pill badge-primary ml-2"
										>{{ substitutionRules.length }}</span>
									</b-button>
								</div>
							</transition>
						</div>
						<!-- Cleaner Rules -->
						<div class="row" v-if="cleanOnImport">
							<div class="col-md-12">
								<b-collapse id="cleanerRules" accordion="accordion">
									<b-card title="File Cleaner Rules">
										<br />
										<div v-for="(column, index) in cleanerRules" :key="index">
											<div class="row">
												<div class="col-md-1"></div>
												<div class="col-md-11">
													<div class="pretty p-icon p-smooth">
														<input type="checkbox" v-model="column.enabled" />
														<div class="state p-primary">
															<i class="icon fa fa-check"></i>
															<label>{{column.name}}</label>
														</div>
													</div>
												</div>
											</div>
											<br />
										</div>
									</b-card>
									<br />
								</b-collapse>
							</div>
						</div>
						<!-- Substitution Rules -->
						<div class="row" v-if="cleanOnImport">
							<div class="col-md-12">
								<b-collapse id="substitutionRules" accordion="accordion">
									<b-card title="Substitution Rules">
										<div class="row">
											<div class="col-12">
												<span
													class="small"
												>Substitute the 'From' character with the value of the 'To' character.</span>
											</div>
										</div>
										<br />
										<div class="row">
											<div class="form-group col-md-12 col-sm-12">
												<div class="row">
													<div class="col-md-1"></div>
													<div class="col-md-2">
														<strong>Enabled</strong>
													</div>
													<div class="col-md-2">
														<strong>From</strong>
													</div>
													<div class="col-md-2">
														<strong>To</strong>
													</div>
												</div>
												<div v-for="(column,index) in substitutionRules" :key="index">
													<div class="row columnSection">
														<div class="col-md-1"></div>
														<div class="col-md-2">
															<div class="pretty p-icon p-smooth">
																<input type="checkbox" v-model="column.enabled" />
																<div class="state p-primary">
																	<i class="icon fa fa-check"></i>
																	<label></label>
																</div>
															</div>
														</div>
														<div class="col-md-1">
															<input type="text" class="form-control" maxlength="1" v-model="column.from" />
														</div>
														<div class="col-md-1"></div>
														<div class="col-md-1">
															<input type="text" class="form-control" maxlength="1" v-model="column.to" />
														</div>
														<div class="col-md-1"></div>
														<div class="col-md-5 text-right">
															<b-button
																variant="link"
																size="sm"
																@click.prevent="btnRemoveSubstitutionRule(index)"
																v-b-popover.hover.top.d500="'Removes the substitute rule.'"
															>
																<span class="text-secondary glyphicon ti-trash text-danger" aria-hidden="true"></span>
															</b-button>
														</div>
													</div>
												</div>
												<br />
												<div class="row">
													<div class="col-md-12 text-right">
														<b-button
															variant="btn btn-outline-primary float-right"
															@click.prevent="btnAddSubstitutionRule(index)"
															v-b-popover.hover.top.d500="'Add a new substitution rule.'"
														>
															<i class="icon fa fa-plus"></i> Add Rule
														</b-button>
													</div>
												</div>
											</div>
										</div>
									</b-card>
								</b-collapse>
							</div>
						</div>
					</div>
				</div>
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>
							File Pre-processing
							<span class="pull-right">
								<a
									:href="`${docUrl}/automation/paygate-mapping/importers/filepreprocessing/`"
									target="_blank"
								>
									<i class="far fa-question-circle"></i>
								</a>
							</span>
						</h2>
					</div>
					<div class="idb-block-content">
						<div class="row mb-4">
							<div class="col-md-2 col-sm-12">
								<label for="preProcessorCheckbox">Enable Pre-processing</label>
							</div>
							<div class="form-group col-md-1 col-sm-12">
								<p-check
									:labelId="'preProcessorCheckbox'"
									name="check"
									class="p-switch"
									color="primary "
									v-model="enablePreProcessor"
								></p-check>
							</div>
						</div>
						<transition name="fadein">
							<div v-if="enablePreProcessor">
								<draggable :list="preProcessingRules" class="list-group" group="preprop" handle=".handle">
									<div v-for="(item, index) in preProcessingRules" :key="index + 1">
										<div class="row">
											<div class="col-md-1 text-center">
												<i class="fa fa-align-justify handle"></i>
											</div>
											<div class="col-md-4">
												<div class="pretty p-icon p-smooth">
													<input type="checkbox" v-model="item.enabled" />
													<div class="state p-primary">
														<i class="icon fa fa-check"></i>
														<label>{{item.description}}</label>
													</div>
												</div>
											</div>
											<div class="col-md-7 text-right">
												<b-button variant="link" @click.prevent="btnRemovePreProcessingRule(index)">
													<span
														class="text-secondary glyphicon ti-trash text-danger"
														v-b-popover.hover.top.d500="'Removes the pre-processing rule.'"
														aria-hidden="true"
													></span>
												</b-button>
											</div>
										</div>
										<div
											v-if="item.hasValueA"
											class="row"
											:class="{ invalid: $v.preProcessingRules.$each[index].valueADefault.$error }"
										>
											<div v-if="item.hasValueA" class="col-md-2 text-right">
												<label>{{item.valueALabel}}</label>
											</div>
											<div v-if="item.hasValueA" class="col-md-2">
												<input
													class="form-control"
													@input="$v.preProcessingRules.$each[index].valueADefault.$touch()"
													type="textbox"
													v-model="item.valueADefault"
												/>
												<validation-messages
													v-model="$v.preProcessingRules.$each[index].valueADefault"
													:name="item.valueALabel"
												></validation-messages>
											</div>
										</div>
										<div
											v-if="item.hasValueB"
											class="row"
											:class="{ invalid: $v.preProcessingRules.$each[index].valueBDefault.$error }"
										>
											<div v-if="item.hasValueB" class="col-md-2 text-right">
												<label>{{item.valueBLabel}}</label>
											</div>
											<div v-if="item.hasValueB" class="col-md-2">
												<input
													class="form-control"
													@input="$v.preProcessingRules.$each[index].valueBDefault.$touch()"
													type="textbox"
													v-model="item.valueBDefault"
												/>
												<validation-messages
													v-model="$v.preProcessingRules.$each[index].valueBDefault"
													:name="item.valueBLabel"
												></validation-messages>
											</div>
										</div>
										<div
											v-if="item.hasValueC"
											class="row"
											:class="{ invalid: $v.preProcessingRules.$each[index].valueCDefault.$error }"
										>
											<div v-if="item.hasValueC" class="col-md-2 text-right">
												<label>{{item.valueCLabel}}</label>
											</div>
											<div v-if="item.hasValueC" class="col-md-2">
												<input
													class="form-control"
													@input="$v.preProcessingRules.$each[index].valueCDefault.$touch()"
													type="textbox"
													v-model="item.valueCDefault"
												/>
											</div>
											<validation-messages
												v-model="$v.preProcessingRules.$each[index].valueCDefault"
												:name="item.valueCLabel"
											></validation-messages>
										</div>
										<div
											v-if="item.hasValueD"
											class="row"
											:class="{ invalid: $v.preProcessingRules.$each[index].valueDDefault.$error }"
										>
											<div v-if="item.hasValueD" class="col-md-2 text-right">
												<label>{{item.valueDLabel}}</label>
											</div>
											<div v-if="item.hasValueD" class="col-md-2">
												<input
													class="form-control"
													:class="{invalid: $v.preProcessingRules.$each[index].valueDDefault.$error}"
													@input="$v.preProcessingRules.$each[index].valueDDefault.$touch()"
													type="textbox"
													v-model="item.valueDDefault"
												/>
											</div>
											<validation-messages
												v-model="$v.preProcessingRules.$each[index].valueDDefault"
												:name="item.valueDLabel"
											></validation-messages>
										</div>
										<hr />
									</div>
								</draggable>
								<div class="row">
									<div class="col-md-5">
										<!-- <div class="input-group">
                    <input type="text" class="form-control" placeholder="Test File Path" aria-label="Test File Path">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" @click.prevent="btnBrowsePreprocTestFile" type="button">...</button>
                    </div>
                    <button class="btn btn-outline-secondary ml-3" @click.prevent="btnTestPreProc" type="button">Test</button>
										</div>-->
									</div>
									<div class="col-md-5"></div>
									<div class="col-md-2">
										<b-button
											variant="btn btn-outline-primary float-right"
											@click.prevent="btnAddPreProcessorRule"
											v-b-popover.hover.top.d500="'Add a new Pre-Processor rule.'"
										>
											<i class="icon fa fa-plus"></i> Add Rule
										</b-button>
									</div>
								</div>
							</div>
						</transition>
					</div>
				</div>
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Columns</h2>
					</div>
					<div class="idb-block-content">
						<transition name="fadein">
							<div class="row" v-if="columnsOverlap">
								<div class="col-md-12">
									<div class="alert alert-warning" role="alert">
										<strong>Warning</strong> One or more of the columns overlap. This might indicate a configuration problem.
									</div>
								</div>
							</div>
						</transition>
						<transition name="fadein">
							<div class="row" v-if="duplicateDataTypes > 0">
								<div class="col-md-12">
									<div class="alert alert-danger" role="alert">
										<strong>Error</strong> Two or more of the columns have the same data type.
									</div>
								</div>
							</div>
						</transition>

						<div class="row">
							<div class="col-md-12">
								<label title="Description">Column Parameters</label>
							</div>
						</div>
						<div class="row">
							<!-- <div class="col-md-2 col-sm-12">
							</div>-->
							<div class="form-group col-md-12 col-sm-12">
								<transition-group name="fadein" tag="ul">
									<li
										v-for="(column, index) in this.importer.configuration"
										:key="index + 1"
										class="list-group-item"
									>
										<div v-if="index == 0" class="row">
											<div class="col-2">Start Position</div>
											<div class="col-2">Length of Field</div>
											<div class="col-2">End Position</div>
											<div class="col-5">Data Type</div>
											<div class="col-1"></div>
										</div>
										<div class="row">
											<div class="col-2">
												<input
													type="number"
													min="1"
													step="1"
													class="form-control"
													:class="{invalid: $v.importer.configuration.$each[index].start.$error}"
													@input="$v.importer.configuration.$each[index].start.$touch(); checkOverlap();"
													v-model="column.start"
												/>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].start.required"
												>The start value is required!</div>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].start.minValue"
												>Min start value is {{ $v.importer.configuration.$each[index].start.$params.minValue.min }}</div>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].start.maxValue"
												>Max start value is {{ $v.importer.configuration.$each[index].start.$params.maxValue.max }}</div>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].start.integer"
												>Start must be a whole number</div>
											</div>
											<div class="col-2">
												<input
													type="number"
													min="1"
													step="1"
													class="form-control"
													:class="{invalid: $v.importer.configuration.$each[index].width.$error}"
													@input="$v.importer.configuration.$each[index].width.$touch(); checkOverlap();"
													v-model="column.width"
												/>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].width.required"
												>The length value is required!</div>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].width.minValue"
												>Min length value is {{ $v.importer.configuration.$each[index].start.$params.minValue.min }}</div>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].width.maxValue"
												>Max length value is {{ $v.importer.configuration.$each[index].start.$params.maxValue.max }}</div>
												<div
													class="validationText"
													v-if="!$v.importer.configuration.$each[index].width.integer"
												>Length must be a whole number</div>
											</div>
											<div class="col-2">
												<label
													:class="{errorText: computeEndPosition(column.width, column.start) === 'Error'}"
												>{{computeEndPosition(column.width, column.start)}}</label>
											</div>
											<div class="col-5">
												<div v-if="selectedDataType === 'bacsPayments'" class>
													<v-select
														@input="changedDataType"
														v-model="column.strongType"
														:options="bacsDataTypes"
													></v-select>
												</div>
												<div v-else-if="selectedDataType === 'bacsBureauCustomers'" class>
													<v-select v-model="column.strongType" :options="bureauCustomerDataTypes"></v-select>
												</div>
											</div>
											<div class="col-1">
												<b-button
													variant="link"
													@click.prevent="btnRemoveColumn(index)"
													v-b-popover.hover.top.d500="'Removes the column from the Column Parameters.'"
												>
													<span class="text-secondary glyphicon ti-trash text-danger" aria-hidden="true"></span>
												</b-button>
											</div>
										</div>
									</li>
								</transition-group>
								<br />
								<button
									class="btn btn-outline-primary float-right"
									aria-hidden="true"
									v-b-popover.hover.top.d500="'Add a new column.'"
									@click.prevent="btnAddColumn"
								>
									<i class="fa fa-plus mr-2"></i>Add Column
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="idb-block">
					<div class="idb-block-title" v-if="!isNew">
						<div class="row">
							<span class="col-md-12 col-sm-12">
								<strong>Mappings using this item</strong>
								<span
									v-if="boundMappings.length > 0"
									class="badge badge-pill badge-primary ml-2"
								>{{ boundMappings.length }}</span>
								<b-button
									@click.prevent="getBoundMappings"
									class
									variant="link"
									v-b-popover.hover.top.d500="'Refresh the list of bound mappings.'"
								>
									<i class="fas fa-sync-alt pointer"></i>
								</b-button>
							</span>
						</div>
					</div>
					<div class="idb-block-content" v-if="!isNew">
						<div v-if="boundMappings.length > 0">
							<div class="row" v-for="m in boundMappings" :key="m.index" :value="m.value">
								<div class="col-md-12 ml-5">
									{{m.metaData.title}} (
									<router-link tag="a" :to="'/automation/mapping/mappingdetail/' + m._id" class="small">Edit</router-link>)
								</div>
							</div>
						</div>
						<div v-else>
							<span v-if="!isNew"></span>
						</div>
						<br />
					</div>
					<div class="idb-block-footer">
						<div class="row">
							<div class="col-md-12 col-sm-3">
								<b-button
									:disabled="isLoading"
									class="mr-3"
									v-if="isNew"
									@click.prevent="createImporter"
									variant="primary"
									v-b-popover.hover.top.d500="'Creates and saves the new item'"
								>Create Importer</b-button>
								<b-button
									:disabled="isLoading"
									class="mr-3"
									v-else
									@click.prevent="updateImporter"
									variant="primary"
									v-b-popover.hover.top.d500="'Update the current item saving any changes.'"
								>
									<i class="fa fa-edit mr-2"></i>Save
								</b-button>
								<!-- <b-button :disabled="isLoading" class="mr-3" v-if="!isNew" @click.prevent="cloneImporter" variant="outline-primary" v-b-popover.hover.top.d500="'Create a clone of this item'">Clone</b-button> -->

								<div class="btn-group" role="group">
									<button
										id="btnGroupDrop1"
										type="button"
										class="btn btn-outline-primary dropdown-toggle ml-3"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i class="glyphicon ti-more mr-2"></i>
									</button>
									<div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
										<a @click="cloneImporter" class="dropdown-item" href="#">
											<i class="glyphicon ti-files mr-2"></i>Clone Importer
										</a>
										<a v-if="isCorvidAdmin" @click="btnTransferImporter" class="dropdown-item" href="#">
											<i class="glyphicon ti-new-window mr-2"></i>Copy Importer to Another Customer
										</a>
										<div class="dropdown-divider"></div>
										<a @click.prevent="deleteImporter" class="dropdown-item text-danger" href="#">
											<i class="glyphicon ti-trash mr-2"></i>Delete Importer
										</a>
									</div>
								</div>

								<!-- <b-button v-if="!isNew" @click.prevent="deleteImporter" :disabled="!isNew && boundMappings.length > 0" variant="danger" class="pull-right" v-b-popover.hover.top.d500="'Permanently delete this item'"><i class="fa fa-trash mr-2"></i>Delete Importer</b-button> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import draggable from 'vuedraggable'
import { required, requiredUnless, maxLength, minValue, maxValue, integer } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'
export default {
	components: {
		'v-select': vSelect,
		fileBrowser,
		draggable,
		swal
	},
	mixins: [loading],
	validations: {
		duplicateDataTypes: {
			maxValue: maxValue(0)
		},
		preProcessingRules: {
			$each: {
				valueADefault: {
					required: requiredUnless(true),
					maxLength: (v, model) => {
						if (model.valueAMaxLength) {
							return maxLength(model.valueAMaxLength)(v)
						}
						return maxLength(512)(v)
					},

				},
				valueBDefault: {
					required,
					maxLength: maxLength(512)
				},
				valueCDefault: {
					required,
					integer,
					minValue: minValue(0),
					maxValue: maxValue(9999)
				},
				valueDDefault: {
					required,
					integer,
					minValue: minValue(0),
					maxValue: maxValue(9999)
				}
			}
		},
		importer: {
			configuration: {
				$each: {
					start: {
						required,
						integer,
						minValue: minValue(1),
						maxValue: maxValue(999)
					},
					width: {
						required,
						integer,
						minValue: minValue(1),
						maxValue: maxValue(999)
					}
				}
			},
			title: {
				required,
				maxLength: maxLength(64)
			},
			description: {
				maxLength: maxLength(1024)
			},
			filePath: {
				maxLength: maxLength(512)
			},
			parserParams: {
				ignoreFirstChars: {
					required,
					integer,
					minValue: minValue(0),
					maxValue: maxValue(9999)
				},
				ignoreLastChars: {
					required,
					integer,
					minValue: minValue(0),
					maxValue: maxValue(9999)
				}
			}
			// configuration: {
			//   required
			//   start: {
			//     required
			//   }
			// }
		}
	},
	computed: {
		corvidSelectedPaygateId () {
			return this.$store.state.common.paygateId
		},
		isCorvidAdmin () {
			return this.$store.getters.isInRole(roles.SystemUser)
		}
	},
	data () {
		return {
			isLicenced: true,
			docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
			boundMappings: [],
			showModalFileBrowser: false,
			isNew: true,
			columnsOverlap: false,
			duplicateDataTypes: 0,
			id: this.$route.params.id,
			importer: {
				parserParams: {
					ignoreFirstChars: '0',
					ignoreLastChars: '0'
				},
				configuration: []
			},
			authToken: '',
			importerTypes: [
				{ text: 'Cron Trigger', value: 'CronTrigger' },
				{ text: 'Schedule Trigger', value: 'ScheduleTrigger' },
				{ text: 'File Trigger', value: 'FileTrigger' }
			],
			selectedImporterType: 'fixedlength',
			enablePreProcessor: false,
			preProcessorRuleOptions: [
				{
					ppn: 'replaceText',
					description: 'Replace Text',
					hasValueA: true,
					valueADefault: 'old text',
					valueALabel: 'From',
					hasValueB: true,
					valueBDefault: 'new text',
					valueBLabel: 'To',
					hasValueC: false,
					valueCDefault: '0',
					valueCLabel: '',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'removeFirstCharacters',
					description: 'Remove First Characters',
					hasValueA: false,
					valueADefault: 'noop',
					valueALabel: '',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 1,
					valueCLabel: 'Number',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'removeLastCharacters',
					description: 'Remove Last Characters',
					hasValueA: false,
					valueADefault: 'noop',
					valueALabel: '',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 1,
					valueCLabel: 'Number',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'removeBetween',
					description: 'Remove Between',
					hasValueA: false,
					valueADefault: 'noop',
					valueALabel: '',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 0,
					valueCLabel: 'Start',
					hasValueD: true,
					valueDDefault: 0,
					valueDLabel: 'Finish',
					enabled: true
				},
				{
					ppn: 'skipfirstlines',
					description: 'Skip First Lines',
					hasValueA: false,
					valueADefault: '0',
					valueALabel: 'noop',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: '1',
					valueCLabel: 'Number of Lines',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'skiplastlines',
					description: 'Skip Last Lines',
					hasValueA: false,
					valueADefault: '0',
					valueALabel: 'noop',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: '1',
					valueCLabel: 'Number of Lines',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'sliceFile',
					description: 'Slice File',
					hasValueA: false,
					valueADefault: 'noop',
					valueALabel: '',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 100,
					valueCLabel: 'Length',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'removeLineIfStartsWith',
					description: 'Remove Line if starts with...',
					hasValueA: true,
					valueADefault: 'text',
					valueALabel: 'Starts with',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: false,
					valueCDefault: '0',
					valueCLabel: '',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'removeLineIfContains',
					description: 'Remove Line If Contains...',
					hasValueA: true,
					valueADefault: 'text',
					valueALabel: 'Contains',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: false,
					valueCDefault: '0',
					valueCLabel: '',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'insertAtPosition',
					description: 'Insert At Position',
					hasValueA: true,
					valueADefault: 'text',
					valueALabel: 'Text',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 0,
					valueCLabel: 'Position',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'padStartLines',
					description: 'Pad Start Lines',
					hasValueA: true,
					valueADefault: '',
					valueALabel: 'Pad Char',
					valueAMaxLength: 1,
					valueAMinLength: 1,
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: '100',
					valueCLabel: 'Length',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'padEndLines',
					description: 'Pad End Lines',
					hasValueA: true,
					valueADefault: '',
					valueALabel: 'Pad Char',
					valueAMaxLength: 1,
					valueAMinLength: 1,
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 100,
					valueCLabel: 'Length',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'truncateStartLines',
					description: 'Truncate Start Lines',
					hasValueA: false,
					valueADefault: 'noop',
					valueALabel: '',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 100,
					valueCLabel: 'Length',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				},
				{
					ppn: 'truncateEndLines',
					description: 'Truncate End Lines',
					hasValueA: false,
					valueADefault: 'noop',
					valueALabel: '',
					hasValueB: false,
					valueBDefault: 'noop',
					valueBLabel: '',
					hasValueC: true,
					valueCDefault: 100,
					valueCLabel: 'Length',
					hasValueD: false,
					valueDDefault: '0',
					valueDLabel: '',
					enabled: true
				}
			],
			selectedDataType: 'bacsPayments',
			dataTypeMode: [
				{ text: 'BACS/FPS Payments', value: 'bacsPayments', id: 0 },
				{ text: 'Bureau Customers', value: 'bacsBureauCustomers', id: 21 },
				{ text: 'ADDACS Report', value: 'addacsReport', id: 31 }
			],
			typeMode: [
				{ text: 'Importer', value: 'importer' },
				{ text: 'Exporter', value: 'exporter' }
			],
			substitutionRules: [],
			preProcessingRules: [],
			cleanerRules: [
				{
					rule: 'convertToUpperCase',
					name: 'Convert to Upper Case',
					enabled: false
				},
				{
					rule: 'removeAsciiNulls',
					name: 'Remove ASCII Nulls',
					enabled: false
				},
				{
					rule: 'ConvertLfToCrLf',
					name: 'Convert LF to CRLF',
					enabled: false
				},
				{
					rule: 'ConvertCrToCrLf',
					name: 'Convert CR to CRLF',
					enabled: false
				},
				{
					rule: 'ConvertCrLfToCr',
					name: 'Convert CRLF to CR',
					enabled: false
				},
				{
					rule: 'ConvertCrLfToLf',
					name: 'Convert CRLF to LF',
					enabled: false
				},
				{
					rule: 'ConverCrToLf',
					name: 'Convert CR to LF',
					enabled: false
				},
				{
					rule: 'ConverLfToCr',
					name: 'Convert LF to CR',
					enabled: false
				},
				{
					rule: 'ConvertToUTF8',
					name: 'Convert To UTF-8',
					enabled: false
				}
			],
			cleanOnImport: false,
			selectedCharset: 'bacs',
			charset: [
				{ text: 'BACS', value: 'bacs', id: 0 },
				{ text: 'Other', value: 'other', id: 1 }
				// { text: 'SEPA', value: 'sepa' },
				// { text: 'SWIFT X', value: 'swiftx' },
				// { text: 'SWIFT Y', value: 'swifty' },
				// { text: 'SWIFT Z', value: 'swiftz' },
				// { text: 'ASCII', value: 'ascii' },
				// { text: 'UTF-8', value: 'utf8' },
				// { text: 'UTF-16', value: 'utf16' }
			],
			bacsDataTypes: [
				'Third-party Sortcode',
				'Third-party Account Number',
				'Third-party Account Type',
				'Third-party Account Name',
				'Originating Sortcode',
				'Originating Account Number',
				'Originating Account Name',
				'Service User Reference',
				'Transaction Code',
				'Amount',
				'Date',
				// 'RTI Reference',
				'Service User Number',
				'Numeric Reference / RTI',
				'Message',
				'File Number',
				'Custom Field 01',
				'Custom Field 02',
				'Custom Field 03',
				'Custom Field 04',
				'Custom Field 05',
				'Custom Field 06',
				'Custom Field 07',
				'Custom Field 08',
				'Custom Field 09',
				'Custom Field 10',
				'Custom Field 11',
				'Custom Field 12',
				'Custom Field 13',
				'Custom Field 14',
				'Custom Field 15',
				'Custom Field 16'
			],
			bureauCustomerDataTypes: [
				'Company Name',
				'Contact First Name',
				'Contact Surname',
				'Address 1',
				'Address 2',
				'Address 3',
				'Address 4',
				'Address 5',
				'Postcode',
				'Telephone 1',
				'Telephone 2',
				'Website URL',
				'Email',
				'SUN',
				'Payment Limit',
				'Submission Limit',
				'Contra Narrative',
				'Bank Name',
				'Bank Reference',
				'Bank Address 1',
				'Bank Address 2',
				'Bank Address 3',
				'Bank Address 4',
				'Bank Address 5',
				'Bank Postcode',
				'Sortcode',
				'Account Number',
				'Default Import Schema',
				'Default Import Mapping',
				'Default Filename',
				'Notes',
				'Custom Field 01',
				'Custom Field 02',
				'Custom Field 03',
				'Custom Field 04',
				'Custom Field 05',
				'Custom Field 06',
				'Custom Field 07',
				'Custom Field 08',
				'Custom Field 09',
				'Custom Field 10',
				'Custom Field 11',
				'Custom Field 12',
				'Custom Field 13',
				'Custom Field 14',
				'Custom Field 15',
				'Custom Field 16'
			]
		}
	},
	methods: {
		async loadLicence () {
			let response
			try {
				response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })
				console.log(response)
				if (response && response.data && response.data.automation && response.data.automation.mappingDesigner === true) {
					console.log('response.data.automation.mappingDesigner: ', response.data.automation.mappingDesigner)
					this.isLicenced = response.data.automation.mappingDesigner
				} else {
					this.isLicenced = false
				}
			} catch (e) {
				console.log(e.message)
				this.isLicenced = false
			}

      // The exception is if the current user is a system admin then we allow it
      if (this.isCorvidAdmin) {
        this.isLicenced = true
      }

			if (this.isLicenced === false) {
				this.$snapbar.e('Sorry, you are not licensed to use the Mapping Designer and Importers.')
			}
		},
		checkOverlap () {
			this.columnsOverlap = false
			// Check if any of the columns start and end positions overlap with any others.
			for (let i = 0; i < this.importer.configuration.length; i++) {
				const s1 = parseInt(this.importer.configuration[i].start)
				const e1 = parseInt(this.importer.configuration[i].width) + s1 - 1
				// Compare against all other items
				for (let j = 0; j < this.importer.configuration.length; j++) {
					if (j !== i) {
						const s2 = parseInt(this.importer.configuration[j].start)
						const e2 = parseInt(this.importer.configuration[j].width) + s2 - 1
						const overlap = s1 <= e2 && s2 <= e1
						if (overlap) {
							this.columnsOverlap = true
						}
					}
				}
			}
		},
		computeEndPosition (s, w) {
			const si = parseInt(s)
			const wi = parseInt(w)
			const ei = si + wi
			if (isNaN(ei)) return 'Error'
			if (!isFinite(ei)) return 'Error'
			if (ei <= 0 || s <= 0 || w <= 0) return 'Error'
			return ei
		},
		checkDuplicateDataType () {
			// Check if any of the columns use the same datatype.  If so set form to invalid
			this.duplicateDataTypes = 0
			for (let i = 0; i < this.importer.configuration.length; i++) {
				const d1 = this.importer.configuration[i].strongType
				// Compare against all other items
				for (let j = 0; j < this.importer.configuration.length; j++) {
					if (j !== i) {
						const d2 = this.importer.configuration[j].strongType
						if (d1 === d2) {
							this.duplicateDataTypes++
							this.$v.duplicateDataTypes.$touch()
						}
					}
				}
			}
		},
		changedDataType () {
			this.checkDuplicateDataType()
		},
		async getBoundMappings () {
			this.boundMappings = []
			try {
				const response = await axios
					.get(`${process.env.VUE_APP_WORKFLOW_API_URL}boundMappings/${this.id}`, { showload: true })
				this.boundMappings = response.data.mappings
			} catch (e) {
				console.log(e)
			}
		},
		truncate (text) {
			if (text.length < 20) {
				return text
			} else {
				return `${text.substring(0, 19)}...`
			}
		},
		async createImporter () {
			if (!this.checkValidation()) {
				return
			}

			this.importer.dataType = this.selectedDataType
			this.importer.cleanerParams.cleanOnImport = this.cleanOnImport
			this.importer.cleanerParams.charset = this.selectedCharset
			this.importer.cleanerParams.cleanerRules = this.cleanerRules
			this.importer.cleanerParams.substitutionRules = this.substitutionRules
			this.importer.enablePreProcessor = this.enablePreProcessor
			this.importer.preProcessingRules = this.preProcessingRules

			let res
			try {
				res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}importer`, this.importer, { showload: true })
				if (res) {
					this.$toastr.s(`The item '${this.importer.title}' was successfully created.`)
					this.$router.push('/automation/mapping/import/importers')
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to create.')
				} else {
					if (e.response && e.response.data && e.response.data.errorMessage) {
						this.$snapbar.e(`Error creating. ${e.response.data.errorMessage}`)
					} else {
						this.$snapbar.e('Error creating.')
					}
				}
			}
		},
		getDataTypeText (selected) {
			const item = this.dataTypeMode.find(x => x.value === selected)
			return item.text
		},
		getTypeText (selected) {
			const item = this.typeMode.find(x => x.value === selected)
			return item.text
		},
		async updateImporter () {
			if (!this.checkValidation()) {
				return
			}
			this.importer.dataType = this.selectedDataType
			this.importer.enablePreProcessor = this.enablePreProcessor
			this.importer.preProcessingRules = this.preProcessingRules
			this.importer.cleanerParams = {
				charset: this.selectedCharset,
				cleanOnImport: this.cleanOnImport,
				substitutionRules: this.substitutionRules,
				cleanerRules: this.cleanerRules
			}
			let res
			try {
				res = await axios.patch(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.importer._id}`, this.importer, { showload: true })
				if (res) {
					this.$toastr.s(`The item '${this.importer.title}' was successfully updated.`)
					this.$router.push('/automation/mapping/import/importers')
				}
			} catch (e) {
				if (e.response && e.response.status === 400) {
					this.$snapbar.e(e.response.data.errorMessage)
				} else if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to update this item.')
				} else {
					this.$snapbar.e(`Update error (MP0017) - ${e.message}`)
				}
			}
		},
		btnAddColumn () {
			let start = 1
			const width = 8
			if (this.importer.configuration.length > 0) {
				const last = this.importer.configuration.slice(-1)[0]
				start = parseInt(last.start) + parseInt(last.width)
			}
			this.importer.configuration.push(
				{
					name: '',
					start,
					width,
					bacsType: '',
					enabled: true
				}
			)
		},
		btnRemoveColumn (index) {
			this.importer.configuration.splice(index, 1)
		},
		async btnTransferImporter () {
			// Get list of customers
			const customers = this.$store.getters.customersOrdered
			let inputOptions = {}
			// Munge the customer data into something that the SweetAlert will use.
			// Probably a nicer way to do this but it works for now
			let str = '{ '
			customers.forEach(el => {
				const name = el.name.replace(/[^0-9a-z-A-Z ]/g, '').replace(/ +/, ' ')
				const token = `"${el.paygateId}": "${name}", `
				str = str + token
			})
			str = str.substring(0, str.length - 2)
			str = str + '}'
			inputOptions = JSON.parse(str)

			const result = await swal.fire({
				title: 'Copy Importer To Another Customer',
				text: 'Copy the current importer to another customer.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes!',
				cancelButtonText: 'No',
				input: 'select',
				inputOptions: inputOptions
			})
			if (result.isConfirmed) {
				// paygateId of the recipient customer is in result.value
				let transferImporter = this.importer
				transferImporter.paygateId = result.value
				transferImporter.filePath = '\\'

				let res
				try {
					res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}transferimporter`, transferImporter)
					if (res) {
						this.$toastr.s(`The importer '${transferImporter.title}' was successfully transferred.`)
					}
				} catch (e) {
					if (e.response && e.response.data && e.response.data.errorMessage) {
						this.$snapbar.e(`Error transferring importer.  The importer was not transferred. ${e.response.data.errorMessage}`)
					} else {
						this.$snapbar.e('Error transferring importer.  The importer was not transferred.')
					}
				}
			}
		},
		async cloneImporter () {
			const result = await swal.fire({
				title: `Clone Importer`,
				text: `Create an exact copy of the current Importer.`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes!',
				cancelButtonText: 'No',
				input: 'text',
				inputValue: `${this.importer.title} (clone)`
			})
			if (result.isConfirmed) {
				if (!this.importer.configuration) {
					this.importer.configuration = {}
				}
				const cloneImporter = {
					title: result.value,
					description: this.importer.description,
					dataType: this.importer.dataType,
					filePath: this.importer.filePath,
					importerType: this.importer.importerType,
					paygateId: this.importer.paygateId,
					configuration: this.importer.configuration,
					parserParams: this.importer.parserParams,
					enablePreProcessor: this.enablePreProcessor,
					preProcessingRules: this.preProcessingRules,
					cleanerParams: {
						cleanOnImport: this.cleanOnImport,
						charset: this.selectedCharset,
						substitutionRules: this.substitutionRules,
						cleanerRules: this.cleanerRules
					}
				}
				let res
				try {
					res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}importer`, cloneImporter, { showload: true })
					if (res) {
						this.$toastr.s(`The item '${cloneImporter}' was successfully cloned.`)
						this.$router.push('/automation/mapping/import/importers')
					}
				} catch (e) {
					if (e.response && e.response.status === 403) {
						this.$snapbar.e('You are not authorised to clone this item.')
					} else {
						if (e.response && e.response.data && e.response.data.errorMessage) {
							this.$snapbar.e(`Error cloning. ${e.response.data.errorMessage}`)
						} else {
							this.$snapbar.e('Error cloning.')
						}
					}
				}
			}
		},
		async deleteImporter () {
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}
			const result = await swal.fire({
				title: 'Are you sure you want to delete this item?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: colours.danger,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			})
			if (result.isConfirmed) {
				let res
				try {
					res = await axios.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.id}`, { showload: true })
					if (res) {
						this.$toastr.s(`The item '${this.importer.title}' was successfully deleted.`)
						this.$router.push('/automation/mapping/import/importers')
					}
				} catch (e) {
					if (e.response && e.response.status === 403) {
						this.$snapbar.e('You are not authorised to delete this item.')
					} else if (e.response && e.response.status === 409) {
						this.$snapbar.e(e.response.data.errorMessage)
					} else {
						this.$snapbar.e(`Could not delete the item - ${e.message}`)
					}
				}
			}
		},
		btnFileBrowser () {
			this.$store.commit('setModalPopupString', this.importer.filePath)
			this.showModalFileBrowser = true
		},
		closePopup () {
			this.importer.filePath = this.$store.state.mapping.modalPopupString
		},
		btnRemoveSubstitutionRule (index) {
			this.substitutionRules.splice(index, 1)
		},
		btnAddSubstitutionRule () {
			const newRule = {
				enabled: true,
				from: '',
				to: ''
			}
			this.substitutionRules.push(newRule)
		},
		defaultImporter () {
			return {
				title: '',
				description: '',
				filePath: '\\',
				importerType: 'fixedlength',
				type: 'importer',
				dataType: this.selectedDataType,
				paygateId: this.paygateId,
				configuration: [],
				parserParams: {
					ignoreFirstChars: '0',
					ignoreLastChars: '0'
				},
				enablePreProcessor: false,
				preProcessingRules: [],
				cleanerParams: {
					cleanOnImport: true,
					charset: 'bacs',
					cleanerRules: [],
					substitutionRules: []
				}
			}
		},
		async loadImporter () {
			if (this.isLicenced === false) {
				return
			}
			let res
			try {
				res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}importer/${this.id}`, { showload: true })
				if (res) {
					if (res.data.importer) {
						this.importer = res.data.importer
						// Default to avoid breaking changes
						console.log(res.data.importer)
						if (!this.importer.cleanerParams) {
							this.importer.cleanerParams = {
								cleanOnImport: false,
								charset: 'bacs',
								cleanerRules: [
									{
										rule: 'convertToUpperCase',
										name: 'Convert to Upper Case',
										enabled: false
									},
									{
										rule: 'removeAsciiNulls',
										name: 'Remove ASCII Nulls',
										enabled: false
									},
									{
										rule: 'ConvertLfToCrLf',
										name: 'Convert LF to CRLF',
										enabled: false
									},
									{
										rule: 'ConvertCrToCrLf',
										name: 'Convert CR to CRLF',
										enabled: false
									},
									{
										rule: 'ConvertCrLfToCr',
										name: 'Convert CRLF to CR',
										enabled: false
									},
									{
										rule: 'ConvertCrLfToLf',
										name: 'Convert CRLF to LF',
										enabled: false
									},
									{
										rule: 'ConverCrToLf',
										name: 'Convert CR to LF',
										enabled: false
									},
									{
										rule: 'ConverLfToCr',
										name: 'Convert LF to CR',
										enabled: false
									},
									{
										rule: 'ConvertToUTF8',
										name: 'Convert To UTF-8',
										enabled: false
									}
								],
								substitutionRules: []
							}
						}
						if (!this.importer.cleanerParams.substitutionRules) {
							this.importer.cleanerParams.substitutionRules = []
							this.substitutionRules = []
						}

						this.selectedDataType = this.importer.dataType
						this.cleanOnImport = this.importer.cleanerParams.cleanOnImport
						this.selectedCharset = this.importer.cleanerParams.charset
						this.cleanerRules = this.importer.cleanerParams.cleanerRules

						if (this.importer.preProcessingRules) {
							this.enablePreProcessor = this.importer.enablePreProcessor
							this.preProcessingRules = this.importer.preProcessingRules
						} else {
							this.enablePreProcessor = false
							this.importer.preProcessingRules = []
						}

						this.substitutionRules = this.importer.cleanerParams.substitutionRules
						this.getBoundMappings()
					} else {
						this.importer = this.defaultImporter()
						this.$snapbar.w('Unknown or Invalid item')
					}
				}
			} catch (e) {
				this.importer = this.defaultImporter()
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('You are not authorised to view this item.')
				} else {
					this.$snapbar.e(`Could not display item - ${e.message}`)
				}
			}
		},
		async btnAddPreProcessorRule () {
			const inputOptions = {
				replaceText: 'Replace Text',
				removeFirstCharacters: 'Remove First Characters',
				removeLastCharacters: 'Remove Last Characters',
				removeBetween: 'Remove between',
				skipfirstlines: 'Skip First Lines',
				skiplastlines: 'Skip Last Lines',
				removeLineIfStartsWith: 'Remove Line if starts with...',
				removeLineIfContains: 'Remove Line if Contains...',
				sliceFile: 'Slice File',
				insertAtPosition: 'Insert at Position',
				padStartLines: 'Pad Start of Lines',
				padEndLines: 'Pad End of Lines',
				truncateStartLines: 'Truncate Start of Lines',
				truncateEndLines: 'Truncate End of Lines'
			}

			const result = await swal.fire({
				title: 'Add a Pre-Processor Rule',
				input: 'select',
				icon: 'question',
				inputOptions: inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Select',
				cancelButtonText: 'Cancel'
			})
			if (result.isConfirmed) {
				const ppItem = this.preProcessorRuleOptions.find(item => item.ppn === result.value)
				const ppItemSC = { ...ppItem } // Made a shallow copy!
				this.preProcessingRules.push(ppItemSC)
			}
		},
		btnRemovePreProcessingRule (index) {
			this.preProcessingRules.splice(index, 1)
		},
		capitalise (word) {
			if (!word) {
				return 'error'
			}
			return word.replace(/\b\w/g, l => l.toUpperCase())
		}
	},
	watch: {
		corvidSelectedPaygateId: async function (val) {
			await this.loadImporter()
		}
	},
	created: async function () {
		await this.loadLicence()
		this.paygateId = this.$store.getters.getClaim('paygate_id').value
		this.importer = this.defaultImporter()

		if (!this.id) {
			this.isNew = true
		} else {
			this.isNew = false
			// get importer from db
			await this.loadImporter()
			this.checkDuplicateDataType()
			this.checkOverlap()
		}
	}
}
</script>

<style scoped>
.handle {
	float: left;
	padding-top: 8px;
	padding-bottom: 8px;
	cursor: ns-resize;
}

.fadein-enter {
	opacity: 0;
}

.fadein-enter-active {
	transition: opacity 0.6s;
}

.fadein-leave-active {
	transition: opacity 0.6s;
	opacity: 0;
}
.errorText {
	color: #ff0000;
}
.invalid {
	border-color: red;
	border-width: 2px;
}
label.required {
	/* $gray-700:  */
	color: #878787;
}
label.required::after {
	content: " *";
	color: red;
}
.top-buffer {
	margin-top: 20px;
}

.columnSection {
	padding-bottom: 14px;
	padding-top: 8px;
	border-bottom: #dddddd;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.subtitle {
	color: #314b5f;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited {
	background-color: #c3164f !important;
}
.fadein-enter {
	opacity: 0;
}

.fadein-enter-active {
	transition: opacity 0.6s;
}

.fadein-leave-active {
	transition: opacity 0.6s;
	opacity: 0;
}
</style>
