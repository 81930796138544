<template>
	<div class="idb-block">
		<div class="idb-block-title">
			<h2>
				Select Files for Submission: {{submissionReference}}
				<help-icon :docPath="'/bacsbureau/builder/'"></help-icon>
			</h2>
		</div>

		<div v-show="useDropzone" class="idb-block-content">
			<b-card
				id="noBankLabel"
				v-if="customerNoBankSelected"
				bg-variant="warning"
				text-variant="white"
				style="margin-bottom: 0.8em; margin-right: 0.8em"
			>This bureau job has associated customers with no bank details set. This bureau job will fail to import with the current configuration.</b-card>
			<b-card
				id="lockedSubmissionLabel"
				v-if="submissionLocked"
				bg-variant="warning"
				text-variant="white"
				style="margin-bottom: 0.8em;"
			>Another user: {{lockingUsername}} is progressing this submission, it cannot be edited or progressed.</b-card>
			<b-row v-if="!submissionLocked">
				<b-col sm="12">
					<dropzone
						id="my-dropzone"
						ref="fileUploader"
						:options="dropzoneOptions"
						@vdropzone-success="afterSuccess"
						@vdropzone-error="afterError"
						@vdropzone-file-added="fileAdded"
						@vdropzone-removed-file="fileRemoved"
						@vdropzone-sending="sendingEvent"
						:use-custom-slot="true"
						:max-file-size-in-m-b="10"
						:useCustomSlot="true"
					>
						<div class="dropzone-custom-content">
							<h3 class="dropzone-custom-title">Drag and drop to add files to the Submission</h3>
							<button type="button" class="btn btn-link">...or click to select a file from your computer</button>
						</div>
					</dropzone>
				</b-col>
			</b-row>
		</div>

		<div v-show="initRestrictedFileBrowser" class="idb-block-content">
			<b-card
				v-if="submissionLocked"
				bg-variant="warning"
				text-variant="white"
				style="margin-bottom: 0.8em;"
			>Another user: {{lockingUsername}} is progressing this submission, it cannot be edited or progressed.</b-card>
			<div class="col-md-3">
				<button
					type="button"
					class="btn btn-primary"
					:disabled="submissionLocked"
					v-on:click="showRestrictedFileBrowser"
				>Select Submission File</button>
			</div>
		</div>
		<div v-show="useFtpFileBrowser" class="idb-block-content">
			<b-card
				v-if="submissionLocked"
				bg-variant="warning"
				text-variant="white"
				style="margin-bottom: 0.8em;"
			>Another user: {{lockingUsername}} is progressing this submission, it cannot be edited or progressed.</b-card>
			<div class="col-md-3">
				<button
					type="button"
					class="btn btn-primary"
					:disabled="submissionLocked"
					v-on:click="btnFtpFileBrowser"
				>Select Submission File</button>
			</div>
		</div>

		<div class="idb-block-content">
			<vue-good-table
				ref="table"
				:paginationOptions="{
                        enabled: true,
                        perPage: 10,
                        dropdownAllowAll: false
                      }"
				:rows="submissionDetails"
				:columns="columns"
				:lineNumbers="true"
				class="table-unset"
				:sort-options="{
                        enabled: true,
                        initialSortBy: {field: 'paymentFilename', type: 'asc'}
                      }"
				styleClass="vgt-table striped bordered "
        @on-row-click="onRowClick"
        :rowStyleClass="rowStyler"
			>
				<template slot="loadingContent">
					<h1>Loading...</h1>
				</template>

				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field == 'bureauCustomerName'">
						<b-input-group>
							<model-list-select
								:isDisabled="submissionLocked || isLoading || loading || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
								:id="props.row.bureauCustomerId"
								:list="BureauCustomersDisplay"
								v-model="props.row.bureauCustomerId"
								label="name"
								option-value="bureauCustomerId"
								option-text="name"
								placeholder="Select Customer"
								class="addFiles-tall-dropdown"
								@input="updateBureauCustomer(props.row)"
							></model-list-select>
							<b-input-group-append>
								<a
									class="btn btn-sm primary mt-1 reset-customer"
									v-if="props.row.status == 'Uploaded' && props.row.schema != 'Standard18' && !submissionLocked && (selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)"
									@click="submissionLocked ? null : onResetSelectedCustomer(props.row)"
								>
									<i class="fa fa-times"></i>
									<span class="sr-only"></span>
								</a>
							</b-input-group-append>
						</b-input-group>
						<small
							v-if="(props.row.importSchema === 'Mapping' || props.row.importSchema === 'Legacy') && props.row.bureauCustomerId === '00000000-0000-0000-0000-000000000000'"
							class="text-danger"
						>Please select a customer</small>
					</span>
					<span v-else-if="props.column.field == 'schema'">
						<b-row>
							<b-col sm="12">
								<b-form-select
									:id="'schemaSelector-'+props.row.bureauSubmissionDetailId"
									:options="schemas"
									v-model="props.row.importSchema"
									:isDisabled="props.row.status != 'Uploaded' ||  submissionLocked || isLoading || loading || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
									:disabled="props.row.status != 'Uploaded' ||  submissionLocked || isLoading || loading || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
									placeholder="Schema"
									class="addFiles-tall-dropdown schemaControl"
									@blur="props.row.importSchema"
								></b-form-select>
								<small
									v-if="props.row.importSchema === 'None'"
									class="text-danger"
								>Please select an import schema</small>
							</b-col>
						</b-row>
					</span>
					<span v-else-if="props.column.field == 'mapping'">
						<b-row>
							<b-col sm="12">
								<model-list-select
									:id="'mappingSelector-'+props.row.bureauSubmissionDetailId"
									:list="mappings"
									option-value="value"
									option-text="text"
									v-model="props.row.mapping"
									placeholder="Mapping"
									class="addFiles-tall-dropdown"
                  @input="props.row.edited = true"
									:isDisabled="props.row.status != 'Uploaded' || props.row.importSchema != 'Mapping' || submissionLocked || isFps || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
								></model-list-select>
								<small
									v-if="props.row.importSchema === 'Mapping' && (props.row.mapping === null || props.row.mapping === '') && !isLoading && !loading"
									class="text-danger"
								>Please select a mapping</small>
							</b-col>
						</b-row>
					</span>
					<span v-else-if="props.column.field == 'paymentDate'">
						<b-row>
							<b-col sm="12">
								<datepicker
									:disabled="submissionLocked || isLoading || loading || (selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)"
									ref="props.row.paymentDate"
									:name="'paymentDate-'+props.row.bureauSubmissionDetailId"
									:id="'paymentDate-'+props.row.bureauSubmissionDetailId"
									v-model="props.row.paymentDate"
									format="dd/MM/yyyy"
									:readonly="false"
									:disabledDates="disabledDates"
									:bootstrap-styling="true"
									v-on:selected="paymentDateChanged(props.row)"
									@focusin.native="dateOnfocusSE(this)"
									:monday-first="true"
								></datepicker>
							</b-col>
						</b-row>
					</span>
					<span v-else-if="props.column.field == 'delete'">
            <button :disabled="isLoading || submissionLocked || isLoading || loading"
                    v-if="(selectedRow.bureauSubmissionDetailId !== props.row.bureauSubmissionDetailId)" @click.stop="onRowClick(props)"
                    class="btn w-100 mb-1 btn-primary btn-sm"><i class="fa fa-edit mr-2"></i>Edit</button>
            <button :disabled="isLoading || submissionLocked || isLoading || loading"
                    v-if="(selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)" @click.stop="updateSubmissionDetail(props.row)"
                    class="btn w-100 mb-1 btn-success btn-sm"><i class="fa fa-save mr-2"></i>Save</button>
            <button :disabled="isLoading || submissionLocked || isLoading || loading"
                    v-if="(selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)" @click.stop="cancelRowUpdate(props.row)"
                    class="btn w-100 mb-1 btn-warning btn-sm"><i class="fa fa-times mr-2"></i>Cancel</button>
						<button
							:disabled="isLoading || submissionLocked || isLoading || loading"
							class="btn btn-danger btn-sm w-100" v-if="(selectedRow.bureauSubmissionDetailId === props.row.bureauSubmissionDetailId)"
							@click.stop="submissionLocked ? null : onDeleteFileClick(props.row)"
						>
							<i class="fa fa-trash mr-2"></i>Delete
						</button>
					</span>
					<span v-else>
						<b-row>
							<b-col sm="12" class="mt-2">{{ props.formattedRow[props.column.field] }}</b-col>
						</b-row>
					</span>
				</template>

				<div slot="table-actions">
					<button
						@click.prevent="clearTableFilters"
						class="btn btn-link"
						v-b-popover.hover.top.d500="'Clear filters'"
					>
						<span class="fa-stack" style="font-size: 10px;">
							<i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
							<i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
							<span class="sr-only">Clear filters</span>
						</span>
						<span class="sr-only">Clear filters</span>
					</button>
					<b-button
						@click.prevent="load"
						class
						variant="link"
						v-b-popover.hover.top.d500="'Refresh the data in the table'"
					>
						<i class="fa fa-sync pointer dimmedIcon"></i>
						<span class="sr-only">Refresh data</span>
					</b-button>
				</div>
			</vue-good-table>
			<div class="d-block py-3">&nbsp;</div>
		</div>

		<div class="idb-block-footer">
			<button
				class="btn btn-primary mr-2"
				@click="ImportManualBacsSubmission"
				:key="fileCount"
				:disabled="submissionDetails.length === 0 || submissionLocked || noSchemaSelected || noMappingSelected || noCustomerSelected || customerNoBankSelected"
			>Import Submission</button>
			<button
				class="btn btn-outline-secondary mr-2"
				@click="EditManualBacsSubmission"
				:disabled="submissionLocked || noSchemaSelected"
			>Change Submission</button>
			<button
				class="btn btn-outline-secondary mr-2"
				@click="onCreateJobFromManualSubmissionClick"
				:disabled="submissionLocked || noSchemaSelected"
			>Save as Automated Job</button>
		</div>
		<div>
			<createJobFromManualSubmissionModal
				:manualBureauJobId="id"
				v-on:submit="createJobFromManualSubmissionClosed"
			></createJobFromManualSubmissionModal>
		</div>
		<plugin @installCancelled="pluginNotInstalled"></plugin>
		<fileBrowser v-if="showFtpFileBrowser" @close="closeFileBrowserPopup()" :groupId="groupId">
			<h3 slot="header">Select Submission File</h3>
		</fileBrowser>
	</div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import axios from 'axios'
import auth from '@/Assets/Components/Authentication/auth.js'
import Dropzone from 'vue2-dropzone'
import swal from 'sweetalert2'
import _ from 'lodash'
// import SubmissionDetails from '@/Components/Bureau/BureauJob/JobSubmissionDetailsComponent.vue'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import colours from '@/Assets/Constants/colours'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import bacsCommon from '@/Lib/BacsCommon.js'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import FileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'
import Datepicker from 'vuejs-datepicker'
import CreateJobFromManualSubmission from '@/Components/Bureau/BureauBuilder/CreateJobFromManualSubmission.vue'
import { HubConnectionBuilder } from '@microsoft/signalr'

var GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export default {
	components: {
		plugin: Plugin,
		fileBrowser: FileBrowser,
		Dropzone,
		ModelListSelect,
		Datepicker,
		CreateJobFromManualSubmissionModal: CreateJobFromManualSubmission
	},
	mixins: [Utility, loading, tableFilterMixin, bacsMixin],
	props: {
		id: String
	},
	data () {
		return {
      selectedRow: { bureauSubmissionDetailId: null },
			submissionDetails: [], // Current files in submission
			SelectedBureauCustomerId: '',
			BureauCustomers: [],
			BureauCustomersDisplay: [],
			BUREAUGROUPTYPE: '1',

			submissionReference: '',
			contraNarrative: '',
			processingDate: '',
			paymentDate: '',
			groupId: '',
			mappings: [],

			bacsGroup: {},
			// file selection modes
			userId: '',
			createUser: {},
			signingUser: {},
			useRestrictedFileBrowser: false,
			useFtpFileBrowser: false,
			useDropzone: false,
			showFtpFileBrowser: false,
			browser: '',
			plugin: undefined,
			pluginLoaded: false,
			pluginResponse: {},
			deviceType: 'Restricted File Browser',
			currentPage: '/bureau/bureauBuilder/manualbacssubmissionaddfiles/',
			componentId: 3, // RFB component in version string returned by plugin.
			expectedVersion: '',
			bacsLicenceSetting: '',
			pluginUpdateNotInstalled: false,
			RFB_Url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadmanualbacspaymentfile_rfb/' + this.id,
			SecFTP_Url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadmanualbacspaymentfile_secftp/' + this.id,
			showDropzone: false,
			showFtpBrowser: false,

			// dropzone
			dropzoneOptions: {
				url: process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/uploadmanualbacspaymentfile',
				autoProcessQueue: true,
				addRemoveLinks: true,
				parallelUploads: 1,
				maxFiles: 50,
				maxFilesize: 500
			},
			BadFilesArray: [],
			// date picker
			disabledDates: {},
			processingDates: {},
			disableDatePicker: false,
			// table
			columns: [
				{
					label: 'Filename',
					field: 'paymentFilename',
					width: '250px',
					sortable: true
				},
				{
					label: 'Customer',
					field: 'bureauCustomerName',
					sortable: true
				},
				{
					label: 'Schema',
					field: 'schema',
					sortable: false,
					width: '150px'
				},
				{
					label: 'Mapping',
					field: 'mapping',
					width: '200px',
					sortable: false
				},
				{
					label: 'Payment Date', // this is the datepicker one
					field: 'paymentDate',
					sortable: false,
					width: '150px'
				},

				{
					label: 'Processing Date',
					field: 'processingDateDisplay',
					width: '170px',
					sortable: false
				},
				// {
				//   label: 'Blocks',
				//   field: 'blocks',
				//   sortable: false
				// },
				{
					label: 'Status',
					field: 'status',
					width: '100px'
				},
				{
					field: 'delete',
					sortable: false,
					width: '50px'
				}
			],

			objectItem: {},
			submissionLockConnection: null,
			hubToken: null,
			submissionDeleted: false,
			submissionLocked: false,
			lockingUsername: '',
			lockedTime: '',
			lockClicked: false,
			fileCount: 0,
			fileQueue: 0,
			schemas: [],
			loading: false,
			isFps: false
		}
	},
	async mounted () {
		const token = await auth.getAccessToken()
		this.hubToken = 'Bearer ' + token
		this.bearerToken = 'Bearer ' + token

		this.userId = this.$store.getters.getClaim('sub').value
		this.browser = bacsCommon.getBrowser()
		this.$store.dispatch('getProcessingDates')
			.then(response => {
				this.processingDates = this.$store.getters.processingDates
				this.paymentDate = new Date(this.processingDates.minPaymentDate)
				this.processingDate = this.processingDates.nextProcessingDate

				// Disable weekends in datepicker.
				this.disabledDates.days = [6, 0]
				// Disable any dates before the earliest possible payment date.
				this.disabledDates.to = this.paymentDate
				// Disable any dates more than 31 days into the future.
				var maxDate = new Date(this.processingDates.maxPaymentDate)
				this.disabledDates.from = maxDate
				// Disable any other non-processing days i.e bank holidays.
				if (this.processingDates.nonProcessingDates.length > 0) {
					var npds = []
					for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
						npds.push(new Date(this.processingDates.nonProcessingDates[i]))
					}
					this.disabledDates.dates = npds
				}
			}
			)
		window.addEventListener('beforeunload', this.stopSocketListener)
		await this.loadMappingsOnLoad()
	},

	computed: {

		initRestrictedFileBrowser: function () {
			return this.useRestrictedFileBrowser === true && this.pluginUpdateNotInstalled === false
		},
		noSchemaSelected () {
			const c = this.fileCount
			const found = this.submissionDetails.find(x => x.importSchema === 'None')
			return found !== undefined && found !== null
		},
		noMappingSelected () {
			const found = this.submissionDetails.find(x => x.importSchema === 'Mapping' && (x.mapping === null || x.mapping === ''))
			return found !== undefined && found !== null
		},
		noCustomerSelected () {
			const found = this.submissionDetails.find(x => (x.importSchema === 'Mapping' || x.importSchema === 'Legacy') && x.bureauCustomerId === '00000000-0000-0000-0000-000000000000')
			return found !== undefined && found !== null
		},
		customerNoBankSelected () {
			const found = this.submissionDetails.filter(x => (x.importSchema === 'Legacy') && x.bureauCustomerId !== '00000000-0000-0000-0000-000000000000')
			let noBank = false
			if (found !== null && found !== undefined) {
				for (let i = 0; i < this.BureauCustomers.length; i++) {
					let foundCust = found.find(x => x.bureauCustomerId === this.BureauCustomers[i].bureauCustomerId)
					if (foundCust !== undefined && foundCust !== null) {
						if (foundCust.accountNumber === '' || foundCust.sortcode === '') {
							noBank = true
						}
					}
				}
			}
			return noBank
		}
	},

	async created () {
		// this.disabledDates = await this.setUpBACSDatePicker()
		await this.GetBureauLicenseCheck()
		await this.checkLock()
		await this.load()
		this.fileCount++
	},

	methods: {
    onRowClick (params) {
      console.log(params.row)
      if(this.selectedRow.bureauSubmissionDetailId !== params.row.bureauSubmissionDetailId) {
        if(this.selectedRow.bureauSubmissionDetailId === null) {
          this.selectedRow = params.row
        } else {
          this.$toastr.e('Please save or cancel your current changes before editing a new row')
        }
      }
    },
    rowStyler (row) {
      var cssClass = ''
      if (this.selectedRow.bureauSubmissionDetailId === row.bureauSubmissionDetailId) {
        cssClass = 'table-primary'
      }
      return cssClass
    },
    async cancelRowUpdate (row) {
      await this.loadManualSubmissionDetails()
      this.selectedRow = { bureauSubmissionDetailId : null }
    },
		stopSocketListener () {
			if (this.submissionLockConnection !== undefined && this.submissionLockConnection !== null) {
				this.submissionLockConnection.stop()
			}
		},
		load: _.debounce(async function () {
			await this.loadBureauCustomers()
			await this.loadManualSubmission()
			await this.loadManualSubmissionDetails()
			await this.getSelectedGroupDetails()
		}, 800),

		async checkLock () {
			try {
				const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/checklock/${this.id}`)
				if (response.data !== null && response.data.userId !== '00000000-0000-0000-0000-000000000000') {
					this.submissionLocked = true
					this.lockingUsername = response.data.userName
					this.lockedTime = response.data.sessionStarted
				} else {
					this.submissionLocked = false
				}
			} catch (e) {
				this.$toastr.e('Could not retrieve lock information on submission')
			}
		},

		// Loader Methods

		async getSelectedGroupDetails () {
			await this.$store.dispatch('getSubBacsGroup', this.groupId)
			this.bacsGroup = this.$store.getters.bacsGroup
			// this.disableDatePicker = this.bacsGroup.importSchema === 'Standard18'

			this.createUser = this.bacsGroup.groupUserRights.find(i => i.userId === this.userId)
			if (this.createUser !== undefined && this.createUser !== null && this.createUser.uploadType === this.deviceType) { // 'Restricted File Browser'
				// Check for web extension and plugin...
				try {
					await this.$store.commit('setDeviceType', this.deviceType)
					await this.$store.dispatch('checkPlugin')
				} catch (error) {
					this.$toastr.w(error.message, 'Warning')
				}

				this.useRestrictedFileBrowser = true
				this.useFtpFileBrowser = false
				this.useDropzone = false
			} else if (this.createUser.uploadType === 'Secure Cloud Folder') {
				this.$store.commit('setModalPopupString', this.createUser.uploadPath)
				this.useFtpFileBrowser = true
				this.useRestrictedFileBrowser = false
				this.useDropzone = false
			} else {
				// Dropzone-browser dialog.
				this.useDropzone = true
				this.useRestrictedFileBrowser = false
				this.useFtpFileBrowser = false
			}
		},

		async loadManualSubmission () {
			// Go and get the submission from the API
			try {
				var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualbacsbuildersubmission/' + this.id)
				this.groupId = response.data.groupId
				this.submissionReference = response.data.submissionReference
				this.contraNarrative = response.data.contraNarrative
				this.paymentDate = response.data.paymentDate
				this.processingDate = response.data.processingDate
				this.isFps = response.data.paymentNetworkType === 1
				this.$set(this.columns[4], 'hidden', this.isFps)
				this.$set(this.columns[5], 'hidden', this.isFps)
			} catch (e) {

			}
		},

		async loadManualSubmissionDetails () {
			// Go and get any files/blocks loaded into the submission so far
			try {
				var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualbacsbuildersubmissiondetails/' + this.id, { showload: true })

				this.submissionDetails = response.data.data.map(d => {
					return {
						...d,
						bureauCustomerName: this.BureauCustomersDisplay.find(bc => bc.bureauCustomerId === d.bureauCustomerId) ? this.BureauCustomersDisplay.find(bc => bc.bureauCustomerId == d.bureauCustomerId).name : null
					}
				})
				this.fileCount++
			} catch (error) {
			}
		},
		loadMappingsOnLoad: async function () {
			this.mappings = await this.loadMappings('bacs')
			this.schemas = this.loadSchemas()
			this.fileCount++
			return true
		},
		loadBureauCustomers: async function () {
			try {
				// Load customers in job
				this.BureauCustomersDisplay = []
				var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/getbureaucustomersforsubmission/', { showload: true })
				this.BureauCustomers = response.data

				var index = 0
				for (index = 0; index < this.BureauCustomers.length; ++index) {
					var current = {
						bureauCustomerId: this.BureauCustomers[index].bureauCustomerId,
						name: this.BureauCustomers[index].name + ' (' + this.BureauCustomers[index].serviceUserNumber + ')',
						disabled: false
					}
					this.BureauCustomersDisplay.push(current)
				}
			} catch (error) {
			}
			return true
		},

		EditManualBacsSubmission () {
			this.$router.push({ name: 'ManualBacsSubmissionEdit', params: { id: this.id } })
		},
		dateOnfocusSE (datePicker) {
			setTimeout((ev) => {
				datePicker.paymentDate.isOpen || datePicker.paymentDate.showCalendar(ev)
			}, 250)
		},
		// Form Edit Methods

		async onDeleteFileClick (row) {
			console.log('delete row', row)
			const swalResult = await swal.fire({
				title: 'Delete Submission File',
				text: 'Are you sure you want to delete the file: ' + row.paymentFilename + '.',
				icon: 'warning',
				showCancelButton: true,
				focusCancel: true,
				confirmButtonColor: colours.danger,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			})

			if (swalResult.isConfirmed) {
				try {
					const data = {
						submissionId: row.bureauSubmissionId,
						bureausubmissionid: row.bureauSubmissionDetailId
					}

					const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteblockfrommanualsubmission', data, { showload: true })

					this.$toastr.s('Removed file ' + row.paymentFilename, 'Bureau Submission')
					this.fileCount--
          this.selectedRow = { bureauSubmissionDetailId : null }
					// Remove from grid
					// this.submissionDetails = this.submissionDetails.filter(function (submissionRow) {
					//   return submissionRow.bureauSubmissionDetailId !== row.bureauSubmissionDetailId
					// })
				} catch (error) {
					console.error('Error in UploadFiles.onDeleteFileClick.DeleteSubmissionDetail')
					console.error(error)
				}
				await this.loadManualSubmissionDetails()
			}
		},
		async paymentDateChanged (row) {
			// Assign the processing date to the row
			await this.$nextTick()
			var newDate = new Date(row.paymentDate)
			var valasjson = row.paymentDate.toJSON()

			var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + valasjson)
			row.processingDateDisplay = response.data
      row.edited = true
			// await this.updateSubmissionDetail(row)
		},

		async onResetSelectedCustomer (submissionDetail) {
			submissionDetail.bureauCustomerId = GUID_EMPTY
			submissionDetail.importSchema = 'None'
			submissionDetail.mapping = null
      row.edited = true
			//this.updateSubmissionDetail(submissionDetail)
		},

		updateBureauCustomer: _.debounce(async function (row) {
			// Go and get this bureau customer's default import
			this.loading = true
			try {
				var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getbureaucustomerdefaultimportschema/' + row.bureauCustomerId)
				if (row.importSchema === null || row.importSchema === '' || row.importSchema === 'None') {
					row.importSchema = response.data.importSchema
					if (row.importSchema === 'Mapping') {
						row.mapping = response.data.mapping
					}
				}
			} catch (e) { }
			row.bureauCustomerName = this.BureauCustomersDisplay.find(bc => bc.bureauCustomerId == row.bureauCustomerId) ? this.BureauCustomersDisplay.find(bc => bc.bureauCustomerId == row.bureauCustomerId).name : null
			this.loading = false
      row.edited = true
      //await this.updateSubmissionDetail(row)
		}, 800),

		createJobFromManualSubmissionClosed () { },

		// Submssion Management Methods
		updateSubmissionDetail: _.debounce(async function (row) {
			this.loading = true
			try {
				this.fileCount++
				row.touched = true
				if (row.importSchema !== 'Mapping') {
					row.mapping = ''
				}

				var response = await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/updatemanualbacssubmissiondetail', row, { showload: true })
				// this.$toastr.s(`Updated ${submissionDetail.paymentFilename}`, 'Update Bureau Submission')
				if (response.data.toastType === 2) {
					this.$toastr.Add({
						name: 'detailUpdated',
						msg: response.data.toastMessage,
						preventDuplicates: true,
						type: 'success'
					})
				} else {
					this.$toastr.Add({
						name: 'detailUpdatedErr',
						msg: response.data.toastMessage,
						preventDuplicates: true,
						type: 'error'
					})
				}
				await this.loadManualSubmissionDetails()
        this.selectedRow.bureauSubmissionDetailId = null
			} catch (error) {
				this.$toastr.e(`Failed to update ${row.paymentFilename}`, 'Update Manual Bacs Bureau Submission')
				//   console.error('Error in Manual Bacs UpdateSubmissionDetail')
				//   console.error(error)
			}
			this.fileCount++
			this.loading = false
		}, 800),

		async ImportManualBacsSubmission () {
			try {
				// var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/manualbacsstartfileimport/' + this.id, { showload: true })
				//
				// if (response.data.toastType === 2) {
				//   try {
				//     await axios.post(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/updatelastmodified/${this.submissionId}`)
				//   } catch (e) {
				//   }
				//   this.$toastr.s(response.data.toastMessage)
				//   var bureauSubmissionId = response.data.id
				//   //this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: this.id, startProcess: true } })
				this.$router.push({ name: 'ImportPaymentFiles', params: { id: this.id, startProcess: true } })
				// } else {
				//   this.$toastr.e(response.data.toastMessage)
				// }
			} catch (error) {
				this.$toastr.e(`Manual BACS Bureau Submission ${this.id} failed ${error}`, 'Manual BACS Bureau Submission')
			}
		},

		// Dropzone Methods
		async RefreshAfterDropzoneQueue () {
			await this.$nextTick
			if (this.fileQueue === 0) {
				await this.loadManualSubmissionDetails()
				this.$refs.fileUploader.removeAllFiles(true)
				this.fileQueue = 0
				if (this.BadFilesArray.length > 0) {
					// prepare a swal

					var failedFiles = ''
					for (var i = 0; i < this.BadFilesArray.length; i++) {
						failedFiles += this.BadFilesArray[i]
					}

					var htmlText = '<p>The following files could not be uploaded.</p><ul>' + failedFiles + '</ul>'

					const swalResult = await swal.fire({
						title: 'Failed To Upload All Files',
						html: htmlText,
						icon: 'warning',
						showCancelButton: false,
						confirmButtonColor: colours.danger,
						confirmButtonText: 'OK'
					})
					if (swalResult.isConfirmed) {
						this.BadFilesArray = []
					}
				}
			}
		},

		async afterError (file) {
			await this.$nextTick()
			this.fileQueue--
			var errormessage = file.xhr.responseText
			var filename = file.upload.filename
			this.BadFilesArray.push(`<li class="text-left"> ${filename}: ${errormessage} </li>`)
			await this.RefreshAfterDropzoneQueue()
		},

		async afterSuccess (file, response) {
			await this.$nextTick()
			this.fileQueue--
			await this.RefreshAfterDropzoneQueue()
		},
		async fileAdded (file) {
			this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
			this.fileQueue++
			this.fileCount++
		},
		fileRemoved (file, error, xhr) {
			this.fileQueue--
		},
		sendingEvent (file, xhr, formData) {
			formData.append('bureauSubmissionId', this.id)
		},
		dropZoneError (file, error, request) {
			this.fileQueue--
			this.$toastr.e('File upload ' + file.name + ' failed', 'Bureau Submission')
		},

		// Restricted File Browser Methods

		showRestrictedFileBrowser () {
			if (this.browser === 'Firefox') {
				this.showRestrictedFileBrowserFirefox()
			} else if (this.browser === 'Chrome') {
				this.showRestrictedFileBrowserChome()
			} else if (this.browser === 'IE') {
				this.showRestrictedFileBrowserIE()
			}
		},

		showRestrictedFileBrowserChome () {
			return new Promise((resolve, reject) => {
				// eslint-disable-next-line
				chrome.runtime.sendMessage(
					process.env.VUE_APP_CHROME_EXT_ID,
					{
						PluginTask: 'UploadFromRestrictedFolder',
						StartDirectory: this.createUser.uploadPath,
						DirectoryLocked: this.createUser.directoryLocked,
						BearerToken: this.bearerToken,
						Url: this.RFB_Url
					}, (response) => {
						var pluginResponse = common.parsePluginResponse(response.response)
						if (pluginResponse.SelectedFileName !== null) {
							var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
							this.afterSuccessRFB(null, fileBrowserResponse)
						} else if (pluginResponse.ErrorMessage !== null) {
							this.$snapbar.e(pluginResponse.ErrorMessage)
						}
					})
				resolve()
			})
		},

		showRestrictedFileBrowserFirefox () {
			this.$store.dispatch('setWebExtPluginResponse', null)

			return new Promise((resolve, reject) => {
				document.addEventListener('getPluginResponseWebPage', (response) => {
					var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
					if (pluginResponse.SelectedFileName !== null) {
						var fileBrowserResponse = { submissionId: pluginResponse.SubmissionId, filename: pluginResponse.SelectedFileName }
						this.afterSuccessRFB(null, fileBrowserResponse)
						resolve()
					} else {
						this.$snapbar.e(pluginResponse.ErrorMessage)
					}
				}, { once: true })

				// eslint-disable-next-line
				var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'UploadFromRestrictedFolder', StartDirectory: this.createUser.uploadPath, DirectoryLocked: this.createUser.directoryLocked, BearerToken: this.bearerToken, Url: this.RFB_Url } })
				document.dispatchEvent(pluginEvent)
			})
		},

		showRestrictedFileBrowserIE () {
			this.plugin = document.PayGatePlugin
			var result = this.plugin.UploadFromRestrictedFolder(this.createUser.uploadPath, this.createUser.directoryLocked, this.bearerToken, this.RFB_Url, 1000)
			if (result.indexOf('Error') === -1) {
				var i = result.indexOf('_')
				var submissionId = result.substring(0, i)
				var filename = result.substring(i + 1)
				var response = { submissionId: submissionId, filename: filename }
				this.afterSuccessRFB(null, response)
			} else {
				this.$snapbar.e(result)
			}
		},

		async afterSuccessRFB (file, response) {
			this.$store.commit('setModalPopupString', this.createUser.uploadPath)
			await this.loadManualSubmissionDetails()
		},
		pluginNotInstalled () {
			this.pluginUpdateNotInstalled = true
		},

		async closeFileBrowserPopup () {
			this.showFtpFileBrowser = false
			var selectedFileName = this.$store.state.mapping.modalPopupString
			// Check user has selected a file.
			var i = selectedFileName.lastIndexOf('.')
			if (i >= 0) {
				var data = {
					bureausubmissionid: this.id,
					filename: selectedFileName
				}

				var response = await axios.post(this.SecFTP_Url, data, { showload: true })

				// var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getNewSubmissionId`)
				// var fileBrowserResponse = { submissionId: response.data, filename: selectedFileName }
				this.afterSuccessRFB(null, selectedFileName)
			}
		},

		btnFtpFileBrowser () {
			this.showFtpFileBrowser = true
		},

		// Other methods
		onCreateJobFromManualSubmissionClick () {
			this.$bvModal.show('create-job-from-manual-submission')
		}

	}

}

</script>

<style scoped>
.vdp-datepicker input {
	background-color: unset;
}

.addFiles-tall-dropdown {
	min-width: 150px !important;
	width: 90% !important;
}
.addFiles-tall-dropdown .visible,
.addFiles-tall-dropdown input {
	min-width: 150px;
	width: 100%;
	max-height: 200px !important;
}

.dark-mode * .ui.selection.dropdown,
.dark-mode * .ui.search.dropdown > input.search,
.dark-mode * .addFiles-tall-dropdown * {
	background-color: transparent;
}

.schemaControl {
	border: 1px solid rgba(34, 36, 38, 0.15);
	color: rgba(0, 0, 0, 0.87);
	line-height: 1.21428571em;
	padding: 0.67857143em 2.1em 0.67857143em 1em;
	font-size: 0.9em;
}

.schemaControl option {
	color: rgba(0, 0, 0, 0.87);
	line-height: 1.21428571em;
	padding: 0.67857143em 2.1em 0.67857143em 1em;
	font-size: 0.9em;
}

.input-group-append { margin-left: -2px; }
</style>
