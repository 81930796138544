<template>
  <div class="fillHeightContainer">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Agent File Transfers</h4>
    </div>
    <span v-if="chartData && options">
      <agent-file-transfer-line-graph :height="height" :chart-data="chartData" :options="options"></agent-file-transfer-line-graph>
    </span>
  </div>
</template>

<script>
import AgentFileTransferLineGraph from '@/Assets/Components/Dashboard/Widgets/Agent/AgentFileTransferLineGraph'
import axios from 'axios'
import { setInterval, clearInterval } from 'timers'
import moment from 'moment'

export default {
  name: 'AgentFileTransferGraphWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  components: {
    AgentFileTransferLineGraph
  },
  data () {
    return {
      chartData: null,
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    height () {
      return this.widget.h * 165
    },
    window () {
      return this.widget.options.find(o => o.label === 'Show transfers from the last X days').value
    },
  },
  methods: {
    toJulianDate (date) {
      var floor = Math.floor;
      var y = date.getUTCFullYear();
      var m = date.getUTCMonth() + 1;
      var d = date.getUTCDate() + (date % 8.64e7) / 8.64e7;      
      if (m < 3) {
        y -= 1;
        m += 12;
      }
      var a = floor(y/100);
      var b = date < new Date(Date.UTC(1582,9,15))? 0 : 2 - a + floor(a/4);
      var c = floor(y < 0? 365.25 * y - 0.75 : 365.25 * y);
      var e = floor(30.6001 * (m + 1));
      
      return b + c + d + e + 1720994.5;
    },
    async populate () {
      const response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}WidgetData/FileTransfersLineGraph/${this.window}`)     
      const labelMerge = response.data.uploads.concat(response.data.downloads)
      let labelDistinct = [...new Map(labelMerge.map(item => [moment(item.key).format('DD-MM-YYYY'), item])).values()]
      for (let p = 0; p < labelDistinct.length; p++) {
        labelDistinct[p].orderday = this.toJulianDate(moment(labelDistinct[p].key).toDate())
      }
      labelDistinct = labelDistinct.sort((a,b) => (a.orderday > b.orderday) ? 1 : ((b.orderday > a.orderday) ? -1 : 0))
      const labels = labelDistinct
        .map(v => v.key)
        .filter((v, i, a) => a.indexOf(v) === i) // Make unique
        .map(date => moment(date).format('DD-MM-YYYY'))

      let downloadChartData = response.data.downloads.map(i => {
              return {
                x: moment(i.key).format('DD-MM-YYYY'),
                y: i.value,
                index: this.toJulianDate(moment(i.key).toDate())
              }
            })
      let uploadChartData = response.data.uploads.map(i => {
          return {
            x: moment(i.key).format('DD-MM-YYYY'),
            y: i.value,
            index: this.toJulianDate(moment(i.key).toDate())
          }
        })
      let merge = downloadChartData.concat(uploadChartData)
      let distinct = [...new Map(merge.map(item => [item['x'], item])).values()]   
      distinct = distinct.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
      downloadChartData = this.buildUniqueChart(downloadChartData, distinct)
      uploadChartData = this.buildUniqueChart(uploadChartData, distinct)
      const downloadData = {
        label: 'Downloads (Number of Files)',
        fill: false,
        borderColor: 'rgb(231, 136, 13)',
        pointBackgroundColor: 'rgba(231, 136, 13)',
        lineTension: 0,
        data: downloadChartData.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
      }

      const uploadData = {
        label: 'Uploads (Number of Files)',
        fill: false,
        borderColor: 'rgb(13, 136, 231)',
        pointBackgroundColor: 'rgba(13, 136, 231)',
        lineTension: 0,
        data: uploadChartData.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
      }
      this.chartData = { labels, datasets: [uploadData, downloadData] }
    },
    buildUniqueChart (source, destination) {
      var retVal = []
      for(var i = 0; i < destination.length; i++) {
        let item = { y: 0, x: destination[i].x }
        for(var a = 0; a < source.length; a++) {
          if(source[a].x === destination[i].x) {
            item.y = source[a].y
          }
        }
        retVal.push(item)
      }
      return retVal
    }
  },
  async mounted () {
    const intervalId = setInterval(() => {
      this.populate()
    }, 30000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(intervalId)
    })
    this.populate()
  }
}
</script>
<style scoped>
.fillHeightContainer {
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
}
</style>
