<template>
  <div>
    <div class="alert alert-danger" v-if="hasErrors">
      <p v-for="(error, index) in errors" :key="index">{{error}}</p>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>
            Payments Approval
            <help-icon docPath="/administration/securitypolicy/paymentgroups/" />
          </h2>
        </div>
        <div class="idb-block-content">
          <approval-settings v-bind:details="$v.policy" :group="$v.policy"></approval-settings>
        </div>
        <div class="idb-block-footer">
          <button class="btn btn-primary" type="submit" :disabled="isLoading">Save</button>
          <button
            @click="reset"
            class="btn btn-outline-warning ml-3"
            type="button"
            :disabled="isLoading"
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import ApprovalSettings from '@/Components/Platform/Group/Approval/ApprovalSettings'
import { requiredIf, helpers } from 'vuelidate/lib/validators'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'

export default {
  mixins: [DataLeaveMixin, loading],
  components: {
    ApprovalSettings
  },
  computed: {
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      errors: [],
      policy: {
        approvalMethod: 'Via Action Item',
        approverAmountRanges: [],
        numberOfApprovers: 1,
        paymentApprovalMethod: 'Default'
      }
    }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/PaymentApproval`, this.policy,
          { showload: true, showerror: true, errormessage: 'Payment approval failed to save' })
        this.$toastr.s('Payment Approval policy changes have been saved', 'Saved')
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$reset()
      this.policy = {
        approvalMethod: 'Via Action Item',
        approverAmountRanges: [],
        numberOfApprovers: 1,
        paymentApprovalMethod: 'Default'
      }
      this.errors = []
      this.$toastr.i('Payment Approval policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/PaymentApproval`,
          { showload: true, showerror: true, errormessage: 'Payment approval failed to load' })
        this.policy = response.data
      } catch { }
    }
  },
  validations () {
    return {
      policy: {
        paymentApprovalMethod: {},
        approvalMethod: {},
        numberOfApprovers: {
          required: requiredIf((model) => {
            return model.paymentApprovalMethod === 'Number of Approvers'
          }),
          minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value, model) => {
            return model.paymentApprovalMethod === 'Number of Approvers' ? value >= 1 : true
          }),
          maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value, model) => {
            return model.paymentApprovalMethod === 'Number of Approvers' ? value <= 10 : true
          })
        },
        approverAmountRanges: {
          $each: {
            greaterThan: {
              required: requiredIf(() => {
                return this.policy.paymentApprovalMethod === 'Approval Range'
              }),
              minValue: helpers.withParams({ type: 'minValue', min: 0 }, (value) => {
                return this.policy.paymentApprovalMethod === 'Approval Range' ? value >= 0 : true
              }),
              valid: (value, model) => {
                var count = _.sumBy(this.policy.approverAmountRanges,
                  v => +(v.greaterThan === value))
                return this.policy.paymentApprovalMethod === 'Approval Range' ? count === 1 : true
              }
            },
            numberOfApprovers: {
              required: requiredIf(() => {
                return this.policy.paymentApprovalMethod === 'Approval Range'
              }),
              minValue: helpers.withParams({ type: 'minValue', min: 1 }, (value) => {
                return this.policy.paymentApprovalMethod === 'Approval Range' ? value >= 1 : true
              }),
              maxValue: helpers.withParams({ type: 'maxValue', max: 10 }, (value) => {
                return this.policy.paymentApprovalMethod === 'Approval Range' ? value <= 10 : true
              })
            }
          }
        }
      }
    }
  }
}
</script>
