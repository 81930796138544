var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Number")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserNumber, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Payment Signing Method")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.tokenType, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionTypeDetails.tokenType === "Hsm"
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("HSM Certificate")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.certificateId, function(diff, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Individual Payment Limit")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.paymentLimit, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Submission Payment Limit")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.submissionLimit, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Delete Payment Data")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.deletePaymentData, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionTypeDetails.tokenType === "Hsm"
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("HSM PIN Required")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.hsmPinRequired, function(diff) {
                return _c(
                  "span",
                  {
                    key: diff.count,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }