<template>
  <div class="idb-block">
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/hosted-forms/managestyles/" />Manage Stylesheet<favourite-icon></favourite-icon></h2>
      </div>
      <div class="idb-block-content">
        <div v-if="currentFile.addedDate !== null">
          <p><strong>Current stylesheet:</strong> {{currentFile.stylesheet}}</p>
          <p><strong>Last Updated:</strong> {{formatDate(currentFile.addedDate)}}</p>
        </div>
        <b-container>
          <dropzoneLike v-if="!useDropzone" ref="serverFileUploader"
            @fileAdded="publicFileAdded"
            @fileRemoved="publicFileRemoved"
            :dropzoneOptions="dropzoneOptions"
            :dropzoneUrl="dropzoneUrl"
            :secureUrl="secureUrl"
            :groupId="group">
            </dropzoneLike>
        </b-container>
        <vue-dropzone
          id="drop1"
          ref="fileUploader"
          :url="dropzoneUrl"
          :options="dropzoneOptions"
          @vdropzone-success="afterSuccess"
          @vdropzone-file-added="fileAdded"
          @vdropzone-removed-file="fileRemoved"
          @vdropzone-sending="sendingEvent"
          :use-custom-slot="true"
          :accepted-file-types="dropzoneOptions.acceptedFiles"
          :max-file-size-in-m-b="200"
          :useCustomDropzoneOptions="true"
          :use-custom-drop-zone-options="true"
          :useCustomSlot="true"
          v-if="useDropzone">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{dropzoneOptions.title}}</h3>
            <div class="subtitle">{{dropzoneOptions.subtitle}}</div>
          </div>
        </vue-dropzone>
      </div>
      <div class="idb-block-footer noprint-footer">
      </div>
  </div>
</template>
<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import dropzoneLike from '@/Components/Shared/DropzoneLike.vue'
import EventBus from '@/Lib/eventBus'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  components: {
    dropzoneLike
  },
  data () {
    return {
      fileFormats: ['css'], // xml, json
      publicFiles: [],
      fileIds: [],
      fileFormat: 'css',
      bearerToken: '',
      group: null,
      dropzoneOptions: {
        maxFilesize: 5, // MB
        maxFiles: 4,
        acceptedFiles: '.css',
        dictDefaultMessage: '<i class="fa fa-cloud-upload fa-4x"></i><br/><br/><h3 class="dropzone-custom-title">Drop a CSS file here.</h3><div class="subtitle">Or click to select a file to upload.</div>',
        url: '',
        title: 'Drag and drop to upload stylesheet',
        subtitle: '...or click to select a file from your computer'
      },
      currentFile: {
        onboardingStylesheetId: '',
        fileName: '',
        addedDate: null
      },
      useDropzone: true
    }
  },
  computed: {
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = this.$store.getters.customerGroups
      }
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    },
    importEnabled () {
      return this.fileIds.length > 0
    },
    dropzoneUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet`
    },
    secureUrl () {
      return `${process.env.VUE_APP_DDMS_API_URL}onboardingstylesheet/secure`
    }
  },
  async created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    const paygateId = this.$store.state.common.paygateId
    if (paygateId) {
      this.dropzoneUrl += '?paygateId=' + paygateId
    }
    this.dropzoneOptions.url = this.dropzoneUrl
    this.dropzoneOptions.secureUrl = this.secureUrl
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })
    var response = await axios.get(this.dropzoneUrl, { showload: true })
    if (response) {
      this.currentFile = response.data
    }
    await this.loadGroups(this.paygateId)

    const onPaygateChange = (paygateId) => {
      this.loadGroups(paygateId)
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })
  },
  methods: {
    afterSuccess (file, response) {
      this.currentFile = response
    },
    fileAdded (file) {
      this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
    },
    fileRemoved (file, error, xhr) {

    },
    sendingEvent (file, xhr, formData) {
      formData.append('paygateId', this.$store.state.common.paygateId)
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var retVal = `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      console.log(retVal)
      return retVal
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null && this.$store.getters.customerGroups.length > 0) {
        this.group = this.$store.getters.customerGroups[0].groupId
      }
    }
  }
}
</script>
