var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Imported Bureau Customer Information "),
            _c("help-icon"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Bureau Customer Details",
                    active: "",
                    "title-link-class": {
                      invalid:
                        _vm.$v.importedBureauCustomer.detailsValidation.$invalid
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: { for: "importedBureauCustomer.name" }
                          },
                          [_vm._v("Name")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value: _vm.$v.importedBureauCustomer.name.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.name.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.importedBureauCustomer.name.$dirty
                    ? _c(
                        "b-col",
                        { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                        [
                          !_vm.$v.importedBureauCustomer.name.required
                            ? _c(
                                "label",
                                { staticClass: "text-danger small" },
                                [_vm._v("A Bureau Customer Name is required")]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: {
                              for: "importedBureauCustomer.serviceUserNumber"
                            }
                          },
                          [_vm._v("Service User Number")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 6 },
                            on: {
                              input: function($event) {
                                return _vm.$v.$touch()
                              }
                            },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.serviceUserNumber
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer
                                    .serviceUserNumber,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "\n                  $v.importedBureauCustomer.serviceUserNumber.$model\n                "
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.importedBureauCustomer.serviceUserNumber.$dirty
                    ? _c(
                        "b-col",
                        { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                        [
                          !_vm.$v.importedBureauCustomer.serviceUserNumber
                            .required
                            ? _c(
                                "label",
                                { staticClass: "text-danger small" },
                                [_vm._v("A Service User Number is required")]
                              )
                            : _vm._e(),
                          !_vm.$v.importedBureauCustomer.serviceUserNumber
                            .sunValidator
                            ? _c(
                                "label",
                                { staticClass: "text-danger small" },
                                [_vm._v("The Service User Number is not valid")]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for:
                                "importedBureauCustomer.defaultImportFilename"
                            }
                          },
                          [_vm._v("Default Import Filename")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 250 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer
                                  .defaultImportFilename.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer
                                    .defaultImportFilename,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "\n                  $v.importedBureauCustomer.defaultImportFilename.$model\n                "
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.defaultImportSchema"
                            }
                          },
                          [_vm._v("Default Import Schema")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.schemas },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer
                                  .defaultImportSchema.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer
                                    .defaultImportSchema,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.defaultImportSchema.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.importedBureauCustomer.defaultImportSchema == "Mapping"
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for:
                                      "importedBureauCustomer.defaultImportMapping"
                                  }
                                },
                                [_vm._v("Default Import Mapping")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.mappings },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer
                                      .defaultImportMapping.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer
                                        .defaultImportMapping,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "\n                  $v.importedBureauCustomer.defaultImportMapping.$model\n                "
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.contraNarrative"
                            }
                          },
                          [_vm._v("Contra Narrative")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 255 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.contraNarrative
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.contraNarrative,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "\n                  $v.importedBureauCustomer.contraNarrative.$model\n                "
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "importedBureauCustomer.tags" } },
                          [_vm._v("Tags")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("input-tag", {
                            model: {
                              value: _vm.$v.importedBureauCustomer.tags.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.tags,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.tags.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Bank Details",
                    "title-link-class": {
                      invalid:
                        _vm.$v.importedBureauCustomer.bankDetailsValidation
                          .$invalid
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: {
                              for: "importedBureauCustomer.bankReference"
                            }
                          },
                          [_vm._v("Reference")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankReference
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankReference,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankReference.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$v.importedBureauCustomer.bankReference.$invalid
                    ? _c(
                        "b-col",
                        { staticClass: "pl-3", attrs: { "offset-sm": "3" } },
                        [
                          !_vm.$v.importedBureauCustomer.bankReference.$required
                            ? _c(
                                "label",
                                { staticClass: "text-danger small" },
                                [_vm._v("A Bank Reference is required")]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", { staticClass: "required" }, [
                          _vm._v("Sort Code")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("the-mask", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            ref: "sortCode",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              mask: ["##-##-##"],
                              guide: true
                            },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.sortCode.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.sortCode,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.sortCode.$model"
                            }
                          }),
                          _vm.$v.importedBureauCustomer.sortCode.$dirty
                            ? _c("span", [
                                !_vm.$v.importedBureauCustomer.sortCode.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [_vm._v("Sort Code is required")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.importedBureauCustomer.sortCode
                                  .minLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Sort Code must be at least " +
                                            _vm._s(
                                              _vm.$v.importedBureauCustomer
                                                .sortCode.$params.minLength.min
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.$v.importedBureauCustomer.sortCode
                                  .maxLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Sort Code must be less than " +
                                            _vm._s(
                                              _vm.$v.importedBureauCustomer
                                                .sortCode.$params.maxLength.max
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.sortCodeValid &&
                                _vm.$v.importedBureauCustomer.sortCode.required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-warning small text-nowrap"
                                      },
                                      [_vm._v(_vm._s(_vm.sortCodeErrorMessage))]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _vm.sortCodeValid
                            ? _c("b-col", [
                                _c("i", {
                                  staticClass: "fa fa-check-circle",
                                  staticStyle: {
                                    "font-size": "200%",
                                    color: "green"
                                  }
                                })
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: {
                              for: "importedBureauCustomer.accountNumber"
                            }
                          },
                          [_vm._v("Account Number")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("the-mask", {
                            ref: "originatingAccountNumber",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              mask: ["########"],
                              guide: false
                            },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.accountNumber
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.accountNumber,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.accountNumber.$model"
                            }
                          }),
                          _vm.$v.importedBureauCustomer.accountNumber.$dirty
                            ? _c("span", [
                                !_vm.$v.importedBureauCustomer.accountNumber
                                  .required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [_vm._v("Account Number is required")]
                                    )
                                  : _vm._e(),
                                !_vm.$v.importedBureauCustomer.accountNumber
                                  .minLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Account Number must be at least " +
                                            _vm._s(
                                              _vm.$v.importedBureauCustomer
                                                .accountNumber.$params.minLength
                                                .min
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.$v.importedBureauCustomer.accountNumber
                                  .maxLength
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-danger small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          " Account Number must be less than " +
                                            _vm._s(
                                              _vm.$v.importedBureauCustomer
                                                .accountNumber.$params.maxLength
                                                .max
                                            ) +
                                            " numbers "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.accountNumberValid &&
                                _vm.$v.importedBureauCustomer.accountNumber
                                  .required
                                  ? _c(
                                      "small",
                                      {
                                        staticClass:
                                          "form-text text-warning small text-nowrap"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.accountNumberErrorMessage)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _vm.accountNumberValid === true
                            ? _c("b-col", [
                                _c("i", {
                                  staticClass: "fa fa-check-circle",
                                  staticStyle: {
                                    "font-size": "200%",
                                    color: "green"
                                  }
                                })
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          { attrs: { for: "importedBureauCustomer.bankName" } },
                          [_vm._v("Bank Name")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankName.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankName.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.bankAddress1"
                            }
                          },
                          [_vm._v("Address")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankAddress1
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankAddress1,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankAddress1.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankAddress2
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankAddress2,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankAddress2.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankAddress3
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankAddress3,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankAddress3.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", "offset-sm": "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankAddress4
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankAddress4,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankAddress4.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c("label", {
                          attrs: { for: "importedBureauCustomer.bankAddress5" }
                        })
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankAddress5
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankAddress5,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankAddress5.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.bankAddressTown"
                            }
                          },
                          [_vm._v("Town")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "5" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankAddressTown
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankAddressTown,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "\n                  $v.importedBureauCustomer.bankAddressTown.$model\n                "
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.bankAddressCounty"
                            }
                          },
                          [_vm._v("County")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "5" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankAddressCounty
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer
                                    .bankAddressCounty,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "\n                  $v.importedBureauCustomer.bankAddressCounty.$model\n                "
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.bankPostcode"
                            }
                          },
                          [_vm._v("Post Code")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 10 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.bankPostcode
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.bankPostcode,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.bankPostcode.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Payment Limits" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.paymentLimit"
                            }
                          },
                          [_vm._v("Payment Limit")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("currency-input", {
                            attrs: { symbol: "£", type: "text" },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.paymentLimit
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.paymentLimit,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.paymentLimit.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.submissionLimit"
                            }
                          },
                          [_vm._v("Submission Limit")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("currency-input", {
                            attrs: { symbol: "£", type: "text" },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.submissionLimit
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.submissionLimit,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.submissionLimit.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Contact Info" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.contactFirstName"
                            }
                          },
                          [_vm._v("Forename")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.contactFirstName
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer
                                    .contactFirstName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "\n                  $v.importedBureauCustomer.contactFirstName.$model\n                "
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.contactSurname"
                            }
                          },
                          [_vm._v("Surname")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: 50 },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.contactSurname
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.contactSurname,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.contactSurname.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "importedBureauCustomer.address1"
                                  }
                                },
                                [_vm._v("Address")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer.address1
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer.address1,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.importedBureauCustomer.address1.$model"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer.address2
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer.address2,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.importedBureauCustomer.address2.$model"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer.address3
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer.address3,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.importedBureauCustomer.address3.$model"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6", "offset-sm": "3" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer.address4
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer.address4,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.importedBureauCustomer.address4.$model"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c("label", {
                                attrs: {
                                  for: "importedBureauCustomer.addressLocality"
                                }
                              })
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer
                                      .addressLocality.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer
                                        .addressLocality,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "\n                  $v.importedBureauCustomer.addressLocality.$model\n                "
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "importedBureauCustomer.addressTown"
                                  }
                                },
                                [_vm._v("Town")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "5" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer.addressTown
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer.addressTown,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.importedBureauCustomer.addressTown.$model"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addressFound === true
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "importedBureauCustomer.addressCounty"
                                  }
                                },
                                [_vm._v("County")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "5" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", maxlength: 50 },
                                model: {
                                  value:
                                    _vm.$v.importedBureauCustomer.addressCounty
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.importedBureauCustomer
                                        .addressCounty,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.importedBureauCustomer.addressCounty.$model"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.addressPostcode"
                            }
                          },
                          [_vm._v("Postcode")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "2" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", maxlength: "10" },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.addressPostcode
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.addressPostcode,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "\n                  $v.importedBureauCustomer.addressPostcode.$model\n                "
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { sm: "2" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info",
                            attrs: {
                              disabled:
                                this.importedBureauCustomer.addressPostcode ==
                                  "" ||
                                this.importedBureauCustomer.addressPostcode ==
                                  null
                            },
                            on: {
                              click: function($event) {
                                return _vm.getAddress()
                              }
                            }
                          },
                          [_vm._v("Lookup Address")]
                        )
                      ])
                    ],
                    1
                  ),
                  this.addressOptions.length > 0 && !this.addressFound
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { sm: "3" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "addressOptions" } },
                                [_vm._v("Addresses")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "8" } },
                            [
                              _c("b-form-select", {
                                staticClass: "mb3",
                                attrs: { options: this.addressOptions },
                                model: {
                                  value: _vm.selectedAddress,
                                  callback: function($$v) {
                                    _vm.selectedAddress = $$v
                                  },
                                  expression: "selectedAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: { for: "importedBureauCustomer.telephone" }
                          },
                          [_vm._v("Telephone")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.telephone.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.telephone,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.telephone.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "importedBureauCustomer.emailAddress"
                            }
                          },
                          [_vm._v("Email")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.emailAddress
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.emailAddress,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.emailAddress.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c("b-col", { staticClass: "mt-2", attrs: { sm: "3" } }, [
                        _c(
                          "label",
                          {
                            attrs: { for: "importedBureauCustomer.websiteUrl" }
                          },
                          [_vm._v("Web Site")]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value:
                                _vm.$v.importedBureauCustomer.websiteUrl.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.importedBureauCustomer.websiteUrl,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.importedBureauCustomer.websiteUrl.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-success mr-2",
            attrs: { disabled: _vm.$v.importedBureauCustomer.$invalid },
            on: {
              click: function($event) {
                return _vm.onUpdateClick()
              }
            }
          },
          [_vm._v("Save")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.onBackButton()
              }
            }
          },
          [_vm._v("Back To Import")]
        ),
        _vm.importedBureauCustomer.deleted
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger float-right",
                on: {
                  click: function($event) {
                    return _vm.onRestoreClick()
                  }
                }
              },
              [_vm._v("Restore Bureau Customer to Import")]
            )
          : _c(
              "button",
              {
                staticClass: "btn btn-danger float-right",
                on: {
                  click: function($event) {
                    return _vm.onRemoveClick()
                  }
                }
              },
              [_vm._v("Remove Bureau Customer from Import")]
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }