var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showInputFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showInputFolderBrowser = false
                _vm.closeInputFolderPopup()
              }
            }
          })
        : _vm._e(),
      _vm.showInputFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showInputFileBrowser = false
                _vm.closeInputFilePopup()
              }
            }
          })
        : _vm._e(),
      _vm.showOutputFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showOutputFolderBrowser = false
                _vm.closeOutputFolderPopup()
              }
            }
          })
        : _vm._e(),
      _vm.showOutputFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showOutputFileBrowser = false
                _vm.closeOutputFilePopup()
              }
            }
          })
        : _vm._e(),
      _c("h3", [
        _vm._v("File Joiner"),
        _c("span", { staticClass: "pull-right" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  _vm.docUrl +
                  "/automation/paygate-workflow/workflow-nodes/fileoperations/filejoiner/",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "far fa-question-circle" })]
          )
        ])
      ]),
      _c("hr"),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Input Filename")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s2.value,
              expression: "selectedNode.props.s2.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s2.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s2.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s2,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s2.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnInputFileBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s2.value.required &&
      _vm.$v.selectedNode.props.s2.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The input filename field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s2.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s2.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Input Folder")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s1.value,
              expression: "selectedNode.props.s1.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s1.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s1.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s1,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s1.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnInputFolderBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s1.value.required &&
      _vm.$v.selectedNode.props.s1.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The input folder field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s1.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s1.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Output Filename")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s4.value,
              expression: "selectedNode.props.s4.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s4.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s4.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s4,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s4.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnOutputFileBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s4.value.required &&
      _vm.$v.selectedNode.props.s4.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The output filename field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s4.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s4.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Output Folder")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s3.value,
              expression: "selectedNode.props.s3.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s3.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s3.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s3,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s3.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnOutputFolderBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s3.value.required &&
      _vm.$v.selectedNode.props.s3.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The output folder field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s3.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s3.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("div", { staticClass: "textarea" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Delimiter")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedNode.props.s5.value,
                expression: "selectedNode.props.s5.value"
              }
            ],
            staticClass: "form-control",
            class: { invalid: _vm.$v.selectedNode.props.s5.value.$error },
            attrs: { rows: "8", cols: "40" },
            domProps: { value: _vm.selectedNode.props.s5.value },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.selectedNode.props.s5,
                    "value",
                    $event.target.value
                  )
                },
                function($event) {
                  return _vm.$v.selectedNode.props.s5.value.$touch()
                }
              ]
            }
          })
        ])
      ]),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }