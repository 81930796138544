<template>
  <div>
    <div class="form-group row">
      <label class="col-3 offset-1 col-form-label">Payment Approval Method</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.paymentApprovalMethod}}</span>
        </div>
      </div>
    </div>
    <div v-if="group.details.paymentApprovalMethod === 'Number of Approvers'">
      <div class="form-group row">
        <label class="col-3 offset-1 col-form-label">Number of Approvers</label>
        <div class="col-8">
          <div class="form-control-plaintext">
            <span>{{group.details.numberOfApprovers}}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="group.details.paymentApprovalMethod === 'Approval Range'">
      <div class="form-group row">
        <label class="col-3 offset-1 col-form-label">Approval Range</label>
        <div class="col-8">
          <div
            class="form-control-plaintext"
            v-for="approvalRange in approvalRanges"
            :key="approvalRange"
          >
            <span>{{approvalRange}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="group.details.paymentApprovalMethod !== 'Default'">
      <label class="col-3 offset-1 col-form-label">Approval Method</label>
      <div class="col-8">
        <div class="form-control-plaintext">
          <span>{{group.details.approvalMethod}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      approvalRanges: null
    }
  },
  created () {
    if (this.group.details.paymentApprovalMethod === 'Approval Range') {
      this.approvalRanges = this.group.details.approverAmountRanges.reduce((a, v) => {
        a.push(`greater than £${v.greaterThan} requires ${v.numberOfApprovers} approvers`)
        return a
      }, [])
    }
  }
}
</script>
