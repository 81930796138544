<template>
  <div>
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">BACS Calendar</h4>
    </div>
    <div class="idb-full-block">
      <div class="col-md-12">

      <!-- <FullCalendar
        class='demo-app-calendar'
        :options='calendarOptions'
      >
        <template v-slot:eventContent='arg'>
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar> -->
<FullCalendar
:options='calendarOptions'

>
</FullCalendar>


      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import multimonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction'


export default {
  components: {
    FullCalendar,
    dayGridPlugin,
    multimonthPlugin,
    interactionPlugin
  },
  name: 'BacsCalendarWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          multimonthPlugin,
          interactionPlugin
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek'
        },
        initialView: 'dayGridMonth', // multiMonthYear', // dayGridMonth',
        dateClick: this.handleDateClick,
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        height: 'auto', // 100%', // 'auto'
        contentHeight: '100%',
        events: []
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      }
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        await this.load()
      },
      deep: true
    }
  },
  computed: {
    // numberOfDays () {
    //   return this.widget.options.find(o => o.label === 'Number of Days').value
    // },
    // selectDataBy () {
    //   return this.widget.options.find(o => o.label === 'Select Data By').value
    // },
    // selectedGroupId () {
    //   var selectedGroupId = this.widget.options.find(o => o.label === 'Group').value
    //   return selectedGroupId !== null ? selectedGroupId : '00000000-0000-0000-0000-000000000000'
    // }
  },
  methods: {
    async load () {
      this.calendarOptions.events = [
        ...this.calendarOptions.events,
        { title: 'Another Event', date: '2023-10-13' }
      ]

      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}widgets/bacsCalendar/dates`)
        if (res) {
          console.log(res.data)
          this.calendarOptions.events = res.data
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to get BACS calendar data.')
        } else {
          this.$snapbar.e(`You are not authorised to get BACS calendar data - ${e.message}`)
        }
      }



    },
    handleDateClick: function (arg) {
      console.log('date click! ' + arg.dateStr)
    },
    handleEventClick: function (arg) {
      console.log('handleEventClick', arg)
    }
  },
  async mounted () {
    await this.load()
  }
}
</script>

<style>
</style>
