var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c(
            "form",
            {
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _vm.anyErrors
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger",
                          attrs: { role: "alert" }
                        },
                        _vm._l(_vm.errors, function(error) {
                          return _c("span", { key: error }, [
                            _vm._v(_vm._s(error))
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "form-group col-md-5 col-sm-10",
                      class: { warn: _vm.$v.currentPassword.$error }
                    },
                    [
                      _c("label", { staticClass: "label-control" }, [
                        _vm._v("Current Password")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.currentPassword.$model,
                            expression: "$v.currentPassword.$model"
                          },
                          { name: "focus", rawName: "v-focus" }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "password" },
                        domProps: { value: _vm.$v.currentPassword.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.currentPassword,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.$v.currentPassword.$dirty
                        ? _c("span", [
                            !_vm.$v.currentPassword.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass: "form-text text-warning small"
                                  },
                                  [
                                    _vm._v(
                                      "You must enter your current password"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("change-password-inputs", {
                    attrs: { "user-id": _vm.userId },
                    model: {
                      value: _vm.passwords,
                      callback: function($$v) {
                        _vm.passwords = $$v
                      },
                      expression: "passwords"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "idb-block-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      type: "submit",
                      disabled: !_vm.passwords.valid || _vm.$v.invalid
                    },
                    on: { click: _vm.submit }
                  },
                  [_vm._v("Submit")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link",
                    attrs: { type: "button" },
                    on: { click: _vm.cancel }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", { attrs: { id: "title-of-block-check" } }, [
        _vm._v("Change Password")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }