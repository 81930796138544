const state = {
  // holds the configuration of the selected node in the diagram canvas
  selectedMappingNode: {},
  mappingMetaData: {},
  selectedMappingObjects: [],
  isMappingDirty: false,
  saveMapping: false,
  modalPopupString: '',
  // Used to display the node configuration popup when opened from the Designer (cog icon)
  showMappingNodeCfgModelPopup: false
}

const getters = {
  // selectedTrigger (state) {
  //   return state.selectedTrigger
  // },
  // test (state) {
  //   return state.test
  // }
  // showPopup: state => state.showNodeCfgModelPopup
}

const mutations = {
  setMappingMetaData (state, payload) {
    state.mappingMetaData = payload
  },
  setModalPopupString (state, payload) {
    state.modalPopupString = payload
  },
  setSelectedMappingNode (state, payload) {
    state.selectedMappingNode = payload
  },
  setSelectedMappingObjects (state, payload) {
    // console.log('Vuex mutation - setSelectedObjects')
    // console.log(payload)
    state.selectedMappingObjects = payload
  },
  // setMappingMetaData (state, payload) {
  //   state.mappingMetaData = payload
  // },
  setSaveMapping (state, payload) {
    state.saveMapping = payload
  },
  setIsMappingDirty (state, payload) {
    state.isMappingDirty = payload
  },
  setSelectedMappingNodeProps (state, payload) {
    if (payload) {
      if (payload.s1 !== undefined) {
        state.selectedMappingNode.props.s1.value = payload.s1
      }
      if (payload.s2 !== undefined) {
        state.selectedMappingNode.props.s2.value = payload.s2
      }
      if (payload.s3 !== undefined) {
        state.selectedMappingNode.props.s3.value = payload.s3
      }
      if (payload.s4 !== undefined) {
        state.selectedMappingNode.props.s4.value = payload.s4
      }
      if (payload.s5 !== undefined) {
        state.selectedMappingNode.props.s5.value = payload.s5
      }
      if (payload.s6 !== undefined) {
        state.selectedMappingNode.props.s6.value = payload.s6
      }
      if (payload.s7 !== undefined) {
        state.selectedMappingNode.props.s7.value = payload.s7
      }
      if (payload.s8 !== undefined) {
        state.selectedMappingNode.props.s8.value = payload.s8
      }
      if (payload.s9 !== undefined) {
        state.selectedMappingNode.props.s9.value = payload.s9
      }

      // console.log('payload s9: ' + payload.s9)
      // console.log('state.selectedMappingNode.props: ')
      // console.dir(state.selectedMappingNode.props.s9)

      if (payload.b1 !== undefined) {
        state.selectedMappingNode.props.b1.value = payload.b1
      }
      if (payload.b2 !== undefined) {
        state.selectedMappingNode.props.b2.value = payload.b2
      }
      if (payload.b3 !== undefined) {
        state.selectedMappingNode.props.b3.value = payload.b3
      }
      if (payload.b4 !== undefined) {
        state.selectedMappingNode.props.b4.value = payload.b4
      }
      if (payload.b5 !== undefined) {
        state.selectedMappingNode.props.b5.value = payload.b5
      }
      if (payload.b6 !== undefined) {
        state.selectedMappingNode.props.b6.value = payload.b6
      }
      if (payload.b7 !== undefined) {
        state.selectedMappingNode.props.b7.value = payload.b7
      }
      if (payload.b8 !== undefined) {
        state.selectedMappingNode.props.b8.value = payload.b8
      }
      if (payload.b9 !== undefined) {
        state.selectedMappingNode.props.b9.value = payload.b9
      }
      if (payload.b10 !== undefined) {
        state.selectedMappingNode.props.b10.value = payload.b10
      }
      if (payload.b11 !== undefined) {
        state.selectedMappingNode.props.b11.value = payload.b11
      }
      if (payload.b12 !== undefined) {
        state.selectedMappingNode.props.b12.value = payload.b12
      }
      if (payload.pw1 !== undefined) {
        state.selectedMappingNode.props.pw1.value = payload.pw1
      }
      if (payload.pw2 !== undefined) {
        state.selectedMappingNode.props.pw2.value = payload.pw2
      }

      if (payload.int1 !== undefined) {
        state.selectedMappingNode.props.int1.value = payload.int1
      }
      if (payload.int2 !== undefined) {
        state.selectedMappingNode.props.int2.value = payload.int2
      }
      if (payload.int3 !== undefined) {
        state.selectedMappingNode.props.int3.value = payload.int3
      }
      if (payload.int4 !== undefined) {
        state.selectedMappingNode.props.int4.value = payload.int4
      }
    }
  },
  setMappingPopupState (state, payload) {
    // Used to display the node configuration popup when opened from the Designer (cog icon)
    state.showMappingNodeCfgModelPopup = payload
  }
  // setSelectedTrigger (state, payload) {
  //   state.selectedTrigger = payload
  // },
  // TEST_COMMIT (state, payload) {
  //   state.test = payload
  // }
}

export default {
  state,
  getters,
  mutations
}
