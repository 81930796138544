<template>
  <div class="fillHeightContainer">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Agent File Transfer Map</h4>
    </div>
    <div class="mapContainer">
      <b-container fluid class="flex-grow" style="min-height:100%; height:100%">
        <b-row class="align-self-stretch d-flex flex-grow" style="min-height:100%; height:100%">
          <b-col class="growBox" v-if="!loading">
            <l-map
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              class="mapBox"
              @update:center="centerUpdate"
              @update:zoom="zoomUpdate"
              ref="flightMap"
            >
              <!-- <h2 style="position: relative; margin: 25px; z-index: 9999;">hello</h2> -->
              <l-tile-layer :url="baseLayer" :attribution="attribution" />
            </l-map>
          </b-col>
          <b-col v-if="loading">
            <Loading></Loading>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import L from 'leaflet'
import axios from 'axios'
import { setTimeout, setInterval, clearInterval } from 'timers'
import '@elfalem/leaflet-curve'
import 'leaflet-geometryutil'
import moment from 'moment'
import Loading from '@/Assets/Components/Loading/Loading'

export default {
  name: 'AgentFlightMap',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      layerId: 'mapbox.streets',
      token: 'pk.eyJ1IjoiZGF2aWRob29kY29ydmlkIiwiYSI6ImNqdnhsMHJjbjA1cjY0M21ydGExNnJjcmgifQ.zpMhPUPQfzkLD9NQnCPHrA',
      zoom: 6,
      center: L.latLng([51.90907690939059, -0.2188360691070557]),
      url: `https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={token}`,
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      currentZoom: 11.5,
      currentCenter: L.latLng(0, 0),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        preferCanvas: true
      },
      payers: [],
      loading: false,
      intevalId: null,
      legend: null
    }
  },
  async mounted () {
    this.$nextTick(async () => {
      await this.populate()
      // this.loading = false
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 250)
    })
    this.intervalId = setInterval(() => {
      this.populate()
    }, 30000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.intervalId)
    })
  },
  computed: {
    lastMinutesConfigValue () {
      const optionValue = this.widget.options.find(o => o.label === 'Show transfers from the last X minutes').value
      return optionValue >= 0 ? optionValue : 0
    },
    baseLayer () {
      return `https://api.mapbox.com/styles/v1/davidhoodcorvid/cjwg8lne82irk1co87dr5b7j8/tiles/256/{z}/{x}/{y}@2x?access_token=${this.token}`
      // `https://api.tiles.mapbox.com/v4/${this.layerId}/{z}/{x}/{y}.png?access_token=${this.token}`
    }
  },
  methods: {
    makeLegend () {
      const legend = L.control({ position: 'bottomright' })
      legend.onAdd = (m) => {
        const div = L.DomUtil.create('div', 'info legend')
        div.innerHTML += `<i style="background:hsl(206, 89.3%, 47.8%)"></i> Uploads<br><i style="background:hsl(24, 89.3%, 47.8%)"></i> Downloads`
        return div
      }
      return legend
    },
    getCurveColour (travelDirection, occurred) {
      let baseH, baseS, baseL
      if (travelDirection === 0) {
        baseH = 206
        baseS = 89.3
        baseL = 47.8
      } else {
        baseH = 24
        baseS = 89.3
        baseL = 47.8
      }
      const calculateSLOffset = (occurred, maxValue) => {
        const age = moment().diff(moment(occurred), 'minutes')
        const fraction = age / maxValue
        // [Saturation, Lightness] return value
        if (fraction < 0.25) {
          return 10
        } else if (fraction >= 0.25 && fraction < 0.5) {
          return 20
        } else if (fraction >= 0.5 && fraction < 0.75) {
          return 30
        } else if (fraction >= 0.75) {
          return 40
        }
      }
      // desaturate in increments of 25%
      const offset = calculateSLOffset(occurred, this.lastMinutesConfigValue)
      const hsl = `hsl(${baseH}, ${baseS - offset}%, ${baseL + offset}%)`
      return hsl
    },
    async getWidgetData () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}WidgetData/FileTransfers/${this.lastMinutesConfigValue}`)
      return response
    },
    async populate () {
      const randomise = (i) => {
        const randomPercent = ((Math.random()) - 0.5) / 60
        return i * (1 + randomPercent)
      }

      const makePopupText = (direction, datetime, originatedAt) => {
        const directionText = direction === 0.0 ? 'Upload' : 'Download'
        const occurred = moment(datetime)
        return `<p class="agentFileTransferPopup"><strong>${directionText}</strong> happened at <strong>${occurred.format('llll')}</strong><br/> (${occurred.fromNow()})</p><p class="agentFileTransferPopup">Originating at <strong onclick="">${originatedAt}</strong> (TODO: Geolocate IP)</p>`
      }

      const makeCurve = (start, end, colour) => {
        const bezierControlMidpointLon = (start[1] + start[1] + end[1]) / 3
        const bezierControl = [start[0] + 1.5, bezierControlMidpointLon]

        return L.curve([
          'M', start,
          'Q', [randomise(bezierControl[0]), randomise(bezierControl[1])],
          end
        ], { color: colour })
      }
      const paygateLatLon = [51.90907690939059, -0.2188360691070557]
      const paygateIcon = L.divIcon({
        html: '<i class="fa fa-database fa-3x"></i>',
        iconSize: [20, 20],
        className: 'paygateLocationDivIcon'
      })

      if (this.$refs['flightMap']) {
        const map = this.$refs['flightMap'].mapObject

        this.disableMap(map)
        if (!this.legend) {
          this.legend = this.makeLegend()
          this.legend.addTo(map)
        }
        let response = await this.getWidgetData()
        if (!response || !response.data) {
          response.data = []
        }
        map.eachLayer((layer) => {
          if (!layer.getTileUrl) {
            layer.remove()
          }
        })

        const paygateMarker = L.marker(paygateLatLon, { icon: paygateIcon }).addTo(map).bindPopup('<h4>PayGate Server</h4>')

        const curves = response.data.map((transfer) => {
          let curve
          if (transfer.direction === 0) { // upload
            curve = makeCurve([transfer.location.x, transfer.location.y], paygateLatLon, this.getCurveColour(transfer.direction, transfer.occurred))
          } else {
            curve = makeCurve(paygateLatLon, [transfer.location.x, transfer.location.y], this.getCurveColour(transfer.direction, transfer.occurred))
          }
          const popupText = makePopupText(transfer.direction, transfer.occurred, transfer.ip)
          curve.addTo(map)
          const points = curve.trace([0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0])
          return { curve: curve, points: points, popupText: popupText }
        })

        paygateMarker.on('click', (e) => {
          map.flyTo(paygateMarker.getLatLng(), 6, { duration: 0.1 })
        })

        map.on('click', (event) => {
          const closestCurveToClick = curves.map(curve => {
            const closest = L.GeometryUtil.closest(map, curve.points, event.latlng)
            return { ...curve, closest }
          })
            .reduce((min, curve) => {
              if (!min) {
                return curve
              }
              if (min.closest.distance < curve.closest.distance) {
                return min
              } else {
                return curve
              }
            }, null)
          if (closestCurveToClick.closest.distance <= 10) {
            map.fitBounds(closestCurveToClick.curve.getBounds())
            const midPointIndex = Math.floor(closestCurveToClick.points.length / 2)
            L.popup().setLatLng(closestCurveToClick.points[midPointIndex]).setContent(closestCurveToClick.popupText).openOn(map)
          }
        })
        this.enableMap(map)
      }
    },
    zoomUpdate (zoom) {
      this.currentZoom = zoom
    },
    centerUpdate (center) {
      this.currentCenter = center
    },
    showLongText () {
      this.showParagraph = !this.showParagraph
    },
    disableMap (map) {
      map._handlers.forEach((handler) => handler.disable())
    },
    enableMap (map) {
      map._handlers.forEach((handler) => handler.enable())
    },
    getContent (p) {
      return p.ukPayerId
    }
  }
}
</script>
<style>
.fillHeightContainer {
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
}
.mapContainer {
  display: flex;
  flex-grow: 1;
}
.mapBox,
.growBox {
  display: flex;
}

.agentFileTransferPopup {
  text-align: center;
}

.paygateLocationDivIcon {
  text-align: center;
  /* Horizontally center the text (icon) */
  line-height: 20px;
  /* Vertically center the text (icon) */
}

.legend {
  line-height: 18px;
  color: #555;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
