<template>
  <div>
    <div id="synchronisationReportDetails">
      <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2>{{title}}</h2>
            </div>
            <div class="idb-block-content">{{body}}</div>
            <div class="idb-block-footer">
              <b-button
                variant="info"
                @click.prevent="removeAction"
                v-if="actionId && actionId !== null"
              >Clear Action</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data () {
    return {
      actionId: null,
      title: 'Loading',
      body: 'Loading',
      removed: false
    }
  },
  methods: {
    async removeAction () {
      if (!this.removed) {
        var response = await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/DeleteRejected/${this.actionId}`)
        if (response) {
          this.removed = true
          this.$toastr.s('Action cleared')
        }
      }
    },
    async getAction () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })
      if (response) {
        this.title = response.data.title
        this.body = response.data.message
      }
    }
  },
  async mounted () {
    this.actionId = this.$route.query.actionId
    this.getAction()
  }
}
</script>
