const state = {
  antiFraudList: {},
  antiFraudListItems: [],
  antiFraudMessages: []
}

const mutations = {
  setAntiFraudList (state, antiFraudList) {
    state.antiFraudList = antiFraudList
  },
  setAntiFraudListItems (state, antiFraudListItems) {
    state.antiFraudListItems = antiFraudListItems
  },
  setAntiFraudMessages (state, antiFraudMessages) {
    state.antiFraudMessages = antiFraudMessages
  }
}

const getters = {
  antiFraudList: state => {
    return state.antiFraudList
  },
  antiFraudListItems: state => {
    return state.antiFraudListItems
  },
  antiFraudMessages: state => {
    return state.antiFraudMessages
  }
}

const actions = {
  setAntiFraudList ({ commit }, antiFraudList) {
    commit('setAntiFraudList', antiFraudList)
  },
  setAntiFraudListItems ({ commit }, antiFraudListItems) {
    commit('setAntiFraudListItems', antiFraudListItems)
  },
  setAntiFraudMessages ({ commit }, antiFraudMessages) {
    commit('setAntiFraudMessages', antiFraudMessages)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
