var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Number")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserNumber, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Bank Account")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.bankAccountId, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Payment Signing Method")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.tokenType, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionTypeDetails.tokenType === "Hsm"
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("HSM Certificate")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.certificateId, function(diff, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Individual Payment Limit")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.paymentLimit, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Submission Payment Limit")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.submissionLimit, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Submission Window Type")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.submissionWindowType, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    this.actionTypeDetails.submissionWindowType === "Multi Day"
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Window Size")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.multidayWindowSize, function(diff, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Advance Notice Period (In Days)")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.advanceNoticeInDays, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Default Contra Narrative")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.defaultContraNarrative, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("AUDDIS Group")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.isAuddis, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Name")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserName, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Address 1")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserAddress1, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Address 2")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserAddress2, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Address 3")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserAddress3, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Address 4")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserAddress4, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Postcode")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserPostcode, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Telephone")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserTelephone, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Fax")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserFax, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Email Address")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserEmail, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Contact Name 1")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserContactName1, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Contact Name 2")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserContactName2, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Contact Name 3")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserContactName3, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 1")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField1, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 2")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField2, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 3")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField3, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 4")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField4, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 5")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField5, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 6")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField6, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 7")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField7, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Custom Field 8")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserCustomField8, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Default Payer Message Type")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.defaultPayerMessageType, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Letter File Export Type")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.docxExportType, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Email BCC Address List")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.emailBccList, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Letter File Export Folder")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.letterOutputFolder, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Email Address")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.domainEmailId, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Allow warnings on missed submissions")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.allowOrphanWarnings, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionTypeDetails.allowOrphanWarnings
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Late submission notification recipients")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.orphanReportingEmails, function(
                diff,
                index
              ) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Default Plan Template")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.defaultPlanTemplateId, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Plan Purpose")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.planPurpose, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Auto Generate References")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.autoGenerateReferences, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Payer Reference Generation Pattern")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.payerReferencePattern, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Allow Multiple Plans")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.allowMultiplePlans, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Enable Schedule Suppression")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.suppressionEnabled, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Suppression Start Day")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.suppressionDayStart, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Suppression Start Month")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.suppressionMonthStart, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Suppression End Day")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.suppressionDayEnd, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Suppression End Month")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.suppressionMonthEnd, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Update Scheduled Payments On Error")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.updateSchedPaymentOnError, function(
            diff,
            index
          ) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Group Workflow Type")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.groupWorkflowType, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Api Active")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.apiActive, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Use Default Variable Amount File Mapping")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.defaultVariableAmountMapping, function(
            diff,
            index
          ) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Variable Amount File Mapping")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.variableAmountMappingId, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }