var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
        _vm.actionsCount === 0
          ? _c("h4", [_vm._v("You have no actions")])
          : _c("h4", { staticClass: "m-0" }, [
              _vm._v(
                "You have a total of " +
                  _vm._s(_vm.actionsCount) +
                  " action" +
                  _vm._s(_vm.actionsCount > 1 ? "s" : "")
              )
            ])
      ]),
      _c(
        "vue-perfect-scrollbar",
        { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
        [
          _c("div", { staticClass: "idb-full-block" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-middle" },
                _vm._l(_vm.actions, function(action) {
                  return _c(
                    "tbody",
                    {
                      key: action.id,
                      staticClass: "clickable",
                      on: {
                        click: function($event) {
                          return _vm.goToAction(action)
                        }
                      }
                    },
                    [
                      _c("tr", [
                        _c("td", { staticStyle: { width: "85px" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "pos-relative actions-avatar-image"
                            },
                            [
                              _c("avatar", {
                                staticClass: "rounded-circle artical-user",
                                attrs: {
                                  width: "64",
                                  height: "64",
                                  "user-id": action.createdBy
                                }
                              }),
                              _c("span", {
                                staticClass:
                                  "actions-widget-badge badge badge-label-sm badge-pill fa-notify",
                                class: _vm.badgeColour(action)
                              })
                            ],
                            1
                          )
                        ]),
                        _c("td", [
                          action.rejected
                            ? _c("div", [
                                _c("h4", [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("Rejected:")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        action.title.replace("Rejected:", "")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            : _c("div", [
                                _c("h4", [_vm._v(_vm._s(action.title))])
                              ]),
                          _c("h6", [_vm._v(_vm._s(action.message))]),
                          _c(
                            "p",
                            {
                              staticClass: "fs-12 artical-author",
                              class: _vm.dateColour(action)
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("formatDate")(action.createdDate))
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }