var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Delete Group Payment Data "),
            _c("help-icon", {
              attrs: { docPath: "/administration/gdpr/deletegroupdata/" }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _c(
          "div",
          { staticClass: "form-group row" },
          [
            _c("label", { staticClass: "label-control col-md-3" }, [
              _vm._v("Groups")
            ]),
            _c("vue-select", {
              staticClass: "col-md-5",
              attrs: {
                multiple: "",
                options: _vm.groups,
                closeOnSelect: false
              },
              model: {
                value: _vm.selectedGroups,
                callback: function($$v) {
                  _vm.selectedGroups = $$v
                },
                expression: "selectedGroups"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "label-control col-md-3" }, [
            _vm._v("Delete data older than")
          ]),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("b-form-select", {
                attrs: { options: _vm.timePeriods },
                model: {
                  value: _vm.selectedDeletionPeriod,
                  callback: function($$v) {
                    _vm.selectedDeletionPeriod = $$v
                  },
                  expression: "selectedDeletionPeriod"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", disabled: _vm.disableButtons },
            on: { click: _vm.deleteData }
          },
          [_vm._v("Delete")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary ml-3",
            attrs: { type: "button", disabled: _vm.disableButtons },
            on: { click: _vm.clear }
          },
          [_vm._v("Clear")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }