<template>
  <div>
    <div v-for="(user) in difference.addedUsers" :key="user.userName">
      <div class="form-group row">
        <label
          for="description"
          class="col-sm-2 text-right col-form-label ins"
          style="word-break: break-all;"
        >{{user.userName}}</label>
        <div class="col-sm-10">
          <div class="form-control-plaintext">
            <span style="margin-right:5px" class="ins">{{user.keys}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(user) in difference.sameUsers" :key="user.userName">
      <div class="form-group row">
        <label
          for="description"
          class="col-sm-2 text-right col-form-label"
          style="word-break: break-all;"
        >{{user.userName}}</label>
        <div class="col-sm-10">
          <div class="form-control-plaintext">
            <span
              style="margin-right:5px"
              v-for="(diff,index) in user.diffObject"
              :key="index"
              :class="{ins:diff.added,del:diff.removed}"
            >{{diff.value}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(user) in difference.removedUsers" :key="user.userName">
      <div class="form-group row">
        <label
          for="description"
          class="col-sm-2 text-right col-form-label del"
          style="word-break: break-all;"
        >{{user.userName}}</label>
        <div class="col-sm-10">
          <div class="form-control-plaintext">
            <span style="margin-right:5px" class="del">{{user.keys}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */

export default {
  props: ['status', 'groupUserRights', 'actionUserRights'],
  computed: {
    difference () {
      if (!this.groupUserRights || !this.actionUserRights) {
        if (this.status === 'Create') {
          this.groupUserRights = this.actionUserRights
        } else if (this.status === 'Delete' && this.groupUserRights) {
          this.actionUserRights = this.groupUserRights
        } else {
          return {}
        }
      }

      var arrayDiff = {
        addedUsers: [],
        sameUsers: [],
        removedUsers: []
      }

      arrayDiff.addedUsers = this.actionUserRights.filter((aur) => {
        return !this.groupUserRights.some(gur => gur.userName === aur.userName)
      }).map((user) => {
        const keys = Object.keys(user).filter((key) => {
          if (key === 'paygateGroupId' || key === 'userId' || key === 'createdDate' || key === 'modifiedDate' || key === 'userName') {
            return false
          }
          return user[key]
        }).join(', ')
        return {
          userName: user.userName,
          keys
        }
      })

      arrayDiff.sameUsers = this.groupUserRights.filter((gur) => {
        return this.actionUserRights.some(aur => aur.userName === gur.userName)
      })

      arrayDiff.removedUsers = this.groupUserRights.filter((gur) => {
        return !this.actionUserRights.some(aur => aur.userName === gur.userName)
      }).map((user) => {
        const keys = Object.keys(user).filter((key) => {
          if (key === 'paygateGroupId' || key === 'userId' || key === 'createdDate' || key === 'modifiedDate' || key === 'userName') {
            return false
          }
          return true
        }).join(', ')
        return {
          userName: user.userName,
          keys
        }
      })

      arrayDiff.sameUsers = arrayDiff.sameUsers.map((user) => {
        const removed = []
        const added = []
        const same = []
        const keys = Object.keys(user)
        const actionUser = this.actionUserRights.find((u) => {
          return u.userName === user.userName
        })

        keys.forEach(key => {
          if (key === 'paygateGroupId' || key === 'userId' || key === 'createdDate' || key === 'modifiedDate' || key === 'userName') {
            return
          }

          if (key === 'uploadPath') {
            if (user[key] === null || user[key] === '') {
              return
            }
            if (user[key] === actionUser[key] && user[key]) {
              same.push(key)
            } else {
              added.push(`Upload Path: ${actionUser[key]}`)
            }
            return
          }

          if (key === 'uploadType') {
            if (user[key] === actionUser[key] && user[key]) {
              same.push(`Upload Type: ${actionUser[key]}`)
            } else {
              added.push(`Upload Type: ${actionUser[key]}`)
            }
            return
          }

          if (key === 'directoryLocked') {
            if (user[key] === actionUser[key] && user[key]) {
              same.push('Cannot Browse Sub-folders')
            } else if (user[key]) {
              removed.push('Cannot Browse Sub-folders')
            } else if (actionUser[key]) {
              added.push('Cannot Browse Sub-folders')
            }
            return
          }

          if (user[key] === actionUser[key] && user[key]) {
            same.push(key)
          } else if (user[key]) {
            removed.push(key)
          } else if (actionUser[key]) {
            added.push(key)
          }
        })

        var diffObject = []
        if (removed.length > 0) {
          diffObject.push({ userName: user.userName, removed: true, value: removed.join(', ') })
        }
        if (added.length > 0) {
          diffObject.push({ userName: user.userName, added: true, value: added.join(', ') })
        }
        if (same.length > 0) {
          diffObject.push({ userName: user.userName, value: same.join(', ') })
        }

        if (diffObject.length === 0) {
          diffObject.push({ userName: user.userName, value: 'No rights' })
        }
        return { userName: user.userName, diffObject }
      })
      return arrayDiff
    }
  }
}
</script>
