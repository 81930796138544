var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-good-table",
    {
      ref: "table",
      attrs: {
        mode: "remote",
        "search-options": {
          enabled: true,
          initialSortBy: { field: "creationDate", type: "desc" }
        },
        paginationOptions: _vm.paginationOptions,
        "sort-options": _vm.sortOptions,
        totalRows: _vm.totalRecords,
        columns: _vm.columns,
        rows: _vm.messages,
        lineNumbers: true,
        isLoading: _vm.isTableLoading
      },
      on: {
        "on-page-change": _vm.onPageChange,
        "on-sort-change": _vm.onSortChange,
        "on-column-filter": _vm.onColumnFilter,
        "on-per-page-change": _vm.onPerPageChange,
        "on-search": _vm.onSearch,
        "on-row-click": _vm.onRowClick,
        "on-cell-click": _vm.onCellClick,
        "update:isLoading": function($event) {
          _vm.isTableLoading = $event
        },
        "update:is-loading": function($event) {
          _vm.isTableLoading = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "table-row",
          fn: function(props) {
            return [
              props.column.field == "ukPayerId" && !_vm.hasPayerId
                ? _c(
                    "span",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "editPayer",
                              params: { ukPayerId: props.row.ukPayerId }
                            }
                          }
                        },
                        [_c("span", [_vm._v("View Payer")])]
                      )
                    ],
                    1
                  )
                : props.column.field == "payerMessageId"
                ? _c(
                    "span",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "viewMessage",
                              params: { messageId: props.row.payerMessageId }
                            }
                          }
                        },
                        [_c("span", [_vm._v("View Message")])]
                      )
                    ],
                    1
                  )
                : props.column.field === "status"
                ? _c(
                    "span",
                    [
                      _c(
                        "b-badge",
                        {
                          attrs: {
                            pill: "",
                            variant: _vm.getVariant(props.row.status)
                          }
                        },
                        [_vm._v(_vm._s(props.formattedRow[props.column.field]))]
                      )
                    ],
                    1
                  )
                : _c("span", [
                    _vm._v(_vm._s(props.formattedRow[props.column.field]))
                  ])
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "loadingContent" }, [
        _c("h1", [_vm._v("Loading...")])
      ]),
      _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
        _c("div", { staticClass: "vgt-center-align vgt-text-disabled" }, [
          _vm._v("No Messages")
        ])
      ]),
      _c(
        "div",
        { attrs: { slot: "table-actions" }, slot: "table-actions" },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.top.d500",
                  value: "Clear filters",
                  expression: "'Clear filters'",
                  modifiers: { hover: true, top: true, d500: true }
                }
              ],
              staticClass: "btn btn-link",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.clearTableFilters($event)
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "fa-stack",
                  staticStyle: { "font-size": "10px" }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                  }),
                  _c("i", { staticClass: "fa fa-ban fa-stack-2x dimmedIcon" })
                ]
              ),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Clear filters")])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.top.d500",
                  value: "Refresh the data in the table",
                  expression: "'Refresh the data in the table'",
                  modifiers: { hover: true, top: true, d500: true }
                }
              ],
              attrs: { variant: "link" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.load($event)
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-sync pointer dimmedIcon" }),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Refresh Table")])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.top.d500",
                  value: "Export the message queue",
                  expression: "'Export the message queue'",
                  modifiers: { hover: true, top: true, d500: true }
                }
              ],
              attrs: { variant: "link" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.exportTable($event)
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-share-square pointer dimmedIcon" }),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Export Table")])
            ]
          ),
          _c(
            "p-check",
            {
              staticClass: "p-switch p-fill",
              attrs: { id: "sentCheck", color: "primary" },
              on: { change: _vm.load },
              model: {
                value: _vm.showSent,
                callback: function($$v) {
                  _vm.showSent = $$v
                },
                expression: "showSent"
              }
            },
            [_vm._v("Include Sent")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }