<template xmlns="http://www.w3.org/1999/html">
  <div :class="{'editable':editable}" class="dashboard-background" ref="fullscreen">
    <loading :loading="loading"></loading>
    <div class="backdrop" :class="{'active': widgetSettings, 'full-active':expanded }"></div>
    <widget-adder :sidebar-open="editable" v-on:add="add"></widget-adder>
    <widget-settings
      :widget="selectedWidget"
      :sidebar-open="widgetSettings"
      @resize="resize"
      @close="closeWidgetSettings"
    ></widget-settings>
    <div>
      <nav
        v-show="!isFullscreen"
        class="widget-navbar navbar navbar-expand-lg navbar-light bg-light justify-content-between"
      >
        <form class="form-inline my-2 my-lg-0 mb-15">
          <label for="dashboardSelector" class="sr-only">Select Dashboard</label>
          <b-form-select id="dashboardSelector"
            v-model="selectedDashboardId"
            :options="dashboards"
            value-field="dashboardId"
            text-field="name"
            title="Dashboard Selector"
            v-if="dashboards.length > 0 && !customer"
            @change="changeDashboard"
          ></b-form-select>
          <button
            class="btn btn-outline-secondary btn-sm ml-2"
            type="button"
            @click="editDashboard"
            v-if="dashboards.length > 0 || customer"
          >
            <i class="fa fa-fw" :class="{'fa-save':editable,'fa-cog':!editable}"></i>
            {{editable? 'Save': ''}}<span v-if="editable" class="sr-only">Save</span><span class="sr-only" v-else>Edit</span>
          </button>
          <button
            class="btn btn-outline-secondary btn-sm ml-2"
            type="button"
            v-if="editable"
            @click="cancelEdit"
          >
            <i class="fa fa-fw fa-undo mr-1"></i>Undo Changes
          </button>
          <button
            class="btn btn-outline-secondary btn-sm ml-2"
            type="button"
            v-if="!editable && !customer "
            v-b-popover.hover.top.d500="'Add new dashboard'"
            @click="showNewDashboardModal"
          >
            <i class="fa fa-fw fa-plus"></i><span class="sr-only">Add New Dashboard</span>
            <!-- Create New Dashboard -->
          </button>
          <button
            class="btn btn-danger btn-sm ml-3"
            type="button"
            v-if="(editable && dashboards.length > 0) && !customer"
            @click="deleteDashboard"
          >
            <i class="fa fa-fw fa-trash-alt"></i>
            Delete Dashboard
          </button>
        </form>
        <div>
          <help-icon style="float:none!important" :docPath="docPath" />

          <a
            class="fa fa-fw fa-expand"
            v-b-popover.bottom.hover="'Fullscreen'"
            v-if="!editable"
            @click="fullscreen"
          ></a>
        </div>
      </nav>
    </div>

    <maintenance-messages></maintenance-messages>

    <grid-layout
      :layout.sync="dashboard.widgets"
      :col-num="4"
      :row-height="200"
      :is-draggable="isDraggable"
      :is-resizable="false"
      :margin="[30, 30]"
      :use-css-transforms="false"
      :vertical-compact="true"
      :responsive="false"
      style="min-height: 260px;"
      ref="gridLayout"
    >
      <div
        @dragover="dragOver($event)"
        @drop="addDropped($event)"
        @dragenter="dragEnter()"
        @dragleave="dragLeave()"
        class="widget-dropzone"
      ></div>
      <grid-item
        v-for="widget in dashboard.widgets"
        :x="widget.x"
        :y="widget.y"
        :w="widget.w"
        :h="widget.h"
        :i="widget.i"
        :key="widget.i"
      >
        <widget-wrapper
          :editable="editable"
          :widget="widget"
          v-on:remove="remove"
          v-on:settings="settings"
          v-on:maximise="maximise"
          v-on:minimise="minimise"
          :class="{'selected-widget':widget.selected , 'expanded-widget': widget.expanded}"
        >
          <!-- Add your widgets in this if-else -->
          <div v-if="widget.name === 'News Widget'" style="height:100%">
            <news-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'Workflow Heatmap Widget'">
            <workflow-heatmap-widget :widget="widget" />
          </div>
          <!-- <div v-else-if="widget.name === 'Workflow Monitor'">
            <workflow-monitor :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'Workflow Load Widget'">
            <workflow-load-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'Trigger Explorer Widget'">
            <trigger-explorer-widget :widget="widget" />
          </div> -->
          <div v-else-if="widget.name === 'Actions Widget'" style="height:100%">
            <actions-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'BACS Submissions Widget'" style="height:100%">
            <bacs-submissions-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'BACS FPS Widget'" style="height:100%">
            <bacs-fps-widget :widget="widget" />
          </div>
          <!-- <div v-else-if="widget.name === 'BACS Calendar Widget'" style="height:100%">
            <bacs-calendar-widget :widget="widget" />
          </div>-->
          <div v-else-if="widget.name === 'Bureau Pending Submissions Widget'" style="height:100%">
            <bureau-pending-jobs-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'Bureau Recent Jobs Widget'" style="height:100%">
            <bureau-recent-jobs-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'Bureau Task Progress Widget'" style="height:100%">
            <bureau-task-progress-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'Bureau Submission History Widget'" style="height:100%">
            <bureau-submission-history-widget :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'File Transfers Map'" style="height: 100%;">
            <agent-flight-map :widget="widget" />
          </div>
          <div v-else-if="widget.name === 'File Transfers Line Graph'" style="height: 100%">
            <agent-file-transfer-graph-widget :widget="widget" />
          </div>
          <!-- <div v-else-if="widget.name === 'Updates Widget'" style="height:100%">
            <updates-widget :widget="widget" />
          </div>-->
          <div v-else-if="widget.name === 'Licence Widget'" style="height:100%">
            <licence-widget :widget="widget" />
          </div>
        </widget-wrapper>
      </grid-item>
    </grid-layout>
    <form @submit.prevent="createNewDashboard" novalidate>
      <b-modal
        id="newDashboardModal"
        ref="new-dashboard-modal"
        title="New Dashboard"
        ok-title="Create New Dashboard"
        cancel-variant="secondary-outline"
        @ok="createNewDashboard"
        @shown="$refs['new-dash-name'].focus()"
        @hidden="resetModal"
        :centered="true"
      >
        <div class="form-group row" :class="{ invalid: $v.newDashboardName.$error }">
          <label class="col-form-label col-md-3 required">Dashboard Name</label>
          <div class="col-md-6">
            <input
              type="text"
              ref="new-dash-name"
              class="form-control required"
              v-model="$v.newDashboardName.$model"
            />
            <!-- Validation -->
            <validation-messages v-model="$v.newDashboardName" name="dashboard name"></validation-messages>
          </div>
        </div>
      </b-modal>
    </form>
  </div>
</template>
<script>
// Third Party
import axios from 'axios'
import _ from 'lodash'
import { GridLayout, GridItem } from 'vue-grid-layout'
import { mapGetters } from 'vuex'
import {
  required
} from 'vuelidate/lib/validators'
import swal from 'sweetalert2'

// Constants
import colours from '@/Assets/Constants/colours'

// Dashboard components
import WidgetAdder from '@/Assets/Components/Dashboard/WidgetAdder'
import WidgetSettings from '@/Assets/Components/Dashboard/WidgetSettings'
import WidgetWrapper from '@/Assets/Components/Dashboard/WidgetWrapper'
import MaintenanceMessages from '@/Assets/Components/MaintenanceMessages/MaintenanceMessages'

// Widget Components
import NewsWidget from '@/Assets/Components/Dashboard/Widgets/General/NewsWidget'
import WorkflowHeatmapWidget from '@/Assets/Components/Dashboard/Widgets/Workflow/WorkflowHeatmapWidget'
import WorkflowLoadWidget from '@/Assets/Components/Dashboard/Widgets/Workflow/WorkflowLoadWidget'
// import TriggerExplorerWidget from '@/Assets/Components/Dashboard/Widgets/Workflow/WorkflowTriggerExplorerWidget'
import WorkflowMonitor from '@/Assets/Components/Dashboard/Widgets/Workflow/WorkflowMonitor'
import ActionsWidget from '@/Assets/Components/Dashboard/Widgets/Actions/ActionsWidget'
import BacsSubmissionsWidget from '@/Assets/Components/Dashboard/Widgets/Bacs/BacsSubmissionsWidget'
import BacsFpsWidget from '@/Assets/Components/Dashboard/Widgets/Bacs/BacsFpsWidget'
import BacsCalendarWidget from '@/Assets/Components/Dashboard/Widgets/Bacs/BacsCalendarWidget'
import CurrentAlertsWidget from '@/Assets/Components/Dashboard/Widgets/Collections/CurrentAlertsWidget'
import PendingSubmissionsWidget from '@/Assets/Components/Dashboard/Widgets/Collections/PendingSubmissionsWidget'
import LookAheadReportWidget from '@/Assets/Components/Dashboard/Widgets/Collections/LookAheadReportWidget'
import RecentlySuspendedWidget from '@/Assets/Components/Dashboard/Widgets/Collections/RecentlySuspendedWidget'
import PayersAddedWidget from '@/Assets/Components/Dashboard/Widgets/Collections/PayersAddedWidget'
import CustomerMapWidget from '@/Assets/Components/Dashboard/Widgets/Collections/CustomerMapWidget'
import MissedPaymentMapWidget from '@/Assets/Components/Dashboard/Widgets/Collections/MissedPaymentMapWidget'
import BureauPendingJobsWidget from '@/Assets/Components/Dashboard/Widgets/Bureau/BureauPendingJobsWidget'
import BureauRecentJobsWidget from '@/Assets/Components/Dashboard/Widgets/Bureau/BureauRecentJobsWidget'
import BureauTaskProgressWidget from '@/Assets/Components/Dashboard/Widgets/Bureau/BureauTaskProgressWidget'
import BureauSubmissionHistoryWidget from '@/Assets/Components/Dashboard/Widgets/Bureau/BureauSubmissionHistoryWidget'
import AgentFlightMap from '@/Assets/Components/Dashboard/Widgets/Agent/AgentFlightMap'
import AgentFileTransferGraphWidget from '@/Assets/Components/Dashboard/Widgets/Agent/AgentFileTransferGraphWidget'
import OrphanedSubmissionsWidget from '@/Assets/Components/Dashboard/Widgets/Collections/OrphanedSubmissionsWidget'
// import UpdatesWidget from '@/Assets/Components/Dashboard/Widgets/Platform/UpdatesWidget'
import LicenceWidget from '@/Assets/Components/Dashboard/Widgets/Platform/LicenceWidget'
import MessagingReportingWidget from '@/Assets/Components/Dashboard/Widgets/Collections/MessagingReportingWidget'
var iCounter = 0

export default {
  name: 'Dashboard',
  components: {
    GridLayout,
    GridItem,
    WidgetWrapper,
    WidgetSettings,
    WidgetAdder,
    MaintenanceMessages,
    // Widgets
    NewsWidget,
    WorkflowHeatmapWidget,
    WorkflowLoadWidget,
    // TriggerExplorerWidget,
    WorkflowMonitor,
    ActionsWidget,
    BacsSubmissionsWidget,
    BacsFpsWidget,
    BacsCalendarWidget,
    CurrentAlertsWidget,
    PendingSubmissionsWidget,
    LookAheadReportWidget,
    RecentlySuspendedWidget,
    PayersAddedWidget,
    BureauPendingJobsWidget,
    BureauRecentJobsWidget,
    BureauTaskProgressWidget,
    BureauSubmissionHistoryWidget,
    CustomerMapWidget,
    MissedPaymentMapWidget,
    AgentFlightMap,
    AgentFileTransferGraphWidget,
    OrphanedSubmissionsWidget,
    // UpdatesWidget,
    LicenceWidget,
    MessagingReportingWidget
  },
  computed: {
    widgetSettings () {
      return this.selectedWidget !== null
    },
    isDraggable () {
      return this.editable && !this.widgetSettings
    },
    ...mapGetters(['selectedCustomer'])
  },
  props: {
    customer: {
      type: Boolean,
      default: false
    },
    docPath: {
      type: String,
      default: '/'
    }
  },
  data: function () {
    return {
      editable: false,
      isFullscreen: false,
      selectedWidget: null,
      expanded: false,
      loading: true,
      dashboard: {
        userId: null,
        paygateId: null,
        widgets:
          [
          ]
      },
      dashboards: [],
      selectedDashboardId: null,
      newDashboardName: null
    }
  },
  methods: {
    showNewDashboardModal () {
      this.$v.$reset()
      this.$refs['new-dashboard-modal'].show()
    },
    hideNewDashboardModal () {
      this.$refs['new-dashboard-modal'].hide()
    },
    resetModal () {
      this.newDashboardName = null
    },
    async createNewDashboard (e) {
      e.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}dashboard`, { Name: this.newDashboardName })
        var newDash = response.data
        this.hideNewDashboardModal()
        this.dashboards.push({ name: newDash.name, dashboardId: newDash.dashboardId })
        this.dashboards.sort((a, b) => ('' + a.name).localeCompare(b.name))
        this.selectedDashboardId = newDash.dashboardId
        await this.changeDashboard()
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    async changeDashboard () {
      await this.loadDashboard()
      await this.setDashboardClaim()
    },
    async setDashboardClaim () {
      try {
        if (this.selectedDashboardId != null) {
          await axios.patch(`${process.env.VUE_APP_PLATFORM_API_URL}dashboard/UserClaim/${this.selectedDashboardId}`)
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    async deleteDashboard () {
      try {
        // Get confirmation from the user that they really want to delete the customer
        var swalResult = await swal.fire({
          title: 'Delete Dashboard',
          text: 'Are you sure you want to delete this dashboard?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } catch {
        return
      }

      if (!swalResult.isConfirmed) {
        return
      }

      try {
        await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}dashboard/${this.selectedDashboardId}`,
          { showload: true, showerror: true, errormessage: 'Dashboard failed to delete' })
        this.dashboards.splice(this.dashboards.findIndex(d => {
          return d.dashboardId === this.selectedDashboardId
        }), 1)
        if (this.dashboards.length > 0) {
          this.selectedDashboardId = this.dashboards[this.dashboards.length - 1].dashboardId
        } else {
          this.selectedDashboardId = null
          this.editable = false
        }
        await this.changeDashboard()
      } catch { }
    },
    remove (widgetToRemove) {
      const widgetIndex = this.dashboard.widgets.findIndex((e) => {
        return e.i === widgetToRemove.i
      })
      if (widgetIndex !== -1) {
        this.dashboard.widgets.splice(widgetIndex, 1)
      }
    },
    resize (widget) {
      this.$nextTick(function () {
        this.$refs.gridLayout.layoutUpdate()
      })
    },
    settings (widget) {
      const selWidget = this.dashboard.widgets.find(w => w.i === widget.i)
      selWidget.selected = true
      this.selectedWidget = selWidget
    },
    maximise (widget) {
      const selWidget = this.dashboard.widgets.find(w => w.i === widget.i)
      selWidget.expanded = true
      this.expanded = true
    },
    minimise (widget) {
      this.dashboard.widgets.forEach(w => { w.expanded = false })
      this.expanded = false
    },
    closeWidgetSettings () {
      this.dashboard.widgets.forEach(w => { w.selected = false })
      this.selectedWidget = null
    },
    async loadDashboards () {
      this.loading = true
      if (!this.customer) {
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}dashboard/Dropdown`)
        this.dashboards = response.data
        if (this.$store.getters.hasClaim('dashboard')) {
          this.selectedDashboardId = this.$store.getters.getClaim('dashboard').value
        } else if (this.dashboards.length > 1) {
          this.selectedDashboardId = this.dashboards[1].dashboardId
        }
      }
      await this.loadDashboard()
    },
    async loadDashboard () {
      this.loading = true
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}dashboard`,
        {
          params:
          {
            dashboardId: this.selectedDashboardId
          }
        })
      this.dashboard = response.data
      this.dashboard.widgets = response.data.widgets.map((w) => {
        w.maxH = 200
        w.minH = 200
        w.i = w.widgetId
        return w
      })

      this.loading = false
    },
    async editDashboard () {
      if (this.editable) {
        await this.save()
      }
      this.editable = !this.editable
    },
    async save () {
      await axios.put(`${process.env.VUE_APP_PLATFORM_API_URL}dashboard`, this.dashboard)
      await this.loadDashboard()
    },
    async cancelEdit () {
      this.editable = false
      this.dashboard.widgets = []
      await this.loadDashboard()
    },
    add ({ name, options, sizes, w, h }) {
      const widgetToAdd = {
        name,
        options: _.cloneDeep(options),
        sizes,
        w,
        h,
        maxH: 200,
        minH: 200,
        i: iCounter,
        widgetId: '00000000-0000-0000-0000-000000000000',
        ...this.getNewXY(w, h)
      }

      this.dashboard.widgets.push(widgetToAdd)
      iCounter++
    },
    dragOver (event) {
      event.preventDefault()
      this.dragging = true
      event.dataTransfer.dropEffect = 'copy'
    },
    dragEnter () {
      document.body.classList.add('dragging-over')
    },
    dragLeave () {
      document.body.classList.remove('dragging-over')
    },
    addDropped (event) {
      document.body.classList.remove('dragging-over')
      var widgetToAdd = JSON.parse(event.dataTransfer.getData('text'))
      this.add(widgetToAdd)
    },
    getNewXY (w, h) {
      let y = 0
      let x = -1
      while (x === -1) {
        x = this.getX(w, h, y)
        y++
      }
      y--
      return { x, y }
    },
    getX (w, h, y) {
      let thisX = 0
      for (let x = 0; x < 4; x++) {
        const isBlockHere = this.dashboard.widgets.some((widget) => ((y >= widget.y && y <= widget.y + (widget.h - 1)) || widget.y === y) && ((x >= widget.x && x <= widget.x + (widget.w - 1)) || widget.x === x))
        if (!isBlockHere) {
          thisX++
          if (thisX === w) {
            return x - (w - 1)
          }
        } else {
          thisX = 0
        }
      }
      return -1
    },
    fullscreen () {
      var elem = this.$refs.fullscreen
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }

      this.isFullscreen = true

      if (document.addEventListener) {
        document.addEventListener('webkitfullscreenchange', this.exitHandler, false)
        document.addEventListener('mozfullscreenchange', this.exitHandler, false)
        document.addEventListener('fullscreenchange', this.exitHandler, false)
        document.addEventListener('MSFullscreenChange', this.exitHandler, false)
      }
    },
    exitHandler () {
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        this.isFullscreen = false
      }
    }
  },
  async mounted () {
    await this.loadDashboards()
    document.body.classList.add('dashboard-page')
  },
  beforeDestroy () {
    document.body.classList.remove('dashboard-page')
  },
  watch: {
    selectedCustomer () { this.loadDashboards() }
  },
  validations: {
    newDashboardName: {
      required
    }
  }

}
</script>
<style lang="scss">
/*** EXAMPLE ***/
#content {
  width: 1458px;
}

.editable {
  width: 75%;
  .vue-grid-layout {
    background-image: linear-gradient(
        90deg,
        rgb(255, 255, 255) 0px,
        rgb(255, 255, 255) 14px,
        rgba(232, 232, 232, 0) 15px,
        rgba(232, 232, 232, 0) calc(100% - 15px),
        rgb(255, 255, 255) calc(100% - 14px),
        rgb(255, 255, 255) 100%
      ),
      linear-gradient(
        0deg,
        rgb(255, 255, 255) 0px,
        rgb(255, 255, 255) 14px,
        rgba(232, 232, 232, 0) 15px,
        rgba(232, 232, 232, 0) 215px,
        rgb(255, 255, 255) 216px,
        rgb(255, 255, 255) 230px
      );
    background-position-x: 15px;
    background-position-y: 15px;
    background-size: calc((100% / 4) - 7.5px) 230px;
  }

  .widget-navbar {
    margin: -1.875rem -5rem 1.875em -3.125em;
  }
}

.selected-widget {
  z-index: 1041;
}

.expanded-widget {
  z-index: 1041;
  width: 70vw !important;
  height: 60% !important;
  left: 20%;
  position: fixed !important;
}
.backdrop {
  transition: background-color 0.4s ease-in-out 0s;
  background-color: rgba(0, 0, 0, 0);
}

.active {
  &.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

.full-active {
  &.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.dragging {
  .widget-dropzone {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 5;

    &:before {
      content: "Drop here to add to the dashboard";
      white-space: pre;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -25px);
      -webkit-transform: translate(-50%, -25px);
      text-align: center;
      display: block;
      position: fixed;
      color: white;
    }
  }
}

.dragging-over {
  .widget-dropzone {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
}

.widget-dropzone {
  transition: background-color 0.4s ease-in-out 0s;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.dashboard-background {
  &:fullscreen {
    background-color: #f3f3f3;
    overflow: auto;

    .maintenance-messages {
      margin-top: 25px !important;
    }
  }
}

.widget-navbar {
  margin: -1.875rem -3.125rem 1.875em;
}

.carousel-caption {
  position: static;
  color: #000;
}

.VueCarousel-navigation-prev {
  left: 8px !important;
}
.VueCarousel-navigation-next {
  right: 8px !important;
}
</style>
