export default {
  methods: {
    checkValidation (customMessage, listOfOtherVObjects) {
      this.$v.$touch()
      var invalid = this.$v.$invalid
      if (listOfOtherVObjects) {
        listOfOtherVObjects.forEach(v => {
          if (v) {
            v.$touch()
            if (!invalid) {
              invalid = v.$invalid
            }
          }
        })
      }

      if (invalid) {
        this.$toastr.e(customMessage || 'There are validation errors on the form', 'Validation')
        return false
      }
      return true
    }
  }
}
