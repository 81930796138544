import Store from '@/Store/index'
import licence from '@/Assets/Constants/licence'

export default [
  {
    menu_title: 'UK Bank Account',
    menu_icon: 'ti-check-box',
    path: '/validate/Validation/BankAccount',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_modcheck)
    },
    title: 'UK Bank Account Validation'
  },
  {
    menu_title: 'IBAN',
    menu_icon: 'ti-check',
    path: '/validate/Validation/IBAN',
    showMethod: async () => {
      return Store.getters.hasLicence(licence.validate_iban)
    },
    title: 'International Bank Account Number Validation'
  }
]
