var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.addedLists && _vm.addedLists.length > 0
      ? _c(
          "div",
          _vm._l(_vm.addedLists, function(antiFraudList) {
            return _c("div", { key: antiFraudList.id }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("div", { staticClass: "form-control-plaintext" }, [
                    _c(
                      "span",
                      {
                        staticClass: "ins",
                        staticStyle: { "margin-right": "5px" }
                      },
                      [
                        _vm._v(
                          _vm._s(antiFraudList.name) +
                            " (" +
                            _vm._s(antiFraudList.listType) +
                            ")"
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.removedLists && _vm.removedLists.length > 0
      ? _c(
          "div",
          _vm._l(_vm.removedLists, function(antiFraudList) {
            return _c("div", { key: antiFraudList.id }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("div", { staticClass: "form-control-plaintext" }, [
                    _c(
                      "span",
                      {
                        staticClass: "del",
                        staticStyle: { "margin-right": "5px" }
                      },
                      [
                        _vm._v(
                          _vm._s(antiFraudList.name) +
                            " (" +
                            _vm._s(antiFraudList.listType) +
                            ")"
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.sameLists && _vm.sameLists.length > 0
      ? _c(
          "div",
          _vm._l(_vm.sameLists, function(antiFraudList) {
            return _c("div", { key: antiFraudList.id }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("div", { staticClass: "form-control-plaintext" }, [
                    _c("span", { staticStyle: { "margin-right": "5px" } }, [
                      _vm._v(
                        _vm._s(antiFraudList.name) +
                          " (" +
                          _vm._s(antiFraudList.listType) +
                          ")"
                      )
                    ])
                  ])
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "row form-group" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-4 col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Pre-submission message severity")]
      ),
      _c("div", { staticClass: "col-md-8" }, [
        _vm.groupPresubMessageSeverity !== _vm.actionPresubMessageSeverity
          ? _c("div", { staticClass: "form-control-plaintext" }, [
              _c(
                "span",
                { staticClass: "del", staticStyle: { "margin-right": "5px" } },
                [_vm._v(_vm._s(_vm.groupPresubMessageSeverity))]
              ),
              _c(
                "span",
                { staticClass: "ins", staticStyle: { "margin-right": "5px" } },
                [_vm._v(_vm._s(_vm.actionPresubMessageSeverity))]
              )
            ])
          : _c("div", { staticClass: "form-control-plaintext" }, [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v(_vm._s(_vm.groupPresubMessageSeverity))
              ])
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }