var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "manageSchedule" } },
      [
        _c(
          "b-row",
          { attrs: { mb: "4" } },
          [
            _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
              _c("div", { staticClass: "idb-block" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.processValid()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "idb-block-title" }, [
                      _c(
                        "h2",
                        [
                          _c("help-icon", {
                            attrs: {
                              docPath:
                                "/paygate-collections/import-export/updatevariableamounts/"
                            }
                          }),
                          _vm._v("Variable Amount Import Details ")
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "idb-block-content" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-sm-4",
                            attrs: {
                              "label-cols": 4,
                              horizontal: "",
                              label: "Import status"
                            }
                          },
                          [
                            _c(
                              "b-badge",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                attrs: {
                                  title: _vm.translateStatus(
                                    _vm.uploadHistory.status
                                  ),
                                  variant: _vm.getSeverityClass(
                                    _vm.uploadHistory.status
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translateStatus(
                                      _vm.uploadHistory.status
                                    )
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-sm-4",
                            attrs: {
                              "label-cols": 4,
                              horizontal: "",
                              label: "Rows in File"
                            }
                          },
                          [_vm._v(_vm._s(_vm.uploadHistory.totalRows))]
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-sm-4",
                            attrs: {
                              "label-cols": 4,
                              horizontal: "",
                              label: "Rows Imported"
                            }
                          },
                          [_vm._v(_vm._s(_vm.uploadHistory.importedRows))]
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-sm-4",
                            attrs: {
                              "label-cols": 4,
                              horizontal: "",
                              label: "Invalid rows"
                            }
                          },
                          [_vm._v(_vm._s(_vm.uploadHistory.failedRows))]
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-sm-12",
                            attrs: {
                              "label-cols": 12,
                              "label-horizontal": "",
                              label: _vm.truncatedMessagesLabel
                            }
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "messagesTextArea",
                                placeholder: "No validation errors found",
                                rows: 3,
                                "max-rows": 6,
                                readonly: ""
                              },
                              model: {
                                value: _vm.messagesToDisplay,
                                callback: function($$v) {
                                  _vm.messagesToDisplay = $$v
                                },
                                expression: "messagesToDisplay"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-sm-12",
                            attrs: {
                              "label-cols": 12,
                              "label-horizontal": "",
                              label: _vm.truncatedCsvLabel
                            }
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "textarea1",
                                placeholder: "No validation errors found",
                                rows: 3,
                                "max-rows": 6,
                                readonly: ""
                              },
                              model: {
                                value: _vm.csvToDisplay,
                                callback: function($$v) {
                                  _vm.csvToDisplay = $$v
                                },
                                expression: "csvToDisplay"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.uploadHistory.badRows !== undefined
                            },
                            on: { click: _vm.download }
                          },
                          [_vm._v("Download Failed Rows")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "idb-block-footer" },
                      [
                        _vm._v(" "),
                        _vm.actionId && _vm.actionId !== null
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "primary",
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.removeAction($event)
                                  }
                                }
                              },
                              [_vm._v("Clear Action")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }