<template>
  <div id="viewFlaggedSubmissions">
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2><help-icon docPath="/paygate-collections/messaging" />Messaging Reports<favourite-icon></favourite-icon></h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group mb-3">
              <div class="col-md-2 required">
                Select a Group
              </div>
              <div class="col-md-4">
                <group-select
                  v-model="group"
                  :groups="customerGroups"
                  @input="customerGroupsChanged()"
                  :clearable="false"
                ></group-select>
              </div>
            </div>
            <div class="row form-group mb-3">
              <div class="col-md-2 required">
                Earliest Date
              </div>
              <div class="col-md-4">
              <div class="right-inner-addon">
                <datepicker id="endPaymentDatePicker"
                  v-model="startDate"                  
                  :format="formatDate" @input="load()" input-class="form-control datepicker"
                /><i class="fa fa-calendar form-control-feedback"></i>
              </div>
              </div>
            </div>
            <div class="row form-group mb-3">
              <div class="col-md-2 required">
                Latest Date
              </div>
              <div class="col-md-4">
              <div class="right-inner-addon">
                <datepicker id="startPaymentDatePicker"
                    v-model="endDate"                    
                    :format="formatDate"
                      @input="load()" input-class="form-control datepicker"
                  /><i class="fa fa-calendar form-control-feedback"></i></div>
              </div>
            </div>
            <vue-good-table
              ref="table" mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :columns="columns"
              :rows="tableData"
              :lineNumbers="true"
              :totalRows="totalRecords"
              :search-options="{
                enabled: true
                }"
              :paginationOptions="paginationOptions"
              :sort-options="sortOptions"
              :isLoading.sync="isTableLoading"
              styleClass="vgt-table striped bordered">
              </vue-good-table>
            </div>
            <div class="idb-block-footer mt-3">                
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import swal from 'sweetalert2'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import papa from 'papaparse'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
export default {
  mixins: [tableFilterMixin, loading],
  props: {
    groupId: String
  },
  components: {
    Datepicker
  },
  data () {
    return {
      columns: [
        {
          label: 'Report Date',
          field: 'reportDate',
          formatFn: (val) => new Date(val).toLocaleDateString(),
          width: '180px'
        },
        {
          label: 'Sent',
          field: 'sentCount'
        },
        {
          label: 'Queued',
          field: 'queuedCount'
        },
        {
          label: 'Bounces',
          field: 'bounceCount'
        }, 
        {
          label: 'Current Errors',
          field: 'currentErrorCount'
        }        
      ],
      serverParams: {
        sort: [{ field: 'processingDate', type: 'desc' }]
      },
      dates: { proc: new Date(), coll: new Date() },
      selectedRow: null,
      originalState: [],
      tableData: [],
      hasChanges: false,
      startDate: new Date().setDate(new Date().getDate() - 7),
      endDate: new Date(),
      group: '00000000-0000-0000-0000-000000000000',
      totalRecords: 0
    }
  },
  methods: {
    back () {
      this.$router.push('/collections/submissions/')
    },
    load: async function () {
      try {
        if (this.group == null) {
          this.group = '00000000-0000-0000-0000-000000000000'
        }
        var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}reporting/messaging/${this.group}`, {
          params: {
            startDate: moment(this.startDate).format('DD-MM-YYYY'),
            endDate: moment(this.endDate).format('DD-MM-YYYY'),
            ...this.buildGoodTableQuery()
          }
        })
        this.originalState = response.data
        this.tableData = response.data
        this.totalRecords = response.data.length
      } catch (error) {
        this.$toastr.e(error)
      }
    },
    printTable () {
      window.print()
    },
    async exportTable () {
      var query = this.buildGoodTableQuery()
      var minDate = moment(this.endDate).format('DD-MM-YYYY')
      var maxDate = moment(this.startDate).format('DD-MM-YYYY')
      query.perPage = this.totalRecords
      var response = await axios.get(this.group !== '00000000-0000-0000-0000-000000000000' ? `${process.env.VUE_APP_DDMS_API_URL}reporting/orphans/${this.group}` : `${process.env.VUE_APP_DDMS_API_URL}reporting/orphans`, {
        params: {
        minDate,
        maxDate,        
        ...query }
      , showload: true })
      var csvString = papa.unparse(response.data.item1)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {        
        window.navigator.msSaveBlob(blob, `orphaned-submissions-report-${minDate}-to-${maxDate}.csv`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = `orphaned-submissions-report-${minDate}-to-${maxDate}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },       
    numberWithCommas (x) {
      x = x.toString()
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) {
        x = x.replace(pattern, '$1,$2')
      }
      return x
    },
    async loadGroups (paygateId) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
    },
    async customerGroupsChanged () {
      await this.load()
    }    
  },
  computed: {
    selectedPaygateId () {
      return this.$store.state.common.paygateId
    },    
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({ description: '', groupId: '00000000-0000-0000-0000-000000000000', groupType: 'UK DDMS', name: 'All Payers', paygateId: this.paygateId, isItemActioned: false, clonedName: null, colour: null })
      return ret
    },
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  validations: {
    group: { required }
  },
  async mounted () {
    await this.loadGroups(this.paygateId)
    await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.group })
    await this.load()
  }
}
</script>
<style>
  .deleted * {
    color: #cccccc;
  }
  .suppressed * {
    color: #cccccc;
  }
  .alert td.amountField {
    color: red !important;
    font-weight: bold;
  }
  #firstPaymentDatePicker, #endPaymentDatePicker, .datepicker, .datepicker:disabled {
  background-color: #ffffff !important
}
.dark-mode #firstPaymentDatePicker, .dark-mode #endPaymentDatePicker, .dark-mode .datepicker, .dark-mode .datepicker:disabled {
  background-color: transparent !important
}
.dark-mode .vdp-datepicker, .dark-mode .vdp-datepicker__calendar {
  background-color: #424242 !important
}
.dark-mode .vdp-datepicker__calendar * .next, .dark-mode .vdp-datepicker__calendar * .prev {
  color: #fff !important
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon  input {
  padding-left: 8px;
}
.right-inner-addon i {
  position: absolute;
  top: 11px;
  right: 15px;
}
</style>
