<template>
  <b-modal
    id="bulk-date-editor"
    ref="bulkDateEditorModal"
    title="Processing Date Editor"
    size="xl"
    hide-header-close
    static
    @shown="load"
    :ok-only="true"
  >
    <div slot="modal-ok">Close</div>
    <div class="form-group">
      <div class="shadow-sm p-3 mb-5 rounded">
        <div class="panel panel-default">
          <div
            class="panel-heading collapsed"
            data-toggle="collapse"
            data-target="#collapseOrderItems1"
          >
            <div class="row">
              <div class="col-sm-3">
                <i class="chevron fa fa-fw"></i>
                Click for filter options
              </div>
              <div class="col-sm-6"></div>

              <div
                v-if="filterOptionsActive"
                class="col-sm-3 pull-right text-warning"
              >Filter options active</div>
            </div>
          </div>
          <div class="collapse" id="collapseOrderItems1">
            <b-card>
              <!-- Filename Filter -->

              <b-row class="mt-2">
                <b-col sm="3" class="mt-2">
                  <label for="FilenameFilter">Filename</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input id="FilenameFilter"
                    ref="BDEfilenameFilter"
                    type="text"
                    @input="onFilterChanged()"
                    v-model.trim="BDEfilenameFilter"
                  ></b-form-input>
                </b-col>

                <span
                  contenteditable
                  onkeypress="event.preventDefault()"
                  onpaste="event.preventDefault()"
                  style="caret-color: transparent;"
                  class="fas fa-question-circle text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  data-bs-trigger="focus"
                  v-b-popover.hover=" { content: '<span>To search filenames you can use wildcards. </br> <ul> <li>You can type a distinct filename to search, <i>for example, MyFile.txt</i></li> <li>You can use a * symbol to denote part of a filename, <i>for example, MyFile*.*, or My*File.txt</i></li> </ul></span>', html: true }"
                ></span>
              </b-row>

              <!-- File Number -->

              <b-row class="mt-2">
                <b-col sm="3" class="mt-2">
                  <label for="BDEFileNumber">File Number</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input
                    id="BDEFileNumber"
                    ref="BDEfilenumberFilter"
                    type="text"
                    @input="onFilterChanged()"
                    v-model.trim="BDEfilenumberFilter"
                  ></b-form-input>
                </b-col>

                <span
                  contenteditable
                  onkeypress="event.preventDefault()"
                  onpaste="event.preventDefault()"
                  style="caret-color: transparent;"
                  class="fas fa-question-circle text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  data-bs-trigger="focus"
                  v-b-popover.hover=" { content: '<span> To search for file numbers, you can use these hints. However, do note that the removal or addition of file blocks will potentially mean files are no longer numbered as you might expect them. </br> <ul> <li>You can type a distinct file number to search</li> <li>You can use a , to enter multiple queries, <i>for example, 1,4,6,8 will return only those file numbers</i></li> <li>You can use a - for a rnage of file numbers, <i>for example, 10-20 will return only file bocks 10 through 20</i></li> <li>You can combine all of these searches, <i>for example, 1,2,5-8 will return only file bocks 1, 2 and 5 through 8</i></li> </ul></span>', html: true }"
                ></span>
              </b-row>

              <!-- SUN and Customer Name Filter -->

              <b-row class="mt-2">
                <b-col sm="3" class="mt-2">
                  <label for="BDEBureauCustomerSUNFilter">Bureau Customer or SUN</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input id="BDEBureauCustomerSUNFilter"
                    ref="BDEbureauCustomerSUNFilter"
                    type="text"
                    @input="onFilterChanged()"
                    v-model.trim="BDEbureauCustomerSunFilter"
                  ></b-form-input>
                </b-col>

                <span
                  contenteditable
                  onkeypress="event.preventDefault()"
                  onpaste="event.preventDefault()"
                  style="caret-color: transparent;"
                  class="fas fa-question-circle text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  data-bs-trigger="focus"
                  v-b-popover.hover=" { content: '<span> To search bureau customer names and SUNs you can use wildcards. </br> <ul> <li>You can type a distinct bureau customer name or a SUN to search, <i>for example, MyCustomer or 123456</i></li> <li>You can use a , to enter multiple queries, <i>for example, 123456, 234567, MyCustomer are valid inputs</i></li> <li>You can use a * for bureau customer names, <i>for example, MyCust* will search for any bureau customer beginning with MyCust</i></li> <li>You can also combine these options <i>for example, MyCust*, 123456, 234567 will search for any bureau customer beginning with MyCust and any bureau customers with those SUNs</i></li> <li> <i>Note that this search criteria will search both bureau customer name and SUN for any inputs, for example if a bureau customer contains a six digit number that also pertains to a separate SUN, this will also be returned.</i> </li> </ul></span>', html: true }"
                ></span>
              </b-row>

              <!-- SUN and Customer Name Filter -->

              <b-row class="mt-2" v-if="false">
                <b-col sm="3" class="mt-2">
                  <label for="BDEDebitsCreditsFilter">Debits And Credits Value Filter</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input id="BDEDebitsCreditsFilter"
                    ref="BDEdebitsCreditsFilter"
                    type="text"
                    @input="onFilterChanged()"
                    v-model.trim="BDEdebitsCreditsFilter"
                  ></b-form-input>
                </b-col>

                <span
                  contenteditable
                  onkeypress="event.preventDefault()"
                  onpaste="event.preventDefault()"
                  style="caret-color: transparent;"
                  class="fas fa-question-circle text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  data-bs-trigger="focus"
                  v-b-popover.hover=" { content: '<span> You can use this to search based on the total value of either debits or credits in a file block. </br> <ul> <li>You can type a distinct value to search, <i>for example, £5000 will return any file block where the total debits or credits sums to exactly £5000. You can also omit the £ if you would like</i></li> <li>You can use a , to enter multiple queries, <i>for example, £5000, £8000 will return any file blocks where the total debits or credits sums to either £5000 or £8000</i></li> <li>You can use a - for a range of values, <i>for example, £5000-£8000 will search for any file blocks where the total credits or debits is between £5000 and £8000</i></li> <li>You can use a ~ symbol to add an implicit 5% variance to the entered amount, <i>for example, ~£5000 will search for any file blocks where the total credits or debits is £5000 with a tolerance of 5%, that is £4750 - £5250. This is useful if you only know the rough value of the file block you are searching for </i></li> <li>You can use combine all of these operations, <i>for example, £1000, £5000-£8000, ~£9000-~£12000 will search for any file blocks where the total credits or debits is £1000, between £5000 and £8000 and between £9000 and £12000 with a 5% tolerance</i></li> </ul></span>', html: true }"
                ></span>
              </b-row>
              <b-row class="mt-2">
                <button
                  class="btn btn-outline-primary ml-1"
                  @click.stop="onClickApplyFilter()"
                  :disabled="isTableLoading"
                >Apply Filter</button>
                <button
                  class="btn btn-outline-danger ml-1"
                  @click.stop="onClickClearFilter()"
                  :disabled="isTableLoading"
                >Clear Filter</button>
              </b-row>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="row form-group col-md-4">
        <b-col sm="5" class="mt-2">
          <label for="paymentDate">
            <strong>Payment Date:</strong>
          </label>
        </b-col>

        <b-col sm="7">
          <vuejsDatepicker
            ref="paymentDate"
            name="paymentDate"
            id="paymentDate"
            v-model="paymentDate"
            format="dd/MM/yyyy"
            input-class="form-control"
            :readonly="false"
            :disabledDates="disabledDates"
            v-on:selected="paymentDateChanged"
            @focusin.native="dateOnfocus"
            :monday-first="true"
          ></vuejsDatepicker>
        </b-col>

      </div>
      <div class="row form-group col-md-4">
        <b-col sm="5" class="mt-2">
          <span>
            <strong>Processing Date:</strong>
          </span>
        </b-col>
        <b-col sm="7" class="mt-2">{{ processingDate }}</b-col>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :isLoading.sync="isTableLoading"
          @on-search="onSearch"
          :rows="rows"
          :columns="columns"
          :totalRows="totalRecords"
          mode="remote"
          @on-selected-rows-change="selectionChanged"
          :select-options="{enabled: true,

          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'checkbox'">
              <label :for="'checkBox_'+props.row.fileNumber"></label>
              <p-check :labelId="'checkBox_'+props.row.fileNumber"
                class="p-default p-curve"
                checked="isChecked(props.row)"
                @change="rowClick(props.row)"
              ></p-check>
            </span>
          </template>
          <div slot="table-actions">
            <!-- <button
              class="btn btn-outline-secondary ml-1 pull-left"
              @click.stop="selectAll()"
              v-b-popover.hover.top.d500="'Select All including rows off screen'"
            >Select All Rows</button>-->

            <!-- Clear filters -->
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span><span class="sr-only">Clear filters</span>
            </button>
          </div>
        </vue-good-table>
      </div>
    </div>
    <footer slot="modal-footer" slot-scope="props">
      <button class="btn btn-secondary mr-1" @click="props.cancel()">Close</button>
      <button
        class="btn btn-primary" :disabled="selectedFilenumbers !== null && selectedFilenumbers.length === 0"
        @click="applyDates"
      >Apply Date to Block/s</button>
    </footer>
  </b-modal>
</template>

<script>
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import Utility from '@/Assets/Mixins/Utility'
import _ from 'lodash'

export default {
  mixins: [tableFilterMixin, Utility, bacsMixin],
  props: {
    submissionId: String,
    filenameFilter: String,
    filenumberFilter: String,
    bureauCustomerSunFilter: String,
    debitsCreditsFilter: String

  },
  data: function () {
    return {
      processingDate: '',
      paymentDate: '',
      disabledDates: {},
      selectedFilenumbers: [],
      rows: [],
      columns: [
        {
          label: 'File No.',
          field: 'fileNumber'
        },
        {
          label: 'Bureau Customer Name',
          field: 'bureauCustomerName'
        },
        {
          label: 'Filename',
          field: 'fileNameDisplay'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Processing Date',
          field: 'processingDateDisplay'
        },
        {
          label: 'Payment Date',
          field: 'paymentDateDisplay'
        }
        // ,
        // {
        //   label: '',
        //   field: 'checkbox',
        //   sortable: false
        // }
      ],
      serverParams: {
        sort: [{ field: 'fileNumber', type: 'asc' }]
      },
      // Filters
      BDEfilenameFilter: '',
      BDEfilenumberFilter: '',
      BDEbureauCustomerSunFilter: '',
      BDEdebitsCreditsFilter: '',
      BDEfilterOptionsActive: false,

      filterOptionsActive: false

    }
  },
  computed: {
    isChecked: function (item) {
      return _.includes(this.selectedFilenumbers, item.fileNumber)
    },
    buttonText: function () {
      if (this.selectedFilenumbers.length === 1) {
        return 'Apply date to 1 block'
      } else if (this.selectedFilenumbers.length > 1) {
        return 'Apply date to ' + this.selectedFilenumbers.length + ' blocks'
      } else {
        return 'No blocks selected'
      }
    }
  },
  async created () {
    this.disabledDates = await this.setUpBACSDatePicker()
    this.paymentDate = await this.getNextPaymentDate()
    this.selectedFilenumbers = []

    this.BDEfilenameFilter = this.filenameFilter
    this.BDEfilenumberFilter = this.filenumberFilter
    this.BDEbureauCustomerSunFilter = this.bureauCustomerSunFilter
    this.BDEdebitsCreditsFilter = this.debitsCreditsFilter
  },

  async mounted () {
    this.disabledDates = await this.setUpBACSDatePicker()
    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'
    var dateResponse = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingDate`)
    this.processingDates = dateResponse.data
    this.paymentDate = new Date(this.processingDates.minPaymentDate)
    this.processingDate = this.processingDates.nextProcessingDate
    // Disable weekends in datepicker.
    this.disabledDates.days = [6, 0]
    // Disable any dates before the earliest possible payment date.
    this.disabledDates.to = this.paymentDate
    // Disable any dates more than 31 days into the future.
    var maxDate = new Date(this.processingDates.maxPaymentDate)
    this.disabledDates.from = maxDate
    // Disable any other non-processing days i.e bank holidays.
    if (this.processingDates.nonProcessingDates.length > 0) {
      var npds = []
      for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
        npds.push(new Date(this.processingDates.nonProcessingDates[i]))
      }
      this.disabledDates.dates = npds
    }
  },

  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getBulkDateEditor`, {
          params: {
            ...this.buildGoodTableQuery(),
            submissionId: this.submissionId,
            filenameFilter: this.BDEfilenameFilter,
            filenumberFilter: this.BDEfilenumberFilter,
            bureauCustomerSunFilter: this.BDEbureauCustomerSunFilter,
            debitsCreditsFilter: this.BDEdebitsCreditsFilter
          },
          showerror: true,
          errormessage: 'Failed loading Processing date editor'
        })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.filterOptionsActive = !(this.BDEfilenameFilter.length === 0 &&
          this.BDEfilenumberFilter.length === 0 &&
          this.BDEbureauCustomerSunFilter.length === 0 &&
          this.BDEdebitsCreditsFilter.length === 0)
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    async initialLoad () {
      this.BDEfilenameFilter = this.filenameFilter
      this.BDEfilenumberFilter = this.filenumberFilter
      this.BDEbureauCustomerSunFilter = this.bureauCustomerSunFilter
      this.BDEdebitsCreditsFilter = this.debitsCreditsFilter
      await this.load()
    },

    selectionChanged (selection) {
      console.log('selection', selection)
      var i = 0
      var Arr = []
      for (i = 0; i < selection.selectedRows.length; i++) {
        Arr.push(selection.selectedRows[i].fileNumber)
      }
      this.selectedFilenumbers = Arr
    },

    async onClickApplyFilter () {
      await this.load()
    },
    async onFilterChanged () {

    },
    async onClickClearFilter () {
      this.BDEfilenameFilter = ''
      this.BDEfilenumberFilter = ''
      this.BDEbureauCustomerSunFilter = ''
      this.BDEdebitsCreditsFilter = ''
      this.BDEfilterOptionsActive = false
      await this.load()
    },

    async paymentDateChanged (value) {
      var valasjson = value.toJSON()
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingdate/getProcessingDateFromPaymentDate?paymentDate=` + value.toJSON())
      this.processingDate = response.data
    },
    async selectAll () {
      // callback to server to get the filenumbers only
      try {
        this.isTableLoading = true
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getBulkDateEditorFileNumbersOnly`, {
          params: {
            ...this.buildGoodTableQuery(),
            submissionId: this.submissionId,
            filenameFilter: this.BDEfilenameFilter,
            filenumberFilter: this.BDEfilenumberFilter,
            bureauCustomerSunFilter: this.BDEbureauCustomerSunFilter,
            debitsCreditsFilter: this.BDEdebitsCreditsFilter
          },
          showerror: true,
          errormessage: 'Failed loading Processing date editor'
        })
        console.log('select all response', response)
        this.selectedFilenumbers = response.data
      } catch { } finally {
        this.isTableLoading = false
      }
    },

    rowClick (item) {
      var fileNumber = item.fileNumber
      if (!_.includes(this.selectedFilenumbers, fileNumber)) {
        this.selectedFilenumbers.push(item.fileNumber)
      } else {
        var i = 0
        var Arr = []
        for (i = 0; i < this.selectedFilenumbers.length; i++) {
          if (this.selectedFilenumbers[i] !== fileNumber) {
            Arr.push(this.selectedFilenumbers[i])
          }
        }
        this.selectedFilenumbers = Arr
      }
    },
    async applyDates () {
      var itemIds = []
      var i = 0
      for (i = 0; i < this.rows.length; i++) {
        if (_.includes(this.selectedFilenumbers, this.rows[i].fileNumber)) {
          itemIds.push(this.rows[i].itemId)
        }
      }
      var requestModel = {
        itemIds: itemIds,
        processingDate: this.processingDate,
        bureauSubmissionId: this.submissionId
      }
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/bulkdateeditorprocesschange', requestModel, { showload: true })
      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
      await this.load()
      this.$emit('submit')
    }
  }

}

</script>
<style lang="scss">
.modal .modal-bulkdateeditor {
  max-width: 95%;
}

.collapse-arrow .icon {
  transform: rotate(-180deg);
  transition: 0.3s transform ease-in-out;
  display: inline-block;
}

.collapse-arrow .collapsed .icon {
  transform: rotate(0deg);
}

.panel-heading .chevron:after {
  content: "\f078";
}
.panel-heading.collapsed .chevron:after {
  content: "\f054";
}
#paymentDate {
  background-color: #ffffff;
}
.dark-mode #paymentDate {
  background-color: inherit;
}

</style>
