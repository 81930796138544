<template>
  <span :class="{'text-success':value && coloured, 'text-danger':!value && coloured}">
    <i class="fa" :class="{'fa-check':value, 'fa-times':!value}"></i>
  </span>
</template>

<script>
export default {
  name: 'tick',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    coloured: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
</style>
