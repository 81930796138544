var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm.hasErrors
          ? _c(
              "div",
              { staticClass: "alert alert-danger" },
              _vm._l(_vm.errors, function(error, index) {
                return _c("div", { key: index }, [
                  _c("ul", [
                    error.message
                      ? _c("li", [
                          _vm._v(
                            _vm._s(error.type) + " - " + _vm._s(error.message)
                          )
                        ])
                      : _c("li", [_vm._v(_vm._s(error))])
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ])
    ]),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("crud-policy-form", {
          attrs: {
            formType: "Creation",
            details: _vm.crudPolicyDetails,
            policy: _vm.$v.policy.createAntiFraudListPolicy,
            docPath: "/administration/securitypolicy/antifraudlists/#create"
          }
        }),
        _c("crud-policy-form", {
          attrs: {
            formType: "Updates",
            details: _vm.crudPolicyDetails,
            policy: _vm.$v.policy.editAntiFraudListPolicy,
            docPath: "/administration/securitypolicy/antifraudlists/#edit"
          }
        }),
        _c("crud-policy-form", {
          attrs: {
            formType: "Removal",
            details: _vm.crudPolicyDetails,
            policy: _vm.$v.policy.deleteAntiFraudListPolicy,
            docPath: "/administration/securitypolicy/antifraudlists/#removal"
          }
        }),
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }