
import PrettyInput from '@/Assets/Components/Inputs/src/PrettyInput'
import PrettyCheckbox from '@/Assets/Components/Inputs/src/PrettyCheckbox'
import PrettyRadio from '@/Assets/Components/Inputs/src/PrettyRadio'

module.exports = {
    install: function (Vue, options) {
        Vue.component('p-input', PrettyInput)
        Vue.component('p-check', PrettyCheckbox)
        Vue.component('p-radio', PrettyRadio)
    }
}
