var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "90%" } },
    [
      _vm._m(0),
      !_vm.loading
        ? _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scroll-area",
              attrs: { settings: { maxScrollbarLength: 60 } }
            },
            [
              _c("table", { staticClass: "table table-striped" }, [
                _c("tbody", [
                  _c("tr", { attrs: { valign: "top" } }, [
                    _c("td", [_vm._v("Date")]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.formatDate(_vm.date)))
                    ])
                  ]),
                  _c("tr", { attrs: { valign: "top" } }, [
                    _c("td", [_vm._v("Sent")]),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-badge",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: { pill: "", variant: "success" }
                          },
                          [_vm._v(_vm._s(_vm.sentCount))]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("tr", { attrs: { valign: "top" } }, [
                    _c("td", [_vm._v("Queued")]),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-badge",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              pill: "",
                              variant: _vm.queudCount > 0 ? "info" : "success"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToProblems("Queued")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.queuedCount))]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("tr", { attrs: { valign: "top" } }, [
                    _c("td", [_vm._v("Current Errors")]),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-badge",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              pill: "",
                              variant:
                                _vm.failedCount > 0 ? "danger" : "success"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToProblems("Error")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.failedCount))]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("tr", { attrs: { valign: "top" } }, [
                    _c("td", [_vm._v("Bounced")]),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-badge",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              pill: "",
                              variant:
                                _vm.bounceCount > 0 ? "danger" : "success"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToProblems("Bounced")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.bounceCount))]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("p", [
                _vm._v(
                  "The report above shows the state of messages as of the end of yesterday. Queued Messages include those that are scheduled to be sent in the future."
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.loading ? _c("div", [_vm._v("Loading...")]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h4", { staticClass: "m-0 w-100" }, [_vm._v("Messaging Report")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }