var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-container" },
    [
      _vm.value.title === undefined
        ? _c("h3", [
            _vm._v(_vm._s(_vm.title)),
            _c("br"),
            _vm.subtitle
              ? _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.subtitle))
                ])
              : _vm._e()
          ])
        : _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c(
                "b-col",
                { staticClass: "mt-2", attrs: { sm: "2", cols: "2" } },
                [
                  _c("label", { staticClass: "font-weight-bold" }, [
                    _vm._v("Title")
                  ])
                ]
              ),
              _c(
                "b-col",
                { staticClass: "mt-2", attrs: { cols: "7", xl: "6" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        model: {
                          value: _vm.value.title,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "title", $$v)
                          },
                          expression: "value.title"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "mt-2", attrs: { sm: "2", cols: "2" } }, [
            _c("label", { staticClass: "font-weight-bold" }, [_vm._v("Letter")])
          ]),
          _c(
            "b-col",
            { staticClass: "mt-2", attrs: { cols: "7", xl: "6" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.templateOptions.letters
                    },
                    model: {
                      value: _vm.value.letterTemplate,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "letterTemplate", $$v)
                      },
                      expression: "value.letterTemplate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.value.letterTemplate !== null &&
          _vm.value.letterTemplate !== undefined
            ? _c(
                "b-col",
                [
                  _vm._v(" "),
                  _vm.value.letterTemplate
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary mt-2 buttonWidthFix",
                          attrs: {
                            to: {
                              name: "SpecificLetterTemplate",
                              params: { templateid: _vm.value.letterTemplate }
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    : _c(
                        "b-btn",
                        {
                          staticClass: "mt-2",
                          attrs: { variant: "primary", disabled: "" }
                        },
                        [_vm._v("Edit")]
                      )
                ],
                1
              )
            : _c(
                "b-col",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary mt-2 buttonWidthFix",
                      attrs: {
                        to: "/collections/defaultmessage/1/" + _vm.messageType
                      }
                    },
                    [_vm._v("View")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "mt-2", attrs: { sm: "2", cols: "2" } }, [
            _c("label", { staticClass: "font-weight-bold" }, [_vm._v("Email")])
          ]),
          _c(
            "b-col",
            { staticClass: "mt-2", attrs: { cols: "7", xl: "6" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.templateOptions.emails
                    },
                    model: {
                      value: _vm.value.emailTemplate,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "emailTemplate", $$v)
                      },
                      expression: "value.emailTemplate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.value.emailTemplate !== null &&
          _vm.value.emailTemplate !== undefined
            ? _c(
                "b-col",
                [
                  _vm._v(" "),
                  _vm.value.emailTemplate
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary mt-2 buttonWidthFix",
                          attrs: {
                            to: {
                              name: "SpecificEmailTemplate",
                              params: { templateid: _vm.value.emailTemplate }
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    : _c(
                        "b-btn",
                        {
                          staticClass: "mt-2",
                          attrs: { variant: "primary", disabled: "" }
                        },
                        [_vm._v("Edit")]
                      )
                ],
                1
              )
            : _c(
                "b-col",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary mt-2 buttonWidthFix",
                      attrs: {
                        to: "/collections/defaultmessage/0/" + _vm.messageType
                      }
                    },
                    [_vm._v("View")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "mt-2", attrs: { cols: "2" } }, [
            _c("label", { staticClass: "font-weight-bold" }, [_vm._v("SMS")])
          ]),
          _c(
            "b-col",
            { staticClass: "mt-2", attrs: { cols: "7", xl: "6" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.templateOptions.sms
                    },
                    model: {
                      value: _vm.value.smsTemplate,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "smsTemplate", $$v)
                      },
                      expression: "value.smsTemplate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.value.smsTemplate !== null && _vm.value.smsTemplate !== undefined
            ? _c(
                "b-col",
                [
                  _vm._v(" "),
                  _vm.value.smsTemplate
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary mt-2 buttonWidthFix",
                          attrs: {
                            to: {
                              name: "SpecificSmsTemplate",
                              params: { templateid: _vm.value.smsTemplate }
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    : _c(
                        "b-btn",
                        {
                          staticClass: "mt-2",
                          attrs: { variant: "primary", disabled: "" }
                        },
                        [_vm._v("Edit")]
                      )
                ],
                1
              )
            : _c(
                "b-col",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary mt-2 buttonWidthFix",
                      attrs: {
                        to: "/collections/defaultmessage/2/" + _vm.messageType
                      }
                    },
                    [_vm._v("View")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.value.title !== undefined
        ? _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "mt-2",
                      attrs: { variant: "danger" },
                      on: { click: _vm.deleteMessageType }
                    },
                    [
                      _c("i", { staticClass: "fa fa-trash mr-2" }),
                      _vm._v("Delete")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }