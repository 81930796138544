var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasErrors
      ? _c(
          "div",
          { staticClass: "alert alert-danger" },
          _vm._l(_vm.errors, function(error, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" IP Address Restrictions "),
                _c("help-icon", {
                  attrs: {
                    docPath:
                      "/administration/securitypolicy/ipaddressrestrictions/"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _vm._m(0),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-form-label col-md-3 offset-md-1",
                    attrs: { for: "enforceIPrestriction" }
                  },
                  [_vm._v("Enforce IP Address Restrictions")]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: {
                        labelId: "enforceIPrestriction",
                        color: "primary"
                      },
                      model: {
                        value: _vm.$v.policy.enforceIpRestrictions.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.policy.enforceIpRestrictions,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.policy.enforceIpRestrictions.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.policy.enforceIpRestrictions
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-md-10 offset-md-1 pl-5",
                          class: {
                            invalid: !_vm.$v.policy.ipAddressItem.atLeastOne
                          }
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "table table-striped table-bordered"
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { attrs: { scope: "col" } }, [
                                    _vm._v("Active")
                                  ]),
                                  _c("th", { attrs: { scope: "col" } }, [
                                    _vm._v(
                                      "IP Address (V4 format) with or without a CIDR"
                                    )
                                  ]),
                                  _c("th", { attrs: { scope: "col" } }, [
                                    _vm._v("Allow/Disallow")
                                  ]),
                                  _c("th", { attrs: { scope: "col" } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { type: "button" },
                                        on: { click: _vm.addNewAddress }
                                      },
                                      [_vm._v("Add New Address")]
                                    )
                                  ])
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.$v.policy.ipAddressItem.$each.$iter,
                                  function(ipAddress, index) {
                                    return _c("tr", { key: index }, [
                                      _c(
                                        "td",
                                        { attrs: { scope: "row" } },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "sr-only",
                                              attrs: {
                                                for: "activeCheckbox" + index
                                              }
                                            },
                                            [_vm._v("Active")]
                                          ),
                                          _c("p-check", {
                                            attrs: {
                                              labelId: "activeCheckbox" + index
                                            },
                                            model: {
                                              value: ipAddress.active.$model,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  ipAddress.active,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ipAddress.active.$model"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        {
                                          class: {
                                            invalid: ipAddress.ipText.$error
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: ipAddress.ipText.$model,
                                                expression:
                                                  "ipAddress.ipText.$model",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: ipAddress.ipText.$model
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  ipAddress.ipText,
                                                  "$model",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _c("validation-messages", {
                                            model: {
                                              value: ipAddress.ipText,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  ipAddress,
                                                  "ipText",
                                                  $$v
                                                )
                                              },
                                              expression: "ipAddress.ipText"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.restrictionTypes
                                            },
                                            model: {
                                              value:
                                                ipAddress.restrictionType
                                                  .$model,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  ipAddress.restrictionType,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ipAddress.restrictionType.$model"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeAddress(index)
                                              }
                                            }
                                          },
                                          [_vm._v("Delete")]
                                        )
                                      ])
                                    ])
                                  }
                                ),
                                0
                              )
                            ]
                          ),
                          _c(
                            "validation-messages",
                            {
                              model: {
                                value: _vm.$v.policy.ipAddressItem,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.policy, "ipAddressItem", $$v)
                                },
                                expression: "$v.policy.ipAddressItem"
                              }
                            },
                            [
                              _c("template", { slot: "override" }, [
                                !_vm.$v.policy.ipAddressItem.atLeastOne
                                  ? _c(
                                      "small",
                                      { staticClass: "form-text small" },
                                      [
                                        _vm._v(
                                          "Please enter at least one IP address restriction"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.isLoading }
              },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning ml-3",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.reset }
              },
              [_vm._v("Reset to default")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c("div", { staticClass: "col-md-10 offset-md-1" }, [
        _c("p", [
          _vm._v(
            " When a user tries to authenticate (Login) to paygate and 'Enforce IP Address Restrictions' is checked, the rules are checked from top to bottom. "
          ),
          _c("br"),
          _vm._v(
            "The first rule to match will be enforced. If no match is found the user will not be allowed to authenticate. "
          )
        ]),
        _c("p", [
          _vm._v(" Examples "),
          _c("br"),
          _vm._v("Single Address, e.g. 192.168.100.25 "),
          _c("br"),
          _vm._v("Subnet, e.g. 192.168.0.5/24 or 192.168.0.5/255.255.255.0 ")
        ]),
        _c("hr")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }