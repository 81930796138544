var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", size: "lg", "no-close-on-backdrop": "" },
      on: { hide: _vm.hide, shown: _vm.setFocus },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-header" },
          slot: "modal-header"
        },
        [_vm._v("Non-Processing Day (Use English Bank Holidays ONLY)")]
      ),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4 required" }, [
          _vm._v("Non-Processing Date")
        ]),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c("vuejsDatepicker", {
              ref: "paymentDate",
              attrs: {
                name: "nonProcessingDate",
                id: "nonProcessingDate",
                format: "dd/MM/yyyy",
                "input-class": "form-control",
                "bootstrap-styling": true,
                "monday-first": true
              },
              on: { selected: _vm.nonProcessingDateChanged },
              nativeOn: {
                focusin: function($event) {
                  return _vm.dateOnfocus($event)
                }
              },
              model: {
                value: _vm.nonProcessingDate,
                callback: function($$v) {
                  _vm.nonProcessingDate = $$v
                },
                expression: "nonProcessingDate"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4" }),
        _c("div", { staticClass: "col-md-8" }, [
          _vm.$v.nonProcessingDate.$dirty
            ? _c("span", [
                !_vm.$v.nonProcessingDate.required
                  ? _c(
                      "small",
                      {
                        staticClass: "form-text text-danger small text-nowrap"
                      },
                      [_vm._v("Non-processing date is required")]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4 required" }, [_vm._v("Reason")]),
        _c(
          "div",
          { staticClass: "col-md-8", class: { invalid: _vm.$v.reason.$error } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim.lazy",
                  value: _vm.$v.reason.$model,
                  expression: "$v.reason.$model",
                  modifiers: { trim: true, lazy: true }
                }
              ],
              staticClass: "form-control",
              domProps: { value: _vm.$v.reason.$model },
              on: {
                change: function($event) {
                  _vm.$set(_vm.$v.reason, "$model", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-4" }),
        _c("div", { staticClass: "col-md-8" }, [
          _vm.$v.reason.$dirty
            ? _c("span", [
                !_vm.$v.reason.required
                  ? _c(
                      "small",
                      {
                        staticClass: "form-text text-danger small text-nowrap"
                      },
                      [_vm._v("Non-processing day reason is required")]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row form-group d-none" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", { attrs: { for: "northernIreOnly" } }, [
            _vm._v("Northern Ireland Only")
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c("p-check", {
              staticClass: "p-switch p-fill",
              attrs: { labelId: "northernIreOnly", color: "primary" },
              model: {
                value: _vm.northernIrelandOnly,
                callback: function($$v) {
                  _vm.northernIrelandOnly = $$v
                },
                expression: "northernIrelandOnly"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c(
            "b-btn",
            {
              staticClass: "float-left",
              attrs: {
                size: "sm",
                variant: "primary",
                disabled: _vm.disableButtons
              },
              on: { click: _vm.saveNonProcessingDay }
            },
            [_vm._v("Save Non-Processing Day")]
          ),
          !_vm.newNonProcessingDay
            ? _c(
                "b-btn",
                {
                  staticClass: "float-right ml-2",
                  attrs: {
                    size: "sm",
                    variant: "danger",
                    disabled: _vm.disableButtons
                  },
                  on: { click: _vm.deleteNonProcessingDay }
                },
                [_vm._v("Delete Non-Processing Day")]
              )
            : _vm._e(),
          _c(
            "b-btn",
            {
              staticClass: "float-right",
              attrs: {
                size: "sm",
                variant: "outline-danger",
                disabled: _vm.disableButtons
              },
              on: { click: _vm.cancel }
            },
            [_vm._v("Cancel")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }