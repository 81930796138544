var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.error
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
              _c("h4", { staticClass: "m-0 w-100" }, [
                _vm._v("Recently Added Payerss"),
                _c("i", {
                  staticClass: "fas fa-sync-alt pull-right",
                  attrs: { role: "button" },
                  on: { click: _vm.populate }
                })
              ])
            ]),
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c(
                    "h2",
                    { staticClass: "bigNumber" },
                    [
                      _c(
                        "router-link",
                        {
                          class: _vm.numberStyle(_vm.recentCount),
                          attrs: {
                            to: {
                              name: "managePayers",
                              query: { groupid: _vm.group, status: 2 }
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.numberFormatter(_vm.recentCount)))]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "text-center" },
              [
                _c(
                  "b-col",
                  [
                    _vm._v(" " + _vm._s(_vm.recentCount) + " "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "managePayers",
                            query: { groupid: _vm.group, status: 2 }
                          }
                        }
                      },
                      [_vm._v("(" + _vm._s(_vm.percentTotal) + "% of group)")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "text-center" },
              [_c("b-col", [_vm._v(" " + _vm._s(_vm.groupName) + " ")])],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { cols: "8" } }, [
                  _vm._v(" Error getting "),
                  _c("strong", [_vm._v("Added Payers")]),
                  _vm._v(". ")
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "3" } },
                  [
                    _c(
                      "b-btn",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.populate($event)
                          }
                        }
                      },
                      [_vm._v("Refresh")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }