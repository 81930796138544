<template>
  <div>
    <form @submit.prevent>
      <div class="idb-block" v-if="approvalMethodWarning">
        <div class="alert alert-warning" role="alert">
          <div>Unable to action - {{approvalMethodWarning}}</div>
        </div>
      </div>
      <div class="idb-block" v-if="userRoleError">
        <div class="alert alert-danger" role="alert">
          <div>User does not have the correct role to approve this action</div>
        </div>
      </div>
      <div class="idb-block" v-if="action.rejected">
        <div class="alert alert-danger" role="alert">
          <div>Rejected By: {{action.rejectedBy}}</div>
          <div>Rejected On: {{action.rejectedDate | formatDate('DD/MM/YYYY HH:mm')}}</div>
          <div v-if="action.rejectionMessage">
            Message:
            <br />
            {{action.rejectionMessage}}
          </div>
        </div>
      </div>
      <div class="idb-block" v-else>
        <div class="alert alert-info" role="alert">
          <div>Action Created By: {{action.name}}</div>
          <div>Action Created On: {{action.createdDate | formatDate('DD/MM/YYYY HH:mm')}}</div>
        </div>
      </div>
      <slot v-if="!userRoleError"></slot>
      <div class="idb-block" v-if="shouldShowFooter">
        <div class="idb-block-footer" v-if="!action.rejected">
          <button
            class="btn btn-success"
            :disabled="isLoading"
            type="submit"
            @click="approve()"
            v-if="!approvalMethodWarning"
          >Approve {{this.status}}</button>
          <button
            class="btn btn-danger pull-right"
            :disabled="isLoading"
            type="button"
            @click="reject()"
          >Reject</button>
        </div>
        <div class="idb-block-footer" v-else>
          <button
            class="btn btn-primary"
            :disabled="isLoading"
            type="submit"
            @click="deleteRejection()"
          >Delete Action</button>
        </div>
      </div>
    </form>
    <actions-approve-modal
      v-if="action.id"
      ref="modal"
      :type="approvalType"
      :actionId="action.id"
      :status="status"
      :name="name"
      :action="action"
      v-on:submit="doneWithCode"
    ></actions-approve-modal>
    <plugin></plugin>
  </div>
</template>
<script>
import Approve from '@/Components/Platform/Actions/ApproveMixin'
import Reject from '@/Components/Platform/Actions/RejectMixin'
import ActionsApproveModal from '@/Components/Platform/Actions/ActionsApprovalModal'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  props: {
    action: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    from: {
      type: Object,
      required: true
    },
    shouldShowFooter: {
      type: Boolean,
      default: false
    },
    userRoleError: {
      type: Boolean,
      default: false
    }
  },
  mixins: [Approve, Reject, loading],
  components: {
    ActionsApproveModal,
    Plugin
  },
  methods: {
    openApprovalModal () {
      this.$refs.modal.$refs.approveAction.show()
    },
    doneWithCode () {
      var name = this.name.replace(/\s/g, '').toLowerCase()
      if (this.status === 'Delete' && this.from.fullPath.toLowerCase().includes(name) && (this.from.params.id === this.action.actionedObjectId || this.from.params[`${name}Id`] === this.action.actionedObjectId)) {
        this.$router.go(-2)
      } else {
        this.$router.back()
      }
    }
  }
}
</script>
