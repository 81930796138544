import Vue from 'vue'
import Accounting from 'accounting-js'

Vue.filter('currency', function (amt) {
  var options = {
    symbol: '£',
    decimal: '.',
    thousand: ',',
    precision: 2,
    format: '%s%v'
  }
  return Accounting.formatMoney(amt, options)
})
