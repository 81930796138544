<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>File Browser
            <span class="pull-right">
              <a href="#" target="_blank">
                <i class="far fa-question-circle"></i>
              </a>
            </span>
          </h2>
        </div>
        <div class="idb-block-content">
          <div class="row">
            <div class="col-md-3">
  <v-jstree :data="treeData" multiple allow-batch whole-row @item-click="itemClick"></v-jstree>
            </div>
            <div class="col-md-6">

<!-- <ul class="list-group"> -->
<draggable v-model="myArray" :options="{group:'people'}" @start="drag=true" @end="drag=false">
  <div v-for="(element, index) in myArray" :key="index"  class="list-complete-item" >
    <div class="list-group-item">
      <div class="d-flex justify-content-start">
        <div class="d-flex align-items-start flex-column mr-20">
          <i v-if = "element.isFolder" class="fa fa-folder fa-4x folder"></i>
          <i v-else class="fa fa-file fa-4x file"></i>
        </div>
        <div class="d-flex align-items-start flex-column pt-10">
          <h6 class="font-lg">{{element.text}}</h6>
          <p class="mb-0">{{element.description}}</p>
          <div v-if = "element.isFolder" class="small fw-bold"></div>
          <div v-if = "!element.isFolder" class="small fw-bold"><strong>Size:</strong> {{bytesToSize(element.stat.size)}}</div>
          <div v-if = "!element.isFolder" class="small fw-bold"><strong>Last Modified:</strong> {{reformatDate(element.stat.mtime)}}</div>
        </div>
      </div>
    </div>
   </div>
</draggable>
            </div>
            <div class="col-md-3">
              Info
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <div class="row" >
            <div class="col-md-12 col-sm-3">
              <b-button @click.prevent="btnClick" variant="primary"><i class="fa fa-download mr-2"></i>Button</b-button>
              <!-- <b-button @click.prevent="testTrigger" variant="outline-secondary" title="Test trigger" v-b-popover.hover.top="'Test this trigger in sandbox (test) mode'">Test</b-button>  -->
              <!-- <b-button @click.prevent="deleteTrigger" variant="danger" class="pull-right" title="Delete" v-b-popover.hover.top.d500="'Wow'"><i class="fa fa-trash mr-2"></i>Delete</b-button>  -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VJstree from 'vue-jstree'
import draggable from 'vuedraggable'
export default {
  components: {
    VJstree,
    draggable
  },
  data () {
    return {
      treeData: [],
      selectedId: null,
      myArray: [
        // {
        //   icon: 'fa fa-file-o',
        //   text: 'One',
        //   description: 'This is file 1'
        // },
        // {
        //   icon: 'fa fa-file-o',
        //   text: 'Two',
        //   description: 'This is file 2'
        // },
        // {
        //   icon: 'fa fa-file-o',
        //   text: 'Three',
        //   description: 'This is file 3'
        // }
      ]
    }
  },
  methods: {
    loadFolderTree () {
      axios
        .get(`${process.env.VUE_APP_WORKFLOW_API_URL}folderStructure/folders`)
        .then(res => {
          console.dir(res.data.response)
          this.treeData = res.data.response
        })
        .catch(e => {
          this.$toastr.e(`Error loading folder structure.`)
        })
    },
    getFolderData (folder) {
      const postData = {
        folder
      }
      console.log(postData)
      axios
        .post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderContents`, postData)
        .then(res => {
          console.log('Response from FolderContents:')
          console.dir(res.data.response)
          this.myArray = res.data.response
        })
        .catch(e => {
          this.$toastr.e(`Error loading folder data.`)
        })
    },
    itemClick (node) {
      console.log(node.model.path + ' clicked !')
      this.getFolderData(node.model.path)
    },
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 bytes'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]}`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      // const ms = this.pad(myDate.getMilliseconds(), 3, '0')
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    }
  },
  created: function () {
    this.loadFolderTree()
  }
}
</script>

<style>
  .folder {
      color: #FFE89F;
      text-shadow: 1px 1px 1px #ccc;
  }
  .file {
      color: #eeeeee;
      text-shadow: 1px 1px 1px #ccc;
  }
</style>
