<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Privacy Policy</h2>
      </div>
      <div class="idb-block-content">
        <h4>Personal information</h4>
        <p>
          In general, you can visit the Paygate Solutions website without
          telling us who you are or revealing any personal information about
          yourself. If some parts of our website invite you to register and
          provide information about yourself, such as the support sections, the
          information that you supply will relate to the customer relationship
          that we have with you or your organisation. Your personal information
          will be retained only for as long as necessary to fulfil the purposes
          for which the information was collected or as required by law. If you
          give us personal information via the Internet, it is our intent to let
          you know how it will be used. We will use your personal information to
          provide the services and information you request. We may also use it
          to: Make the Paygate Solutions Website easier for you to use by not
          having to enter information more than once Help Paygate Solutions
          customise content most relevant to you Notify you about important news
          concerning Paygate Solutions, Jonas Software and/or its subsidiaries
          In the process of administering the Paygate Solutions website, we
          maintain and track usage through web server logs. Web server logs
          provide such information as what types of browsers are accessing our
          website, which pages receive high traffic and the times of day our
          servers experience significant load. We use this information to
          improve the content and navigation features of our website, but not to
          personally identify you.
        </p>
        <h4>Correcting/updating personal information</h4>
        <p>
          If a visitor's personal Information changes, we provide a way to
          correct, update or remove any personal information held on that
          visitor in our active databases. We may need to retain archive copies
          of that personal information where required for legal or audit
          purposes.
        </p>
        <h4>How Paygate Solutions shares it</h4>
        <p>
          Paygate Solutions will not sell, rent or lease your personal
          information to anyone else. We may share the personal data you provide
          with our professional advisors, sales force, tied intermediaries or
          website developers who are acting on our behalf for the uses described
          above, except as provided below (see Consent).
        </p>
        <h4>Cookies</h4>
        <p>
          We collect standard information about visits to our website through
          the collection of log files, Google Analytics and other similar lead
          trackers. These log files track user preferences, page visits, and
          browser and operating system usage. These logs and analytical systems
          do not attempt to collect any personal information that can identify a
          visitor. They are used to ensure our website is built to provide a
          superior browsing experience. Our website uses several cookies for
          tracking purposes:
        </p>
        <h5>Google Analytics</h5>
        <p>
          The Google Analytics cookies are used to help Google track usage of
          our website. We use this analytics information to compile data on key
          pages, key words in search terms and to gain a better understanding of
          the usage of our website. This cookie does not collect any personal
          information.
        </p>
        <h5>PECR cookie law</h5>
        <p>
          This cookie is left on your computer when you dismiss the cookie alert
          we show on our website. This allows us to ensure you are not reminded
          about cookies when you next visit our website.
        </p>
        <h4>Consent</h4>
        <p>
          By using the Paygate Solutions website, you consent to the computer
          storage and processing of your personal data by Paygate Solutions, our
          subsidiaries and professional advisors. The personally identifiable
          information you have provided will be used and shared as described
          above. We reserve the right to disclose any and all pertinent customer
          information to law enforcement or other government officials as, in
          our sole discretion, believe necessary or appropriate.
        </p>
        <h4>Security</h4>
        <p>
          We will take all reasonable precautions to protect our website
          visitors' information. When visitors submit information via the
          Paygate Solutions website, we try to ensure that information is
          protected both on and off-line. As a minimum we use encryption and
          other methods to protect sensitive information on-line but we may
          employ other methods or forms of security from time to time as well.
          Off-line, your information is protected by a number of strictly
          regulated processes and measures.
        </p>
        <h4>Third party links</h4>
        <p>
          Links to third party websites from the Paygate Solutions website are
          provided solely as a convenience to you. If you use these links, you
          will leave the Paygate Solutions website. Paygate Solutions has not
          reviewed these third-party websites and does not control them. We
          assume no responsibility for any of these websites, their content or
          their privacy policies. We do not endorse or make any representations
          about them or any information, software or other products or materials
          found there, or any results that may be obtained from using them. If
          you decide to access any of the third-party websites linked to the
          Paygate Solutions Website, you do so at your own risk.
        </p>
        <h4>Your acceptance of these terms</h4>
        <p>
          By using Paygate Solutions' website, you signify your assent to this
          Privacy Statement. If we update or change this Privacy Statement, the
          changes will be made on this page. Your continued use of the Paygate
          Solutions website following the posting of changes to this Privacy
          Statement will mean you accept those changes.
        </p>
        <h4>How to contact Paygate Solutions</h4>
        <p>
          Should you have questions or concerns about this Privacy Statement,
          please letter post or e-mail Paygate Solutions at:
        </p>
        <address>
          Paygate Solutions Ltd.
          Gladstone House,
          Hithercroft Road,
          Wallingford,
          Oxfordshire.
          OX10 9BT
          Tel: <a
            href="tel:+441462708444"
          >+44 (0) 1462 708 444</a>
          Email: <a href="emailto:support@paygate.uk">support@paygate.uk</a>
        </address>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
address {
  white-space: pre-line;
}
</style>
