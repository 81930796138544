<template>
  <div>
    <form @submit.prevent="saveUsers()" novalidate>
      <div class="idb-block">
        <div class="idb-block-title">
          <h2>Import Users</h2>
        </div>
        <div class="idb-block-content">
          <div v-if="showDropzone" class="row form-group">
            <div id="dzUpload" class="col-md-12">
              <vue-dropzone
                id="drop1"
                ref="fileUploader"
                v-bind:use-custom-dropzone-options="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="afterSuccess"
                v-on:vdropzone-file-added="fileAdded"
                v-on:vdropzone-sending="sending"
              ></vue-dropzone>
            </div>
          </div>
          <div v-if="errors.length > 0 && !showDropzone">
            <b-card :border-variant="errorVariant" :header="errorHeader" :header-bg-variant="errorVariant" header-text-variant="white">
              <div v-for="error in errors" v-bind:key="error">
                <div>{{ error }}</div>
              </div>
            </b-card>
            <br />
          </div>
          <div v-if="userCount > 0" class="row form-group">
            <div class="col-md-12">
              <vue-good-table
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-row-click="onRowClick"
                :columns="columns"
                :rows="users"
                :lineNumbers="true"
                :totalRows="userCount"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  dropdownAllowAll: false
                }"
                styleClass="vgt-table striped bordered"
              >
              </vue-good-table>
            </div>
          </div>
        </div>
        <div class="idb-block-footer">
          <button
            class="btn btn-primary"
            type="submit"
            v-if="canManageUsers"
            :disabled="preventSaveUsers"
          >Save Users</button>
          <button
            class="btn btn-outline-danger pull-right ml-3"
            type="button"
            @click="cancel"
          >Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/Assets/Components/Authentication/auth.js'
import axios from 'axios'
import roles from '@/Assets/Constants/roles'

export default {
  data: () => ({
    bearerToken: '',
    dropzoneOptions: {
      url: '',
      language: {
        dictDefaultMessage: 'Drop a file here or click to select a file to upload.'
      },
      maxNumberOfFiles: 1,
      maxFileSizeInMB: 200 // MBs
    },
    showDropzone: true,
    userCount: 0,
    users: [],
    columns: [
      {
        label: 'User Name',
        field: 'name',
        width: '25%'
      },
      {
        label: 'Email',
        field: 'email',
        width: '50%'
      },
      {
        label: 'Phone Number',
        field: 'phoneNumber',
        width: '25%'
      }
    ],
    errors: [],
    errorHeader: 'Imported User Errors',
    errorVariant: 'danger',
    licenceCountExceeded: false
  }),
  computed: {
    canManageUsers () {
      return this.$store.getters.isInRole(roles.ManageUsers)
    },
    preventSaveUsers () {
      return this.userCount === 0 || this.licenceCountExceeded
    }
  },
  methods: {
    // uploadUsersFromFile () {
    //   this.showDropzone = true
    // },
    fileAdded (file) {
      this.$refs.fileUploader.setOption('headers', { Authorization: this.bearerToken })
    },

    async afterSuccess (file, response) {
      this.showDropzone = false
      await this.importUsersFromFile(response.filename)
    },

    async sending (file, xhr, formData) {
      formData.append('paygateId', this.$store.state.common.paygateId)
    },

    async importUsersFromFile (filename) {
      // console.log('importUsersFromFile: ' + filename)
      try {
        var json = JSON.stringify({
          filename: filename
        })

        const response = await axios.post(
          `${process.env.VUE_APP_PLATFORM_API_URL}Users/importUsers`, json
        )

        this.users = response.data.users
        this.userCount = this.users.length

        if (response.data.errors.length > 0) {
          this.errors = response.data.errors
        }

        this.licenceCountExceeded = response.data.response.status === 'Failed'
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    async saveUsers () {
      // console.log('Save users')
      try {
        this.errors = []
        const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Users/createImportedUsers`, this.users)
        console.log('save response: ' + JSON.stringify(response.data))
        if (response.data.errors.length === 0) {
          if (response.data.response.status === 'Action') {
            this.$toastr.s(`${response.data.newUserCount} new user${response.data.newUserCount > 1 ? 's' : ''} need${response.data.newUserCount > 1 ? '' : 's'} to be approved`, 'Needs Approval')
          } else {
            this.$toastr.s(`${response.data.newUserCount} new user${response.data.newUserCount > 1 ? 's' : ''} created`, 'Created')
          }
          this.$router.push({ name: 'Users' })
        } else {
          this.$toastr.e('There were some errors...')
          this.errors = response.data.errors
        }
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },

    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
    },

    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
    },

    onSortChange (params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type
        }
      })
    },

    onRowClick (params) {
    },
    cancel () {
      this.$router.back()
    }
  },
  created () {
    this.dropzoneOptions.url = process.env.VUE_APP_PLATFORM_API_URL + 'Users/uploadFile'
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
      })
  }
}
</script>
