<template>
  <div>
    <div id="payerSetupForm">
      <div class="row mb-4">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="idb-block">
            <div class="idb-block-title">
              <h2><help-icon docPath="/paygate-collections/payers/managepayers/" />Manage Payers<favourite-icon></favourite-icon></h2>
            </div>
              <div class="idb-block-content">
              <b-card v-if="incompleteCount > 0 && !isLoading"
                        bg-variant="warning"
                        text-variant="white"
                        style="margin-bottom: 0.8em; margin-right: 0.8em"
                      >
                        <i class="fa fa-exclamation-triangle rpad"></i> You have {{incompleteCount}} incomplete Payers. <b-btn variant="warning ml-3" @click="statusFilter = 4; load()">View</b-btn>
                      </b-card>
              <div class="groupSelector">
                <div class="row form-group">
                  <div class="col-md-2 required">
                    Select a Group
                  </div>
                  <div class="col-md-4">
                    <group-select
                      v-model="group"
                      :groups="customerGroups"
                      @input="groupChanged"
                      :clearable="false"
                    ></group-select>
                    <p v-if="allGroups"><small>Payers can only be created once a group is selected.</small></p>
                  </div>
                  <b-form-group :label-cols="2"  class="col-sm-4" horizontal label="Status">
                    <b-form-select v-model="statusFilter" :options="statuses" required value-field="value" text-field="name" @change="load"/>
                  </b-form-group>
              </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <vue-good-table mode="remote"
                  ref="table"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  @on-row-click="onRowClick"
                  @on-cell-click="onCellClick"
                  :columns="columns"
                  :rows="inactivePayers"
                  :lineNumbers="true"
                  :totalRows="totalRecords"
                  :search-options="{
                    enabled: true
                    }"
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :rowStyleClass="rowToCss"
                  :isLoading.sync="isTableLoading"
                  styleClass="vgt-table striped bordered">
                  <template slot="loadingContent">
                    <h1>Loading...</h1>
                  </template>
                  <div slot="emptystate">
                      <div class="vgt-center-align vgt-text-disabled">
                        No Payers Available
                      </div>
                    </div>
                    <div slot="table-actions">
                      <button
                        @click.prevent="clearTableFilters"
                        class="btn btn-link"
                        v-b-popover.hover.top.d500="'Clear filters'"
                      >
                        <span class="fa-stack" style="font-size: 10px;">
                          <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                          <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                        </span><span class="sr-only">Clear filters</span>
                      </button>
                      <b-button @click.prevent="addNewPayer" class="" :disabled="allGroups || isLoading" variant="link" v-b-popover.hover.top.d500="allGroups ? 'A group must be selected to add a payer' : 'Create a new payer in the current group.'">
                        <i class="fa fa-plus pointer dimmedIcon"></i><span class="sr-only">Add Payer</span>
                      </b-button>
                      <b-button @click.prevent="load" class="" :disabled="isLoading" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the table'">
                        <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                      </b-button>
                    </div>
                     <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'status'">
                          <b-badge pill
                            :variant="getBadgeClass(props.row.status)"
                          >{{props.formattedRow[props.column.field]}}</b-badge>
                        </span>
                        <span v-else-if="props.column.field === 'buttons'">
                          <b-button v-if="props.row.plans.length === 1" @click="goToSchedule(props.row.ukPayerId, props.row.plans[0].planId)" variant="outline-secondary"><i class="fa fa-calendar mr-2"></i>View Schedule</b-button>
                          <b-dropdown v-if="props.row.plans.length > 1" variant="outline-secondary"> <!-- no-caret -->
                            <span slot="button-content"><i class="fa fa-calendar mr-2"></i>View Schedule</span>
                            <b-dropdown-item v-for="(plan, index) in props.row.plans" :key="index" @click="goToSchedule(props.row.ukPayerId, plan.planId)">{{plan.planName}}</b-dropdown-item>
                          </b-dropdown>
                        </span>
                        <span v-else>
                          {{props.formattedRow[props.column.field]}}
                        </span>
                     </template>
                  </vue-good-table>
                </div>
              </div>
              <div class="idb-block-footer">
                <b-btn id="addPayerButton" :disabled="allGroups || isLoading" type="link" variant="primary" @click="addNewPayer" v-b-popover.hover.top.d500="allGroups ? 'A group must be selected to add a payer' : 'Create a new payer in the current group.'">
                  <i class="fa fa-plus mr-2"></i>Create New Payer
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import EventBus from '@/Lib/eventBus'
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import axios from 'axios'

const statusToText = (x) => {
  switch (x) {
    case -1:
      return 'Any'
    case 0:
      return 'Operational'
    case 1:
      return 'Suspended'
    case 2:
      return 'Alert'
    case 3:
      return 'Closed'
    case 4:
      return 'Incomplete'
    case 5:
      return 'Awaiting Activation'
    default:
      return 'Unknown'
  }
}

const statusToBadgeVariant = (x) => {
  switch (x) {
    case 3:
      return 'secondary'
    case 2:
      return 'warning'
    case 1:
      return 'danger'
    case 0:
      return 'success'
    case 4:
    case 5:
      return 'info'
    default:
      return 'dark'
  }
}

export default {
  mixins: [tableFilterMixin, loading],
  name: 'payerSetup',
  async mounted () {
    const onPaygateChange = (paygateId) => {
      const paygateIdNotDefault = (paygateId && paygateId !== '00000000-0000-0000-0000-000000000000')
      if (paygateIdNotDefault) {
        this.$store.dispatch('loadCustomerGroups', paygateId)
      }
    }
    EventBus.$on('paygateIdChange', onPaygateChange)
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })
    if (this.$store.getters.customerGroups === null) {
      await this.$store.dispatch('loadCustomerGroups', this.paygateId)
      if (this.$store.getters.customerGroups !== null) {
        const initiallySelectedGroup = this.prepopulatedGroup ? this.prepopulatedGroup : '00000000-0000-0000-0000-000000000000'
        this.group = initiallySelectedGroup
        const table = this.$refs['table']
        const searchTerm = this.prepopulatedSearchTerm ? this.prepopulatedSearchTerm : ''
        table.globalSearchTerm = searchTerm
        table.$emit('on-search', {
          searchTerm: searchTerm
        })
      }
    } else {
      this.group = '00000000-0000-0000-0000-000000000000'
    }
    this.updateTotal = true
    this.load()
  },
  computed: {
    paygateId () {
      return this.$store.state.common.paygateId
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'All Payers', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
    inactivePayers () {
      return this.$store.state.payer.inactivePayers
    },
    group: {
      get () {
        return this.$store.state.payer.currentPayer.groupId
      },
      set (value) {
        if (value) {
          this.$store.commit('updateGroup', value)
        }
      }
    },
    allGroups () {
      return !this.group || this.group === undefined || this.group === null || this.group === '00000000-0000-0000-0000-000000000000'
    }
  },
  props: {
    prepopulatedGroup: String,
    prepopulatedSearchTerm: String,
    prepopulatedStatus: String
  },
  data () {
    return {
      statusFilter: parseInt(this.prepopulatedStatus, 10) ? parseInt(this.prepopulatedStatus, 10) : -1, // if fails to parse to int w/ radix 10 then default to value '3'
      statuses: [-1, 0, 1, 2, 3, 4, 5].map(i => ({ 'name': statusToText(i), 'value': i })), // Server side enum values
      selectedUkPayer: {},
      groupValid: false,
      ukPayerSelected: false,
      columns: [
        {
          label: 'id',
          field: 'ukPayerId',
          hidden: true
        },
        {
          label: 'Reference',
          field: 'reference'
        },
        {
          label: 'Group',
          field: 'groupName',
          sortable: false
        },
        {
          label: 'Payer Status',
          field: 'status',
          formatFn: statusToText,
          tdClass: 'text-center'
        },
        {
          label: 'First Name',
          field: 'firstName'
        },
        {
          label: 'Surname',
          field: 'surname'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Mobile Number',
          field: 'mobile'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      incompleteCount: 0,
      serverParams: {
        sort: [{ field: 'reference', type: 'asc' }],
      }
    }
  },
  methods: {
    formatValidationBool (row, col) {
      if (['payerDetailsValidated', 'bankAccountValidated', 'paymentPlansValidated'].indexOf(col.field) > -1) {
        return row[col.field] ? 'Complete' : 'More details required...'
      } else {
        return row[col.field]
      }
    },
    rowToCss (row) {
      if (row.isNew) {
        return 'new-item'
      } else {
        return ''
      }
    },
    addNewPayer () {
      this.$router.push({ name: 'createPayer', params: { groupid: this.group } })
    },
    confirmActivation (ukPayerId) {
      this.$store.dispatch('confirmActivation', { ukPayerId })
    },
    groupChanged () {
      this.updateParams({ page: 1 })
    },
    load: _.debounce(async function () {
      if (this.updateTotal) {
        document.querySelector('.footer__navigation__page-info').style.display = 'none'
      }
      this.incompleteCount = 0
      this.$set(this.columns[2], 'hidden', this.group !== '00000000-0000-0000-0000-000000000000')
      if (this.group !== 0) {
        // await this.$store.dispatch('getGroupConfigurationFromGroupId', { id: this.group })
        var query = this.buildGoodTableQuery()
        query.payerStatus = this.statusFilter === -1 ? null : this.statusFilter // if -1 ('Any'), send null to server
        query.groupId = this.group
        var response = await this.$store.dispatch('getAllPayersPaged', { serverParams: query })
        if (this.updateTotal) {
          this.totalRecords = response.count
          var totalResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payers/count`, { params: query })
          var incompleteResponse = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payers/incomplete`, { params: query })
          this.totalRecords = totalResponse.data
          this.incompleteCount = incompleteResponse.data
          document.querySelector('.footer__navigation__page-info').style.display = 'inline-block'
        }
        else {
          document.querySelector('.footer__navigation__page-info').style.display = 'inline-block'
        }
        this.updateTotal = false
      }
    }, 300),
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onRowClick (event) {
      // forces the table to do pointer cursor on row etc
    },
    onCellClick (event) {
      if (event.column.field !== 'buttons') {
        this.$store.dispatch('blankPayer')
        this.$router.push({ name: 'editPayer', params: { ukPayerId: event.row.ukPayerId } })
      }
    },
    dirtyGroup () {
      this.$v.group.$touch()
      this.groupValid = !this.$v.group.$invalid
    },
    goToSchedule (payerId, planId) {
      this.$router.push({ name: 'PayerSchedule', params: { id: payerId, planId: planId } })
    },
    getBadgeClass (value) {
      return statusToBadgeVariant(value)
    }
  },
  validations: {
    group: { required }
  }
}
</script>

<style>
@keyframes yellowfade {
    from { background: yellow }
    to { background: transparent; }
}

.new-item {
    animation-name: yellowfade;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
}

.groupSelector {
  margin-top: 1em;
  margin-left: 1em;
}
</style>
