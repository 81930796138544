<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/administration/customer/details/" />Customer Emails</h2>
    </div>

    <div class="idb-block-content">
      <vue-good-table
        mode="remote"
        :search-options="{
                    enabled: true
                    }"
        :sort-options="{
                enabled: true
              }"
        :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                  }"
        :totalRows="totalRecords"
        :columns="columns"
        :rows="emailAddresses"
        @on-row-click="onRowClick"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        styleClass="vgt-table striped bordered"
      >
        <div slot="table-actions">
          <b-button
            @click.prevent="loadEmails"
            class
            variant="link"
            title="Refresh Table"
            v-b-popover.hover.top.d500="'Refresh the data in the table'"
          >
            <i class="fa fa-redo pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
          </b-button>
          <b-button
            @click.prevent="exportTable"
            class
            variant="link"
            title="Export Table"
            v-b-popover.hover.top.d500="'Export the contents of the Emails table'"
          >
            <i class="fa fa-share-square pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
          </b-button>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'verified'">
            <i class="fa fa-fw" :class="props.row.verified ? 'fa-check' : 'fa-times'"></i>
          </span>
          <span v-else-if="props.column.field == 'buttons'">
            <b-btn
              :disabled="props.row['verified']"
              variant="primary"
              @click.stop="sendVerification(props.row['id'])"
            >Retry Verification</b-btn>&nbsp;
            <b-btn variant="danger" @click.stop="deleteEmail(props.row['id'])">
              <i class="fa fa-trash-alt"></i><span class="sr-only">Retry Verification</span>
            </b-btn>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
        <template slot="table-column" slot-scope="props">
             <span v-if="props.column.field =='buttons'">
                <span class="d-none">Commands</span>
             </span>
          <span v-else>
                {{props.column.label}}
             </span>
        </template>
      </vue-good-table>
    </div>
    <div class="idb-block-footer">
      <button class="btn btn-primary" @click.prevent="create">Add Email</button>
    </div>
    <b-modal
      id="emailAddressModal"
      ref="emailAddressModal"
      title="Email Address"
      size="lg"
      hide-header-close
      cancel-variant="secondary-outline"
      lazy
    >
      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label class="required" for="emailAddressInput">Email Address</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="emailAddressInput"
            type="email"
            v-model.trim="selectedEmailAddress.emailAddress"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="4" class="mt-2">
          <label for="fromNameInput">Email Sender Name</label>
        </b-col>
        <b-col sm="7">
          <b-form-input id="fromNameInput" type="text" v-model.trim="selectedEmailAddress.fromName"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>A verification email will be sent automatically when 'OK' is pressed.</b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.loadEmails()
    }
  },
  async mounted () {
    const handleModalHide = async (bvEvent, modalId) => {
      const target = bvEvent.target.id
      switch (target) {
        case 'emailAddressModal':
          if (bvEvent.trigger === 'ok') {
            const isNew = this.selectedEmailAddress.id === null
            if (isNew) {
              const emailAddress = await this.insertEmailAddressFromModal()
              this.emailAddresses.splice(0, 0, emailAddress)
            } else {
              const emailAddress = await this.updateEmailAddressFromModal()
              for (var i = 0; i < this.emailAddresses.length; i++) {
                if (this.emailAddresses[i].id === emailAddress.id) {
                  this.emailAddresses[i].emailAddress = emailAddress.emailAddress
                  this.emailAddresses[i].fromName = emailAddress.fromName
                  this.emailAddresses[i].verified = emailAddress.verified
                }
              }
            }
          }
          break
        default:
          break
      }
    }

    this.$root.$on('bv::modal::hide', handleModalHide)
    this.$once('hook:beforeDestroy', () => {
      this.$root.$off('bv::modal::hide', handleModalHide)
    })
    this.loadEmails()
  },
  data () {
    return {
      selectedEmailAddress: {
        emailAddress: '',
        id: null,
        fromName: '',
        verified: false
      },
      emailAddresses: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'emailAddress',
          type: 'ASC'
        },
        page: 1,
        perPage: 10
      },
      columns: [
        {
          hidden: true,
          field: 'id'
        },
        {
          label: 'Email Address',
          field: 'emailAddress'
        },
        {
          label: 'Sender Name',
          field: 'fromName'
        },
        {
          label: 'Verified',
          field: 'verified'
        },
        {
          label: '',
          field: 'buttons',
          sortable: false
        }]
    }
  },
  methods: {
    async create () {
      this.selectedEmailAddress = {
        emailAddress: '',
        id: null,
        fromName: '',
        verified: false
      }
      this.$refs.emailAddressModal.show()
    },
    async insertEmailAddressFromModal () {
      const url = process.env.VUE_APP_PLATFORM_API_URL + 'emails'

      try {
        var response = await axios.post(url, this.selectedEmailAddress)
        this.$toastr.s('Email Address added', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to add email address', 'An error occurred')
        return null
      }
    },
    async updateEmailAddressFromModal () {
      const url = `${process.env.VUE_APP_PLATFORM_API_URL}emails/${this.selectedEmailAddress.id}`
      try {
        var response = await axios.put(url, this.selectedEmailAddress)
        this.$toastr.s('Email address updated', 'Success')
        return response.data
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to update email address', 'An error occurred')
        return null
      }
    },
    sendVerification: _.debounce(async function (id) {
      const url = `${process.env.VUE_APP_PLATFORM_API_URL}emails/${id}/sendverification`
      try {
        var response = await axios.post(url)
        let verified = false
        for (var i = 0; i < this.emailAddresses.length; i++) {
          if (this.emailAddresses[i].id === response.data.id) {
            this.emailAddresses[i].verified = response.data.verified
            verified = response.data.verified
          }
        }
        if (!verified) {
          this.$toastr.s('A verification email has been sent.', 'Success')
        } else {
          this.$toastr.s('Verification has occurred.', 'Success')
        }
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to send verification email', 'An error occurred')
      }
    }, 300),
    deleteEmail: _.debounce(async function (id) {
      const url = `${process.env.VUE_APP_PLATFORM_API_URL}emails/${id}`
      try {
        var response = await axios.delete(url)
        console.log(response.data.id)
        this.emailAddresses = this.emailAddresses.filter(x => x.id !== response.data.id)
        this.$toastr.s('Email has been deleted', 'Success')
      } catch (e) {
        console.error(e)
        this.$toastr.e('Unable to delete email address', 'An error occurred')
      }
    }, 300),
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    exportTable () {
      console.error('exportTable not implemented yet')
    },
    printTable () {
      console.log('print table')
    },
    getQuery () {
      var query = {}
      query.sort = this.serverParams.sort.field + ':' + this.serverParams.sort.type
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      return query
    },
    loadEmails: _.debounce(async function () {
      const getEmailUrl = process.env.VUE_APP_PLATFORM_API_URL + 'emails'
      const query = this.getQuery()
      const paygateId = this.$store.state.common.paygateId
      if (paygateId) {
        query.paygateId = paygateId
        query.includeUnverified = true
      }
      const response = await axios.get(getEmailUrl, { params: query })
      this.emailAddresses = response.data.data
      this.totalRows = response.data.totalItems
    }, 300),
    onRowClick (event) {
      this.selectedEmailAddress = Object.assign({}, event.row)
      this.$refs.emailAddressModal.show()
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadEmails()
    },
    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.loadEmails()
    },
    onColumnFilter (params) {
      this.updateParams(params)
      this.loadEmails()
    },
    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadEmails()
    },
    onSearch (params) {
      this.serverParams.searchKey = params.searchTerm
      this.loadEmails()
    }
  },
  validations: {
    customer: {
      paygateId: { required, isGuid },
      name: { required, maxLen: maxLength(50) }
    }
  }
}

function isGuid (value) {
  var regex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$')
  var result = regex.test(value)
  return result
}

</script>
