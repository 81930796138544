var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "importingPayments" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-progress",
                    {
                      staticClass: "mb-2",
                      attrs: { striped: "", animated: true, max: _vm.max }
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          value: _vm.importProgress,
                          label: "Importing " + _vm.importProgress + "%"
                        }
                      }),
                      _c("b-progress-bar", {
                        attrs: {
                          value: _vm.processingProgress,
                          variant: "info",
                          label: "Processing " + _vm.processingProgress + "%"
                        }
                      }),
                      _c("b-progress-bar", {
                        attrs: {
                          value: _vm.validationProgress,
                          variant: "success",
                          label: "Validating " + _vm.validationProgress + "%"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row form-group" }, [
              _c("div", { staticClass: "col-md-12" }, [
                this.monitorStatus == "Compiling"
                  ? _c("div", [_vm._v("Gathering data to import")])
                  : this.monitorStatus == "Importing"
                  ? _c("div", [
                      _vm._v(
                        " Importing file " +
                          _vm._s(
                            _vm.fileImportedCount +
                              _vm.fileErrorCount +
                              (_vm.fileImportedCount + _vm.fileErrorCount ===
                              _vm.totalFiles
                                ? 0
                                : 1)
                          ) +
                          " of " +
                          _vm._s(_vm.totalFiles) +
                          ": " +
                          _vm._s(_vm.currentFilename) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.fileErrorCount) +
                          " files cannot be imported "
                      )
                    ])
                  : this.monitorStatus == "Imported"
                  ? _c("div", [
                      _vm._v(
                        " Processing file " +
                          _vm._s(
                            _vm.fileCompletedCount +
                              _vm.fileErrorCount +
                              (_vm.fileCompletedCount + _vm.fileErrorCount ===
                              _vm.totalFiles
                                ? 0
                                : 1)
                          ) +
                          " of " +
                          _vm._s(_vm.totalFiles) +
                          ": " +
                          _vm._s(_vm.currentFilename) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.fileErrorCount) +
                          " files cannot be imported "
                      )
                    ])
                  : this.monitorStatus == "Validating"
                  ? _c("div", [
                      _vm._v(
                        " Validating submission " +
                          _vm._s(_vm.fileValidationPercent) +
                          "% "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.fileErrorCount) +
                          " files cannot be imported "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Importing submission files, please wait...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }