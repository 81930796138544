var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top mt-4" }, [
      _c("div", { staticClass: "container-fluid clearfix" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.topButtons
              ? _c(
                  "div",
                  { staticClass: "col-sm" },
                  [
                    _vm.topButtons
                      ? [
                          _vm.currentStep.index > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "pull-left",
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.isLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.backStep()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-angle-left mr-2"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._l(_vm.steps, function(step, index) {
              return [
                _c(
                  "div",
                  {
                    key: index,
                    staticClass: "col-sm",
                    class: ["step", _vm.isStepActive(index, step)],
                    style: { width: 100 / _vm.steps.length + "%" }
                  },
                  [
                    _c("div", { staticClass: "circle" }, [
                      _c("i", {
                        class: step.completed ? "fas fa-check" : step.icon
                      })
                    ]),
                    _c("div", { staticClass: "step-title mt-3" }, [
                      _c("h4", [_vm._v(_vm._s(step.title))]),
                      _c("h5", { staticClass: "step-subtitle" }, [
                        _vm._v(_vm._s(step.subtitle))
                      ])
                    ])
                  ]
                )
              ]
            }),
            _vm.topButtons
              ? _c(
                  "div",
                  { staticClass: "col-sm" },
                  [
                    _vm.topButtons
                      ? _c(
                          "b-button",
                          {
                            staticClass: "pull-right",
                            attrs: {
                              variant: "primary",
                              disabled: _vm.isLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.nextStep()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-angle-right ml-2" })]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c(
          "transition",
          {
            attrs: {
              "enter-active-class": _vm.enterAnimation,
              "leave-active-class": _vm.leaveAnimation,
              mode: "out-in"
            }
          },
          [
            _vm.keepAliveData
              ? _c(
                  "keep-alive",
                  [
                    _c(_vm.steps[_vm.currentStep.index].component, {
                      tag: "component",
                      attrs: {
                        clickedNext: _vm.nextButton[_vm.currentStep.name],
                        "current-step": _vm.currentStep
                      },
                      on: {
                        "can-continue": _vm.proceed,
                        "change-next": _vm.changeNextBtnValue
                      }
                    })
                  ],
                  1
                )
              : _c(_vm.steps[_vm.currentStep.index].component, {
                  tag: "component",
                  attrs: {
                    clickedNext: _vm.nextButton[_vm.currentStep.name],
                    "current-step": _vm.currentStep
                  },
                  on: {
                    "can-continue": _vm.proceed,
                    "change-next": _vm.changeNextBtnValue
                  }
                })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "idb-block-footer noprint-footer clearfix mt-4" },
      [
        _vm.currentStep.index === 0
          ? _c(
              "b-button",
              {
                staticClass: "pull-left",
                attrs: { variant: "danger", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    return _vm.cancel()
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-times mr-2" }),
                _c("span", [_vm._v(_vm._s("Cancel"))])
              ]
            )
          : _vm._e(),
        _vm.currentStep.index > 0
          ? _c(
              "b-button",
              {
                staticClass: "pull-left",
                attrs: { variant: "primary", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    return _vm.backStep()
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-angle-left mr-2" }),
                _c("span", [_vm._v(_vm._s("Back"))])
              ]
            )
          : _vm._e(),
        _c(
          "b-button",
          {
            staticClass: "pull-left ml-2",
            attrs: {
              variant: "primary",
              disabled: !_vm.canContinue || _vm.isLoading
            },
            on: {
              click: function($event) {
                return _vm.nextStep()
              }
            }
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.finalStep ? "Finish" : "Next"))]),
            _c("i", { staticClass: "fas fa-angle-right ml-2" })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }