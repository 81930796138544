var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-5",
              attrs: {
                "label-cols": 5,
                "label-class": "required",
                horizontal: "",
                label: "Plan Type"
              }
            },
            [
              _c(
                "b-form-select",
                {
                  staticClass: "form-control",
                  attrs: {
                    id: "selectPlanInput",
                    type: "text",
                    placeholder: "New plan name...",
                    options: _vm.planNames,
                    "text-field": "name",
                    "value-field": "name",
                    disabled: _vm.outOfPlanNames
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addNewPaymentPlan($event)
                    },
                    change: _vm.addNewPaymentPlan
                  },
                  model: {
                    value: _vm.selectedPlanName,
                    callback: function($$v) {
                      _vm.selectedPlanName = $$v
                    },
                    expression: "selectedPlanName"
                  }
                },
                [
                  _c("template", { slot: "first" }, [
                    _c(
                      "option",
                      { attrs: { disabled: "" }, domProps: { value: null } },
                      [_vm._v("-- Please select an option --")]
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.selectedPaymentPlan !== null && _vm.selectedPaymentPlan !== undefined
        ? _c("payment-plan-input", {
            ref: "planControl",
            attrs: {
              plan: _vm.selectedPaymentPlan,
              createMode: true,
              "label-cols": 5,
              "column-class": "col-sm-5",
              limitFirstPaymentDate: true
            },
            on: { input: _vm.setValid, isinvalid: _vm.setValidity }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "float-right pb-1 mr-2" },
        [
          _c("label", { attrs: { for: "addAnotherCheckBox" } }, [
            _vm._v("Add another Payer on completion")
          ]),
          _c("p-check", {
            staticClass: "p-switch p-fill",
            attrs: {
              id: "addAnotherCheckBox",
              labelId: "addAnotherCheckBox",
              color: "primary"
            },
            model: {
              value: _vm.addAnother,
              callback: function($$v) {
                _vm.addAnother = $$v
              },
              expression: "addAnother"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }