var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.users.length !== 0
      ? _c("div", { staticClass: "form-group row" }, [
          _c("table", { staticClass: "table offset-1" }, [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.users, function(user) {
                return _c("tr", { key: user.name }, [
                  _c("td", [_vm._v(_vm._s(user.email))]),
                  _c("td", [
                    _vm._v(_vm._s(user.roles == "0" ? "None" : user.roles))
                  ]),
                  _c("td", [
                    _vm._v(" " + _vm._s(user.uploadType) + " "),
                    user.uploadType == "Secure Cloud Folder" ||
                    user.uploadType == "Restricted File Browser"
                      ? _c("span", [
                          _c("br"),
                          _vm._v(
                            " File Upload Directory: " +
                              _vm._s(user.uploadPath) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    user.uploadType == "Restricted File Browser"
                      ? _c(
                          "span",
                          [
                            _c("br"),
                            _vm._v("User Cannot Browse Sub-folders: "),
                            _c("tick", {
                              attrs: { coloured: false },
                              model: {
                                value: user.directoryLocked,
                                callback: function($$v) {
                                  _vm.$set(user, "directoryLocked", $$v)
                                },
                                expression: "user.directoryLocked"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              }),
              0
            )
          ])
        ])
      : _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-3 offset-1 col-form-label" }, [
            _vm._v("Users Assigned to Group")
          ]),
          _vm._m(1)
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", width: "25.5%" } }, [
          _vm._v("Users Assigned to Group")
        ]),
        _c("th", { attrs: { scope: "col", width: "35%" } }, [
          _vm._v("User's Roles within the Group")
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Upload Type")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "form-control-plaintext" }, [
        _c("span", [_vm._v("No Users")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }