<template>
  <div id="bureauSummary">
    <table role="table" class="table table-striped pt-0 mt-0">
      <thead>
        <tr><th role="columnheader"><span class="sr-only">Unit</span></th><th role="columnheader">Items</th><th role="columnheader">Total</th></tr>
      </thead>
      <tbody>
        <tr><td role="rowheader"><strong>Credits</strong></td><td>{{ formatCount(BureauSummaryData.totalCreditCount) }}</td><td>£{{ formatValue(BureauSummaryData.totalCreditValue) }}</td></tr>
        <tr><td role="rowheader"><strong>Debits</strong></td><td>{{ formatCount(BureauSummaryData.totalDebitCount) }}</td><td>£{{ formatValue(BureauSummaryData.totalDebitValue) }}</td></tr>
        <tr><td role="rowheader"><strong>DDIs</strong></td><td>{{ formatCount(BureauSummaryData.totalDDICount) }}</td><td><span class="sr-only">N/A</span></td></tr>
        <tr><td role="rowheader"><strong>Contras</strong></td><td>{{ formatCount(BureauSummaryData.contraCount) }}</td><td><span class="sr-only">N/A</span></td></tr>
        <tr><td role="rowheader"><strong>Total Blocks</strong></td><td>{{ formatCount(BureauSummaryData.totalFiles) }}</td><td><span class="sr-only">N/A</span></td></tr>
      </tbody>
    </table>
    <div v-if="actionId !== undefined && false" class="row form-group mt-2">
      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-outline-secondary"
          v-on:click="downloadReport"
          :disabled="isLoading"
        >
          <span class="far fa-arrow-alt-circle-down" style="padding-right: 5px;"></span>Download Merged Submission Report
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
  mixins: [
    bacsMixin,
    loading
  ],
  data () {
    return {
      submissionId: '',
      BureauSummaryData: {},
      actionId: ''
    }
  },
  mounted () {
    this.submissionId = this.$store.getters.submissionId
    this.BureauSummaryData = this.$store.getters.bureauSummaryData
    this.actionId = this.$store.getters.actionId
  },
  async created () {
    await this.load()
  },
  methods: {
    async load () {

    },
    async downloadReport () {
      try {
        var response = await axios({
          url: `${process.env.VUE_APP_BACS_API_URL}bacs/report/createBacsSummaryReportMerged`,
          method: 'GET',
          responseType: 'blob', // important
          showload: true,
          params: {
            submissionId: this.submissionId,
            actionId: this.actionId,
            fileNumber: this.fileNumber
          }
        })
        this.saveFile(response)
      } catch (ex) {
        console.log(JSON.stringify(ex))
      }
    }
  }

}
</script>
