// Bureau Customer
import licence from '@/Assets/Constants/licence'

const BureauCustomer = () =>
  import(/* webpackChunkName: "bureau-customer" */ '@/Components/Bureau/BureauCustomer/BureauCustomer.vue')
const ImportBureauCustomer = () =>
  import(/* webpackChunkName: "bureau-customer" */ '@/Components/Bureau/BureauCustomer/Import.vue')
const ImportBureauCustomerVerify = () =>
  import(/* webpackChunkName: "bureau-customer" */ '@/Components/Bureau/BureauCustomer/ImportBureauCustomerVerify.vue')
const ImportBureauCustomerEdit = () =>
  import(/* webpackChunkName: "bureau-customer" */ '@/Components/Bureau/BureauCustomer/ImportBureauCustomerEdit.vue')
const ListBureauCustomers = () =>
  import(/* webpackChunkName: "bureau-customer" */ '@/Components/Bureau/BureauCustomer/ListBureauCustomers.vue')
// Bureau Job
const ManageBureauJobs = () => import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJobsAdministration/ManageBureauJobs.vue')
const ManageJob = () => import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJobsAdministration/ManageJob.vue')
const CreateJob = () => import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJobsAdministration/CreateBureauJob.vue')

const JobSubmissionDetails = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/JobSubmissionDetails.vue')
const UploadPaymentFiles = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/UploadFiles.vue')
const LinkSubmissionFiles = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/LinkSubmissionFiles.vue')
const ImportValidateProgress = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/ImportValidateProgress.vue')
const PendingSubmissions = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/PendingSubmissions.vue')
const PaymentDetails = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/PaymentDetails.vue')

const PaymentDetailsFPS = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/PaymentDetailsFPS.vue')

const PreSubmissionValidation = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/PreSubmissionValidation.vue')
const BacsBureauReports = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauReports/BacsBureauReports.vue')
const BureauCustomerReport = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauReports/BureauCustomerReport.vue')
const BureauSubmissionReport = () => import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauReports/BureauSubmissionReport.vue')
const BureauAutomatedBACS = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/AutomatedBACSSubmission.vue')
const AutomatedBACSDetailSelection = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/AutomatedBACSDetailSelection.vue')

const BureauAutomatedFPS = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/AutomatedFPSSubmission.vue')
const AutomatedFPSDetailSelection = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/AutomatedFPSDetailSelection.vue')

const ImportPaymentFiles = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/ImportPaymentFiles.vue')
const ImportedPaymentFilesSummary = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/ImportedPaymentFilesSummary.vue')

const ManualBacsSubmission = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualBacsSubmission.vue')
const ManualBacsSubmissionEdit = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualBacsSubmissionEdit.vue')
const ManualBacsSubmissionAddFiles = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualBacsSubmissionAddFiles.vue')
const ManualBacsImportPaymentFiles = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualBacsImportPaymentFiles.vue')

const ManualFPSSubmission = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualFPSSubmission.vue')
const ManualFPSSubmissionEdit = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualFPSSubmissionEdit.vue')
const ManualFPSSubmissionAddFiles = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualFPSSubmissionAddFiles.vue')
const ManualFPSImportPaymentFiles = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauBuilder/ManualFPSImportPaymentFiles.vue')

const UploadAdditionalFilesBacs = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/UploadAdditionalFilesBacs.vue')
const UploadAdditionalFilesFPS = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/UploadAdditionalFilesFPS.vue')

const UploadAdditionalFilesBacsImport = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/UploadAdditionalFilesBacsImport.vue')

const SubmissionsInProgress = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauJob/SubmissionsInProgress.vue')

const BureauUsageOverDateRange = () =>
  import(/* webpackChunkName: "bureau-jobs" */ '@/Components/Bureau/BureauUsageReports/UsageOverDateRange.vue')

const BureauTheSceneryChannel = () =>
  import(/* webpackChunkName: "bureau-scenerychannel" */ '@/Components/Bureau/BureauSceneryChannel/thescenerychannelworkflow.vue')

const LicensingTableTest = () =>
  import(/* webpackChunkName: "bureau-scenerychannel" */ '@/Components/Bureau/BureauSceneryChannel/licensingtabletest.vue')

export default [
  {
    name: 'BureauCustomerCreate',
    path: 'bureau/bureauCustomers/create',
    component: BureauCustomer,
    meta: {
      licence: [licence.bureau],
      auth: ['ManageBureauCustomers'],
      title: 'Create Bureau Customer'
    }
  },
  {
    name: 'BureauCustomerDetails',
    path: 'bureau/bureauCustomers/details/:id',
    component: BureauCustomer,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['ViewBureauCustomers', 'ManageBureauCustomers'],
      title: ' Bureau Customer Details'
    }
  },
  {
    name: 'ListBureauCustomers',
    path: 'bureau/bureauCustomers/list',
    component: ListBureauCustomers,
    meta: {
      licence: [licence.bureau],
      auth: ['ViewBureauCustomers', 'ManageBureauCustomers'],
      title: 'List Bureau Customers'
    }
  },
  {
    name: 'BureauAutomatedBACS',
    path: 'bureau/bureauJob/automatedbacssubmission',
    component: BureauAutomatedBACS,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Automated Bureau BACS Submission'
    }
  },
  {
    name: 'AutomatedBACSDetailSelection',
    path: 'bureau/bureauJob/automatedbacsdetailselection/:id',
    component: AutomatedBACSDetailSelection,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Automated Bureau BACS Submission'
    }
  },
  {
    name: 'BureauAutomatedFPS',
    path: 'bureau/bureauJob/automatedfpssubmission',
    component: BureauAutomatedFPS,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Automated Bureau FPS Submission'
    }
  },
  {
    name: 'AutomatedFPSDetailSelection',
    path: 'bureau/bureauJob/automatedfpsdetailselection/:id',
    component: AutomatedFPSDetailSelection,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Automated Bureau FPS Submission'
    }
  },
  {
    name: 'ImportPaymentFiles',
    path: 'bureau/bureauJob/importpaymentfiles/:id/:startProcess',
    component: ImportPaymentFiles,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Import Automated Bureau BACS Submission Files'
    }
  },
  {
    name: 'ImportedPaymentFilesSummary',
    path: 'bureau/bureauJob/importedpaymentfilessummary/:id',
    component: ImportedPaymentFilesSummary,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Summary of Imported Submission Files for Automated Bureau Payment'
    }
  },

  {
    name: 'ManualBacsSubmission',
    path: 'bureau/bureauBuilder/manualbacssubmission/',
    component: ManualBacsSubmission,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Manual BACS Bureau Builder'
    }
  },
  {
    name: 'ManualBacsSubmissionEdit',
    path: 'bureau/bureauBuilder/manualbacssubmissionedit/:id',
    component: ManualBacsSubmissionEdit,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Manual BACS Bureau Builder'
    }
  },
  {
    name: 'ManualBacsSubmissionAddFiles',
    path: 'bureau/bureauBuilder/manualbacssubmissionaddfiles/:id',
    component: ManualBacsSubmissionAddFiles,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Add Files to Manual BACS Bureau Builder'
    }
  },

  {
    name: 'ManualBacsImportPaymentFiles',
    path: 'bureau/bureauJob/manualbacsimportpaymentfiles/:id/:startProcess',
    component: ManualBacsImportPaymentFiles,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Import Manual Bureau BACS Submission Files'
    }
  },

  {
    name: 'ManualFPSSubmission',
    path: 'bureau/bureauBuilder/manualfpssubmission/',
    component: ManualFPSSubmission,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Manual FPS Bureau Builder'
    }
  },
  {
    name: 'ManualFPSSubmissionEdit',
    path: 'bureau/bureauBuilder/manualfpssubmissionedit/:id',
    component: ManualFPSSubmissionEdit,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Manual FPS Bureau Builder'
    }
  },
  {
    name: 'ManualFPSSubmissionAddFiles',
    path: 'bureau/bureauBuilder/manualfpssubmissionaddfiles/:id',
    component: ManualFPSSubmissionAddFiles,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Add Files to Manual FPS Bureau Builder'
    }
  },

  {
    name: 'ManualFPSImportPaymentFiles',
    path: 'bureau/bureauJob/manualFPSimportpaymentfiles/:id/:startProcess',
    component: ManualFPSImportPaymentFiles,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Import Manual Bureau FPS Submission Files'
    }
  },

  {
    name: 'ImportBureauCustomers',
    path: 'bureau/bureauCustomers/import',
    component: ImportBureauCustomer,
    meta: {
      licence: [licence.bureau],
      auth: ['ManageBureauCustomerImport'],
      title: 'Import Bureau Customers'
    }
  },
  {
    name: 'ImportBureauCustomerVerify',
    path: 'bureau/bureauCustomers/import/verify/:id',
    component: ImportBureauCustomerVerify,
    meta: {
      licence: [licence.bureau],
      auth: ['ManageBureauCustomerImport'],
      title: 'Verify Imported Bureau Customers'
    }
  },
  {
    name: 'ImportBureauCustomerEdit',
    path: 'bureau/bureauCustomers/import/edit/:id',
    component: ImportBureauCustomerEdit,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['ManageBureauCustomerImport'],
      title: 'Edit Imported Bureau Customer'
    }
  },
  {
    name: 'CreateJob',
    path: 'bureau/bureaujobs/create',
    component: CreateJob,
    meta: {
      licence: [licence.bureau],
      auth: ['ManageBureauJobs'],
      title: 'Create Bureau Job',
      mode: 'create'
    }
  },
  {
    name: 'ManageJob',
    path: 'bureau/bureaujobs/manage/:id',
    component: ManageJob,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['ManageBureauJobs', 'ViewBureauJobs'],
      title: 'Manage Bureau Jobs',
      mode: 'manage'
    }
  },
  {
    name: 'ManageBureauJobs',
    path: 'bureau/bureaujobs/managebureaujobs',
    component: ManageBureauJobs,
    meta: {
      licence: [licence.bureau],
      title: 'Manage Bureau Jobs',
      auth: ['ManageBureauJobs', 'ViewBureauJobs'],
      target: 'managebureaujobs'
    }
  },

  // {
  //   name: 'ListJobs',
  //   path: 'bureau/bureaujobs/list',
  //   component: ListJobs,
  //   meta: {
  //     licence: [licence.bureau],
  //     title: 'Manage Bureau Jobs',
  //     target: 'managejob'
  //   }
  // },
  // {
  //   name: 'StartJob',
  //   path: 'bureau/bureaujobs/start',
  //   component: ListJobs,
  //   meta: {
  //     licence: [licence.bureau],
  //     title: 'Start Submission',
  //     target: 'runjob'
  //   }
  // },
  {
    name: 'PendingSubmissions',
    path: 'bureau/bureaujobs/pending',
    component: PendingSubmissions,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Pending Submissions'
    }
  },
  {
    name: 'JobSubmissionDetails',
    path: 'bureau/bureaujobs/jobsubmissiondetails',
    component: JobSubmissionDetails,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Job Details'
    }
  },
  {
    name: 'UploadPaymentFiles',
    path: 'bureau/bureaujobs/uploadpaymentfiles:id',
    component: UploadPaymentFiles,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Upload Payment Files'
    }
  },
  {
    name: 'UploadAdditionalFilesBacs',
    path: 'bureau/bureaujobs/UploadAdditionalFilesBacs/:id',
    component: UploadAdditionalFilesBacs,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Upload Payment Files'
    }
  },
  {
    name: 'UploadAdditionalFilesFPS',
    path: 'bureau/bureaujobs/UploadAdditionalFilesFPS/:id',
    component: UploadAdditionalFilesFPS,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Upload Payment Files'
    }
  },
  {
    name: 'UploadAdditionalFilesBacsImport',
    path: 'bureau/bureauJob/importadditionalpaymentfiles/:id/:startProcess',
    component: UploadAdditionalFilesBacsImport,
    props: true,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Import Additional Bureau BACS Submission Files'
    }
  },

  {
    name: 'LinkSubmissionFiles',
    path: 'bureau/bureaujobs/linksubmissionfiles',
    component: LinkSubmissionFiles,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Link files for submission'
    }
  },
  {
    name: 'SubmissionsInProgress',
    path: 'bureau/bureaujobs/submissionsinprogress',
    component: SubmissionsInProgress,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Submissions In Progress'
    }
  },

  {
    name: 'ImportValidateProgress',
    path: 'bureau/bureaujobs/importvalidateprogress:id',
    component: ImportValidateProgress,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Status of Import/Validate'
    }
  },
  {
    name: 'PaymentDetails',
    path: 'bureau/bureaujobs/paymentDetails/:id',
    component: PaymentDetails,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'BACS Submssion Details'
    }
  },

  {
    name: 'PaymentDetailsFPS',
    path: 'bureau/bureaujobs/paymentdetailsfps/:id',
    component: PaymentDetailsFPS,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Payment Details'
    }
  },

  {
    name: 'PreSubmissionValidation',
    path: 'bureau/bureaujobs/preSubmissionValidation/:id',
    component: PreSubmissionValidation,
    meta: {
      licence: [licence.bureau],
      auth: ['SubmitBureauJobs'],
      title: 'Pre-Submission Validation'
    }
  },
  {
    name: 'BacsBureauReports',
    path: 'bureau/bureaureports/bacsbureaureports',
    component: BacsBureauReports,
    meta: {
      licence: [licence.bureau],
      auth: ['ViewBureauBacsReports'],
      title: 'Bacs Bureau Reports'
    }
  },
  {
    name: 'BureauCustomerReport',
    path: 'bureau/bureaureports/bureaucustomerreport',
    component: BureauCustomerReport,
    meta: {
      licence: [licence.bureau],
      auth: ['ViewBureauCustomReports'],
      title: 'Bureau Customer Report'
    }
  },
  {
    name: 'BureauUsageOverDateRange',
    path: 'bureau/bureauusagereports/usageoverdaterange',
    component: BureauUsageOverDateRange,
    meta: {
      licence: [licence.bureau],
      auth: ['ViewBureauCustomReports'],
      title: 'Bureau Usage Over Date Range'
    }
  },

  {
    name: 'BureauSubmissionReport',
    path: 'bureau/bureaureports/bureausubmissionreport/:id',
    props: true,
    component: BureauSubmissionReport,
    meta: {
      licence: [licence.bureau],
      auth: ['ViewBureauCustomReports'],
      title: 'Bureau Submission Report'
    }
  }
  // Debug use only
  // {
  //   name: 'BureauTheSceneryChannel',
  //   path: 'bureau/bureauscenerychannel/thescenerychannelworkflow',
  //   component: BureauTheSceneryChannel,
  //   meta: {
  //     licence: [licence.bureau],
  //     title: 'Bureau Scenery Channel'
  //   }
  // }

]
