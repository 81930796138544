var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: { docPath: "/paygate-collections/payers/editpayer/" }
          }),
          _vm._v("Submission Cancelled at BACS")
        ],
        1
      )
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "idb-block-footer noprint-footer" },
      [
        _vm.actionId && _vm.actionId !== null
          ? _c(
              "b-button",
              {
                attrs: { variant: "primary", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.removeAction($event)
                  }
                }
              },
              [_vm._v("Clear Action")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-content" }, [
      _c("p", [
        _vm._v(
          "A submission has been cancelled as the result of a BACS Withdrawal Report, it can be resubmitted."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }