var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "pull-right mr-2" }, [
    _c(
      "a",
      {
        attrs: {
          href: "#",
          title: "Add " + _vm.currentRouteName + " to favourites"
        },
        on: { click: _vm.toggle }
      },
      [
        _c("i", {
          staticClass: "fa-star star",
          class: _vm.active ? "fas" : "far"
        }),
        _c("span", { staticClass: "d-none" }, [
          _vm._v("Add " + _vm._s(_vm.currentRouteName) + " to favourites")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }