var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-1" }, [_vm._v("Path")]),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFolder,
                          expression: "selectedFolder"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.selectedFolder },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.selectedFolder = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("br"),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                    [
                      _c("div", { staticClass: "idb-block" }, [
                        _c("div", { staticClass: "idb-block-content" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-5" },
                              [
                                _c("v-jstree", {
                                  attrs: {
                                    data: _vm.treeData,
                                    multiple: "",
                                    "allow-batch": "",
                                    "whole-row": ""
                                  },
                                  on: { "item-click": _vm.itemClick }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "draggable",
                                  {
                                    attrs: { options: { group: "people" } },
                                    on: {
                                      start: function($event) {
                                        _vm.drag = true
                                      },
                                      end: function($event) {
                                        _vm.drag = false
                                      }
                                    },
                                    model: {
                                      value: _vm.myArray,
                                      callback: function($$v) {
                                        _vm.myArray = $$v
                                      },
                                      expression: "myArray"
                                    }
                                  },
                                  _vm._l(_vm.myArray, function(element, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.selectItem(element)
                                          },
                                          dblclick: function($event) {
                                            return _vm.selectItemAndClose(
                                              element
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "list-group-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-start flex-column mr-20"
                                                  },
                                                  [
                                                    element.isFolder
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-folder fa-4x folder"
                                                        })
                                                      : _c("i", {
                                                          staticClass:
                                                            "fa fa-file fa-4x file"
                                                        })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-start flex-column pt-10"
                                                  },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass: "font-lg"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(element.text)
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            element.description
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    element.isFolder
                                                      ? _c("div", {
                                                          staticClass:
                                                            "small fw-bold"
                                                        })
                                                      : _vm._e(),
                                                    !element.isFolder
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "small fw-bold"
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Size:")
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.bytesToSize(
                                                                    element.stat
                                                                      .size
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !element.isFolder
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "small fw-bold"
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                "Last Modified:"
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.reformatDate(
                                                                    element.stat
                                                                      .mtime
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm._t("footer", [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "warning" },
                    on: { click: _vm.cancelPopup }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success" },
                    on: { click: _vm.closePopup }
                  },
                  [
                    _c("span", {
                      staticClass: "fa fa-times",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" OK ")
                  ]
                )
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }