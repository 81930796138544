<template>
<div class="row pb-16">
  <div class="col-md-12">
    <div v-for="(label, index) in labels" :key = "index" class="form-check form-check-inline">
      <label class = "form-check-label">
        <input class = "form-check-input" type = "radio"
          :name = "name"
          :value = label
          :checked = "label == value"
          @input = "$emit('input',$event.target.value)">
        {{label}}
      </label>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'RadioButton',
  props: [
    'labels',
    'name',
    'selectedValue',
    'value'
  ]
}
</script>
