var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("hr"),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Transfer Type")]),
          _c("br"),
          _c(
            "p-radio",
            {
              attrs: { value: "Upload", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s7.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s7, "value", $$v)
                },
                expression: "selectedNode.props.s7.value"
              }
            },
            [_vm._v("Upload")]
          ),
          _c(
            "p-radio",
            {
              attrs: { value: "Download", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s7.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s7, "value", $$v)
                },
                expression: "selectedNode.props.s7.value"
              }
            },
            [_vm._v("Download")]
          )
        ],
        1
      ),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [
          _vm._v("Host FTP Server Address")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s1.value,
              expression: "selectedNode.props.s1.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s1.value.$error },
          attrs: { type: "url" },
          domProps: { value: _vm.selectedNode.props.s1.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s1,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s1.value.$touch()
              }
            ]
          }
        }),
        !_vm.$v.selectedNode.props.s1.value.required &&
        _vm.$v.selectedNode.props.s1.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("The Server Address field is required!")
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.s1.value.maxLength
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Maximum length is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.s1.value.$params.maxLength.max
                  ) +
                  " characters!"
              )
            ])
          : _vm._e()
      ]),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("Port")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.int1.value,
              expression: "selectedNode.props.int1.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.int1.value.$error },
          attrs: { type: "number" },
          domProps: { value: _vm.selectedNode.props.int1.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.int1,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.int1.value.$touch()
              }
            ]
          }
        }),
        !_vm.$v.selectedNode.props.int1.value.required &&
        _vm.$v.selectedNode.props.int1.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("The Port field is required!")
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.int1.value.minValue
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Minimum Port value is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.int1.value.$params.minValue.min
                  ) +
                  "!"
              )
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.int1.value.maxValue
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Maximum Port value is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.int1.value.$params.maxValue.max
                  ) +
                  "!"
              )
            ])
          : _vm._e()
      ]),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "p-check",
            {
              staticClass: "p-switch",
              attrs: { name: "check", color: "primary" },
              model: {
                value: _vm.selectedNode.props.b2.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.b2, "value", $$v)
                },
                expression: "selectedNode.props.b2.value"
              }
            },
            [_vm._v("Use FTPS (TLS)")]
          )
        ],
        1
      ),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", {}, [_vm._v("Local folder")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s3.value,
              expression: "selectedNode.props.s3.value"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s3.value },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.selectedNode.props.s3, "value", $event.target.value)
            }
          }
        })
      ]),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("File")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s4.value,
              expression: "selectedNode.props.s4.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s4.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s4.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s4,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s4.value.$touch()
              }
            ]
          }
        }),
        !_vm.$v.selectedNode.props.s4.value.required &&
        _vm.$v.selectedNode.props.s4.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("The File field is required!")
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.s4.value.maxLength
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Maximum length is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.s4.value.$params.maxLength.max
                  ) +
                  " characters!"
              )
            ])
          : _vm._e()
      ]),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", {}, [_vm._v("Remote folder")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s2.value,
              expression: "selectedNode.props.s2.value"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s2.value },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.selectedNode.props.s2, "value", $event.target.value)
            }
          }
        })
      ]),
      _c("br"),
      _c("div", { staticClass: "font-weight-bold" }, [
        _vm._v("Authentication")
      ]),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "p-check",
            {
              staticClass: "p-switch",
              attrs: { name: "check", color: "primary" },
              model: {
                value: _vm.selectedNode.props.b1.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.b1, "value", $$v)
                },
                expression: "selectedNode.props.b1.value"
              }
            },
            [_vm._v("Anonymous")]
          )
        ],
        1
      ),
      _c("br"),
      _c("transition", { attrs: { name: "fadein" } }, [
        !_vm.selectedNode.props.b1.value
          ? _c("div", [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "required" }, [_vm._v("Username")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedNode.props.s5.value,
                      expression: "selectedNode.props.s5.value"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.selectedNode.props.s5.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.selectedNode.props.s5,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("br")
              ]),
              _vm.selectedNode.props.s8.value === "Password"
                ? _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "required" }, [
                      _vm._v("Password")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedNode.props.pw1.value,
                          expression: "selectedNode.props.pw1.value"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "password" },
                      domProps: { value: _vm.selectedNode.props.pw1.value },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedNode.props.pw1,
                            "value",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("br")
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("FTP / FTPS File Transfer"),
      _c("span", { staticClass: "pull-right" }, [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("i", { staticClass: "far fa-question-circle" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }