<template>
  <div id="viewSubmissionSummary">
    <div style="width: 98%; margin:0 auto;">
      <p v-html="submissionSummary"></p>
    </div>
    <div class="idb-block-footer">
      <div class="row form-group">
        <div class="col-md-2">
          <button type="button" class="btn btn-primary" v-on:click="downloadXml">Download XML</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsMixin from '@/Lib/BacsMixin.js'
import supportLinkMixin from '@/Assets/Mixins/SupportLinkMixin'

export default {
  mixins: [
    bacsMixin,
    supportLinkMixin
  ],
  data () {
    return {
      submissionSummary: '',
      submissionId: ''
    }
  },

  methods: {
    async getSubmissionSummary () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/submission/getSubmissionSummary?submissionId=` + this.submissionId)
      this.submissionSummary = response.data
      if (this.submissionSummary.includes('Your submission has been aborted at your request')) {
        this.$snapbar.w('User aborted sending the submission while the payment data was being sent.')
      } else if (this.submissionSummary.includes('rejected')) {
        this.showSupportLinkSnapbar('Your submission was rejected.')
      }
    },

    async downloadXml () {
      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/getSubmissionSummaryXml?submissionId=' + this.submissionId
      var response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
        showload: true
      })

      this.saveFile(response)
    }
  },

  async mounted () {
    this.submissionId = this.$store.getters.submissionId
    if (this.submissionId === undefined || this.submissionId === null || this.submissionId === '') {
      this.submissionId = this.$route.query.submissionId
    }
    await this.getSubmissionSummary()
  }
}
</script>
