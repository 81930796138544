var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        ref: "table",
        attrs: {
          mode: "remote",
          columns: _vm.columns,
          rows: _vm.rows,
          totalRows: _vm.totalRecords,
          isLoading: _vm.isTableLoading,
          "search-options": {
            enabled: true
          },
          "pagination-options": {
            enabled: true,
            perPage: 10,
            dropdownAllowAll: false
          },
          styleClass: "vgt-table striped bordered"
        },
        on: {
          "on-row-click": _vm.viewHistory,
          "on-page-change": _vm.onPageChange,
          "on-sort-change": _vm.onSortChange,
          "on-column-filter": _vm.onColumnFilter,
          "on-per-page-change": _vm.onPerPageChange,
          "on-search": _vm.onSearch,
          "update:isLoading": function($event) {
            _vm.isTableLoading = $event
          },
          "update:is-loading": function($event) {
            _vm.isTableLoading = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }