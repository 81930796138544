import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  created () {
    window.addEventListener('beforeunload', (e) => {
      try {
        if (this.$v.$anyDirty && !this.$store.getters.forceLogout) {
          e.preventDefault()
          e.returnValue = ''
        }
      } catch (e) {
      }
    })
  },
  async beforeRouteLeave (to, from, next) {
    try {
      if (this.$v.$anyDirty && to.name !== 'Help') {
        const result = await this.leaving()
        if (result.isConfirmed) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    } catch (e) {
    }
  },
  methods: {
    async leaving () {
      var result = await swal.fire({
        title: 'Navigation',
        text: 'Data has not been saved. Continue without saving?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      return result
    }
  }
}
