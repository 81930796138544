<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-content">
            <div class="alert alert-danger">
              <h4 class="alert-heading">
                <i class="fa fa-exclamation-triangle mr-2"></i>
                Action Not Found
              </h4>
              <hr />
              <p>
                An action could not be found with that ID and may have already been actioned.
                <br />Please check the action list to ensure the action still exists.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionNotFound',
  created () {
    this.$snapbar.e('Action Not Found')
  }

}
</script>

<style>
</style>
