import WebSocketsManager from './Store'

export default {
  install (Vue, connection, opts = {}) {
    let observer = null
    observer = new WebSocketsManager(connection, opts)
    Vue.prototype.$socket = observer.WebSocket

    Vue.mixin({
      created () {
        Object.seal(this.$options.sockets)
      }
    })
  }
}
