import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (value, format) {
  if (value) {
    if (!format) {
      format = 'DD/MM/YYYY'
    }
    return moment(String(value)).format(format)
  }
})
