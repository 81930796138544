<template>
  <div>
    <div v-if="widget.w == 1">
      <h5 class="unpad">Workflow Monitor <span><b-button @click.prevent="getMonitorData" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the heatmap'" ><i class="fas fa-sync-alt pointer"></i></b-button></span></h5>
      <div class="row bottomPadding">
        <div class="col-md-12">
          <span class="small"><strong>{{workflow.title}} (</strong></span>
          <router-link tag="a" :to="'/automation/workflow/workflowdetail/' + workflowId" class="small">View</router-link>
          <span>)</span>
          <br>
        </div>
      </div>
      <div class="" v-for="(item, index) in this.monitorData" :key="index">
          <span class="small">{{niceDateTime(item.startTime)}} </span>
        <span class="padleft">
          <router-link v-if="item.currentStatus == 'Failed'" tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-danger">{{item.currentStatus}}</router-link>
          <router-link v-else-if="item.currentStatus == 'Execution'" tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-success">{{item.currentStatus}}</router-link>
          <router-link v-else-if="item.currentStatus == 'Success'" tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-success">{{item.currentStatus}}</router-link>
          <router-link v-else tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-secondary">{{item.currentStatus}}</router-link>
        </span>
      </div>
    </div>
    <div v-if="widget.w > 1">
      <h5 class="unpad">Workflow Monitor
        <span class="small"><strong>{{workflow.title}} (</strong></span>
          <router-link tag="a" :to="'/automation/workflow/workflowdetail/' + workflowId" class="small">View</router-link>
          <span>)</span>
        <span><b-button @click.prevent="getMonitorData" class="" variant="link" v-b-popover.hover.top.d500="'Refresh the data in the heatmap'" ><i class="fas fa-sync-alt pointer"></i></b-button></span></h5>
        <div class="row">
          <div class="col-md-4 underline" >Start Time</div>
          <div class="col-md-4 underline">Last Update</div>
          <div class="col-md-4 underline">Current Status</div>
        </div>
        <div class="row" v-for="(item, index) in this.monitorData" :key="index">
          <div class="col-md-4">
            <div class="small">{{niceDateTime(item.startTime)}}</div>
          </div>
          <div class="col-md-4">
            <div class="small">{{niceTime(item.startTime)}}</div>
          </div>
          <div class="col-md-4">
            <router-link v-if="item.currentStatus == 'Failed'" tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-danger">{{item.currentStatus}}</router-link>
            <router-link v-else-if="item.currentStatus == 'Execution'" tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-success">{{item.currentStatus}}</router-link>
            <router-link v-else-if="item.currentStatus == 'Complete'" tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-success">{{item.currentStatus}}</router-link>
            <router-link v-else tag="a" :to="'/automation/workflow/workflowtelemetry/' + item.executionId" class="small badge badge-secondary">{{item.currentStatus}}</router-link>
          </div>
        </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'

export default {
  name: 'WorkflowHeatmapWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        this.getWorkflow()
        this.getMonitorData()
      },
      deep: true
    }
  },
  data () {
    return {
      workflow: {},
      monitorData: [],
      intervalId: null,
      settings: {
        maxScrollbarLength: 60
      }
    }
  },
  mounted () {
    this.getWorkflow()
    this.getMonitorData()
    this.intervalId = setInterval(this.getMonitorData, 30000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.intervalId)
    })
  },
  computed: {
    workflowId () {
      return this.widget.options.find(o => o.label === 'Workflow').value
    }
  },
  methods: {
    getMonitorData () {
      axios
        .get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowmonitordata/${this.workflowId}/${this.widget.h}`)
        .then(res => {
          this.monitorData = res.data
        })
        .catch(e => {
          console.log('error: ' + e.message)
        })
    },
    getWorkflow () {
      axios
        .get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflow/${this.workflowId}`)
        .then(res => {
          this.workflow = res.data.workflow
        })
        .catch(e => {
          console.log('error: ' + e.message)
        })
    },
    niceDateTime (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1 // January is 0!
      const yyyy = myDate.getYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + '  ' + h + ':' + m + ':' + s
    },
    niceTime (d) {
      const myDate = new Date(d)
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    }
  }
}
</script>

<style lang="scss" scoped>
  .bottomPadding {
    padding-bottom: 8px;
  }
  .unpad {
    margin-top: -24px;
  }
  .underline {
    border-bottom: 1px;
    border-bottom-style: solid;
    margin-bottom: 8px;
    border-bottom-color: #cccccc;
  }
  .padleft {
    padding-left: 14px;
  }
</style>
