var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("DDMS Collections Dashboard")
      ]),
      _c("information-control", {
        attrs: {
          title: "Pending Submissions",
          messageCount: 1,
          linkText: "Pending Submissions",
          linkUrl: "http://www.google.com"
        }
      }),
      _c("information-control", {
        attrs: {
          title: "New Actions!",
          messageCount: 1,
          linkText: "View Actions List",
          linkUrl: "http://www.google.com"
        }
      }),
      _c("information-control", {
        attrs: {
          title: "New Alerts!",
          messageCount: 1,
          linkText: "View Alerts List",
          linkUrl: "http://www.google.com"
        }
      }),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.pingServer()
            }
          }
        },
        [_vm._v("Test")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }