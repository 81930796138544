<template>
  <div id="manualBacsSubmissionBuilder">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Manual Bureau Submission Builder
              <help-icon :docPath="'/bacsbureau/builder/'"></help-icon>
              <favourite-icon></favourite-icon>
            </h2>
          </div>

          <!-- Group Selection -->
          <div class="idb-block-content">
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label class="required" for="groupSelect">Select a Submission Group</label>
              </div>
              <div class="col-sm-6">
                <group-select id="groupSelect"
                  v-model="SelectedGroupId"
                  :groups="Groups"
                  :clearable="false"
                  :change="ChangeGroup(SelectedGroupId)"
                ></group-select>
              </div>
              <div class="col-md-3" offset-sm="3" v-if="$v.SelectedGroupId.$dirty">
                <label
                  class="text-danger small"
                  v-if="!$v.SelectedGroupId.required"
                >A 'Submission Group' is required</label>
              </div>
            </div>

            <!-- Submission Detail Selection -->
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label class="required" for="submissionReference">Submission Reference</label>
              </div>
              <div class="col-md-6">
                <b-form-input id="submissionReference" type="text" v-model.trim="$v.submissionReference.$model"></b-form-input>
              </div>
              <div class="col-md-3" offset-sm="3" v-if="$v.submissionReference.$dirty">
                <label id="submissionReferenceRequired"
                  class="text-danger small"
                  v-if="!$v.submissionReference.required"
                >A 'Submission Reference' is required</label>
                <label
                  class="text-danger small" id="submissionReferenceLength"
                  v-if="!$v.submissionReference.maxLength"
                >A 'Submission Reference' must be less than {{$v.submissionReference.$params.maxLength.max}} characters</label>
              </div>
            </div>
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label for="contraNarrative">Contra Narrative</label>
              </div>
              <div class="col-md-6">
                <b-form-input id="contraNarrative" type="text" v-model.trim="$v.contraNarrative.$model"></b-form-input>
              </div>
              <div class="col-md-3" offset-sm="3" v-if="$v.contraNarrative.$dirty">
                <label
                  class="text-danger small" id="contraNarrativeBad"
                  v-if="!$v.contraNarrative.maxLength"
                >A 'Contra Narrative' must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
              </div>
            </div>
            <div class="row form-group col-md-12" v-if="!isFps">
              <div class="col-md-2">
                <label for="paymentDate">Payment Date</label>
              </div>
              <div class="col-md-3">
                <vuejsDatepicker
                  ref="paymentDate"
                  name="paymentDate"
                  id="paymentDate"
                  v-model="paymentDate"
                  format="dd/MM/yyyy"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  :disabledDates="disabledDates"
                  v-on:selected="paymentDateChanged"
                  @focusin.native="dateOnfocus"
                  :monday-first="true"
                  :disabled="disableDatePicker"
                ></vuejsDatepicker><small>This is a default date for the submission that can be overridden for individual blocks.</small>
              </div>

              <div class="col-md-2">
                <label for="processingDate">Processing Date</label>
              </div>
              <div id="processingDate" class="col-md-2">{{ processingDate }}</div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button id="createSubmissionButton"
              class="btn btn-primary mr-2"
              :disabled="$v.$invalid"
              @click="onCreateClick"
            >Create Submission</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import { required, maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
export default {
  mixins: [Utility, tableFilterMixin, bacsMixin, loading],

  data () {
    return {
      SelectedGroupId: '',
      Groups: [],
      GroupDisplay: [],
      BUREAUGROUPTYPE: '1',
      paygateId: '',
      submissionReference: '',
      contraNarrative: '',
      processingDate: '',
      paymentDate: '',
      disabledDates: {},
      processingDates: {},
      disableDatePicker: false
    }
  },

  async created () {
    this.disabledDates = await this.setUpBACSDatePicker()
    this.paymentDate = await this.getNextPaymentDate()
    await this.load()
    await this.GetBureauLicenseCheck()
  },
  async mounted () {
    var dateElement = document.getElementById('paymentDate')
    dateElement.style.backgroundColor = 'white'

    this.$store.dispatch('getProcessingDates')
      .then(response => {
        this.processingDates = this.$store.getters.processingDates
        this.paymentDate = new Date(this.processingDates.minPaymentDate)
        this.processingDate = this.processingDates.nextProcessingDate

        // Disable weekends in datepicker.
        this.disabledDates.days = [6, 0]
        // Disable any dates before the earliest possible payment date.
        this.disabledDates.to = this.paymentDate
        // Disable any dates more than 31 days into the future.
        var maxDate = new Date(this.processingDates.maxPaymentDate)
        this.disabledDates.from = maxDate
        // Disable any other non-processing days i.e bank holidays.
        if (this.processingDates.nonProcessingDates.length > 0) {
          var npds = []
          for (var i = 0; i < this.processingDates.nonProcessingDates.length; i++) {
            npds.push(new Date(this.processingDates.nonProcessingDates[i]))
          }
          this.disabledDates.dates = npds
        }
      }
      )
  },
  methods: {
    load: _.debounce(async function () {
      this.paygateId = this.$store.state.common.paygateId
      await this.loadGroups()
    }, 800),
    async loadSubmission () {
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/getmanualbacsbuildersubmission/' + this.id)
        this.SelectedGroupId = response.data.groupId
        this.submissionReference = response.data.submissionReference
        this.contraNarrative = response.data.contraNarrative
        this.paymentDate = response.data.paymentDate
      } catch (e) {
      }
    },

    async loadGroups () {
      const getGroupsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.paygateId}/1/`, { params: { rights: ['Create'] } })
      var arr = []
      for(var i = 0; i < getGroupsResponse.data.length; i++){
        arr.push(getGroupsResponse.data[i])
      }
      try {
        const getFpsGroupsResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.paygateId}/6/`, {params: {rights: ['Create']}})
        for (var i = 0; i < getFpsGroupsResponse.data.length; i++) {
          getFpsGroupsResponse.data[i].name = `${getFpsGroupsResponse.data[i].name} (Faster Payments)`
          arr.push(getFpsGroupsResponse.data[i])
        }
      } catch (e) {
        console.log('Could not load FPS groups')
      }
      arr.sort(function (a, b) { return a.name.localeCompare(b.name) })
      this.Groups = arr
    },

    ChangeGroup (SelectedGroupId) {      // Needed?

    },

    async onCreateClick () {
      try {
        var params = {
          groupId: this.SelectedGroupId,
          submissionReference: this.submissionReference,
          contraNarrative: this.contraNarrative,
          processingDate: this.processingDate
        }

        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/createmanualbacsbuildersubmission', params, { showload: true })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          var bureauSubmissionId = response.data.id
          this.$store.dispatch('updateBureauTopSeverityLevel')
          this.$store.dispatch('updateBureauSubmissionPill')
          this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: bureauSubmissionId } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
        this.$toastr.e('Failed to create manual bacs submission', 'Bureau Submission')
      } finally {
      }
    },

    paymentDateChanged (value) {
      value = moment(value).format('DD/MM/YYYY')
      this.$store.dispatch('getNextProcessingDate', value)
        .then(response => {
          this.processingDate = this.$store.getters.nextProcessingDate
        })
    }
  },
  computed: {
    groupSelected () {
      return this.SelectedGroupId !== undefined && this.SelectedGroupId !== null && this.SelectedGroupId !== ''
    },
    isFps () {
      return this.Groups.filter(x=> { return x.groupId === this.SelectedGroupId && x.groupType === 6 }).length > 0
    }
  },
  validations: {
    submissionReference: {
      required,
      maxLength: maxLength(150)
    },
    contraNarrative: {
      maxLength: maxLength(50)
    },
    SelectedGroupId: {
      required
    }
  }
}

</script>

<style>
.vdp-datepicker input {
  background-color: unset;
}
</style>
