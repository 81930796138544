<template>
  <div style="visibility: visible; height: 100px; margin: 10px 0; text-align:center">
    <small>This report was generated by paygate on {{new Date().toLocaleString()}}</small>
  </div>
</template>

<script>
export default {
  name: 'ReportingFooter'
}
</script>

<style lang="scss" scoped>
</style>
