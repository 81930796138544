<template>
  <div id="downloadReports">
    <object id="PayGatePlugin" classid="clsid:8C1E006B-FDF1-4AEF-9B8A-83D8004C2E8B" hidden="hidden"></object>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              <help-icon docPath="/paygate-collections/reconciliation/" />
              Synchronise {{ this.networkName }} Data
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div>
              <p>Manually synchronise your Direct Debit records with {{ this.networkName }} Advices. Once the login button has been pressed, you will be prompted to press a button to sign a login response.</p>
              <p>Once logged in, DDMS will synchronise with {{ this.networkName }} to update your records, and generate alerts if necessary.</p>
            </div>
            <div class="row form-group mb-3">
              <div class="col-md-2 required">Select a Group</div>
              <div class="col-md-4">
                <group-select
                  v-model="selectedGroupId"
                  :groups="customerGroups"
                  @input="selectGroup()"
                  :clearable="false"
                ></group-select>
              </div>
            </div>
            <div v-if="isLoggedIn === false" class="row form-group">
              <div class="col-md-1">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="loginStart"
                  :disabled="this.enableLogin === false || isLoading"
                >Login</button>
              </div>
              <div class="col-md-1">
                <span style="font-size: 200%" class="fa fa-unlock"></span>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-light"
                  v-on:click="viewVocalinkCertificate"
                  :disabled="this.enableLogin === false || isLoading"
                >Vocalink Certificate</button>
              </div>
            </div>
            <div v-else>
              <div class="row form-group">
                <div class="col-md-1">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="logoff"
                    :disabled="this.loggingIn === true || isLoading"
                  >Log Off</button>
                </div>
                <div class="col-md-3">
                  <span style="font-size: 200%" class="fa fa-lock"></span>&nbsp;&nbsp;Securely logged into Vocalink
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-light"
                    v-on:click="viewVocalinkCertificate"
                    :disabled="this.loggingIn === true || isLoading"
                  >Vocalink Certificate</button>
                </div>
              </div>
              <br />
              <!-- <div class="row form-group">
                <div class="col-md-2">
                  <button type="button" class="btn btn-primary" v-on:click="getReportList" :disabled="this.enableGetReportList === false">Get Report List</button>
                </div>
              </div>-->
              <div v-if="haveReportList === true" class="row form-group">
                <div class="col-md-12">
                  <vue-good-table
                    ref="reportList"
                    @on-cell-click="viewReportModal"
                    :columns="columns"
                    :rows="rows"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionInfoClass: 'report-row-selected',
                      clearSelectionText: ''
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage: 10,
                      dropdownAllowAll: false
                    }"
                    styleClass="vgt-table striped bordered"
                  >
                    <template slot="loadingContent">
                      <h1>Loading...</h1>
                    </template>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'viewReportXml'">
                        <span class="grid-link">XML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportHtml'">
                        <span class="grid-link">HTML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportCsv'">
                        <span class="grid-link">CSV</span>
                      </span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                    <div slot="selected-row-actions" style="width: 800px;">
                      <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-3">Download Format</div>
                        <div class="col-md-2">
                          <b-select
                            v-model.trim="selectedDownloadFormat"
                            :options="downloadFormats"
                          />
                        </div>
                        <div class="col-md-3">
                          <button
                            type="button"
                            class="btn btn-primary pull-right"
                            v-on:click="downloadReports"
                            :disabled="this.selectedDownloadFormat === '' || isLoading"
                          >Download</button>
                        </div>
                      </div>
                    </div>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showPluginLink">
      <a v-bind:href="downloadPluginUrl">A new version of the Paygate plugin is required.</a>
    </div>
    <div id="eSigner" v-show="showeSigner">
      <span v-html="eSignerHtml"></span>
    </div>
    <div>
      <viewReportModal
        v-model="isModalVisible"
        :reportData="reportContent"
        :reportFormat="selectedReportFormat"
        @close="closeViewReport"
      ></viewReportModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import ViewReportModal from '@/Views/Collections/Reconciliation/ViewReportModal.vue'
import auth from '@/Assets/Components/Authentication/auth.js'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import loading from '@/Assets/Mixins/LoadingMixin'
export default {
  mixins: [loading],
  data () {
    return {
      submissionId: '',
      networkType: '',
      networkName: '',
      isLoaded: false,
      authResponse: '',
      isLoggedIn: false,
      loggingIn: false,
      loading: false,
      selectedGroup: '',
      selectedGroupValue: '',
      selectedGroupText: 'Please Select',
      groups: [],
      simpleGroups: [],
      selectedGroupId: '',
      bacsGroup: {},
      base64Signature: '',
      submissionTypes: {},
      getTaskProgressUrl: '',
      endTaskUrl: '',
      vocalinkCertificate: {},
      showPluginLink: false,
      downloadPluginUrl: '',
      eSignerHtml: '',
      showeSigner: false,
      reportTypes: {},
      reportPeriods: {},
      reportAccessed: {},
      downloadFormats: {},
      selectedReportType: '',
      selectedReportPeriod: '',
      selectedReportAccessed: '',
      selectedDownloadFormat: '',
      selectedRetrievalUrl: '',
      selectedReportFormat: 0,
      haveReportList: false,
      isModalVisible: false,
      reportContent: '',
      reportDownload: {},
      columns: [
        {
          label: 'Report Name',
          field: 'reportName'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Generated',
          field: 'formattedGenerationTimestamp'
        },
        {
          label: 'Processing Date',
          field: 'formattedProcessingDay'
        },
        {
          label: 'Prev Accessed',
          field: 'previouslyAccessed'
        },
        {
          label: 'Retrieval Url',
          field: 'retrievalUrl',
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportXml'
        },
        {
          label: 'View Report',
          field: 'viewReportHtml'
        },
        {
          label: 'View Report',
          field: 'viewReportCsv'
        }
      ],
      rows: [],
      reconciliationHubUrl: '',
      userPin: '',
      reconciliationConnection: null
    }
  },
  created () {
    window.addEventListener('beforeunload', this.stopSocketListener)
    this.reconciliationHubUrl = process.env.VUE_APP_DDMS_API_URL + 'hubs/reconciliation?paygateid=' + this.paygateId
    this.$store.dispatch('loadCustomerGroups', this.paygateId)
  },
  computed: {
    enableLogin: function () {
      return this.isLoaded === true && this.selectedGroupId !== '' && this.loggingIn === false
    },

    enableGetReportList: function () {
      return this.selectedReportType !== '' && this.selectedReportPeriod !== '' && this.selectedReportAccessed !== '' && this.loading === false
    },

    paygateId () {
      return this.$store.state.common.paygateId
    },
    customerGroups () {
      return this.$store.getters.customerGroups || []
    }
  },

  methods: {
    async getEnums () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/report/getDropdownValues', { params: { paygateid: this.paygateId, networkType: this.networkType, groupId: this.selectedGroupId }, showload: true })
      this.reportTypes = response.data.reportTypeLookups
      this.reportPeriods = response.data.periodLookups
      this.reportAccessed = response.data.accessedLookups
      this.downloadFormats = response.data.downloadFormatLookups
    },

    selectGroup () {
      console.log('selectGroup - selectedGroupId: ' + this.selectedGroupId)
      this.$store.dispatch('getSubBacsGroup', this.selectedGroupId)
      this.loggingIn = false
    },

    async loginStart () {
      this.isLoggedIn = false
      this.loggingIn = true
      this.haveReportList = false
      this.rows = []

      await this.getVocalinkLoginChallenge()

      if (this.authResponse.success === true) {
        // Sign either with HSM or smartcard depending on the submission group.
        this.bacsGroup = this.$store.getters.bacsGroup
        if (this.bacsGroup.groupTypeDetails === undefined) {
          this.$toastr.e('Error retrieving the BACS details for this group')
          this.loggingIn = false
        } else {
          if (this.bacsGroup.groupTypeDetails.tokenType === 'Hsm') {
            this.signChallengeWithHsm()
          } else {
            this.browser = bacsCommon.getBrowser()
            if (this.browser === 'IE') {
              this.plugin = document.PayGatePlugin

              try {
                var pluginVersion = this.plugin.GetVersion()
                if (pluginVersion !== undefined) {
                  var componentVersions = pluginVersion.split('.')
                  var cardComponent = 2
                  var cardVersion = componentVersions[cardComponent]
                  var expectedCardVersion = '3'
                  if (cardVersion === expectedCardVersion) {
                    this.usePlugin()
                  } else {
                    this.showPluginLink = true
                  }
                }
              } catch (ex) {
                this.useeSigner()
              }
            } else {
              bacsCommon.smartcardBrowserWarning(this)
              this.loggingIn = false
            }
          }
        }
      } else {
        this.loggingIn = false
      }
    },

    async getVocalinkLoginChallenge () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/comms/getVocalinkLoginChallenge', { params: { networkType: this.networkType, groupId: this.selectedGroupId }, showload: true })
      this.authResponse = response.data
      // console.log('auth response: ' + JSON.stringify(this.authResponse))
      if (this.authResponse.success === true) {
        this.vocalinkCertificate = this.authResponse.vocalinkCertificate
        // this.haveVocalinkChallenge = true
      } else {
        this.$toastr.e(this.authResponse.errorMessage)
      }
    },

    async showVocalinkChallenge () {
      await this.$swal({
        title: 'Vocalink Login Challenge',
        html: bacsCommon.formatVocalinkLoginChallenge(this.authResponse.loginChallenge),
        type: 'info',
        animation: false
      })
    },

    async callHsm () {
      console.log('callHsm')
      var json = JSON.stringify({
        certificateThumbprint: this.bacsGroup.groupTypeDetails.certificate.thumbprint,
        loginChallenge: this.authResponse.loginChallenge,
        isPaygateOnline: false,
        hsmPinRequired: this.bacsGroup.groupTypeDetails.hsmPinRequired,
        userPin: this.userPin
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWithHsm',
        data: json,
        showload: true
      })
      return response.data
    },

    async signChallengeWithHsm () {
      console.log('signChallengeWithHsm')
      await this.showVocalinkChallenge()
      var signResponse = await this.callHsm()

      if (signResponse.success === true) {
        this.base64Signature = signResponse.tag
        await this.vocalinkLogin()

        if (this.authResponse.success === true) {
          this.isLoggedIn = true
          this.getReportList()
        } else {
          this.$toastr.e(this.authResponse.errorMessage)
        }
      } else {
        this.$toastr.e(signResponse.errorMessage)
      }
    },

    async usePlugin () {
      console.log('usePlugin')
      this.plugin.InitSmartCardSigning()
      this.plugin.SubmissionLength = this.authResponse.loginChallenge.length
      this.plugin.SubmissionData = this.authResponse.loginChallenge
      this.base64Signature = this.plugin.SmartCardSignature(true, 'gclib.dll', '')
      if (this.base64Signature.indexOf('Error') === -1) {
        await this.vocalinkLogin()
        if (this.authResponse.success === true) {
          this.isLoggedIn = true
          this.getReportList()
        } else {
          this.loggingIn = false
          this.$toastr.e(this.authResponse.errorMessage)
        }
      } else {
        this.loggingIn = false
        this.$toastr.e(this.base64Signature)
      }
    },

    async useeSigner () {
      console.log('useeSigner')
      var cookies = this.authResponse.cookieCollection
      var domain = ''
      var name = ''
      var value = ''
      if (cookies.length > 0) {
        var cookie = cookies[0]
        domain = cookie.domain
        name = cookie.name
        value = cookie.value
      }

      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWitheSigner?submissionId=' + this.submissionId + '&actionId=' + this.actionId
      url += '&challenge=' + this.authResponse.loginChallenge
      url += '&cookieName=' + name
      url += '&cookieValue=' + value
      url += '&cookieDomain=' + domain
      url += '&networkType=' + this.networkType
      url += '&groupId=' + this.selectedGroupId

      var response = await axios.get(url, { showload: true })
      this.eSignerHtml = response.data
      this.showeSigner = true
    },

    async geteSignerSignature () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/comms/geteSignerSignature?submissionId=' + this.submissionId)
      this.base64Signature = response.data
      await this.vocalinkLogin()
      if (this.authResponse.success === true) {
        this.isLoggedIn = true
        this.getReportList()
      } else {
        this.$toastr.e(this.authResponse.errorMessage)
      }
    },

    async vocalinkLogin () {
      var json = JSON.stringify({
        networkType: this.networkType,
        base64Signature: this.base64Signature,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogin',
        data: json,
        showload: true
      })

      this.authResponse = response.data
      this.loggingIn = false
    },

    async logoff () {
      var json = JSON.stringify({
        networkType: this.networkType,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogoff',
        data: json
      })

      this.authResponse = response.data
      this.isLoggedIn = false
      this.loggingIn = false
      this.isLoaded = true
    },

    async getVocalinkCertificate () {
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/comms/getVocalinkCertificate?networkType=' + this.networkType, { showload: true })
      if (response.data.success === true) {
        this.vocalinkCertificate = response.data.vocalinkCertificate
      } else {
        this.$toastr.e(response.data.errorMessage)
      }

      return response.data.success
    },

    async viewVocalinkCertificate () {
      if (this.vocalinkCertificate.issuer === undefined) {
        var success = await this.getVocalinkCertificate()
        if (success === true) {
          this.$swal({
            title: 'Vocalink Certificate',
            html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
            type: 'info',
            animation: false
          })
        }
      } else {
        this.$swal({
          title: 'Vocalink Certificate',
          html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
          type: 'info',
          animation: false
        })
      }
    },

    async getReportList () {
      const typesArray = ['8000', '5000', '9000', '9001', '7000']
      var rowsOut = []
      for (var t = 0; t < typesArray.length; t++) {
        var json = JSON.stringify({
          networkType: this.networkType,
          serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
          reportType: typesArray[t],
          reportPeriod: 31,
          reportAccessed: true,
          authResponse: this.authResponse
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/getReportList',
          data: json
        })
        // axios({
        //   method: 'POST',
        //   url: '/reconciliation',
        //   data: json
        // })
        if (response.data.success) {
          rowsOut = [...rowsOut, ...response.data.availableReports.report]
        } else {
          this.$toastr.e(response.data.errorMessage)
        }
      }
      console.log(rowsOut)
      var reportUrls = []
      for (var i = 0; i < rowsOut.length; i++) {
        reportUrls.push(rowsOut[i].retrievalUrl)
      }
      axios({
        method: 'POST',
        url: process.env.VUE_APP_DDMS_API_URL + 'reconciliation',
        data: JSON.stringify({
          networkType: this.networkType,
          downloadUrls: reportUrls,
          downloadFormat: 0,
          authResponse: this.authResponse,
          groupId: this.selectedGroupId
        })
      })
      this.rows = rowsOut
      this.haveReportList = true

      if (this.haveReportList) {
        if (this.networkType === 'BacstelIp') {
          this.columns[8].hidden = true
        } else {
          this.columns[7].hidden = true
        }

        await this.saveBacsReportUserPreferences()
      }
    },

    async downloadReports () {
      console.log('downloadReports')
      var selectedReports = this.$refs.reportList.selectedRows
      var selectedReportUrls = []
      for (var i = 0; i < selectedReports.length; i++) {
        var tmpUrl = selectedReports[i].retrievalUrl
        console.log('retrieval url: ' + tmpUrl)
        selectedReportUrls.push(tmpUrl)
      }
      console.log('selected reports: ' + JSON.stringify(selectedReportUrls))

      var json = JSON.stringify({
        networkType: this.networkType,
        downloadUrls: selectedReportUrls,
        downloadFormat: this.selectedDownloadFormat,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/downloadReports',
        data: json,
        responseType: 'blob',
        showload: true
      })
      const blob = new Blob([response.data], { type: 'who/cares' })
      const downloadUrl = window.URL.createObjectURL(blob)
      let filename = ''
      const disposition = response.headers['content-disposition']
      console.log('disposition: ' + disposition)
      console.log('headers: ' + JSON.stringify(response.headers))

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // Works in IE and Edge.
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Uses HTML5 file attribute. Works in Chrome, Firefox and Edge.
        var a = document.createElement('a')
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      }
    },

    async viewReport (params) {
      console.log('params: ' + JSON.stringify(params))
      var cellClicked = params.column.field
      console.log('cellClicked: ' + cellClicked)
      if (cellClicked === 'viewReportHyperlink') {
        console.log('view report')

        this.reportDownload.authResponse = this.authResponse
        this.reportDownload.networkType = this.networkType
        this.reportDownload.downloadUrl = params.row.retrievalUrl
        // TODO: Get correct format.
        this.reportDownload.downloadFormat = 0

        this.$store.dispatch('setReportDownload', this.reportDownload)

        // var json = JSON.stringify({
        //   networkType: this.reportDownload.networkType,
        //   downloadUrl: this.reportDownload.downloadUrl,
        //   downloadFormat: this.reportDownload.downloadFormat,
        //   authResponse: this.reportDownload.authResponse
        // })

        // var response = await axios({
        //   method: 'POST',
        //   url: 'bacs/report/viewReport',
        //   data: json
        // })

        this.$router.push('/bacsReports/viewReport', '_blank')

        // let routeData = this.$router.resolve({ name: '/bacsReports/viewReport' })
        // console.log('routeData: ' + routeData.href)
        // window.open(routeData.href, '_blank')
        // window.open('http://localhost:8081/bacsReports/viewReport', '_blank')
      }
    },

    async viewReportModal (params) {
      console.log('params: ' + JSON.stringify(params))
      var cellClicked = params.column.field
      console.log('cellClicked: ' + cellClicked)

      if (cellClicked === 'viewReportXml' || cellClicked === 'viewReportHtml' || cellClicked === 'viewReportCsv') {
        if (this.networkType === 'BacstelIp') {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 0
          } else {
            this.selectedReportFormat = 1
          }
        } else {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 2
          } else {
            this.selectedReportFormat = 6
          }
        }

        console.log('selectedReportFormat: ' + this.selectedReportFormat)
        var json = JSON.stringify({
          networkType: this.networkType,
          downloadUrl: params.row.retrievalUrl,
          downloadFormat: this.selectedReportFormat,
          authResponse: this.authResponse
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/viewReport',
          data: json,
          showload: true
        })
        this.reportContent = response.data
        this.isModalVisible = true
      }
    },

    async getBacsReportUserPreferences () {
      console.log('getBacsReportUserPreferences')
      var response = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/report/getBacsReportUserPreferences?networkType=' + this.networkType, { showload: true })

      if (response.data !== null) {
        console.log('user prefs: ' + JSON.stringify(response.data))
        this.selectedReportType = response.data.reportType
        this.selectedReportPeriod = response.data.reportPeriod
        this.selectedReportAccessed = response.data.reportAccessed
        this.selectedGroupId = response.data.groupId
      } else {
        this.selectedReportType = this.reportTypes[0].value
      }
    },

    async saveBacsReportUserPreferences () {
      console.log('saveBacsReportUserPreferences')
      var json = JSON.stringify({
        networkType: this.networkType,
        reportType: this.selectedReportType,
        reportPeriod: this.selectedReportPeriod,
        reportAccessed: this.selectedReportAccessed,
        groupId: this.selectedGroupId
      })

      await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/saveBacsReportUserPreferences',
        data: json,
        showload: true
      })
    },

    closeViewReport () {
      console.log('closeViewReport')
      this.isModalVisible = false
    },
    createUid () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0
        var v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    stopSocketListener () {
      this.reconciliationConnection.stop()
    }
  },

  async mounted () {
    this.networkType = this.$route.query.networkType
    this.networkName = this.networkType === 'BacstelIp' ? 'BACS' : 'Faster Payment'
    await this.$store.dispatch('loadCustomerGroups', this.paygateId)
    await this.getEnums()
    await this.getBacsReportUserPreferences()
    // Check if redirected here after signing with eSigner.
    var getSignature = this.$route.query.getSignature
    if (getSignature === 'true') {
      this.authResponse = {}
      var cookie = {}
      cookie.name = this.$route.query.cookieName
      cookie.value = this.$route.query.cookieValue
      cookie.domain = this.$route.query.cookieDomain

      var cookieCollection = []
      cookieCollection.push(cookie)
      this.authResponse.cookieCollection = cookieCollection
      this.authResponse.cookieValueIsBase64 = true
      this.authResponse.loginChallenge = this.$route.query.challenge

      // TEMP ID just to get signature from database.
      this.submissionId = this.$route.query.submissionId
      this.networkType = this.$route.query.networkType
      this.selectedGroupId = this.$route.query.groupId

      await this.$store.dispatch('getSubBacsGroup', this.selectedGroupId)
      this.bacsGroup = this.$store.getters.bacsGroup
      await this.geteSignerSignature()
      await this.getBacsReportUserPreferences()
    }

    var paygateId = this.$store.getters.getClaim('paygate_id').value
    var payload = { paygateId: paygateId, groupRole: ['groupAdministrator'] }
    this.$store.dispatch('getGroupsWithRights', payload)
      .then(response => {
        this.groups = this.$store.getters.rightsGroups
        if (this.groups.length === 0) {
          this.$toastr.w('You don\'t have permission to collect reports for any groups.')
        } else {
          for (var i = 0; i < this.groups.length; i++) {
            var obj = {}
            obj.value = this.groups[i].id
            obj.text = this.groups[i].label
            this.simpleGroups.push(obj)
          }
        }

        if (getSignature !== 'true') {
          this.isLoggedIn = false
          this.isLoaded = true
        }
      })

    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        // this.$refs['fileUploader'].setOption('headers', { 'Authorization': this.bearerToken })
        console.log(this.bearerToken)
        this.reconciliationConnection = new HubConnectionBuilder().withUrl(this.reconciliationHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.reconciliationConnection.start()
        this.reconciliationConnection.on('SynchronisationStarted', data => {
          this.$toastr.s('Synchronising data from BACS')
        })
        this.reconciliationConnection.on('ProcessingComplete', data => {
          for (var i = 0; i < data.length; i++) {
            if (data[i] !== undefined) {
              if (data[i].alreadyProcessed) {
                this.$toastr.s(`${data[i].fileName} has already been processed.`)
              } else if (data[i].couldNotParse) {
                this.$toastr.e(`${data[i].fileName} could not be parsed.`)
              } else if (data[i].generalError) {
                this.$toastr.e(`An error occured processing ${data[i].fileName}.`)
              } else {
                this.$toastr.s(`${data[i].fileName} successfully processed.`)
              }
            }
          }
        })
        this.reconciliationConnection.on('ProcessingError', data => {
          console.log(data)
          this.$toastr.e(data.originalFileName + '. failed to process reports.')
        })
      })
    var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
    if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
      this.selectedGroup = this.customerGroups.find(i => i.groupId === defaultGroupId)
      // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
      if (this.selectedGroup !== undefined) {
        this.selectedGroupId = defaultGroupId
      }
    }
    this.selectGroup()
  },
  components: {
    viewReportModal: ViewReportModal
  }
}
</script>
<style>
.report-row-selected {
  height: 60px;
  background-color: #f0f0f0;
  color: black;
}
.grid-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
