var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(0),
      _c("div", { staticClass: "idb-block-content" }, [
        _c("table", { staticStyle: { "margin-bottom": "10px" } }, [
          _vm._m(1),
          _c(
            "tbody",
            _vm._l(_vm.contactUsers, function(user) {
              return _c("tr", { key: user.userId }, [
                _c("td", { staticStyle: { width: "20px" } }, [
                  _c("span", {
                    staticClass: "fa fa-trash",
                    attrs: { role: "button" },
                    on: {
                      click: function($event) {
                        return _vm.removeUser(user.userId)
                      }
                    }
                  })
                ]),
                _c(
                  "td",
                  {
                    staticClass: "row-header",
                    staticStyle: { width: "150px" }
                  },
                  [_vm._v(_vm._s(user.email))]
                ),
                _c(
                  "td",
                  [
                    _c(
                      "label",
                      {
                        staticClass: "sr-only",
                        attrs: { for: user.userId + "_contactViaEmail" }
                      },
                      [_vm._v("Contact Via Email?")]
                    ),
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      attrs: { labelId: user.userId + "_contactViaEmail" },
                      model: {
                        value: user.contactViaEmail,
                        callback: function($$v) {
                          _vm.$set(user, "contactViaEmail", $$v)
                        },
                        expression: "user.contactViaEmail"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c(
                      "label",
                      {
                        staticClass: "sr-only",
                        attrs: { for: user.userId + "_contactViaSms" }
                      },
                      [_vm._v("Contact Via SMS?")]
                    ),
                    _c("p-check", {
                      staticClass: "p-default p-curve",
                      attrs: { labelId: user.userId + "_contactViaSms" },
                      model: {
                        value: user.contactViaSms,
                        callback: function($$v) {
                          _vm.$set(user, "contactViaSms", $$v)
                        },
                        expression: "user.contactViaSms"
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ]),
        _c(
          "div",
          { staticStyle: { "padding-bottom": "10px", ", margin-top": "10px" } },
          [
            _c(
              "b-form",
              { attrs: { inline: "" } },
              [
                _c("b-form-select", {
                  staticClass: "col-md-3",
                  attrs: { options: _vm.userList },
                  scopedSlots: _vm._u([
                    {
                      key: "first",
                      fn: function() {
                        return [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: "", disabled: "" } },
                            [_vm._v("-- Please select an option --")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.selectedUnallocatedUser,
                    callback: function($$v) {
                      _vm.selectedUnallocatedUser = $$v
                    },
                    expression: "selectedUnallocatedUser"
                  }
                }),
                _c(
                  "b-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { variant: "primary" },
                    on: { click: _vm.addUser }
                  },
                  [_vm._v("Add")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-footer" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.updateLoggingUsers($event)
                }
              }
            },
            [_c("i", { staticClass: "fa fa-save mr-2" }), _vm._v("Save ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Email Monitoring")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", { staticClass: "w-75" }),
        _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("Email")])])
        ]),
        _c("th", { staticClass: "rotate", staticStyle: { width: "50px" } }, [
          _c("div", [_c("span", [_vm._v("SMS")])])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }