<template>
<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <label>{{label}}</label>
      <select class="form-control" v-model=value @input="$emit('input', $event.target.value)">
        <option v-for="option in options" :key="option.value" v-bind:value="option.value">
          {{option.text}}
        </option>
      </select>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'BankAccount',
  props: [
    'multi',
    'name',
    'label',
    'value'
  ],
  data () {
    return {
      options: []
    }
  },
  created: async function () {
    // Fetch Bank Account key/values from platform
    try {
      const banks = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/dropdown`)
      if (banks && banks.data) {
        for (const bankAccount of banks.data) {
          this.options.push({
            text: bankAccount.reference,
            value: bankAccount.bankAccountId
          })
        }
      } else {
        this.$snapbar.e('Error retrieving list of available bank accounts.')
      }
    } catch (e) {
      this.$snapbar.e('Error getting list of available bank accounts.')
    }
  }
}
</script>
