<template>
  <div>
    <b-button
      v-if="reloadEnabled"
      @click.prevent="callReloadTable"
      class
      variant="link"
      v-b-popover.hover.top.d500="'Refresh the data in the table'"
    >
      <i class="fas fa-sync-alt pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
    </b-button>
    <b-button
      v-if="printEnabled"
      @click.prevent="printTable"
      class
      variant="link"
      v-b-popover.hover.top.d500="'Print out the contents of the table'"
    >
      <i class="fa fa-print pointer dimmedIcon"></i><span class="sr-only">Print Table</span>
    </b-button>
    <b-button
      v-if="exportEnabled"
      @click.prevent="exportTable"
      class
      variant="link"
      v-b-popover.hover.top.d500="'Export the contents of the table'"
    >
      <i class="fas fa-file-export pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
    </b-button>
    <slot></slot>
  </div>
</template>
<script>
import axios from 'axios'
import { saveAs } from 'file-saver'

export default {
  name: 'table-actions',
  props: {
    exportEnabled: {
      type: Boolean,
      default: true
    },
    printEnabled: {
      type: Boolean,
      default: true
    },
    reloadEnabled: {
      type: Boolean,
      default: true
    },
    dataUrl: {
      type: String
    },
    data: {
      type: Array
    },
    exportData: {
      type: Object
    },
    exportName: {
      type: String,
      default: 'TableExport'
    },
    reloadTable: {
      type: Function
    },
    tableColumns: {
      type: Array
    },
    tableAmount: {
      type: Number
    }
  },
  data: function () {
    return {
      savedTableColumnsHidden: []
    }
  },
  methods: {
    async callReloadTable () {
      await this.reloadTable()
    },
    async exportTable () {
      var response
      if (this.data) {
        var download = this.data.map((rv) => {
          var keys = this.tableColumns.map(v => v.field)
          var line = keys
            .map((kv) => {
              return rv[kv]
            })
            .join(',')
          return line
        })
        response = { data: download.join('\n') }
      } else {
        var axiosData = Object.assign({}, this.exportData, { download: true })
        response = await axios.get(this.dataUrl, { params: { ...axiosData } })
      }

      const blob = new Blob([response.data], { type: 'text/csv' })
      saveAs(blob, `${this.exportName}.csv`)
    },
    async printTable () {
      if (this.tableAmount > 1000) {
        this.$swal('Too Big.', 'The Table is too big to print from the browser, please export the table and print from Excel instead', 'warning')
        return
      }

      this.savedTableColumnsHidden = []
      if (this.exportData) {
        this.exportData.page = undefined
        this.exportData.perPage = undefined
      }

      this.tableColumns.forEach((v, i, a) => {
        this.savedTableColumnsHidden.push(v.hidden)
        if (a[i].print_hidden) {
          a[i].hidden = true
        } else {
          a[i].hidden = false
        }
      })

      await this.reloadTable()

      window.print()
    }
  },
  async mounted () {
    window.onafterprint = async () => {
      if (this.exportData) {
        this.exportData.page = 0
        this.exportData.perPage = 10
      }

      this.savedTableColumnsHidden.forEach((v, i) => {
        this.tableColumns[i].hidden = v
      })
      await this.reloadTable()
    }
  }
}
</script>
