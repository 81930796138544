var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: {
        "text-success": _vm.value && _vm.coloured,
        "text-danger": !_vm.value && _vm.coloured
      }
    },
    [
      _c("i", {
        staticClass: "fa",
        class: { "fa-check": _vm.value, "fa-times": !_vm.value }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }