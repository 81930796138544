import axios from 'axios'

// initial state
const state = {
  customer: {}
}

// actions
const actions = {
  async getCustomer ({ commit }, paygateId) {
    try {
      const response = await axios.get(process.env.PLATFORM_API + '/api/customers/' + paygateId)
      commit('getCustomer', response.data.data[0])
    } catch (error) {
      throw error
    }
  },
  async saveCustomer ({ commit }, customer) {
    if (customer.isNew) {
      try {
        const response = await axios.post(process.env.PLATFORM_API + '/api/customers', customer)
        commit('getCustomer', response.data)
      } catch (error) {
        throw error
      }
    } else {
      try {
        const response = await axios.put(process.env.PLATFORM_API + '/api/customers/' + customer.paygateId, customer)
        commit('getCustomer', response.data)
      } catch (error) {
        throw error
      }
    }
  },
  async deleteCustomer ({ commit }, paygateId) {
    try {
      await axios.delete(process.env.PLATFORM_API + '/api/customers/' + paygateId)
      commit('deleteCustomer', paygateId)
    } catch (error) {
      throw error
    }
  }
}

// mutations
const mutations = {
  getCustomer (state, customer) {
    state.customer = customer
  },
  deleteCustomer (state, paygateId) {
    state.customer = null
    state.customer = {
      defaultCurrency: 'GBP'
    }
  }
}

export default {
  state,
  actions,
  mutations
}
