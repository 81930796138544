var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h4", { staticClass: "m-0" }, [
        _vm._v(_vm._s(_vm.itemCountLabel) + " " + _vm._s(_vm.chartText))
      ])
    ]),
    _c("div", { staticClass: "idb-full-block" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("pie-chart", {
            attrs: {
              "chart-data": _vm.chartData,
              options: _vm.chartOptions,
              height: 300
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }