var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "idb-right-panel",
      class: _vm.sidebarOpen ? "collapse-show" : "collapse-hide"
    },
    [
      _c("div", { staticClass: "custom-sidebar-pan" }, [
        _c("div", { staticClass: "system-setting" }, [
          _vm._m(0),
          _vm.widget !== null
            ? _c(
                "form",
                { staticClass: "pt-10 m-10" },
                [
                  _vm.widget.sizes.length > 1
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Size")
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedSize,
                                expression: "selectedSize"
                              }
                            ],
                            staticClass: "form-control",
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedSize = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.updateSize
                              ]
                            }
                          },
                          _vm._l(_vm.widget.sizes, function(size) {
                            return _c(
                              "option",
                              {
                                key: "" + size.w + size.h,
                                domProps: { value: size }
                              },
                              [_vm._v(_vm._s(size.w) + " x " + _vm._s(size.h))]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._l(_vm.widget.options, function(option) {
                    return _c(
                      "div",
                      {
                        key: "" + _vm.widget.widgetid + option.label,
                        staticClass: "form-group"
                      },
                      [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v(_vm._s(option.label))
                        ]),
                        option.type == 0
                          ? _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: option.value,
                                    expression: "option.value"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: option.value },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      option,
                                      "value",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        option.type == 1
                          ? _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: option.value,
                                    expression: "option.value"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number", min: 0 },
                                domProps: { value: option.value },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      option,
                                      "value",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        option.type == 2
                          ? _c(
                              "div",
                              [
                                _c("p-check", {
                                  staticClass: "p-switch",
                                  staticStyle: { padding: "0.5rem 0" },
                                  attrs: { name: "check", color: "primary" },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 101
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.groupTypes,
                                    multiple: "",
                                    title: "Group Type"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 102
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.bacsReportPeriods,
                                    title: "Report Periods"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 103
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.bacsSelectDataBy,
                                    title: "BACS Select Data By"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 104
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.bacsGroups,
                                    title: "Group",
                                    "value-field": "id",
                                    "text-field": "label"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 201
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.ddmsGroups,
                                    title: "DDMS Groups",
                                    "value-field": "id",
                                    "text-field": "label"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 202
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.ddmsGroupsWithNull,
                                    title: "DDMS Groups with all",
                                    "value-field": "id",
                                    "text-field": "label"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 203
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.ddmsNumberRange,
                                    title: "DDMS Number Range"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 204
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.ddmsReportPeriods,
                                    title: "Report Periods"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        option.type == 301
                          ? _c(
                              "div",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.availableWorkflows,
                                    title: "Workflow"
                                  },
                                  model: {
                                    value: option.value,
                                    callback: function($$v) {
                                      _vm.$set(option, "value", $$v)
                                    },
                                    expression: "option.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "mt-auto buttons" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary mr-10", on: { click: _vm.save } },
              [_vm._v("Save")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary",
                on: { click: _vm.cancel }
              },
              [_vm._v("Cancel")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-header" }, [
      _c("i", { staticClass: "fa fa-cog" }),
      _vm._v(" Widget Settings ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }