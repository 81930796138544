var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        { class: _vm.rowClass },
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6",
              attrs: {
                "label-cols": 4,
                horizontal: "",
                label: "Payer Reference Generation Pattern"
              }
            },
            [
              _c("input-tag", {
                attrs: {
                  "allow-duplicates": true,
                  "add-tag-on-blur": true,
                  placeholder: "add pattern",
                  validate: _vm.isValid
                },
                on: { input: _vm.cleanUp },
                model: {
                  value: _vm.tags,
                  callback: function($$v) {
                    _vm.tags = $$v
                  },
                  expression: "tags"
                }
              }),
              _vm.errorTriggered
                ? _c("b-col", [
                    _c("label", { staticClass: "text-danger small" }, [
                      _vm._v(
                        "Tag removed as it would generate a reference that violates scheme rules"
                      )
                    ])
                  ])
                : _vm._e(),
              _c("b-col", [
                _c("label", { staticClass: "text-info small" }, [
                  _vm._v("i.e. " + _vm._s(_vm.generatedPayer))
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        { class: _vm.rowClass },
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-6",
              attrs: {
                "label-cols": 4,
                horizontal: "",
                label: "Add pattern (Type, Length)"
              }
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _c(
                            "b-form-select",
                            {
                              attrs: { variant: "outline-secondary" },
                              model: {
                                value: _vm.selectedCount,
                                callback: function($$v) {
                                  _vm.selectedCount = $$v
                                },
                                expression: "selectedCount"
                              }
                            },
                            _vm._l(6, function(n) {
                              return _c("option", { key: n }, [
                                _vm._v(_vm._s(n))
                              ])
                            }),
                            0
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { disabled: _vm.disableAddition },
                              on: { click: _vm.addPattern }
                            },
                            [_vm._v("+")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.types },
                    model: {
                      value: _vm.selectedPattern,
                      callback: function($$v) {
                        _vm.selectedPattern = $$v
                      },
                      expression: "selectedPattern"
                    }
                  })
                ],
                1
              ),
              _vm.disableAddition
                ? _c("b-col", [
                    _vm.disableAddition
                      ? _c("label", { staticClass: "text-danger small" }, [
                          _vm._v(
                            "Additional entries will create invalid references that violates scheme rules"
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("b-form-row")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }