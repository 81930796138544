var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          { staticClass: "modal-container" },
          [
            _vm._t("header", [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "pull-right close-btn",
                      attrs: { variant: "link", size: "lg" },
                      on: { click: _vm.cancelPopup }
                    },
                    [
                      _c("span", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  ),
                  _c("h3", [_vm._v("File Browser")])
                ],
                1
              )
            ]),
            _vm.errorMessage !== ""
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 text-danger" }, [
                    _vm._v(_vm._s(_vm.errorMessage))
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm._t("body", [
                  _vm.errorMessage === ""
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("span", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh Folders",
                                    expression: "'Refresh Folders'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link dimmedIcon",
                                on: { click: _vm.btnRefresh }
                              },
                              [_c("i", { staticClass: "fas fa-sync" })]
                            )
                          ]),
                          _c("span", { staticClass: "mr-2" }, [_vm._v("Path")]),
                          _c("span", [_vm._v(_vm._s(_vm.selectedFolder))])
                        ])
                      ])
                    : _vm._e(),
                  _c("hr"),
                  _c("br"),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 folderPanel" },
                            [
                              _c(
                                "VuePerfectScrollbar",
                                { staticClass: "scroll-area" },
                                [
                                  _c("v-jstree", {
                                    attrs: {
                                      data: _vm.treeData,
                                      "drag-over-background-color": "#F4E3EE",
                                      size: "large"
                                    },
                                    on: { "item-click": _vm.itemClick }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 folderPanel" },
                            [
                              _c(
                                "VuePerfectScrollbar",
                                { staticClass: "scroll-area" },
                                [
                                  _c(
                                    "div",
                                    {},
                                    _vm._l(_vm.myArray, function(
                                      element,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "folderManagerClickable",
                                          on: {
                                            click: function($event) {
                                              return _vm.selectItem(element)
                                            },
                                            dblclick: function($event) {
                                              return _vm.selectItemAndClose(
                                                element
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "list-group-item" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-start"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-start flex-column mr-20"
                                                    },
                                                    [
                                                      element.isFolder
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-folder fa-4x folderManagerFolder"
                                                          })
                                                        : _c("i", {
                                                            staticClass:
                                                              "fa fa-file fa-4x folderManagerFile"
                                                          })
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-start flex-column pt-10"
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass: "font-lg"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(element.text)
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              element.description
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      element.isFolder
                                                        ? _c("div", {
                                                            staticClass:
                                                              "small fw-bold"
                                                          })
                                                        : _vm._e(),
                                                      !element.isFolder
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "small fw-bold"
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v("Size:")
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.bytesToSize(
                                                                      element
                                                                        .stat
                                                                        .size
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !element.isFolder
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "small fw-bold"
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Last Modified:"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.reformatDate(
                                                                      element
                                                                        .stat
                                                                        .mtime
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _vm._t("footer", [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: { click: _vm.cancelPopup }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: { click: _vm.selectAndClose }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }),
                      _vm._v("Ok ")
                    ]
                  )
                ])
              ],
              2
            )
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }