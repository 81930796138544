var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "viewFlaggedSubmissions" } },
    [
      _c(
        "b-row",
        { attrs: { mb: "4" } },
        [
          _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [
                _c(
                  "h2",
                  [
                    _c("help-icon", {
                      attrs: { docPath: "/paygate-collections/messaging" }
                    }),
                    _vm._v("Messaging Reports"),
                    _c("favourite-icon")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c("div", { staticClass: "row form-group mb-3" }, [
                    _c("div", { staticClass: "col-md-2 required" }, [
                      _vm._v(" Select a Group ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("group-select", {
                          attrs: {
                            groups: _vm.customerGroups,
                            clearable: false
                          },
                          on: {
                            input: function($event) {
                              return _vm.customerGroupsChanged()
                            }
                          },
                          model: {
                            value: _vm.group,
                            callback: function($$v) {
                              _vm.group = $$v
                            },
                            expression: "group"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row form-group mb-3" }, [
                    _c("div", { staticClass: "col-md-2 required" }, [
                      _vm._v(" Earliest Date ")
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "right-inner-addon" },
                        [
                          _c("datepicker", {
                            attrs: {
                              id: "endPaymentDatePicker",
                              format: _vm.formatDate,
                              "input-class": "form-control datepicker"
                            },
                            on: {
                              input: function($event) {
                                return _vm.load()
                              }
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate"
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-calendar form-control-feedback"
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-group mb-3" }, [
                    _c("div", { staticClass: "col-md-2 required" }, [
                      _vm._v(" Latest Date ")
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "right-inner-addon" },
                        [
                          _c("datepicker", {
                            attrs: {
                              id: "startPaymentDatePicker",
                              format: _vm.formatDate,
                              "input-class": "form-control datepicker"
                            },
                            on: {
                              input: function($event) {
                                return _vm.load()
                              }
                            },
                            model: {
                              value: _vm.endDate,
                              callback: function($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate"
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-calendar form-control-feedback"
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("vue-good-table", {
                    ref: "table",
                    attrs: {
                      mode: "remote",
                      columns: _vm.columns,
                      rows: _vm.tableData,
                      lineNumbers: true,
                      totalRows: _vm.totalRecords,
                      "search-options": {
                        enabled: true
                      },
                      paginationOptions: _vm.paginationOptions,
                      "sort-options": _vm.sortOptions,
                      isLoading: _vm.isTableLoading,
                      styleClass: "vgt-table striped bordered"
                    },
                    on: {
                      "on-page-change": _vm.onPageChange,
                      "on-sort-change": _vm.onSortChange,
                      "on-column-filter": _vm.onColumnFilter,
                      "on-per-page-change": _vm.onPerPageChange,
                      "on-search": _vm.onSearch,
                      "update:isLoading": function($event) {
                        _vm.isTableLoading = $event
                      },
                      "update:is-loading": function($event) {
                        _vm.isTableLoading = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "idb-block-footer mt-3" })
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }