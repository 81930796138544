var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _c("span", { staticClass: "pull-right" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          _vm.docUrl +
                          "/automation/configuration/foldermanager/",
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "far fa-question-circle" })]
                  )
                ]),
                _vm._v(" Folder Manager "),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("form", [
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-navbar",
                      {
                        ref: "navbar",
                        staticClass: "pgnav",
                        attrs: { toggleable: "md", type: "light" }
                      },
                      [
                        _c("b-navbar-toggle", {
                          attrs: { target: "nav_collapse" }
                        }),
                        _c(
                          "b-collapse",
                          { attrs: { "is-nav": "", id: "nav_collapse" } },
                          [
                            _c(
                              "b-navbar-nav",
                              [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      href: "#",
                                      title: "Create Folder"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuCreateFolder($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-folder-plus text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: { href: "#", title: "Cut" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.clipboardCut($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-cut text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: { href: "#", title: "Copy" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.clipboardCopy($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-copy text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: { href: "#", title: "Paste" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.clipboardPaste($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-clipboard text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: { href: "#", title: "Refresh" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuRefresh($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-sync text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: { href: "#", title: "Delete" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuDelete($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-trash-alt text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: { href: "#", title: "Rename" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuRename($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-edit text-secondary"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: { href: "#", title: "Info" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.menuInfo($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-info-circle text-secondary"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("b-navbar-nav", { staticClass: "ml-auto" }, [
                              _vm.selectedFolder
                                ? _c(
                                    "span",
                                    { staticClass: "text-secondary" },
                                    [_vm._v(_vm._s(_vm.selectedFolder))]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "text-secondary" },
                                    [_vm._v(_vm._s(_vm.selectedFile))]
                                  )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("hr")
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 folderPanel" },
                  [
                    _c(
                      "VuePerfectScrollbar",
                      {
                        staticClass: "scroll-area",
                        attrs: { settings: _vm.settings }
                      },
                      [
                        _c("v-jstree", {
                          attrs: {
                            data: _vm.treeData,
                            "drag-over-background-color": "#F4E3EE",
                            size: "large"
                          },
                          on: { "item-click": _vm.itemClick }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6 folderPanel" },
                  [
                    _c(
                      "VuePerfectScrollbar",
                      {
                        staticClass: "scroll-area",
                        attrs: { settings: _vm.settings }
                      },
                      [
                        _c(
                          "div",
                          {},
                          _vm._l(_vm.myArray, function(element, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                on: {
                                  click: function($event) {
                                    return _vm.selectItem(element)
                                  },
                                  dblclick: function($event) {
                                    return _vm.selectItemAndClose(element)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "list-group-item folderManagerClickable",
                                    class: {
                                      folderManagerSelectedItem:
                                        element.path === _vm.selectedFolder ||
                                        element.path === _vm.selectedFile
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-start flex-column mr-20"
                                          },
                                          [
                                            element.isFolder
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-folder fa-4x folderManagerFolder"
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "fa fa-file fa-4x folderManagerFile"
                                                })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-start flex-column pt-10"
                                          },
                                          [
                                            _c(
                                              "h6",
                                              { staticClass: "font-lg" },
                                              [_vm._v(_vm._s(element.text))]
                                            ),
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                _vm._s(element.description)
                                              )
                                            ]),
                                            element.isFolder
                                              ? _c("div", {
                                                  staticClass: "small fw-bold"
                                                })
                                              : _vm._e(),
                                            !element.isFolder
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "small fw-bold"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("Size:")
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.bytesToSize(
                                                            element.stat.size
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            !element.isFolder
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "small fw-bold"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("Last Modified:")
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.reformatDate(
                                                            element.stat.mtime
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }