import axios from 'axios'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

function getToastTitle (status) {
  if (status.slice(-1) === 'e') {
    return `${status}d`
  }
  return `${status}ed`
}

export default {
  methods: {
    async reject () {
      try {
        try {
          // Get confirmation from the user that they really want to delete the customer
          var result = await swal.fire({
            title: 'Reject Action',
            text: 'Are you sure you want to reject this action?',
            icon: 'warning',
            input: 'text',
            showCancelButton: true,
            confirmButtonColor: colours.danger,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            inputPlaceholder: 'Rejection Reason'
          })
        } catch (error) {
          // Do nothing as this will be if they clicked cancel
          return
        }
        if (!result.isConfirmed) {
          return
        }

        await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/Reject/${this.action.id}`, { RejectionMessage: result.value }, { showload: true, showerror: true, errormessage: 'Failed to reject action' })
        this.$toastr.s(`${this.name} ${this.status} Rejected`, 'Rejected')
        this.$router.back()
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    },
    async deleteRejection () {
      try {
        await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/DeleteRejected/${this.action.id}`, { showload: true, showerror: true, errormessage: 'Failed to delete action' })
        this.$router.back()
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
      }
    }
  }
}
