var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showFolderBrowser = false
                _vm.closeFolderPopup()
              }
            }
          })
        : _vm._e(),
      _c("h3", [
        _vm._v(" BACS Bureau Report Download "),
        _c("span", { staticClass: "pull-right" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  _vm.docUrl +
                  "/automation/paygate-workflow/workflow-nodes/bureausautomation/bureaureports/",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "far fa-question-circle" })]
          )
        ])
      ]),
      _c("hr"),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Report Service")]),
          _c("br"),
          _c(
            "p-radio",
            {
              attrs: { value: "BACS", color: "primary" },
              model: {
                value: _vm.selectedNode.props.s5.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s5, "value", $$v)
                },
                expression: "selectedNode.props.s5.value"
              }
            },
            [_vm._v("BACS")]
          ),
          _c(
            "p-radio",
            {
              attrs: {
                value: "Faster Payments",
                color: "primary",
                disabled: ""
              },
              model: {
                value: _vm.selectedNode.props.s5.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.s5, "value", $$v)
                },
                expression: "selectedNode.props.s5.value"
              }
            },
            [_vm._v("Faster Payments")]
          )
        ],
        1
      ),
      _c("br"),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _vm.selectedNode.props.s5.value === "BACS"
            ? _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectAll,
                        expression: "selectAll"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectAll)
                        ? _vm._i(_vm.selectAll, null) > -1
                        : _vm.selectAll
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectAll,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectAll = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectAll = $$c
                          }
                        },
                        _vm.selectAllClick
                      ]
                    }
                  }),
                  _vm._m(0)
                ])
              ])
            : _c("div", [_c("label", [_vm._v("Report Type")])])
        ])
      ]),
      _vm.selectedNode.props.s5.value === "BACS"
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b2.value,
                        expression: "selectedNode.props.b2.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b2.value)
                        ? _vm._i(_vm.selectedNode.props.b2.value, null) > -1
                        : _vm.selectedNode.props.b2.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b2.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b2,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b2,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b2, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(1)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b3.value,
                        expression: "selectedNode.props.b3.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b3.value)
                        ? _vm._i(_vm.selectedNode.props.b3.value, null) > -1
                        : _vm.selectedNode.props.b3.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b3.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b3,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b3,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b3, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(2)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b4.value,
                        expression: "selectedNode.props.b4.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b4.value)
                        ? _vm._i(_vm.selectedNode.props.b4.value, null) > -1
                        : _vm.selectedNode.props.b4.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b4.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b4,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b4,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b4, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(3)
                ])
              ])
            ]),
            _c("br"),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b5.value,
                        expression: "selectedNode.props.b5.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b5.value)
                        ? _vm._i(_vm.selectedNode.props.b5.value, null) > -1
                        : _vm.selectedNode.props.b5.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b5.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b5,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b5,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b5, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(4)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b6.value,
                        expression: "selectedNode.props.b6.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b6.value)
                        ? _vm._i(_vm.selectedNode.props.b6.value, null) > -1
                        : _vm.selectedNode.props.b6.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b6.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b6,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b6,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b6, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(5)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b7.value,
                        expression: "selectedNode.props.b7.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b7.value)
                        ? _vm._i(_vm.selectedNode.props.b7.value, null) > -1
                        : _vm.selectedNode.props.b7.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b7.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b7,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b7,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b7, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(6)
                ])
              ])
            ]),
            _c("br"),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b8.value,
                        expression: "selectedNode.props.b8.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b8.value)
                        ? _vm._i(_vm.selectedNode.props.b8.value, null) > -1
                        : _vm.selectedNode.props.b8.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b8.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b8,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b8,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b8, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(7)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b9.value,
                        expression: "selectedNode.props.b9.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b9.value)
                        ? _vm._i(_vm.selectedNode.props.b9.value, null) > -1
                        : _vm.selectedNode.props.b9.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b9.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b9,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b9,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b9, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(8)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b14.value,
                        expression: "selectedNode.props.b14.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b14.value)
                        ? _vm._i(_vm.selectedNode.props.b14.value, null) > -1
                        : _vm.selectedNode.props.b14.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b14.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b14,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b14,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b14, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(9)
                ])
              ])
            ]),
            _c("br"),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b11.value,
                        expression: "selectedNode.props.b11.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b11.value)
                        ? _vm._i(_vm.selectedNode.props.b11.value, null) > -1
                        : _vm.selectedNode.props.b11.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b11.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b11,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b11,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b11, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(10)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b12.value,
                        expression: "selectedNode.props.b12.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b12.value)
                        ? _vm._i(_vm.selectedNode.props.b12.value, null) > -1
                        : _vm.selectedNode.props.b12.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b12.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b12,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b12,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b12, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(11)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b10.value,
                        expression: "selectedNode.props.b10.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b10.value)
                        ? _vm._i(_vm.selectedNode.props.b10.value, null) > -1
                        : _vm.selectedNode.props.b10.value
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.selectedNode.props.b10.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b10,
                                  "value",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedNode.props.b10,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedNode.props.b10, "value", $$c)
                          }
                        },
                        _vm.checked
                      ]
                    }
                  }),
                  _vm._m(12)
                ])
              ])
            ])
          ])
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "pretty p-icon p-smooth" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNode.props.b10.value,
                        expression: "selectedNode.props.b10.value"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedNode.props.b10.value)
                        ? _vm._i(_vm.selectedNode.props.b10.value, null) > -1
                        : _vm.selectedNode.props.b10.value
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.selectedNode.props.b10.value,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.selectedNode.props.b10,
                                "value",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.selectedNode.props.b10,
                                "value",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.selectedNode.props.b10, "value", $$c)
                        }
                      }
                    }
                  }),
                  _vm._m(13)
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }),
              _c("div", { staticClass: "col-md-3" })
            ])
          ]),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Report Format")]),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted ml-2" }, [
          _vm._v(
            "Note, The BACS service does not provide reports in all formats."
          )
        ]),
        _c("br"),
        _vm.selectedNode.props.s5.value === "BACS"
          ? _c(
              "div",
              [
                _c(
                  "p-radio",
                  {
                    attrs: { value: "XML", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("XML")]
                ),
                _c(
                  "p-radio",
                  {
                    attrs: { value: "HTML", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("HTML")]
                ),
                _c(
                  "p-radio",
                  {
                    attrs: { value: "PDF", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("PDF")]
                ),
                _c(
                  "p-radio",
                  {
                    attrs: { value: "CSV", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("CSV")]
                ),
                _c(
                  "p-radio",
                  {
                    attrs: { value: "All", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("All")]
                )
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "p-radio",
                  {
                    attrs: { value: "XML", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("XML")]
                ),
                _c(
                  "p-radio",
                  {
                    attrs: { value: "CSV", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("CSV")]
                ),
                _c(
                  "p-radio",
                  {
                    attrs: { value: "BOTH", color: "primary" },
                    model: {
                      value: _vm.selectedNode.props.s2.value,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
                      },
                      expression: "selectedNode.props.s2.value"
                    }
                  },
                  [_vm._v("Both")]
                )
              ],
              1
            )
      ]),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Save Path")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s3.value,
              expression: "selectedNode.props.s3.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s3.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s3.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s3,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s3.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnFolderBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s3.value.required &&
      _vm.$v.selectedNode.props.s3.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The Save Folder field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s3.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s3.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Report Age")]),
        _c("br"),
        _vm.selectedNode.props.s5.value == "BACS"
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedNode.props.s4.value,
                    expression: "selectedNode.props.s4.value"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.selectedNode.props.s4,
                      "value",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", [_vm._v("Current Day")]),
                _c("option", [_vm._v("Last Two Days")]),
                _c("option", [_vm._v("Last Two Days")]),
                _c("option", [_vm._v("Last Week")]),
                _c("option", [_vm._v("Last Month")])
              ]
            )
          : _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedNode.props.s4.value,
                    expression: "selectedNode.props.s4.value"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.selectedNode.props.s4,
                      "value",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", [_vm._v("Current Day")]),
                _c("option", [_vm._v("Last Two Days")]),
                _c("option", [_vm._v("Last Three Days")]),
                _c("option", [_vm._v("Last Four Days")]),
                _c("option", [_vm._v("Last Five Days")])
              ]
            )
      ]),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "p-check",
            {
              staticClass: "p-switch",
              attrs: { name: "check", color: "primary" },
              model: {
                value: _vm.selectedNode.props.b1.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.b1, "value", $$v)
                },
                expression: "selectedNode.props.b1.value"
              }
            },
            [_vm._v("Download previously accessed reports")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "p-check",
            {
              staticClass: "p-switch",
              attrs: { name: "check", color: "primary" },
              model: {
                value: _vm.selectedNode.props.b15.value,
                callback: function($$v) {
                  _vm.$set(_vm.selectedNode.props.b15, "value", $$v)
                },
                expression: "selectedNode.props.b15.value"
              }
            },
            [_vm._v("Download reports from all Bureau Customers")]
          )
        ],
        1
      ),
      _c("br"),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Service User Numbers")]),
          _c("span", { staticClass: "text-muted ml-2" }, [
            _vm._v("A comma separated or list of individual SUNS")
          ]),
          _c("br"),
          _c("b-form-textarea", {
            attrs: { id: "sunsTextArea", rows: "10" },
            model: {
              value: _vm.selectedNode.props.s1.value,
              callback: function($$v) {
                _vm.$set(_vm.selectedNode.props.s1, "value", $$v)
              },
              expression: "selectedNode.props.s1.value"
            }
          })
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("Report Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("ADDACS Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("ARUCS Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("ARUDD Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("AUDDIS Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("AWACS Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("DDIC Advice Reports")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("Arrival Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("Withdrawal Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("DDIC Reports")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("Component History Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("Test Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("Input Report")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-primary" }, [
      _c("i", { staticClass: "icon fa fa-check" }),
      _c("label", [_vm._v("DCA Input Report")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }