<template>
  <div>
    <div class="snap-container">
      <transition-group name="snapbar-animation" tag="div">
        <div
          class="snap-box"
          :class="messageObject.type"
          v-for="( messageObject,$index) in messageQueue"
          :key="messageObject.message"
        >
          <span class="snap-wrapper" :class="{expand:messageObject.notify}">
            <span class="snap-logo"></span>
            <span class="snap-message" v-if="!messageObject.html">{{messageObject.message}}</span>
            <span class="snap-message" v-else v-html="messageObject.message"></span>
          </span>
          <span class="snap-close" @click="hide($index)">
            <span class="fas fa-times"></span>
          </span>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnapBar',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      messageQueue: [],
      maxMessages: 3,
      autoClose: this.processOption('autoClose', false),
      timeout: this.processOption('timeout', 5000)
    }
  },
  methods: {
    show ({ message, type, autoClose, timeout, html }) {
      if (type === undefined) {
        type = 'info'
      }

      var alreadyInQueueMessage = this.messageQueue.find((m) => m.message === message)
      if (alreadyInQueueMessage) {
        if (alreadyInQueueMessage.notifyTimer === null) {
          alreadyInQueueMessage.notify = true
          alreadyInQueueMessage.notifyTimer = setTimeout(() => {
            alreadyInQueueMessage.notify = false
            clearTimeout(alreadyInQueueMessage.notifyTimer)
            alreadyInQueueMessage.notifyTimer = null
          }, 500)
        }
        return
      }

      if (this.messageQueue.length >= this.maxMessages) {
        this.hide(0)
      }

      var messageToAdd = { message, type, html, notify: false, notifyTimer: null, timer: null }
      this.messageQueue.splice(this.messageQueue.length, 0, messageToAdd)

      if (this.processSetting(autoClose, 'autoClose')) {
        messageToAdd.timer = setTimeout(() => {
          this.hide(this.messageQueue.findIndex((m) => m.message === message))
        }, this.processSetting(timeout, 'timeout'))
      }
    },
    hideByMessage (message) {
      var messageIndex = this.messageQueue.findIndex((m) => m.message === message)
      if (messageIndex !== -1) {
        this.hide(messageIndex)
      }
    },
    hide (index = -1) {
      if (index === -1) {
        this.clearAll()
        return
      }
      var messageObject = this.messageQueue[index]
      if (messageObject && messageObject.timer) {
        clearTimeout(messageObject.timer)
      }
      this.messageQueue.splice(index, 1)
    },
    clearAll () {
      this.messageQueue.forEach(messageObject => {
        if (messageObject.timer) {
          clearTimeout(messageObject.timer)
        }
      })

      this.messageQueue.splice(0)
    },
    i (message, options = {}) {
      this.show({ message, type: 'info', ...options })
    },
    s (message, options = {}) {
      this.show({ message, type: 'success', ...options })
    },
    e (message, options = {}) {
      this.show({ message, type: 'error', ...options })
    },
    w (message, options = {}) {
      this.show({ message, type: 'warning', ...options })
    },
    info (message, options = {}) {
      this.i(message, options)
    },
    success (message, options = {}) {
      this.s(message, options)
    },
    error (message, options = {}) {
      this.e(message, options)
    },
    warning (message, options = {}) {
      this.w(message, options)
    },
    processOption (optionValue, defaultValue) {
      if (!this.options) {
        return defaultValue
      }
      return typeof this.options[optionValue] !== 'undefined'
        ? this.options[optionValue]
        : defaultValue
    },
    processSetting (value, namedValue) {
      if (value === undefined || value === null) {
        return this[namedValue]
      }
      return value
    }
  },
  mounted () {
    var pushState = history.pushState
    var hideMethod = this.clearAll
    history.pushState = function () {
      pushState.call(history, ...arguments)
      hideMethod()
    }

    window.onpopstate = (event) => {
      this.clearAll()
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
$black: #000000;
$grey: #999999;
$light-grey: #cccccc;
$white: #ffffff;
$near-black: #030303;
$green: #51a351;
$red: #bd362f;
$blue: #2f96b4;
$orange: #f89406;
$default-container-opacity: 0.8;

.snap-container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  color: $white;
  overflow-y: hidden;
  transition: all 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.snap-box {
  text-align: center;
  background-color: $near-black;
  padding: 3px;
  opacity: 0.9;
  min-height: 0;

  &:hover {
    opacity: 1;
  }
  .snap-close {
    right: 25px;
    position: absolute;
    vertical-align: middle;
    cursor: pointer;
  }

  .snap-wrapper {
    width: 100%;
    display: inline-block;
    &.expand {
      animation-name: shake;
      animation-duration: 0.5s;
    }
  }

  .snap-logo {
    &::before {
      font-family: "Font Awesome 5 Free";
      display: inline-block;
      padding-right: 10px;
      vertical-align: middle;
      font-weight: 900;
      color: $white;
    }
  }

  &.info {
    background-color: $blue;
    .snap-logo {
      &::before {
        content: "\f05a";
      }
    }
  }
  &.success {
    background-color: $green;
    .snap-logo {
      &::before {
        content: "\f00c";
      }
    }
  }
  &.error {
    background-color: $red;
    .snap-logo {
      &::before {
        content: "\f071";
      }
    }
  }
  &.warning {
    background-color: $orange;
    .snap-logo {
      &::before {
        content: "\f06a";
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-5%);
  }
  30% {
    transform: translateX(5%);
  }
  45% {
    transform: translateX(-3%);
  }
  60% {
    transform: translateX(1%);
  }
  75% {
    transform: translateX(-1%);
  }
  100% {
    transform: translateX(0%);
  }
}

.snapbar-animation-enter-active {
  transition: all 0.4s;
}

.snapbar-animation-enter {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
