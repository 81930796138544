<template>
  <div style="height: 100%;overflow-y: scroll;overflow-x: hidden;">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0">Licence Widget</h4>
    </div>
    <div class="idb-full-block">
      <div class="row">
        <div class="col-md-4">
          <pie-chart :chart-data="charts.user.data" :options="charts.user.options" :height="300"></pie-chart>
        </div>
        <div class="col-md-4">
          <pie-chart :chart-data="charts.group.data" :options="charts.group.options" :height="300"></pie-chart>
        </div>
        <div class="col-md-4">
          <pie-chart
            :chart-data="charts.serviceAccount.data"
            :options="charts.serviceAccount.options"
            :height="300"
          ></pie-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import axios from 'axios'
import randomColor from 'randomcolor'

import PieChart from '@/Assets/Components/Charts/PieChart'

export default {
  name: 'LicenceWidget',
  components: {
    PieChart
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      licence: {},
      charts: {
        user: {
          data: {},
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
              onClick: function () { }
            },
            title: {
              display: true,
              text: 'Users used/remaining'
            }
          }
        },
        group: {
          data: {},
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
              onClick: function () { }
            },
            title: {
              display: true,
              text: 'Groups used/remaining'
            }
          }
        },
        serviceAccount: {
          data: {},
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              // This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
              onClick: function () { }
            },
            title: {
              display: true,
              text: 'Service Accounts used/remaining'
            }
          }
        }
      }
    }
  },
  methods: {
    async load () {
      try {
        var results = await Promise.allSettled([
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/GroupCount`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/UserCount`),
          axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/ServiceAccountCount`)
        ])

        const licenceResponse = results[0].value
        const groupResponse = results[1].value
        const userResponse = results[2].value
        const serviceAccountResponse = results[3].value

        this.licence = licenceResponse.data

        this.groupsAmount = groupResponse.data
        this.usersAmount = userResponse.data
        this.serviceAccountsAmount = serviceAccountResponse.data

        this.charts.user.data = {
          labels: ['Used', 'Remaining'],
          datasets: [{
            data: [this.usersAmount, this.licence.platform.users - this.usersAmount],
            backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
          }]
        }
        this.charts.group.data = {
          labels: ['Used', 'Remaining'],
          datasets: [{
            data: [this.groupsAmount, this.licence.platform.groups - this.groupsAmount],
            backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
          }]
        }

        this.charts.serviceAccount.data = {
          labels: ['Used', 'Remaining'],
          datasets: [{
            data: [this.serviceAccountsAmount, this.licence.platform.serviceAccounts - this.serviceAccountsAmount],
            backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
          }]
        }
      } catch { }
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style >
</style>
