var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "viewFlaggedSubmissions" } },
    [
      _c(
        "b-row",
        { attrs: { mb: "4" } },
        [
          _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [
                _c(
                  "h2",
                  [
                    _c("help-icon", {
                      attrs: { docPath: "/paygate-collections/submissions/" }
                    }),
                    _vm._v("Orphaned Submissions"),
                    _c("favourite-icon")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c("div", { staticClass: "row form-group mb-3" }, [
                    _c("div", { staticClass: "col-md-2 required" }, [
                      _vm._v(" Select a Group ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("group-select", {
                          attrs: {
                            groups: _vm.customerGroups,
                            clearable: false
                          },
                          on: {
                            input: function($event) {
                              return _vm.customerGroupsChanged()
                            }
                          },
                          model: {
                            value: _vm.group,
                            callback: function($$v) {
                              _vm.group = $$v
                            },
                            expression: "group"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row form-group mb-3" }, [
                    _c("div", { staticClass: "col-md-2 required" }, [
                      _vm._v(" Earliest Date ")
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "right-inner-addon" },
                        [
                          _c("datepicker", {
                            attrs: {
                              id: "endPaymentDatePicker",
                              disabledDates: _vm.disabledRangeDates,
                              format: _vm.formatDate,
                              "input-class": "form-control datepicker"
                            },
                            on: {
                              input: function($event) {
                                return _vm.load()
                              }
                            },
                            model: {
                              value: _vm.endDate,
                              callback: function($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate"
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-calendar form-control-feedback"
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-group mb-3" }, [
                    _c("div", { staticClass: "col-md-2 required" }, [
                      _vm._v(" Latest Date ")
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "right-inner-addon" },
                        [
                          _c("datepicker", {
                            attrs: {
                              id: "startPaymentDatePicker",
                              disabledDates: _vm.disabledRangeDatesEnd,
                              format: _vm.formatDate,
                              "input-class": "form-control datepicker"
                            },
                            on: {
                              input: function($event) {
                                return _vm.load()
                              }
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate"
                            }
                          }),
                          _c("i", {
                            staticClass: "fa fa-calendar form-control-feedback"
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c(
                    "vue-good-table",
                    {
                      ref: "table",
                      attrs: {
                        mode: "remote",
                        columns: _vm.columns,
                        rows: _vm.tableData,
                        lineNumbers: true,
                        totalRows: _vm.totalRecords,
                        "search-options": {
                          enabled: true
                        },
                        paginationOptions: _vm.paginationOptions,
                        "sort-options": _vm.sortOptions,
                        isLoading: _vm.isTableLoading,
                        styleClass: "vgt-table striped bordered"
                      },
                      on: {
                        "on-page-change": _vm.onPageChange,
                        "on-sort-change": _vm.onSortChange,
                        "on-column-filter": _vm.onColumnFilter,
                        "on-per-page-change": _vm.onPerPageChange,
                        "on-search": _vm.onSearch,
                        "on-cell-click": _vm.onCellClick,
                        "on-row-click": _vm.onRowClick,
                        "update:isLoading": function($event) {
                          _vm.isTableLoading = $event
                        },
                        "update:is-loading": function($event) {
                          _vm.isTableLoading = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "table-row",
                          fn: function(props) {
                            return [
                              _vm.selectedRow &&
                              _vm.selectedRow.paymentComposite ===
                                props.row.paymentComposite
                                ? _c("span", [
                                    props.column.field === "amount"
                                      ? _c(
                                          "span",
                                          [
                                            _c("currency-input", {
                                              attrs: {
                                                currency: "GBP",
                                                locale: "en"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.amountChanged(
                                                    props
                                                  )
                                                }
                                              },
                                              model: {
                                                value: props.row.amount,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    props.row,
                                                    "amount",
                                                    $$v
                                                  )
                                                },
                                                expression: "props.row.amount"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : props.column.field === "processingDate"
                                      ? _c(
                                          "span",
                                          [
                                            _c("datepicker", {
                                              attrs: {
                                                disabledDates: _vm.disabledDates
                                              },
                                              on: {
                                                selected:
                                                  _vm.processingDateChanged
                                              },
                                              model: {
                                                value: props.row.processingDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    props.row,
                                                    "processingDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "props.row.processingDate"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : props.column.field === "viewPayerBtn"
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "editPayer",
                                                    params: {
                                                      ukPayerId:
                                                        props.row.ukPayerId
                                                    }
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("View Payer")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.formattedRow[
                                                  props.column.field
                                                ]
                                              ) +
                                              " "
                                          )
                                        ])
                                  ])
                                : _c("span", [
                                    props.column.field === "viewPayerBtn"
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "editPayer",
                                                    params: {
                                                      ukPayerId:
                                                        props.row.ukPayerId
                                                    }
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("View Payer")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : props.column.field === "amount"
                                      ? _c("span", [
                                          props.row.pendingVariableAmount ||
                                          props.row.amount == 0
                                            ? _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover.top.d500",
                                                    value:
                                                      "Variable payment amount required, 0 amounts are invalid under BACS rules.",
                                                    expression:
                                                      "'Variable payment amount required, 0 amounts are invalid under BACS rules.'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      d500: true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "fa fa-exclamation-circle text-warning mr-2"
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.formattedRow[
                                                  props.column.field
                                                ]
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.formattedRow[
                                                  props.column.field
                                                ]
                                              ) +
                                              " "
                                          )
                                        ])
                                  ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "emptystate" }, slot: "emptystate" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vgt-center-align vgt-text-disabled"
                            },
                            [_vm._v(" No Flagged Payments Found ")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          attrs: { slot: "table-actions" },
                          slot: "table-actions"
                        },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top.d500",
                                  value: "Clear filters",
                                  expression: "'Clear filters'",
                                  modifiers: {
                                    hover: true,
                                    top: true,
                                    d500: true
                                  }
                                }
                              ],
                              staticClass: "btn btn-link",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.clearTableFilters($event)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "fa-stack",
                                  staticStyle: { "font-size": "10px" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-filter fa-stack-1x dimmedIcon"
                                  }),
                                  _c("i", {
                                    staticClass:
                                      "fa fa-ban fa-stack-2x dimmedIcon"
                                  })
                                ]
                              ),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Clear filters")
                              ])
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top.d500",
                                  value: "Refresh the data in the table",
                                  expression: "'Refresh the data in the table'",
                                  modifiers: {
                                    hover: true,
                                    top: true,
                                    d500: true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "link",
                                disabled: _vm.isLoading
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.load($event)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-sync pointer dimmedIcon"
                              }),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Refresh Table")
                              ])
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top.d500",
                                  value: "Export the contents of the table",
                                  expression:
                                    "'Export the contents of the table'",
                                  modifiers: {
                                    hover: true,
                                    top: true,
                                    d500: true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "link",
                                disabled: _vm.isLoading
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.exportTable($event)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-share-square pointer dimmedIcon"
                              }),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Export Table")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "idb-block-footer mt-3" },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: {
                        type: "link",
                        variant: "primary",
                        disabled: _vm.isLoading || !_vm.hasChanges
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveSubmissionChanges($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save mr-2",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v("Save Changes ")
                    ]
                  ),
                  _c(
                    "b-btn",
                    {
                      staticClass: "float-right",
                      attrs: {
                        type: "link",
                        variant: "danger",
                        disabled: _vm.isLoading || !_vm.hasChanges
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.cancelSubmissionChanges($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-times mr-2" }),
                      _vm._v("Cancel Changes ")
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }