var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              _vm.checkValidation() && _vm.saveAntiFraudList()
            }
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c(
                "h2",
                [
                  _vm._v(" " + _vm._s(_vm.status) + " Anti-Fraud List "),
                  _c("help-icon", { attrs: { docPath: _vm.docPath } })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "idb-block-content" },
              [
                _vm.antiFraudList.isItemActioned
                  ? _c("div", { staticClass: "alert alert-warning" }, [
                      _vm._v(
                        "This anti-fraud list has a pending action against it and cannot be edited"
                      )
                    ])
                  : _vm._e(),
                _c(
                  "b-tabs",
                  {
                    model: {
                      value: _vm.tabIndex,
                      callback: function($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Anti-Fraud List Details",
                          "title-link-class": {
                            invalid:
                              _vm.$v.antiFraudList.mainAntiFraudListValidation
                                .$error
                          },
                          active: ""
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: { invalid: _vm.$v.antiFraudList.name.$error }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label col-md-3 required"
                              },
                              [_vm._v("Name")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.$v.antiFraudList.name.$model,
                                      expression:
                                        "$v.antiFraudList.name.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  ref: "defaultElement",
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.antiFraudList.isItemActioned
                                  },
                                  domProps: {
                                    value: _vm.$v.antiFraudList.name.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.antiFraudList.name,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _c("validation-messages", {
                                  attrs: { name: "name" },
                                  model: {
                                    value: _vm.$v.antiFraudList.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.antiFraudList,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "$v.antiFraudList.name"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: {
                              invalid: _vm.$v.antiFraudList.description.$error
                            }
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "col-form-label col-md-3" },
                              [_vm._v("Description")]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.antiFraudList.description.$model,
                                      expression:
                                        "$v.antiFraudList.description.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    rows: "3",
                                    disabled: _vm.antiFraudList.isItemActioned
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.antiFraudList.description.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.antiFraudList.description,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _c("validation-messages", {
                                  attrs: { name: "description" },
                                  model: {
                                    value: _vm.$v.antiFraudList.description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.antiFraudList,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "$v.antiFraudList.description"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group row",
                            class: {
                              invalid: _vm.$v.antiFraudList.listType.$error
                            }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "label-control col-md-3 required"
                              },
                              [_vm._v("List Type")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-md-3",
                                on: {
                                  mouseover: _vm.mouseoverListType,
                                  mouseout: _vm.mouseoutListType
                                }
                              },
                              [
                                _c(
                                  "p-radio",
                                  {
                                    staticClass: "p-default p-round",
                                    attrs: {
                                      value: "Disallowed List",
                                      disabled: _vm.disableListType
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onListTypeChange(
                                          _vm.$v.antiFraudList.listType
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.antiFraudList.listType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.antiFraudList,
                                          "listType",
                                          $$v
                                        )
                                      },
                                      expression: "antiFraudList.listType"
                                    }
                                  },
                                  [_vm._v("Disallowed List")]
                                ),
                                _c(
                                  "p-radio",
                                  {
                                    staticClass: "p-default p-round",
                                    attrs: {
                                      value: "Allowed List",
                                      disabled: _vm.disableListType
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onListTypeChange(
                                          _vm.$v.antiFraudList.listType
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.antiFraudList.listType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.antiFraudList,
                                          "listType",
                                          $$v
                                        )
                                      },
                                      expression: "antiFraudList.listType"
                                    }
                                  },
                                  [_vm._v("Allowed List")]
                                ),
                                _c("validation-messages", {
                                  attrs: { name: "anti-fraud list type" },
                                  model: {
                                    value: _vm.$v.antiFraudList.listType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.antiFraudList,
                                        "listType",
                                        $$v
                                      )
                                    },
                                    expression: "$v.antiFraudList.listType"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.showAntiFraudGroupWarning,
                                expression: "this.showAntiFraudGroupWarning"
                              }
                            ],
                            staticClass: "form-group row"
                          },
                          [
                            _c("span", { staticClass: "alert alert-warning" }, [
                              _vm._v(_vm._s(_vm.antiFraudGroupText))
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Anti-Fraud List Items" } },
                      [
                        _c("antiFraudListItems", {
                          attrs: {
                            status: _vm.status,
                            selectedAntiFraudListId: _vm.antiFraudList.id,
                            isItemActioned: _vm.antiFraudList.isItemActioned,
                            antiFraudList: _vm.antiFraudList
                          },
                          on: { itemsChanged: _vm.updateItemsChanged }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "idb-block-footer" }, [
              _vm.canManageAntiFraud
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.disableSave, type: "submit" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.status === "Edit" ? "Save" : _vm.status)
                      )
                    ]
                  )
                : _vm._e(),
              _vm.status === "Edit"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary ml-3",
                      attrs: {
                        disabled:
                          _vm.antiFraudList.isItemActioned || _vm.callingApi,
                        type: "button"
                      },
                      on: { click: _vm.showCloneModel }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-layers rpad" }),
                      _vm._v("Clone ")
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger pull-right ml-3",
                  attrs: { disabled: _vm.callingApi, type: "button" },
                  on: { click: _vm.cancelChanges }
                },
                [_vm._v("Cancel")]
              ),
              _vm.canManageAntiFraud && _vm.status === "Edit"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger pull-right",
                      attrs: {
                        disabled:
                          _vm.antiFraudList.isItemActioned || _vm.callingApi,
                        type: "button"
                      },
                      on: { click: _vm.deleteAntiFraudList }
                    },
                    [
                      _c("i", { staticClass: "glyphicon ti-trash rpad" }),
                      _vm._v("Delete Anti-Fraud List ")
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "clone-modal",
          attrs: {
            id: "cloneModal",
            title: "Clone Anti-Fraud List",
            "ok-title": "Clone",
            "cancel-variant": "secondary-outline",
            "no-close-on-backdrop": "",
            centered: true
          },
          on: {
            ok: _vm.cloneAntiFraudList,
            hidden: _vm.resetModal,
            shown: _vm.setCloneModalFocus
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "form-group row",
              class: { invalid: _vm.$v.antiFraudList.clonedName.$error }
            },
            [
              _c("label", { staticClass: "col-form-label col-md-3 required" }, [
                _vm._v("New Name")
              ]),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.antiFraudList.clonedName.$model,
                        expression: "$v.antiFraudList.clonedName.$model",
                        modifiers: { trim: true }
                      }
                    ],
                    ref: "defaultElement",
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.$v.antiFraudList.clonedName.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.antiFraudList.clonedName,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _c(
                    "validation-messages",
                    {
                      attrs: { name: "new name" },
                      model: {
                        value: _vm.$v.antiFraudList.clonedName,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.antiFraudList, "clonedName", $$v)
                        },
                        expression: "$v.antiFraudList.clonedName"
                      }
                    },
                    [
                      _vm.$v.antiFraudList.clonedName.notSameAs !== undefined &&
                      !_vm.$v.antiFraudList.clonedName.notSameAs
                        ? _c("small", { staticClass: "form-text small" }, [
                            _vm._v(
                              "Cloned name cannot be the same as the original name"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }