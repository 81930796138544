<template>
  <b-modal
    id="edit-bureau-job-detail"
    ref="editBureauJobDetailModal"
    title="Edit Bureau Job Detail"
    size="xl"
    hide-header-close
    cancel-variant="danger"
    static
    :ok-disabled="!canManage"
    @shown="load"
    @ok="editJobDetail"
  >
    <div slot="modal-ok">Save</div>

    <fileBrowser v-if="showFileBrowser" @close="showFileBrowser = false; onCloseFileBrowser()"></fileBrowser>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.bureauCustomerId">Bureau Customer</label>
      </b-col>
      <b-col sm="6">
        <vue-select
          single
          v-model="selectedBureauCustomer"
          :options="bureauCustomers"
          label="name"
          :closeOnSelect="true"
          @input="CheckBureauCustomerOnSelect"
          :clearable="true"
          :disabled="!canManage"
        ></vue-select>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.paymentfile">Payment File</label>
      </b-col>
      <b-col sm="6">
        <b-form-input
          ref="filename"
          type="text"
          @input="onFilenameChanged()"
          @keypress="SetFilenameManualInput($event)"
          v-model.trim="$v.jobDetailRecord.filename.$model"
          :disabled="!canManage"
        ></b-form-input>
        <span v-if="showDuplicateWarning">
          <br />
          <span class="text-danger small">
            This job already contains a filename with this bureau customer
            <br />
          </span>
        </span>
        <span class="small" v-if="jobDetailRecord.filename.length > 0">Example: {{ parsedFilename }}</span>
        <span v-if="!filenameValid">
          <br />
          <span class="text-danger small">This filename is not valid</span>
        </span>
      </b-col>
      <b-col sm="2">
        <button class="btn btn-outline-primary ml-3" type="button" @click="onShowFileBrowser"           :disabled="!canManage">
          <i class="glyphicon ti-layers rpad"></i>Browse Folder
        </button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="9" offset-sm="3">
        <label>
          <a class="text-primary small" @click="addLookup('[CUSTOMERREF]')">[CUSTOMERREF]</a>
          will be replaced with Customer Reference
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label>
          <a class="text-primary small" @click="addLookup('[SUN]')">[SUN]</a>
          will be replaced with customers Service Reference Number
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label>
          <a
            class="text-primary small"
            @click="addLookup('[DATETIME, DD-MM-YYYY]')"
          >[DATETIME, FORMAT]</a>
          will be replaced with the current formatted date/time
        </label>
      </b-col>
      <b-col sm="9" offset-sm="3">
        <label class="small">* and ? can be used as wildcards</label>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.importSchema">Import Schema</label>
      </b-col>
      <b-col sm="5">
        <b-form-select v-model="jobDetailRecord.importSchema" :options="this.schemas"          :disabled="!canManage"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="jobDetailRecord.importSchema === 'Mapping'">
      <b-col sm="3" class="mt-2">
        <label for="jobDetailRecord.mapping">Mapping</label>
      </b-col>
      <b-col sm="5">
        <b-form-select v-model="jobDetailRecord.mapping" :options="this.mappings"           :disabled="!canManage"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Utility from '@/Assets/Mixins/Utility'
import { maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import VueSelect from 'vue-select'
import _ from 'lodash'
import fileBrowser from '@/Views/Workflow/Shared/FileBrowser.vue'

export default {
  mixins: [Utility, loading],
  components: {
    fileBrowser,
    VueSelect
  },
  props: ['bureauJobId', 'bureauCustomers', 'bureauJobDetailId', 'duplicateMode'],
  data () {
    return {
      jobDetailRecord: {
        filename: '',
        mapping: null,
        importSchema: ''
      },
      FilenameHasManualInput: false,
      mappings: [],
      showDuplicateWarning: false,
      parsedFilename: '',
      filenameValid: false,
      selectedBureauCustomer: {},
      showFileBrowser: false,
      selectedNode: {},
      schemas: []
    }
  },
  computed: {
    canManage () {
      return this.$store.getters.isInRole('ManageBureauJobs')
    }
  },
  async created () {
    this.schemas = this.loadSchemas()
    this.mappings = await this.loadMappings('bacs')
  },

  methods: {
    async load () {
      this.FilenameHasManualInput = false
      this.filenameValid = true
      await this.getExistingJobDetail()
    },
    SetFilenameManualInput: function ($event) {
      this.FilenameHasManualInput = true
    },

    onShowFileBrowser () {
      this.showFileBrowser = true
    },

    onCloseFileBrowser () {
      var filename = this.$store.state.mapping.modalPopupString.substring(this.$store.state.mapping.modalPopupString.lastIndexOf('\\') + 1)
      if (!this.pathHasFileExtension(filename)) {
        filename += '/*'
      }
      this.jobDetailRecord.filename = filename
      this.FilenameHasManualInput = true
    },

    pathHasFileExtension: function (path) {
      return path.slice((path.lastIndexOf('.') - 1 >>> 0) + 2)
    },

    async getExistingJobDetail () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/getbureaujobdetail/' + this.bureauJobDetailId, { showload: true })
      this.jobDetailRecord.filename = response.data.filename
      this.jobDetailRecord.importSchema = response.data.importSchema
      this.jobDetailRecord.mapping = response.data.mapping
      var bureauCustomerId = response.data.bureauCustomerId

      var bureauCustomerObject = _.find(this.bureauCustomers, function (item) {
        return item.bureauCustomerId === bureauCustomerId
      })
      if (bureauCustomerObject === undefined) {
        this.selectedBureauCustomer = { id: 'ffffffff-ffff-ffff-ffff-ffffffffffff' }
      } else {
        this.selectedBureauCustomer = bureauCustomerObject
      }

      this.onFilenameChanged()
    },

    async updateJobDetail () {
      var payload = {
        jobid: this.bureauJobId,
        filename: this.jobDetailRecord.filename,
        importschema: this.jobDetailRecord.importSchema,
        mapping: this.jobDetailRecord.mapping,
        bureaucustomerid: this.selectedBureauCustomer.bureauCustomerId,
        bureauJobDetailId: this.bureauJobDetailId
      }
      console.log('update paylioad', payload)
      var data = JSON.stringify(payload)
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/insertupdatebureaujobdetail', data, { showload: true })

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)
        if (this.cloning) {
          this.$router.push({ name: 'ManageJob', params: { id: this.response.bureauJobId } })
        } else {
          this.$emit('submit')
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    },

    async saveJobDetail () {
      if (!this.canManage) { return }
      var payload = {
        jobid: this.bureauJobId,
        filename: this.jobDetailRecord.filename,
        importschema: this.jobDetailRecord.importSchema,
        mapping: this.jobDetailRecord.mapping,
        bureaucustomerid: this.selectedBureauCustomer.bureauCustomerId
      }
      var data = JSON.stringify(payload)
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/insertupdatebureaujobdetail', data, { showload: true })

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)

        if (this.cloning) {
          this.$router.push({ name: 'ManageJob', params: { id: this.response.bureauJobId } })
        } else {
          this.$emit('submit')
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    },

    async editJobDetail () {
      if (!this.canManage) { return }
      if (this.duplicateMode === 1) {
        await this.saveJobDetail()
      } else {
        await this.updateJobDetail()
      }
      this.$emit('submit')
    },

    async CheckBureauCustomerOnSelect (row) {
      if (!this.canManage) { return }
      if (row !== null) {
        if (!this.FilenameHasManualInput) {
          this.jobDetailRecord.filename = row.defaultImportFilename
        }
        if (row.defaultImportSchema !== 'None' && !(row === undefined || row === null || row === '')) {
          this.jobDetailRecord.importSchema = row.defaultImportSchema
        }

        await this.VerifyDuplicateData()

        // if (row.defaultImportFilename !== '') {
        //   this.jobDetailRecord.filename = row.defaultImportFilename
        // }
        // if (row.defaultImportSchema !== '') {
        //   this.jobDetailRecord.importSchema = row.defaultImportSchema
        // }

        if (this.jobDetailRecord.importSchema === 'Mapping') {
          var oid = row.defaultImportMapping
          var map = _.find(this.mappings, function (m) {
            if (m.value === oid) {
              return true
            }
          })

          this.jobDetailRecord.mapping = oid
        }
      } else {
        this.selectedBureauCustomer = { bureauCustomerId: 'ffffffff-ffff-ffff-ffff-ffffffffffff' }
      }
    },

    async VerifyDuplicateData () {
      if (!this.canManage) { return }
      var payload = {
        jobid: this.bureauJobId,
        filename: this.jobDetailRecord.filename,
        importschema: this.jobDetailRecord.importSchema,
        mapping: this.jobDetailRecord.mapping,
        bureaucustomerid: this.selectedBureauCustomer.bureauCustomerId,
        bureauJobDetailId: this.bureauJobDetailId
      }

      var data = JSON.stringify(payload)

      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/verifyduplicatejobdetail', data, { showload: true })
      if (response.data.toastType === 2 || response.data.toastType === 3) {
        this.showDuplicateWarning = false
      } else {
        this.showDuplicateWarning = true
      }
    },
    async addLookup (lookup) {
      this.jobDetailRecord.filename += lookup
      await this.onFilenameChanged()
      this.$refs.filename.$el.focus()
    },
    async onFilenameChanged () {
      let filename = this.jobDetailRecord.filename
      if (filename.length === 0) {
        this.FilenameHasManualInput = false
      }

      // Customer reference
      if (filename.includes('[CUSTOMERREF,')) {
        if (this.jobDetailRecord.customerReference !== 'undefined') {
          filename = filename.replace(/\[CUSTOMERREF\]/g, this.jobDetailRecord.customerReference)
        }
      }

      // Service user number
      if (filename.includes('[SUN,')) {
        if (this.jobDetailRecord.serviceUserNumber !== 'undefined') {
          filename = filename.replace(/\[SUN\]/g, this.selectedBureauCustomer.serviceUserNumber)
        }
      }

      // Date/Time
      if (filename.includes('[DATETIME,')) {
        const start = filename.indexOf('[DATETIME,')
        const end = filename.indexOf(']', start + 10)
        const stringToReplace = filename.substr(start, end - start + 1)
        console.info(`string to replace is: ${stringToReplace}`)
        const format = filename.substr(start + 11, end - start - 11)
        console.info(`format is "${format}"`)
        const value = moment().format(format)
        filename = filename.replace(stringToReplace, value)
        console.info(`${value}`)
      }
      this.parsedFilename = filename
      this.filenameValid = this.validateFilename(filename)
      console.info(`Parsed filename is: ${filename}`)

      await this.VerifyDuplicateData()
    },
    validateFilename (filename) {
      // Check length
      if (!filename || filename.length > 255) {
        return false
      }

      // Check for ..
      if (filename.includes('..')) {
        return false
      }

      // Check for \\
      if (filename.includes('\\\\')) {
        return false
      }

      // Check for ports
      if (/^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i.test(filename)) {
        return false
      }

      return true
    }
  },
  validations: {
    jobDetailRecord: {
      filename: { maxLength: maxLength(255) }
    }
  }

}

</script>
