<template>
  <div id="tabs">
    <b-tabs>
      <b-tab title="First" active>
        <p> Basic tabs example using <code>.nav-tabs</code> class. Also requires base <code>.nav</code> class. </p>
      </b-tab>
      <b-tab title="Second">
        <p>This style Should Apply same for all the possible variations of this sections.</p>
      </b-tab>
      <b-tab title="disabled" disabled>
        <p> Basic tabs example using <code>.nav-tabs</code> class. Also requires base <code>.nav</code> class.</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  components: {},
  data: function () {
    return {}
  }
}
</script>

<style>

</style>
