import axios from 'axios'
const state = {
  groupConfig: {},
  allGroups: null
}

const mutations = {
  setGroupConfig (state, groupConfig) {
    state.groupConfig = groupConfig
  },
  setAllGroups (state, groups) {
    state.allGroups = groups
  }
}

const getters = {
  collectionsGroupConfig: (state) => {
    return state.groupConfig
  },
  collectionsAllGroups: (state) => {
    return state.allGroups
  }
}

const actions = {
  getGroupConfigurationFromGroupId: async ({ commit, rootState }, request) => {
    var result = null
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config/${request.id}`)
      result = response.data
      commit('setGroupConfig', response.data)
    } catch (err) {
      console.log(err)
      result = { error: true, exception: err }
    }
    return result
  },
  getGroupConfigurationFromPayerId: async ({ commit, rootState }, request) => {
    var result = null
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config/payer/${request.id}`)
      result = response.data
      commit('setGroupConfig', response.data)
    } catch (err) {
      console.log(err)
      result = { error: true, exception: err }
    }
    return result
  },
  populateAllCollectionsGroups: async ({ commit }, request) => {
    var result = null
    try {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}config`, { showLoad: true })
      result = response.data
      commit('setAllGroups', response.data)
    } catch (err) {
      console.log(err)
      result = { error: true, exception: err }
    }
    return result
  }
}
export default { actions, state, mutations, getters }
