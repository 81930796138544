var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block dash-card widget" }, [
    _c("div", { staticClass: "idb-block-content widget" }, [
      _vm.editable && !_vm.widget.selected
        ? _c("div", { staticClass: "button-block" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-danger float-right",
                on: { click: _vm.remove }
              },
              [_c("span", { staticClass: "fa fa-fw fa-times" })]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-info pull-right mr-10",
                on: { click: _vm.settings }
              },
              [_c("span", { staticClass: "fa fa-fw fa-cog" })]
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { ref: "fullscreen", staticStyle: { height: "100%" } },
        [_vm._t("default")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }