var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [
      _vm._v("Approve Submission"),
      _c("span", { staticClass: "pull-right" }, [
        _c(
          "a",
          {
            attrs: {
              href:
                _vm.docUrl +
                "/automation/paygate-workflow/workflow-nodes/bacsautomation/approvesubmission/",
              target: "_blank"
            }
          },
          [_c("i", { staticClass: "far fa-question-circle" })]
        )
      ])
    ]),
    _c("hr"),
    _c("br"),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Approval Method")]),
        _c("br"),
        _c(
          "p-radio",
          {
            attrs: { value: "Auto Approval", color: "primary" },
            model: {
              value: _vm.selectedNode.props.s2.value,
              callback: function($$v) {
                _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
              },
              expression: "selectedNode.props.s2.value"
            }
          },
          [_vm._v("Auto Approval")]
        ),
        _c(
          "p-radio",
          {
            attrs: { value: "Manual (User) Approval", color: "primary" },
            model: {
              value: _vm.selectedNode.props.s2.value,
              callback: function($$v) {
                _vm.$set(_vm.selectedNode.props.s2, "value", $$v)
              },
              expression: "selectedNode.props.s2.value"
            }
          },
          [_vm._v("Manual (User) Approval")]
        )
      ],
      1
    ),
    _c("br"),
    _c("div", { staticClass: "spacer50" }),
    _c("hr"),
    _c(
      "div",
      { staticClass: "pull-right" },
      [
        _c(
          "b-button",
          {
            staticClass: "btnPad",
            attrs: { variant: "danger" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnCancel($event)
              }
            }
          },
          [_vm._v(" Cancel ")]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "success" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.btnSave($event)
              }
            }
          },
          [_vm._v(" OK ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }