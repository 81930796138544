var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showControlBar
    ? _c("div", { staticClass: "vgt-global-search vgt-clearfix" }, [
        _c("div", { staticClass: "vgt-global-search__input vgt-pull-left" }, [
          _vm.searchEnabled
            ? _c(
                "form",
                {
                  attrs: { role: "search" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("label", { attrs: { for: _vm.id } }, [
                    _vm._m(0),
                    _c("span", { staticClass: "sr-only" }, [_vm._v("Search")])
                  ]),
                  _c("input", {
                    staticClass: "vgt-input vgt-pull-left",
                    attrs: {
                      id: _vm.id,
                      type: "text",
                      placeholder: _vm.globalSearchPlaceholder
                    },
                    domProps: { value: _vm.value },
                    on: {
                      input: function($event) {
                        return _vm.updateValue($event.target.value)
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.entered($event.target.value)
                      }
                    }
                  })
                ]
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "vgt-global-search__actions vgt-pull-right" },
          [_vm._t("internal-table-actions")],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input__icon", attrs: { "aria-hidden": "true" } },
      [_c("div", { staticClass: "magnifying-glass" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }