<template>
  <div>
     <b-row mb="4">
        <b-col xs="12" sm="12" md="12" lg="12">
          <div class="idb-block">
              <div class="idb-block-title">
                <h2><span class="pull-right"><a href="#" target="_blank"><i class="far fa-question-circle"></i></a></span>View SFTP Request</h2>
              </div>
              <div class="idb-block-content">
                <table class="mb-3">
                  <tr><td class="pr-5 font-weight-bold">User Name</td><td>{{sftpRequest ? sftpRequest.userName : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Customer Name</td><td>{{sftpRequest ? sftpRequest.customerName: 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Customer code</td><td>{{sftpRequest ? sftpRequest.customerCode : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Sent Date</td><td>{{sftpRequest ? formatDate(sftpRequest.timeRequested) : 'Loading'}}</td></tr>
                  <tr v-if="false"><td class="pr-5 font-weight-bold">Email Address</td><td>{{sftpRequest ? sftpRequest.emailAddress : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Pem Key (to send to client)</td><td>
                    <div class="input-group">
                      <input
                        :type="pinRevealed? 'text': 'password'"
                        readonly
                        class="form-control"
                        v-model="sftpRequest.pemPass"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          @click.prevent="showKey"
                        >{{ pinRevealed ? 'Hide' : 'Reveal' }}</button>
                        <button
                          class="btn"
                          :class="pinRevealed ? 'btn-outline-primary' : ' btn-outline-secondary'"
                          type="button"
                          :disabled="!pinRevealed"
                          @click="copyPin"
                        >Copy Key</button>
                      </div>
                    </div>
                  </td></tr>
                  <tr><td class="pr-5 font-weight-bold">Actioned</td><td>{{sftpRequest ? sftpRequest.actioned : 'Loading'}}</td></tr>
                  <tr><td class="pr-5 font-weight-bold">Public Key</td><td><b-button variant="success" @click="savePublicKey"  size="sm"><i class="fas fa-save mr-2"></i>Save</b-button></td></tr>
                </table>
              </div>
              <div class="idb-block-footer noprint-footer">
                <b-button variant="primary" :disabled="sftpRequest.actioned" @click="setToActioned">Mark as actioned</b-button>
              </div>
          </div>
        </b-col>
     </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import { saveAs } from 'file-saver'
export default {
  props: {
    requestId: String
  },
  data () {
    return {
      sftpRequest: {
        agentSftpDetailsRequestId: null,
        userId: null,
        paygateId: null,
        timeRequested: null,
        userName: null,
        customerName: null,
        customerCode: null,
        emailAddress: null,
        publicKey: null,
        actioned: false,
        pemPass: null
      },
      pinRevealed: false,
    }
  },
  methods: {
    async getData () {
      var response = await axios.get(`${process.env.VUE_APP_AGENT_API_URL}sftprequests/` + this.requestId)
      if (response !== null) {
        this.sftpRequest = response.data
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      try {
        if (!date.getDate) {
          date = new Date(date)
        }
        var day = date.getDate()
        var monthIndex = date.getMonth() + 1
        var year = date.getFullYear()
        return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
      } catch (e) {
        return 'Invalid Date'
      }
    },
    async setToActioned () {
      var response = await axios.post(`${process.env.VUE_APP_AGENT_API_URL}sftprequests/${this.requestId}/actioned`, {})
      if (response !== null) {
        if(response.data === true) {
            this.$toastr.s('Request set to actioned.')
        } else {
            this.$toastr.e('Failed to set request to actioned.')
        }
      }
    },
    savePublicKey () {
        var blob = new Blob([this.sftpRequest.publicKey])
      if (window.navigator.msSaveOrOpenBlob) {        
        window.navigator.msSaveBlob(blob, `${this.sftpRequest.userName.replace('@', '-')}-public-key.pub`)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = `${this.sftpRequest.userName.replace('@', '-')}-public-key.pub`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    async showKey () {
      if (this.pinRevealed) {
        this.pinRevealed = false
      } else {
        this.pinRevealed = true
      }
    },
    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement("textarea")
      textArea.value = text
      
      // Avoid scrolling to bottom
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')       
        this.$toastr.s('The SFTP Key PIN has been copied', 'PIN Copied')
      } catch (err) {
        this.$toastr.e('Unable to Copy PIN', 'PIN Failed to Copy')
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      var toastr = this.$toastr
      navigator.clipboard.writeText(text).then(function() {
        toastr.s('The SFTP Key PIN has been copied', 'PIN Copied')
      }, function(err) {
        toastr.e('Unable to Copy PIN', 'PIN Failed to Copy')
      })
    },
    copyPin () {
      this.copyTextToClipboard(this.sftpRequest.pemPass)
    }
  },
  async mounted () {
    this.getData()
  }
}
</script>
