var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Service User Number")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.serviceUserNumber, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Import Schema")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.importSchema, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionTypeDetails.importSchema === "Mapping"
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Mapping")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.mappingId, function(diff, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Bank Account")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.bankAccountId, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Payment Signing Method")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.tokenType, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Individual Payment Limit")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.paymentLimit, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Submission Payment Limit")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.submissionLimit, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Group Email Address")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.email, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Segregate Group Roles")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.segregateGroupRoles, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.bacsLicenceSettings.proxyGroup === true,
            expression: "bacsLicenceSettings.proxyGroup === true"
          }
        ],
        staticClass: "form-group row"
      },
      [
        _c(
          "label",
          {
            staticClass: "col-sm-2 text-right col-form-label",
            attrs: { for: "description" }
          },
          [_vm._v("Allow Multi-Block")]
        ),
        _c("div", { staticClass: "col-sm-10" }, [
          _c(
            "div",
            { staticClass: "form-control-plaintext" },
            _vm._l(_vm.difference.proxyGroup, function(diff, index) {
              return _c(
                "span",
                {
                  key: index,
                  class: { ins: diff.added, del: diff.removed },
                  staticStyle: { "margin-right": "5px" }
                },
                [_vm._v(_vm._s(diff.value))]
              )
            }),
            0
          )
        ])
      ]
    ),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Delete Payment Data")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.deletePaymentData, function(diff, index) {
            return _c(
              "span",
              {
                key: index,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }