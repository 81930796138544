<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="hasErrors">
          <div v-for="(error, index) in errors" :key="index">
            <ul>
              <li v-if="error.message">{{error.type}} - {{error.message}}</li>
              <li v-else>{{error}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="checkValidation() && save()" novalidate>
      <crud-policy-form
        :formType="'Creation'"
        :details="crudPolicyDetails"
        :policy="$v.policy.accountCreatePolicy"
        docPath="/administration/securitypolicy/useraccounts/#create"
      ></crud-policy-form>
      <crud-policy-form
        :formType="'Updates'"
        :details="crudPolicyDetails"
        :policy="$v.policy.accountEditPolicy"
        docPath="/administration/securitypolicy/useraccounts/#edit"
      ></crud-policy-form>
      <crud-policy-form
        :formType="'Removal'"
        :details="crudPolicyDetails"
        :policy="$v.policy.accountDeletePolicy"
        docPath="/administration/securitypolicy/useraccounts/#removal"
      ></crud-policy-form>
      <div class="idb-block">
        <div class="idb-block-footer">
          <button type="submit" class="btn btn-primary" :disabled="isLoading">Save</button>
          <button
            type="button"
            class="btn btn-outline-warning ml-3"
            @click="reset"
            :disabled="isLoading"
          >Reset to default</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import CrudPolicyForm from '@/Components/Platform/SecurityPolicy/CrudPolicyForm'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import { required, minValue, maxValue, requiredIf, alphaNum } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import loading from '@/Assets/Mixins/LoadingMixin'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [DataLeaveMixin, loading],
  components: {
    CrudPolicyForm
  },
  computed: {
    hasErrors () { return this.errors.length > 0 },
    ...mapGetters(['selectedCustomer'])
  },
  watch: {
    selectedCustomer () {
      this.load()
    }
  },
  data () {
    return {
      errors: [],
      crudPolicyDetails: {
        areaName: 'User Account',
        role: 'Manage Users',
        users: []
      },
      policy: {
        accountCreatePolicy: {
          behaviour: 'Default',
          customBehaviour: 'Administrators',
          numberOfApprovalUsers: 1,
          approvalUsers: [],
          canApproveOwnCreation: false,
          selfApprovalUsers: [],
          approvalMethod: 'Via Action Item'
        },
        accountEditPolicy: {
          behaviour: 'Default',
          customBehaviour: 'Administrators',
          numberOfApprovalUsers: 1,
          approvalUsers: [],
          canApproveOwnCreation: false,
          selfApprovalUsers: [],
          approvalMethod: 'Via Action Item'
        },
        accountDeletePolicy: {
          behaviour: 'Default',
          customBehaviour: 'Administrators',
          numberOfApprovalUsers: 1,
          approvalUsers: [],
          canApproveOwnCreation: false,
          selfApprovalUsers: [],
          approvalMethod: 'Via Action Item'
        }
      },
      customerAdmins: 0,
      roles: 0
    }
  },
  async created () {
    await this.load()
  },
  methods: {
    async save () {
      try {
        this.errors = []
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/UserAccounts`, this.policy,
          { showload: true, showerror: true, errormessage: 'User accounts security policy failed to save' })
        if (response.data.error) {
          this.errors = response.data.errors
          this.$toastr.w('Unable to save due to conflicts', 'Not Saved')
        } else {
          this.$toastr.s(this.crudPolicyDetails.areaName + ' policy changes have been saved', 'Saved')
        }
      } catch (e) {
        if (e.response.status === 422) {
          this.errors = e.response.data
          this.$toastr.e('There are errors on the page, please see the top for more information', 'Validation Error')
        }
      } finally {
        this.$v.$reset()
      }
    },
    async reset () {
      this.$v.$reset()
      this.policy = {
        accountCreatePolicy: {
          behaviour: 'Default',
          customBehaviour: 'Administrators',
          numberOfApprovalUsers: 1,
          approvalUsers: [],
          canApproveOwnCreation: false,
          selfApprovalUsers: [],
          approvalMethod: 'Via Action Item'
        },
        accountEditPolicy: {
          behaviour: 'Default',
          customBehaviour: 'Administrators',
          numberOfApprovalUsers: 1,
          approvalUsers: [],
          canApproveOwnCreation: false,
          selfApprovalUsers: [],
          approvalMethod: 'Via Action Item'
        },
        accountDeletePolicy: {
          behaviour: 'Default',
          customBehaviour: 'Administrators',
          numberOfApprovalUsers: 1,
          approvalUsers: [],
          canApproveOwnCreation: false,
          selfApprovalUsers: [],
          approvalMethod: 'Via Action Item'
        }
      }
      this.errors = []
      this.$toastr.i(this.crudPolicyDetails.areaName + ' policy changes have been reset, please save to apply', 'Reset')
    },
    async load () {
      try {
        await this.$store.dispatch('loadTwoFactorOptions')
        this.crudPolicyDetails.users = await this.$store.dispatch('getUsersAsDropDownSource')
        var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/UserAccounts`,
          { showload: true, showerror: true, errormessage: 'User accounts security policy failed to load' })
        this.policy = response.data
        var adminResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/GetUsersInRole`, { params: { roleName: roles.CustomerAdministrator } })
        var roleResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}SecurityPolicy/GetUsersInRole`, { params: { roleName: roles.ManageUsers } })
        this.customerAdmins = adminResponse.data
        this.roles = roleResponse.data
      } catch { }
    }
  },
  validations () {
    return {
      policy: {
        accountCreatePolicy: {
          behaviour: {},
          numberOfApprovalUsers: {
            required,
            numeric: alphaNum,
            minValue: (value, model) => {
              return model.behaviour === 'Default' ? true : minValue(1)(value)
            },
            maxValue: (value, model) => {
              if (model.behaviour === 'Default') {
                return true
              }

              switch (model.customBehaviour) {
                case 'SpecificUsers':
                  return maxValue(10)
                case 'Administrators':
                  return maxValue(this.customerAdmins)(value)
                case 'UsersInRole':
                  return maxValue(this.roles)(value)
              }
            }
          },
          customBehaviour: {},
          approvalUsers: {
            required: requiredIf(value => {
              return value.customBehaviour === 'SpecificUsers' && value.behaviour === 'Custom'
            }),
            min: (value, model) => {
              var minLength = model.customBehaviour === 'SpecificUsers' && model.behaviour === 'Custom' ? model.numberOfApprovalUsers : 0
              return value.length >= minLength
            }
          },
          canApproveOwnCreation: {},
          selfApprovalUsers: {
            required: requiredIf(value => value.canApproveOwnCreation)
          },
          approvalMethod: {}
        },
        accountEditPolicy: {
          behaviour: {},
          numberOfApprovalUsers: {
            required,
            numeric: alphaNum,
            minValue: (value, model) => {
              return model.behaviour === 'Default' ? true : minValue(1)(value)
            },
            maxValue: (value, model) => {
              if (model.behaviour === 'Default') {
                return true
              }

              switch (model.customBehaviour) {
                case 'SpecificUsers':
                  return maxValue(10)
                case 'Administrators':
                  return maxValue(this.customerAdmins)(value)
                case 'UsersInRole':
                  return maxValue(this.roles)(value)
              }
            }
          },
          customBehaviour: {},
          approvalUsers: {
            required: requiredIf(value => {
              return value.customBehaviour === 'SpecificUsers' && value.behaviour === 'Custom'
            }),
            min: (value, model) => {
              var minLength = model.customBehaviour === 'SpecificUsers' && model.behaviour === 'Custom' ? model.numberOfApprovalUsers : 0
              return value.length >= minLength
            }
          },
          canApproveOwnCreation: {},
          selfApprovalUsers: {
            required: requiredIf(value => value.canApproveOwnCreation)
          },
          approvalMethod: {}
        },
        accountDeletePolicy: {
          behaviour: {},
          numberOfApprovalUsers: {
            required,
            numeric: alphaNum,
            minValue: (value, model) => {
              return model.behaviour === 'Default' ? true : minValue(1)(value)
            },
            maxValue: (value, model) => {
              if (model.behaviour === 'Default') {
                return true
              }

              switch (model.customBehaviour) {
                case 'SpecificUsers':
                  return maxValue(10)
                case 'Administrators':
                  return maxValue(this.customerAdmins)(value)
                case 'UsersInRole':
                  return maxValue(this.roles)(value)
              }
            }
          },
          customBehaviour: {},
          approvalUsers: {
            required: requiredIf(value => {
              return value.customBehaviour === 'SpecificUsers' && value.behaviour === 'Custom'
            }),
            min: (value, model) => {
              var minLength = model.customBehaviour === 'SpecificUsers' && model.behaviour === 'Custom' ? model.numberOfApprovalUsers : 0
              return value.length >= minLength
            }
          },
          canApproveOwnCreation: {},
          selfApprovalUsers: {
            required: requiredIf(value => value.canApproveOwnCreation)
          },
          approvalMethod: {}
        }
      }
    }
  }
}
</script>
