var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Payment Approval Method")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.paymentApprovalMethod, function(diff) {
            return _c(
              "span",
              {
                key: diff.count,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ]),
    _vm.actionGroup.groupTypeDetails.paymentApprovalMethod ===
    "Number of Approvers"
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Number Of Approvers")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.numberOfApprovers, function(diff) {
                return _c(
                  "span",
                  {
                    key: diff.count,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: { "margin-right": "5px" }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _vm.actionGroup.groupTypeDetails.paymentApprovalMethod === "Approval Range"
      ? _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-sm-2 text-right col-form-label",
              attrs: { for: "description" }
            },
            [_vm._v("Approval Range")]
          ),
          _c("div", { staticClass: "col-sm-10" }, [
            _c(
              "div",
              { staticClass: "form-control-plaintext" },
              _vm._l(_vm.difference.approvalRanges, function(diff) {
                return _c(
                  "div",
                  {
                    key: diff.count,
                    class: { ins: diff.added, del: diff.removed },
                    staticStyle: {
                      "margin-right": "5px",
                      "white-space": "pre-wrap",
                      display: "inline-block"
                    }
                  },
                  [_vm._v(_vm._s(diff.value))]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group row" }, [
      _c(
        "label",
        {
          staticClass: "col-sm-2 text-right col-form-label",
          attrs: { for: "description" }
        },
        [_vm._v("Approval Method")]
      ),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "div",
          { staticClass: "form-control-plaintext" },
          _vm._l(_vm.difference.approvalMethod, function(diff) {
            return _c(
              "span",
              {
                key: diff.count,
                class: { ins: diff.added, del: diff.removed },
                staticStyle: { "margin-right": "5px" }
              },
              [_vm._v(_vm._s(diff.value))]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }