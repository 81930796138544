<template>
  <div>
    <b-row mb="4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              <span class="pull-right">
                <a href="#" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span>Email Bounces
            </h2>
          </div>
          <div class="idb-block-content">
            <p>Emails communications in which messages have been bounced are listed below.</p>
            <p>If a communication exceeds the maximum number of allowed bounces, the communication is halted, but can be restarted once all the bounced email addresses have been updated.</p>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-row-click="onRowClick"
              :columns="columns"
              :rows="bounceReports"
              :lineNumbers="true"
              :totalRows="totalRecords"
              :search-options="{
                    enabled: true
                    }"
              :pagination-options="{
                    enabled: true,
                    dropdownAllowAll: false,
                  }"
              styleClass="vgt-table striped bordered"
            >
              <template slot="loadingContent">
                <h1>Loading...</h1>
              </template>
              <div slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">No Payers Available</div>
              </div>
              <div slot="table-actions">
                <b-button
                  @click.prevent="reloadItems"
                  class
                  variant="link"
                  title="Refresh Table"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  title="Export Table"
                  v-b-popover.hover.top.d500="'Export the contents of the table'"
                >
                  <i class="fa fa-share-square pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
                </b-button>
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- <router-link :to="{ path: '/payer/' + props.row.ukPayerId + '/edit', params: { ukPayerId: props.row.ukPayerId }, query: { retPath: 'manage' }  }"> eslint-disable-line -->
                <span v-if="props.column.field === 'status'">{{ displayStatus(props.row.status) }}</span>
                <span v-else>{{ props.row[props.column.field] }}</span>
                <!-- </router-link> -->
              </template>
            </vue-good-table>
          </div>
          <div class="idb-block-footer noprint-footer"></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data () {
    return {
      bounceReports: [],
      columns: [
        {
          label: 'id',
          field: 'payerMessageBatchId',
          hidden: true
        },
        {
          label: 'Customer',
          field: 'customerName'
        },
        {
          label: 'Group',
          field: 'groupName'
        },
        {
          label: 'Sent Date',
          field: 'sentDate',
          formatFn: this.formatDate
        },
        {
          label: 'Total',
          field: 'total'
        },
        {
          label: '# Sent',
          field: 'sent'
        },
        {
          label: '# Bounced',
          field: 'bounced'
        },
        {
          label: 'Halted',
          field: 'halted'
        }
      ],
      totalRecords: 0,
      serverParams: {
        columnFilters: { },
        sort: {
          field: 'surname',
          type: 'ASC'
        },
        page: 1,
        perPage: 10,
        searchKey: ''
      }
    }
  },
  methods: {
    async getData () {
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}EmailReporting/`)
      if (response !== null) {
        this.bounceReports = response.data
      }
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year}`
    },
    printTable () {
      console.log('printTable not yet implemented')
    },
    exportTable () {
      console.log('exportTable not yet implemented')
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.reloadItems()
    },
    onSortChange (params) {
      this.updateParams({
        sort: {
          type: params.sortType,
          field: this.columns[params.columnIndex].field
        }
      })
      this.reloadItems()
    },
    onColumnFilter (params) {
      console.log('onColumnFilter not yet implemented')
      this.updateParams(params)
      this.reloadItems()
    },
    onPerPageChange (params) {
      console.log('onPerPageChange', params)
      this.updateParams({ perPage: params.currentPerPage })
      console.log('perpage', this.perPage)
      this.reloadItems()
    },
    onRowClick (params) {
      this.$router.push(`/admin/email/bounces/${params.row.payerMessageBatchId}`)
    },
    onCellClick (params) {
      this.selectedRow = this.tableData[params.rowIndex]
    },
    onSearch (params) {
      console.log('in search', params)
      this.serverParams.searchKey = params.searchTerm
      this.reloadItems()
    }
  },
  async mounted () {
    this.getData()
  }
}
</script>
