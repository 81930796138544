export default [
  {
    menu_title: 'Bureau Customers',
    menu_icon: 'ti-agenda',
    path: '/bureau/bureaucustomers/list'
    /* child_routes: [
      {
        path: '/bureau/bureaucustomers/create',
        menu_title: 'Create'
      },
      {
        path: '/bureau/bureaucustomers/list',
        menu_title: 'Manage'
      },
      {
        path: '/bureau/bureaucustomers/import',
        menu_title: 'Import',
        counterData: 'bureauCustomerImportPill',
        severityData: 'bureauCustomerImportSeverity'
      }
    ] */
  },
  {
    menu_title: 'Import Bureau Customers',
    menu_icon: 'ti-exchange-vertical',
    path: '/bureau/bureaucustomers/import',
    counterData: 'bureauCustomerImportPill',
    severityData: 'bureauCustomerImportSeverity'
  },
  {
    menu_title: 'Bureau Jobs',
    path: '/bureau/bureaujobs/managebureaujobs',
    menu_icon: 'ti-settings'
    /* child_routes: [
      {
        path: '/bureau/bureaujobs/create',
        menu_title: 'Create Bureau Job'
      },
      {
        path: '/bureau/bureaujobs/managebureaujobs',
        menu_title: 'Manage Bureau Job'
      }
    ] */
  },
  {
    menu_title: 'Bureau Builder',
    menu_icon: 'ti-control-play',
    path: '/bureau/bureauBuilder/ManualBacsSubmission'
    /* child_routes: [
      {
        path: '/bureau/bureauJob/automatedbacssubmission',
        menu_title: 'Bureau Job'
      },
      {

        menu_title: 'Bureau Builder'
      }

    ] */
  },
  // {
  //   menu_title: 'FPS Submission',
  //   menu_icon: 'ti-panel',
  //   child_routes: [
  //     {
  //       path: '/bureau/bureauJob/automatedfpssubmission',
  //       menu_title: 'Bureau Job'
  //     },
  //     {
  //       path: '/bureau/bureauBuilder/ManualFPSSubmission',
  //       menu_title: 'Bureau Builder'
  //     }

  //   ]
  // },
  {
    menu_icon: 'ti-save',
    path: '/bureau/bureaujobs/submissionsinprogress',
    menu_title: 'Submissions In Progress',
    counterData: 'bureauSubmissionPill'
  },
  {
    menu_title: 'Reports',
    menu_icon: 'ti-receipt',
    child_routes: [
      {
        path: '/bureau/bureaureports/bacsbureaureports?networkType=BacstelIp',
        menu_title: 'Bureau BACS Reports'
      },
      // {
      //   path: '/bureau/bureaureports/bacsbureaureports?networkType=SecureIp',
      //   menu_title: 'Bureau FPS Reports'
      // },
      {
        path: '/bureau/bureaureports/bureaucustomerreport',
        menu_title: 'Bureau Customer Report'
      }
    ]
  } // {
  //   menu_title: 'Usage Reports',
  //   menu_icon: 'ti-panel',
  //   child_routes: [
  //     // {
  //     //   path: '/bureau/bureauusagereports/usageoverdaterange',
  //     //   menu_title: 'Usage over Date Range'
  //     // },
  //     {
  //       path: '/bureau/bureauusagereports/licenseusage',
  //       menu_title: 'LicenseUsage'
  //     }
  //   ]

  // }

  // {
  //   menu_title: 'The Scenery Channel',
  //   menu_icon: 'ti-panel',
  //   child_routes: [
  //     {
  //       path: '/bureau/bureauscenerychannel/thescenerychannelworkflow',
  //       menu_title: 'The Scenery Channel Workflow'
  //     },
  //     {
  //       path: '/bureau/bureauscenerychannel/licensingtabletest',
  //       menu_title: 'Licensing Table'
  //     }
  //   ]
  // }
]
