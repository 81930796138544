var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "importedPayments" } }, [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Imported Files Summary "),
            _c("help-icon", { attrs: { docPath: "/bacsbureau/submissions/" } })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-content" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("span", [
                _c("b", [_vm._v("Submission Reference:")]),
                _vm._v(" " + _vm._s(_vm.submissionReference) + " ")
              ])
            ])
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("span", [
                _c("b", [_vm._v("Bureau Job Template:")]),
                _vm._v(" " + _vm._s(_vm.bureauJobName) + " ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "vue-good-table",
                  {
                    ref: "table",
                    attrs: {
                      paginationOptions: _vm.paginationOptions,
                      "sort-options": _vm.sortOptions,
                      rows: _vm.rows,
                      columns: _vm.columns,
                      searchOptions: { enabled: true },
                      mode: "remote",
                      totalRows: _vm.totalRecords,
                      isLoading: _vm.isTableLoading,
                      styleClass: "vgt-table striped bordered"
                    },
                    on: {
                      "on-page-change": _vm.onPageChange,
                      "on-per-page-change": _vm.onPerPageChange,
                      "on-search": _vm.onSearch,
                      "on-sort-change": _vm.onSortChange,
                      "update:isLoading": function($event) {
                        _vm.isTableLoading = $event
                      },
                      "update:is-loading": function($event) {
                        _vm.isTableLoading = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-row",
                        fn: function(props) {
                          return [
                            props.column.field === "filename"
                              ? _c("span", [
                                  props.row.bureauFileImportStatus === "Error"
                                    ? _c("div", {
                                        staticClass: "group-colour",
                                        style: { "background-color": "red" }
                                      })
                                    : _c("div", {
                                        staticClass: "group-colour",
                                        style: { "background-color": "green" }
                                      }),
                                  _vm._v(" " + _vm._s(props.row.filename) + " ")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "div",
                      {
                        attrs: { slot: "table-actions" },
                        slot: "table-actions"
                      },
                      [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top.d500",
                                value: "Clear filters",
                                expression: "'Clear filters'",
                                modifiers: {
                                  hover: true,
                                  top: true,
                                  d500: true
                                }
                              }
                            ],
                            staticClass: "btn btn-link",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.clearTableFilters($event)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fa-stack",
                                staticStyle: { "font-size": "10px" }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-filter fa-stack-1x dimmedIcon"
                                }),
                                _c("i", {
                                  staticClass:
                                    "fa fa-ban fa-stack-2x dimmedIcon"
                                })
                              ]
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Clear filters")
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "idb-block-footer" }, [
        _vm.canProceed
          ? _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ml-2",
                  attrs: { disabled: _vm.isTableLoading },
                  on: {
                    click: function($event) {
                      return _vm.onViewPaymentsClick()
                    }
                  }
                },
                [_vm._v("View Submission Details")]
              )
            ])
          : !_vm.canProceed && _vm.showTotalFailure
          ? _c("div", [
              _c("span", { staticClass: "fw-bold text-danger" }, [
                _vm._v(
                  "There are no viable payment files in this submission. The submission cannot proceed."
                )
              ]),
              _c("span", { staticClass: "col-sm-3 pull-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger pull-right",
                    attrs: { type: "button" },
                    on: { click: _vm.onCancelSubmission }
                  },
                  [_vm._v("Cancel Submission")]
                )
              ])
            ])
          : _c("div", { staticClass: "fw-bold text-warning" }, [
              _vm.finishedLoading
                ? _c("span", [
                    _vm._v(
                      "The submission still appears to be processing. Please wait and refresh."
                    )
                  ])
                : _vm._e()
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }