var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", size: "lg", "no-close-on-backdrop": "" },
      on: { hide: _vm.hide, shown: _vm.setFocus },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-header" },
          slot: "modal-header"
        },
        [_vm._v("Payment Details")]
      ),
      _vm.isMultiDate
        ? _c("div", { staticClass: "row mb-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value:
                      "This is the date the payment will be processed by Vocalink.",
                    expression:
                      "'This is the date the payment will be processed by Vocalink.'",
                    modifiers: { hover: true, top: true }
                  }
                ],
                staticClass: "col-md-3 modal-field required"
              },
              [
                _c("strong", [
                  _c("label", { attrs: { for: "paymentDate" } }, [
                    _vm._v("Payment Date")
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("vuejsDatepicker", {
                  ref: "paymentDate",
                  attrs: {
                    name: "paymentDate",
                    id: "paymentDate",
                    format: "dd/MM/yyyy",
                    "input-class": "form-control",
                    "bootstrap-styling": true,
                    disabledDates: _vm.disabledDates,
                    "monday-first": true,
                    disabled: !_vm.canSavePayment
                  },
                  on: { selected: _vm.paymentDateChanged },
                  nativeOn: {
                    focusin: function($event) {
                      return _vm.dateOnfocus($event)
                    }
                  },
                  model: {
                    value: _vm.paymentDate,
                    callback: function($$v) {
                      _vm.paymentDate = $$v
                    },
                    expression: "paymentDate"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "col-md-4" }, [
              _c("strong", [_vm._v("Processing Date")]),
              _vm._v(" " + _vm._s(_vm.processingDate) + " ")
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "This is the third party account name.",
                expression: "'This is the third party account name.'",
                modifiers: { hover: true, top: true }
              }
            ],
            staticClass: "col-md-3 required"
          },
          [
            _c("label", { attrs: { for: "accountName" } }, [
              _vm._v("Account Name")
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-4",
            class: { invalid: _vm.$v.accountName.$error }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim.lazy",
                  value: _vm.$v.accountName.$model,
                  expression: "$v.accountName.$model",
                  modifiers: { trim: true, lazy: true }
                }
              ],
              ref: "defaultElement",
              staticClass: "form-control",
              attrs: {
                id: "accountName",
                type: "text",
                disabled: !_vm.canSavePayment
              },
              domProps: { value: _vm.$v.accountName.$model },
              on: {
                change: [
                  function($event) {
                    _vm.$set(
                      _vm.$v.accountName,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.onChange(_vm.$v.accountName)
                  }
                ],
                blur: [
                  function($event) {
                    return _vm.onBlur(_vm.$v.accountName)
                  },
                  function($event) {
                    return _vm.$forceUpdate()
                  }
                ]
              }
            })
          ]
        ),
        _vm.$v.accountName.$dirty
          ? _c("span", [
              !_vm.$v.accountName.required
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [_vm._v("Account Name is required")]
                  )
                : _vm._e(),
              !_vm.$v.accountName.maxLen
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [
                      _vm._v(
                        "Maximum account name length is " +
                          _vm._s(_vm.$v.accountName.$params.maxLen.max) +
                          " characters"
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "This is the third party's sort code.",
                expression: "'This is the third party\\'s sort code.'",
                modifiers: { hover: true, top: true }
              }
            ],
            staticClass: "col-md-3 required"
          },
          [_c("label", { attrs: { for: "sortCode" } }, [_vm._v("Sort Code")])]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-3",
            class: { invalid: _vm.$v.sortCode.$error && _vm.sortCodeBlurFired }
          },
          [
            _c("the-mask", {
              staticClass: "form-control",
              attrs: {
                id: "sortCode",
                type: "text",
                mask: "##-##-##",
                disabled: !_vm.canSavePayment
              },
              nativeOn: {
                blur: function($event) {
                  return _vm.onSortCodeBlur(_vm.$v.sortCode)
                },
                focus: function($event) {
                  _vm.sortCodeBlurFired = false
                }
              },
              model: {
                value: _vm.$v.sortCode.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.sortCode,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.sortCode.$model"
              }
            })
          ],
          1
        ),
        _vm.bankDetailsValidated && _vm.validSortCode
          ? _c("div", { staticClass: "col-md-1" }, [
              _c("span", {
                staticClass: "fa fa-check-circle",
                staticStyle: { "font-size": "200%", color: "green" }
              })
            ])
          : _vm._e(),
        _vm.$v.sortCode.$dirty && _vm.sortCodeBlurFired
          ? _c("span", [
              !_vm.$v.sortCode.required
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [_vm._v("Sort Code is required")]
                  )
                : _vm._e(),
              !_vm.$v.sortCode.minLen || !_vm.$v.sortCode.maxLen
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [
                      _vm._v(
                        "Sort code must contain " +
                          _vm._s(_vm.$v.sortCode.$params.minLen.min) +
                          " numbers"
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.validSortCode
                ? _c(
                    "small",
                    { staticClass: "form-text text-warning small text-nowrap" },
                    [_vm._v(_vm._s(_vm.sortCodeError))]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "This is the third party's account number.",
                expression: "'This is the third party\\'s account number.'",
                modifiers: { hover: true, top: true }
              }
            ],
            staticClass: "col-md-3 required"
          },
          [
            _c("label", { attrs: { for: "accountNumber" } }, [
              _vm._v("Account Number")
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-3",
            class: {
              invalid: _vm.$v.accountNumber.$error && _vm.accountNumberBlurFired
            }
          },
          [
            _c("the-mask", {
              staticClass: "form-control",
              attrs: {
                id: "accountNumber",
                type: "text",
                mask: "########",
                disabled: !_vm.canSavePayment
              },
              nativeOn: {
                blur: function($event) {
                  return _vm.onAccountNumberBlur(_vm.$v.accountNumber)
                },
                focus: function($event) {
                  _vm.accountNumberBlurFired = false
                }
              },
              model: {
                value: _vm.$v.accountNumber.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.accountNumber,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.accountNumber.$model"
              }
            })
          ],
          1
        ),
        _vm.bankDetailsValidated && _vm.validAccountNumber
          ? _c("div", { staticClass: "col-md-1" }, [
              _c("span", {
                staticClass: "fa fa-check-circle",
                staticStyle: { "font-size": "200%", color: "green" }
              })
            ])
          : _vm._e(),
        _vm.$v.accountNumber.$dirty && _vm.accountNumberBlurFired
          ? _c("span", [
              !_vm.$v.accountNumber.required
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [_vm._v("Account Number is required")]
                  )
                : _vm._e(),
              !_vm.$v.accountNumber.minLen || !_vm.$v.accountNumber.maxLen
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [
                      _vm._v(
                        "Account number must contain " +
                          _vm._s(_vm.$v.accountNumber.$params.minLen.min) +
                          " numbers"
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.validAccountNumber
                ? _c(
                    "small",
                    { staticClass: "form-text text-warning small text-nowrap" },
                    [_vm._v(_vm._s(_vm.accountNumberError))]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        !_vm.newPayment && !_vm.accountNumberBlurFired
          ? _c("span", [
              !_vm.validAccountNumber
                ? _c(
                    "small",
                    { staticClass: "form-text text-warning small text-nowrap" },
                    [_vm._v(_vm._s(_vm.accountNumberError))]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "This is the payment transaction type",
                expression: "'This is the payment transaction type'",
                modifiers: { hover: true, top: true }
              }
            ],
            staticClass: "col-md-3 required"
          },
          [
            _c("label", { attrs: { for: "txCode" } }, [
              _vm._v("Transaction Code")
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "col-md-3", class: { invalid: _vm.$v.txCode.$error } },
          [
            _c("b-form-select", {
              attrs: {
                id: "txCode",
                options: _vm.txCodes,
                disabled: !_vm.canSavePayment
              },
              on: {
                change: function($event) {
                  return _vm.validateBacsDetails()
                }
              },
              model: {
                value: _vm.$v.txCode.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.txCode,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.txCode.$model"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "col-md-6" }, [
          _c("span", { staticClass: "form-text small" }, [
            _vm._v(_vm._s(_vm.transactionCodeDescription))
          ])
        ]),
        _vm.$v.txCode.$dirty
          ? _c("span", [
              !_vm.$v.txCode.required
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [_vm._v("Transaction Code is required")]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "This is the user's payment reference.",
                expression: "'This is the user\\'s payment reference.'",
                modifiers: { hover: true, top: true }
              }
            ],
            staticClass: "col-md-3 required"
          },
          [
            _c("label", { attrs: { for: "userReference" } }, [
              _vm._v("User Reference")
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-4",
            class: { invalid: _vm.$v.userReference.$error }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim.lazy",
                  value: _vm.$v.userReference.$model,
                  expression: "$v.userReference.$model",
                  modifiers: { trim: true, lazy: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "userReference",
                type: "text",
                disabled: !_vm.canSavePayment
              },
              domProps: { value: _vm.$v.userReference.$model },
              on: {
                change: [
                  function($event) {
                    _vm.$set(
                      _vm.$v.userReference,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.onChange(_vm.$v.userReference)
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]
        ),
        _vm.$v.userReference.$dirty
          ? _c("span", [
              !_vm.$v.userReference.required
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [
                      _vm._v(
                        "User Ref is required if the transaction code is not 99"
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.$v.userReference.maxLen
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [
                      _vm._v(
                        "Maximum user reference length is " +
                          _vm._s(_vm.$v.accountName.$params.maxLen.max) +
                          " characters"
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "This is the payment amount.",
                expression: "'This is the payment amount.'",
                modifiers: { hover: true, top: true }
              }
            ],
            staticClass: "col-md-3 required"
          },
          [_c("label", { attrs: { for: "amount" } }, [_vm._v("Amount (£)")])]
        ),
        _c(
          "div",
          { staticClass: "col-md-3", class: { invalid: _vm.$v.amount.$error } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim.lazy",
                  value: _vm.$v.amount.$model,
                  expression: "$v.amount.$model",
                  modifiers: { trim: true, lazy: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "amount",
                type: "text",
                disabled: _vm.disableAmount || !_vm.canSavePayment
              },
              domProps: { value: _vm.$v.amount.$model },
              on: {
                change: [
                  function($event) {
                    _vm.$set(
                      _vm.$v.amount,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.onChangeAmount(_vm.$v.amount)
                  }
                ],
                keypress: function($event) {
                  return _vm.onKeypressAmount($event)
                },
                keyup: function($event) {
                  return _vm.onKeyupAmount($event)
                },
                blur: [
                  function($event) {
                    return _vm.onBlur(_vm.$v.amount)
                  },
                  function($event) {
                    return _vm.$forceUpdate()
                  }
                ]
              }
            })
          ]
        ),
        _vm.$v.amount.$dirty
          ? _c("span", [
              !_vm.$v.amount.required
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [_vm._v("Amount is required")]
                  )
                : _vm._e(),
              !_vm.$v.amount.decimal
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [_vm._v("Amount must be a monetory value")]
                  )
                : _vm._e(),
              !_vm.$v.amount.between
                ? _c(
                    "small",
                    { staticClass: "form-text text-danger small text-nowrap" },
                    [
                      _vm._v(
                        "Amount must be between £" +
                          _vm._s(this.minAmount) +
                          " and £" +
                          _vm._s(this.maxAmount)
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "float-left",
                      attrs: {
                        size: "sm",
                        variant: "primary",
                        disabled: _vm.disableButtons || !_vm.canSavePayment
                      },
                      on: { click: _vm.savePayment }
                    },
                    [_vm._v("Save Payment")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "float-left",
                      attrs: {
                        size: "sm",
                        variant: "danger",
                        disabled: _vm.disableButtons
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.newPayment,
                          expression: "!newPayment"
                        }
                      ],
                      staticClass: "float-right",
                      attrs: {
                        size: "sm",
                        variant: "danger",
                        disabled: _vm.disableButtons || !_vm.canDeletePayment
                      },
                      on: { click: _vm.deletePayment }
                    },
                    [_vm._v("Delete Payment")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }