<template>
  <div style="height:90%">
    <div class="d-flex justify-content-between mb-2">
      <h4 class="m-0 w-100">
        Pending Submissons
        <i @click="populate" role="button" class="fas fa-sync-alt pull-right"></i>
      </h4>
    </div>
    <b-row v-if="group !== null && oneSquare">
      <b-col cols="6">Group</b-col>
      <b-col>{{groupName}}</b-col>
    </b-row>
    <b-row v-if="oneSquare">
      <b-col cols="6">AUDDIS</b-col>
      <b-col>{{auddisCount}}</b-col>
    </b-row>
    <b-row v-if="oneSquare">
      <b-col cols="6">Payments</b-col>
      <b-col>{{new Intl.NumberFormat('en-GB', {}).format(collectionCount)}}</b-col>
    </b-row>
    <b-row v-if="oneSquare">
      <b-col cols="6">Value</b-col>
      <b-col>{{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(debitAmount)}}</b-col>
    </b-row>
    <b-row v-if="!oneSquare" class="text-center">
      <b-col v-if="group !== null && !oneSquare">
        <h2>
          <a @click="goToSub(true,false)">{{groupName}}</a>
        </h2>
      </b-col>
      <b-col>
        <div class="pos-relative">
          <h2 :class="auddisStyle">
            <a @click="goToSub(false,true)">{{numberFormatter(auddisCount)}}</a>
          </h2>
        </div>
      </b-col>
      <b-col>
        <div class="pos-relative">
          <h2 :class="ddiStyle">
            <a @click="goToSub(false,false)">{{numberFormatter(collectionCount)}}</a>
          </h2>
        </div>
      </b-col>
      <b-col>
        <div class="pos-relative">
          <h2 :class="ddiTotalStyle">
            <a @click="goToSub(false,false)">£{{numberFormatter(debitAmount)}}</a>
          </h2>
          <i
            v-if="flaggedPayments > 0"
            v-b-tooltip.hover
            :title="`${flaggedPayments} payment(s) flagged for attention!`"
            class="fa fa-exclamation-circle text-danger align-top"
          ></i>
        </div>
      </b-col>
    </b-row>
    <!-- actual values row -->
    <b-row v-if="!oneSquare" class="text-center">
      <b-col v-if="group !== null && !oneSquare">
        <span class="d-block">&nbsp;</span>
      </b-col>
      <b-col>
        <span class="d-block">{{auddisCount}}</span>
      </b-col>
      <b-col>
        <span class="d-block">{{new Intl.NumberFormat('en-GB', {}).format(collectionCount)}}</span>
      </b-col>
      <b-col>
        <span
          class="d-block"
        >{{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(debitAmount)}}</span>
      </b-col>
    </b-row>
    <b-row v-if="!oneSquare" class="text-center">
      <b-col v-if="group !== null && !oneSquare">
        <span class="pb-10 d-block">Group</span>
      </b-col>
      <b-col>
        <span class="d-block">AUDDIS Count</span>
      </b-col>
      <b-col>
        <span class="d-block">Direct Debit Count</span>
      </b-col>
      <b-col>
        <span class="d-block">Direct Debit Total</span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from 'axios'
import { setTimeout, clearInterval } from 'timers'
export default {
  name: 'PendingSubmissionsWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      auddisCount: 0,
      collectionCount: 0,
      debitAmount: 0,
      groupName: '',
      intervalId: null,
      flaggedPayments: 0
    }
  },
  methods: {
    groupChanged (e) {
      this.populate()
    },
    populate () {
      const url = this.group !== null ? `${process.env.VUE_APP_DDMS_API_URL}widget/submissions/${this.group}` : `${process.env.VUE_APP_DDMS_API_URL}widget/submissions`
      axios.get(url, { params: { paygateid: this.$store.state.common.paygateId } })
        .then((response) => {
          this.auddisCount = response.data.auddisCount
          this.collectionCount = response.data.collectionCount
          this.debitAmount = response.data.debitAmount
          this.groupName = response.data.groupName
          this.flaggedPayments = response.data.flaggedCount
        }).catch((response) => {
          // window.clearInterval(this.intervalId)
        })
    },
    goToSub (allData, auddis) {
      let url = `${process.env.VUE_APP_DDMS_URL}/submissions/`
      if (this.group !== null && !allData) {
        if (auddis) {
          url = `${process.env.VUE_APP_DDMS_URL}/submissions/${this.group}/auddis`
        } else {
          url = `${process.env.VUE_APP_DDMS_URL}/submissions/${this.group}/collection`
        }
      }
      this.$router.push(url)
    },
    numberFormatter (num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
      }
      return num
    }
  },
  mounted () {
    this.populate()
    // this.intervalId = setInterval(this.populate, 30000) // 30s
    // this.$once('hook:beforeDestroy', () => {
    //   window.clearInterval(this.intervalId)
    // })
  },
  beforeRouteLeave (to, from, next) {
    // window.clearInterval(this.intervalId)
    next()
  },
  computed: {
    group () {
      return this.widget.options.find(o => o.label === 'GroupId').value
    },
    oneSquare () {
      return this.widget.h === 1 && this.widget.w === 1
    },
    ddiStyle () {
      return this.collectionCount > 0 ? 'bigNumber ddiCount' : 'bigNumber zero'
    },
    ddiTotalStyle () {
      let classNames = 'bigNumber'
      if (this.flaggedPayments > 0) {
        classNames += ' flagged'
      } else {
        classNames += this.debitAmount > 0 ? ' ddiTotal' : ' zero'
      }
      return classNames
    },
    auddisStyle () {
      return this.auddisCount > 0 ? 'bigNumber auddis' : 'bigNumber zero'
    }
  },
  watch: {
    group () {
      this.populate()
    }
  }
}
</script>
<style scoped>
.bigNumber {
  font-size: 3rem !important;
  min-width: 80px;
  /*min-height: 80px;*/
  display: inline-block;
}
.auddis {
  color: #60bd68;
}
.ddiCount {
  color: #60bd68;
}
.ddiTotal {
  color: #60bd68;
}
.zero {
  color: #000000;
}
.flagged {
  color: #f15854;
}
</style>
