var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "snap-container" },
      [
        _c(
          "transition-group",
          { attrs: { name: "snapbar-animation", tag: "div" } },
          _vm._l(_vm.messageQueue, function(messageObject, $index) {
            return _c(
              "div",
              {
                key: messageObject.message,
                staticClass: "snap-box",
                class: messageObject.type
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "snap-wrapper",
                    class: { expand: messageObject.notify }
                  },
                  [
                    _c("span", { staticClass: "snap-logo" }),
                    !messageObject.html
                      ? _c("span", { staticClass: "snap-message" }, [
                          _vm._v(_vm._s(messageObject.message))
                        ])
                      : _c("span", {
                          staticClass: "snap-message",
                          domProps: { innerHTML: _vm._s(messageObject.message) }
                        })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "snap-close",
                    on: {
                      click: function($event) {
                        return _vm.hide($index)
                      }
                    }
                  },
                  [_c("span", { staticClass: "fas fa-times" })]
                )
              ]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }