<template>
  <div>
    <!-- Approval Method -->

    <div class="form-group row">
      <label for="approval-level-default" class="col-form-label col-md-2 offset-md-1">Payment Approval Method</label>
      <div class="col-md-9">
        <p-radio id="approval-level-default"
          class="p-default p-round"
          value="Default"
          v-model="details.paymentApprovalMethod.$model"
        >Default</p-radio>
        <p-radio id="approval-level-numApprovers"
          class="p-default p-round"
          value="Number of Approvers"
          v-model="details.paymentApprovalMethod.$model"
          @change="selectNumberOfApprovers()"
        >Number of Approvers</p-radio>
        <p-radio id="approval-level-range"
          class="p-default p-round"
          value="Approval Range"
          v-model="details.paymentApprovalMethod.$model"
        >Approval Range</p-radio>
      </div>
    </div>

    <!-- Number of Approvers -->
    <transition name="fade">
      <div
        class="form-group row"
        v-if="details.paymentApprovalMethod.$model === 'Number of Approvers'"
        :class="{invalid: details.numberOfApprovers.$error}"
      >
        <label for="number-of-approvals" class="col-form-label col-md-2 offset-md-1">Number of Approvers</label>
        <div class="col-md-2">
          <input id="number-of-approvals"
            type="number"
            class="form-control"
            min="1"
            v-model.number="details.numberOfApprovers.$model"
          />
          <validation-messages v-model="details.numberOfApprovers"></validation-messages>
        </div>
      </div>
    </transition>

    <!-- Approval Ranges -->
    <transition name="fade">
      <div class="form-group row" v-if="details.paymentApprovalMethod.$model === 'Approval Range'">
        <approval-ranges class="offset-md-3" :ranges="group.approverAmountRanges"></approval-ranges>
      </div>
    </transition>
    <transition name="fade">
      <!-- Approval Method -->
      <div class="form-group row" v-if="details.paymentApprovalMethod.$model !== 'Default'">
        <label for="approval-method-input" class="col-form-label col-md-2 offset-md-1">Approval Method</label>
        <div class="col-md-2">
          <b-form-select id="approval-method-input" v-model="details.approvalMethod.$model" :options="approvalMethods"></b-form-select>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ApprovalRanges from './ApprovalRanges.vue'
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['approvalMethods']),
  props: ['details', 'disabled', 'group'],
  components: {
    'approval-ranges': ApprovalRanges
  },
  data () {
    return {
    }
  },
  methods: {
    selectNumberOfApprovers () {
      if (this.details.numberOfApprovers.$model === null) {
        this.details.numberOfApprovers.$model = 1
      }
    }
  },
  async created () {
    await this.$store.dispatch('loadTwoFactorOptions')
  }
}

</script>

<style>
</style>
