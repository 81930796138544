<template>
  <div>
    <vue-perfect-scrollbar class="scroll-area" :settings="settings">
      <bar-chart :chart-data="chartData" :options="chartOptions"></bar-chart>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import axios from 'axios'
import BarChart from '@/Assets/Components/Charts/BarChart'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BarChart,
    VuePerfectScrollbar
  },
  name: 'BacsSubmissionsWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  watch: {
    'widget.options': {
      async handler () {
        await this.load()
      },
      deep: true
    }
  },
  computed: {
    numberOfDays () {
      return this.widget.options.find(o => o.label === 'Number of Days').value
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return value.substring(0, 3)
              }
            },
            gridLines: {
              display: false
            },
            categoryPercentage: 0.5,
            barPercentage: 0.8
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = 'Sent Submissions: '
              label += tooltipItem.yLabel
              return label
            },
            title: function (chart, data) {
              return data.labels[chart[0].index].substring(3)
            }
          }
        },
        onClick: (e, item) => {
          if (item.length !== 0) {
            var pos = item[0]._index
            var createdDate = this.submissionCountData[pos].date
            var submissionSearchParams = JSON.stringify({
              createdFrom: createdDate,
              createdTo: createdDate,
              paymentEngineType: 0,
              paygateType: -1,
              maxRows: 1000,
              reference: ''
            })
            this.$store.dispatch('setSearchParams', submissionSearchParams)
            this.$router.push('/payments/search/searchSubmissions?useSearchParams=auto')
          }
        },
        onHover: (e, item) => {
          if (item.length !== 0) {
            e.target.style.cursor = 'pointer'
          } else {
            e.target.style.cursor = 'default'
          }
        }
      },
      chartData: {},
      settings: {
        maxScrollbarLength: 60
      },
      submissionCountData: {}
    }
  },

  methods: {
    async load () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/dashboard/getSubmissionCount?numberOfDays=${this.numberOfDays}`)
      this.submissionCountData = response.data

      var labels = []
      var data = []
      this.dates = []

      for (var i = 0; i < this.submissionCountData.length; i++) {
        labels.push(this.submissionCountData[i].day + this.submissionCountData[i].dateAsString)
        data.push(this.submissionCountData[i].count)
      }

      this.chartData = {
        labels: labels,
        datasets: [{
          data: data,
          label: 'BACS & FPS Submissions',
          backgroundColor: '#456aa5'
        }]
      }
    }
  },

  async mounted () {
    await this.load()
  }
}
</script>
