<template>
  <b-modal
    id="clone-bureau-job"
    ref="cloneBureauJob"
    title="Clone Bureau Job"
    hide-header-close
    static
    @shown="load"
    @ok="CloneBureauJob"
    :centered="true"
    footer-class="clone-job-footer"
  >
    <div slot="modal-ok">Clone</div>
    <div class>
      <label class="col-form-label col-md-6 required">Bureau Job Name</label>
      <div class="col-md-12">
        <b-form-input class="form-control" type="text" v-model.trim="$v.cloneName.$model" @input="checkName" :formatter="trimInput"></b-form-input>
        <!-- Validation -->
        <validation-messages v-model="$v.cloneName" name="new name">
          <small
            class="form-text small"
            v-if="
                $v.cloneName.notSameAs != undefined &&
                !$v.cloneName.notSameAs
              "
          >Cloned name cannot be the same as the original name</small>
        </validation-messages>
        <small
          class="form-text small text-danger"
          v-if="nameInUse"
        >Bureau Job Name already exists</small>
      </div>
    </div>
    <template  #modal-footer="{ ok, cancel, hide }">
      <b-button variant="primary" :disabled="nameInUse" @click="ok()">Clone</b-button>
      <b-button @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Utility from '@/Assets/Mixins/Utility'
import { maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import VueSelect from 'vue-select'
import _ from 'lodash'

export default {
  props: ['bureauJobId'],
  data () {
    return {
      cloneName: '',
      nameInUse: false
    }
  },

  async created () {
  },

  methods: {
    load () {
      // Loader

    },
    checkName: _.debounce(async function () {
      this.nameInUse = false
      let url = `${process.env.VUE_APP_BUREAU_API_URL}bureauJob/jobnameexists`
      const response = await axios.get(url, { showload: true, params: { paygateId: this.paygateId, jobName: this.cloneName } })
      this.nameInUse = response.data
    }, 300),
    // trims the input string to a maximum character length
    trimInput(e) {
      if(e.length > 50) {
        return e.substring(0,50)
      }
      return e
    },
    async CloneBureauJob () {
      var data = {
        bureauJobId: this.bureauJobId,
        cloning: true,
        clonedName: this.cloneName
      }
      var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/SaveBureauJob', data, { showload: true })

      if (response.data.toastType === 2) {
        this.$toastr.s(response.data.toastMessage)
        if (data.cloning) {
          this.$refs['cloneBureauJob'].hide()
          this.$refs.cloneBureauJob.hide()
          this.$router.push({ name: 'ManageJob', params: { id: response.data.id } })
        }
      } else {
        this.$toastr.e(response.data.toastMessage)
      }
    }

  },
  validations: {
    cloneName: { maxLength: maxLength(255) }
  }

}

</script>
<style>
  .clone-job-footer {
    justify-content: flex-start !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
