<template>
  <div class="idb-block">
    <div class="idb-block-title">
      <h2><help-icon docPath="/paygate-collections/messaging/messagetemplates/" />Email Designer</h2>
    </div>
    <div id="emailEditor" class="idb-block-content" v-if="!previewOpen">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group>
            <label for="emailTemplateName">
              <strong>Template Name</strong>
            </label>
            <b-form-input
              class
              id="emailTemplateName"
              v-model="templateName"
              :disabled="loading"
              type="text"
              autocomplete="off"
              :state="templateNameStateValid"
              aria-describedby="emailTemplateName emailTemplateNameFeedback"
              placeholder="Email Template Name"
              maxlength="50"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!templateNameStateValid" id="emailTemplateNameFeedback">
              The template name may only be up to 11 characters of the following types:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, underscores "_", or dashes "-"</li>
              </ul>
            </b-form-invalid-feedback>
            <b-form-text id="smsTemplateNameHelp">The name of the template in paygate.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="8">
          <b-form-group>
            <label for="smsSenderName">Email Title</label>
            <b-form-input
              id="smsSenderName"
              v-model.trim="messageTitle"
              type="text"
              autocomplete="off"
              :state="emailTitleStateValid"
              aria-describedby="emailTitleHelp emailTitleFeedback"
              placeholder="Enter the email title"
              maxlength="80"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!emailTitleStateValid" id="emailTitleFeedback">
              The email title must only have:
              <ul>
                <li>Upper-case letters A-Z</li>
                <li>Lower-case letters a-z</li>
                <li>Numbers 0-9</li>
                <li>Spaces, underscores "_" or dashes "-"</li>
              </ul>
            </b-form-invalid-feedback>

            <b-form-text id="emailTitleHelp">The title seen by the email recipient.</b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <label>Message Body</label>
        <wysiwyg v-model="messageBody" :upload-url="uploadUrl"></wysiwyg>
      </b-form-group>
    </div>
    <b-modal id="preview-modal" size="lg" :title="'Subject: ' + preview.title">
      <!-- <h4 v-html="preview.title"></h4> -->
      <div v-html="preview.body"></div>
    </b-modal>
    <!-- <div id="emailPreview" class="idb-block-content" v-if="previewOpen">
      <h4 v-html="preview.title"></h4>
      <div v-html="preview.body"></div>
    </div> -->

    <div class="idb-block-footer">
      <b-btn @click.prevent="saveContent" :disabled="isLoading" variant="primary">Save</b-btn>&nbsp;
      <router-link :to="{ name: 'EmailDesigner' }" class="btn btn-outline-secondary">New Template</router-link>&nbsp;
      <!-- <b-btn
        :disabled="previewDisabled"
        @click.prevent="togglePreview"
        variant="info"
      >{{previewButtonLabel}}</b-btn> -->
      <b-btn variant="info" :disabled="isLoading" @click.prevent="togglePreview"
      >Preview</b-btn>
    </div>
  </div>
</template>
<script>
import Wysiwyg from '@/Components/Collections/EmailWysiwyg.vue'
import EventBus from '@/Lib/eventBus'
import axios from 'axios'
import loading from '@/Assets/Mixins/LoadingMixin'
const hasNonAlphaNumOrSpaces = (str) => {
  if (!str) {
    return null
  }
  const nonAlphaNumsOrSpace = /[^A-Za-z0-9 -_+()]/
  return !nonAlphaNumsOrSpace.test(str)
}

export default {
  mixins: [loading],
  props: {
    templateid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      templateName: '',
      previewButtonLabel: 'Preview',
      messageTitle: '',
      messageBody: '',
      loading: !!this.templateid,
      uploadUrl: process.env.VUE_APP_DDMS_API + '/designer/userimages',
      preview: { title: '', body: '' },
      previewOpen: false
    }
  },
  components: {
    Wysiwyg
  },
  computed: {
    templateNameStateValid () {
      const str = this.templateName
      if (!str) {
        return null
      }
      const hasNonTemplateChar = /^[\w-]*$/g
      return hasNonTemplateChar.test(str)
    },
    previewDisabled () {
      const messageBodyValid = !!this.messageBody
      return !messageBodyValid || !this.emailTitleStateValid
    },
    emailTitleStateValid () {
      if (this.messageTitle === '') {
        return null
      }
      const nonAlphaNumsOrSpace = /[^\w [\]]/
      return !nonAlphaNumsOrSpace.test(this.messageTitle)
    }
    // previewDisabled () {
    //   return (this.messageBody === '' && this.messageTitle === '')
    // }
  },
  async mounted () {
    const onPaygateChange = async (paygateId) => {
      if (this.templateid) {
        this.loading = true
        try {
          await this.loadTemplateInfo(this.templateid)
          this.loading = false
        } catch (e) {
          this.$toastr.e('An error occurred loading the email template information.', 'Error')
          this.$router.push({ name: 'EmailDesigner' })
        }
      }
    }

    EventBus.$on('paygateIdChange', onPaygateChange)

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('paygateIdChange', onPaygateChange)
    })

    onPaygateChange()
  },
  methods: {
    async loadTemplateInfo (templateId) {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/email/` + this.templateid, { showload: true })
      this.templateName = response.data.name
      this.messageTitle = response.data.messageTitle
      this.messageBody = response.data.messageBody
    },
    async saveContent () {
      const paygateId = this.$store.state.common.paygateId
      if (this.templateid) {
        await this.trySave(this.templateid, paygateId)
      } else {
        await this.tryCreate(paygateId)
      }
    },
    async trySave (templateId, paygateId) {
      try {
        const body = {
          name: this.templateName,
          messageBody: this.messageBody,
          messageTitle: this.messageTitle
        }
        await axios.put(`${process.env.VUE_APP_DDMS_API_URL}designer/email/${templateId}`, body, { showload: true })
        this.$toastr.s(`'${this.templateName}' saved successfully.`, `Email Template Saved`)
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Unable to save changes to the email template.', 'An Error Occurred')
      }
    },
    async tryCreate (paygateId) {
      try {
        const body = {
          name: this.templateName,
          messageBody: this.messageBody,
          messageTitle: this.messageTitle
        }
        const response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/email`, body, { showload: true })
        history.pushState({}, 'paygate Collections', window.location + '/' + response.data.templateId)
        this.$toastr.s(`'${this.templateName}' saved successfully.`, `Email Template Saved`)
        this.$router.push('/collections/message/templates')
      } catch (e) {
        this.$toastr.e('Unable to save changes to the email template.', 'An Error Occurred')
      }
    },
    async viewPreview () {
      var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}designer/email/preview`, {
        'bodyTemplate': this.messageBody,
        'titleTemplate': this.messageTitle
      }, { showload: true })
      this.preview = response.data
    },
    async togglePreview () {
      this.preview = { bodyTemplate: 'Loading...', titleTemplate: '' }
      await this.viewPreview()
      this.$bvModal.show('preview-modal')
    }
  }
}
</script>

<style>
#emailTitleInput {
  min-width: 40em;
  margin-bottom: 2.5em;
}

#preview-modal {
  z-index: 1090 !important
}

#preview-modal___BV_modal_outer_ {
  z-index: auto !important
}
</style>
