<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<help-icon docPath="/administration/customer/licence/" />
			</div>
		</div>
		<div class="row">
			<div class="col-md">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>User Usage</h2>
					</div>
					<div class="idb-block-content">
						<pie-chart :chart-data="charts.user.data" :options="charts.user.options" :height="300"></pie-chart>
					</div>
				</div>
			</div>
			<div class="col-md">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Group Usage</h2>
					</div>
					<div class="idb-block-content">
						<pie-chart :chart-data="charts.group.data" :options="charts.group.options" :height="300"></pie-chart>
					</div>
				</div>
			</div>
			<div class="col-md">
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Service Account Usage</h2>
					</div>
					<div class="idb-block-content">
						<pie-chart
							:chart-data="charts.serviceAccount.data"
							:options="charts.serviceAccount.options"
							:height="300"
						></pie-chart>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 col-lg-6">
				<!-- Payments -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Payments</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>BACS</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.bacs.value === 'Both' || licence.bacs.value === 'Direct')"
									>
										<span
											class="sr-only ml-2"
										>{{licence.bacs.value === 'Both' || licence.bacs.value === 'Direct'}}</span>
									</span>
								</div>
								<div class="form-group col-md-6">
									<span>BACS Online</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.bacs.value === 'Both' || licence.bacs.value === 'Indirect')"
									>
										<span
											class="sr-only ml-2"
										>{{licence.bacs.value === 'Both' || licence.bacs.value === 'Indirect'}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.bacs.value !== 'Off'">
								<div
									class="form-group col-md-6"
									v-if="licence.bacs.value === 'Both' || licence.bacs.value === 'Direct'"
								>
									<span>Allow Bank Grade BACS</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.bacs.bankGrade)">
										<span class="sr-only ml-2">{{licence.bacs.bankGrade}}</span>
									</span>
								</div>
								<div
									class="form-group col-md-6"
									v-if="licence.bacs.value === 'Both' || licence.bacs.value === 'Indirect'"
								>
									<span>Allow Multi-Block BACS</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.bacs.proxyGroup)">
										<span class="sr-only ml-2">{{licence.bacs.proxyGroup}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.bacs.value !== 'Off'">
								<div class="form-group col-md-6">
									<span>BACS API</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.bacs.api)">
										<span class="sr-only ml-2">{{licence.bacs.api}}</span>
									</span>
								</div>
							</div>
							<hr />
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>FPS</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.fps.value === 'Both' || licence.fps.value === 'Direct')"
									>
										<span
											class="sr-only ml-2"
										>{{licence.fps.value === 'Both' || licence.fps.value === 'Direct'}}</span>
									</span>
								</div>
								<div class="form-group col-md-6">
									<span>FPS Online</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.fps.value === 'Both' || licence.fps.value === 'Indirect')"
									>
										<span
											class="sr-only ml-2"
										>{{licence.fps.value === 'Both' || licence.fps.value === 'Indirect'}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.fps.value !== 'Off'">
								<div
									class="form-group col-md-6"
									v-if="licence.fps.value === 'Both' || licence.fps.value === 'Indirect'"
								>
									<span>Allow Multi-Block FPS</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.bacs.proxyGroup)">
										<span class="sr-only ml-2">{{licence.bacs.proxyGroup}}</span>
									</span>
								</div>
								<div class="form-group col-md-6">
									<span>FPS API</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.fps.api)">
										<span class="sr-only ml-2">{{licence.fps.api}}</span>
									</span>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Automation -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Automation</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Mapping</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.automation.value)">
										<span class="sr-only ml-2">{{licence.automation.value}}</span>
									</span>
								</div>
								<div class="form-group col-md-6">
									<span>Mapping Designer</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.automation.mappingDesigner)"
									>
										<span class="sr-only ml-2">{{licence.automation.mappingDesigner}}</span>
									</span>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Folder Manager</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.automation.folderManager)"
									>
										<span class="sr-only ml-2">{{licence.automation.folderManager}}</span>
									</span>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Workflow</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.automation.workflow)">
										<span class="sr-only ml-2">{{licence.automation.workflow}}</span>
									</span>
								</div>
								<div class="form-group col-md-6" v-if="licence.automation.workflow">
									<span>Workflow Designer</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.automation.workflowDesigner)"
									>
										<span class="sr-only ml-2">{{licence.automation.workflowDesigner}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.automation.workflow">
								<div class="form-group col-md-6" v-if="licence.automation.workflow">
									<span>Frequency</span>
									<span class="form-control-plaintext">{{licence.automation.frequency}}</span>
								</div>
								<div class="form-group col-md-6">
									<span>Live Workflows</span>
									<span class="form-control-plaintext">{{licence.automation.liveWorkflows}}</span>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Bureau -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Bureau</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.bureau.value)">
										<span class="sr-only ml-2">{{licence.bureau.value}}</span>
									</span>
								</div>
								<div class="form-group col-md-6" v-if="licence.bureau.value">
									<span>Faster Payments Service (FPS)</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.bureau.fps)">
										<span class="sr-only ml-2">{{licence.bureau.fps}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.bureau.value">
								<div class="form-group col-md-6">
									<span>Limit</span>
									<span class="form-control-plaintext">{{licence.bureau.threshold}}</span>
								</div>
								<div class="form-group col-md-6">
									<span>Bureau API</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.bureau.api)">
										<span class="sr-only ml-2">{{licence.bureau.api}}</span>
									</span>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Other -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Other</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>SMS Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.other.sms)">
										<span class="sr-only ml-2">{{licence.other.sms}}</span>
									</span>
								</div>
								<div class="form-group col-md-6" v-if="licence.other.sms">
									<span>SMS Limit</span>
									<span class="form-control-plaintext">{{licence.other.smsThreshold}}</span>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Email Limit</span>
									<span class="form-control-plaintext">{{licence.other.emailThreshold}}</span>
								</div>
								<div class="form-group col-md-6">
									<span>Secure Cloud Storage</span>
									<span class="form-control-plaintext">{{licence.other.storage + 'GB'}}</span>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>SSO Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.other.sso)">
										<span class="sr-only ml-2">{{licence.other.sso}}</span>
									</span>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-md-12 col-lg-6">
				<!-- Validation -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Validation</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.validate.value)">
										<span class="sr-only ml-2">{{licence.validate.value}}</span>
									</span>
								</div>
								<div class="form-group col-md-6" v-if="licence.validate.value">
									<span>UK Bank Account</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.validate.modCheck)">
										<span class="sr-only ml-2">{{licence.validate.modCheck}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.validate.value">
								<div class="form-group col-md-6">
									<span>IBAN</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.validate.iban)">
										<span class="sr-only ml-2">{{licence.validate.iban}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.validate.value">
								<div class="form-group col-md-6">
									<span>Limit</span>
									<span class="form-control-plaintext">{{licence.validate.threshold}}</span>
								</div>
								<div class="form-group col-md-6">
									<span>API</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.validate.api)">
										<span class="sr-only ml-2">{{licence.validate.api}}</span>
									</span>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Agent -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Agent</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.agent.value)">
										<span class="sr-only ml-2">{{licence.agent.value}}</span>
									</span>
								</div>
								<div class="form-group col-md-6">
									<span>Use Own Certificate</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.agent.useOwnCertificate)">
										<span class="sr-only ml-2">{{licence.agent.useOwnCertificate}}</span>
									</span>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Allow FTP Address Change</span>
									<span
										class="fa form-control-plaintext"
										:class="getClass(licence.agent.allowFtpAddressChange)"
									>
										<span class="sr-only ml-2">{{licence.agent.allowFtpAddressChange}}</span>
									</span>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- HSM -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Hardware Security Module (HSM)</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.hsm.value)">
										<span class="sr-only ml-2">{{licence.hsm.value}}</span>
									</span>
								</div>
								<div class="form-group col-md-6" v-if="licence.hsm.value">
									<span>HSM Limit</span>
									<span class="form-control-plaintext">{{licence.hsm.hsmThreshold}}</span>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Anti-Fraud -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Anti-Fraud Module</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.antiFraud.enabled)">
										<span class="sr-only ml-2">{{licence.antiFraud.enabled}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.antiFraud.enabled">
								<div class="form-group col-md-6">
									<span>Allowed List Limit</span>
									<span class="form-control-plaintext">{{licence.antiFraud.whitelistThreshold}}</span>
								</div>
								<div class="form-group col-md-6">
									<span>Disallowed List Limit</span>
									<span class="form-control-plaintext">{{licence.antiFraud.blacklistThreshold}}</span>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- MFA -->
				<div class="idb-block">
					<div class="idb-block-title">
						<h2>Multi-Factor Authentication (MFA)</h2>
					</div>
					<div class="idb-block-content">
						<form>
							<div class="form-row">
								<div class="form-group col-md-6">
									<span>Enabled</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.mfa.value)">
										<span class="sr-only ml-2">{{licence.mfa.value}}</span>
									</span>
								</div>
								<div class="form-group col-md-6" v-if="licence.mfa.value">
									<span>Authenticator</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.mfa.authenticator)">
										<span class="sr-only ml-2">{{licence.mfa.authenticator}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.mfa.value">
								<div class="form-group col-md-6" v-if="licence.mfa.value">
									<span>USB Tokens</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.mfa.usbTokens)">
										<span class="sr-only ml-2">{{licence.mfa.usbTokens}}</span>
									</span>
								</div>
								<div class="form-group col-md-6" v-if="licence.mfa.value">
									<span>Email</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.mfa.emails)">
										<span class="sr-only ml-2">{{licence.mfa.emails}}</span>
									</span>
								</div>
							</div>
							<div class="form-row" v-if="licence.mfa.value && licence.other.sms">
								<div class="form-group col-md-6" v-if="licence.mfa.value">
									<span>SMS</span>
									<span class="fa form-control-plaintext" :class="getClass(licence.mfa.sms)">
										<span class="sr-only ml-2">{{licence.mfa.sms}}</span>
									</span>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import randomColor from 'randomcolor'

import PieChart from '@/Assets/Components/Charts/PieChart'

export default {
	name: 'ReadOnlyCustomerLicence',
	components: {
		PieChart
	},
	data () {
		return {
			usersAmount: 0,
			groupsAmount: 0,
			serviceAccountsAmount: 0,
			licence: {
				platform: {
					users: 10,
					groups: 3,
					serviceAccounts: 0
				},
				bacs: {
					value: 'Direct',
					api: false
				},
				fps: {
					value: 'Off',
					api: false
				},
				collections: {
					value: false,
					api: false,
					onBoarding: false,
					payerPortal: false,
					payerThreshold: 10,
					refunds: false
				},
				bureau: {
					value: false,
					api: false,
					threshold: 100
				},
				validate: {
					value: true,
					modCheck: false,
					iban: false,
					cc: false,
					api: false,
					threshold: 20000
				},
				automation: {
					value: true,
					workflow: false,
					mode: 'Managed',
					frequency: 'Hourly',
					liveWorkflows: 5,
					api: false,
					workflowDesigner: false,
					mappingDesigner: false
				},
				agent: {
					value: false,
					useOwnCertificate: false,
					allowFtpAddressChange: false
				},
				hsm: {
					value: false
				},
				mfa: {
					value: true,
					authenticator: true,
					usbTokens: false,
					sms: false,
					emails: false
				},
				other: {
					sms: false,
					smsThreshold: 10000,
					emailThreshold: 10000,
					storage: 15
				},
				antiFraud: {
					enabled: false
				}
			},
			charts: {
				user: {
					data: {},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							position: 'bottom',
							// This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
							onClick: function () { }
						}
					}
				},
				group: {
					data: {},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							position: 'bottom',
							// This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
							onClick: function () { }
						}
					}
				},
				serviceAccount: {
					data: {},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							position: 'bottom',
							// This means they can't toggle the datasets, as there is only two it wouldn't make sence to turn them off!
							onClick: function () { }
						}
					}
				}
			}
		}
	},
	methods: {
		async loadLicence () {
			try {
				this.$Progress.start()
				var results = await Promise.allSettled([
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence`),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/GroupCount`),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/UserCount`),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Licence/ServiceAccountCount`)
				])

				const licenceResponse = results[0].value
				const groupResponse = results[1].value
				const userResponse = results[2].value
				const serviceAccountResponse = results[3].value

				this.licence = licenceResponse.data
				if (licenceResponse.data.error) {
					this.$snapbar.w('There are objects in paygate which are unlicensed')
					this.errors = licenceResponse.data.error.errors
					var container = document.getElementById('base-container')
					container.scrollTop = 0
				}
				this.groupsAmount = groupResponse.data
				this.usersAmount = userResponse.data
				this.serviceAccountsAmount = serviceAccountResponse.data

				this.charts.user.data = {
					labels: ['Used', 'Remaining'],
					datasets: [{
						data: [this.usersAmount, this.licence.platform.users - this.usersAmount],
						backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
					}]
				}
				this.charts.group.data = {
					labels: ['Used', 'Remaining'],
					datasets: [{
						data: [this.groupsAmount, this.licence.platform.groups - this.groupsAmount],
						backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
					}]
				}

				this.charts.serviceAccount.data = {
					labels: ['Used', 'Remaining'],
					datasets: [{
						data: [this.serviceAccountsAmount, this.licence.platform.serviceAccounts - this.serviceAccountsAmount],
						backgroundColor: [randomColor({ hue: 'red', seed: 'Ive come to talk with you again', luminosity: 'bright' }), randomColor({ hue: 'green', seed: 'Hello darkness, my old friend', luminosity: 'bright' })]
					}]
				}

				this.$Progress.finish()
			} catch {
				this.$Progress.fail()
			}
		},
		getClass (bool) {
			var classObj = {
				'fa-check': false,
				'fa-times': false,
				'text-success': false,
				'text-danger': false
			}

			if (bool) {
				classObj['fa-check'] = true
				classObj['text-success'] = true
			} else {
				classObj['fa-times'] = true
				classObj['text-danger'] = true
			}
			return classObj
		}
	},
	async created () {
		await this.loadLicence()
	}
}
</script>

<style>
</style>
