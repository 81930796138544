var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          { staticClass: "modal-container" },
          [
            _vm._t("header", [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "pull-right close-btn",
                      attrs: { variant: "link", size: "lg" },
                      on: { click: _vm.cancelPopup }
                    },
                    [
                      _c("span", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  ),
                  _c("h3", [_vm._v("Folder Browser")])
                ],
                1
              )
            ]),
            _vm.errorMessage !== ""
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 text-danger" }, [
                    _vm._v(_vm._s(_vm.errorMessage))
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm._t("body", [
                  _vm.errorMessage === ""
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-1" }, [
                          _vm._v("Path")
                        ]),
                        _c("div", { staticClass: "col-md-11" }, [
                          _vm._v(" " + _vm._s(_vm.selectedFolder) + " ")
                        ])
                      ])
                    : _vm._e(),
                  _c("hr"),
                  _c("br"),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12 folderPanel" },
                      [
                        _c(
                          "VuePerfectScrollbar",
                          {
                            staticClass: "scroll-area",
                            attrs: { settings: _vm.settings }
                          },
                          [
                            _c("v-jstree", {
                              attrs: {
                                data: _vm.treeData,
                                multiple: "",
                                "allow-batch": "",
                                "whole-row": ""
                              },
                              on: {
                                "item-click": _vm.itemClick,
                                "item-dblclick": _vm.itemDblClick
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _vm._t("footer", [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: { click: _vm.closePopup }
                    },
                    [
                      _c("span", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(" Cancel ")
                    ]
                  )
                ])
              ],
              2
            )
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }