<template>
  <div id="audits">
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Audit Log
          <help-icon docPath="/administration/customer/auditlogs/" />
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <div class="row mb-1">
          <div class="col-md-2">
            <div class="form-group">
              <label for="fromDate" class="form-label">From Date</label>
              <div class="input-group date">
                <date-picker id="fromDate"
                  v-model="fromDate"
                  :config="fromDateOptions"
                  @dp-change="load"
                  :wrap="true"
                ></date-picker>
                <div class="input-group-addon">
                  <span class="far fa-calendar"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="toDate" class="form-label">To Date</label>
              <div class="input-group date">
                <date-picker id="toDate"
                  v-model="toDate"
                  :config="toDateOptions"
                  @dp-change="load"
                  :wrap="true"
                ></date-picker>
                <div class="input-group-addon">
                  <span class="far fa-calendar"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <span class="form-label">
                <br />
              </span>
              <button
                style="display:block"
                class="btn btn-info mt-2"
                type="button"
                @click="resetDates"
              >Reset Dates</button>
            </div>
          </div>
        </div>
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          :row-style-class="checkSumValid"
          ref="table"
        >
          <div slot="table-actions">
            <table-actions
              v-bind:data-url="dataUrl"
              v-bind:export-data="tableServerData"
              v-bind:print-enabled="false"
              v-bind:table-amount="totalRecords"
              export-name="test"
              v-bind:reload-table="load"
              v-bind:tableColumns="columns"
            >
              <button
                @click.prevent="clearTableFilters() && resetDates()"
                class="btn btn-link"
                v-b-popover.hover.top.d500="'Clear filters'"
              >
                <span class="fa-stack" style="font-size: 10px;">
                  <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                  <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                </span><span class="sr-only">Clear filters</span>
              </button>
            </table-actions>
          </div>
        </vue-good-table>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="alert alert-secondary" role="alert">
              <p>
                <span class="success">Green</span> means that the audit has not been tampered with
              </p>
              <p>
                <span class="danger">Red</span> means that the audit has been tampered with
              </p>
              <p>
                <span class="blue">Blue</span> means that the audit has been imported and is exempt from tamper protection
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { codes, types, severities } from '@/Assets/Constants/audits'
import datePicker from '@/Assets/Components/DateTimePicker/DatePicker'
import moment from 'moment'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'

export default {
  mixins: [tableFilterMixin],
  components: {
    datePicker
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    dataUrl () {
      return `${process.env.VUE_APP_PLATFORM_API_URL}Audits/Customer/${this.selectedCustomer}`
    },
    tableServerData () {
      return this.buildGoodTableQuery()
    },
    fromDateOptions () {
      return {
        ...this.dateOptions,
        maxDate: this.toDate
      }
    },
    toDateOptions () {
      return {
        ...this.dateOptions,
        minDate: this.fromDate
      }
    }
  },
  data () {
    return {
      fromDate: moment().subtract(3, 'months').millisecond(0).second(0).minute(0).hour(0),
      toDate: moment().millisecond(999).second(59).minute(59).hour(23),
      dateOptions: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        sideBySide: true
      },
      rows: [],
      columns: [
        {
          label: 'Audit Code',
          field: 'auditCode',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by audit code',
            filterDropdownItems: Object.values(codes)
          },
          sortable: false
        },
        {
          label: 'Audit Type',
          field: 'auditType',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by audit type',
            filterDropdownItems: Object.values(types)
          },
          sortable: false
        },
        {
          label: 'Audit Title',
          field: 'auditTitle',
          sortable: false
        },
        {
          label: 'Audit Body',
          field: 'auditBody',
          sortable: false
        },
        {
          label: 'Severity',
          field: 'severity',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by audit severity',
            filterDropdownItems: Object.values(severities)
          },
          sortable: false
        },
        {
          label: 'Username',
          field: 'username',
          filterOptions: {
            enabled: true
          },
          sortable: false
        },
        {
          label: 'Audit Date',
          field: 'createdDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm'
        }
      ],
      serverParams: {
        sort: [{ field: 'createdDate', type: 'desc' }]
      },
      paginationOptions: {
        dropdownAllowAll: false,
        mode: 'pages'
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(this.dataUrl, {
          params: {
            ...this.tableServerData
          },
          showerror: true,
          errormessage: 'Failed loading bank account'
        })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    checkSumValid (row) {
      return row.imported ? 'blue' : row.checksumValid ? 'success' : 'danger'
    },
    onRowClick (params) {
      var auditId = params.row.auditId
      this.$router.push({ name: 'AuditDetails', params: { auditId: auditId } })
    },

    buildGoodTableQuery () {
      const query = { ...this.serverParams.columnFilters }
      Object.keys(query).forEach((key) => {
        if (key !== 'auditCode') {
          query[key] = `*${query[key]}*`
        }
      })
      query.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.perPage = this.serverParams.perPage
      query.page = this.serverParams.page
      if (this.serverParams.searchKey) {
        query.searchFilter = this.serverParams.searchKey
      }
      query.fromDate = this.fromDate.toISOString()
      query.toDate = moment(this.toDate).second(59).millisecond(999).toISOString()

      if (this.doneParams) {
        this.$store.commit('updateTable', { name: this.$route.path, filters: this.serverParams })
      }
      return query
    },
    resetDates () {
      // Reset dates
      this.fromDate = moment().subtract(3, 'months').second(0).minute(0).hour(0).toDate()
      this.toDate = moment().second(59).minute(59).hour(23).toDate()
    }
  }
}

</script>
<style lang="scss">
#audits {
  tr {
    &.danger {
      background-color: #ffbbc0 !important;
      &:hover {
        background-color: #ffbbc066 !important;
      }
    }

    &.success {
      background-color: #d5ffc8 !important;
      &:hover {
        background-color: #d5ffc866 !important;
      }
    }

    &.blue {
      background-color: #c8eeff !important;
      &:hover {
        background-color: #c8eeff66 !important;
      }
    }
  }

  .danger {
    background-color: #ffbbc0 !important;
  }
  .success {
    background-color: #d5ffc8 !important;
  }

  .blue {
    background-color: #c8eeff !important;
  }
}

.dark-mode {
  #audits {
    tr {
      &.danger {
        background-color: #af8184 !important;
        &:hover {
          background-color: #9c747766 !important;
        }
      }

      &.success {
        background-color: #72886c !important;
        &:hover {
          background-color: #7d967566 !important;
        }
      }
    }

    .danger {
      background-color: #866365 !important;
    }
    .success {
      background-color: #758b6e !important;
    }
  }
}
</style>
