<template>
  <div id="downloadReports">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <!-- Header -->
          <div class="idb-block-title">
            <h2>
              Download {{ this.networkName }} Reports
              <help-icon :docPath="helpUrl"></help-icon>
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <!-- Main content -->
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="col-md-2">
                <strong><label for="groupSelector">Select a Group</label></strong>
              </div>
              <div class="col-md-4">
                <group-select
                  id="groupSelector"
                  v-model="selectedGroupId"
                  :groups="groups"
                  @input="selectGroup()"
                  :clearable="false"
                  :disabled="this.isLoggedIn"
                ></group-select>
              </div>
              <div class="col-md-1">
                <button
                  class="btn btn-outline-secondary"
                  :disabled="this.groupSelected === false"
                  @click="viewGroupBankDetails"
                >View</button>
              </div>
            </div>
            <br />
            <div v-if="isLoggedIn === false" class="row form-group">
              <div class="col-md-1">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="loginStart"
                  :disabled="this.enableLogin === false"
                >Login</button>
              </div>
              <div class="col-md-1">
                <span style="font-size: 200%" class="fa fa-unlock"></span>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  v-on:click="viewVocalinkCertificate"
                  :disabled="this.isLoggedIn === false"
                >{{ certificateButtonText }}</button>
              </div>
            </div>
            <div v-else>
              <div class="row form-group">
                <div class="col-md-1">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="logoff"
                    :disabled="this.loggingIn === true"
                  >Log Off</button>
                </div>
                <div class="col-md-3">
                  <span style="font-size: 200%" class="fa fa-lock"></span>
                  &nbsp;&nbsp;Securely logged into {{ networkName }}
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click="viewVocalinkCertificate"
                    :disabled="this.isLoggedIn === false"
                  >{{ certificateButtonText }}</button>
                </div>
              </div>
              <br />
              <div class="row form-group">
                <div class="col-md-2"><label for="reportTypes">Report Types</label></div>
                <div class="col-md-3">
                  <b-select id="reportTypes" v-model.trim="selectedReportType" :options="reportTypes" />
                </div>
                <div class="col-md-1"></div>
                <div
                  v-show="networkType === 'BacstelIp'"
                  class="col-form-label col-md-3"
                ><label for="downloadGenericReport">Download Generic Test Report</label></div>
                <div v-show="networkType === 'BacstelIp'" class="col-md-3">
                  <p-check :labelId="'downloadGenericReport'"
                    class="p-switch p-fill"
                    color="primary"
                    v-model="downloadGenericTestReport"
                  ></p-check>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2"><label for="reportPeriods">Report Period</label></div>
                <div class="col-md-3">
                  <b-select id="reportPeriods" v-model.trim="selectedReportPeriod" :options="reportPeriods" />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-2"><label for="prevAccessed">Include Previously Accessed</label></div>
                <div class="col-md-3">
                  <b-select id="prevAccessed" v-model.trim="selectedReportAccessed" :options="reportAccessed" />
                </div>
              </div>
              <br />
              <div class="row form-group">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="getReportList"
                    :disabled="this.enableGetReportList === false"
                  >Get Report List</button>
                </div>
              </div>
              <div v-if="haveReportList === true" class="row form-group">
                <div class="col-md-12">
                  <vue-good-table
                    ref="reportList"
                    @on-per-page-change="onPerPageChange"
                    :columns="columns"
                    :rows="rows"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionInfoClass: 'report-row-selected',
                      clearSelectionText: ''
                    }"
                    :paginationOptions="paginationOptions"
                    styleClass="vgt-table striped bordered"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'buttons'">
                        <b-dropdown right variant="outline-primary" split text="Options" class="m-2">
                          <b-dropdown-item v-if="showCsvOption(props)" @click.prevent="viewReportModal(props, 'CSV')">CSV</b-dropdown-item>
                          <b-dropdown-item v-if="showHtmlOption(props)" @click.prevent="viewReportModal(props, 'HTML')">HTML</b-dropdown-item>
                          <b-dropdown-item v-if="showPdfOption(props)" @click.prevent="viewReportModal(props, 'PDF')">PDF</b-dropdown-item>
                          <b-dropdown-item v-if="showXmlOption(props)" @click.prevent="viewReportModal(props, 'XML')">XML</b-dropdown-item>
                        </b-dropdown>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportXml'">
                        <span class="grid-link">XML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportHtml'">
                        <span class="grid-link">HTML</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportCsv'">
                        <span class="grid-link">CSV</span>
                      </span>
                      <span v-else-if="props.column.field === 'viewReportPdf'">
                        <span class="grid-link">PDF</span>
                      </span>
                      <span v-else-if="props.column.field === 'displayTimestamp'"
                      >{{ formatDate(props.row.displayTimestamp) }}</span>
                      <span v-else-if="props.column.field === 'displayProcessingDay'"
                      >{{ formatDate(props.row.displayProcessingDay) }}</span>
                      <span v-else>{{props.formattedRow[props.column.field]}}</span>
                    </template>
                    <div slot="selected-row-actions" style="width: 1200px;">
                      <div class="row">
                        <div v-if="!showDownloadWarning" class="col-md-6"></div>
                        <div v-else class="col-md-6" style="color: #DD6B55;">
                          Not all available reports can be downloaded in the same format. Incompatible selected reports cannot be downloaded.
                        </div>
                        <div class="col-md-2">Download Format</div>
                        <div class="col-md-2">
                          <b-select
                            v-model.trim="selectedDownloadFormat"
                            :options="downloadFormats"
                          />
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="btn btn-primary pull-right"
                            v-on:click="downloadReports"
                            :disabled="this.selectedDownloadFormat === ''"
                          >Download</button>
                        </div>
                      </div>
                    </div>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="eSigner" v-show="showeSigner">
      <span v-html="eSignerHtml"></span>
    </div>
    <div id="websignerContent" v-if="showWebSigner">
      <span v-html="webSignerHtml"></span>
    </div>    
    <div>
      <viewReportModal
        v-model="isViewReportModalVisible"
        :reportData="reportContent"
        :reportFormat="selectedReportFormat"
        @close="closeViewReport"
      ></viewReportModal>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import ViewReportModal from '@/Views/Bacs/BacsReports/ViewReportModal.vue'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import bacsMixin from '@/Lib/BacsMixin.js'
import moment from 'moment'
import swal from 'sweetalert2'
import supportLinkMixin from '@/Assets/Mixins/SupportLinkMixin'

export default {
  mixins: [
    bacsMixin,
    supportLinkMixin
  ],
  components: {
    plugin: Plugin,
    viewReportModal: ViewReportModal
  },

  data () {
    return {
      submissionId: '',
      networkType: '',
      networkName: '',
      isLoaded: false,
      authResponse: '',
      isLoggedIn: false,
      loggingIn: false,
      groups: [],
      pgeGroups: [],
      pgoGroups: [],
      BACSPGEGROUPTYPE: '0',
      BACSPGOGROUPTYPE: '3',
      FPSPGEGROUPTYPE: '4',
      FPSPGOGROUPTYPE: '5',
      directGroupType: '',
      indirectGroupType: '',
      paygateId: '',
      groupSelected: false,
      selectedGroupId: null,
      selectedGroup: {},
      bacsGroup: {},
      base64Signature: '',
      submissionTypes: {},
      getTaskProgressUrl: '',
      endTaskUrl: '',
      vocalinkCertificate: {},
      showPluginLink: false,
      downloadPluginUrl: '',
      eSignerHtml: '',
      newPluginRequired: false,
      pluginLoaded: false,
      downloadPluginLinkText: 'Click here to download the PayGate Plugin',
      canProceed: true,
      showeSigner: false,
      reportTypes: {},
      reportPeriods: {},
      reportAccessed: {},
      downloadFormats: {},
      allDownloadFormats: {},
      selectedReportType: '',
      selectedReportPeriod: '',
      selectedReportAccessed: '',
      selectedDownloadFormat: '',
      selectedRetrievalUrl: '',
      selectedReportFormat: 0,
      haveReportList: false,
      isViewReportModalVisible: false,
      reportContent: '',
      reportDownload: {},
      columns: [
        {
          label: 'Report Name',
          field: 'reportName'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Generated',
          field: 'displayTimestamp'
        },
        {
          label: 'Processing Date',
          field: 'displayProcessingDay'
        },
        {
          label: 'Previously Accessed',
          field: 'previouslyAccessedText'
        },
        {
          label: 'Retrieval Url',
          field: 'retrievalUrl',
          hidden: true
        },
        {
          label: 'ReportType',
          field: 'reportType',
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportXml',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportHtml',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportCsv',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'viewReportPdf',
          sortable: false,
          hidden: true
        },
        {
          label: 'View Report',
          field: 'buttons',
          sortable: false,
          filterOptions: { enabled: false },
          hidden: false
        }
      ],
      rows: [],
      deviceType: '', // Either USB token or Smartcard depending on group.
      currentPage: '',
      componentId: 0, // Either 0 for USB access or 2 for smartcard component in version string returned by plugin.
      expectedVersion: '',
      userPin: '',
      licenceSetting: '',
      licenceWarningMessage: '',
      browser: '',
      pluginUpdateNotInstalled: false,
      helpUrl: '',
      downloadGenericTestReport: false,
      ddicReportTypes: ['1225', '1226', '1227'],
      paginationOptions: {
        enabled: true,
        perPage: 10,
        setCurrentPage: 1,
        dropdownAllowAll: false,
        jumpFirstOrLast: true
      },
      currentPerPage: 10,
      webSignerHtml: '',
      showWebSigner: false,
      useWebSigner: false
    }
  },

  computed: {
    enableLogin: function () {
      return this.isLoaded === true && this.selectedGroupId !== '' && this.loggingIn === false && this.canProceed === true && this.pluginUpdateNotInstalled === false
    },

    enableGetReportList: function () {
      return this.selectedReportType !== '' && this.selectedReportPeriod !== '' && this.selectedReportAccessed !== ''
    },

    certificateButtonText: function () {
      return this.networkName + ' Scheme Certificate'
    },

    showDownloadWarning: function () {
      let anyDdicReports = this.rows.some(a1 => this.ddicReportTypes.includes(a1.reportType))
      let anyNonDdicReports = this.rows.some(a1 => !this.ddicReportTypes.includes(a1.reportType))
      return anyDdicReports && anyNonDdicReports
    }
  },

  methods: {
    formatDate: function (dateVal) {
      return moment(dateVal).format('DD/MM/YYYY')
    },

    async getEnums () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getDropdownValues?networkType=` + this.networkType, { showload: true })
      this.reportTypes = response.data.reportTypeLookups
      this.reportPeriods = response.data.periodLookups
      this.reportAccessed = response.data.accessedLookups
      this.downloadFormats = response.data.downloadFormatLookups
      this.allDownloadFormats = Array.from(this.downloadFormats)
    },

    async selectGroup () {
      this.groupSelected = false
      this.canProceed = false
      this.selectedGroup = this.groups.find(i => i.groupId === this.selectedGroupId)

      if (this.selectedGroup !== undefined) {
        this.$store.commit('setSelectedGroup', this.selectedGroupId)
        await this.getSelectedGroupDetails()

        // To show group bank details if user clicks "View" button.
        var payload = { paygateId: this.paygateId, groupId: this.selectedGroupId, platformUrl: this.$platformApiBaseUrl }
        this.$store.dispatch('getGroupBankAccount', payload)
        this.loggingIn = false
        this.groupSelected = true
        await this.checkForUniqueSun()
      }
    },

    async getSelectedGroupDetails () {
      if (this.selectedGroupId === undefined || this.selectedGroupId === null) {
        return
      }
      await this.$store.dispatch('getSubBacsGroup', this.selectedGroupId)
      this.bacsGroup = this.$store.getters.bacsGroup
    },

    async checkForUniqueSun () {
      var json = JSON.stringify({
        serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
        groupType: this.bacsGroup.groupType,
        tokenType: this.bacsGroup.groupTypeDetails.tokenType
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/checkForUniqueSun',
        data: json,
        showload: true
      })

      if (!response.data.success) {
        this.$snapbar.e(response.data.errorMessage)
      }

      this.canProceed = response.data.success
    },

    async loginStart () {
      if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard') {
        await this.customerUsesWebSigner()
        if (!this.useWebSigner) {
          // Check for web extension and plugin...
          try {
            await this.$store.commit('setDeviceType', 'SmartCard')
            await this.$store.dispatch('checkPlugin')
            if (this.pluginUpdateNotInstalled === false) {
              await this.signChallenge()
            }
          } catch (error) {
            this.$toastr.w(error.message, 'Warning')
          }
        } else {
          await this.signChallenge()  
        }
      } else {
        await this.signChallenge()
      }
    },

    async signChallenge () {
      this.isLoggedIn = false
      this.loggingIn = true
      this.canProceed = false
      this.canCancel = false

      await this.getVocalinkLoginChallenge()

      if (this.authResponse.success === true) {
        // Sign either with HSM or smartcard depending on the submission group.
        this.bacsGroup = this.$store.getters.bacsGroup
        if (this.bacsGroup.groupTypeDetails.tokenType === 'Hsm') {
          this.signChallengeWithHsm()
        } else {
          if (this.useWebSigner) {
            this.signWithWebSigner()
          } else {
            if (this.browser !== 'IE') {
              this.startSignNonActiveXPlugin()
            } else {
              this.signWithActiveXPlugin()
            }
          }
        }
      } else {
        this.loggingIn = false
        this.canProceed = true
      }
    },

    async customerUsesWebSigner () {
      console.log('customerUsesWebSigner')
      let useWebSignerResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/useWebSigner`, { showload: true })
      this.useWebSigner = useWebSignerResponse.data
      console.log('useWebSigner: ' + this.useWebSigner)
    },

    async signWithWebSigner () {
      console.log('signWithWebSigner')
      var cookies = this.authResponse.cookieCollection
      var domain = ''
      var name = ''
      var value = ''
      if (cookies.length > 0) {
        var cookie = cookies[0]
        domain = cookie.domain
        name = cookie.name
        value = cookie.value
      }

      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWithWebSigner?submissionId=00000000-0000-0000-0000-000000000000&actionId=00000000-0000-0000-0000-000000000000'
      url += '&challenge=' + this.authResponse.loginChallenge
      url += '&cookieName=' + name
      url += '&cookieValue=' + value
      url += '&cookieDomain=' + domain
      url += '&networkType=' + this.networkType
      url += '&groupId=' + this.selectedGroupId

      var response = await axios.get(url)
      this.webSignerHtml = response.data

      let challengeText = await this.extractLoginChallenge(this.webSignerHtml)

      let webSignerDivCount = document.querySelectorAll('div[id=websigner]').length
      var parseEvent = new CustomEvent('webSignerParse');
      document.dispatchEvent(parseEvent);

      const element = document.getElementById('websigner')
      if (element !== null) {
        element.remove()
      }

      const challengeDiv = `<div style="text-align: left;"><p>${challengeText}</p></div>`
      const websignerWarning = '<br/><div class="webSignerWarning">If you don\'t see a \'Sign\' button please install WebSigner. Then press F5 to refresh the page.</div><br/><br/>'

      let result = await swal.fire({
        title: 'Sign the following Login Challenge',
        html: websignerWarning + challengeDiv + this.webSignerHtml,
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: true
      })

      console.log('result: ' + result.isConfirmed)
      if (!result.isConfirmed) {
        this.loggingIn = false
        this.canProceed = true
      }
    },

    // Extract the Vocalink login challenge from the "GetLoginChallenge" response in order to display it in the sigining modal.
    // This is because Vocalink want the user to see the data that they are signing.
    async extractLoginChallenge(response) {
      let challengeText = '';
      let srchStr = 'data-in_data=\"';
      let idxStart = response.indexOf(srchStr);
      if (idxStart > 0) {
          challengeText = response.substring(idxStart + srchStr.length);
          let idxEnd = challengeText.indexOf('\"');
          if (idxEnd > 0) {
              challengeText = challengeText.substring(0, idxEnd);
          }
      }
      return challengeText;
    },

    async getVocalinkLoginChallenge () {
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/comms/getVocalinkLoginChallenge?networkType=${this.networkType}&groupId=${this.selectedGroupId}`
      var response = await axios.get(url, { showload: true })
      this.authResponse = response.data
      if (this.authResponse.success === true) {
        this.vocalinkCertificate = this.authResponse.vocalinkCertificate
      } else {
        if (!this.authResponse.licenceError) {
          this.$snapbar.e(this.authResponse.errorMessage)
        } else {
          this.$swal({
            title: 'Licence Warning',
            text: this.authResponse.errorMessage,
            type: 'warning'
          })
        }
      }
    },

    async callHsm () {
      var thumbprint = ''
      var paygateType = bacsCommon.getPaygateType(this.bacsGroup.groupType)

      if (paygateType !== 'Online') {
        thumbprint = this.bacsGroup.groupTypeDetails.certificate.thumbprint
      }

      var json = JSON.stringify({
        certificateThumbprint: thumbprint,
        loginChallenge: this.authResponse.loginChallenge,
        isPaygateOnline: paygateType === 'Online',
        userPin: this.userPin,
        groupId: this.selectedGroupId
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWithHsm',
        data: json,
        showload: true
      })

      return response.data
    },

    async signChallengeWithHsm () {
      if (this.bacsGroup.groupTypeDetails.hsmPinRequired) {
        this.userPin = await bacsCommon.showVocalinkChallengePin(this.authResponse.loginChallenge)
      } else {
        await bacsCommon.showVocalinkChallengeNoPin(this.authResponse.loginChallenge)
      }

      var signResponse = await this.callHsm()

      if (signResponse.success === true) {
        this.base64Signature = signResponse.tag
        await this.vocalinkLogin()
      } else {
        this.$snapbar.e(signResponse.errorMessage)
        this.loggingIn = false
        this.canProceed = true
      }
    },

    async signWithActiveXPlugin () {
      this.plugin = document.PayGatePlugin
      this.plugin.InitSmartCardSigning()
      this.plugin.SubmissionLength = this.authResponse.loginChallenge.length
      this.plugin.SubmissionData = this.authResponse.loginChallenge
      this.base64Signature = this.plugin.SmartCardSignature(true, 'gclib.dll', '')
      if (this.base64Signature.indexOf('Error') === -1) {
        await this.vocalinkLogin()
      } else {
        this.$snapbar.e(this.base64Signature)
        this.canCancel = true
        this.canProceed = true
        this.isLoggedIn = false
        this.loggingIn = false
      }
    },

    async startSignNonActiveXPlugin () {
      if (this.browser === 'Chrome') {
        this.processSignatureChrome()
          .then(() => {
          })
          .catch(() => {
            console.log('reject')
          })
      } else if (this.browser === 'Firefox') {
        this.processSignatureFirefox()
          .then(() => {
          })
          .catch(() => {
          })
      }
    },

    processSignatureFirefox () {
      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.vocalinkLogin()
              resolve()
            } else {
              this.showSupportLinkSnapbar(this.base64Signature)
              this.canCancel = true
              this.canProceed = true
              this.isLoggedIn = false
              this.loggingIn = false
              reject(new Error(this.base64Signature))
            }
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.canCancel = true
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
            reject(new Error(pluginResponse.ErrorMessage))
          }
        }, { once: true })
        // eslint-disable-next-line
        var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge } })
        document.dispatchEvent(pluginEvent)
      })
    },

    processSignatureChrome () {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge }, (response) => {
          var pluginResponse = common.parsePluginResponse(response.response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.vocalinkLogin()
            } else {
              this.showSupportLinkSnapbar(this.base64Signature)
              this.canCancel = true
              this.canProceed = true
              this.isLoggedIn = false
              this.loggingIn = false
            }
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.canCancel = true
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
          }
        })
        resolve()
      })
    },

    async signWitheSigner () {
      var cookies = this.authResponse.cookieCollection
      var domain = ''
      var name = ''
      var value = ''
      if (cookies.length > 0) {
        var cookie = cookies[0]
        domain = cookie.domain
        name = cookie.name
        value = cookie.value
      }

      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWitheSigner?submissionId=00000000-0000-0000-0000-000000000000&actionId=00000000-0000-0000-0000-000000000000'
      url += '&challenge=' + this.authResponse.loginChallenge
      url += '&cookieName=' + name
      url += '&cookieValue=' + value
      url += '&cookieDomain=' + domain
      url += '&networkType=' + this.networkType
      url += '&groupId=' + this.selectedGroupId

      var response = await axios.get(url)
      this.eSignerHtml = response.data
      this.showeSigner = true
    },

    // async geteSignerSignature () {
    //   var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/geteSignerSignature?submissionId=` + this.submissionId, { showload: true })
    //   this.base64Signature = response.data
    //   await this.vocalinkLogin()
    // },

    async getWebSignerSignature () {
      console.log('getWebSignerSignature')
      let response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getWebSignerSignature?submissionId=` + this.submissionId, { showload: true })
      this.base64Signature = response.data
      await this.vocalinkLogin()
    },

    async vocalinkLogin () {
      var json = JSON.stringify({
        networkType: this.networkType,
        base64Signature: this.base64Signature,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogin',
        data: json,
        showload: true
      })

      this.authResponse = response.data
      this.loggingIn = false
      if (this.authResponse.success === true) {
        this.isLoggedIn = true
      } else {
        this.showSupportLinkSnapbar(this.authResponse.errorMessage)
        this.canCancel = true
        this.canProceed = true
        this.isLoggedIn = false
        this.loggingIn = false
      }
    },

    async logoff () {
      var json = JSON.stringify({
        networkType: this.networkType,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogoff',
        data: json,
        showload: true
      })

      this.authResponse = response.data
      this.isLoggedIn = false
      this.loggingIn = false
      this.isLoaded = true
      this.canProceed = true
      this.haveReportList = false
      this.downloadGenericTestReport = false
    },

    async getVocalinkCertificate () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getVocalinkCertificate?networkType=` + this.networkType, { showload: true })
      if (response.data.success === true) {
        this.vocalinkCertificate = response.data.vocalinkCertificate
      } else {
        this.$snapbar.e(response.data.errorMessage)
      }

      return response.data.success
    },

    async viewVocalinkCertificate () {
      if (this.vocalinkCertificate.issuer === undefined) {
        var success = await this.getVocalinkCertificate()
        if (success === true) {
          await swal.fire({
            title: this.certificateButtonText,
            html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
            icon: 'info',
            allowOutsideClick: false
          })
        }
      } else {
        await swal.fire({
          title: this.certificateButtonText,
          html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
          icon: 'info',
          allowOutsideClick: false
        })
      }
    },

    onPerPageChange (params) {
      this.currentPerPage = params.currentPerPage
    },

    async getReportList () {
      this.haveReportList = false
      var json = JSON.stringify({
        networkType: this.networkType,
        serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
        reportType: this.selectedReportType,
        reportPeriod: this.selectedReportPeriod,
        reportAccessed: this.selectedReportAccessed,
        downloadGenericTestReport: this.downloadGenericTestReport,
        groupType: this.bacsGroup.groupType,
        tokenType: this.bacsGroup.groupTypeDetails.tokenType,
        bacsReports: true,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/getReportList',
        data: json,
        showload: true
      })

      let perPage = this.paginationOptions.perPage

      if (response.data.success === true) {
        this.rows = response.data.availableReports.report
        this.haveReportList = true
        this.filterDownloadFormatOptions()
        // if (this.networkType === 'BacstelIp') {
        //   this.columns[8].hidden = true
        // } else {
        //   this.columns[3].hidden = true
        //   this.columns[7].hidden = true
        // }

        await this.saveBacsReportUserPreferences()
        this.paginationOptions.perPage = this.currentPerPage
        if (this.$refs.reportList) {
          this.$refs.reportList.$refs.paginationBottom.perPage = this.currentPerPage
          this.$refs.reportList.$refs.paginationBottom.handlePerPage()
          this.$refs.reportList.currentPerPage = this.currentPerPage
        }
      } else {
        this.$snapbar.e(response.data.errorMessage)
      }
    },

    // Remove redundant download report formats depending on what reports are returned in the list.
    filterDownloadFormatOptions () {
      if (this.networkType === 'BacstelIp') {
        this.downloadFormats = Array.from(this.allDownloadFormats)
        let anyDdicReports = this.rows.some(a1 => this.ddicReportTypes.includes(a1.reportType))
        let anyNonDdicReports = this.rows.some(a1 => !this.ddicReportTypes.includes(a1.reportType))

        if (!anyDdicReports) {
          this.downloadFormats = this.downloadFormats.filter(i => i.text !== 'PDF')
          this.downloadFormats = this.downloadFormats.filter(i => i.text !== 'CSV')
        } else if (!anyNonDdicReports) {
          this.downloadFormats = this.downloadFormats.filter(i => i.text !== 'HTML')
        }
      }
    },

    async downloadReports () {
      // eslint-disable-next-line
      var selectedReports = this.$refs['reportList'].selectedRows
      var selectedReportUrls = []
      for (var i = 0; i < selectedReports.length; i++) {
        var tmpUrl = selectedReports[i].retrievalUrl
        selectedReportUrls.push(tmpUrl)
      }

      var json = JSON.stringify({
        networkType: this.networkType,
        downloadUrls: selectedReportUrls,
        downloadFormat: this.selectedDownloadFormat,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/downloadReports',
        data: json,
        responseType: 'blob',
        showload: true
      })

      this.saveFile(response)
    },

    async viewReportModal (params, reportFormat) {
      var cellClicked = params.column.field
      if (cellClicked === 'buttons') {
        if (this.networkType === 'BacstelIp') {
          if (reportFormat === 'XML') {
            this.selectedReportFormat = 0
          } else if (reportFormat === 'HTML') {
            this.selectedReportFormat = 1
          } else if (reportFormat === 'PDF') {
            this.selectedReportFormat = 3
          } else if (reportFormat === 'CSV') {
            this.selectedReportFormat = 7
          }
        } else {
          if (reportFormat === 'XML') {
            this.selectedReportFormat = 2
          } else {
            this.selectedReportFormat = 6
          }
        }

        var json = JSON.stringify({
          networkType: this.networkType,
          downloadUrl: params.row.retrievalUrl,
          downloadFormat: this.selectedReportFormat,
          authResponse: this.authResponse
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/viewReport',
          data: json,
          showload: true
        })

        this.reportContent = response.data

        if (this.selectedReportFormat !== 3) {
          // Not PDF format.
          this.isViewReportModalVisible = true
        } else {
          // PDF format.
          if (!this.reportContent.startsWith('data:application/pdf;base64,')) {
            // This is built approach and avoids having new browser tab open with a very long URL.
            // WARNING: Pop up blocker could cause this new tab to close.
            var binaryString = window.atob(this.reportContent);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }

            var blob = new Blob([bytes], {type: "application/pdf"});
            var link = window.URL.createObjectURL(blob);
            window.open(link, '_blank')
          } else {
            // This prefix allows the PDF to be encoded as a very long URL.
            // New browser tab opens with link but doesn't auto load PDF. User has to select the address bar and press 'Return' key.
            window.open(this.reportContent, '_blank')
          }
        }
      }
    },

    // Always a CSV option for FPS. Only a CSV for new DDIC BACS reports.
    showCsvOption (params) {
      let showOption = false
      const reportType = params.row.reportType
      if (this.networkType === 'BacstelIp') {
        if (this.ddicReportTypes.includes(reportType)) {
          showOption = true
        }
      } else {
        showOption = true
      }
      return showOption
    },

    // Only the original BACS reports have a HTML option.
    showHtmlOption (params) {
      let showOption = false
      const reportType = params.row.reportType
      if (this.networkType === 'BacstelIp') {
        if (!this.ddicReportTypes.includes(reportType)) {
          showOption = true
        }
      }
      return showOption
    },

    // All reports have a XML option - for now!
    showXmlOption (params) {
      return true
    },

    // Only new BACS DDIC reports have a PDF option.
    showPdfOption (params) {
      let showOption = false
      const reportType = params.row.reportType
      if (this.networkType === 'BacstelIp') {
        if (this.ddicReportTypes.includes(reportType)) {
          showOption = true
        }
      }
      return showOption
    },

    async viewReportModalBAK (params) {
      console.log('viewReportModal')
      var cellClicked = params.column.field
      console.log('cellClicked: ' + params.column.field)

      if (cellClicked === 'viewReportXml' || cellClicked === 'viewReportHtml' || cellClicked === 'viewReportCsv' || cellClicked === 'viewReportPdf') {
        if (this.networkType === 'BacstelIp') {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 0
          } else if (cellClicked === 'viewReportHtml') {
            this.selectedReportFormat = 1
          } else if (cellClicked === 'viewReportPdf') {
            this.selectedReportFormat = 3
          } else if (cellClicked === 'viewReportCsv') {
            this.selectedReportFormat = 7
          }
        } else {
          if (cellClicked === 'viewReportXml') {
            this.selectedReportFormat = 2
          } else {
            this.selectedReportFormat = 6
          }
        }

        var json = JSON.stringify({
          networkType: this.networkType,
          downloadUrl: params.row.retrievalUrl,
          downloadFormat: this.selectedReportFormat,
          authResponse: this.authResponse
        })

        var response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/viewReport',
          data: json,
          showload: true
        })

        this.reportContent = response.data
        this.isViewReportModalVisible = true
      }
    },

    async getBacsReportUserPreferences (ignoreGroup) {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/report/getBacsReportUserPreferences?networkType=` + this.networkType, { showload: true })

      if (response.data !== null) {
        this.selectedReportType = response.data.reportType
        this.selectedReportPeriod = response.data.reportPeriod
        this.selectedReportAccessed = response.data.reportAccessed

        if (ignoreGroup === false) {
          this.selectedGroupId = response.data.groupId
          this.selectGroup()
        }
      } else {
        this.selectedReportType = this.reportTypes[0].value
      }
    },

    async getUsersDefaultGroup () {
      // Only get default group if no report download preferences have been retrieved.
      if (this.selectedGroup === undefined || Object.keys(this.selectedGroup).length === 0) {
        var defaultGroupId = this.$store.getters.getClaim('defaultgroup').value
        if (defaultGroupId !== '' && defaultGroupId !== undefined && defaultGroupId !== null) {
          this.selectedGroup = this.groups.find(i => i.groupId === defaultGroupId)
          // Default group might be a FPS group which means it can't be the BACS default group and vice versa.
          if (this.selectedGroup !== undefined) {
            this.selectedGroupId = defaultGroupId
            await this.selectGroup()
          }
        }
      }
    },

    async saveBacsReportUserPreferences () {
      var json = JSON.stringify({
        networkType: this.networkType,
        reportType: this.selectedReportType,
        reportPeriod: this.selectedReportPeriod,
        reportAccessed: this.selectedReportAccessed,
        groupId: this.selectedGroupId
      })

      await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/report/saveBacsReportUserPreferences',
        data: json,
        showload: true
      })
    },

    closeViewReport () {
      this.isViewReportModalVisible = false
      this.reportContent = ''
    },

    async viewGroupBankDetails () {
      var groupBankAccount = await this.$store.getters.groupBankAccount
      await swal.fire({
        title: '"' + this.selectedGroup.name + '" group bank details',
        html: bacsCommon.viewGroupBankDetails(groupBankAccount, this.selectedGroupText),
        icon: 'info'
      })
    },

    async loadGroups (groupType) {
      var payload = { paygateId: this.paygateId, groupType: groupType, groupRole: ['collectReports'] }
      await this.$store.dispatch('getGroups', payload)

      if (groupType === this.BACSPGEGROUPTYPE || groupType === this.FPSPGEGROUPTYPE) {
        this.pgeGroups = this.$store.getters.groups
      } else {
        this.pgoGroups = this.$store.getters.groups
      }
    },

    async populateGroupCombo () {
      this.groups = []
      var i = 0
      for (i = 0; i < this.pgeGroups.length; i++) {
        this.groups.push(this.pgeGroups[i])
      }

      for (i = 0; i < this.pgoGroups.length; i++) {
        this.groups.push(this.pgoGroups[i])
      }

      this.groups.sort((a, b) => a.name.localeCompare(b.name))
    },

    async isServiceWindowOpen () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingDate/isServiceWindowOpen?paymentNetworkType=` + this.networkType, { showload: true })
      if (response.data.isWindowOpen === false) {
        this.canProceed = false
        var networkName = this.networkType === 'BacstelIp' ? 'BACS' : 'Faster Payments'
        this.$toastr.w('You cannot download reports at this time as the ' + networkName + ' service window is closed.')
      }
    },

    async loadLicence () {
      var response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence', { showload: true })
      if (this.networkType === 'BacstelIp') {
        this.licenceSetting = response.data.bacs.value
      } else {
        this.licenceSetting = response.data.fps.value
      }
    },

    async checkLicence () {
      var licenceOk = true

      if (this.licenceSetting === 'Direct' || this.licenceSetting === 'Both') {
        await this.loadGroups(this.directGroupType)
      }
      if (this.licenceSetting === 'Indirect' || this.licenceSetting === 'Both') {
        await this.loadGroups(this.indirectGroupType)
      }
      if (this.licenceSetting === 'Off') {
        this.$snapbar.w(this.licenceWarningMessage)
        licenceOk = false
      }

      return licenceOk
    },

    pluginNotInstalled () {
      this.pluginUpdateNotInstalled = true
    }
  },

  async mounted () {
    // Init websigner div so it exists when needed.
    this.webSignerHtml = '<div id="websigner" data-mode="button" data-in_data="DUMMY" data-reply_url="DUMMY" data-certfilter_ku=""192:8""></div>'
    this.showWebSigner = true;

    this.networkType = this.$route.query.networkType
    this.networkName = this.networkType === 'BacstelIp' ? 'BACS' : 'Faster Payments'
    this.currentPage = '/payments/bacsreports/downloadReports?networkType=' + this.networkType
    this.browser = bacsCommon.getBrowser()

    if (this.networkName === 'BACS') {
      this.helpUrl = '/payments/reports/downloadbacsreports/'
    } else {
      this.helpUrl = '/payments/reports/downloadfpsreports/'
    }

    this.paygateId = this.$store.getters.getClaim('paygate_id').value

    if (this.networkType === 'BacstelIp') {
      this.directGroupType = this.BACSPGEGROUPTYPE
      this.indirectGroupType = this.BACSPGOGROUPTYPE
      this.licenceWarningMessage = 'You are not licensed to download BACS reports.'
    } else {
      this.directGroupType = this.FPSPGEGROUPTYPE
      this.indirectGroupType = this.FPSPGOGROUPTYPE
      this.licenceWarningMessage = 'You are not licensed to download Faster Payments reports.'
    }

    await this.loadLicence()

    if (!await this.checkLicence()) {
      return
    }

    await this.populateGroupCombo()
    await this.isServiceWindowOpen()

    if (this.groups.length > 0) {
      await this.$store.dispatch('setGroups', this.groups)
    } else {
      this.$toastr.w('You don\'t have permission to collect reports for any groups.')
    }

    await this.getEnums()

    // Check if redirected here after signing with eSigner.
    var getSignature = this.$route.query.getSignature
    var extLoaded = this.$route.query.pluginAccessible

    if (getSignature === 'true') {
      this.authResponse = {}
      var cookie = {}
      // eslint-disable-next-line
      cookie['name'] = this.$route.query.cookieName
      // eslint-disable-next-line
      cookie['value'] = this.$route.query.cookieValue
      // eslint-disable-next-line
      cookie['domain'] = this.$route.query.cookieDomain

      var cookieCollection = []
      cookieCollection.push(cookie)
      this.authResponse.cookieCollection = cookieCollection
      this.authResponse.cookieValueIsBase64 = true
      this.authResponse.loginChallenge = this.$route.query.challenge

      // TEMP ID just to get signature from database.
      this.submissionId = this.$route.query.submissionId
      this.networkType = this.$route.query.networkType
      this.selectedGroupId = this.$route.query.groupId

      // await this.geteSignerSignature()
      await this.getWebSignerSignature()

      await this.getBacsReportUserPreferences(true)
      this.selectGroup()
    } else if (extLoaded === 'true') {
      if (this.browser === 'Firefox') {
        document.addEventListener('getExtensionResponseWebPage', this.initExtensionEvent)
        document.addEventListener('getPluginResponseWebPage', this.initPluginEvent)
      }

      this.pluginCheckComplete = true
      this.isLoggedIn = false
      this.isLoaded = true

      this.selectedGroupId = this.$store.getters.selectedGroup
      if (this.selectedGroupId !== null && this.selectedGroupId !== undefined) {
        await this.getBacsReportUserPreferences(true)
        await this.selectGroup()
        this.loginStart()
      }
    } else if (extLoaded === 'false') {
      this.isLoggedIn = false
      this.isLoaded = true
      this.selectedGroupId = this.$store.getters.selectedGroup

      if (this.selectedGroupId !== null && this.selectedGroupId !== undefined) {
        await this.getBacsReportUserPreferences(true)
        await this.selectGroup()
      }
    } else {
      await this.getBacsReportUserPreferences(false)
      this.isLoggedIn = false
      this.isLoaded = true
    }

    await this.getUsersDefaultGroup()
    this.numberOfSubmissionBytes = 1 // Not signing a submission but want to use eSigner if no plugin loaded when using IE.
  }
}
</script>
<style>
.report-row-selected {
  height: 60px;
  background-color: #f0f0f0;
  color: black;
}
.grid-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.webSignerWarning {
  color: blue;
  text-align: left; 
}

.dark-mode {
  .report-row-selected {
    height: 60px;
    background-color: black;
    color: white;
  }
  .webSignerWarning {
    color: whitesmoke;
    text-align: left; 
  }
}
</style>
