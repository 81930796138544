var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    _vm._m(0),
    _c("hr"),
    _c(
      "div",
      { staticStyle: { height: "100%" } },
      [
        _c("bar-chart", {
          attrs: { "chart-data": _vm.chartData, options: _vm.chartOptions }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h5", { staticClass: "m-0" }, [_vm._v("Bureau Submission History")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }