var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.verifyCode($event)
          }
        }
      },
      [
        _c(
          "b-modal",
          {
            ref: "verifyModal",
            attrs: {
              id: "verifyModal",
              variant: "primary",
              "no-close-on-backdrop": "",
              "no-close-on-esc": "",
              "hide-header-close": "",
              centered: "",
              static: "",
              title: _vm.title
            },
            on: { shown: _vm.focusMyElement }
          },
          [
            _vm.type == "Email" || _vm.type == "Phone"
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("p", { domProps: { innerHTML: _vm._s(_vm.body) } })
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("p", { staticClass: "help-block" }, [
                        _vm._v(
                          "Once you've received the code, please enter it here."
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { invalid: _vm.incorrectCode }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.code,
                                expression: "code"
                              }
                            ],
                            ref: "code",
                            staticClass: "form-control",
                            attrs: {
                              id: "code",
                              type: "text",
                              autocomplete: "off"
                            },
                            domProps: { value: _vm.code },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.code = $event.target.value
                              }
                            }
                          }),
                          _vm.incorrectCode
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "form-text text-danger small"
                                  },
                                  [_vm._v("The Code is incorrect")]
                                )
                              ])
                            : _vm._e(),
                          _vm.type == "Email"
                            ? _c(
                                "small",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    " Or if you want a link to be sent to your email to confirm later, please "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "alert-link",
                                      attrs: { href: "#" },
                                      on: { click: _vm.sendEmail }
                                    },
                                    [_vm._v("Click Here")]
                                  ),
                                  _vm._v(". ")
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                ])
              : _vm.type == "Authenticator"
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v(
                          "Please scan this QR code with any authentication app"
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6 mx-auto" }, [
                      _vm.qrCode
                        ? _c("img", {
                            staticClass: "img-fluid",
                            staticStyle: {
                              "min-width": "150px",
                              "min-height": "150px",
                              "max-height": "250px"
                            },
                            attrs: { src: _vm.qrCode }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "row text-center" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { invalid: _vm.incorrectCode }
                        },
                        [
                          _c("p", { staticClass: "help-block" }, [
                            _vm._v(
                              "After scanning the QR code, enter the verification code below:"
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.code,
                                expression: "code"
                              }
                            ],
                            ref: "code",
                            staticClass: "form-control",
                            attrs: {
                              id: "code",
                              type: "text",
                              autocomplete: "off"
                            },
                            domProps: { value: _vm.code },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.code = $event.target.value
                              }
                            }
                          }),
                          _vm.incorrectCode
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "text-danger field-validation-error"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v("The Code is incorrect")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c("small", { staticClass: "form-text text-muted" }, [
                            _vm._v(" Your Two-Factor backup code is "),
                            _c("b", [_vm._v(_vm._s(_vm.authenticatorCode))]),
                            _vm._v(". This can be used for manual set up. ")
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _c("template", { slot: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", disabled: _vm.isLoading },
                  on: { click: _vm.hideModal }
                },
                [_vm._v("Close")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: _vm.isLoading }
                },
                [_vm._v("Verify Code")]
              )
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }