import axios from 'axios'

// initial state
const state = {
  messages: [],
  currentMessage: null,
  currentDefaultMessage: null,
  messageError: false,
  assignTemplatesGroup: null
}

// getters
const getters = {
  assignTemplatesGroup: state => {
    return state.assignTemplatesGroup
  }
}

const createFormData = (file) => {
  const data = new FormData()
  data.append('file', file)
  data.append('Content-Type', file.type)
  return data
}

// actions
const actions = {
  async handleAttachmentAdd ({ commit, rootState }, { event }) {
    if (!event.attachment.file) {
      return
    }
    const fileFormData = createFormData(event.attachment.file)

    await axios.post(`${process.env.VUE_APP_DDMS_API_URL}userimages`, fileFormData, {
      onUploadProgress: (e) => {
        var progress = e.loaded / e.total * 100
        event.attachment.setUploadProgress(progress)
      },
      showload: true
    })
    const newAttributes = {
      url: `${process.env.VUE_APP_DDMS_API_URL}userimages/${event.attachment.file.name}`,
      href: `${process.env.VUE_APP_DDMS_API_URL}userimages/${event.attachment.file.name}?content-disposition=attachment`
    }
    event.attachment.setAttributes(newAttributes)
  },
  async handleAttachmentRemove ({ commit, rootState }, { attachments }) {
    attachments.forEach(async event => {
      const filename = event.attachment.attachment.attributes.values.filename
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}userimages/${filename}`)
    })
  },
  async loadMessages ({ commit, rootState }, serverParams) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}messages`, { params: serverParams, showload: true })
      commit('loadMessages', response.data.data)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  async deleteMessage ({ commit, rootState }, messageId) {
    const response = await axios.delete(`${process.env.VUE_APP_DDMS_API_URL}messages/${messageId}`, { showload: true })
    const { deletedMessageId } = response.data
    commit('deleteMessage', deletedMessageId)
  },
  async loadMessageDetails ({ commit, rootState }, messageId) {
    try {
      let url = `${process.env.VUE_APP_DDMS_API_URL}messages/${messageId}`

      const response = await axios.get(url, { showload: true })
      commit('loadMessageDetails', response.data)
    } catch (e) {
      commit('loadMessageDetails', null)
      throw e
    }
  },
  async loadDefaultMessageDetails ({ commit, rootState }, payload) {
    try {
      let url = `${process.env.VUE_APP_DDMS_API_URL}messages/preview/default/${payload.channel}/${payload.type}`
      const response = await axios.get(url, { showload: true })
      commit('loadDefaultMessageDetails', response.data)
    } catch (e) {
      commit('loadDefaultMessageDetails', null)
      throw e
    }
  }
}

// mutations
const mutations = {
  setAssignTemplatesGroup (state, groupId) {
    state.assignTemplatesGroup = groupId
  },
  deleteMessage (state, messageId) {
    state.messages = state.messages.filter(p => p.messageId !== messageId)
    if (state.currentMessage.messageId === messageId) {
      state.currentMessage = {}
    }
  },
  loadMessageDetails (state, details) {
    state.messageError = !details
    state.currentMessage = details
  },
  loadDefaultMessageDetails (state, details) {
    state.messageError = !details
    console.log(details)
    var messageBody = details[0]
    var  messageTitle = ''
    if (details.length > 1) {
      messageTitle = details[1]
    }
    var senderName= 'paygate'
    state.currentDefaultMessage = { messageBody, messageTitle, senderName }
    console.log(state.currentDefaultMessage)
  },
  loadMessages (state, messages) {
    state.messages = messages
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
