var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-content" }, [
            _vm.story.enabled
              ? _c("div", { staticClass: "media pb-1 mb-3" }, [
                  _c("img", {
                    staticClass: "d-block ui-w-40 rounded-circle",
                    attrs: {
                      src: _vm.story.imageUrl,
                      width: "48px",
                      height: "48px",
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "media-body ml-3" }, [
                    _c("div", { staticClass: "text-dark" }, [
                      _vm._v(_vm._s(_vm.story.title))
                    ]),
                    _c("div", { staticClass: "float-left text-muted small" }, [
                      _vm._v(
                        _vm._s(_vm.story.authorName) +
                          " - " +
                          _vm._s(
                            new Date(_vm.story.createdDate).toLocaleString()
                          )
                      )
                    ]),
                    _c("br"),
                    _c("div", {
                      staticClass: "mt-2",
                      domProps: { innerHTML: _vm._s(_vm.story.body) }
                    }),
                    _c("div", { staticClass: "clearfix mt-2" })
                  ])
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }