var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: { docPath: "/paygate-collections/payers/editpayer/" }
          }),
          _vm._v(
            'Payer "' +
              _vm._s(_vm.reference) +
              '" Due To Expire Due to Inactivity'
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "idb-block-content" }, [
      _c("p", [
        _vm._v("The payer below is due to be closed due to inactivity.")
      ]),
      _c("p", [_vm._v("Reference: " + _vm._s(_vm.reference))]),
      _c("p", [_vm._v("Status: " + _vm._s(_vm.status))]),
      _c("p", [
        _vm._v("Most Recent Payment: " + _vm._s(_vm.mostRecentPayment))
      ]),
      _vm.ukPayerId !== null
        ? _c(
            "p",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "editPayer",
                      params: { ukPayerId: _vm.ukPayerId }
                    }
                  }
                },
                [_c("span", [_vm._v("View Payer")])]
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "idb-block-footer noprint-footer" },
      [
        _vm.actionId && _vm.actionId !== null
          ? _c(
              "b-button",
              {
                attrs: { variant: "primary", disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.removeAction($event)
                  }
                }
              },
              [_vm._v("Clear Action")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }