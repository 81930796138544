// Authentication Routes
const Auth = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Authentication/AuthCallback')
const AuthSilent = () => import(/* webpackPrefetch: true */ '@/Assets/Components/Authentication/AuthCallbackSilent')

export default {
  callbacks: [
    {
      path: '/callback',
      component: Auth,
      meta: {
        anon: true
      }
    },
    {
      path: '/silentrenew',
      component: AuthSilent,
      meta: {
        anon: true
      }
    }
  ]
}
