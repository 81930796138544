<template>
	<form @submit.prevent="!$v.$invalid && saveCertificate()">
		<div class="idb-block">
			<div class="idb-block-title">
				<h2>
					Add Customer Certificate
					<help-icon docPath="/administration/customer/certificates/addcertificate/" />
				</h2>
			</div>
			<div class="idb-block-content">
				<div>
					<!-- HSM slots -->
					<div class="row form-group">
						<div class="col-md-2 required">HSM Slots</div>
						<div class="col-md-5">
							<b-form-select v-model="selectedSlotValue" :options="hsmSlots" @input="selectHsmSlot"></b-form-select>
						</div>
					</div>

					<!-- Slot certificates -->
					<div v-if="slotSelected === true" class="row form-group">
						<div class="col-md-2 required">HSM Slot Certificates</div>
						<div class="col-md-5">
							<vue-select
								id="select"
								v-model="selectedCertificateValue"
								:options="slotCertificates"
								:closeOnSelect="true"
								label="text"
								:reduce="certificate => certificate.value"
								:clearable="true"
								@input="selectSlotCertificate"
							></vue-select>
						</div>
					</div>
				</div>

				<div v-if="selected">
					<!-- friendlyName -->
					<div class="form-group row" :class="{invalid: $v.certificate.friendlyName.$error}">
						<label class="col-form-label col-md-2 required">Friendly Name</label>
						<div class="col-md-6">
							<input class="form-control" v-focus v-model.trim="$v.certificate.friendlyName.$model" />
							<span v-if="$v.certificate.friendlyName.$error" class="validation-messages">
								<small
									class="form-text small"
									v-if="!$v.certificate.friendlyName.required"
								>Please enter a friendly name for this certificate</small>
							</span>
						</div>
					</div>

					<!-- distinguishedName -->
					<div class="form-group row">
						<label class="col-form-label col-md-2">Distinguished Name</label>
						<div class="col-md-10">
							<input class="form-control" v-model.trim="certificate.distinguishedName" disabled readonly />
						</div>
					</div>

					<!-- issuer -->
					<div class="form-group row">
						<label class="col-form-label col-md-2">Issuer</label>
						<div class="col-md-10">
							<input class="form-control" v-model.trim="certificate.issuer" disabled readonly />
						</div>
					</div>

					<!-- SerialNumber -->
					<div class="form-group row">
						<label class="col-form-label col-md-2">Serial Number</label>
						<div class="col-md-10">
							<input class="form-control" v-model.trim="certificate.serialNumber" disabled readonly />
						</div>
					</div>

					<!-- Thumbprint -->
					<div class="form-group row">
						<label class="col-form-label col-md-2">Thumbprint</label>
						<div class="col-md-10">
							<input class="form-control" v-model.trim="certificate.thumbprint" disabled readonly />
						</div>
					</div>

					<!-- ValidFrom -->
					<div class="form-group row">
						<label class="col-form-label col-md-2">Valid From</label>
						<div class="col-md-10">
							<input class="form-control" v-model.trim="certificate.validFrom" disabled readonly />
						</div>
					</div>

					<!-- ValidTo -->
					<div class="form-group row">
						<label class="col-form-label col-md-2">Valid To</label>
						<div class="col-md-10">
							<input class="form-control" v-model.trim="certificate.validTo" disabled readonly />
						</div>
					</div>
				</div>
			</div>
			<div class="idb-block-footer">
				<button
					class="btn btn-primary"
					type="submit"
					:disabled="$v.$invalid || isLoading"
				>Save Certificate</button>
				<button class="btn btn-danger pull-right" type="button" @click="cancel">Cancel</button>
			</div>
		</div>
	</form>
</template>

<script>
// Third Party
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

// Components
import VueSelect from 'vue-select'

// Mixins
import loading from '@/Assets/Mixins/LoadingMixin'

export default {
	mixins: [loading],
	components: {
		VueSelect
	},
	computed: {
		...mapGetters(['selectedCustomer'])
	},
	watch: {
		selectedCustomer () { this.$router.push({ name: 'CustomerCertificates' }) }
	},
	data () {
		return {
			hsmSlotsAndCertificates: [],
			hsmSlots: [],
			selectedSlotValue: '',
			selectedCertificateValue: '',
			slotSelected: false,
			slotCertificates: [],
			selected: false,
			certificate: {
				friendlyName: null,
				distinguishedName: null,
				issuer: null,
				serialNumber: null,
				thumbprint: null,
				validFrom: null,
				validTo: null,
				id: null,
			}
		}
	},
	methods: {
		async getHsmSlotsAndCertificates () {
			var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/admin/getHsmSlotsAndCertificates`, { showload: true })
			this.hsmSlotsAndCertificates = response.data
			this.hsmSlots = this.hsmSlotsAndCertificates.map(hsmSlot => {
				return { value: hsmSlot.slotName, text: hsmSlot.tokenLabel }
			})
		},

		selectHsmSlot (slot) {
			var tmpSlot = this.hsmSlotsAndCertificates.find(i => i.slotName === slot)
			if (tmpSlot !== undefined) {
				this.slotCertificates = tmpSlot.slotCertificates.map(certificate => {
					return { value: certificate.certificateThumbprint, text: certificate.certificateLabel }
				})

				this.slotCertificates.sort((a, b) => a.text.localeCompare(b.text))

				this.slotSelected = true
			}
		},

		selectSlotCertificate (certificate) {
			var selectedCert = this.hsmSlotsAndCertificates.find(s => s.slotName === this.selectedSlotValue).slotCertificates.find(c => c.certificateThumbprint === certificate)
			if (selectedCert) {
				console.log(selectedCert)
				this.selected = true
				this.certificate.distinguishedName = selectedCert.certificateDistinguishedName
				this.certificate.issuer = selectedCert.certificateIssuer
				this.certificate.serialNumber = selectedCert.certificateSerialNumber
				this.certificate.thumbprint = selectedCert.certificateThumbprint
				this.certificate.validFrom = selectedCert.certificateValidFrom
				this.certificate.validTo = selectedCert.certificateValidTo
				this.certificate.id = selectedCert.certificateId
			}
		},
		async saveCertificate () {
			try {
				this.certificate.paygateId = this.$store.state.common.paygateId
				await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Certificate`, this.certificate, {
					showload: true,
					showerror: true,
					errormessage: 'Certificate failed to save'
				})
				this.$toastr.s('Customer Certificate Created', 'Created')
				this.$router.back()
			} catch { }
		},
		cancel () {
			this.$router.back()
		}
	},
	async mounted () {
		await this.getHsmSlotsAndCertificates()
	},
	validations: {
		certificate: {
			friendlyName: { required }
		}
	}
}

</script>
<style lang="scss" scoped>
.dropbox {
	position: relative;
}
.input-file {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	cursor: pointer;
	min-height: 150px;
	left: 0;
	top: 0;
	&:disabled {
		cursor: not-allowed;
	}
}
</style>
