<template>
  <div id="downloadBureauCustomerReports">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Bureau Customer Configuration Report
              <help-icon :docPath="'/bacsbureau/reports/'"></help-icon>
              <favourite-icon></favourite-icon>
            </h2>
          </div>

          <div class="idb-block-content">
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label>Search Type</label>
              </div>
              <div class="col-md-6">
                <vue-select
                  v-model="searchType"
                  label="name"
                  :options="searchTypes"
                  :closeOnSelect="false"
                  @input="searchTypeChanged"
                  :clearable="false"
                ></vue-select>
              </div>
            </div>
            <template v-if="checkedAdvanced">
              <div class="row form-group col-md-12">
                <div class="col-md-2">
                  <label>Bureau Jobs</label>
                </div>
                <div class="col-md-6">
                  <vue-select
                    multiple
                    v-model="SelectedBureauJobs"
                    label="name"
                    :options="BureauJobDisplay"
                    :closeOnSelect="false"
                    @input="CheckBureauJobsDropdownEntries"
                  ></vue-select>
                </div>
              </div>
              <div class="row form-group col-md-12">
                <div class="col-md-2">
                  <label>Bureau Customers</label>
                </div>
                <div class="col-md-6">
                  <vue-select
                    multiple
                    v-model="SelectedBureauCustomers"
                    label="name"
                    :options="BureauCustomersDisplay"
                    :closeOnSelect="false"
                    @input="CheckBureauCustomerDropdownEntries"
                  ></vue-select>
                </div>
              </div>
              <div class="row form-group col-md-12">
                <div class="col-md-2">
                  <label class="mt-2" for="filter">Group</label>
                </div>
                <div class="col-md-6">
                  <group-select v-model="groupId" :groups="groups" :clearable="false" @input="load"></group-select>
                </div>
              </div>
            </template>

            <!-- TABLE -->

            <vue-good-table
              mode="remote"
              :paginationOptions="paginationOptions"
              :rows="rows"
              :columns="columns"
              :totalRows="totalRecords"
              :lineNumbers="true"
              :searchOptions="{
                          enabled: true
                        }"
              :sortOptions="{
                        enabled: true
                      }"
              @on-search="onSearch"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              ref="table"
              styleClass="vgt-table striped bordered"
            >
              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'"
                >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                  <span class="sr-only">Clear filters</span>
                </button>
                <b-button
                  @click.prevent="load"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i>
                  <span class="sr-only">Refresh Table</span>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Export the contents of the table'"
                >
                  <i class="fa fa-share-square pointer dimmedIcon"></i>
                  <span class="sr-only">Export Table</span>
                </b-button>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import papa from 'papaparse'
import { mapGetters } from 'vuex'
export default {
  mixins: [Utility, tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  components: {
    VueSelect
  },

  data () {
    return {
      searchTypes: [
        { id: 0, name: 'Select All Bureau Customers Not In Jobs' },
        { id: 1, name: 'Select All Bureau Jobs' },
        { id: 2, name: 'Select All Bureau Customers' },
        { id: 3, name: 'Advanced' }
      ],
      searchType: { id: 2, name: 'Select All Bureau Customers' },
      isTableLoading: true,
      BureauCustomersLoaded: false,
      BureauJobsLoaded: false,
      checkedAllCustomers: true,
      checkedAllJobs: false,
      checkedAllNotInJob: false,
      checkedAdvanced: false,
      SelectedBureauCustomers: [],
      BureauCustomers: [],
      BureauCustomersDisplay: [],
      BureauJobs: [],
      BureauJobDisplay: [],
      SelectedBureauJobs: [],
      inverseBureauCustomerSelection: false,
      serverParams: {
        sort: [{ field: 'bureaucustomername', type: 'asc' }]
      },
      // page: 1,
      // perPage: 10,
      // totalRecords: 0,
      // searchTerm: '',
      rows: [],
      columns: [ // BureauJobId, BureauJobName, BureauCustomerId,  BureauCustomerName, ServiceUserNumber, BankReference, SortCode, AccountNum, Filename
        {
          label: 'Bureau Customer Name',
          field: 'bureauCustomerName'
        },
        {
          label: 'Bureau Job Name',
          field: 'bureauJobName'
        },
        {
          label: 'Bureau Job Group',
          field: 'bureauJobGroup'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Filename',
          field: 'filename'
        },
        {
          label: 'Bank Reference',
          field: 'bankReference'
        },
        {
          label: 'Sort Code',
          field: 'sortCode'
        },
        {
          label: 'Account Number',
          field: 'accountNumber'
        }
      ],
      highGuid: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
      groups: [],
      groupId: '00000000-0000-0000-0000-000000000000'
    }
  },

  methods: {
    async load () {
      this.isTableLoading = true

      var jobIdList = []
      var jobIndex = 0
      for (jobIndex = 0; jobIndex < this.SelectedBureauJobs.length; ++jobIndex) {
        jobIdList.push(this.SelectedBureauJobs[jobIndex].id)
      }

      var customerIdList = []
      var customerIndex = 0
      for (customerIndex = 0; customerIndex < this.SelectedBureauCustomers.length; ++customerIndex) {
        customerIdList.push(this.SelectedBureauCustomers[customerIndex].id)
      }

      var searchData = {
        checkedAllCustomers: this.checkedAllCustomers,
        checkedAllJobs: this.checkedAllJobs,
        checkedAllNotInJobs: this.checkedAllNotInJob,
        inverseBureauCustomerSelection: this.inverseBureauCustomerSelection,
        selectedBureauJobs: jobIdList,
        selectedBureauCustomers: customerIdList,
        selectedGroup: this.groupId
      }
      this.serverParams.searchData = searchData

      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/loadTable', { params: { ...this.buildGoodTableQuery(), searchData }, showload: true })
      this.rows = response.data.data
      this.totalRecords = response.data.meta.totalItems
      await this.getBureauJobs()
      this.isTableLoading = true
    },
    async exportTable () {
      var jobIdList = []
      var jobIndex = 0
      for (jobIndex = 0; jobIndex < this.SelectedBureauJobs.length; ++jobIndex) {
        jobIdList.push(this.SelectedBureauJobs[jobIndex].id)
      }

      var customerIdList = []
      var customerIndex = 0
      for (customerIndex = 0; customerIndex < this.SelectedBureauCustomers.length; ++customerIndex) {
        customerIdList.push(this.SelectedBureauCustomers[customerIndex].id)
      }

      var searchData = {
        checkedAllCustomers: this.checkedAllCustomers,
        checkedAllJobs: this.checkedAllJobs,
        checkedAllNotInJobs: this.checkedAllNotInJob,
        inverseBureauCustomerSelection: this.inverseBureauCustomerSelection,
        selectedBureauJobs: jobIdList,
        selectedBureauCustomers: customerIdList
      }
      this.serverParams.searchData = searchData
      const query = this.buildGoodTableQuery()
      query.perPage = this.totalRecords
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/exportTable', { params: { ...query, searchData }, showload: true })
      const fileName = 'customerreport.csv'
      var csvString = papa.unparse(response.data)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    async searchTypeChanged (e) {
      if (e.id === 0) {
        await this.reportAllNotInJob()
      } else if (e.id === 1) {
        await this.reportAllJobs()
      } else if (e.id === 2) {
        await this.reportAllCustomers()
      } else {
        await this.advancedChecked()
      }
    },

    async reportAllNotInJob () {
      this.checkedAllNotInJob = true
      this.checkedAllCustomers = false
      this.checkedAllJobs = false
      this.checkedAdvanced = false
      this.SelectedBureauCustomers = []
      this.SelectedBureauJobs = []
      await this.load()
    },

    async reportAllCustomers () {
      this.checkedAllNotInJob = false
      this.checkedAllJobs = false
      this.checkedAllCustomers = true
      this.checkedAdvanced = false
      this.SelectedBureauCustomers = []
      this.SelectedBureauJobs = []
      await this.load()
    },

    async reportAllJobs () {
      this.checkedAllNotInJob = false
      this.checkedAllJobs = true
      this.checkedAllCustomers = false
      this.checkedAdvanced = false
      this.SelectedBureauCustomers = []
      this.SelectedBureauJobs = []
      await this.load()
    },
    async advancedChecked () {
      this.checkedAllNotInJob = false
      this.checkedAllCustomers = false
      this.checkedAdvanced = true
      this.SelectedBureauCustomers = [{
        id: this.highGuid,
        name: 'All Bureau Customers...',
        disabled: false
      }]
      this.SelectedBureauJobs = [{
        id: this.highGuid,
        name: 'All Bureau Jobs...',
        disabled: false
      }]
      this.groupId = '00000000-0000-0000-0000-000000000000'
      await this.load()
    },

    async inverseBureauCustomerSelectionSelected () {
      this.checkedAllNotInJob = false
      this.checkedAllCustomers = false
      this.checkedAllData = false
      this.inverseBureauCustomerSelection = true
      this.checkedAdvanced = false
      if (this.SelectedBureauJobs.length === 0) {
        this.inverseBureauCustomerSelection = false
      }

      if (this.inverseBureauCustomerSelection) {
        this.SelectedBureauCustomers = []
      }
      await this.load()
    },

    async getBureauJobs () {
      var result = await axios.get(this.groupId !== undefined && this.groupId.toString() !== '00000000-0000-0000-0000-000000000000' ? process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/getgroupjobsmodel/' + this.groupId : process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomerreports/getgroupjobsmodel')
      this.BureauJobs = result.data
      this.BureauJobDisplay = [] // reset the display array

      var BureauJobSelectAllOption = {
        id: this.highGuid,
        name: 'All Bureau Jobs...',
        disabled: false
      }
      this.BureauJobDisplay.push(BureauJobSelectAllOption)

      var index = 0
      for (index = 0; index < this.BureauJobs.length; ++index) {
        var currentJob = {
          id: this.BureauJobs[index].bureauJobId,
          name: this.BureauJobs[index].bureauJobName + ' (' + this.BureauJobs[index].groupName + ')',
          disabled: false
        }
        this.BureauJobDisplay.push(currentJob)
      }
      this.BureauJobsLoaded = true
    },
    async getBureauCustomers () {
      var query = {
        params: {
          fields: 'name,serviceusernumber,bureaucustomerid'
        }
      }
      var ids = this.SelectedBureauJobs.map(x => x.id)
      query.params.selectedBureauJobs = this.SelectedBureauJobs
      query.params.jobIds = ids
      query.params.perPage = 100
      await axios.get(ids.length > 0 && ids[0] !== this.highGuid ? process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/getforjob' : process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer', query)
        .then(response => {
          this.BureauCustomers = ids.length > 0 && ids[0] !== this.highGuid ? response.data : response.data.data
          this.BureauCustomers.sort((x, y) => { return x.name.localeCompare(y.name) })
          this.BureauCustomersDisplay = [] // reset the display array

          var BureauCustomerSelectAllOption = {
            id: this.highGuid,
            serviceUserNumber: '000000',
            name: 'All Bureau Customers...',
            disabled: false
          }

          this.BureauCustomersDisplay.push(BureauCustomerSelectAllOption)

          var index = 0
          for (index = 0; index < this.BureauCustomers.length; ++index) {
            var currentBC = {
              id: this.BureauCustomers[index].bureauCustomerId,
              serviceUserNumber: this.BureauCustomers[index].serviceUserNumber,
              name: this.BureauCustomers[index].name + ' (' + this.BureauCustomers[index].serviceUserNumber + ')',
              disabled: false
            }
            this.BureauCustomersDisplay.push(currentBC)
          }
          this.BureauCustomersLoaded = true
        })
        .catch(error => console.error(error))
    },
    async CheckBureauCustomerDropdownEntries (value) {
      // Clear Down Other Entries
      this.checkedAllCustomers = false
      this.checkedAllJobs = false
      this.checkedAllNotInJob = false
      this.inverseBureauCustomerSelection = false
      var bureauCustomerIdAdded = value.id
      if (value.length > 0 && value[value.length - 1].id === this.highGuid) {
        var allVal = value[value.length - 1]
        this.SelectedBureauCustomers = []
        this.SelectedBureauCustomers.push(allVal)

        // disable all other entries
        var i = 0
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (this.BureauCustomersDisplay[i].id === bureauCustomerIdAdded) {
            this.BureauCustomersDisplay[i].disabled = true
          } else {
            this.BureauCustomersDisplay[i].disabled = false
          }
        }
      } else {
        if (value.length > 0 && value[0].id === this.highGuid) {
          this.SelectedBureauCustomers = this.SelectedBureauCustomers.slice(1)
        }
        for (i = 0; i < this.BureauCustomersDisplay.length; ++i) {
          if (this.BureauCustomersDisplay[i].id === this.highGuid) {
            this.BureauCustomersDisplay[i].disabled = false
          } else {
            this.BureauCustomersDisplay[i].disabled = true
          }
        }
      }

      await this.load()
    },

    async CheckBureauJobsDropdownEntries (value) {
      this.checkedAllCustomers = false
      this.checkedAllJobs = false
      this.checkedAllNotInJob = false

      var p = this.SelectedBureauJobs.length - 1
      var bureauJobIdAdded = this.SelectedBureauJobs[p].id

      if (bureauJobIdAdded === this.highGuid) {
        this.SelectedBureauJobs = this.SelectedBureauJobs.slice(this.SelectedBureauJobs.length - 1)

        var i = 0
        for (i = 0; i < this.BureauJobDisplay.length; ++i) {
          if (this.BureauJobDisplay[i].id === bureauJobIdAdded) {
            this.BureauJobDisplay[i].disabled = false
          } else {
            this.BureauJobDisplay[i].disabled = true
          }
        }
      } else {
        for (i = 0; i < this.BureauJobDisplay.length; ++i) {
          if (this.BureauJobDisplay[i].id === this.highGuid) {
            this.BureauJobDisplay[i].disabled = true
          } else {
            this.BureauJobDisplay[i].disabled = false
          }
        }
        if (p > 0 && (this.SelectedBureauJobs[0].id === this.highGuid)) {
          this.SelectedBureauJobs.shift()
        }
      }
      await this.getBureauCustomers()
      await this.load()
    },

    async getBureauGroupData () {
      const getGroupsResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaugroups/getbureaugroups', { showload: true })
      var allGroupsOption = {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'All Bureau Groups...',
        disabled: false
      }
      this.groups = getGroupsResponse.data
      this.groups.unshift(allGroupsOption)
    }
  },

  async mounted () {
  },
  async created () {
    this.isTableLoading = true
    await this.getBureauJobs()
    await this.getBureauCustomers()
    await this.getBureauGroupData()
    this.isTableLoading = false
    await this.load()
  },
  watch: {
    async selectedCustomer () {
      this.isTableLoading = true
      await this.getBureauJobs()
      await this.getBureauCustomers()
      await this.getBureauGroupData()
      this.isTableLoading = false
      await this.load()
    }
  }
}
</script>
