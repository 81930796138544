var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", [
                _c("div", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "required" }, [_vm._v("Title")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.workflowMetaData.title,
                          expression: "workflowMetaData.title"
                        }
                      ],
                      staticClass: "form-control",
                      class: { invalid: _vm.$v.workflowMetaData.title.$error },
                      attrs: { type: "text" },
                      domProps: { value: _vm.workflowMetaData.title },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.workflowMetaData,
                              "title",
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.$v.workflowMetaData.title.$touch()
                          }
                        ]
                      }
                    }),
                    !_vm.$v.workflowMetaData.title.maxLength &&
                    _vm.$v.workflowMetaData.title.$dirty
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v(
                            "The title is too long. The maximum length is 64 characters."
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.workflowMetaData.title.required &&
                    _vm.$v.workflowMetaData.title.$dirty
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v("A workflow title is required.")
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                      _vm._v("Description")
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.workflowMetaData.description,
                          expression: "workflowMetaData.description"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        invalid: _vm.$v.workflowMetaData.description.$error
                      },
                      attrs: { rows: "4", cols: "60" },
                      domProps: { value: _vm.workflowMetaData.description },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.workflowMetaData,
                              "description",
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.$v.workflowMetaData.description.$touch()
                          }
                        ]
                      }
                    }),
                    !_vm.$v.workflowMetaData.description.maxLength &&
                    _vm.$v.workflowMetaData.description.$dirty
                      ? _c("p", { staticClass: "validationText" }, [
                          _vm._v(
                            "The description is too long. The maximum length is 128 characters."
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("br"),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("label", [_vm._v("Author")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.workflowMetaData.author,
                            expression: "workflowMetaData.author"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          invalid: _vm.$v.workflowMetaData.author.$error
                        },
                        attrs: { type: "text", disabled: true },
                        domProps: { value: _vm.workflowMetaData.author },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.workflowMetaData,
                                "author",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.workflowMetaData.author.$touch()
                            }
                          ]
                        }
                      }),
                      !_vm.$v.workflowMetaData.author.maxLength &&
                      _vm.$v.workflowMetaData.author.$dirty
                        ? _c("p", { staticClass: "validationText" }, [
                            _vm._v(
                              "The author field is too long. The maximum length is 128 characters."
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "col-md-2" }),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [_vm._v("Type")]),
                      _c("br"),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.workflowMetaData.type,
                              expression: "workflowMetaData.type"
                            }
                          ],
                          staticClass: "custom-select custom-select-lg",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.workflowMetaData,
                                "type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.typeOptions, function(option) {
                          return _c(
                            "option",
                            {
                              key: option.index,
                              domProps: { value: option.value }
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _c("br")
                ])
              ])
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm._t("footer", [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "danger", title: "Cancel" },
                    on: { click: _vm.btnCancel }
                  },
                  [
                    _c("span", {
                      staticClass: "fa fa-times",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" Cancel ")
                  ]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success", title: "Save Metadata" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.btnSave($event)
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "fa fa-save",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" Save ")
                  ]
                )
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }