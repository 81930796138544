var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "audits" } }, [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" Audit Log "),
            _c("help-icon", {
              attrs: { docPath: "/administration/customer/auditlogs/" }
            }),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c("div", { staticClass: "row mb-1" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "form-label", attrs: { for: "fromDate" } },
                  [_vm._v("From Date")]
                ),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _c("date-picker", {
                      attrs: {
                        id: "fromDate",
                        config: _vm.fromDateOptions,
                        wrap: true
                      },
                      on: { "dp-change": _vm.load },
                      model: {
                        value: _vm.fromDate,
                        callback: function($$v) {
                          _vm.fromDate = $$v
                        },
                        expression: "fromDate"
                      }
                    }),
                    _vm._m(0)
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "form-label", attrs: { for: "toDate" } },
                  [_vm._v("To Date")]
                ),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _c("date-picker", {
                      attrs: {
                        id: "toDate",
                        config: _vm.toDateOptions,
                        wrap: true
                      },
                      on: { "dp-change": _vm.load },
                      model: {
                        value: _vm.toDate,
                        callback: function($$v) {
                          _vm.toDate = $$v
                        },
                        expression: "toDate"
                      }
                    }),
                    _vm._m(1)
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info mt-2",
                    staticStyle: { display: "block" },
                    attrs: { type: "button" },
                    on: { click: _vm.resetDates }
                  },
                  [_vm._v("Reset Dates")]
                )
              ])
            ])
          ]),
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                paginationOptions: _vm.paginationOptions,
                "sort-options": _vm.sortOptions,
                isLoading: _vm.isTableLoading,
                rows: _vm.rows,
                columns: _vm.columns,
                lineNumbers: true,
                mode: "remote",
                totalRows: _vm.totalRecords,
                styleClass: "vgt-table striped bordered",
                "row-style-class": _vm.checkSumValid
              },
              on: {
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                },
                "on-row-click": _vm.onRowClick,
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "table-actions",
                    {
                      attrs: {
                        "data-url": _vm.dataUrl,
                        "export-data": _vm.tableServerData,
                        "print-enabled": false,
                        "table-amount": _vm.totalRecords,
                        "export-name": "test",
                        "reload-table": _vm.load,
                        tableColumns: _vm.columns
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Clear filters",
                              expression: "'Clear filters'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          staticClass: "btn btn-link",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.clearTableFilters() && _vm.resetDates()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fa-stack",
                              staticStyle: { "font-size": "10px" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-filter fa-stack-1x dimmedIcon"
                              }),
                              _c("i", {
                                staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                              })
                            ]
                          ),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Clear filters")
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._m(3)
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "far fa-calendar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("span", { staticClass: "far fa-calendar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-label" }, [_c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticClass: "alert alert-secondary", attrs: { role: "alert" } },
          [
            _c("p", [
              _c("span", { staticClass: "success" }, [_vm._v("Green")]),
              _vm._v(" means that the audit has not been tampered with ")
            ]),
            _c("p", [
              _c("span", { staticClass: "danger" }, [_vm._v("Red")]),
              _vm._v(" means that the audit has been tampered with ")
            ]),
            _c("p", [
              _c("span", { staticClass: "blue" }, [_vm._v("Blue")]),
              _vm._v(
                " means that the audit has been imported and is exempt from tamper protection "
              )
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }