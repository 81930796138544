import moment from 'moment'

function formatDate (value, inputFormat = null, outputFormat = 'DD/MM/YYYY') {
  let testDate = moment(value, inputFormat)

  if (!testDate.isValid()) {
    testDate = moment(value)
  }

  return testDate.format(outputFormat)
}

export {
  formatDate
}
