var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.dataLoaded
      ? _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(0),
              _c("div", { staticClass: "idb-block-content" }, [
                _c("div", { staticClass: "container-fluid" }, [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("SMS Sent")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.smsSent,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Emails Sent")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.emailSent,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Address Lookups")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.addressLookupSent,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Bacs Submissions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.bacsSubs,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Bacs Transactions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.bacsTrans,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-5" }, [
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("FPS Submissions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.fpsSubs,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("FPS Transactions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.fpsTrans,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Collections Submissions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.collectionsSubs,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Collections Transactions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.collectionsTrans,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Bureau Unique SUNs")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.bureauUniqueSuns,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-5" }, [
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Bureau Submissions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.bureauSubs,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Bureau Transactions")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.bureauTrans,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Workflows Executed")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.workflowsExecuted,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Agent Files Downloaded")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.agentFilesDownloaded,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Agent Files Uploaded")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.agentFilesUploaded,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-5" }, [
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Credit Card Validations")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.creditCardValidations,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("Bank Account Validations")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.bankAccountValidations,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col text-center" }, [
                      _c("h5", [_vm._v("IBAN Validations")]),
                      _c(
                        "h2",
                        [
                          _c("i-count-up", {
                            staticClass: "ml-2",
                            attrs: {
                              delay: 1,
                              endVal: _vm.ibanValidations,
                              options: _vm.counterOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._m(1)
                ])
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm.dataLoaded
      ? _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "idb-block" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c("vue-good-table", {
                    attrs: {
                      rows: _vm.rows,
                      columns: _vm.columns,
                      styleClass: "vgt-table striped bordered  table-responsive"
                    },
                    on: { "on-row-click": _vm.onRowClick }
                  })
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Billing Report All Customer Stats")]),
      _c("span", {})
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-4 justify-content-center" }, [
      _c("div", { staticClass: "text-small col-12 text-center" }, [
        _vm._v(
          "This is a sum of all the customers below, to go into the specific details about a customer and how close they are to certain thresholds in the licence, please click on them in the table below"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Billing Report All Customer Table")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }