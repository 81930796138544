var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "h5",
          { staticClass: "unpad" },
          [
            _vm._v("Workflow Load: "),
            _c("span", { staticClass: "small" }, [
              _c("strong", [_vm._v(_vm._s(_vm.workflow.title) + " (")])
            ]),
            _c(
              "router-link",
              {
                staticClass: "small",
                attrs: {
                  tag: "a",
                  to: "/automation/workflow/workflowdetail/" + _vm.workflowId
                }
              },
              [_vm._v("View")]
            ),
            _c("span", [_vm._v(")")]),
            _c("span", { staticClass: "validationText" }, [
              _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
            ])
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("load-chart", {
            attrs: {
              height: 375,
              frequency: "minute",
              title: "Concurrent Workflows",
              workflowid: _vm.workflowId
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }