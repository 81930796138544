var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: {
              docPath: "/paygate-collections/hosted-forms/managestyles/"
            }
          }),
          _vm._v("Manage Stylesheet"),
          _c("favourite-icon")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _vm.currentFile.addedDate !== null
          ? _c("div", [
              _c("p", [
                _c("strong", [_vm._v("Current stylesheet:")]),
                _vm._v(" " + _vm._s(_vm.currentFile.stylesheet))
              ]),
              _c("p", [
                _c("strong", [_vm._v("Last Updated:")]),
                _vm._v(" " + _vm._s(_vm.formatDate(_vm.currentFile.addedDate)))
              ])
            ])
          : _vm._e(),
        _c(
          "b-container",
          [
            !_vm.useDropzone
              ? _c("dropzoneLike", {
                  ref: "serverFileUploader",
                  attrs: {
                    dropzoneOptions: _vm.dropzoneOptions,
                    dropzoneUrl: _vm.dropzoneUrl,
                    secureUrl: _vm.secureUrl,
                    groupId: _vm.group
                  },
                  on: {
                    fileAdded: _vm.publicFileAdded,
                    fileRemoved: _vm.publicFileRemoved
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm.useDropzone
          ? _c(
              "vue-dropzone",
              {
                ref: "fileUploader",
                attrs: {
                  id: "drop1",
                  url: _vm.dropzoneUrl,
                  options: _vm.dropzoneOptions,
                  "use-custom-slot": true,
                  "accepted-file-types": _vm.dropzoneOptions.acceptedFiles,
                  "max-file-size-in-m-b": 200,
                  useCustomDropzoneOptions: true,
                  "use-custom-drop-zone-options": true,
                  useCustomSlot: true
                },
                on: {
                  "vdropzone-success": _vm.afterSuccess,
                  "vdropzone-file-added": _vm.fileAdded,
                  "vdropzone-removed-file": _vm.fileRemoved,
                  "vdropzone-sending": _vm.sendingEvent
                }
              },
              [
                _c("div", { staticClass: "dropzone-custom-content" }, [
                  _c("h3", { staticClass: "dropzone-custom-title" }, [
                    _vm._v(_vm._s(_vm.dropzoneOptions.title))
                  ]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(_vm._s(_vm.dropzoneOptions.subtitle))
                  ])
                ])
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "idb-block-footer noprint-footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }