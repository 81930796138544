<template>
  <actions-block
    :action="action"
    :status="status"
    :from="from"
    name="Group"
    v-if="action.id"
    :shouldShowFooter="shouldShowFooter"
    :userRoleError="userRoleError"
  >
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>Group Information</h2>
      </div>
      <div class="idb-block-content">
        <div class="alert alert-secondary" role="alert" v-if="status === 'Edit'">
          The previous details are shown in
          <span class="del">red</span>, the new details in
          <span class="ins">green</span>.
        </div>
        <b-tabs lazy>
          <b-tab title="Group Details" active>
            <div class="form-group row">
              <label for="description" class="col-sm-2 text-right col-form-label">Reference</label>
              <div class="col-sm-10">
                <div class="form-control-plaintext">
                  <span
                    style="margin-right:5px"
                    v-for="diff in difference.name"
                    :key="diff.count"
                    :class="{ins:diff.added,del:diff.removed}"
                  >{{diff.value}}</span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="description" class="col-sm-2 text-right col-form-label">Description</label>
              <div class="col-sm-10">
                <div class="form-control-plaintext">
                  <span
                    style="margin-right:5px"
                    v-for="diff in difference.description"
                    :key="diff.value"
                    :class="{ins:diff.added,del:diff.removed}"
                  >{{diff.value}}</span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="description" class="col-sm-2 text-right col-form-label">Type</label>
              <div class="col-sm-10">
                <div class="form-control-plaintext">
                  <span
                    style="margin-right:5px"
                    v-for="diff in difference.groupType"
                    :key="diff.value"
                    :class="{ins:diff.added,del:diff.removed}"
                  >{{diff.value}}</span>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Group Users -->
          <b-tab v-if="group" title="Group Users">
            <group-users-details
              :status="status"
              :groupUserRights="group.groupUserRights"
              :actionUserRights="action.data.groupUserRights"
            ></group-users-details>
          </b-tab>

          <!-- BACS Config -->
          <b-tab v-if="group && group.groupType === groupTypes.bacs " title="BACS Config">
            <bacs-details
              :groupTypeDetails="group.groupTypeDetails"
              :actionTypeDetails="action.data.groupTypeDetails"
            ></bacs-details>
          </b-tab>

          <!-- BACS Online Config -->
          <b-tab
            v-if="group && group.groupType === groupTypes.bacsOnline "
            title="BACS Online Config"
          >
            <bacs-online-details
              :groupTypeDetails="group.groupTypeDetails"
              :actionTypeDetails="action.data.groupTypeDetails"
            ></bacs-online-details>
          </b-tab>

          <!-- FPS Config -->
          <b-tab v-if="group && group.groupType === groupTypes.fps" title="FPS Config">
            <fps-details
              :groupTypeDetails="group.groupTypeDetails"
              :actionTypeDetails="action.data.groupTypeDetails"
            ></fps-details>
          </b-tab>

          <!-- FPS Online Config -->
          <b-tab v-if="group && group.groupType === groupTypes.fpsOnline" title="FPS Online Config">
            <fps-online-details
              :groupTypeDetails="group.groupTypeDetails"
              :actionTypeDetails="action.data.groupTypeDetails"
            ></fps-online-details>
          </b-tab>

          <!-- Bureau -->
          <b-tab v-if="group && group.groupType === groupTypes.bureau" title="Bureau Config">
            <bureau-details
              :groupTypeDetails="group.groupTypeDetails"
              :actionTypeDetails="action.data.groupTypeDetails"
            ></bureau-details>
          </b-tab>

          <!-- Collections -->
          <b-tab
            v-if="group && group.groupType === groupTypes.collections"
            title="Collections Config"
          >
            <collections-details
              :groupTypeDetails="group.groupTypeDetails"
              :actionTypeDetails="action.data.groupTypeDetails"
            ></collections-details>
          </b-tab>

          <!-- Approval Settings -->
          <b-tab
            v-if="group && (group.groupType === groupTypes.bacs || group.groupType === groupTypes.collection || group.groupType === groupTypes.sepa || group.groupType === groupTypes.fps ||
                group.groupType === groupTypes.bacsOnline|| group.groupType === groupTypes.fpsOnline)"
            title="Approval Settings"
          >
            <approval-settings-details :group="group" :actionGroup="action.data"></approval-settings-details>
          </b-tab>

          <!-- Auto File Import Config -->
          <b-tab v-if="showAutoFileImportTab" title="Auto File Import Config">
            <auto-file-import-details
              :status="status"
              :groupAutoFileImport="group.groupTypeDetails.bacsAutoFileImportConfig"
              :actionAutoFileImport="action.data.groupTypeDetails.bacsAutoFileImportConfig"
            ></auto-file-import-details>
          </b-tab>

          <!-- Anti-Fraud Lists -->
          <b-tab v-if="showAntiFraudListTab" title="Anti-Fraud Lists">
            <group-anti-fraud-list-details
              :status="status"
              :groupAntiFraudLists="group.antiFraudGroupLists"
              :actionAntiFraudLists="action.data.antiFraudGroupLists"
            ></group-anti-fraud-list-details>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </actions-block>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { diffSentences } from 'diff'
import axios from 'axios'
import ActionsBlock from '@/Components/Platform/Actions/ActionsBlock'
import groupTypes from '@/Assets/Constants/groupTypes'

// Group types
import BacsDetails from '@/Components/Platform/Actions/Types/Group/BACS/BACSGroupDetails'
import BacsOnlineDetails from '@/Components/Platform/Actions/Types/Group/BACSOnline/BACSOnlineGroupDetails'
import FpsDetails from '@/Components/Platform/Actions/Types/Group/FPS/FPSGroupDetails'
import FpsOnlineDetails from '@/Components/Platform/Actions/Types/Group/FPSOnline/FPSOnlineGroupDetails'
import BureauDetails from '@/Components/Platform/Actions/Types/Group/Bureau/BureauGroupDetails'
import CollectionsDetails from '@/Components/Platform/Actions/Types/Group/Collections/CollectionsGroupDetails'

// Other tabs
import GroupUsersDetails from '@/Components/Platform/Actions/Types/Group/GroupUsersDetails'
import ApprovalSettingsDetails from '@/Components/Platform/Actions/Types/Group/ApprovalSettingsDetails'
import GroupAntiFraudListDetails from '@/Components/Platform/Actions/Types/Group/GroupAntiFraudListDetails'
import AutoFileImportDetails from '@/Components/Platform/Actions/Types/Group/AutoFileImportDetails'

export default {
  props: ['actionId', 'groupId'],
  components: {
    ActionsBlock,
    BacsDetails,
    BacsOnlineDetails,
    FpsDetails,
    FpsOnlineDetails,
    BureauDetails,
    CollectionsDetails,
    GroupUsersDetails,
    ApprovalSettingsDetails,
    GroupAntiFraudListDetails,
    AutoFileImportDetails
  },
  computed: {
    difference () {
      if (!this.group || !this.action.data) {
        if (this.status === 'Create') {
          this.group = this.action.data
        } else if (this.status === 'Delete' && this.group) {
          this.action.data = this.group
        } else {
          return {}
        }
      }
      return Object.keys(this.group).reduce((a, v) => {
        if (v === 'groupTypeDetails' || v === 'groupUserRights' || v === 'modifiedBy') {
          return a
        }
        if (this.group[v] == null) {
          this.group[v] = ''
        }

        if (this.action.data[v] == null) {
          this.action.data[v] = ''
        }

        var tempObj = {}
        tempObj[v] = diffSentences(this.group[v].toString(), this.action.data[v].toString())
        return Object.assign(a, tempObj)
      }, {})
    },
    showAntiFraudListTab () {
      if (this.group) {
        const anyGroupAntiFraudLists = this.group.antiFraudGroupLists !== undefined && this.group.antiFraudGroupLists.length > 0
        const anyActionAntiFraudLists = this.action.data.antiFraudGroupLists !== undefined && this.action.data.antiFraudGroupLists.length > 0
        return anyGroupAntiFraudLists || anyActionAntiFraudLists
      } else {
        return false
      }
    },
    showAutoFileImportTab () {
      if (this.group && this.action.data.groupTypeDetails.bacsAutoFileImportConfig) {
        return this.action.data.groupTypeDetails.bacsAutoFileImportConfig.watchedFolder !== undefined
      } else {
        return false
      }
    }
  },
  data () {
    return {
      group: null,
      action: { data: null },
      status: '',
      groupTypes,
      from: {},
      shouldShowFooter: false,
      userRoleError: false
    }
  },
  async mounted () {
    // Get Action Detail and Group
    try {
      this.$Progress.start()
      const actionResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/${this.actionId}`, { action: true })

      this.action = actionResponse.data
      this.status = this.action.actionSubClass

      var roleResult = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/CheckRole/${this.actionId}`, { action: true })

      if (!roleResult.data) {
        this.userRoleError = true
        if (this.action.rejected) {
          this.shouldShowFooter = true
        } else {
          this.shouldShowFooter = false
        }
        this.$Progress.finish()
        return
      }

      if (this.status !== 'Create') {
        var groupResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/${this.groupId}`, { showerror: true, errormessage: 'Failed to get group' })
        if (this.status === 'Delete') {
          this.action.data = null
        }
        this.group = groupResponse.data
      }

      this.$Progress.finish()
      this.shouldShowFooter = true
    } catch {
      this.$Progress.fail()
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  }
}
</script>
