<template>
  <div>
    <form @submit.prevent="sendMessage(sendMessageType)">
      <div class="idb-block">
        <div class="idb-block-title">
          <h2><help-icon docPath="/paygate-collections/messaging/bulkmessaging/" />Bulk Message Sender<favourite-icon></favourite-icon></h2>
        </div>
        <div class="idb-block-content">
          <b-form-group
            :label-cols="3"
            horizontal
            label="Groups"
            :class="{invalid: $v.selectedGroup.$error}"            
          >            
            <group-select
              v-model="selectedGroup"
              :groups="customerGroups"
              @input="groupChange"
              :clearable="false"
            ></group-select>
            <validation-messages name="Groups" v-model="$v.selectedGroup" />
          </b-form-group>

          <b-form-group :label-cols="3" horizontal label="Message Type">
            <b-form-select class="w-25" :options="messageTypeSelect" text-field="name" value-field="value" v-model="sendMessageType">
              <!-- option :value="null" disabled>Select a message type</option>
              <option value="FirstPayment">First Payment</option>
              <option value="AdvanceNotice">Advance Notice</option>
              <option value="MultipleScheduleUpdate">Multiple Schedule Update</option>
              <option value="CustomMessage1">Custom Message 1</option>
              <option value="CustomMessage2">Custom Message 2</option>
              <option value="CustomMessage3">Custom Message 3</option>
              <option value="CustomMessage4">Custom Message 4</option>
              <option value="CustomMessage5">Custom Message 5</option-->
            </b-form-select>
          </b-form-group>
        </div>
        <div class="idb-block-footer">
          <b-btn
            type="submit"
            variant="primary"
            :disabled="sendMessageLoading || sendMessageType === null || selectedGroup === null || isLoading"
          >
            <i class="fa fa-envelope">&nbsp;</i>Send
          </b-btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'
import loading from '@/Assets/Mixins/LoadingMixin'
const messageTypes = [
        { name: 'Select A Message Type', value: null},
        { name: 'First Message', value: 'FirstMessage' },
        { name: 'Advance Notice', value: 'AdvanceNotice' },
        { name: 'Multiple Schedule Update', value: 'MultipleScheduleUpdate' },
        { name: 'Custom Message 1', value: 'CustomMessage1' },
        { name: 'Custom Message 2', value: 'CustomMessage2' },
        { name: 'Custom Message 3', value: 'CustomMessage3' },
        { name: 'Custom Message 4', value: 'CustomMessage4' },
        { name: 'Custom Message 5', value: 'CustomMessage5' },
        { name: 'Cancellation Notice', value: 'CancellationNotice' },
        { name: 'Email Verification', value: 'EmailVerification' },
        { name: 'Portal Message Sent', value: 'PortalMessageSent' },
        { name: 'Portal Email Verification', value: 'PortalEmailVerification' },
        { name: 'Refund Requested', value: 'RefundRequested' }
      ]
export default {
  mixins: [loading],
  name: 'BulkMessageSender',
  components: {
    VueSelect
  },
  data () {
    return {
      sendMessageLoading: false,
      sendMessageType: null,
      selectedGroups: [],
      selectedGroup: null,
      searchResults: [],
      messageTypeSelect: [
        { name: 'Select A Message Type', value: null},
        { name: 'First Message', value: 'FirstMessage' },
        { name: 'Advance Notice', value: 'AdvanceNotice' },
        { name: 'Multiple Schedule Update', value: 'MultipleScheduleUpdate' },
        { name: 'Custom Message 1', value: 'CustomMessage1' },
        { name: 'Custom Message 2', value: 'CustomMessage2' },
        { name: 'Custom Message 3', value: 'CustomMessage3' },
        { name: 'Custom Message 4', value: 'CustomMessage4' },
        { name: 'Custom Message 5', value: 'CustomMessage5' },
        { name: 'Cancellation Notice', value: 'CancellationNotice' },
        { name: 'Email Verification', value: 'EmailVerification' },
        { name: 'Portal Message Sent', value: 'PortalMessageSent' },
        { name: 'Portal Email Verification', value: 'PortalEmailVerification' },
        { name: 'Refund Requested', value: 'RefundRequested' }
      ]
    }
  },
  mounted () {
    // this.autoCompleteGroup()
  },
  methods: {
    async sendMessage (messageType) {
      let group = this.searchResults.filter((value) => {
        console.log(value.name)
        console.log(this.selectedGroups.indexOf(value.name))
        return this.selectedGroups.indexOf(value.name) > -1
      })
      group = group.map((val) => { return val.id })
      console.log(group)
      if (group.length) {
        const { data: { smsCount, letterCount, emailCount } } = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}messages/bulk/warningstats`, { groupIds: [ this.selectedGroup ] }, { showload: true })
        let text = 'This action will send '
        let messageTypeCount = [smsCount, letterCount, emailCount].reduce((prev, current) => {
          if (current > 0) {
            return prev + 1
          } else {
            return prev
          }
        }, 0)

        if (letterCount) {
          text += `${letterCount} letters`
        }

        if (emailCount) {
          if (messageTypeCount > 2) {
            text += ', '
          } else if (messageTypeCount === 2) {
            text += ' and '
          }
          text += `${emailCount} emails`
        }

        if (smsCount) {
          if (messageTypeCount > 1) {
            text += ' and '
          }
          text += `${smsCount} SMS messages`
        }
        text += '.'
        // let text = `This action will send ${letterCount} letters, ${emailCount} emails and ${smsCount} SMS messages.`

        await this.$swal({
          title: 'Are you sure?',
          text: text,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          showConfirmButton: true,
          confirmButtonText: 'Yes'
        })
        await axios.post(`${process.env.VUE_APP_DDMS_API_URL}messages/bulk`, { groupIds: [ this.selectedGroup ], payerMessageType: messageType }, { showload: true })
      }
    },
    // autoCompleteGroup: async function () {
    //   if (this.selectedGroups) {
    //     const params = { 'startsWith': this.selectedGroups }
    //     const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}autocomplete/groups`, { params: params, showload: true })
    //     this.searchResults = response.data.results || []
    //   } else {
    //     const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}autocomplete/groups`, { showload: true })
    //     this.searchResults = response.data.results || []
    //   }
    // },
    async groupChange() {
      const response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}designer/messagetemplates/custom/${this.selectedGroup}`, { showload: true })
      this.messageTypeSelect = _.cloneDeep(messageTypes)
      for (var i = 0; i < response.data.length; i++) {
        this.messageTypeSelect.push({ name: response.data[i].title, value: response.data[i].customGroupTemplateId })
      }
    }
  },
  validations: {
    selectedGroup: { required }
  },
  computed: {
    paygateId () {
      return this.$store.state.common.paygateId
    },
    customerGroups () {
      var ret = []
      if (this.$store.getters.customerGroups !== null) {
        ret = _.cloneDeep(this.$store.getters.customerGroups)
      }
      ret.unshift({
        'description': '', 'groupId': '00000000-0000-0000-0000-000000000000', 'groupType': 'UK DDMS', 'name': 'Please select a group', 'paygateId': this.paygateId, 'isItemActioned': false, 'clonedName': null, 'colour': null })
      return ret
    },
  }
}
</script>
