var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
        _c("h5", { staticClass: "m-0" }, [
          _vm._v("Pending Bureau Submissions")
        ]),
        _c(
          "i",
          {
            staticClass: "fas fa-sync-alt pull-right",
            attrs: { role: "button" },
            on: { click: _vm.load }
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Refresh")])]
        )
      ]),
      _c(
        "VuePerfectScrollbar",
        { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
        [
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                isLoading: _vm.isTableLoading,
                mode: "remote",
                columns: _vm.columns,
                rows: _vm.rows,
                totalRows: _vm.totalRecords,
                "search-options": {
                  enabled: false
                },
                paginationOptions: _vm.paginationOptions,
                "sort-options": _vm.sortOptions,
                styleClass: "vgt-table striped bordered condensed"
              },
              on: {
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                },
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange,
                "on-search": _vm.onSearch,
                "on-row-click": _vm.onRowClick
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(props.formattedRow[props.column.field]))
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "loadingContent" }, [
                _c("h1", [_vm._v("Loading...")])
              ]),
              _c("template", { slot: "emptystate" }, [
                _c("span", [
                  _vm._v("You do not currently have any pending bureau jobs")
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }