var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _vm._v(" View Expiring USB Tokens "),
            _c("help-icon", {
              attrs: { docPath: "/administration/usbtokens/expiringusbtokens/" }
            }),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c("div", { staticClass: "row mb-1" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("From Date")
                  ]),
                  _c("vuejsDatepicker", {
                    attrs: {
                      name: "from",
                      id: "from",
                      format: "dd/MM/yyyy",
                      "input-class": "form-control bg-white",
                      "bootstrap-styling": true,
                      minimumView: "day",
                      maximumView: "year",
                      "initial-view": "day",
                      "use-utc": true,
                      disabledDates: _vm.fromDisabledDates
                    },
                    on: { input: _vm.loadTokens },
                    model: {
                      value: _vm.fromDate,
                      callback: function($$v) {
                        _vm.fromDate = $$v
                      },
                      expression: "fromDate"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("To Date")
                  ]),
                  _c("vuejsDatepicker", {
                    attrs: {
                      name: "to",
                      id: "to",
                      format: "dd/MM/yyyy",
                      "input-class": "form-control bg-white",
                      "bootstrap-styling": true,
                      minimumView: "day",
                      maximumView: "year",
                      "initial-view": "day",
                      "use-utc": true,
                      disabledDates: _vm.toDisabledDates
                    },
                    on: { input: _vm.loadTokens },
                    model: {
                      value: _vm.toDate,
                      callback: function($$v) {
                        _vm.toDate = $$v
                      },
                      expression: "toDate"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("vue-good-table", {
            attrs: {
              paginationOptions: {
                enabled: true,
                perPage: 10
              },
              rows: _vm.rows,
              columns: _vm.columns,
              lineNumbers: true,
              searchOptions: {
                enabled: false
              },
              styleClass: "vgt-table striped bordered"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }