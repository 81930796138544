var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c("h2", [
              _vm._v(" View Usage Settings "),
              _c("span", { staticClass: "pull-right" }, [
                _c("a", { attrs: { href: _vm.helpUrl, target: "_blank" } }, [
                  _c("i", { staticClass: "far fa-question-circle" })
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label col-md-3",
                  attrs: { for: "optinReport" }
                },
                [_vm._v("Opt in to Receive Report")]
              ),
              _c(
                "div",
                { staticClass: "col-md-1" },
                [
                  _c("p-check", {
                    staticClass: "p-switch p-fill",
                    attrs: { labelId: "optinReport", color: "primary" },
                    model: {
                      value: _vm.viewUsageSettings.optedIn,
                      callback: function($$v) {
                        _vm.$set(_vm.viewUsageSettings, "optedIn", $$v)
                      },
                      expression: "viewUsageSettings.optedIn"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.viewUsageSettings.optedIn,
                    expression: "viewUsageSettings.optedIn"
                  }
                ]
              },
              [
                _c("div", { staticClass: "row form-group" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Report Frequency")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.reportFrequencies },
                        model: {
                          value: _vm.viewUsageSettings.reportFrequency,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.viewUsageSettings,
                              "reportFrequency",
                              $$v
                            )
                          },
                          expression: "viewUsageSettings.reportFrequency"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row form-group" }, [
                  _c("label", { staticClass: "col-form-label col-md-3" }, [
                    _vm._v("Included Groups")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("vue-select", {
                        attrs: {
                          multiple: "",
                          options: _vm.usersGroups,
                          closeOnSelect: false,
                          "append-to-body": ""
                        },
                        model: {
                          value: _vm.selectedGroups,
                          callback: function($$v) {
                            _vm.selectedGroups = $$v
                          },
                          expression: "selectedGroups"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", disabled: _vm.disableSave },
                on: { click: _vm.saveSettings }
              },
              [_vm._v("Save Settings")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }