var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [_vm._v("Workflow Load"), _c("favourite-icon")], 1)
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", { staticClass: "row no-gutters row-bordered" }, [
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6 col-sm-12" },
              [
                _c(
                  "b-card-body",
                  [
                    _c("load-chart", {
                      attrs: {
                        height: 375,
                        frequency: "hour",
                        title: "Concurrent Workflows / Hour"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6 col-sm-12" },
              [
                _c(
                  "b-card-body",
                  [
                    _c("load-chart", {
                      attrs: {
                        height: 375,
                        frequency: "day",
                        title: "Concurrent Workflows / Day"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row no-gutters row-bordered" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12 col-xl-12" },
              [
                _c(
                  "b-card-body",
                  [
                    _c("load-chart", {
                      attrs: {
                        height: 375,
                        frequency: "minute",
                        title: "Concurrent Workflows / Minute"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("br")
        ])
      ]),
      _vm.displaySystemItems === true
        ? _c("div", { staticClass: "idb-block mb-5" }, [
            _vm._m(0),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row mb-5" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("p-check", {
                      staticClass: "p-switch",
                      attrs: {
                        name: "check",
                        labelId: "enableWorkflowProcessor",
                        color: "primary "
                      },
                      model: {
                        value: _vm.enabledWorkflowProcessor,
                        callback: function($$v) {
                          _vm.enabledWorkflowProcessor = $$v
                        },
                        expression: "enabledWorkflowProcessor"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-6" }, [
                  _vm._v(
                    " When disabled, workflows that are currently running will be allowed to complete but no new workflows will start. "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "idb-block-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-sm-3" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top.d500",
                            value:
                              "Update the current workflow load configuration",
                            expression:
                              "'Update the current workflow load configuration'",
                            modifiers: { hover: true, top: true, d500: true }
                          }
                        ],
                        attrs: { variant: "primary", title: "Update" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.btnUpdate($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-save mr-2" }),
                        _vm._v("Save")
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Global Workflow Control")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("label", { attrs: { for: "enableWorkflowProcessor" } }, [
        _vm._v("Enabled Workflow Processor")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }