var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFolderBrowser
        ? _c("folderBrowser", {
            on: {
              close: function($event) {
                _vm.showFolderBrowser = false
                _vm.closeFolderPopup()
              }
            }
          })
        : _vm._e(),
      _vm.showFileBrowser
        ? _c("fileBrowser", {
            on: {
              close: function($event) {
                _vm.showFileBrowser = false
                _vm.closeFilePopup()
              }
            }
          })
        : _vm._e(),
      _c("h3", [
        _vm._v(" Email File "),
        _c("span", { staticClass: "pull-right" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  _vm.docUrl +
                  "/automation/paygate-workflow/workflow-nodes/filetransfer/emailfile/",
                target: "_blank"
              }
            },
            [_c("i", { staticClass: "far fa-question-circle" })]
          )
        ])
      ]),
      _c("hr"),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Filename")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s2.value,
              expression: "selectedNode.props.s2.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s2.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s2.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s2,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s2.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnFileBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s2.value.required &&
      _vm.$v.selectedNode.props.s2.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The filename field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s2.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s2.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("label", { staticClass: "required" }, [_vm._v("Folder")]),
      _c("div", { staticClass: "input-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s1.value,
              expression: "selectedNode.props.s1.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s1.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s1.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s1,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s1.value.$touch()
              }
            ]
          }
        }),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnFolderBrowser($event)
                }
              }
            },
            [_vm._v("...")]
          )
        ])
      ]),
      !_vm.$v.selectedNode.props.s1.value.required &&
      _vm.$v.selectedNode.props.s1.value.$dirty
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v("The Path field is required!")
          ])
        : _vm._e(),
      !_vm.$v.selectedNode.props.s1.value.maxLength
        ? _c("p", { staticClass: "validationText" }, [
            _vm._v(
              "Maximum length is " +
                _vm._s(
                  _vm.$v.selectedNode.props.s1.value.$params.maxLength.max
                ) +
                " characters!"
            )
          ])
        : _vm._e(),
      _c("br"),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "required" }, [_vm._v("Subject")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedNode.props.s6.value,
              expression: "selectedNode.props.s6.value"
            }
          ],
          staticClass: "form-control",
          class: { invalid: _vm.$v.selectedNode.props.s6.value.$error },
          attrs: { type: "text" },
          domProps: { value: _vm.selectedNode.props.s6.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.selectedNode.props.s6,
                  "value",
                  $event.target.value
                )
              },
              function($event) {
                return _vm.$v.selectedNode.props.s6.value.$touch()
              }
            ]
          }
        }),
        !_vm.$v.selectedNode.props.s6.value.required &&
        _vm.$v.selectedNode.props.s6.value.$dirty
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v("The Subject field is required!")
            ])
          : _vm._e(),
        !_vm.$v.selectedNode.props.s6.value.maxLength
          ? _c("p", { staticClass: "validationText" }, [
              _vm._v(
                "Maximum length is " +
                  _vm._s(
                    _vm.$v.selectedNode.props.s6.value.$params.maxLength.max
                  ) +
                  " characters!"
              )
            ])
          : _vm._e()
      ]),
      _c("br"),
      _c("div", { staticClass: "textarea" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "required" }, [_vm._v("Message")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedNode.props.s7.value,
                expression: "selectedNode.props.s7.value"
              }
            ],
            staticClass: "form-control",
            class: { invalid: _vm.$v.selectedNode.props.s7.value.$error },
            attrs: { rows: "8", cols: "40" },
            domProps: { value: _vm.selectedNode.props.s7.value },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.selectedNode.props.s7,
                    "value",
                    $event.target.value
                  )
                },
                function($event) {
                  return _vm.$v.selectedNode.props.s7.value.$touch()
                }
              ]
            }
          }),
          !_vm.$v.selectedNode.props.s7.value.required &&
          _vm.$v.selectedNode.props.s7.value.$dirty
            ? _c("p", { staticClass: "validationText" }, [
                _vm._v("The message field is required!")
              ])
            : _vm._e(),
          !_vm.$v.selectedNode.props.s7.value.maxLength
            ? _c("p", { staticClass: "validationText" }, [
                _vm._v(
                  "Maximum length is " +
                    _vm._s(
                      _vm.$v.selectedNode.props.s7.value.$params.maxLength.max
                    ) +
                    " characters!"
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("br"),
      _c("div", { staticClass: "textarea" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { staticClass: "required" }, [_vm._v("To")]),
            _c("input-tag", {
              class: { invalid: _vm.$v.toEmailAddress.$error },
              attrs: { limit: 32, validate: "email", allowDuplicates: false },
              model: {
                value: _vm.toEmailAddress,
                callback: function($$v) {
                  _vm.toEmailAddress = $$v
                },
                expression: "toEmailAddress"
              }
            }),
            !_vm.$v.toEmailAddress.required && _vm.$v.toEmailAddress.$dirty
              ? _c("p", { staticClass: "validationText" }, [
                  _vm._v("At least one 'To' email address is required!")
                ])
              : _vm._e(),
            !_vm.$v.toEmailAddress.maxLength
              ? _c("p", { staticClass: "validationText" }, [
                  _vm._v(
                    "Maximum length is " +
                      _vm._s(_vm.$v.toEmailAddress.$params.maxLength.max) +
                      " characters!"
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c("br"),
      _c("div", { staticClass: "textarea" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Cc")]),
            _c("input-tag", {
              attrs: { limit: 32, validate: "email", allowDuplicates: false },
              model: {
                value: _vm.ccEmailAddress,
                callback: function($$v) {
                  _vm.ccEmailAddress = $$v
                },
                expression: "ccEmailAddress"
              }
            })
          ],
          1
        )
      ]),
      _c("br"),
      _c("hr"),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "btnPad",
              attrs: { variant: "danger" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnCancel($event)
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.btnSave($event)
                }
              }
            },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }