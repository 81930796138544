<template>
    <div style="height:90%">
    <div class="d-flex justify-content-between mb-20">
      <h4 class="m-0 w-100">Messaging Report</h4>
    </div>    
    <vue-perfect-scrollbar class="scroll-area" v-if="!loading" :settings="{maxScrollbarLength: 60}">
      <table  class="table table-striped">        
        <tbody>
          <tr valign="top">            
            <td>Date</td>
            <td class="text-center">{{formatDate (date)}}</td>
          </tr>
          <tr valign="top">            
            <td>Sent</td>
            <td class="text-center"><b-badge
                pill
                :variant="'success'" style="cursor:pointer"
              >{{sentCount}}</b-badge></td>
          </tr>
          <tr valign="top">            
            <td>Queued</td>
            <td class="text-center"><b-badge
                pill @click="goToProblems('Queued')"
                :variant="queudCount > 0 ? 'info' : 'success'" style="cursor:pointer"
              >{{queuedCount}}</b-badge></td>
          </tr>
          <tr valign="top">            
            <td>Current Errors</td>
            <td class="text-center"><b-badge
                pill @click="goToProblems('Error')"
                :variant="failedCount > 0 ? 'danger' : 'success'" style="cursor:pointer"
              >{{failedCount}}</b-badge></td>
          </tr>
          <tr valign="top">            
            <td>Bounced</td>
            <td class="text-center"><b-badge
                pill @click="goToProblems('Bounced')"
                :variant="bounceCount > 0 ? 'danger' : 'success'" style="cursor:pointer"
              >{{bounceCount}}</b-badge></td>
          </tr>
        </tbody>
      </table>
      <p>The report above shows the state of messages as of the end of yesterday. Queued Messages include those that are scheduled to be sent in the future.</p>      
    </vue-perfect-scrollbar>    
    <div v-if="loading">Loading...</div>
  </div>
</template>
<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { setTimeout, clearInterval } from 'timers'
import Loading from '@/Assets/Components/Loading/Loading'

export default {
  name: 'MessagingReporting',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Loading
  },
  data: function () {
    return {
      sentCount: 0,
      queuedCount: 0,
      failedCount: 0,
      bounceCount: 0,
      date: '',
      loading: false
    }
  },
  mounted () {
    this.populate()    
    // this.intervalId = setInterval(this.populate, 30000) // 30s
    // this.$once('hook:beforeDestroy', () => {
    //   window.clearInterval(this.intervalId)
    // })
  },
  beforeRouteLeave(to, from, next) {
    // window.clearInterval(this.intervalId)
    next();
  },
  methods: {
    getSeverityClass (value) {
      let retVal = ''
      switch (value) {
        case 'High':
          retVal = 'danger'
          break
        default:
          retVal = 'info'
          break
      }
      return retVal
    },
    formatDate (date) {
      var newDate = new Date(Date.parse(date))
      return newDate.toLocaleDateString()
    },    
    populate () {
      this.loading = true
      axios.get(`${process.env.VUE_APP_DDMS_API_URL}widget/messaging/${this.groupId}?reportDate=${ this.reportDate }`, { params: { paygateid: this.$store.state.common.paygateId } })
        .then((response) => {
          this.sentCount = response.data.sentCount
          this.queuedCount = response.data.queuedCount
          this.failedCount = response.data.currentErrorCount
          this.bounceCount = response.data.bounceCount
          this.date = response.data.reportDate
          this.loading = false
        })
        .catch((response) => {
          console.log('error' + response)
          // window.clearInterval(this.intervalId)
        })
    },
    goToProblems (mode) {
      this.$router.push('/collections/message/queue?searchTerm='+mode)
    }
  },
  computed: {
    groupId () {
      let id = this.widget.options.find(o => o.label === 'GroupId').value
      if (id === null) {
        return '00000000-0000-0000-0000-000000000000'
      } else { 
        return id
      }
    },
    reportDate () {
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      try {
        let date = this.widget.options.find(o => o.label === 'ReportDate').value
        if (date === undefined || date === null) {
          
          return yesterday.toISOString()
        } else { 
          return date.toISOString()
        }
      } catch (e) {
        return yesterday.toISOString()
      }
    }
  },
  watch: {    
  }
}
</script>

<style lang="scss" scoped>
  .textRow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10px;
  }

  .scroll-area {
    margin-top: 20px;
    height: calc(100% - 25px);
    min-height: unset;
    border-top: solid 1px #eeeeee;
  }

  .alertsRow {
    border-bottom: solid 1px #dddddd;
  }

  .alertsRow:hover  {
    cursor: pointer
  }

  .alertsRow:hover td {
    background-color: #f1f5fd;
  }
</style>
