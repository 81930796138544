<template>
  <div>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              <span class="pull-right">
                <a :href="`${docUrl}/automation/configuration/foldermanager/`" target="_blank">
                  <i class="far fa-question-circle"></i>
                </a>
              </span> Folder Manager
              <favourite-icon></favourite-icon>
            </h2>
          </div>
          <form>
            <div class="idb-block-content">
              <div class="row">
                <div class="col-md-12">
                  <b-navbar ref="navbar" toggleable="md" type="light" class="pgnav">
                    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                    <b-collapse is-nav id="nav_collapse">
                      <b-navbar-nav>
                        <b-nav-item
                          href="#"
                          title="Create Folder"
                          @click.prevent="menuCreateFolder"
                        >
                          <i class="fas fa-folder-plus text-secondary"></i>
                        </b-nav-item>
                        <b-nav-item href="#" title="Cut" @click.prevent="clipboardCut">
                          <i class="fas fa-cut text-secondary"></i>
                        </b-nav-item>
                        <b-nav-item href="#" title="Copy" @click.prevent="clipboardCopy">
                          <i class="fas fa-copy text-secondary"></i>
                        </b-nav-item>
                        <b-nav-item href="#" title="Paste" @click.prevent="clipboardPaste">
                          <i class="fas fa-clipboard text-secondary"></i>
                        </b-nav-item>
                        <b-nav-item href="#" title="Refresh" @click.prevent="menuRefresh">
                          <i class="fas fa-sync text-secondary"></i>
                        </b-nav-item>
                        <b-nav-item href="#" title="Delete" @click.prevent="menuDelete">
                          <i class="fas fa-trash-alt text-secondary"></i>
                        </b-nav-item>
                        <b-nav-item href="#" title="Rename" @click.prevent="menuRename">
                          <i class="fas fa-edit text-secondary"></i>
                        </b-nav-item>
                        <b-nav-item href="#" title="Info" @click.prevent="menuInfo">
                          <i class="fas fa-info-circle text-secondary"></i>
                        </b-nav-item>
                      </b-navbar-nav>
                      <b-navbar-nav class="ml-auto">
                        <span class="text-secondary" v-if="selectedFolder">{{selectedFolder}}</span>
                        <span class="text-secondary" v-else>{{selectedFile}}</span>
                      </b-navbar-nav>
                    </b-collapse>
                  </b-navbar>
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 folderPanel">
                  <VuePerfectScrollbar class="scroll-area" :settings="settings">
                    <v-jstree
                      :data="treeData"
                      drag-over-background-color="#F4E3EE"
                      size="large"
                      @item-click="itemClick"
                    ></v-jstree>
                  </VuePerfectScrollbar>
                </div>
                <div class="col-md-6 folderPanel">
                  <VuePerfectScrollbar class="scroll-area" :settings="settings">
                    <div class>
                      <div
                        v-for="(element, index) in myArray"
                        :key="index"
                        v-on:click="selectItem(element)"
                        v-on:dblclick="selectItemAndClose(element)"
                      >
                        <div
                          class="list-group-item folderManagerClickable"
                          :class="{ folderManagerSelectedItem: element.path === selectedFolder || element.path === selectedFile } "
                        >
                          <div class="d-flex justify-content-start">
                            <div class="d-flex align-items-start flex-column mr-20">
                              <i
                                v-if="element.isFolder"
                                class="fa fa-folder fa-4x folderManagerFolder"
                              ></i>
                              <i v-else class="fa fa-file fa-4x folderManagerFile"></i>
                            </div>
                            <div class="d-flex align-items-start flex-column pt-10">
                              <h6 class="font-lg">{{element.text}}</h6>
                              <p class="mb-0">{{element.description}}</p>
                              <div v-if="element.isFolder" class="small fw-bold"></div>
                              <div v-if="!element.isFolder" class="small fw-bold">
                                <strong>Size:</strong>
                                {{bytesToSize(element.stat.size)}}
                              </div>
                              <div v-if="!element.isFolder" class="small fw-bold">
                                <strong>Last Modified:</strong>
                                {{reformatDate(element.stat.mtime)}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </VuePerfectScrollbar>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import VJstree from 'vue-jstree'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import colours from '@/Assets/Constants/colours'
import roles from '@/Assets/Constants/roles'


export default {
  components: {
    VJstree,
    VuePerfectScrollbar,
    swal
  },
  computed: {
    corvidSelectedPaygateId () {
      return this.$store.state.common.paygateId
    }
  },
  data: () => ({
    isLicensed: false,
    docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL,
    treeData: [],
    selectedId: null,
    baseFoler: '\\',
    selectedFolder: '',
    selectedFile: '',
    selectedName: '',
    selectedNode: {},
    currentFolder: '\\',
    myArray: [],
    clipboard: {},
    settings: {
      maxScrollbarLength: 60
    }
  }),
  methods: {
    getSelectedName (text) {
      if (!this.isLicensed) return
      try {
        const li = text.split('\\')
        return li[li.length - 1]
      } catch (e) {
        this.selectedName = ''
        console.log(e.message)
      }
    },
    selectItem (e) {
      if (!this.isLicensed) return
      if (e.isFolder) {
        this.selectedFolder = e.path
        this.selectedFile = ''
      } else {
        this.selectedFile = e.path
        this.selectedFolder = ''
      }
      this.selectedName = this.getSelectedName(e.path)
    },
    async selectItemAndClose (e) {
      if (!this.isLicensed) return
      if (e.isFolder) {
        this.selectedFolder = e.path
        this.currentFolder = e.path
        this.selectedFile = ''
        await this.getFolderData(this.selectedFolder)
      } else {
        console.log('File')
        const clipboardData = {
          vueClipboard: {
            selectedFile: this.selectedFile,
            selectedFolder: this.selectedFolder,
            currentFolder: this.currentFolder,
            selectedName: this.selectedName
          }
        }

        this.selectedFolder = ''
        this.currentFolder = ''
        this.selectedFile = e.path

        const body = {
          type: 'readFile',
          cwd: this.currentFolder,
          clipboardData
        }

        try {
          const res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderOperation`, body, { showload: true })
          if (res && res.data && res.data.content) {
            let fileContent = res.data.content
            fileContent = fileContent.replace(/\r\n/g, '<br/>')
            fileContent = fileContent.replace(/\n/g, '<br/>')
            fileContent = fileContent.replace(/\r/g, '<br/>')
            console.log(fileContent)
            const html = `<small>Previews the first 20 lines or 2000 characters of the file</small><hr/><p align="left" class="previewText">${fileContent}</p>`
            await swal.fire({
              title: 'File Preview: ' + this.selectedFile,
              html,
              showCancelButton: false
            })
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to view files.')
          } else {
            this.$snapbar.e(`Could not display the file - ${e.message}`)
          }
        }
      }
    },
    async loadFolderTree () {
      if (!this.isLicensed) return
      try {
        const body = {
          selectedFolder: this.selectedFolder,
          selectedFile: this.selectedFile,
          currentFolder: this.currentFolder,
          treeData: this.treeData
        }

        const res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}fmfolderStructure/folders`, body, { showload: true })
        if (res.data.response) {
          if (Array.isArray(res.data.response)) {
            this.treeData = res.data.response
          } else {
            await this.$snapbar.w('Error retrieving remote folder tree data. (Folder format error)')
          }
        } else {
          await this.$snapbar.w('Error retrieving remote folder tree data. (Folder anomaly)')
          this.treeData = []
          this.myArray = []
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to manage folders.')
        } else {
          await this.$snapbar.e('Error retrieving remote folder tree data.')
        }
        this.treeData = []
        this.myArray = []
      }
    },
    async getFolderData (folder) {
      if (!this.isLicensed) return
      const postData = {
        folder
      }
      let res
      try {
        console.log(postData)
        res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderContents`, postData, { showload: true })
        if (res && res.data) {
          this.myArray = res.data.response
        } else {
        }
      } catch (e) {
        console.log(e)
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to manage folders.')
        } else {
          let msg = 'e.message'
          if (e.response.data) {
            msg = e.response.data
          }
          console.log(e.response.data)
          this.$snapbar.e(`Could not get folder data - ${msg}`)
        }
        this.myArray = []
      }
    },
    async itemClick (node, item) {
      if (!this.isLicensed) return
      this.selectedNode = node
      this.selectedFolder = node.model.path
      this.currentFolder = node.model.path
      this.selectedFile = ''
      console.log(node.model.path)
      this.selectedName = this.getSelectedName(node.model.path)
      await this.getFolderData(node.model.path)
    },
    async getInitialFolderView (path) {
      if (!this.isLicensed) return
      await this.getFolderData(path)
    },
    fileClick (e) {
      console.log('file clicked')
      console.log(e)
    },
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 bytes'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]}`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },
    reformatDate (d) {
      const myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      const yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      const ms = this.pad(myDate.getMilliseconds(), 3, '0')
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    async menuRefresh () {
      if (!this.isLicensed) return
      this.$snapbar.hide()
      await this.loadFolderTree()
      await this.getFolderData(this.selectedFolder)
    },
    async menuInfo () {
      if (!this.isLicensed) return
      console.log('menuInfo')
      const body = {
        type: 'info',
        cwd: this.selectedFolder
      }
      try {
        const infoRes = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderOperation`, body, { showload: true })
        const html = `<hr/><div class="">Total usage: ${this.bytesToSize(infoRes.data.info.pubdataSizeInBytes)} of ${infoRes.data.info.pubdataLicenceSizeInGB} GB</div>`
        await swal.fire({
          title: 'Information',
          html,
          showCancelButton: false
        })
      } catch (e) {
        console.log(e.message)
        await this.$snapbar.e('Error getting folder information')
      }
    },
    async menuRename () {
      if (!this.isLicensed) return
      const swName = this.normaliseName(this.selectedName)
      const result = await swal.fire({
        title: 'New name',
        input: 'text',
        icon: 'question',
        inputValue: swName,
        allowOutsideClick: false,
        showCloseButton: true,
        showCancelButton: true
      })
      if (result.isConfirmed) {
        const title = result.value
        let body = {}
        if (title) {
          console.log('title', title)
          if (title === swName) return
          if (this.selectedFolder !== '' && this.selectedFile === '') {
            body = {
              type: 'renameFolder',
              cwd: this.selectedFolder,
              name: title
            }
            try {
              await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderOperation`, body, { showload: true })
            } catch (e) {
              if (e.response && e.response.data) {
                console.log(e.response.data)
                await this.$snapbar.e(`Error renaming folder: ${e.response.data.errorMessage} (${e.response.data.errorCode})`)
              } else {
                await this.$snapbar.e('Error renaming folder')
              }
              return
          // if (e.response && e.response.status === 403) {
          //   this.$snapbar.e('You are not authorised to rename.')
          // } else {
          //   if (e === 'close' || e === 'esc') return
          //   console.log(e)
          //   this.$snapbar.e(`Could not rename - ${e.message}`)
          // }
            }

            // Determine the new selected folder
            let sf = this.selectedFolder
            let li = this.selectedFolder.lastIndexOf('/')
            let leftbit = sf.substring(0, li)
            let newSelectedpath = `${leftbit}/${title}`
            this.selectedFolder = newSelectedpath
            await this.menuRefresh()
            // Refresh the folder manager panles
            await this.getFolderData(this.selectedFolder)
          }
          if (this.selectedFolder === '' && this.selectedFile !== '') {
            body = {
              type: 'renameFile',
              cwd: this.selectedFile,
              name: title
            }
            const res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderOperation`, body, { showload: true })
            console.log(res.data)
            this.selectedFile = ''
            this.selectedFolder = this.selectedNode.model.path
            await this.menuRefresh()
          }
        }
      }
    },
    clipboardCopy () {
      if (!this.isLicensed) return
      // console.log('clipboardCopy')
      const cb = {
        selectedFile: this.selectedFile,
        selectedFolder: this.selectedFolder,
        currentFolder: this.currentFolder,
        selectedName: this.selectedName,
        entryType: 'copy',
        added: new Date()
      }
      this.clipboard = cb
    },
    clipboardCut () {
      if (!this.isLicensed) return
      const cb = {
        selectedFile: this.selectedFile,
        selectedFolder: this.selectedFolder,
        currentFolder: this.currentFolder,
        selectedName: this.selectedName,
        entryType: 'cut',
        added: new Date()
      }
      this.clipboard = cb
    },
    async clipboardPaste () {
      if (!this.isLicensed) return
      const clipboardData = {
        vueClipboard: this.clipboard,
        selectedFile: this.selectedFile,
        selectedFolder: this.selectedFolder,
        currentFolder: this.currentFolder,
        selectedName: this.selectedName,
        entryType: 'paste',
        added: new Date()
      }

      const body = {
        type: 'paste',
        cwd: this.currentFolder,
        clipboardData
      }

      try {
        await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderOperation`, body, { showload: true })
      } catch (e) {
        if (e.response && e.response.data) {
          console.log(e.response.data)
          await this.$snapbar.e('Paste Error. ' + e.response.data)
        } else {
          await this.$snapbar.e('Paste Error.')
        }
      }
      this.selectedFolder = this.selectedNode.model.path
      await this.menuRefresh()
    },
    async menuDelete () {
      if (!this.isLicensed) return
      let typeOfThing = 'folder'
      // Don't delete the root
      if (!this.selectedName) {
        return
      }
      if (this.selectedName === '/') {
        return
      }

      if (!this.selectedFolder && this.selectedFile) typeOfThing = 'file'
      const result = await swal.fire({
        title: `Are you sure you want to delete the ${typeOfThing}:<br/> '${this.selectedName}'?`,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result.isConfirmed) {
        console.log('result:', result)

        const body = {
          selectedFile: this.selectedFile,
          selectedFolder: this.selectedFolder,
          cwd: this.currentFolder,
          name: this.selectedName,
          type: 'delete',
          added: new Date()
        }

        try {
          const res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderOperation`, body, { showload: true })
          console.log(res.data)
          if (typeOfThing === 'folder') {
            this.selectedFolder = '/'
            this.currentFolder = '/'
          } else {
            try {
              // console.log('selectedNode')
              // console.log(this.selectedNode.model)
              if (this.selectedNode && this.selectedNode.model) {
                this.selectedFolder = this.selectedNode.model.path
              }
            } catch (e) {
              console.log(e)
            }
          }
          this.selectedName = ''
          this.selectedFile = ''
          await this.menuRefresh()
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.$snapbar.e('You are not authorised to delete.')
          } else {
            console.log(e)
            this.$snapbar.e(`Could not delete - ${e.message}`)
          }
        }
      }
    },
    doCopy () {
    },
    doPasteRhs () {
    },
    async menuCreateFolder () {
      if (!this.isLicensed) return
      const result = await swal.fire({
        title: 'Folder Name',
        icon: 'question',
        input: 'text',
        inputValue: '',
        allowOutsideClick: false,
        showCloseButton: true,
        showCancelButton: true
      })
      if (result.isConfirmed) {
        if (result.value) {
          if (this.currentFolder) {
            const isValidChars = result.value.match(/^[^$<>:"/\\|?*]+$/)
            console.log('isValidChars: ' + isValidChars)
            if (!isValidChars) {
              this.$snapbar.e('Error: The supplied folder name contains invalid characters.')
              return
            }
            const body = {
              type: 'createFolder',
              cwd: this.currentFolder,
              name: result.value
            }
            try {
              const res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderOperation`, body, { showload: true })
              console.log(res.data)
              await this.menuRefresh()
            } catch (e) {
              if (e.response && e.response.status === 403) {
                this.$snapbar.e('You are not authorised to create folders.')
              } else if (e.response && e.response.status === 500) {
                if (e.response.data.errorMessage) {
                  this.$snapbar.e(e.response.data.errorMessage)
                } else {
                  this.$snapbar.e(`Could not create a folder - ${e.message}`)
                }
              } else {
                this.$snapbar.e(`Could not create a folder - ${e.message}`)
              }
            }
          }
        }
      }
    },
    async loadLicence () {
      const response = await axios.get(process.env.VUE_APP_PLATFORM_API_URL + 'licence')
      this.isLicensed = response.data.automation.folderManager

      // Override customer licence if the user is corvid
      if (this.isCorvid()) {
        this.isLicensed = true
      }

      if (!this.isLicensed) this.$snapbar.w('You are not licensed to use the Folder Manager.')
    },
    isCorvid () {
      return this.$store.getters.isInRole(roles.SystemUser)
    },
    normaliseName (name) {
      // I found in dev that Windows and Linux give different results for paths and whatnot.  This tries to nrmalize things.
      if (name.indexOf('/') > -1) {
        return name.substring(name.lastIndexOf('/') + 1)
      } else if (name.indexOf('\\') > -1) {
        return name.substring(name.lastIndexOf('\\') + 1)
      } else {
        return name
      }
    }
  },
  created: async function () {
    await this.loadLicence()
    await this.loadFolderTree()
    this.getInitialFolderView('/')
  },
  mounted: function () {
  },
  beforeDestroy () {
    // document.removeEventListener('keydown', this._keyListener);
  },
  watch: {
    corvidSelectedPaygateId: function (val) {
      this.menuRefresh()
    }
  }
}
</script>

<style scoped>
/* .previewText {
    font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
  } */
.swal-wide {
  width: 850px !important;
}

.list-group-item {
  border-style: none none none solid;
}

.swal2-content {
  font-family: Courier New, monospace;
  font-style: italic;
}
.scroll-area {
  height: calc(100% - 0px);
  min-height: unset;
}
.folderPanel {
  height: 700px;
  min-height: 700px;
}
</style>
<style lang="scss">


</style>
