var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm._m(0),
      _c(
        "vue-perfect-scrollbar",
        { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
        _vm._l(_vm.stories, function(story, index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "media pb-1 mb-3" }, [
              _c("img", {
                staticClass: "d-block ui-w-40 rounded-circle",
                attrs: {
                  src: story.imageUrl,
                  width: "48px",
                  height: "48px",
                  alt: ""
                }
              }),
              _c("div", { staticClass: "media-body ml-3" }, [
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "text-dark",
                        attrs: {
                          tag: "a",
                          "class-active": "active",
                          to: "/news/" + story.id,
                          exact: ""
                        }
                      },
                      [_vm._v(_vm._s(story.title))]
                    )
                  ],
                  1
                ),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(new Date(story.createdDate).toLocaleString()))
                ]),
                _c("div", { domProps: { innerHTML: _vm._s(story.intro) } }),
                _c("div", { staticClass: "clearfix mt-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "float-left text-lightest small",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.more(story)
                        }
                      }
                    },
                    [_c("span", {}, [_vm._v("More")])]
                  )
                ])
              ])
            ]),
            story.enabled && index < _vm.stories.length - 1
              ? _c("hr")
              : _vm._e()
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between mb-20" }, [
      _c("h5", { staticClass: "m-0" }, [_vm._v("News")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }