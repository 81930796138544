var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.actionLoaded,
          expression: "actionLoaded"
        }
      ],
      attrs: { id: "submissionWrapper" }
    },
    [
      _c(
        "div",
        { staticClass: "idb-block" },
        [
          _c("div", { staticClass: "idb-block-title" }, [
            _c("h2", [
              _vm._v("Submission Details "),
              _c("span", { staticClass: "pull-right" }, [
                _c("a", { attrs: { href: _vm.helpUrl, target: "_blank" } }, [
                  _c("i", { staticClass: "far fa-question-circle" })
                ])
              ])
            ])
          ]),
          _c("viewSubmission"),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.backToSearchResults }
              },
              [_vm._v("Back to Search Results")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.submissionStatus === "complete" ||
                      _vm.submissionStatus === "rejected",
                    expression:
                      "submissionStatus === 'complete' || submissionStatus === 'rejected'"
                  }
                ],
                staticClass: "btn btn-outline-primary pull-right",
                attrs: { type: "button" },
                on: { click: _vm.showSubmissionSummary }
              },
              [_vm._v("BACS Submission Summary")]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }