var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _vm._v(
            " Pre-Submission Validation for '" +
              _vm._s(this.submissionDetails.submissionReference) +
              "' "
          ),
          _c("help-icon", { attrs: { docPath: "/bacsbureau/submissions/" } })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("strong", [_vm._v("Submission Reference")])
                    ]),
                    _c("b-col", [
                      _vm._v(_vm._s(this.submissionDetails.submissionReference))
                    ])
                  ],
                  1
                ),
                this.submissionDetails.contraNarrative !== "CONTRA" &&
                this.submissionDetails.contraNarrative.length > 0
                  ? _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("strong", [_vm._v("Contra Narrative")])
                        ]),
                        _c("b-col", [
                          _vm._v(_vm._s(this.submissionDetails.contraNarrative))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isFps
                  ? _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("strong", [_vm._v("Default Payment Date")])
                        ]),
                        _c("b-col", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                this.submissionDetails.paymentDate,
                                "DD/MM/YYYY"
                              )
                            )
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("strong", [_vm._v("Default Processing Date")])
                    ]),
                    _c("b-col", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            this.submissionDetails.processingDate,
                            "DD/MM/YYYY"
                          )
                        )
                      )
                    ])
                  ],
                  1
                ),
                false
                  ? _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("strong", [_vm._v("Submission Status")])
                        ]),
                        _c("b-col", [
                          _vm._v(_vm._s(this.bureauSubmissionStatus))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  { staticClass: "mt-2" },
                  [
                    _c("b-col", [_c("strong", [_vm._v("Must Fix Errors")])]),
                    _c("b-col", [
                      _vm._v(_vm._s(_vm._f("number")(this.numberOfMustFix)))
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [_c("strong", [_vm._v("Warnings")])]),
                    _c("b-col", [
                      _vm._v(_vm._s(_vm._f("number")(this.numberOfWarnings)))
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [_c("strong", [_vm._v("Duplicates")])]),
                    _c("b-col", [
                      _vm._v(_vm._s(_vm._f("number")(this.numberOfDuplicates)))
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("strong", [_vm._v("Information Messages")])
                    ]),
                    _c("b-col", [
                      _vm._v(_vm._s(_vm._f("number")(this.numberOfMessages)))
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-col",
              [
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-striped payment-details-summary pt-0 mt-0"
                  },
                  [
                    _c("thead", { staticClass: "pt-0 mt-0" }, [
                      _c("tr", { staticClass: "pt-0 mt-0" }, [
                        _c("th", { staticClass: "pt-0 mt-0" }, [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Transaction")
                          ])
                        ]),
                        _c(
                          "th",
                          {
                            staticClass: "text-right pt-0 mt-0",
                            attrs: { scope: "col" }
                          },
                          [_c("strong", [_vm._v("Items")])]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-right pt-0 mt-0",
                            attrs: { scope: "col" }
                          },
                          [_c("strong", [_vm._v("Total (£)")])]
                        )
                      ])
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_c("strong", [_vm._v("Credits")])]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(_vm._f("number")(this.totalCreditCount))
                          )
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(_vm._f("currency")(this.totalCreditValue))
                          )
                        ])
                      ]),
                      !_vm.isFps
                        ? _c("tr", [
                            _c("td", [_c("strong", [_vm._v("Debits")])]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(_vm._f("number")(this.totalDebitCount))
                              )
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(_vm._f("currency")(this.totalDebitValue))
                              )
                            ])
                          ])
                        : _vm._e(),
                      !_vm.isFps
                        ? _c("tr", [
                            _c("td", [_c("strong", [_vm._v("DDIs")])]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(_vm._f("number")(this.totalDDICount))
                              )
                            ]),
                            _c("td")
                          ])
                        : _vm._e()
                    ])
                  ]
                ),
                _c("b-row", [_c("b-col", [_c("br")])], 1),
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("strong", [_vm._v("Bureau Service User Number")])
                    ]),
                    _c("b-col", [
                      _vm._v(
                        _vm._s(this.submissionDetails.groupServiceUserNumber)
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [_c("strong", [_vm._v("Submission Type")])]),
                    _c("b-col", [_vm._v(_vm._s(this.PaymentNetworkTypeName))])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [_c("strong", [_vm._v("Work Code")])]),
                    _c("b-col", [
                      _vm._v(_vm._s(this.submissionDetails.workCode))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.validationMessages.length > 0
      ? _c("hr", { staticClass: "mt-2" })
      : _vm._e(),
    _vm.validationMessages.length > 0
      ? _c("div", { staticClass: "idb-block-content" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("vue-good-table", {
                  ref: "table",
                  attrs: {
                    mode: "remote",
                    totalRows: _vm.totalRecords,
                    isLoading: _vm.isTableLoading,
                    paginationOptions: _vm.paginationOptions,
                    rows: _vm.validationMessages,
                    columns: _vm.validationFields,
                    "sort-options": {
                      enabled: true,
                      initialSortBy: { field: "messageSeverity", type: "desc" }
                    },
                    styleClass: "vgt-table striped bordered"
                  },
                  on: {
                    "on-page-change": _vm.onPageChange,
                    "on-sort-change": _vm.onSortChange,
                    "on-column-filter": _vm.onColumnFilter,
                    "on-per-page-change": _vm.onPerPageChange,
                    "update:isLoading": function($event) {
                      _vm.isTableLoading = $event
                    },
                    "update:is-loading": function($event) {
                      _vm.isTableLoading = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "table-row",
                        fn: function(props) {
                          return [
                            props.column.field == "messageSeverity"
                              ? _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-pill table-pill",
                                      class: _vm.getSeverityClass(
                                        props.row.messageSeverity
                                      )
                                    },
                                    [_vm._v(_vm._s(props.row.messageSeverity))]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    )
                                  )
                                ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1794883577
                  )
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "idb-block-footer mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          attrs: {
            disabled:
              !(_vm.numberOfMustFix == 0) || _vm.isLoading || _vm.submitClicked
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.onSubmitClick()
            }
          }
        },
        [_vm._v("Process Submission")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-primary ml-2",
          attrs: { disabled: _vm.isLoading },
          on: {
            click: function($event) {
              return _vm.onViewPaymentsClick()
            }
          }
        },
        [_vm._v("View Submission Details")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-danger pull-right",
          attrs: { disabled: _vm.isLoading },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.onCancelSubmission()
            }
          }
        },
        [_vm._v("Cancel Submission")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }