<template>
  <transition name="modal">
    <div class="modal-mask" >
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div>
                <WorkflowStartUi v-if="nodeType === 'workflowStart'">
                </WorkflowStartUi>
                <CopyFileUi v-if="nodeType === 'copyFile'">
                </CopyFileUi>
                <MoveFileUi v-if="nodeType === 'moveFile'">
                </MoveFileUi>
                <MoveSomeFilesUi v-if="nodeType === 'moveSomeFiles'">
                </MoveSomeFilesUi>
                <RenameFileUi v-if="nodeType === 'renameFile'">
                </RenameFileUi>
                <DeleteFileUi v-if="nodeType === 'deleteFile'">
                </DeleteFileUi>
                <CreateFolderUi v-if="nodeType === 'createFolder'">
                </CreateFolderUi>
                <FileJoinerUi v-if="nodeType === 'fileJoiner'">
                </FileJoinerUi>
                <FileSplitterUi v-if="nodeType === 'fileSplitter'">
                </FileSplitterUi>
                <EmailFileUi v-if="nodeType === 'emailFile'">
                </EmailFileUi>
                <FtpFileUi v-if="nodeType === 'ftpFile'">
                </FtpFileUi>
                <SftpFileUi v-if="nodeType === 'sftpFile'">
                </SftpFileUi>
                <ZipFileUi v-if="nodeType === 'zipCompression'">
                </ZipFileUi>
                <UnzipFileUi v-if="nodeType === 'zipDecompression'">
                </UnzipFileUi>
                <EncryptFileUi v-if="nodeType === 'encryptFile'">
                </EncryptFileUi>
                <DecryptFileUi v-if="nodeType === 'decryptFile'">
                </DecryptFileUi>
                <FilenameVariableUi v-if="nodeType === 'filenameVariable'">
                </FilenameVariableUi>
                <FileExistsUi v-if="nodeType === 'fileExists'">
                </FileExistsUi>
                <DelayUi v-if="nodeType === 'delay'">
                </DelayUi>
                <WaitForFileUi v-if="nodeType === 'waitForFile'">
                </WaitForFileUi>
                <RulesUi v-if="nodeType === 'rule'">
                </RulesUi>
                <SubmissionValidationUi v-if="nodeType === 'bacsPreSubValidation'">
                </SubmissionValidationUi>
                <BacsBureauJobProcessorUi v-if="nodeType === 'bacsBureauJobProcessor'">
                </BacsBureauJobProcessorUi>
                <SignSubmissionUi v-if="nodeType === 'bacsSignSubmission'">
                </SignSubmissionUi>
                <ApproveSubmissionUi v-if="nodeType === 'bacsApproveSubmission'">
                </ApproveSubmissionUi>             </div>
                <SendSubmissionUi v-if="nodeType === 'bacsSubmission'">
                </SendSubmissionUi>
                <BacsReportDownloadUi v-if="nodeType === 'bacsReportDownload'">
                </BacsReportDownloadUi>
                <BureauReportDownloadUi v-if="nodeType === 'bacsBureauReportDownload'">
                </BureauReportDownloadUi>
                <BacsReportMappingUi v-if="nodeType === 'bacsReportMapping'">
                </BacsReportMappingUi>
                <EmailMessageUi v-if="nodeType === 'emailMessage'">
                </EmailMessageUi>
                <WebhookUi v-if="nodeType === 'webhook'">
                </WebhookUi>
                <SendTweetUi v-if="nodeType === 'sendTweet'">
                </SendTweetUi>
                <SmsMessageUi v-if="nodeType === 'smsMessage'">
                </SmsMessageUi>
                <LogEntryUi v-if="nodeType === 'addToWorkflowLog'">
                </LogEntryUi>
                <AuditLogEntryUi v-if="nodeType === 'addToAuditLog'">
                </AuditLogEntryUi>
                <!-- <CollectionsFinaliseSubmissionUi v-if="nodeType === 'collectionsFinaliseSubmission'">
                </CollectionsFinaliseSubmissionUi> -->
                <CollectionsReportReconciliationUi v-if="nodeType === 'collectionsReportReconciliation'">
                </CollectionsReportReconciliationUi>
                <CollectionsProcessorUi v-if="nodeType === 'collectionsProcessor'">
                </CollectionsProcessorUi>
                <MappingUi v-if="nodeType === 'mapping'">
                </MappingUi>
                <WorkflowUi v-if="nodeType === 'workflow'">
                </WorkflowUi>
                <ExporterUi v-if="nodeType === 'exporter'">
                </ExporterUi>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import WorkflowStartUi from './NodeConfigUi/WorkflowStartUi'
import CopyFileUi from './NodeConfigUi/FileOperations/CopyFileUi'
import MoveFileUi from './NodeConfigUi/FileOperations/MoveFileUi'
import MoveSomeFilesUi from './NodeConfigUi/FileOperations/MoveSomeFilesUi'
import RenameFileUi from './NodeConfigUi/FileOperations/RenameFileUi'
import DeleteFileUi from './NodeConfigUi/FileOperations/DeleteFileUi'
import CreateFolderUi from './NodeConfigUi/FileOperations/CreateFolderUi'
import FileJoinerUi from './NodeConfigUi/FileOperations/FileJoinerUi'
import FileSplitterUi from './NodeConfigUi/FileOperations/FileSplitterUi'
import FtpFileUi from './NodeConfigUi/FileTransfer/FtpFileUi'
import SftpFileUi from './NodeConfigUi/FileTransfer/SftpFileUi'
import EmailFileUi from './NodeConfigUi/FileTransfer/EmailFileUi'
import ZipFileUi from './NodeConfigUi/Compression/ZipFileUi'
import UnzipFileUi from './NodeConfigUi/Compression/UnzipFileUi'
import EncryptFileUi from './NodeConfigUi/Encryption/EncryptFileUi'
import DecryptFileUi from './NodeConfigUi/Encryption/DecryptFileUi'
import FilenameVariableUi from './NodeConfigUi/Flow/FilenameVariableUi'
import FileExistsUi from './NodeConfigUi/Flow/FileExistsUi'
import DelayUi from './NodeConfigUi/Flow/DelayUi'
import RulesUi from './NodeConfigUi/Flow/RulesUi'
import SubmissionValidationUi from './NodeConfigUi/BacsAutomation/SubmissionValidationUi'
import BacsBureauJobProcessorUi from './NodeConfigUi/BureauAutomation/BureauJobProcessorUi'
import SignSubmissionUi from './NodeConfigUi/BacsAutomation/SignSubmissionUi'
import ApproveSubmissionUi from './NodeConfigUi/BacsAutomation/ApproveSubmissionUi'
import SendSubmissionUi from './NodeConfigUi/BacsAutomation/SendSubmissionUi'
import BacsReportDownloadUi from './NodeConfigUi/BacsAutomation/ReportDownloadUi'
import BureauReportDownloadUi from './NodeConfigUi/BureauAutomation/BureauReportDownloadUi'
import BacsReportMappingUi from './NodeConfigUi/BacsAutomation/ReportMappingUi'
// https://localhost:8080/automation/workflow/workflows
import EmailMessageUi from './NodeConfigUi/Messaging/EmailMessageUi'
import WebhookUi from './NodeConfigUi/Messaging/WebhookUi'
import SendTweetUi from './NodeConfigUi/Messaging/SendTweetUi'
import SmsMessageUi from './NodeConfigUi/Messaging/SmsMessageUi'
import LogEntryUi from './NodeConfigUi/Logging/LogEntryUi'
import AuditLogEntryUi from './NodeConfigUi/Logging/AuditLogEntryUi'
// import CollectionsFinaliseSubmissionUi from './NodeConfigUi/Collections/CollectionsFinaliseSubmissionUi'
import CollectionsProcessorUi from './NodeConfigUi/Collections/CollectionsProcessorUi'
import CollectionsReportReconciliationUi from './NodeConfigUi/Collections/CollectionsReportReconciliationUi'
import MappingUi from './NodeConfigUi/Mappings/Mapping'
import WorkflowUi from './NodeConfigUi/Mappings/Workflow'
import ExporterUi from './NodeConfigUi/Mappings/Exporter'
import WaitForFileUi from './NodeConfigUi/Flow/WaitForFileUi'

export default {
  name: 'FormGenerator',
  components: {
    WorkflowStartUi,
    CopyFileUi,
    MoveFileUi,
    MoveSomeFilesUi,
    RenameFileUi,
    DeleteFileUi,
    CreateFolderUi,
    FileJoinerUi,
    FileSplitterUi,
    EmailFileUi,
    FtpFileUi,
    SftpFileUi,
    ZipFileUi,
    UnzipFileUi,
    EncryptFileUi,
    DecryptFileUi,
    FilenameVariableUi,
    FileExistsUi,
    DelayUi,
    WaitForFileUi,
    RulesUi,
    BacsReportDownloadUi,
    BureauReportDownloadUi,
    BacsReportMappingUi,
    SubmissionValidationUi,
    BacsBureauJobProcessorUi,
    SignSubmissionUi,
    ApproveSubmissionUi,
    SendSubmissionUi,
    EmailMessageUi,
    WebhookUi,
    SendTweetUi,
    SmsMessageUi,
    LogEntryUi,
    AuditLogEntryUi,
    // CollectionsFinaliseSubmissionUi,
    CollectionsProcessorUi,
    CollectionsReportReconciliationUi,
    MappingUi,
    WorkflowUi,
    ExporterUi
  },
  data () {
    return {
      nodeType: '',
      settings: {
        // maxScrollbarLength: 100
      },
      formData: {
      }
    }
  },
  methods: {
    btnSave () {
      console.log('Parent Form')
      // Add the updated data into the selected node props.
      // console.log(this.formData)
      // this.$store.commit('setSelectedNodeProps', this.formData)
      this.$emit('close')
    },
    setNodeProp (prop) {
      if (prop) {
        return prop.value
      }
    },
    scrollHandle (evt) {
      console.log(evt)
    }
  },
  async created () {
    this.nodeType = await this.$store.state.workflow.selectedNode.name
    // console.log('nodeType: ' + this.nodeType)
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
