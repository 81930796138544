var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "manualBacsSubmissionBuilder" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _vm._v(" Manual Bureau Submission Builder "),
                _c("help-icon", { attrs: { docPath: "/bacsbureau/builder/" } }),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "idb-block-content" }, [
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("group-select", {
                    attrs: {
                      id: "groupSelect",
                      groups: _vm.Groups,
                      clearable: false,
                      change: _vm.ChangeGroup(_vm.SelectedGroupId)
                    },
                    model: {
                      value: _vm.SelectedGroupId,
                      callback: function($$v) {
                        _vm.SelectedGroupId = $$v
                      },
                      expression: "SelectedGroupId"
                    }
                  })
                ],
                1
              ),
              _vm.$v.SelectedGroupId.$dirty
                ? _c(
                    "div",
                    { staticClass: "col-md-3", attrs: { "offset-sm": "3" } },
                    [
                      !_vm.$v.SelectedGroupId.required
                        ? _c("label", { staticClass: "text-danger small" }, [
                            _vm._v("A 'Submission Group' is required")
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("b-form-input", {
                    attrs: { id: "submissionReference", type: "text" },
                    model: {
                      value: _vm.$v.submissionReference.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.submissionReference,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.submissionReference.$model"
                    }
                  })
                ],
                1
              ),
              _vm.$v.submissionReference.$dirty
                ? _c(
                    "div",
                    { staticClass: "col-md-3", attrs: { "offset-sm": "3" } },
                    [
                      !_vm.$v.submissionReference.required
                        ? _c(
                            "label",
                            {
                              staticClass: "text-danger small",
                              attrs: { id: "submissionReferenceRequired" }
                            },
                            [_vm._v("A 'Submission Reference' is required")]
                          )
                        : _vm._e(),
                      !_vm.$v.submissionReference.maxLength
                        ? _c(
                            "label",
                            {
                              staticClass: "text-danger small",
                              attrs: { id: "submissionReferenceLength" }
                            },
                            [
                              _vm._v(
                                "A 'Submission Reference' must be less than " +
                                  _vm._s(
                                    _vm.$v.submissionReference.$params.maxLength
                                      .max
                                  ) +
                                  " characters"
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "row form-group col-md-12" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("b-form-input", {
                    attrs: { id: "contraNarrative", type: "text" },
                    model: {
                      value: _vm.$v.contraNarrative.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.contraNarrative,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.contraNarrative.$model"
                    }
                  })
                ],
                1
              ),
              _vm.$v.contraNarrative.$dirty
                ? _c(
                    "div",
                    { staticClass: "col-md-3", attrs: { "offset-sm": "3" } },
                    [
                      !_vm.$v.contraNarrative.maxLength
                        ? _c(
                            "label",
                            {
                              staticClass: "text-danger small",
                              attrs: { id: "contraNarrativeBad" }
                            },
                            [
                              _vm._v(
                                "A 'Contra Narrative' must be less than " +
                                  _vm._s(
                                    _vm.$v.contraNarrative.$params.maxLength.max
                                  ) +
                                  " characters"
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]),
            !_vm.isFps
              ? _c("div", { staticClass: "row form-group col-md-12" }, [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("vuejsDatepicker", {
                        ref: "paymentDate",
                        attrs: {
                          name: "paymentDate",
                          id: "paymentDate",
                          format: "dd/MM/yyyy",
                          "input-class": "form-control",
                          "bootstrap-styling": true,
                          disabledDates: _vm.disabledDates,
                          "monday-first": true,
                          disabled: _vm.disableDatePicker
                        },
                        on: { selected: _vm.paymentDateChanged },
                        nativeOn: {
                          focusin: function($event) {
                            return _vm.dateOnfocus($event)
                          }
                        },
                        model: {
                          value: _vm.paymentDate,
                          callback: function($$v) {
                            _vm.paymentDate = $$v
                          },
                          expression: "paymentDate"
                        }
                      }),
                      _c("small", [
                        _vm._v(
                          "This is a default date for the submission that can be overridden for individual blocks."
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._m(4),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-2",
                      attrs: { id: "processingDate" }
                    },
                    [_vm._v(_vm._s(_vm.processingDate))]
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: {
                  id: "createSubmissionButton",
                  disabled: _vm.$v.$invalid
                },
                on: { click: _vm.onCreateClick }
              },
              [_vm._v("Create Submission")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { staticClass: "required", attrs: { for: "groupSelect" } }, [
        _vm._v("Select a Submission Group")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c(
        "label",
        { staticClass: "required", attrs: { for: "submissionReference" } },
        [_vm._v("Submission Reference")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { for: "contraNarrative" } }, [
        _vm._v("Contra Narrative")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { for: "paymentDate" } }, [_vm._v("Payment Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", { attrs: { for: "processingDate" } }, [
        _vm._v("Processing Date")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }