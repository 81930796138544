var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _vm._m(1),
          _c("div", { staticClass: "idb-block-footer" }, [
            _c("div", { staticClass: "row " }, [
              _c("div", { staticClass: "col-md-12 col-sm-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.updateSomething($event)
                      }
                    }
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.viewSomething($event)
                      }
                    }
                  },
                  [_vm._v("View")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger pull-right",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.deleteSomething($event)
                      }
                    }
                  },
                  [_vm._v("Delete")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [
        _c("i", { staticClass: "fa fa-clock-o" }),
        _vm._v(" Page Title")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-content" }, [
      _c("form", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("p", [_vm._v("Main body area")]),
            _c("p", { staticClass: "bread" }, [_vm._v("Hello World!")])
          ]),
          _c("div", { staticClass: "col-md-9 col-sm-12" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }