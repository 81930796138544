<template>
<div>
  <div class="row pb-16 pt-16">
    <div class="col-md-12">
      <div class="form-group">
        <strong>{{label}}</strong>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'Label',
  props: ['label']
}
</script>
