<template>
    <div id="myId">
        <div class="row mb-4">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="idb-block">
                    <div class="idb-block-title">
                        <h2>Sweet Alert</h2>
                    </div>
                    <div class="idb-block-content">
                      <button class="btn btn-danger" @click="alertDanger">Danger Alert</button>
                      <button class="btn btn-warning" @click="alertWarning">Warning Alert</button>
                      <button class="btn btn-success" @click="alertSuccess">Success Alert</button>
                      <button class="btn btn-primary" @click="alertOther">Other Alert</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  methods: {
    alertDanger () {
      this.$toastr.e('<strong>Wow</strong> something really bad happened', 'Error PG100')
    },
    alertWarning () {
      this.$toastr.w('This is a Warning Message', 'Warning')
    },
    alertSuccess () {
      this.$toastr.s('Hurray.  That totally worked')
    },
    alertOther () {
      this.$toastr.i('Info Message')
    }
  }
}
</script>

<style>
</style>
