<template>
  <b-modal v-model="value" @hide="hide" @shown="setFocus" centered size="lg" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">Anti-Fraud List Item</div>
    <!-- Third party destination account name -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party account name.'">Account Name</div>
      <div class="col-md-4" :class="{invalid: $v.accountName.$error}">
        <input
          type="text"
          class="form-control"
          ref="defaultElement"
          v-model.trim.lazy="$v.accountName.$model"
          @change="onChange($v.accountName)"
          @blur="onBlur($v.accountName)"
          title="Account Name"
        />
      </div>
      <span v-if="$v.accountName.$dirty">
        <small
          v-if="!$v.accountName.required"
          class="form-text text-danger small text-nowrap"
        >Account Name is required</small>
        <small
          v-if="!$v.accountName.maxLen"
          class="form-text text-danger small text-nowrap"
        >Maximum account name length is {{ $v.accountName.$params.maxLen.max }} characters</small>
      </span>
    </div>

    <!-- Third party sort code -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party\'s sort code.'">Sort Code</div>
      <div class="col-md-3" :class="{invalid: $v.sortCode.$error && sortCodeBlurFired}">
        <the-mask
          type="text"
          class="form-control"
          :mask="'##-##-##'"
          v-model.trim.lazy="$v.sortCode.$model"
          @blur.native="onSortCodeBlur($v.sortCode)"
          @focus.native="sortCodeBlurFired = false"
          title="Sort Code"
        ></the-mask>
      </div>
      <div v-if="validatingBankDetails" class="col-md-4">
        <small>Validating bank details...</small>
      </div>
      <div v-else-if="bankDetailsValidated && validSortCode" class="col-md-1">
        <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
      </div>
      <span v-if="$v.sortCode.$dirty && sortCodeBlurFired">
        <small
          v-if="!$v.sortCode.required"
          class="form-text text-danger small text-nowrap"
        >Sort Code is required</small>
        <small
          v-if="!$v.sortCode.minLen || !$v.sortCode.maxLen"
          class="form-text text-danger small text-nowrap"
        >Sort code must contain {{ $v.sortCode.$params.minLen.min }} numbers</small>
        <small
          v-if="!validSortCode"
          class="form-text text-warning small text-nowrap"
        >{{ sortCodeError }}</small>
      </span>
    </div>

    <!-- Third party account number -->
    <div class="row form-group">
      <div class="col-md-3 required" v-b-popover.hover.top="'This is the third party\'s account number.'">Account Number</div>
      <div class="col-md-3" :class="{invalid: $v.accountNumber.$error && accountNumberBlurFired}">
        <the-mask
          type="text"
          class="form-control"
          :mask="'########'"
          v-model.trim="$v.accountNumber.$model"
          @blur.native="onAccountNumberBlur($v.accountNumber)"
          @focus.native="accountNumberBlurFired = false"
          title="Account Number"
        ></the-mask>
      </div>
      <div v-if="bankDetailsValidated && validAccountNumber" class="col-md-1">
        <span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
      </div>
      <span v-if="$v.accountNumber.$dirty && accountNumberBlurFired">
        <small
          v-if="!$v.accountNumber.required"
          class="form-text text-danger small text-nowrap"
        >Account Number is required</small>
        <small
          v-if="!$v.accountNumber.minLen || !$v.accountNumber.maxLen"
          class="form-text text-danger small text-nowrap"
        >Account number must contain {{ $v.accountNumber.$params.minLen.min }} numbers</small>
        <small
          v-if="!validAccountNumber"
          class="form-text text-warning small text-nowrap"
        >{{ accountNumberError }}</small>
      </span>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn
            size="sm"
            class="float-left"
            variant="primary"
            @click="saveItem"
            :disabled="disableButtons"
          >Save Item</b-btn>
        </b-col>
        <b-col cols="2">

        </b-col>
        <b-col cols="8">
          <b-btn
            size="sm"
            class="float-right"
            variant="danger"
            @click="cancel"
            :disabled="disableButtons"
          >Cancel</b-btn>

          <b-btn
            size="sm"
            class="float-right mr-2"
            variant="outline-danger"
            @click="deleteItem"
            :disabled="disableButtons"
            v-show="!newItem"
          >Delete Item</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'

export default {
  components: { TheMask },
  props: {
    value: Boolean,
    item: Object,
    newItem: Boolean
  },
  data: () => ({
    accountName: '',
    sortCode: '',
    accountNumber: '',
    disableButtons: false,
    validSortCode: false,
    validAccountNumber: false,
    sortCodeError: '',
    accountNumberError: '',
    bankDetailsValidated: false,
    bankId: 0,
    sortCodeBlurFired: false,
    accountNumberBlurFired: false,
    editedItem: {
      thirdPartyAccountName: '',
      thirdPartySortCode: '',
      thirdPartyAccountNumber: ''
    },
    validatingBankDetails: false
  }),
  methods: {
    hide () {
      this.$emit('input', false)
    },

    setFocus () {
      this.disableButtons = false
      this.$v.$reset()
      this.$refs.defaultElement.focus()
      this.accountName = this.item.thirdPartyAccountName
      this.sortCode = this.item.thirdPartySortCode
      this.accountNumber = this.item.thirdPartyAccountNumber
      this.validSortCode = false
      this.validAccountNumber = false
      this.bankDetailsValidated = false
      this.bankId = 0
      if (!this.newItem) {
        this.validateBankAccount()
        this.sortCodeBlurFired = true
        this.accountNumberBlurFired = true
      } else {
        this.sortCodeBlurFired = false
        this.accountNumberBlurFired = false
      }
    },

    onChange (element) {
      element.$touch()
      this.forceUpper()
    },

    onBlur (element) {
      element.$touch()
    },

    onSortCodeBlur (element) {
      element.$touch()
      this.sortCodeBlurFired = true
      this.validateBankAccount()
    },

    onAccountNumberBlur (element) {
      element.$touch()
      this.accountNumberBlurFired = true
      this.validateBankAccount()
    },

    forceUpper () {
      this.accountName = this.accountName.toUpperCase()
    },

    cancel () {
      this.$emit('close')
    },

    saveItem () {
      this.$v.$touch()
      this.disableButtons = true
      this.sortCodeBlurFired = true
      this.accountNumberBlurFired = true

      if (this.$v.$invalid) {
        this.disableButtons = false
        return false
      }

      this.editedItem.id = this.item.id
      this.editedItem.antiFraudListId = this.item.antiFraudListId
      this.editedItem.paygateId = this.item.paygateId
      this.editedItem.thirdPartyAccountName = this.accountName
      this.editedItem.thirdPartySortCode = this.sortCode
      this.editedItem.thirdPartyAccountNumber = this.accountNumber
      this.$emit('saveItem', this.editedItem)
    },

    async deleteItem () {
      var result = await swal.fire({
        title: 'Delete Item?',
        text: 'Are you sure want to delete this anti-fraud item?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result.isConfirmed) {
        this.disableButtons = true
        this.$emit('deleteItem')
      }
    },

    async validateBankAccount () {
      if (this.sortCode.length === 6 && this.accountNumber.length === 8) {
        this.disableButtons = true
        this.validatingBankDetails = true
        this.bankDetailsValidated = false
        this.validAccountNumber = false
        this.validSortCode = false
        this.accountNumberError = ''
        this.sortCodeError = ''
        const validateUrl = process.env.VUE_APP_PLATFORM_API_URL + 'BankAccounts/ValidateBankAccount'
        const data = { sortCode: this.sortCode, accountNumber: this.accountNumber }

        try {
          const response = await axios.post(validateUrl, data, { showload: true })
          var validationResult = response.data
          if (validationResult.valid) {
            this.validAccountNumber = true
            this.validSortCode = true
          } else {
            if (validationResult.errorCode === '1502') {
              this.validSortCode = true
              this.accountNumberError = validationResult.errorText
            } else {
              this.sortCodeError = validationResult.errorText
            }
          }
          this.bankId = validationResult.fid
          this.bankDetailsValidated = true
          this.$v.sortCode.$touch()
          this.$v.accountNumber.$touch()
        } catch (e) {
          console.log(e)
        } finally {
          this.disableButtons = false
          this.validatingBankDetails = false
        }
      }
    }
  },

  validations: {
    accountName: { required, maxLen: maxLength(18) },
    sortCode: { required, numeric, minLen: minLength(6), maxLen: maxLength(6) },
    accountNumber: { required, numeric, minLen: minLength(8), maxLen: maxLength(8) }
  }
}
</script>
