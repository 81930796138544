var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "div",
        { staticClass: "form-group", class: { invalid: _vm.$v.value.$error } },
        [
          _c("label", [_vm._v(_vm._s(_vm.label))]),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "number",
              name: _vm.name,
              placeholder: _vm.placeholder
            },
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                _vm.$emit("input", $event.target.value)
                _vm.$v.value.$touch()
              }
            }
          }),
          _c("validation-messages", {
            attrs: { name: _vm.label },
            model: {
              value: _vm.$v.value,
              callback: function($$v) {
                _vm.$set(_vm.$v, "value", $$v)
              },
              expression: "$v.value"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }