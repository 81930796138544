<template>
  <div>
    <div class="idb-block" v-if="roles.length > 0">
      <div class="idb-block-title">
        <h2>Roles</h2>
      </div>
      <div class="idb-block-content">
        <div class="container-fluid">
          <div v-for="(role, index) in roles" :key="role.name">
            <div class="form-group row">
              <label class="col-3 offset-1 col-form-label">Role Name</label>
              <div class="col-8">
                <div class="form-control-plaintext">
                  <span>{{role.name}}</span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <label class="col-12 offset-1 form-label">Users in roles</label>
              </div>
              <div v-for="user in role.users" :key="user">
                <div class="row">
                  <div class="col-3 offset-1">
                    <div class="form-control-plaintext">
                      <span>{{user}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="role.users.length === 0">
                <div class="row">
                  <div class="col-3 offset-1">
                    <div class="form-control-plaintext">
                      <span>No users in role</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr v-if="index !== roles.length -1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleConfigReport',
  props: {
    roles: {
      required: true,
      type: Array
    }
  }
}
</script>

<style>
</style>
