function returnRouteObject (type, data) {
  var objectToReturn = {}

  switch (type) {
    case 'User': {
      const userId = data.id
      objectToReturn.path = `/admin/users/edit/${userId}`
      break
    }
    case 'BACS Group':
    case 'BACS Bureau Group':
    case 'Collections Group':
    case 'BACS Online Group':
    case 'FPS Online Group':
    case 'FPS Group': {
      const groupId = data.groupId
      objectToReturn.path = `/admin/groups/edit/${groupId}`
      break
    }
    case 'Bank Account': {
      const bankId = data.bankAccountId
      objectToReturn.path = `/admin/bankaccounts/edit/${bankId}`
      break
    }
    case 'Mappings': {
      const mappingId = data.id
      objectToReturn.path = `/automation/mapping/mappingdetail/${mappingId}`
      break
    }
    case 'Workflows': {
      const workflowId = data.id
      objectToReturn.path = `/automation/workflow/workflowdetail/${workflowId}`
      break
    }
    case 'Triggers': {
      const triggerId = data.id
      const triggerType = data.triggerType
      objectToReturn.path = `/automation/workflow/triggerconfig/${triggerType}/${triggerId}`
      break
    }
    case 'Importers': {
      const importerId = data.id
      const importerType = data.importerType
      objectToReturn.path = `/automation/mapping/import/${importerType}Importer/${importerId}`
      break
    }
    case 'Collections Payer': {
      const payerId = data.ukPayerId
      objectToReturn.path = `/collections/payer/${payerId}/edit`
      break
    }
    default: {
      console.error('Not implemented')
      break
    }
  }

  return objectToReturn
}

export {
  returnRouteObject
}
