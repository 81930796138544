<template>
  <div>
    <input
      :id="id"
      type="text"
      class="form-control"
      v-model="displayValue"
      :disabled="disabled"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    value: Number,
    disabled: Boolean
  },
  data: function () {
    return {
      isInputActive: false
    }
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.value === undefined || this.value === null) {
          this.value = 0
        }
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value.toString()
        } else {
          // User is not modifying now. Format display value for user interface
          var result = '£' + this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
          return result
        }
      },
      set: function (modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue)
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    setActive (data) {
      console.log(data)
      this.isInputActive = false
    }
  }
}
</script>
