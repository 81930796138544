var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "viewSubmissionSummary" } }, [
    _c("div", { staticStyle: { width: "98%", margin: "0 auto" } }, [
      _c("p", { domProps: { innerHTML: _vm._s(_vm.submissionSummary) } })
    ]),
    _c("div", { staticClass: "idb-block-footer" }, [
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.downloadXml }
            },
            [_vm._v("Download XML")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }