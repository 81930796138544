var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.sendMessage(_vm.sendMessageType)
          }
        }
      },
      [
        _c("div", { staticClass: "idb-block" }, [
          _c("div", { staticClass: "idb-block-title" }, [
            _c(
              "h2",
              [
                _c("help-icon", {
                  attrs: {
                    docPath: "/paygate-collections/messaging/bulkmessaging/"
                  }
                }),
                _vm._v("Bulk Message Sender"),
                _c("favourite-icon")
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c(
                "b-form-group",
                {
                  class: { invalid: _vm.$v.selectedGroup.$error },
                  attrs: { "label-cols": 3, horizontal: "", label: "Groups" }
                },
                [
                  _c("group-select", {
                    attrs: { groups: _vm.customerGroups, clearable: false },
                    on: { input: _vm.groupChange },
                    model: {
                      value: _vm.selectedGroup,
                      callback: function($$v) {
                        _vm.selectedGroup = $$v
                      },
                      expression: "selectedGroup"
                    }
                  }),
                  _c("validation-messages", {
                    attrs: { name: "Groups" },
                    model: {
                      value: _vm.$v.selectedGroup,
                      callback: function($$v) {
                        _vm.$set(_vm.$v, "selectedGroup", $$v)
                      },
                      expression: "$v.selectedGroup"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": 3,
                    horizontal: "",
                    label: "Message Type"
                  }
                },
                [
                  _c("b-form-select", {
                    staticClass: "w-25",
                    attrs: {
                      options: _vm.messageTypeSelect,
                      "text-field": "name",
                      "value-field": "value"
                    },
                    model: {
                      value: _vm.sendMessageType,
                      callback: function($$v) {
                        _vm.sendMessageType = $$v
                      },
                      expression: "sendMessageType"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "idb-block-footer" },
            [
              _c(
                "b-btn",
                {
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    disabled:
                      _vm.sendMessageLoading ||
                      _vm.sendMessageType === null ||
                      _vm.selectedGroup === null ||
                      _vm.isLoading
                  }
                },
                [_c("i", { staticClass: "fa fa-envelope" }), _vm._v("Send ")]
              )
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }