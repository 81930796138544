import axios from 'axios'
// default state
const state = {
  availableTemplates: [],
  groupBankAccounts: [],
  planTemplateTree: [],
  bankAccounts: []
}

// mutators
const mutations = {
  setAvailableTemplates (state, value) {
    state.availableTemplates = value
  },
  setGroupBankAccounts (state, value) {
    state.groupBankAccounts = value
  },
  setPlanTemplateTree (state, value) {
    state.planTemplateTree = value
  },
  setAvailableBankAccounts (state, value) {
    state.bankAccounts = value
  }
}

// getters
const getters = {
  availableTemplates: (state) => {
    return state.availableTemplates
  },
  groupBankAccounts: (state) => {
    return state.groupBankAccounts
  },
  planTemplateTree: (state) => {
    return state.planTemplateTree
  },
  availableBankAccounts: (state) => {
    return state.bankAccounts
  }
}

// actions
const actions = {
  updateAvailablePlanTemplates: async ({ commit }, groupId) => {
    var result = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/${groupId}`, { showload: true })
    commit('setAvailableTemplates', result.data)
  },
  getGroupBankAccounts: async ({ commit }, groupId) => {
    var result = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/BankAccounts`)
    commit('setGroupBankAccounts', result.data.map(bankAccount => {
      return { value: bankAccount.bankAccountId, text: bankAccount.reference }
    }))
  },
  getAvailableBankAccounts: async ({ commit }) => {
    try {
      let response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Groups/BankAccounts`)
      let bankAccounts = response.data.map(bankAccount => {
        return { value: bankAccount.bankAccountId, text: bankAccount.reference }
      })
      commit('setAvailableBankAccounts', bankAccounts)
    } catch {}
  },
  buildPlanTemplateTree: async ({ commit }) => {
    var result = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}plantemplate/tree`, { showload: true })
    commit('setPlanTemplateTree', result.data)
  }
}

export default { state, getters, actions, mutations }
