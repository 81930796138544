var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: { docPath: "/paygate-collections/payers/managepayer/" }
            }),
            _vm._v("View Payer History Item: " + _vm._s(_vm.title))
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _vm._v(" View an individual payer's history item. "),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _vm._v("Name: "),
                  _c(
                    "router-link",
                    { attrs: { to: "/collections/payer/" + _vm.id + "/edit" } },
                    [_vm._v(_vm._s(_vm.name))]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _vm._v(" Payer Reference : "),
                  _c(
                    "router-link",
                    { attrs: { to: "/collections/payer/" + _vm.id + "/edit" } },
                    [_vm._v(_vm._s(_vm.reference))]
                  )
                ],
                1
              ),
              _c("b-col", { attrs: { md: "4" } })
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "4" } }, [
                _vm._v(
                  "Message Type: " +
                    _vm._s(_vm.getTextFromMessageType(_vm.messageType))
                )
              ]),
              _c("b-col", { attrs: { md: "4" } }, [
                _vm._v(
                  "Reason Code : " +
                    _vm._s(_vm.reasonCode ? _vm.reasonCode : "")
                )
              ]),
              _c("b-col", { attrs: { md: "4" } }, [
                _vm._v(
                  "Generation Date : " +
                    _vm._s(_vm.formatDate(_vm.generationDate))
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm.body !== null
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _vm._v(_vm._s(_vm.body))
          ])
        ])
      : _vm._e(),
    _vm.circumstances !== null && _vm.circumstances !== ""
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(1),
          _c("div", { staticClass: "idb-block-content" }, [
            _vm._v(_vm._s(_vm.circumstances))
          ])
        ])
      : _vm._e(),
    _vm.instructions !== null && _vm.instructions !== ""
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(2),
          _c("div", { staticClass: "idb-block-content" }, [
            _vm._v(_vm._s(_vm.instructions))
          ])
        ])
      : _vm._e(),
    _vm.paygateAction !== null && _vm.paygateAction !== ""
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(3),
          _c("div", { staticClass: "idb-block-content" }, [
            _vm._v(_vm._s(_vm.paygateAction))
          ])
        ])
      : _vm._e(),
    _vm.allowRepresentation
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(4),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _vm._v(
                " You can re-present this failed collection up to 30 days after the original processing day. "
              ),
              _c("br"),
              _c("span", [
                _vm._v(
                  "The original processing date was: " +
                    _vm._s(_vm.formatdate(_vm.originalProcessingDate))
                )
              ]),
              _vm._v(" To re-present the collection, click the button below. "),
              _c("br"),
              _c("br"),
              _c(
                "b-btn",
                {
                  attrs: { variant: "warning", disabled: _vm.isLoading },
                  on: { click: _vm.representCollection }
                },
                [_vm._v("Re-Present Collection")]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.messageData !== null
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(5),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _c("strong", [_vm._v("Subject")])
                  ]),
                  _c("b-col", {
                    domProps: { innerHTML: _vm._s(_vm.messageData.title) }
                  })
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _c("strong", [_vm._v("Body")])
                  ]),
                  _c("b-col", {
                    domProps: { innerHTML: _vm._s(_vm.messageData.body) }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.messageData === null && _vm.payerMessageId !== null
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(6),
          _c(
            "div",
            { staticClass: "idb-block-content" },
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c("p", [
                        _vm._v(
                          "Save a copy of the letter using the button below:"
                        )
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.saveFile }
                        },
                        [
                          _c("i", { staticClass: "far fa-file-pdf mr-2" }),
                          _vm._v("Save Letter As PDF")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    this.alertMessageId !== null && this.alertMessageId !== ""
      ? _c("div", { staticClass: "idb-block" }, [
          _c(
            "div",
            { staticClass: "idb-block-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger", disabled: _vm.isLoading },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.deleteAlert($event)
                    }
                  }
                },
                [_vm._v("Clear Alert")]
              ),
              _vm.messageType === 13 && !_vm.planInfo.ignoreWarnings
                ? _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        variant: "outline-warning",
                        disabled: _vm.isLoading
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setToIgnore($event)
                        }
                      }
                    },
                    [_vm._v("Ignore future warnings")]
                  )
                : _vm._e(),
              _vm.canRequestRefund && _vm.hasRefunds && _vm.canRefund
                ? _c(
                    "b-button",
                    {
                      staticClass: "pull-right",
                      attrs: {
                        variant: "outline-primary",
                        disabled:
                          _vm.isLoading ||
                          _vm.refundRequested ||
                          !_vm.refundable
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.initiateRefund($event)
                        }
                      }
                    },
                    [_vm._v("Refund")]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Description")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Circumstances")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Instructions")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Paygate Action")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Re-Presentation")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Outgoing Message Details")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Outgoing Message Details")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }