import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'

export default [
	{
		menu_title: 'Workflow',
		menu_icon: 'ti-control-shuffle',
		showMethod: async () => {
			return Store.getters.isInRoles([roles.ViewWorkflow]) && parseInt(process.env.VUE_APP_VERSION) >= 335 // 340
		},
		child_routes: [
			{
				path: '/automation/workflow/workflows',
				menu_title: 'Workflows',
				menu_icon: 'ti-control-shuffle'
			},
			{
				path: '/automation/workflow/triggers',
				menu_title: 'Triggers',
				counterData: 'triggerCount',
				menu_icon: 'ti-pulse'
			}
			// {
			//   path: '/automation/workflow/designer',
			//   menu_title: 'Workflow Designer',
			//   menu_icon: 'ti-view-grid'
			// }
		]
	},
	{
		menu_title: 'Mapping',
		menu_icon: 'ti-layout',
		child_routes: [
			{
				path: '/automation/mapping/mappings',
				menu_title: 'Mappings'
			},
			{
				path: '/automation/mapping/import/importers',
				menu_title: 'Importers',
				menu_icon: 'ti-import'
			},
			{
				path: '/automation/mapping/designer',
				menu_title: 'Mapping Designer',
				menu_icon: 'ti-layout-sidebar-right'
			}
		]
	},
	{
		menu_title: 'Logging',
		menu_icon: 'ti-view-list',
		child_routes: [
			{
				path: '/automation/logging/triggerTelemetry',
				menu_title: 'Workflow & Mapping Logs'
			}
			// {
			//   path: '/automation/system/triggerexplorer',
			//   menu_title: 'Trigger Explorer',
			//   showMethod: async () => {
			//     return Store.getters.isInRoles([roles.SystemUser])
			//   }
			// },
		]
	},
	{
		menu_title: 'Automation Tools',
		menu_icon: 'ti-panel',
		child_routes: [
			{
				path: '/automation/config/duplication',
				menu_title: 'Duplication',
				menu_icon: 'ti-layers'
			},
			{
				path: '/automation/config/foldermanager',
				menu_title: 'Folder Manager',
				menu_icon: 'ti-folder'
			},
			{
				path: '/automation/config/designersettings',
				menu_title: 'Designer Settings',
				menu_icon: 'ti-layers'
			},
			// {
			//   path: '/automation/system/triggerexplorer',
			//   menu_title: 'Trigger Explorer',
			//   showMethod: async () => {
			//     return parseInt(process.env.VUE_APP_VERSION) >= 340
			//   }
			// },
			// {
			//   path: '/automation/config/workflowqueue',
			//   menu_title: 'Workflow Queue',
			//   showMethod: async () => {
			//     return Store.getters.isInRoles([roles.SystemUser]) && parseInt(process.env.VUE_APP_VERSION) >= 340
			//   }
			// },
			{
				path: '/automation/system/workflowmonitor',
				menu_title: 'Workflow Monitor',
				showMethod: async () => {
					return Store.getters.isInRoles([roles.SystemUser]) && parseInt(process.env.VUE_APP_VERSION) >= 335 // 340
				}
			},
			{
				path: '/automation/system/workflowload',
				menu_title: 'Workflow Load',
				showMethod: async () => {
					return false // Store.getters.isInRoles([roles.SystemUser])
				}
			}
		]
	}
]
