<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="idb-block">
      <!-- Header -->
      <div class="idb-block-title">
        <h2><help-icon docPath="/paygate-collections/payers/managepayer/" />View Payer History Item: {{ title }}</h2>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">
        View an individual payer's history item.
        <b-row class="mt-3">
          <b-col md="4">Name: <router-link
              :to="'/collections/payer/'+id+'/edit'"
            >{{name}}</router-link></b-col>
          <b-col md="4">
            Payer Reference :
            <router-link
              :to="'/collections/payer/'+id+'/edit'"
            >{{reference}}</router-link>
          </b-col>
          <b-col md="4">&nbsp;</b-col>
        </b-row>
        <b-row>
          <b-col md="4">Message Type: {{ getTextFromMessageType(messageType) }}</b-col>
          <b-col md="4">Reason Code : {{ reasonCode ? reasonCode : ''}}</b-col>
          <b-col md="4">Generation Date : {{ formatDate(generationDate) }}</b-col>
        </b-row>
      </div>
    </div>
    <div class="idb-block" v-if="body !== null">
      <!-- Header  -->
      <div class="idb-block-title">
        <h3>Description</h3>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ body }}</div>
    </div>
    <div class="idb-block" v-if="circumstances !== null && circumstances !== ''">
      <!-- Header -->
      <div class="idb-block-title">
        <h3>Circumstances</h3>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ circumstances }}</div>
    </div>
    <div class="idb-block" v-if="instructions !== null && instructions !== ''">
      <!-- Header -->
      <div class="idb-block-title">
        <h3>Instructions</h3>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ instructions }}</div>
    </div>
    <div class="idb-block" v-if="paygateAction !== null && paygateAction !== ''">
      <!-- Header -->
      <div class="idb-block-title">
        <h3>Paygate Action</h3>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">{{ paygateAction }}</div>
    </div>
    <div class="idb-block" v-if="allowRepresentation">
      <!-- Header -->
      <div class="idb-block-title">
        <h3>Re-Presentation</h3>
      </div>
      <!-- Main content -->
      <div class="idb-block-content">
        You can re-present this failed collection up to 30 days after the original processing day.
        <br />
        <span>The original processing date was: {{ formatdate(originalProcessingDate ) }}</span>
        To re-present the collection, click the button below.
        <br />
        <br />
        <b-btn variant="warning" @click="representCollection" :disabled="isLoading">Re-Present Collection</b-btn>
      </div>      
    </div>
    <div class="idb-block" v-if="messageData !== null">
      <!-- Header -->
      <div class="idb-block-title">
        <h3>Outgoing Message Details</h3>
      </div>
      <div class="idb-block-content">
        <b-row class="mt-2">
          <b-col cols="2">
            <strong>Subject</strong>
          </b-col>
          <b-col v-html="messageData.title">
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="2">
            <strong>Body</strong>
          </b-col>
          <b-col v-html="messageData.body">
          </b-col>
        </b-row>
        
      </div>
    </div>
    <div class="idb-block"  v-if="messageData === null && payerMessageId !== null">
      <div class="idb-block-title">
        <h3>Outgoing Message Details</h3>
      </div>
      <div class="idb-block-content">
        <b-row class="mt-2">
          <b-col>
            <p>Save a copy of the letter using the button below:</p>
            <b-button @click="saveFile" variant="outline-primary"><i class="far fa-file-pdf mr-2"></i>Save Letter As PDF</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="idb-block" v-if="this.alertMessageId !== null && this.alertMessageId !== ''">
      <div class="idb-block-footer">
        <b-button variant="outline-danger" @click.prevent="deleteAlert" :disabled="isLoading">Clear Alert</b-button>
        <b-button class="ml-1" v-if="messageType===13 && !planInfo.ignoreWarnings" variant="outline-warning" @click.prevent="setToIgnore" :disabled="isLoading">Ignore future warnings</b-button>
        <b-button class="pull-right" variant="outline-primary" v-if="canRequestRefund && hasRefunds && canRefund" :disabled="isLoading || refundRequested || !refundable" @click.prevent="initiateRefund">Refund</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import loading from '@/Assets/Mixins/LoadingMixin'
import swal from 'sweetalert2'
import roles from '@/Assets/Constants/roles'

export default {
  mixins: [loading],
  data () {
    return {
      alertMessageId: null,
      name: null,
      reference: null,
      messageType: null,
      reasonCode: null,
      generationDate: null,
      originalProcessingDate: null,
      title: null,
      body: null,
      paygateAction: null,
      instructions: null,
      circumstances: null,
      statusReason: null,
      ukpayerid: null,
      allowRepresentationStatae: [1, 10, 11, 12, 13, 14],
      metaData: null,
      refundRequested: false,
      paymentComposite: '',
      refundable: false,
      messageData: null,
      messageIsLetter: true,
      payerMessageId: null
    }
  },
  props: {
    id: String,
    historyId: String
  },
  computed: {
    allowRepresentation () {
      let allow = false
      for (var s = 0; s < this.allowRepresentationStatae.length; s++) {
        if (this.allowRepresentationStatae[s] === this.statusReason) {
          allow = true
        }
      }
      return allow
    },
    planInfo () {
      var plan = { planId: null, ignoreWarnings: false }
      if (this.metaData !== null) {
        plan = { ...plan, ...JSON.parse(this.metaData) }
      }
      return plan
    },
    canRequestRefund () {
      return this.paymentComposite !== ''
    },
    hasRefunds () {
      return this.$store.getters.hasLicence('LicenceCollectionsRefunds') && !this.$store.getters.isInRole(roles.SystemUser)
    },
    canRefund () {
      return this.$store.getters.isInRole('RefundPayments') && !this.$store.getters.isInRole(roles.SystemUser)
    }
  },
  async mounted () {
    try {      
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.id}/history/${this.historyId}`, { showload: true })
      if (response) {
        let history = response.data.history
        let envelope = response.data.envelope
        this.reasonCode = history.reasonCode
        this.title = history.title
        this.name = history.payer.payerDetails.firstName + ' ' + history.payer.payerDetails.surname
        this.reference = history.payer.reference
        this.statusReason = history.payer.statusReason
        this.messageType = history.payerHistoryMessageType
        this.generationDate = history.generationDate
        this.body = history.body
        this.refundRequested = history.refundRequested
        this.paymentComposite = history.paymentComposite
        this.originalProcessingDate = history.originalProcessingDate
        this.messageIsLetter = history.messageIsLetter
        this.payerMessageId = history.payerMessageId
        let msg = null
        try {
          msg = JSON.parse(history.messageData)
        } catch (e) {

        }
        if (msg !== null && msg !== undefined) {
          this.messageData = { title: msg.Title, body: msg.Body }
          console.log(this.messageData)
        }        
        if (envelope !== null && envelope !== undefined) {
          this.circumstances = envelope.circumstances
          this.instructions = envelope.instructions
          this.paygateAction = envelope.payGateAction
        }
        this.alertMessageId = history.alertMessageId
        this.metaData = history.metaData
        if (this.hasRefunds && this.canRefund) {
          await this.checkIfRefundable()
        }
      }      
    } catch(err) {
      console.log(err)
      this.$toastr.e('An error occurred retrieving the history item.')
    }
  },
  methods: {
    async representCollection (e) {
      axios.post(`${process.env.VUE_APP_DDMS_API_URL}payer/${this.id}/history/${this.historyId}/represent`, {}, { showload: true })
        .then((response) => {
          this.$toastr.s('Payment Re Presented')
          this.statusReason = -1
        })
        .catch((err) => {
          console.log(err)
          this.$toastr.e('An error occurred Re Presenting the payment.')
        })
    },
    getTextFromMessageType (messageType) {
      let messageTypeStr = ''
      switch (messageType) {
        case 0:
          messageTypeStr = 'AUDDIS'
          break
        case 1:
          messageTypeStr = 'FirstPayment'
          break
        case 2:
          messageTypeStr = 'RegularPayment'
          break
        case 3:
          messageTypeStr = 'LastPayment'
          break
        case 4:
          messageTypeStr = 'RePresentationPayment'
          break
        case 5:
          messageTypeStr = 'Account'
          break
        case 6:
          messageTypeStr = 'CollectionError'
          break
        case 7:
          messageTypeStr = 'ARUDD'
          break
        case 8:
          messageTypeStr = 'ADDACS'
          break
        case 9:
          messageTypeStr = 'AWACS'
          break
        case 10:
          messageTypeStr = 'DDIC'
          break
        case 11:
          messageTypeStr = 'Message Sent'
          break
        case 12:
          messageTypeStr = 'Planned Regular Amount Change'
          break
        case 13:
          messageTypeStr = 'Ad Hoc Payment Due'
          break
        case 14:
          messageTypeStr = 'Portal Message Sent'
          break
        case 15:
          messageTypeStr = 'Portal Message Receieved'
          break
        default: break
      }
      return messageTypeStr
    },
    async deleteAlert (e) {
      axios.delete(`${process.env.VUE_APP_DDMS_API_URL}alerts/${this.alertMessageId}`, { showload: true }).then(async () => {
        axios.get(`${process.env.VUE_APP_DDMS_API_URL}alerts/`, { showload: true }).then((response) => {
          this.$toastr.s('Alert deleted')
          this.alertMessageId = null
          this.$store.dispatch('updateCurrentCollectionsAlertCount')
          this.$router.push('/collections/alerts')
        })
      }).catch((e) => { this.$toastr.e('Could not delete the alert') })
    },
    async setToIgnore (e) {
      await axios.post(`${process.env.VUE_APP_DDMS_API_URL}plan/${this.planInfo.planId}/ignore/${this.historyId}`, {}, { showload: true })
      this.metaData = `{ "planId": "${this.planInfo.planId}", "ignoreWarnings": true }`
    },
    formatDate (date) {
      var momDate = moment(date)
      // | moment("dddd, MMMM Do YYYY")
      return momDate.format('dddd, MMMM Do YYYY')
    },
    async checkIfRefundable () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}amendment/refund/check/${this.historyId}`)
      if (response) {
        this.refundable = response.data
      }
    },
    async saveFile () {
      var response = await axios.get(`${process.env.VUE_APP_DDMS_API_URL}messages/letter/${this.payerMessageId}`)      
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(this.base64ImageToBlob(response.data))
      a.download = `${this.getTextFromMessageType(this.messageType)} - ${this.formatDate(this.generationDate)}.pdf`
      // Append anchor to body.
      document.body.appendChild(a)
      a.click();
      // Remove anchor from body
      document.body.removeChild(a)
    },
    base64ImageToBlob (str) {
      // extract content type and base64 payload from original string
      var imageContent = atob(str)

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)

      // fill the view, using the decoded base64
      for(var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }

      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessing' });
      return blob;
    },
    async initiateRefund () {
      var result = null
      try {
        result = await swal.fire({
          title: 'Refund Payment',
          text: `Are you sure you want this Payment to be refunded?`,
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.success,
          confirmButtonText: 'Create Refund',
          cancelButtonText: 'Cancel',
          width: '40%'
        })
      } catch (err) {
        console.log(err)
        result = false
      }
      if (result.isConfirmed) {
        try {
          var response = await axios.post(`${process.env.VUE_APP_DDMS_API_URL}amendment/refund`, { paymentComposite: this.paymentComposite, payerHistoryId: this.historyId }, { showload: true })
          if (response && response.data) {
            if (response.data.errorMessages.length == 0) {
              this.$toastr.s('Refund requested.')
              this.refundRequested = true
            }
            else {
              this.refundRequested = false
              this.$toastr.e(response.data.errorMessages[0])
            }
          }
        } catch  {
          this.$toastr.e('Could not initate refund process.')
        }
      }
    }
  }
}
</script>
