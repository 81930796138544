import axios from 'axios'

function getToastTitle (status) {
  if (status.slice(-1) === 'e') {
    return `${status}d`
  }
  return `${status}ed`
}

export default {
  data () {
    return {
      approvalType: null,
      approvalMethodWarning: null
    }
  },
  async mounted () {
    console.log(this.action)
    if (!this.action.rejected) {
      // Here we will check if the action has a non-default approval method and if they can even approve it?
      var response = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/Method/${this.action.id}`, { showload: true, showerror: true, errormessage: 'Failed to get approval method' })

      if (!response.data.hasProvider) {
        switch (response.data.approvalMethod) {
          case 'USB Token':
            this.approvalMethodWarning = 'You are not assigned to a usb token, please contact your system administrator'
            this.$snapbar.w('You are not assigned to a usb token, please contact your system administrator')
            break

          default:
            this.approvalMethodWarning = `You do not have ${response.data.approvalMethod} authentication setup, you can do this in the user settings or contact your user administrator`
            this.$snapbar.w(`You do not have ${response.data.approvalMethod} authentication setup, you can do this in the user settings or contact your user administrator`)
            break
        }
      }
    }
  },
  methods: {
    async approve () {
      try {
        var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/Approve/${this.action.id}`, null, { showload: true, showerror: true, errormessage: `Failed to approve ${this.status}` })
        switch (response.data.approvalType) {
          case 'Via Action Item':
            if (response.data.amountRemaining === 0) {
              this.$toastr.s(`${this.name} ${getToastTitle(this.status)}`, getToastTitle(this.status))
              if (
                this.name === 'User' &&
                this.action.data.clonedUserTempId !== '00000000-0000-0000-0000-000000000000'
              ) {
                this.cloneUserGroups(response.data.entityId)
              }
            } else if (response.data.amountRemaining === -1) {
              this.$toastr.e('Not authorized to approve action', 'Not Authorized')
            } else {
              this.$toastr.s(
                `${this.name} needs ${response.data.amountRemaining} more approval${response.data.amountRemaining > 1
                  ? 's'
                  : ''}`,
                'Approved'
              )
            }
            this.doneWithCode()
            break
          case 'USB Token':
            try {
              await this.$store.commit('setDeviceType', 'USB Token')
              var thumbprint = await this.$store.dispatch('getUsbTokenThumbprint')

              try {
                var responseWithCode = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}Actions/Approve/Code`, { Code: thumbprint, ActionId: this.action.id }, { showload: true, showerror: true, errormessage: `Failed to approve ${this.status}` })
                if (responseWithCode.data.codeValid) {
                  if (responseWithCode.data.amountRemaining === 0) {
                    this.$toastr.s(`${this.name} ${getToastTitle(this.status)}`, getToastTitle(this.status))
                    this.cloneUserGroups(responseWithCode.data.entityId)
                    if (
                      this.name === 'User' &&
                      this.action.data.clonedUserTempId !== '00000000-0000-0000-0000-000000000000'
                    ) {
                      this.cloneUserGroups(responseWithCode.data.entityId)
                    }
                  } else if (responseWithCode.data.amountRemaining === -1) {
                    this.$toastr.e('Not authorized to approve action', 'Not Authorized')
                  } else {
                    this.$toastr.s(
                      `${this.name} needs ${responseWithCode.data} more approval${responseWithCode.data
                        .amountRemaining > 1
                        ? 's'
                        : ''}`,
                      'Approved'
                    )
                  }
                  this.doneWithCode()
                } else {
                  this.$toastr.w('You are not assigned to a usb token', 'Unable to continue - Incomplete set-up')
                }
              } catch { }
            } catch (error) {
              if (error.message) {
                this.$toastr.e(error.message, 'Error')
              } else {
                this.$toastr.e('Something went wrong getting the USB token', 'Failed')
              }
            }
            break
          case 'Password':
            this.approvalType = response.data.approvalType
            this.openApprovalModal()
            break
          default:
            this.approvalType = response.data.approvalType
            if (response.data.hasProvider) {
              this.openApprovalModal()
            } else {
              this.$toastr.w(
                `You do not have ${this
                  .approvalType} authentication setup, you can do this in the user settings or contact your user administrator`,
                'Unable to continue - Incomplete set-up'
              )
            }

            break
        }
      } catch { }
    },
    async cloneUserGroups (entityId) {
      this.action.data.clonedUserTempId = entityId
      const cloneResponse = await axios.post(
        `${process.env.VUE_APP_PLATFORM_API_URL}Users/CloneUserGroupBindings`,
        this.action.data
      )
      if (cloneResponse.data.status === 'Action') {
        this.$toastr.s('Adding cloned user to groups needs to be approved', 'Needs Approval')
      }
    }
  }
}
