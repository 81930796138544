<template>
  <b-modal v-model="value" centered size="logModal" @hide="close" no-close-on-backdrop>
    <div slot="modal-header" class="w-100">Log Details</div>
    <div class="row form-group">
      <div class="col-md-12">
        <b-form-textarea v-model="logContents" :rows="20"></b-form-textarea>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-row>
        <b-col cols="2">
          <b-btn size="sm" class="float-left" variant="primary" @click="close">Close</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'viewLogModal',

  props: {
    value: Boolean,
    logContents: String
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.modal .modal-logModal {
  max-width: 95%;
}
</style>
