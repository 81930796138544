var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "performanceTuning" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.performanceTuningLoaded,
            expression: "performanceTuningLoaded"
          }
        ]
      },
      [
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _vm._v("Payments Per Block")
          ]),
          _c(
            "div",
            {
              staticClass: "col-md-3",
              class: {
                invalid: _vm.$v.performanceTuning.paymentsPerBlock.$error
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.$v.performanceTuning.paymentsPerBlock.$model,
                    expression: "$v.performanceTuning.paymentsPerBlock.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                staticStyle: { width: "150px" },
                attrs: { type: "number" },
                domProps: {
                  value: _vm.$v.performanceTuning.paymentsPerBlock.$model
                },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.performanceTuning.paymentsPerBlock,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("validation-messages", {
                attrs: { name: "number of Payments per block" },
                model: {
                  value: _vm.$v.performanceTuning.paymentsPerBlock,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.performanceTuning, "paymentsPerBlock", $$v)
                  },
                  expression: "$v.performanceTuning.paymentsPerBlock"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _vm._v("Bulk Copy Batch Size")
          ]),
          _c(
            "div",
            {
              staticClass: "col-md-3",
              class: {
                invalid: _vm.$v.performanceTuning.bulkCopyBatchSize.$error
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.$v.performanceTuning.bulkCopyBatchSize.$model,
                    expression: "$v.performanceTuning.bulkCopyBatchSize.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                staticStyle: { width: "150px" },
                attrs: { type: "number" },
                domProps: {
                  value: _vm.$v.performanceTuning.bulkCopyBatchSize.$model
                },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.performanceTuning.bulkCopyBatchSize,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("validation-messages", {
                attrs: { name: "Bulk copy batch size" },
                model: {
                  value: _vm.$v.performanceTuning.bulkCopyBatchSize,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.performanceTuning, "bulkCopyBatchSize", $$v)
                  },
                  expression: "$v.performanceTuning.bulkCopyBatchSize"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _vm._v("Deletion Block Size")
          ]),
          _c(
            "div",
            {
              staticClass: "col-md-3",
              class: {
                invalid: _vm.$v.performanceTuning.deletionBlockSize.$error
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.$v.performanceTuning.deletionBlockSize.$model,
                    expression: "$v.performanceTuning.deletionBlockSize.$model",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                staticStyle: { width: "150px" },
                attrs: { type: "number" },
                domProps: {
                  value: _vm.$v.performanceTuning.deletionBlockSize.$model
                },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.performanceTuning.deletionBlockSize,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("validation-messages", {
                attrs: { name: "Deletion block size" },
                model: {
                  value: _vm.$v.performanceTuning.deletionBlockSize,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.performanceTuning, "deletionBlockSize", $$v)
                  },
                  expression: "$v.performanceTuning.deletionBlockSize"
                }
              })
            ],
            1
          )
        ]),
        _c("br"),
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.savePerformanceTuning }
              },
              [_vm._v("Save Performance Tuning")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }