var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showItemTable && _vm.itemsLoaded
      ? _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _vm._v(" Create or upload items to this anti-fraud list. ")
          ])
        ])
      : _vm._e(),
    !_vm.showItemTable && _vm.itemsLoaded
      ? _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: this.isItemActioned },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.uploadItemsFromFile($event)
                  }
                }
              },
              [_vm._v("Upload Items From File ")]
            )
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { disabled: this.isItemActioned },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.addNewItem($event)
                  }
                }
              },
              [_vm._v("Add New Item ")]
            )
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { disabled: this.isItemActioned },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.uploadItemsFromSubmission($event)
                  }
                }
              },
              [_vm._v("Upload Items From Submission ")]
            )
          ])
        ])
      : _vm._e(),
    _vm.showDropzone
      ? _c("div", { staticClass: "row form-group" }, [
          _c(
            "div",
            { staticClass: "col-md-12", attrs: { id: "dzUpload" } },
            [
              _c("vue-dropzone", {
                ref: "fileUploader",
                attrs: {
                  id: "drop1",
                  "use-custom-dropzone-options": true,
                  options: _vm.dropzoneOptions
                },
                on: {
                  "vdropzone-success": _vm.afterSuccess,
                  "vdropzone-file-added": _vm.fileAdded,
                  "vdropzone-sending": _vm.sending
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.showItemTable
      ? _c("div", { staticClass: "row form-group" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "vue-good-table",
                {
                  ref: "antiFraudListItems",
                  attrs: {
                    mode: "remote",
                    columns: _vm.columns,
                    rows: _vm.antiFraudListItems,
                    lineNumbers: true,
                    totalRows: _vm.itemCount,
                    "pagination-options": {
                      enabled: true,
                      perPage: 10,
                      dropdownAllowAll: false
                    },
                    styleClass: "vgt-table striped bordered"
                  },
                  on: {
                    "on-page-change": _vm.onPageChange,
                    "on-sort-change": _vm.onSortChange,
                    "on-per-page-change": _vm.onPerPageChange,
                    "on-row-click": _vm.onRowClick
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Upload items from file",
                              expression: "'Upload items from file'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: {
                            disabled: this.isItemActioned,
                            variant: "link",
                            title: "Upload Items"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.uploadItemsFromFile($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-upload pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Upload items from file")
                          ])
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Create a new anti-fraud item",
                              expression: "'Create a new anti-fraud item'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: {
                            disabled: this.isItemActioned,
                            variant: "link",
                            title: "Add New Item"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addNewItem($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus-circle pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Create a new anti-fraud module")
                          ])
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Upload items from a previous submission",
                              expression:
                                "'Upload items from a previous submission'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: {
                            disabled: this.isItemActioned,
                            variant: "link",
                            title: "Upload Items"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.uploadItemsFromSubmission($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-database pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Upload items from a previous submission")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _c(
      "div",
      [
        _c("editAntiFraudItemModal", {
          attrs: { item: _vm.currentItem, newItem: _vm.newItem },
          on: {
            deleteItem: _vm.itemDeleted,
            saveItem: _vm.itemSaved,
            close: _vm.editCancelled
          },
          model: {
            value: _vm.isModalVisible,
            callback: function($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }