<template>
  <div>
    <loading v-bind:loading="loading"></loading>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Create a New Bureau Job
          <help-icon :docPath="'/bacsbureau/jobs/'"></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <b-row>
          <b-col sm="2">
            <label class="mt-2 required" for="filter">Group</label>
          </b-col>
          <b-col sm="3">
            <group-select
              :id="'filter'"
              v-model="bureauJob.groupId"
              :groups="groups"
              :clearable="false"
              @input="SilentSave"
            ></group-select>
          </b-col>
        </b-row>
        <!-- New One -->
        <b-row class="mt-2">
          <label for="bureau-job-name" class="label-control col-md-2 required">Bureau Job Name</label>
          <b-col sm="3">
            <b-form-input
              id="bureau-job-name"
              type="text"
              v-model.trim="bureauJob.name"
              :formatter="trimInput"
              @input="checkName"
            ></b-form-input>
            <small class="text-danger" v-if="nameInUse">Bureau Job Name already exists</small>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col sm="2">
            <label for="deleteSourceFilesCheckbox">Delete Source Files</label>
          </b-col>
          <b-col sm="3">
            <p-check
              :labelId="'deleteSourceFilesCheckbox'"
              class="p-switch p-fill"
              color="primary"
              id="deleteSourceFilesCheckbox"
              @change="SilentSave"
              v-model="bureauJob.deleteSourceFiles"
            ></p-check>
            <span
              class="form-text small"
            >Specifies whether the source files will be deleted upon successfully being submitted to BACS</span>
          </b-col>
        </b-row>

        <!-- table -->

        <b-row class="mt-3">
          <b-col sm="12">
            <vue-good-table
              :paginationOptions="paginationOptions"
              :rows="rows"
              :columns="columns"
              :lineNumbers="true"
              @on-cell-click="onCellClick"
              @on-row-click="() => {}"
              styleClass="vgt-table striped bordered"
              :searchOptions="{
                          enabled: true
                        }"
              :sort-options="sortOptions"
              mode="remote"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              ref="table"
              :isLoading.sync="isTableLoading"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <button
                    class="btn btn-sm btn-warning"
                    id="onDuplicateJobDetailClick"
                    @click.stop="onDuplicateJobDetailClick(props.row)"
                    title="Duplicate Job Detail"
                    :disabled="isLoading"
                  >
                    <span class="fa fa-copy"></span>
                  </button>
                  <button
                    class="btn btn-sm btn-danger ml-1"
                    id="onDeleteJobDetailClick"
                    @click="onDeleteJobDetailClick(props.row)"
                    title="Delete Job Detail"
                    :disabled="isLoading"
                  >
                    <span class="fa fa-trash-alt"></span>
                  </button>
                </span>
                <span v-else-if="props.column.field === 'hasWildcard'">
                  <i class="fa fa-check" v-if="props.row.hasWildcard"></i>
                  <i class="fa fa-times" v-else></i>
                </span>
                <span v-else-if="props.column.field === 'customerName'">
                  <span v-if="props.row.customerName === ' ()'">
                    <i>Not Set</i>
                  </span>
                  <span v-else>{{props.row.customerName }}</span>
                </span>

                <span v-else-if="props.column.field === 'importSchema'">
                  {{
                  props.row.mapping === null ? props.formattedRow[props.column.field] : getMappingName(props.row.mapping)
                  }}
                </span>
                <span v-else>
                  {{
                  props.formattedRow[props.column.field]
                  }}
                </span>
              </template>

              <div slot="table-actions">
                <button
                  @click.prevent="clearTableFilters"
                  class="btn btn-link"
                  v-b-popover.hover.top.d500="'Clear filters'"
                >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span>
                  <span class="sr-only">Clear filters</span>
                </button>

                <b-button
                  @click.prevent="load"
                  class
                  variant="link"
                  id="refreshJobDetailTableButton"
                  v-b-popover.hover.top.d500="'Refresh the data in the table'"
                >
                  <i class="fa fa-sync pointer dimmedIcon"></i>
                  <span class="sr-only">Refresh Table</span>
                </b-button>
                <b-button
                  @click.prevent="exportTable"
                  class
                  variant="link"
                  id="export-table-button"
                  v-b-popover.hover.top.d500="'Export the contents of the table'"
                >
                  <i class="fa fa-file-export pointer dimmedIcon"></i>
                  <span class="sr-only">Export Table</span>
                </b-button>
                <b-button id="addNewJobDetailButton"
                  @click.prevent="onAddNewJobDetailClick"
                  class
                  variant="link"
                  v-b-popover.hover.top.d500="'Add Job Detail'"
                >
                  <i class="fa fa-plus pointer dimmedIcon"></i>
                  <span class="sr-only">Add new Job Detail</span>
                </b-button>
              </div>

              <div slot="table-actions-bottom">
                <div class="row" style="padding: 8px 0 8px 8px;">
                  <div class="col-md-2">
                    <button
                      class="btn btn-outline-primary"
                      @click.prevent="onAddNewJobDetailClick"
                    >Add Job Detail</button>
                  </div>
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>

      <div class="idb-block-footer">
        <b-button
          variant="primary" id="saveJobButton"
          @click="onSave()"
          :disabled="isLoading || (bureauJob.groupId === undefined || bureauJob.groupId === null) || (bureauJob.name === undefined || bureauJob.name === null || bureauJob.name === '') || nameInUse"
        >Save</b-button>
        <b-button id="clearJobButton"
          class="float-right"
          variant="danger"
          @click="onResetCreateJobClick()"
          :disabled="isLoading"
        >Clear Job</b-button>
      </div>
    </div>

    <div>
      <addBureauJobDetailModal
        :bureauJobId="bureauJob.bureauJobId"
        :bureauCustomers="bureauCustomers"
        v-on:submit="addBureauJobDetailClosed"
      ></addBureauJobDetailModal>
    </div>
    <div>
      <editBureauJobDetailModal
        :bureauJobId="bureauJob.bureauJobId"
        :bureauJobDetailId="bureauJobDetailId"
        :bureauCustomers="bureauCustomers"
        :duplicateMode="duplicateMode"
        v-on:submit="editBureauJobDetailClosed"
      ></editBureauJobDetailModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import loading from '@/Assets/Mixins/LoadingMixin'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import Utility from '@/Assets/Mixins/Utility'
import AddBureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/AddBureauJobDetail.vue'
import EditBureauJobDetail from '@/Components/Bureau/BureauJobsAdministration/EditBureauJobDetail.vue'

import swal from 'sweetalert2'
import colours from '@/Assets/Constants/colours'
import papa from 'papaparse'
import { cH } from '@fullcalendar/core/internal-common'
import { mapGetters } from 'vuex'

export default {
  components: { addBureauJobDetailModal: AddBureauJobDetail, editBureauJobDetailModal: EditBureauJobDetail },
  mixins: [Utility, loading, tableFilterMixin],
  data () {
    return {
      groups: [],
      bureauCustomers: [],
      bureauCustomerDisplay: [],
      bureauJobDetailId: '',
      AddEditStandard18Job: false,
      duplicateMode: 0,
      bureauJob: {},
      loading: false,

      // table
      rows: [],
      columns: [
        {
          label: 'Job Details Id',
          field: 'bureauJobDetailId',
          hidden: true
        },
        {
          label: 'Bureau Customer Id',
          field: 'bureauCustomerId',
          hidden: true
        },
        {
          label: 'Customer Name (SUN)',
          field: 'customerName',
          formatFn: this.FormatCustomerName
        },
        {
          label: 'Customer Reference',
          field: 'customerReference',
          hidden: true
        },
        {
          label: 'Payment File/URL',
          field: 'filename'
        },
        {
          label: 'Wildcard',
          field: 'hasWildcard',
          tdClass: 'text-center'
        },
        {
          label: 'Import Schema',
          field: 'importSchema'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        }
      ],
      serverParams: {
        sort: [{ field: 'customerName', type: 'asc' }]
      },
      nameInUse: false,
      saved: true,
      changesMade: false
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.state.common.paygateId
    }
  },
  async created () {
    this.bureauJob.bureauJobId = ''
    this.bureauJob.name = ''
    this.bureauJob.paygateId = this.selectedCustomer
    await this.load()
    this.mappings = await this.loadMappings('')
    this.changesMade = false
    this.saved = false
  },
  methods: {
    cH,
    // trims the input string to a maximum character length
    trimInput (e) {
      if (e.length > 50) {
        return e.substring(0, 50)
      }
      return e
    },
    // ================================== Supporting Methods

    onAddNewJobDetailClick () {
      this.AddEditStandard18Job = false
      this.$bvModal.show('add-bureau-job-detail')
    },

    onAddNewStandard18JobDetailClick () {
      this.AddEditStandard18Job = true
      this.$bvModal.show('add-bureau-job-detail')
    },

    async addBureauJobDetailClosed () {
      await this.getBureauJobDetails()
    },

    async editBureauJobDetailClosed () {
      this.$nextTick(async function () {
        this.duplicateMode = 0
        await this.getBureauJobDetails()
      })
    },

    async onResetCreateJobClick () {
      const swalResult = await swal.fire({
        title: 'Reset this Bureau Job',
        text: 'Are you sure you want to reset the job creation in process? This action will remove all the job details currently entered.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          // await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/deleteJob/' + this.bureauJob.bureauJobId, { showload: true })
          var response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureaujob/deletejob/' + this.bureauJob.bureauJobId,
            {
              showerror: true,
              errormessage: 'Failed deleting bureau job'
            })

          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
            await this.loadCreateBureauJob()
            await this.getBureauJobDetails()
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        } finally {

        }
      }
    },

    async onDeleteJobDetailClick (row) {
      var _name = row.customerName
      var _filename = row.filename
      var messageText = _name + ' filename:' + _filename + ''
      const swalResult = await swal.fire({
        title: 'Delete Bureau Job Detail',
        html: 'Are you sure you want to <strong>delete</strong> this job detail for <strong>' + messageText + '</strong>?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        try {
          var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/deletebureaujobdetail/' + row.bureauJobDetailId,
            {
              showerror: true,
              errormessage: 'Failed deleting bureau job detail'
            })

          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          await this.getBureauJobDetails()
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
        } finally {

        }
      }
    },

    async onDuplicateJobDetailClick (row) {
      var _name = row.customerName
      var _filename = row.filename
      var messageText = _name + ' filename:' + _filename + ''
      const swalResult = await swal.fire({
        title: 'Duplicate Bureau Job Detail',
        html: 'Are you sure you want to <strong>duplicate</strong> this job detail for <strong>' + messageText + '</strong>?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.warn,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        this.$nextTick(function () {
          this.duplicateMode = 1
          console.log('duplicate mode', this.duplicateMode)
          this.bureauJobDetailId = row.bureauJobDetailId
          // go open the modal
          this.$bvModal.show('edit-bureau-job-detail')
        })
      }
    },
    onCellClick (params) {
      if (params.column.field !== 'actions') {
        this.$nextTick(function () {
          this.duplicateMode = 0
          console.log('duplicate mode', this.duplicateMode)
          this.bureauJobDetailId = params.row.bureauJobDetailId
          this.$bvModal.show('edit-bureau-job-detail')
        })
      }
    },

    FormatCustomerName (field) {
      if (field === ' ()') return ''
      else return field
    },

    // ================================== Initial Load

    load: _.debounce(async function () {
      await this.getBureauGroupData()
      await this.loadCreateBureauJob()
      await this.getBureauJobDetails()
      await this.getBureauCustomerData()
    }, 800),

    async getBureauGroupData () {
      const getGroupsResponse = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaugroups/getbureaugroups', { showload: true, params: { paygateId: this.selectedCustomer } })
      this.groups = getGroupsResponse.data
      for (let i = 0; i < this.groups.length; i++) {
        if (this.groups[i].groupType === 'FPS Bureau') {
          this.groups[i].name = `${this.groups[i].name} (Faster Payments)`
        }
      }
    },

    async loadCreateBureauJob () {
      var bureauJob = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureaujob/CreateBureauJob', { showload: true })
      this.bureauJob = bureauJob.data
    },

    async getBureauJobDetails () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJobDetails/', { params: { ...this.buildGoodTableQuery(), id: this.bureauJob.bureauJobId, paygateId: this.selectedCustomer }, showload: true })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch (e) {

      } finally {
        this.isTableLoading = false
      }
    },
    async exportTable () {
      try {
        this.isTableLoading = true
        const query = this.buildGoodTableQuery()
        query.perPage = this.totalRecords
        query.forExport = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/GetBureauJobDetails/', { params: { ...query, id: this.id }, showload: true })

        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `${this.bureauJob.name}-job-details.csv`)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `${this.bureauJob.name}-job-details.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch (e) { } finally {
        this.isTableLoading = false
      }
    },
    async getBureauCustomerData () {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/getbureaucustomerlist', { showload: true })
      this.bureauCustomers = response.data

      this.bureauCustomerDisplay = []

      var index = 0
      for (index = 0; index < this.bureauCustomers.length; ++index) {
        var current = {
          id: this.bureauCustomers[index].bureauCustomerId,
          name: this.bureauCustomers[index].display,
          disabled: false
        }
        this.bureauCustomerDisplay.push(current)
      }
    },

    // ================================== Save

    async SilentSave () {
      this.saved = false
      var data = {
        name: this.bureauJob.name,
        bureauJobId: this.bureauJob.bureauJobId,
        groupId: this.bureauJob.groupId,
        deleteSourceFiles: this.bureauJob.deleteSourceFiles,
        bureauJobDetails: this.rows
      }

      var silentSaveResult = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/CreateBureauJobSilentSave', data, { showload: false })
      this.changesMade = true
    },

    async onSave () {
      var data = {
        name: this.bureauJob.name,
        bureauJobId: this.bureauJob.bureauJobId,
        groupId: this.bureauJob.groupId,
        bureauJobDetails: this.rows
      }

      var persistResult = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/PersistJobInBureauJobCreate', data, { showload: true })
      if (persistResult.data.toastType === 2) {
        this.$toastr.s(persistResult.data.toastMessage)
        // navigate immediately
        this.changesMade = false
        this.saved = true
        this.$router.push({ name: 'ManageJob', params: { id: this.bureauJob.bureauJobId } })
      } else {
        this.$toastr.e(persistResult.data.toastMessage)
      }
    },
    getMappingName (mapping) {
      let mappingName = 'unknown'
      for (let i = 0; i < this.mappings.length; i++) {
        if (this.mappings[i].value === mapping) {
          mappingName = this.mappings[i].text
        }
      }
      return mappingName
    },
    checkName: _.debounce(async function () {
      const url = `${process.env.VUE_APP_BUREAU_API_URL}bureauJob/jobnameexists`
      const response = await axios.get(url, { showload: true, params: { paygateId: this.paygateId, jobName: this.bureauJob.name } })
      this.nameInUse = response.data
      await this.SilentSave()
    }, 300)
  },
  async beforeRouteLeave (to, from, next) {
    if (this.changesMade) {
      const swalResult = await swal.fire({
        title: 'Job not saved',
        html: 'Your changes will be lost if your changes are not saved?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Leave',
        cancelButtonText: 'Cancel'
      })
      if (swalResult.isConfirmed) {
        try {
          // await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureauJob/deleteJob/' + this.bureauJob.bureauJobId, { showload: true })
          var response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureaujob/deletejob/' + this.bureauJob.bureauJobId,
            {
              showerror: true,
              errormessage: 'Failed deleting bureau job'
            })
          next()
        } catch (e) {
        } finally {

        }
      }
    } else {
      if (!this.saved) {
        var response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureaujob/deletejob/' + this.bureauJob.bureauJobId,
          {
            showerror: true,
            errormessage: 'Failed deleting bureau job'
          })
        next()
      } else {
        next()
      }
    }
  }
}

</script>
