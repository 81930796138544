<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Bureau Usage Report
              <help-icon docPath="/administration/reports/licensingusage/" />
              <favourite-icon />
            </h2>
          </div>
          <div class="idb-block-content">
            <div class="row form-group">
              <div class="form-group col-md-4">
                <label for="from" class="required">Billing Month</label>
                <vuejsDatepicker
                  name="month"
                  id="month"
                  format="MMM/yyyy"
                  input-class="form-control bg-white"
                  :bootstrap-styling="true"
                  minimumView="month"
                  maximumView="year"
                  initial-view="month"
                  :use-utc="true"
                  v-model="monthselection"
                  :disabledDates="{from:new Date(Date.now())}"
                  v-on:selected="dateChanged"
                ></vuejsDatepicker>
              </div>
            </div>
            <div class="row form-group">
              <div class=" col-md-12">
                <vue-good-table
                  :paginationOptions="paginationOptions"
                  :sort-options="sortOptions"
                  :isLoading.sync="isTableLoading"
                  :rows="rows"
                  :columns="columns"
                  :lineNumbers="true"
                  mode="remote"
                  :totalRows="totalRecords"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  styleClass="vgt-table striped bordered"
                  ref="table"
                >
                  <div slot="table-actions">
                    <button
                      @click.prevent="clearTableFilters"
                      class="btn btn-link"
                      v-b-popover.hover.top.d500="'Clear filters'"
                    >
                  <span class="fa-stack" style="font-size: 10px;">
                    <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                    <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
                  </span><span class="sr-only">Clear filters</span>
                    </button>
                    <b-button
                      @click.prevent="load"
                      class
                      variant="link"
                      v-b-popover.hover.top.d500="'Refresh the data in the table'"
                    >
                      <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh Table</span>
                    </b-button>
                    <b-button
                      @click.prevent="exportTable"
                      class
                      variant="link"
                      v-b-popover.hover.top.d500="'Export the contents of the table'"
                    >
                      <i class="fa fa-file-export pointer dimmedIcon"></i><span class="sr-only">Export Table</span>
                    </b-button>
                  </div>
                </vue-good-table>
              </div>

            </div>
          </div>
        </div>

        <div class="idb-block">
          <div class="idb-block-title">
            <h2>Email Control</h2>
          </div>
          <div class="idb-block-content">
            <table
              class="table table-header-rotated"
              v-if="EmailRecipients.length > 0"

            >
              <thead>
              <tr>
                <th style="width:75px;"></th>
                <!-- <th class="rotate" style="width:50px;">
                  <div>
                    <span>CC Email only</span>
                  </div>
                </th> -->
                <th class="rotate" style="width:50px;">
                  <div>
                    <span>Enabled</span>
                  </div>
                </th>
                <th class="rotate" style="width:50px;">
                  <div>
                    <span>Edit</span>
                  </div>
                </th>
                <th class="rotate" style="width:50px;">
                  <div>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="recipient in EmailRecipients" :key="recipient.id + componentKey">
                <td class="row-header" style="width:150px;">{{ recipient.name }} <i>({{ recipient.email }})</i></td>
                <!-- <td>
                  <p-check
                    class="p-default p-curve"
                    v-model="recipient.ccEmail"
                    @change="ChangeCcEmail(recipient)"
                  ></p-check>
                </td> -->
                <td>
                  <label :for="recipient.id+'_disabled'" class="sr-only">Disable Recipient</label>

                  <a href="#" @click.prevent="ToggleRecipientEnabled(recipient)">
                  <i :key="recipient.id+'_disabled'+componentKey" class="fa fa-fw" :class="recipient.disabled ? 'text-danger fa-times' : 'text-success fa-check'" ></i>
                </a>
                  <!-- <p-check :labelId="recipient.id+'_disabled'"
                    class="p-default p-curve"
                    v-model="recipient.disabled"
                    @change="UpdateEmailRecipientData(recipient)"
                  ></p-check> -->
                </td>
                <td style="width:20px;">
                  <a
                    class="fa fa-cog"
                    role="button"
                    @click="EditRecipient(recipient.id)"
                  ></a>
                </td>
                <td style="width:20px;">
                  <a
                    class="fa fa-trash"
                    role="button"
                    @click="DeleteEmailRecipientData(recipient)"
                  ></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="idb-block-footer">
            <b-button
              variant="primary"
              @click="AddNewRecipient()"
            >Add New Email Recipient</b-button>
            <b-button
              class="float-right"
              variant="warning"
              @click="ResendEmail()"
            >Resend Email</b-button>

          </div>
        </div>

      </div>
    </div>

    <addRecipientModal
    :RecipientId="RecipientId"
    :EditMode = "EditMode"

      v-on:submit="addRecipientModalClosed"
    ></addRecipientModal>

  </div>
</template>

<script>

// Third Party
import axios from 'axios'
import moment from 'moment'
import AddRecipientModal from '@/Components/Platform/Reports/BureauUsageReport/AddRecipientEmailModal.vue'

// Mixins
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import Utility from '@/Assets/Mixins/Utility'
import loading from '@/Assets/Mixins/LoadingMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import papa from 'papaparse'
import _ from 'lodash'
import colours from '@/Assets/Constants/colours'
import swal from 'sweetalert2'

export default {
  mixins: [tableFilterMixin, Utility, loading, bacsMixin],
  components: { AddRecipientModal },
  data () {
    return {
      EmailRecipients: [],
      RecipientId: '',
      EditMode: false,
      monthselection: new Date(),
      disabledDates: null,
      componentKey: 0,

      columns: [
        {
          label: 'Paygate ID',
          field: 'paygateId',
          hidden: true
        },
        {
          label: 'Customer Name', // Does not refer to bureau customer, this is the paygate customer name
          field: 'customerName',
          filterOptions: {
            enabled: true,
            placeholder: 'Name'
          },
          sortable: true
        },
        {
          label: 'Month/Year',
          field: 'dateString',
          sortable: false
        },

        {
          label: 'Number Of Submissions',
          field: 'numberOfSubmissions',

          sortable: true
        },
        {
          label: 'Number Of Files',
          field: 'numberOfPaymentFiles',
          sortable: true
        },
        {
          label: 'Number Of Submission Items',
          field: 'numberOfPaymentItems',
          sortable: true
        },
        {
          label: 'Licensed Active SUNs',
          field: 'licensedActiveSuns',
          sortable: true
        },

        {
          label: 'Actual Active SUNs Used',
          field: 'activeSunsUsed',
          sortable: true
        },

        {
          label: 'Licensed SUNs Exceeded',
          field: 'licensedExceeded',
          sortable: true
        }
      ],

      rows: [],
      serverParams: {
        sort: [{ field: 'customername', type: 'asc' }]
      }
    }
  },
  computed: {
    DisabledDates () {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      var month = today.getMonth()
      var year = today.getYear()
      if (month === 12) {
        month = 1
        year = year + 1
      }
      today.setMonth(month)
      today.setFullYear(year)
      return this.monthselection > today
    }
  },
  created () {
    // await this.load()
  },
  methods: {
    async load () {
      this.componentKey += 1
      await this.GetLicensingData()
      await this.GetEmailRecipients()
    },

    async GetLicensingData () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauusagereport/GetLicensingData`, {
          params: {
            ...this.buildGoodTableQuery(), monthselection: this.monthselection
          },
          showerror: true,
          errormessage: 'Failed loading licensing table'
        })
        console.log('table response', response)
        this.rows = response.data.data
        this.totalRecords = response.data.count
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    async GetEmailRecipients () {
      try {
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauusagereport/GetEmailRecipientData`, {
          showerror: true,
          errormessage: 'Failed to get email recipients'
        })
        console.log('response', response)
        this.EmailRecipients = response.data
      } catch { } finally {

      }
    },

    async AddEmailRecipientData (recipient) {
      try {
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/AddEmailRecipientData', recipient, { showload: true })
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (error) {
        this.$toastr.e('Failed to add recipient', 'LicensingEmail Recipient')
      }
      await this.load()
    },

    async ToggleRecipientEnabled (recipient) {
      recipient.disabled = !recipient.disabled
      await this.UpdateEmailRecipientData(recipient)
    },

    async UpdateEmailRecipientData (recipient) {
      try {
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/UpdateEmailRecipientData', recipient, { showload: true })
        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (error) {
        this.$toastr.e('Failed to update recipient', 'LicensingEmail Recipient')
      } finally { await this.load() }
    },
    async DeleteEmailRecipientData (recipient) {
      const swalResult = await swal.fire({
        title: 'Delete Email Recipient',
        html: 'Are you sure you want to <strong>delete</strong> the email recipient: <strong>' + recipient.name + '</strong> with email <strong>' + recipient.email + '</strong> ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      console.log(recipient)
      if (swalResult.isConfirmed) {
        try {
          var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/DeleteEmailRecipientData/' + recipient.id, { showload: true })
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
        } catch (error) {
          this.$toastr.e('Failed to delete recipient', 'LicensingEmail Recipient')
        }
      }
      await this.load()
    },

    async ChangeCcEmail (recipient) {
      await this.$nextTick()
      await this.UpdateEmailRecipientData(recipient)
    },

    async dateChanged () {
      await this.$nextTick()
      await this.load()
    },
    onCellClick () { },
    onRowClick () { },
    async exportTable () {
      try {
        let query = this.buildGoodTableQuery()
        query.perPage = this.totalRecords
        query.page = 1
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauusagereport/GetLicensingDataTableExport`, {
          params: {
            ...query, monthselection: this.monthselection
          },
          showerror: true,
          errormessage: 'Failed exporting licensing table'
        })
        console.log('response', response)

        const filename = 'licensedetails.csv'
        var csvString = papa.unparse(response.data)
        var blob = new Blob([csvString])
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } catch { } finally {
        this.isTableLoading = false
      }
    },
    DeleteRecipient (id) {
      console.log('Deleting Recipient id: ', id)
    },
    AddNewRecipient () {
      this.EditMode = false
      this.$bvModal.show('add-email-recipient')
    },
    async addRecipientModalClosed () {
      this.$nextTick(async function () {
        this.RecipientId = ''
        await this.load()
      })
    },
    EditRecipient (recipientid) {
      this.RecipientId = recipientid
      this.EditMode = true
      this.$bvModal.show('add-email-recipient')
    },
    async ResendEmail () {
      if (this.monthselection.getMonth === undefined) {
        this.monthselection = new Date()
      }
      var periodText = this.formatDateForEmail(this.monthselection)
      const swalResult = await swal.fire({
        title: 'Resend Email',
        html: 'Are you sure you want to <strong>resend</strong> the usage emails for the period <strong>' + periodText + '</strong>?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (swalResult.isConfirmed) {
        var data = {
          monthselection: this.monthselection
        }
        try {
          var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauusagereport/ResendEmail', data, { showload: true })
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$snapbar.e(`Could not resend the email: ${e.message}`)
        }
      }
      await this.load()
    },
    formatDateForEmail (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      return `${this.pad(monthIndex, 2)}/${year}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  }
}
</script>
<style scoped>
th {
  white-space: nowrap;
  border: none !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

th.rotate {
  /* Something you can count on */
  height: 130px;
}

th.rotate > div {
  transform:
    /* Magic Numbers */ translate(0px, 0px)
    /* 45 is really 360 - 45 */ rotate(315deg);
  width: 30px;
}

.small-text {
  height: 24px;
}

.scrollable {
  overflow: auto;
}
</style>
