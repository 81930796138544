var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value.$error
    ? _c(
        "span",
        { staticClass: "validation-messages" },
        [
          _vm._t("override", [
            _vm.value.maxLength != undefined && !_vm.value.maxLength
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " +
                      _vm._s(_vm.aOrAn) +
                      " " +
                      _vm._s(_vm.name) +
                      " shorter than or equal to " +
                      _vm._s(_vm.value.$params.maxLength.max) +
                      " characters"
                  )
                ])
              : _vm._e(),
            _vm.value.minLength != undefined && !_vm.value.minLength
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " +
                      _vm._s(_vm.aOrAn) +
                      " " +
                      _vm._s(_vm.name) +
                      " longer than or equal to " +
                      _vm._s(_vm.value.$params.minLength.min) +
                      " characters"
                  )
                ])
              : _vm._e(),
            _vm.value.exactLength != undefined && !_vm.value.exactLength
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " +
                      _vm._s(_vm.aOrAn) +
                      " " +
                      _vm._s(_vm.name) +
                      " equal to " +
                      _vm._s(_vm.value.$params.exactLength.exact) +
                      " characters"
                  )
                ])
              : _vm._e(),
            _vm.value.maxValue != undefined && !_vm.value.maxValue
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " +
                      _vm._s(_vm.aOrAn) +
                      " " +
                      _vm._s(_vm.name) +
                      " less than or equal to " +
                      _vm._s(_vm.value.$params.maxValue.max)
                  )
                ])
              : _vm._e(),
            _vm.value.minValue != undefined && !_vm.value.minValue
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " +
                      _vm._s(_vm.aOrAn) +
                      " " +
                      _vm._s(_vm.name) +
                      " greater than or equal to " +
                      _vm._s(_vm.value.$params.minValue.min)
                  )
                ])
              : _vm._e(),
            _vm.value.required != undefined && !_vm.value.required
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " + _vm._s(_vm.aOrAn) + " " + _vm._s(_vm.name)
                  )
                ])
              : _vm._e(),
            _vm.value.select != undefined && !_vm.value.select
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please select " +
                      _vm._s(_vm.aOrAn) +
                      " " +
                      _vm._s(_vm.name)
                  )
                ])
              : _vm._e(),
            _vm.value.numeric != undefined && !_vm.value.numeric
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(_vm._s(_vm.name) + " can only be numeric")
                ])
              : _vm._e(),
            _vm.value.url != undefined && !_vm.value.url
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " +
                      _vm._s(_vm.aOrAn) +
                      " valid web address that starts with http or https"
                  )
                ])
              : _vm._e(),
            _vm.value.email != undefined && !_vm.value.email
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " + _vm._s(_vm.aOrAn) + " valid email address"
                  )
                ])
              : _vm._e(),
            _vm.value.ipAddress != undefined && !_vm.value.ipAddress
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " + _vm._s(_vm.aOrAn) + " valid IP address"
                  )
                ])
              : _vm._e(),
            _vm.value.int32 != undefined && !_vm.value.int32
              ? _c("small", { staticClass: "form-text small" }, [
                  _vm._v(
                    "Please enter " +
                      _vm._s(_vm.aOrAn) +
                      " number smaller than 2147483648"
                  )
                ])
              : _vm._e()
          ]),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }