<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div class="row">
                <div class="col-md-1">Path</div>
                <div class="col-md-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectedFolder">
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="idb-block">
                    <div class="idb-block-content">
                      <div class="row">
                        <div class="col-md-5">
                          <v-jstree
                            :data="treeData"
                            multiple allow-batch whole-row
                            @item-click="itemClick">
                          </v-jstree>
                        </div>
                        <div class="col-md-7">
                          <!-- <ul class="list-group"> -->
                          <draggable v-model="myArray" :options="{group:'people'}" @start="drag=true" @end="drag=false"  >
                            <div v-for="(element, index) in myArray" :key="index"  class="clickable" v-on:click="selectItem(element)" v-on:dblclick="selectItemAndClose(element)" >
                              <div class="list-group-item">
                                <div class="d-flex justify-content-start">
                                  <div class="d-flex align-items-start flex-column mr-20">
                                    <i v-if = "element.isFolder" class="fa fa-folder fa-4x folder"></i>
                                    <i v-else class="fa fa-file fa-4x file"></i>
                                  </div>
                                  <div class="d-flex align-items-start flex-column pt-10">
                                    <h6 class="font-lg">{{element.text}}</h6>
                                    <p class="mb-0">{{element.description}}</p>
                                    <div v-if = "element.isFolder" class="small fw-bold"></div>
                                    <div v-if = "!element.isFolder" class="small fw-bold"><strong>Size:</strong> {{bytesToSize(element.stat.size)}}</div>
                                    <div v-if = "!element.isFolder" class="small fw-bold"><strong>Last Modified:</strong> {{reformatDate(element.stat.mtime)}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </draggable>
                          <!-- </ul> -->
                        </div>
                        <!-- <div class="col-md-3">
                          Info
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="warning" @click="cancelPopup">
                Cancel
              </b-button>
              <b-button variant="success"
                @click="closePopup">
                <span class="fa fa-times" aria-hidden="true"></span>
                OK
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'
import VJstree from 'vue-jstree'
import draggable from 'vuedraggable'
export default {
  name: 'Popup',
  components: {
    VJstree,
    draggable
  },
  // props: ['schema', 'value'],
  props: ['property', 'defaultFolder', 'filesOnly'],
  data () {
    return {
      treeData: [],
      selectedId: null,
      selectedFolder: '',
      selectedFile: '',
      myArray: [
      ]
    }
  },
  methods: {
    closePopup () {
      console.log('Closing popup - saving state')
      this.$store.commit('setModalPopupString', this.selectedFolder)
      this.$emit('close', this.selectedFolder)
    },
    selectItem (e) {
      if (e.isFolder && this.filesOnly) {
        this.getFolderData(e.path)
      }
      // Single Click
      this.selectedFolder = e.path
      console.log(e)
    },
    selectItemAndClose (e) {
      // Double click
      if (e.isFolder && !this.filesOnly) {
        this.selectedFolder = e.path
        console.log(e)
        this.closePopup()
      } else if (e.isFolder && this.filesOnly) {
        this.getFolderData(e.path)
      }
      if (!e.isFolder && this.filesOnly) {
        this.selectedFolder = e.path
        this.closePopup()
      }
    },
    async loadFolderTree () {
      console.log('load')
      try {
        let res = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}foldertree`, { folder: this.defaultFolder })
        // let res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}folderStructure/folders`)
        console.log('folderStructure response A')
        console.log(res.data.response)
        if (res.data.response) {
          if (Array.isArray(res.data.response)) {
            this.treeData = res.data.response
          } else {
            console.log('not an array')
          }
        } else {
          console.log('no response')
        }
      } catch (e) {
        this.$swal('Error.', e.message, 'error')
      }
    },
    async getFolderData (folder) {
      let postData = {
        folder
      }
      console.log(postData)
      // let res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderContents`, postData)
      let res = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}foldertree/contents`, { folder })
      console.log('Response from FolderContents:')
      console.dir(res.data.response)
      this.myArray = res.data.response
    },
    itemClick (node) {
      console.log(node.model.path + ' clicked !')
      this.selectedFolder = node.model.path
      this.getFolderData(node.model.path)
    },
    fileClick (e) {
      console.log('clicked')
      console.log(e)
    },
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 bytes'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]}`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },
    reformatDate (d) {
      console.log(d)
      let myDate = new Date(d)
      let dd = myDate.getDate()
      let mm = myDate.getMonth() + 1
      let yyyy = myDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      let h = myDate.getHours()
      let m = myDate.getMinutes()
      let s = myDate.getSeconds()
      let ms = this.pad(myDate.getMilliseconds(), 3, '0')
      h = this.checkTime(h)
      m = this.checkTime(m)
      s = this.checkTime(s)
      let reformatted = dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s + '.' + ms
      return reformatted
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    pad (n, width, z) {
      z = z || '0'
      n += ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    cancelPopup () {
      this.$emit('cancel')
    }
  },
  created: function () {
    this.loadFolderTree()
    this.selectedFolder = this.defaultFolder
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  max-height: 90vh;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter, .modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
  .folder {
    color: #FFE89F;
    text-shadow: 1px 1px 1px #ccc;
  }
  .treeFolder {
    color: #FFCC43;
  }
  .file {
      color: #cccccc;
      text-shadow: 1px 1px 1px #ccc;
  }
  .clickable {
    cursor: pointer;
    border-style: none;
  }
  .clickable div:hover {
    background: #efefef;
  }
  .list-group-item {
    border-style: none;
  }
</style>
