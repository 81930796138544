var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.roles.length > 0
      ? _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              _vm._l(_vm.roles, function(role, index) {
                return _c("div", { key: role.name }, [
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      { staticClass: "col-3 offset-1 col-form-label" },
                      [_vm._v("Role Name")]
                    ),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "form-control-plaintext" }, [
                        _c("span", [_vm._v(_vm._s(role.name))])
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _vm._m(1, true),
                      _vm._l(role.users, function(user) {
                        return _c("div", { key: user }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-3 offset-1" }, [
                              _c(
                                "div",
                                { staticClass: "form-control-plaintext" },
                                [_c("span", [_vm._v(_vm._s(user))])]
                              )
                            ])
                          ])
                        ])
                      }),
                      role.users.length === 0
                        ? _c("div", [_vm._m(2, true)])
                        : _vm._e()
                    ],
                    2
                  ),
                  index !== _vm.roles.length - 1 ? _c("hr") : _vm._e()
                ])
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Roles")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-12 offset-1 form-label" }, [
        _vm._v("Users in roles")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3 offset-1" }, [
        _c("div", { staticClass: "form-control-plaintext" }, [
          _c("span", [_vm._v("No users in role")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }