<template>
  <b-modal v-model="value" centered size="reportModal" @hide="close">
    <div v-if="reportFormat === 1" class="row form-group">
      <div class="col-md-12">
        <span v-html="reportData"></span>
      </div>
    </div>
    <div v-else class="row form-group">
      <div class="col-md-12">
        <b-form-textarea v-model="reportData" :rows="20"></b-form-textarea>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'viewReportModal',

  props: {
    value: Boolean,
    reportData: { type: String, default: '' },
    reportFormat: { type: Number, default: 0 }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style>
  .modal .modal-reportModal {
    max-width: 95%;
  }
  .primary_line {
    LINE-HEIGHT: 1px; BACKGROUND-COLOR: #993300
  }
  th.voca {
    FONT-WEIGHT: bold; FONT-SIZE: 8pt; LINE-HEIGHT: 20px; BACKGROUND-COLOR: #f5e0d6; TEXT-ALIGN: left
  }
  tr.row1 {
    HEIGHT: 20px; BACKGROUND-COLOR: #ffffff
  }
  tr.row2 {
    HEIGHT: 20px; BACKGROUND-COLOR: #faf5f5
  }
</style>
