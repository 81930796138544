var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer__navigation__page-info" }, [
    _vm.infoFn
      ? _c("div", [_vm._v(" " + _vm._s(_vm.infoFn(_vm.infoParams)) + " ")])
      : _vm.mode === "pages"
      ? _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "label",
              { staticClass: "page-info__label", attrs: { for: _vm.id } },
              [
                _c("span", [_vm._v(_vm._s(_vm.pageText))]),
                _c("input", {
                  staticClass: "footer__navigation__page-info__current-entry",
                  attrs: {
                    id: _vm.id,
                    "aria-describedby": "change-page-hint",
                    "aria-controls": "vgb-table",
                    type: "text"
                  },
                  domProps: { value: _vm.currentPage },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      return _vm.changePage($event)
                    }
                  }
                }),
                _c("span", [_vm._v(_vm._s(_vm.pageInfo))])
              ]
            ),
            _c(
              "span",
              {
                staticStyle: { display: "none" },
                attrs: { id: "change-page-hint" }
              },
              [
                _vm._v(
                  " Type a page number and press Enter to change the page. "
                )
              ]
            )
          ]
        )
      : _c("div", [_vm._v(" " + _vm._s(_vm.recordInfo) + " ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }