import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  name: 'LookAheadBarChart',
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  data: function () {
    return {}
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData () {
      this.$data._chart.update()
    }
  }
}
