var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", [
    _c("div", { staticClass: "idb-block" }, [
      _c("div", { staticClass: "idb-block-title" }, [
        _c(
          "h2",
          [
            _c("help-icon", {
              attrs: { docPath: "/paygate-collections/plans/plandesigner/" }
            }),
            _vm._v("Plan Designer"),
            _c("favourite-icon")
          ],
          1
        )
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "m-3" },
          [
            _c("div", { staticClass: "row form-group" }, [
              _c("div", { staticClass: "col-md-2 required" }, [
                _vm._v(" Select a Group ")
              ]),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("group-select", {
                    attrs: { groups: _vm.customerGroups, clearable: false },
                    on: { input: _vm.customerGroupsChanged },
                    model: {
                      value: _vm.$v.group.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.group, "$model", $$v)
                      },
                      expression: "$v.group.$model"
                    }
                  }),
                  _c("validation-messages", {
                    attrs: { name: "Group" },
                    model: {
                      value: _vm.$v.group,
                      callback: function($$v) {
                        _vm.$set(_vm.$v, "group", $$v)
                      },
                      expression: "$v.group"
                    }
                  })
                ],
                1
              )
            ]),
            _c("b-row", { staticClass: "planRow" }, [
              _c("div", { staticClass: "tabs row" }, [
                _c(
                  "div",
                  { staticClass: "col-auto col-3 vertical-tab-container" },
                  [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "nav nav-tabs flex-column w-90 ml-2 vertical-tab-list",
                        attrs: { role: "tablist" }
                      },
                      [
                        _vm.groupPlans === undefined ||
                        _vm.groupPlans === null ||
                        _vm.groupPlans.length === 0
                          ? _c(
                              "li",
                              {
                                staticClass: "nav-item",
                                attrs: { role: "presentation" }
                              },
                              [
                                _vm._v(
                                  " There are no plan templates associated with this group. "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._l(_vm.groupPlans, function(paymentPlan, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "nav-item",
                              attrs: { role: "presentation" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  class:
                                    _vm.activeTabIndex === index
                                      ? "active"
                                      : null,
                                  attrs: { role: "tab" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updatePlanInput(index)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(paymentPlan.name))]
                              )
                            ]
                          )
                        }),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "btn mt-4 btn-primary",
                              attrs: {
                                type: "button",
                                disabled: _vm.isLoading
                              },
                              on: { click: _vm.addNewPaymentPlan }
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus rpad" }),
                              _vm._v("Add new plan template")
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "tab-content col" },
                  [
                    _vm.activePlanValid
                      ? _c("payment-plan-input", {
                          ref: "planInput",
                          attrs: {
                            plan: _vm.activePlan.plan,
                            templateView: true,
                            insightFeedback: true,
                            sharedTemplate:
                              _vm.group ===
                              "00000000-0000-0000-0000-000000000000"
                          },
                          on: { isinvalid: _vm.setValidity }
                        })
                      : _vm._e(),
                    _vm.activePlanValid
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "float-right ml-1",
                            attrs: {
                              variant: "danger",
                              disabled: _vm.isLoading
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deletePlan($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-trash",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(" Delete ")
                          ]
                        )
                      : _vm._e(),
                    _vm.activePlanValid
                      ? _c(
                          "b-btn",
                          {
                            attrs: {
                              variant: "primary",
                              disabled:
                                (_vm.activePlanValid &&
                                  !_vm.activePlan.plan.valid) ||
                                _vm.isLoading
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.savePlan($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-save",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(" Save ")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "idb-block-footer" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }