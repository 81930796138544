var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", size: "reportModal" },
      on: { hide: _vm.close },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _vm.reportFormat === 1
        ? _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.reportData) } })
            ])
          ])
        : _c("div", { staticClass: "row form-group" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("b-form-textarea", {
                  attrs: { rows: 20 },
                  model: {
                    value: _vm.reportData,
                    callback: function($$v) {
                      _vm.reportData = $$v
                    },
                    expression: "reportData"
                  }
                })
              ],
              1
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }