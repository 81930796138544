var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "tabs" } },
    [
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { title: "First", active: "" } }, [
            _c("p", [
              _vm._v(" Basic tabs example using "),
              _c("code", [_vm._v(".nav-tabs")]),
              _vm._v(" class. Also requires base "),
              _c("code", [_vm._v(".nav")]),
              _vm._v(" class. ")
            ])
          ]),
          _c("b-tab", { attrs: { title: "Second" } }, [
            _c("p", [
              _vm._v(
                "This style Should Apply same for all the possible variations of this sections."
              )
            ])
          ]),
          _c("b-tab", { attrs: { title: "disabled", disabled: "" } }, [
            _c("p", [
              _vm._v(" Basic tabs example using "),
              _c("code", [_vm._v(".nav-tabs")]),
              _vm._v(" class. Also requires base "),
              _c("code", [_vm._v(".nav")]),
              _vm._v(" class.")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }