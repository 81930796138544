var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        visibility: "visible",
        height: "100px",
        margin: "10px 0",
        "text-align": "center"
      }
    },
    [
      _c("small", [
        _vm._v(
          "This report was generated by paygate on " +
            _vm._s(new Date().toLocaleString())
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }