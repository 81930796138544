<template>
  <div id="manualBacsSubmissionBuilder">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="idb-block">
          <div class="idb-block-title">
            <h2>
              Manual FPS Bureau Submission Builder
              <help-icon :docPath="'/bacsbureau/builder/'"></help-icon>
              <favourite-icon></favourite-icon>
            </h2>
          </div>

          <!-- Group Selection -->
          <div class="idb-block-content">
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <strong>Select a Submission Group</strong>
              </div>
              <div class="col-sm-6">
                <group-select
                  v-model="SelectedGroupId"
                  :groups="Groups"
                  :clearable="false"
                  :change="ChangeGroup(SelectedGroupId)"
                ></group-select>
              </div>
            </div>

            <!-- Submission Detail Selection -->
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label class="required" for="submissionReference">Submission Reference</label>
              </div>
              <div class="col-md-6">
                <b-form-input type="text" v-model.trim="$v.submissionReference.$model"></b-form-input>
              </div>
              <div class="col-md-3" offset-sm="3" v-if="$v.submissionReference.$dirty">
                <label
                  class="text-danger small"
                  v-if="!$v.submissionReference.required"
                >A 'Submission Reference' is required</label>
                <label
                  class="text-danger small"
                  v-if="!$v.submissionReference.maxLength"
                >A 'Submission Reference' must be less than {{$v.submissionReference.$params.maxLength.max}} characters</label>
              </div>
            </div>
            <div class="row form-group col-md-12">
              <div class="col-md-2">
                <label for="contraNarrative">Contra Narrative</label>
              </div>
              <div class="col-md-6">
                <b-form-input type="text" v-model.trim="$v.contraNarrative.$model"></b-form-input>
              </div>
              <div class="col-md-3" offset-sm="3" v-if="$v.contraNarrative.$dirty">
                <label
                  class="text-danger small"
                  v-if="!$v.contraNarrative.maxLength"
                >A 'Contra Narrative' must be less than {{$v.contraNarrative.$params.maxLength.max}} characters</label>
              </div>
            </div>
          </div>
          <div class="idb-block-footer">
            <button
              class="btn btn-primary mr-2"
              :disabled="$v.$invalid"
              @click="onCreateClick"
            >Create Submission</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import bacsMixin from '@/Lib/BacsMixin.js'
import loading from '@/Assets/Mixins/LoadingMixin'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  mixins: [Utility, tableFilterMixin, bacsMixin, loading],

  data () {
    return {
      SelectedGroupId: '',
      Groups: [],
      GroupDisplay: [],
      BUREAUGROUPTYPE: '1',
      paygateId: '',
      submissionReference: '',
      contraNarrative: ''
    }
  },

  async created () {
    await this.load()
  },

  methods: {
    load: _.debounce(async function () {
      this.paygateId = this.$store.state.common.paygateId
      await this.loadGroups()
    }, 800),

    async loadGroups () {
      var payload = { paygateId: this.paygateId, groupType: this.BUREAUGROUPTYPE, groupRole: ['GroupAdministrator'] }
      await this.$store.dispatch('getGroups', payload)
      this.Groups = this.$store.getters.groups
    },

    ChangeGroup (SelectedGroupId) {
      // Needed?
    },

    async onCreateClick () {
      try {
        var params = {
          groupId: this.SelectedGroupId,
          submissionReference: this.submissionReference,
          contraNarrative: this.contraNarrative
        }
        var response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauSubmission/createmanualfpsbuildersubmission', params, { showload: true })

        if (response.data.toastType === 2) {
          this.$toastr.s(response.data.toastMessage)
          var bureauSubmissionId = response.data.id

          this.$router.push({ name: 'ManualFPSSubmissionAddFiles', params: { id: bureauSubmissionId } })
        } else {
          this.$toastr.e(response.data.toastMessage)
        }
      } catch (e) {
           this.$toastr.e('Failed to create manual bacs submission', 'Bureau Submission')
      } finally {
      }
    }
  },
  validations: {
    submissionReference: {
      required,
      maxLength: maxLength(150)
    },
    contraNarrative: {
      maxLength: maxLength(50)
    }
  }
}
</script>
