var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idb-block" }, [
    _c("div", { staticClass: "idb-block-title" }, [
      _c(
        "h2",
        [
          _c("help-icon", {
            attrs: {
              docPath: "/paygate-collections/messaging/messagetemplates/"
            }
          }),
          _vm._v("SMS Designer")
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "idb-block-content" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c(
                  "b-form-group",
                  [
                    _c("label", { attrs: { for: "smsTemplateName" } }, [
                      _c("strong", [_vm._v("Template Name")])
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        id: "smsTemplateName",
                        type: "text",
                        autocomplete: "off",
                        disabled: _vm.loading,
                        state: _vm.templateNameStateValid,
                        "aria-describedby":
                          "smsTemplateNameHelp smsTemplateNameFeedback",
                        placeholder: "SMS Template Name",
                        maxlength: "50"
                      },
                      model: {
                        value: _vm.templateName,
                        callback: function($$v) {
                          _vm.templateName = $$v
                        },
                        expression: "templateName"
                      }
                    }),
                    !_vm.templateNameStateValid
                      ? _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "smsTemplateNameFeedback" } },
                          [
                            _vm._v(
                              " The template name may only have characters of the following types: "
                            ),
                            _c("ul", [
                              _c("li", [_vm._v("Upper-case letters A-Z")]),
                              _c("li", [_vm._v("Lower-case letters a-z")]),
                              _c("li", [_vm._v("Numbers 0-9")]),
                              _c("li", [
                                _vm._v('Spaces, underscores "_" or dashes "-"')
                              ])
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-form-text",
                      { attrs: { id: "smsTemplateNameHelp" } },
                      [_vm._v("The name of the template in paygate.")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("br"),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12", md: "4", id: "smsEditor" } },
              [
                _c(
                  "b-form-group",
                  [
                    _c("label", { attrs: { for: "smsSenderName" } }, [
                      _vm._v("Sender Name")
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        id: "smsSenderName",
                        type: "text",
                        autocomplete: "off",
                        state: _vm.senderNameStateValid,
                        "aria-describedby":
                          "smsSenderNameHelp smsSenderNameFeedback",
                        placeholder: "paygate",
                        maxlength: "11"
                      },
                      model: {
                        value: _vm.senderName,
                        callback: function($$v) {
                          _vm.senderName = $$v
                        },
                        expression: "senderName"
                      }
                    }),
                    !_vm.senderNameStateValid
                      ? _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "smsSenderNameFeedback" } },
                          [
                            _vm._v(
                              " The sender name must be between 3 and 11 characters in length, and of the following types: "
                            ),
                            _c("ul", [
                              _c("li", [_vm._v("Upper-case letters A-Z")]),
                              _c("li", [_vm._v("Lower-case letters a-z")]),
                              _c("li", [_vm._v("Numbers 0-9")]),
                              _c("li", [
                                _vm._v(
                                  "At least one of these characters must be a letter."
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c("b-form-text", { attrs: { id: "smsSenderNameHelp" } }, [
                      _vm._v(
                        "The name seen by the SMS recipient (max 11 characters)."
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12", md: "5" } },
              [
                _c(
                  "b-form-group",
                  [
                    _c("label", { attrs: { for: "smsMessageBody" } }, [
                      _vm._v("Message")
                    ]),
                    _c("b-form-textarea", {
                      attrs: {
                        id: "smsMessageBody",
                        maxlength: "140",
                        placeholder: "SMS message body"
                      },
                      on: {
                        input: function($event) {
                          _vm.previewLoading = true
                          _vm.previewText = _vm.messageBody
                          _vm.updatePreview()
                        }
                      },
                      model: {
                        value: _vm.messageBody,
                        callback: function($$v) {
                          _vm.messageBody = $$v
                        },
                        expression: "messageBody"
                      }
                    }),
                    _c("b-form-text", { attrs: { id: "smsMessageBodyHelp" } }, [
                      _vm._v(
                        "The message seen by the SMS recipient (max 140 characters)."
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-col",
              {
                attrs: {
                  "offset-md": "1",
                  md: "5",
                  cols: "12",
                  id: "smsPreview"
                }
              },
              [
                _c("h3", [_vm._v("Preview")]),
                _c("div", { staticClass: "inline-flex" }, [
                  _c("label", { staticClass: "speech-bubble-label" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.senderNamePreview))])
                  ]),
                  _c("div", { staticClass: "speech-bubble" }, [
                    !_vm.previewLoading
                      ? _c("div", {
                          staticClass: "smsPreviewText",
                          domProps: {
                            innerHTML: _vm._s(_vm.previewTextPreview)
                          }
                        })
                      : _vm._e(),
                    _vm.previewLoading
                      ? _c("div", {
                          staticClass: "smsPreviewTextLoading",
                          domProps: {
                            innerHTML: _vm._s(_vm.previewTextPreview)
                          }
                        })
                      : _vm._e()
                  ])
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "idb-block-footer" },
      [
        _c(
          "b-btn",
          {
            attrs: {
              variant: "primary",
              disabled:
                _vm.isLoading ||
                !_vm.senderNameStateValid ||
                _vm.senderName === ""
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.saveClick($event)
              }
            }
          },
          [_vm._v("Save")]
        ),
        _vm._v(" "),
        _vm.templateid !== null
          ? _c(
              "router-link",
              {
                staticClass: "btn btn-outline-secondary",
                attrs: { to: "{ name: 'SmsDesigner' }" }
              },
              [_vm._v("New Template")]
            )
          : _vm._e(),
        _vm.templateid === null
          ? _c(
              "b-btn",
              {
                attrs: {
                  variant: "outline-secondary",
                  disabled: _vm.isLoading
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearTemplate($event)
                  }
                }
              },
              [_vm._v("New Template")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }