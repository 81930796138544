var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "importingPayments" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.browserBackButtonClicked,
                expression: "!browserBackButtonClicked"
              }
            ],
            staticClass: "idb-block"
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("div", { staticClass: "row form-group" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-progress",
                      {
                        staticClass: "mb-3",
                        attrs: {
                          striped: "",
                          animated: true,
                          max: _vm.max,
                          "show-progress": ""
                        }
                      },
                      [
                        _c("b-progress-bar", {
                          attrs: {
                            value: _vm.progress,
                            label: _vm.progress + "%"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h3", [_vm._v("Importing payments, please wait...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }