var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "manageSchedule" } },
    [
      _c(
        "b-row",
        { attrs: { mb: "4" } },
        [
          _c("b-col", { attrs: { xs: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "idb-block" }, [
              _c("div", { staticClass: "idb-block-title" }, [
                _c(
                  "h2",
                  [
                    _c("help-icon", {
                      attrs: { docPath: "/paygate-collections/submissions/" }
                    }),
                    _vm._v("Process Submissions"),
                    _c("favourite-icon")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "idb-block-content" },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "vue-good-table",
                        {
                          ref: "table",
                          attrs: {
                            mode: "remote",
                            columns: _vm.columns,
                            rows: _vm.submissions,
                            lineNumbers: true,
                            totalRows: _vm.totalRecords,
                            "search-options": {
                              enabled: true
                            },
                            paginationOptions: _vm.paginationOptions,
                            "sort-options": _vm.sortOptions,
                            isLoading: _vm.isTableLoading,
                            styleClass: "vgt-table striped bordered"
                          },
                          on: {
                            "on-page-change": _vm.onPageChange,
                            "on-sort-change": _vm.onSortChange,
                            "on-column-filter": _vm.onColumnFilter,
                            "on-per-page-change": _vm.onPerPageChange,
                            "on-search": _vm.onSearch,
                            "update:isLoading": function($event) {
                              _vm.isTableLoading = $event
                            },
                            "update:is-loading": function($event) {
                              _vm.isTableLoading = $event
                            },
                            "on-row-click": _vm.onRowClick,
                            "on-cell-click": _vm.handleClick
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field == "viewBtn"
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              color: "blue"
                                            }
                                          },
                                          [_vm._v("View")]
                                        )
                                      ])
                                    : props.column.field == "processBtn"
                                    ? _c(
                                        "span",
                                        [
                                          props.row.submissionType ==
                                            "Collections" &&
                                          props.row.flaggedPaymentsCount > 0
                                            ? _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "fa fa-exclamation-circle text-danger",
                                                attrs: {
                                                  title:
                                                    props.row
                                                      .flaggedPaymentsCount +
                                                    " payment(s) flagged for attention!"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "ProcessCollection",
                                                  params: {
                                                    groupId: props.row.groupId,
                                                    mode:
                                                      props.row
                                                        .submissionType ===
                                                      "Collections"
                                                        ? "collection"
                                                        : "auddis"
                                                  }
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Process Submission")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.formattedRow[
                                                props.column.field
                                              ]
                                            ) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("template", { slot: "loadingContent" }, [
                            _c("h1", [_vm._v("Loading...")])
                          ]),
                          _c(
                            "div",
                            {
                              attrs: { slot: "emptystate" },
                              slot: "emptystate"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vgt-center-align vgt-text-disabled"
                                },
                                [_vm._v(" No Submissions Available ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "table-actions" },
                              slot: "table-actions"
                            },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Clear filters",
                                      expression: "'Clear filters'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  staticClass: "btn btn-link",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.clearTableFilters($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fa-stack",
                                      staticStyle: { "font-size": "10px" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-filter fa-stack-1x dimmedIcon"
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "fa fa-ban fa-stack-2x dimmedIcon"
                                      })
                                    ]
                                  ),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Clear filters")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Refresh the data in the table",
                                      expression:
                                        "'Refresh the data in the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    disabled: _vm.isLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.load($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-sync pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Refresh Table")
                                  ])
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top.d500",
                                      value: "Export the contents of the table",
                                      expression:
                                        "'Export the contents of the table'",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d500: true
                                      }
                                    }
                                  ],
                                  attrs: {
                                    variant: "link",
                                    disabled: _vm.isLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.exportTable($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-share-square pointer dimmedIcon"
                                  }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Export Table")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }