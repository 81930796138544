<template>
  <div>
    <div v-if="reportDownload.downloadFormat === 1" style="width: 98%; margin:0 auto;">
      <span v-html="reportData"></span>
    </div>
    <div v-else style="width: 98%; margin:0 auto;">
      <b-form-textarea v-model="reportData" :rows="20"></b-form-textarea>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  // props: {
  //   rnetworkType: '',
  //   rdownloadUrl: '',
  //   rdownloadFormat: '0',
  //   rauthResponse: Object
  // },

  data () {
    return {
      reportDownload: {},
      reportData: ''
    }
  },

  methods: {
  },

  async mounted () {
    this.loading = true

    this.reportDownload = this.$store.getters.reportDownload

    // TEMP HACK
    this.reportDownload.downloadFormat = 1

    console.log('reportDownload: ' + JSON.stringify(this.reportDownload))

    var json = JSON.stringify({
      networkType: this.reportDownload.networkType,
      downloadUrl: this.reportDownload.downloadUrl,
      downloadFormat: this.reportDownload.downloadFormat,
      authResponse: this.reportDownload.authResponse
    })

    var response = await axios({
      method: 'POST',
      url: 'bacs/report/viewReport',
      data: json,
      showload: true
    })

    this.loading = false
    this.reportData = response.data
  }
}
</script>
