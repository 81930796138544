<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h5 class="unpad">Workflow Load:
        <span class="small"><strong>{{workflow.title}} (</strong></span>
        <router-link tag="a" :to="'/automation/workflow/workflowdetail/' + workflowId" class="small">View</router-link>
        <span>)</span>
        <span class="validationText">
          {{errorMessage}}
        </span>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <load-chart :height="375" frequency="minute" title="Concurrent Workflows" :workflowid="workflowId"   />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import VueChartJs from 'vue-chartjs'

const options = {
  responsive: true,
  maintainAspectRatio: false
}

Vue.component('load-chart', {
  extends: VueChartJs.Line,
  props: {
    frequency: String,
    title: String,
    workflowid: String
  },
  data () {
    return {
      labels: [],
      workflow: {},
      chartData: [],
      selectedLoadFrequency: 'Daily'
    }
  },
  methods: {
    async getChartData () {
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflowload?frequency=${this.frequency}&workflowid=${this.workflowid}`)
        if (res && res.data) {
          this.labels = res.data.map(x => x._id)
          this.chartData = res.data.map(x => x.count)
        }
      } catch (e) {
        this.errorMessage = 'Could not get workflow load data.'
      }
      let backgroundColor = 'rgba(65, 102, 137, 0.15)'
      let borderColor = 'rgba(65, 102, 137, .5)'
      if (this.$store.getters.getClaim('theme').value === 'dark') {
        backgroundColor = 'rgba(65, 102, 137, 0.9)'
        borderColor = 'rgba(65, 102, 137, 1)'
      }
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            label: this.title,
            data: this.chartData,
            borderWidth: 1,
            steppedLine: 'middle',
            backgroundColor,
            borderColor
          }
        ]
      }, options)
    }
  },
  created: async function () {
    await this.getChartData()
  }
})

export default {
  components: {
  },
  name: 'WorkflowLoadWidget',
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      workflow: {},
      activityData: [],
      intervalId: null,
      errorMessage: ''
    }
  },
  created () {
  },
  mounted () {
    this.getWorkflow()
  },
  computed: {
    workflowId () {
      return this.widget.options.find(o => o.label === 'Workflow').value
    }
  },
  methods: {
    async getWorkflow () {
      console.log('this.workflowId ', this.workflowId)
      axios
        .get(`${process.env.VUE_APP_WORKFLOW_API_URL}workflow/${this.workflowId}`)
        .then(res => {
          this.workflow = res.data.workflow
          console.log(this.workflow.title)
        })
        .catch(e => {
          console.log('error: ' + e.message)
        })
    }
  }
}
</script>
