var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "myId" } }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "idb-block" }, [
          _vm._m(0),
          _c("div", { staticClass: "idb-block-content" }, [
            _c(
              "button",
              { staticClass: "btn btn-danger", on: { click: _vm.alertDanger } },
              [_vm._v("Danger Alert")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                on: { click: _vm.alertWarning }
              },
              [_vm._v("Warning Alert")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: { click: _vm.alertSuccess }
              },
              [_vm._v("Success Alert")]
            ),
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.alertOther } },
              [_vm._v("Other Alert")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Sweet Alert")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }