var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "idb-block" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "idb-block-content" },
        [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass: "col-form-label col-md-2",
                attrs: { for: "includeHistoric" }
              },
              [_vm._v("Include Historic Events")]
            ),
            _c(
              "div",
              { staticClass: "col-md-5" },
              [
                _c("p-check", {
                  staticClass: "p-switch p-fill",
                  attrs: { labelId: "includeHistoric", color: "primary" },
                  on: { change: _vm.onHistoricChange },
                  model: {
                    value: _vm.serverParams.historic,
                    callback: function($$v) {
                      _vm.$set(_vm.serverParams, "historic", $$v)
                    },
                    expression: "serverParams.historic"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "vue-good-table",
            {
              ref: "table",
              attrs: {
                isLoading: _vm.isTableLoading,
                paginationOptions: _vm.paginationOptions,
                rows: _vm.rows,
                columns: _vm.columns,
                lineNumbers: true,
                "sort-options": _vm.sortOptions,
                styleClass: "vgt-table striped bordered"
              },
              on: {
                "update:isLoading": function($event) {
                  _vm.isTableLoading = $event
                },
                "update:is-loading": function($event) {
                  _vm.isTableLoading = $event
                },
                "on-row-click": _vm.onRowClick,
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function(props) {
                    return [
                      props.column.field == "enabled"
                        ? _c(
                            "span",
                            [
                              _c("tick", {
                                model: {
                                  value: props.row.enabled,
                                  callback: function($$v) {
                                    _vm.$set(props.row, "enabled", $$v)
                                  },
                                  expression: "props.row.enabled"
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { attrs: { slot: "table-actions" }, slot: "table-actions" },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Create Maintenance Window",
                          expression: "'Create Maintenance Window'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      attrs: { title: "New Maintenance Window" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.create($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus dimmedIcon pointer" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Create Maintenance Window")
                      ])
                    ]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Clear filters",
                          expression: "'Clear filters'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearTableFilters($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fa-stack",
                          staticStyle: { "font-size": "10px" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter fa-stack-1x dimmedIcon"
                          }),
                          _c("i", {
                            staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                          })
                        ]
                      ),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Clear filters")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "idb-block-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.create }
          },
          [
            _c("i", { staticClass: "fa fa-plus rpad" }),
            _vm._v("Create Maintenance Window ")
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Maintenance Windows")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }