import Store from '@/Store/index'
import roles from '@/Assets/Constants/roles'

export default [
	{
		menu_title: 'BACS Payments',
		menu_icon: 'ti-control-play',
		showMethod: async () => {
			return !Store.getters.isInRoles([roles.SystemUser])
		},
		child_routes: [{
			path: '/payments/bacspayments/createBacsSubFromFile',
			menu_title: 'Submission From File',
			title: 'BACS submission from file',
			active_recursion: 3
		},
		{
			path: '/payments/bacspayments/createBacsSubUsingEditor',
			menu_title: 'Submission Using Editor',
			title: 'BACS submission using editor',
			active_recursion: 3
		}],

	},
	{
		menu_title: 'Faster Payments',
		menu_icon: 'ti-control-forward',
		showMethod: async () => {
			return !Store.getters.isInRoles([roles.SystemUser])
		},
		child_routes: [{
			path: '/payments/fasterpayments/createFasterPaymentSubFromFile',
			menu_title: 'Submission From File',
			title: 'FPS submission from file',
			active_recursion: 3
		},
		{
			path: '/payments/fasterpayments/createFasterPaymentSubUsingEditor',
			menu_title: 'Submission Using Editor',
			title: 'FPS submission using editor',
			active_recursion: 3
		}]
	},
	{
		menu_title: 'Submissions in Progress',
		menu_icon: 'ti-save',
		showMethod: async () => {
			return !Store.getters.isInRoles([roles.SystemUser])
		},
		path: '/payments/bacspayments/submissionsInProgress',
		counterData: 'submissionsInProgressCount'
	},
	{
		menu_title: 'Reports',
		menu_icon: 'ti-receipt',
		showMethod: async () => {
			return !Store.getters.isInRoles([roles.SystemUser])
		},
		child_routes: [{
			path: '/payments/bacsreports/downloadReports?networkType=BacstelIp',
			menu_title: 'BACS Reports'
		},
		{
			path: '/payments/fpsreports/downloadReports?networkType=SecureIp',
			menu_title: 'Faster Payment Reports'
		}]
	},
	{
		menu_title: 'Search',
		menu_icon: 'ti-search',
		child_routes: [{
			path: '/payments/search/searchSubmissions',
			menu_title: 'Search Submissions'
		},
		{
			path: '/payments/search/searchPayments',
			menu_title: 'Search Payments'
		},
		{
			path: '/payments/search/searchSentSubmissions',
			menu_title: 'Search Sent Submissions',
			showMethod: async () => {
				return Store.getters.isInRole(roles.SystemUser)
			}
		}]
	},
	{
		menu_title: 'Unlock Submissions',
		menu_icon: 'ti-unlock',
		path: '/payments/bacsAdmin/unlockSubmissions',
		showMethod: async () => {
			return Store.getters.isInRole(roles.SystemUser)
		}
	}
]
