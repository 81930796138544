<template>
	<div>
		<form @submit.prevent="checkValidation() && saveAccountDetails()">
			<div class="row">
				<div class="col-md-12">
					<div class="idb-block">
						<div class="idb-block-title">
							<h2 id="title-of-block-check">
								Account Details
								<help-icon docPath="/common/accountsettings/" />
							</h2>
						</div>
						<div class="idb-block-content">
							<div class="alert alert-danger" v-if="anyErrors" role="alert">
								<span v-for="error in errors" v-bind:key="error">{{error}}</span>
							</div>
							<div class="row">
								<div class="col-lg-2">
									<div class="form-group">
										<div id="avatar-wrapper">
											<avatar class="avatar-image"></avatar>

											<a id="change-picture" @click="changeAvatar(true)">Change Avatar</a>
										</div>
										<my-upload
											field="img"
											@crop-success="cropSuccess"
											@crop-upload-success="cropUploadSuccess"
											@crop-upload-fail="cropUploadFail"
											:value.sync="show"
											@input="changeAvatar"
											:width="300"
											:height="300"
											:url="uploadURL"
											:headers="headers"
											img-format="png"
											langType="en"
										></my-upload>
									</div>
								</div>
								<div class="col-lg-9">
									<div class="form-group row">
										<label class="col-form-label col-md-3">Password</label>
										<div class="col-md-9">
											<button
												type="button"
												class="btn btn-outline-info"
												@click="changePassword"
												:disabled="isLoading"
											>Change Password</button>
										</div>
									</div>
									<div class="form-group row" :class="{invalid: $v.emailAddress.$error}">
										<label class="col-form-label col-md-3 required">Email</label>
										<div class="col-md-9">
											<input type="email" class="form-control" placeholder="Email" v-model="emailAddress" />
											<span v-if="$v.emailAddress.$dirty">
												<small
													class="form-text text-danger small"
													v-if="!$v.emailAddress.required"
												>An email address is required</small>
												<small
													class="form-text text-danger small"
													v-if="!$v.emailAddress.email"
												>A valid email address is required</small>
											</span>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-form-label col-md-3">Phone Number</label>
										<div class="col-md-9">
											<input type="tel" class="form-control" placeholder="Phone Number" v-model="phoneNumber" />
										</div>
									</div>
									<div class="form-group row">
										<label for="darkTheme" class="col-form-label col-md-3">Dark Theme</label>
										<div class="col-md-9">
											<p-check
												:label-id="'darkTheme'"
												name="check"
												class="p-switch"
												style="padding: 0.5rem 0;"
												color="default"
												v-model="darkTheme"
											></p-check>
										</div>
									</div>

									<div class="form-group row">
										<label class="col-form-label col-md-3">Default Group</label>
										<div class="col-md-9">
											<group-select v-model="defaultGroup" :groups="groups"></group-select>
											<small
												id="helpBlock"
												class="form-text-text-muted"
											>When used, this option automatically sets the selected group in various pages across the application</small>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-form-label col-md-3">Cookies</label>
										<div class="col-md-9">
											<button
												type="button"
												class="btn btn-outline-info"
												@click="changeCookies"
												:disabled="isLoading"
											>Change Cookie Settings</button>
										</div>
									</div>
									<div v-if="this.canChangeViewUsageSettings" class="form-group row">
										<label class="col-form-label col-md-3">View Usage Settings</label>
										<div class="col-md-9">
											<button
												type="button"
												class="btn btn-outline-info"
												@click="changeUsageReportSettings"
												:disabled="isLoading"
											>Change View Usage Settings</button>
										</div>
									</div>

									<div class="form-group row" v-if="ssoLicence">
										<label class="col-form-label col-md-3">Microsoft Account</label>
										<div class="col-md-9">
											<button
												v-if="!microsoftAdded"
												type="button"
												class="btn btn-outline-info"
												@click="addMicrosoftSignin"
												:disabled="isLoading || !ssoDomainSet"
											>Link Microsoft Account</button>
											<button
												v-else
												type="button"
												class="btn btn-outline-danger"
												@click="removeMicrosoftSignin"
												:disabled="isLoading || !ssoDomainSet"
											>Unlink Microsoft Account</button>
											<br />
											<small
												v-if="!ssoDomainSet"
												class="text-danger"
											>Unable to link Microsoft account, please contact your system administrator</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="authenticatorLicence || emailLicence || smsLicence">
				<div class="col-md-12">
					<div class="idb-block">
						<div class="idb-block-title">
							<h2 id="title-of-block-check">Two-Factor Authentication</h2>
						</div>
						<div class="idb-block-content">
							<div class="form-group row" v-if="emailLicence">
								<label class="col-form-label col-md-3">Email Confirmed</label>
								<div class="col-md-9">
									<p class="form-control-static text-success" v-if="emailConfirmed">
										<i class="fa fa-check" aria-hidden="true"></i> Email Confirmed
									</p>
									<div v-else>
										<p class="form-control-static text-warning">
											<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Email has not been confirmed!
										</p>
										<p class="form-control-static text-warning" v-show="hasEmailAndNotChanged">
											<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Please save your changes before confirming your new email
										</p>
										<button
											class="btn btn-warning"
											type="button"
											@click="verifyModal('Email')"
											:disabled="isLoading || hasEmailAndNotChanged"
										>Confirm Email</button>
									</div>
								</div>
							</div>

							<div class="form-group row" v-if="smsLicence">
								<label class="col-form-label col-md-3">Phone Number Confirmed</label>
								<div class="col-md-9">
									<p
										class="form-control-static text-success"
										v-if="phoneNumberConfirmed && !hasPhoneNumberAndNotChanged"
									>
										<i class="fa fa-check" aria-hidden="true"></i> Phone Number
									</p>
									<div v-else>
										<p class="form-control-static text-warning" v-show="saved.phoneNumber">
											<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Phone Number has not been confirmed!
										</p>
										<p class="form-control-static text-warning" v-show="hasPhoneNumberAndNotChanged">
											<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Please save your changes before confirming your new phone number
										</p>
										<p class="form-control-static text-warning" v-show="!saved.phoneNumber">
											<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Please enter a phone number and save your changes before confirming your phone number
										</p>
										<button
											class="btn btn-warning"
											type="button"
											@click="verifyModal('Phone')"
											:disabled="isLoading  || hasPhoneNumberAndNotChanged"
										>Confirm Phone Number</button>
									</div>
								</div>
							</div>

							<div class="form-group row" v-if="authenticatorLicence">
								<label class="col-form-label col-md-3">Authenticator Confirmed</label>
								<div class="col-md">
									<p class="form-control-static text-success" v-if="authenticatorConfirmed">
										<i class="fa fa-check" aria-hidden="true"></i> Authenticator Confirmed
										<button
											class="btn btn-sm btn-danger ml-2"
											type="button"
											@click="unConfirmAuthenticator"
										>Reset Authenticator</button>
									</p>
									<div v-else>
										<p class="form-control-static text-warning">
											<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Authenticator has not been confirmed!
										</p>
										<button
											class="btn btn-warning confirm"
											type="button"
											@click="verifyModal('Authenticator')"
											:disabled="isLoading"
										>Confirm Authenticator</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="idb-block">
						<div class="idb-block-footer">
							<button type="submit" class="btn btn-primary" :disabled="isLoading">Save Account Details</button>
							<button
								type="button"
								class="btn btn-danger pull-right"
								@click="cancel"
								:disabled="isLoading"
							>Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</form>
		<confirm-modal :type="modalDetails.type" v-on:submit="load"></confirm-modal>
	</div>
</template>
<script>

import axios from 'axios'
import EventBus from '@/Lib/eventBus'
import { required } from 'vuelidate/lib/validators'
import Confirm2FA from '@/Assets/Components/UserSettings/ChangeSettings/ConfirmModal'
import myUpload from '@/Assets/Components/UserSettings/ChangeSettings/AvatarUploader/AvatarUploader'
import loading from '@/Assets/Mixins/LoadingMixin'
import licence from '@/Assets/Constants/licence'
import { email } from '@/Assets/Validators'

export default {
	mixins: [loading],
	name: 'changeSettings',
	props: ['passwordChanged', 'valid', 'message'],
	components: {
		'confirm-modal': Confirm2FA,
		'my-upload': myUpload
	},
	computed: {
		anyErrors () {
			// some with this will return true if anything exists in JS
			return this.errors.some(x => x)
		},
		uploadURL () {
			return `${process.env.VUE_APP_PLATFORM_API_URL}User/UploadAvatar`
		},
		hasEmailAndNotChanged () {
			return this.emailAddress !== this.saved.emailAddress || !this.saved.emailAddress
		},
		hasPhoneNumberAndNotChanged () {
			return this.phoneNumber !== this.saved.phoneNumber || !this.saved.phoneNumber
		},
		canChangeViewUsageSettings () {
			// Don't want QA seeing this yet!
			return false // this.$store.getters.isInRole(roles.CustomerReports)
		},
		smsLicence () {
			return this.$store.getters.hasLicence(licence.other_sms) && this.$store.getters.hasLicence(licence.mfa) && this.$store.getters.hasLicence(licence.mfa_sms)
		},
		emailLicence () {
			return this.$store.getters.hasLicence(licence.mfa) && this.$store.getters.hasLicence(licence.mfa_email)
		},
		authenticatorLicence () {
			return this.$store.getters.hasLicence(licence.mfa) && this.$store.getters.hasLicence(licence.mfa_authenticator)
		},
		microsoftAdded () {
			return this.loginProviders.some(lp => lp === 'Microsoft')
		},
		ssoLicence () {
			return this.$store.getters.hasLicence(licence.other_sso)
		}
	},
	data () {
		return {
			errors: [],
			defaultGroup: '',
			providers: [],
			groups: null,
			emailAddress: '',
			phoneNumber: '',
			darkTheme: false,
			emailConfirmed: true,
			phoneNumberConfirmed: true,
			authenticatorConfirmed: true,
			loginProviders: [],
			modalDetails: {
				type: ''
			},
			headers: {},
			show: false,
			imgDataUrl: '',
			saved: {
				emailAddress: '',
				phoneNumber: ''
			},
			ssoDomainSet: true
		}
	},
	beforeRouteLeave (to, from, next) {
		EventBus.$emit('darkMode')
		next()
	},
	methods: {
		async saveAccountDetails () {
			try {
				var requestData = { Email: this.emailAddress, PhoneNumber: this.phoneNumber, DefaultProvider: this.defaultProvider, DefaultGroup: this.defaultGroup, DarkMode: this.darkTheme }

				var response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}User/EditAccountDetails`,
					requestData,
					{ showload: true, showerror: true, errormessage: 'User settings failed to save' })

				if (response.data.error) {
					this.errors = response.data.errors
				} else {
					if (response.data.redirect) {
						this.$toastr.s('Settings have been saved successfully', 'Settings Changed')
						this.$router.back()
					}

					if (response.data.emailChanged) {
						this.emailConfirmed = false
						this.saved.emailAddress = response.data.email
						this.$toastr.s('Email has successfully been changed', 'Email Changed')
					}

					if (response.data.phoneNumberChanged) {
						this.phoneNumberConfirmed = false
						this.saved.phoneNumber = response.data.phoneNumber
						this.$toastr.s('Phone Number has successfully been changed', 'Phone Number Changed')
					}

					await this.$store.dispatch('loadUser')
				}
			} catch { }
		},
		changePassword () {
			this.$router.replace({ name: 'ChangePassword' })
		},
		changeCookies () {
			this.$router.push({ name: 'Cookies' })
		},
		changeUsageReportSettings () {
			this.$router.push({ name: 'ViewUsageSettings' })
		},
		changeAvatar (show) {
			this.show = show
		},
		cropSuccess (imgDataUrl, field) {
			this.imgDataUrl = imgDataUrl
		},
		cropUploadSuccess (jsonData, field) {
			this.$store.commit('updateAvatar')
		},
		cropUploadFail (status, field) {
			this.imgDataUrl = ''
		},
		verifyModal (type) {
			this.modalDetails.type = type
			this.$bvModal.show('verifyModal')
		},
		async load () {
			try {
				this.$Progress.start()
				var results = await Promise.allSettled([
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}User/EditAccountDetails`, { showerror: true, errormessage: 'User settings failed to load' }),
					axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}groups/ChangeSettings/${this.$store.state.common.paygateId}`, { params: { rights: ['create'] }, showerror: true, errormessage: 'Groups failed to load' })
				])

				if (results[0].status === 'fulfilled') {
					this.emailAddress = results[0].value.data.email
					this.phoneNumber = results[0].value.data.phoneNumber
					this.darkTheme = results[0].value.data.darkTheme
					this.saved.emailAddress = this.emailAddress
					this.saved.phoneNumber = this.phoneNumber

					this.emailConfirmed = results[0].value.data.emailConfirmed
					this.phoneNumberConfirmed = results[0].value.data.phoneNumberConfirmed
					this.authenticatorConfirmed = results[0].value.data.authenticatorConfirmed
					this.defaultProvider = results[0].value.data.defaultProvider
					this.providers = results[0].value.data.providers
					this.loginProviders = results[0].value.data.loginProviders
					this.defaultGroup = results[0].value.data.defaultGroup
					this.ssoDomainSet = results[0].value.data.ssoDomainSet
				}

				if (results[1].status === 'fulfilled') {
					this.groups = results[1].value.data
				}
			} catch {
				this.$Progress.fail()
			} finally {
				this.$Progress.finish()
			}
		},
		cancel () {
			this.$router.back()
		},
		async unConfirmAuthenticator () {
			try {
				await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}User/ResetAuthenticator`, null,
					{ showload: true, showerror: true, errormessage: 'User settings failed to save' })
				this.$toastr.s('Authenticator has successfully been reset', 'Authenticator Reset')

				await this.$store.dispatch('loadUser')
				await this.load()
			} catch { }
		},
		async addMicrosoftSignin () {
			window.location = `${process.env.VUE_APP_IDS_AUTHORITY}/api/ExternalSignIn?provider=Microsoft&returnUrl=${window.location.href}`
		},
		async removeMicrosoftSignin () {
			this.$Progress.start()
			try {
				await axios.delete(`${process.env.VUE_APP_PLATFORM_API_URL}User/Login`, { params: { provider: 'Microsoft' }, showerror: true, errormessage: 'Microsoft Sign-in failed to remove' })

				await this.load()

				this.$toastr.s('Microsoft sign-in Removed', 'Sign-in Removed')
			} catch {
				this.$Progress.fail()
			} finally {
				this.$Progress.finish()
			}
		}
	},
	async created () {
		if (this.passwordChanged) {
			this.$toastr.s('Password has successfully been changed', 'Password Changed')
		}

		if (this.valid === 'False') {
			this.$snapbar.e(this.message)
		}

		if (this.valid === 'True') {
			this.$snapbar.s(this.message)
		}

		this.headers.Authorization = `Bearer ${this.$store.getters.getAccessToken}`
		await this.load()
	},
	validations () {
		return {
			emailAddress: { required, email }
		}
	}
}
</script>
<style lang="scss" scoped>
#avatar-wrapper {
	position: relative;
	width: 164px;

	.avatar-image {
		width: 164px;
		border-radius: 4px;
	}

	#change-picture {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 188, 212, 0.4);
		border: 0;
		border-radius: 0 0 3px 3px;
		color: #fff;
		text-align: center;
		padding: 8px 0;
		width: auto;
		transition: background 0.3s ease;

		&:hover {
			background: rgba(0, 188, 212, 1);
		}
	}
}
</style>
