var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.action.id
    ? _c(
        "actions-block",
        {
          attrs: {
            action: _vm.action,
            status: _vm.status,
            from: _vm.from,
            name: "User",
            shouldShowFooter: _vm.shouldShowFooter,
            userRoleError: _vm.userRoleError
          }
        },
        [
          _c("div", { staticClass: "idb-block" }, [
            _c("div", { staticClass: "idb-block-title" }, [
              _c("h2", [_vm._v("User Information")])
            ]),
            _c("div", { staticClass: "idb-block-content" }, [
              _c("form", [
                _vm.status === "Edit"
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-secondary",
                        attrs: { role: "alert" }
                      },
                      [
                        _vm._v(" The previous details are shown in "),
                        _c("span", { staticClass: "del" }, [_vm._v("red")]),
                        _vm._v(", the new details in "),
                        _c("span", { staticClass: "ins" }, [_vm._v("green")]),
                        _vm._v(". ")
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Name")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.name, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Email")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.email, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Enabled")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.enabled, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Mobile Phone")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.phoneNumber, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Email Action")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.emailAction, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Individual Item Limit")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.paymentLimit, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Submission Limit")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.submissionLimit, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Approval Limit")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.approvalLimit, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 text-right col-form-label",
                      attrs: { for: "description" }
                    },
                    [_vm._v("Roles")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "form-control-plaintext" },
                      _vm._l(_vm.difference.roles, function(diff) {
                        return _c(
                          "span",
                          {
                            key: diff.value,
                            class: { ins: diff.added, del: diff.removed },
                            staticStyle: { "margin-right": "5px" }
                          },
                          [_vm._v(_vm._s(diff.value))]
                        )
                      }),
                      0
                    )
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }