import { render, staticRenderFns } from "./SearchSubmissions.vue?vue&type=template&id=99f28724&"
import script from "./SearchSubmissions.vue?vue&type=script&lang=js&"
export * from "./SearchSubmissions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/paygate-client/paygate-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('99f28724')) {
      api.createRecord('99f28724', component.options)
    } else {
      api.reload('99f28724', component.options)
    }
    module.hot.accept("./SearchSubmissions.vue?vue&type=template&id=99f28724&", function () {
      api.rerender('99f28724', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Views/Bacs/Search/SearchSubmissions.vue"
export default component.exports